import React, { useContext, useEffect, useState } from 'react'
import Tour from 'reactour'
import { FiInfo } from 'react-icons/fi'

import Card from '../../../../components/Card'
import { Button } from '../../../../components/Form'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import AtendimentoProvider from '../../../../contexts/AtendimentoContext'

import { Container, TourHelper } from './styles'

import scrollUtils from '../../../../util/scroll'

import DialogoBox from './Dialogo'
import ArquivosBox from './Arquivos'
import AtendenteBox from './Atendente'
import ListagemReduzidaBox from './Agendamento/ListagemReduzida'

import syndi from '../../../../assets/images/syndi.png'
import syndiHelp from '../../../../assets/images/syndi-help.png'

import { api, authHeaders } from '../../../../services/api'

export default function () {
    scrollUtils.toTop()

    const { user } = useContext(GlobalContext)

    const [showTour, setShowTour] = useState(false)

    const tourSteps = [
        {
            selector: '#startup-tour',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Quer conhecer melhor o módulo de demandas? Eu posso lhe ajudar. Vamos?</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(1)}>COMEÇAR</Button>
                </div>
            )
        },
        {
            selector: '#acoes-disponiveis',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Aqui você tem as ações disponíveis.</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(2)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '.dialog-container',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        {[2, 3, 9, 27, 29, 28, 34, 33].includes(user.perfil.id) ? (
                            <p>Aqui você poderá interagir com os advogados da Assessoria Jurídica do sindicato.</p>
                        ) : (
                            <p>Aqui é possível interagir sigilosamente com o médico.</p>
                        )}
                    </div>
                    <br />
                    <Button onClick={() => goTo(3)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '.conversation__write',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Basta você digitar sua mensagem aqui.</p>
                        <p className="extra">
                            <FiInfo />
                            Você pode enviar links também.
                        </p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(4)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '.btn--send',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>E não esqueça de enviá-la.</p>
                        {[2, 3, 9, 27, 29, 28, 34, 33].includes(user.perfil.id) ? (
                            <p className="extra">
                                <FiInfo />
                                Assim que você enviar, eu avisarei a Assessoria Jurídica imediatamente.
                            </p>
                        ) : (
                            <p className="extra">
                                <FiInfo />
                                Assim que você enviar, o médico e a Assessoria Jurídica receberão, automaticamente, uma cópia da mensagem.
                            </p>
                        )}
                    </div>
                    <br />
                    <Button onClick={() => goTo(5)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '.atendente-container',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Aqui será mostrado quem está atendendo a demanda atualmente.</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(6)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '#button-enviar',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Aqui você pode anexar documentos e modelos a qualquer momento.</p>
                        {![2, 3, 9, 27, 29, 28, 34, 33].includes(user.perfil.id) && (
                            <p className="extra">
                                <FiInfo />
                                Para enviar documentos é preciso que a demanda esteja iniciada.
                            </p>
                        )}
                    </div>
                    <br />
                    <Button onClick={() => goTo(7)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '.atendimentos-presenciais-container',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Aqui serão listados os atendimentos feitos presencialmente, com horário agendado.</p>
                        {[2, 3, 9, 27, 29, 28, 34, 33].includes(user.perfil.id) ? (
                            <p className="extra">
                                <FiInfo />
                                Você pode agendar seu atendimento na lista de ações.
                            </p>
                        ) : (
                            <p className="extra">
                                <FiInfo />
                                Você pode agendar um atendimento presencial com o médico.
                            </p>
                        )}
                    </div>
                    <br />
                    <Button onClick={() => { goTo(8) }}>
                        Próximo
                    </Button>
                </div>
            )
        },
        {
            selector: '.helper-tour-trigger',
            content: () => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Ótimo! Espero ter ajudado.</p>
                        <p className="extra">
                            <FiInfo />
                            Qualquer dúvida, estarei disponível aqui.
                        </p>
                    </div>
                    <br />
                    <Button
                        onClick={() => {
                            setShowTour(false)

                            document.querySelector('.helper-tour-trigger').classList.remove('fadeInUp')
                            document.querySelector('.helper-tour-trigger').classList.add('heartBeat')
                        }}
                    >
                        Finalizar
                    </Button>
                </div>
            )
        }
    ]

    async function checkTour() {
        try {
            const response = await api.post('tour/demanda', {}, authHeaders())

            if (!response.visualizado) {
                setShowTour(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        checkTour()
    }, [])

    return (
        <AtendimentoProvider>
            <Container>
                <main>
                    <Card className="dialog-box animated fadeInLeft">
                        <DialogoBox />
                    </Card>

                    <AtendenteBox className="animated fadeIn slower delay-500ms" />

                    <Card className="files-box animated fadeInRight delay-100ms">
                        <ArquivosBox send_email />

                        <Tour
                            steps={tourSteps}
                            isOpen={showTour}
                            onRequestClose={() => {
                                setShowTour(false)
                            }}
                            rounded={10}
                            scrollDuration={200}
                            startAt={0}
                            showNumber={false}
                        />
                    </Card>

                    <Card className="visitas-box animated fadeInRight delay-300ms">
                        <ListagemReduzidaBox />
                    </Card>
                </main>
            </Container>

            <TourHelper>
                <div className="helper-tour-trigger animated fadeInUp" role="button" tabIndex={0} onClick={() => { setShowTour(true) }}>
                    <img src={syndiHelp} alt="" />
                </div>
            </TourHelper>

        </AtendimentoProvider>
    )
}
