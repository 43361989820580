import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { api } from '../../services/api'

import { Textbox, Calendar, Button } from '../../components/Form'
import Card from '../../components/Card'

const { Iugu } = window

const initialValues = {
    expiracao: '',
    numero: '',
    titular: '',
    cvv: '',
    valor: 10.0
}

export default function () {
    useEffect(() => {
        console.log('Modo teste Iugu habilitado:', process.env.REACT_APP_IUGU_TEST_MODE === 'true')

        Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
        Iugu.setTestMode(process.env.REACT_APP_IUGU_TEST_MODE === 'true')
    }, [])

    async function handleSubmit(values) {
        const {
            expiracao, numero, titular, cvv, valor
        } = values

        const mes = expiracao.getMonth() + 1
        const ano = expiracao.getFullYear()

        const posicao = titular.indexOf(' ')
        const nome = titular.slice(0, posicao)
        const sobrenome = titular.slice(posicao + 1)

        const cartaoCredito = Iugu.CreditCard(numero, mes, ano, nome, sobrenome, cvv)

        Iugu.createPaymentToken(cartaoCredito, async payment => {
            if (payment.errors) {
                console.log(payment.errors)

                toast.error('Ocorreu um erro no pagamento com cartão de crédito.')
            } else {
                const token = payment.id

                try {
                    const body = {
                        token,
                        valor
                    }

                    await api.post('compra_cartao', body)

                    toast.success('Sucesso!')
                } catch (e) {
                    toast.error('Ocorreu um erro no processo de pagamento.')
                }
            }
        })
    }

    return (
        <Card style={{ width: '100%', maxWidth: 600, margin: '20px auto' }}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form style={{ display: 'flex', flexDirection: 'column' }}>
                        <h1 style={{ margin: '0 8px' }}>Simulação de pagamento com cartão de crédito</h1>

                        <Calendar
                            name="expiracao"
                            dateFormat="mm/yy"
                            yearNavigator
                            view="month"
                            label="Data de validade"
                            yearRange={`${new Date().getFullYear()}:${new Date().getFullYear() + 20}`}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Textbox
                            label="Número do cartão"
                            name="numero"
                            // mask={masks.cardNumber} 
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Textbox
                            label="Nome do titular"
                            name="titular"
                            style={{ textTransform: 'uppercase' }}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Textbox
                            label="CVV"
                            name="cvv"
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Textbox
                            label="Valor a pagar"
                            name="valor"
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Button type="submit" loading={isSubmitting} className="transparent" style={{ alignSelf: 'flex-end', marginTop: 16 }}>
                            Pagar
                        </Button>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}
