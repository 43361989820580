import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import * as icons from 'react-icons/fa'
import { toast } from 'react-toastify'

import { Button, Textbox, Select } from '../../../../components/Form'
import Table from '../../../../components/Table'

import { api, authHeaders } from '../../../../services/api'

import formUtils from '../../../../util/form'
import scrollUtils from '../../../../util/scroll'

import { PermissoesContext } from '../../../../contexts/PermissoesContext'

import { Container } from './styles'
import { TipContext } from '../../../../contexts/TipContext'

const baseInitialValues = {
    descricao: '',
    subdescricao: '',
    icone: null,
    grupo: null,
    link: ''
}

export default function () {
    const { permissoes, reload, gruposPermissao } = useContext(PermissoesContext)
    const { tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [editId, setEditId] = useState(null)

    async function handleAdd(values) {
        await api.post('permissao', values, authHeaders())

        toast.success('Permissão cadastrada com sucesso.')

        setInitialValues(baseInitialValues)
    }

    async function handleEdit(values) {
        await api.put(`permissao/${values.id}`, values, authHeaders())

        setEditId(null)

        toast.success('Permissão alterada com sucesso.')
    }

    async function handleSubmit(values, { resetForm }) {
        values = formUtils.extractFormValues(values)

        try {
            if (editId) {
                await handleEdit(values)
            } else {
                await handleAdd(values)

                resetForm()
            }

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handlePrepareEdit(id) {
        setEditId(id)

        scrollUtils.toTop()
    }

    async function handleDelete(id) {
        try {
            await api.delete(`permissao/${id}`, authHeaders())

            toast.success('Permissão excluída com sucesso.')

            reload()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    useEffect(() => {
        if(editId) {
            const permissaoEdit = permissoes.find(perfil => perfil.id === editId)
            const grupo = gruposPermissao.find(g => g.id === permissaoEdit.grupo_permissao_id)

            setInitialValues({
                ...permissaoEdit,
                grupo: {
                    value: grupo.id,
                    label: grupo.descricao
                },
                icone: {
                    value: permissaoEdit.icone,
                    label: permissaoEdit.icone
                }
            })
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [editId])

    return (
        <Container>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize
            >
                {({ setFieldValue }) => (
                    <Form>
                        <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                        <Textbox
                            name="descricao"
                            label="Nome"
                        />

                        <Textbox
                            name="subdescricao"
                            label="Descrição"
                        />

                        <Textbox
                            name="link"
                            label="Link"
                        />

                        <Select 
                            label="Ícone"
                            name="icone"
                            options={Object.keys(icons)?.map(icon => ({
                                label: icon,
                                value: icon
                            })) || []}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <Select 
                            label="Grupo"
                            name="grupo"
                            options={gruposPermissao?.map(grupo => ({
                                label: grupo.descricao,
                                value: grupo.id
                            })) || []}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <Button type="submit" className="transparent">
                            {editId ? 'Salvar alterações' : 'Cadastrar Permissão'}
                        </Button>
                    </Form>
                )}
            </Formik>

            <Table
                headers={[
                    { name: 'descricao', value: 'Nome' },
                    { name: 'subdescricao', value: 'Descrição' }
                ]}
                data={permissoes || []}
                caption="Permissões cadastrados"
                handleDelete={handleDelete}
                handlePrepareEdit={handlePrepareEdit}
            />
        </Container>
    )
}
