import React from 'react'

import { getFrequenceByExpression } from '../..'

import { Container } from './styles'

export default function ({ task }) {
    return (
        <Container>
            <p>
                <b>Consulta SQL:</b>
            </p>

            <p>
                {task.data.sql}
            </p>

            <p style={{ marginTop: 10 }}>
                <b>Frequência:</b>
            </p>

            <p>
                {getFrequenceByExpression(task.expression)}
            </p>
        </Container>
    )
}
