import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 90px auto auto auto auto auto;
		gap: 0 16px;
		grid-template-areas: 
			'nome preview'
			'filtros preview'
			'colunas preview'
			'total preview'
			'ordenacao preview'
			'button button';

		@media(max-width: 1350px) {
			display: flex;
			flex-direction: column;
		}

		section {
			margin-top: 24px;

			ul {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				gap: 0 20px;

				@media(max-width: 900px) {
					grid-template-columns: repeat(2, 1fr);
				}

				@media(max-width: 700px) {
					display: flex;
					flex-direction: column;
				}
			}

			.selects {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
			}

			> h2 {
				font-size: 17px;
				font-weight: 500;
				margin: 0 8px;
			}

			.checkbox {
				margin-bottom: 0;
			}

			.calendar {
				max-width: 340px;
			}

			&:not(.preview) {
				height: fit-content;
			}
		}

		.nome {
			display: flex;
			grid-area: nome;

			.textbox {
				max-width: 450px;
			}
		}

		.ordenacao {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-areas: 
				'titulo titulo'
				'ordenacao quebra';

			> h2 {
				grid-area: titulo;
			}

			@media(max-width: 700px) {
				display: flex;
				flex-direction: column;
			}
		}

		.preview {
			grid-area: preview;

			@media(max-width: 1350px) {
				display: none;
			}

			> h1 {
				font-size: 16px;
				margin-bottom: 14px;
			}
		}

		section:not(.nome) {
			margin-top: 40px;
		}

		.button-container {
			grid-area: button;
			margin-top: 44px;
			display: flex;
			justify-content: flex-end;
		}
	}
`

export const Preview = styled.div`
	background-color: #fff;
	border: solid 1px #0004;
	color: #424242;
	padding: 16px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);

	/* HEADER */
	.header {
		border-spacing: 0;
    	border-collapse: collapse;
		margin-bottom: 16px;
		width: 100%;
	}

	.header tr td {
		border: none;
		font-size: 10px;
	}

	tr.title {
		border-top: solid 2px #424242;
		border-bottom: solid 2px #424242;
	}

	tr.title td.title {
		font-weight: 600;
		font-size: 13px;
		padding: 6px;
	}

	/* BODY */
	.data {
		border-spacing: 0;
    	border-collapse: collapse;
		margin-bottom: 16px;
		width: 100%;
	}

	.data thead tr {
		background-color: #a7e1aa;
		border: solid 1px #424242;
	}
	
	.data thead th {
		font-size: 8px;
		font-weight: 600;
		padding: 3px;
		text-transform: uppercase;
		border-right: solid 1px #424242;
		text-align: center;
	}

	.data thead th:first-child { 
		border-left: solid 1px #424242;
	}

	.data tbody tr {
		border-bottom: solid 1px #424242;
	}

	.data tbody tr td {
		font-size: 8px;
		padding: 0 3px;
		text-transform: uppercase;
		border-right: solid 1px #424242;
		word-break: break-all;
		text-align: center;
	}

	.data tbody tr td:first-child {
		padding-left: 4px;
		border-left: solid 1px #424242;
	}

	.data tbody tr td:last-child {
		padding-right: 4px;
	}

	.data tfoot tr {
		border: solid 1px #424242;
	}

	.data tfoot td {
		font-size: 9px;
		font-weight: 600;
		padding: 4px;
	}

	.data tfoot td:first-child {
		border-left: solid 1px #424242;
	}
	
	.data tfoot td:last-child {
		border-right: solid 1px #424242;
	}

	/* FOOTER */
	.footer {
		font-family: Arial, Helvetica, sans-serif;
		border-top: solid 1px grey;
		font-size: 8px;
		position: absolute;
		bottom: 12px;
		width: calc(100% - 32px);
	}

	footer span{ 
		border: solid red;
	}

	.empty-placeholder {
		text-align: center;
		margin: 14px 0 26px;
		font-size: 12px;
	}
`
