import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { Editor } from 'primereact/editor'
import {
    FaReply, FaEllipsisV, FaArrowRight 
} from 'react-icons/fa'
import { FiSend, FiChevronDown } from 'react-icons/fi'

import { api, authHeaders } from '../../../../services/api'
import ibge from '../../../../services/ibge'

import formUtils from '../../../../util/form'
import scrollUtils from '../../../../util/scroll'
import maskUtils from '../../../../util/masks'

import Card from '../../../../components/Card'
import ProgressBar from '../../../../components/ProgressBar'
import Spinner from '../../../../components/Spinner'
import {
    Select, Button, Checkbox, Textbox, Textarea 
} from '../../../../components/Form'

import {
    Container, ButtonContainer, FiltroContainer, PreviewContainer, MensagemAvulsaContainer, FormEnvioContainer
} from './styles'

import avatar from '../../../../assets/images/avatar.png'
import emailHeader from '../../../../assets/images/layout_email/header.png'
import emailFooter from '../../../../assets/images/layout_email/footer.png'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

const baseInitialValues = {
    canal: { label: 'E-mail', value: 'email' },
    subconjunto: { value: null, label: 'Nenhum' },
    especialidade: null,
    instituicao: null,
    rule: null,
    visao: { value: null, label: 'Filtro personalizado' },
    copia: '',
    template: { label: 'Padrão', value: 'default' }
}

const subconjuntos = [
    { id: null, nome: 'Nenhum' },
    { id: 1, nome: 'Todos os médicos', filtros: [0] },
    { id: 2, nome: 'Apenas médicos associados', filtros: [0, 3] },
    { id: 3, nome: 'Apenas médicos ex-associados', filtros: [0, 4] },
    { id: 4, nome: 'Apenas associados quites', filtros: [0, 3, 5] },
    { id: 5, nome: 'Apenas associados não quites', filtros: [0, 3, 6] },
    { id: 7, nome: 'Por especialidade' }, 
    { id: 8, nome: 'Por instituição' },
    { id: 9, nome: 'Por município' }
]

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [especialidades, setEspecialidades] = useState([])
    const [instituicoes, setInstituicoes] = useState([])
    const [rules, setRules] = useState([])
    const [filtros, setFiltros] = useState([])
    const [visoes, setVisoes] = useState([])
    const [destinatariosCount, setDestinatariosCount] = useState(null)
    const [confirmacao, setConfirmacao] = useState(false)
    const [enableScroll, setEnableScroll] = useState(true)
    const [filtering, setFiltering] = useState(false)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [cidades, setCidades] = useState(null)
	
    async function loadEspecialidades() {
        const response = await api.get('/especialidade')

        const serializado = response.map(especialidade => ({
            label: especialidade.descricao,
            value: especialidade.id
        }))

        setEspecialidades(serializado)
    }

    async function loadInstituicoes() {
        const response = await api.get('/instituicao', {
            params: {
                types_ids: [2, 3, 4, 5, 6, 7]
            }
        })

        const serializado = response.map(instituicao => ({
            label: instituicao.nome_fantasia,
            value: instituicao.id
        }))

        setInstituicoes(serializado)
    }

    async function loadCidades() {
        const { data } = await ibge.get('estados/PA/municipios')

        setCidades(data.map(({ nome }) => nome).sort())
    }
	
    async function loadRules() {
        try {
            const response = await api.get('mensageria/template', authHeaders())

            setRules(response)
        } catch(e) {
            toast.error('Houve um problema ao carregar as mensagens predefinidas.')
        }
    }

    async function loadVisoes() {
        try {
            const response = await api.get('visao', authHeaders())

            setVisoes([
                {
                    id: null,
                    descricao: 'Filtro personalizado'
                },
                ...response
            ])
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function loadFiltros() {
        const response = await api.get('mensageria_filtro', authHeaders())

        const filtroLista = response.map(filtro => ({
            descricao: filtro.descricao,
            id: filtro.id
        }))

        setFiltros(filtroLista)

        const filtrosInitialValues = filtroLista.reduce((result, filtro, index) => ({
            ...result,
            [`filtro[${index}]`]: filtro.id
        }), {})

        setInitialValues({
            ...baseInitialValues,
            ...filtrosInitialValues
        })
    }

    async function handleFilterApply(values) {
        try {
            setFiltering(true)

            const especialidade = values.especialidade?.map(esp => esp.value) || []
            const instituicao = values.instituicao?.map(inst => inst.value) || []
            const cidade = values.cidade?.map(cid => cid.value) || []
            const visao = values.visao?.value
            
            const idFiltros = values.filtro
                ?.map((f, index) => f ? filtros[index].id : null)
                .filter(f => !!f) || []

            const { count } = await api.post('mensageria_filtro', { 
                filtros: idFiltros,
                especialidades: especialidade,
                instituicoes: instituicao,
                cidades: cidade,
                visao,
                canal: values.canal?.value,
                sexo: values.sexo?.value
            }, authHeaders())

            setDestinatariosCount(count)
        } catch(e) {
            toast.error('Erro ao aplicar os filtros.')
        } finally {
            setFiltering(false)
        }
    }

    useEffect(() => {
        loadEspecialidades()
        loadInstituicoes()
        loadRules()
        loadFiltros()
        loadVisoes()
        loadCidades()

        loadTips(setCodigo, 'form_envio_mensagem')

        setTimeout(() => { scrollUtils.toTop() }, 500)
    }, [])

    useEffect(() => {
        if(enableScroll) {
            setTimeout(() => {
                setEnableScroll(false)
            }, 2000)
        }
    }, [enableScroll])

    async function handleSubmit(values, { resetForm }) {
        try {
            values = formUtils.extractFormValues(values)

            const especialidade = values.especialidade?.map(esp => esp.value) || []
            const instituicao = values.instituicao?.map(inst => inst.value) || []
            const cidade = values.cidade?.map(cid => cid.value) || []

            const idFiltros = values.filtro
                ?.map((f, index) => f ? filtros[index].id : null)
                .filter(f => !!f) || []

            const { count } = await api.post('mensageria/enviar', {
                ...values,
                filtro: idFiltros,
                especialidades: especialidade,
                instituicoes: instituicao,
                cidades: cidade,
                visao: values.visao
            }, authHeaders())
            
            toast.success(`${count} mensagens foram enviadas!`)

            resetForm()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    function handleCheckFilters(subconjunto, setFieldValue, values) {
        filtros.forEach((_, index) => {
            setFieldValue(`filtro[${index}]`, false)
        })

        let atualizado = subconjuntos.map(() => false)

        const { filtros: filters } = subconjuntos.find(sub => sub.id === subconjunto)

        if(filters) {
            atualizado = []

            subconjuntos.forEach((_, index) => { 
                atualizado.push(filters.includes(index))
    
                setFieldValue(`filtro[${index}]`, filters.includes(index))
            })
        }

        handleFilterApply({
            ...values,
            filtro: atualizado
        })
    }

    function getTemplateHeader(template) {
        switch(template) {
            case 'default': return emailHeader
            default: return null
        }
    }

    function getTemplateFooter(template) {
        switch(template) {
            case 'default': return emailFooter
            default: return null
        }
    }

    async function handleEnviarAvulso(values, { resetForm }) {
        values = formUtils.extractFormValues(values)

        try {
            const { avulso_canal, avulso_mensagem, avulso_contato } = values

            await api.post('mensageria/enviar_avulso', {
                avulso_mensagem,
                avulso_contato,
                avulso_canal
            }, authHeaders())

            toast.success('Mensagem enviada com sucesso.')

            resetForm()
        } catch(e) {
            toast.error(e.msg)
        }
    }
	
    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Envio de mensagens</h1>

                    <p style={{ margin: '14px 8px' }}>{tips?.formulario}</p>

                    <FormEnvioContainer>
                        <summary>
                            <div>
                                <h1>Envio em massa</h1>    
                                <FiChevronDown size={18} />
                            </div>
                        </summary>

                        <div className="detalhes">
                            <Formik
                                onSubmit={handleSubmit}
                                initialValues={initialValues}
                            >
                                {({
                                    setFieldValue, values, setValues, isSubmitting 
                                }) => (
                                    <Form>
                                        <Select 
                                            name="canal"
                                            label="Canal"
                                            onChange={(selected, meta) => { 
                                                setFieldValue(meta.name, selected) 

                                                handleFilterApply({
                                                    ...values,
                                                    canal: selected
                                                })

                                                setEnableScroll(values.rule && selected.value === 'email')
                                            }}
                                            options={[
                                                { label: 'E-mail', value: 'email' }
                                            ]}
                                            containerStyle={{ gridArea: 'canal' }}
                                        />

                                        <Select 
                                            name="rule"
                                            label="Mensagem"
                                            onChange={(selected, meta) => { 
                                                setFieldValue(meta.name, selected)

                                                // setEnableScroll(selected && values.canal?.value === 'email')
                                            }}
                                            options={rules.filter(rule => !rule.template.automatic)
                                                .map(rule => ({
                                                    label: rule.name,
                                                    value: rule.id
                                                }))}
                                            containerStyle={{ gridArea: 'mensagens' }}
                                        />

                                        {values.canal?.value === 'email' && (
                                            <Select 
                                                name="template"
                                                label="Template"
                                                onChange={(selected, meta) => {
                                                    setFieldValue(meta.name, selected)

                                                    // setEnableScroll(selected && values.canal?.value === 'email')
                                                }}
                                                options={[
                                                    { label: 'Padrão', value: 'default' }
                                                ]}
                                                containerStyle={{ gridArea: 'template' }}
                                            />
                                        )}

                                        <h2 style={{ gridArea: 't2' }}>Destinatários</h2>
                                        
                                        {visoes.length > 0 ? (
                                            <div style={{ gridArea: 'visao' }} className="visao-container">
                                                <Select 
                                                    name="visao"
                                                    label="Grupos de pessoas"
                                                    onChange={(selected, meta) => { 
                                                        setFieldValue(meta.name, selected)

                                                        handleFilterApply({
                                                            ...values,
                                                            visao: selected
                                                        })
                                                    }}
                                                    options={visoes
                                                        .map(visao => ({
                                                            label: visao.descricao,
                                                            value: visao.id
                                                        }))}
                                                />

                                                {!values.visao?.value && (
                                                    <p>Selecione ao lado os filtros e verifique a quantidade de destinatários.</p>
                                                )}
                                            </div>
                                        ) : (
                                            <Spinner label="Carregando grupos..." containerStyle={{ gridArea: 'visao' }} />
                                        )}

                                        {values.visao?.value === null && values.subconjunto?.value === 7 && (
                                            <Select 
                                                name="especialidade"
                                                label="Especialidade"
                                                onChange={(selected, meta) => {
                                                    if(selected) {
                                                        setFieldValue(meta.name, selected)

                                                        handleFilterApply({
                                                            ...values,
                                                            especialidade: selected
                                                        })
                                                    }
                                                }}
                                                options={especialidades}
                                                isMulti
                                                containerStyle={{ gridArea: 'esp' }}
                                            />
                                        )}

                                        {values.visao?.value === null && values.subconjunto?.value === 8 && (
                                            <Select 
                                                name="instituicao"
                                                label="Local de trabalho"
                                                onChange={(selected, meta) => {
                                                    if(selected) {
                                                        setFieldValue(meta.name, selected)

                                                        handleFilterApply({
                                                            ...values,
                                                            instituicao: selected
                                                        })
                                                    }
                                                }}
                                                options={instituicoes}
                                                isMulti
                                                containerStyle={{ gridArea: 'esp' }}
                                            />
                                        )}

                                        {values.visao?.value === null && values.subconjunto?.value === 9 && (
                                            <Select 
                                                name="cidade"
                                                label="Cidade / município"
                                                onChange={(selected, meta) => {
                                                    if(selected) {
                                                        setFieldValue(meta.name, selected)

                                                        handleFilterApply({
                                                            ...values,
                                                            cidade: selected
                                                        })
                                                    }
                                                }}
                                                options={cidades.map(c => ({
                                                    label: c,
                                                    value: c
                                                }))}
                                                isMulti
                                                containerStyle={{ gridArea: 'esp' }}
                                            />
                                        )}

                                        {values.canal?.value === 'email' ? (
                                            <Textbox 
                                                name="copia"
                                                label="Enviar cópia para"
                                                type="email"
                                                placeholder="exemplo@email.com"
                                                containerStyle={{ gridArea: 'copia' }}
                                            />
                                        ) : (
                                            <div />
                                        )}

                                        {!values.visao?.value && filtros.length > 0 && (
                                            <>
                                                <Select 
                                                    name="subconjunto"
                                                    label="Filtros predefinidos"
                                                    isDisabled={!!values.visao.value}
                                                    onChange={(selected, meta) => { 
                                                        setFieldValue(meta.name, selected) 

                                                        handleCheckFilters(selected.value, setFieldValue, values)
                                                    }}
                                                    options={subconjuntos.map(subconjunto => ({
                                                        label: subconjunto.nome,
                                                        value: subconjunto.id
                                                    }))}
                                                    containerStyle={{ gridArea: 'subconjunto' }}
                                                    className="animated fadeIn"
                                                />
                                        
                                                <FiltroContainer>
                                                    {filtros.map((filtro, index) => (
                                                        <Checkbox 
                                                            key={filtro.id}
                                                            name={`filtro[${index}]`}
                                                            disabled={!!values.visao.value || filtering}
                                                            label={filtro.descricao}
                                                            handleChange={e => { 
                                                                setFieldValue(e.target.name, e.target.checked) 

                                                                let filters = values.filtro

                                                                if(!filters) {
                                                                    filters = filtros.map(() => false)
                                                                }

                                                                filters[index] = e.target.checked

                                                                setValues({
                                                                    ...values,
                                                                    filtro: filters
                                                                })

                                                                setFieldValue('confirmacao', false)
                                                                setConfirmacao(false)

                                                                handleFilterApply({
                                                                    ...values,
                                                                    filtro: filters
                                                                })
                                                            }}
                                                        />	
                                                    ))}                                        
                                                </FiltroContainer>
                                            </>
                                        )}

                                        {filtering ? (
                                            <div className="badge">
                                                <Spinner 
                                                    type="ThreeDots"
                                                    label="Aplicando filtros..."
                                                    color="#424242"
                                                    fontSize={14}
                                                    containerStyle={{ margin: 10 }}
                                                />
                                            </div>
                                        ) : destinatariosCount !== null ? (
                                            <div className="badge">
                                                <Checkbox 
                                                    name="confirmacao"
                                                    handleChange={e => { 
                                                        setFieldValue(e.target.name, e.target.checked) 

                                                        setConfirmacao(e.target.checked)
                                                    }}
                                                />

                                                <span>
                                                    Enviar para 
                                                    <b>{` ${destinatariosCount}`}</b>
                                                    destinatário(s)
                                                </span>
                                            </div>
                                        ) : null}
                                        
                                        <ProgressBar label="Enviando mensagens" visible={isSubmitting} modal />

                                        <ButtonContainer>
                                            <Button type="reset" className="transparent">Limpar</Button>

                                            <Button type="submit" className="white" disabled={!confirmacao || isSubmitting}>
                                                Enviar
                                            </Button>
                                        </ButtonContainer>

                                        {values.rule && values.canal?.value === 'email' && (
                                            <PreviewContainer>
                                                {enableScroll && (() => {
                                                    const previewContainer = document.querySelector('.preview-container')

                                                    if(previewContainer) {
                                                        scrollUtils.toElement('.preview-container')

                                                        previewContainer.classList.add('flash')
                                                    }

                                                    setTimeout(() => {
                                                        scrollUtils.toTop()
                                                    }, 1800)
                                                })()}

                                                <h1>Previsualização da mensagem</h1>

                                                <img src={avatar} alt="" style={{ gridArea: 'avatar', width: 44, height: 44 }} />

                                                <span style={{ gridArea: 'recipient' }}>
                                                    <strong>João da Silva</strong>
                                                    {'<joaosilva@email.com>'}
                                                    <br />
                                                    <span>para mim</span>
                                                </span>

                                                <FaReply size={14} style={{ gridArea: 'arrow' }} />

                                                <FaEllipsisV size={14} style={{ gridArea: 'menu' }} />

                                                <img src={getTemplateHeader(values.template.value)} alt="" style={{ gridArea: 'header' }} className="header" />

                                                <Editor 
                                                    className="editor-container"
                                                    value={rules.find(rule => rule.id === values.rule?.value)?.template.text?.replace(/\n/g, '<br/>')} 
                                                    readOnly
                                                    theme="snow"
                                                />

                                                <img src={getTemplateFooter(values.template.value)} alt="" style={{ gridArea: 'footer' }} className="footer" />

                                                <div style={{ gridArea: 'btn' }} className="button-container">
                                                    <button className="btn">
                                                        <FaReply size={14} />
                                                        Responder
                                                    </button>

                                                    <button className="btn">
                                                        <FaArrowRight size={14} />
                                                        Encaminhar
                                                    </button>
                                                </div>
                                            </PreviewContainer>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </FormEnvioContainer>

                    <FormEnvioContainer>
                        <summary>
                            <div>
                                <h1>Envio avulso</h1>    
                                <FiChevronDown size={18} />
                            </div>
                        </summary>

                        <div className="detalhes">
                            <MensagemAvulsaContainer>
                                <Formik
                                    onSubmit={handleEnviarAvulso}
                                    initialValues={{
                                        avulso_canal: { label: 'E-mail', value: 'email' },
                                        avulso_mensagem: '',
                                        avulso_contato: ''
                                    }}
                                >
                                    {({ setFieldValue: avulsoSetFieldValue, values: avulsoValues, isSubmitting: avulsoIsSubmitting }) => (
                                        <Form>                                            
                                            <Select 
                                                name="avulso_canal"
                                                label="Canal de envio"
                                                options={[
                                                    { label: 'E-mail', value: 'email' }
                                                ]}
                                                onChange={(selected, meta) => { avulsoSetFieldValue(meta.name, selected) }}
                                            />

                                            <Textbox 
                                                name="avulso_contato"
                                                label={avulsoValues.avulso_canal?.value === 'email' ? 'E-mail' : 'Número'}
                                                type={avulsoValues.avulso_canal?.value === 'email' ? 'email' : 'text'}
                                                mask={avulsoValues.avulso_canal?.value === 'email' ? null : maskUtils.mobile}
                                            />

                                            <Textarea 
                                                name="avulso_mensagem"
                                                label="Mensagem"
                                            />

                                            <Button type="submit" className="transparent" loading={avulsoIsSubmitting}>
                                                Enviar
                                                <FiSend style={{ transform: 'rotate(45deg)' }} size={16} />
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </MensagemAvulsaContainer>
                        </div>
                    </FormEnvioContainer>
                </Card>
            </main>
        </Container>
    )
}
