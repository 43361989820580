import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import Table from '../../../../../components/Table'
import Modal from '../../../../../components/Modal'
import FormAdvogado from '../../../../../components/FormAdvogado'

import { api, authHeaders } from '../../../../../services/api'

import { Container } from './styles'

export default function ({ detalhes, reload }) {
    const [editAdvogado, setEditAdvogado] = useState(false)

    function getHeaders() {
        const headers = []

        if (detalhes.descontos.some(desc => desc.sexo)) {
            headers.push({ name: 'sexo', value: 'Sexo', centered: true })
        }

        if (detalhes.descontos.some(desc => desc.universidade)) {
            headers.push({ name: 'universidade', value: 'Universidade', centered: true })
        }

        if (detalhes.descontos.some(desc => desc.pcd)) {
            headers.push({ name: 'pcd', value: 'PCD', centered: true })
        }

        headers.push(
            { name: 'faixa_etaria', value: 'Faixa etária', centered: true }
        )

        if(detalhes.descontos.every(desc => desc.valor_sem_desconto)) {
            headers.push(
                { name: 'valor_sem_desconto', value: 'Valor de balcão', centered: true }
            )
        }

        if(detalhes.descontos.every(desc => desc.valor_com_desconto)) {
            headers.push(
                { name: 'valor_com_desconto', value: 'Valor a pagar', centered: true }
            )
        }

        if(detalhes.descontos.every(desc => desc.percentual_desconto)) {
            headers.push(
                { name: 'percentual_desconto', value: '% de desconto', centered: true }
            )
        }

        // if(porPercentual) {
        //     headers.push(
        //         { name: 'valor_sem_desconto', value: 'Valor de balcão', centered: true },
        //         { name: 'valor_com_desconto', value: 'Valor a pagar', centered: true },
        //         { name: 'percentual_desconto', value: '% de desconto', centered: true }
        //     )
        // }

        return headers
    }

    async function handleDeleteAdvogado(pessoaId) {
        try {
            await api.delete(`advogado/${detalhes.id}/${pessoaId}`, authHeaders())

            toast.success('Advogado excluído.')

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handlePrepareEdit(pessoaId) {
        const response = await api.get('advogado', {
            params: {
                pessoa_id: pessoaId
            },
            ...authHeaders()
        })

        const { id, pessoa, areas } = response[0]

        setEditAdvogado({
            id,
            nome: pessoa.nome,
            data_nascimento: format(new Date(pessoa.data_nascimento), 'dd/MM/yyyy'),
            areas: areas?.map(a => ({
                label: a.descricao,
                value: a.id
            })) || [],
            assuntos: pessoa.assuntos?.map(a => ({
                label: a.descricao,
                value: a.id
            })) || [],
            cpf: pessoa.documentos.find(doc => doc.tipo_documento.id === 2).identificador,
            email: pessoa.contato.find(c => c.tipo_contato_id === 6).contato,
            fone: pessoa.contato.find(c => c.tipo_contato_id === 3).contato,
            oab: pessoa.documentos.find(doc => doc.tipo_documento.id === 32).identificador
        })
    }

    return (
        <>
            <Container>
                <div>
                    <b>{detalhes?.name}</b>
                </div>

                <div>
                    <b>Endereço: </b>
                    {detalhes?.endereco?.uf ? `${detalhes?.endereco.rua || ''}${detalhes?.endereco.numero ? `, ${detalhes?.endereco.numero}` : ''} ${detalhes?.endereco.complemento ? `- ${detalhes?.endereco.complemento}` : ''}${detalhes?.endereco.bairro ? ` - ${detalhes?.endereco.bairro}` : ''} - ${detalhes?.endereco.cidade} - ${detalhes?.endereco.uf}` : 'Não informado'}
                </div>

                <div>
                    <b>
                        {`${detalhes?.tipo === 'PF' ? 'CPF' : 'CNPJ'}: `}
                    </b>
                    {detalhes?.documentos.find(doc => [2, 14].includes(doc.tipo_documento_id)).identificador}
                </div>

                <div>
                    <b>E-mail: </b>
                    {detalhes?.email || 'Não informado'}
                </div>

                <div>
                    <b>Área de atuação: </b>
                    {detalhes?.area_atuacao}
                </div>

                <div>
                    <b>Fone: </b>
                    {detalhes?.fone || 'Não informado'}
                </div>

                <div>
                    <b>Data de início do contrato: </b>
                    {detalhes?.data_inicio ? detalhes.data_inicio : ''}
                </div>

                <div>
                    <b>Data de término do contrato: </b>
                    {detalhes?.data_fim ? detalhes.data_fim : ''}
                </div>

                <div className="descricao" dangerouslySetInnerHTML={{ __html: detalhes?.descricao ? detalhes?.descricao.replace(/\n/g, '<br/>') : '' }} />

                {detalhes?.descontos.length > 0 && (
                    <Table
                        headers={getHeaders()}
                        data={detalhes?.descontos}
                    />
                )}

                {detalhes?.parentescos?.length > 0 && (
                    <Table
                        headers={[
                            { name: 'parentesco', value: 'Parentesco', centered: true },
                            { name: 'documentos', value: 'Documentos exigidos', centered: true }
                        ]}
                        data={detalhes?.parentescos}
                    />
                )}

                {detalhes?.advogados && (
                    <Table
                        caption="Advogados vinculados"
                        headers={[
                            { name: 'nome', value: 'Advogado' },
                            { name: 'areas', value: 'Áreas de atuação', centered: true }
                        ]}
                        data={detalhes.advogados}
                        handleDelete={handleDeleteAdvogado}
                        confirmExclusion={{
                            template: 'Deseja realmente excluir o advogado <b>#attr#</b>?',
                            attr: 'nome'
                        }}
                        handlePrepareEdit={handlePrepareEdit}
                    />
                )}
            </Container>

            <Modal
                isOpen={!!editAdvogado}
                handleClose={() => { setEditAdvogado(false) }}
                title="Editar advogado"
            >
                <FormAdvogado
                    editItem={editAdvogado}
                    convenio={detalhes}
                    reload={() => {
                        setEditAdvogado(false)

                        reload()
                    }}
                />
            </Modal>
        </>
    )
}
