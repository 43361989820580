import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'

import Card from '../../../../components/Card'
import { Container } from './styles'
import { api, authHeaders } from '../../../../services/api'
import Spinner from '../../../../components/Spinner'
import { Button } from '../../../../components/Form'

export default function () {
	const { id } = useParams()

	const history = useHistory()

	const [enquete, setEnquete] = useState(null)
	const [enqueteSituacao, setEnqueteSituacao] = useState(null)
	const [enqueteParticipacao, setEnqueteParticipacao] = useState(null)

	async function loadEnquete() {
		try {
			const response = await api.get(`enquete/${id}`, authHeaders())
			const responseSituacao = await api.get(`enquete/${id}/situacao`, authHeaders())

			setEnquete(response)
			setEnqueteSituacao(responseSituacao.enquete)
			setEnqueteParticipacao(responseSituacao.participacao)
		} catch (e) {
			toast.error(e.msg)
		}
	}

	useEffect(() => {
		loadEnquete()
	}, [])

	if (!enquete || !enqueteSituacao || !enqueteParticipacao) {
		return (
			<Container>
				<main className="animated fadeIn faster">
					<Card>
						<Spinner />
					</Card>
				</main>
			</Container>
		)
	}

	if (enqueteSituacao === 'não iniciada') {
		toast.error('Esta enquete ainda não foi iniciada. Aguarde a data de início.')

		history.push('/grupo_permissoes/29')

		return null
	}

	if (enqueteSituacao === 'finalizada') {
		toast.error('Esta enquete já foi encerrada.')

		history.push('/grupo_permissoes/29')

		return null
	}

	if (enqueteParticipacao === 'não iniciada') {
		history.push(`/enquete/${id}/votar`)

		return null
	}

	if (enqueteParticipacao === 'não finalizada') {
		history.push(`/enquete/${id}/votar`)

		return null
	}

	return (
		<Container>
			<main className="animated fadeIn faster">
				<Card>
					<h1>Obrigado!</h1>

					<p
						dangerouslySetInnerHTML={{
							__html: `Sua participação na enquete <b>${enquete.nome}</b> foi registrada!`
						}}
					/>

					<div className="button-container">
						<Button onClick={() => { history.push('/grupo_permissoes/29') }} className="white">
							Voltar
						</Button>
					</div>
				</Card>
			</main>
		</Container>
	)
}
