import React, { useEffect, useState, useContext } from 'react'
import { format } from 'date-fns'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { FiCheck, FiTrash } from 'react-icons/fi'
import { extname } from '../../../../../../util/path'
import genericIcon from '../../../../../../assets/images/file.png'
import Modal from '../../../../../../components/Modal'
import Spinner from '../../../../../../components/Spinner'
import {
    File, Textbox, Button
} from '../../../../../../components/Form'

import {
    Container, PreviewContainer, UploadContainer
} from './styles'

import { GlobalContext } from '../../../../../../contexts/GlobalContext'
import { ProcessoContext } from '../../../../../../contexts/ProcessoContext'
import { api, authHeaders } from '../../../../../../services/api'
import stringUtils from '../../../../../../util/string'
import Icon from '../../../../../../components/Icon'

const previewableFormats = ['.jpg', '.jpeg', '.bmp', '.png', '.gif']

const perfisAssociado = [2, 16, 21, 22]

export default function ({
    send_email = false, tramiteId, uploadURL, readOnly
}) {
    const { user } = useContext(GlobalContext)
    const {
        processo, reloadArquivos, interacaoHabilitada, reloadProcesso, tramite, setTramite
    } = useContext(ProcessoContext)

    const [arquivos, setArquivos] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [showUploadForm, setShowUploadForm] = useState(false)
    const [uploadDesbloqueado, setUploadDesbloqueado] = useState(false)

    function handleShowArquivo(arquivo) {
        const { link } = arquivo

        const extension = extname(link)

        if (previewableFormats.includes(extension)) {
            setPreviewImage(arquivo)
        } else {
            window.open(link, '_blank').focus()
        }
    }

    async function handleAdd(values) {
        try {
            const { arquivo, descricao } = values

            setArquivos(old => [
                ...old,
                {
                    id: arquivo,
                    link: null,
                    created_at: new Date(),
                    upload: {
                        descricao,
                        sender_id: user.pessoa.id
                    },
                    sender: {
                        id: user.pessoa.id,
                        nome: user.pessoa.nome
                    }
                }
            ])

            await api.post(uploadURL, {
                ...values,
                send_email
            }, authHeaders())

            toast.success('Documento anexado.')

            setShowUploadForm(false)

            reloadArquivos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(arquivo) {
        try {
            setArquivos(old => old.filter(file => file.id !== arquivo.id))

            await api.delete(`processo/${processo.id}/arquivo/${arquivo.id}`, authHeaders())

            toast.success('Documento removido.')

            reloadArquivos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSendToPublico(arquivo) {
        try {
            await api.post('processo/tramite/arquivo/tornar_publico', {
                processo_id: processo.id,
                arquivo_id: arquivo.id
            }, authHeaders())

            toast.success('O arquivo agora está acessível na tela inicial do atendimento.')

            setTramite(null)

            reloadProcesso()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if (processo) {
            if (tramiteId) {
                setArquivos(processo.tramites.find(t => t.id === tramiteId).arquivos)
            } else {
                setArquivos(processo.arquivos)
            }

            if (readOnly === undefined) {
                setUploadDesbloqueado(interacaoHabilitada)
            } else {
                setUploadDesbloqueado(!readOnly)
            }
        }
    }, [processo, tramiteId, interacaoHabilitada, readOnly])

    return (
        <>
            <Container className="animated fadeIn delay-300ms">
                <h1>Documentos</h1>

                {uploadDesbloqueado ? (
                    <Button id="button-enviar" className="transparent" onClick={() => { setShowUploadForm(true) }}>Enviar documento</Button>
                ) : null}

                {arquivos ? (
                    <>
                        {!arquivos.length ? (
                            <p className="empty-label">
                                {(processo.status_id === 3 && !perfisAssociado.includes(user.perfil.id)) || processo.status_id === 2
                                    ? 'Não é possível enviar documentos.'
                                    : 'Nenhum documento anexado'}
                            </p>
                        ) : (
                            <ul>
                                {arquivos.map(arquivo => {
                                    const extension = extname(arquivo.link)
                                    const uploading = arquivo.link === null

                                    return (
                                        <li key={arquivo.id}>
                                            {uploading ? (
                                                <Spinner label="Enviando..." containerClass="spinner-upload" />
                                            ) : (
                                                <img
                                                    src={previewableFormats.includes(extension) ? arquivo.link : genericIcon}
                                                    alt={arquivo.descricao}
                                                    onClick={() => { handleShowArquivo(arquivo) }}
                                                />
                                            )}

                                            <h1
                                                style={{ gridColumn: processo.status_id === 2 ? '2/span 2' : '2' }}
                                                title={arquivo.upload.descricao}
                                            >
                                                {arquivo.upload.descricao}
                                            </h1>

                                            {uploadDesbloqueado && !uploading && (
                                                <Icon
                                                    Icon={FiTrash}
                                                    onClick={() => { handleDelete(arquivo) }}
                                                    confirmable
                                                    texto="Tem certeza de que deseja excluir o documento?"
                                                    className="remover"
                                                    size={20}
                                                    tooltip="Remover"
                                                />
                                            )}

                                            <h2 dangerouslySetInnerHTML={{ __html: `Enviado por <b>${stringUtils.reduceName(arquivo.sender.nome)}</b>` }} />

                                            <h3>{format(new Date(arquivo.created_at), 'dd/MM/yyyy HH:mm\'h\'')}</h3>

                                            {tramite && (
                                                <Icon
                                                    Icon={FiCheck}
                                                    onClick={() => { handleSendToPublico(arquivo) }}
                                                    confirmable
                                                    texto="Deseja realmente tornar este arquivo público dentro do atendimento?"
                                                    className="tornar-publico"
                                                    size={20}
                                                    tooltip="Tornar público"
                                                />
                                            )}
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                    </>
                ) : (
                    <Spinner label="Carregando documentos..." />
                )}
            </Container>

            <Modal
                isOpen={!!previewImage}
                handleClose={() => { setPreviewImage(null) }}
                title={previewImage?.upload?.descricao}
            >
                <PreviewContainer>
                    <p dangerouslySetInnerHTML={{ __html: `Arquivo anexado por <b>${stringUtils.reduceName(previewImage?.sender.nome)}</b>` }} />

                    <p>Para salvar a imagem, clique com o botão direito do mouse.</p>

                    <img src={previewImage?.link} alt="" />
                </PreviewContainer>
            </Modal>

            <Modal
                isOpen={showUploadForm}
                handleClose={() => { setShowUploadForm(false) }}
                title="Enviar novo documento"
            >
                <UploadContainer>
                    <Formik
                        onSubmit={handleAdd}
                        initialValues={{ arquivo: null, descricao: '' }}
                    >
                        {({ setFieldValue, isSubmitting }) => (
                            <Form>
                                <File
                                    name="arquivo"
                                    onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                    label="Anexe aqui o documento"
                                    format="square"
                                    style={{ width: 'calc(100% - 16px)' }}
                                    previewSize={['100%', '140px']}
                                    maxSize={30 * 1024 * 1024}
                                />

                                <Textbox
                                    label="Nome do documento"
                                    name="descricao"
                                />

                                <Button type="submit" className="transparent" loading={isSubmitting}>Enviar</Button>
                            </Form>
                        )}
                    </Formik>
                </UploadContainer>
            </Modal>
        </>
    )
}
