import React, {
    useState, useEffect, useCallback, useContext
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { FaFilePdf } from 'react-icons/fa'

import { api, authHeaders } from '../../../services/api'

import { downloadRelatorioJasper } from '../../../util/file'

import { Button, Select } from '../../../components/Form'
import Spinner from '../../../components/Spinner'

import Card from '../../../components/Card'

import { Container, ButtonContainer } from './styles'

import Yup from '../../../services/yup'
import { GlobalContext } from '../../../contexts/GlobalContext'

const validation = Yup.object().shape({
    ano: Yup.string().ensure().required('Informe o ano.')
})

export default function () {
    const { user } = useContext(GlobalContext)

    const [anos, setAnos] = useState([])

    useEffect(() => {
        const currentYear = new Date().getFullYear()
        const listaAnos = []

        for (let i = currentYear; i >= 2019; i--) {
            listaAnos.push({
                label: i,
                value: i
            })
        }

        setAnos(listaAnos)
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const { ano } = values

        const { associado: { pessoa } } = await api.get(`pessoa/${user.pessoa.id}`, authHeaders())

        const cpf = pessoa.documentos.find(doc => doc.tipo_documento.id === 2).identificador

        if (!cpf) {
            toast.error('Usuário sem CPF cadastrado.')
            return
        }

        const dadosPlano = await api.get('declaracao_anual', {
            params: {
                ano: ano?.value,
                cpf
            },
            ...authHeaders()
        })

        if (dadosPlano.length === 0) {
            toast.info('Não há dados referente ao ano informado.')

            return
        }

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioDeclaracaoAnualUnimed',
                parametros: {
                    ano: ano?.value,
                    cpf
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, 'RelatorioDeclaracaoAnualUnimed')

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Declaração anual Unimed</h1>

                    <Formik
                        initialValues={{ ano: '' }}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                        containerStyle={{ gridArea: 'form' }}
                    >
                        {({
                            isSubmitting, setFieldValue
                        }) => (
                            <Form>
                                <Select
                                    name="ano"
                                    label="Ano"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={anos}
                                    selectionMode="range"
                                    containerStyle={{ gridArea: 'f1' }}
                                />

                                <Spinner
                                    visible={isSubmitting}
                                    label="Gerando documento..."
                                    containerStyle={{ gridArea: 'spinner' }}
                                />

                                <ButtonContainer>
                                    <Button
                                        type="submit"
                                        loading={isSubmitting}
                                        className="transparent"
                                    >
                                        Gerar declaração
                                        <FaFilePdf size={20} />
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
