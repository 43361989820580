import styled from 'styled-components'

import Card from '../../../../components/Card'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;
		line-height: 30px;
		display: grid;
		grid-template-columns: repeat(1fr, 3);
		grid-template-areas:
			'dados_pessoais dados_pessoais dados_pessoais'
			'pessoal endereco pagamento'
			'locais_trabalho locais_trabalho locais_trabalho'
			'contatos contatos contatos'
			'medias_sociais medias_sociais medias_sociais'
			'arquivos arquivos arquivos';

		@media(max-width: 1090px) {
			grid-template-columns: auto auto;
			grid-template-areas:
				'dados_pessoais dados_pessoais'
				'pessoal pagamento'
				'endereco contatos'
				'medias_sociais medias_sociais'
				'locais_trabalho locais_trabalho'
				'arquivos arquivos';
		}

		@media(max-width: 800px) {
			padding: 30px 12px;
			grid-template-columns: 1fr;
			grid-template-areas:
				'dados_pessoais'
				'pessoal'
				'pagamento'
				'endereco'
				'locais_trabalho'
				'contatos'
				'medias_sociais'
				'arquivos';
		}

		@media(max-width: 440px) {
			grid-gap: 20px 0;
			padding: 30px 0;

			.card > h1 {
				margin: 0;
			}
		}
	}
`

export const BoxDocumentos = styled(Card).attrs({
	className: 'animated fadeInUp delay-900ms'
})`
	grid-area: arquivos;
	display: grid;
	grid-template-columns: 1fr 24px;

	> h1 {
		+ svg {
			font-size: 24px;
			cursor: pointer;
		}
	}

	> div {
		grid-column: 1/span 2;
	}

	@media(max-width: 440px) {
		margin: 0 !important;
	}
`

export const DadosBasicosContainer = styled.div`
	justify-content: center;
	color: #424242;
	display: grid;
	grid-gap: 0 34px;
	grid-area: dados_pessoais;
	min-height: 190px;
	grid-template-rows: 20px 56px 32px auto;
	grid-template-columns: 150px 280px auto;
	grid-template-areas:
		'foto x x'
		'foto nome nome'
		'foto crm status'
		'foto especialidade data';

	.spinner-container {
		grid-column: 1/5;
		grid-row: 1/5;
		align-self: center;
	}

	a {
		color: #FFF;
		height: 155px;

		img {
			border-radius: 50%;
			align-self: center;
			height: 160px;
		}
	}

	h1 {
		font-size: 28px;
		font-weight: 300;
		color: #424242;
		text-transform: uppercase;
	}

	.crm {
		display: flex;
		align-items: baseline;

		.situacao {
			display: flex; 
			align-items: center;
			margin-left: 20px;

			svg {
				margin-right: 4px;
			}
		}
	}

	.nome_reduzido {
		display: none;
	}

	@media(max-width: 900px) {
		margin-bottom: 14px;
		grid-template-rows: 20px 44px 30px 30px 30px 30px;
		grid-template-columns: 150px auto;
		grid-template-areas:
			'foto x'
			'foto nome'
			'foto crm'
			'foto status'
			'foto especialidade'
			'foto data';
	}

	@media(max-width: 800px) {
		justify-items: center;
		grid-template-rows: 180px 44px 30px 30px 30px 30px;
		grid-template-columns: 1fr;
		grid-template-areas:
			'foto'
			'nome'
			'crm'
			'status'
			'especialidade'
			'data';

		h1 {
			font-size: 20px;
			font-weight: 500;
		}

		.crm > b {
			font-weight: unset;
		}

		.nome_completo {
			display: none;
		}

		.nome_reduzido {
			display: block;
		}
	}
`

export const AddInstituicao = styled.div`

`
