import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			> h1 {
				margin-bottom: 20px;
			}
		}

		> h1 {
			margin: 0 8px 20px;
			grid-area: title;
			margin-bottom: 24px;	
		}

		@media(max-width: 800px) {
			padding: 20px;
		}

		@media(max-width: 400px) {
			padding: 15px 0;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`

export const Content = styled.div`
	> p {
		margin: 12px 8px 14px;
	}

	form {
		display: grid;
		gap: 4px 0;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: flex-start;
		grid-template-areas: 
			'f1 f2 f3'
			'dest dest dest'
			'preview preview preview'
			'anexos anexos anexos'
			'mensagem mensagem mensagem'
			'agendamento agendamento agendamento'
			'button button button';
			
		> button {
			grid-area: button;
			justify-self: flex-end;
		}

		.file {
			grid-area: file;
		}

		.textarea {
			grid-area: mensagem;
			
			textarea {
				height: 300px;
			}
		}

		@media(max-width: 950px) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 60px auto auto auto auto;
			grid-template-areas: 
				'f1 f2'
				'f3 f4'
				'dest dest'
				'preview preview'
				'params params'
				'anexos anexos'
				'mensagem mensagem'
				'agendamento agendamento';

			> button{
				grid-column: 1/span 2;
			}
		}

		@media(max-width:700px){
			display: flex;
			flex-direction: column;

			> button{
				align-self: flex-end;
			}
		}
	}
`

export const DestinatarioContainer = styled.div`
	padding: 0 8px;
	grid-area: dest;
	display: flex;
	flex-direction: column;
	width: 100%;

	> h1 {
		font-size: 18px;
		margin: 12px 0 14px !important;
	}

	> p {
		text-align: center;
		margin: 6px 0 14px;
	}

	> details {
		margin-bottom: 10px;
		border-radius: 6px;
		border: solid 1px #fff;
		border-bottom-width: 3px;
		height: fit-content;

		&[disabled] {
			pointer-events: none;
    		user-select: none;
			opacity: 0.6;
		}

		&[open] {
			summary {
				background-color: #FFF;
				color: #424242;

				h1 {
					color: #424242;
				}
			}
		}

		&:not([open]) {
			summary.selected {
				background-color: #FFF4;

				&:hover {
					background-color: #FFF;
					color: #424242;
				}
			}
		}

		> div {
			display: flex !important;
			justify-content: center;
		}

		summary {
			cursor: pointer;
			padding: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;

			img {
				height: 180px;
				object-fit: cover;
				border-radius: 10px;
			}

			div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 50px;
				padding: 0 18px;

				h1 {
					font-size: 16px;
					font-weight: 500;
					color: #FFF;
					margin: 0 !important;
				}

				svg {
					font-size: 22px;
				}
			}

			&:hover {
				background-color: #FFF;
				color: #424242;

				h1 {
					color: #424242;
				}
			}

			& + div {
				margin: 18px;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;

				.table-container {
					grid-column: 2/span 2;
				}

				@media(max-width: 700px) {
					display: flex;
					flex-direction: column;

					.table-container {
						width: calc(100% - 16px);
						margin: 8px;
					}
				}
			}
		}
	}
`

export const MensagemEmailContainer = styled.div`
	grid-area: mensagem;
	display: flex;
	flex-direction: column;
	width: 100%;

	> h1 {
		font-size: 18px;
		margin: 12px 8px 6px !important;
	}

	.textarea {
		height: 150px;
	}

	.aux-buttons-container {
		display: flex;
	}
`

export const AgendamentoContainer = styled.div`
	grid-area: agendamento;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 0 16px;
	width: calc(100% - 16px);

	> h1 {
		grid-column: 1/span 3;
		font-size: 18px;
		margin: 24px 8px 6px !important;
	}

	@media(max-width: 950px) {
		grid-template-columns: 1fr 1fr;

		> h1 {
			grid-column: 1/span 2;
		}
	}

	@media(max-width: 700px) {
		display: flex;
		flex-direction: column;
		
		.field {
			width: 100%;
		}
	}
`

export const SelecaoDocumentoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	margin-bottom: 16px;

	> button {
		width: fit-content;
	}

	> p {
		width: 100%;
		font-weight: 500;
	}
`

export const DocumentosContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	gap: 14px;
`

export const Document = styled.div.attrs({
	className: 'animated faster'
})`
	border: dashed 1px #fff;
	border-radius: 6px;

	.content {
		display: flex;
		align-items: center;
		gap: 4px;
		padding: 8px;
		border-radius: 6px;
		color: #fff;

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 160px;
		}
	}

	&:hover {
		opacity: 0.7;
		color: #fff;
	}

	.icon-remove {
		position: absolute;
		right: -7px;
		top: -6px;
		background-color: #fff;
		border-radius: 100%;
		color: #424242;
		cursor: pointer;
	}
`

export const ModalSelecaoDocumentoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 8px;
	min-height: 80vh;

	h2 {
		font-size: 16px;
		font-weight: 400;
		margin: 8px 0 16px;
	}

	.navegacao {
		display: flex;
		gap: 16px;
	}

	.empty-placeholder {
		width: 200px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 18px;

		svg {
			opacity: 0.6;
		}

		i {
			text-align: center;
		}
	}

	.pastas-documentos-lista {
		display: flex;
		flex-direction: column;
		max-width: 200px;

		.filtro-documentos {
			margin-top: -6px;
			width: 158px;
		}
	}

	ul {
		width: fit-content;
		min-width: 176px;

		li {
			display: flex;
			justify-content: space-between;
			align-items: center !important;
			padding: 6px;
			border: solid 1px #fff6;
			cursor: pointer;
			padding: 12px;
			width: 100%;
			gap: 6px;

			span {
				flex: 1;
			}

			&.active {
				background-color: #fff3;
			}

			.arrow {
				opacity: 0;
			}

			&:hover {
				background-color: #fff2;

				.arrow {
					opacity: 1;
				}
			}
		}
	}

	button {
		margin-top: 34px;
	}
`

export const DestinatarioInstituicaoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0 12px;

	.selecao-instituicao {
		display: flex;
		gap: 16px;

		.select {
			width: 100%;
			max-width: 300px;
		}

		.destinatario-selecionado {
			font-size: 15px;
			margin-top: 36px;
		}
	}

	.filtro-nome-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 6px;
		justify-content: center;

		> p {
			margin: 16px 8px 0;
		}

		.textbox {
			max-width: 300px;
			width: 100%;
		}
	}

	.empty-organograma {
		margin: 16px;
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 12px;

		.link {
			text-decoration: underline;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	ul {
		margin: 24px 8px 0;
		display: flex;
		justify-content: center;
		gap: 12px;

		li {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 6px;
			border: solid 1px #fff6;
			cursor: pointer;
			padding: 12px;
			gap: 6px;
			width: 200px;

			&.active {
				background-color: #fff3;
				border: solid 2px #fff;
			}

			&.selected {
				border: solid 4px #43a047;
			}

			&:hover {
				background-color: #fff2;
			}

			b {
				font-weight: 600;
				font-size: 15px;
			}

			span {
				font-size: 13px;
				text-align: center;
			}
		}
	}
`
