import React, {
    createContext, useState, useEffect, useContext
} from 'react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

import { getAge } from '../util/date'
import formUtils from '../util/form'

import { GlobalContext } from './GlobalContext'
import { validarDocumentos } from '../util/documentos'

export const DependenteContext = createContext()

const DependenteProvider = ({ children }) => {
    const { user } = useContext(GlobalContext)

    const [items, setItems] = useState(null)
    const [detalhesDependente, setDetalhesDependente] = useState(null)
    const [editItem, setEditItem] = useState(null)
    const [modoCadastro, setModoCadastro] = useState(false)
    const [associado, setAssociado] = useState(null)
    const [identificador, setIdentificador] = useState('')
    const [showFiltro, setShowFiltro] = useState(true)
    const [prevDocumentos, setPrevDocumentos] = useState([])
    const [documentos, setDocumentos] = useState([])
    const [tiposDocumentos, setTipoDocumentos] = useState([])

    async function loadAssociado() {
        const response = await api.get(`associado/${identificador}`)

        if (!response) {
            toast.warn('Nenhum associado encontrado.')
            return
        }

        setAssociado(response)
    }

    async function loadTiposDocumentos() {
        const response = await api.get('tipo_documento', authHeaders())

        setTipoDocumentos(response)
    }

    function getEstadoCivil(id) {
        switch (id) {
            case 1:
                return 'Solteiro'
            case 2:
                return 'Casado'
            case 3:
                return 'Divorciado'
            case 4:
                return 'Viúvo'
            default:
                return ''
        }
    }

    async function loadItems() {
        const response = await api.get('dependente', {
            params: {
                associado_id: associado.id
            },
            ...authHeaders()
        })

        setItems(response
            .map(dependente => ({
                ...dependente,
                name: dependente.pessoa.nome,
                data_nascimento: format(new Date(dependente.pessoa.data_nascimento), 'dd/MM/yyyy'),
                idade: `${getAge(dependente.pessoa.data_nascimento)} anos`,
                sexo: dependente.pessoa.sexo === 'M' ? 'Masculino' : 'Feminino',
                parentesco: dependente.grau_parentesco.descricao,
                pcd: dependente.pne ? 'Sim' : 'Não',
                estado_civil: getEstadoCivil(dependente.estado_civil)
            })))
    }

    async function loadIdentificador() {
        if ([2, 16, 21, 22].includes(user?.perfil.id)) {
            setIdentificador(user.associado.id)

            setShowFiltro(false)
        }
    }

    async function handlePrepareEdit(id) {
        const response = await api.get(`dependente/${id}`, authHeaders())

        const { pessoa, ...dependenteData } = response
        const { documentos: docs } = pessoa

        setPrevDocumentos(docs)

        setEditItem({
            ...dependenteData,
            estado_civil: {
                id: dependenteData.estado_civil,
                descricao: getEstadoCivil(dependenteData.estado_civil)
            },
            pessoa: {
                ...pessoa,
                data_nascimento: format(new Date(pessoa.data_nascimento), 'dd/MM/yyyy'),
                documentos: docs.map(doc => ({
                    id: doc.id,
                    arquivo: doc.arquivo || null,
                    tipo_documento: {
                        label: doc.tipo_documento.descricao,
                        value: doc.tipo_documento.id
                    },
                    identificador: doc.identificador,
                    validade: doc.extra ? JSON.parse(doc.extra)?.validade : ''
                }))
            }
        })
    }

    async function handleDelete(id) {
        try {
            await api.delete(`dependente/${id}`, authHeaders())

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function reload() {
        loadItems()

        setModoCadastro(false)
        setEditItem(null)
    }

    async function handleAdd(values) {
        try {
            values = formUtils.extractFormValues(values)

            const {
                nome, sexo, data_nascimento, parentesco, estado_civil, pne
            } = values

            await api.post('dependente', {
                grau_parentesco_id: parentesco,
                associado_id: associado.id,
                estado_civil,
                pne,
                pessoa: {
                    nome,
                    sexo,
                    data_nascimento,
                    documentos: documentos.map(doc => ({
                        tipo_documento_id: doc.tipo_documento.id,
                        identificador: doc.identificador,
                        arquivo_id: doc.arquivo.id,
                        validade: doc.validade
                    }))
                }
            }, authHeaders())

            toast.success('Dependente cadastrado com sucesso.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleEdit(values) {
        try {
            values = formUtils.extractFormValues(values)

            const documentoFaltando = validarDocumentos('dependente', documentos)

            const prevRG = prevDocumentos.some(doc => doc.tipo_documento.id === 1)
            const prevCPF = prevDocumentos.some(doc => doc.tipo_documento.id === 2)

            if ((documentoFaltando === 1 && prevRG) || (documentoFaltando === 2 && prevCPF)) {
                const tipo = tiposDocumentos.find(i => i.id === documentoFaltando)

                toast.error(`É obrigatório incluir ${tipo.descricao}.`)

                return
            }

            const {
                nome, sexo, data_nascimento, parentesco, estado_civil,
                pne
            } = values

            await api.put(`dependente/${editItem.id}`, {
                grau_parentesco_id: parentesco,
                associado_id: associado.id,
                estado_civil,
                pne,
                pessoa: {
                    nome,
                    sexo,
                    data_nascimento,
                    documentos: documentos.map(doc => ({
                        tipo_documento_id: doc.tipo_documento.id,
                        identificador: doc.identificador,
                        arquivo: doc.arquivo.id,
                        validade: doc.validade
                    }))
                }
            }, authHeaders())

            toast.success('Dependente alterado com sucesso.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmit(values) {
        if (editItem) {
            await handleEdit(values)
        } else {
            await handleAdd(values)
        }
    }

    useEffect(() => {
        if (user) {
            loadIdentificador()

            loadTiposDocumentos()
        }
    }, [user])

    useEffect(() => {
        if (identificador) {
            loadAssociado()
        }
    }, [identificador])

    useEffect(() => {
        if (associado) {
            loadItems()
        }
    }, [associado])

    return (
        <DependenteContext.Provider value={{
            editItem,
            setEditItem,
            items,
            reload,
            modoCadastro,
            setModoCadastro,
            handlePrepareEdit,
            setIdentificador,
            handleDelete,
            setDetalhesDependente,
            detalhesDependente,
            associado,
            showFiltro,
            handleSubmit,
            documentos,
            setDocumentos
        }}
        >
            {children}
        </DependenteContext.Provider>
    )
}

export default DependenteProvider
