import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`

export const ResultadosContainer = styled.ul.attrs({
    className: 'result-list'
})`
	z-index: 2;
	width: calc(100% - 16px);
	margin-left: 8px;
	border-radius: 6px;
	background-color: #FFF;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
	height: fit-content;

	li {
		padding: 8px;
		color: #424242;
		display: flex;
		align-items: center;

		&:hover {
			background-color: #0001;
			cursor: pointer;
		}
	}
`
