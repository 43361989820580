import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FaExternalLinkAlt, FaStarHalfAlt } from 'react-icons/fa'
import {
    Tab, TabList, TabPanel, Tabs
} from 'react-tabs'
import { FiPlus } from 'react-icons/fi'
import { getDay, format } from 'date-fns'

import { isBetweenTimes } from '../../../../util/date'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import { Button } from '../../../../components/Form'
import Modal from '../../../../components/Modal'
import FeedbackDemanda from '../../../../components/FeedbackDemanda'

import { AtendimentoContext } from '../../../../contexts/AtendimentoContext'

import { Container } from './styles'

import { GlobalContext } from '../../../../contexts/GlobalContext'

export default function () {
    const history = useHistory()

    const { user } = useContext(GlobalContext)

    const {
        atendimentos,
        atendimentosFinalizados,
        setShowNovoAtendimento,
        tipoAtendimento,
        advogados,
        reload,
        desabilitarAberturaDemanda,
        perfisDemandaBloqueados
    } = useContext(AtendimentoContext)

    const [demandaFeedback, setDemandaFeedback] = useState(null)

    function getExplicativo() {
        if (user.perfil.id !== 20) {
            return
        }

        const { agenda, agendamentos } = advogados.find(a => a.pessoa.id === user.pessoa.id)
        const diaSemana = getDay(new Date()) + 1
        const agendaDoDia = agenda.find(a => a.dia_semana === diaSemana)

        if (agendaDoDia && isBetweenTimes(format(new Date(), 'HH:mm:ss'), agendaDoDia.horario_inicio, agendaDoDia.horario_fim)) {
            return <p className="badge">Você está no horário de plantão. Todas as demandas estão visíveis.</p>
        }

        if (agendamentos.length) {
            return <p className="badge">Você está visualizando todas as demandas sob sua responsabilidade ou com as quais você possui agendamento marcado.</p>
        }

        if (atendimentos.length) {
            return <p className="badge">Você está visualizando as demandas que estão sob sua responsabilidade.</p>
        }

        return null
    }

    const [indiceTabAtiva, setIndiceTabAtiva] = useState(0)

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>
                            {tipoAtendimento?.id === 1 ? 'Demandas jurídicas' : 'Atendimentos'}
                        </h1>

                        {atendimentos ? (
                            <>
                                <Tabs
                                    selectedIndex={indiceTabAtiva}
                                    onSelect={indiceTab => setIndiceTabAtiva(indiceTab)}
                                >
                                    <TabList>
                                        <Tab>Em aberto</Tab>
                                        <Tab>Finalizadas</Tab>
                                    </TabList>

                                    <TabPanel>
                                        {[2, 9, 27, 28, 29, 30, 31, 33, 34].includes(user?.perfil.id) && (
                                            <div className="button-container">
                                                <Button
                                                    className="transparent"
                                                    onClick={() => {
                                                        if (tipoAtendimento) {
                                                            const queryTipo = tipoAtendimento?.id ? `&tipo=${tipoAtendimento.id}` : ''
                                                            history.push(`/atendimento/listar?add=1${queryTipo}`)
                                                        } else {
                                                            setShowNovoAtendimento(true)
                                                        }
                                                    }}
                                                    disabled={desabilitarAberturaDemanda}
                                                    tooltip={desabilitarAberturaDemanda ? 'Você não pode abrir uma nova demanda, pois possui contribuições pendentes.' : ''}
                                                >
                                                    {tipoAtendimento?.id === 1 ? 'Abrir nova demanda' : 'Abrir novo atendimento'}
                                                    <FiPlus />
                                                </Button>
                                            </div>
                                        )}

                                        {getExplicativo()}

                                        <Table
                                            headers={[
                                                { name: 'atendimento_id', value: '', centered: true },
                                                { name: 'protocolo', value: 'Nº Processo', centered: true },
                                                { name: 'crm', value: 'CRM' },
                                                { name: 'nome', value: 'Associado' },
                                                { name: 'data', value: 'Data/hora da solicitação', centered: true },
                                                { name: 'tipo', value: 'Tipo', centered: true },
                                                { name: 'assunto', value: 'Assunto', centered: true },
                                                { name: 'status', value: 'Status', centered: true },
                                                { name: 'ultimo_atendente', value: 'Último atendimento', centered: true }
                                            ]}
                                            data={atendimentos || []}
                                            actions={[
                                                {
                                                    name: 'Abrir atendimento',
                                                    action: atendimento => {
                                                        history.push(`/atendimento/${atendimento.id}`)
                                                    },
                                                    icon: FaExternalLinkAlt,
                                                    iconSize: 14,
                                                    checkDisabled: item => perfisDemandaBloqueados.includes(user.perfil.id) && item.associado.pessoa.id !== user.pessoa.id && item.tipo_id === 1,
                                                    disabledTooltip: 'Demandas jurídicas só podem ser abertas por usuários com perfil de advogado.'
                                                }
                                            ]}
                                            hideOffset={650}
                                            hideHeadersOffset={1160}
                                            hideOnSmallHeaders={['atendimento_id', 'ultimo_atendente', 'tipo', 'data']}
                                            filterable
                                        // legends={![2, 9, 27, 28, 29, 30, 31, 34].includes(user.perfil.id) ? [
                                        //     {
                                        //         color: '#ffcdd2',
                                        //         text: 'Demandas da Diretoria'
                                        //     }
                                        // ] : null}
                                        />
                                    </TabPanel>

                                    <TabPanel>
                                        <Table
                                            headers={[
                                                { name: 'atendimento_id', value: '', centered: true },
                                                { name: 'protocolo', value: 'Processo', centered: true },
                                                { name: 'crm', value: 'CRM' },
                                                { name: 'nome', value: 'Associado' },
                                                { name: 'data', value: 'Data da solicitação', centered: true },
                                                { name: 'tipo', value: 'Tipo', centered: true },
                                                { name: 'assunto', value: 'Assunto', centered: true },
                                                { name: 'status', value: 'Status', centered: true },
                                                { name: 'ultimo_atendente', value: 'Último atendimento', centered: true }
                                            ]}
                                            data={atendimentosFinalizados || []}
                                            actions={[
                                                {
                                                    name: 'Abrir atendimento',
                                                    action: atendimento => {
                                                        history.push(`/atendimento/${atendimento.id}`)
                                                    },
                                                    icon: FaExternalLinkAlt,
                                                    iconSize: 14,
                                                    checkDisabled: atendimento => perfisDemandaBloqueados.includes(user.perfil.id) && atendimento.associado.pessoa.id !== user.pessoa.id && atendimento.tipo_id === 1,
                                                    disabledTooltip: 'Demandas jurídicas só podem ser abertas por usuários com perfil de advogado.'
                                                },
                                                {
                                                    name: 'Avaliar',
                                                    action: setDemandaFeedback,
                                                    icon: FaStarHalfAlt,
                                                    iconSize: 16,
                                                    checkDisabled: atendimento => !!atendimento.avaliacao || ![2, 9, 27, 28, 29, 30, 31, 33, 34].includes(user.perfil.id) || atendimento.associado.pessoa.id !== user.pessoa.id,
                                                    disabledTooltip: atendimento => atendimento.avaliacao ? `Avaliado com ${atendimento.avaliacao}⭐` : user?.perfil.id !== 2 ? 'Apenas o associado pode avaliar o atendimento.' : null
                                                }
                                            ]}
                                            hideOffset={600}
                                            hideHeadersOffset={800}
                                            hideOnSmallHeaders={['ultimo_atendente', 'tipo', 'data']}
                                            filterable
                                        // legends={![2, 9, 27, 28, 29, 30, 31, 34].includes(user.perfil.id) ? [
                                        //     {
                                        //         color: '#ffcdd2',
                                        //         text: 'Demandas da Diretoria'
                                        //     }
                                        // ] : null}
                                        />
                                    </TabPanel>
                                </Tabs>
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!demandaFeedback}
                handleClose={() => { setDemandaFeedback(null) }}
                title="Avalie nosso atendimento"
            >
                <FeedbackDemanda
                    demanda={demandaFeedback}
                    onFeedbackSubmit={() => {
                        setDemandaFeedback(null)
                        reload()
                    }}
                />
            </Modal>
        </>
    )
}
