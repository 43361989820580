import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FaArrowRight } from 'react-icons/fa'

import Yup from '../../../../../../services/yup'
import masks from '../../../../../../util/masks'

import {
    Textbox, Button, Select, Textarea 
} from '../../../../../../components/Form'
import ActionFooter from '../../../../../../components/ActionFooter'

import { Container } from './styles'

const baseValidation = Yup.object({
    razao_social: Yup.string().required('Campo obrigatório.'),
    nome_fantasia: Yup.string(),
    rg: Yup.string(),
    inscricao_estadual: Yup.string(),
    inscricao_federal: Yup.string(),
    tipo_pessoa: Yup.string().ensure().required('Campo Obrigatório'),
    observacao: Yup.string().nullable()
})

export default function ({ callback, dadosFornecedor }) {
    const [tipoPessoa, setTipoPessoa] = useState('PJ')
    const [validation, setValidation] = useState(baseValidation)

    useEffect(() => {
        if(dadosFornecedor?.pessoal) {
            const { tipo_pessoa: tipo } = dadosFornecedor.pessoal

            setTipoPessoa(tipo)

            setValidation(baseValidation.concat(Yup.object({
                identificador: tipo === 'PF' ? Yup.string().cpf() : Yup.string().cnpj()
            })))
        }
    }, [dadosFornecedor])

    useEffect(() => {
        setValidation(baseValidation.concat(Yup.object({
            identificador: tipoPessoa === 'PJ' ? Yup.string().cnpj() : Yup.string().cpf()
        })))
    }, [tipoPessoa])
    
    function handleSubmit(values) {
        values.tipo_pessoa = values.tipo_pessoa.value
        values.nome_fantasia = values.tipo_pessoa === 'PJ' ? values.nome_fantasia : ''

        callback({ pessoal: values })
    }

    return (
        <Container>
            <Formik
                initialValues={{
                    ...dadosFornecedor.pessoal,
                    tipo_pessoa: {
                        label: dadosFornecedor.pessoal.tipo_pessoa,
                        value: dadosFornecedor.pessoal.tipo_pessoa
                    }
                }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, values
                }) => (
                    <Form>
                        <Select
                            name="tipo_pessoa"
                            label="Tipo de pessoa"
                            options={[
                                { label: 'PJ', value: 'PJ' },
                                { label: 'PF', value: 'PF' }
                            ]}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected) 
                                setTipoPessoa(selected.value)
                                setFieldValue('identificador', '')
                            }}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        {values.tipo_pessoa.value === 'PJ' ? (
                            <>
                                <Textbox 
                                    label="Razao social" 
                                    name="razao_social" 
                                    containerStyle={{ gridArea: 'f2' }}
                                />
                                
                                <Textbox 
                                    label="Nome Fantasia" 
                                    name="nome_fantasia" 
                                    containerStyle={{ gridArea: 'f3' }}                          
                                />

                                <Textbox 
                                    label="CNPJ" 
                                    name="identificador"  
                                    mask={masks.cnpj} 
                                    containerStyle={{ gridArea: 'f4' }}
                                />
                            </>
                        ) : (
                            <>
                                <Textbox 
                                    label="Nome" 
                                    name="razao_social" 
                                    containerStyle={{ gridArea: 'f2' }}                          
                                />

                                <Textbox 
                                    label="CPF" 
                                    name="identificador"
                                    mask={masks.cpf}  
                                    containerStyle={{ gridArea: 'f3' }}                           
                                />

                                <Textbox 
                                    label="RG"
                                    name="rg" 
                                    containerStyle={{ gridArea: 'f4' }}                          
                                />
                            </>
                        )}

                        <Textbox 
                            label="Inscrição estadual" 
                            name="inscricao_estadual"
                            containerStyle={{ gridArea: 'f5' }}                            
                        />

                        <Textbox 
                            label="Inscrição Federal" 
                            name="inscricao_federal"
                            containerStyle={{ gridArea: 'f6' }}                           
                        />

                        <Textarea 
                            name="observacao"
                            label="Observação"
                            containerStyle={{ gridArea: 'f7' }}
                            style={{ height: 40 }}
                        />

                        <ActionFooter style={{ gridArea: 'button' }}>
                            <Button type="submit" className="white" loading={isSubmitting}>
                                Continuar
                                <FaArrowRight />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
