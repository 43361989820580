import styled, { css } from 'styled-components'

const badge = css`
	font-size: 12px;
	background-color: white;
	padding: 6px 8px;
	border-radius: 8px;
	color: #424242;
	width: fit-content;
`

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		.card {
			min-height: 35vh;
		}

		@media(max-width: 600px) {
			padding: 30px 0;
		}
	}
`

export const ModalContent = styled.div`
	display: grid;
	grid-template-columns: 30% 30% 40%;
	grid-template-areas:
		'title title buttonTop'
		'pessoal endereco pagamento'
		'locais_trabalho locais_trabalho locais_trabalho'
		'contatos contatos contatos'
		'arquivos arquivos arquivos'
		'buttonBottom buttonBottom buttonBottom';
	padding: 20px 0;

	> button {
		width: fit-content;
		justify-self: right;
	}

	h2 {
		grid-area: title;
		font-size: 24px;
		font-weight: 500;
		margin: 0 20px;
		display: flex;
		align-items: center;

		.badge {
			${badge}
			margin-left: 20px;
			width: 100px;
		}
	}

	.card {
		background-image: unset;
		background-color: rgba(0,0,0,0.2);
	}

	.action-footer {
		grid-area: buttonBottom;
	}

	@media(max-width: 900px) {
		padding: 30px 14px;
		grid-template-columns: 1fr;
		grid-template-areas:
			'title'
			'pessoal'
			'endereco'
			'pagamento'
			'locais_trabalho'
			'contatos'
			'arquivos'
			'buttonBottom';

		> button {
			display: none;
		}
	}
`

export const ModalConfirmationContent = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto 3fr 50px 70px;
		grid-template-areas: 
			'anexo f1 f2'
			'anexo f3 f4'
			'anexo badge badge'
			'btn btn btn';
		align-items: flex-start;

		.action-footer {
			grid-area: btn;
		}

		@media(max-width: 800px) {
			display: flex;
			flex-direction: column;
		}
	}

	p.badge {
		${badge}
		grid-area: badge;
		background-color: #ffeb3b;
		margin: 24px 8px 0;
		justify-self: flex-end;
	}
`
