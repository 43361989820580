import React, { useEffect, useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'

import { Container } from './styles'

import { Button, Calendar, Select } from '../../../../components/Form'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import Card from '../../../../components/Card'

import { api, authHeaders } from '../../../../services/api'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

export default function () {
    const { user } = useContext(GlobalContext)
    const { setCodigo, tips } = useContext(TipContext)

    const history = useHistory()

    const [locaisAposentadoria, setLocaisAposentadoria] = useState([])

    async function loadLocaisAposentadoria() {
        const locais = await api.get('instituicao', authHeaders())

        setLocaisAposentadoria(locais)
    }

    async function handleSubmit(values) {
        const { data, locais } = values

        try {	
            await api.put(`associado/${user.associado.id}`, {
                status: 7,
                data_aposentadoria: data,
                locais_aposentadoria: locais.map(l => l.value)
            }, authHeaders())

            toast.success('Sua aposentadoria foi confirmada.')

            history.push('/associado/dados_cadastrais')
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadLocaisAposentadoria()

        loadTips(setCodigo)
    }, [])

    return (
        <Container>
            <main>
                <Card id="form_aposentadoria">
                    <Formik
                        initialValues={{
                            data: '',
                            locais: []
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue }) => (
                            <Form>
                                <h1>Aposentadoria</h1>

                                <p style={{ gridColumn: '1/span 3', margin: 8 }}>{tips?.formulario}</p>

                                <Calendar
                                    name="data"
                                    label="Data da aposentadoria"
                                    yearNavigator
                                    maxDate={new Date()}
                                />

                                <Select
                                    name="locais"
                                    label="Locais de aposentadoria"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={locaisAposentadoria.map(local => ({
                                        label: local.nome_fantasia,
                                        value: local.id
                                    }))}
                                    isMulti
                                />

                                <Button type="submit" className="transparent">
                                    Confirmar aposentadoria
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
