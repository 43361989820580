import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FaUser } from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { api } from '../../services/api'

import scroll from '../../util/scroll'

import { Textbox, Button } from '../../components/Form'

import { Container, Content } from './styles'

import logo from '../../assets/images/logo-mini.png'

const validation = Yup.object({
    email: Yup.string().email('E-mail inválido.').required('Especifique o e-mail cadastrado no sistema.')
})

export default function () {
    scroll.toTop()

    const history = useHistory()

    async function handleSubmit({ email }) {
        try {
            const response = await api.post('matricula_recover', { email })

            if (response.found) {
                setTimeout(() => {
                    history.push('/')

                    toast.info(`Sua matrícula acabou de ser enviada para o e-mail ${email}.`)
                }, 1000)
            } else {
                toast.warn(response.msg)
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <Content className="animated fadeInUp faster delay-300ms">
                <img src={logo} alt="SINDMEPA" />

                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <h1>Recuperação de código do acadêmico</h1>

                            <Textbox name="email" label="Seu e-mail" icon={{ source: FaUser }} />

                            <Button type="submit" loading={isSubmitting} className="transparent">Recuperar código</Button>

                            <p>ou</p>

                            <Link to="/">Voltar para o login</Link>
                        </Form>
                    )}
                </Formik>

            </Content>
        </Container>
    )
}
