import React, { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FiPlus } from 'react-icons/fi'
import { FaRegSave } from 'react-icons/fa'

import { Textbox, Button, Select } from '../../components/Form'

import { api, authHeaders } from '../../services/api'
import Yup from '../../services/yup'

import formUtils from '../../util/form'

import { Container } from './styles'

const baseInitialValues = {
    tipo_media_social: { value: '', label: '' },
    valor_media_social: ''
}

export default function ({
    reload, style, mediaInitialValues, editId, pessoa, ...rest
}) {
    const [validacaoMediaSocial, setValidacaoMediaSocial] = useState(null)
    const [redesSociais, setRedesSociais] = useState([])
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    useEffect(() => {
        setInitialValues(mediaInitialValues || baseInitialValues)
    }, [mediaInitialValues])

    useEffect(() => {
        async function loadMediaSocial() {
            const response = await api.get('/media_social')

            setRedesSociais(response)
        }

        loadMediaSocial()
    }, [])

    async function adicionarRedeSocial(values, { setSubmitting, resetForm }) {
        const data = formUtils.extractFormValues(values)

        await api.post('associado_media_social', {
            pessoa_id: pessoa.id,
            media_social_id: data.tipo_media_social,
            valor: data.valor_media_social
        }, authHeaders())

        reload()

        setSubmitting(false)
        resetForm()
    }

    async function editarRedeSocial(values, { setSubmitting, resetForm }) {
        const data = formUtils.extractFormValues(values)

        await api.put(`associado_media_social/${editId}`, {
            media_social_id: data.tipo_media_social,
            valor: data.valor_media_social
        }, authHeaders())

        reload()

        setSubmitting(false)
        resetForm()
    }

    const handleRedeSocialChange = useCallback((selected, setFieldValue) => {
        setFieldValue('tipo_media_social', selected)

        const perfilRegex = /^\w+$/

        setValidacaoMediaSocial(Yup.object({
            tipo_media_social: Yup.object({ value: Yup.number(), label: Yup.string() }).required('Selecione uma rede social.'),
            valor_media_social: Yup.string().matches(perfilRegex, 'Perfil inválido.').required('Digite seu perfil.')
        }))
    }, [])

    return (
        <Container style={style} edit-mode={!!editId} {...rest}>
            <Formik
                initialValues={initialValues}
                validationSchema={validacaoMediaSocial}
                onSubmit={editId ? editarRedeSocial : adicionarRedeSocial}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                        <Select
                            name="tipo_media_social"
                            label="Rede social"
                            onChange={selected => handleRedeSocialChange(selected, setFieldValue)}
                            options={redesSociais.map(media => ({
                                label: media.nome,
                                value: media.id
                            }))}
                        />

                        <Textbox
                            name="valor_media_social"
                            label="Perfil"
                            disabled={!values.tipo_media_social.value}
                        />

                        <Button type="submit" loading={isSubmitting} className="transparent">
                            {editId ? (
                                <>
                                    Salvar alterações
                                    <FaRegSave />
                                </>
                            ) : (
                                <>
                                    Adicionar
                                    <FiPlus />
                                </>
                            )}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
