import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			display: flex;
			flex-direction: column;

			button {
				width: fit-content;
				align-self: end;
			}

			form {
				display: flex;
				flex-direction: column;
				width: 100%;

				> div {
					display: flex;
				}
				
				button {
					width: fit-content;
				}

				.textbox {
					max-width: 400px;
				}
			}
		}
	}
`
