import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { getYear } from 'date-fns'

import PieChart from '../../../../components/Chart/Pie'
import LineChart from '../../../../components/Chart/Line'
import Card from '../../../../components/Card'
import { Select, Calendar } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'

import { api, authHeaders } from '../../../../services/api'

import formUtils from '../../../../util/form'

import { Container, LineChartContainer } from './styles'
import SmallScreenPlaceholder from '../../../../components/SmallScreenPlaceholder'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

export default function () {
    const { user } = useContext(GlobalContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [atendimentos, setAtendimentos] = useState(null)
    const [tiposAtendimento, setTiposAtendimento] = useState([])

    async function loadTiposAtendimento() {
        const response = await api.get('atendimento_tipo')

        setTiposAtendimento(response)
    }

    async function loadAtendimentos(values) {
        setAtendimentos(null)

        if(values?.periodo && values?.periodo?.some(p => !p)) {
            return
        }

        const response = await api.get('atendimento/estatisticas', {
            ...authHeaders(),
            params: {
                inicio: values?.periodo?.[0].toISOString().substr(0, 10) || null,
                fim: values?.periodo?.[1].toISOString().substr(0, 10) || null,
                tipo_atendimento: [6, 20, 23].includes(user?.perfil.id) ? 1 : values?.tipo
            }
        })

        setAtendimentos(response)
    }

    useEffect(() => {
        loadAtendimentos()
		
        loadTiposAtendimento()
        
        loadTips(setCodigo, 'form_estatisticas_atendimento')
    }, [])

    async function handlePesquisar(values) {
        values = formUtils.extractFormValues(values)

        await loadAtendimentos(values)
    }

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Estatísticas de demandas</h1>

                    <Formik
                        initialValues={{ 
                            periodo: [
                                new Date(getYear(new Date()), 0, 1),
                                new Date(getYear(new Date()), 11, 31, 23, 59, 59)
                            ], 
                            tipo: !user?.perfil.id === 23 
                                ? { label: 'Todos', value: null } 
                                : undefined
                        }}
                        onSubmit={handlePesquisar}
                    >
                        {({ setFieldValue, submitForm }) => (
                            <Form>
                                <p style={{ gridColumn: '1/span 3', margin: 8 }}>{tips?.formulario}</p>

                                {user?.perfil.id !== 23 && (
                                    <Select 
                                        name="tipo"
                                        label="Tipo de atendimento"
                                        options={[
                                            { label: 'Todos', value: null },
                                            ...tiposAtendimento.map(t => ({
                                                label: t.descricao,
                                                value: t.id
                                            }))
                                        ]}
                                        onChange={(selected, meta) => { 
                                            setFieldValue(meta.name, selected) 

                                            submitForm()
                                        }}
                                    />
                                )}
								
                                <Calendar 
                                    name="periodo"
                                    label="Período"
                                    dateFormat="mm/yy"
                                    selectionMode="range"
                                    view="month"
                                    onChange={submitForm}
                                />
                            </Form>
                        )}
                    </Formik>
					
                    {atendimentos?.empty ? (
                        <p style={{ textAlign: 'center', paddingTop: 60 }}><i>Nenhum atendimento a exibir</i></p>
                    ) : atendimentos ? (
                        <>
                            <PieChart 
                                data={{
                                    Abertos: {
                                        value: atendimentos.pie['3'],
                                        color: '#fff176'
                                    },
                                    'Em atendimento': {
                                        value: atendimentos.pie['1'],
                                        color: '#03a9f4'
                                    },
                                    Finalizados: {
                                        value: atendimentos.pie['2'],
                                        color: '#e57373'
                                    }
                                }}
                            />

                            <LineChartContainer>
                                <LineChart
                                    data={[
                                        {
                                            dados: atendimentos?.line['1'].dados,                                
                                            borderColor: '#03a9f4',
                                            label: 'Em atendimento'
                                        },
                                        {
                                            dados: atendimentos?.line['3'].dados,                              
                                            borderColor: '#fff176',
                                            label: 'Abertos'
                                        },
                                        {
                                            dados: atendimentos?.line['2'].dados,                                
                                            borderColor: '#e57373',
                                            label: 'Finalizados'
                                        }
                                    ]}
                                    fontColor="#424242"
                                />
                            </LineChartContainer>

                            <SmallScreenPlaceholder hideOffset={700} text="Gire o dispositivo para visualizar o gráfico" />
                        </>
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
