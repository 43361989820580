import React, { useState, useEffect } from 'react'

import { Select } from '../../../../../../components/Form'

import { api, authHeaders } from '../../../../../../services/api'

export default function ({ setFieldValue }) {
    const [templates, setTemplates] = useState(null)
    const [visoes, setVisoes] = useState(null)
    const [connections, setConnections] = useState([])
    
    async function loadTemplates() {
        const response = await api.get('mensageria/template', authHeaders())

        setTemplates(response)
    }

    async function loadVisoes() {
        const response = await api.get('visao', authHeaders())

        setVisoes(response)
    }

    async function loadConnections() {
        const response = await api.get('task_connections', authHeaders())

        setConnections(response)
    }

    useEffect(() => {
        loadTemplates()

        loadVisoes()

        loadConnections()
    }, [])

    return (
        <>
            <Select 
                label="Conexão"
                name="conexao"
                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                options={connections.map(conn => ({
                    label: `[${conn.dialect.toUpperCase()}] ${conn.database}`, 
                    value: conn.id
                }))}
            />

            <Select 
                name="template"
                label="Mensagem"
                options={templates?.map(template => ({
                    label: template.name,
                    value: template.id
                })) || []}
                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
            />

            <Select 
                label="Destinatários"
                name="visao"
                options={visoes?.map(visao => ({
                    label: visao.descricao,
                    value: visao.id
                })) || []}
                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
            />
        </>
    )
}
