import styled from 'styled-components'

export const FiltrosContainer = styled.div`
	background-color: #0003;
	border-radius: 10px;
	padding: 16px;
	margin-top: 16px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: end;

	button {
		width: fit-content;
	}

	@media(max-width: 1100px) {
		grid-template-columns: 1fr 1fr;
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`
