import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { descriptografar } from '../util/crypto'

import AuthLayout from '../pages/_layouts/Auth'
import DefaultLayout from '../pages/_layouts/Default'
import NoHeaderLayout from '../pages/_layouts/NoHeader'

import { unsignedDefaultPermissions, signedDefaultPermissions } from './defaultRoutes'

function clearSessionStorage() {
    sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN)
    sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_USER)
}

function redirectToLogin() {
    clearSessionStorage()

    return <Redirect to="/" />
}

export default function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {
    const token = sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN)
    const userData = sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER)

    const deciphered = descriptografar(userData)

    if (deciphered?.error) {
        return redirectToLogin()
    }

    const user = (userData && deciphered) || null
    const userPermissions = user?.perfil.grupos.reduce((final, current) => [...final, ...current.permissoes], [])

    const signed = !!token && !!userData
    const currentURL = window.location.href

    if (!signed && currentURL.includes('checkout_auth')) {
        const faturaId = currentURL.split('/').at(-1)

        return <Redirect to={`/checkout/${faturaId}`} />
    }

    if (!signed && isPrivate) {
        return redirectToLogin()
    }

    if (signed && !isPrivate) {
        return redirectToLogin()
    }

    const permissions = signed ? [
        ...signedDefaultPermissions,
        ...userPermissions
    ] : unsignedDefaultPermissions

    const allowed = !!permissions.find(permission => {
        const [baseLink] = permission.link.split('?')

        return baseLink === rest.path
    })

    if (!allowed && rest.path !== '/not_allowed') {
        return <Redirect to="/not_allowed" />
    }

    let Layout

    if(signed) {
        if(rest.path.includes('checkout_auth_iframe')) {
            Layout = NoHeaderLayout
        } else {
            Layout = DefaultLayout
        }
    } else if(rest.path.includes('checkout')) {
        Layout = NoHeaderLayout
    } else {
        Layout = AuthLayout
    }

    return (
        <Route
            {...rest}
            component={props => (
                <Layout userData={user}>
                    <Component profile={user?.perfil?.id} {...props} />
                </Layout>
            )}
        />
    )
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

RouteWrapper.defaultProps = {
    isPrivate: false,
    component: () => <Redirect to="/" />
}
