import React, {
    useState, useEffect, useCallback, useContext
} from 'react'
import { format, differenceInMonths } from 'date-fns'
import { toast } from 'react-toastify'
import { FaBarcode } from 'react-icons/fa'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'

import { api, authHeaders } from '../../../services/api'

import masks from '../../../util/masks'

import BoxList from '../../../components/BoxList'
import Modal from '../../../components/Modal'
import { Financeiro } from '../../../components/Associado'
import { Button } from '../../../components/Form'
import Spinner from '../../../components/Spinner'

import { ModalContainer, FormaPagamentoContainer, QRCodeContainer } from './styles'

import pixIcon from '../../../assets/images/payment/pix.png'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { getFromSubstring } from '../../../util/string'

const compactListLength = 5

export default function ({
    compacto = true,
    pessoa,
    showTitle = true,
    onIconClick,
    showDetalhes = false
}) {
    const { user } = useContext(GlobalContext)
    const history = useHistory()

    const [contribuicoes, setContribuicoes] = useState(null)
    const [contribuicoesPendentes, setContribuicoesPendentes] = useState(0)
    const [showDetalhe, setShowDetalhe] = useState(showDetalhes)
    const [quantMesesPagaveis, setQuantMesesPagaveis] = useState(3)
    const [contribuicaoAPagar, setContribuicaoAPagar] = useState(null)
    const [QRCode, setQRCode] = useState(null)
    const [PIXText, setPIXText] = useState(null)
    const [allowSendEmail, setAllowSendEmail] = useState(false)
    const [gerandoFatura, setGerandoFatura] = useState(false)

    const loadContribuicoes = useCallback(async () => {
        const response = await api.get('contribuicao', {
            params: {
                associado_id: pessoa.associado.id
            },
            ...authHeaders()
        })

        let pendentes = compacto ? response.filter(contribuicao => !contribuicao.status) : response

        setContribuicoesPendentes(pendentes.length)

        if (compacto && pendentes.length > compactListLength) {
            pendentes = pendentes.slice(0, compactListLength)
        }

        if (compacto && pendentes.length < compactListLength) {
            pendentes = [
                ...pendentes,
                ...response.filter(contribuicao => contribuicao.status).slice(0, compactListLength - pendentes.length)
            ]
        }

        setContribuicoes(pendentes.map(contribuicao => ({
            id: contribuicao.id,
            referencia: format(new Date(contribuicao.competencia), ![4, 5, 7].includes(contribuicao.tipo_pagamento?.id) ? 'MM/yyyy' : 'yyyy'),
            dataPagamento: contribuicao.data_consolidacao ? format(new Date(contribuicao.data_consolidacao), 'dd/MM/yyyy') : '',
            valor: masks.maskApply.currency(contribuicao.valor),
            instituicao: contribuicao.instituicao?.sigla || contribuicao.instituicao?.nome_fantasia || '',
            tipo: [4, 5, 7].includes(contribuicao.tipo_pagamento?.id) ? 'Anuidade' : 'Mensalidade',
            formaPagamento: contribuicao.tipo_pagamento?.descricao_resumida,
            iconColor: contribuicao.status ? '#4caf50' : '#f44336',
            status: contribuicao.status,
            statusIcon: contribuicao.status ? 'FaCheckCircle' : 'FaMinusCircle',
            formaPagamentoId: contribuicao.tipo_pagamento?.id,
            fatura: contribuicao.fatura,
            competencia: new Date(contribuicao.competencia)
        })))
    }, [compacto, pessoa])

    async function loadQuantMesesPagaveis() {
        const { valor } = await api.get('parametro/dias_vencimento_pagamento_avulso')
        setQuantMesesPagaveis(valor)
    }

    useEffect(() => {
        if (pessoa) {
            loadContribuicoes()
        }

        loadQuantMesesPagaveis()
    }, [])

    useEffect(() => {
        if (user) {
            const editable = ![3, 11, 12, 15].includes(user.perfil.id)

            setAllowSendEmail(editable)
        }
    }, [user])

    async function handleVerificarContribuicao(contribuicao) {
        try {
            const response = await api.get(`fatura/${contribuicao.id}`, authHeaders())

            if (!response) {
                setContribuicaoAPagar(contribuicao)
                return
            }

            if (response.msg) {
                toast.warn(response.msg)
                return
            }

            if (response.url_fatura) {
                const url = getFromSubstring(response.url_fatura.replace('checkout', 'checkout_auth'), '/checkout')

                history.push(url)

                setContribuicaoAPagar(null)
                return
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handlePagarBoleto(contribuicao) {
        try {
            setGerandoFatura(true)

            const { url_fatura, msg } = await api.put(`fatura/${contribuicao.id}`, {}, authHeaders())

            if (url_fatura) {
                const url = getFromSubstring(url_fatura.replace('checkout', 'checkout_auth'), '/checkout')

                history.push(url)

                toast.success('Boleto gerado. Faça o download.')
            } else {
                toast.info(msg)
            }
            setContribuicaoAPagar(null)
        } catch (e) {
            toast.error(e.msg)
        }

        setGerandoFatura(false)
    }

    async function handlePagarPIX(contribuicao) {
        try {
            setGerandoFatura(true)

            const { pix, msg } = await api.put(`fatura_pix/${contribuicao.id}`, {}, authHeaders())

            if (pix.qrcode) {
                setQRCode(pix.qrcode)
                setPIXText(pix.qrcode_text)
            }

            if (msg) {
                toast.warn(msg)
            }
        } catch (e) {
            toast.error(e.msg)
        }

        setGerandoFatura(false)
    }

    async function handleSendPIXEmail(contribuicao) {
        try {
            await api.post(`fatura_pix_email/${contribuicao.id}`, {}, authHeaders())

            setContribuicaoAPagar(null)
            setQRCode(null)

            toast.success('E-mail enviado com sucesso.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <>
            {compacto ? (
                <>
                    <BoxList
                        compacto
                        limit={contribuicoes?.length || compactListLength}
                        title={showTitle ? 'Pagamentos' : ''}
                        extraText={contribuicoes?.length
                            ? contribuicoesPendentes
                                ? `Pagamentos em aberto: ${contribuicoesPendentes}`
                                : 'Pagamentos em dia'
                            : null}
                        onIconClick={onIconClick === undefined ? (() => { setShowDetalhe(true) }) : onIconClick}
                        headers={[
                            { name: 'referencia', value: 'Mês de referência' },
                            { name: 'statusIcon', value: 'Status', icon: true }
                        ]}
                        data={contribuicoes}
                        actions={
                            [
                                {
                                    name: 'pagar',
                                    action: contribuicao => {
                                        handleVerificarContribuicao(contribuicao)
                                    },
                                    icon: FaBarcode,
                                    iconSize: 22,
                                    headerTitle: 'Pagamento',
                                    checkDisabled: item => contribuicoes?.find(contribuicao => contribuicao.id === item.id).status
                                        || contribuicoes?.filter(contribuicao => differenceInMonths(new Date(), new Date(contribuicao.competencia)) > quantMesesPagaveis)
                                            .find(contribuicao => contribuicao.id === item.id)
                                        || ([4, 5, 7].includes(item.formaPagamentoId) && item.fatura && item.fatura?.length !== 32) || [3, 11, 12, 15].includes(user.perfil.id) // Fatura CONSIR

                                }
                            ]
                        }
                    />

                    <Modal
                        isOpen={showDetalhe}
                        handleClose={() => setShowDetalhe(false)}
                        title="Pagamentos"
                        closeOnOverlayClick
                    >
                        <Financeiro compacto={false} pessoa={pessoa} showTitle={false} />
                    </Modal>
                </>
            ) : (
                <BoxList
                    title={showTitle ? 'Pagamentos' : ''}
                    limit={contribuicoes?.length || 1000}
                    style={{ width: '100%' }}
                    headers={[
                        { name: 'referencia', value: 'Mês de referência', centered: true },
                        { name: 'dataPagamento', value: 'Data de pagamento', centered: true },
                        { name: 'valor', value: 'Valor', centered: true },
                        { name: 'instituicao', value: 'Instituição', centered: true },
                        { name: 'tipo', value: 'Tipo', centered: true },
                        { name: 'formaPagamento', value: 'Forma de pagamento', centered: true },
                        { name: 'statusIcon', value: 'Status', icon: true }
                    ]}
                    data={contribuicoes}
                    actions={
                        [
                            {
                                name: 'pagar',
                                action: contribuicao => {
                                    handleVerificarContribuicao(contribuicao)
                                },
                                icon: FaBarcode,
                                iconSize: 22,
                                headerTitle: 'Pagamento',
                                checkDisabled: item => contribuicoes?.find(contribuicao => contribuicao.id === item.id).status
                                    || contribuicoes?.filter(contribuicao => differenceInMonths(new Date(), new Date(contribuicao.competencia)) > quantMesesPagaveis)
                                        .find(contribuicao => contribuicao.id === item.id)
                                    || ([4, 5, 7].includes(item.formaPagamentoId) && item.fatura && item.fatura?.length !== 32) || [3, 11, 12, 15].includes(user.perfil.id) // Fatura CONSIR

                            }
                        ]
                    }
                    hideOnSmallHeaders={['dataPagamento', 'instituicao', 'tipo', 'formaPagamento']}
                    hideHeadersOffset={880}
                />
            )}

            <Modal
                isOpen={!!contribuicaoAPagar}
                handleClose={() => {
                    setContribuicaoAPagar(null)
                    setQRCode(null)
                }}
                title={QRCode ? 'Efetue o pagamento' : !gerandoFatura ? 'Selecione a forma de pagamento' : 'Aguarde...'}
            >
                <ModalContainer>
                    {gerandoFatura ? (
                        <Spinner label="Gerando sua fatura..." />
                    ) : !QRCode ? (
                        <FormaPagamentoContainer>
                            <button type="button" onClick={() => { handlePagarPIX(contribuicaoAPagar) }}>
                                <img src={pixIcon} alt="" />
                            </button>

                            <button type="button" onClick={() => { handlePagarBoleto(contribuicaoAPagar) }}>
                                <FaBarcode size={50} />
                                <h1>Boleto Bancário</h1>
                            </button>
                        </FormaPagamentoContainer>
                    ) : (
                        <>
                            <QRCodeContainer>
                                <img src={QRCode} alt="QRCode PIX" />

                                <div>
                                    <CopyToClipboard
                                        text={PIXText}
                                        onCopy={() => toast.info('Código PIX copiado!', { autoClose: 1100 })}
                                    >
                                        <span className="pix-code-copy">Copiar código PIX</span>
                                    </CopyToClipboard>

                                    <span className="hint">* Use o código copiado na seção de PIX do seu Internet Banking.</span>
                                </div>
                            </QRCodeContainer>

                            {allowSendEmail && (
                                <Button onClick={() => { handleSendPIXEmail(contribuicaoAPagar) }} className="white">
                                    Enviar por e-mail
                                </Button>
                            )}
                        </>
                    )}
                </ModalContainer>
            </Modal>
        </>
    )
}
