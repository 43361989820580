import React, { useEffect, useState, useContext } from 'react'
import { Form, Formik } from 'formik'
import { format } from 'date-fns'

import {
    Button, Checkbox, Textbox, Select 
} from '../../../../components/Form'

import { RelatorioCustomizadoContext } from '../../../../contexts/RelatorioCustomizadoContext'

import Yup from '../../../../services/yup'
import { api, authHeaders } from '../../../../services/api'
import ibge from '../../../../services/ibge'

import stringUtils from '../../../../util/string'

import { Container, Preview } from './styles'

const validation = Yup.object({
    descricao: Yup.string().required('Forneça um nome para seu relatório.'),
    ordenacao: Yup.string().ensure(),
    quebra: Yup.string().ensure()
})

export default function () {
    const { handleSubmit, initialValues, relatorioEdicao } = useContext(RelatorioCustomizadoContext)

    const [filtros, setFiltros] = useState([])
    const [exibicoes, setExibicoes] = useState([])
    const [cidades, setCidades] = useState([])
    const [instituicoes, setInstituicoes] = useState([])

    async function loadFiltros() {
        const { filtros: response } = await api.get('relatorio_customizado/filtros', authHeaders())

        setFiltros(response)
    }

    async function loadCidades() {
        const { data } = await ibge.get('estados/PA/municipios')

        setCidades([
            {
                label: 'Todas as cidades',
                value: ''
            },
            ...data.map(({ nome }) => nome).sort().map(cidade => ({
                label: cidade,
                value: cidade
            }))
        ])
    }

    async function loadInstituicoes() {
        const response = await api.get('instituicao')

        setInstituicoes([
            {
                label: 'Todas as instituições',
                value: ''
            },
            ...response.map(i => ({
                label: `${i.nome_fantasia || i.razao_social}${i.sigla ? ` (${i.sigla})` : ''}`,
                value: i.id
            }))
        ])
    }

    async function loadExibicoes() {
        const { exibicoes: response } = await api.get('relatorio_customizado/exibicoes', authHeaders())

        setExibicoes(response)
    }

    useEffect(() => {
        loadFiltros()

        loadExibicoes()

        loadCidades()

        loadInstituicoes()
    }, [])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
                enableReinitialize
            >
                {({ setFieldValue, values }) => {
                    const columnsRelatorio = Object.entries(values.exibicoes)
                        // eslint-disable-next-line no-unused-vars
                        .filter(([_, value]) => !!value)
                        .map(([key]) => exibicoes
                            .find(e => e.id === Number(key)).colunas)
                        .map(grupo => grupo.split(','))
                        .reduce((result, current) => [
                            ...result,
                            ...current.map(c => c.split(':')[0].toUpperCase().replace(/_/g, ' '))
                        ], [])

                    return (
                        <Form>
                            <section className="nome">
                                <Textbox 
                                    label="Nome do relatório"
                                    name="descricao"
                                />
                            </section>

                            <section className="filtros">
                                <h2>Filtros aplicáveis</h2>

                                <ul>
                                    {filtros.map(filtro => (
                                        <li key={filtro.id}>
                                            <Checkbox 
                                                label={filtro.descricao}
                                                name={`filtros[${filtro.id}]`}
                                                handleChange={e => { 
                                                    const filtrosCheckbox = [...document.querySelectorAll('input[name^=filtros]')].filter(f => f.name !== e.target.name)

                                                    for(const filter of filtrosCheckbox) {
                                                        setFieldValue(filter.name, false) 
                                                    }

                                                    setFieldValue(e.target.name, e.target.checked)
                                                }}
                                            />
                                        </li>
                                    ))}
                                </ul>

                                <div className="selects">
                                    <Select 
                                        name="cidade"
                                        label="Município"
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                        options={cidades}
                                    />

                                    <Select 
                                        name="instituicao"
                                        label="Instituição"
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                        options={instituicoes}
                                    />
                                </div>
                            </section>

                            <section className="exibicoes">
                                <h2>Dados a serem exibidos no relatório</h2>

                                <ul>
                                    {exibicoes.map(exibicao => (
                                        <li key={exibicao.id}>
                                            <Checkbox 
                                                label={exibicao.descricao}
                                                name={`exibicoes[${exibicao.id}]`}
                                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </section>

                            <section className="total">
                                <h2>Totalização</h2>

                                <Checkbox 
                                    label="Incluir totalização na última página"
                                    name="com_totalizacao"
                                    handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                />
                            </section>

                            <section className="ordenacao">
                                <h2>Ordenação e quebra de seção</h2>

                                <Select 
                                    label="Selecione a coluna para ordenação"
                                    name="ordenacao"
                                    options={columnsRelatorio.map(col => ({
                                        label: stringUtils.capitalize(col),
                                        value: col.replace(/ /g, '_')
                                    }))}
                                    onChange={(selected, meta) => { 
                                        setFieldValue(meta.name, selected) 

                                        setFieldValue('quebra', null) 
                                    }}
                                />

                                <Select 
                                    label="Selecione a coluna para quebra de seção"
                                    name="quebra"
                                    options={columnsRelatorio.map(col => ({
                                        label: stringUtils.capitalize(col),
                                        value: col.replace(/ /g, '_')
                                    }))}
                                    onChange={(selected, meta) => { 
                                        setFieldValue(meta.name, selected) 

                                        setFieldValue('ordenacao', null) 
                                    }}
                                />
                            </section>

                            <section className="preview">
                                <h1>Previsualização do relatório (dados fictícios)</h1>

                                <Preview>
                                    <table className="header">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '50%' }}>
                                                    <img src="http://apisindmepa.robot.rio.br/resources/relatorio/SYNDI.png" alt="" style={{ height: '13mm' }} />
                                                </td>
		
                                                <td style={{ width: '50%', textAlign: 'right' }}>
                                                    <img src="http://apisindmepa.robot.rio.br/resources/relatorio/ROBOT.png" alt="" style={{ height: '13mm' }} />
                                                </td>
                                            </tr>
                                            <tr className="title">
                                                <td className="title">
                                                    {values.descricao || 'Nome do seu relatório'}
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {`Data de emissão: ${format(new Date(), 'dd/MM/yyyy')}`}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {Object.values(values.exibicoes).every(v => !v) ? (
                                        <p className="empty-placeholder"><i>Selecione pelo menos um grupo de dados para visualizar o resultado</i></p>
                                    ) : (
                                        <table className="data">
                                            <thead>
                                                <tr>
                                                    {columnsRelatorio.map(colName => (
                                                        <th key={colName}>{colName}</th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {Array.from(Array(20).keys()).map(row => (
                                                    <tr key={row}>
                                                        {columnsRelatorio.map(col => (
                                                            <td key={col}>xxxxx</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>

                                            {values.com_totalizacao && (
                                                <tfoot>
                                                    <tr>
                                                        <td>TOTAL</td>
                                                        <td colSpan={columnsRelatorio.length - 1} style={{ textAlign: 'right' }}>20 itens listados</td>
                                                    </tr>
                                                </tfoot>
                                            )}
                                        </table>
                                    )}

                                    <table className="footer">
                                        <tbody>
                                            <tr>
                                                <td>Sistema de Controle e Gestão de Sindicatos - Robot Genesis Desenvolvimento e Consultoria em Tecnologia da Informação</td>
                                                <td style={{ textAlign: 'right' }}>Página 01 de 01</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Preview>
                            </section>

                            <div className="button-container">
                                <Button className="transparent" type="submit">
                                    {relatorioEdicao ? 'Salvar alterações' : 'Criar relatório'}
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Container>
    )
}
