import React from 'react'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Routes from './routes'

import history from './services/history'

import './config/ReactotronConfig'

import GlobalProvider from './contexts/GlobalContext'

import './styles/progress.css'
import './styles/toast.css'
import './styles/editor.css'
import './styles/tooltip.css'
import './styles/tour.css'
import 'react-tabs/style/react-tabs.css'

import GlobalStyle from './styles/global'
import TabsStyle from './styles/tabs'
import TooltipStyle from './styles/tooltip2'
import { TipProvider } from './contexts/TipContext'
import { AvatarProvider } from './contexts/AvatarContext'

export default function App() {
    return (
        <Router history={history}>
            <GlobalProvider>
                <AvatarProvider>
                    <TipProvider>
                        <Routes />
                    </TipProvider>
                </AvatarProvider>
            </GlobalProvider>

            <GlobalStyle />

            <TabsStyle />

            <TooltipStyle />

            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                pauseOnHover
                pauseOnVisibilityChange
                hideProgressBar
                draggable
            />
        </Router>
    )
}
