import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { Container } from './styles'
import Modal from '../Modal'
import { Button } from '../Form'

export default function ({
    Icon, onClick, texto, size = 16, confirmable = false, className = '', tooltip, tooltipPosition = 'left', disabled = false
}) {
    const [showConfirmation, setShowConfirmation] = useState(false)

    function handleClick() {
        if(confirmable) {
            setShowConfirmation(true)

            return
        }

        onClick()
    }

    return (
        <>
            <Icon 
                onClick={disabled ? null : handleClick}
                size={size}
                className={`${className} ${disabled ? 'icon disabled' : ''}`}
                data-tip={!disabled ? tooltip : 'Não é possível executar esta ação'}
                data-for="icon-tooltip" 
            />

            <Modal 
                isOpen={showConfirmation}
                handleClose={() => {
                    setShowConfirmation(false)
                }}
                title="Confirmação"
            >
                <Container>
                    <p dangerouslySetInnerHTML={{ __html: texto }} />

                    <div className="button-container">
                        <Button className="transparent" onClick={() => { setShowConfirmation(false) }}>
                            Não
                        </Button>

                        <Button 
                            className="white" 
                            onClick={() => {
                                onClick()

                                setShowConfirmation(false)
                            }}
                        >
                            Sim
                        </Button>
                    </div>
                </Container>
            </Modal>

            <ReactTooltip
                id="icon-tooltip"
                place={tooltipPosition}
                effect="solid"
                type="dark"
            />
        </>
    )
}
