export function loadTips(setCodigo, formId) {
    setCodigo('')

    setTimeout(() => {
        if (formId) {
            setCodigo(formId)
        } else {
            const id = document.querySelector('*[id^=form_]')?.id
            setCodigo(id)
        }
    }, 500)
}
