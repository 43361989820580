import styled, { css } from 'styled-components'

export const TableContainer = styled.div.attrs({
    className: 'table-container'
})`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 24px;

	@media(max-width: ${props => props.hideOffset}px) {
		.caption,
		.textbox,
		.legends-container,
		table {
			display: none !important;
		}
	}

	.caption {
		align-self: flex-start;
		margin: 0 8px 8px;
    	font-size: 16px;
	}

	.textbox {
		margin-bottom: 16px;
		width: 340px;
		align-self: ${props => props.filterPosition === 'right' ? 'flex-end' : 'flex-start'};

		> span {
			display: none;
		}

		svg {
			top: 18px;
		}

		input {
			background-color: transparent;
			border-bottom: solid 1px rgba(255, 255, 255, 0.8);
			border-radius: unset;
			color: #fff;
			transition: 200ms;
			box-shadow: unset;

			&:focus {
				border: unset;
				border-bottom: solid 2px #FFF;
			}

			&::placeholder {
				color: #FFF7;
			}

			& + svg {
				color: #fff !important;
			}
		}

		@media(max-width: 600px) {
			width: calc(100% - 16px);
		}
	}

	.table-tooltip {
		max-width: 400px;
		
		span {
			text-align: left;
		}
	}

	.button-delete-checkeds-container {
		padding: 6px;

		&.fab {
			width: 30px;
			height: 30px;
		}
	}
`

export const Table = styled.table`
	width: 100%;
	box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
	border-radius: 4px;
	background-color: #FFF;

	thead {
		th {
			padding: 8px 10px;
			font-weight: 600;
			height: 34px;
			color: #424242;
			border-left: solid 1px #0002;
			display: table-cell;
			font-size: ${props => props.textSize}px;

			@media(max-width: ${props => props.hideHeadersOffset}px) {
				&.hide-on-small {
					display: none;
				}
			}

			&:first-child {
				border-top-left-radius: 4px;
			}

			&:last-child {
				border-top-right-radius: 4px;
			}

			&.check-column {
				width: 50px;
			}

			&:hover {
				padding: 8px 10px;
				cursor: ${props => props.orderable ? 'pointer' : 'default'};

				svg {
					opacity: 1;
					transform: scale(1.1);
				}
			}

			svg {
				opacity: 0.5;
				transition: 300ms;
				float: right;
				color: #424242 !important;
			}

			@media (max-width: 500px) {
				font-size: 13px;
				font-weight: 400;
			}

			&.action-icon {
				width: 10px;
			}
		}
	}

	tbody {
		tr {
			background-color: rgba(255,255,255,0.8);
			color: #424242;
			font-weight: 400;
			font-size: ${props => props.textSize}px;

			td {
				padding: 4px 8px;
				border-top: solid 0.5px #333;
				text-align: center;
				height: 36px;
				min-width: 52px;
				border-left: solid 1px #0002;

				&:not(.data) svg:not(.disabled) {
					margin: 0 10px;
					transition: 0.3s;
					color: #424242;
				}

				&.data.icon svg {
					cursor: default;

					&:hover {
						opacity: 1;
					}
				}

				&.action-icon svg:hover {
					color: #2196f3;
					cursor: pointer;
				}

				&.delete-icon {
					width: 30px;

					svg:hover {
						color: #f44336;
						cursor: pointer;
					}
				}

				&.edit-icon {
					width: 30px;

					svg:hover {
						color: #2196f3;
						cursor: pointer;
					}
				}

				&.success-icon svg:hover {
					color: #4caf50;
				}

				&.highlight-cell {
					&.highlight-bold {
						font-weight: bold;
					}

					&.highlight-level-warn {
						&.highlight-background {
							background-color: #ffeb3b;
						}

						&.highlight-font-color {
							color: #f57f17;
						}
					}

					&.highlight-level-error {
						&.highlight-background {
							background-color: #ef9a9a;
						}

						&.highlight-font-color {
							color: #f44336;
						}
					}

					&.highlight-level-success {
						&.highlight-background {
							background-color: #a5d6a7;
						}

						&.highlight-font-color {
							color: #4caf50;
						}
					}

					&.highlight-level-info {
						&.highlight-background {
							background-color: #81d4fa;
						}

						&.highlight-font-color {
							color: #2196f3;
						}
					}
				}

				& svg.disabled {
					color: #424242;

					&:hover {
						cursor: not-allowed;
					}
				}

				&.icon > span {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				@media(max-width: ${props => props.hideHeadersOffset}px) {
					&.hide-on-small {
						display: none;
					}
				}
			}

			${props => !props.withFooter && css`
				&:last-child {
					td:first-child {
						border-bottom-left-radius: 4px;
					}

					td:last-child {
						border-bottom-right-radius: 4px;
					}
				}
			`}

			@media (max-width: 500px) {
				font-size: 11px;
			}

			&.read-more-line {
				td {
					height: 30px;
					background-color: rgba(255,255,255,0.8);
					color: #424242;
					text-align: center;
					transition: 300ms;
					cursor: pointer;
					
					svg {
						opacity: 0.3;
						transition: 300ms;
					}

					&:hover {
						svg {
							opacity: 0.6;
						}
					}
				}
			}

			&:hover {
				background-color: rgba(0,0,0,0.04);
			}

			.spinner-container {
				margin-top: 10px;
			}
		}
	}

	tfoot {
		font-weight: 600;
		background-color: #FFFE;
		color: #000C;

		tr {
			height: 36px;
			
			td {
				padding: 4px 8px;
				border-top: solid 1px #0002;
			}

			&:last-child {
				td:first-child {
					border-bottom-left-radius: 4px;
				}

				td:last-child {
					border-bottom-right-radius: 4px;
				}
			}
		}
	}

	input[type=checkbox] {
		cursor: pointer;
	}

	@media (max-width: ${props => props.hideOffset}px) {
		.hide-on-small {
			display: none;
		}
	}
`

export const EmptyContainer = styled.div`
	background: #FFF3;
	color: #FFF;
	border-radius: 6px;
	width: 100%;
	max-width: unset !important;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 20px 0;

	svg {
		opacity: 0.5;
		margin-bottom: 15px;
	}

	span {
		opacity: 0.5;
		font-style: italic;
		text-align: center;
	}
`

export const ModalDeleteContainer = styled.div`
	padding: 0 8px;
	display: grid;
	grid-template-rows: 30px auto;
	margin-top: 20px;

	.button-container {
		display: flex;
		justify-content: flex-end;
	}
`

export const PaginationContainer = styled.div.attrs({
    className: 'table-pagination'
})`
	display: grid;
	grid-template-columns: 50px auto 50px;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 20px 0 10px;

	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 6px;
		padding: 0 8px;

		.page {
			font-size: 16px;
			font-weight: 600;
		}

		.count {
			font-size: 11px;
		}
	}

	button {
		width: 40px !important;
		justify-self: center;

		svg {
			font-size: 16px;
		}
	}

	@media(max-width: ${props => props.hideOffset}px){
		display: none;
	}
`

export const ActionCheckboxContainer = styled.div`
	display: flex;
	gap: 8px;

	button {
		svg {
			color: #FFF;
			font-size: 14px;
		}

		&:hover {
			svg {
				color: #424242;
			}	
		}
	}
`

export const Badge = styled.div`
	background-color: #ffeb3b;
	border-radius: 6px;
	color: #424242;
	display: grid;
	grid-gap: 14px 0;
	line-height: 22px;
	grid-template-columns: 60px auto;
	grid-template-areas: 'icon texto';
	align-items: center;
	font-size: 13px;
	margin: 12px auto;
	width: fit-content;
	padding: 24px 14px 14px;

	span {
		display: flex;
	}

	> button {
		width: fit-content;
		justify-self: center;
	}

	svg {
		grid-area: icon;
		justify-self: center;
		margin-right: 12px;
		font-size: 26px;
		cursor: default;
		color: #424242;
	}
`

export const OptionsContainer = styled.div`
	display: flex;
	justify-content: space-between;

	.legends-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: 50px;

		.table-legend {
			display: flex;
			align-items: center;	
			padding: 4px 14px;
	
			.table-legend-square {
				width: 12px;
				height: 12px;
				margin-right: 4px;
			}
		}
	}
`
