import styled from 'styled-components'

export const Container = styled.div`
	> p {
		grid-column: 1/span 3;
	}

	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: end;

		button {
			width: fit-content;
		}
	}
`
