import React, {
    useState, useEffect, useCallback, useContext
} from 'react'
import { format } from 'date-fns'
import { FaBarcode } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { api, authHeaders } from '../../../services/api'

import BoxList from '../../../components/BoxList'
import Modal from '../../../components/Modal'
import { PlanoSaude } from '../../../components/Associado'

import { GlobalContext } from '../../../contexts/GlobalContext'

import masks from '../../../util/masks'
import { getFromSubstring } from '../../../util/string'

const compactListLength = 5

export default function ({
    compacto = true, pessoa, onIconClick, showTitle = true
}) {
    const history = useHistory()

    const { user } = useContext(GlobalContext)
    const [contribuicoes, setContribuicoes] = useState(null)
    const [showDetalhe, setShowDetalhe] = useState(false)

    const loadContribuicoes = useCallback(async () => {
        const response = await api.get('contribuicao_plano_saude', {
            params: {
                associado_id: pessoa.associado.id
            },
            ...authHeaders()
        })

        let filtered = compacto ? response.filter(contribuicao => !contribuicao.status) : response

        if (compacto && filtered.length < compactListLength) {
            filtered = [
                ...filtered,
                ...response.filter(contribuicao => contribuicao.status).slice(0, compactListLength - filtered.length)
            ]
        }

        setContribuicoes(filtered.map(contribuicao => ({
            id: contribuicao.id,
            referencia: format(new Date(contribuicao.competencia), 'MM/yyyy'),
            dataPagamento: contribuicao.data_consolidacao ? format(new Date(contribuicao.data_consolidacao), 'dd/MM/yyyy') : '',
            valor: masks.maskApply.currency(contribuicao.valor),
            status: contribuicao.status ? 'FaCheckCircle' : 'FaMinusCircle',
            status_bool: contribuicao.status,
            iconColor: contribuicao.status ? '#4caf50' : '#f44336',
            fatura: contribuicao.fatura,
            tipoCobranca: contribuicao.tipo_cobranca.descricao
        })))
    }, [pessoa, compacto])

    useEffect(() => {
        loadContribuicoes()
    }, [])

    async function handleAbrirFatura(contribuicao) {
        try {
            const response = await api.get(`fatura/plano_saude/${contribuicao.id}`, authHeaders())

            if (response?.msg) {
                toast.warn(response.msg)
                return
            }

            if (response.fatura_url) {
                const url = getFromSubstring(response.fatura_url.replace('checkout', 'checkout_auth'), '/checkout')

                history.push(url)
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <>
            {compacto ? (
                <>
                    <BoxList
                        compacto
                        limit={contribuicoes?.length || compactListLength}
                        title={showTitle ? 'Plano de saúde' : ''}
                        extraText={pessoa.plano_saude?.status ? `Situação: ${pessoa.plano_saude.status !== 4 ? 'ATIVO' : 'INATIVO'}` : null}
                        onIconClick={onIconClick === undefined ? (() => { setShowDetalhe(true) }) : onIconClick}
                        headers={[
                            { name: 'referencia', value: 'Mês referência' },
                            {
                                name: 'status', value: 'Status', icon: true
                            }
                        ]}
                        data={contribuicoes}
                        actions={
                            [
                                {
                                    name: 'pagar',
                                    action: contribuicao => {
                                        handleAbrirFatura(contribuicao)
                                    },
                                    icon: FaBarcode,
                                    iconSize: 22,
                                    headerTitle: 'Pagamento',
                                    checkDisabled: item => {
                                        const contrib = contribuicoes?.find(contribuicao => contribuicao.id === item.id)

                                        return (pessoa.plano_saude?.status === 4 && [2, 16, 21, 22].includes(user.perfil.id)) || contrib.status_bool || !contrib.fatura || [3, 11, 12, 15].includes(user.perfil.id)
                                    }
                                }
                            ]
                        }
                    />

                    <Modal
                        isOpen={showDetalhe}
                        handleClose={() => setShowDetalhe(false)}
                        title="Contribuições de Plano de Saúde"
                        closeOnOverlayClick
                    >
                        <PlanoSaude compacto={false} pessoa={pessoa} showTitle={false} />
                    </Modal>
                </>
            ) : (
                <BoxList
                    title={showTitle ? 'Plano de saúde' : ''}
                    limit={contribuicoes?.length || 1000}
                    style={{ width: '100%' }}
                    headers={[
                        { name: 'referencia', value: 'Mês de referência', centered: true },
                        { name: 'tipoCobranca', value: 'Tipo', centered: true },
                        { name: 'dataPagamento', value: 'Data de pagamento', centered: true },
                        { name: 'valor', value: 'Valor', centered: true },
                        { name: 'status', value: 'Status', icon: true }
                    ]}
                    data={contribuicoes}
                    actions={
                        [
                            {
                                name: 'pagar',
                                action: contribuicao => {
                                    handleAbrirFatura(contribuicao)
                                },
                                icon: FaBarcode,
                                iconSize: 22,
                                headerTitle: 'Pagamento',
                                checkDisabled: item => {
                                    const contrib = contribuicoes?.find(contribuicao => contribuicao.id === item.id)

                                    return contrib.status_bool || !contrib.fatura || [3, 11, 12, 15].includes(user.perfil.id)
                                }
                            }
                        ]
                    }
                    hideOnSmallHeaders={['dataPagamento', 'status']}
                    hideHeadersOffset={550}
                />
            )}
        </>
    )
}
