import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	form {
		display: flex;
		flex-direction: column;
		width: 100%;

		> div {
			display: flex;
			align-items: flex-end;
			width: 100%;
		}

		button {
			width: calc(100% - 16px);
			max-width: 200px;
		}

		@media(max-width:600px){
			flex-direction: column;

			button {
				max-width: unset;
			}
		}
	}

	.table-container{
		width: calc(100% - 16px);
	}
`
