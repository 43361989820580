import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'action-footer'
})`
	grid-area: action_footer;
	border-top: solid 1px #fff4;
	padding: 4px 0 10px;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 16px;

	svg {
		fill: #656565;
	}
`
