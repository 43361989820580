import React, { useContext, useState } from 'react'
import { Form, Formik } from 'formik'
import { FiUpload } from 'react-icons/fi'
import { toast } from 'react-toastify'

import { Button, File, Textbox } from '../../../../../../components/Form'
import Modal from '../../../../../../components/Modal'
import Table from '../../../../../../components/Table'

import { api, authHeaders } from '../../../../../../services/api'
import Yup from '../../../../../../services/yup'

import { EventoContext } from '../../../../../../contexts/EventoContext'

import { Container, Bagde, ModalContainer } from './styles'

const validation = Yup.object({
    nome_grupo: Yup.string().required('Forneça o nome da lista de convidados.'),
    arquivo: Yup.number().typeError('Faça o upload da planilha de convidados.').required('Faça o upload da planilha de convidados.')
})

const baseInitialValues = {
    nome_grupo: '',
    arquivo: null
}

export default function ({ reload }) {
    const { evento } = useContext(EventoContext)

    const [inconsistencias, setInconsistencias] = useState(null)

    async function handleCargaPlanilha(values) {
        try {
            const { data, rowsWithInvalidData } = await api.post(`evento/${evento.id}/carga`, values, authHeaders())

            if(rowsWithInvalidData?.length) {
                setInconsistencias(rowsWithInvalidData.map(inc => {
                    const cellHighlight = {
                        columns: [],
                        format: 'font-color',
                        level: 'error'
                    }
                    
                    if(!inc.fone) {
                        cellHighlight.columns.push(1)    
                    }

                    if(!inc.email) {
                        cellHighlight.columns.push(2)    
                    }

                    if(!inc.cpf) {
                        cellHighlight.columns.push(3)    
                    }

                    return {
                        nome: inc.nome,
                        fone: inc.fone || '[inconsistência]',
                        email: inc.email || '[inconsistência]',
                        cpf: inc.cpf || '[inconsistência]',
                        cellHighlight
                    }
                }))

                return
            }

            toast.success(`${data.length} pessoas foram convidadas para o evento.`)

            reload()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    return (
        <>
            <Container>
                <Formik
                    onSubmit={handleCargaPlanilha}
                    initialValues={baseInitialValues}
                    validationSchema={validation}
                >
                    {({ setFieldValue, errors }) => (
                        <Form>
                            <Textbox 
                                name="nome_grupo"
                                label="Nome da lista de convidados"
                                placeholder="Forneça um nome para a lista de convidados carregados"
                            />

                            <File 
                                name="arquivo"
                                onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                label="Planilha de convidados"
                                format="square"
                                error={errors.arquivo}
                                previewSize={['100%', '200px']}
                                maxSize={10 * 1024 * 1024}
                                acceptedExtensions={[
                                    '.csv',
                                    '.xls',
                                    '.xlsx'
                                ]}
                            />

                            <div className="orientacao" dangerouslySetInnerHTML={{ __html: 'Sua planilha de convidados deve ter as seguintes colunas, nesta ordem: <b>nome, fone, email, cpf</b>.<br/><br/>Apenas a coluna nome é obrigatória, porém, para melhor controle, recomendamos preencher todos os dados.' }} />

                            <Button type="submit" className="transparent">
                                Carregar planilha
                                <FiUpload size={18} />
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={!!inconsistencias}
                handleClose={() => {
                    setInconsistencias(null)
                }}
                title="Convidados com dados inconsistentes"
            >
                <ModalContainer>
                    <Bagde dangerouslySetInnerHTML={{ __html: 'As pessoas abaixo apresentam dados inconsistentes em sua planilha.<br/><br/>Verifique os campos que apresentam o marcador "[inconsistência]" e corrija em sua planilha.<br/><br/>Assim que o tiver feito, tente enviá-la novamente.' }} />

                    <Table 
                        headers={[
                            { name: 'nome', value: 'Nome' },
                            { name: 'fone', value: 'Fone' },
                            { name: 'email', value: 'E-mail' },
                            { name: 'cpf', value: 'CPF' }
                        ]}
                        data={inconsistencias || []}
                    />
                </ModalContainer>
            </Modal>
        </>
    )
}
