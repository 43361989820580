import React, { useContext, useEffect, useState } from 'react'
import { FieldArray, Form, Formik } from 'formik'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import {
    FiFileText, FiPaperclip, FiPlus, FiSearch 
} from 'react-icons/fi'

import { ExtratoContext } from '../../../../../contexts/ExtratoContext'

import { api, authHeaders } from '../../../../../services/api'

import {
    Button, Calendar, Select, File 
} from '../../../../../components/Form'
import Table from '../../../../../components/Table'
import Modal from '../../../../../components/Modal'
import SmallScreenPlaceholder from '../../../../../components/SmallScreenPlaceholder'

import BoxArquivos from '../../../../../boxes/Arquivos'

import { Container, DocumentosContainer, ModalCadastroContainer } from './styles'
import { TipContext } from '../../../../../contexts/TipContext'
import { loadTips } from '../../../../../util/tip'

const baseInitialValues = {
    conta_corrente: null,
    competencia: '',
    documentos: []
}

export default function () {
    const {
        extratos, loadExtratos, handleFilterExtratos, contasCorrente 
    } = useContext(ExtratoContext)
    const { tips, setCodigo } = useContext(TipContext)

    const [extratoDocumentos, setExtratoDocumentos] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [showCadastro, setShowCadastro] = useState(false)
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [editId, setEditId] = useState(null)

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento', {
            params: {
                grupo: 'extrato'
            }
        })

        setTiposDocumento(response)
    }

    useEffect(() => {
        loadTiposDocumento()
    }, [])

    useEffect(() => {
        if(showCadastro) {
            setInitialValues(baseInitialValues)

            loadTips(setCodigo, 'form_cadastro_extratos_bancarios')
        } else {
            loadTips(setCodigo, 'form_pesquisa_extratos_bancarios')
        }
    }, [showCadastro])

    function reload() {
        setEditId(null)
        setShowCadastro(false)
        setInitialValues(baseInitialValues)
        
        loadExtratos({
            competencia: format(new Date(), 'yyyy-MM-\'01\'')
        })
    }

    function handleShowDocumentos(item) {
        setExtratoDocumentos(item.documentos)
    }

    async function handleAdd(values) {
        try {
            await api.post('extratos_bancarios', values, authHeaders())

            toast.success('Extrato cadastrado.')
        } catch(e) {
            toast.error('Erro ao cadastrar o extrato.')
        }
    }

    async function handleEdit(values) {
        try {
            await api.put(`extratos_bancarios/${editId}`, values, authHeaders())

            toast.success('Extrato atualizado.')
        } catch(e) {
            toast.error('Erro ao atualizar o extrato.')
        }
    }

    async function handleSubmit(values) {
        const body = {
            conta_corrente: values.conta_corrente.value,
            documentos: values.documentos.map(doc => ({
                arquivo: doc.arquivo.id || doc.arquivo,
                tipo_documento: doc.tipo_documento.id || doc.tipo_documento.value
            })),
            competencia: format(new Date(values.competencia), 'yyyy-MM-\'01\' 03:00:00')
        }

        if(editId) {
            await handleEdit(body)
        } else {
            await handleAdd(body)
        }

        reload()
    }

    async function handleDelete(id) {
        try {
            await api.delete(`extratos_bancarios/${id}`, authHeaders())

            toast.success('Extrato removido com sucesso.')

            return true
        } catch(e) {
            toast.error('Erro ao remover extrato.')

            return false
        }
    }

    async function handlePrepareEdit(id) {
        try {
            const { conta_corrente, competencia, documentos } = await api.get(`extratos_bancarios/${id}`, authHeaders())

            setInitialValues({
                conta_corrente: {
                    label: `${conta_corrente.dados_bancarios.instituicao.nome_fantasia} (${conta_corrente.dados_bancarios.conta})`,
                    value: conta_corrente.id
                },
                competencia: new Date(competencia),
                documentos
            })

            setEditId(id)

            return true
        } catch(e) {
            toast.error('Erro ao editar o extrato.')

            return false
        }
    }
    
    return (
        <>
            <Container>
                <p style={{ margin: 8 }}>{tips?.formulario}</p>

                <Button className="transparent" onClick={() => { setShowCadastro(true) }}>
                    Anexar extrato
                    <FiPlus />
                </Button>

                <Formik
                    onSubmit={handleFilterExtratos}
                    initialValues={{
                        competencia: '',
                        conta_corrente: null
                    }}
                >
                    {({ setFieldValue }) => (
                        <Form>
                            <Calendar 
                                name="competencia" 
                                dateFormat="mm/yy" 
                                yearNavigator 
                                view="month"
                                label="Competência"
                            />

                            <Select
                                name="conta_corrente"
                                label="Conta corrente"
                                isSearchable={false}
                                options={contasCorrente.map(conta => ({
                                    label: `${conta.dados_bancarios.instituicao.nome_fantasia} (${conta.dados_bancarios.conta})`,
                                    value: conta.id
                                }))}
                                onChange={selected => { setFieldValue('conta_corrente', selected) }}
                            />

                            <Button type="submit" className="transparent">
                                Pesquisar
                                <FiSearch />
                            </Button>
                        </Form>
                    )}
                </Formik>

                <SmallScreenPlaceholder hideOffset={600} />

                <Table 
                    headers={[
                        { name: 'conta_corrente', value: 'Conta corrente', centered: true },
                        { name: 'competencia', value: 'Competência', centered: true }
                    ]}
                    data={extratos?.map(extrato => ({
                        id: extrato.id,
                        conta_corrente: `${extrato.conta_corrente.dados_bancarios.instituicao.nome_fantasia} (${extrato.conta_corrente.dados_bancarios.conta})`,
                        competencia: format(new Date(extrato.competencia), 'MM/yyyy'),
                        documentos: extrato.documentos
                    })) || []}
                    actions={[
                        {
                            action: handleShowDocumentos,
                            name: 'Visualizar',
                            icon: FiFileText,
                            iconSize: 16
                        }
                    ]}
                    handlePrepareEdit={handlePrepareEdit}
                    handleDelete={handleDelete}
                    confirmExclusion={{
                        template: 'Deseja realmente excluir o extrato?'
                    }}
                />
            </Container>

            <Modal
                isOpen={showCadastro || !!editId}
                handleClose={() => { 
                    setShowCadastro(false) 
                    setEditId(null)
                }}
                title={`${editId ? 'Edição' : 'Cadastro'} de extratos bancários`}
            >
                <ModalCadastroContainer>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                    >
                        {({ setFieldValue, errors, values }) => (
                            <Form>
                                <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                                <Calendar 
                                    name="competencia" 
                                    dateFormat="mm/yy" 
                                    yearNavigator 
                                    view="month"
                                    label="Competência"
                                />

                                <Select
                                    name="conta_corrente"
                                    label="Conta corrente"
                                    isSearchable={false}
                                    options={contasCorrente.map(conta => ({
                                        label: `${conta.dados_bancarios.instituicao.nome_fantasia} (${conta.dados_bancarios.conta})`,
                                        value: conta.id
                                    }))}
                                    onChange={selected => { setFieldValue('conta_corrente', selected) }}
                                />

                                <DocumentosContainer>
                                    <FieldArray 
                                        name="documentos"
                                        render={arrayHelpers => (
                                            <>
                                                <h1>Documentos</h1>

                                                <File 
                                                    name="arquivo"
                                                    onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                                    label="Documento"
                                                    format="square"
                                                    previewSize={['100%', '150px']}
                                                    error={errors.documento}
                                                    getPreloadImage={async () => {
                                                        const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                                        return arquivo?.link
                                                    }}
                                                    previewPDF
                                                />

                                                <Select 
                                                    name="tipo_documento"
                                                    label="Tipo de documento"
                                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                                    options={tiposDocumento.map(({ id, descricao }) => ({
                                                        label: descricao,
                                                        value: id
                                                    }))}
                                                />

                                                <Table 
                                                    headers={[
                                                        { name: 'tipo_documento', value: 'Documento' }
                                                    ]}
                                                    data={values.documentos?.map(doc => ({
                                                        id: `${doc.arquivo.id}_${doc.tipo_documento.id}`,
                                                        tipo_documento: doc.tipo_documento.descricao || doc.tipo_documento.label
                                                    })) || []}
                                                    handleDelete={(_, index) => {
                                                        arrayHelpers.remove(index)
                                                        return true
                                                    }}
                                                    confirmExclusion={{
                                                        attr: 'tipo_documento',
                                                        template: 'Deseja realmente excluir o documento #attr#?'
                                                    }}
                                                    emptyLabel="Nenhum documento anexado"
                                                    disableTooltips
                                                />

                                                <Button 
                                                    className="transparent"
                                                    onClick={() => {
                                                        const { tipo_documento, arquivo } = values

                                                        if(!arquivo || !tipo_documento) {
                                                            toast.warn('Adicione o documento e escolha o tipo.')
                                                            return
                                                        }

                                                        arrayHelpers.push({
                                                            tipo_documento,
                                                            arquivo
                                                        })

                                                        setFieldValue('arquivo', null)
                                                        setFieldValue('tipo_documento', null)
                                                    }}
                                                >
                                                    Anexar novo documento
                                                    <FiPaperclip size={16} />
                                                </Button>
                                            </>
                                        )}
                                    />
                                </DocumentosContainer>

                                <Button type="submit" className="transparent">
                                    {editId ? 'Salvar alterações' : 'Cadastrar'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalCadastroContainer>
            </Modal>

            <Modal
                isOpen={!!extratoDocumentos}
                handleClose={() => { setExtratoDocumentos(null) }}
                title="Extratos bancários"
            >
                <BoxArquivos 
                    arquivos={extratoDocumentos?.map(extrato => ({
                        nome: extrato.tipo_documento.descricao,
                        link: extrato.arquivo.link
                    })) || []}
                    title=""
                />
            </Modal>
        </>
    )
}
