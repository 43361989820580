import React from 'react'

import { Container } from './styles'

import Card from '../../../../components/Card'
import Content from './Content'

export default function () {
    return (
        <Container>
            <main>
                <Card>
                    <h1>Atendimento</h1>

                    <Content />
                </Card>
            </main>
        </Container>
    )
}
