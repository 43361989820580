import React, { useContext, useEffect, useState } from 'react'
import { FiArrowLeft, FiEye } from 'react-icons/fi'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import { Container } from './styles'

import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import { api, authHeaders } from '../../../../services/api'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import Visualizar from './Visualizar'
import { Button } from '../../../../components/Form'

export default function () {
    const { user } = useContext(GlobalContext)

    const [enquete, setEnquete] = useState(null)
    const [respostaVisualizacao, setRespostaVisualizacao] = useState(null)

    const { id } = useParams()

    const history = useHistory()

    async function loadEnquete() {
        try {
            const response = await api.get(`enquete/${id}`, {
                params: {
                    scope: 'com_respostas'
                },
                ...authHeaders()
            })

            setEnquete(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if (user && [2, 16, 25].includes(user.perfil.id)) {
            history.push('/grupo_permissoes/29')
        } else {
            loadEnquete()
        }
    }, [user])


    if (!enquete) {
        return (
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <Spinner />
                    </Card>
                </main>
            </Container>
        )
    }

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <h1>{`Respostas da enquete: ${enquete.nome}`}</h1>

                                <p>{`Motivo: ${enquete.motivo}`}</p>
                            </div>
                            <Button className="transparent" onClick={() => { history.push('/grupo_permissoes/29') }} style={{ gap: 10 }}>
                                <FiArrowLeft size={16} style={{ marginLeft: 0 }} />
                                Voltar
                            </Button>
                        </div>

                        <Table
                            headers={[
                                { name: 'nome', value: 'Enquete' },
                                {
                                    name: 'data', value: 'Respondida em', centered: true, style: { width: 145 }
                                }
                            ]}
                            data={enquete.respostas.map(resp => ({
                                id: resp.id,
                                nome: resp.pessoa.nome,
                                respostas: resp.questao_respostas,
                                mensagem: resp.mensagem,
                                data: format(new Date(resp.fim), 'dd/MM/yyyy HH:mm\'h\'')
                            }))}
                            actions={[
                                {
                                    name: 'visualizar',
                                    icon: FiEye,
                                    iconSize: 16,
                                    action: item => {
                                        setRespostaVisualizacao(item)
                                    }
                                }
                            ]}
                            footers={[
                                {
                                    text: 'TOTAL',
                                    align: 'center'
                                },
                                {
                                    text: enquete.respostas.length,
                                    align: 'center',
                                    colspan: 2
                                }
                            ]}
                        />
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!respostaVisualizacao}
                handleClose={() => {
                    setRespostaVisualizacao(null)
                }}
                title={`Resposta de ${respostaVisualizacao?.nome || ''}`}
            >
                {respostaVisualizacao?.respostas && (
                    <Visualizar
                        respostas={respostaVisualizacao.respostas}
                        mensagem={respostaVisualizacao.mensagem}
                    />
                )}
            </Modal>
        </>
    )
}
