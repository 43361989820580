import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'

import { Button, Textbox } from '../../../../components/Form'
import Table from '../../../../components/Table'

import { api, authHeaders } from '../../../../services/api'

import { PermissoesContext } from '../../../../contexts/PermissoesContext'

import { Container } from './styles'
import { TipContext } from '../../../../contexts/TipContext'

const baseInitialValues = {
    descricao: ''
}

export default function () {
    const { perfis, reload } = useContext(PermissoesContext)
    const { tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [editId, setEditId] = useState(null)

    async function handleAdd(values) {
        await api.post('perfil', values, authHeaders())

        toast.success('Perfil cadastrado com sucesso.')
    }

    async function handleEdit(values) {
        await api.put(`perfil/${values.id}`, values, authHeaders())

        setEditId(null)

        toast.success('Perfil editado com sucesso.')
    }

    async function handleSubmit(values) {
        try {
            if (editId) {
                await handleEdit(values)
            } else {
                await handleAdd(values)
            }

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handlePrepareEdit(id) {
        setEditId(id)
    }

    async function handleDelete(id) {
        try {
            await api.delete(`perfil/${id}`, authHeaders())

            toast.success('Perfil excluído com sucesso.')

            reload()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    useEffect(() => {
        if (editId) {
            setInitialValues(perfis.find(perfil => perfil.id === editId))
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [editId])

    return (
        <Container>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <p style={{ margin: 8, width: '100%' }}>{tips?.formulario}</p>

                        <div>
                            <Textbox
                                name="descricao"
                                label="Descrição"
                            />

                            <Button type="submit" className="transparent">
                                {editId ? 'Salvar alterações' : 'Cadastrar Perfil'}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

            <Table
                headers={[
                    { name: 'descricao', value: 'Descrição' }
                ]}
                data={perfis || []}
                caption="Perfis cadastrados"
                handleDelete={handleDelete}
                handlePrepareEdit={handlePrepareEdit}
            />

        </Container>
    )
}
