import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { FiSearch } from 'react-icons/fi'
import {
    Button, Calendar 
} from '../../../../../components/Form'
import { ProcessoContext } from '../../../../../contexts/ProcessoContext'

import { FiltrosContainer } from './styles'

export default function () {
    const {
        loadProcessos,
        filtroAtual
    } = useContext(ProcessoContext)

    return (
        <Formik
            initialValues={filtroAtual}
            onSubmit={loadProcessos}
        >
            {() => (
                <Form>
                    <FiltrosContainer>
                        <Calendar
                            name="periodo"
                            label="Período"
                            selectionMode="range"
                            required
                        />

                        <Button className="transparent" type="submit">
                            Pesquisar
                            <FiSearch size={16} />
                        </Button>
                    </FiltrosContainer>
                </Form>
            )}
        </Formik>
    )
}
