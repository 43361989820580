import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { format, addYears } from 'date-fns'
import { FiEdit } from 'react-icons/fi'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'

import Yup from '../../../services/yup'

import Spinner from '../../../components/Spinner'
import Modal from '../../../components/Modal'
import ActionFooter from '../../../components/ActionFooter'
import { Textbox, Button } from '../../../components/Form'

import masks from '../../../util/masks'
import { stringToDate, getDate } from '../../../util/date'

import { api, authHeaders } from '../../../services/api'

import { Container, ModalContainer } from './styles'

const baseInitialValues = {
    nascimento: ''
}

const boxName = 'Dados pessoais'
const idadeMinima = 20

const validation = Yup.object().shape({
    nascimento: Yup.string()
        .date({ max: { value: addYears(new Date(), -idadeMinima), message: `A idade mínima é ${idadeMinima} anos.` } })
        .required('Especifique a data de nascimento.')
})

export default function ({ pessoa, reload, editable = true }) {
    const [editMode, setEditMode] = useState(false)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function handlePrepareEdit() {
        const {
            data_nascimento,
            documentos
        } = pessoa

        const cpf = documentos.find(d => d.tipo_documento.id === 2)
        const rg = documentos.find(d => d.tipo_documento.id === 1)
        const dataEmissao = rg?.extra ? rg.extra.data_emissao : null

        setInitialValues({
            nascimento: data_nascimento ? format(stringToDate(data_nascimento), 'dd/MM/yyyy') : '',
            cpf: cpf?.identificador || '',
            rg: rg?.identificador || '',
            rg_orgao: rg?.extra ? rg.extra.orgao_emissor : '',
            rg_emissao: dataEmissao ? format(stringToDate(`${dataEmissao} 00:00:00`), 'dd/MM/yyyy') : '',
            cpf_id: cpf?.id || '',
            rg_id: rg?.id || ''
        })

        setEditMode(true)
    }

    async function handleEdit(values) {
        try {
            const {
                nascimento, cpf, rg, rg_emissao, rg_orgao, rg_id, cpf_id
            } = values

            await api.put(`pessoa/${pessoa.id}`, {
                data_nascimento: getDate(nascimento),
                cpf,
                rg,
                rg_id,
                cpf_id,
                rg_emissao: rg_emissao ? getDate(rg_emissao) : null,
                rg_orgao
            }, authHeaders())

            toast.success('Dados atualizados.')

            setEditMode(false)

            setInitialValues(baseInitialValues)

            reload()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao atualizar os dados pessoais.')
        }
    }

    return (
        <>
            <Container className="animated fadeInUp delay-200ms no-responsive">
                <h1>Dados pessoais</h1>

                {editable ? (
                    <FiEdit
                        size={20}
                        data-tip="Editar"
                        data-for="tooltip"
                        onClick={handlePrepareEdit}
                    />
                ) : (
                    <div />
                )}

                <div />

                {pessoa ? (
                    <div>
                        <span>
                            <b>Data nascimento: </b>
                            {pessoa.dados_pessoais?.data_nascimento || ''}
                        </span>

                        <span>
                            <b>CPF: </b>
                            {pessoa.dados_pessoais?.cpf || ''}
                        </span>

                        <span>
                            <b>RG: </b>
                            {pessoa.dados_pessoais?.rg
                                ? `${pessoa.dados_pessoais.rg} - ${pessoa.dados_pessoais.rg_orgao_emissor}`
                                : '-'}
                        </span>

                        <span>
                            <b>Data de emissão: </b>
                            {pessoa.dados_pessoais.rg_data_emissao || ''}
                        </span>
                    </div>
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={editMode}
                handleClose={() => setEditMode(false)}
                title={boxName}
            >
                <ModalContainer>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={editMode ? handleEdit : () => null}
                        enableReinitialize
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Field type="hidden" name="cpf_id" />

                                <Field type="hidden" name="rg_id" />

                                <Textbox
                                    label="Data de nascimento"
                                    name="nascimento"
                                    mask={masks.date}
                                />

                                <Textbox
                                    label="CPF"
                                    name="cpf"
                                    mask={masks.cpf}
                                />

                                <Textbox
                                    label="RG"
                                    name="rg"
                                />

                                <Textbox
                                    label="Data emissão"
                                    name="rg_emissao"
                                    mask={masks.date}
                                />

                                <Textbox
                                    label="Orgão emissor"
                                    name="rg_orgao"
                                />

                                <ActionFooter>
                                    <Button type="submit" loading={isSubmitting} className="white">
                                        Salvar alterações
                                        <FaRegSave />
                                    </Button>
                                </ActionFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContainer>
            </Modal>
        </>
    )
}
