import styled from 'styled-components'

export const Container = styled.div`
	padding: 0 8px;

	p {
		line-height: 24px;
	}
`

export const ButtonContainer = styled.div`
	grid-area: button;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	gap: 8px;

	@media(max-width: 600px) {
		button {
			width: 100%;
		}
	}
`
