import React, { useContext } from 'react'
import { FaCheck, FaUserAltSlash, FaTimes } from 'react-icons/fa'

import Table from '../../../../../../components/Table'

import { AtendimentoContext } from '../../../../../../contexts/AtendimentoContext'

import Spinner from '../../../../../../components/Spinner'

import { Container } from './styles'

export default function () {
    const { registroAtendimentoPresencial, handleAtualizarStatus } = useContext(AtendimentoContext)

    if(!registroAtendimentoPresencial) {
        return <Spinner />
    }

    return (
        <Container>
            <p>Esta demanda possui atendimentos presenciais sem conclusão. Deseja realizar alguma ação agora?</p>

            <Table 
                headers={[
                    { name: 'atendente', value: 'Atendente' },
                    { name: 'associado', value: 'Associado' },
                    { name: 'status_descricao', value: 'Status', centered: true },
                    { name: 'data_hora', value: 'Data/hora', centered: true }
                ]}
                data={registroAtendimentoPresencial}
                actions={[
                    {
                        action: item => { handleAtualizarStatus(item, 3) },
                        name: 'Atendido',
                        icon: FaCheck,
                        iconSize: 16,
                        color: '#4caf50'
                    },
                    {
                        action: item => { handleAtualizarStatus(item, 4) },
                        name: 'Cancelar agendamento',
                        icon: FaTimes,
                        iconSize: 16,
                        color: '#f44336'
                    },
                    {
                        action: item => { handleAtualizarStatus(item, 2) },
                        name: 'Não compareceu',
                        icon: FaUserAltSlash,
                        iconSize: 16
                    }
                ]}
            />
        </Container>
    )
}
