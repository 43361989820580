import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik, FieldArray } from 'formik'
import { FiTrash } from 'react-icons/fi'

import ActionFooter from '../../../../components/ActionFooter'
import { AssociacaoContext } from '../../../../contexts/AssociacaoContext'
import formUtils from '../../../../util/form'
import { Button, Select, Textbox } from '../../../../components/Form'
import { api } from '../../../../services/api'
import masks from '../../../../util/masks'
import yup from '../../../../services/yup'
import { AvatarContext } from '../../../../contexts/AvatarContext'

const baseInitialValues = {
    contatos: []
}

const validation = yup.object({
    contatos: yup.array().of(
        yup.object({
            tipo: yup.string().ensure().required('Selecione o tipo de contato.'),
            valor: yup.string().required('Informe o contato.')
        })
    )
})

export function Contato() {
    const { tempData, syncTempData, avancarEtapa } = useContext(AssociacaoContext)
    const { handleSpeak } = useContext(AvatarContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [tiposContato, setTiposContato] = useState([])

    function handleSubmit(values) {
        const data = values.contatos.map(item => {
            return formUtils.extractFormValues(item)
        })

        syncTempData({
            contatos: data
        })
    }

    function getMascaraContato(tipo) {
        switch (tipo) {
            case 1: case 2:
                return masks.phone
            case 3: case 4: case 5:
                return masks.mobile
            default:
                return null
        }
    }

    useEffect(() => {
        if(!tiposContato.length) {
            return
        }
        
        const { contatos } = tempData

        if(contatos) {
            setInitialValues({
                contatos: contatos.map(cont => ({
                    valor: cont.valor,
                    tipo: {
                        label: tiposContato.find(c => c.id === cont.tipo).descricao,
                        value: cont.tipo
                    }
                }))
            })

            if(!contatos.some(c => c.tipo === 6)) {
                handleSpeak('Preencha cuidadosamente seu endereço de e-mail, pois este é nosso principal canal de comunicação.')
            }
        }
    }, [tempData, tiposContato])

    useEffect(() => {
        async function loadTiposContato() {
            const response = await api.get('tipo_contato')

            setTiposContato(response)
        }

        loadTiposContato()
    }, [])

    if(!tiposContato) {
        return null
    }

    return (
        <div>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validation}
            >
                {({ setFieldValue, values, submitForm }) => (
                    <Form className="flex flex-col pb-4">
                        <FieldArray 
                            name="contatos"
                            render={({ push, remove }) => {
                                return (
                                    <div>
                                        <Button 
                                            onClick={() => {
                                                push({
                                                    tipo: null,
                                                    valor: ''
                                                }) 
                                            }} 
                                            className="transparent"
                                        >
                                            Incluir contato
                                        </Button>

                                        {values.contatos.map((contato, idx) => (
                                            <div key={idx} className="flex flex-col border-b border-gray-500 pb-4 sm:pb-0 sm:border-b-0 sm:flex-row items-center max-w-[32rem]">
                                                <Select 
                                                    name={`contatos.${idx}.tipo`}
                                                    label="Tipo de contato"
                                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                                    options={tiposContato.map(tipo => ({
                                                        label: tipo.descricao,
                                                        value: tipo.id
                                                    }))}
                                                    isSearchable={false}
                                                />

                                                <Textbox 
                                                    name={`contatos.${idx}.valor`}
                                                    label="Contato"
                                                    mask={getMascaraContato(values.contatos[idx].tipo?.value)}
                                                    disabled={!values.contatos[idx].tipo}
                                                    onBlur={submitForm}
                                                />

                                                <Button
                                                    fab
                                                    tiny
                                                    className="white"
                                                    style={{
                                                        width: 28,
                                                        height: 28,
                                                        marginTop: 24
                                                    }}
                                                    onClick={() => {
                                                        remove(idx)

                                                        submitForm()
                                                    }}
                                                >
                                                    <FiTrash />
                                                </Button>

                                                {contato.tipo?.value === 6 && (
                                                    <div className="w-full max-w-[20rem] sm:max-w-[10rem] mt-4 ml-2">Este será seu principal canal de comunicação.</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )
                            }}
                        />

                        <ActionFooter>
                            <Button 
                                className="transparent w-fit self-end"
                                onClick={avancarEtapa}
                            >
                                Continuar
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </div>
    )
} 
