import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'
import { format, subDays } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeCalendarGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    contaCorrente: '',
    pagamento: ''
}

const validation = Yup.object().shape({
    contaCorrente: Yup.string().ensure().required('Selecione a conta corrente.'),
    pagamento: Yup.string().nullable().required('Informe um periodo para a busca.')
})

const nomeRelatorio = 'Relatório de Extratos bancários'

export default function (props) {
    const [contas, setContas] = useState([])

    async function loadContas() {
        const response = await api.get('conta_sindicato', authHeaders())

        setContas(response)
    }

    useEffect(() => {
        loadContas()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            contaCorrente,
            pagamento 
        } = values

        const [inicio, fim] = pagamento

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioExtratoBancario',
                parametros: {
                    idConta: contaCorrente?.value || '',
                    dataInicio: format(new Date(inicio), 'yyyy-MM-dd'),
                    dataFim: format(new Date(fim), 'yyyy-MM-dd'),
                    dataFinalSub: format(subDays(new Date(inicio), 1), 'yyyy-MM-dd 23:59:59'),
                    banco: contaCorrente.label.split('-')[0].trim(),
                    contacorrente: contaCorrente.label.split('C/C')[1].trim() 
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })
            
            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch(e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm 
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>
                        
                        <Calendar 
                            name="pagamento" 
                            label="Periodo de busca"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f1' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeCalendarGif}" style="width:300px; height:auto" />`}
                        />

                        <Select 
                            name="contaCorrente"
                            label="Conta corrente"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={contas.map(({ id, dados_bancarios }) => ({
                                label: `${dados_bancarios.instituicao.sigla} - AG ${dados_bancarios.agencia} C/C ${dados_bancarios.conta}`,
                                value: id
                            }))}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Spinner
                            visible={isSubmitting} 
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>
                            
                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
