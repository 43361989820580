import styled from 'styled-components'

import crachaHorizontal from '../../assets/images/crachá-horizontal.png'
import crachaVertical from '../../assets/images/crachá-vertical.png'

export const Container = styled.div`
	margin: 36px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70vh;
	flex-direction: column;

	.card {
		width: 100%;
		max-width: 800px;

		> h1 {
			grid-column: 1/span 2;
			margin: 0 8px;
		}

		.table-container {
			margin-bottom: 20px;
		}

		a {
			grid-column: 1/span 2;
			color: #FFF;
		}
	}
	
	form {
		display: grid;
		grid-template-columns: 1fr 2fr;

		button {
			justify-self: flex-end;
			grid-column: 1/span 2;
			margin-top: 12px;
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
		}
	}

	@media(max-width: 600px) {
		margin: 0 0 40px;

		.card {
			width: 100%;
		}
	}
`

export const CrachaContainer = styled.div.attrs({
	className: 'animated fadeInUp'
})`
	background-image: url(${crachaHorizontal});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	max-width: 672px;
	color: #fff;
	padding: 140px 44px 110px;
	margin: 20px;
	display: grid;
	grid-template-columns: 100px 1fr;
	grid-template-rows: 40px 70px auto 44px;
	grid-template-areas: 
		'logo logo'
		'foto nome'
		'foto dados'
		'foto extra';
	grid-gap: 0 30px;

	h1, h2, h3 {
		color: #fff;
	}

	> h1 {
		align-self: center;
		font-size: 24px;
		grid-area: nome;
	}

	> img.foto {
		grid-area: foto;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		object-fit: cover;
		justify-self: center;
	}

	> img.logo {
		grid-area: logo;
		height: 40px;
		justify-self: flex-end;
	}

	@media(max-width: 800px) {
		font-size: 12px;
	}

	@media(max-width: 600px) {
		padding: 116px 44px 34px;
		background-image: url(${crachaVertical});
		grid-template-rows: 100px minmax(24px, 30px) minmax(24px, 72px) auto auto;
		grid-template-areas: 
			'foto foto'
			'nome nome'
			'dados dados'
			'extra extra'
			'logo logo';
		grid-gap: 20px 30px;
		max-width: 330px;
		
		> h1 {
			font-size: 16px;
			font-weight: 600;
			text-align: center;
			margin-bottom: 10px;
		}

		> img.logo {
			justify-self: center;
		}
	}
`

export const MedicoContainer = styled.div`
	grid-area: dados;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 12px;
	grid-template-areas: 
		'tipo crm'
		'especialidade especialidade';

	h2 {
		font-size: 14px;
		font-weight: 500;

		svg {
			margin-right: 4px;
		}
	}

	> div {
		grid-area: especialidade;

		p {
			font-size: 14px;
			margin: 4px 0;
		}
	}
`

export const AcademicoContainer = styled.div`
	grid-area: dados;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 12px;
	grid-template-areas: 
		'tipo matricula'
		'faculdade faculdade';

	h2 {
		font-size: 14px;
		font-weight: 500;
		display: flex;
		align-items: center;

		svg {
			margin-right: 4px;
		}
	}

	> div {
		grid-area: faculdade;
		display: flex;
		align-items: center;
	}

	.badge {
		padding: 6px;
		border-radius: 4px;
		margin-left: 10px;

		&.green {
			background-color: #66bb6a;
			color: #fff;
		}

		&.warn {
			background-color: #ffeb3b;
			color: #424242;
		}
	}
`

export const ExtraDataContainer = styled.div`
	grid-area: extra;
	margin-top: 12px;

	svg {
		margin-right: 4px;
	}
`
