import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { FiCheck } from 'react-icons/fi'

import Yup from '../../../../services/yup'

import { ColaboradorContext } from '../../../../contexts/ColaboradorContext'

import {
    Button, File, Textarea, Textbox
} from '../../../../components/Form'

import masks from '../../../../util/masks'

import { Container } from './styles'

const validation = Yup.object({
    data_desligamento: Yup.string().required('Informe a data do desligamento.'),
    motivo: Yup.string().required('Informe o motivo do desligamento.'),
    recisao: Yup.number().typeError('Anexe o documento de recisão.').required('Anexe o documento de recisão.')
})

export default function () {
    const { colaboradorDesligamento, handleDesligarColaborador } = useContext(ColaboradorContext)

    return (
        <Container>
            <p>
                {'Nome: '}
                <b>{colaboradorDesligamento?.nome}</b>
            </p>

            {colaboradorDesligamento?.data_admissao && (
                <p>
                    {'Data de admissão: '}
                    <b>
                        {colaboradorDesligamento?.data_admissao}
                    </b>
                </p>
            )}

            <p>
                {'Cargo: '}
                <b>{colaboradorDesligamento?.cargo || '-'}</b>
            </p>

            <Formik
                onSubmit={handleDesligarColaborador}
                initialValues={{
                    data_desligamento: '',
                    motivo: '',
                    recisao: null
                }}
                validationSchema={validation}
            >
                {({ setFieldValue, errors }) => (
                    <Form>
                        <Textbox
                            name="data_desligamento"
                            label="Data do desligamento"
                            mask={masks.date}
                        />

                        <Textarea
                            label="Motivo"
                            name="motivo"
                        />

                        <File
                            name="recisao"
                            onSuccess={fileId => setFieldValue('recisao', fileId)}
                            label="Documento de recisão"
                            format="square"
                            previewSize={['100%', '150px']}
                            error={errors.recisao}
                            maxSize={3 * 1024 * 1024}
                        />

                        <Button type="submit" className="transparent">
                            Confirmar desligamento
                            <FiCheck size={16} />
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
