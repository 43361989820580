import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			display: flex;
			flex-direction: column;

			> h1 {
				margin: 0 8px 20px;
				grid-area: title;
			}
		}
	}
`

export const DetalhesContainer = styled.div`
	margin: 18px 8px 5px;
	display: grid;
	gap: 0 16px;
	grid-template-columns: 2fr 1fr;

	p {
		grid-column: 1/span 2;
		line-height: 30px;
	}

	.table-beneficiarios {
		grid-column: 1/span 2;
	}

	.table-adicionais {
		margin-bottom: 36px;
	}

	.table-historico {
		grid-column: 1/span 2;
		margin-top: 0;
	}

	.plano-container {
		display: flex;
		align-items: center;
		gap: 4px;
		grid-column: 1/span 2;

		> div {
			display: flex;
			align-items: end;

			.select {
				width: 300px;
			}
		}
	}

	@media(max-width: 900px) {
		display: flex;
		flex-direction: column;

		.table-adicionais {
			margin-bottom: 0;
		}

		.table-taxas {
			margin-bottom: 36px;
		}
	}
`
