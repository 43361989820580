import styled from 'styled-components'

import { Container as Card } from '../../components/Card/styles'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;
	
		@media(max-width: 1200px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media(max-width: 1000px) {
			display: flex;
			flex-direction: column;
			padding: 30px 0;
		}

		${Card} {
			> h1 {
				margin: 16px 24px;
			}

			.react-tabs {
				.react-tabs__tab {
					display: inline-block;

					@media(max-width: 700px) {
						display: flex;
						flex-direction: column;
					}

					/* @media(max-width: 910px) {
						&:not(.react-tabs__tab--selected) {
							display: none;
						}
					} */
				}

				.react-tabs__tab-panel {
					background-color: #00000022;
					border-radius: 16px;
					padding: 16px 24px 0;
					min-height: 120px;
				}
			}
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;
	}
`

export const ExpanderTab = styled.div`
	display: none;
	padding: 6px;
	background-color: #0001;
	border-radius: 50%;
	margin: 2px 8px;
	transition: 300ms;

	&:hover {
		cursor: pointer;
		background-color: #0002;
	}

	/* .expander-tab {
		height: 100%;
		display: flex;
		align-items: center;
	} */

	/* @media(max-width: 910px) { 
		display: inline-block;		
	} */
`

export const ImageSelect = styled.div`
	display: ${props => props.academico === null ? 'grid' : 'none'};
	grid-template-columns: 1fr 1fr;
	gap: 48px;
	margin-top: 28px;
	width: fit-content;
	margin: 0 auto;

	@media(max-width: 600px) {
		display: ${props => props.academico === null ? 'flex' : 'none'};
		flex-direction: column;
		align-items: center;
	}

	.categoria {
		width: 160px;
		transition: 300ms;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;

		span {
			font-weight: 800;
		}

		&:hover {
			transform: scale(1.05);
			cursor: pointer;
		}
	}
`
