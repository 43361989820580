import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik, FieldArray } from 'formik'
import { FiPlus, FiTrash } from 'react-icons/fi'

import { weekDays } from '../../../../util/calendar'

import { Button, Select, Textbox } from '../../../../components/Form'

import maskUtils from '../../../../util/masks'
import { getMinutesFromTime } from '../../../../util/date'

import { Container, DiaAtendimentoContainer } from './styles'

import { AdvogadoContext } from '../../../../contexts/AdvogadoContext'

export default function () {
    const { handleSalvarAgenda, showFormAgenda } = useContext(AdvogadoContext)

    const [initialValues, setInitialValues] = useState({
        dias_plantao: []
    })

    useEffect(() => {
        if(showFormAgenda) {
            setInitialValues({
                dias_plantao: showFormAgenda.agenda.map(a => ({
                    inicio: a.horario_inicio,
                    fim: a.horario_fim,
                    dia: weekDays.find(d => d.value === a.dia_semana),
                    tempo_atendimento: getMinutesFromTime(a.tempo_atendimento)
                }))
            })
        }
    }, [showFormAgenda])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSalvarAgenda}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <FieldArray 
                            name="dias_plantao"
                            render={arrayHelpers => (
                                <>
                                    <div className="actions-container">
                                        <Button className="transparent" onClick={() => { arrayHelpers.push('') }}>
                                            Adicionar dia de atendimento
                                            <FiPlus />
                                        </Button>
                                    </div>

                                    {values.dias_plantao.map((_, index) => (
                                        <DiaAtendimentoContainer key={index}>
                                            <Select 
                                                label="Dia da semana"
                                                name={`dias_plantao.${index}.dia`}
                                                options={weekDays}
                                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                                style={{ gridArea: 'semana' }}
                                            />

                                            <Textbox 
                                                name={`dias_plantao.${index}.tempo_atendimento`}
                                                label="Tempo de atendimento em minutos"
                                                mask={maskUtils.timeInMinutes}
                                                style={{ gridArea: 'tempo' }}
                                            />

                                            <Textbox 
                                                name={`dias_plantao.${index}.inicio`}
                                                label="Início"
                                                mask={maskUtils.time}
                                                style={{ gridArea: 'inicio' }}
                                            />

                                            <Textbox 
                                                name={`dias_plantao.${index}.fim`}
                                                label="Término"
                                                mask={maskUtils.time}
                                                style={{ gridArea: 'fim' }}
                                            />

                                            <Button fab onClick={() => { arrayHelpers.remove(index) }} className="transparent remove">
                                                <FiTrash />
                                            </Button>
                                        </DiaAtendimentoContainer>
                                    ))}

                                    {(values.dias_plantao.length > 0 || initialValues.dias_plantao.length > 0) && (
                                        <div className="button-container">
                                            <Button className="white" type="submit">
                                                Salvar agenda
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
