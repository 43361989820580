import styled from 'styled-components'

export const Container = styled.article`
	form {
		display: grid;
		grid-template-areas:
              'f1 f2 f3'
              'f4 f5 f6'
			  'f7 f8 f8'
			  'f9 f10 f10'
              'bs bs bs';

		& > button {
			width: calc(100% - 16px);
			max-width: 200px;
			justify-self: end;
			margin-top: 20px !important;
		}

		@media(max-width: 900px) {
			grid-template-areas:
              'f1 f2'
              'f3 f4'
			  'f5 f6'
			  'f7 f8'
			  'f9 f10'
			  'f11 f11'
              'bs bs';
		}

		@media(max-width: 600px) {
			grid-template-areas:
              'f1'
              'f2'
			  'f3'
			  'f4'
			  'f5'
			  'f6'
			  'f7'
			  'f8'
			  'f9'
			  'f10'
			  'f11'
			  'bs';

			.field.checkbox {
				grid-column: unset;
			}

			& > button {
				max-width: unset;
			}
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`
