import styled from 'styled-components'

import { Container as Card } from '../../../components/Card/styles'

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`

export const AvatarContainer = styled.div.attrs({
    className: 'animated zoomIn faster'
})`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
`

export const Container = styled.article`
	min-height: 80vh;
`

export const Content = styled(Card)`
	display: flex;
	flex-direction: column;
	padding: 35px;
	margin: 50px 100px;

	@media(max-width: 900px) {
		padding: 20px;
		margin: 36px 40px;
	}

	@media(max-width: 600px) {
		padding: 20px;
		margin: 24px 20px;
	}

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		
		h2 {
			grid-column: 1 / 4;
			font-size: 16px;
			font-weight: 400;
			min-height: 60px;
			display: flex;
			align-items: center;

			@media(max-width: 600px) {
				margin: 0 10px;
			}
		}

		${ButtonContainer} {
			margin-top: 20px;

			@media(max-width: 600px) {
				button {
					width: 100%;
				}
			}
		}

		@media(max-width: 900px) {
			div.field-container:first-child {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto auto auto auto auto;
			}

			div.field-container {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto auto auto auto;
			}
		}
	}
`

export const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
`

export const PasswordContainer = styled.div`
	display: grid;
	grid-template-rows: 50px 102px;
	grid-template-areas:
		'title title title'
		'f1 f2 f3'
		'btn btn btn';
	column-gap: 20px;
	justify-items: center;
	background-color: #00000022;
	border-radius: 16px;
	padding: 16px 24px 20px;

	h2 {
		grid-area: title;
	}

	${ButtonContainer} {
		grid-area: btn;
	}

	.field.textbox {
		width: 100%;
	}

	@media(max-width: 700px) {
		display: block;

		.field.textbox {
			width: calc(100% - 16px);
		}
	}
`

export const EmailFuncionalContainer = styled.div`
	display: grid;
	background-color: #00000022;
	border-radius: 16px;
	padding: 16px 24px 20px;
	justify-items: center;
	column-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas:
		'title title title'
		'f1 f1 f2'
		'btn btn btn';
	margin-bottom: 14px;

	h2 {
		grid-area: title;
	}

	.field.textbox {
		grid-area: f1;
	}

	${ButtonContainer} {
		grid-area: f2;
		justify-self: flex-start;
	}

	@media(max-width: 700px) {
		display: block;

		.field.textbox {
			width: calc(100% - 16px);
		}

		${ButtonContainer} {
			grid-area: btn;
		}
	}
`

export const NotificacaoPermissaoContainer = styled.div.attrs({
    className: 'notificacao-container'
})`
	display: none;
	grid-template-rows: 60px auto 36px auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas:
		'title title title'
		'detalhes detalhes detalhes'
		'f1 f2 f3'
		'd1 d2 d3';
	column-gap: 20px;
	justify-items: center;
	background-color: #00000022;
	border-radius: 16px;
	padding: 16px 24px 20px;
	margin-top: 24px;	

	h2 {
		grid-area: title;
		font-size: 16px;
		font-weight: 400;
		margin-top: 20px;
	}

	.checkbox {
		margin-top: 0px;
	}

	.detalhes {
		grid-area: detalhes;
		line-height: 24px;
		padding-bottom: 20px;
	}

	span {
		position: absolute;
		padding: 8px 10px;
		background-color: #fff;
		color: #424242;
		border-radius: 10px;
	}

	@media(max-width: 700px) {
		display: block;
	}
`
