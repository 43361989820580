import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'
import * as icons from 'react-icons/fa'

import { Button, Textbox, Select } from '../../../../components/Form'
import Table from '../../../../components/Table'

import { api, authHeaders } from '../../../../services/api'

import formUtils from '../../../../util/form'
import scrollUtils from '../../../../util/scroll'

import { PermissoesContext } from '../../../../contexts/PermissoesContext'

import { Container } from './styles'
import { TipContext } from '../../../../contexts/TipContext'

const baseInitialValues = {
    descricao: '',
    icone: null
}

export default function () {
    const { gruposPermissao, reload } = useContext(PermissoesContext)
    const { tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [editId, setEditId] = useState(null)

    async function handleAdd(values) {
        await api.post('grupo_permissao', values, authHeaders())

        toast.success('Grupo cadastrado com sucesso.')
    }

    async function handleEdit(values) {
        await api.put(`grupo_permissao/${values.id}`, values, authHeaders())

        setEditId(null)

        toast.success('Grupo editado com sucesso.')
    }

    async function handleSubmit(values, { resetForm }) {
        values = formUtils.extractFormValues(values)

        try {
            if (editId) {
                await handleEdit(values)
            } else {
                await handleAdd(values)
            }

            reload()

            resetForm()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(id) {
        try {
            await api.delete(`grupo_permissao/${id}`, authHeaders())

            toast.success('Grupo excluído com sucesso.')

            reload()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handlePrepareEdit(id) {
        setEditId(id)

        scrollUtils.toTop()
    }

    useEffect(() => {
        if (editId) {
            const grupo = gruposPermissao.find(g => g.id === editId)

            setInitialValues({
                ...grupo,
                icone: {
                    value: grupo.icone,
                    label: grupo.icone
                }
            })
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [editId])

    return (
        <Container>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize
            >
                {({ setFieldValue }) => (
                    <Form>
                        <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                        <div>
                            <Textbox
                                name="descricao"
                                label="Nome"
                            />

                            <Select 
                                label="Ícone"
                                name="icone"
                                options={Object.keys(icons)?.map(icon => ({
                                    label: icon,
                                    value: icon
                                })) || []}
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            />

                            <Button type="submit" className="transparent">
                                {editId ? 'Salvar alterações' : 'Cadastrar grupo'}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

            <Table
                headers={[
                    { name: 'descricao', value: 'Grupo' }
                ]}
                data={gruposPermissao || []}
                caption="Grupos cadastrados"
                handleDelete={handleDelete}
                handlePrepareEdit={handlePrepareEdit}
            />
        </Container>
    )
}
