import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		.card {
			min-height: 70vh;
			
			.button-container {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-top: 36px;
			}
		}
	}
`
