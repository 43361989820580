import styled from 'styled-components'

export const Container = styled.article`
	min-height: 40vh;

	form {
		display: grid;
		margin-top: 25px;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 
			'title title'
			'f1 f2'
			'f3 f4'
			'f5 f6'
			'f7 f8'
			'f9 f10'
			'f11 f12'
			'spinner spinner'
			'button button';

		@media(max-width: 1000px) {
			grid-template-columns: 1fr;
			grid-template-areas: 
				'title'
				'f1'
				'f2'
				'f3'
				'f4'
				'f5'
				'f6'
				'f7'
				'f8'
				'f9'
				'f10'
				'f11'
				'f12'
				'spinner'
				'button';
		}

		.spinner-container {
			grid-area: spinner;
			margin-top: 20px;
		}

		& > button {
			width: calc(100% - 16px);
			max-width: 200px;
			justify-self: end;
			margin-top: 20px !important;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;
	}
`

export const ButtonContainer = styled.div`
	grid-area: button;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 10px;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`
