import React, { useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { format, lastDayOfMonth } from 'date-fns'

import { OrcamentoContext } from '../../../../../contexts/OrcamentosContext'

import Spinner from '../../../../../components/Spinner'
import { Textbox, Button } from '../../../../../components/Form'

import maskUtils from '../../../../../util/masks'
import {
    isBRDate, convertBRDateToUSDate, convertUSDateToBRDate, firstDayOfMonth
} from '../../../../../util/date'

import { Container, FiltroPeriodoContainer, BorderCard } from './styles'

export default function () {
    const {
        saldoGeralContasCorrentes,
        contasCorrentes,
        contasCorrentesPeriodo,
        receitaGeralContasCorrentes,
        despesaGeralContasCorrentes,
        updateSaldosContasCorrentesPorData,
        updateReceitasEDespesasContasCorrentesPorData
    } = useContext(OrcamentoContext)

    const [dataSaldo, setDataSaldo] = useState(format(new Date(), 'dd/MM/yyyy'))
    const [dataSaldoCompleta, setDataSaldoCompleta] = useState(true)
    const [periodo, setPeriodo] = useState({
        inicio: format(firstDayOfMonth(new Date()), 'yyyy-MM-dd'),
        fim: format(lastDayOfMonth(new Date()), 'yyyy-MM-dd')
    })

    useEffect(() => {
        if (isBRDate(dataSaldo)) {
            updateSaldosContasCorrentesPorData(undefined, convertBRDateToUSDate(dataSaldo))
            setDataSaldoCompleta(true)
        } else {
            setDataSaldoCompleta(false)
        }
    }, [dataSaldo])

    async function handlePesquisarReceitasEDespesasValores() {
        try {
            if (periodo.fim < periodo.inicio) {
                toast.warn('A data de início deve ser anterior à data de fim da pesquisa.')
                return
            }

            updateReceitasEDespesasContasCorrentesPorData(periodo.inicio, periodo.fim)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <BorderCard>
                <h1>Saldos por conta corrente</h1>

                <Textbox
                    withoutForm
                    label="Saldo até a data"
                    mask={maskUtils.date}
                    value={dataSaldo}
                    onChange={e => { setDataSaldo(e.target.value) }}
                />

                {!dataSaldoCompleta ? (
                    <p className="placeholder">
                        Digite a data completa para buscar os saldos.
                    </p>
                ) : !!contasCorrentes?.length && !!saldoGeralContasCorrentes ? (
                    <>
                        <div className="total">
                            <h1>
                                {`SALDO GERAL EM ${dataSaldo}: `}
                                {dataSaldoCompleta ? (
                                    <span className={saldoGeralContasCorrentes < 0 ? 'negativo' : 'positivo'}>
                                        {maskUtils.maskApply.currency(saldoGeralContasCorrentes)}
                                    </span>
                                ) : '-'}
                            </h1>
                        </div>

                        {contasCorrentes.map(c => (
                            <div key={c.id}>
                                <h1>{c.dados_bancarios.instituicao.nome_fantasia}</h1>
                                <h2>{`${c.dados_bancarios.agencia ? `Ag.: ${c.dados_bancarios.agencia}` : ''} ${c.dados_bancarios.agencia ? '-' : ''} Conta: ${c.dados_bancarios.conta}`}</h2>
                                <h3>{maskUtils.maskApply.currency(c.saldo)}</h3>
                            </div>
                        ))}
                    </>
                ) : (
                    <Spinner />
                )}
            </BorderCard>

            <BorderCard>
                <h1>Receitas e despesas por conta corrente</h1>

                <FiltroPeriodoContainer>
                    <Textbox
                        withoutForm
                        label="Data inicial"
                        mask={maskUtils.date}
                        onChange={e => {
                            setPeriodo({
                                ...periodo,
                                inicio: convertBRDateToUSDate(e.target.value)
                            })
                        }}
                        value={convertUSDateToBRDate(periodo.inicio)}
                    />

                    <Textbox
                        withoutForm
                        label="Data final"
                        mask={maskUtils.date}
                        onChange={e => {
                            setPeriodo({
                                ...periodo,
                                fim: convertBRDateToUSDate(e.target.value)
                            })
                        }}
                        value={convertUSDateToBRDate(periodo.fim)}
                    />

                    <Button className="transparent" onClick={handlePesquisarReceitasEDespesasValores}>
                        Pesquisar
                    </Button>
                </FiltroPeriodoContainer>

                {contasCorrentesPeriodo?.length ? (
                    <>
                        <div className="total">
                            <h1>
                                {'RECEITAS DO PERÍODO: '}
                                <span>{maskUtils.maskApply.currency(receitaGeralContasCorrentes)}</span>
                            </h1>
                        </div>

                        {contasCorrentesPeriodo.map(c => (
                            <div key={c.id}>
                                <h1>{c.dados_bancarios.instituicao.nome_fantasia}</h1>
                                <h2>{`${c.dados_bancarios.agencia ? `Ag.: ${c.dados_bancarios.agencia}` : ''} ${c.dados_bancarios.agencia ? '-' : ''} Conta: ${c.dados_bancarios.conta}`}</h2>
                                <h3>{maskUtils.maskApply.currency(c.receita)}</h3>
                            </div>
                        ))}

                        <div className="total">
                            <h1>
                                {'DESPESAS DO PERÍODO: '}
                                <span>{maskUtils.maskApply.currency(despesaGeralContasCorrentes)}</span>
                            </h1>
                        </div>

                        {contasCorrentesPeriodo.map(c => (
                            <div key={c.id}>
                                <h1>{c.dados_bancarios.instituicao.nome_fantasia}</h1>
                                <h2>{`${c.dados_bancarios.agencia ? `Ag.: ${c.dados_bancarios.agencia}` : ''} ${c.dados_bancarios.agencia ? '-' : ''} Conta: ${c.dados_bancarios.conta}`}</h2>
                                <h3>{maskUtils.maskApply.currency(c.despesa)}</h3>
                            </div>
                        ))}
                    </>
                ) : (
                    <Spinner />
                )}
            </BorderCard>
        </Container>
    )
}
