import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { forceDownloadFileFromURL } from '../../../../../util/file'
import formUtils from '../../../../../util/form'

import {
    Button, Select, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeMonthGif from '../../../../../assets/images/gifs/periodo-mes.gif'

const initialValues = {
    competencia: '',
    status: null,
    tipo_cobranca: null
}

const validation = Yup.object().shape({
    competencia: Yup.string().ensure().required('Informe o período de competência.'),
    status: Yup.string().ensure().nullable(),
    tipo_cobranca: Yup.string().ensure().nullable()
})

const nomeRelatorio = 'Relatório de Faturas Avulsas'

export default function (props) {
    const [tiposCobranca, setTiposCobranca] = useState([])

    async function loadTiposCobranca() {
        const response = await api.get('tipo_cobranca', authHeaders())

        setTiposCobranca(response.map(t => ({
            label: t.descricao,
            value: t.id
        })))
    }

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        values = formUtils.extractFormValues(values)

        try {
            const response = await api.post('relatorio/faturasAvulsasPlanoSaude', values, authHeaders())

            forceDownloadFileFromURL(response.file, nomeRelatorio)

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])
    
    useEffect(() => {
        loadTiposCobranca()
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="competencia"
                            dateFormat="mm/yy"
                            view="month"
                            label="Competência"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f1' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeMonthGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="tipo_cobranca"
                            label="Tipo de cobrança" 
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={tiposCobranca}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="status"
                            label="Status"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Efetuadas', value: 1 },
                                { label: 'Pendentes', value: 0 }
                            ]}
                            containerStyle={{ gridArea: 'f3' }}
                        />
                        
                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
