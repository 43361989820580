import React, { useContext, useEffect, useState } from 'react'
import { FiPlus, FiEye } from 'react-icons/fi'

import { api } from '../../../../services/api'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import { Button } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'

import { ConvenioContext } from '../../../../contexts/ConvenioContext'

import ConvenioAssociadoCadastro from '../Cadastro'
import ConvenioAssociadoDetalhes from '../Detalhes'

import { Container } from './styles'

export default function () {
    const {
        servicosContratados,
        reload,
        convenioStatus,
        refreshDetalhes,
        titular,
        loadTitular
    } = useContext(ConvenioContext)

    const [items, setItems] = useState(null)
    const [editItem, setEditItem] = useState(null)
    const [modoCadastro, setModoCadastro] = useState(false)
    const [detalhesConvenioAssociado, setDetalhesConvenioAssociado] = useState(null)
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [convenioAdicionais, setConvenioAdicionais] = useState([])

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento')

        setTiposDocumento(response)
    }

    async function loadConvenioAdicionais() {
        const response = await api.get('convenio_adicional')

        setConvenioAdicionais(response)
    }

    useEffect(() => {
        loadTiposDocumento()

        loadConvenioAdicionais()
    }, [])

    useEffect(() => {
        if (servicosContratados) {
            setItems(servicosContratados)
        }
    }, [servicosContratados])

    async function refreshDetalhesConvenio(id) {
        const normalizado = await refreshDetalhes(id)

        setDetalhesConvenioAssociado(normalizado)

        reload()
    }

    useEffect(() => {
        if (tiposDocumento.length && convenioStatus.length && convenioAdicionais) {
            reload()
        }
    }, [tiposDocumento, convenioStatus, convenioAdicionais])

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Serviços contratados</h1>

                        {items !== null ? (
                            <>
                                <Button className="transparent button-cadastrar" onClick={() => setModoCadastro(true)}>
                                    Aderir a um novo serviço
                                    <FiPlus />
                                </Button>

                                <Table
                                    headers={[
                                        { name: 'name', value: 'Nome' },
                                        { name: 'tipo_desconto', value: 'Tipo de desconto', centered: true },
                                        { name: 'area_atuacao', value: 'Área de atuação', centered: true },
                                        { name: 'data_adesao', value: 'Data de adesão ao serviço', centered: true },
                                        { name: 'quantidade_dependentes', value: 'Dependentes incluídos', centered: true },
                                        { name: 'status_descricao', value: 'Status', centered: true }
                                    ]}
                                    data={items.map(item => ({
                                        ...item,
                                        rowClass: item.status_periodo_renovacao_matricula_academico ? 'blink' : ''
                                    }))}
                                    actions={[
                                        {
                                            action: setDetalhesConvenioAssociado,
                                            name: 'Ver detalhes',
                                            icon: FiEye,
                                            iconSize: 16
                                        }
                                    ]}
                                    hideOnSmallHeaders={['tipo_desconto', 'area_atuacao', 'quantidade_dependentes']}
                                    hideHeadersOffset={950}
                                    hideOffset={600}
                                />
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!editItem || modoCadastro}
                handleClose={() => {
                    setEditItem(null)
                    setModoCadastro(false)
                }}
                title={`${modoCadastro ? 'Serviços disponíveis' : 'Editar serviço'}`}
                closeOnOverlayClick={false}
            >
                <ConvenioAssociadoCadastro
                    convenio={editItem}
                    reload={() => {
                        setEditItem(null)
                        setModoCadastro(false)
                        reload()
                    }}
                    edicao={!!editItem}
                />
            </Modal>

            <Modal
                isOpen={!!detalhesConvenioAssociado}
                handleClose={() => {
                    setDetalhesConvenioAssociado(null)
                }}
                title={`Detalhes do ${detalhesConvenioAssociado?.tipo_convenio === 'servico' ? 'Serviço' : 'Parceiro'}`}
            >
                <ConvenioAssociadoDetalhes
                    detalhes={detalhesConvenioAssociado}
                    reload={(cancelamento = false, id) => {
                        if (cancelamento) {
                            setDetalhesConvenioAssociado(null)
                            reload()
                        } else {
                            refreshDetalhesConvenio(id)
                            loadTitular()
                        }
                    }}
                    refreshDetalhes={refreshDetalhesConvenio}
                    titular={titular}
                />
            </Modal>
        </>
    )
}
