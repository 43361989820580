import React, { useContext, useEffect, useState } from 'react'
import { FiArrowRight, FiUpload } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Table from '../../../../../components/Table'
import { Select, Button, Checkbox } from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'
import Modal from '../../../../../components/Modal'

import { api, authHeaders } from '../../../../../services/api'

import { Container, EventDetails, ButtonContainer } from './styles'

import { EventoContext } from '../../../../../contexts/EventoContext'
import { GlobalContext } from '../../../../../contexts/GlobalContext'
import Carga from './Carga'

export default function () {
    const { evento, reload, reloadEvento } = useContext(EventoContext)
    const { user } = useContext(GlobalContext)

    const [filtros, setFiltros] = useState(null)
    const [filtroSelecionado, setFiltroSelecionado] = useState(null)
    const [pessoas, setPessoas] = useState(null)
    const [convidados, setConvidados] = useState([])
    const [enviandoConvites, setEnviandoConvites] = useState(false)
    const [showModalCargaPlanilha, setShowModalCargaPlanilha] = useState(false)
    const [pessoasConfirmadas, setPessoasConfirmadas] = useState(null)

    async function loadVisoes() {
        try {
            let response = await api.get('visao', authHeaders())

            setFiltros(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if (user) {
            loadVisoes()
        }
    }, [user])

    function getIcon(pessoa) {
        if (!pessoa || (!pessoa.data_hora_confirmacao_presenca && !pessoa.data_hora_confirmacao_ausencia)) {
            return {
                presenca_confirmada: null,
                iconColor: null
            }
        }

        if (pessoa.data_hora_confirmacao_presenca) {
            return {
                presenca_confirmada: 'FaCheck',
                iconColor: '#4caf50'
            }
        }

        return {
            presenca_confirmada: 'FaTimes',
            iconColor: '#f44336'
        }
    }

    async function loadPessoas() {
        const response = await api.get(`visao/${filtroSelecionado.value}`, authHeaders())

        setPessoas(
            response.pessoas.map(pessoa => ({
                id: pessoa.id,
                identificador: pessoa.medico
                    ? pessoa.crm
                    : pessoa.cpf_cnpj || '',
                nome: pessoa.nome,
                confirmada: evento.participantes.filter(p => !!p.data_hora_confirmacao_presenca).map(p => p.id).includes(pessoa.id),
                ...getIcon(evento.participantes.find(p => p.id === pessoa.id))
            }))
        )
    }

    useEffect(() => {
        if (filtroSelecionado) {
            setPessoas(null)

            loadPessoas()
        }
    }, [filtroSelecionado])

    useEffect(() => {
        if (evento) {
            setPessoas(evento.participantes.map(pessoa => {
                let documento = pessoa.documentos?.find(doc => doc.tipo_documento.id === 3)

                if (!documento) {
                    documento = pessoa.documentos?.find(doc => doc.tipo_documento.id === 2)
                }

                return {
                    id: pessoa.id,
                    identificador: documento?.identificador || '-',
                    nome: pessoa.nome,
                    confirmada: !!pessoa.data_hora_confirmacao_presenca,
                    ...getIcon(pessoa)
                }
            }))

            setConvidados(evento.participantes.map(p => p.id))
        }
    }, [evento])

    async function handleEnviarConvites() {
        try {
            setEnviandoConvites(true)

            await api.post(`evento/${evento.id}/convidar`, { pessoas: convidados }, authHeaders())

            toast.success('Os convites foram enviados com sucesso.')

            reload()
        } catch (e) {
            toast.error('Ocorreu um erro ao enviar os convites para o evento.')
        } finally {
            setEnviandoConvites(false)
        }
    }

    function handleChangeMostrarConfirmados(e) {
        if (e.target.checked) {
            setPessoasConfirmadas(pessoas.filter(p => p.confirmada))
        } else {
            setPessoasConfirmadas(null)
        }
    }

    if (!evento) {
        return null
    }

    return (
        <>
            <Container>
                <EventDetails>
                    <h1>{`Evento: ${evento.name}`}</h1>
                    <h2>{`Local: ${evento.nome_local}`}</h2>
                    <h2>{`Início: ${evento.inicio}`}</h2>
                </EventDetails>

                {filtros ? (
                    <Select
                        label="Filtros"
                        options={filtros.map(filtro => ({
                            label: filtro.descricao,
                            value: filtro.id
                        }))}
                        withoutForm
                        onChange={selected => { setFiltroSelecionado(selected) }}
                        value={filtroSelecionado}
                    />
                ) : (
                    <Spinner />
                )}

                <p>ou</p>

                <Button onClick={() => { setShowModalCargaPlanilha(true) }} className="transparent">
                    Carregue uma planilha
                    <FiUpload size={18} />
                </Button>

                <ButtonContainer>
                    {filtros && (
                        <Button
                            onClick={handleEnviarConvites}
                            className="white"
                            loading={enviandoConvites}
                            loadingText="Enviando..."
                        >
                            Enviar convites
                            <FiArrowRight size={18} />
                        </Button>
                    )}
                </ButtonContainer>

                <Checkbox
                    withoutForm
                    label="Mostrar apenas presenças confirmadas"
                    handleChange={handleChangeMostrarConfirmados}
                />

                {pessoas ? (
                    <Table
                        headers={[
                            {
                                name: 'identificador', value: 'CRM/CPF', centered: true, style: { width: 160 }
                            },
                            { name: 'nome', value: 'Nome' },
                            {
                                name: 'presenca_confirmada', value: 'Presença confirmada?', icon: true, style: { width: 190 }
                            }
                        ]}
                        data={pessoasConfirmadas || pessoas || []}
                        filterable
                        tableId="convite-table"
                        checkable
                        checkedItems={convidados}
                        onCheckItem={item => {
                            setConvidados(old => [
                                ...old,
                                item.id
                            ])
                        }}
                        onUncheckItem={item => { setConvidados(convidados.filter(c => c !== item.id)) }}
                        toggleCheckAll={isChecked => {
                            if (isChecked) {
                                setConvidados(pessoas.map(p => p.id))
                            } else {
                                setConvidados([])
                            }
                        }}
                    />
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={showModalCargaPlanilha}
                handleClose={() => { setShowModalCargaPlanilha(false) }}
                title="Carga de convidados para o evento"
            >
                <Carga
                    reload={async () => {
                        setShowModalCargaPlanilha(false)

                        await reloadEvento()
                    }}
                />
            </Modal>
        </>
    )
}
