import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		form {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-template-areas:
				'tip tip tip tip'
				'f1 f2 f3 x'
				'f5 f5 f6 f4'
				'desc desc desc desc'
				'badge badge button button'
				'progress progress progress progress'
				'inconsistencias inconsistencias inconsistencias y';

			> button {
				justify-self: flex-end;
				grid-column-start: 4;
			}

			.progress-bar {
				grid-area: progress;
			}

			.badge-spinner {
				grid-area: badge;
				
				label {
					font-size: 12px;
				}
			}
		}

		@media(max-width: 440px) {
			padding: 30px 0;
		}
	}
`

export const RegrasDescontoContainer = styled.div.attrs({
	className: 'descontos-container'
})`
	grid-column: 1/span 4;
	max-width: 700px;

	h1 {
		font-size: 18px;
		margin: 16px 8px 4px !important;
	}
`

export const RegraDesconto = styled.div.attrs({
	className: 'desconto-item animated fadeInLeft faster'
})`
	display: grid;
	grid-template-columns: 1fr 1fr 40px;
	align-items: flex-end;
	grid-gap: 12px;

	button {
		width: 34px;
		height: 34px;
		margin-bottom: 12px;
	}
`

export const Badge = styled.div`
	padding: 8px 10px;
	background-color: #ffeb3b;
	color: #424242;
	border-radius: 8px;
	grid-area: badge;
	height: fit-content;
	text-align: center;
	align-self: center;
	width: calc(100% - 16px);
	margin: 0 8px;
`
