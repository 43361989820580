import React, { createContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import scrollUtils from '../util/scroll'
import stringUtils from '../util/string'

import { api, authHeaders } from '../services/api'

import { downloadRelatorioJasper, forceDownloadFileFromURL } from '../util/file'

export const RelatorioCustomizadoContext = createContext()

const baseInitialValues = {
    descricao: '',
    com_totalizacao: false,
    filtros: {},
    exibicoes: {},
    ordenacao: null,
    quebra: null,
    instituicao: null,
    cidade: null
}

function RelatorioCustomizadoProvider({ children }) {
    const [relatorios, setRelatorios] = useState(null)
    const [relatorioEdicao, setRelatorioEdicao] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function loadRelatorios() {
        setRelatorios(null)

        const response = await api.get('relatorio_customizado', authHeaders())

        setRelatorios(response)
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            const {
                descricao, com_totalizacao, filtros, exibicoes, ordenacao, quebra, instituicao, cidade
            } = values

            if (!Object.keys(filtros).length || !Object.keys(exibicoes).length) {
                toast.warn('Selecione pelo menos um filtro e um grupo de dados.')
                return
            }

            const body = {
                descricao,
                com_totalizacao,
                ordenacao: ordenacao?.value?.toLowerCase(),
                quebra: quebra?.value?.toLowerCase(),
                filtros: Object.entries(filtros)
                    // eslint-disable-next-line no-unused-vars
                    .filter(([_, value]) => !!value)
                    .reduce((result, [key]) => [
                        ...result,
                        key
                    ], []),
                exibicoes: Object.entries(exibicoes)
                    // eslint-disable-next-line no-unused-vars
                    .filter(([_, value]) => !!value)
                    .reduce((result, [key]) => [
                        ...result,
                        key
                    ], []),
                instituicao: instituicao?.value,
                cidade: cidade?.value?.toUpperCase()
            }

            if (relatorioEdicao) {
                await api.put(`relatorio_customizado/${relatorioEdicao.id}`, body, authHeaders())

                toast.success('Relatório atualizado.')
            } else {
                await api.post('relatorio_customizado', body, authHeaders())

                toast.success('Relatório cadastrado.')
            }

            setInitialValues(baseInitialValues)

            resetForm()

            loadRelatorios()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function handlePrepareEdit(id) {
        const rel = relatorios.find(r => r.id === id)

        setRelatorioEdicao(rel)

        scrollUtils.toTop()
    }

    async function handleDelete(id) {
        try {
            await api.delete(`relatorio_customizado/${id}`, authHeaders())

            toast.success('Relatório removido.')

            return true
        } catch (e) {
            toast.error(e.msg)
            return false
        }
    }

    async function handleGerarRelatorio(item) {
        const response = await api.get(`relatorio_customizado/${item.id}`, authHeaders())

        forceDownloadFileFromURL(response.link, item.descricao)
    }

    async function handleGerarRelatorioPlanilha(item) {
        try {
            const response = await api.get(`relatorio_customizado/${item.id}/planilha`, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, item.descricao, 'xlsx')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadRelatorios()
    }, [])

    useEffect(() => {
        if (relatorioEdicao) {
            setInitialValues({
                descricao: relatorioEdicao.descricao,
                com_totalizacao: !!relatorioEdicao.total,
                ordenacao: {
                    label: stringUtils.capitalize(relatorioEdicao.ordenacao),
                    value: relatorioEdicao.ordenacao
                },
                quebra: {
                    label: stringUtils.capitalize(relatorioEdicao.quebra),
                    value: relatorioEdicao.quebra
                },
                filtros: relatorioEdicao.filtros.reduce((result, current) => ({
                    ...result,
                    [current.id]: true
                }), {}),
                instituicao: relatorioEdicao.filtro_instituicao ? {
                    label: `${relatorioEdicao.filtro_instituicao.nome_fantasia || relatorioEdicao.filtro_instituicao.razao_social}${relatorioEdicao.filtro_instituicao.sigla ? ` (${relatorioEdicao.filtro_instituicao.sigla})` : ''}`,
                    value: relatorioEdicao.filtro_instituicao.id
                } : null,
                cidade: relatorioEdicao.filtro_cidade ? {
                    label: relatorioEdicao.filtro_cidade,
                    value: relatorioEdicao.filtro_cidade
                } : null,
                exibicoes: relatorioEdicao.colunas.reduce((result, current) => ({
                    ...result,
                    [current.id]: true
                }), {})
            })
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [relatorioEdicao])

    return (
        <RelatorioCustomizadoContext.Provider
            value={{
                relatorios,
                handleSubmit,
                handleDelete,
                handlePrepareEdit,
                initialValues,
                relatorioEdicao,
                handleGerarRelatorio,
                handleGerarRelatorioPlanilha
            }}
        >
            {children}
        </RelatorioCustomizadoContext.Provider>
    )
}

export default RelatorioCustomizadoProvider
