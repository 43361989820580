import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;
		display: flex;
		flex-direction: column;

		form {
			display: grid;
			grid-template-columns: 1fr 2fr 1fr;
			align-items: end;

			h1 {
				grid-column: 1/span 3;
				margin: 0 8px 12px;
			}

			button {
				width: fit-content;
				margin-top: 12px;
			}

			@media(max-width: 800px) {
				grid-template-columns: 1fr 1fr;

				h1 {
					grid-column: 1/span 2;
				}
			}

			@media(max-width: 600px) {
				display: flex;
				flex-direction: column;
				align-items: unset;

				button {
					width: calc(100% - 16px);
				}
			}
		}

		@media(max-width: 440px) {
			padding: 0;
		}
	}
`
