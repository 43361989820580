import React, {
    createContext, useState, useEffect, useContext
} from 'react'
import { format } from 'date-fns'

import { getAge, isBetweenDateTimes } from '../util/date'
import maskUtils from '../util/masks'

import { api, authHeaders } from '../services/api'

import { GlobalContext } from './GlobalContext'

export const ConvenioContext = createContext()

function ConvenioProvider({ children }) {
    const { user } = useContext(GlobalContext)

    const [servicosDisponiveis, setServicosDisponiveis] = useState(null)
    const [servicosContratados, setServicosContratados] = useState(null)
    const [convenioStatus, setConvenioStatus] = useState([])
    const [titular, setTitular] = useState(null)
    const [showFormAtualizacaoComprovanteMatricula, setShowFormAtualizacaoComprovanteMatricula] = useState(false)

    function normalize(data) {
        const normalizados = data
            .map(item => {
                const { convenio } = item

                return {
                    id: convenio.id,
                    adesao_id: item.id,
                    name: convenio.empresa.nome,
                    tipo_desconto: convenio.tipo_desconto === 'tabelado' ? 'Tabelado' : 'Fixo',
                    tipo_convenio: convenio.tipo || '',
                    area_atuacao: convenio.convenio_area_atuacao.descricao,
                    data_adesao: format(new Date(item.createdAt), 'dd/MM/yyyy'),
                    inicio_vigencia: item.inicio_vigencia ? format(new Date(item.inicio_vigencia), 'dd/MM/yyyy') : null,
                    quantidade_dependentes: item.dependentes?.length || 0,
                    descontos: convenio.descontos,
                    criterios: convenio.criterios_names,
                    descricao: convenio.descricao,
                    favorecido: convenio.favorecido ? convenio.favorecido.split(',').map(f => f).join(', ') : 'Médicos e acadêmicos',
                    portabilidade: item.portabilidade ? item.portabilidade === 1 ? 'UNIMED Belém para UNIMED Belém' : 'Outras operadoras ou outras UNIMEDs' : null,
                    taxa_administrativa: convenio.taxa_administrativa,
                    taxa_bancaria: convenio.taxa_bancaria,
                    adicionais: item.adicionais?.map(adc => ({
                        id: adc.adicional.id,
                        descricao: adc.adicional.descricao || '',
                        valor: maskUtils.maskApply.currency(adc.adicional.valor),
                        beneficiario: adc.dependente || adc.associado,
                        titular: !adc.dependente,
                        status: !!adc.status.id
                    })) || [],
                    dependentes: item.dependentes?.map(({ id, dependente, status }) => ({
                        id,
                        pessoa: dependente.pessoa,
                        dependente_id: dependente.id,
                        nome: dependente.pessoa.nome,
                        parentesco: dependente.grau_parentesco.descricao,
                        data_nascimento: `${format(new Date(dependente.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(dependente.pessoa.data_nascimento)} anos)`,
                        sexo: dependente.pessoa.sexo,
                        documentos: dependente.pessoa.documentos,
                        status,
                        status_descricao: status.descricao,
                        idade: getAge(dependente.pessoa.data_nascimento)
                    })) || [],
                    checkbox: {
                        disabled: true
                    },
                    contribuicoes_pendentes_quantidade: item.contribuicoes_pendentes_quantidade || 0,
                    status_descricao: convenioStatus.find(status => status.id === item.status)?.descricao || '',
                    status: convenioStatus.find(status => status.id === item.status),
                    justificativa: item.justificativa_reprovacao,

                    validade: item.ultima_matricula_academico?.validade || null,
                    data_antecedencia_para_solicitacao_de_renovacao_academico: item.data_antecedencia_para_solicitacao_de_renovacao_academico || null,
                    status_periodo_renovacao_matricula_academico: item.data_antecedencia_para_solicitacao_de_renovacao_academico
                        ? isBetweenDateTimes(
                            new Date(),
                            new Date(item.data_antecedencia_para_solicitacao_de_renovacao_academico),
                            new Date(item.ultima_matricula_academico.validade)
                        ) : false
                }
            })

        return normalizados
    }

    async function loadConvenioStatus() {
        const response = await api.get('convenio_status', authHeaders())

        setConvenioStatus(response)
    }

    async function loadServicos() {
        let todos = await api.get('convenio', authHeaders())

        const contratados = await api.get('convenio_associado', {
            params: {
                associado_id: user.associado.id
            },
            ...authHeaders()
        })

        setServicosContratados(normalize(contratados))

        if (user.perfil.id !== 2 && !user.associado.aspirante) {
            todos = todos.filter(c => c.convenio_area_atuacao.id !== 1)
        }

        if (contratados.length) {
            const temPlanoSaude = contratados.some(c => c.convenio.convenio_area_atuacao.id === 1)

            if (temPlanoSaude) {
                setServicosDisponiveis(
                    todos.filter(c => !contratados.map(contratado => contratado.id).includes(c.id))
                        .filter(c => c.convenio_area_atuacao.id !== 1)
                )
            } else {
                setServicosDisponiveis(todos.filter(c => !contratados.map(contratado => contratado.id).includes(c.id)))
            }
        } else {
            setServicosDisponiveis(todos)
        }
    }

    async function loadTitular() {
        const response = await api.get(`pessoa/${user.pessoa.id}`, authHeaders())

        setTitular({
            ...response,
            data_nascimento: `${format(new Date(response.data_nascimento), 'dd/MM/yyyy')} (${getAge(response.data_nascimento)} anos)`,
            idade: getAge(response.data_nascimento)
        })
    }

    async function refreshDetalhes(id) {
        const response = await api.get('convenio_associado', {
            ...authHeaders(),
            params: {
                associado_id: user.associado.id,
                convenio_id: id
            }
        })

        const normalizado = normalize(response)[0]

        return normalizado
    }

    useEffect(() => {
        loadConvenioStatus()
    }, [])

    useEffect(() => {
        if (user && convenioStatus) {
            loadServicos()

            loadTitular()
        }
    }, [user, convenioStatus])

    return (
        <ConvenioContext.Provider
            value={{
                servicosDisponiveis,
                servicosContratados,
                reload: loadServicos,
                convenioStatus,
                refreshDetalhes,
                titular,
                loadTitular,
                showFormAtualizacaoComprovanteMatricula,
                setShowFormAtualizacaoComprovanteMatricula
            }}
        >
            {children}
        </ConvenioContext.Provider>
    )
}

export default ConvenioProvider
