import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'
import { format, differenceInMonths } from 'date-fns'
import { FiPlus, FiSearch, FiX } from 'react-icons/fi'

import {
    Button, Select, Textarea, Textbox
} from '../../../../components/Form'
import Modal from '../../../../components/Modal'

import Yup from '../../../../services/yup'

import { VisitaContext } from '../../../../contexts/VisitaContext'

import {
    Container, FormContainer, PessoaContainer, PessoaCadastroContainer, PlaceholderPessoa
} from './styles'

import { api, authHeaders } from '../../../../services/api'
import history from '../../../../services/history'

import avatarDefault from '../../../../assets/images/avatar.png'

import masks from '../../../../util/masks'
import { GlobalContext } from '../../../../contexts/GlobalContext'

import { loadTips } from '../../../../util/tip'

import { TipContext } from '../../../../contexts/TipContext'

const baseInitialValues = {
    motivo: null,
    detalhes: '',
    data_hora: '',
    data_hora_saida: '',
    tipo: null,
    nome: '',
    fone: '',
    email: ''
}

const baseValidation = Yup.object({
    motivo: Yup.string().ensure().required('Selecione o motivo do contato.'),
    detalhes: Yup.string().notRequired(),
    data_hora: Yup.string().required('Informe a data e hora de início.'),
    data_hora_saida: Yup.string().notRequired(),
    tipo: Yup.string().ensure().required('Selecione o tipo de contato.')
})

export default function () {
    const {
        setShowCadastro, showCadastro, visitaEdit, setVisitaEdit, motivos, handleSubmit
    } = useContext(VisitaContext)

    const { setCodigo, tips } = useContext(TipContext)

    const { user } = useContext(GlobalContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [resultadoBusca, setResultadoBusca] = useState(null)
    const [pessoa, setPessoa] = useState(null)
    const [showCadastroPessoa, setShowCadastroPessoa] = useState(false)
    const [validation, setValidation] = useState(baseValidation)

    function searchIsNumeric(search) {
        return /^\d+$/g.test(search.trim())
    }

    async function buscar(search) {
        try {
            if (!search) {
                setPessoa(null)
                return
            }

            const porCRM = searchIsNumeric(search)

            const response = await api.get('pessoa', {
                params: porCRM ? {
                    crm: search
                } : {
                    nome: search
                },
                ...authHeaders()
            })

            const listaParcial = response.filter(p => (p.fornecedor && response.filter(r => r.nome === p.nome).length === 1) || !p.fornecedor)

            if (listaParcial.length) {
                setShowCadastroPessoa(false)

                setResultadoBusca(listaParcial.slice(0, 8).map(r => ({
                    ...r,
                    crm: r.documentos.find(d => d.tipo_documento.id === 3)?.identificador,
                    nome: r.nome,
                    status_associacao: r.associado?.status_associacao?.descricao,
                    data_ultima_atualizacao: r.data_ultima_atualizacao && r.associado ? format(new Date(r.data_ultima_atualizacao), 'dd/MM/yyyy') : null,
                    alerta_atualizacao: !!r.associado
                })))

                setValidation(baseValidation)
            } else {
                setResultadoBusca([])

                setPessoa(null)
            }
        } catch (e) {
            toast.error('Erro ao buscar por nome.')
        }
    }

    const handleBuscar = debounce(buscar, 1000)

    function handleSelecionarPessoa(result) {
        setPessoa(result)

        document.querySelector('#nome-visitante').value = result.nome

        setResultadoBusca(null)
    }

    async function handleSubmitForm(values) {
        if (!showCadastroPessoa && !pessoa) {
            toast.warn('Busque uma pessoa pelo nome antes de cadastrar.')
            return
        }

        await handleSubmit({
            ...values,
            pessoa_id: pessoa?.id,
            atendente_id: user.pessoa.id
        })

        setShowCadastroPessoa(false)
        setPessoa(null)

        setInitialValues(baseInitialValues)
    }

    async function loadPessoa(pessoaId) {
        setShowCadastroPessoa(false)

        const response = await api.get('pessoa', {
            params: {
                id: pessoaId
            },
            ...authHeaders()
        })

        const visitante = response[0]

        setPessoa({
            ...visitante,
            crm: visitante.documentos.find(d => d.tipo_documento.id === 3)?.identificador,
            nome: visitante.nome,
            status_associacao: visitante.associado?.status_associacao?.descricao,
            data_ultima_atualizacao: visitante.data_ultima_atualizacao ? format(new Date(visitante.data_ultima_atualizacao), 'dd/MM/yyyy') : null,
            alerta_atualizacao: visitante.data_ultima_atualizacao ? differenceInMonths(new Date(), new Date(visitante.data_ultima_atualizacao)) > 2 : false
        })

        setValidation(baseValidation)

        const nomeVisitanteField = document.querySelector('#nome-visitante')

        if (nomeVisitanteField) {
            document.querySelector('#nome-visitante').value = visitante.nome
        }
    }

    useEffect(() => {
        if (visitaEdit) {
            loadPessoa(visitaEdit.pessoa.id)

            let tipoContato

            switch (visitaEdit.tipo) {
                case 'telefone':
                    tipoContato = 'Via telefone'
                    break
                case 'email':
                    tipoContato = 'Via e-mail'
                    break
                case 'whatsapp':
                    tipoContato = 'WhatsApp'
                    break
                default:
                    tipoContato = 'Presencial'
                    break
            }

            setInitialValues({
                ...visitaEdit,
                data_hora: format(new Date(visitaEdit.data_hora), 'dd/MM/yyyy HH:mm'),
                detalhes: visitaEdit.detalhes,
                motivo: {
                    label: visitaEdit.motivo.descricao,
                    value: visitaEdit.motivo.id
                },
                tipo: {
                    label: tipoContato,
                    value: visitaEdit.tipo
                }
            })

            setResultadoBusca(null)
        }
    }, [visitaEdit])

    useEffect(() => {
        if (showCadastroPessoa) {
            setValidation(validation.concat(
                Yup.object({
                    nome: Yup.string().required('Informe o nome do visitante'),
                    email: Yup.string().email('E-mail inválido'),
                    fone: Yup.string().mobile()
                })
            ))
        }
    }, [showCadastroPessoa])

    useEffect(() => {
        if (showCadastro) {
            setResultadoBusca(null)
        }
    }, [showCadastro])

    useEffect(() => {
        if (showCadastro) {
            loadTips(setCodigo, 'form_relacionamento_cadastro_visita')
        } else {
            loadTips(setCodigo, 'form_relacionamento_buscar_visita')
        }
    }, [showCadastro])

    return (
        <Container>
            <Button className="transparent" onClick={() => { setShowCadastro(true) }}>
                Registrar contato
                <FiPlus size={17} />
            </Button>

            <Modal
                isOpen={showCadastro || !!visitaEdit}
                handleClose={() => {
                    setShowCadastro(false)
                    setShowCadastroPessoa(false)
                    setVisitaEdit(null)
                    setPessoa(null)

                    setInitialValues(baseInitialValues)
                }}
                title={visitaEdit ? 'Alterar contato' : 'Registrar contato'}
            >
                <FormContainer>
                    <p>{tips?.formulario}</p>
                    <Formik
                        onSubmit={handleSubmitForm}
                        initialValues={{
                            ...initialValues,
                            data_hora: initialValues.data_hora
                                ? initialValues.data_hora.replace('h', '')
                                : format(new Date(), 'dd/MM/yyyy HH:mm')
                        }}
                        enableReinitialize
                        validationSchema={validation}
                    >
                        {({ setFieldValue, isSubmitting }) => (
                            <Form>
                                {showCadastroPessoa ? (
                                    <p>Faça o cadastro do visitante ao lado antes de registrar o atendimento.</p>
                                ) : (
                                    <Textbox
                                        withoutForm
                                        id="nome-visitante"
                                        label="Nome ou CRM"
                                        onChange={e => {
                                            handleBuscar(e.target.value)

                                            if (!searchIsNumeric(e.target.value)) {
                                                setFieldValue('nome', e.target.value.toUpperCase())
                                            }
                                        }}
                                        containerStyle={{ gridArea: 'nome' }}
                                        disabled={!!visitaEdit}
                                    />
                                )}

                                {!showCadastroPessoa ? (
                                    <Button
                                        className="transparent btn-cadastro"
                                        onClick={() => {
                                            setShowCadastroPessoa(old => !old)

                                            setPessoa(null)

                                            setFieldValue('nome', '')
                                            setFieldValue('email', '')
                                            setFieldValue('fone', '')
                                        }}
                                    >
                                        Cadastrar uma pessoa
                                        <FiPlus size={16} />
                                    </Button>
                                ) : (
                                    <Button
                                        className="transparent btn-cadastro"
                                        onClick={() => {
                                            setResultadoBusca(null)

                                            setShowCadastroPessoa(false)
                                        }}
                                    >
                                        Cancelar cadastro
                                        <FiX size={16} />
                                    </Button>
                                )}

                                {pessoa ? (
                                    <PessoaContainer className="animated fadeIn">
                                        <img src={pessoa.usuario?.avatar?.link || avatarDefault} alt="Avatar" className="avatar" />

                                        <h1>{pessoa.nome}</h1>

                                        {pessoa.crm ? (
                                            <>
                                                {pessoa.data_ultima_atualizacao && (
                                                    <h2>{`Data da última atualização cadastral: ${pessoa.data_ultima_atualizacao}`}</h2>
                                                )}

                                                {pessoa.crm && (
                                                    <h3>{`CRM: ${pessoa.crm}`}</h3>
                                                )}

                                                {pessoa.status_associacao && (
                                                    <h2>{`Status: ${pessoa.status_associacao}`}</h2>
                                                )}

                                                {pessoa.alerta_atualizacao && (
                                                    <h3>
                                                        <Button
                                                            onClick={() => {
                                                                history.push(`/associado/ficha_associado?c=${pessoa.crm}`)
                                                            }}
                                                            className="transparent"
                                                            tiny
                                                        >
                                                            Revisar cadastro
                                                        </Button>
                                                    </h3>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <h2>{`E-mail: ${pessoa.contato?.find(c => c.tipo_contato_id === 6)?.contato}` || 'Não informado'}</h2>
                                                <h3>{`Fone: ${pessoa.contato?.find(c => c.tipo_contato_id === 4)?.contato}` || 'Não informado'}</h3>
                                            </>
                                        )}
                                    </PessoaContainer>
                                ) : null}

                                {!pessoa && !showCadastroPessoa && (
                                    <PlaceholderPessoa>
                                        <FiSearch />

                                        <h1>Pesquise uma pessoa pelo nome</h1>
                                    </PlaceholderPessoa>
                                )}

                                {showCadastroPessoa && (
                                    <PessoaCadastroContainer className="animated fadeIn">
                                        <Textbox
                                            name="nome"
                                            label="Nome"
                                            style={{ textTransform: 'uppercase' }}
                                            onFocus={() => { setResultadoBusca([]) }}
                                        />

                                        <Textbox
                                            name="fone"
                                            label="Celular"
                                            mask={masks.mobile}
                                        />

                                        <Textbox
                                            name="email"
                                            label="E-mail"
                                            style={{ textTransform: 'lowercase' }}
                                        />
                                    </PessoaCadastroContainer>
                                )}

                                {resultadoBusca?.length > 0 ? (
                                    <ul className="result-list">
                                        {resultadoBusca.map(result => (
                                            <li key={result.id} onClick={() => { handleSelecionarPessoa(result) }}>
                                                {result.nome}
                                            </li>
                                        ))}
                                    </ul>
                                ) : !showCadastroPessoa && resultadoBusca?.length === 0 ? (
                                    <div className="empty-list">
                                        Nenhuma pessoa foi encontrada. Faça um cadastro rápido ao lado.
                                    </div>
                                ) : null}

                                <Select
                                    label="Motivo"
                                    name="motivo"
                                    options={motivos.filter(m => !!m.id)
                                        .map(m => ({
                                            label: m.descricao,
                                            value: m.id
                                        }))}
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)
                                    }}
                                    containerStyle={{ gridArea: 'motivo' }}
                                />

                                <Textbox
                                    label="Data e hora da entrada"
                                    name="data_hora"
                                    containerStyle={{ gridArea: 'data_hora' }}
                                    mask={masks.datetime}
                                />

                                <Textbox
                                    label="Data e hora da saída"
                                    name="data_hora_saida"
                                    containerStyle={{ gridArea: 'data_hora_saida' }}
                                    mask={masks.datetime}
                                />

                                <Select
                                    label="Canal de atendimento"
                                    name="tipo"
                                    options={
                                        [
                                            {
                                                label: 'Presencial',
                                                value: 'presencial'
                                            },
                                            {
                                                label: 'Via telefone',
                                                value: 'telefone'
                                            },
                                            {
                                                label: 'Via e-mail',
                                                value: 'email'
                                            },
                                            {
                                                label: 'WhatsApp',
                                                value: 'whatsapp'
                                            }
                                        ]
                                    }
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)
                                    }}
                                    containerStyle={{ gridArea: 'tipo' }}
                                />

                                <Textarea
                                    name="detalhes"
                                    label="Detalhes"
                                    containerStyle={{ gridArea: 'detalhes' }}
                                />

                                <div style={{ gridArea: 'button' }} className="button-container">
                                    <Button className="transparent" type="submit" loading={isSubmitting}>
                                        Registrar
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </FormContainer>
            </Modal>
        </Container>
    )
}
