import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'

import { Container, FormDocumentoContainer } from './styles'

import { api, authHeaders } from '../../../../services/api'
import { loadTips } from '../../../../util/tip'

import { TipContext } from '../../../../contexts/TipContext'

import formUtils from '../../../../util/form'

import {
    Textbox, Select, Button, Checkbox
} from '../../../../components/Form'

const baseInitialValues = {
    descricao: '',
    grupo: null,
    digitavel: false,
    validade: false
}

const grupos = [
    { label: 'Sem grupo', value: null },
    { label: 'Documento financeiro', value: 'financeiro' },
    { label: 'Documentos de lançamento', value: 'lancamento' },
    { label: 'Documentos jurídicos', value: 'juridico' },
    { label: 'Extratos', value: 'extrato' },
    { label: 'Documentos avulsos', value: 'avulso' },
    { label: 'Documento do acervo', value: 'acervo' }
]

export default function ({ edicao, reload }) {
    const { setCodigo } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)

    useEffect(() => {
        if (edicao) {
            setInitialValues({
                ...edicao,
                grupo: {
                    label: grupos.find(g => g.value === edicao.grupo).label,
                    value: edicao.grupo
                }
            })
        }
    }, [edicao])

    async function handleAdd(values) {
        await api.post('tipo_documento', values, authHeaders())

        toast.success('Documento cadastrado com sucesso.')
    }

    async function handleEdit(values) {
        await api.put(`tipo_documento/${edicao.id}`, values, authHeaders())

        toast.success('Documento atualizado com sucesso.')
    }

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)

            if (edicao) {
                await handleEdit(values)
            } else {
                await handleAdd(values)
            }

            reload()
        } catch (e) {
            toast.error(`Ocorreu um erro ao ${edicao ? 'editar' : 'cadastrar'} o tipo de documento.`)
        }
    }

    useEffect(() => {
        loadTips(setCodigo, 'form_acervo_tipo_documento')
    }, [])

    return (
        <Container>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize
            >
                {({ setFieldValue }) => (
                    <Form>
                        <FormDocumentoContainer>
                            <Textbox
                                name="descricao"
                                label="Nome do documento"
                            />

                            <Select
                                name="grupo"
                                label="Grupo"
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                options={grupos}
                            />

                            <div className="space" />

                            <Checkbox
                                name="digitavel"
                                label="Possui numeração?"
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            />

                            <Checkbox
                                name="validade"
                                label="Possui validade?"
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            />

                            <div className="button-container">
                                <Button type="submit" className="transparent">
                                    {edicao ? 'Salvar alterações' : 'Cadastrar'}
                                </Button>
                            </div>
                        </FormDocumentoContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
