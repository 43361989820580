import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { FiFile, FiLink } from 'react-icons/fi'

import { Form, Formik } from 'formik'
import Card from '../../components/Card'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import {
    Button, Calendar, Select, Textbox
} from '../../components/Form'

import { api } from '../../services/api'
import Yup from '../../services/yup'

import formUtils from '../../util/form'
import { forceDownloadFileFromURL } from '../../util/file'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../assets/images/gifs/periodo-dias.gif'

const validation = Yup.object({
    titulo: Yup.string(),
    periodo: Yup.array().nullable(),
    palavras_chave: Yup.string()
})

export default function () {
    const [acervo, setAcervo] = useState(null)
    const [segmentos, setSegmentos] = useState([])
    const [segmentosSelecionados, setSegmentosSelecionados] = useState([])

    async function loadSegmentos() {
        const response = await api.get('acervo/segmento', {
            params: {
                publico: 1
            }
        })

        setSegmentos(response)
    }

    async function handleSearch(values) {
        values = formUtils.extractFormValues(values)

        const { titulo, periodo, palavras_chave } = values

        setAcervo(false)

        const response = await api.get('acervo/publico', {
            params: {
                titulo,
                periodo,
                palavras_chave,
                segmento: segmentosSelecionados.length ? segmentosSelecionados.at(-1) : null
            }
        })

        setAcervo(response)
    }

    function obterSegmentosFilhos(nivel) {
        let segmentoAberto = segmentos

        for (let i = 0; i < nivel; i++) {
            const selecionado = segmentosSelecionados[i]

            if (i === 0) {
                segmentoAberto = segmentos.find(s => s.id === selecionado).segmentos_filhos
            } else {
                segmentoAberto = segmentoAberto.find(s => s.id === selecionado).segmentos_filhos
            }
        }

        return segmentoAberto
    }

    useEffect(() => {
        loadSegmentos()
    }, [])

    if (!segmentos?.length) {
        return null
    }

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Publicações SINDMEPA</h1>

                    <Formik
                        onSubmit={handleSearch}
                        initialValues={{
                            periodo: null,
                            titulo: '',
                            palavras_chave: ''
                        }}
                        validationSchema={validation}
                    >
                        {({ resetForm, setFieldValue, values }) => (
                            <Form>
                                <Textbox
                                    label="Por título"
                                    name="titulo"
                                />

                                <Textbox
                                    label="Por palavras-chave"
                                    name="palavras_chave"
                                />

                                <Calendar
                                    name="periodo"
                                    yearNavigator
                                    label="Período de publicação"
                                    maxDate={new Date()}
                                    selectionMode="range"
                                    tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                                />

                                <p className="label-segmento">
                                    Especifique o tipo de documento que está procurando
                                </p>

                                <Select
                                    name="nivel_1"
                                    label=""
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)
                                        setFieldValue('nivel_2', null)

                                        setSegmentosSelecionados([selected.value])
                                    }}
                                    options={segmentos?.filter(seg => seg.publico).map(segmento => ({
                                        label: segmento.descricao,
                                        value: segmento.id
                                    })) || []}
                                />

                                {segmentos.length > 0 && segmentosSelecionados.map((seg, idx) => {
                                    if (!obterSegmentosFilhos(idx + 1)?.length) {
                                        return null
                                    }

                                    return (
                                        <Select
                                            key={seg}
                                            name={`nivel_${idx + 2}`}
                                            label=""
                                            placeholder={`Nível ${idx + 2}`}
                                            onChange={(selected, meta) => {
                                                setFieldValue(meta.name, selected)

                                                const posicaoAtual = Number(meta.name.split('_')[1])

                                                const proximosNiveis = Object.keys(values).filter(k => k.startsWith('nivel_') && Number(k.split('_')[1]) > posicaoAtual)

                                                proximosNiveis.forEach(k => {
                                                    setFieldValue(k, null)
                                                })

                                                setSegmentosSelecionados(old => [...old.slice(0, idx + 1), selected.value])
                                            }}
                                            options={obterSegmentosFilhos(idx + 1)?.map(segmento => ({
                                                label: segmento.descricao,
                                                value: segmento.id
                                            })) || []}
                                        />
                                    )
                                })}

                                <ButtonContainer>
                                    <Button onClick={resetForm} className="transparent">
                                        Limpar
                                    </Button>

                                    <Button type="submit" className="white">
                                        Pesquisar
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>

                    {Array.isArray(acervo) ? (
                        <Table
                            headers={[
                                { name: 'titulo', value: 'Título' },
                                {
                                    name: 'data', value: 'Data de publicação', centered: true, style: { width: 170 }
                                }
                            ]}
                            data={acervo.map(acervoItem => ({
                                id: acervoItem.id,
                                titulo: acervoItem.titulo,
                                data: format(new Date(acervoItem.data_publicacao), 'dd/MM/yyyy'),
                                link: acervoItem.link || null,
                                arquivo: acervoItem.arquivo?.link || null
                            }))}
                            actions={[
                                {
                                    name: 'visualizar',
                                    icon: FiFile,
                                    iconSize: 16,
                                    action: item => {
                                        window.open(item.arquivo, '_blank')
                                    },
                                    checkDisabled: item => !item.arquivo,
                                    disableTooltip: true
                                },
                                {
                                    name: 'abrir link',
                                    icon: FiLink,
                                    iconSize: 15,
                                    action: item => {
                                        forceDownloadFileFromURL(item.link)
                                    },
                                    checkDisabled: item => !item.link,
                                    disableTooltip: true
                                }
                            ]}
                            hideOffset={600}
                            hideOnSmallHeaders={['formato', 'veiculo']}
                            itemsByPage={20}
                        />
                    ) : acervo === false ? (
                        <Spinner />
                    ) : null}
                </Card>
            </main>
        </Container>
    )
}
