import React from 'react'
import { FiAlertTriangle } from 'react-icons/fi'

import Card from '../../../components/Card'

import Lista from './Lista'

import { Container } from './styles'

export default function () {
    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Desenvolvimento do sistema</h1>

                    <Lista />
                </Card>

                <p className="alerta-tamanho-tela">
                    <FiAlertTriangle size={28} />
                    <span>Este dispositivo tem a tela muito reduzida. Tente acessar a partir de um computador com maior resolução.</span>
                </p>
            </main>
        </Container>
    )
}
