import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			display: flex;
			flex-direction: column;

			form {
				display: flex;
				align-items: end;
				gap: 8px;

				.textbox {
					width: 400px; 
				}

				button {
					width: fit-content;
				}
			}
		}
	}
`
