import React, { useEffect, useState } from 'react'
import { FieldArray, Form, Formik } from 'formik'
import { FiPaperclip, FiEye } from 'react-icons/fi'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { ValidationError } from 'yup'
import { differenceInMinutes, format, getYear } from 'date-fns'

import { api } from '../../services/api'
import Yup from '../../services/yup'

import maskUtils from '../../util/masks'
import stringUtils from '../../util/string'
import { calcularDuracao, getDateObject, isUSDate, stringToDate } from '../../util/date'

import {
    Select, File, Textbox, Button
} from '../../components/Form'
import Table from '../../components/Table'

import { Container, DocumentosContainer, ModalContent } from './styles'
import Modal from '../Modal'
import { extname } from '../../util/path'

const imageFormats = ['.jpg', '.jpeg', '.png', '.gif']

export default function ({
    documentos, handleSubmit, handleAdd = () => { }, handleDelete = () => { }, grupo = '', enablePreview = true
}) {
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [visibleFile, setVisibleFile] = useState(null)

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento', {
            params: {
                grupo
            }
        })

        setTiposDocumento(response)
    }

    useEffect(() => {
        loadTiposDocumento()
    }, [])

    function getDocumentoMask(tipoSelecionado) {
        switch (tipoSelecionado.value) {
            case 2: return maskUtils.cpf
            case 14: return maskUtils.cnpj
            case 11: return maskUtils.pis
            default: return null
        }
    }

    function handleAddDocumento(values, setFieldValue, arrayHelpers) {
        const {
            tipo_documento, arquivo, identificador, validade, inicio, fim, cid
        } = values

        if (!arquivo || !tipo_documento) {
            toast.warn('Adicione o documento e escolha o tipo.')
            return
        }

        if (tiposDocumento.find(tipo => tipo.id === tipo_documento?.value)?.digitavel && !identificador) {
            toast.warn('Digite o número do documento.')
            return
        }

        if (tipo_documento.value === 21) {
            try {
                Yup.string().date({
                    min: { value: new Date(), message: 'A data informada está no passado.' }
                }).validateSync(validade)
            } catch (e) {
                if (e instanceof ValidationError) {
                    toast.warn(e.message)
                } else {
                    toast.warn('Verifique a data de validade informada.')
                }

                return
            }
        }

        const doc = {
            tipo_documento,
            arquivo,
            identificador,
            validade,
            inicio,
            fim,
            cid
        }

        arrayHelpers.push(doc)
        handleAdd(doc)

        setFieldValue('arquivo', null)
        setFieldValue('tipo_documento', null)

        loadTiposDocumento()
    }

    async function handleSave(values) {
        if (handleSubmit) {
            const obrigatorioIds = [999, 2]
            const documentosObrigatorios = tiposDocumento.filter(t => obrigatorioIds.includes(t.id))
            const obrigatoriosIds = documentosObrigatorios.map(d => d.id)

            const obrigatoriosIncluidos = values.documentos.filter(d => obrigatoriosIds.includes(d.tipo_documento.value))

            if(obrigatoriosIncluidos.length < obrigatorioIds.length) {
                const obrigatoriosDescricoes = documentosObrigatorios.map(d => d.descricao).join(', ')

                toast.error(`Os seguintes documentos são obrigatórios: ${obrigatoriosDescricoes}`)
                return
            }

            handleSubmit(values)
        }
    }

    return (
        <>
            <Container>
                <Formik
                    initialValues={{
                        documentos: documentos?.map(doc => {
                            let dataValidade
                            let dataEmissao
                            let orgaoEmissor
                            let inicioData
                            let fimData
                            let cidValue

                            if ([21, 4, 16, 36].includes(doc.tipo_documento.id) && doc.extra) {
                                const { validade } = doc.extra

                                if (validade) {
                                    dataValidade = format(stringToDate(`${validade} 00:00:00`), 'dd/MM/yyyy')
                                }
                            }

                            if ([10, 58].includes(doc.tipo_documento.id) && doc.extra) {
                                const { inicio, fim, cid } = doc.extra

                                cidValue = cid

                                if (inicio && fim) {
                                    inicioData = format(getDateObject(inicio), 'dd/MM/yyyy HH:mm:ss')
                                    fimData = format(getDateObject(fim), 'dd/MM/yyyy HH:mm:ss')
                                }
                            }

                            if (doc.tipo_documento.id === 1 && doc.extra) {
                                const {
                                    data_emissao, orgao_emissor
                                } = doc.extra

                                if (data_emissao) {
                                    dataEmissao = format(stringToDate(`${data_emissao} 00:00:00`), 'dd/MM/yyyy')
                                }

                                if (orgao_emissor) {
                                    orgaoEmissor = orgao_emissor
                                }
                            }

                            return {
                                doc_id: doc.id,
                                arquivo: doc.arquivo?.id,
                                tipo_documento: {
                                    label: doc.tipo_documento.descricao,
                                    value: doc.tipo_documento.id
                                },
                                identificador: doc.identificador || '',
                                validade: dataValidade || '',
                                dataEmissao: dataEmissao || '',
                                orgaoEmissor: orgaoEmissor || '',
                                inicio: inicioData || '',
                                fim: fimData || '',
                                cid: cidValue || ''
                            }
                        }) || []
                    }}
                    onSubmit={handleSave}
                    enableReinitialize
                >
                    {({ setFieldValue, values, errors }) => (
                        <Form>
                            <DocumentosContainer>
                                <FieldArray
                                    name="documentos"
                                    style={{ gridArea: 'arquivos' }}
                                    render={arrayHelpers => {
                                        const tipoDoc = tiposDocumento.find(tipo => tipo.id === values.tipo_documento?.value)
                                        const duracao = calcularDuracao(differenceInMinutes(getDateObject(values.fim), getDateObject(values.inicio)))

                                        return (
                                            <>
                                                <File
                                                    name="arquivo"
                                                    onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                                    label="Documento"
                                                    format="square"
                                                    previewSize={['100%', '242px']}
                                                    error={errors.documento}
                                                    getPreloadImage={async () => {
                                                        const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                                        return arquivo?.link
                                                    }}
                                                    maxSize={2 * 1024 * 1024}
                                                    accept={[
                                                        'application/pdf',
                                                        'image/jpeg',
                                                        'image/png'
                                                    ]}
                                                />

                                                <Select
                                                    name="tipo_documento"
                                                    label="Tipo de documento"
                                                    onChange={(selected, meta) => {
                                                        setFieldValue(meta.name, selected)

                                                        setFieldValue('identificador', '')
                                                        setFieldValue('validade', '')
                                                        setFieldValue('inicio', '')
                                                        setFieldValue('fim', '')
                                                        setFieldValue('cid', '')
                                                    }}
                                                    options={tiposDocumento.map(({ id, descricao }) => ({
                                                        label: descricao,
                                                        value: id
                                                    }))}
                                                />

                                                {tipoDoc?.digitavel && (
                                                    <Textbox
                                                        label="Número do documento"
                                                        name="identificador"
                                                        mask={getDocumentoMask(values.tipo_documento)}
                                                    />
                                                )}

                                                {tipoDoc?.validade && (
                                                    <Textbox
                                                        label="Data de validade"
                                                        name="validade"
                                                        mask={maskUtils.date}
                                                    />
                                                )}

                                                {tipoDoc?.id === 10 && (
                                                    <Textbox
                                                        label="CID"
                                                        name="cid"
                                                    />
                                                )}

                                                {tipoDoc?.periodo && (
                                                    <>
                                                        <Textbox
                                                            label="Início"
                                                            name="inicio"
                                                            mask={maskUtils.datetime}
                                                        />

                                                        <Textbox
                                                            label="Fim"
                                                            name="fim"
                                                            mask={maskUtils.datetime}
                                                        />

                                                        {!!duracao && (
                                                            <span style={{ margin: 8 }}>
                                                                {`Duração: ${duracao}`}
                                                            </span>
                                                        )}
                                                    </>
                                                )}

                                                <Button
                                                    className="transparent"
                                                    onClick={() => { handleAddDocumento(values, setFieldValue, arrayHelpers) }}
                                                >
                                                    Anexar novo documento
                                                    <FiPaperclip size={16} />
                                                </Button>

                                                <Table
                                                    headers={[
                                                        { name: 'tipo_documento', value: 'Documento' }
                                                    ]}
                                                    data={values.documentos?.map(doc => {
                                                        let descricao = doc.tipo_documento.label

                                                        if (doc.tipo_documento.value === 21 && doc.extra) {
                                                            const { validade, periodo } = doc.extra

                                                            if (validade && periodo) {
                                                                const ano = getYear(new Date(`${validade} 00:00:00`))
                                                                descricao = `${doc.tipo_documento.label} (${periodo}º ${ano})`
                                                            }
                                                        }

                                                        if ([4, 16, 36].includes(doc.tipo_documento.value) && doc.extra) {
                                                            const { validade } = doc.extra

                                                            if (validade) {
                                                                descricao = `${doc.tipo_documento.label} (${format(new Date(`${validade} 00:00:00`))})`
                                                            }
                                                        }

                                                        if ([10, 58].includes(doc.tipo_documento.value) && doc.extra) {
                                                            const { inicio, fim } = doc.extra

                                                            if (inicio && fim) {
                                                                descricao = `${doc.tipo_documento.label} (${format(new Date(inicio), 'dd/MM/yyyy')} a ${format(new Date(fim), 'dd/MM/yyyy')})`
                                                            }
                                                        }

                                                        return {
                                                            ...doc,
                                                            id: doc.arquivo,
                                                            tipo_documento: descricao,
                                                            arquivo: doc.arquivo
                                                        }
                                                    }) || []}
                                                    keyProp="doc_id"
                                                    handleDelete={(id, index) => {
                                                        arrayHelpers.remove(index)
                                                        handleDelete(id)
                                                        loadTiposDocumento()
                                                        return true
                                                    }}
                                                    confirmExclusion={{
                                                        attr: 'tipo_documento',
                                                        template: 'Deseja realmente excluir o documento #attr#?'
                                                    }}
                                                    emptyLabel="Nenhum documento anexado"
                                                    disableTooltips
                                                    actions={enablePreview ? [
                                                        {
                                                            icon: FiEye,
                                                            iconSize: 16,
                                                            action: doc => {
                                                                const documento = documentos.find(d => d.arquivo?.id === doc.arquivo)

                                                                if (documento.arquivo) {
                                                                    setVisibleFile(documento)
                                                                } else {
                                                                    toast.warn('Visualização não disponível.')
                                                                }
                                                            },
                                                            name: 'Visualizar',
                                                            checkDisabled: item => !documentos?.find(d => d.arquivo?.id === item.arquivo)
                                                        }
                                                    ] : []}
                                                />
                                            </>
                                        )
                                    }}
                                />
                            </DocumentosContainer>

                            {
                                handleSubmit && (
                                    <Button type="submit" className="transparent">
                                        Atualizar documentos
                                        <FaRegSave />
                                    </Button>
                                )
                            }
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={!!visibleFile}
                handleClose={() => setVisibleFile(null)}
                closeOnOverlayClick
                title={visibleFile?.tipo_documento.descricao}
            >
                <ModalContent>
                    {visibleFile?.extra && Object.entries(visibleFile?.extra).map(([k, v]) => (
                        <p key={k}>{`${stringUtils.capitalize(k)}: ${isUSDate(v) ? format(new Date(`${v}${v.length === '10' ? ' 00:00:00' : ''}`), 'dd/MM/yyyy') : v}`}</p>
                    ))}

                    {imageFormats.includes(extname(visibleFile?.arquivo?.link).toLowerCase()) ? (
                        <img src={visibleFile?.arquivo?.link} alt="" />
                    ) : (
                        <iframe src={visibleFile?.arquivo?.link} frameBorder="0" title="Anexo" />
                    )}
                </ModalContent>
            </Modal>

        </>
    )
}
