import React, {
    useState, useCallback, useEffect, useContext 
} from 'react'
import { Link } from 'react-router-dom'
import { FiLogIn } from 'react-icons/fi'

import { Navbar, MenuContainer } from './styles'

import { GlobalContext } from '../../contexts/GlobalContext'

export default function () {
    const { clienteRobot } = useContext(GlobalContext)

    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = useCallback(() => {
        const menu = document.querySelector('.menu')
        const items = [...menu.querySelectorAll('aside > *')]

        document.body.style.overflow = menuOpen ? 'initial' : 'hidden'

        menu.classList.toggle('on', !menuOpen)
        menu.classList.toggle('slideInLeft', !menuOpen)
            
        items.forEach((option, i) => {
            option.classList.add('animated', 'fadeInLeft', 'faster', `delay-${(i + 1) * 200}ms`)
        })

        setMenuOpen(!menuOpen)
    }, [menuOpen])

    useEffect(() => {
        if(menuOpen) {
            const options = document.querySelector('.menu aside')
            options.addEventListener('click', toggleMenu)
        }
    }, [menuOpen, toggleMenu])

    return (
        <>
            <Navbar>
                <img src={clienteRobot?.logo} alt="Home" className="object-contain" />

                <MenuContainer className="menu animated faster">
                    <div className="menu-toggle" onClick={toggleMenu} role="button" tabIndex={0} aria-label="x">
                        <div className="one" />
                        <div className="two" />
                        <div className="three" />
                    </div>

                    <aside>
                        <Link to="/consulta_publica">
                            <span>Consulta de Membros</span>
                        </Link>

                        <Link to="/associacao">
                            <span>Associação</span>
                        </Link>

                        <Link to="/">
                            <FiLogIn size={20} style={{ marginRight: 6 }} />
                            <span>Login</span>
                        </Link>
                    </aside>
                </MenuContainer>
            </Navbar>
        </>
    )
}
