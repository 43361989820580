import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 24px;
		
		.select{
			grid-column: 1 / span 2;
			max-width: 250px;
		}

		> div {
			> h1 {
				font-size: 16px;
				font-weight: 400;
				margin: 8px;
			}

			> i {
				margin: 8px;
			}
		}

		@media(max-width: 800px){
			display: flex;
			flex-direction: column;
		}
	}
`

export const List = styled.div`
	width: 100%;
	height: 100%;
	background-color: #FFF;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
	border-radius: 4px;
	margin: 5px 8px;
	max-height: 500px;
	overflow: hidden auto;
	display: flex;
	flex-direction: column;
	transition: 300ms;
	
	&.highlight {
		border: dashed 2px grey;
	}
`
