import styled from 'styled-components'

export const Container = styled.a.attrs({
    className: 'new-container'
})`
	display: flex;
	flex-direction: column;
	padding-bottom: 14px;
	transition: 300ms;

	&:hover {
		opacity: 0.8;
	}

	.image {
		border-radius: 6px;
		display: inline-table;
		height: 120px;
		width: 200px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	}

	h1 {
		color: #FFF !important;
		font-size: 15px;
		width: 200px;
		margin-top: 10px;
	}
`
