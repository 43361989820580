import React from 'react'
import { FiEyeOff } from 'react-icons/fi'

import { Container } from './styles'

export default function () {
    return (
        <Container>
            <FiEyeOff size={50} />
            <h1>Desculpe, você não tem permissão para acessar esta página.</h1>
        </Container>
    )
}
