import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import { Button, Textbox } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import { TipContext } from '../../../../contexts/TipContext'
import { api, authHeaders } from '../../../../services/api'
import { loadTips } from '../../../../util/tip'

import { Container } from './styles'

const baseInitialValues = {
    descricao: ''
}

export default function MotivoCancelamentoPlano() {
    const { setCodigo, tips } = useContext(TipContext)

    const [motivos, setMotivos] = useState(null)
    const [editItem, setEditItem] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function loadMotivos() {
        const response = await api.get('plano_de_saude/motivos_cancelamento', authHeaders())

        setMotivos(response)
    }

    async function handleAdd(values, { resetForm }) {
        try {
            if(!values.descricao) {
                return
            }
        
            await api.post('plano_de_saude/motivos_cancelamento', values, authHeaders())

            toast.success('Motivo cadastrado com sucesso.')

            loadMotivos()

            resetForm()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(item) {
        try {
            await api.delete(`plano_de_saude/motivos_cancelamento/${item.id}`, authHeaders())

            toast.success('Motivo removido com sucesso.')

            loadMotivos()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleEdit(values, { resetForm }) {
        try {
            await api.put(`plano_de_saude/motivos_cancelamento/${editItem.id}`, values, authHeaders())

            toast.success('Motivo atualizado com sucesso.')

            resetForm()

            setInitialValues(baseInitialValues)
            setEditItem(null)

            loadMotivos()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadMotivos()

        loadTips(setCodigo, 'form_motivos_cancelamento_plano_saude')
    }, [])

    useEffect(() => {
        if(editItem) {
            setInitialValues({
                descricao: editItem.descricao
            })
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [editItem])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Motivos de Cancelamento para Plano de Saúde</h1>

                    <p style={{ margin: 8, paddingTop: 12 }}>{tips?.formulario}</p>

                    <Formik
                        onSubmit={editItem ? handleEdit : handleAdd}
                        initialValues={initialValues}
                        enableReinitialize
                    >
                        {() => (
                            <Form>
                                <Textbox 
                                    name="descricao"
                                    label="Nome do motivo"
                                />

                                {editItem && (
                                    <Button className="transparent" onClick={() => { setEditItem(null) }}>
                                        Cancelar edição
                                    </Button>
                                )}

                                <Button type="submit" className="white">
                                    {editItem ? 'Atualizar' : 'Cadastrar'}
                                </Button>
                            </Form>
                        )}
                    </Formik>

                    {motivos ? (
                        <Table 
                            headers={[
                                { name: 'descricao', value: 'Nome do motivo' }
                            ]}
                            data={motivos}
                            actions={[
                                {
                                    name: 'editar',
                                    icon: FaEdit,
                                    iconSize: 16,
                                    action: setEditItem
                                },
                                {
                                    name: 'excluir',
                                    icon: FaTrash,
                                    iconSize: 14,
                                    action: handleDelete,
                                    confirmation: 'Deseja realmente excluir este motivo de cancelamento?'
                                }
                            ]}
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
