import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid !important;
		justify-items: center;
		align-items: center;
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'declaracao valor'
			'check check'
			'button button';

		> .file {
			grid-area: declaracao;
			justify-self: left;

			.file-select-area {
				background-position-y: top;
			}
		}

		> p {
			grid-area: valor;
			font-size: 16px;
			margin: 20px 8px;
			width: calc(100% - 16px);
			margin: 24px 8px;
		}

		@media(max-width: 650px) {
			grid-template-columns: 1fr;
			grid-template-areas:
				'declaracao'
				'valor'
				'check'
				'button';
		}
	}
`
