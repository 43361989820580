import React, {
    useState, useEffect, useCallback
} from 'react'
import { format, getYear } from 'date-fns'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FaFilePdf } from 'react-icons/fa'

import { api, authHeaders } from '../../../../services/api'
import arrayUtils from '../../../../util/array'

import { downloadRelatorioJasper } from '../../../../util/file'
import formUtils from '../../../../util/form'

import { Button, Select, Textbox } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'

import Card from '../../../../components/Card'

import { Container, ButtonContainer } from './styles'

import Yup from '../../../../services/yup'
import masks from '../../../../util/masks'
import { getMesPorExtenso } from '../../../../util/date'

const validation = Yup.object({
    ano: Yup.string().required('Selecione o ano.').nullable(),
    cpf: Yup.string().required('Informe o CPF.')
})

export default function () {
    const [anos, setAnos] = useState([])

    useEffect(() => {
        const currentYear = new Date().getFullYear()
        const listaAnos = []

        for (let i = currentYear; i >= 2022; i--) {
            listaAnos.push({
                label: i,
                value: i
            })
        }

        setAnos(listaAnos)
    }, [])

    const handleSubmit = useCallback(async (values) => {
        try {
            values = formUtils.extractFormValues(values)
            let competenciaInicio = `${values.ano}-01-01 00:00:00`
            let competenciaFim = ''
            let competenciaText = ''

            const pessoa = await api.get('pessoa', {
                params: {
                    cpf: values.cpf
                },
                ...authHeaders()
            })

            if (pessoa.length > 0 && pessoa[0].associado) {
                const contribuicoes_plano_saude = await api.get('contribuicao_plano_saude', {
                    params: {
                        associado_id: pessoa[0].associado.id,
                        competencia: `${values.ano}-01-01|${getYear(new Date())}-12-01`
                    },
                    ...authHeaders()
                })

                if (contribuicoes_plano_saude.length > 0) {
                    const contribuicoes = contribuicoes_plano_saude.sort(arrayUtils.sort.comparisonFunction('competencia'))

                    for (let index = 0; index < contribuicoes.length; index++) {
                        if (!contribuicoes[index].status) {
                            competenciaFim = contribuicoes[index - 1].competencia
                            break
                        } else {
                            competenciaFim = format(new Date(contribuicoes[index].competencia), 'yyyy-MM-dd 23:59:59')
                        }
                    }

                    competenciaText = getMesPorExtenso(new Date(competenciaFim))
                } else {
                    toast.info('Não há contribuições de plano no período informado!.')
                    return
                }
            } else {
                toast.info('Associado não encontrato.')
                return
            }

            const response = await api.post('relatorio', {
                relatorio: 'relatorioRessarcimentoPlanoSaude',
                parametros: {
                    competenciaInicio,
                    competenciaFim,
                    competenciaText,
                    cpf: values.cpf
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, 'RelatorioDeclaracaoRessarcimentoPlanoSaude')
        } catch (e) {
            toast.error('Erro ao gerar a declaração.')
        }
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Declaração de Ressarcimento do Plano de Saúde</h1>

                    <Formik
                        initialValues={{
                            ano: '',
                            cpf: ''
                        }}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                        containerStyle={{ gridArea: 'form' }}
                    >
                        {({
                            isSubmitting, setFieldValue
                        }) => (
                            <Form>
                                <Textbox
                                    name="cpf"
                                    label="CPF"
                                    containerStyle={{ gridArea: 'f1' }}
                                    mask={masks.cpf}
                                />

                                <Select
                                    name="ano"
                                    label="Apartir do ano"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={anos}
                                    selectionMode="range"
                                    containerStyle={{ gridArea: 'f2' }}
                                />

                                <Spinner
                                    visible={isSubmitting}
                                    label="Gerando declaração..."
                                    containerStyle={{ gridArea: 'spinner' }}
                                />

                                <ButtonContainer>
                                    <Button
                                        type="submit"
                                        loading={isSubmitting}
                                        className="transparent"
                                    >
                                        Gerar declaração
                                        <FaFilePdf size={20} />
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
