import React, { useState, useRef, useContext } from 'react'
import {
    FiX, FiEye, FiCircle, FiFileText
} from 'react-icons/fi'
import { FaRegFileExcel } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import { format } from 'date-fns'
import { toast } from 'react-toastify'

import maskUtils from '../../../../../util/masks'
import stringUtils from '../../../../../util/string'
import { forceDownloadFileFromURL } from '../../../../../util/file'

import defaultAvatar from '../../../../../assets/images/avatar.png'

import { api, authHeaders } from '../../../../../services/api'

import Modal from '../../../../../components/Modal'
import TimeLine from './Timeline'

import {
    Container, Badge, HeaderItem, ItemContent, EnvolvidoContainer, DependenciasContainer, EvidenciaContainer, EnvolvidosContainer 
} from './styles'

import { GlobalContext } from '../../../../../contexts/GlobalContext'

const prioridadeCor = {
    1: {
        color: '#ffeb3b',
        descricao: 'baixa'
    },
    2: {
        color: '#ffc107',
        descricao: 'média baixa'
    },
    3: {
        color: '#ff9800',
        descricao: 'média'
    },
    4: {
        color: '#ff5722',
        descricao: 'média alta'
    },
    5: {
        color: '#f44336',
        descricao: 'alta'
    }
}

const statusCor = {
    1: '#bbb',
    2: '#ffeb3b',
    3: '#4fc3f7',
    4: '#00e676'
}

export default function ({ data, onUpdate }) {
    const { 
        id,
        descricao,
        detalhes,
        prioridade,
        prazo,
        apf,
        apf_arquivo,
        valor,
        data_solicitacao,
        data_aprovacao,
        data_inicio,
        data_homologacao,
        data_entrega,
        status,
        solicitante,
        aprovador,
        dependencias,
        evidencia_solicitacao,
        evidencia_aprovacao
    } = data

    const { user } = useContext(GlobalContext)

    const detailsRef = useRef()

    const manage = user.perfil.id === 1

    const [arquivoVisualizacao, setArquivoVisualizacao] = useState(null)

    function getSolicitanteTooltip(pessoa) {
        if(!pessoa) {
            return
        }

        const email = pessoa.contato.find(c => c.tipo_contato_id === 6)?.contato
        const fone = pessoa.contato.find(c => [1, 2, 3, 4, 5].includes(c.tipo_contato_id))?.contato

        return `
            <div style="display: flex; flex-direction: column; align-items: center">
                <img src="${pessoa.usuario?.avatar?.link || defaultAvatar}" alt="" style="height: 90px; width: 90px; border-radius: 50%; object-fit: cover; margin: 8px 0" />
                <h1 style="font-weight: 500; font-size: 12px; margin: 0 0 14px">${pessoa.nome}</h1>
                ${email ? `<p style="font-size: 11px; margin: 4px 0">E-mail: ${email}</p>` : ''}
                ${fone ? `<p style="font-size: 11px; margin: 4px 0">Fone: ${fone}</p>` : ''}
            </div>
        `
    }

    function handleRetractAllTasks() {
        const tarefasItens = [...document.querySelectorAll('.tarefa-item')]

        tarefasItens.forEach(tarefa => tarefa.removeAttribute('open'))
    }

    async function handleUpdateTaskStatus(evt, subtask) {
        if(manage) {
            evt.preventDefault()
        }

        try {
            await api.put(`tarefa/${id}/status`, {
                subtask: subtask || null
            }, authHeaders())

            onUpdate()

            ReactTooltip.hide()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    return (
        <li>
            <Container color={statusCor[status.id]} ref={detailsRef}>
                <summary onClick={e => { e.preventDefault() }} role="button" tabIndex={-1}>
                    <HeaderItem>
                        <div />
                        <span>Funcionalidade</span>
                        <span>Prioridade</span>
                        <span>Prazo estimado</span>
                        <span>Pontos de função</span>
                        <span>Custo estimado</span>
                        <div />
                    </HeaderItem>

                    <FiCircle 
                        className="status-icon" 
                        data-tip={status.descricao}
                        data-for="tooltip-right"
                        onClick={manage ? handleUpdateTaskStatus : null}
                    />
                    
                    <span className="title">{descricao}</span>

                    <Badge color={prioridadeCor[prioridade].color}>
                        <h1>{prioridade}</h1>
                        <span>{`(${prioridadeCor[prioridade].descricao})`}</span>
                    </Badge>

                    <span>{`${prazo} dias`}</span>

                    <span 
                        onClick={() => {
                            if(apf_arquivo?.link) {
                                forceDownloadFileFromURL(apf_arquivo.link)
                            }
                        }}
                        role="button"
                        tabIndex={-1}
                        className="link"
                        data-tip="Baixar planilha APF"
                        data-for="tooltip-left"
                    >
                        <FaRegFileExcel size={14} />
                        {apf ? `${apf}PF` : '-'}
                    </span>

                    <span>{`${valor ? maskUtils.maskApply.currency(valor) : '-'}`}</span>

                    <FiX size={18} onClick={() => { detailsRef.current.removeAttribute('open') }} className="close-icon" />
                    
                    <FiEye 
                        size={18} 
                        onClick={() => { 
                            handleRetractAllTasks()

                            detailsRef.current.setAttribute('open', '1') 
                        }} 
                        className="view-icon" 
                    />
                </summary>

                <ItemContent>
                    <p dangerouslySetInnerHTML={{ __html: detalhes.replace(/\n/g, '<br/>') }} />

                    <TimeLine 
                        dates={[
                            {
                                title: 'Solicitação',
                                date: data_solicitacao
                            },
                            {
                                title: 'Aprovação',
                                date: data_aprovacao ? format(new Date(data_aprovacao), 'dd/MM/yyyy') : null
                            },
                            {
                                title: 'Início',
                                date: data_inicio ? format(new Date(data_inicio), 'dd/MM/yyyy') : null
                            },
                            {
                                title: 'Homologação',
                                date: data_homologacao ? format(new Date(data_homologacao), 'dd/MM/yyyy') : null
                            },
                            {
                                title: 'Entrega',
                                date: data_entrega ? format(new Date(data_entrega), 'dd/MM/yyyy') : null
                            }
                        ]}
                    />

                    <EnvolvidosContainer>
                        <EnvolvidoContainer style={{ gridArea: 'solicitante' }}>
                            <h2>Solicitante:</h2>

                            <img 
                                src={solicitante.usuario?.avatar?.link || defaultAvatar} 
                                alt=""
                                data-tip={getSolicitanteTooltip(solicitante)}
                                data-for="tooltip-left"
                            />

                            <h1>{stringUtils.reduceName(solicitante.nome)}</h1>

                            <p>{data_solicitacao}</p>

                            {evidencia_solicitacao?.link && (
                                <div className="evidencia">
                                    <FiFileText size={14} />

                                    <span 
                                        onClick={() => { setArquivoVisualizacao(evidencia_solicitacao.link) }}
                                        role="button"
                                        focusable
                                        tabIndex={0}
                                    >
                                        Evidência
                                    </span>
                                </div>
                            )}
                        </EnvolvidoContainer>

                        <EnvolvidoContainer style={{ gridArea: 'aprovador' }}>
                            <h2>Aprovação:</h2>

                            <img 
                                src={aprovador.usuario?.avatar?.link || defaultAvatar} 
                                alt=""
                                data-tip={getSolicitanteTooltip(aprovador)}
                                data-for="tooltip-left"
                            />

                            <h1>{stringUtils.reduceName(aprovador.nome)}</h1>

                            <p>{format(new Date(data_aprovacao), 'dd/MM/yyyy')}</p>

                            {evidencia_aprovacao?.link && (
                                <div className="evidencia">
                                    <FiFileText size={14} />

                                    <span 
                                        onClick={() => { setArquivoVisualizacao(evidencia_aprovacao.link) }}
                                        role="button"
                                        focusable
                                        tabIndex={0}
                                    >
                                        Evidência
                                    </span>
                                </div>
                            )}
                        </EnvolvidoContainer>
                    </EnvolvidosContainer>

                    <DependenciasContainer>
                        <h1>Lista de dependências</h1>

                        {dependencias.length ? (
                            <>
                                {dependencias?.map(dep => (
                                    <details key={dep.id}>
                                        <summary>
                                            <FiCircle 
                                                size={10}
                                                data-tip={dep.status.descricao}
                                                data-for="tooltip-right"
                                                style={{ 
                                                    fill: statusCor[dep.status.id], 
                                                    stroke: statusCor[dep.status.id] 
                                                }} 
                                                onClick={e => {
                                                    if(manage) {
                                                        handleUpdateTaskStatus(e, dep.id)
                                                    } 
                                                }}
                                            />
                                            <span>{dep.descricao}</span>
                                        </summary>

                                        <div className="content">
                                            {dep.detalhes}
                                        </div>
                                    </details>
                                ))}
                            </>
                        ) : (
                            <p><i>Nenhuma dependência</i></p>
                        )}
                    </DependenciasContainer>
                </ItemContent>
            </Container>

            <ReactTooltip
                id="tooltip-right"
                place="right"
                effect="solid"
                type="dark"
                html
            />

            <ReactTooltip
                id="tooltip-left"
                place="left"
                effect="solid"
                type="dark"
                html
            />

            <Modal
                isOpen={!!arquivoVisualizacao}
                title="Visualização"
                handleClose={() => { setArquivoVisualizacao(null) }}
            >
                <EvidenciaContainer>
                    <img src={arquivoVisualizacao} alt="" />
                </EvidenciaContainer>
            </Modal>
        </li>
    )
}
