import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			min-height: 78vh;

			> h1 {
				margin-bottom: 24px;
			}

			.react-tabs__tab-panel {
				padding-bottom: 30px;
			}
		}

		@media(max-width: 900px) {
			padding: 20px 16px;
		}
	}
`
