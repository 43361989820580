import styled, { css } from 'styled-components'

const gridMedico = css`
	grid-template-areas:
		'foto x x'
		'foto nome nome'
		'foto identificador status'
		'foto data y'
		'foto separator separator'
		'foto aposentadoria aposentadoria'
		'foto dt_aposentadoria inst_aposentadoria';
`

const gridAcademico = css`
	grid-template-areas:
		'foto x x'
		'foto nome nome'
		'foto identificador status'
		'foto periodo_curso data';
`

const gridMedicoM = css`
	grid-template-areas:
		'foto x'
		'foto nome'
		'foto identificador'
		'foto status'
		'foto especialidade'
		'foto data'
		'foto separator'
		'foto aposentadoria'
		'foto dt_aposentadoria'
		'foto inst_aposentadoria';
`

const gridAcademicoM = css`
	grid-template-areas:
		'foto x'
		'foto nome'
		'foto identificador'
		'foto status'
		'foto periodo_curso'
		'foto data';
`

const gridMedicoS = css`
	grid-template-areas:
		'foto'
		'nome'
		'identificador'
		'status'
		'especialidade'
		'data'
		'separator'
		'aposentadoria'
		'dt_aposentadoria'
		'inst_aposentadoria';
`

const gridAcademicoS = css`
	grid-template-areas:
		'foto'
		'nome'
		'identificador'
		'status'
		'periodo_curso'
		'data';
`

export const Container = styled.div`
	justify-content: center;
	color: #fff;
	display: grid;
	grid-gap: 0 34px;
	grid-area: dados_pessoais;
	min-height: 190px;
	grid-template-rows: 20px 56px 42px auto;
	grid-template-columns: 150px 280px auto;
	
	${props => props.medico ? gridMedico : gridAcademico}

	.spinner-container {
		grid-column: 1/5;
		grid-row: 1/5;
		align-self: center;
	}

	a {
		color: #FFF;
		height: 155px;

		img {
			border-radius: 50%;
			align-self: center;
			height: 160px;
		}
	}

	h1 {
		font-size: 28px;
		font-weight: 300;
		color: #fff;
		text-transform: uppercase;
	}

	.identificador {
		display: flex;
		align-items: flex-start;

		.situacao {
			display: flex; 
			align-items: center;
			margin-left: 20px;

			svg {
				margin-right: 4px;
			}
		}
	}

	.nome_reduzido {
		display: none;
	}

	hr {
		grid-area: separator;
		height: 1px;
		border: solid 1px #42424255;
		width: 100%;

		@media(max-width: 800px) {
			width: 90%;
		}
	}

	.aposentado-title {
		font-size: 16px;
		font-weight: 600;
		grid-area: aposentadoria;
	}

	.ints-aposentadoria {
		max-width: 500px;

		@media(max-width: 800px) {
			margin: 0 12px;
			text-align: center;
		}
	}

	@media(max-width: 900px) {
		margin-bottom: 14px;
		grid-template-rows: 20px 44px 30px 30px 30px 30px;
		grid-template-columns: 150px auto;

		${props => props.medico ? gridMedicoM : gridAcademicoM}
	}

	@media(max-width: 800px) {
		justify-items: center;
		grid-template-rows: 180px 44px 30px 30px auto auto;
		grid-template-columns: 1fr;

		${props => props.medico ? gridMedicoS : gridAcademicoS}

		h1 {
			font-size: 20px;
			font-weight: 500;
		}

		.identificador > b {
			font-weight: unset;
		}

		.nome_completo {
			display: none;
		}

		.nome_reduzido {
			display: block;
		}

		.especialidade {
			text-align: center;
			padding: 0 20px;
		}
	}

	@media(max-width: 440px) {
		margin-bottom: 0;
	}
`

export const Avatar = styled.div`
	grid-area: foto;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	background-image: url(${props => props.image});
	background-position: center;
	background-size: cover;

	@media(max-width: 880px) {
		width: 150px;
		height: 150px;
		padding-top: unset;
	}
`

export const ModalEdicaoFaculdade = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: flex-end;

	button {
		max-width: fit-content;
		grid-column: 3;
		justify-self: flex-end;
	}

	@media(max-width: 800px) {
		display: flex;
		flex-direction: column;
	}
`
