import { createGlobalStyle } from 'styled-components'
import { darken } from 'polished'

export default createGlobalStyle`

	@media(min-width: 601px) {
		::-webkit-scrollbar {
			width: 7px;
			height: 7px;
		}
		::-webkit-scrollbar-track {
			background: rgba(255, 255, 255, 0.2);
			border-radius: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.3); 
			border-radius: 10px;
		}
		::-webkit-scrollbar-thumb:hover {
			background: rgba(0, 0, 0, 0.4); 
		}
	}


	/* BROWSER NORMALIZATION */

	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

	*:focus {
		outline: 0;
	}

	html, body, #root {
		min-height: 100%;
	}

	body {
		/* background-image: linear-gradient(180deg, #97DB9B, #cef0d0); */
		background-color: #111827;
		background-attachment: fixed;
		-webkit-font-smoothing: antialiased !important;
		overflow-x: hidden;
		scroll-behavior: smooth;
	}

	body, button, input, textarea, h1, h2, h3, h4, h5, h6 {
		color: #fff;
		font-size: 14px;
		font-family: Ubuntu, Roboto, sans-serif;
		font-weight: 300;
	}

	button {
		color: #FFF;
	}

	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 26px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 16px;
	}

	h6 {
		font-size: 12px;
	}

	a {
		text-decoration: none;
	}

	ul {
		list-style: none;
	}

	blockquote {
		margin-left: 20px;
	}

	span.error {
		color: #222;
		font-size: 12px;
		background-color: #ffeb3b;
		border-radius: 4px;
		padding: 5px;
		display: block;
		width: 100%;
	}


	button {
		&.disabled {
			opacity: 0.6;
		}
	}

	.button {
		cursor: pointer;
		font-size: 14px;
		font-weight: 300;
		background: #245C27;
		color: #FFF;
		border: none;
		border-radius: 4px;
		transition: all 0.3s;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		margin: 5px 0 5px 8px;
		height: 44px;
		padding: 0 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.2s;

		&:hover {
			box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 5px 8px 0 rgba(0,0,0,0.2);
			background: ${darken(0.03, '#245C27')};
		}

		&:disabled {
			opacity: 0.3;
			cursor: wait;
		}

		> svg {
			margin-left: 10px;
		}

		&.transparent {
			cursor: pointer;
			background: transparent;
			border: solid 1px #FFF;
			box-shadow: none;
			color: #fff;

			&:hover {
				color: #2c2c2c !important;
				background: #FFF;
				font-weight: 500;

				svg {
					color: #2c2c2c;
				}
			}

			&:disabled {
				cursor: wait;
				color: #eee;
				font-weight: 300;
				background: transparent;
				border: dashed 1px #ccc;
			}
		}

		&.green {
			background: #4caf50;
			color: #fff;

			&:hover {
				background: ${darken(0.03, '#4caf50')};
			}
		}

		&.red {
			background: #f44336;

			&:hover {
				background: ${darken(0.03, '#f44336')};
			}
		}

		&.yellow {
			background: #ffeb3b;
			color: #2c2c2c;

			&:hover {
				background: ${darken(0.03, '#ffeb3b')};
			}
		}

		&.white {
			background: #fff;
			color: #2c2c2c;

			svg {
				color: #2c2c2c;
			}

			&:hover {
				background: ${darken(0.03, '#fff')};
			}
		}
	}

	.__react_component_tooltip {
		width: fit-content !important;
		z-index: 99999;
	}

	p.tip {
		margin: 14px 11px 18px;
		grid-area: tip;
	}

	
	.icon.disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}
`
