import styled from 'styled-components'

import Card from '../../components/Card'

export const Container = styled(Card)`
	grid-area: observacao;
	display: grid;
	grid-template-columns: auto 24px;
	grid-template-rows: min-content auto;
	grid-template-areas:
		'title edit_icon'
		'obs obs';

	> h1 {
		font-size: 19px;
		font-weight: 500;
	}

	> div {
		grid-area: obs;
		margin-top: 24px;
		display: flex;
		flex-wrap: wrap;
		align-self: flex-start;
		line-height: 24px;
		
		span {
			margin-right: 28px;
		}

		.placeholder {
			width: 100%;
			text-align: center;
		}
	}

	> svg {
		color: #FFF;
		transition: 300ms;

		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}
	}

	.spinner-container {
		grid-area: obs;
		justify-self: center;
	}

	.observacao-conteudo {
		color: #fff;
	}
`

export const ModalContainer = styled.div`
	form {
		display: flex;
		flex-direction: column;
	}
`
