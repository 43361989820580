import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin: 0 8px 20px;
			grid-area: title;
		}

		@media(max-width: 800px) {
			padding: 20px;
		}

		@media(max-width: 400px) {
			padding: 15px 0;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`
