import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'
import {
    FiEye, FiXCircle, FiCheckCircle, FiEyeOff, FiAlertTriangle
} from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { api, authHeaders } from '../../../../services/api'

import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import Noticia from '../../../../components/Noticia'
import { Button } from '../../../../components/Form'
import { Financeiro, Juridico, PlanoSaude } from '../../../../components/Associado'

import arrayUtils from '../../../../util/array'

import {
    Container, DadosCadastrais, DadosExtras, NoticiasContainer, Avatar, Badge
} from './styles'

import avatarDefault from '../../../../assets/images/avatar.png'
import { GlobalContext } from '../../../../contexts/GlobalContext'

const autoScrollNoticias = false
let scrollDirection = '->'

export default function ({ showContribuicoes }) {
    const history = useHistory()

    const { user } = useContext(GlobalContext)

    const [noticiasScrollDirection, setNoticiasScrollDirection] = useState('->')
    const [noticiasScrollInterval, setNoticiasScrollInterval] = useState(null)
    const [pessoa, setPessoa] = useState(null)
    const [noticias, setNoticias] = useState([])

    const temPendencias = user?.associado.status_associacao.id === 6

    async function loadPessoa(pessoaId) {
        const response = await api.get(`pessoa/${pessoaId}`, authHeaders())
        const associado = await api.get(`associado/${response.associado.id}`, authHeaders())

        // if (response.associado.medico) {
        //     const crm = response.associado.pessoa.documentos.find(documento => documento.tipo_documento.descricao === 'CRM').identificador

        // let CFMData
        // let situacao

        // try {
        //     CFMData = await api.get(`consulta_cfm/PA/${crm}`)

        //     const { descricao } = await api.get(`situacao_associacao/${CFMData.situacao}`)
        //     situacao = descricao
        // } catch (e) {
        //     const crmDoc = response.associado.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)

        //     situacao = crmDoc?.extra?.situacao || 'Não definido'

        //     const situacoes = await api.get('situacao_associacao')

        //     CFMData = {
        //         situacao: situacoes.find(sit => sit.descricao === situacao).id,
        //         especialidade: response.associado.especialidades.map(esp => esp.descricao),
        //         nome: response.nome
        //     }
        // }

        // const { situacao: situacaoCodigo, especialidade } = CFMData

        // response.associado.situacao = {
        //     codigo: situacaoCodigo,
        //     descricao: situacao,
        //     icone: situacaoCodigo === 'A' ? FiCheckCircle : FiXCircle
        // }

        // response.associado.especialidades = !especialidade?.length ? [] : especialidade.map(espec => {
        //     const [descricao, codigoRQE] = espec.split(' - ')

        //     if (codigoRQE) {
        //         const [, rqe] = codigoRQE.split(': ')

        //         return {
        //             descricao: `${descricao}${rqe ? ` - ${codigoRQE}` : ''}`
        //         }
        //     }

        //     return { descricao }
        // })
        // }

        const nomes = response.nome.split(' ')
        response.nome_reduzido = `${nomes[0]} ${nomes.slice(1, nomes.length - 1).map(sobrenome => `${sobrenome[0]}. `).join(' ')}${nomes[nomes.length - 1]}`

        // response.comprovante_matricula_pendente = associado.comprovante_matricula_pendente

        // const filtrarPlanoAtivo = c => c.convenio.convenio_area_atuacao_id === 1 && c.status !== 4
        // const filtrarPlano = c => c.convenio.convenio_area_atuacao_id === 1

        // response.plano_saude = response.associado.conveniosAssociado?.find(filtrarPlanoAtivo)
        //     || response.associado.conveniosAssociado?.find(filtrarPlano)
        //     || null

        setPessoa(response)
    }

    async function loadNoticias() {
        const response = await api.get('acervo', {
            ...authHeaders(),
            params: {
                exibir_espaco_medico: 1
            }
        })

        setNoticias(response.map(noticia => ({
            id: noticia.id,
            titulo: noticia.titulo,
            imagem: noticia.thumbnail?.link || noticia.arquivo?.link || '',
            link: noticia?.link || noticia.arquivo?.link || '',
            data: format(new Date(noticia.data_publicacao), 'dd/MM/yyyy')
        })))
    }

    useEffect(() => {
        const pessoaId = user?.pessoa.id

        loadPessoa(pessoaId)

        loadNoticias()
    }, [])

    function getScrollInterval(direction) {
        return setInterval(() => {
            const increment = direction === '->' ? 1 : -1

            document.querySelector('.news-list').scrollBy(increment, increment)
        }, 30)
    }

    useEffect(() => {
        if (autoScrollNoticias) {
            setInterval(() => {
                const newDirection = scrollDirection === '->' ? '<-' : '->'

                scrollDirection = newDirection

                setNoticiasScrollDirection(newDirection)
            }, 2166 * noticias.length)
        }
    }, [noticias])

    useEffect(() => {
        if (autoScrollNoticias) {
            clearInterval(noticiasScrollInterval)

            setNoticiasScrollInterval(getScrollInterval(noticiasScrollDirection))
        }
    }, [noticiasScrollDirection, noticiasScrollInterval])

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">

                    {pessoa && !pessoa.associado.medico && pessoa.comprovante_matricula_pendente && (
                        <Badge>
                            <FiAlertTriangle size={36} />

                            <span dangerouslySetInnerHTML={{ __html: 'Seu comprovante de matrícula encontra-se <b>DESATUALIZADO</b>.' }} />

                            <div className="button-container">
                                <Button className="transparent dark" onClick={() => { history.push('/associado/dados_cadastrais?upd_doc=1') }}>
                                    Atualizar agora
                                </Button>
                            </div>
                        </Badge>
                    )}

                    <DadosCadastrais>
                        <Card className="animated fadeIn delay-100ms">
                            {pessoa ? (
                                <>
                                    <Avatar
                                        image={pessoa.usuario?.avatar?.link || avatarDefault}
                                        className="animated zoomIn faster"
                                    />

                                    <h1 className="nome_completo">
                                        {pessoa.nome}
                                    </h1>
                                    <h1 className="nome_reduzido">
                                        {pessoa.nome_reduzido}
                                    </h1>

                                    <div style={{ gridArea: 'detail_button', justifySelf: 'end' }} className="detail-button">
                                        <Button className="transparent" onClick={() => { history.push('/associado/dados_cadastrais') }}>
                                            <span>Meu cadastro</span>
                                            <FiEye size={18} />
                                        </Button>
                                    </div>

                                    <div style={{ gridArea: 'crm' }} className="crm">
                                        <span>Matrícula: </span>

                                        <b style={{ fontSize: 18 }}>
                                            {pessoa.associado.pessoa.documentos.find(documento => documento.tipo_documento.id === 999).identificador}
                                        </b>
                                    </div>

                                    <div style={{ gridArea: 'status' }}>
                                        <span>Membro: </span>

                                        {pessoa.associado.status_associacao.id === 3 ? (
                                            <>
                                                <span className="blue">SIM</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="red">NÃO</span>
                                            </>
                                        )}
                                    </div>

                                    <div style={{ gridArea: 'data' }}>
                                        {pessoa.associado.status_associacao.id === 3 && pessoa.associado.sindicalizacao.length ? (
                                            <>
                                                <span>Data de cadastro: </span>
                                                <b>{pessoa.associado.sindicalizacao.length ? format(new Date(pessoa.associado.sindicalizacao.filter(sindicalizacao => sindicalizacao.status).sort(arrayUtils.sort.comparisonFunction('data', 'desc'))[0].data), 'dd/MM/yyy') : ''}</b>
                                            </>
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <Spinner />
                            )}
                        </Card>
                    </DadosCadastrais>

                    <DadosExtras>
                        <Card className="animated fadeIn delay-200ms">
                            {pessoa ? (
                                <Financeiro pessoa={pessoa} showDetalhes={showContribuicoes} onIconClick={temPendencias ? null : undefined} />
                            ) : (
                                <Spinner />
                            )}
                        </Card>
                    </DadosExtras>

                    <NoticiasContainer className="animated fadeIn delay-200ms">
                        <h1>Últimas notícias</h1>

                        <div className="news-list">
                            {noticias ? (
                                <>
                                    {noticias.length > 0 ? noticias.slice(0, 10).map(noticia => (
                                        <Noticia
                                            key={noticia.id}
                                            image={noticia.imagem}
                                            title={noticia.titulo}
                                            link={noticia.link}
                                        />
                                    )) : (
                                        <div className="em-construcao">
                                            <FiEyeOff size={30} />
                                            <h2>Nenhuma notícia cadastrada</h2>
                                            <p>Aqui você terá acesso às ultimas notícias publicadas pelo SINDMEPA.</p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    </NoticiasContainer>

                </main>
            </Container>
        </>
    )
}
