import styled, { keyframes } from 'styled-components'

const blink = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`

export const Container = styled.div.attrs({
    className: 'dialog-container'
})`
	button {
		border: 0;
		background-color: transparent;
		cursor: pointer;
	}

	div.minimize {
		height: 40px;
	}

	div.list--active {
		transform: translateX(0);

		@media (max-width: 500px) {
			transform: translateX(50%);
		}
	}

	.mask {
		width: 100%;
		height: ${props => props.height}px;
		overflow: hidden;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
		transition: height 400ms cubic-bezier(0.65, 0.05, 0.36, 1);
		border-radius: 10px;

		.container {
			width: 100%;
			height: ${props => props.height}px;
			display: flex;
			flex-direction: row;
			transition: transform 400ms cubic-bezier(0.65, 0.05, 0.36, 1);

			.conversation {
				width: 100%;
				height: ${props => props.height}px;
				display: flex;
				flex-direction: column;
				background-color: #f5f7fa;

				.btn {
					width: 100px;
					height: 42px;
					align-self: end;

					svg {
						color: #FFF;
					}

					&:hover, 
					&:focus {
						opacity: 0.8;
					}
				}

				@media (max-width: 500px) {
					width: 100vw;
					height: 100vh;
				}
			}

			@media (max-width: 500px) {
				width: 100%;
				height: 100%;
				transform: translateX(0);
			}
		}

		&.readonly {
			height: 500px;

			.container {
				height: 500px;

				.conversation {
					height: 500px;
				}
			}
		}

		.conversation__name {
			font-weight: 800;
		}

		@media(max-width: 560px) {
			.conversation__name {
				display: none;
			}
		}

		@media (max-width: 500px) {
			right: 0;
			width: auto;
			height: 100vh;
		}

		@media (max-width: ${props => props.minWidthToHide}px) {
			display: none;
		}
	}

	.observacao {
		margin: 8px 8px 0;
		font-size: 13px;
	}

	.conversation__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		min-height: 40px;
		border-bottom: 1px solid #ced4da;

		.update-container {
			display: flex;
			align-items: center;
			color: #686868;

			span {
				font-size: 12px;
			}

			svg {
				margin: 0 12px;
				cursor: pointer;
				transition: 300ms;

				&:hover {
					opacity: 0.8;
				}

				&.rotate {
					animation: ${rotate} 1s linear infinite;
				}
			}
		}
	}

	.conversation__header-actions {
		display: flex;
		flex-direction: row;
	}

	.conversation__body {
		height: 100%;
		padding: 0.8rem;
		position: relative;
		overflow-y: auto;
		background-color: #eff2f6;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		scroll-behavior: smooth;

		.loading-message{
			font-weight: 600;
			font-size: 20px;
			color: #888;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			animation: ${blink} 400ms linear infinite alternate;
		}
	}

	.conversation__info {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		padding-left: 0.8rem;
		color: #243366;
	}

	.conversation__footer {
		display: flex;
		flex-direction: column;
		padding: 0.8rem;
		border-top: 1px solid #ced4da;
		gap: 10px;

		.placeholder-text {
			color: #999;
			margin-top: 0;
		}

		input {
			::placeholder {
				color: #0005;
			}

			&:disabled {
				cursor: not-allowed;
			}
		}

		@media(max-width: 700px) {
			flex-direction: column;
		}

		> button {
			border-radius: 6px;
			transition: 300ms;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			background-color: #111827;
			width: 100px;

			svg {
				transition: 300ms;
			}

			&:hover {
				background-color: #111827EE;
				width: 100px;

				> svg {
					color: #FFF;
				}

				> .title {
					display: block;
				}
			}

			&:disabled {
				opacity: 0.6;
				cursor: not-allowed;

				&:hover {
					width: 100px;
					color: #FFF;

					> svg {
						color: #FFF;
					}
				}
			}
		}
	}

	.conversation__write {
		width: 100%;
		border: 0;
		border-radius: 5px;
		border: 1px solid #ced4da;
		font-size: 14px;
		color: #424242;
	}

	input.conversation__write {
		padding: 12px 10px;
	}

	.p-editor-container {
		&.conversation__write {
			.ql-editor {
				background-color: #f5f7fa !important;
			}

			.ql-toolbar {
				border-radius: 4px !important;
			}

			.p-editor-toolbar {
				background-color: #111827 !important;
			}
		}
	}

	@media(max-width: 1000px) {
		.ql-container {
			height: 100px !important;
		}
	}
`
