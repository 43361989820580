import React, { useEffect, useState } from 'react'
import { FaImage } from 'react-icons/fa'
import { format, isBefore } from 'date-fns'
import { extname } from '../../../../util/path'
import { Container, EventoDetalhesContainer, Galeria } from './styles'
import Card from '../../../../components/Card'
import { api } from '../../../../services/api'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import { Textbox } from '../../../../components/Form'

function getStatus(evento) {
    if(isBefore(new Date(), new Date(evento.inicio))) {
        return 'Não iniciado'
    }

    if(isBefore(new Date(evento.fim), new Date())) {
        return 'Encerrado'
    }

    return 'Em curso'
}

const imageExtensions = ['.png', '.jpg', '.gif', '.jpeg', '.webp']

export default function () {
    const [eventos, setEventos] = useState(null)
    const [eventoVisualizacao, setEventoVisualizacao] = useState(null)
    const [fotoPreview, setFotoPreview] = useState(null)
    const [fotosFiltradas, setFotosFiltradas] = useState(null)
    const [search, setSearch] = useState('')

    async function loadEventos() {
        const response = await api.get('evento/publico')

        setEventos(response.map(evt => ({
            id: evt.id,
            nome: evt.nome,
            descricao: evt.descricao,
            periodo: `${format(new Date(evt.inicio), 'dd/MM/yyyy HH:mm')} a ${format(new Date(evt.fim), 'dd/MM/yyyy HH:mm')}`,
            status: getStatus(evt),
            fotos: evt.galeria
                .filter(item => imageExtensions.includes(extname(item.arquivo.link)))
                .map(item => ({
                    arquivo: item.arquivo,
                    descricao: item.descricao
                }))
        })))
    }

    useEffect(() => {
        loadEventos()
    }, [])

    useEffect(() => {
        if(eventoVisualizacao) {
            setFotosFiltradas(eventoVisualizacao.fotos)
        }
    }, [eventoVisualizacao])

    useEffect(() => {
        if(!search) {
            setFotosFiltradas(eventoVisualizacao?.fotos || [])
        } else {
            setFotosFiltradas(eventoVisualizacao?.fotos.filter(foto => foto.descricao.includes(search)) || [])
        }
    }, [search])

    if(!eventos) {
        return <Spinner />
    }

    return (
        <Container>
            <main>
                <Card>
                    <h1>Eventos</h1>

                    <Table 
                        headers={[
                            { name: 'nome', value: 'Evento' },
                            { name: 'periodo', value: 'Período', centered: true },
                            { name: 'status', value: 'Status', centered: true }
                        ]}
                        data={eventos}
                        actions={[
                            {
                                name: 'visualizar',
                                action: setEventoVisualizacao,
                                icon: FaImage,
                                iconSize: 16
                            }
                        ]}
                    />
                </Card>
            </main>

            <Modal
                isOpen={!!eventoVisualizacao}
                handleClose={() => {
                    setEventoVisualizacao(null)
                }}
                title={eventoVisualizacao?.nome}
            >
                <EventoDetalhesContainer>
                    <p>{`Período: ${eventoVisualizacao?.periodo}`}</p>
                    <p dangerouslySetInnerHTML={{ __html: eventoVisualizacao?.descricao || '' }} />

                    <h2>Galeria de fotos</h2>

                    <Textbox 
                        withoutForm
                        onChange={e => { setSearch(e.target.value) }}
                        value={search}
                        label="Busca"
                        name="search"
                        containerStyle={{ maxWidth: 300, margin: 0 }}
                    />

                    {fotosFiltradas?.length ? (
                        <Galeria>
                            {fotosFiltradas.map(foto => (
                                <div key={foto.arquivo.id} onClick={() => { setFotoPreview(foto) }}>
                                    <img src={foto.arquivo.link} alt="" />
                                    <span>{foto.descricao}</span>
                                </div>
                            ))}
                        </Galeria>
                    ) : search ? (
                        <p className="empty-galeria">Nenhuma imagem corresponde ao filtro aplicado.</p>
                    ) : (
                        <p className="empty-galeria">Nenhuma imagem foi incluída neste evento.</p>
                    )}
                </EventoDetalhesContainer>
            </Modal>

            <Modal
                isOpen={fotoPreview}
                handleClose={() => {
                    setFotoPreview(null)
                }}
                title={fotoPreview?.descricao}
            >
                <img src={fotoPreview?.arquivo.link} alt="" style={{ width: '100%' }} />
            </Modal>
        </Container>
    )
}
