import React, { useContext } from 'react'
import { FaFileExcel, FaFilePdf } from 'react-icons/fa'

import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'

import { RelatorioCustomizadoContext } from '../../../../contexts/RelatorioCustomizadoContext'

import { Container } from './styles'

export default function () {
    const {
        relatorios, handleDelete, handlePrepareEdit, handleGerarRelatorio, handleGerarRelatorioPlanilha 
    } = useContext(RelatorioCustomizadoContext)

    return (
        <Container>
            {relatorios ? (
                <Table 
                    headers={[
                        { name: 'descricao', value: 'Relatório' }
                    ]}
                    data={relatorios}
                    emptyLabel="Nenhum relatório customizado"
                    caption="Relatórios customizados"
                    handleDelete={handleDelete}
                    confirmExclusion={{
                        attr: 'descricao',
                        template: 'Deseja realmente excluir o relatório #attr#?'
                    }}
                    handlePrepareEdit={handlePrepareEdit}
                    actions={[
                        {
                            name: 'Gerar em PDF',
                            action: handleGerarRelatorio,
                            icon: FaFilePdf,
                            iconSize: 16
                        },
                        {
                            name: 'Gerar em Planilha',
                            action: handleGerarRelatorioPlanilha,
                            icon: FaFileExcel,
                            iconSize: 16
                        }
                    ]}
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
