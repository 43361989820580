import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Container } from './styles'

import { GlobalContext } from '../../../contexts/GlobalContext'
import ConvenioProvider from '../../../contexts/ConvenioContext'
import DependenteProvider from '../../../contexts/DependenteContext'

import ListaContratados from './ListagemContratados'
import ListaServicos from './ListagemServicos'

export default function () {
    const { user } = useContext(GlobalContext)

    const history = useHistory()

    useEffect(() => {
        if(user?.perfil.id === 2 && user?.associado.status_associacao.id !== 3) {
            toast.warn('Você não pode acessar o Módulo de Serviços e Parcerias. Sua associação não está regular.')

            history.push('/dashboard')
        }
    }, [user])
    
    return (
        <ConvenioProvider>
            <Container>
                <DependenteProvider>
                    <ListaContratados />
                </DependenteProvider>
                
                <ListaServicos />
            </Container>
        </ConvenioProvider>
    )
}
