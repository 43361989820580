import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { FiFile, FiFileText } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'
import ibge from '../../../../../services/ibge'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar, Textbox
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'
import rangeMonthGif from '../../../../../assets/images/gifs/periodo-mes.gif'

const initialValues = {
    situacao: '',
    cidade: '',
    competencia: '',
    instituicao: '',
    'tipo-associado': '',
    'tipo-beneficiario': '',
    'data-nascimento': '',
    aniversario: '',
    crm: '',
    pagamento: ''
}

const validation = Yup.object().shape({
    situacao: Yup.string().ensure(),
    cidade: Yup.string().ensure(),
    competencia: Yup.string().ensure(),
    instituicao: Yup.string().ensure(),
    'tipo-associado': Yup.string().ensure(),
    'tipo-beneficiario': Yup.string().ensure(),
    'data-nascimento': Yup.string().nullable(),
    aniversario: Yup.string().nullable(),
    crm: Yup.string().nullable(),
    pagamento: Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de Pagamentos de Plano de Saúde'

export default function (props) {
    const [cidades, setCidades] = useState([])
    const [instituicoes, setInstituicoes] = useState([])
    const [isSubmittingPlanilha, setIsSubmittingPlanilha] = useState(false)

    useEffect(() => {
        async function loadCidades() {
            const { data } = await ibge.get('estados/PA/municipios')

            setCidades(data.map(({ nome }) => nome).sort())
        }

        loadCidades()
    }, [])

    useEffect(() => {
        async function loadInstituicoes() {
            const response = await api.get('instituicao')

            setInstituicoes(response)
        }

        loadInstituicoes()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            situacao,
            cidade,
            'data-nascimento': nascimento,
            aniversario,
            competencia,
            instituicao,
            'tipo-associado': tipoAssociado,
            'tipo-beneficiario': tipoBeneficiario,
            crm,
            pagamento
        } = values

        const nascimentoInicio = nascimento ? nascimento[0] : null
        const nascimentoFim = nascimento ? nascimento[1] : null
        const aniversarioInicio = aniversario ? aniversario[0] : null
        const aniversarioFim = aniversario ? aniversario[1] : null
        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null
        const competenciaInicio = competencia ? competencia[0] : null
        const competenciaFim = competencia ? competencia[1] : null

        let filtros = ''

        if (tipoAssociado) {
            filtros += `Médicos: ${tipoAssociado.label}, `
        }

        if (tipoBeneficiario) {
            filtros += `Tipo beneficiário: ${tipoBeneficiario.label}, `
        }

        if (situacao) {
            filtros += `Status de pagamento: ${situacao.label}, `
        } else {
            filtros += 'Status de pagamento: Todos, '
        }

        if (instituicao) {
            filtros += `Instituição: ${instituicao.label}, `
        }

        if (nascimentoInicio) {
            filtros += `Data nasc.: ${format(new Date(nascimentoInicio), 'dd/MM/yyyy')} a ${nascimentoFim !== null ? format(new Date(nascimentoFim), 'dd/MM/yyyy') : format(new Date(nascimentoInicio), 'dd/MM/yyyy')}, `
        }

        if (aniversarioInicio) {
            filtros += `Aniversário: ${format(new Date(aniversarioInicio), 'dd/MM/yyyy')} a ${aniversarioFim !== null ? format(new Date(aniversarioFim), 'dd/MM/yyyy') : format(new Date(aniversarioInicio), 'dd/MM/yyyy')}, `
        }

        if (cidade) {
            filtros += `Cidade: ${cidade.label}, `
        }


        try {
            const response = await api.post('/relatorio', {
                relatorio: 'RelatorioPagamentosPlanoSaude',
                parametros: {
                    situacao: situacao?.value || '',
                    cidade: cidade?.value || '',
                    dataNascimentoInicio: nascimentoInicio !== null ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : '',
                    dataNascimentoFim: nascimentoFim !== null ? format(new Date(nascimentoFim), 'yyyy-MM-dd') : (nascimentoInicio !== null) ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : '',
                    dataAniversarioInicio: aniversarioInicio !== null ? format(new Date(aniversarioInicio), 'yyyy-MM-dd') : '',
                    dataAniversarioFim: aniversarioFim !== null ? format(new Date(aniversarioFim), 'yyyy-MM-dd') : (aniversarioInicio !== null) ? format(new Date(aniversarioInicio), 'yyyy-MM-dd') : '',
                    dataPagamentoInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    dataPagamentoFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    competenciaInicio: competenciaInicio !== null ? format(new Date(competenciaInicio), 'yyyy-MM-dd') : '',
                    competenciaFim: competenciaFim !== null ? format(new Date(competenciaFim), 'yyyy-MM-dd') : (competenciaInicio !== null) ? format(new Date(competenciaInicio), 'yyyy-MM-dd') : '',
                    instituicao: instituicao?.value || '',
                    tipoAssociado: tipoAssociado?.value || '',
                    tipoBeneficiario: tipoBeneficiario?.value || '',
                    crm: crm || '',
                    filtros: filtros !== '' ? filtros.slice(0, -2) : ''
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    const handleSubmitPlanilha = useCallback(async (values, resetForm) => {
        setIsSubmittingPlanilha(true)

        const {
            situacao,
            cidade,
            'data-nascimento': nascimento,
            aniversario,
            competencia,
            instituicao,
            'tipo-associado': tipoAssociado,
            'tipo-beneficiario': tipoBeneficiario,
            crm,
            pagamento
        } = values

        const nascimentoInicio = nascimento ? nascimento[0] : null
        const nascimentoFim = nascimento ? nascimento[1] : null
        const aniversarioInicio = aniversario ? aniversario[0] : null
        const aniversarioFim = aniversario ? aniversario[1] : null
        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null
        const competenciaInicio = competencia ? competencia[0] : null
        const competenciaFim = competencia ? competencia[1] : null

        try {
            const response = await api.post('/relatorio/plano_saude_pagamentos_excel', {
                relatorio: 'RelatorioPlanoPagamentos',
                parametros: {
                    status: situacao?.value || null,
                    cidade: cidade?.value || null,
                    dataNascimentoInicio: nascimentoInicio !== null ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : null,
                    dataNascimentoFim: nascimentoFim !== null ? format(new Date(nascimentoFim), 'yyyy-MM-dd') : (nascimentoInicio !== null) ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : null,
                    dataAniversarioInicio: aniversarioInicio !== null ? format(new Date(aniversarioInicio), 'yyyy-MM-dd') : null,
                    dataAniversarioFim: aniversarioFim !== null ? format(new Date(aniversarioFim), 'yyyy-MM-dd') : (aniversarioInicio !== null) ? format(new Date(aniversarioInicio), 'yyyy-MM-dd') : null,
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    competenciaInicio: competenciaInicio !== null ? format(new Date(competenciaInicio), 'yyyy-MM-dd') : null,
                    competenciaFim: competenciaFim !== null ? format(new Date(competenciaFim), 'yyyy-MM-dd') : (competenciaInicio !== null) ? format(new Date(competenciaInicio), 'yyyy-MM-dd') : null,
                    instituicao: instituicao?.value || null,
                    tipoAssociado: tipoAssociado?.value || null,
                    tipoBeneficiario: tipoBeneficiario?.value || null,
                    crm: crm || null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            if (response.type === 'application/json') {
                const fr = new FileReader()

                fr.onload = function (e) {
                    const json = e.target.result
                    toast.warn(JSON.parse(json).msg)
                }
                fr.readAsText(response)
            } else {
                downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')
            }


            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }

        setIsSubmittingPlanilha(false)
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm, values
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="situacao"
                            label="Situação"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Efetuados', value: 1 },
                                { label: 'Pendentes', value: 2 }
                            ]}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Calendar
                            name="competencia"
                            dateFormat="mm/yy"
                            view="month"
                            label="Competência"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f2' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeMonthGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="instituicao"
                            label="Instituição"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={instituicoes.map(instituicao => ({
                                label: `${instituicao.nome_fantasia} ${instituicao.sigla ? `(${instituicao.sigla})` : ''}`,
                                value: instituicao.id
                            }))}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Calendar
                            name="pagamento"
                            label="Data de Pagamento"
                            maxDate={new Date()}
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Textbox
                            name="crm"
                            label="Matrícula"
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Select
                            name="cidade"
                            label="Cidade"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={cidades.map(cidade => ({
                                label: cidade,
                                value: cidade
                            }))}
                            containerStyle={{ gridArea: 'f6' }}
                        />

                        <Select
                            name="tipo-associado"
                            label="Tipo Associado"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Associado', value: 3 },
                                { label: 'Ex-associado', value: 5 }
                            ]}
                            containerStyle={{ gridArea: 'f7' }}
                        />

                        <Select
                            name="tipo-beneficiario"
                            label="Tipo Beneficiário"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Médico', value: 1 },
                                { label: 'Acâdemico', value: 2 }
                            ]}
                            containerStyle={{ gridArea: 'f8' }}
                        />

                        <Calendar
                            name="data_nascimento"
                            dateFormat="dd/mm/yy"
                            label="Data de Nascimento"
                            selectionMode="range"
                            maxDate={new Date(new Date().getFullYear() - 14, 11, 31)}
                            yearRange={`${new Date().getFullYear() - 100}:${new Date().getFullYear() - 14}`}
                            containerStyle={{ gridArea: 'f9' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Calendar
                            name="aniversario"
                            dateFormat="dd/mm"
                            label="Data de Aniversário"
                            yearNavigator={false}
                            containerClass="header-without-year"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f10' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => handleSubmitPlanilha(values, resetForm)}
                                loading={isSubmittingPlanilha}
                                className="white"
                            >
                                Gerar planilha
                                <FiFileText size={20} />
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
