import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;
	}
`

export const EventoDetalhesContainer = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 12px;

	h2 {
		font-size: 16px;
		font-weight: 700;
		margin-top: 16px;
		margin-bottom: 10px;
	}

	.empty-galeria {
		font-style: italic;
	}
`

export const Galeria = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 16px;

	> div {
		display: flex;
		flex-direction: column;
		gap: 6px;
		width: 100%;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}

		img {
			width: 100%;
			height: 160px;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	@media(max-width: 1000px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media(max-width: 800px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media(max-width: 600px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media(max-width: 500px) {
		display: flex;
		flex-direction: column;
	}
`
