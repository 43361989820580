import styled from 'styled-components'

export const Container = styled.div`
	margin: 15px 8px 5px;
	display: grid;
	grid-template-columns: 1.9fr 1.1fr 1fr;
	grid-template-rows: 130px auto;
	grid-template-areas: 
		'principal principal principal'
		'chapas periodo progresso';
	gap: 0 24px;
	min-height: 70vh;

	.principal {
		grid-area: principal;

		> h1 {
			font-size: 16px;
			font-weight: 500;
			margin: 8px 0;
		}

		> h2 {
			font-size: 24px;
			font-weight: 400;
			margin: 8px 0;
		}

		> h3 {
			font-size: 18px;
			display: flex;
			gap: 0 8px;
			align-items: center;
		}

		svg:hover {
			cursor: default;
			opacity: unset;
		}
	}

	.detalhe {
		h1 {
			margin: 8px 0;
			font-size: 16px;
			font-weight: 500;
		}

		p {
			line-height: 24px;
		}
	}

	.table-container {
		margin-top: 8px;
	}
`
