import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'

import { Container } from './styles'

import { TutorialContext } from '../../../../../../contexts/TutorialContext'

import { api } from '../../../../../../services/api'

import {
    File, Textarea, Textbox, Button 
} from '../../../../../../components/Form'

const baseInitialValues = {
    descricao: '',
    detalhes: '',
    imagem: null
}

export default function () {
    const { handleSubmitAssunto, editAssunto } = useContext(TutorialContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)

    useEffect(() => {
        if(editAssunto) {
            setInitialValues({
                ...editAssunto,
                imagem: editAssunto.imagem?.id || null
            })
        }
    }, [editAssunto])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitAssunto}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <File
                            name="imagem"
                            label="Imagem"
                            format="square"
                            onSuccess={fileId => setFieldValue('imagem', fileId)}
                            previewSize={['100%', '242px']}
                            accept={['image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp']}
                            getPreloadImage={async () => {
                                if(values.imagem) {
                                    const arquivo = await api.get(`arquivo/${values.imagem}`)
                                    return arquivo?.link
                                }
                            }}
                        />

                        <Textbox 
                            name="descricao"
                            label="Nome do assunto"
                        />

                        <Textarea 
                            name="detalhes"
                            label="Detalhes"
                        />

                        <Button type="submit" className="transparent">
                            {editAssunto ? 'Salvar alterações' : 'Cadastrar'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
