import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 30px 80px 80px auto;
		min-height: 80vh;
		align-items: start;

		.button-container {
			grid-column: 1/span 3;
			display: flex;
			justify-content: flex-end;
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
		}
	}
`
