import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { Item } from './styles'

export default function ({ atendente, draggableId, ...props }) {
    return (
        <Draggable draggableId={draggableId} {...props}>
            {provided => (
                <Item
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <span>{`${atendente.nome}${atendente.usuario ? ` (${atendente.usuario?.perfil.descricao})` : ''}`}</span>
                </Item>
            )}
        </Draggable>
    )
}
