import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { format, isSameDay } from 'date-fns'

import Yup from '../../services/yup'

import Card from '../../components/Card'
import { Button, Textbox } from '../../components/Form'

import { Container, CertificadoContainer, NaoEncontradoContainer } from './styles'

import { api } from '../../services/api'

import avatarDefault from '../../assets/images/avatar.png'
import { stringToDate } from '../../util/date'

export default function () {
    const [certificado, setCertificado] = useState(null)

    async function handleVerificar(values) {
        try {
            const { codigo } = values

            const response = await api.get(`evento/certificado/${codigo}`)

            if (response) {
                response.evento.inicio = stringToDate(response.evento.inicio)
                response.evento.fim = stringToDate(response.evento.fim)

                setCertificado(response)
            } else {
                setCertificado(false)
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <main>
                <Card expandOffset={500}>
                    {certificado === null ? (
                        <>
                            <h1>Verificação de certificados</h1>

                            <Formik
                                initialValues={{ codigo: '' }}
                                onSubmit={handleVerificar}
                                validationSchema={Yup.object({
                                    codigo: Yup.string().required('Informe o código localizado no certificado.').length(10, 'O código digitado deve ter 10 dígitos. Verifique e tente novamente.')
                                })}
                            >
                                {({ isSubmitting }) => (
                                    <Form className="animated fadeIn">
                                        <Textbox name="codigo" label="Código de verificação" />

                                        <Button
                                            className="transparent"
                                            type="submit"
                                            loading={isSubmitting}
                                            loadingText="Buscando certificado..."
                                        >
                                            Verificar
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    ) : certificado === false ? (
                        <NaoEncontradoContainer>
                            <h1>Verificação de certificados</h1>

                            <p className="certificado-nao-encontrado">O código digitado não corresponde a nenhum certificado gerado pelo sistema Gestão na Mão.</p>

                            <Button className="transparent" onClick={() => { setCertificado(null) }}>
                                Verificar novamente
                            </Button>
                        </NaoEncontradoContainer>
                    ) : (
                        <CertificadoContainer>
                            <div className="content">
                                <p className="title">Certificado encontrado!</p>

                                <img src={certificado.pessoa?.usuario?.avatar?.link || avatarDefault} alt="Avatar" />

                                <h1>{certificado.pessoa.nome}</h1>

                                <h2>{certificado.evento.nome}</h2>

                                <div>
                                    <div dangerouslySetInnerHTML={{
                                        __html: isSameDay(certificado.evento.inicio, certificado.evento.fim)
                                            ? `Data: ${format(certificado.evento.inicio, 'dd/MM/yyyy')}`
                                            : `Período: ${format(certificado.evento.inicio, 'dd/MM/yyyy')} a ${format(certificado.evento.fim, 'dd/MM/yyyy')}`
                                    }}
                                    />
                                </div>

                                <Button className="transparent" onClick={() => { setCertificado(null) }}>
                                    Verificar novamente
                                </Button>
                            </div>
                        </CertificadoContainer>
                    )}
                </Card>
            </main>
        </Container>
    )
}
