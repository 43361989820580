import styled from 'styled-components'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	grid-column: 2/span 3;
	margin-top: 24px;

	h2 {
		font-size: 18px;
		margin: 0 8px 12px;
		font-weight: 500;
		grid-column: 2/span 3;
	}

	.button-container {
		display: flex;
		justify-content: end;
		margin-top: 36px;
	}

	@media(max-width: 900px) {
		grid-column: unset;
	}
`
