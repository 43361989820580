import React, { useContext } from 'react'

import FormAdvogado from '../../../../components/FormAdvogado'
import { AdvogadoContext } from '../../../../contexts/AdvogadoContext'

import { Container } from './styles'

export default function () {
    const { convenio, reload, advogadoEdit } = useContext(AdvogadoContext)

    return (
        <Container>
            <FormAdvogado
                convenio={convenio}
                editItem={advogadoEdit}
                reload={reload} 
            />
        </Container>
    )
}
