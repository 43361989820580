import React from 'react'

import { Container } from './styles'

export default function Card({
    className = '', style = {}, disabled = false, light = false, id, expandOffset = 440, onContextMenu, onClick, children, ...props 
}) {
    return (
        <Container
            light={light}
            expandOffset={expandOffset}
            className={className}
            style={style}
            disabled={disabled}
            id={id}
            onContextMenu={onContextMenu} 
            onClick={onClick}
            {...props}
        >
            {children}
        </Container>
    )
}
