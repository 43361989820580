import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'

import { Button } from '../../../../components/Form'
import FormEndereco from '../../../../components/FormEndereco/v2'
import formUtils from '../../../../util/form'
import { AssociacaoContext } from '../../../../contexts/AssociacaoContext'
import yup from '../../../../services/yup'

const baseInitialValues = {
    bairro: '',
    cep: '',
    cidade: '',
    complemento: '',
    numero: '',
    rg: '',
    logradouro: ''
}

const validation = yup.object({
    cep: yup.string().cep().required('Campo obrigatório.'),
    uf: yup.string().ensure().required('Selecione a UF.'),
    cidade: yup.string().ensure().when('uf', (uf, schema) => uf
        ? schema.required('Selecione a cidade.')
        : schema.required('Primeiro selecione uma UF.')),
    bairro: yup.string().required('Especifique o bairro.'),
    logradouro: yup.string().required('Especifique o logradouro.'),
    numero: yup.string().required('Digite o número.'),
    complemento: yup.string().nullable()
})

export function Endereco() {
    const { tempData, syncTempData, avancarEtapa } = useContext(AssociacaoContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function handleSubmit(values) {
        const data = formUtils.extractFormValues(values)

        syncTempData({
            endereco: data
        })

        avancarEtapa()
    }

    useEffect(() => {
        if(tempData.endereco) {
            const { cidade, uf } = tempData.endereco

            setInitialValues({
                ...tempData.endereco,
                cidade: {
                    label: cidade,
                    value: cidade
                },
                uf: {
                    label: uf,
                    value: uf
                }
            })
        }
    }, [tempData])

    return (
        <div>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validation}
            >
                {({ setFieldValue }) => (
                    <Form className="flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 pb-6">
                        <FormEndereco 
                            onSubmit={handleSubmit}
                            storageRecover={{ storage: process.env.REACT_APP_LOCAL_STORAGE_DADOS_ASSOCIACAO, prop: 'endereco' }}
                            initialValues={initialValues}
                            setFieldValue={setFieldValue}
                            buttonContent={(
                                <>
                                    Continuar
                                </>
                            )}
                        />

                        <div className="sm:col-span-2 md:col-span-4 flex justify-end mt-3 border-t border-gray-300">
                            <Button type="submit" className="transparent w-full sm:w-fit">
                                Continuar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
} 
