import React, { useContext } from 'react'
import { isBefore } from 'date-fns'
import {
    FaCheck, FaEdit, FaTimes, FaUserAltSlash
} from 'react-icons/fa'

import Spinner from '../../../../../../components/Spinner'
import Table from '../../../../../../components/Table'

import { AtendimentoContext } from '../../../../../../contexts/AtendimentoContext'
import { GlobalContext } from '../../../../../../contexts/GlobalContext'

import { Container } from './styles'

export default function () {
    const { user } = useContext(GlobalContext)
    const { agendamentos, handleAtualizarStatus, setAgendamentoEdit } = useContext(AtendimentoContext)

    const eAssociado = [2, 3, 9, 27, 29, 28, 34, 33].includes(user.perfil.id)
    const eAdvogado = user.perfil.id === 20
    const naoAgendado = item => item.status.id !== 1
    const jaIniciado = item => eAssociado && isBefore(new Date(`${item.data} ${item.inicio}`), new Date())
    const naoAtendente = item => eAdvogado && item.atendente.id !== user.pessoa.id
    const advogado = item => eAdvogado && isBefore(new Date(), new Date(`${item.data} ${item.inicio}`))

    return (
        <Container>
            {agendamentos ? (
                <Table
                    headers={[
                        { name: 'associado', value: 'Associado' },
                        { name: 'nome_atendente', value: 'Atendente' },
                        { name: 'data_formatada', value: 'Data', centered: true },
                        { name: 'status_descricao', value: 'Status', centered: true }
                    ]}
                    data={agendamentos || []}
                    actions={[
                        {
                            action: setAgendamentoEdit,
                            name: 'Editar',
                            icon: FaEdit,
                            iconSize: 16,
                            color: '#2196f3',
                            checkDisabled: item => naoAgendado(item)
                                || naoAtendente(item)
                                || jaIniciado(item),
                            disabledTooltip: 'Ação não disponível'
                        },
                        {
                            action: item => { handleAtualizarStatus(item, 2) },
                            name: 'Não compareceu',
                            icon: FaUserAltSlash,
                            iconSize: 16,
                            color: '#424242',
                            confirmation: 'Deseja confirmar que o associado NÃO COMPARECEU ao atendimento presencial?',
                            checkDisabled: item => eAssociado
                                || naoAgendado(item)
                                || naoAtendente(item)
                                || jaIniciado(item)
                                || advogado(item),
                            disabledTooltip: 'Ação não disponível'
                        },
                        {
                            action: item => { handleAtualizarStatus(item, 4) },
                            name: 'Cancelar',
                            icon: FaTimes,
                            iconSize: 16,
                            color: '#f44336',
                            confirmation: 'Deseja realmente CANCELAR este atendimento presencial?',
                            checkDisabled: item => naoAgendado(item)
                                || naoAtendente(item)
                                || jaIniciado(item),
                            disabledTooltip: 'Ação não disponível'
                        },
                        {
                            action: item => { handleAtualizarStatus(item, 3) },
                            name: 'Atendido',
                            icon: FaCheck,
                            iconSize: 16,
                            color: '#4caf50',
                            confirmation: 'Deseja realmente REGISTRAR este atendimento presencial?',
                            checkDisabled: item => eAssociado
                                || naoAgendado(item)
                                || naoAtendente(item)
                                || jaIniciado(item)
                                || advogado(item),
                            disabledTooltip: 'Ação não disponível'
                        }
                    ]}
                    emptyLabel="Nenhum atendimentio presencial registrado"
                    legends={[
                        {
                            color: '#fff59d',
                            text: 'Agendamentos passados'
                        },
                        {
                            color: '#c8e6c9',
                            text: 'Atendimentos realizados'
                        }
                    ]}
                    filterable
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
