import React, {
    useCallback, useEffect, useState, useRef
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import cepPromise from 'cep-promise'
import ibge from '../../services/ibge'

import Yup from '../../services/yup'

import masks from '../../util/masks'
import estadosCidades from '../../util/estadosCidades'

import {
    Textbox, Button, Select
} from '../Form'
import ActionFooter from '../ActionFooter'

import { Container } from './styles'

export default function ({
    onSubmit, defaultValues, buttonContent, required = true, buttonDisabled = false, title, ...props
}) {
    const mounted = useRef(true)

    const [validation, setValidation] = useState(Yup.object({
        cep: Yup.string().cep().required('Campo obrigatório.'),
        uf: Yup.string().ensure().required('Selecione a UF.'),
        cidade: Yup.string().ensure().when('uf', (uf, schema) => uf
            ? schema.required('Selecione a cidade.')
            : schema.required('Primeiro selecione uma UF.')),
        bairro: Yup.string().required('Especifique o bairro.'),
        rua: Yup.string().required('Especifique o logradouro.'),
        numero: Yup.string().required('Digite o número.'),
        complemento: Yup.string().nullable()
    }))
    const [UFs, setUFs] = useState([])
    const [cidades, setCidades] = useState([])
    const [loadingData, setLoadingData] = useState(false)
    const [initialValues, setInitialValues] = useState(defaultValues || {
        cep: '',
        uf: '',
        cidade: '',
        bairro: '',
        rua: '',
        numero: '',
        complemento: ''
    })

    async function loadCidades(uf) {
        try {
            if (!uf) {
                return
            }

            const { data } = await ibge.get(`estados/${uf}/municipios`)

            setCidades(data.map(({ nome }) => nome).sort())
        } catch (e) {
            const { cidades: cidadesUf } = estadosCidades.find(item => item.sigla === uf)
            setCidades(cidadesUf.sort())
        }
    }

    useEffect(() => {
        if (defaultValues) {
            const { uf, cidade } = defaultValues

            loadCidades(uf, cidade)

            setInitialValues({
                ...defaultValues,
                uf: { label: uf, value: uf },
                cidade: { label: cidade, value: cidade }
            })
        }
    }, [defaultValues])

    useEffect(() => {
        if (!required) {
            setValidation(Yup.object({
                cep: Yup.string().cep(),
                uf: Yup.string().ensure(),
                cidade: Yup.string().ensure(),
                bairro: Yup.string(),
                rua: Yup.string(),
                numero: Yup.string(),
                complemento: Yup.string()
            }))
        }
    }, [required])

    async function loadUFs() {
        try {
            const { data } = await ibge.get('estados')

            if (mounted.current) {
                setUFs(data.map(({ sigla }) => sigla).sort())
            }
        } catch (e) {
            const siglas = estadosCidades.map(item => item.sigla)

            setUFs(siglas.sort())
        }
    }

    useEffect(() => {
        loadUFs()

        return () => { mounted.current = false }
    }, [])

    const handleCEPChange = useCallback(async (cep, setFieldValue, resetForm) => {
        if (cep.length === 9 && !isNaN(Number(cep.replace('-', '')))) {
            setLoadingData(true)
            setFieldValue('cep', cep)

            try {
                const response = await cepPromise(cep.replace('-', ''))
                const {
                    state, city, neighborhood, street
                } = response

                await loadCidades(state)

                setInitialValues({
                    cep,
                    uf: { label: state, value: state },
                    cidade: { label: city, value: city },
                    bairro: neighborhood,
                    rua: street,
                    numero: '',
                    complemento: ''
                })
            } catch (err) {
                if (err.errors) {
                    toast.error('CEP não encontrado. Verifique o valor digitado.')
                    resetForm()

                    setInitialValues({
                        ...initialValues,
                        uf: { label: '', value: '' },
                        cidade: { label: '', value: '' }
                    })

                    setCidades([])
                }
            }

            setLoadingData(false)
        }
    }, [initialValues])

    const handleSelectChange = useCallback((selected, field) => {
        const { value } = selected

        if (field === 'uf') {
            setInitialValues({
                ...initialValues,
                uf: { label: value, value },
                cidade: { label: '', value: '' }
            })

            loadCidades(value)
        } else {
            setInitialValues({
                ...initialValues,
                cidade: { label: value, value }
            })
        }
    }, [initialValues])

    function handleSubmit(values, formikBag) {
        if (onSubmit) {
            onSubmit(values, formikBag)
        }

        formikBag.setSubmitting(false)
    }

    return (
        <Container {...props}>
            {title && <h2>{title}</h2>}

            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, resetForm }) => (
                    <Form>
                        <Textbox
                            label="CEP"
                            name="cep"
                            mask={masks.zipcode}
                            inputMode="numeric"
                            onChange={e => handleCEPChange(e.target.value, setFieldValue, resetForm)}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Select
                            name="uf"
                            label="UF"
                            onChange={(selected, actionMeta) => handleSelectChange(selected, actionMeta.name, setFieldValue)}
                            options={UFs.map(uf => ({
                                label: uf,
                                value: uf
                            }))}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="cidade"
                            label="Cidade"
                            onChange={(selected, actionMeta) => handleSelectChange(selected, actionMeta.name, setFieldValue)}
                            options={cidades.map(cidade => ({
                                label: cidade,
                                value: cidade
                            }))}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Textbox label="Bairro" name="bairro" loading={loadingData} containerStyle={{ gridArea: 'f4' }} />

                        <Textbox label="Logradouro" name="rua" loading={loadingData} containerStyle={{ gridArea: 'f5' }} />

                        <Textbox label="Número" name="numero" containerStyle={{ gridArea: 'f6' }} />

                        <Textbox label="Complemento" name="complemento" containerStyle={{ gridArea: 'f7' }} />

                        {buttonContent && (
                            <ActionFooter style={{ gridArea: 'button' }}>
                                <Button type="submit" loading={isSubmitting} disabled={buttonDisabled} className="transparent">
                                    {buttonContent}
                                </Button>
                            </ActionFooter>
                        )}
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
