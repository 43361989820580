import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			> h1 {
				margin: 0 8px;
			}

			> p {
				margin-top: 10px;
				margin-bottom: 18px;
				margin-left: 9px;
			}
		}

		form {
			display: flex;
			flex-direction: column;

			.select {
				max-width: 400px;
			}

			button {
				align-self: end;
			}
		}

		@media(max-width: 440px) {
			padding: 20px 0;
		}
	}
`
