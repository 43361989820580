import styled from 'styled-components'

export const Container = styled.div`
	main {
		display: flex;
		justify-content: center;
		padding: 30px;

		.card {
			max-width: 1000px;
			width: 100%;
			min-height: 60vh;

			> h1{
				grid-column: 1/span 3;
			}

		}
	}
`
