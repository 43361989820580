import React, { useContext, useEffect } from 'react'
import { FiPlus, FiTrash } from 'react-icons/fi'

import { Container } from './styles'
import Table from '../../../../../components/Table'
import { ProcessoGerenciamentoContext } from '../ProcessoGerenciamentoContext'
import Spinner from '../../../../../components/Spinner'
import { Button } from '../../../../../components/Form'
import Modal from '../../../../../components/Modal'
import Cadastro from './Cadastro'
import { loadTips } from '../../../../../util/tip'
import { TipContext } from '../../../../../contexts/TipContext'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)
    const {
        setoresDisponiveis, showCadastroSetor, setShowCadastroSetor, handleRemoverSetor
    } = useContext(ProcessoGerenciamentoContext)

    useEffect(() => {
        loadTips(setCodigo, 'form_processo_setores')
    }, [])

    if (!setoresDisponiveis) {
        return <Spinner />
    }

    return (
        <>
            <Container>
                <p className="tip">{tips?.formulario}</p>

                <Button className="transparent" onClick={() => { setShowCadastroSetor(true) }}>
                    Novo Grupo
                    <FiPlus size={16} />
                </Button>

                <Table
                    headers={[
                        { name: 'descricao', value: 'Grupo' }
                    ]}
                    data={setoresDisponiveis}
                    actions={[
                        {
                            action: handleRemoverSetor,
                            name: 'Remover',
                            icon: FiTrash,
                            iconSize: 16,
                            confirmation: 'Deseja realmente remover este grupo?'
                        }
                    ]}
                />
            </Container>

            <Modal
                isOpen={showCadastroSetor}
                handleClose={() => { setShowCadastroSetor(false) }}
                title="Cadastro de grupo"
            >
                <Cadastro />
            </Modal>
        </>
    )
}
