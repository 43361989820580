import styled, { css } from 'styled-components'

import { Container as Card } from '../../components/Card/styles'

export const Container = styled(Card).attrs({
	className: 'box-link no-responsive'
})`
	padding: 0;
	width: 100%;

	@media(max-width: 600px) {
		width: calc(100% - 16px);
	}

	&:hover {
		background-color: #3c4a5e;
		transition: all 200ms;
	}

	> a,
	> div {
		display: grid;
		grid-template-columns: 26px auto;
		grid-column-gap: 16px;
		width: 100%;
		padding: 10px 16px;
		border-radius: 12px;

		h1 {
			margin-bottom: 6px;	
			font-size: 14px;
			color: #fff;
		}

		svg {
			transition: 300ms;
			color: #fff;
			grid-row-start: 1;
			grid-row-end: 3;
			align-self: center;
		}

		.subdescricao {
			color: #fffa;
			font-size: 11px;
		}
	}
`
