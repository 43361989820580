import React, { useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'

import Spinner from '../../../components/Spinner'
import Modal from '../../../components/Modal'
import FormEndereco from '../../../components/FormEndereco'

import stringUtils from '../../../util/string'

import { api, authHeaders } from '../../../services/api'

import { Container } from './styles'
import masks from '../../../util/masks'

const boxName = 'Endereço'

const baseInitialValues = {
    cep: '',
    uf: '',
    cidade: '',
    bairro: '',
    rua: '',
    numero: '',
    complemento: ''
}

export default function ({ pessoa, reload, editable = true }) {
    const [editMode, setEditMode] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    function handlePrepareEdit() {
        const {
            cep, uf, cidade, bairro, rua, numero, complemento
        } = pessoa.endereco

        setInitialValues({
            cep: masks.maskApply.cep(cep),
            uf,
            cidade,
            bairro,
            rua,
            numero,
            complemento: complemento || ''
        })

        setEditMode(true)
    }

    async function handleEdit(values) {
        setSubmitting(true)

        values.uf = values.uf.value
        values.cidade = values.cidade.value

        try {
            await api.put(`endereco/${pessoa.endereco.id}`, values, authHeaders())

            toast.success('Endereço atualizado.')

            setEditMode(false)

            setInitialValues(baseInitialValues)

            reload()
        } catch (e) {
            console.log(e)

            toast.error('Erro ao atualizar o endereço.')
        }

        setSubmitting(false)
    }

    return (
        <>
            <Container className="animated fadeInUp delay-300ms no-responsive">
                <h1>{boxName}</h1>

                {editable ? (
                    <FiEdit
                        size={20}
                        data-tip="Editar"
                        data-for="tooltip"
                        onClick={handlePrepareEdit}
                    />
                ) : (
                    <div />
                )}

                {pessoa ? (
                    <div>
                        {Object.entries(pessoa.endereco).filter(([key]) => key !== 'id').map(([key, value]) => (
                            <span key={key}>
                                <b>{['cep', 'uf'].includes(key) ? key.toUpperCase() : key === 'numero' ? 'Número' : stringUtils.capitalize(key)}</b>
                                :
                                {' '}
                                {value}
                            </span>
                        ))}
                    </div>
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={editMode}
                handleClose={() => setEditMode(false)}
                title={boxName}
            >
                <FormEndereco
                    onSubmit={handleEdit}
                    buttonDisabled={submitting}
                    buttonContent={(
                        <>
                            Salvar alterações
                            <FaRegSave color="#424242" />
                        </>
                    )}
                    defaultValues={initialValues}
                />
            </Modal>
        </>
    )
}
