import React, { useContext, useEffect, useRef } from 'react'
import { useField } from 'formik'
import { FiHelpCircle } from 'react-icons/fi'
import { FaQuestionCircle } from 'react-icons/fa'

import { Textarea, TextareaContainer } from './styles'
import { TipContainer } from '../styles'
import { TipContext } from '../../../contexts/TipContext'

export default function ({
    label,
    icon,
    containerClass = '',
    style = {},
    containerStyle = {},
    withoutForm,
    tip,
    initialValue,
    ...props 
}) {
    const Icon = icon?.Icon

    let field
    let meta

    const input = useRef()
    
    const { tips, handleSpeak } = useContext(TipContext)

    if(!withoutForm) {
        [field, meta] = useField(props)
    }

    const tip2 = tips.campos?.find(item => item.campo === field?.name || item.campo === props.id || props.name === item.campo || props.id === item.campo)
    const tipText = tip2?.texto
    const tipAvatarText = tip2?.texto_avatar

    useEffect(() => {
        if(props.focused) {
            input.current.focus()
        }
    })
	
    return (
        <TextareaContainer className={containerClass} style={containerStyle}>
            <span className="label">{label}</span>

            <Textarea
                {...field}
                {...props}
                ref={input}
                defaultValue={initialValue}
                style={{ ...style, paddingRight: icon ? 42 : 15 }} 
            />

            {icon && <Icon {...icon.props} size={icon.props?.size || 14} />}

            {!withoutForm && meta.touched && meta.error ? (
                <span className="error">{meta.error}</span>
            ) : null}

            {tip && (
                <div className="tip" dangerouslySetInnerHTML={{ __html: tip }} />
            )}

            {tipText ? (
                <>
                    <FiHelpCircle size={14} onMouseEnter={() => handleSpeak(tipAvatarText || tipText)} />

                    <TipContainer>
                        <FaQuestionCircle size={13} />
                        <span>{tipText}</span>
                    </TipContainer>
                </>
            ) : null}
        </TextareaContainer>
    )
}
