import styled from 'styled-components'

export const Container = styled.article`
	min-height: 78vh;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 45px 30px 30px;
	align-items: start;

	.spinner-container {
		padding: 50px 0;
		width: 100%;
		align-self: center;
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
		padding: 14px 14px 30px;
	}

	@media(min-width: 601px) {
		margin-left: 50px;
	}
`
