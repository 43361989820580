import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { Container, ButtonContainer } from './styles'

import { Calendar, Button, Select } from '../../../../../components/Form'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

import { forceDownloadFileFromURL } from '../../../../../util/file'
import formUtils from '../../../../../util/form'

const nomeRelatorio = 'Relatório Respostas por Questão'

const validation = Yup.object().shape({
    periodo: Yup.string().nullable()
})

export default function (props) {
    const [enquetes, setEnquetes] = useState([])
    const [visoesDisponiveis, setVisoesDisponiveis] = useState(null)

    async function loadEnquetes() {
        const response = await api.get('enquete', authHeaders())

        setEnquetes(response)
    }

    async function loadVisoesDisponiveis(enqueteSelecionadaId) {
        try {
            const response = await api.get(`enquete/${enqueteSelecionadaId}/visoes`, authHeaders())

            setVisoesDisponiveis(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadEnquetes()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        try {
            values = formUtils.extractFormValues(values)

            values.visoes = values.visoes ? values.visoes.map(v => v.value) : null

            const response = await api.post('enquete/relatorio/respostas', values, authHeaders())

            forceDownloadFileFromURL(response.link, nomeRelatorio)

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{ periodo: '', enquete: null, visoes: null }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="periodo"
                            label="Período para busca"
                            selectionMode="range"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="enquete"
                            label="Enquete"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)

                                loadVisoesDisponiveis(selected.value)
                            }}
                            options={enquetes.map(enquete => ({
                                label: enquete.nome,
                                value: enquete.id
                            }))}
                        />

                        {visoesDisponiveis && (
                            <Select
                                name="visoes"
                                label="Grupos"
                                isMulti
                                onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                options={visoesDisponiveis.map(visao => ({
                                    label: visao.descricao,
                                    value: visao.id
                                }))}
                                containerStyle={{ gridColumn: '1/span 2' }}
                            />
                        )}

                        <ButtonContainer>
                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
