import React from 'react'
import ReactTooltip from 'react-tooltip'

import { Button } from './styles'

export default function ({
    children, loading = false, loadingText = 'Aguarde...', disabled = false, type = 'button', fab = false, tiny = false, tooltip, tooltipPosition = 'right', className = '', ...props 
}) {
    return (
        <>
            <Button 
                type={type} 
                disabled={disabled} 
                loading={loading ? 1 : undefined}
                fab={fab}
                tiny={tiny}
                data-tip={tooltip}
                data-for="button-tooltip"
                className={`${fab ? 'fab' : ''} ${className}`}
                {...props}
            >
                {loading ? (
                    <>{loadingText}</>
                ) : (
                    <>{children}</>
                )}
            </Button>

            {tooltip && (
                <ReactTooltip
                    id="button-tooltip"
                    place={tooltipPosition}
                    effect="solid"
                    type="dark"
                    html
                />
            )}
        </>
    )
}
