import React, { useContext, useState, useEffect } from 'react'
import { format } from 'date-fns'

import { OrcamentoContext } from '../../../../../contexts/OrcamentosContext'

import Spinner from '../../../../../components/Spinner'
import { Textbox } from '../../../../../components/Form'

import maskUtils from '../../../../../util/masks'
import {
    isBRDate, convertBRDateToUSDate
} from '../../../../../util/date'

import { Container, BorderCard } from './styles'

export default function () {
    const {
        saldoGeralContasAplicacoes,
        contasAplicacoes,
        updateSaldosContasAplicacoesPorData
    } = useContext(OrcamentoContext)

    const [dataSaldo, setDataSaldo] = useState(format(new Date(), 'dd/MM/yyyy'))
    const [dataSaldoCompleta, setDataSaldoCompleta] = useState(true)

    useEffect(() => {
        if (isBRDate(dataSaldo)) {
            updateSaldosContasAplicacoesPorData(undefined, convertBRDateToUSDate(dataSaldo))
            
            setDataSaldoCompleta(true)
        } else {
            setDataSaldoCompleta(false)
        }
    }, [dataSaldo])

    return (
        <Container>
            <BorderCard>
                <h1>Saldos de Aplicações por conta corrente</h1>

                <Textbox
                    withoutForm
                    label="Saldo até a data"
                    mask={maskUtils.date}
                    value={dataSaldo}
                    onChange={e => { setDataSaldo(e.target.value) }}
                />

                {!dataSaldoCompleta ? (
                    <p className="placeholder">
                        Digite a data completa para buscar os saldos.
                    </p>
                ) : !!contasAplicacoes?.length && !!saldoGeralContasAplicacoes ? (
                    <>
                        <div className="total">
                            <h1>
                                {`SALDO GERAL EM ${dataSaldo}: `}
                                {dataSaldoCompleta ? (
                                    <span className={saldoGeralContasAplicacoes < 0 ? 'negativo' : 'positivo'}>
                                        {maskUtils.maskApply.currency(saldoGeralContasAplicacoes)}
                                    </span>
                                ) : '-'}
                            </h1>
                        </div>

                        {contasAplicacoes.map(c => (
                            <div key={c.id}>
                                <h1>{c.dados_bancarios.instituicao.nome_fantasia}</h1>
                                <h2>{`${c.dados_bancarios.agencia ? `Ag.: ${c.dados_bancarios.agencia}` : ''} ${c.dados_bancarios.agencia ? '-' : ''} Conta: ${c.dados_bancarios.conta}`}</h2>
                                <h3>{maskUtils.maskApply.currency(c.saldo)}</h3>
                            </div>
                        ))}
                    </>
                ) : (
                    <Spinner />
                )}
            </BorderCard>
        </Container>
    )
}
