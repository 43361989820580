import styled from 'styled-components'

export const Container = styled.div`
	margin: 36px 20px;
	display: flex;
	align-items: center;
	min-height: 70vh;
	flex-direction: column;

	.title {
		margin-bottom: 16px; 
		font-size: 1.4rem;
		
		> img {
			height: 40px;
		}

		@media(max-width: 640px) {
			font-size: 1.2rem;
		}
	}
	
	@media(max-width: 600px) {
		margin: 0 0 40px;

		.card {
			width: 100%;
		}
	}
`

export const FormaPagamentoContainer = styled.details`
	margin-bottom: 10px;
	border-radius: 6px;
	border: solid 1px #242424;
	border-bottom-width: 3px;
	height: fit-content;
	
	> div {
		margin: 18px;

		.pix-container {
			display: grid;
			grid-template-columns: 1fr 2fr;
			gap: 28px;

			@media(max-width: 500px) {
				display: flex;
				flex-direction: column;
				gap: 20px;
			}

			.qr-code {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 12px;

				.button {
					text-align: center;
					margin-left: 0;
				}
			}

			.qrcode-pix {
				width: 180px;
				height: 180px;
			}

			.detalhes {
				line-height: 20px;
				display: flex;
				flex-direction: column;
				gap: 14px;

				> h2 {
					font-size: 16px;
					font-weight: 700;
				}
			}
		}
	}

	&[open] {
		summary {
			background-color: #eee;
			color: #424242;

			h1 {
				color: #424242;
			}
		}
	}

	summary {
		cursor: pointer;
		padding: 12px 18px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 6px;
		background-color: #fdfdfd;

		img {
			height: 180px;
			object-fit: cover;
			border-radius: 10px;
		}

		div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 50px;

			h1 {
				font-size: 16px;
				font-weight: 500;
				color: #FFF;
				margin: 0 !important;
			}

			svg {
				font-size: 22px;
			}
		}

		&:hover {
			background-color: #eee;
			color: #424242;

			h1 {
				color: #424242;
			}
		}
	}
`
