import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 260px;
	grid-template-rows: 24px 40px auto;
	grid-template-areas:
		'label button'
		'nome button'
		'tabela tabela';
	align-items: center;
	margin-top: 18px;

	button {
		grid-area: button;
	}

	> h2 {
		font-size: 22px;
		font-weight: 500;
	}

	.table-container {
		grid-area: tabela;
	}
`
