import styled from 'styled-components'

export const Container = styled.div`
	> h1 {
		margin-bottom: 16px;
	}

	> button {
		width: 100%;
		margin: 0 0 16px 0;
	}

	.empty-label {
		text-align: center;
		font-style: italic;
		margin: 20px;
	}

	ul {
		display: flex;
		flex-direction: column;
		gap: 14px 0;
		height: 100%;
    	max-height: 96vh;
		overflow: hidden auto;

		li {
			cursor: pointer;
			display: grid;
			grid-template-columns: 90px 1fr 24px;
			grid-template-rows: auto 1fr;
			grid-gap: 8px;
			transition: 300ms;

			> svg {
				color: #FFF;
				font-size: 16px;
				margin: 4px;
				display: none;
				justify-self: center;
				transition: 300ms;

				&:hover {
					color: #f44336;
				}
			}

			&:hover {
				opacity: 0.8;
				background-color: #0002;
				border-radius: 6px;

				svg {
					display: block;
				}
			}

			img {
				object-fit: cover;
				width: 84px;
				height: 84px;
				grid-row: 1/span 2;
				border-radius: 6px;
			}

			> h1 {
				font-size: 16px;
				margin: 6px 6px 0 0;
			}

			> h2 {
				font-size: 11px;
				margin-bottom: 6px;
				grid-column: 2/span 2;
			}
		}
	}
`


export const PreviewContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	button {
		width: fit-content;
		align-self: center;

		svg {
			font-size: 18px;
		}
	}
	
	img {
		width: 100%;
		margin: 20px 0;
	}
`

export const UploadContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	form {
		display: flex;
		flex-direction: column;
		max-width: 300px;
		width: 100%;
		align-items: center;

		button {
			width: calc(100% - 16px);
		}
	}
`

export const ConfirmDeleteContainer = styled.div`
	display: flex;
	flex-direction: column;

	> p {
		margin: 0 8px;
	}
	
	.button-container {
		display: flex;
		justify-content: flex-end;
		gap: 4px;
	}
`
