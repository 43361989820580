import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { FaFilePdf, FaFileWord, FaFileExcel } from 'react-icons/fa'

import { extname } from '../../util/path'
import { api } from '../../services/api'
import Spinner from '../../components/Spinner'
import Card from '../../components/Card'
import Modal from '../../components/Modal'

import {
    Container, Imagem, GaleriaContainer, GaleriaItem, DocumentosContainer, DocumentosItem, VisualizacaoContainer
} from './styles'

import syndyHeader from '../../assets/images/syndi-header.png'
import { forceDownloadFileFromURL } from '../../util/file'

const iconesExtensoes = {
    '.pdf': FaFilePdf,
    '.doc': FaFileWord,
    '.docx': FaFileWord,
    '.odf': FaFileWord,
    '.xls': FaFileExcel,
    '.xlsx': FaFileExcel
}

export default function () {
    const { id } = useParams()

    const [evento, setEvento] = useState(null)
    const [arquivoVisualizacao, setArquivoVisualizacao] = useState(null)
    const [documentoVisualizacao, setDocumentoVisualizacao] = useState(null)

    async function loadEvento() {
        const response = await api.get(`evento/${id}`)

        if (!response) {
            setEvento(false)
            return
        }

        setEvento(response)
    }

    function getIconeDocumento(link) {
        const Icon = iconesExtensoes[extname(link)]

        return <Icon size={30} />
    }

    function handleAbrirDocumento(doc) {
        if (extname(doc.arquivo.link) === '.pdf') {
            setDocumentoVisualizacao(doc)
        } else {
            forceDownloadFileFromURL(doc.arquivo.link)
        }
    }

    useEffect(() => {
        loadEvento()
    }, [])

    if (evento === null) {
        return (
            <Container>
                <Spinner />
            </Container>
        )
    }

    if (evento === false) {
        return (
            <Container>
                <Card>
                    <p className="event-not-found">Evento não encontrado!</p>
                </Card>
            </Container>
        )
    }

    return (
        <>
            <Container>
                {evento.imagem?.link ? (
                    <Imagem src={evento.imagem.link} />
                ) : (
                    <Imagem src={syndyHeader} />
                )}

                <h1>{evento.nome}</h1>

                <h2>
                    {evento.nome_local ? evento.nome_local : <div />}
                    {evento.endereco?.uf && (
                        <span>
                            {`${evento.endereco.rua}, ${evento.endereco.numero} ${evento.endereco.complemento && `(${evento.endereco.complemento})`} - ${evento.endereco.cep} - ${evento.endereco.uf}`}
                        </span>
                    )}
                </h2>

                <h3>{`Período: ${format(new Date(evento.inicio), 'dd/MM/yyyy\' às \'HH:mm\'h\'')} a ${format(new Date(evento.fim), 'dd/MM/yyyy\' às \'HH:mm\'h\'')}`}</h3>

                {evento.galeria?.length > 0 && (
                    <GaleriaContainer>
                        {evento.galeria?.map(item => {
                            if (item.tipo_arquivo === 'imagem') {
                                return (
                                    <GaleriaItem
                                        href={item.arquivo.link}
                                        size={evento.galeria.length <= 3 ? 'large' : 'small'}
                                        onClick={() => { setArquivoVisualizacao(item) }}
                                    >
                                        <img src={item.arquivo.link} alt="" />
                                    </GaleriaItem>
                                )
                            }

                            if (item.tipo_arquivo === 'video') {
                                return (
                                    <GaleriaItem
                                        href={item.arquivo.link}
                                        size={evento.galeria.length <= 3 ? 'large' : 'small'}
                                        onClick={() => { setArquivoVisualizacao(item) }}
                                    >
                                        <video controls="">
                                            <source src={item.arquivo.link} type="video/mp4" />
                                        </video>
                                    </GaleriaItem>
                                )
                            }

                            return null
                        })}
                    </GaleriaContainer>
                )}

                {evento.descricao && (
                    <Card>
                        <p className="descricao" dangerouslySetInnerHTML={{ __html: evento.descricao }} />
                    </Card>
                )}

                {evento.galeria?.filter(g => g.tipo_arquivo === 'documento').length > 0 && (
                    <DocumentosContainer>
                        <h1>Documentos deste evento</h1>

                        {evento.galeria.filter(g => g.tipo_arquivo === 'documento').map(doc => (
                            <DocumentosItem onClick={() => { handleAbrirDocumento(doc) }}>
                                {getIconeDocumento(doc.arquivo.link)}
                                <span>{doc.descricao}</span>
                            </DocumentosItem>
                        ))}
                    </DocumentosContainer>
                )}
            </Container>

            <Modal
                isOpen={!!arquivoVisualizacao || !!documentoVisualizacao}
                handleClose={() => {
                    setArquivoVisualizacao(null)
                    setDocumentoVisualizacao(null)
                }}
                title="Visualização"
            >
                <VisualizacaoContainer>
                    {arquivoVisualizacao?.tipo_arquivo === 'imagem' ? (
                        <img src={arquivoVisualizacao?.arquivo?.link} alt="" />
                    ) : arquivoVisualizacao?.tipo_arquivo === 'video' ? (
                        <video controls="1" autoPlay="1">
                            <source src={arquivoVisualizacao?.arquivo?.link} type="video/mp4" />
                        </video>
                    ) : documentoVisualizacao?.arquivo?.link ? (
                        <iframe src={documentoVisualizacao.arquivo.link} frameBorder="0" title="Visualização" />
                    ) : (
                        <span>{arquivoVisualizacao?.tipo_arquivo}</span>
                    )}
                </VisualizacaoContainer>
            </Modal>
        </>
    )
}
