import styled from 'styled-components'

export const MensagemMovimentacaoContainer = styled.div`
	margin: 0 8px;
	display: flex;
	flex-direction: column;

	p {
		margin-top: 16px;
	}
`

export const AtendimentoContainer = styled.div`
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: 70px auto 70px;
	grid-template-areas: 
		'participantes participantes'
		'dialogo arquivos'
		'dialogo aprovacao';
	gap: 24px;
	margin: 0 0 24px;

	.participantes-container {
		grid-area: participantes;
		display: flex;
		gap: 18px;
		align-items: center;
		margin-top: 10px;
		justify-content: space-between;

		.participantes {
			display: flex;
			gap: 20px;
			align-items: center;

			.participante {
				display: flex;
				gap: 8px;
				align-items: center;

				> div {
					display: flex;
					gap: 3px;
					flex-direction: column;

					.nome {
						font-weight: 500;
					}

					.convidado {
						font-size: 11px;
						display: flex;
						align-items: center;
						gap: 4px;
					}
				}

				img {
					width: 36px;
					height: 36px;
					object-fit: cover;
					border-radius: 50%;
				}
			}
		}
	}

	.dialogo {
		grid-area: dialogo;
	}

	> button {
		grid-area: aprovacao;
	}

	@media(max-width: 800px) {
		display: flex;
		flex-direction: column;

		.participantes-container {
			display: none;
		}
	}
`
