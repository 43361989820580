import React, { useState, createContext, useEffect } from 'react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

import formUtils from '../util/form'

export const OperacaoContext = createContext()

const baseInitialValues = {
    tipo: null,
    descricao: ''
}

export default function OperacaoProvider({ children }) {
    const [operacoes, setOperacoes] = useState(null)
    const [tipos, setTipos] = useState([])
    const [operacaoEdicao, setOperacaoEdicao] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [operacaoDetalhe, setOperacaoDetalhe] = useState(null)
    const [arquivosAdicionados, setArquivosAdicionados] = useState([])

    async function loadOperacoes() {
        const response = await api.get('operacao', authHeaders())

        const normalizado = response.map(operacao => ({
            ...operacao,
            tipo: operacao.operacao_tipo.descricao,
            autorizacoes_quantidade: operacao.operacao_tipo.autorizadores.length
                ? operacao.operacao_tipo.autorizadores.length === operacao.autorizacoes.length
                    ? 'Autorizado'
                    : `${operacao.autorizacoes.length}/${operacao.operacao_tipo.autorizadores.length}`
                : 'Não exigido',
            data_autorizacao_formatada: operacao.data_autorizacao ? format(new Date(operacao.data_autorizacao), 'dd/MM/yyyy') : ''
        }))

        setOperacoes(normalizado)

        return normalizado
    }

    async function reload() {
        const data = await loadOperacoes()

        if (operacaoDetalhe) {
            const operacaoAberta = data.find(item => item.id === operacaoDetalhe.id)

            setOperacaoDetalhe(operacaoAberta)
        }
    }

    async function loadTipos() {
        const response = await api.get('operacao_tipo', authHeaders())

        setTipos(response)
    }

    function handlePrepareEdit(id) {
        const operacaoAEditar = operacoes.find(o => o.id === id)

        setOperacaoEdicao(operacaoAEditar)
    }

    function handleCancelEdit() {
        setOperacaoEdicao(null)
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            values = formUtils.extractFormValues(values)

            if (operacaoEdicao) {
                await api.put(`operacao/${operacaoEdicao.id}`, values, authHeaders())

                toast.success('Operação atualizada.')
            } else {
                await api.post('operacao', values, authHeaders())

                const { autorizadores } = await api.get(`operacao_tipo/${values.tipo}`, authHeaders())

                let message = 'Operação cadastrada.'

                if (autorizadores.length) {
                    message += ' As autorizações serão solicitadas por e-mail.'
                }

                toast.success(message)
            }

            setOperacaoEdicao(null)

            resetForm()

            loadOperacoes()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(id) {
        try {
            await api.delete(`operacao/${id}`, authHeaders())

            loadOperacoes()

            toast.success('Operação removida.')

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handleAutorizar(operacao) {
        try {
            await api.patch(`operacao/${operacao.id}/autorizar`, {}, authHeaders())

            toast.success('Operação autorizada.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDesautorizar(operacao) {
        try {
            await api.patch(`operacao/${operacao.id}/desautorizar`, {}, authHeaders())

            toast.success('Autorização revogada.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function resetInitialValues() {
        setInitialValues(baseInitialValues)
    }

    useEffect(() => {
        loadOperacoes()

        loadTipos()
    }, [])

    useEffect(() => {
        setInitialValues(operacaoEdicao ? {
            tipo: {
                label: operacaoEdicao.operacao_tipo.descricao,
                value: operacaoEdicao.operacao_tipo.id
            },
            descricao: operacaoEdicao.descricao
        } : baseInitialValues)

        setArquivosAdicionados(operacaoEdicao ? operacaoEdicao.arquivos : [])
    }, [operacaoEdicao])

    return (
        <OperacaoContext.Provider
            value={{
                operacoes,
                initialValues,
                resetInitialValues,
                operacaoEdicao,
                setOperacaoEdicao,
                handlePrepareEdit,
                handleSubmit,
                handleCancelEdit,
                handleDelete,
                handleAutorizar,
                handleDesautorizar,
                tipos,
                operacaoDetalhe,
                setOperacaoDetalhe,
                arquivosAdicionados,
                setArquivosAdicionados
            }}
        >
            {children}
        </OperacaoContext.Provider>
    )
}
