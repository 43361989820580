import React, { useState } from 'react'
import {
    Tab, TabList, TabPanel, Tabs
} from 'react-tabs'

import ProcessoGerenciamentoProvider from './ProcessoGerenciamentoContext'

import SetorAtendente from './SetorAtendente'
import TipoProcessoSetor from './TipoProcessoSetor'
import TipoProcessoPerfil from './TipoProcessoPerfil'
import Setores from './Setores'

import { Container } from './styles'
import Card from '../../../../components/Card'

export default function () {
    const [indiceTabAtiva, setIndiceTabAtiva] = useState(0)

    return (
        <Container>
            <main>
                <Card>
                    <h1>Grupos e atendentes</h1>

                    <ProcessoGerenciamentoProvider>
                        <Tabs
                            selectedIndex={indiceTabAtiva}
                            onSelect={indiceTab => setIndiceTabAtiva(indiceTab)}
                        >
                            <TabList>
                                <Tab>Grupos de atendimento</Tab>
                                <Tab>Atendentes por grupo</Tab>
                                <Tab>Tipos de atendimento por grupo</Tab>
                                <Tab>Tipos de atendimento por perfil</Tab>
                            </TabList>

                            <TabPanel>
                                <Setores />
                            </TabPanel>

                            <TabPanel>
                                <SetorAtendente />
                            </TabPanel>

                            <TabPanel>
                                <TipoProcessoSetor />
                            </TabPanel>

                            <TabPanel>
                                <TipoProcessoPerfil />
                            </TabPanel>
                        </Tabs>
                    </ProcessoGerenciamentoProvider>
                </Card>
            </main>
        </Container>
    )
}
