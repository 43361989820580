import React, { useEffect, useState } from 'react'
import { FaSearch, FaArrowRight, FaArrowDown } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import { extname } from '../../../../util/path'
import { api, authHeaders } from '../../../../services/api'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import Modal from '../../../../components/Modal'
import { Button } from '../../../../components/Form'
import ActionFooter from '../../../../components/ActionFooter'

import BoxPagamento from '../../../../boxes/EspacoMedico/Pagamento'
import BoxArquivos from '../../../../boxes/Arquivos'

import {
    Container, ModalContent, ModalConfirmationContent, BoxesContainer
} from './styles'
import SmallScreenPlaceholder from '../../../../components/SmallScreenPlaceholder'

export default function () {
    const [pendencias, setPendencias] = useState(null)
    const [associado, setAssociado] = useState(null)
    const [showConfirmacao, setShowConfirmacao] = useState(false)
    const [aprovando, setAprovando] = useState(false)

    async function loadPendencias() {
        const response = await api.get('associado', {
            params: {
                medico: 1,
                tipo_pagamento_aprovacao_status: 0
            }
        })

        setPendencias(response ? response.map(pendencia => ({
            id: pendencia.id,
            crm: pendencia.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.identificador,
            nome: pendencia.pessoa.nome,
            forma_pagamento: pendencia.tipo_pagamento.descricao_resumida,
            data_submissao: format(new Date(pendencia.updated_at), 'dd/MM/yyyy')
        })) : [])
    }

    useEffect(() => {
        loadPendencias()
    }, [])

    async function handleShowAssociacao(associacaoLinha) {
        const partialResponse = await api.get('associado', {
            params: {
                crm: associacaoLinha.crm
            }
        })

        if (!partialResponse) {
            toast.error('Nenhuma associação pendente para este CRM.')
        }

        const response = partialResponse[0]

        const pessoa = await api.get(`pessoa/${response.pessoa.id}`, authHeaders())

        response.nome = response.pessoa.nome
        response.crm = response.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.identificador || ''
        response.associado = response

        response.situacao = {
            descricao: response.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.extra?.situacao,
            icone: response.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.extra?.situacao === 'Regular' ? 'FiCheckCircle' : 'FiXCircle'
        }

        response.especialidades = !response.especialidades?.length ? [] : response.especialidades.map(espec => espec.descricao)

        response.locais_trabalho = [
            ...response.locais_trabalho.map(local => {
                const {
                    rua, numero, complemento, bairro, cidade, uf, cep
                } = local.endereco

                return {
                    ...local,
                    endereco: `${rua}, ${numero}, ${complemento} - ${bairro} - ${cidade} - ${uf} - ${cep}`,
                    editable: false
                }
            }),
            ...response.instituicoes.filter(instituicao => !instituicao.associado_instituicao.desconto).map(instituicao => ({
                id: instituicao.id,
                nome: instituicao.razao_social,
                endereco: '',
                editable: false
            }))
        ]

        response.documentos = response.pessoa.documentos

        response.endereco = response.pessoa.endereco

        const rgExtraData = response.documentos?.find(documento => documento.tipo_documento.id === 1)?.extra
        const rg_data_emissao = rgExtraData?.data_emissao ? rgExtraData.data_emissao : ''
        const rg_orgao_emissor = rgExtraData?.orgao_emissor ? rgExtraData.orgao_emissor : ''

        response.dados_pessoais = {
            cpf: response.pessoa.documentos?.find(documento => documento.tipo_documento.id === 2)?.identificador || '',
            data_nascimento: response.pessoa.data_nascimento ? format(new Date(response.pessoa.data_nascimento), 'dd/MM/yyyy') : '',
            rg: response.pessoa.documentos?.find(documento => documento.tipo_documento.id === 1)?.identificador || '',
            rg_data_emissao: rg_data_emissao ? format(new Date(rg_data_emissao), 'dd/MM/yyyy') : '',
            rg_orgao_emissor
        }

        const tipoPagamento = response?.tipo_pagamento

        const dadosBancarios = response?.dados_bancarios

        const dadosInstituicao = response?.instituicoes?.find(instituicao => instituicao.associado_instituicao.desconto)

        response.pagamento = {
            tipo: tipoPagamento,
            dados_bancarios: tipoPagamento?.id === 1 && dadosBancarios ? {
                ...dadosBancarios,
                instituicao: dadosBancarios.instituicao
            } : null,
            dados_instituicao: tipoPagamento?.id === 2 && dadosInstituicao ? {
                ...dadosInstituicao,
                matricula: dadosInstituicao.associado_instituicao.matricula
            } : null,
            dados_cartao_credito: tipoPagamento?.id === 3 ? pessoa.associado.dados_cartao_credito : null
        }

        response.contatos = [
            ...response.pessoa.contato.map(contato => ({
                ...contato,
                icone: contato.tipo_contato.icone
            }))
        ]

        setAssociado(response)
    }

    function handleShowConfirmation() {
        setShowConfirmacao(true)
    }

    async function handleAprovarAnuidadeResidente() {
        setAprovando(true)

        try {
            if (!associado) {
                toast.error('Ocorreu um erro ao aprovar a associação do médico.')
            }

            await api.post(`alteracao_pagamento_residente/${associado.id}`, null, authHeaders())

            loadPendencias()

            setShowConfirmacao(false)
            setAssociado(null)

            toast.success('Alteração aprovada. O médico receberá o boleto por e-mail.')
        } catch (e) {
            toast.error(e.msg || e)
        } finally {
            setAprovando(false)
        }
    }

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Aprovação de Anuidade Residente</h1>

                        <SmallScreenPlaceholder hideOffset={920} />

                        {pendencias ? (
                            <Table
                                headers={[
                                    { name: 'crm', value: 'CRM', centered: true },
                                    { name: 'nome', value: 'Nome' },
                                    { name: 'forma_pagamento', value: 'Pagamento', centered: true },
                                    {
                                        name: 'data_submissao', value: 'Data de submissão', centered: true, style: { width: 180 }
                                    }
                                ]}
                                data={pendencias}
                                actions={[
                                    {
                                        action: handleShowAssociacao,
                                        name: 'Abrir',
                                        icon: FaSearch
                                    }
                                ]}
                                hideOnSmallHeaders={['forma_pagamento', 'residente', 'data_submissao']}
                                hideOffset={920}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!associado}
                handleClose={() => {
                    setAssociado(null)
                }}
                title="Alteração da Forma de Pagamento"
            >
                <ModalContent>
                    <h2>
                        <span>{associado?.nome}</span>
                        <span className="badge">
                            {`CRM: ${associado?.crm}`}
                        </span>
                    </h2>

                    <Button type="button" className="transparent" style={{ gridArea: 'buttonTop' }} onClick={handleShowConfirmation}>
                        Aprovar alteração de pagamento
                    </Button>

                    <BoxesContainer>
                        <BoxPagamento
                            pessoa={associado}
                            showEditIcon={false}
                            ignoreAlert
                            title="Forma de pagamento atual"
                        />

                        <div className="seta-direita">
                            <FaArrowRight size={40} />
                        </div>

                        <div className="seta-baixo">
                            <FaArrowDown size={40} />
                        </div>

                        <BoxPagamento
                            showEditIcon={false}
                            ignoreAlert
                            title="Nova forma de pagamento"
                            data={{
                                pagamento: {
                                    tipo: {
                                        id: 5,
                                        descricao: 'Anuidade Residente'
                                    }
                                }
                            }}
                        />
                    </BoxesContainer>

                    <BoxArquivos
                        arquivos={
                            associado?.documentos
                                .filter((documento, _, array) => documento.tipo_documento.id === 16 && documento.id === array[array.length - 1].id)
                                .map(documento => ({
                                    nome: documento.tipo_documento.descricao,
                                    link: documento.arquivo?.link,
                                    extensao: extname(documento.arquivo?.link)
                                })) || []
                        }
                    />

                    <ActionFooter>
                        <Button type="button" className="transparent" onClick={handleShowConfirmation}>
                            Aprovar alteração de pagamento
                        </Button>
                    </ActionFooter>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={showConfirmacao && associado}
                handleClose={() => {
                    setShowConfirmacao(false)
                    setAssociado(null)
                }}
                title="Confirmação"
                style={{ width: 'fit-content' }}
            >
                <ModalConfirmationContent>
                    <p style={{ margin: 8 }}>{`Tem certeza que deseja aprovar a alteração de pagamento do(a) médico(a) ${associado?.nome}?`}</p>
                    <p className="badge">Ao clicar em SIM, o médico receberá um e-mail com o link para o boleto de anuidade.</p>

                    <ActionFooter>
                        <Button
                            type="button"
                            className="transparent"
                            onClick={() => {
                                setShowConfirmacao(false)
                                setAssociado(null)
                            }}
                            disabled={aprovando}
                        >
                            Não
                        </Button>

                        <Button type="button" className="white" onClick={handleAprovarAnuidadeResidente} disabled={aprovando}>
                            Sim
                        </Button>
                    </ActionFooter>
                </ModalConfirmationContent>
            </Modal>
        </>
    )
}
