import React from 'react'
import { ListBox } from 'primereact/listbox'

import { Container } from './styles'

export default function ({
    label, name, containerStyle = {}, selectedIndex, filterPlaceholder = 'Filtrar por...', ...props
}) {
    return (
        <Container style={containerStyle} selectedIndex={selectedIndex}>
            {label && (
                <label htmlFor={name}>{label}</label>
            )}
			
            <ListBox
                {...props}
                id={name}
                name={name}
                filterPlaceholder={filterPlaceholder}
            />
        </Container>
    )
}
