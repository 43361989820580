import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	grid-column: 1/span 2;

	.table-container {
		margin-top: 0;
	}

	> button {
		width: fit-content;
		align-self: end;
	}

	.spinner-container {
		grid-column: 1/span 2;
		justify-self: center;
	}
`

export const FilterContainer = styled.div`
	width: 100%;

	.calendar {
		width: 250px;
	}
`
