import React, { useContext } from 'react'

import { AtendimentoContext } from '../../../../../contexts/AtendimentoContext'

import { Container } from './styles'

import avatarDefault from '../../../../../assets/images/avatar.png'

export default function ({ className }) {
    const { atendimento } = useContext(AtendimentoContext)

    return (
        <Container className={`atendente-container ${className || ''}`}>
            <img src={atendimento?.ultimo_atendente?.usuario?.avatar_url || avatarDefault} alt="" />
            <p>Último atendimento:</p>
            <h2>{atendimento?.ultimo_atendente?.nome || 'Ninguém iniciou atendimento.'}</h2>
        </Container>
    )
}
