import React from 'react'

import Card from '../../../../components/Card'
import ProcessoProvider from '../../../../contexts/ProcessoContext'

import Header from './Header'
import Interacao from './Interacao'
import Tramites from './Tramites'
import Alertas from './Alertas'

import { Container } from './styles'

export default function () {
    return (
        <ProcessoProvider>
            <Container>
                <main>
                    <Card>
                        <Header />
                        
                        <Interacao />
                        
                        <Tramites />

                        <Alertas />
                    </Card>
                </main>
            </Container>
        </ProcessoProvider>
    )
}
