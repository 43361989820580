import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { FiChevronRight } from 'react-icons/fi'
import {
    Tab, Tabs, TabList, TabPanel
} from 'react-tabs'

import { api, authHeaders } from '../../../../../services/api'

import FormPessoal from './Pessoal'
import FormEndereco from './Endereco'
import FormContatos from './Contatos'
import FormDadosBancarios from './DadosBancarios'

import { Container, ExpanderTab } from './styles'
import { loadTips } from '../../../../../util/tip'
import { TipContext } from '../../../../../contexts/TipContext'

const baseInitialValues = {
    pessoal: {
        tipo_pessoa: '',
        razao_social: '',
        nome_fantasia: '',
        rg: '',
        identificador: '',
        inscricao_estadual: '',
        inscricao_federal: '',
        observacao: ''
    },
    endereco: {
        cep: '',
        uf: '',
        cidade: '',
        rua: '',
        numero: '',
        bairro: '',
        complemento: ''
    },
    contatos: [],
    site: '',
    dados_bancarios: {
        banco: '',
        agencia: '',
        conta: ''
    }
}

export default function ({ editId, reload }) {
    const { setCodigo, tips } = useContext(TipContext)

    const [tabsStatus, setTabsStatus] = useState([true, false, false, false])
    const [indiceTabAtiva, setIndiceTabAtiva] = useState(0)
    const [dadosFornecedor, setDadosFornecedor] = useState(baseInitialValues)

    async function loadFornecedor() {
        const response = await api.get(`fornecedor/${editId}`, authHeaders())

        const {
            nome_fantasia, observacao, site, banco, agencia, conta, pessoa: { endereco, documentos, ...pessoa }, contatos
        } = response

        setDadosFornecedor({
            pessoal: {
                tipo_pessoa: pessoa.tipo,
                razao_social: pessoa.nome || '',
                nome_fantasia: nome_fantasia || '',
                rg: documentos.find(documento => documento.tipo_documento.id === 1)?.identificador || '',
                identificador: documentos.find(documento => [2, 14].includes(documento.tipo_documento.id))?.identificador || '',
                inscricao_estadual: documentos.find(documento => documento.tipo_documento.id === 12)?.identificador || '',
                inscricao_federal: documentos.find(documento => documento.tipo_documento.id === 13)?.identificador || '',
                observacao
            },
            endereco,
            contatos: contatos.map(c => ({
                tipo_contato: c.tipo_contato.id,
                valor_contato: c.contato,
                responsavel_contato: c.pessoa?.nome || ''
            })),
            site,
            dados_bancarios: {
                banco,
                agencia,
                conta
            }
        })
    }

    useEffect(() => {
        loadTips(setCodigo, 'form_fornecedores_cadastro')
    }, [])

    useEffect(() => {
        let tabSelecionada = 0

        if (dadosFornecedor?.pessoal?.razao_social) {
            tabSelecionada++
        }
        if (dadosFornecedor?.endereco?.cep) {
            tabSelecionada++
        }
        if (dadosFornecedor?.contatos || dadosFornecedor?.site) {
            tabSelecionada++
        }
        if (dadosFornecedor?.dados_bancarios?.banco) {
            tabSelecionada++
        }

        setTabsStatus(tabsStatus.map((_, index) => index <= tabSelecionada))
    }, [dadosFornecedor])

    useEffect(() => {
        if (!editId) {
            setDadosFornecedor(baseInitialValues)
            setIndiceTabAtiva(0)

            return
        }

        loadFornecedor()

        setIndiceTabAtiva(0)
    }, [editId])

    async function cadastrar(fornecedor) {
        try {
            await api.post('fornecedor', fornecedor, authHeaders())

            toast.success('Cadastro concluído!')
        } catch (e) {
            toast.error(e.message)
        }
    }

    async function editar(fornecedor) {
        const { pessoal } = fornecedor

        const tiposDocumento = [
            { name: 'rg', id: 1 },
            { name: 'identificador', id: 14 },
            { name: 'inscricao_estadual', id: 12 },
            { name: 'inscricao_federal', id: 13 }
        ]

        const documentos = []

        tiposDocumento.forEach(({ id, name }) => {
            if (name === 'identificador' && pessoal.tipo_pessoa === 'PF') {
                id = 2
            }

            if (pessoal[name]) {
                documentos.push({
                    tipo: id,
                    identificador: pessoal[name]
                })
            }
        })

        const serialized = {
            ...fornecedor,
            pessoal: {
                tipo_pessoa: pessoal.tipo_pessoa,
                razao_social: pessoal.razao_social,
                nome_fantasia: pessoal.nome_fantasia,
                observacao: pessoal.observacao
            },
            documentos
        }

        try {
            await api.put(`fornecedor/${editId}`, serialized, authHeaders())

            toast.success('As alterações foram salvas!')
        } catch (e) {
            toast.error(e.message)
        }
    }

    function handleNextStep(values, enableNext = true) {
        setDadosFornecedor({
            ...dadosFornecedor,
            ...values
        })

        if (indiceTabAtiva === tabsStatus.length - 1) {
            return
        }

        const proximaTabIndice = indiceTabAtiva + 1

        if (enableNext) {
            setTabsStatus([...tabsStatus.slice(0, proximaTabIndice), true, ...tabsStatus.slice(proximaTabIndice + 1)])
            setIndiceTabAtiva(proximaTabIndice)
        }
    }

    async function concluirForm(values) {
        const fornecedor = {
            ...dadosFornecedor,
            ...values
        }

        setDadosFornecedor({
            ...dadosFornecedor,
            ...values
        })

        if (editId) {
            await editar(fornecedor)
        } else {
            await cadastrar(fornecedor)
        }

        reload()

        setDadosFornecedor(baseInitialValues)
    }

    return (
        <Container>
            <p style={{ margin: 8 }}>{tips?.formulario}</p>

            <Tabs
                selectedIndex={indiceTabAtiva}
                onSelect={indiceTab => setIndiceTabAtiva(indiceTab)}
            >
                <TabList>
                    <Tab>1 - Dados do parceiro</Tab>
                    <Tab disabled={!tabsStatus[1]}>2 - Endereço</Tab>
                    <Tab disabled={!tabsStatus[2]}>3 - Contato</Tab>
                    <Tab disabled={!tabsStatus[3]}>4 - Dados Bancarios</Tab>
                    {indiceTabAtiva < 3 && (
                        <ExpanderTab onClick={() => handleNextStep(false)} role="button" tabIndex={0}>
                            <div className="expander-tab">
                                <FiChevronRight />
                            </div>
                        </ExpanderTab>
                    )}
                </TabList>

                <TabPanel>
                    <FormPessoal callback={handleNextStep} dadosFornecedor={dadosFornecedor} />
                </TabPanel>

                <TabPanel>
                    <FormEndereco callback={handleNextStep} dadosFornecedor={dadosFornecedor} />
                </TabPanel>

                <TabPanel>
                    <FormContatos callback={handleNextStep} dadosFornecedor={dadosFornecedor} />
                </TabPanel>

                <TabPanel>
                    <FormDadosBancarios callback={concluirForm} dadosFornecedor={dadosFornecedor} />
                </TabPanel>
            </Tabs>
        </Container>
    )
}
