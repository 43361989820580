import styled from 'styled-components'

export const Container = styled.div`
	main {
		padding: 14px 30px;
	
		.card {
			max-width: 900px;
			min-height: 60vh;
			margin: 30px auto;
			display: grid;
			grid-template-columns: 240px 1fr;
			grid-template-areas: 
				'title title'
				'syndi balloon'
				'syndi form';

			> h1 {
				margin-bottom: 30px;
				grid-area: title;
			}

			img {
				width: 200px;
				justify-self: center;
				grid-area: syndi;
			}

			.balloon {
				grid-area: balloon;
				display: flex;
				background-color: #fff;
				padding: 20px;
				border-radius: 30px;
				width: calc(100% - 30px);
				min-height: 40px;
				margin: 20px;
				height: fit-content;
				position: relative;
				align-items: center;
				justify-content: center;
				text-align: center;
				color: #424242;
				flex-direction: column;

				> p {
					z-index: 2;
					line-height: 30px;
					font-size: 14px;
					font-weight: 500;
					text-align: center;
					width: 100%;
				}

				&:before,
				&:after {
					content: "";
					background-color: #fff;
					border-radius: 50%;
					display: block;
					position: absolute;
					z-index: 1;
				}

				&:before {
					width: 44px;
					height: 44px;
					top: -12px;
					left: 28px;
					box-shadow: -50px 30px 0 -12px #fff;
				}
				&:after {
					bottom: -10px;
					right: 26px;
					width: 30px;
					height: 30px;
					box-shadow: 40px -34px 0 0 #fff,
								-28px -6px 0 -2px #fff,
								-24px 17px 0 -6px #fff,
								-5px 25px 0 -10px #fff;
				}
			}

			form {
				grid-area: form;
				display: grid;
				grid-template-columns: 240px 1fr;

				.textarea textarea {
					height: 130px !important;
				}

				button {
					grid-column: 2;
					justify-self: flex-end;
					width: fit-content;
					align-self: flex-end;
				}

				@media(max-width: 900px) {
					display: flex;
					flex-direction: column;
				}
			}

			@media(max-width: 700px) {
				grid-template-columns: 80px 1fr;
				grid-template-areas: 
					'title title'
					'syndi balloon'
					'form form';

				img {
					width: 80px;
				}
			}
		}

		@media(max-width: 470px) {
			padding: 14px 0;
		}
	}
`
