import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 70vh;
	color: #424242;

	h1 {
		color: #424242;
		margin-top: 20px;
		font-size: 20px;
		font-weight: 400;
		text-align: center;
		padding: 0 20px;
	}
`
