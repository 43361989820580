import React from 'react'
import {
    Tab, TabList, TabPanel, Tabs 
} from 'react-tabs'

import Card from '../../../components/Card'

import Facebook from './Facebook'

import { Container } from './styles'

export default function () {
    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Mídias Sociais</h1>

                    <Tabs>
                        <TabList>
                            <Tab>Facebook</Tab>
                            <Tab>Instagram</Tab>
                        </TabList>

                        <TabPanel>
                            <Facebook />
                        </TabPanel>

                        <TabPanel>
                            <h1>Instagram</h1>
                        </TabPanel>
              
                    </Tabs>

                </Card>
            </main>
        </Container>
    )
}
