import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../../../services/api'
import { loadTips } from '../../../util/tip'

import Card from '../../../components/Card'
import {
    Select, Button, Calendar
} from '../../../components/Form'
import Table from '../../../components/Table'
import Spinner from '../../../components/Spinner'
import ProgressBar from '../../../components/ProgressBar'

import { Container } from './styles'
import { TipContext } from '../../../contexts/TipContext'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [planosDeSaude, setPlanosDeSaude] = useState(null)
    const [associados, setAssociados] = useState([])
    const [faturasAReceber, setFaturasAReceber] = useState(null)
    const [tempoEspera, setTempoEspera] = useState(0)
    const [cobrancasProgresso, setCobrancasProgresso] = useState(0)
    const [cobrancasGeradas, setCobrancasGeradas] = useState(0)

    async function loadPlanosDeSaude() {
        const response = await api.get('convenio', {
            ...authHeaders(),
            params: {
                convenio_area_atuacao_id: 1
            }
        })

        setPlanosDeSaude(response)
    }

    useEffect(() => {
        loadPlanosDeSaude()
        loadTips(setCodigo, 'form_plano_saude_geracao_faturas')
    }, [])

    async function loadAssociados(planoId) {
        setAssociados(null)

        const response = await api.get('convenio_associado', {
            params: {
                convenio_id: planoId
            },
            ...authHeaders()
        })

        setAssociados(response.map(a => {
            const [convenio] = a.conveniosAssociado

            return {
                id: a.id,
                nome: a.pessoa.nome,
                status: convenio.convenio_status.descricao,
                dependentes: String(convenio.dependentes.length)
            }
        }))
    }

    async function handleSubmit(values, { resetForm }) {
        let incremento

        try {
            const { plano, vencimento } = values

            const quantidade = 1
            const interacoes = faturasAReceber.length % quantidade === 0 ? faturasAReceber.length / quantidade : Math.trunc(faturasAReceber.length / quantidade) + 1

            setCobrancasGeradas(0)

            for (let i = 0; i < interacoes; i++) {
                const response = await api.post(`plano_de_saude/${plano.value}`, {
                    data_vencimento: vencimento,
                    quantidade
                }, authHeaders())

                if (response.erros.length) {
                    throw new Error(`Houve um erro ao enviar a fatura para ${response.erros.length} beneficiário(s).`)
                }

                setCobrancasProgresso(Math.round(((i + 1) * 100) / interacoes))

                setCobrancasGeradas((i + 1) * quantidade)
            }

            setCobrancasProgresso(100)

            setTimeout(() => {
                setCobrancasProgresso(0)
                setCobrancasGeradas(0)
            }, 5000)

            loadAssociados(plano.value)

            resetForm()

            toast.success('As cobranças foram enviadas com sucesso.')
        } catch (e) {
            clearInterval(incremento)

            toast.error(e.msg || e.message)
        }
    }

    async function obterFaturasAReceber(planoId, vencimento) {
        if (vencimento && planoId) {
            const response = await api.get('convenio_associado/obter_sem_fatura', {
                ...authHeaders(),
                params: {
                    convenio_id: planoId,
                    vencimento
                }
            })

            setFaturasAReceber(response)
        }
    }

    useEffect(() => {
        if (associados) {
            const tempoSegundos = associados.length * 3
            const unidade = tempoSegundos > 59 ? 'minutos' : 'segundos'
            const tempo = tempoSegundos > 59 ? tempoSegundos / 60 : tempoSegundos

            setTempoEspera(`${tempo} ${unidade}`)
        }
    }, [associados])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <Formik
                        initialValues={{
                            plano: null,
                            vencimento: ''
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue, values, isSubmitting }) => (
                            <Form>
                                <h1>Cobranças de Plano de Saúde</h1>
                                <p>{tips.formulario}</p>

                                <Select
                                    label="Plano de saúde"
                                    name="plano"
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)

                                        loadAssociados(selected.value)

                                        obterFaturasAReceber(selected.value, values.vencimento)
                                    }}
                                    options={planosDeSaude?.map(p => ({
                                        label: p.empresa.nome,
                                        value: p.id
                                    })) || []}
                                />

                                <Calendar
                                    label="Data de vencimento"
                                    name="vencimento"
                                    minDate={new Date()}
                                    onChange={e => {
                                        setFieldValue('vencimento', e.value)

                                        obterFaturasAReceber(values.plano?.value, e.value)
                                    }}
                                />

                                <Button
                                    type="submit"
                                    className="transparent"
                                    loading={isSubmitting}
                                    disabled={!values.plano || !values.vencimento || faturasAReceber?.length === 0}
                                    tooltip={`Esta operação poderá levar<br/>em média <b>${tempoEspera}</b> e não<br/>pode ser interrompida.<br>Ao clicar, não saia desta tela<br/>até a conclusão do processo.`}
                                >
                                    Enviar cobranças
                                </Button>

                                {cobrancasProgresso !== 0 && (
                                    <ProgressBar
                                        percentage={cobrancasProgresso}
                                        hint={`${cobrancasGeradas} de ${faturasAReceber?.length} contribuições já foram geradas`}
                                    />
                                )}

                                {values.plano && values.vencimento && faturasAReceber !== null && (
                                    <span className="badge">
                                        {faturasAReceber.length === 0
                                            ? 'Nenhum beneficiário deste plano a receber'
                                            : faturasAReceber.length === 1
                                                ? '1 beneficiário deste plano receberá'
                                                : `${faturasAReceber.length} beneficiários deste plano receberão`}
                                        {' a cobrança por e-mail.'}

                                        {faturasAReceber.length > 0 && (
                                            <>
                                                {' Clique em '}
                                                <b>Enviar cobranças</b>
                                                {' para confirmar.'}
                                            </>
                                        )}
                                    </span>
                                )}
                            </Form>
                        )}
                    </Formik>

                    {associados ? (
                        <Table
                            headers={[
                                { name: 'nome', value: 'Titular' },
                                { name: 'dependentes', value: 'Dependentes', centered: true },
                                { name: 'status', value: 'Status', centered: true }
                            ]}
                            data={associados || []}
                            filterable
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
