import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			min-height: 70vh;
		}

		@media(max-width: 715px) {
			padding: 20px;	
		}
	}
`

export const VotacaoContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px 0;

	> span {
		margin: 18px 8px 0;
	}

	details {
		grid-column: 1/span 2;

		summary {
			border-radius: 8px;
			border: solid 1px #fff;
			padding: 16px;
			display: flex;
			justify-content: space-between;

			> span:first-child {
				font-weight: 600;
			}

			&:hover {
				background-color: #fff;
				cursor: pointer;
				color: #424242;
			}

			@media(max-width: 700px) {
				flex-direction: column;
				gap: 12px;
			}
		}

		&[open] {
			summary {
				background-color: #fff;
				color: #424242;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}		
	}
`

export const ChapaContainer = styled.div`
	display: grid;
	grid-template-columns: 300px auto;
	grid-template-rows: 40px auto;
	gap: 0 24px;
	padding: 16px;
	border: solid 1px #fff;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	grid-template-areas:
		'imagem right'
		'imagem right'
		'button button'; 

	> div {
		grid-area: right;
		display: flex;
		flex-direction: column;
		gap: 16px;

		> h1 {
			margin: 0;
		}

		> p {
			line-height: 24px;
		}
	}

	> img {
		grid-area: imagem;
		width: 300px;
		border-radius: 6px;
	}

	.cargo-container {
		> h1 {
			font-size: 17px;
		}
	}

	.lista-candidatos {
		grid-area: candidatos;

		.item-candidato {
			display: grid;
			grid-template-columns: 60px auto 120px;
			align-items: center;
			border-bottom: solid 1px #fff9;
			padding: 4px 8px;
			width: calc(100% - 8px);
			font-size: 13px;

			img {
				width: 44px;
				height: 44px;
				object-fit: cover;
				border-radius: 50%;
			}
			
			a {
				color: #fff;
				font-size: 12px;
				display: flex;
				align-items: center;
				gap: 4px;
				justify-self: end;
				transition: 300ms;

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}

	> button {
		grid-area: button;
		margin-top: 24px;
	}

	@media(max-width: 1050px) {
		display: flex;
		flex-direction: column;

		> img {
			align-self: center;
			margin-bottom: 24px;
		}
	}
`

export const ConfirmacaoVotoContainer = styled.div`
	display: flex;
	flex-direction: column;

	p {
		margin: 8px;
		font-size: 16px;

		.chapa-voto {
			font-size: 24px;
			text-transform: uppercase;
		}
	}

	.hint {
		font-size: 13px;
	}

	.button-container {
		display: flex;
		justify-content: space-between;

		button {
			width: 100%;
		}
	}

	@media(max-width: 600px) {
		.button-container {
			flex-direction: column;
		}
	}
`

export const DetalhesEleicaoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 8px;

	> p {
		line-height: 24px;
	}

	.voto {
		font-size: 18px;
		margin-top: 16px;
	}

	.resultado {
		font-size: 18px;
		margin: 24px 0 0;
	}
`

export const ResultadoFinalContainer = styled.div`
	border-top: solid 1px #fff;
	margin-top: 24px;
	display: flex;
	flex-direction: column;

	h1 {
		font-size: 20px;
		margin: 16px 0;
	}

	h2 {
		font-size: 20px;
	}

	.table-container {
		align-self: center;
		max-width: 600px;
	}
`

export const VotosAlternativosContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column: 1/span 2;
`
