import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	button {
		align-self: flex-end;
	}
`
