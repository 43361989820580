import React, { useContext } from 'react'
import { format } from 'date-fns'
import { FiCalendar, FiEdit } from 'react-icons/fi'

import { AdvogadoContext } from '../../../../contexts/AdvogadoContext'

import { Container, AgendaContainer } from './styles'

import { weekDays } from '../../../../util/calendar'

export default function () {
    const { showDetalhes, handleEditAgenda, handleEditCadastro } = useContext(AdvogadoContext)

    if(!showDetalhes) {
        return null
    }

    return (
        <Container>
            <img src={showDetalhes.avatar} alt="" />
            
            <FiEdit onClick={() => { handleEditCadastro(showDetalhes) }} />
            
            <div dangerouslySetInnerHTML={{ __html: `Nome: <b>${showDetalhes.nome}</b>` }} />
            <div dangerouslySetInnerHTML={{ __html: `Data de nascimento: <b>${format(new Date(showDetalhes.data_nascimento), 'dd/MM/yyyy')}</b>` }} />
            <div dangerouslySetInnerHTML={{ __html: `Carteira OAB: <b>${showDetalhes.oab}</b>` }} />
            <div dangerouslySetInnerHTML={{ __html: `CPF: <b>${showDetalhes.cpf}</b>` }} />
            <div dangerouslySetInnerHTML={{ __html: `Celular: <b>${showDetalhes.fone}</b>` }} />
            <div dangerouslySetInnerHTML={{ __html: `E-mail: <b>${showDetalhes.email}</b>` }} />
            <div dangerouslySetInnerHTML={{ __html: `Áreas de atuação: <b>${showDetalhes.areas.map(a => a.descricao).join(', ')}</b>` }} />
            <div dangerouslySetInnerHTML={{ __html: `Assuntos tratados: <b>${showDetalhes.assuntos.map(a => a.descricao).join(', ')}</b>` }} />

            <AgendaContainer>
                <h1>
                    Agenda de plantões
                    <FiEdit onClick={() => { handleEditAgenda(showDetalhes) }} />
                </h1>

                <ul>
                    {showDetalhes.agenda.length > 0 ? showDetalhes.agenda.map(item => (
                        <li key={item.id}>
                            <FiCalendar />
                            <div dangerouslySetInnerHTML={{ __html: `<b>${weekDays.find(d => d.value === item.dia_semana).label}</b> - ${item.horario_inicio.substr(0, 5)} até ${item.horario_fim.substr(0, 5)}` }} />
                        </li>
                    )) : (
                        <p><i>Nenhum horário de plantão cadastrado</i></p>
                    )}
                </ul>
            </AgendaContainer>
        </Container>
    )
}
