import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { FiSave } from 'react-icons/fi'
import {
    Button, Select, Textarea, Textbox 
} from '../../../components/Form'
import { api, authHeaders } from '../../../services/api'
import Card from '../../../components/Card'

import { Container, FieldRow } from './styles'
import { loadTips } from '../../../util/tip'
import { TipContext } from '../../../contexts/TipContext'

const baseInitialValues = {}

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [modulos, setModulos] = useState(null)
    const [forms, setForms] = useState(null)
    const [selectedForm, setSelectedForm] = useState(null)
    const [tempForm, setTempForm] = useState(null)

    async function loadModulos() {
        const response = await api.get('tips/modulos', authHeaders())

        setModulos(response)
    }

    async function handleFilterByModulo(selected) {
        setSelectedForm(null)

        const selectedModulo = modulos.find(item => item.id === selected.value)

        const response = await api.get(`tips/${selectedModulo.id}/forms`, authHeaders())

        setForms(response)
    }

    async function handleFilterByForm(selected) {
        const selectedFormItem = forms.find(item => item.id === selected.value)

        setSelectedForm(selectedFormItem)

        const { campos } = selectedFormItem

        setInitialValues({
            [selectedFormItem.identificador]: selectedFormItem.texto || '',
            ...campos.reduce((result, current) => ({
                ...result,
                [current.nome]: current.texto || '',
                [`detalhes_${current.nome}`]: current.detalhes || ''
            }), {})
        })
    }

    async function handleSubmit(values) {
        try {
            await api.put('tips', values, authHeaders())

            toast.success('Textos de ajuda atualizados com sucesso.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadModulos()

        loadTips(setCodigo, 'form_tips')
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Textos de ajuda do sistema</h1>

                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        enableReinitialize
                    >
                        {({ touched }) => (
                            <Form>
                                <p style={{ margin: 8 }}>{tips?.formulario}</p>

                                <div className="select-container">
                                    <Select
                                        label="Módulo"
                                        id="modulo"
                                        withoutForm
                                        onChange={(selected) => {
                                            handleFilterByModulo(selected)

                                            setTempForm(null)
                                        }}
                                        options={modulos?.map(mod => ({
                                            label: mod.descricao,
                                            value: mod.id
                                        })) || []}
                                    />

                                    <Select
                                        label="Formulário"
                                        withoutForm
                                        id="formulario"
                                        onChange={(selected) => {
                                            handleFilterByForm(selected)

                                            setTempForm(selected)
                                        }}
                                        options={forms?.map(form => ({
                                            label: form.descricao,
                                            value: form.id
                                        })) || []}
                                        value={tempForm}
                                    />
                                </div>

                                {selectedForm && (
                                    <>
                                        <Textbox
                                            name={selectedForm.identificador}
                                            label="Texto de ajuda do formulário"
                                        />

                                        <h2>Campos</h2>
                                    </>
                                )}

                                {selectedForm?.campos?.map(campo => (
                                    <FieldRow key={campo.id}>
                                        <span>{campo.descricao}</span>

                                        <Textbox
                                            name={campo.nome}
                                            label="Texto de ajuda"
                                        />

                                        <Textarea
                                            name={`detalhes_${campo.nome}`}
                                            label="Detalhes do campo"
                                        />

                                    </FieldRow>
                                ))}

                                <div className="button-container">
                                    <Button type="submit" className="transparent" disabled={!Object.keys(touched).length}>
                                        Salvar alterações
                                        <FiSave size={20} />
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </Card>
            </main>
        </Container>
    )
}
