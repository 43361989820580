import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'

import Yup from '../../services/yup'

import { api, authHeaders } from '../../services/api'

import formUtils from '../../util/form'
import maskUtils from '../../util/masks'

import { Select, Textbox, Button } from '../../components/Form'

import { Container } from './styles'
import { TipContext } from '../../contexts/TipContext'
import { loadTips } from '../../util/tip'

const baseInitialValues = {
    nome: '',
    data_nascimento: '',
    areas: null,
    assuntos: null,
    cpf: '',
    email: '',
    fone: '',
    oab: ''
}

const validation = Yup.object({
    nome: Yup.string().required('Forneça seu nome completo.'),
    cpf: Yup.string().cpf().required('O CPF é obrigatório.'),
    email: Yup.string().email('E-mail inválido').required('Forneça um e-mail para contato.'),
    fone: Yup.string().mobile().required('Forneça um número de celular para contato.'),
    data_nascimento: Yup.string().date().required('Forneça a data de nascimento.'),
    oab: Yup.string().required('Forneça o número da carteira OAB.'),
    areas: Yup.string().ensure().required('Selecione a área de atuação do advogado.')
})

export default function ({ convenio, reload, editItem }) {
    const { setCodigo, tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [areas, setAreas] = useState([])
    const [tiposAssuntos, setTipoAssuntos] = useState([])

    useEffect(() => {
        if (editItem) {
            setInitialValues(editItem)
        }
    }, [editItem])

    async function loadAreas() {
        const response = await api.get('area_juridica', authHeaders())

        setAreas(response)
    }

    async function loadTiposAssuntos() {
        const response = await api.get('/atendimento_assunto', {
            params: {
                tipoId: 1
            }
        }, authHeaders())

        setTipoAssuntos(response)
    }

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)

            values = {
                ...values,
                areas: values.areas.map(a => a.value),
                assuntos: values.assuntos?.map(a => a.value) || [],
                convenio_id: convenio.id
            }

            if (editItem) {
                await api.put(`advogado/${editItem.id}`, values, authHeaders())
            } else {
                await api.post('advogado', values, authHeaders())
            }

            reload()

            toast.success(`Advogado ${editItem ? 'atualizado' : 'cadastrado'} com sucesso.`)
        } catch (e) {
            toast.error(`Erro ao ${editItem ? 'atualizar' : 'cadastrar'} o advogado.`)
        }
    }

    useEffect(() => {
        loadAreas()
        loadTiposAssuntos()

        loadTips(setCodigo, 'form_advogados')
    }, [])

    return (
        <Container>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validation}
                enableReinitialize
            >
                {({ setFieldValue }) => (
                    <Form>
                        <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                        <Textbox
                            name="nome"
                            label="Nome"
                        />

                        <Textbox
                            name="data_nascimento"
                            label="Data de nascimento"
                            mask={maskUtils.date}
                            inputMode="numeric"
                        />

                        <Textbox
                            name="cpf"
                            label="CPF"
                            mask={maskUtils.cpf}
                            inputMode="numeric"
                        />

                        <Textbox
                            name="fone"
                            label="Celular comercial"
                            mask={maskUtils.mobile}
                            inputMode="numeric"
                        />

                        <Textbox
                            name="email"
                            label="E-mail"
                        />

                        <Textbox
                            name="oab"
                            label="Carteira OAB"
                        />

                        <Select
                            name="areas"
                            label="Áreas de atuação"
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            options={areas.map(area => ({
                                label: area.descricao,
                                value: area.id
                            }))}
                            isMulti
                        />

                        <Select
                            name="assuntos"
                            label="Assuntos"
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            options={tiposAssuntos.map(assunto => ({
                                label: assunto.descricao,
                                value: assunto.id
                            }))}
                            isMulti
                            containerStyle={{ gridColumn: '2/span 2' }}
                        />

                        <div className="button-container">
                            <Button type="submit" className="transparent">
                                {editItem ? 'Salvar alterações' : 'Cadastrar'}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
