import styled from 'styled-components'

import Card from '../../../components/Card'

export const Container = styled(Card).attrs({
    className: 'box-contatos'
})`
	grid-area: contatos;
	display: grid;
	grid-template-columns: auto auto 24px;
	grid-template-rows: min-content min-content auto;
	grid-template-areas:
		'title x icon'
		'form form form'
		'table table table';

	> h1 {
		font-size: 19px;
		font-weight: 500;
	}

	form {
		width: 100%;
	}

	> svg {
		color: #FFF;
		transition: 300ms;

		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}
	}

	@media(max-width: 500px) {
		grid-template-columns: auto 24px;
		grid-template-areas:
			'title icon'
			'form form'
			'table table';
	}

	@media(max-width: 440px) {
		margin: 0 !important;
	}

	.table-container {
		grid-area: table;
	}

	.spinner-container {
		grid-area: table;
		justify-self: center;
	}
`
