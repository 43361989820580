import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	
	.table-container {
		width: calc(100% - 16px);
		margin: 24px 8px;
	}
`
