import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		h1 {
			margin-bottom: 20px;
			grid-area: title;
		}

		.card {
			display: flex;
			flex-direction: column;
			min-height: 80vh;

			.pie,
			.line {
				margin-top: 40px;
			}

			form {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;

				button {
					margin-top: 24px;
					width: fit-content;
				}

				@media(max-width: 800px) {
					display: flex;
					flex-direction: column;

					button {
						align-self: flex-end;
					}
				}
			}

			.require-rotation {
				margin-top: 40px;
			}
		}

		@media(max-width: 900px) {
			padding: 35px 20px;
		}

		@media(max-width: 400px) {
			padding: 35px 0;
		}
	}
`

export const LineChartContainer = styled.div`
	background-color: #FFF;
	border-radius: 4px;
	margin-top: 24px;
	padding: 0 40px 40px;

	@media(max-width: 700px) {
		display: none;
	}
`
