import React, { useContext, useEffect } from 'react'
import { Form, Formik } from 'formik'

import {
    Button, Select, Textarea, Textbox 
} from '../../../../components/Form'

import { RegraContext } from '../../../../contexts/RegraContext'
import { GlobalContext } from '../../../../contexts/GlobalContext'

import { Container } from './styles'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

export default function () {
    const { user } = useContext(GlobalContext)
    const {
        initialValues, handleSubmit, regraEdit, visoes 
    } = useContext(RegraContext)
    const { setCodigo, tips } = useContext(TipContext)

    useEffect(() => {
        loadTips(setCodigo, 'form_regra_envio')
    }, [])

    return (
        <Container>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize
            >
                {({ setFieldValue }) => (
                    <Form>
                        <p style={{ margin: 8, gridColumn: '1/span 2' }}>{tips?.formulario}</p>
                        
                        <Textbox 
                            name="descricao"
                            label="Nome da regra"
                        />

                        {user?.perfil?.id === 1 ? (
                            <Select 
                                name="visao"
                                label="View aplicada"
                                options={visoes?.map(visao => ({
                                    label: visao.descricao,
                                    value: visao.id
                                })) || []}
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            />
                        ) : <div />}

                        <Textarea 
                            name="definicao"
                            label="Definição detalhada da regra"
                        />

                        <div className="button-container">
                            <Button className="transparent" type="submit">
                                {regraEdit ? 'Salvar alterações' : 'Cadastrar regra'}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
