import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

const initialValues = {
    tipo: { label: 'Despesas e receitas', value: '' }
}

const validation = Yup.object().shape({
    tipo: Yup.string().ensure()
})

const nomeRelatorio = 'Relatório de Eventos'

export default function (props) {
    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const { tipo } = values

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioEventos',
                parametros: {
                    tipo: tipo?.value || '' 
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })
            
            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch(e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm 
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select 
                            name="tipo"
                            label="Tipo de evento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Despesas e receitas', value: '' },
                                { label: 'Despesas', value: 'D' },
                                { label: 'Receitas', value: 'R' }
                            ]}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Spinner
                            visible={isSubmitting} 
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
