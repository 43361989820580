import React from 'react'

import ProcessoProvider from '../../../contexts/ProcessoContext'

import Listagem from './Listagem'

export default function () {
    return (
        <ProcessoProvider>
            <Listagem />
        </ProcessoProvider>
    )
}
