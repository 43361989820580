import React from 'react'
import { Line } from '@reactchartjs/react-chart.js'

import { Container } from './styles'

export default function ({
    width, height, fontColor = '#FFF', data 
}) {
    return (
        <Container canvas={(width && height) ? { width, height } : null}>
            <Line 
                data={{
                    labels: Object.keys(data[0].dados),
                    datasets: data.map(item => ({
                        data: Object.values(item.dados),
                        backgroundColor: 'transparent',
                        borderColor: item.borderColor,
                        label: item.label
                    }))
                }} 
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        labels: {
                            fontColor,
                            fontSize: 14
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                fontColor,
                                fontSize: 14,
                                stepSize: 500,
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor,
                                fontSize: 14,
                                stepSize: 1
                            }
                        }]
                    },
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true
                            }
                        }
                    },
                    elements: {
                        point: {
                            borderWidth: 10,
                            hoverBorderWidth: 20
                        }
                    }
                }}
            />
        </Container>
    )
}
