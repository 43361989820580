import styled from 'styled-components'

export const Container = styled.div`
	> form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: flex-start;

		.button-container {
			grid-column: 1/span 3;
			display: flex;
			justify-content: flex-end;
			gap: 4px;

			> button {
				width: fit-content;
			}
		}

		.textarea {
			grid-column: 1/span 2;
		}

		details {
			grid-column: 1/span 3;
			margin: 24px 8px 18px;
			transition: 300ms;
			border: solid 1px #fff;
			border-bottom-width: 3px;
			padding: 16px;
			border-radius: 6px;
			padding: 0;

			&[open] {
				summary {
					background-color: #FFF;
					color: #424242;
				}
			}

			summary {
				cursor: pointer;
				padding: 18px;
				transition: 300px;

				&:hover {
					background-color: #FFF;
					color: #424242;
				}
			}

			> div {
				padding: 8px 16px 16px;
			}
			
			&.endereco {
				grid-row: 5;
				margin-bottom: 8px;
			}

			&.parentesco {
				grid-row: 6;
				margin-top: 8px;

				.field {
					text-align: left;
				}
			}

			&.taxas {
				grid-row: 7;
				margin-top: 0;
			}

			&.adicionais {
				grid-row: 8;
				margin-top: 0;
			}
		}

		.badge {
			grid-column: 1/span 3;
			background-color: #1565c0;
			border-radius: 6px;
			color: #FFF;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			font-size: 12px;
			margin: 5px 8px;
			width: fit-content;
			padding: 8px;
		}

		> table {
			grid-column: 1/span 3;
			border-spacing: 0;
			margin: 20px 8px 5px;

			thead {
				tr {
					background-color: rgba(255,255,255,0.9);
					color: #424242;
					font-weight: 400;
					font-size: 13px;
					border-radius: 10px;

					th {
						padding: 8px 10px;
						font-weight: 600;
						height: 34px;
						color: #424242;
						border-left: solid 1px #0002;

						&:first-child {
							border-top-left-radius: 4px;
						}

						&:last-child {
							border-top-right-radius: 4px;
						}
					}
				}
				
			}

			tbody {
				tr {
					background-color: rgba(255,255,255,0.9);
					color: #424242;
					font-weight: 400;
					font-size: 13px;

					td {
						text-align: center;
						height: 46px;
						min-width: 52px;
						border-top: solid 0.5px #333;
						border-left: solid 1px #0002;						

						.field {
							margin: 0;
							width: 100%;
							
							> input {
								text-align: center;
								padding: 0;
							}

							> span {
								display: none;
							}
						}

						input {
							box-shadow: unset;
							background-color: transparent;
						}

						.select {
							> div {
								box-shadow: none;
							}

							svg {
								fill: #424242;
							}
						}

						.fab {
							margin: 0 auto;
						}

						> button {
							margin: 10px 0 0;
						}

						&.idade {
							display: flex;
							align-items: center;

							span {
								padding: 0 8px;
							}

							.field {
								width: auto;
							}
						}

						&.universidade > div,
						&.pcd > div {
							display: block;
						}
					}

					&:not(.footer) {
						border-radius: 10px;
						
						& td:first-child {
							border-bottom-left-radius: 4px;
						}

						& td:last-child {
							border-bottom-right-radius: 4px;
							
							button {
								display: block;
								margin: auto;

								> svg {
									font-size: 12px;
								}
							}
						}
					}

					&.footer,
					&.errors {
						background-color: unset;

						td {
							border-left: unset;
							border-top: unset;
						}
					}
				}
			}
		}

		@media(max-width: 800px) {
			grid-template-columns: 1fr 1fr;

			> button,
			> table,
			.badge,
			details {
				grid-column: 1/span 2;
			}
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const ErrorContainer = styled.div`
	grid-column: 1/span 3;
	display: flex;
	flex-wrap: wrap;

	span {
		color: #222;
		font-size: 12px;
		background-color: #ffeb3b;
		border-radius: 4px;
		padding: 5px;
		display: block;
		margin-top: 6px;
		margin-right: 8px;
		width: fit-content;
	}
`

export const ParentescoDocumentosContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	> div {
		display: grid;
		grid-template-columns: 1fr 2.5fr 40px;
		align-items: center;

		> button {
			margin-top: 22px;
		}
	}

	> button {
		width: fit-content;
		margin-top: 12px;
		align-self: center;
	}

	@media(max-width: 900px) {
		> div {
			grid-template-columns: 1fr 1fr 40px;
		}
	}

	@media(max-width: 700px) {
		> div {
			display: flex;
			flex-direction: column;
		}
	}
`

export const TaxasContainer = styled.div`
	width: 100%;
	max-width: 700px;
	display: flex;
	flex-wrap: wrap;

	.field {
		width: 300px;
	}

	> button {
		grid-column: 1/span 3;
		margin-top: 12px;
	}
`

export const ItensAdicionaisContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	> div {
		display: grid;
		grid-template-columns: 3fr 1fr 40px;
		align-items: center;

		> button {
			margin-top: 22px;
		}
	}

	> button {
		width: fit-content;
		margin-top: 12px;
		align-self: center;
	}

	@media(max-width: 900px) {
		> div {
			grid-template-columns: 1fr 1fr 40px;
		}
	}

	@media(max-width: 700px) {
		> div {
			display: flex;
			flex-direction: column;
		}
	}
`
