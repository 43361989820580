import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'
import { format } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeCalendarGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    pagamento: '',
    tipo: '',
    fornecedor: '',
    evento: ''
}

const validation = Yup.object().shape({
    pagamento: Yup.string().nullable(),
    tipo: Yup.string().ensure(),
    fornecedor: Yup.string().nullable(),
    evento: Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de despesas por parceiro'

export default function (props) {
    const [fornecedores, setFornecedores] = useState([])
    const [eventos, setEventos] = useState([])

    async function loadFornecedores() {
        const response = await api.get('fornecedor', authHeaders())

        setFornecedores(response)
    }

    async function loadEventos() {
        const response = await api.get('evento_contabil', authHeaders())

        setEventos(response)
    }

    useEffect(() => {
        loadFornecedores()
        loadEventos()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            pagamento,
            evento,
            fornecedor,
            tipo
        } = values

        const [inicio, fim] = pagamento

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioDespesasPorFornecedor',
                parametros: {
                    idEvento: evento ? evento.map(e => e.value).join(',') : '',
                    idFornecedor: fornecedor ? fornecedor.map(f => f.value).join(',') : '',
                    caixa: tipo.value ? tipo.value === 'MV' ? '1' : '0' : null,
                    dataInicio: inicio ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim ? format(new Date(fim), 'yyyy-MM-dd') : null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="pagamento"
                            label="Periodo de busca"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f1' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeCalendarGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="tipo"
                            label="Tipo de evento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Despesas', value: 'D' },
                                { label: 'Movimento de Caixa', value: 'MV' }
                            ]}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="fornecedor"
                            label="Parceiro"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={fornecedores.map(({ id, pessoa }) => ({
                                label: pessoa.nome,
                                value: id
                            }))}
                            isMulti
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Select
                            name="evento"
                            label="Evento"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            options={eventos.map(({ id, nome, conta_contabil }) => ({
                                label: `${nome}${conta_contabil?.codigo_reduzido ? ` - ${conta_contabil.codigo_reduzido}` : ''}`,
                                value: id
                            }))}
                            isMulti
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
