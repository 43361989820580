import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { FaEye } from 'react-icons/fa'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'

import { getAge } from '../../../../util/date'
import maskUtils from '../../../../util/masks'

import { api, authHeaders } from '../../../../services/api'

import { Container, DetalhesContainer } from './styles'
import { Button, Select } from '../../../../components/Form'
import { getPortabilidadeDescricao } from '../../Associado/AprovacaoAdesaoServico'

export default function () {
    const [associadosComPlano, setAssociadosComPlano] = useState(null)
    const [showDetalhes, setShowDetalhes] = useState(null)
    const [logs, setLogs] = useState(null)
    const [showTransferencia, setShowTransferencia] = useState(false)
    const [planoSaude, setPlanosSaude] = useState(null)
    const [planoDestino, setPlanoDestino] = useState(null)
    const [estaTransferindo, setEstaTransferindo] = useState(false)

    async function loadAssociadosComPlano() {
        setAssociadosComPlano(null)

        const response = await api.get('convenio_associado/listar_adesoes', {
            ...authHeaders(),
            params: { status_id: [1, 2, 3, 4, 5, 6, 7, 8, 9] }
        })

        const data = []

        for (const adesao of response) {
            const totalTitular = Number(adesao.valor_pago)

            const totalDependentes = adesao.dependentes
                .filter(dep => dep.status !== 4)
                .reduce((result, dep) => result + Number(dep.dependente.valor_pago), 0)

            const adicionaisDependentes = adesao.dependentes
                .filter(dep => dep.status !== 4)
                .reduce((result, dep) => [
                    ...result,
                    ...dep.dependente.adicionais.filter(adc => !adc.convenio_dependente_adicional.deleted_at).map(adc => ({
                        ...adc,
                        nome: dep.dependente.pessoa.nome,
                        valor_number: Number(adc.valor),
                        valor: maskUtils.maskApply.currency(adc.valor)
                    }))
                ], [])

            const totalAdicionaisTitular = adesao.adicionais
                .filter(adc => !adc.deleted_at)
                .reduce((result, adc) => result + Number(adc.valor), 0)

            const totalAdicionaisDependentes = adicionaisDependentes.reduce((result, adc) => result + adc.valor_number, 0)

            data.push({
                id: `${adesao.id}_${adesao.convenio.id}`,
                convenio_id: adesao.convenio.id,
                pessoa_id: adesao.associado.pessoa.id,
                convenio_associado_id: adesao.id,
                nome: adesao.associado.pessoa.nome,
                convenio: adesao.convenio.empresa.nome,
                inicio_vigencia: adesao.inicio_vigencia ? format(new Date(adesao.inicio_vigencia), 'dd/MM/yyyy') : '-',
                dependentes_count: String(adesao.dependentes.filter(dep => dep.status !== 4).length),
                adicionais_count: String(adesao.adicionais.length),
                portabilidade: adesao.portabilidade ? adesao.portabilidade === 1 ? 'UNIMED Belém para UNIMED Belém' : 'Outras operadoras ou outras UNIMEDs' : null,
                status_id: adesao.convenio_status.id,
                status: adesao.convenio_status.descricao,
                dependentes: [
                    {
                        id: new Date().getTime(),
                        nome: `${adesao.associado.pessoa.nome} (titular)`,
                        data_nascimento: `${format(new Date(adesao.associado.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(adesao.associado.pessoa.data_nascimento)} anos)`,
                        admissao: format(new Date(adesao.inicio_vigencia), 'dd/MM/yyyy'),
                        demissao: adesao.deleted_at ? format(new Date(adesao.deleted_at), 'dd/MM/yyyy') : '-',
                        valor: maskUtils.maskApply.currency(adesao.valor_pago),
                        portabilidade: getPortabilidadeDescricao(adesao.portabilidade)
                    },
                    ...adesao.dependentes.map(dep => ({
                        id: dep.id,
                        nome: dep.dependente.pessoa.nome,
                        data_nascimento: `${format(new Date(dep.dependente.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(dep.dependente.pessoa.data_nascimento)} anos)`,
                        admissao: dep.inicio_vigencia ? format(new Date(dep.inicio_vigencia), 'dd/MM/yyyy') : '-',
                        demissao: dep.deleted_at ? format(new Date(dep.deleted_at), 'dd/MM/yyyy') : '-',
                        valor: dep.deleted_at ? '' : maskUtils.maskApply.currency(dep.dependente.valor_pago),
                        portabilidade: getPortabilidadeDescricao(dep.portabilidade)
                    }))
                ],
                adicionais: [
                    ...adesao.adicionais.map(adicional => ({
                        id: adicional.id,
                        nome: adesao.associado.pessoa.nome,
                        descricao: adicional.descricao,
                        valor: maskUtils.maskApply.currency(adicional.valor)
                    })),
                    ...adicionaisDependentes
                ],
                taxas: [
                    {
                        id: 1,
                        descricao: 'Taxa administrativa',
                        valor: adesao.convenio.taxa_administrativa ? maskUtils.maskApply.currency(Number(adesao.convenio.taxa_administrativa)) : null,
                        ocultar: !Number(adesao.convenio.taxa_administrativa)
                    },
                    {
                        id: 2,
                        descricao: 'Taxa bancária',
                        valor: adesao.convenio.taxa_bancaria ? maskUtils.maskApply.currency(Number(adesao.convenio.taxa_bancaria)) : null,
                        ocultar: !Number(adesao.convenio.taxa_bancaria)
                    }
                ],
                total_taxas: maskUtils.maskApply.currency(Number(adesao.convenio.taxa_bancaria) + Number(adesao.convenio.taxa_administrativa)),
                valor_total: maskUtils.maskApply.currency(
                    adesao.dependentes.reduce((total, current) => current.status === 4 ? total : total + Number(current.dependente.valor_pago), 0) + Number(adesao.valor_pago)
                ),
                valor_total_adicionais: maskUtils.maskApply.currency(totalAdicionaisTitular + totalAdicionaisDependentes),
                valor_total_plano: maskUtils.maskApply.currency(
                    totalTitular 
                    + totalDependentes 
                    + totalAdicionaisTitular 
                    + totalAdicionaisDependentes
                    + (adesao.convenio.taxa_administrativa ? Number(adesao.convenio.taxa_administrativa) : 0)
                    + (adesao.convenio.taxa_bancaria ? Number(adesao.convenio.taxa_bancaria) : 0)
                )
            })
        }

        setAssociadosComPlano(data)
    }

    async function loadPlanos() {
        const response = await api.get('convenio', {
            params: {
                convenio_area_atuacao_id: 1
            },
            ...authHeaders()
        })
        
        setPlanosSaude(response)
    }

    async function handleTransferirPlano(convenio_associado_id) {
        try {
            setEstaTransferindo(true)

            await api.put(`convenio_associado/${convenio_associado_id}`, { convenio_id: planoDestino }, authHeaders())

            toast.success('Transferência realizada.')

            setPlanoDestino(null)
            setShowTransferencia(false)
            setShowDetalhes(null)
            setEstaTransferindo(false)

            loadAssociadosComPlano()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function loadLogs() {
        const response = await api.get(`convenio_associado/logs_transferencia/${showDetalhes.pessoa_id}`, authHeaders())

        setLogs(response.map(item => ({
            id: item.id,
            origem: item.detalhe['convenio anterior'].nome,
            destino: item.detalhe['transferido para'].nome,
            data: format(new Date(item.createdAt), 'dd/MM/yyyy')
        })))
    }

    useEffect(() => {
        loadAssociadosComPlano()

        loadPlanos()
    }, [])

    useEffect(() => {
        if(showDetalhes) {
            loadLogs()
        } else {
            setLogs(null)
        }
    }, [showDetalhes])

    const estaRegular = showDetalhes?.status_id === 6

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Beneficiários de Plano de Saúde</h1>

                        {associadosComPlano ? (
                            <Table
                                headers={[
                                    { value: 'Associado', name: 'nome' },
                                    { value: 'Plano', name: 'convenio', centered: true },
                                    { value: 'Inicio vigência', name: 'inicio_vigencia', centered: true },
                                    { value: 'Situação', name: 'status', centered: true },
                                    { value: 'Nº dependentes', name: 'dependentes_count', centered: true },
                                    { value: 'Nº serviços opcionais', name: 'adicionais_count', centered: true }
                                ]}
                                data={associadosComPlano}
                                actions={[
                                    {
                                        name: 'Detalhar',
                                        action: item => {
                                            setShowDetalhes(item)
                                        },
                                        icon: FaEye,
                                        iconSize: 16
                                    }
                                ]}
                                filterable
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!showDetalhes}
                handleClose={() => { 
                    setShowDetalhes(null) 
                }}
                title="Detalhes da associação ao plano de saúde"
            >
                {showDetalhes && (
                    <DetalhesContainer>
                        <p>
                            {'Associado(a): '}
                            <b>{showDetalhes.nome}</b>
                        </p>

                        <div className="plano-container">
                            <span dangerouslySetInnerHTML={{ __html: `Serviço: <b>${showDetalhes.convenio}</b>` }} />
                            
                            <div>
                                {showTransferencia && (
                                    <Select 
                                        name="plano"
                                        label="Plano de saúde"
                                        withoutForm
                                        onChange={selected => {
                                            setPlanoDestino(selected.value)
                                        }}
                                        options={planoSaude.filter(plano => plano.id !== showDetalhes.convenio_id).map(plano => ({
                                            value: plano.id,
                                            label: plano.empresa.nome
                                        }))}
                                    />
                                )}

                                {!showTransferencia && estaRegular && (
                                    <Button className="transparent" onClick={() => { setShowTransferencia(true) }}>
                                        Transferir plano
                                    </Button>
                                )}

                                {planoDestino && (
                                    <Button
                                        className="transparent"
                                        onClick={() => {
                                            handleTransferirPlano(showDetalhes.convenio_associado_id)
                                        }}
                                        loading={estaTransferindo}
                                    >
                                        Confirmar transferência
                                    </Button>
                                )}
                            </div>
                        </div>

                        <p>
                            {'Inicio vigência: '}
                            <b>{showDetalhes.inicio_vigencia}</b>
                        </p>

                        <p>
                            {'Portabilidade: '}
                            <b>{showDetalhes.portabilidade ? showDetalhes.portabilidade : 'Não'}</b>
                        </p>

                        <p>
                            {'Valor total do plano: '}
                            <b>{showDetalhes.valor_total_plano}</b>
                        </p>

                        <Table
                            caption="Beneficiários"
                            headers={[
                                { name: 'nome', value: 'Dependente' },
                                { name: 'data_nascimento', value: 'Data de nascimento', centered: true },
                                { name: 'admissao', value: 'Admissão', centered: true },
                                { name: 'demissao', value: 'Demissão', centered: true },
                                { name: 'valor', value: 'Valor', centered: true },
                                { name: 'portabilidade', value: 'Portabilidade', centered: true }
                            ]}
                            data={showDetalhes.dependentes}
                            className="table-beneficiarios"
                            footers={[
                                {
                                    text: 'Total',
                                    colspan: 5
                                },
                                {
                                    text: showDetalhes.valor_total,
                                    align: 'center'
                                }
                            ]}
                        />

                        <Table
                            caption="Serviços opcionais contratados"
                            headers={[
                                { name: 'nome', value: 'Nome' },
                                { name: 'descricao', value: 'Serviço adicional' },
                                { name: 'valor', value: 'Valor', centered: true }
                            ]}
                            data={showDetalhes.adicionais}
                            className="table-adicionais"
                            footers={[
                                {
                                    text: 'Total',
                                    colspan: 2
                                },
                                {
                                    text: showDetalhes.valor_total_adicionais,
                                    align: 'center'
                                }
                            ]}
                        />

                        <Table
                            caption="Taxas"
                            headers={[
                                { name: 'descricao', value: 'Taxa' },
                                { name: 'valor', value: 'Valor', centered: true }
                            ]}
                            data={showDetalhes.taxas.filter(tx => !tx.ocultar)}
                            className="table-taxas"
                            footers={[
                                {
                                    text: 'Total'
                                },
                                {
                                    text: showDetalhes.total_taxas,
                                    align: 'center'
                                }
                            ]}
                        />

                        <Table
                            caption="Histórico de transferências"
                            headers={[
                                { name: 'origem', value: 'Plano origem', centered: true },
                                { name: 'destino', value: 'Plano destino', centered: true },
                                { name: 'data', value: 'Data da transferência', centered: true }
                            ]}
                            data={logs || []}
                            className="table-historico"
                        />
                    </DetalhesContainer>
                )}
            </Modal>
        </>
    )
}
