import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		form {
			display: grid;
			grid-template-columns: 1fr 0.8fr 1fr;

			> h1 {
				grid-column: 1/span 3;
			}

			> p {
				grid-column: 1/span 3;
				margin-bottom: 10px;
			}

			> button {
				align-self: flex-end;
				width: fit-content;
			}

			.select {
				margin-left: 0;
			}

			.badge {
				grid-column: 1/span 3;
				background-color: #ffeb3b;
				border-radius: 6px;
				color: #424242;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				font-size: 14px;
				margin-top: 14px;
				width: fit-content;
				padding: 8px;

				> b {
					margin: 0 4px;
				}
			}

			.progress-bar {
				grid-column: 2;
				width: calc(100% - 16px);
				margin: 14px 8px;
			}
		}

		@media(max-width: 900px) {
			form {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}

		@media(max-width: 700px) {
			padding: 20px 10px;

			form {
				display: flex;
				flex-direction: column;

				.select {
					margin-left: 8px;
				}
			}
		}
	}
`
