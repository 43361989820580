import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 2fr 1.5fr 1fr 1fr;
		grid-gap: 0 14px;

		.textbox,
		.select,
		.calendar {
			margin: 8px 0;
			width: 100%;
		}

		> p {
			grid-column: 1/span 5;
			margin-bottom: 15px;
		}

		.button-container {
			width: 100%;
			grid-column: 1/span 5;
			display: flex;
			justify-content: flex-end;

			button {
				margin: 8px 0 8px 14px;
			}
		}

		@media(max-width: 1000px) {
			grid-template-columns: 1fr 2fr 1fr;

			.button-container {
				grid-column: 2/span 2;
				justify-content: flex-end;

				button {
					width: auto;
				}
			}
		}

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;

			button {
				width: 100%;
			}
		}
	}
`
