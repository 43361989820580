import React, { useState, useEffect } from 'react'

import { Select, Textarea } from '../../../../../../components/Form'

import { api, authHeaders } from '../../../../../../services/api'

export default function ({ setFieldValue }) {
    const [connections, setConnections] = useState([])

    async function loadConnections() {
        const response = await api.get('task_connections', authHeaders())

        setConnections(response)
    }

    useEffect(() => {
        loadConnections()
    }, [])

    return (
        <>
            <Select 
                label="Conexão"
                name="conexao"
                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                options={connections.map(conn => ({
                    label: `[${conn.dialect.toUpperCase()}] ${conn.database}`, 
                    value: conn.id
                }))}
            />

            <Textarea 
                label="Comando SQL"
                name="sql"
            />
        </>
    )
}
