export const stringNomalize = str => {
    if (!str) {
        return ''
    }

    return String(str).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}

export function fillWithZeros(number, digits = 2) {
    const base = Array.from({ length: digits }).reduce(result => `${result}0`, '')

    return (base + Number(number)).slice(-digits)
}

export function unmask(str) {
    return str.replace(/[-/ .,(R$)]/g, '')
}

export function pluralize(count, wordSing, wordPlural, empty = 'Nenhum') {
    if (!count) {
        return `${empty} ${wordSing}`
    }

    if (count === 1) {
        return `${count} ${wordSing}`
    }

    return `${count} ${wordPlural}`
}

export function activateLinks(text) {
    const regex = /https?:\/\/(www\.)?[a-zA-Z0-9@:%.-_+~#=]+\.[a-zA-Z0-9()]+\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gm

    const urls = text.match(regex)

    if (!urls) { return text }

    if (urls.some(url => urls.filter(u => u === url).length > 1)) {
        return text
    }

    urls.forEach(url => { text = text.replace(url, `<a href="${url}" target="_blank">${url}</a>`) })

    return text
}

export function removerTagsHTML(texto) {
    const regexTagsHTML = /(<([^>]+)>)/ig

    return texto.replace(regexTagsHTML, '')
}

export function removerTag(htmlText, tag) {
    const regex = new RegExp(`<${tag}\\b[^>]*>([\\s\\S]*?)<\\/${tag}>`, 'gi')

    return htmlText.replace(regex, '')
}

const accentsRegex = /[áéíóúÁÉÍÓÚâêôÂÊÔçÇãÃõÕ/:]/g

const replacers = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',

    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',

    â: 'a',
    ê: 'e',
    ô: 'o',
    Â: 'A',
    Ê: 'E',
    Ô: 'O',

    ç: 'c',
    Ç: 'C',

    ã: 'a',
    Ã: 'A',

    õ: 'o',
    Õ: 'O',

    '/': '_',
    ':': '_'
}

export function replaceSpecialChars(str) {
    return str.replace(accentsRegex, match => replacers[match])
}

export default {
    extractDomain: url => {
        const matched = url.match(/^(http(s)?:\/\/)?\w+[a-z0-9:.-_]+\//i)

        if (matched.length) {
            return matched[0].replace(/\/$/, '')
        }

        return ''
    },

    moneyFormat: value => {
        if (value === null) {
            return ''
        }

        switch (typeof value) {
            case 'string':
                return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`
            case 'number':
                return `R$ ${value.toFixed(2).replace('.', ',')}`
            default: throw new Error('Invalid value passed to function moneyFormat (in utils/string.js).')
        }
    },

    capitalize: value => {
        if (!value) {
            return ''
        }

        const names = value.trim().split(' ')

        const namesCapitalizados = names.reduce((result, name) => {
            const capitalizado = `${name[0].toUpperCase()}${name.substr(1).toLowerCase()}`

            return `${result} ${capitalizado}`
        }, '')

        return namesCapitalizados.trim()
    },

    reduceName(name) {
        if (!name) {
            return ''
        }

        const names = name.split(' ')

        if (names.length > 1) {
            return `${names[0]} ${names[names.length - 1]}`
        }
        return names[0]
    }
}

export function getFromSubstring(text, search) {
    const position = text.search(search)

    return text.substring(position)
}
