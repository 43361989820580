import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.button-container {
			display: flex;
			justify-content: flex-end;
		}

		@media(max-width: 850px) {
			padding: 35px;
		}

		@media(max-width: 600px) {
			padding: 35px 0;
		}
	}
`
