import React, { useState } from 'react'
import { FiPlusCircle, FiXCircle } from 'react-icons/fi'

import FormContatos from '../../../components/FormContatos'
import Spinner from '../../../components/Spinner'
import Table from '../../../components/Table'

import { api, authHeaders } from '../../../services/api'

import { Container } from './styles'

export default function ({ pessoa, reload, editable = true }) {
    const [showForm, setShowForm] = useState(false)
    const [initialValues, setInitialValues] = useState(null)
    const [editId, setEditId] = useState(null)

    async function handlePrepareEdit(id) {
        setEditId(id)

        const { contato, tipo_contato } = pessoa?.contatos.find(c => c.id === id) || []

        setInitialValues({
            tipo_contato: { value: tipo_contato.id, label: tipo_contato.descricao },
            valor_contato: contato
        })

        setShowForm(true)
    }

    async function handleDelete(id) {
        try {
            await api.delete(`contato/${id}`, authHeaders())

            reload()
        } catch (e) {
            return false
        }

        return true
    }

    return (
        <Container className="animated fadeInUp delay-700ms">
            <h1>Contatos</h1>

            {editable ? (
                <>
                    {showForm ? (
                        <FiXCircle
                            size={24}
                            data-tip="Fechar"
                            data-for="tooltip"
                            style={{ gridArea: 'icon' }}
                            onClick={() => {
                                setShowForm(false)
                                setInitialValues(null)
                                setEditId(null)
                            }}
                        />
                    ) : (
                        <FiPlusCircle
                            size={24}
                            data-tip="Adicionar"
                            data-for="tooltip"
                            style={{ gridArea: 'icon' }}
                            onClick={() => {
                                setShowForm(true)
                            }}
                        />
                    )}
                </>
            ) : (
                <div />
            )}

            {showForm && (
                <FormContatos
                    contatoInitialValues={initialValues}
                    editId={editId}
                    pessoa={pessoa}
                    style={{ gridArea: 'form' }}
                    className="animated fadeIn"
                    reload={() => {
                        reload()
                        setShowForm(false)
                        setInitialValues(null)
                        setEditId(null)
                    }}
                />
            )}

            {pessoa ? (
                <Table
                    headers={[
                        { name: 'contato', value: 'Contato' },
                        {
                            name: 'icone', value: 'Tipo', icon: true, style: { width: 80 }
                        }
                    ]}
                    data={pessoa?.contatos || []}
                    handlePrepareEdit={editable && handlePrepareEdit}
                    handleDelete={editable && handleDelete}
                    confirmExclusion={{
                        attr: 'contato',
                        template: 'Deseja realmente excluir o contato #attr#?'
                    }}
                    emptyLabel="Nenhum contato adicionado"
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
