import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
	.react-tabs {
		.react-tabs__tab-list {
			border-bottom: 0;
			margin: 0 20px 1px 20px;

			.react-tabs__tab {
				font-size: 16px;
				color: #fff;
				padding: 10px 14px;
				background-color: #0003;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				margin-left: 4px;
				
				&.react-tabs__tab--selected {
					font-weight: 700;
					border: 0;
					background-color: #0002;

					&:focus {
						border: 0;
						box-shadow: unset;
					}
				}

				&:hover {
					background-color: #0003;
				}

				@media(max-width: 700px) {
					border-radius: 0;

					&:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}
				}

				@media(max-width: 500px) {
					&:not(.react-tabs__tab--selected) {
						display: none;
					}
				}

				&.react-tabs__tab--disabled {
					color: #fff9;
				}
			}
		}

		.react-tabs__tab-panel {
			background-color: #00000022;
			border-radius: 16px;
			padding: 16px 24px;
		}
	}
`
