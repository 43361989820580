import RelatorioAnuidades from './Contribuicao/Anuidades'
import RelatorioAnuidadesPendentesEPagas from './Contribuicao/AnuidadesPendentesEPagas'
import RelatorioMensalidades from './Contribuicao/Mensalidades'
import RelatorioAssociadosQuites from './Contribuicao/AssociadosQuites'
import RelatorioAssociadosNaoQuites from './Contribuicao/AssociadosNaoQuites'
import RelatorioAssociadosIdosos from './Contribuicao/AssociadosIdosos'
import RelatorioResumoGeral from './Financeiro/ResumoGeral'
import RelatorioEventos from './Financeiro/Evento'
import RelatorioResumoGeralNaoOficial from './Financeiro/ResumoGeralNaoOficial'
import RelatorioReceitas from './Financeiro/Receitas'
import RelatorioDespesas from './Financeiro/Despesas'
import RelatorioExtratosBancarios from './Financeiro/ExtratosBancarios'
import RelatorioLancamentos from './Financeiro/Lancamentos'
import RelatorioRepassesInstituicaoPagamento from './Financeiro/RepassesInstituicaoPagamento'
import RelatorioContabilidadeReceitas from './Contabilidade/Receitas'
import RelatorioContabilidadeDespesas from './Contabilidade/Despesas'
import RelatorioMovimentodeCaixa from './Contabilidade/MovimentodeCaixa'
import RelatorioFornecedor from './Fornecedor/DadosFornecedor'
import RelatorioPendenciasFornecedor from './Fornecedor/PendenciasFornecedor'
import RelatorioDespesasPorFornecedor from './Fornecedor/DespesasPorFornecedor'
import RelatorioPagamentosPlanodeSaude from './PlanodeSaude/Pagamentos'
import RelatorioAtivosInativosPlanodeSaude from './PlanodeSaude/AtivosInativos'
import RelatorioAtivadosCanceladosPlanodeSaude from './PlanodeSaude/AtivadosCancelados'
import RelatorioPlanodeSaudePorStatus from './PlanodeSaude/PlanoPorStatus'
import RelatorioPlanoPorTipodePlano from './PlanodeSaude/PlanoPorTipoPlano'
import RelatorioAtivosSemContribuicao from './PlanodeSaude/AtivosSemContribuicao'
import RelatorioQuitesComPlanodeSaude from './PlanodeSaude/QuitesPlanodeSaude'
import RelatorioNaoQuitesComPlanodeSaude from './PlanodeSaude/NaoQuitesPlanodeSaude'
import RelatorioBalanco from './Financeiro/Balanco'
import RelatorioJuridico from './Juridico/AcoesJuridicas'
import RelatorioMembros from './Associados/RelatorioAssociados'
import RelatorioDespesasCozinha from './Contabilidade/DespesasCozinha'
import RelatorioMongeralQuites from './Contribuicao/Mongeral'
import RelatorioAcademicos from './Associados/RelatorioAcademicos'
import RelatorioPagamentos from './Contabilidade/Pagamentos'
import RelatorioGerencialQuantitativo from './Gerencial/Quantitativo'
import RelatorioResumoMensalidades from './Contribuicao/ResumoMensalidades'
import RelatorioResumoQuites from './Contribuicao/ResumoQuites'
import RelatorioEleitoresVoto from './Eleicao/EleitoresVoto'
import RelatorioResultadoEleicao from './Eleicao/RelatorioResultadoEleicao'
import RelatorioVotosDemonstrativo from './Eleicao/DemonstrativoVotos'
import RelatorioGerecialJuridico from './Juridico/Gerencial'
import RelatorioFaturasAvulsas from './PlanodeSaude/FaturasAvulsas'
import RelatorioDocumentacao from './Tips/Documentacao'
import RelatorioDocumentosQuantitativo from './Acervo/Quantitativo'
import RelatorioDocumentosCriados from './Acervo/Criados'
import RelatorioEnquetesGeral from './Enquetes/Geral'
import RelatorioEnquetesRespostasPorQuestao from './Enquetes/RespostasPorQuestao'
import RelatorioMensagensPorParticipante from './Enquetes/MensagensPorParticipante'
import RelatorioAtestadosMedicos from './Empregado/AtestadosMedicos'
import ListaPresencaEleicao from './Eleicao/ListaPresencaEleicao'

export default {
    RelatorioAnuidades,
    RelatorioAnuidadesPendentesEPagas,
    RelatorioMensalidades,
    RelatorioAssociadosQuites,
    RelatorioAssociadosNaoQuites,
    RelatorioResumoGeral,
    RelatorioEventos,
    RelatorioResumoGeralNaoOficial,
    RelatorioReceitas,
    RelatorioDespesas,
    RelatorioExtratosBancarios,
    RelatorioLancamentos,
    RelatorioContabilidadeReceitas,
    RelatorioContabilidadeDespesas,
    RelatorioMovimentodeCaixa,
    RelatorioFornecedor,
    RelatorioPendenciasFornecedor,
    RelatorioDespesasPorFornecedor,
    RelatorioPagamentosPlanodeSaude,
    RelatorioAtivosInativosPlanodeSaude,
    RelatorioAtivadosCanceladosPlanodeSaude,
    RelatorioPlanodeSaudePorStatus,
    RelatorioPlanoPorTipodePlano,
    RelatorioAtivosSemContribuicao,
    RelatorioQuitesComPlanodeSaude,
    RelatorioNaoQuitesComPlanodeSaude,
    RelatorioBalanco,
    RelatorioJuridico,
    RelatorioMembros,
    RelatorioDespesasCozinha,
    RelatorioMongeralQuites,
    RelatorioAcademicos,
    RelatorioPagamentos,
    RelatorioGerencialQuantitativo,
    RelatorioResumoMensalidades,
    RelatorioResumoQuites,
    RelatorioRepassesInstituicaoPagamento,
    RelatorioEleitoresVoto,
    RelatorioResultadoEleicao,
    RelatorioVotosDemonstrativo,
    RelatorioGerecialJuridico,
    RelatorioFaturasAvulsas,
    RelatorioDocumentacao,
    RelatorioDocumentosCriados,
    RelatorioDocumentosQuantitativo,
    RelatorioAssociadosIdosos,
    RelatorioEnquetesGeral,
    RelatorioEnquetesRespostasPorQuestao,
    RelatorioAtestadosMedicos,
    RelatorioMensagensPorParticipante,
    ListaPresencaEleicao
}
