import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: flex-end;
`

export const CadastroContainer = styled.div`
	display: grid;
	grid-template-columns: 1.7fr 1fr 1fr;
	grid-template-areas: 
		'descricao inicio fim'
		'eleitores x x'
		'button button button';

	.button-container {
		grid-area: button;
		display: flex;
		justify-content: flex-end;
	}

	@media(max-width: 800px) {
		display: flex;
		flex-direction: column;
	}
`

export const CategoriasContainer = styled.div`
	grid-area: eleitores;
	display: flex;
	flex-direction: column;
	margin-top: 16px;

	> h1 {
		font-size: 15px;
		font-weight: 600;
	}

	.checkbox {
		margin: 8px;
	}
`
