import React, { useContext, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { FiSearch, FiTrash } from 'react-icons/fi'

import { PlanoSaudeContext } from '../../../../../contexts/PlanoSaudeContext'

import Table from '../../../../../components/Table'

import { Container } from './styles'
import Spinner from '../../../../../components/Spinner'
import Modal from '../../../../../components/Modal'

export default function () {
    const { contribuicoes, handleDelete, handleDeleteMultiple } = useContext(PlanoSaudeContext)

    const [faturaUrl, setFaturaUrl] = useState(null)

    return (
        <>
            <Container>
                {contribuicoes === 'loading' ? (
                    <Spinner />
                ) : (
                    <Table
                        headers={[
                            { name: 'crm', value: 'CRM', centered: true },
                            { name: 'nome', value: 'Nome' },
                            { name: 'competencia', value: 'Competência', centered: true },
                            { name: 'tipo_cobranca', value: 'Tipo', centered: true },
                            { name: 'valor', value: 'Valor', centered: true },
                            { name: 'data_consolidacao', value: 'Data de pagamento', centered: true },
                            { name: 'status', value: 'Status', centered: true }
                        ]}
                        data={contribuicoes || []}
                        actions={[
                            {
                                name: 'Visualizar fatura',
                                icon: FiSearch,
                                iconSize: 16,
                                action: item => {
                                    setFaturaUrl(item.link_fatura || null)
                                },
                                checkDisabled: item => !item.link_fatura,
                                disableTooltip: true
                            },
                            {
                                action: handleDelete,
                                icon: FaTrash,
                                iconSize: 14,
                                checkDisabled: item => item.status_bool,
                                name: 'Excluir',
                                confirmation: 'Deseja realmente excluir esta contribuição?'
                            }
                        ]}
                        checkable
                        checkboxActions={[
                            {
                                icon: FiTrash,
                                handle: handleDeleteMultiple,
                                confirmationMessage: 'Tem certeza que deseja excluir todas as contribuições selecionadas?',
                                tooltip: 'Excluir selecionados'
                            }
                        ]}
                    />
                )}
            </Container>

            <Modal
                isOpen={!!faturaUrl}
                handleClose={() => {
                    setFaturaUrl('')
                }}
                title="Fatura"
            >
                <iframe
                    src={faturaUrl?.replace('checkout', 'checkout_auth_iframe')}
                    title="Fatura"
                    className="w-full h-[80vh] rounded-md"
                />
            </Modal>
        </>
    )
}
