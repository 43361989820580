import styled from 'styled-components'

export const Container = styled.div`
	main {
		padding: 35px 50px 35px 90px;

		@media(max-width: 1000px) {
			padding: 20px 30px;
		}

		@media(max-width: 400px) {
			padding: 0;
		}
	}
`
