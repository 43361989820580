import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../../../../../services/api'

import { Container, ButtonContainer } from './styles'

import formUtils from '../../../../../util/form'

import { Button, Select } from '../../../../../components/Form'

import { downloadRelatorioJasper } from '../../../../../util/file'

const nomeRelatorio = 'Relatório Plano de saúde por tipo de plano'

export default function (props) {
    const [convenioStatus, setConvenioStatus] = useState([])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        values = formUtils.extractFormValues(values)
        try {
            const response = await api.post('relatorio/plano_saude_por_plano',
                values,
                { ...authHeaders(), responseType: 'blob' })

            downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    useEffect(() => {
        async function loadConvenioStatus() {
            const response = await api.get('convenio_status', authHeaders())

            setConvenioStatus(response)
        }

        loadConvenioStatus()
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{ medico: '', status: '' }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, resetForm }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="medico"
                            label="Tipo beneficiário"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Médico', value: '1' },
                                { label: 'Acadêmico', value: '0' }
                            ]}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Select
                            name="status"
                            label="Status do plano"
                            options={convenioStatus.map(m => ({
                                label: m.descricao,
                                value: m.id
                            }))}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
