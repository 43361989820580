import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		margin-top: 25px;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 
			'title title'
			'f1 f2'
			'button button';

		> h2 {
			grid-area: title;
		}

		.spinner-container {
			grid-column: 1/span 2;
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 10px;
	grid-area: button;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`
