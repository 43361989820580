import React, { useState, useContext, useEffect } from 'react'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Editor } from 'primereact/editor'

import { GlobalContext } from '../../../../../contexts/GlobalContext'
import { EventoContext } from '../../../../../contexts/EventoContext'

import FormEndereco from '../../../../../components/FormEndereco'
import {
    Textbox, File, Textarea, Select, Button,
    Checkbox
} from '../../../../../components/Form'
import Modal from '../../../../../components/Modal'

import formUtils from '../../../../../util/form'
import masks from '../../../../../util/masks'
import { getDateObject, getPeriodoPorExtenso } from '../../../../../util/date'

import { api, authHeaders } from '../../../../../services/api'

import { Container, CertificadoForm, CertificadoPreviewContainer } from './styles'
import { loadTips } from '../../../../../util/tip'
import { TipContext } from '../../../../../contexts/TipContext'

const certificadoTextoComprimeiroMaximo = 300

export default function ({ evento, edicao }) {
    const { reload } = useContext(EventoContext)
    const { user } = useContext(GlobalContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [data, setData] = useState({
        ...evento,
        certificado_texto: evento.certificado_texto || 'Digite aqui o texto do certificado...'
    })
    const [imagemId, setImagemId] = useState(evento.imagem?.id)
    const [certificadoTemplate, setCertificadoTemplate] = useState(evento.certificado_template?.id)
    const [certificadoTemplateImage, setCertificadoTemplateImage] = useState(null)
    const [programacaoId, setProgramacaoId] = useState(evento.documento?.id)
    const [tiposEvento, setTiposEvento] = useState([])
    const [tipoSelecionado, setTipoSelecionado] = useState(evento.tipo_evento ? {
        label: evento.tipo_evento.descricao,
        value: evento.tipo_evento.id
    } : null)
    const [lembreteAntecedencia, setLembreteAntecedencia] = useState(evento.lembrete_antecedencia ? {
        label: `${evento.lembrete_antecedencia} hora(s)`,
        value: evento.lembrete_antecedencia
    } : null)
    const [showCertificadoPreview, setShowCertificadoPreview] = useState(false)
    const [eventoPublico, setEventoPublico] = useState(evento.publico)

    async function handleEditar(dadosEndereco) {
        try {
            const values = [...document.querySelectorAll('.form-edicao-evento > .field')]
                .map(field => field.querySelector('input, textarea, select'))
                .map(input => ({
                    [input.name]: input.value
                }))
                .reduce((result, current) => {
                    const [[key, value]] = Object.entries(current)

                    return {
                        ...result,
                        [key]: value
                    }
                }, {})

            const endereco = formUtils.extractFormValues(dadosEndereco)

            await api.put(`evento/${evento.id}`, {
                ...values,
                imagem_id: imagemId,
                documento_id: programacaoId,
                endereco,
                tipo_id: tipoSelecionado.value,
                lembrete_antecedencia: lembreteAntecedencia?.value || null,
                pessoas: [],
                certificado_arquivo_id: certificadoTemplate || data.certificado_arquivo?.id,
                certificado_texto: data.certificado_texto,
                publico: eventoPublico
            }, authHeaders())

            reload()
            toast.success('Evento alterado com sucesso.')
        } catch (e) {
            toast.error('Erro ao editar dados do evento.')
        }
    }

    async function handleCadastrar(dadosEndereco) {
        try {
            const values = [...document.querySelectorAll('.form-edicao-evento > .field')]
                .map(field => field.querySelector('input, textarea, select'))
                .map(input => ({
                    [input.name]: input.value
                }))
                .reduce((result, current) => {
                    const [[key, value]] = Object.entries(current)

                    return {
                        ...result,
                        [key]: value
                    }
                }, {})

            const endereco = formUtils.extractFormValues(dadosEndereco)

            await api.post('evento', {
                ...values,
                imagem_id: imagemId,
                documento_id: programacaoId,
                endereco,
                tipo_id: tipoSelecionado.value,
                lembrete_antecedencia: lembreteAntecedencia?.value || null,
                pessoas: [],
                usuario_criador_id: user.id,
                certificado_arquivo_id: certificadoTemplate || data.certificado_arquivo?.id,
                certificado_texto: data.certificado_texto,
                publico: eventoPublico
            }, authHeaders())

            reload()

            toast.success('Evento cadastrado com sucesso.')
        } catch (e) {
            toast.error('Erro ao cadastrar dados do evento.')
        }
    }

    async function loadTiposEvento() {
        const response = await api.get('tipo_evento', authHeaders())

        setTiposEvento(response)
    }

    async function loadCertificadoTemplateImage() {
        const { link } = await api.get(`arquivo/${certificadoTemplate}`)

        setCertificadoTemplateImage(link)
    }

    function getCertificadoPreview() {
        if ((!certificadoTemplateImage && !data?.certificado_arquivo?.link && !certificadoTemplate) || !data.certificado_texto) {
            return null
        }

        const dataInicio = document.querySelector('input[name=inicio]')?.value
        const dataFim = document.querySelector('input[name=fim]')?.value
        let periodo

        if (dataInicio && dataFim) {
            periodo = {
                inicio: getDateObject(dataInicio),
                fim: getDateObject(dataFim)
            }
        }

        const replacements = {
            nome: user.pessoa.nome,
            evento: document.querySelector('input[name=nome]')?.value || '[NOME DO EVENTO]',
            data: periodo ? getPeriodoPorExtenso(periodo) : '[DATA DO EVENTO]'
        }

        let textoTratado = data.certificado_texto

        for (const [attr, value] of Object.entries(replacements)) {
            const regex = new RegExp(`#${attr}#`, 'g')
            textoTratado = textoTratado.replace(regex, value)
        }

        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: textoTratado }} />
                <img src={certificadoTemplateImage || data?.certificado_arquivo?.link} alt="" />
            </>
        )
    }

    useEffect(() => {
        loadTiposEvento()

        setTimeout(() => {
            document.querySelector('.ReactModal__Content').scroll(0, 0)
        }, 50)

        loadTips(setCodigo, 'form_cadastro_eventos')
    }, [])

    useEffect(() => {
        if (certificadoTemplate) {
            loadCertificadoTemplateImage()
        }
    }, [certificadoTemplate])

    return (
        <>
            <Container>
                <input type="hidden" value={evento.endereco?.id} name="endereco_id" />

                <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                <File
                    name="imagem"
                    onSuccess={setImagemId}
                    label="Anexe um banner para o evento"
                    format="square"
                    previewSize={['100%', '300px']}
                    getPreloadImage={async () => {
                        if (imagemId) {
                            const arquivo = await api.get(`arquivo/${imagemId}`)
                            return arquivo?.link
                        }
                        return null
                    }}
                    className="imagem"
                />

                <Textbox
                    label="Nome do evento"
                    name="nome"
                    withoutForm
                    value={data.nome}
                    onChange={e => {
                        setData({
                            ...data,
                            nome: e.target.value
                        })
                    }}
                />

                <Select
                    name="tipo_evento"
                    label="Tipo de evento"
                    options={tiposEvento.map(tipo => ({
                        label: tipo.descricao,
                        value: tipo.id
                    }))}
                    onChange={selected => { setTipoSelecionado(selected) }}
                    value={tipoSelecionado}
                    withoutForm
                />

                <Textbox
                    label="Início do evento"
                    name="inicio"
                    withoutForm
                    mask={masks.datetime}
                    value={data.inicio}
                    onChange={e => {
                        setData({
                            ...data,
                            inicio: e.target.value
                        })
                    }}
                />

                <Textbox
                    label="Fim do evento"
                    name="fim"
                    withoutForm
                    mask={masks.datetime}
                    value={data.fim}
                    onChange={e => {
                        setData({
                            ...data,
                            fim: e.target.value
                        })
                    }}
                />

                <Textbox
                    label="Nome do local"
                    name="nome_local"
                    withoutForm
                    value={data.nome_local}
                    onChange={e => {
                        setData({
                            ...data,
                            nome_local: e.target.value
                        })
                    }}
                />

                <Textbox
                    name="link_video_conferencia"
                    label="Link da vídeo-conferência"
                    withoutForm
                    value={data.link_video_conferencia}
                    onChange={e => {
                        setData({
                            ...data,
                            link_video_conferencia: e.target.value
                        })
                    }}
                />

                <Select 
                    name="lembrete_antecedencia"
                    label="Notificar convidados"
                    onChange={selected => { setLembreteAntecedencia(selected) }}
                    options={[
                        { label: '1 hora antes do evento', value: 1 },
                        { label: '2 hora antes do evento', value: 2 },
                        { label: '8 hora antes do evento', value: 8 }
                    ]}
                    value={lembreteAntecedencia}
                    withoutForm
                    containerStyle={{ gridArea: 'f7' }}
                />

                <Checkbox 
                    name="publico"
                    label="Evento público?"
                    onChange={e => { setEventoPublico(e.target.checked ? 1 : 0) }}
                    checked={eventoPublico}
                    withoutForm
                    containerStyle={{ gridArea: 'f8' }}
                />

                <Textarea
                    label="Descrição do evento"
                    name="descricao"
                    withoutForm
                    value={data.descricao}
                    onChange={e => {
                        setData({
                            ...data,
                            descricao: e.target.value
                        })
                    }}
                />

                <File
                    name="programacao"
                    onSuccess={setProgramacaoId}
                    label="Anexe a programação do evento"
                    format="square"
                    previewSize={['100%', '100px']}
                    getPreloadImage={async () => {
                        if (programacaoId) {
                            const arquivo = await api.get(`arquivo/${programacaoId}`)
                            return arquivo?.link
                        }
                        return null
                    }}
                    className="programacao"
                />

                <CertificadoForm>
                    <h2>O evento terá certificado? (opcional)</h2>

                    <File
                        name="certificado_template"
                        onSuccess={setCertificadoTemplate}
                        label="Anexe a arte do certificado (formato PNG ou JPEG)"
                        format="square"
                        previewSize={['100%', '250px']}
                        getPreloadImage={async () => {
                            if (certificadoTemplate) {
                                const arquivo = await api.get(`arquivo/${certificadoTemplate}`)
                                return arquivo?.link
                            }

                            if (data?.certificado_arquivo?.link) {
                                return data.certificado_arquivo.link
                            }

                            return null
                        }}
                        requiredSize={{
                            width: 1366,
                            height: 969
                        }}
                        accept={['image/png', 'image/jpeg']}
                        tip={`
                            Sua imagem deve ter <b>1366px de largura e 969px de altura</b>.<br/>
                            O texto do certificado será posicionado a uma distância de <b>12cm da margem superior, 23cm da margem inferior e 5cm das margens lateriais</b>. Considere estas medidas ao fazer o layout.
                        `}
                    />

                    <Editor
                        style={{ height: 300 }}
                        className="editor-container"
                        value={data.certificado_texto}
                        onTextChange={e => {
                            if (e.textValue.length > certificadoTextoComprimeiroMaximo) {
                                toast.warn(`Não ultrapasse ${certificadoTextoComprimeiroMaximo} caracteres em seu texto.`)
                                return
                            }

                            setData({
                                ...data,
                                certificado_texto: e.htmlValue
                            })
                        }}
                    />

                    <div className="button-container">
                        <Button
                            onClick={() => { setShowCertificadoPreview(true) }}
                            disabled={(!certificadoTemplate && !data?.certificado_arquivo?.link) || !data.certificado_texto}
                            className="transparent"
                        >
                            Previsualizar certificado
                        </Button>
                    </div>

                    <div className="texto-orientacao">
                        Você pode utilizar no texto os seguintes parâmetros:

                        <ul>
                            <li>
                                <b>#nome#</b>
                                {' '}
                                - Nome do participante
                            </li>
                            <li>
                                <b>#evento#</b>
                                {' '}
                                - Nome do evento
                            </li>
                            <li>
                                <b>#data#</b>
                                {' '}
                                - Data ou período em que ocorrerá o evento
                            </li>
                        </ul>

                        Ex.: Certificamos que #nome# participou do #evento# em #data#...
                    </div>

                </CertificadoForm>

                <FormEndereco
                    defaultValues={evento.endereco}
                    onSubmit={edicao ? handleEditar : handleCadastrar}
                    buttonContent={(
                        <>
                            Salvar alterações
                            <FaRegSave color="#424242" />
                        </>
                    )}
                    required={false}
                    title="Endereço do evento (opcional)"
                />
            </Container>

            <Modal
                isOpen={showCertificadoPreview && (!!certificadoTemplateImage || !!data?.certificado_arquivo?.link)}
                handleClose={() => { setShowCertificadoPreview(false) }}
            >
                <CertificadoPreviewContainer>
                    {showCertificadoPreview && getCertificadoPreview()}
                </CertificadoPreviewContainer>
            </Modal>
        </>
    )
}
