import React, {
    useState, useEffect, useCallback 
} from 'react'
import {
    Tab, Tabs, TabList, TabPanel 
} from 'react-tabs'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../../../../services/api'

import scrollUtils from '../../../../util/scroll'

import Card from '../../../../components/Card'

import Listagem from './Listagem'
import Cadastro from './Cadastro'

import { Container } from './styles'

export default function () {
    const [editId, setEditID] = useState(null)
    const [eventos, setEventos] = useState(null)
    const [initialValues, setInitialValues] = useState({
        nome: '',
        descricao: '',
        conta_contabil: '',
        tipo: '',
        conta_obrigatoria: false,
        recorrente: false
    })

    async function loadEventos() {
        const response = await api.get('evento_contabil', authHeaders())

        setEventos(response.map(evento => ({
            id: evento.id,
            nome: evento.nome,
            tipo: evento.tipo_evento_contabil.sigla,
            codigo: evento.conta_contabil?.codigo_reduzido || '',
            recorrente: evento.recorrente ? 'Sim' : 'Não'
        })))
    }

    useEffect(() => {
        loadEventos()
    }, [])

    const handlePrepareEdit = useCallback(async id => {
        setEditID(id)

        const { 
            nome, 
            descricao, 
            conta_contabil, 
            tipo_evento_contabil: tipo, 
            conta_corrente_obrigatoria,
            recorrente
        } = await api.get(`evento_contabil/${id}`, authHeaders())

        setInitialValues({
            nome,
            descricao,
            conta_contabil: conta_contabil?.codigo_reduzido || '',
            tipo: { label: tipo.descricao, value: tipo.id },
            conta_obrigatoria: conta_corrente_obrigatoria,
            recorrente
        })

        scrollUtils.toTop(0, '.card')
    }, [])

    const handleDelete = useCallback(async id => {
        try {
            await api.delete(`evento_contabil/${id}`, authHeaders())
			
            loadEventos()

            toast.success('Evento excluído!')
            
            return true
        } catch(e) {
            toast.error(e.msg)
            return false
        }
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Eventos Contábeis</h1>

                    <Tabs>
                        <TabList>
                            <Tab>{editId ? 'Edição' : 'Cadastro'}</Tab>
                        </TabList>

                        <TabPanel>
                            <Cadastro 
                                editId={editId}
                                initialValues={initialValues} 
                                reload={() => {
                                    loadEventos()

                                    setEditID(null)

                                    setInitialValues({
                                        nome: '',
                                        descricao: '',
                                        conta_contabil: '',
                                        tipo: '',
                                        conta_obrigatoria: false
                                    })
                                }}
                            />
                        </TabPanel>
                    </Tabs>

                    <Listagem 
                        eventos={eventos} 
                        handlePrepareEdit={handlePrepareEdit}
                        handleDelete={handleDelete}
                    />

                </Card>
            </main>
        </Container>
    )
}
