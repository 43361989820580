import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			> h1 {
				margin-bottom: 20px;
			}
		}

		> h1 {
			margin: 0 8px 20px;
			grid-area: title;
			margin-bottom: 24px;	

		@media(max-width: 800px) {
			padding: 20px;
		}

		@media(max-width: 400px) {
			padding: 15px 0;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	
	> p {
		margin: 8px;
	}

	form {
		display: grid;
		grid-gap: 14px 8px;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 70px auto auto auto;
		align-items: flex-start;
		grid-template-areas: 
			'f1 f2 f3'
			'textarea textarea params'
			'tip tip params'
			'header footer x';
			
		.button-container {
			grid-column: 1/span 3;
			display: flex;
			justify-content: flex-end;
		}

		.file {
			grid-area: textarea;
		}

		.editor-container {
			grid-area: textarea;

			& + p {
				grid-area: tip;
				font-size: 11px;
				margin: 0 8px;
			}
		}

		.tip {
			margin-left: 16px !important;
		}

		@media(max-width: 950px) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 70px auto 70px auto;
			grid-template-areas: 
				'f1 f2'
				'f3 f4'
				'textarea textarea'
				'params params';

			.button-container {
				grid-column: 1/span 2;
			}
		}

		@media(max-width:700px){
			display: flex;
			flex-direction: column;

			> button{
				align-self: flex-end;
			}
		}
	}
`

export const ParametrosLista = styled.div`
	grid-area: params;
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	padding: 0 16px;

	> h1 {
		font-size: 18px;
		margin: 0 0 14px !important;
	}

	> i {
		align-self: center;
	}

	> p {
		line-height: 20px;
	}
`
