import styled from 'styled-components'

export const Container = styled.div`
	margin: 30px auto 80px;
	max-width: 750px;

	h1, h2, h3, p {
		color: #424242;
		margin: 0 14px;
	}

	h1 {
		margin-top: 10px;
	}

	h2 {
		font-size: 18px;
		font-weight: 500;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 8px 14px;

		span {
			font-size: 14px;
			margin-left: 10px;
		}	
	}

	h3 {
		font-size: 16px;
		margin: 14px 14px;
	}

	p {
		line-height: 50px;
	}

	.event-not-found {
		font-size: 18px;
		color: #fff;
		font-weight: 600;
		text-align: center;
		padding: 100px 0;
	}

	.descricao {
		margin: 20px 0 10px;
		line-height: 24px;
	    padding-left: 14px;
		color: #fff;
	}

	.card {
		padding: 8px 16px;
	}
`

export const Imagem = styled.img`
	object-fit: cover;
	width: 100%;
	height: 250px;
`

export const GaleriaContainer = styled.div`
	display: flex;
	margin: 0 20px;
	gap: 0 10px;
	max-width: 750px;
	overflow-x: scroll;
	overflow-y: hidden;
`

export const GaleriaItem = styled.div`
	margin-bottom: 8px;
	cursor: pointer;
	transition: 300ms;
	
	img {
		height: ${props => props.size === 'large' ? 180 : 120}px;
		width: ${props => props.size === 'large' ? 200 : 140}px;
		object-fit: cover;
		border-radius: 10px;
	}

	video {
		height: ${props => props.size === 'large' ? 180 : 120}px;
		width: ${props => props.size === 'large' ? 200 : 140}px;
		border-radius: 10px;
		object-fit: cover;
	}

	&:hover {
		opacity: 0.8;
	}
`

export const DocumentosContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 20px 8px 0;
	grid-gap: 12px 8px;
	width: calc(750px - 8px);
	overflow-y: auto;

	> h1 {
		grid-column: 1/span 3;
		font-size: 18px;
		font-weight: 500;
		margin: 0 0 4px;
	}
`

export const DocumentosItem = styled.div`
	height: fit-content;
	width: 234px;
	display: grid;
	grid-template-columns: 40px auto;
	background-color: #77AE7A;
	border-radius: 14px;
	padding: 14px;
	cursor: pointer;
	transition: 300ms;

	&:hover {
		opacity: 0.8;
	}
`

export const VisualizacaoContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 10px;

	img {
		height: 100%;
	}

	video {
		height: 73vh;
		width: 100%;
	}

	iframe {
		height: 70vh;
		width: 100%;
	}
`
