import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		.button-container {
			display: flex;
			justify-content: end;
		}

		table {
			line-height: 20px;
		}
	}
`
