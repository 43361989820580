import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;
		
		.card {
			min-height: 64vh;
			display: flex;
			flex-direction: column;

			> button {
				align-self: flex-end;
			}
		}

		@media(max-width: 700px) {
			padding: 30px 20px;
		}

		@media(max-width: 500px) {
			padding: 0;
		}
	}
`
