import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { format, isBefore } from 'date-fns'
import { FaTimes } from 'react-icons/fa'
import {
    FiDownload, FiFileText, FiPlus, FiCheck
} from 'react-icons/fi'
import {
    Tab, TabList, TabPanel, Tabs
} from 'react-tabs'

import formUtils from '../../../../util/form'
import { getAge, getDateString, getDate } from '../../../../util/date'
import maskUtils from '../../../../util/masks'
import stringUtils from '../../../../util/string'

import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'

import {
    Checkbox, Button, Select, Textbox
} from '../../../../components/Form'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import GerenciamentoDocumentos from '../../../../components/GerenciamentoDocumentos'
import Spinner from '../../../../components/Spinner'

import CadastroDependente from '../../../../pages/UserViews/Dependente/Cadastro'

import scrollUtils from '../../../../util/scroll'

import {
    Container, DocumentosTemplatesContainer, PortabilidadeContainer, RegrasPortabilidadeContainer, FiliacaoContainer
} from './styles'

import { ConvenioContext } from '../../../../contexts/ConvenioContext'
import { DependenteContext } from '../../../../contexts/DependenteContext'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

const baseInitialValues = {
    convenio: null,
    mae: '',
    pai: ''
}

export const tiposPortabilidade = [
    { label: 'Não', value: 0 },
    { label: 'UNIMED Belém para UNIMED Belém', value: 1 },
    { label: 'Outras operadoras ou outras UNIMEDs', value: 2 }
]

export default function ({ reload }) {
    const { user } = useContext(GlobalContext)
    const {
        servicosDisponiveis, titular, loadTitular
    } = useContext(ConvenioContext)
    const { setCodigo } = useContext(TipContext)

    const eMedico = user.perfil.id === 2

    const { setModoCadastro } = useContext(DependenteContext)

    const [convenio, setConvenio] = useState(null)
    const [dependentes, setDependentes] = useState([])
    const [parentescosPermitidos, setParentescosPermitidos] = useState([])
    const [convenioId, setConvenioId] = useState(null)
    const [aceite, setAceite] = useState(false)
    const [aceitePortabilidade, setAceitePortabilidade] = useState(false)
    const [servicos, setServicos] = useState(servicosDisponiveis)
    const [dependente, setDependente] = useState(null)
    const [portabilidadeRegraToShow, setPortabilidadeRegraToShow] = useState(null)
    const [tipoPortabilidade, setTipoPortabilidade] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [beneficiariosSelecionados, setBeneficiariosSelecionados] = useState([])
    const [dependentesTabIndex, setDependentesTabIndex] = useState(0)
    const [valorMaioridadePlano, setValorMaioridadePlano] = useState(0)

    async function loadParentescosPermitidos(convenioSelecionadoId) {
        const response = await api.get(`convenio/${convenioSelecionadoId}`, authHeaders())

        setParentescosPermitidos(response.parentescos.map(p => p.id))
    }

    async function loadValorMaioridadePlano() {
        const { valor } = await api.get('parametro/valor_maioridade_plano')
        setValorMaioridadePlano(valor)
    }

    async function loadDependentes() {
        const response = await api.get('dependente', {
            params: {
                associado_id: user.associado.id
            },
            ...authHeaders()
        })

        setDependentes(response.map(dep => ({
            ...dep,
            data_nascimento: `${format(new Date(dep.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(dep.pessoa.data_nascimento)} anos)`,
            idade: getAge(dep.pessoa.data_nascimento)
        })))
    }

    async function loadConvenio(id) {
        setConvenio('loading')

        const response = await api.get(`convenio/${id}`, authHeaders())

        setConvenio(response)

        if (response.convenio_area_atuacao.id === 1) {
            setInitialValues({
                ...baseInitialValues,
                convenio: {
                    label: response.empresa.nome,
                    value: response.id
                },
                portabilidade: null
            })
        }
    }

    useEffect(() => {
        if (user?.associado) {
            loadDependentes()
            loadValorMaioridadePlano()
        }
    }, [user])

    useEffect(() => {
        if (convenioId) {
            loadConvenio(convenioId)

            loadParentescosPermitidos(convenioId)
        }
    }, [convenioId])

    useEffect(() => {
        if (servicosDisponiveis) {
            setServicos(servicosDisponiveis.filter(conv => conv.tipo !== 'parceria'))
        }
    }, [servicosDisponiveis])

    useEffect(() => {
        if (tipoPortabilidade !== null) {
            setInitialValues({
                ...baseInitialValues,
                convenio: { label: convenio.empresa.nome, value: convenio.id },
                portabilidade: tiposPortabilidade[tipoPortabilidade]
            })
        }
    }, [tipoPortabilidade])

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)

            const { portabilidade, mae, pai } = values

            const body = {
                convenio_id: convenio.id,
                associado_id: user.associado.id,
                beneficiarios: beneficiariosSelecionados.map(b => ({
                    id: b.id,
                    titular: b.titular,
                    adicionais: b.adicionais?.map(a => a.id) || []
                })),
                portabilidade,
                mae,
                pai
            }

            try {
                const validation = Yup.object({
                    convenio_id: Yup.number().required('Especifique o plano de saúde.'),
                    associado_id: Yup.number().required('Associado não identificado.'),
                    portabilidade: Yup.string().nullable().required('Selecione uma opção.'),
                    mae: Yup.string().required('Informe o nome da mãe do titular.'),
                    pai: Yup.string(),
                    beneficiarios: Yup.array().of(
                        Yup.object({
                            id: Yup.number(),
                            titular: Yup.string(),
                            adicionais: Yup.array().of(Yup.number())
                        })
                    )
                })

                await validation.validate(body)
            } catch (e) {
                toast.error(e.message)
                return
            }

            await api.post('convenio_associado', body, authHeaders())

            toast.success('Serviço contratado com sucesso.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function obterDocumentacaoBeneficiario(documentosNecessarios) {
        switch (Number(tipoPortabilidade)) {
            case 0: return documentosNecessarios.filter(d => ![36, 37, 38, 41].includes(d.id))
            case 1: return documentosNecessarios.filter(d => ![33, 34, 37, 38, 41].includes(d.id))
            case 2: return documentosNecessarios.filter(d => ![33, 34].includes(d.id))
            default: return documentosNecessarios
        }
    }

    function isDisableCheck(dep) {
        if (!convenio) {
            return
        }

        const { descontos, criterios } = convenio

        if (parentescosPermitidos.length && !parentescosPermitidos.includes(dep.grau_parentesco.id)) {
            return 'parentesco'
        }

        const sexoAceito = descontos.find(d => !d.sexo || d.sexo === dep.pessoa.sexo)
        const idadeAceita = descontos.find(d => (!d.idade_minima || d.idade_minima <= dep.idade) && (!d.idade_maxima || d.idade_maxima >= dep.idade))
        const universidadeAceita = descontos.find(d => !d.universidade || (d.universidade && dep.pessoa.documentos?.find(doc => doc.tipo_documento.id === 21)))

        if (criterios.includes('sexo') && !sexoAceito) {
            return 'sexo'
        }

        if ([3, 4, 14, 15, 16, 17].includes(dep.grau_parentesco.id) && Number(getAge(dep.pessoa.data_nascimento)) > Number(valorMaioridadePlano)) {
            return 'idade'
        }

        if (criterios.includes('idade') && !idadeAceita) {
            return 'idade'
        }

        if (criterios.includes('universidade') && !universidadeAceita) {
            return 'universidade'
        }

        let documentosNecessarios = convenio.parentescos.find(p => p.id === dep.grau_parentesco.id)?.documentacao_necessaria || []

        if (tipoPortabilidade !== null) {
            documentosNecessarios = obterDocumentacaoBeneficiario(documentosNecessarios)
        }

        let documentosEmFalta = documentosNecessarios
            .filter(tipoDoc => !dep.pessoa.documentos
                .map(d => d.tipo_documento.id)
                .includes(tipoDoc.id)
                || !dep.pessoa.documentos.find(d => d.tipo_documento.id === tipoDoc.id)?.arquivo)

        if (documentosEmFalta.length) {
            return `documentação. Os seguintes documentos devem ser digitalizados e anexados ao sistema: ${documentosEmFalta.map(d => d.descricao).join(', ')}`
        }

        return false
    }

    function isDisableCheckTitular(titularPessoa) {
        if (!convenio) {
            return
        }

        let documentosNecessarios = convenio.parentescos.find(p => p.id === (eMedico ? 1 : 18))?.documentacao_necessaria || []

        if (tipoPortabilidade !== null) {
            documentosNecessarios = obterDocumentacaoBeneficiario(documentosNecessarios)
        }

        const documentosEmFalta = documentosNecessarios
            .filter(tipoDoc => {
                const documento = titularPessoa.documentos.find(d => d.tipo_documento.id === tipoDoc.id)

                if (tipoDoc.id === 21) {
                    const docsComValidade = titularPessoa.documentos
                        .filter(doc => doc.extra && doc.tipo_documento.id === tipoDoc.id)
                        .map(doc => ({
                            ...doc,
                            validade: new Date(JSON.parse(doc.extra).validade)
                        }))

                    return docsComValidade.every(doc => isBefore(doc.validade, new Date()))
                }

                return !titularPessoa?.documentos
                    .map(d => d.tipo_documento.id)
                    .includes(tipoDoc.id)
                    || !documento?.arquivo
            })

        if (documentosEmFalta.length) {
            return `documentação. Os seguintes documentos devem ser digitalizados e anexados ao sistema: ${documentosEmFalta.map(d => d.descricao).join(', ')}`
        }

        return false
    }

    function getTabelaDescontos() {
        if (!convenio) {
            return
        }

        const { descontos } = convenio

        function getTextFaixaEtaria(desconto) {
            if (!desconto.idade_minima && !desconto.idade_maxima) {
                return 'Qualquer faixa etária'
            }

            if (!desconto.idade_minima && desconto.idade_maxima) {
                return `Até ${desconto.idade_maxima} anos`
            }

            if (desconto.idade_minima && !desconto.idade_maxima) {
                return `A partir de ${desconto.idade_minima} anos`
            }

            if (desconto.idade_minima && desconto.idade_maxima) {
                return `Entre ${desconto.idade_minima} e ${desconto.idade_maxima} anos`
            }
        }

        return descontos.map(desc => ({
            id: desc.id,
            percentual_desconto: maskUtils.maskApply.percent(desc.percentual_desconto),
            valor_sem_desconto: maskUtils.maskApply.currency(desc.valor_sem_desconto),
            valor_com_desconto: maskUtils.maskApply.currency(desc.valor_com_desconto),
            faixa_etaria: getTextFaixaEtaria(desc),
            sexo: desc.sexo || 'Sem restrição',
            universidade: desc.universidade === null ? 'Sem restrição' : desc.universidade ? 'Sim' : 'Não'
        }))
    }

    function getTabelaDescontosHeaders() {
        if (!convenio) {
            return
        }

        const { descontos } = convenio

        const headers = [
            { name: 'percentual_desconto', value: '% desconto', centered: true },
            { name: 'valor_sem_desconto', value: 'Valor sem desconto', centered: true },
            { name: 'valor_com_desconto', value: 'Valor a pagar', centered: true },
            { name: 'faixa_etaria', value: 'Faixa etária', centered: true }
        ]

        const criterioSexo = descontos.some(d => d.sexo)
        const criterioUniversidade = descontos.some(d => d.universidade)

        if (criterioSexo) {
            headers.push({ name: 'sexo', value: 'Sexo', centered: true })
        }

        if (criterioUniversidade) {
            headers.push({ name: 'universidade', value: 'Universitário(a)', centered: true })
        }

        return headers
    }

    async function loadDependenteDocumentos(dep) {
        const pessoa = await api.get(`pessoa/${dep.pessoa_id}`, authHeaders())

        setDependente(pessoa)
    }

    async function updateDocumentos(values) {
        try {
            await api.put(`documentos/${dependente.id}`, {
                documentos: values.documentos
                    .map(doc => ({
                        arquivo: doc.arquivo,
                        identificador: doc.identificador,
                        extra: doc.validade ? {
                            validade: getDateString(getDate(doc.validade))
                        } : null,
                        tipo_documento: doc.tipo_documento.value
                    }))
            }, authHeaders())

            setDependente(null)

            toast.success('Documentos atualizados.')

            loadDependentes()
            loadTitular()
        } catch (e) {
            toast.error('Ocorreu um erro ao tentar atualizar os documentos.')
        }
    }

    function getArquivoRegrasPortabilidadePlano(portabilidadeId) {
        switch (portabilidadeId) {
            case 1: return `${process.env.REACT_APP_BACKEND_URL}/resources/Portabilidade UNIMED.pdf`
            case 2: return `${process.env.REACT_APP_BACKEND_URL}/resources/Portabilidade outro plano.pdf`
            default: return ''
        }
    }

    function updateSelectedDependentes() {
        const dependentesMarcados = document.querySelectorAll('#dependentes-convenio tbody tr td > input[type=checkbox]:checked')

        const dependentesIds = [...dependentesMarcados].map(cb => Number(cb.classList[0].split('-')[1]))

        const selecionados = dependentes.filter(dep => dependentesIds.includes(dep.id))

        setBeneficiariosSelecionados(selecionados)
    }

    function handleCheckAdicional(beneficiario) {
        const adicionaisMarcadosElems = document.querySelectorAll(`#convenio-adicionais-${beneficiario.id} tbody tr td > input[type=checkbox]:checked`)

        const adicionaisMarcadosIds = [...adicionaisMarcadosElems].map(cb => Number(cb.classList[0].split('-')[1]))

        const adicionaisMarcados = convenio.convenio_adicionais.filter(a => adicionaisMarcadosIds.includes(a.id))

        const dependenteIndex = beneficiariosSelecionados.map(d => d.id).indexOf(beneficiario.id)

        if (dependenteIndex === -1) { // É o titular
            setBeneficiariosSelecionados([
                {
                    titular: true,
                    ...beneficiario,
                    adicionais: adicionaisMarcados
                },
                ...beneficiariosSelecionados
            ])

            return
        }

        setBeneficiariosSelecionados([
            ...beneficiariosSelecionados.slice(0, dependenteIndex),
            {
                ...beneficiariosSelecionados.find(d => d.id === beneficiario.id),
                adicionais: adicionaisMarcados
            },
            ...beneficiariosSelecionados.slice(dependenteIndex + 1)
        ])
    }

    useEffect(() => {
        loadTips(setCodigo, 'form_servico_adesao')
    }, [])

    return (
        <>
            <Container id="form_plano_saude_adesao">
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                >
                    {({
                        setFieldValue, isSubmitting, values
                    }) => (
                        <Form>
                            <Select
                                name="convenio"
                                label="Serviço"
                                placeholder={!servicos ? 'Carregando serviços...' : !servicos.length ? 'Nenhum serviço disponível' : undefined}
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)

                                    setConvenioId(selected.value)

                                    setTipoPortabilidade(null)
                                }}
                                options={servicos
                                    ?.map(conv => ({
                                        label: conv.empresa.nome,
                                        value: conv.id
                                    }))}
                                isSearchable={false}
                            />

                            {convenio === 'loading' ? (
                                <Spinner />
                            ) : convenio && (
                                <>
                                    {convenio.parentescos.find(p => p.id === (eMedico ? 1 : 18))?.documentacao_necessaria && (
                                        <div className="badge white">
                                            {'A documentação exigida para o '}
                                            <b>TITULAR</b>
                                            {' deste serviço é: '}
                                            <b>
                                                {convenio.parentescos.find(p => p.id === (eMedico ? 1 : 18)).documentacao_necessaria
                                                    .filter(doc => !tipoPortabilidade ? ![36, 37, 38, 41].includes(doc.id) : true)
                                                    .filter(doc => tipoPortabilidade === 1 ? ![33, 34, 37, 38, 41].includes(doc.id) : true)
                                                    .filter(doc => tipoPortabilidade === 2 ? ![33, 34].includes(doc.id) : true)
                                                    .map(doc => doc.descricao)
                                                    .join(', ')}
                                            </b>

                                            {convenio?.convenio_area_atuacao.id === 1 && (
                                                <>
                                                    <br />
                                                    <br />
                                                    {'Obs.: Dependentes maiores de '}
                                                    <b>18 anos</b>
                                                    {' deverão assinar a Carta ANS e a Declaração de Saúde individualmente.'}
                                                    <br />
                                                    <br />
                                                    Em caso de portabilidade, não é necessário preencher os
                                                    formulários da UNIMED (Carta da ANS e declaração de saúde).
                                                    Por gentileza, leia as regras da portabilidade.
                                                </>
                                            )}
                                        </div>
                                    )}

                                    {convenio.convenio_area_atuacao.id === 1 && (
                                        <>
                                            <p>Caso você já tenha um Plano de Saúde, é possível realizar a portabilidade.</p>

                                            <PortabilidadeContainer>
                                                <Select
                                                    label="Selecione abaixo se deseja ou não fazer a portabilidade do plano"
                                                    name="portabilidade"
                                                    options={tiposPortabilidade}
                                                    onChange={(selected, meta) => {
                                                        setFieldValue(meta.name, selected)

                                                        setTipoPortabilidade(selected.value)

                                                        setAceitePortabilidade(false)
                                                    }}
                                                    isSearchable={false}
                                                />

                                                {(tipoPortabilidade || tipoPortabilidade !== null) ? (
                                                    <>
                                                        <DocumentosTemplatesContainer>
                                                            <p>
                                                                A documentação exigida para a adesão a este serviço está discriminada abaixo.
                                                                Baixe os formulários, em seguida, imprima, preencha a caneta, assine, escanceie e salve em PDF.
                                                                Anexe usando o ícone
                                                                <FiFileText />
                                                                ao lado do beneficiário correspondente, assim como toda a documentação necessária.
                                                            </p>

                                                            {convenio?.parentescos.find(p => p.id === (user.associado.medico ? 1 : 18))
                                                                .documentacao_necessaria
                                                                .filter(doc => tipoPortabilidade === 0
                                                                    ? [33, 34].includes(doc.id)
                                                                    || doc.id === (user.associado.medico ? 35 : 43)
                                                                    : doc.id === (user.associado.medico ? 35 : 43)
                                                                ).map(doc => (
                                                                    <a key={doc.id} target="_blank" rel="noopener noreferrer" focusable className="button transparent" href={`/documentos/${doc.id}`}>
                                                                        {doc.descricao}
                                                                        <FiDownload size={18} />
                                                                    </a>
                                                                ))}
                                                        </DocumentosTemplatesContainer>

                                                        {tipoPortabilidade !== 0 ? (
                                                            <>
                                                                <span>Conheça a seguir as regras para usar a portabilidade.</span>

                                                                <Button onClick={() => { setPortabilidadeRegraToShow(values.portabilidade?.value) }} className="transparent termos-portabilidade-button">
                                                                    Leia as regras de portabilidade
                                                                </Button>

                                                                <Checkbox
                                                                    name="aceite_portabilidade"
                                                                    label="Declaro que li e estou de acordo com a regras de portabilidade do plano de saúde."
                                                                    containerClass="check-portabilidade"
                                                                    handleChange={e => {
                                                                        setFieldValue(e.target.name, e.target.checked)
                                                                        setAceitePortabilidade(e.target.checked)
                                                                        scrollUtils.toElementContent('.ReactModal__Content', '.termos-portabilidade-button')
                                                                    }}
                                                                />
                                                            </>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                            </PortabilidadeContainer>
                                        </>
                                    )}

                                    {((convenio.convenio_area_atuacao.id === 1 && tipoPortabilidade !== null && aceitePortabilidade) || (convenio.convenio_area_atuacao.id === 1 && tipoPortabilidade === 0) || convenio.convenio_area_atuacao.id !== 1) && (
                                        <>
                                            <FiliacaoContainer>
                                                <h1>Filiação</h1>

                                                <div className="nomes-pais">
                                                    <Textbox
                                                        label="Nome completo da mãe (sem abreviações)"
                                                        name="mae"
                                                    />

                                                    <Textbox
                                                        label="Nome completo do pai (sem abreviações)"
                                                        name="pai"
                                                    />
                                                </div>
                                            </FiliacaoContainer>

                                            <p>Marque abaixo os dependentes que deseja incluir no serviço contratado, ou cadastre-os clicando no botão Cadastrar dependentes.</p>

                                            <div className="button-container">
                                                <Button onClick={() => { setModoCadastro(true) }} className="transparent">
                                                    Cadastrar dependentes
                                                    <FiPlus />
                                                </Button>
                                            </div>

                                            <Table
                                                tableId="dependentes-convenio"
                                                headers={[
                                                    { name: 'nome', value: 'Nome' },
                                                    { name: 'parentesco', value: 'Parentesco', centered: true },
                                                    { name: 'documentacao_necessaria', value: 'Documentação necessária', centered: true },
                                                    { name: 'data_nascimento', value: 'Data de nascimento / idade', centered: true }
                                                ]}
                                                hideOnSmallHeaders={['data_nascimento']}
                                                data={[
                                                    {
                                                        id: titular?.id,
                                                        pessoa_id: titular?.id,
                                                        nome: titular?.nome,
                                                        parentesco: 'Titular',
                                                        documentacao_necessaria: convenio?.parentescos
                                                            .find(p => p.id === (eMedico ? 1 : 18))?.documentacao_necessaria
                                                            .filter(d => values.portabilidade.value === 1 ? ![33, 34, 37, 38, 41].includes(d.id) : true)
                                                            .filter(d => values.portabilidade.value === 2 ? ![33, 34].includes(d.id) : true)
                                                            .filter(d => values.portabilidade.value === 0 ? ![36, 37, 38, 41].includes(d.id) : true)
                                                            .map(d => d.descricao)
                                                            .join(', ') || '',
                                                        data_nascimento: titular?.data_nascimento,
                                                        checkbox: {
                                                            disabled: !!isDisableCheckTitular(titular),
                                                            tooltip: `Você não atende ao critério ${isDisableCheckTitular(titular)}.`,
                                                            fullWidth: true
                                                        }
                                                    },
                                                    ...dependentes.map(dep => ({
                                                        id: dep.id,
                                                        pessoa_id: dep.pessoa.id,
                                                        nome: dep.pessoa.nome,
                                                        parentesco: dep.grau_parentesco.descricao,
                                                        documentacao_necessaria: convenio?.parentescos
                                                            .find(p => p.id === dep.grau_parentesco.id)?.documentacao_necessaria
                                                            .filter(d => values.portabilidade.value === 1 ? ![33, 34, 37, 38, 41].includes(d.id) : true)
                                                            .filter(d => values.portabilidade.value === 2 ? ![33, 34].includes(d.id) : true)
                                                            .filter(d => values.portabilidade.value === 0 ? ![36, 37, 38, 41].includes(d.id) : true)
                                                            .map(d => d.descricao)
                                                            .join(', ') || '',
                                                        data_nascimento: dep.data_nascimento,
                                                        checkbox: {
                                                            disabled: !!isDisableCheck(dep),
                                                            tooltip: `Este dependente não atende ao critério ${isDisableCheck(dep)}.`,
                                                            fullWidth: true
                                                        }
                                                    }))
                                                ]}
                                                checkable
                                                checkedItems={[titular?.id]}
                                                checkedReadonlyItems={[titular?.id]}
                                                onCheckItem={updateSelectedDependentes}
                                                onUncheckItem={updateSelectedDependentes}
                                                toggleCheckAll={updateSelectedDependentes}
                                                hideOffset={690}
                                                actions={[
                                                    {
                                                        name: 'Gerenciar documentos',
                                                        action: loadDependenteDocumentos,
                                                        icon: FiFileText,
                                                        iconSize: 16,
                                                        noTooltip: true
                                                    }
                                                ]}
                                            />

                                            <p className="badge">
                                                Os beneficiários marcados com
                                                <FaTimes />
                                                não são permitidos para o serviço selecionado ou tem alguma pendencia de documentação. Passe o mouse em cima do nome para esclarecimento.
                                            </p>

                                            <Tabs
                                                selectedIndex={dependentesTabIndex}
                                                onSelect={indiceTab => { setDependentesTabIndex(indiceTab) }}
                                            >
                                                <TabList>
                                                    <Tab>{stringUtils.reduceName(titular?.nome)}</Tab>

                                                    {beneficiariosSelecionados.filter(dep => !dep.titular).map(dep => (
                                                        <Tab key={dep.id}>{stringUtils.reduceName(dep.pessoa.nome)}</Tab>
                                                    ))}
                                                </TabList>

                                                <TabPanel>
                                                    <p>{`Tabela de serviços opcionais para ${titular?.nome}`}</p>

                                                    <Table
                                                        tableId={`convenio-adicionais-${titular?.id}`}
                                                        headers={[
                                                            { name: 'descricao', value: 'Serviço opcional' },
                                                            { name: 'valor', value: 'Valor por beneficário', centered: true }
                                                        ]}
                                                        data={convenio?.convenio_adicionais.map(adicional => ({
                                                            id: adicional.id,
                                                            descricao: adicional.descricao,
                                                            valor: maskUtils.maskApply.currency(adicional.valor)
                                                        })) || []}
                                                        checkable
                                                        toggleCheckAll={() => { handleCheckAdicional(titular) }}
                                                        onCheckItem={() => { handleCheckAdicional(titular) }}
                                                        onUncheckItem={() => { handleCheckAdicional(titular) }}
                                                        checkedItems={beneficiariosSelecionados.find(b => b.titular)?.adicionais.map(a => a.id) || []}
                                                    />
                                                </TabPanel>

                                                {beneficiariosSelecionados.filter(dep => !dep.titular).map(dep => (
                                                    <TabPanel key={dep.id}>
                                                        <p>{`Tabela de serviços opcionais para ${dep?.pessoa.nome}`}</p>

                                                        <Table
                                                            tableId={`convenio-adicionais-${dep.id}`}
                                                            headers={[
                                                                { name: 'descricao', value: 'Serviço opcional' },
                                                                { name: 'valor', value: 'Valor por beneficário', centered: true }
                                                            ]}
                                                            data={convenio?.convenio_adicionais.map(adicional => ({
                                                                id: adicional.id,
                                                                descricao: adicional.descricao,
                                                                valor: maskUtils.maskApply.currency(adicional.valor)
                                                            })) || []}
                                                            checkable
                                                            toggleCheckAll={() => { handleCheckAdicional(dep) }}
                                                            onCheckItem={() => { handleCheckAdicional(dep) }}
                                                            onUncheckItem={() => { handleCheckAdicional(dep) }}
                                                            checkedItems={dep.adicionais?.map(a => a.id) || []}
                                                        />
                                                    </TabPanel>
                                                ))}
                                            </Tabs>

                                            {!isDisableCheckTitular(titular) ? (
                                                <>
                                                    <p>{`Tabela de valores do serviço por ${convenio?.criterios.join(', ').replace(/, (\w+)$/, ' e $1')}`}</p>

                                                    <Table
                                                        headers={getTabelaDescontosHeaders()}
                                                        data={getTabelaDescontos()}
                                                        hideOffset={690}
                                                    />

                                                    <Checkbox
                                                        name="aceite"
                                                        label="Li e aceito todos os termos do serviço contratado"
                                                        handleChange={e => {
                                                            setFieldValue(e.target.name, e.target.checked)
                                                            setAceite(e.target.checked)
                                                        }}
                                                    />

                                                    <Button type="submit" className="white submit-button" disabled={!aceite || (tipoPortabilidade !== 0 && !aceitePortabilidade)} loading={isSubmitting}>
                                                        Aderir ao serviço
                                                        <FiCheck size={18} />
                                                    </Button>
                                                </>
                                            ) : (
                                                <p className="badge red">
                                                    Regularize sua documentação para poder continuar com a adesão ao Plano de Saúde.
                                                </p>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={!!dependente}
                handleClose={() => { setDependente(null) }}
                title={`Gerenciamento documentos de ${dependente?.nome}`}
            >
                <GerenciamentoDocumentos
                    handleSubmit={updateDocumentos}
                    documentos={dependente?.documentos.map(doc => ({
                        id: doc.id,
                        arquivo: doc.arquivo,
                        tipo_documento: doc.tipo_documento,
                        extra: JSON.parse(doc.extra),
                        identificador: doc.identificador
                    }))}
                    categoria={!dependente?.associado ? 'dependente' : dependente?.associado.medico ? 'medico' : 'academico'}
                />
            </Modal>

            <Modal
                isOpen={!!portabilidadeRegraToShow}
                handleClose={() => { setPortabilidadeRegraToShow(null) }}
                title="Portabilidade de carência"
            >
                <RegrasPortabilidadeContainer>
                    <iframe src={getArquivoRegrasPortabilidadePlano(portabilidadeRegraToShow)} title="regras" frameBorder="0" />
                </RegrasPortabilidadeContainer>
            </Modal>

            <CadastroDependente onSubmit={loadDependentes} />
        </>
    )
}
