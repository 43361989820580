import React, { useContext } from 'react'
import {
    FaDownload, FaEdit, FaEye, FaTrash 
} from 'react-icons/fa'

import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'

import { ContratoContext } from '../../../../contexts/ContratoContext'

import { Container } from './styles'

export default function () {
    const {
        contratos, setContratoDetalhe, handleDownloadContrato, handlePrepararEdicao, handleRemoverContrato 
    } = useContext(ContratoContext)

    return (
        <Container>
            {contratos ? (
                <Table 
                    headers={[
                        { name: 'fornecedor_nome', value: 'Fornecedor' },
                        { name: 'tipo', value: 'Tipo', centered: true },
                        { name: 'objeto', value: 'Objeto', centered: true },
                        { name: 'inicio_formatado', value: 'Início', centered: true },
                        { name: 'fim_formatado', value: 'Fim', centered: true },
                        { name: 'manutencao_periodica_string', value: 'Manutenção períodica', centered: true },
                        { name: 'manutencao_prevista', value: 'Manutenção prevista', centered: true }
                    ]}
                    hideOnSmallHeaders={['manutencao_periodica_string', 'objeto']}
                    hideHeadersOffset={945}
                    hideOffset={700}
                    filterable
                    data={contratos}
                    actions={[
                        {
                            action: setContratoDetalhe,
                            name: 'Detalhar',
                            icon: FaEye,
                            iconSize: 15
                        },
                        {
                            action: handleDownloadContrato,
                            name: 'Baixar contrato',
                            icon: FaDownload,
                            iconSize: 14,
                            color: '#4caf50',
                            checkDisabled: item => !item.arquivo_link,
                            disabledTooltip: 'Contrato não disponível para download'
                        },
                        {
                            action: handlePrepararEdicao,
                            name: 'Editar',
                            icon: FaEdit,
                            iconSize: 15,
                            color: '#03a9f4',
                            checkDisabled: item => {
                                const encerrado = new Date(item.fim) < new Date()

                                return encerrado
                            },
                            disabledTooltip: 'Não é possível editar contratos encerrados.'
                        },
                        {
                            action: handleRemoverContrato,
                            name: 'Remover',
                            icon: FaTrash,
                            iconSize: 13,
                            color: '#f44336',
                            confirmation: 'Deseja realmente REMOVER este contrato do sistema?',
                            checkDisabled: item => {
                                const encerrado = new Date(item.fim) < new Date()

                                return encerrado
                            },
                            disabledTooltip: 'Não é possível remover contratos encerrados.'
                        }
                    ]}
                    legends={[
                        {
                            color: '#fff59d',
                            text: 'Possui manutenção próxima'
                        },
                        {
                            color: '#ffcdd2',
                            text: 'Possui manuntenção em atraso'
                        },
                        {
                            color: '#ddd',
                            text: 'Contrato encerrado'
                        }
                    ]}
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
