import styled from 'styled-components'

export const Container = styled.div`
	margin-top: 14px;
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 8px;
	grid-template-areas: 
		'd1 d1'
		'd2 d2'
		'd3 d3'
		'd4 d4'
		'table_beneficiarios table_beneficiarios'
		'table_adicionais table_adicionais'
		'check check'
		'button button';

	p {
		margin: 0 8px;
	}

	.table-container {
		margin: 10px 8px 0;
		width: calc(100% - 16px);

		&.table-beneficiarios {
			grid-area: table_beneficiarios;	
		}

		&.table-adicionais {
			grid-area: table_adicionais;	
		}
	}

	.checkbox {
		grid-area: check;
	}

	> button {
		justify-self: flex-end;
		width: fit-content;

		&:first-child {
			grid-area: btn1;
		}

		&:last-child {
			grid-area: btn2;
		}
	}
`

export const ButtonContainer = styled.div`
	grid-area: button;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	gap: 8px;

	@media(max-width: 600px) {
		button {
			width: 100%;
		}
	}
`

export const ModalContent = styled.div`
	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		
		p {
			margin: 0 8px;
		}

		> button {
			align-self: flex-end;
		}
	}
`

export const DocumentosContainer = styled.div.attrs({
    className: 'documentos-container'
})`
	grid-area: content;
	display: flex;
	flex-direction: column;
	align-items: center;

	.buttons-container { 
		display: flex;
		width: 100%;
		justify-content: flex-end;
	}
`

export const SelecaoDocumentosContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.table-container {
		width: calc(100% - 16px);
	}
`
