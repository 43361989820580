import styled from 'styled-components'

export const Container = styled.article`
	form {
		display: grid;
		grid-template-areas:
			  'tip tip tip'
              'f1 f2 f3'
              'f4 f5 f6'
			  'f7 f8 f8'
			  'badge badge badge'
			  'f9 f9 f9'
			  'doc doc doc'
              'bs bs bs';

		& > button {
			width: calc(100% - 16px);
			max-width: 200px;
			justify-self: end;
			margin-top: 20px !important;
		}

		.badge {
			grid-area: badge;
			padding: 8px;
			background-color: #ffeb3b;
			border-radius: 8px;
			color: #424242;
			max-width: 600px;
			justify-self: center;
		}

		@media(max-width: 900px) {
			grid-template-areas:
			  'tip tip'
              'f1 f2'
              'f3 f4'
			  'f5 f6'
			  'f7 f8'
			  'f9 f9'
			  'doc doc'
              'bs bs';
		}

		@media(max-width: 600px) {
			grid-template-areas:
              'f1'
              'f2'
			  'f3'
			  'f4'
			  'f5'
			  'f6'
			  'f7'
			  'f8'
			  'f9'
			  'doc'
			  'bs';

			.field.checkbox {
				grid-column: unset;
			}

			& > button {
				max-width: unset;
			}
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`

export const CheckboxesContainer = styled.div`
	grid-area: f9;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	.checkbox {
		grid-column: 1;
	}

	.textarea {
		grid-row: 1/span 3;
		grid-column: 2/span 2;
	}

	.textbox {
		grid-column: 2;
	}

	.valor-parcela {
		font-size: 18px;
		font-weight: 600;
		align-self: center;
	}
`

export const DocumentosContainer = styled.div`
	grid-area: doc;
	display: flex;
	flex-direction: column;

	.controle-documentos {
		display: grid;
		margin-bottom: 20px;
		grid-template-columns: 1.4fr 1fr 0.9fr;
		grid-template-rows: 50px auto 54px;
		grid-template-areas: 
			'caption caption caption'
			'arquivo tipo table'
			'arquivo button table';

		> * {
			align-self: flex-start;
		}

		.file {
			grid-area: arquivo;	
		}

		.table-container {
			grid-area: table;	
		}

		> h1 {
			grid-area: caption;
			margin: 20px 10px 0;
		}

		button {
			grid-area: button;
		}

		@media(max-width: 1000px) {
			display: flex;
			flex-direction: column;
			
			button {
				align-self: flex-end;
				margin-top: 8px;
			}

			.table-container {
				align-self: center;
			}
		}
	}

	.table-container {
		width: calc(100% - 16px);
		justify-self: flex-end;
		margin-top: 24px;
	}

	> button {
		align-self: center;
		width: fit-content;
	}
`
