import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import { FiSave } from 'react-icons/fi'
import { format } from 'date-fns'

import {
    Button, File, Select, Textbox 
} from '../../../../../components/Form'

import ProfissionalCadastro from '../../Profissional/Cadastro'

import { Container } from './styles'

import formUtils from '../../../../../util/form'
import masks from '../../../../../util/masks'

import { api, authHeaders } from '../../../../../services/api'
import Yup from '../../../../../services/yup'

const baseInitialValues = {
    profissional: null,
    data_hora: '',
    nome: '',
    credencial: '',
    fornecedor_id: null,
    os: null
}

export default function ({ manutencaoEdicao, onSubmit, contrato }) {
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function handleSubmit(values) {
        values = formUtils.extractFormValues(values, true)

        try {
            if(manutencaoEdicao) {
                await api.put(`contrato/${contrato.id}/manutencao/${manutencaoEdicao.id}`, values, authHeaders())

                toast.success('Manutenção cadastrada.')
            } else {
                await api.post(`contrato/${contrato.id}/manutencao`, values, authHeaders())

                toast.success('Manutenção atualizada.')
            }

            onSubmit()
        } catch(e) {
            toast.error(e.msg)
        }
    }
	
    useEffect(() => {
        if(manutencaoEdicao) {
            setInitialValues({
                profissional: {
                    label: manutencaoEdicao.profissional.pessoa.nome,
                    value: manutencaoEdicao.profissional.id
                },
                data_hora: format(new Date(manutencaoEdicao.data), 'dd/MM/yyyy HH:mm'),
                os: manutencaoEdicao?.os_id || null
            })
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={Yup.object({
                    data_hora: Yup.string().date().required('Informe a data da amnutenção.')
                })}
            >
                {({ setFieldValue, values, errors }) => (
                    <Form>
                        <Select 
                            label="Profissional"
                            name="profissional"
                            options={[
                                {
                                    label: 'Cadastrar novo',
                                    value: ''
                                },
                                ...contrato.fornecedor.profissionais.map(prof => ({
                                    label: prof.pessoa.nome,
                                    value: prof.id
                                }))
                            ]}
                            onChange={(selected, meta) => { 
                                setFieldValue(meta.name, selected) 

                                if(selected.value === '') {
                                    setFieldValue('fornecedor_id', contrato.fornecedor.id)
                                }
                            }}
                        />

                        {values.profissional?.value === '' ? (
                            <ProfissionalCadastro />
                        ) : null}

                        <Textbox 
                            name="data_hora"
                            label="Data / hora"
                            mask={masks.datetime}
                        />

                        <File
                            name="os"
                            onSuccess={fileId => setFieldValue('os', fileId)}
                            label="Ordem de Serviço"
                            format="square"
                            previewSize={['100%', '242px']}
                            error={errors.os}
                            getPreloadImage={async () => {
                                const arquivo = await api.get(`arquivo/${values?.os}`)
                                return arquivo?.link
                            }}
                        />

                        <Button className="transparent" type="submit">
                            {manutencaoEdicao ? 'Salvar alterações' : 'Cadastrar'}
                            <FiSave size={18} />
                        </Button>

                        <Field 
                            type="hidden"
                            name="fornecedor_id"
                        />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
