import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
})

api.interceptors.response.use(
    response => response.data,
    err => new Promise((_, reject) => reject(err.response?.data))
)

const authHeaders = () => {
    let token

    while (!token) {
        token = sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN)
    }

    return {
        headers: { Authorization: `Bearer ${token}` }
    }
}

const apiLegado = axios.create({
    baseURL: process.env.REACT_APP_API_LEGADA_URL || 'http://localhost/legado',
    responseType: 'blob'
})

const apiLegadoCarga = axios.create({
    baseURL: process.env.REACT_APP_API_LEGADA_URL || 'http://localhost/legado'
})

export {
    authHeaders,
    api,
    apiLegado,
    apiLegadoCarga
}
