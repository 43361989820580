import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		.textarea,
		.button-container {
			grid-column: 1/span 3;
		}

		.button-container {
			display: flex;
			justify-content: flex-end;
		}

		.badge {
			padding: 6px 8px;
			background-color: #ffeb3b;
			color: #424242;
			border-radius: 10px;
			height: fit-content;
			width: fit-content;
			grid-column: 3;
			margin: 4px 8px;
			font-size: 12px;
		}

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;
		}
	}
`
