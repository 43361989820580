import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { Container, List, FilterContainer } from './styles'
import { ProcessoGerenciamentoContext } from '../ProcessoGerenciamentoContext'
import { Select, Textbox } from '../../../../../components/Form'
import Item from './Item'
import { TipContext } from '../../../../../contexts/TipContext'
import { loadTips } from '../../../../../util/tip'


export default function () {
    const { setCodigo, tips } = useContext(TipContext)
    const {
        setoresDisponiveis, atendentesDisponiveis, setSetorSelecionado, setorSelecionado, atendentesDoSetor, handleUpdateSetorAtendente
    } = useContext(ProcessoGerenciamentoContext)

    const [atendentesFiltrados, setAtendentesFiltrados] = useState(atendentesDisponiveis)

    function getAtendentesNaoAlocados() {
        const alocadosIds = atendentesDoSetor.map(a => a.id)

        return atendentesFiltrados.filter(a => !alocadosIds.includes(a.id))
    }

    function getSetorSelecionado() {
        return setoresDisponiveis?.find(s => s.id === setorSelecionado)?.descricao || ''
    }

    function handleFilter(event) {
        const search = event.target.value

        if (!search) {
            setAtendentesFiltrados(atendentesDisponiveis)

            return
        }

        const filtrados = atendentesDisponiveis.filter(atendente => {
            const { nome } = atendente
            const { descricao: perfil } = atendente.usuario.perfil

            return `${nome} ${perfil}`.toLowerCase().includes(search.toLowerCase())
        })

        setAtendentesFiltrados(filtrados)
    }

    useEffect(() => {
        loadTips(setCodigo, 'form_processo_setores_atendentes')
    }, [])

    return (
        <Container>
            <p className="tip">{tips?.formulario}</p>

            <DragDropContext onDragEnd={handleUpdateSetorAtendente}>
                {atendentesDisponiveis ? (
                    <Formik
                        onSubmit={() => { }}
                        initialValues={{ perfil: '' }}
                    >
                        {({ setFieldValue, values }) => (
                            <Form>
                                <Select
                                    label="Grupo"
                                    name="setor"
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)

                                        setSetorSelecionado(selected.value)
                                    }}
                                    options={setoresDisponiveis?.map(perfil => ({
                                        label: perfil.descricao,
                                        value: perfil.id
                                    })) || []}
                                />

                                <div>
                                    <h1>{`Atendentes disponíveis / adicionados ao grupo ${getSetorSelecionado()}`}</h1>

                                    <FilterContainer>
                                        <Textbox
                                            withoutForm
                                            label="Busca"
                                            onChange={handleFilter}
                                        />
                                    </FilterContainer>

                                    <Droppable droppableId="disponiveis">
                                        {provided => (
                                            <List
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {values.setor?.value
                                                    ? getAtendentesNaoAlocados().map((atendente, index) => (
                                                        <Item
                                                            key={atendente.id}
                                                            draggableId={String(atendente.id)}
                                                            atendente={atendente}
                                                            index={index}
                                                        />
                                                    ))
                                                    : atendentesFiltrados.map((atendente, index) => (
                                                        <Item
                                                            key={atendente.id}
                                                            draggableId={String(atendente.id)}
                                                            atendente={atendente}
                                                            index={index}
                                                        />
                                                    ))}
                                            </List>
                                        )}
                                    </Droppable>

                                    {values.setor?.value && !atendentesDisponiveis.length && (
                                        <i>Nenhum atendente disponível</i>
                                    )}
                                </div>

                                <div>
                                    {values.setor?.value ? (
                                        <>
                                            <Droppable droppableId="atuais">
                                                {provided => (
                                                    <>
                                                        {atendentesDoSetor && (
                                                            <List
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {atendentesDoSetor.map((atendente, index) => (
                                                                    <Item
                                                                        key={atendente.id}
                                                                        draggableId={String(atendente.id)}
                                                                        atendente={atendente}
                                                                        index={index}
                                                                    />
                                                                ))}
                                                            </List>
                                                        )}
                                                    </>
                                                )}
                                            </Droppable>
                                        </>
                                    ) : (
                                        <p className="placeholder">
                                            <i>Selecione um grupo para definir os atendentes</i>
                                        </p>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <p>
                        <i>Selecione um grupo para gerenciar os membros</i>
                    </p>
                )}
            </DragDropContext>
        </Container>
    )
}
