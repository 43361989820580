import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'

import SignIn from '../pages/SignIn'
import SignUp from '../pages/SignUp'
import DeclaracaoAnual from '../pages/UserViews/Relatorio/DeclaracaoAnual'
import DeclaracaoAnualMedico from '../pages/UserViews/DeclaracaoAnualMedico'
import PassRecoverGetCRM from '../pages/PassRecover/GetCRM'
import PassRecoverPasswordChange from '../pages/PassRecover/PasswordChange'
import MatriculaRecover from '../pages/MatriculaRecover'

import NotAllowed from '../pages/NotAllowed'

import Dashboard from '../pages/UserViews/Dashboard'
import GrupoPermissoes from '../pages/UserViews/GrupoPermissoes'
import Perfil from '../pages/UserViews/Perfil'

import Associacao from '../pages/Associacao'
import AssociacoesPendentes from '../pages/UserViews/Associado/AssociacoesPendentes'
import AprovacaoFormaPagamentoResidente from '../pages/UserViews/Associado/AprovacaoFormaPagamentoResidente'

import FinanceiroEvento from '../pages/UserViews/Financeiro/Evento'
import FinanceiroLancamento from '../pages/UserViews/Financeiro/Lancamento'
import Relatorio from '../pages/UserViews/Relatorio'
import FinanceiroFornecedor from '../pages/UserViews/Financeiro/Fornecedor'
import Extrato from '../pages/UserViews/Financeiro/Extrato'
import Orcamento from '../pages/UserViews/Orcamento'

import ResumoAssociado from '../pages/UserViews/EspacoMedico/Resumo'
import DadosCadastraisAssociado from '../pages/UserViews/EspacoMedico/DadosCadastrais'

import AssociadoFichaAssociado from '../pages/UserViews/Associado/FichaAssociado'
import AssociadoFichaAcademico from '../pages/UserViews/Associado/FichaAcademico'
import CancelamentoAssociacao from '../pages/UserViews/CancelamentoAssociacao'

import Contribuicao from '../pages/UserViews/Contribuicao'

import FaturaPaga from '../pages/FaturaPaga'
import Checkout from '../pages/Checkout'

import ArquivoCarga from '../pages/UserViews/Arquivo'

import AtualizacoesPlanodeSaude from '../pages/UserViews/Atualizacoes/PlanodeSaude'
import AtualizacoesJuridico from '../pages/UserViews/Atualizacoes/Juridico'
import AtualizacoesContribuicoes from '../pages/UserViews/Atualizacoes/Contribuicoes'

import MensageriaEnvio from '../pages/UserViews/Mensageria/Envio'
import MensageriaCadastro from '../pages/UserViews/Mensageria/Cadastro'
import MensageriaEstatistica from '../pages/UserViews/Mensageria/Estatistica'

import Gerenciamento from '../pages/UserViews/Gerenciamento'

import ImprensaPublicacao from '../pages/UserViews/Imprensa/Publicacao'
import PublicacoesPublicas from '../pages/PublicacoesPublicas'
import ImprensaGerenciamento from '../pages/UserViews/Imprensa/Gerenciamento'
import MediaSocial from '../pages/UserViews/MediaSocial'

import AssociadoEstatisticas from '../pages/UserViews/Associado/Estatisticas'

import EventoGerenciar from '../pages/UserViews/Evento/Gerenciar'
import EventoAcompanhar from '../pages/UserViews/Evento/Acompanhar'
import EventosPublicos from '../pages/UserViews/Evento/Publico'
import CertificadoVerificacao from '../pages/CertificadoVerificacao'

import DependenteGerenciar from '../pages/UserViews/Dependente'

import CompraCartaoCredito from '../pages/CompraCartaoCredito'

import EventoDetalhes from '../pages/EventoDetalhes'
import EventoPainel from '../pages/EventoPainel'

import ConsultaPublica from '../pages/ConsultaPublica'

import PlanoDeSaude from '../pages/UserViews/PlanoDeSaude'
import PlanoSaudeBeneficiarios from '../pages/UserViews/PlanoDeSaude/Beneficiarios'
import PlanoSaudeContribuicoes from '../pages/UserViews/PlanoDeSaude/Gerenciar'

import AprovacaoAdesapServico from '../pages/UserViews/Associado/AprovacaoAdesaoServico'

import DocumentosModelos from '../pages/UserViews/Documentos/CadastroModelos'
import DocumentosEnvio from '../pages/UserViews/Documentos/Envio'
import DocumentosGeracao from '../pages/UserViews/Documentos/Geracao'
import DocumentoTemplate from '../pages/UserViews/Documentos/Template'

import AtendimentoGerenciamento from '../pages/UserViews/Atendimento'
import AtendimentoAcompanhar from '../pages/UserViews/Atendimento/Acompanhar'
import AtendimentoFeedback from '../pages/UserViews/Atendimento/Feedback'
import AtendimentoEstatisticas from '../pages/UserViews/Atendimento/Estatisticas'
import AtendimentoAgendamentos from '../pages/UserViews/Atendimento/Agendamentos'

import ConvenioAssociado from '../pages/UserViews/ConvenioAssociado'

import Visoes from '../pages/UserViews/Visoes'

import Permissao from '../pages/UserViews/Permissao'

import Diretoria from '../pages/UserViews/Gerenciamento/Diretoria'

import RotinaGerenciamento from '../pages/UserViews/Rotina/Gerenciar'

import AdvogadoGerenciar from '../pages/UserViews/Advogados'

import TutorialVisualizar from '../pages/UserViews/Tutorial'
import TutorialGerenciar from '../pages/UserViews/Tutorial/Gerenciar'

import GerarAnuidades from '../pages/UserViews/GerarAnuidades'

import CarregarDadosDeclaracao from '../pages/UserViews/DeclaracaoAnualMedico/DeclaracaoCarga'

import DeclaracaoSindicatoAnualMedico from '../pages/UserViews/DeclaracaoSindicatoAnualMedico'
import DeclaracaoSindicatoAnual from '../pages/UserViews/Relatorio/DeclaracaoAnualSindicato'
import DeclaracaoRessarcimentoPlanoSaude from '../pages/UserViews/Relatorio/DeclaracaoRessarcimentoPlanoSaude'
import DeclaracaoRessarcimentoPlanoSaudeMedico from '../pages/UserViews/DeclaracaoRessarcimentoPlanoSaudeMedico'

import Visita from '../pages/UserViews/Visita'
import VisitaFeedback from '../pages/UserViews/Visita/Feedback'
import VisitaMotivo from '../pages/UserViews/Gerenciamento/VisitaMotivo'

import AvaliarDocumentacao from '../pages/UserViews/Associado/AvaliarDocumentacao'

import AssociacoesRealizadas from '../pages/UserViews/Associado/AssociacoesRealizadas'
import Colaborador from '../pages/UserViews/Colaborador'

import Tarefas from '../pages/UserViews/Tarefas'

import Parametros from '../pages/UserViews/Parametros'

import Usuario from '../pages/UserViews/Usuario'

import Eleicao from '../pages/UserViews/Eleicao'
import EleicaoVoto from '../pages/UserViews/Eleicao/Voto'
import EleicaoResultado from '../pages/UserViews/Eleicao/Resultado'
import EleicaoCargos from '../pages/UserViews/Gerenciamento/CargosEleicao'
import EleicaoVerificacao from '../pages/EleicaoVerificacao'

import Contrato from '../pages/UserViews/Contrato'

import AssociadoAposentadoria from '../pages/UserViews/Associado/Aposentadoria'

import Recados from '../pages/UserViews/Recado'

import Pastas from '../pages/UserViews/Documentos/Pastas'

import RelatorioCustomizado from '../pages/UserViews/RelatorioCustomizado'

import Regra from '../pages/UserViews/Regra'

import Operacao from '../pages/UserViews/Operacao'
import OperacaoTipo from '../pages/UserViews/Gerenciamento/OperacaoTipo'

import Logs from '../pages/UserViews/Log'

import RemessaBancaria from '../pages/UserViews/ArquivoBancario'

import Faculdades from '../pages/UserViews/Gerenciamento/Faculdades'

import TipoCobranca from '../pages/UserViews/Gerenciamento/TipoCobranca'

import Tips from '../pages/UserViews/Tips'

import ProcessosListagem from '../pages/UserViews/Processo'
import ProcessosVisualizacao from '../pages/UserViews/Processo/Visualizacao'
import ProcessosRelatorios from '../pages/UserViews/Processo/Relatorio'
import ProcessoGerenciar from '../pages/UserViews/Processo/Gerenciar'
import ProcessoFeedback from '../pages/UserViews/Processo/Feedback'

import AtendimentoAbetura from '../pages/Atendimento'

import PlanoSaudeAtivacao from '../pages/UserViews/PlanoDeSaude/AtivacaoDesativacao'
import MotivoCancelamentoPlano from '../pages/UserViews/Gerenciamento/MotivoCancelamentoPlano'

import CriacaoEnquete from '../pages/UserViews/Enquete/Criacao'
import GerenciarEnquetes from '../pages/UserViews/Enquete/Gerenciar'
import EnquetesVotar from '../pages/UserViews/Enquete/Lista'
import EnqueteVotar from '../pages/UserViews/Enquete/Votar'
import EnqueteFeedback from '../pages/UserViews/Enquete/Feedback'
import EnqueteRespostas from '../pages/UserViews/Enquete/Respostas'
import EnqueteEstatistica from '../pages/UserViews/Enquete/Estatistica'

import DocumentosFinanceiros from '../pages/UserViews/Financeiro/Documentos'

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={SignIn} />

            <Route path="/signup" component={SignUp} />
            <Route path="/pass_recover" exact component={PassRecoverGetCRM} />
            <Route path="/pass_recover/:token/:crm" component={PassRecoverPasswordChange} />
            <Route path="/matricula_recover" component={MatriculaRecover} />

            <Route path="/checkout/:fatura_id" component={Checkout} />
            <Route path="/checkout_auth/:fatura_id" component={Checkout} isPrivate />
            <Route path="/checkout_auth_iframe/:fatura_id" component={Checkout} isPrivate />
            <Route path="/fatura_paga" component={FaturaPaga} />

            <Route path="/compra_cartao" component={CompraCartaoCredito} />

            <Route path="/atendimento" exact component={AtendimentoAbetura} />

            <Route path="/declaracao_anual" component={DeclaracaoAnualMedico} isPrivate />
            <Route path="/declaracao_anual_sindmepa" component={DeclaracaoAnual} isPrivate />

            <Route path="/not_allowed" component={NotAllowed} isPrivate />

            <Route path="/associacao" component={Associacao} />
            <Route path="/associacoes_realizadas" component={AssociacoesRealizadas} isPrivate />

            <Route path="/dashboard" component={Dashboard} isPrivate />
            <Route path="/perfil" component={Perfil} isPrivate />
            <Route path="/grupo_permissoes/:grupoId" component={GrupoPermissoes} isPrivate />

            <Route path="/associado/resumo" component={ResumoAssociado} isPrivate />
            <Route path="/associado/dados_cadastrais" component={DadosCadastraisAssociado} isPrivate />
            <Route path="/associado/cadastro" component={Associacao} isPrivate />
            <Route path="/associado/ficha_associado" component={AssociadoFichaAssociado} isPrivate />
            <Route path="/associado/ficha_academico" component={AssociadoFichaAcademico} isPrivate />
            <Route path="/associado/associacoes_pendentes" component={AssociacoesPendentes} isPrivate />
            <Route path="/associado/aprovacao_forma_pagamento_residente" component={AprovacaoFormaPagamentoResidente} isPrivate />
            <Route path="/associado/cancelar_associacao" component={CancelamentoAssociacao} isPrivate />
            <Route path="/associado/aprovacao_adesao" component={AprovacaoAdesapServico} isPrivate />
            <Route path="/associado/plano_de_saude" component={PlanoDeSaude} isPrivate />

            <Route path="/estatistica/associado" component={AssociadoEstatisticas} isPrivate />
            <Route path="/estatistica/mensageria" component={MensageriaEstatistica} isPrivate />

            <Route path="/financeiro/evento" component={FinanceiroEvento} isPrivate />
            <Route path="/financeiro/fornecedor" component={FinanceiroFornecedor} isPrivate />
            <Route path="/financeiro/lancamento" component={FinanceiroLancamento} isPrivate />
            <Route path="/financeiro/contribuicao" component={Contribuicao} isPrivate />
            <Route path="/financeiro/extrato" component={Extrato} isPrivate />
            <Route path="/financeiro/orcamento" component={Orcamento} isPrivate />

            <Route path="/contribuicao/gerar_anuidades" component={GerarAnuidades} isPrivate />

            <Route path="/relatorio/:grupoId" component={Relatorio} isPrivate />

            <Route path="/arquivo/carga" component={ArquivoCarga} isPrivate />

            <Route path="/atualizacoes/planodesaude" component={AtualizacoesPlanodeSaude} isPrivate />
            <Route path="/atualizacoes/contribuicoes" component={AtualizacoesContribuicoes} isPrivate />
            <Route path="/atualizacoes/juridico" component={AtualizacoesJuridico} isPrivate />

            <Route path="/mensageria/envio" component={MensageriaEnvio} isPrivate />
            <Route path="/mensageria/cadastro" component={MensageriaCadastro} isPrivate />

            <Route path="/acervo/gerenciar" component={ImprensaPublicacao} isPrivate />
            <Route path="/acervo/imprensa/gerenciar" component={ImprensaGerenciamento} isPrivate />
            <Route path="/acervo/media_social" component={MediaSocial} isPrivate />
            <Route path="/acervo/documentos" component={Pastas} isPrivate />

            <Route path="/publicacao/publico" component={PublicacoesPublicas} />

            <Route path="/associado/associacao" component={Associacao} isPrivate />

            <Route path="/evento/publico" component={EventosPublicos} />
            <Route path="/evento/gerenciar" component={EventoGerenciar} isPrivate />
            <Route path="/evento/acompanhamento" component={EventoAcompanhar} isPrivate />
            <Route path="/evento/:id/painel" component={EventoPainel} />
            <Route path="/evento/:id/:pessoa_id" component={EventoDetalhes} />
            <Route path="/evento/:id" component={EventoDetalhes} isPrivate />

            <Route path="/certificado" component={CertificadoVerificacao} />

            <Route path="/permissoes/gerenciar" component={Permissao} isPrivate />

            <Route path="/diretoria/gerenciar" component={Diretoria} isPrivate />

            <Route path="/dependente/gerenciar" component={DependenteGerenciar} isPrivate />

            <Route path="/plano_saude/beneficiarios" component={PlanoSaudeBeneficiarios} isPrivate />
            <Route path="/plano_saude/cobrancas/gerenciar" component={PlanoSaudeContribuicoes} isPrivate />
            <Route path="/plano_saude/ativacao" component={PlanoSaudeAtivacao} isPrivate />
            <Route path="/gerenciar/plano_saude_motivos_cancelamento" component={MotivoCancelamentoPlano} isPrivate />

            <Route path="/convenio_associado" component={ConvenioAssociado} isPrivate />

            <Route path="/gerenciar/:entidade" component={Gerenciamento} isPrivate />

            <Route path="/documentos/envio" component={DocumentosEnvio} isPrivate />
            <Route path="/documentos/modelos" component={DocumentosModelos} isPrivate />
            <Route path="/documentos/geracao" component={DocumentosGeracao} isPrivate />
            <Route path="/documentos/:id" component={DocumentoTemplate} />

            <Route path="/consulta_publica" component={ConsultaPublica} />

            <Route path="/atendimento/feedback/:token" component={AtendimentoFeedback} />
            <Route path="/atendimento/listar" component={AtendimentoGerenciamento} isPrivate />
            <Route path="/atendimento/relatorio" component={AtendimentoEstatisticas} isPrivate />
            <Route path="/atendimento/agendamento" component={AtendimentoAgendamentos} isPrivate />
            <Route path="/atendimento/estatisticas" component={AtendimentoEstatisticas} isPrivate />
            <Route path="/atendimento/:id" component={AtendimentoAcompanhar} isPrivate />

            <Route path="/visao" component={Visoes} isPrivate />

            <Route path="/rotina/gerenciar" component={RotinaGerenciamento} isPrivate />

            <Route path="/advogado/gerenciar" component={AdvogadoGerenciar} isPrivate />

            <Route path="/tutorial" exact component={TutorialVisualizar} isPrivate />
            <Route path="/tutorial/gerenciar" component={TutorialGerenciar} isPrivate />

            <Route path="/declaracao/carga" component={CarregarDadosDeclaracao} isPrivate />

            <Route path="/declaracao_sindicato_medico" component={DeclaracaoSindicatoAnualMedico} isPrivate />

            <Route path="/declaracao_sindicato" component={DeclaracaoSindicatoAnual} isPrivate />

            <Route path="/visita/gerenciar" component={Visita} isPrivate />
            <Route path="/visita/feedback/:token" component={VisitaFeedback} />
            <Route path="/visita/motivo/gerenciar" component={VisitaMotivo} isPrivate />

            <Route path="/associado/documento/avaliar" component={AvaliarDocumentacao} isPrivate />

            <Route path="/colaboradores" component={Colaborador} isPrivate />

            <Route path="/tarefas" component={Tarefas} isPrivate />

            <Route path="/parametros" component={Parametros} isPrivate />

            <Route path="/usuarios" component={Usuario} isPrivate />

            <Route path="/eleicao" exact component={Eleicao} isPrivate />
            <Route path="/eleicao/voto" component={EleicaoVoto} isPrivate />
            <Route path="/eleicao/resultados" component={EleicaoResultado} isPrivate />
            <Route path="/eleicao/cargos/gerenciar" component={EleicaoCargos} isPrivate />
            <Route path="/eleicao/verificacao/:id" component={EleicaoVerificacao} />

            <Route path="/contratos" component={Contrato} isPrivate />

            <Route path="/associado/aposentadoria" component={AssociadoAposentadoria} isPrivate />

            <Route path="/recepcao/recados" component={Recados} isPrivate />

            <Route path="/relatorios_customizados" component={RelatorioCustomizado} isPrivate />

            <Route path="/regra" component={Regra} isPrivate />

            <Route path="/operacoes" exact component={Operacao} isPrivate />
            <Route path="/operacoes/tipo/gerenciar" component={OperacaoTipo} isPrivate />

            <Route path="/logs" component={Logs} isPrivate />

            <Route path="/arquivo_bancario/remessa" component={RemessaBancaria} isPrivate />

            <Route path="/faculdade/gerenciar" component={Faculdades} isPrivate />

            <Route path="/tipo_cobranca/gerenciar" component={TipoCobranca} isPrivate />

            <Route path="/tips" component={Tips} isPrivate />

            <Route path="/processos" component={ProcessosListagem} isPrivate exact />
            <Route path="/processos/relatorio" component={ProcessosRelatorios} isPrivate />
            <Route path="/processos/gerenciar" component={ProcessoGerenciar} isPrivate />
            <Route path="/processo/feedback/:token" component={ProcessoFeedback} />

            {/* Deve ser a última rota iniciada com /processos */}
            <Route path="/processos/:processo_id" component={ProcessosVisualizacao} isPrivate />

            <Route path="/enquete/criar" component={CriacaoEnquete} isPrivate />
            <Route path="/enquete/gerenciar" component={GerenciarEnquetes} isPrivate />
            <Route path="/enquete/participacao" component={EnquetesVotar} isPrivate />
            <Route path="/enquete/:id/votar" component={EnqueteVotar} isPrivate />
            <Route path="/enquete/:id/feedback" component={EnqueteFeedback} isPrivate />
            <Route path="/enquete/:id/respostas" component={EnqueteRespostas} isPrivate />
            <Route path="/enquete/estatistica" component={EnqueteEstatistica} isPrivate />

            <Route path="/documentos_financeiros" component={DocumentosFinanceiros} isPrivate />

            <Route path="/declaracao_ressarcimento_plano_saude_associado" component={DeclaracaoRessarcimentoPlanoSaudeMedico} isPrivate />
            <Route path="/declaracao_ressarcimento_plano_saude" component={DeclaracaoRessarcimentoPlanoSaude} isPrivate />

            <Route path="*" />
        </Switch>
    )
}
