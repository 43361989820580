import React from 'react'

import {
    ContainerDefault, ContainerModal, ProgressModal, ProgressPercentage, ProgressDefault, ProgressWindow, ProgressPercentageContainer
} from './styles'

export default function ({
    backgroundColor = '#388E3C', label = '', visible = false, modal = false, percentage = 0, hint, style
}) {
    if (percentage !== null) {
        if (!visible) {
            return null
        }

        return (
            <ProgressPercentageContainer style={style}>
                <ProgressPercentage value={percentage} color="#FFEB3B" />

                {hint && (<p className="hint">{hint}</p>)}
            </ProgressPercentageContainer>
        )
    }

    return modal ? (
        <ContainerModal visible={visible} style={style}>
            <ProgressWindow>
                <ProgressModal mode="indeterminate" />

                <p className="progress-label">
                    {label}
                </p>
            </ProgressWindow>
        </ContainerModal>
    ) : (
        <ContainerDefault backgroundColor={backgroundColor} visible={visible} style={style}>
            <ProgressDefault mode="indeterminate" />

            <span className="progress-label">
                {label}
            </span>
        </ContainerDefault>
    )
}
