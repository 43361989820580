import React, { useEffect, useState, useContext } from 'react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { FaRegFileAlt } from 'react-icons/fa'

import masks from '../../../../../util/masks'

import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'
import Modal from '../../../../../components/Modal'
import BoxArquivos from '../../../../../boxes/Arquivos'

import { GlobalContext } from '../../../../../contexts/GlobalContext'

import useEventListener from '../../../../../hooks/useEventListener'

import { Container } from './styles'
import { api, authHeaders } from '../../../../../services/api'

const tableHideOffset = 1180
const historicoLimit = 110

export default function ({
    lancamentos, handlePrepareEdit, handleDelete
}) {
    const { user } = useContext(GlobalContext)

    const [documentos, setDocumentos] = useState(null)
    const [lancamento, setLancamento] = useState(null)
    const [editable, setEditable] = useState(false)

    useEventListener('change', () => {
        setTimeout(() => {
            const horizontal = [90, 270].includes(window.screen.orientation.angle)

            if (horizontal && tableHideOffset > window.innerWidth) {
                toast.info('Infelizmente seu dispositivo não tem dimensões suficientes para visualização dos dados. Consulte a versão web.', { autoClose: 6000 })
            }
        }, 500)
    }, window.screen.orientation)

    async function loadDocumentos() {
        const response = await api.get(`lancamento/${lancamento.id}`, authHeaders())

        setDocumentos(response.documentos)
    }

    useEffect(() => {
        if (lancamento) {
            loadDocumentos()
        }
    }, [lancamento])

    useEffect(() => {
        if (user) {
            setEditable(user.perfil.id !== 17)
        }
    }, [user])

    return (
        <Container>
            {lancamentos ? (
                <Table
                    headers={[
                        { name: 'fornecedor', value: 'Parceiro' },
                        { name: 'evento', value: 'Evento', style: { width: 200 } },
                        {
                            name: 'competencia', value: 'Competência', centered: true, style: { width: 130 }
                        },
                        {
                            name: 'historico_truncado', value: 'Descrição'
                        },
                        {
                            name: 'data_consolidacao', value: 'Data consolidação', centered: true, style: { width: 180 }
                        },
                        {
                            name: 'valor', value: 'Valor', centered: true, style: { width: 110 }
                        }
                    ]}
                    data={lancamentos.map(lancamentoItem => ({
                        id: lancamentoItem.id,
                        fornecedor: lancamentoItem.fornecedor?.pessoa.nome || '',
                        evento: `${lancamentoItem.evento.nome}${lancamentoItem.evento.conta_contabil?.codigo_reduzido ? ` - ${lancamentoItem.evento.conta_contabil.codigo_reduzido}` : ''}`,
                        competencia: format(new Date(lancamentoItem.competencia), 'MM/yyyy'),
                        historico_truncado: !lancamentoItem.historico ? '' : lancamentoItem.historico.length > historicoLimit ? `${lancamentoItem.historico.substr(0, historicoLimit)}...` : lancamentoItem.historico,
                        historico: lancamentoItem.historico,
                        data_consolidacao: format(new Date(lancamentoItem.data_consolidacao), 'dd/MM/yyyy'),
                        valor: masks.maskApply.currency(lancamentoItem.valor),
                        background: lancamentoItem.consolidado ? lancamentoItem.fornecedor_pendencia ? '#fff59d' : 'unset' : '#ffcdd2'
                    }))}
                    showId
                    handlePrepareEdit={editable ? handlePrepareEdit : null}
                    handleDelete={editable ? handleDelete : null}
                    hideOffset={tableHideOffset}
                    confirmExclusion={{
                        attr: 'valor',
                        template: 'Deseja realmente excluir o lançamento no valor de #attr#?'
                    }}
                    actions={[
                        {
                            name: 'Ver documentos',
                            icon: FaRegFileAlt,
                            iconSize: 14,
                            action: item => {
                                setLancamento(item)
                            }
                        }
                    ]}
                    footers={[
                        {
                            colspan: 6,
                            text: 'TOTAL'
                        },
                        {
                            colspan: 1,
                            text: masks.maskApply.currency(lancamentos.reduce((total, current) => {
                                if (current.evento.id === 122) {
                                    return total
                                }

                                return total + Number(current.valor)
                            }, 0)),
                            align: 'left'
                        },
                        {
                            colspan: 3,
                            text: `${lancamentos.length} lançamentos`,
                            align: 'right'
                        }
                    ]}
                    legends={[
                        {
                            color: '#ffcdd2',
                            text: 'Não consolidado'
                        }
                    ]}
                />
            ) : (
                <Spinner size={36} />
            )}

            <Modal
                isOpen={!!lancamento && !!documentos}
                handleClose={() => {
                    setDocumentos(null)
                    setLancamento(null)
                }}
                title="Documentos"
            >
                <BoxArquivos
                    arquivos={documentos?.map(doc => ({
                        nome: doc.tipo_documento.descricao,
                        link: doc.arquivo.link,
                        extensao: doc.arquivo.link.substr(doc.arquivo.link.lastIndexOf('.'))
                    })) || []}
                    title={null}
                />
            </Modal>
        </Container>
    )
}
