import styled from 'styled-components'

export const Container = styled.div`
	margin-top: 28px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: start;

	> h1 {
		font-size: 16px;
		margin: 0 8px 8px;
	}

	.table-container {
		margin-top: 12px;
		grid-column: 1/span 2;
	}

	> button {
		align-self: end;
		justify-self: end;
		width: fit-content;
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`
