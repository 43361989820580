import styled from 'styled-components'

export const Container = styled.div.attrs({
	className: 'credit-card'
})`
	display: flex;
	flex-direction: column;

	ul {
		margin-top: 16px;

		li {
			border: solid 1px #fff;
			padding: 12px 10px;
			margin: 8px 0;
			cursor: pointer;
			border-radius: 4px;

			&:hover {
				background-color: #fff;
				color: #424242;
			}
		}
	}
`
