import React, { useState, useEffect, useContext } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { FiEye, FiSearch } from 'react-icons/fi'

import Card from '../../../components/Card'
import Spinner from '../../../components/Spinner'
import Table from '../../../components/Table'
import { Button, Select, Calendar } from '../../../components/Form'

import formUtils from '../../../util/form'

import { api, authHeaders } from '../../../services/api'

import { Container, FormContainer, ModalDetalhesContainer } from './styles'

import rangeDayGif from '../../../assets/images/gifs/periodo-dias.gif'
import BuscaPessoas from '../../../components/BuscaPessoas'
import Modal from '../../../components/Modal'
import { TipContext } from '../../../contexts/TipContext'
import { loadTips } from '../../../util/tip'

const initialValues = {
    tipo: null,
    usuario: '',
    periodo: ''
}

let detalhes = ''

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [logs, setLogs] = useState([])
    const [tipos, setTipos] = useState([])
    const [pessoaSelecionada, setPessoaSelecionada] = useState(null)
    const [logDetalhe, setLogDetalhe] = useState(null)
    const [logDetalheItens, setLogDetalheItens] = useState('')

    async function loadTipos() {
        const response = await api.get('log_tipos')

        setTipos(response)
    }

    async function handleSubmit(values) {
        setLogs(null)

        values = formUtils.extractFormValues(values)

        try {
            let inicio
            let fim

            if(values.periodo) {
                [inicio, fim] = values.periodo
            }

            const body = {
                usuario: pessoaSelecionada.usuario.id,
                tipo: values.tipo,
                inicio,
                fim
            }

            const response = await api.get('log', {
                params: body,
                ...authHeaders()
            })

            setLogs(response.map(log => ({
                ...log,
                operacao: log.tipo.descricao,
                perfil: log.usuario.perfil.descricao,
                nome: log.usuario.pessoa.nome,
                data_hora: format(new Date(log.created_at), 'dd/MM/yyyy HH:mm\'h\'')
            })))
        } catch(e) {
            setLogs([])

            toast.error(e.msg)

            console.log(e)
        }
    }

    function inserirEmDetalhes(obj) {
        if(obj instanceof Object && !Object.keys(obj).length) {
            setLogDetalheItens('')
            return
        }

        detalhes += '<blockquote>'

        for (let k in obj) {
            if(!['id', 'executing'].includes(k) && !k.includes('_id')) {
                if (typeof obj[k] === 'object') {
                    detalhes += `<p><b>${k.toUpperCase()}</b></p>`
	
                    inserirEmDetalhes(obj[k])
                } else {
                    detalhes += `<p>${k.toUpperCase()}: ${obj[k]}</p>`
                }
            }
        }

        detalhes += '</blockquote>'

        setLogDetalheItens(detalhes)
    }

    useEffect(() => {
        loadTipos()

        loadTips(setCodigo, 'form_logs')
    }, [])

    useEffect(() => {
        inserirEmDetalhes(logDetalhe)
    }, [logDetalhe])

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Logs do sistema</h1>

                        <FormContainer>
                            <Formik
                                onSubmit={handleSubmit}
                                initialValues={initialValues}
                            >
                                {({ setFieldValue }) => (
                                    <Form>
                                        <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                                        <BuscaPessoas
                                            name="usuario"
                                            label="Usuário"
                                            normalizar={pessoas => pessoas.filter(p => !!p.usuario)}
                                            onSelectResultado={setPessoaSelecionada}
                                        />

                                        <Select 
                                            name="tipo"
                                            label="Tipo de operação"
                                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                            options={tipos.map(tipo => ({
                                                label: tipo.descricao,
                                                value: tipo.id
                                            }))}
                                        />

                                        <Calendar 
                                            name="periodo"
                                            yearNavigator 
                                            label="Período"
                                            maxDate={new Date()}
                                            selectionMode="range"
                                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                                        />

                                        <div className="button-container">
                                            <Button className="transparent" type="submit">
                                                Buscar
                                                <FiSearch size={16} />
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </FormContainer>

                        {logs ? (
                            <Table 
                                headers={[
                                    { name: 'nome', value: 'Nome' },
                                    { name: 'perfil', value: 'Perfil', centered: true },
                                    { name: 'operacao', value: 'Operação', centered: true },
                                    { name: 'data_hora', value: 'Data e hora', centered: true }
                                ]}
                                data={logs}
                                actions={[
                                    {
                                        name: 'Detalhar',
                                        action: item => {
                                            setLogDetalhe(item.detalhe)

                                            detalhes = ''
                                        },
                                        icon: FiEye,
                                        iconSize: 16
                                    }
                                ]}
                                filterable
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!logDetalhe}
                handleClose={() => { setLogDetalhe(null) }}
                title="Detalhes do log"
            >
                <ModalDetalhesContainer>
                    {logDetalheItens ? (
                        <div dangerouslySetInnerHTML={{ __html: logDetalheItens }} />
                    ) : (
                        <p className="empty"><i>Nenhum detalhamento a ser exibido.</i></p>
                    )}
                </ModalDetalhesContainer>
            </Modal>
        </>
    )
}
