import React from 'react'
import { Button } from '../Form'

import Spinner from '../Spinner'

import { MenuOptions } from './styles'

export default function ({ options }) {
    return (
        <MenuOptions>
            {options.map(item => (
                <li key={item.text}>
                    <Button onClick={item.editable && !item.loading ? item.action : null} className={item.loading ? 'loading' : ''}>
                        <span>{!item.loading ? item.text : 'Aguarde...'}</span>
                        {item.editable && (
                            <>
                                {item.loading ? (
                                    <Spinner size={16} label="" />
                                ) : (
                                    <item.icon size={16} />
                                )}
                            </>
                        )}
                    </Button>
                </li>
            ))}
        </MenuOptions>
    )
}
