import React from 'react'
import { Pie } from '@reactchartjs/react-chart.js'

import { Container } from './styles'

const colors = [
    '#29a1d9',
    '#FF3D67',
    '#FFC533',
    '#8142FF',
    '#5e755f',
    '#00b0ff'
]

export default function ({ width = 600, height = 300, data }) {
    return (
        <Container canvas={(width && height) ? { width, height } : null}>
            <Pie
                data={{
                    labels: Object.keys(data),
                    datasets: [
                        {
                            data: Object.values(data).map(d => d.value),
                            backgroundColor: Object.values(data)?.[0].color ? Object.values(data).map(i => i.color) : colors,
                            borderColor: Object.values(data)?.[0].color ? Object.values(data).map(i => i.color) : colors
                        }
                    ]
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        position: 'right',
                        labels: {
                            fontColor: '#FFF',
                            fontSize: 13
                        }
                    }
                }}
            />
        </Container>
    )
}
