import styled, { css } from 'styled-components'

const badge = css`
	font-size: 12px;
	background-color: white;
	padding: 6px 8px;
	border-radius: 8px;
	color: #424242;
	width: fit-content;
	margin-left: 20px;
`

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		.card {
			min-height: 35vh;

			h1 {
				margin: 0;
			}
		}

		@media(max-width: 600px) {
			padding: 30px 0;
		}
	}
`

export const ModalContent = styled.div`
	display: grid;
	grid-template-columns: 44% 12% 44%;
	grid-template-areas:
		'title title buttonTop'
		'boxes boxes boxes'
		'arquivos arquivos arquivos'
		'buttonBottom buttonBottom buttonBottom';
	padding: 30px;

	> button {
		width: fit-content;
		justify-self: right;
	}

	h2 {
		grid-area: title;
		font-size: 24px;
		font-weight: 500;
		margin: 0 8px;
		display: flex;
		align-items: center;

		.badge {
			${badge}	
		}
	}

	.card {
		background-image: unset;
		background-color: rgba(0,0,0,0.2);
	}

	.action-footer {
		grid-area: buttonBottom;
	}

	@media(max-width: 900px) {
		padding: 30px 14px;
		grid-template-columns: 1fr;
		grid-template-areas:
			'title'
			'boxes'
			'arquivos'
			'buttonBottom';

		> button {
			display: none;
		}
	}
`

export const ModalConfirmationContent = styled.div`
	.badge {
		${badge}
		background-color: #ffeb3b;
		margin-left: 8px;
	}
`

export const BoxesContainer = styled.div`
	grid-area: boxes;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	> article:first-child,
	> article:last-child {
		width: 44%;
	}

	.seta-baixo,
	.seta-direita {
    	display: flex;
		justify-content: center;
	}

	.seta-baixo {
		display: none;
	}

	.seta-direita {
		width: 12%;
		cursor: default;

		&:hover {
			opacity: unset;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column;
		align-items: center;

		.seta-baixo {
			display: block;
		}

		.seta-direita {
			display: none;
		}

		> article:first-child,
		> article:last-child {
			width: calc(100% - 16px);
		}
	}
`
