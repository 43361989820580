import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'
import { format } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    pagamento: ''
}

const validation = Yup.object().shape({
    pagamento: Yup.string().nullable().required('informe um período para a busca.')
})

const nomeRelatorio = 'Relatório de Despesas da Cozinha'

export default function (props) {
    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            pagamento
        } = values

        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioDespesasCozinha',
                parametros: {
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, resetForm
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="pagamento"
                            label="Período para busca"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f1' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
