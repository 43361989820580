import styled, { css } from 'styled-components'

import Card from '../../../../components/Card'

export const Container = styled.div`
	min-height: 80vh;

	main {
		${props => props.loadedData ? css`
			padding: 35px 50px 35px 90px;
			display: grid;
			grid-template-areas: 'perfil dados';
			grid-template-columns: 250px auto;
			grid-gap: 20px;
		` : css`
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 30px 0;
		`}

		> .spinner-container {
			margin-top: 50px;
		}

		@media(max-width: 1100px) {
			grid-template-areas: 
				'perfil'
				'dados';
			grid-template-columns: 1fr;
			justify-items: center;
		}

		@media(max-width: 900px) {
			padding: 14px 10px;
		}

		@media(max-width: 400px) {
			padding: 0;
		}
	}
`

export const Profile = styled.div.attrs({
	className: 'profile-container'
})`
	h1 {
		font-size: 26px;
		font-weight: 500;
		margin: 8px 0;
		color: #424242;

		span {
			font-size: 12px;
			display: block;
		}
	}

	h2 {
		font-size: 18px;
		font-weight: 400;
		margin: 14px 0;
		color: #424242;
		display: flex;
		align-items: center;

		svg {
			margin-right: 6px;
			background-color: white;
			border-radius: 50%;
		}
	}

	h3 {
		font-size: 14px;
		display: flex;
		align-items: center;
		margin: 10px 0;
		color: #424242;
	}

	hr {
		border: dashed 0.1px #999;
	}

	.badge {
		font-size: 12px;
		background-color: white;
		padding: 6px 8px;
		border-radius: 8px;
		width: fit-content;
		line-height: 24px;

		&.danger {
			background-color: #f44336;
			color: #FFF;
		}

		&.warn {
			background-color: #ffeb3b;
			color: #424242;
		}
	}

	@media(max-width: 1100px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

export const Avatar = styled.div`
	grid-area: foto;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	background-image: url(${props => props.image});
	background-position: center;
	background-size: cover;

	@media(max-width: 880px) {
		width: 150px;
		height: 150px;
		padding-top: unset;
	}
`

export const DadosContainer = styled.div.attrs({
	className: 'dados-container'
})`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'warn warn'
		'pessoal endereco'
		'contatos contatos'
		'medias_sociais medias_sociais'
		'observacao plano_saude'
		'arquivos arquivos';
	grid-gap: 4px;

	.observacao {
		grid-area: observacao;
	}

	@media(max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
`

export const SearchFormContainer = styled(Card).attrs({
	className: 'form-container'
})`
	max-width: 600px;
	width: 100%;

	form {
		display: grid;
		grid-template-columns: 1fr 1.7fr;
		grid-gap: 10px 0;
		grid-template-areas:
			'header header'
			'f1 f2'
			'button button';

		h1 {
			grid-area: header;
			margin: 8px;
		}

		button {
			grid-area: button;
			width: fit-content;
			justify-self: flex-end;
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
			justify-content: stretch;
			width: 100%;

			> * {
				width: calc(100% - 16px);
			}
		}
	}
`

export const ResultTableContainer = styled.div`
	margin-top: 14px;

	h2 {
		font-size: 18px;
		font-weight: 500;
	}

	.table-container {
		margin-top: 12px;
	}
`

export const ModalEdicaoStatusContainer = styled.div`
	display: grid;
    grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'status x'
		'btn btn';
    align-items: flex-end;

	button {
		grid-area: btn;
		width: fit-content;
		justify-self: flex-end;
	}

	.badge {
			grid-area: x;
			margin: 0 8px 10px;
			width: fit-content;
			background-color: #ffeb3b;
			border-radius: 10px;
			padding: 6px;
			color: #424242;
			font-size: 13px;
			line-height: 18px;
		}
`

export const BoxDocumentos = styled(Card).attrs({
	className: 'animated fadeInUp delay-700ms'
})`
	grid-area: arquivos;
	display: grid;
	grid-template-columns: 1fr 24px;

	> h1 {
		+ svg {
			font-size: 24px;
			cursor: pointer;
		}
	}

	> div {
		grid-column: 1/span 2;
	}
`

export const ModalEdicaoFaculdade = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	
	button {
		max-width: fit-content;
	}
`

export const Badge = styled.div`
	grid-area: warn;
	padding: 12px;
	background-color: ${props => props.background || '#ffeb3b'};
	color: ${props => props.color || '#424242'};
	width: fit-content;
	display: grid;
	align-items: center;
	grid-template-columns: 56px auto;
	grid-column: 1/span 2;
	margin: 0 8px;
	border-radius: 10px;
	justify-self: center;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);

	svg {
		grid-row: 1/span 2;
	}

	p {
		line-height: 30px;
	}

	.button-container {
		display: flex;
		width: 100%;
		justify-content: center;

		button {
			width: fit-content;
		}
	}
`
