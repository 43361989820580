import styled, { keyframes } from 'styled-components'

import { Field } from 'formik'

const openTooltip = keyframes`
	from {
		width: 0;
		font-size: 0;
		height: 0;
	}
	to {
		width: 300px;
		font-size: 12px;
		height: fit-content;
		color: #424242;
	}
`

const openTooltipMobile = keyframes`
	from {
		width: 0;
		font-size: 0;
		height: 0;
	}
	to {
		width: 240px;
		font-size: 12px;
		height: fit-content;
		color: #424242;
	}
`

export const CheckboxContainer = styled.div.attrs({
    className: 'field checkbox'
})`
	display: grid;
	position: relative;
	grid-template-columns: ${props => props.hasTip ? '20px auto 24px' : '20px auto'};
	grid-gap: 4px;
	align-items: center;
	margin: 24px 8px 12px 8px;
	width: fit-content;

	input[type=checkbox] {
		width: 18px;
		height: 18px;
		cursor: pointer;
	}

	.tooltip-message {
		position: relative;
		left: 14px;
		top: -10px;
		display: none;
		padding: 8px 10px;
		background-color: #fff;
		color: transparent;
		border-radius: 10px;
		box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
		transition: 300ms;
		animation: ${openTooltip} 150ms ease forwards;
	}

	&:hover {
		.tooltip-message {
			display: unset;
		}
	}

	.tip-container {
		fill: #424242 !important;
		top: 36px;
	}

	> svg {
		width: 18px;
		opacity: 0.7;
		fill: #ddd;

		&:hover {
			cursor: help;

			& + .tip-container {
				display: grid;
			}
		}
	}

	@media(max-width: 500px) {
		.tooltip-message {
			left: -4px;
			top: 0;
			animation: ${openTooltipMobile} 150ms ease forwards;
		}
	}
`

export const Checkbox = styled(Field).attrs({
    type: 'checkbox'
})`
	+ span {
		font-size: 14px;
	}
`
