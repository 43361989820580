import React, { createContext, useEffect, useState } from 'react'

import { api, authHeaders } from '../services/api'
import { descriptografar } from '../util/crypto'

export const GlobalContext = createContext()

const GlobalProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [clienteRobot, setClienteRobot] = useState(null)

    async function loadRobotCliente() {
        const response = await api.get('cliente_data')

        console.log('dados cliente:', response)

        setClienteRobot(response?.customer)
    }

    useEffect(() => {
        loadRobotCliente()
    }, [])

    useEffect(() => {
        const storedUser = descriptografar(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER))

        if (storedUser) {
            setUser(storedUser)
        } else {
            setUser(null)
        }
    }, [])

    async function reload() {
        if (user) {
            const response = await api.get('usuario', authHeaders())

            const storedUser = descriptografar(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER))

            setUser({
                ...storedUser,
                avatar: response.avatar.link
            })
        }
    }

    return (
        <GlobalContext.Provider value={{
            user,
            setUser,
            reload,
            clienteRobot
        }}
        >
            {clienteRobot ? children : null}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider
