import React, { useContext } from 'react'
import { Formik, Form } from 'formik'

import { AcervoContext } from '../index'

import {
    Textbox, Calendar, Button, Select
} from '../../../../../components/Form'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

export default function () {
    const {
        handleSearch, segmentos, setSegmentosSelecionados, segmentosSelecionados
    } = useContext(AcervoContext)

    function obterSegmentosFilhos(nivel) {
        let segmentoAberto = segmentos

        for (let i = 0; i < nivel; i++) {
            const selecionado = segmentosSelecionados[i]

            if (i === 0) {
                segmentoAberto = segmentos.find(s => s.id === selecionado).segmentos_filhos
            } else {
                segmentoAberto = segmentoAberto.find(s => s.id === selecionado).segmentos_filhos
            }
        }

        return segmentoAberto
    }

    if (!segmentos?.length) {
        return null
    }

    return (
        <Container>
            <Formik
                onSubmit={handleSearch}
                initialValues={{
                    periodo: null,
                    titulo: '',
                    palavras_chave: ''
                }}
            >
                {({ setFieldValue, resetForm, values }) => (
                    <Form>
                        <Textbox
                            label="Por título"
                            name="titulo"
                        />

                        <Textbox
                            label="Por palavras-chave"
                            name="palavras_chave"
                        />

                        <Calendar
                            name="periodo"
                            yearNavigator
                            label="Período de publicação"
                            maxDate={new Date()}
                            selectionMode="range"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="nivel_1"
                            label=""
                            placeholder="Nível 1"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                                setFieldValue('nivel_2', null)

                                setSegmentosSelecionados([selected.value])
                            }}
                            options={segmentos?.map(segmento => ({
                                label: segmento.descricao,
                                value: segmento.id
                            })) || []}
                        />

                        {segmentos.length > 0 && segmentosSelecionados.map((seg, idx) => {
                            if (!obterSegmentosFilhos(idx + 1)?.length) {
                                return null
                            }

                            return (
                                <Select
                                    key={seg}
                                    name={`nivel_${idx + 2}`}
                                    label=""
                                    placeholder={`Nível ${idx + 2}`}
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)

                                        const posicaoAtual = Number(meta.name.split('_')[1])

                                        const proximosNiveis = Object.keys(values).filter(k => k.startsWith('nivel_') && Number(k.split('_')[1]) > posicaoAtual)

                                        proximosNiveis.forEach(k => {
                                            setFieldValue(k, null)
                                        })

                                        setSegmentosSelecionados(old => [...old.slice(0, idx + 1), selected.value])
                                    }}
                                    options={obterSegmentosFilhos(idx + 1)?.map(segmento => ({
                                        label: segmento.descricao,
                                        value: segmento.id
                                    })) || []}
                                />
                            )
                        })}

                        {/* <Select
                            name="setor"
                            label="Nível 1"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)

                                setFieldValue('tipo', null)
                                setFieldValue('formato', null)
                            }}
                            options={segmentos?.map(segmento => ({
                                label: segmento.descricao,
                                value: segmento.id
                            })) || []}
                        />

                        {values.setor && (
                            <Select
                                name="tipo"
                                label="Nível 2"
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)

                                    setFieldValue('formato', null)
                                }}
                                options={values.setor ? segmentos.find(seg => seg.id === values.setor.value).segmentos_filhos.map(tipos => ({
                                    label: tipos.descricao,
                                    value: tipos.id
                                })) : []}
                            />
                        )}

                        {values.tipo && (
                            <Select
                                name="formato"
                                label="Nível 3"
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)
                                }}
                                options={values.tipo ? segmentos
                                    .find(seg => seg.id === values.setor.value).segmentos_filhos
                                    .find(seg => seg.id === values.tipo.value).segmentos_filhos
                                    ?.map(formato => ({
                                        label: formato.descricao,
                                        value: formato.id
                                    })) : []}
                            />
                        )} */}

                        <ButtonContainer>
                            <Button onClick={resetForm} className="transparent">
                                Limpar
                            </Button>

                            <Button type="submit" className="white">
                                Pesquisar
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
