import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import {
    FiArrowRight,
    FiChevronDown, FiFileText, FiFolder, FiX 
} from 'react-icons/fi'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'

import { FaInbox } from 'react-icons/fa'
import {
    Select, Textbox, Button, Textarea, Checkbox, Calendar
} from '../../../../components/Form'
import Table from '../../../../components/Table'

import formUtils from '../../../../util/form'
import { loadTips } from '../../../../util/tip'

import {
    Container, Content, DestinatarioContainer, MensagemEmailContainer, AgendamentoContainer, SelecaoDocumentoContainer, Document, DestinatarioInstituicaoContainer, DocumentosContainer, ModalSelecaoDocumentoContainer
} from './styles'

import { api, authHeaders } from '../../../../services/api'

import { TipContext } from '../../../../contexts/TipContext'
import Card from '../../../../components/Card'
import Modal from '../../../../components/Modal'

const baseInitialValues = {
    mensagem_email: '',
    agendado: false,
    instituicao: null,
    agendamento_envio_data: ''
}

const validationFormik = Yup.object({
    mensagem_email: Yup.string().required('Escreva uma mensagem para o e-mail a ser recebido pelo(s) destinatário(s).')
})

const validation = Yup.object({
    metodo_envio: Yup.string().oneOf(['especifico', 'grupo', 'instituicao']).required('Selecione o destinatário.'),
    documentos: Yup.array().of(Yup.number()).min(1, 'Selecione pelo menos um documento para enviar.').required('Selecione um ou mais documentos para enviar.')
})

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const history = useHistory()

    const [visoes, setVisoes] = useState(null)
    const [pessoasVisao, setPessoasVisao] = useState(null)
    const [destinatariosSelecionados, setDestinatariosSelecionados] = useState([])
    const [segmentos, setSegmentos] = useState([])
    const [segmentosSelecionados, setSegmentosSelecionados] = useState([])
    const [segmentosSemOcultacaoItens, setSegmentosSemOcultacaoItens] = useState([])
    const [documentos, setDocumentos] = useState([])
    const [documentosFiltrados, setDocumentosFiltrados] = useState([])
    const [documentosIncluidos, setDocumentosIncluidos] = useState([])
    const [showModalSelectDocumento, setShowModalSelectDocumento] = useState(false)
    const [instituicoes, setInstituicoes] = useState(null)
    const [instituicaoSelecionada, setInstituicaoSelecionada] = useState(null)
    const [niveisOrganogramaSelecionados, setNiveisOrganogramaSelecionados] = useState([])
    const [selecaoDestinatarios, setSelecaoDestinatarios] = useState(null)
    const [pastasFiltradas, setPastasFiltradas] = useState([])
    const [organogramaFiltrados, setOrganogramaFiltrados] = useState(null)

    let atual
    const destinatarioInstituicaoSelecionado = niveisOrganogramaSelecionados.at(-1)

    function clearForm() {
        setSelecaoDestinatarios(null)
        setPastasFiltradas([])
        setDocumentosFiltrados([])
        setDocumentosIncluidos([])

        setOrganogramaFiltrados(null)
        setNiveisOrganogramaSelecionados([])

        setInstituicaoSelecionada(null)
        setSelecaoDestinatarios(null)
        setPessoasVisao(null)
    }

    async function loadVisoes() {
        const response = await api.get('visao', authHeaders())

        setVisoes(response.map(visao => ({
            label: visao.descricao,
            value: visao.id
        })))
    }

    async function loadInstituicoes() {
        try {
            const response = await api.get('instituicao', authHeaders())

            setInstituicoes(response.filter(i => i.id !== 17866))
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function loadSegmentos() {
        const response = await api.get('acervo/segmento', {
            params: {
                publico: 0
            }
        })

        setSegmentos(response)
    }

    async function handleVisaoChange(visaoId) {
        try {
            const { pessoas } = await api.get(`visao/${visaoId}`, authHeaders())

            setPessoasVisao(pessoas.map(pessoa => ({
                ...pessoa,
                identificador: pessoa.crm || pessoa.cpf_cnpj || '-'
            })))
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            if(!selecaoDestinatarios) {
                toast.warn('Selecione um ou mais destinatários para enviar o(s) documento(s).')

                return
            }

            values = formUtils.extractFormValues(values)
        
            const {
                mensagem_email, agendado, agendamento_envio_data, destinatario_email, destinatario_nome 
            } = values
        
            const body = {
                metodo_envio: selecaoDestinatarios,
                mensagem_email,
                documentos: documentosIncluidos.map(doc => doc.id),
                agendado,
                agendamento_envio_data
            }
        
            let validacao

            switch(selecaoDestinatarios) {
                case 'instituicao':
                    const validateInstituicao = Yup.object({
                        destinatario: Yup.object({
                            instituicao_id: Yup.number().required('Selecione a instituição para enviar o documento.'),
                            item_organograma_id: Yup.number().required('Selecione um dos destinatários a partir do organograma para enviar o documento.')
                        })
                    })

                    validacao = validation.concat(validateInstituicao)

                    body.destinatario = {
                        instituicao_id: instituicaoSelecionada?.id,
                        item_organograma_id: niveisOrganogramaSelecionados.last()?.id
                    }

                    try {
                        validacao.validateSync(body)
                    } catch(e) {
                        if(e.errors?.length) {
                            toast.warn(e.errors[0])
                        } else {
                            toast.error('Erro ao enviar o documento.')
                        }

                        return
                    }

                    break

                case 'grupo': 
                    const validateGrupo = Yup.object({
                        destinatario: Yup.array().of(Yup.number()).min(1, 'Selecione pelo menos um destinatário.').required('Selecione a instituição para enviar o documento.')
                    })

                    validacao = validation.concat(validateGrupo)

                    body.destinatario = destinatariosSelecionados

                    try {
                        validacao.validateSync(body)
                    } catch(e) {
                        if(e.errors?.length) {
                            toast.warn(e.errors[0])
                        } else {
                            toast.error('Erro ao enviar o documento.')
                        }

                        return
                    }

                    break

                case 'especifico':
                    const validateEspecifico = Yup.object({
                        destinatario: Yup.object({
                            email: Yup.string().email().required('Informe o e-mail do destinatário.'),
                            nome: Yup.string().required('Informe o nome do destinatário.')
                        })
                    })

                    validacao = validation.concat(validateEspecifico)

                    body.destinatario = {
                        email: destinatario_email,
                        nome: destinatario_nome
                    }

                    try {
                        validacao.validateSync(body)
                    } catch(e) {
                        if(e.errors?.length) {
                            toast.warn(e.errors[0])
                        } else {
                            toast.error('Erro ao enviar o documento.')
                        }

                        return
                    }

                    break

                default: break
            }
        
            await api.post('documento_envio', body, authHeaders())

            if(!agendado) {
                toast.success('O documento foi enviado com sucesso.')
            } else {
                toast.success('O envio do documento foi agendado com sucesso.')
            }

            resetForm()
            clearForm()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadDocumentos(segmentoId) {
        const response = await api.get('acervo', {
            params: {
                segmento: segmentoId
            },
            ...authHeaders()
        })

        setDocumentos(response)
    }

    function handleAddDocumento(doc) {
        setDocumentosIncluidos(old => [
            ...old,
            { 
                id: doc.id, 
                titulo: doc.titulo,
                link: doc.arquivo?.link || doc.link
            }
        ])

        setShowModalSelectDocumento(false)
    }

    function handleRemoverDocumentoIncluido(doc) {
        const item = document.querySelector(`.documento-adicionado-${doc.id}`)

        if(item) {
            item.classList.remove('delay-200ms')
            item.classList.replace('zoomIn', 'zoomOut')
        }

        setTimeout(() => {
            setDocumentosIncluidos(old => old.filter(d => d.id !== doc.id))
        }, 600)
    }

    function obterSegmentosFilhos() {
        let subpasta

        segmentosSelecionados.forEach((segmentoId, index) => {
            if(index === 0) {
                subpasta = segmentos.find(s => s.id === segmentoId)
                return 
            }

            subpasta = subpasta.segmentos_filhos.find(s => s.id === segmentoId)
        })

        return subpasta
    }

    function handleFiltrarPastasEDocumentos(filtro) {
        const subpasta = obterSegmentosFilhos()

        if(!filtro) {
            setPastasFiltradas(subpasta?.segmentos_filhos || [])
            setDocumentosFiltrados(documentos)
            return
        }
        
        const filtroNormalizado = filtro.toLowerCase()

        setPastasFiltradas(subpasta?.segmentos_filhos?.filter(s => s.descricao.toLowerCase().includes(filtroNormalizado)) || [])
        setDocumentosFiltrados(documentos.filter(d => d.titulo.toLowerCase().includes(filtroNormalizado)))
    }

    async function loadInstituicaoComOrganograma(instituicao) {
        setOrganogramaFiltrados(null)

        try {   
            const response = await api.get(`instituicao/${instituicao.id}`, authHeaders())

            setInstituicaoSelecionada(response)
        } catch(e) {
            toast.error(e.msg)
        }
    }

    function handleRemoverOcultacaoItens(index) {
        setSegmentosSemOcultacaoItens(old => [...old, index])
    }

    function searchItemOrganogramaByName(nome) {
        if(!nome) {
            setOrganogramaFiltrados(null)
            return
        }

        const filtrados = instituicaoSelecionada.organograma.filter(o => o.pessoa.nome.toLowerCase().includes(nome.toLowerCase()))
        
        setOrganogramaFiltrados(filtrados)
    }

    useEffect(() => {
        loadVisoes()

        loadSegmentos()

        loadInstituicoes()
    }, [])

    useEffect(() => {
        loadTips(setCodigo, 'form_documento_envio')
    }, [])

    useEffect(() => {
        if(segmentosSelecionados.length) {
            const ultimoSegmento = segmentosSelecionados.at(-1)

            loadDocumentos(ultimoSegmento)

            const subpasta = obterSegmentosFilhos()

            setPastasFiltradas(subpasta.segmentos_filhos || [])
        }
    }, [segmentosSelecionados])

    useEffect(() => {
        if(!showModalSelectDocumento) {
            setSegmentosSelecionados([])
        }
    }, [showModalSelectDocumento])

    useEffect(() => {
        setNiveisOrganogramaSelecionados([])
    }, [instituicaoSelecionada])

    useEffect(() => {
        setDocumentosFiltrados(documentos)
    }, [documentos])

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Envio de documentos</h1>

                        <Content>
                            {tips?.formulario && <p>{tips?.formulario}</p>}

                            <Formik
                                initialValues={baseInitialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validationFormik}
                            >
                                {({
                                    setFieldValue, isSubmitting, values
                                }) => (
                                    <Form>
                                        <DestinatarioContainer>
                                            <h1>Documentos a enviar</h1>

                                            <SelecaoDocumentoContainer>
                                                <Button
                                                    className="transparent"
                                                    onClick={() => {
                                                        setShowModalSelectDocumento(true)
                                                    }}
                                                >
                                                    {documentosIncluidos.length ? 'Selecionar outro documento' : 'Selecione o documento a enviar'}
                                                </Button>

                                                {!!documentosIncluidos.length && <p>Documentos incluídos</p>}

                                                <DocumentosContainer>
                                                    {documentosIncluidos.map(doc => (
                                                        <Document 
                                                            key={doc.id} 
                                                            className={`documento-adicionado-${doc.id} zoomIn delay-200ms`}
                                                        >
                                                            <a className="content" href={doc.link} target="_blank" rel="noopener noreferrer">
                                                                <FiFileText size={24} />
                                                                <span>{doc.titulo}</span>
                                                            </a>

                                                            <FiX 
                                                                className="icon-remove" 
                                                                size={16} 
                                                                onClick={() => {
                                                                    handleRemoverDocumentoIncluido(doc)
                                                                }}
                                                            />
                                                        </Document>
                                                    ))}
                                                </DocumentosContainer>
                                            </SelecaoDocumentoContainer>

                                            <h1>Destinatários</h1>

                                            <details
                                                className="grupo"
                                                disabled={!!selecaoDestinatarios && selecaoDestinatarios !== 'grupo'}
                                                open={selecaoDestinatarios === 'grupo'}
                                            >
                                                <summary className={selecaoDestinatarios === 'grupo' ? 'selected' : ''}>
                                                    <div
                                                        onClick={e => {
                                                            e.preventDefault()

                                                            setSelecaoDestinatarios(old => old === 'grupo' ? null : 'grupo')
                                                        }}
                                                        role="button"
                                                        tabIndex="-1"
                                                    >
                                                        <h1>Para um grupo predefinido</h1>
                                                        <FiChevronDown size={18} />
                                                    </div>
                                                </summary>

                                                <div>
                                                    <Select
                                                        withoutForm
                                                        id="destinatarios"
                                                        label="Selecione um grupo de pessoas"
                                                        onChange={selected => {
                                                            handleVisaoChange(selected.value)
                                                        }}
                                                        options={visoes}
                                                    />

                                                    <Table
                                                        headers={[
                                                            { name: 'identificador', value: 'CRM, CPF ou CNPJ', centered: true },
                                                            { name: 'nome', value: 'Nome' }
                                                        ]}
                                                        data={pessoasVisao || []}
                                                        checkable
                                                        onCheckItem={item => {
                                                            setDestinatariosSelecionados(old => [
                                                                ...old,
                                                                item.id
                                                            ])
                                                        }}
                                                        onUncheckItem={item => {
                                                            setDestinatariosSelecionados(old => old.filter(dest => dest !== item.id))
                                                        }}
                                                        toggleCheckAll={status => {
                                                            setDestinatariosSelecionados(status ? pessoasVisao.map(p => p.id) : [])
                                                        }}
                                                        checkedItems={pessoasVisao?.filter(pessoa => destinatariosSelecionados.includes(pessoa.id)) || []}
                                                    />
                                                </div>
                                            </details>

                                            <details
                                                className="especifico"
                                                disabled={!!selecaoDestinatarios && selecaoDestinatarios !== 'especifico'}
                                                open={selecaoDestinatarios === 'especifico'}
                                            >
                                                <summary className={selecaoDestinatarios === 'especifico' ? 'selected' : ''}>
                                                    <div
                                                        onClick={e => {
                                                            e.preventDefault()

                                                            setSelecaoDestinatarios(old => old === 'especifico' ? null : 'especifico')
                                                        }}
                                                        role="button"
                                                        tabIndex="-1"
                                                    >
                                                        <h1>Para um destinatário específico</h1>
                                                        <FiChevronDown size={18} />
                                                    </div>
                                                </summary>

                                                <div>
                                                    <Textbox
                                                        name="destinatario_nome"
                                                        label="Nome do destinatário"
                                                        containerClass="destinatario-nome"
                                                    />

                                                    <Textbox
                                                        name="destinatario_email"
                                                        label="E-mail destino"
                                                        containerClass="destinatario-email"
                                                    />
                                                </div>
                                            </details>

                                            <details
                                                className="instituicao"
                                                disabled={!!selecaoDestinatarios && selecaoDestinatarios !== 'instituicao'}
                                                open={selecaoDestinatarios === 'instituicao'}
                                            >
                                                <summary className={selecaoDestinatarios === 'instituicao' ? 'selected' : ''}>
                                                    <div
                                                        onClick={e => {
                                                            e.preventDefault()

                                                            setSelecaoDestinatarios(old => old === 'instituicao' ? null : 'instituicao')
                                                        }}
                                                        role="button"
                                                        tabIndex="-1"
                                                    >
                                                        <h1>Para membros de uma instituição</h1>
                                                        <FiChevronDown size={18} />
                                                    </div>
                                                </summary>

                                                <div>
                                                    <DestinatarioInstituicaoContainer>
                                                        <div className="selecao-instituicao">
                                                            <Select 
                                                                name="instituicao"
                                                                label="Instituição"
                                                                onChange={(selected, meta) => {
                                                                    setFieldValue(meta.name, selected)

                                                                    const selecionada = instituicoes.find(i => i.id === selected.value)

                                                                    loadInstituicaoComOrganograma(selecionada)
                                                                }}
                                                                options={instituicoes?.map(i => ({
                                                                    label: i.nome_fantasia || i.razao_social,
                                                                    value: i.id
                                                                })) || []}
                                                            />

                                                            {!!niveisOrganogramaSelecionados.length 
                                                                ? <span className="destinatario-selecionado" dangerouslySetInnerHTML={{ __html: `Você selecionou o seguinte destinatário: <b>${destinatarioInstituicaoSelecionado.descricao} - ${destinatarioInstituicaoSelecionado.pessoa.nome}</b>` }} />
                                                                : instituicaoSelecionada ? <span className="destinatario-selecionado">Selecione um destinatário a seguir.</span> : null}
                                                        </div>

                                                        {instituicaoSelecionada && (
                                                            <>
                                                                {!!instituicaoSelecionada.organograma?.length ? (
                                                                    <>
                                                                        <div className="filtro-nome-container">
                                                                            <p>Busque por nome ou clique nos setores / áreas para expandir.</p>

                                                                            <Textbox 
                                                                                withoutForm
                                                                                label="Buscar por nome..."
                                                                                name="organograma-pessoa"
                                                                                onChange={e => { searchItemOrganogramaByName(e.target.value) }}
                                                                                placeholder="Filtrar por nome..."
                                                                            />
                                                                        </div>

                                                                        <ul>
                                                                            {organogramaFiltrados ? organogramaFiltrados.map((item, index) => (
                                                                                <li 
                                                                                    key={item.id}
                                                                                    onClick={() => {
                                                                                        setNiveisOrganogramaSelecionados([item])
                                                                                    }}
                                                                                    className={`
                                                                                        animated zoomIn faster delay-${100 * index}ms 
                                                                                        ${niveisOrganogramaSelecionados[0]?.id === item.id ? 'active' : ''}
                                                                                        ${destinatarioInstituicaoSelecionado?.id === item.id ? 'selected' : ''}
                                                                                    `}
                                                                                >
                                                                                    <b>{item.descricao}</b>

                                                                                    {item.pessoa.nome && <span>{item.pessoa.nome}</span>}
                                                                                </li>
                                                                            )) : instituicaoSelecionada.organograma.filter(o => !o.pai).map((item, index) => (
                                                                                <li 
                                                                                    key={item.id}
                                                                                    onClick={() => {
                                                                                        setNiveisOrganogramaSelecionados([item])
                                                                                    }}
                                                                                    className={`
                                                                                        animated zoomIn faster delay-${100 * index}ms 
                                                                                        ${niveisOrganogramaSelecionados[0]?.id === item.id ? 'active' : ''}
                                                                                        ${destinatarioInstituicaoSelecionado?.id === item.id ? 'selected' : ''}
                                                                                    `}
                                                                                >
                                                                                    <b>{item.descricao}</b>
                                                                                    
                                                                                    {item.pessoa.nome && <span>{item.pessoa.nome}</span>}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </>
                                                                ) : (
                                                                    <div className="empty-organograma">
                                                                        <i>Não há nenhum organograma cadastrado para esta instituição.</i>

                                                                        <span>
                                                                            <span 
                                                                                className="link"
                                                                                onClick={() => {
                                                                                    history.push({
                                                                                        pathname: '/gerenciar/instituicao',
                                                                                        state: { instituicao: instituicaoSelecionada.id }
                                                                                    })
                                                                                }}
                                                                                role="button"
                                                                                tabIndex={-1}
                                                                            >
                                                                                Cadastre o organograma
                                                                            </span>
                                                                            {' '}
                                                                            ou utilize uma das formas de envio acima.
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}

                                                        {niveisOrganogramaSelecionados.map((item, index, arr) => {
                                                            const nivelFilhoSelecionado = index < arr.length - 1 ? arr[index + 1] : null

                                                            return (
                                                                <ul key={item.id}>
                                                                    {instituicaoSelecionada.organograma.filter(i => i.pai?.id === item.id).map((nivel, indexNivel) => (
                                                                        <li 
                                                                            key={nivel.id}
                                                                            onClick={() => {
                                                                                setNiveisOrganogramaSelecionados(old => [...old.slice(0, index + 1), nivel])
                                                                            }}
                                                                            className={`
                                                                                animated zoomIn faster delay-${100 * indexNivel}ms 
                                                                                ${nivel.id === nivelFilhoSelecionado?.id ? 'active' : ''}
                                                                                ${destinatarioInstituicaoSelecionado?.id === nivel.id ? 'selected' : ''}
                                                                            `}
                                                                        >
                                                                            <b>{nivel.descricao}</b>
                                                                            {nivel.pessoa.nome && <span>{nivel.pessoa.nome}</span>}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )
                                                        })}
                                                    </DestinatarioInstituicaoContainer>
                                                </div>
                                            </details>
                                        </DestinatarioContainer>

                                        <MensagemEmailContainer>
                                            <h1>Sua mensagem</h1>

                                            <Textarea
                                                name="mensagem_email"
                                                label="Texto do e-mail a ser enviado com o documento"
                                                id="mensagem_email"
                                            />
                                        </MensagemEmailContainer>

                                        <AgendamentoContainer>
                                            <h1>Agendamento de envio</h1>

                                            <Checkbox
                                                label="Agendar o envio deste documento?"
                                                name="agendado"
                                                handleChange={e => {
                                                    const marcado = e.target.checked

                                                    setFieldValue('agendado', e.target.checked)

                                                    if (!marcado) {
                                                        setFieldValue('agendamento_envio_data', '')
                                                    }
                                                }}
                                            />

                                            {values.agendado && (
                                                <Calendar
                                                    name="agendamento_envio_data"
                                                    label="Data do envio"
                                                />
                                            )}
                                        </AgendamentoContainer>

                                        <Button type="submit" className="white" loading={isSubmitting}>
                                            Enviar documento
                                            <FiArrowRight size={18} />
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Content>
                    </Card>
                </main>
            </Container>

            <Modal
                title="Selecione o documento"
                isOpen={showModalSelectDocumento}
                handleClose={() => {
                    setShowModalSelectDocumento(false)
                }}
            >
                {segmentos && (
                    <ModalSelecaoDocumentoContainer>
                        <h2>{!segmentosSelecionados.length && 'Selecione uma pasta...'}</h2>

                        <div className="navegacao">
                            <div className="pastas-documentos-lista">
                                <ul>
                                    {segmentos.map(s => (
                                        <li 
                                            key={s.id} 
                                            className={segmentosSelecionados?.[0] === s.id ? 'active' : ''}
                                            onClick={() => {
                                                setSegmentosSelecionados([s.id])
                                            }}
                                        >
                                            <FiFolder size={18} />
                                            <span>{s.descricao}</span>
                                            <FiArrowRight className="arrow" size={18} />
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {segmentosSelecionados.map((s, index, arr) => {
                                const idSubNivelSelecionado = index < arr.length - 1 ? arr[index + 1] : null
                                const eUltimoSegmento = s === arr.at(-1)
                                const limiteItensAExibir = 10
                                
                                atual = index === 0 
                                    ? segmentos.find(seg => seg.id === s)
                                    : atual?.segmentos_filhos?.find(seg => seg.id === s)

                                const totalSegmentos = atual?.segmentos_filhos?.length || 0
                                const totalDocumentos = documentos.length
                                const totalItensSegmentoAtual = totalDocumentos + totalSegmentos
                                const listaPastasLimiteAExibir = atual?.segmentos_filhos?.slice(0, limiteItensAExibir) || []
                                const listaPastasUltimoNivelLimiteAExibir = pastasFiltradas.slice(0, limiteItensAExibir)
                                const listaDocumentosLimiteAExibir = documentosFiltrados.slice(0, limiteItensAExibir - listaPastasLimiteAExibir.length)
                                const semOcultacao = segmentosSemOcultacaoItens.includes(index)
                                const quantidadeItensOcultados = totalSegmentos - limiteItensAExibir

                                return !atual?.segmentos_filhos?.length && !documentos.length ? (
                                    <div className="empty-placeholder animated zoomIn faster">
                                        <FaInbox size={60} />
                                        <i>Nenhuma pasta ou documento para selecionar</i>
                                    </div>
                                ) : atual?.segmentos_filhos?.length || documentos ? (
                                    <div className="pastas-documentos-lista">
                                        {eUltimoSegmento && totalItensSegmentoAtual >= 0 && (
                                            <Textbox 
                                                name="filtro-documentos"
                                                withoutForm
                                                label="Busca..."
                                                className="transparent"
                                                containerClass="filtro-documentos"
                                                onChange={e => { 
                                                    handleRemoverOcultacaoItens(index)

                                                    handleFiltrarPastasEDocumentos(e.target.value) 
                                                }}
                                            />
                                        )}

                                        <ul key={s} className={eUltimoSegmento ? 'animated fadeInLeft faster' : ''}>
                                            {/* Listagem de pastas (segmentos)) */}
                                            {eUltimoSegmento 
                                                ? (semOcultacao ? pastasFiltradas : listaPastasUltimoNivelLimiteAExibir).map(s2 => (
                                                    <li 
                                                        key={s2.id}
                                                        className={idSubNivelSelecionado === s2.id ? 'active' : ''}
                                                        onClick={() => {
                                                            setSegmentosSelecionados(old => [
                                                                ...old.slice(0, index + 1), 
                                                                s2.id
                                                            ])
                                                        }}
                                                    >
                                                        <FiFolder size={18} />
                                                        <span>
                                                            {s2.descricao} 
                                                        </span>
                                                        <FiArrowRight className="arrow" size={18} />
                                                    </li>
                                                )) 
                                                : (semOcultacao ? atual?.segmentos_filhos : listaPastasLimiteAExibir)?.map(s2 => (
                                                    <li 
                                                        key={s2.id}
                                                        className={idSubNivelSelecionado === s2.id ? 'active' : ''}
                                                        onClick={() => {
                                                            setSegmentosSelecionados(old => [
                                                                ...old.slice(0, index + 1), 
                                                                s2.id
                                                            ])
                                                        }}
                                                    >
                                                        <FiFolder size={18} />
                                                        <span>
                                                            {s2.descricao} 
                                                        </span>
                                                        <FiArrowRight className="arrow" size={18} />
                                                    </li>
                                                ))}

                                            {/* Listagem de documentos */}
                                            {eUltimoSegmento && (semOcultacao ? documentosFiltrados : listaDocumentosLimiteAExibir).map(doc => (
                                                <li 
                                                    key={doc.id} 
                                                    onClick={() => {
                                                        handleAddDocumento(doc)
                                                    }}
                                                >
                                                    <FiFileText size={18} className="icon-file" />
                                                    <span>{doc.titulo}</span>
                                                </li>
                                            ))}

                                            {/* Itens ocultados */}
                                            {!semOcultacao && totalSegmentos > limiteItensAExibir && (
                                                <li onClick={() => { handleRemoverOcultacaoItens(index) }}>
                                                    {`${quantidadeItensOcultados} pasta${quantidadeItensOcultados > 1 ? 's' : ''} ocultada${quantidadeItensOcultados > 1 ? 's' : ''}. Clique para exibir todas.`}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                ) : null
                            })}
                        </div>
                    </ModalSelecaoDocumentoContainer>
                )}
            </Modal>
        </>
    )
}
