import styled, { css } from 'styled-components'

import Card from '../../../../components/Card'

export const Container = styled.div`
	min-height: 80vh;

	main {
		${props => props.loadedData ? css`
			padding: 35px 50px 35px 90px;
			display: grid;
			grid-template-areas: 'perfil dados';
			grid-template-columns: 250px auto;
			grid-gap: 20px;
		` : css`
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 30px 0;
		`}

		> .spinner-container {
			margin-top: 50px;
		}

		@media(max-width: 1100px) {
			grid-template-areas: 
				'perfil'
				'dados';
			grid-template-columns: 1fr;
			justify-items: center;
		}

		@media(max-width: 900px) {
			padding: 14px 10px;
		}

		@media(max-width: 400px) {
			padding: 0;
		}
	}
`

export const Profile = styled.div.attrs({
	className: 'profile-container'
})`
	h1 {
		font-size: 26px;
		font-weight: 500;
		margin: 8px 0;

		span {
			font-size: 12px;
			display: block;
		}
	}

	h2 {
		font-size: 18px;
		font-weight: 400;
		margin: 14px 0;
		display: flex;
		align-items: center;

		svg {
			margin-right: 6px;
			background-color: white;
			border-radius: 50%;
		}
	}

	h3 {
		font-size: 14px;
		display: flex;
		align-items: flex-end;
		margin: 24px 0 10px;
	}

	.badge {
		font-size: 12px;
		background-color: #334155;
		padding: 6px 8px;
		border-radius: 8px;
		width: fit-content;
		line-height: 24px;

		&.danger {
			background-color: #f44336;
			color: #FFF;
		}

		&.multiline {
			display: flex;
			flex-direction: column;
			align-items: start;

			h3 {
				font-weight: 600;
				font-size: 14px;
				margin: 0 0 6px 0;
			}
		}
	}

	@media(max-width: 1100px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

export const Avatar = styled.div`
	grid-area: foto;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	background-image: url(${props => props.image});
	background-position: center;
	background-size: cover;

	@media(max-width: 880px) {
		width: 150px;
		height: 150px;
		padding-top: unset;
	}
`

export const DadosContainer = styled.div.attrs({
	className: 'dados-container'
})`
	display: flex;
	flex-direction:column;
	// grid-template-areas:
	// 	'pessoal endereco pagamento'
	// 	'financeiro financeiro observacao'
	// 	'contatos contatos contatos'
	// 	'locais_trabalho locais_trabalho locais_trabalho'
	// 	'medias_sociais medias_sociais medias_sociais'
	// 	'arquivos arquivos arquivos';
	// grid-gap: 4px;
	// grid-template-columns: 1fr 1fr 1fr;

	.observacao {
		grid-area: observacao;
	}

	@media(max-width: 900px) {
		display: flex;
		flex-direction: column;
		margin-left: 50px;
	}

	@media(max-width: 600px) {
		margin-left: 0;
	}
`

export const SearchFormContainer = styled(Card).attrs({
	className: 'form-container'
})`
	max-width: 550px;
	width: 100%;

	form {
		display: grid;
		align-items: end;
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'title title'
			'field1 field2'
			'x button';

		h1 {
			grid-area: title;
			margin: 8px;
		}

		button {
			margin-top: 20px;
		}

		@media(max-width: 550px) {
			grid-template-columns: 1fr;
			grid-template-areas:
				'title'
				'field1'
				'field2'
				'button';
		}

		@media(max-width: 400px) {
			display: flex;
			flex-direction: column;
			justify-content: stretch;
			width: 100%;

			> * {
				width: calc(100% - 16px);
			}
		}
	}
`

export const ResultTableContainer = styled.div`
	margin-top: 14px;

	h2 {
		font-size: 18px;
		font-weight: 500;
	}

	.table-container {
		margin-top: 12px;
	}
`

export const ModalEdicaoStatusContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 140px auto;
	grid-template-areas:
		'status f1 f2'
		'btn btn btn';
	align-items: flex-start;
	min-height: 80vh;

	.button-container {
		grid-column: 1/span 3;
		width: fit-content;
		display: flex;
		justify-content: flex-end;
		grid-area: btn;
		width: 100%;
		border-top: solid 1px #fff4;
		margin-top: 24px;
		padding-top: 14px;
	}
`

export const BoxDocumentos = styled(Card).attrs({
	className: 'animated fadeInUp delay-700ms'
})`
	grid-area: arquivos;
	display: grid;
	grid-template-columns: 1fr 28px 28px;

	svg {
		font-size: 24px;
		cursor: pointer;
	}

	> div {
		grid-column: 1/span 3;
	}
`

export const SelecaoDocumentosContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.table-container {
		width: calc(100% - 16px);
	}
`
export const MenuOptions = styled.ul.attrs({
	className: 'menu-options'
})`
	padding: 2px 0;

	li:first-child {
		button {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}

	li:last-child {
		button {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}

	button {
		margin: 0;
		box-shadow: none;
		border-radius: 0;
		width: 100%;
		justify-content: space-between;
	}

`
