import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			min-height: 70vh;
		}

		@media(max-width: 715px) {
			padding: 20px;	
		}
	}
`

export const ChapaContainer = styled.div`
	> h1 {
		font-size: 18px;
		margin-bottom: 24px;

		b {
			font-size: 20px;
		}
	}

	h2 {
		font-size: 18px;
		font-weight: 500;
		margin: 0 8px;
	}

	ul {
		margin: 0 8px 14px;

		li {
			line-height: 24px;
		}
	}
`
