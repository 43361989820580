import styled from 'styled-components'

export const Container = styled.ul`
	li {
		> details {
			background-color: #5F8561;
			transition: 300ms;
			border-radius: 10px;

			summary {
				border: solid 1px #fff;
				padding: 12px 18px;
				cursor: pointer;

				.close-icon {
					display: none;
				}

				.view-icon {
					transition: 150ms;
				}

				&:hover {
					.view-icon {
						transform: scale(1.2);
					}
				}
			}

			&[open] {
				background-color: #FFF;
				border-radius: 0;
				transform: scale(1.08);

				summary, 
				.content {
					border: 0;
					color: #424242;
				}

				.content {
					border-top: solid 1px #ddd;
				}

				summary {
					.close-icon {
						display: block;
						transition: 300ms;
						margin-top: -28px;

						&:hover {
							transform: scale(1.3);
						}
					}

					.view-icon {
						display: none;
					}
				}
			}
		}

		&:last-child {
			details summary {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}

			details[open] {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
`

export const Header = styled.div`
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border: solid 1px #fff;
	padding: 12px;
	display: grid;
	grid-template-columns: 20px 366px 110px 140px 154px 144px 1fr;

	span {
		font-weight: 500;
		font-size: 16px;
	}
`
