import { useEffect, useState } from "react"

const speech = typeof window !== 'undefined' ? window.speechSynthesis : null

const allowedVoices = speech?.getVoices().filter(v => v.name.includes('Brazil'))

const defaultVoiceIndex = 3

export function useVoice() {
	const [isSpeaking, setIsSpeaking] = useState(false)
	const [voiceQueue, setVoiceQueue] = useState([])

	function handleNumbersInText(text) {
		const matches = text.match(/ \d+ /g)

		matches?.forEach(number => {
			const spaced = number.split('').join(' ')
			text = text.replaceAll(number, spaced)
		})

		return text
	}

	function speak(text, options) {
		if(!speech) {
			return
		}

		setIsSpeaking(true)

		text = handleNumbersInText(text)
		
		const ut = new SpeechSynthesisUtterance(text)

		ut.voice = allowedVoices?.length > 0 ? allowedVoices[options?.voice || defaultVoiceIndex] : undefined
		ut.rate = options?.rate || 1.2
		ut.volume = options?.volume || 1
		ut.onend = () => {
			setIsSpeaking(false)
		}

		if(!speech.speaking) {
			speech.speak(ut)
		}
	}

	function queue(text, options) {
		if(!speech) {
			return
		}

		text = handleNumbersInText(text)

		const ut = new SpeechSynthesisUtterance(text)

		ut.voice = allowedVoices?.length > 0 ? allowedVoices[options?.voice || defaultVoiceIndex] : undefined
		ut.rate = options?.rate || 1.4
		ut.volume = options?.volume || 1
		ut.onend = () => {
			setIsSpeaking(false)
		}

		setVoiceQueue(old => [...old, ut])
	}

	useEffect(() => {
		if(voiceQueue.length) {
			if(!speech) {
				return
			}

			const ut = voiceQueue[0]

			speech.speak(ut)

			setIsSpeaking(true)

			setVoiceQueue(old => old.slice(1))
		}
	}, [voiceQueue])

	return {
		speak,
		isSpeaking,
		queue,
		isQueueEmpty: !voiceQueue.length
	}
}