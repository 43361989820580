import React, {
    useState, useEffect, useCallback, useContext 
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'

import { api, authHeaders } from '../../../../services/api'

import { downloadRelatorioJasper } from '../../../../util/file'

import { Button, Select, Textbox } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'

import Card from '../../../../components/Card'

import { Container, ButtonContainer } from './styles'

import Yup from '../../../../services/yup'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

const initialValues = {
    ano: '',
    crm: ''
}

const validation = Yup.object().shape({
    ano: Yup.string().ensure().required('Informe o ano.'),
    crm: Yup.string().required('Especifique o CRM.')
})

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [anos, setAnos] = useState([])

    useEffect(() => {
        const currentYear = new Date().getFullYear()
        const listaAnos = []

        for (let i = currentYear; i >= 2019; i--) {
            listaAnos.push({
                label: i,
                value: i
            })
        }

        setAnos(listaAnos)
    }, [])

    useEffect(() => {
        loadTips(setCodigo, 'form_declaracao_anual_sindicato')
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            ano,
            crm
        } = values

        const params = {
            competencia: `${ano.value}-01-01|${ano.value}-12-01`,
            crm
        }

        const contribuicoes = await api.get('contribuicao', {
            params,
            ...authHeaders()
        })

        if (contribuicoes.length === 0) {
            toast.info('Você não possui contribuições referente ao ano informado.')
            return
        }

        const pendentes = contribuicoes.filter(c => !c.status)

        if (pendentes.length > 0) {
            toast.error('Você possui contribuições em aberto, favor quitar as mesmas, para que seja possível gerar a declaração.')
            return
        }

        const tipo_pagamento = contribuicoes[0].tipo_pagamento.id

        let nomeRelatorio

        if (tipo_pagamento === 4 || tipo_pagamento === 5 || tipo_pagamento === 7) {
            nomeRelatorio = 'RelatorioDeclaracaoSindicatoAnuidade'
        } else {
            nomeRelatorio = 'RelatorioDeclaracaoSindicalMensalidade'
        }

        try {
            const response = await api.post('relatorio', {
                relatorio: nomeRelatorio,
                parametros: {
                    ano: ano?.value,
                    crm
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })
            
            downloadRelatorioJasper(response, 'RelatorioDeclaracaoAnualSindicato')

            resetForm()
        } catch(e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Declaração anual sindicato</h1>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                        containerStyle={{ gridArea: 'form' }}
                    >
                        {({
                            isSubmitting, setFieldValue
                        }) => (
                            <Form>
                                <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                                <Textbox
                                    label="Matrícula"
                                    name="crm"
                                    containerStyle={{ gridArea: 'f1' }}
                                />

                                <Select
                                    name="ano"
                                    label="Ano"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={anos}
                                    selectionMode="range"
                                    containerStyle={{ gridArea: 'f2' }}
                                />

                                <Spinner
                                    visible={isSubmitting}
                                    label="Gerando relatório..."
                                    containerStyle={{ gridArea: 'spinner' }}
                                />

                                <ButtonContainer>
                                    <Button
                                        type="submit"
                                        loading={isSubmitting}
                                        className="transparent"
                                    >
                                        Gerar declaração
                                        <FiFile size={20} />
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
