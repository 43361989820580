import React from 'react'

import EventoProvider from '../../../../contexts/EventoContext'

import Card from '../../../../components/Card'

import ListagemEventos from './Listagem'

import { Container } from './styles'

export default function () {
    return (
        <EventoProvider>
            <Container>
                <main>
                    <Card>
                        <h1>Eventos</h1>

                        <ListagemEventos />
                    </Card>
                </main>
            </Container>
        </EventoProvider>
    )
}
