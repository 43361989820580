import React, {
    useEffect, useState, useCallback
} from 'react'
import cepPromise from 'cep-promise'
import { toast } from 'react-toastify'

import { Textbox, Select } from '../../../components/Form'

import ibge from '../../../services/ibge'

import masks from '../../../util/masks'
import estadosCidades from '../../../util/estadosCidades'

export default function ({ setFieldValue }) {
    const [UFs, setUFs] = useState([])
    const [cidades, setCidades] = useState([])
    const [loadingData, setLoadingData] = useState(false)

    async function loadUFs() {
        try {
            const { data } = await ibge.get('estados')

            setUFs(data.map(({ sigla }) => sigla).sort())
        } catch (e) {
            const siglas = estadosCidades.map(item => item.sigla)

            setUFs(siglas.sort())
        }
    }

    useEffect(() => {
        loadUFs()
    }, [])

    async function loadCidades(uf) {
        try {
            if (!uf) {
                return
            }

            const { data } = await ibge.get(`estados/${uf}/municipios`)

            setCidades(data.map(({ nome }) => nome).sort())
        } catch (e) {
            const { cidades: cidadesUf } = estadosCidades.find(item => item.sigla === uf)

            setCidades(cidadesUf.sort())
        }
    }

    async function handleUFChange(selected) {
        const { value: sigla } = selected

        await loadCidades(sigla)
    }

    const handleCEPChange = useCallback(async cep => {
        if (cep.length === 9 && !isNaN(Number(cep.replace('-', '')))) {
            setLoadingData(true)
            setFieldValue('cep', cep)

            try {
                const response = await cepPromise(cep.replace('-', ''))
                const {
                    state, city, neighborhood, street
                } = response

                await loadCidades(state)

                setFieldValue('uf', { label: state, value: state })
                setFieldValue('cidade', { label: city, value: city })
                setFieldValue('bairro', neighborhood)
                setFieldValue('logradouro', street)
                setFieldValue('numero', '')
                setFieldValue('complemento', '')
            } catch (err) {
                if (err.errors) {
                    toast.error('CEP não encontrado. Verifique o valor digitado.')

                    setFieldValue('uf', null)
                    setFieldValue('cidade', null)
                    setFieldValue('bairro', '')
                    setFieldValue('logradouro', '')
                    setFieldValue('numero', '')
                    setFieldValue('complemento', '')

                    setCidades([])
                }
            } finally {
                setLoadingData(false)
            }
        }
    }, [])

    return (
        <>
            <Textbox
                name="cep"
                label="CEP"
                onChange={e => handleCEPChange(e.target.value)}
                mask={masks.zipcode}
                containerClass="cep"
            />

            <Select
                name="uf"
                label="UF"
                mask={masks.zipcode}
                containerClass="uf"
                onChange={(selected, meta) => {
                    setFieldValue(meta.name, selected)

                    handleUFChange(selected)
                }}
                options={UFs.map(uf => ({
                    label: uf,
                    value: uf
                }))}
            />

            <Select
                name="cidade"
                label="Cidade"
                containerClass="cidade"
                onChange={(selected, meta) => {
                    setFieldValue(meta.name, selected)
                }}
                options={cidades.map(cidade => ({
                    label: cidade,
                    value: cidade
                }))}
            />

            <Textbox
                name="bairro"
                label="Bairro"
                containerClass="bairro"
                loading={loadingData}
            />

            <Textbox
                name="logradouro"
                label="Logradouro / localidade"
                containerClass="logradouro"
                loading={loadingData}
            />

            <Textbox
                name="numero"
                label="Número"
                containerClass="numero"
            />

            <Textbox
                name="complemento"
                label="Complemento"
                containerClass="complemento"
            />
        </>
    )
}
