import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px;

		h1 {
			margin-bottom: 20px;
		}

		form {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			column-gap: 12px;

			@media(max-width: 600px) {
				display: flex;
				flex-direction: column;
			}
		}

		h2 {
			font-size: 16px;
			font-weight: 600;
			border-bottom: solid 1px #fff;
			padding: 8px 0;
			margin: 14px 8px 12px;
			height: fit-content;
			grid-column: 1/span 4;
		}

		@media(max-width: 600px) {
			padding: 10px 0;
		}

		@media(min-width: 601px) {
			margin-left: 50px;
		}
	}
`

export const QuestoesContainer = styled.div`
	grid-column: 1/span 4;
	margin: 12px 8px;
	display: flex;
	flex-direction: column;

	> button {
		align-self: center;
	}
`

export const QuestaoLinha = styled.details`
	margin-bottom: 10px;
	border-radius: 6px;
	border: solid 1px #fff;
	border-bottom-width: 3px;
	height: fit-content;
	
	&[open] {
		summary {
			background-color: #FFF;
			color: #424242;

			h1 {
				color: #424242;
			}
		}
	}

	summary {
		cursor: pointer;
		padding: 0 18px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		img {
			height: 180px;
			object-fit: cover;
			border-radius: 10px;
		}

		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 50px;

			h1 {
				font-size: 16px;
				font-weight: 500;
				color: #FFF;
				margin: 0 !important;
			}

			svg {
				font-size: 22px;

				&:hover {
					color: red;
				}
			}
		}

		&:hover {
			background-color: #FFF;
			color: #424242;

			h1 {
				color: #424242;
			}
		}
	}

	> .content {
		margin: 12px;
	}

	.button-container {
		display: flex;
		width: 100%;
		justify-content: center;
		margin: 12px;
	}
`

export const AlternativaLinha = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	
	> svg {
		margin-top: 14px;
		width: 20px;
		height: 20px;
		cursor: pointer;
		margin-right: 14px;

		&:hover {
			opacity: 0.6;
		}
	}
`

export const Badge = styled.div`
	grid-column: 3/span 2;
	margin: 4px 8px;
	font-size: 14px;
	font-weight: 800;
	background-color: #ffeb3b;
	color: #424242;
	display: flex;
	align-items: center;
	padding: 12px;
	border-radius: 10px;
	width: fit-content;
`
