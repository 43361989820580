import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto auto auto auto auto auto auto auto auto 70px 70px 70px auto 70px;
		grid-template-areas: 
			'nome nascimento admissao'
			'email atividades setor'
			'telefone atividades cargo'
			'sexo perfil y'
			'observacao observacao observacao'
			'title_documentos title_documentos title_documentos'
			'z z z'
			'title_endereco title_endereco title_endereco'
			'cep uf cidade'
			'bairro logradouro numero'
			'complemento x x'
			'arquivos arquivos arquivos'
			'button button button';

		.textarea {
			grid-area: atividades;

			textarea {
				height: 100%;
				margin-bottom: 0;
			}
		}

		.title {
			width: calc(100% - 16px);
			margin: 14px 8px 0;
			
			h1 {
				font-size: 18px;
				margin: 0 0 14px;
			}
		}

		.cep {
			grid-area: cep;
		}

		.uf {
			grid-area: uf;
		}

		.cidade {
			grid-area: cidade;
		}

		.bairro {
			grid-area: bairro;
		}

		.numero {
			grid-area: numero;
		}

		.logradouro {
			grid-area: logradouro;
		}

		.complemento {
			grid-area: complemento;
		}

		.button-container {
			grid-area: button;
			display: flex;
			justify-content: flex-end;
			margin-top: 16px;
		}

		.button-container-edicao {
			grid-area: button;
			display: flex;
			justify-content: flex-end;
			margin-top: 250px;
		}

		@media(max-width: 950px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 70px 70px 70px auto auto 70px 70px auto auto auto 70px 70px 70px 70px 70px 70px, 70px;
			grid-template-areas: 
				'nome nascimento'
				'admissao email'
				'telefone sexo'
				'atividades setor'
				'atividades cargo'
				'perfil w'
				'title_documentos title_documentos'
				'rg cpf'
				'pis x'
				'cadastral curriculo'
				'contrato contrato'
				'title_endereco title_endereco'
				'cep uf'
				'cidade bairro'
				'logradouro numero'
				'complemento y'
				'button button';
		}

		@media(max-width: 700px) {
			display:block;
			grid-template-columns: 1fr;
			grid-template-rows: 70px 70px 70px 70px 70px 70px 70px 70px 70px auto auto 70px 70px 70px auto auto auto auto 70px 70px 70px 70px 70px 70px 70px 70px;
			grid-template-areas: 
				'nome'
				'nascimento'
				'sexo'
				'email'
				'telefone'
				'admissao'
				'setor'
				'cargo'
				'perfil'
				'atividades'
				
				'title_documentos'
				'rg'
				'cpf'
				'pis'
				'cadastral'
				'curriculo'
				'contrato'
				
				'title_endereco'
				'cep'
				'uf'
				'cidade'
				'bairro'
				'logradouro'
				'numero'
				'complemento'
				'button';
		}
	}
`

export const BoxDocumentos = styled.div`
	margin-top: 16px;
	grid-area: arquivos;
	display: grid;
	grid-template-columns: 1fr 28px 28px;

	h1 {
		font-size: 18px;
	}

	svg {
		font-size: 24px;
		cursor: pointer;
	}

	> div {
		grid-column: 1/span 3;
	}
`
