import React, { useContext } from 'react'
import { FiPlus, FiEye } from 'react-icons/fi'

import { DependenteContext } from '../../../../contexts/DependenteContext'

import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import { Button } from '../../../../components/Form'

import { Container } from './styles'

export default function () {
    const {
        items,
        handlePrepareEdit,
        setModoCadastro,
        handleDelete,
        setDetalhesDependente,
        associado
    } = useContext(DependenteContext)

    return (
        <>
            {associado && (
                <>
                    {items !== null ? (
                        <Container>
                            <Button className="transparent button-cadastrar" onClick={() => setModoCadastro(true)}>
                                Cadastrar novo dependente
                                <FiPlus />
                            </Button>

                            <Table
                                headers={[
                                    { name: 'name', value: 'Nome' },
                                    { name: 'data_nascimento', value: 'Data de nascimento', centered: true },
                                    { name: 'idade', value: 'Idade', centered: true },
                                    { name: 'sexo', value: 'Sexo', centered: true },
                                    { name: 'parentesco', value: 'Parentesco', centered: true },
                                    { name: 'pcd', value: 'Deficiência', centered: true }
                                ]}
                                data={items}
                                hideOffset={600}
                                emptyLabel="Nenhum dependente cadastrado"
                                handleDelete={handleDelete}
                                handlePrepareEdit={id => { handlePrepareEdit(id) }}
                                hideOnSmallHeaders={['sexo', 'idade', 'pcd', 'parentesco']}
                                hideHeadersOffset={883}
                                confirmExclusion={{
                                    attr: 'name',
                                    template: 'Deseja realmente excluir #attr#?'
                                }}
                                actions={[
                                    {
                                        action: setDetalhesDependente,
                                        name: 'Ver detalhes',
                                        icon: FiEye,
                                        iconSize: 16
                                    }
                                ]}
                            />
                        </Container>
                    ) : (
                        <Spinner />
                    )}
                </>
            )}
        </>
    )
}
