import React, { useContext, useEffect, useState } from 'react'
import {
    Form, Formik, FieldArray, Field
} from 'formik'
import { toast } from 'react-toastify'
import { FiMinus } from 'react-icons/fi'
import { ref } from 'yup'

import maskUtils from '../../../../util/masks'
import formUtils from '../../../../util/form'

import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'

import {
    Textbox, Button, Select, File, Checkbox, Textarea
} from '../../../../components/Form'
import FormEndereco from '../../../../components/FormEndereco'
import FormAdvogado from '../../../../components/FormAdvogado'

import {
    Container, ErrorContainer, ParentescoDocumentosContainer, TaxasContainer, ItensAdicionaisContainer
} from './styles'
import Modal from '../../../../components/Modal'

import useQuery from '../../../../hooks/useQuery'
import { TipContext } from '../../../../contexts/TipContext'

const baseInitialValues = {
    nome: '',
    identificador: '',
    data_inicio: '',
    data_fim: '',
    area_atuacao: { value: 1, label: 'Plano de saúde' },
    tipo_pessoa: { label: 'PJ', value: 'PJ' },
    tipo_desconto: { label: 'Fixo', value: 'fixo' },
    tipo: null,
    fone: '',
    email: '',
    descontos: [
        {
            idade_minima: null,
            idade_maxima: null,
            valor_com_desconto: '',
            valor_sem_desconto: '',
            percentual_desconto: ''
        }
    ],
    criterios: null,
    parentescos: null,
    adicionais: null,
    termo_aceite: null,
    taxa_administrativa: null,
    taxa_bancaria: null
}

const validation = Yup.object({
    area_atuacao: Yup.string().ensure().required('Selecione a área de atuação do convênio.'),
    nome: Yup.string().required('Digite o nome da PJ ou PF.'),
    tipo_pessoa: Yup.string().ensure().required('Selecione o tipo de pessoa.'),
    identificador: Yup.string(),
    data_inicio: Yup.string().date().required('Digite a data de início do contrato.'),
    data_fim: Yup.string().date().required('Digite a data de término do contrato.'),
    tipo_desconto: Yup.string().ensure().required('Selecione o tipo de desconto.'),
    descontos: Yup.array().of(
        Yup.object({
            idade_minima: Yup.number().integer().nullable(),
            idade_maxima: Yup.number().integer()
                .when('idade_minima', (attr, schema) => attr
                    ? schema.moreThan(ref('idade_minima'), 'A idade máxima deve ser maior do que a mínima.').nullable()
                    : schema.nullable()),
            valor_sem_desconto: Yup.string().money(),
            valor_com_desconto: Yup.string().money(),
            percentual_desconto: Yup.string(),
            sexo: Yup.string().ensure().nullable(),
            universidade: Yup.boolean().nullable(),
            pcd: Yup.boolean().nullable()
        })
    ).nullable(),
    tipo: Yup.string().ensure().required('Selecione o tipo.'),
    fone: Yup.string().mobileOrPhone().nullable(),
    email: Yup.string().email('E-mail inválido.').nullable()
})

export default function ({ convenio, reload, edicao }) {
    const { area: areaId } = useQuery()

    const { tips } = useContext(TipContext)

    const [initialValues] = useState(convenio || baseInitialValues)
    const [areasAtuacao, setAreasAtuacao] = useState([])
    const [grausParentesco, setGrausParentesco] = useState([])
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [showFormAddAdvogado, setShowFormAddAdvogado] = useState(false)

    async function loadAreasAtuacao() {
        const response = await api.get('convenio_area_atuacao', authHeaders())

        setAreasAtuacao(response)
    }

    async function loadGrausParentesco() {
        const response = await api.get('grau_parentesco')

        setGrausParentesco(response.map(grau => ({
            label: grau.descricao,
            value: grau.id
        })))
    }

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento', {
            params: {
                grupo: ''
            }
        })

        setTiposDocumento(response)
    }

    useEffect(() => {
        loadAreasAtuacao()

        loadGrausParentesco()

        loadTiposDocumento()
    }, [])

    async function handleAdd(values) {
        try {
            await api.post('convenio', {
                ...values,
                tipo_desconto: values.tipo_desconto
            }, authHeaders())

            toast.success('Parceiro cadastrado com sucesso.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleEdit(values) {
        try {
            await api.put(`convenio/${convenio.id}`, {
                ...values,
                tipo_desconto: values.tipo_desconto
            }, authHeaders())

            toast.success('Serviço atualizado com sucesso.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmit(values) {
        values = formUtils.extractFormValues(values)

        values.criterios = values.criterios ? values.criterios.map(criterio => criterio.value) : []

        values.parentescos = values.parentescos?.filter(p => p)
            .map(p => ({
                id: p.parentesco.value,
                documentos: p.documentos?.map(d => d.value) || []
            })) || []

        values.descontos = values.descontos.map(desconto => {
            if (values.criterios.includes('universidade')) {
                desconto.universidade = !!desconto.universidade
            }

            if (values.criterios.includes('sexo')) {
                desconto.sexo = desconto.sexo.value
            }

            if (!values.criterios.includes('idade')) {
                desconto.idade_minima = null
                desconto.idade_maxima = null
            }

            if (!values.criterios.includes('pcd')) {
                desconto.pcd = !!desconto.pcd
            }

            return desconto
        })

        values.contatos = []

        if (values.fone) {
            values.contatos.push({
                tipo: values.fone.length === 14 ? 2 : 3,
                valor: values.fone
            })
        }

        if (values.email) {
            values.contatos.push({
                tipo: 6,
                valor: values.email
            })
        }

        const fieldsEndereco = document.querySelectorAll('#form-endereco-convenio .field input')

        const endereco = [...fieldsEndereco].filter(c => c.name).reduce((result, c) => ({
            ...result,
            [c.name]: c.value
        }), {})

        values.endereco = endereco

        if (edicao) {
            handleEdit(values)
        } else {
            handleAdd(values)
        }
    }

    function handleValorSemDescontoBlur(index, valorSemDesconto, setFieldValue) {
        const inputComDesconto = document.querySelector(`.valor_com_desconto_${index}`)

        valorSemDesconto = maskUtils.unmask.money(valorSemDesconto)
        const valorComDesconto = inputComDesconto ? maskUtils.unmask.money(inputComDesconto.value) : null

        if (valorSemDesconto && valorComDesconto) {
            const percentualDesconto = 100 - ((valorComDesconto * 100) / valorSemDesconto)

            setFieldValue(`descontos.${index}.percentual_desconto`, maskUtils.maskApply.percent(percentualDesconto))
        }
    }

    function handleValorComDescontoBlur(index, valorComDesconto, setFieldValue) {
        const inputSemDesconto = document.querySelector(`.valor_sem_desconto_${index}`)

        valorComDesconto = maskUtils.unmask.money(valorComDesconto)
        const valorSemDesconto = inputSemDesconto ? maskUtils.unmask.money(inputSemDesconto.value) : null

        if (valorSemDesconto && valorComDesconto) {
            const percentualDesconto = 100 - ((valorComDesconto * 100) / valorSemDesconto)

            setFieldValue(`descontos.${index}.percentual_desconto`, maskUtils.maskApply.percent(percentualDesconto))
        }
    }

    return (
        <>
            <Container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validation}
                >
                    {({
                        setFieldValue, values, errors
                    }) => (
                        <Form id="form-convenio">
                            <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                            <Select
                                name="area_atuacao"
                                label="Área de atuação"
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                options={areasAtuacao
                                    .filter(area => areaId ? area.id === Number(areaId) : true)
                                    .map(area => ({
                                        label: area.descricao,
                                        value: area.id
                                    }))}
                            />

                            <Textbox
                                name="nome"
                                label="Nome"
                            />

                            <Select
                                name="tipo_pessoa"
                                label="Tipo de parceiro"
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                options={[
                                    { label: 'PJ', value: 'PJ' },
                                    { label: 'PF', value: 'PF' }
                                ]}
                            />

                            <Textbox
                                name="identificador"
                                label="CNPJ / CPF"
                                mask={values.tipo_pessoa?.value === 'PJ' ? maskUtils.cnpj : maskUtils.cpf}
                                readOnly={!values.tipo_pessoa}
                                placeholder={!values.tipo_pessoa ? 'Selecione primeiramente o tipo' : ''}
                            />

                            <Textbox
                                name="data_inicio"
                                label="Data de início do contrato"
                                mask={maskUtils.date}
                            />

                            <Textbox
                                name="data_fim"
                                label="Data de término do contrato"
                                mask={maskUtils.date}
                            />

                            <Select
                                name="tipo"
                                label="Tipo"
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                options={[
                                    { label: 'Serviço (precisa de aprovação)', value: 'servico' },
                                    { label: 'Parceria', value: 'parceria' }
                                ]}
                            />

                            <Textbox
                                name="fone"
                                label="Telefone para contato"
                                id="fone"
                                inputMode="numeric"
                                maxLength={11}
                                onBlur={() => {
                                    const field = document.querySelector('#fone')
                                    const { length } = values.fone?.match(/\d/g) || 0

                                    if (length === 11) {
                                        setFieldValue('fone', maskUtils.maskApply.mobile(field.value))
                                    } else if (length === 10) {
                                        setFieldValue('fone', maskUtils.maskApply.phone(field.value))
                                    }
                                }}
                                onFocus={() => {
                                    const field = document.querySelector('#fone')

                                    setFieldValue('fone', maskUtils.unmask.mobile(field.value))
                                }}
                            />

                            <Textbox
                                name="email"
                                label="E-mail"
                            />

                            <Textarea
                                label="Texto informativo para o associado"
                                name="descricao"
                            />

                            <File
                                name="logo"
                                onSuccess={fileId => setFieldValue('logo', fileId)}
                                label="Logo do parceiro (opcional)"
                                format="square"
                                previewSize={['100%', '150px']}
                                getPreloadImage={async () => {
                                    if (values.logo) {
                                        const arquivo = await api.get(`arquivo/${values.logo}`)
                                        return arquivo?.link
                                    }
                                    return null
                                }}
                            />

                            <Select
                                name="favorecido"
                                label="Quem tem direito?"
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                options={[
                                    { label: 'Associados (Adimplentes)', value: 'Associados (Adimplentes)' },
                                ]}
                                isMulti
                            />

                            <details className="endereco">
                                <summary>
                                    {`Incluir endereço ${document.querySelector('#form-convenio input[name=cep]')?.value !== '' ? '(preenchido)' : ''}`}
                                </summary>

                                <FormEndereco
                                    defaultValues={convenio?.endereco}
                                    id="form-endereco-convenio"
                                />
                            </details>

                            <details className="parentesco">
                                <summary>
                                    {`Definir parentescos aceitos ${values.parentescos?.length ? `(${values.parentescos.length} adicionados)` : ''}`}
                                </summary>

                                <ParentescoDocumentosContainer>
                                    <FieldArray
                                        name="parentescos"
                                        validateOnChange
                                        render={arrayHelpers => (
                                            <>
                                                {values.parentescos && values.parentescos.length > 0 && (
                                                    values.parentescos.map((_, index) => (
                                                        <div key={index}>
                                                            <Select
                                                                name={`parentescos.${index}.parentesco`}
                                                                label="Parentesco"
                                                                onChange={(selected, meta) => {
                                                                    setFieldValue(meta.name, selected)
                                                                }}
                                                                options={grausParentesco}
                                                            />

                                                            <Select
                                                                name={`parentescos.${index}.documentos`}
                                                                label="Documento"
                                                                onChange={(selected, meta) => {
                                                                    setFieldValue(meta.name, selected)
                                                                }}
                                                                options={tiposDocumento.map(tipo => ({
                                                                    label: tipo.descricao,
                                                                    value: tipo.id
                                                                }))}
                                                                isMulti
                                                            />

                                                            <Button
                                                                fab
                                                                tiny
                                                                className="white"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <FiMinus />
                                                            </Button>
                                                        </div>
                                                    ))
                                                )}

                                                <Button onClick={() => arrayHelpers.push('')} className="transparent">
                                                    Adicionar um parentesco
                                                </Button>
                                            </>
                                        )}
                                    />
                                </ParentescoDocumentosContainer>
                            </details>

                            <details className="taxas">
                                <summary>
                                    Taxas extras
                                </summary>

                                <TaxasContainer>
                                    <Textbox
                                        name="taxa_administrativa"
                                        label="Taxa administrativa"
                                        mask={maskUtils.money}
                                    />

                                    <Textbox
                                        name="taxa_bancaria"
                                        label="Taxa bancária"
                                        mask={maskUtils.money}
                                    />
                                </TaxasContainer>
                            </details>

                            <details className="adicionais">
                                <summary>
                                    {`Itens adicionais ${values.adicionais?.length ? `(${values.adicionais?.length} adicionados)` : ''}`}
                                </summary>

                                <ItensAdicionaisContainer>
                                    <FieldArray
                                        name="adicionais"
                                        validateOnChange
                                        render={arrayHelpers => (
                                            <>
                                                {values.adicionais && values.adicionais.length > 0 && (
                                                    values.adicionais.map((_, index) => (
                                                        <div key={index}>
                                                            <Field
                                                                type="hidden"
                                                                name={`adicionais.${index}.id`}
                                                            />

                                                            <Textbox
                                                                name={`adicionais.${index}.descricao`}
                                                                label="Nome do serviço adicional"
                                                            />

                                                            <Textbox
                                                                name={`adicionais.${index}.valor`}
                                                                label="Valor cobrado"
                                                                mask={maskUtils.money}
                                                            />

                                                            <Button
                                                                fab
                                                                tiny
                                                                className="white"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <FiMinus />
                                                            </Button>
                                                        </div>
                                                    ))
                                                )}

                                                <Button onClick={() => arrayHelpers.push('')} className="transparent">
                                                    Incluir serviço adicional
                                                </Button>
                                            </>
                                        )}
                                    />
                                </ItensAdicionaisContainer>
                            </details>

                            <Select
                                name="tipo_desconto"
                                label="Tipo de desconto"
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)

                                    setFieldValue('descontos', [
                                        {
                                            idade_minima: null,
                                            idade_maxima: null,
                                            valor_com_desconto: '',
                                            valor_sem_desconto: '',
                                            percentual_desconto: ''
                                        }
                                    ])
                                }}
                                options={[
                                    { label: 'Fixo', value: 'fixo' },
                                    { label: 'Tabelado', value: 'tabelado' }
                                ]}
                            />

                            <Select
                                name="criterios"
                                label="Critérios de valor"
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)
                                }}
                                options={[
                                    { label: 'Idade', value: 'idade' },
                                    { label: 'Sexo', value: 'sexo' },
                                    { label: 'Universidade', value: 'universidade' },
                                    { label: 'PCD', value: 'pcd' }
                                ]}
                                isMulti
                            />

                            <File
                                name="termo_aceite"
                                onSuccess={fileId => setFieldValue('termo_aceite', fileId)}
                                label="Termo de aceite do serviço"
                                format="square"
                                previewSize={['100%', '150px']}
                                error={errors.termo_aceite}
                                getPreloadImage={async () => {
                                    if (values.termo_aceite) {
                                        const arquivo = await api.get(`arquivo/${values.termo_aceite}`)
                                        return arquivo?.link
                                    }
                                    return null
                                }}
                            />

                            {values.descontos && values.descontos.length > 0 && (
                                <>
                                    <table>
                                        <thead>
                                            <tr>
                                                {values.criterios?.map(criterio => criterio.value).includes('idade') && (
                                                    <th style={{ width: 200 }}>Faixa etária</th>
                                                )}

                                                {values.criterios?.map(criterio => criterio.value).includes('sexo') && (
                                                    <th style={{ width: 100 }}>Sexo</th>
                                                )}

                                                {values.criterios?.map(criterio => criterio.value).includes('universidade') && (
                                                    <th style={{ width: 120 }}>Universidade</th>
                                                )}

                                                {values.criterios?.map(criterio => criterio.value).includes('pcd') && (
                                                    <th style={{ width: 70 }}>PCD</th>
                                                )}

                                                <th>Valor sem desconto</th>
                                                <th>Valor a pagar</th>
                                                <th>% de desconto</th>
                                                <th style={{ width: 40 }}>{' '}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <FieldArray
                                                name="descontos"
                                                validateOnChange
                                                render={arrayHelpers => (
                                                    <>
                                                        {values.descontos && values.descontos.length > 0 && (
                                                            values.descontos.map((_, index) => (
                                                                <tr key={index}>

                                                                    {values.criterios?.map(criterio => criterio.value).includes('idade') && (
                                                                        <td className="idade">
                                                                            <Textbox
                                                                                name={`descontos.${index}.idade_minima`}
                                                                                placeholder="Digite..."
                                                                                mask={maskUtils.numero}
                                                                            />
                                                                            <span>até</span>
                                                                            <Textbox
                                                                                name={`descontos.${index}.idade_maxima`}
                                                                                placeholder="Digite..."
                                                                                mask={maskUtils.numero}
                                                                            />
                                                                        </td>
                                                                    )}

                                                                    {values.criterios?.map(criterio => criterio.value).includes('sexo') && (
                                                                        <td className="sexo">
                                                                            <Select
                                                                                name={`descontos.${index}.sexo`}
                                                                                label="Sexo"
                                                                                onChange={(selected, meta) => {
                                                                                    setFieldValue(meta.name, selected)
                                                                                }}
                                                                                options={[
                                                                                    { label: 'Masculino', value: 'M' },
                                                                                    { label: 'Feminino', value: 'F' }
                                                                                ]}
                                                                            />
                                                                        </td>
                                                                    )}

                                                                    {values.criterios?.map(criterio => criterio.value).includes('universidade') && (
                                                                        <td className="universidade">
                                                                            <Checkbox
                                                                                name={`descontos.${index}.universidade`}
                                                                                label=""
                                                                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                                                            />
                                                                        </td>
                                                                    )}

                                                                    {values.criterios?.map(criterio => criterio.value).includes('pcd') && (
                                                                        <td className="pcd">
                                                                            <Checkbox
                                                                                name={`descontos.${index}.pcd`}
                                                                                label=""
                                                                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                                                            />
                                                                        </td>
                                                                    )}

                                                                    <td>
                                                                        <Textbox
                                                                            name={`descontos.${index}.valor_sem_desconto`}
                                                                            mask={maskUtils.money}
                                                                            placeholder="Digite..."
                                                                            className={`valor_sem_desconto_${index}`}
                                                                            onBlur={e => {
                                                                                handleValorSemDescontoBlur(index, e.target.value, setFieldValue)
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <Textbox
                                                                            name={`descontos.${index}.valor_com_desconto`}
                                                                            mask={maskUtils.money}
                                                                            placeholder="Digite..."
                                                                            className={`valor_com_desconto_${index}`}
                                                                            onBlur={e => {
                                                                                handleValorComDescontoBlur(index, e.target.value, setFieldValue)
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <Textbox
                                                                            name={`descontos.${index}.percentual_desconto`}
                                                                            mask={maskUtils.percent}
                                                                            placeholder="Digite..."
                                                                            className={`percentual_desconto_${index}`}
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <Button
                                                                            fab
                                                                            tiny
                                                                            className="white"
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                        >
                                                                            <FiMinus />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )}

                                                        {errors.descontos && (
                                                            <tr className="errors">
                                                                <td colSpan="5">
                                                                    <ErrorContainer>
                                                                        {Object.entries(errors.descontos?.[0] || [])
                                                                            .filter(([key]) => ['idade_maxima', 'valor_sem_desconto', 'percentual_desconto'].includes(key))
                                                                            .map(([, err]) => (
                                                                                <span key={err}>{err}</span>
                                                                            ))}
                                                                    </ErrorContainer>
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {values.tipo_desconto.value !== 'fixo' && (
                                                            <tr className="footer">
                                                                <td colSpan="5">
                                                                    <Button onClick={() => arrayHelpers.push('')} className="transparent">
                                                                        Adicionar um desconto
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </tbody>
                                    </table>

                                    <span className="badge">Se não houver restrição por idade, não preencha os campos de Faixa etária.</span>
                                </>
                            )}

                            <div className="button-container">
                                {convenio?.area_atuacao.value === 7 && (
                                    <Button className="transparent" onClick={() => { setShowFormAddAdvogado(true) }}>
                                        Cadastrar um advogado
                                    </Button>
                                )}

                                <Button className="transparent" type="submit">
                                    {edicao ? 'Salvar alterações' : 'Cadastrar'}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={showFormAddAdvogado}
                handleClose={() => { setShowFormAddAdvogado(false) }}
                title="Cadastro de advogados"
            >
                <FormAdvogado
                    convenio={convenio}
                    reload={() => {
                        setShowFormAddAdvogado(false)
                    }}
                />
            </Modal>
        </>
    )
}
