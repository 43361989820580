import masks from './masks'

export function fillWithZeros(number, totalLength = 2) {
    let mask = ''

    for (let i = 0; i < totalLength; i++) {
        mask += '0'
    }

    return (mask + number).slice(-totalLength)
}

export default {
    getRandomInt: (min = 0, max = 9999999) => Math.floor(Math.random() * (max - min + 1)) + min,

    forceLength: (number, length) => {
        let result = number.toString()

        while (result.length < length) {
            result = `0${result}`
        }

        return result
    }
}

export function arredondar(valor, numeroCasasDecimais = 2) {
    const fator = 10 ** numeroCasasDecimais

    return Math.round(valor * fator) / fator
}

export function fromCentsToCurrency(value) {
    return masks.maskApply.currency(Math.trunc((value / 100) * 100) / 100)
}
