import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Form, Formik } from 'formik'
import { differenceInDays, format, subDays } from 'date-fns'
import {
    FiAlertTriangle, FiCheck, FiCheckCircle, FiCopy,
    FiLink
} from 'react-icons/fi'

import { useLocation } from 'react-use'
import Yup from '../../services/yup'
import { Container, FormaPagamentoContainer } from './styles'
import { api, authHeaders } from '../../services/api'
import Card from '../../components/Card'
import Spinner from '../../components/Spinner'
import {
    Button, Calendar, Select, Textbox
} from '../../components/Form'
import CreditCard from '../../components/CreditCard'
import masks from '../../util/masks'
import { fromCentsToCurrency } from '../../util/number'
import arrayUtils from '../../util/array'

import { GlobalContext } from '../../contexts/GlobalContext'

const { Iugu } = window

const formasPagamentoDescricao = {
    credit_card: 'Cartão de crédito',
    bank_slip: 'Boleto bancário',
    pix: 'PIX'
}

const creditCardInitialValues = {
    expiracao: '',
    numero: '',
    titular: '',
    cvv: '',
    parcelas: null
}

const creditCardValidation = Yup.object({
    expiracao: Yup.string().required('Selecione a data de validade.').nullable(),
    numero: Yup.string().creditCard().required('Digite o número do cartão.'),
    titular: Yup.string().required('Digite o nome como consta no cartão.'),
    cvv: Yup.number().typeError('Código de segurança inválido.').required('Digite o código de segurança impresso em seu cartão.')
})

function maskCpf(cpf) {
    const parte1 = cpf.substring(0, 3)
    const parte2 = cpf.substring(9, 11)

    return `${parte1}.***.***-${parte2}`
}

function listDescontos(descontos, vencimento) {
    const output = []

    vencimento = new Date(`${vencimento} 00:00:00`)

    descontos = descontos.sort(arrayUtils.sort.comparisonFunction('percent', 'desc'))

    descontos.forEach(desconto => {
        const maxDate = subDays(vencimento, desconto.days)

        output.push({
            percent: desconto.percent,
            max_date: maxDate
        })
    })

    return output
}

function obterDescontoAtual(total, descontos, vencimento) {
    const descontosList = listDescontos(descontos, vencimento)

    let diferencasEmDias = descontosList.map(desc => ({
        ...desc,
        diferenca: differenceInDays(desc.max_date, new Date())
    }))

    diferencasEmDias = diferencasEmDias.filter(dif => dif.diferenca >= 0)

    const diferencaMinima = diferencasEmDias.reduce((result, dif) => result === null || result > dif.diferenca ? dif : result, null) || {}

    const valorDesconto = diferencaMinima ? ((diferencaMinima.percent * total) / 100) : 0

    diferencaMinima.value = valorDesconto

    diferencaMinima.total = total - valorDesconto

    return diferencaMinima
}

function obterMultaEjurosAtual(total, multa, vencimento, juros, status, data_pagamento) {
    let diasAtraso = differenceInDays((status === 'paid' || status === 'partially_paid' ? new Date(data_pagamento) : new Date()), new Date(`${vencimento} 00:00:00`))

    diasAtraso = diasAtraso < 0 ? 0 : diasAtraso

    const valorMulta = diasAtraso > 0 ? (multa * total) / 100 : 0

    const valorJuros = diasAtraso * juros

    const valorMultaJuros = valorMulta + valorJuros

    const valorTotal = total + valorMultaJuros

    return {
        valorTotal,
        valorMultaJuros
    }
}

export default function () {
    const { fatura_id } = useParams()
    const { pathname, href } = useLocation()

    const { user, clienteRobot } = useContext(GlobalContext)

    const [fatura, setFatura] = useState(null)
    const [formasPagamento, setFormasPagamento] = useState([])
    const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] = useState(null)
    const [faturaFoiPaga, setFaturaFoiPaga] = useState(false)
    const [estaGerandoFaturaPDF, setEstaGerandoFaturaPDF] = useState(false)

    const descontoAtual = fatura && fatura.early_payment_discount ? obterDescontoAtual(fatura.total_cents, fatura.early_payment_discounts, fatura.due_date) : null

    const multaJurosAtual = fatura && (new Date(`${fatura.due_date} 00:00:00`) < new Date() && fatura.late_payment_fine) ? obterMultaEjurosAtual(fatura.total_cents, fatura.late_payment_fine, fatura.due_date, fatura.per_day_interest_cents, fatura.status, fatura.paid_at) : null

    useEffect(() => {
        Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
        Iugu.setTestMode(process.env.REACT_APP_IUGU_TEST_MODE === 'true')
    }, [])

    async function loadFatura() {
        try {
            const data = await api.get(`fatura_iugu/${fatura_id}`)

            setFatura(data)
            setFaturaFoiPaga(data.status === 'paid' || data.status === 'partially_paid')

            setFormasPagamento(Array.isArray(data.payable_with) ? data.payable_with : [data.payable_with])
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmitCreditCard(values) {
        const {
            expiracao, numero, titular, cvv
        } = values

        const mes = masks.maskApply.month(expiracao.getMonth() + 1)
        const ano = expiracao.getFullYear().toString()

        const posicao = titular.indexOf(' ')
        const nome = titular.slice(0, posicao)
        const sobrenome = titular.slice(posicao + 1)

        const cartaoCredito = Iugu.CreditCard(numero, mes, ano, nome, sobrenome, cvv)

        try {
            await (new Promise((resolve, reject) => {
                Iugu.createPaymentToken(cartaoCredito, async payment => {
                    if (payment.errors) {
                        reject(payment.errors)
                    } else {
                        const token = payment.id

                        try {
                            await api.post('pagamento/cartao_credito/cobranca_direta', {
                                token,
                                invoice_id: fatura.id,
                                months: values.parcelas.value
                            })

                            resolve(token)

                            toast.success('Pagamento efetuado com sucesso!')

                            loadFatura()
                        } catch (e) {
                            reject(e)
                        }
                    }
                })
            }))
        } catch (e) {
            if (e.verification_value) {
                toast.error('O código de segurança é inválido.')
            } else if (e.expiration) {
                toast.error('Este cartão está vencido.')
            } else if (e.record_invalid) {
                toast.error('Número do cartão é inválido. Verifique e tente novamente.')
            } else if (e.adblock) {
                toast.error('Por favor desabilite seu bloqueador de anúncios e recarregue a página para conseguir efetivar a transação com seu cartão de crédito!.')
            } else {
                toast.error(e.msg || 'Ocorreu um erro na tentiva de pagamento.')

                await api.post('iugu/erro', {
                    stack: e
                }, authHeaders())
            }
        }
    }

    async function checkPagamentoFatura() {
        const data = await api.get(`fatura_iugu/${fatura_id}`)

        setFaturaFoiPaga(data.status === 'paid' || data.status === 'partially_paid')
        setFatura(data)
    }

    async function handleGerarFaturaPDF() {
        try {
            setEstaGerandoFaturaPDF(true)

            const response = await api.post(`contribuicao/fatura_pdf/${fatura_id}`, {})

            window.open(response.link, '_blank')
        } catch (e) {
            toast.error(e.msg)
        } finally {
            setEstaGerandoFaturaPDF(false)
        }
    }

    useEffect(() => {
        loadFatura()
    }, [])

    useEffect(() => {
        if (['pix', 'credit_card'].includes(formaPagamentoSelecionada)) {
            const checkInterval = setInterval(checkPagamentoFatura, 5000)

            return () => {
                clearInterval(checkInterval)
            }
        }
    }, [formaPagamentoSelecionada])

    function obterFormulario(formaPagamento) {
        switch (formaPagamento) {
            case 'pix':
                return (
                    <div className="pix-container">
                        <div className="qr-code">
                            <img src={fatura.pix.qrcode} alt="" className="qrcode-pix rounded-md" />

                            <CopyToClipboard
                                text={fatura.pix.qrcode_text}
                                onCopy={() => toast.info('Código PIX copiado!', { autoClose: 2000 })}
                            >
                                <div className="button">
                                    Copiar código de pagamento
                                </div>
                            </CopyToClipboard>
                        </div>

                        <div className="detalhes">
                            <h2 className="text-gray-800">O que é o PIX?</h2>
                            <p>O Pix é uma modalidade de transferências do Banco Central, que funcionam 24 horas por dia e possuem confirmação em tempo real.</p>
                            <p>Procure em seu aplicativo de banco ou conta digital a funcionalidade de pagamento com PIX e escaneie o QR Code ao lado ou copie o código usando para efetuar o pagamento.</p>
                        </div>
                    </div>
                )

            case 'bank_slip':
                return (
                    <div className="bank-slip-container flex flex-col gap-4">
                        <p>
                            Escaneie o código de barras com seu aplicativo bancário ou digite o código do boleto para efetuar o pagamento.
                            <b>A compensação poderá ocorrer em até 72 horas.</b>
                        </p>

                        <div className="flex flex-col gap-2 items-center bg-white pt-5 px-4 max-w-[640px] w-full self-center rounded-md animated fadeIn">
                            <div className="ring-2 ring-gray-600 rounded-lg p-4 flex gap-4 flex-col sm:flex-row sm:items-center">
                                <span className="text-md text-gray-800 font-bold sm:font-medium break-all sm:text-[1.05rem]">
                                    {fatura.bank_slip.digitable_line}
                                </span>

                                <CopyToClipboard
                                    text={fatura.bank_slip.digitable_line}
                                    onCopy={() => toast.info('Código de barras copiado!', { autoClose: 2000 })}
                                >
                                    <div className="p-2 cursor-pointer hover:bg-gray-200 rounded-full w-fit h-fit self-end sm:self-start">
                                        <FiCopy size={28} />
                                    </div>
                                </CopyToClipboard>
                            </div>

                            <img src={fatura.bank_slip.barcode} alt="código de barras" className="w-full" />
                        </div>

                        <Button
                            className="max-w-[300px] w-full self-center hover:text-white"
                            onClick={handleGerarFaturaPDF}
                            loading={estaGerandoFaturaPDF}
                        >
                            Visualizar boleto
                        </Button>

                        <p className="font-bold mt-2">Fique atento aos golpes!</p>

                        <p>
                            {'Antes de efetuar o pagamento, verifique se o código do boleto inicia com '}
                            <span className="font-bold bg-white text-gray-800 px-2 py-1 rounded-md">{clienteRobot?.inicio_numeracao_boleto}</span>
                            {` e se o beneficiário é ${clienteRobot?.name}.`}
                        </p>

                        <p>Caso encontre algo diferente, entre em contato conosco para reportar.</p>
                    </div>
                )

            case 'credit_card':
                return (
                    <div>
                        <Formik
                            initialValues={creditCardInitialValues}
                            validationSchema={creditCardValidation}
                            onSubmit={handleSubmitCreditCard}
                            enableReinitialize
                        >
                            {({ isSubmitting, values, setFieldValue }) => (
                                <Form className="flex flex-col sm:grid sm:grid-cols-2">
                                    <CreditCard
                                        name={values?.titular}
                                        number={values?.numero}
                                        expiration={values?.expiracao}
                                        cvv={values?.cvv}
                                        className="row-span-6 hidden my-0 sm:block sm:my-5"
                                    />

                                    <Textbox
                                        label="Número do cartão"
                                        name="numero"
                                        id="numero_cartao"
                                        inputMode="numeric"
                                        maxLength={16}
                                    />

                                    <Textbox
                                        label="Nome do titular"
                                        name="titular"
                                        style={{ textTransform: 'uppercase' }}
                                    />

                                    <Calendar
                                        name="expiracao"
                                        dateFormat="mm/yy"
                                        yearNavigator
                                        view="month"
                                        label="Data de validade"
                                        yearRange={`${new Date().getFullYear()}:${new Date().getFullYear() + 20}`}
                                    />

                                    <Textbox
                                        label="CVV"
                                        name="cvv"
                                        inputMode="numeric"
                                        maxLength={4}
                                    />

                                    <Select
                                        label="Parcelas"
                                        name="parcelas"
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)
                                        }}
                                        options={Array.from({ length: fatura.max_installments_value }).map((_, idx) => {
                                            const quantParcelas = idx + 1
                                            const total = descontoAtual ? descontoAtual.total : fatura.total_cents

                                            return {
                                                value: quantParcelas,
                                                label: `${quantParcelas}x de ${fromCentsToCurrency(total / quantParcelas)}`
                                            }
                                        })}
                                    />

                                    <div className="mt-2 flex justify-end">
                                        <Button type="submit" loading={isSubmitting}>
                                            Efetuar pagamento
                                            <FiCheck size={18} />
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                )

            default:
                return (
                    <div>não implementado</div>
                )
        }
    }

    if (!fatura) {
        return (
            <Container>
                <Card>
                    <Spinner label="Carregando fatura..." />
                </Card>
            </Container>
        )
    }

    const exibirBotaoNovaFatura = !pathname.includes('checkout_auth_iframe')
    const deveMostrarBotaoCopiarLinkFatura = ![2, 16, 21, 22].includes(user?.perfil.id)
    const estaAutenticado = !!user
    const urlFaturaParaCopiar = href.replace('checkout_auth_iframe/', 'checkout/').replace('checkout_auth/', 'checkout/')

    return (
        <Container>
            {deveMostrarBotaoCopiarLinkFatura && estaAutenticado && (
                <div className="flex self-center">
                    <CopyToClipboard text={urlFaturaParaCopiar} onCopy={() => toast.info('Link copiado!', { autoClose: 1100 })}>
                        <Button tiny className="white">
                            Copiar link desta fatura
                            <FiLink size={16} />
                        </Button>
                    </CopyToClipboard>
                </div>
            )}

            <div className="w-full max-w-[880px] bg-white text-gray-800 shadow-lg px-5 py-4 sm:px-10 sm:py-8 rounded-md flex flex-col gap-2 my-2 mb-8 mx-8">
                <h1 className="title text-gray-800 flex gap-4 justify-between font-semibold">
                    Cobrança de fatura

                    <img src={clienteRobot?.logo_dark} alt="" className="hidden sm:block" />
                </h1>

                <div className="flex flex-col sm:grid sm:grid-cols-[2fr_1.5fr] gap-4 mb-4">
                    <div className="left flex flex-col border-b pb-4">
                        <p className="font-bold text-[0.95rem] mb-2">Dados do Associado</p>

                        <p className="font-bold">
                            {fatura.payer_name}
                        </p>

                        <p dangerouslySetInnerHTML={{
                            __html: `CPF: <b class="font-bold">${fatura.payer_cpf_cnpj ? maskCpf(fatura.payer_cpf_cnpj) : 'Não informado'}</b>`
                        }}
                        />

                        <p
                            dangerouslySetInnerHTML={{
                                __html: `Endereço: <b class="font-bold">${fatura.payer_address_city ? `${fatura.payer_address_street}, ${fatura.payer_address_number}, ${fatura.payer_address_city}, ${fatura.payer_address_state}` : 'Não informado'}</b>`
                            }}
                        />
                    </div>

                    <div className="right flex flex-col border-b pb-4">
                        <p className="font-bold text-[0.95rem] mb-2">Dados do Cedente</p>

                        <p className="font-bold">{clienteRobot?.name}</p>

                        <p
                            dangerouslySetInnerHTML={{
                                __html: `CNPJ: <b class="font-bold">${clienteRobot?.cnpj}</b>`
                            }}
                        />

                        <p
                            dangerouslySetInnerHTML={{
                                __html: `Endereço: <b class="font-bold">${clienteRobot?.footer_content.endereco}</b>`
                            }}
                        />
                    </div>

                    <div className="col-span-2">
                        <p className="font-bold text-[0.95rem] mb-2">Dados do Pagamento</p>

                        <p
                            dangerouslySetInnerHTML={{
                                __html: `Vencimento: <b class="font-bold">${format(new Date(`${fatura.due_date} 00:00:00`), 'dd/MM/yyyy')}</b>`
                            }}
                        />

                        {fatura.late_payment_fine > 0 && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: `O pagamento após o vencimento inclui: <b class="font-bold">Multa por atraso de ${fromCentsToCurrency((fatura.late_payment_fine * fatura.total_cents) / 100)} e Mora diária de ${fromCentsToCurrency(fatura.per_day_interest_cents)}</b>`
                                }}
                            />
                        )}

                        {fatura.early_payment_discount && (
                            <>
                                <p className="font-bold mt-2">Descontos</p>

                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: listDescontos(fatura.early_payment_discounts, fatura.due_date)
                                            .reduce((result, desconto) => {
                                                return `${result}<p>${desconto.percent}% até o dia ${format(desconto.max_date, 'dd/MM/yyyy')}</p>`
                                            }, '')
                                    }}
                                    className="ml-4"
                                />
                            </>
                        )}

                        <p className="font-bold mt-2">Itens da fatura</p>

                        {fatura.items.map(item => (
                            <p
                                key={item.id}
                                className="ml-4"
                                dangerouslySetInnerHTML={{
                                    __html: `<b>${item.description} - ${fromCentsToCurrency(item.price_cents)}</b>`
                                }}

                            />
                        ))}

                        <p
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                                __html: `Subtotal: <b class="font-bold">${fromCentsToCurrency(fatura.total_cents)}</b>`
                            }}
                        />

                        <p
                            dangerouslySetInnerHTML={{
                                __html: `${descontoAtual?.percent ? `Desconto: <b class="font-bold">${descontoAtual.percent}% (${fromCentsToCurrency(descontoAtual?.value)})</b>` : ''}`
                            }}
                        />

                        <p
                            dangerouslySetInnerHTML={{
                                __html: `${multaJurosAtual ? `Multa/Juros: <b class="font-bold">${fromCentsToCurrency(multaJurosAtual.valorMultaJuros)}</b>` : ''}`
                            }}
                        />

                        <p
                            className="mt-2 pt-2 text-[0.95rem] border-t"
                            dangerouslySetInnerHTML={{
                                __html: `Total: <b class="font-bold">${fromCentsToCurrency(descontoAtual?.total || multaJurosAtual?.valorTotal || ((fatura.status === 'paid' || fatura.status === 'partially_paid') ? fatura.paid_cents : fatura.total_cents))}</b>`
                            }}
                        />
                    </div>
                </div>

                {faturaFoiPaga ? (
                    <div className="bg-emerald-500 text-gray-800 flex gap-2 items-center p-2 rounded-lg ring-1 ring-gray-800">
                        <FiCheckCircle size={22} />

                        <span
                            className="text-[0.95rem]"
                            dangerouslySetInnerHTML={{
                                __html: `<b class="font-bold">Esta fatura já está paga. Data do Pagamento: ${format(new Date(fatura.paid_at), 'dd/MM/yyyy')}</b>`
                            }}
                        />
                    </div>
                ) : (
                    <>
                        {fatura.status === 'canceled' || fatura.status === 'expired' ? (
                            <div className="bg-yellow-500 text-gray-800 flex gap-2 items-center px-2 py-4 rounded-lg ring-1 ring-gray-800">
                                <FiAlertTriangle size={22} className="w-16" />

                                <div className="flex flex-col gap-2">
                                    <span className="text-[0.95rem] font-bold">
                                        Não é possível efetuar o pagamento desta fatura. A mesma está cancelada ou expirada.
                                    </span>

                                    {exibirBotaoNovaFatura && (
                                        <a href="/?atendimento_abrir=1" target="_top" className="button white w-fit self-center">
                                            Solicite uma nova fatura aqui
                                        </a>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                {!formasPagamento.length ? (
                                    <p>Nenhuma forma de pagamento permitida para esta fatura. Consulte o sindicato para corrigir o problema.</p>
                                ) : (
                                    <>
                                        {formasPagamento.map(forma => (
                                            <FormaPagamentoContainer key={forma} onClick={() => { setFormaPagamentoSelecionada(forma) }}>
                                                <summary className="font-bold text-md">
                                                    {formasPagamentoDescricao[forma]}
                                                </summary>

                                                <div>
                                                    {obterFormulario(forma)}
                                                </div>
                                            </FormaPagamentoContainer>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </Container>
    )
}
