import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { FiFileText, FiFile } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'
import ibge from '../../../../../services/ibge'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    faculdade: '',
    aspirante: '',
    'data-cadastro': '',
    cidade: ''
}

const validation = Yup.object().shape({
    faculdade: Yup.string().ensure(),
    aspirante: Yup.string().ensure(),
    'data-cadastro': Yup.string().nullable(),
    cidade: Yup.string().ensure()
})

const nomeRelatorio = 'Relatório de Academicos'

export default function (props) {
    const [faculdades, setFaculdades] = useState([])
    const [cidades, setCidades] = useState([])
    const [isSubmittingPlanilha, setIsSubmittingPlanilha] = useState(false)

    useEffect(() => {
        async function loadFaculdades() {
            const response = await api.get('/faculdade')
            setFaculdades(response)
        }

        loadFaculdades()
    }, [])

    useEffect(() => {
        async function loadCidades() {
            const { data } = await ibge.get('estados/PA/municipios')

            setCidades(data.map(({ nome }) => nome).sort())
        }

        loadCidades()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            aspirante,
            faculdade,
            'data-cadastro': dataCadastro,
            cidade
        } = values

        const inicio = dataCadastro ? dataCadastro[0] : null
        const fim = dataCadastro ? dataCadastro[1] : null

        let filtros = ''

        if (aspirante) {
            filtros += `Aspirante: ${aspirante.value === '1' ? 'Sim' : 'Não'}, `
        }

        if (faculdade) {
            filtros += `Faculdade: ${faculdade.label}, `
        }

        if (cidade) {
            filtros += `Cidade: ${cidade.label}, `
        }

        if (inicio) {
            filtros += `Data cadastro: ${format(new Date(inicio), 'dd/MM/yyyy')} a ${fim !== null ? format(new Date(fim), 'dd/MM/yyyy') : format(new Date(inicio), 'dd/MM/yyyy')}, `
        }

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioAcademicos',
                parametros: {
                    aspirante: aspirante?.value || '',
                    faculdade_id: faculdade?.value || '',
                    cidade: cidade?.value || '',
                    filtros: filtros !== '' ? filtros.slice(0, -2) : '',
                    dataCadastroInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    dataCadastroFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : ''
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    const handleSubmitPlanilha = useCallback(async (values, resetForm) => {
        setIsSubmittingPlanilha(true)

        const {
            faculdade,
            cidade,
            'data-cadastro': dataCadastro,
            aspirante
        } = values

        const inicio = dataCadastro ? dataCadastro[0] : null
        const fim = dataCadastro ? dataCadastro[1] : null

        try {
            const response = await api.post('/relatorio/academicos_excel', {
                relatorio: 'RelatorioAcademicos',
                parametros: {
                    aspirante: aspirante?.value || null,
                    faculdade: faculdade?.value || null,
                    cidade: cidade?.value || null,
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            if (response.type === 'application/json') {
                const fr = new FileReader()

                fr.onload = function (e) {
                    const json = e.target.result
                    toast.warn(JSON.parse(json).msg)
                }
                fr.readAsText(response)
            } else {
                downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')
            }

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }

        setIsSubmittingPlanilha(false)
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm, values
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="aspirante"
                            label="Acadêmico aspirante"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Sim', value: 1 },
                                { label: 'Não', value: 2 }
                            ]}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Calendar
                            name="data-cadastro"
                            label="Data do Cadastro"
                            maxDate={new Date()}
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f3' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="faculdade"
                            label="Faculdade"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={faculdades.map(fac => ({
                                label: fac.descricao,
                                value: fac.id
                            }))}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Select
                            name="cidade"
                            label="Cidade"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={cidades.map(cidade => ({
                                label: cidade,
                                value: cidade
                            }))}
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => handleSubmitPlanilha(values, resetForm)}
                                loading={isSubmittingPlanilha}
                                className="white"
                            >
                                Gerar planilha
                                <FiFileText size={20} />
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
