import styled, { css } from 'styled-components'

import MaskedInput from 'react-text-mask'

export const InputContainer = styled.div.attrs({
    className: 'field textbox'
})`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 5px 8px;
	width: calc(100% - 16px);

	input {
		box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
	}

	> svg {
		position: absolute;
		right: 14px;
		top: 32px;
		width: 18px;
		opacity: 0.7;
		color: rgba(0, 0, 0, 0.3) !important;

		&:hover {
			cursor: help;

			& + .tip-container {
				display: grid;
			}
		}
	}

	.spinner {
		> svg {
			position: absolute;
			right: 14px;
			top: 30px;
			width: 18px;
			opacity: 0.6;
			fill: #424242;

			&:hover {
				cursor: help;

				& + .tip-container {
					display: grid;
				}
			}
		}
	}

	input:focus + svg {
		opacity: 1;
	}

	input:disabled {
		opacity: 0.4;
	}

	input + span.error,
	svg + span.error {
		width: 100%;
		margin-top: 6px;
	}

	.spinner svg {
		margin-top: -8px;
	}

	.label {
		font-size: 11px;
		margin: 4px 4px 2px;
		text-align: left;
	}

	.hint {
		font-size: 10px;
		margin: 3px 2px 0;
	}

	${props => props.error && css`
		padding-left: 8px;
		border-left: solid 4px #ffeb3b;
	`};
`

export const Textbox = styled.input`
	background-color: rgba(255, 255, 255, 0.8);
	border: 0;
	border-radius: 4px;
	height: 42px;
	padding: 0 50px 0 15px;
	color: #424242;
	width: 100%;

	&::placeholder {
		color: rgba(0, 0, 0, 0.2);
		font-weight: 400;
	}

	&:focus {
		border: dashed 1px rgba(0, 0, 0, 0.2);
		background-color: rgba(255, 255, 255, 0.9);
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type=number] {
		-moz-appearance: textfield;
	}

	&.transparent {
		background-color: transparent;
		border-bottom: solid 1px #fff;
		border-radius: 0;
		box-shadow: unset;
		color: #fff;
		padding: 4px;
		height: 28px;
		margin-bottom: 12px;

		&:focus {
			border: 0;
			border-bottom: solid 2px #fff;
		}
	}
`

export const MaskedTextbox = styled(MaskedInput)`
	background: rgba(255, 255, 255, 0.8);
	border: 0;
	border-radius: 4px;
	height: 42px;
	padding: 0 40px 0 15px;
	color: #424242;
	width: 100%;

	&::placeholder {
		color: rgba(0, 0, 0, 0.2);
		font-weight: 400;
	}

	&:focus {
		border: dashed 1px rgba(0, 0, 0, 0.2);
		background: rgba(255, 255, 255, 0.9);
	}
`
