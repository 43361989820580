import React, { useContext, useEffect, useState } from 'react'
import {
    Tab, TabList, TabPanel, Tabs 
} from 'react-tabs'

import Card from '../../../../components/Card'

import ExtratoProvider from '../../../../contexts/ExtratoContext'

import Cadastro from './Cadastro'
import Listagem from './Listagem'
import ListagemPendentes from './ListagemPendentes'
import Edicao from './Edicao'

import { Container } from './styles'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

export default function () {
    const { user } = useContext(GlobalContext)
    const { setCodigo } = useContext(TipContext)

    const [indiceTabAtiva, setIndiceTabAtiva] = useState(0)
    const [editable, setEditable] = useState(false)

    useEffect(() => {
        if(user) {
            setEditable(user.perfil.id !== 17)
        }
    }, [user])

    useEffect(() => {
        switch(indiceTabAtiva) {
            case 0:
                loadTips(setCodigo, 'form_carga_extratos_bancarios')
                break
            default: 
                loadTips(setCodigo, 'form_pesquisa_extratos_bancarios')
                break
        }
    }, [indiceTabAtiva])

    return (
        <ExtratoProvider>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Carga de extratos bancários</h1>

                        <Tabs
                            selectedIndex={indiceTabAtiva}
                            onSelect={indiceTab => setIndiceTabAtiva(indiceTab)}
                        >
                            <TabList>
                                {editable && (
                                    <Tab>Carga de arquivos bancários</Tab>
                                )}

                                <Tab>Extratos</Tab>
                            </TabList>

                            {editable && (
                                <TabPanel>
                                    <Cadastro />
                        
                                    <ListagemPendentes />
                                </TabPanel>
                            )}

                            <TabPanel>
                                <Listagem />
                            </TabPanel>
                        </Tabs>

                    </Card>
                </main>
            </Container>

            <Edicao />
        </ExtratoProvider>
    )
}
