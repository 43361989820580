import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'spinner-container'
})`
	flex-direction: ${props => props.display} !important;
	justify-content: center;
	align-items: center;
	display: ${props => props.visible ? 'flex' : 'none'};
	height: fit-content;
	margin-top: 30px;
	cursor: wait;
	column-gap: 12px;

	.spinner {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: wait;
	}

	label {
		margin-top: 6px;
		font-size: 18px;
	}
`
