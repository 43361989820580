import React, { useState, useEffect } from 'react'
import { Bar } from '@reactchartjs/react-chart.js'

import { Container } from './styles'

const barColors = ['#FFF9', '#448aff', '#aeea00', '#ffee58']

export default function ({
    width = 600, height = 400, data, ...props 
}) {
    const [dataset, setDataset] = useState(null)

    useEffect(() => {
        const datasetItems = []

        if(data) {
            for(const indicativo of Object.keys(Object.values(data)[0])) {
                datasetItems.push({
                    data: Object.values(data).map(d => d[indicativo].value),
                    label: Object.values(data).map(d => d[indicativo].label)[0],
                    backgroundColor: barColors[Object.values(datasetItems).length]
                })
            }

            setDataset(datasetItems)
        }
    }, [data])

    return (
        <Container canvas={(width && height) ? { width, height } : null} {...props}>
            {dataset && (
                <Bar 
                    data={{
                        labels: Object.keys(data),
                        datasets: dataset
                    }} 
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        legend: {
                            labels: {
                                fontColor: '#FFF'
                            }
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    fontColor: '#FFF',
                                    stepSize: 500,
                                    beginAtZero: true
                                }
                            }],
                            xAxes: [{
                                ticks: {
                                    fontColor: '#FFF',
                                    stepSize: 1
                                }
                            }]
                        }
                    }}
                />
            )}
        </Container>
    )
}
