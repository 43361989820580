import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			min-height: 64vh;

			details {
				margin-top: 24px;

				&[open] {
					summary {
						background-color: #fff;
						padding: 18px 12px;
						color: #424242;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				}

				summary {
					font-size: 17px;
					font-weight: 500;
					cursor: pointer;
					transition: 200ms;
					padding: 18px 12px;
					border-radius: 10px;
					border: solid 1px #fff;
					border-bottom-width: 3px;

					&:hover {
						opacity: 0.9;
						background-color: #fff;
						color: #424242;
					}
				}

				.content {
					padding: 14px 14px 0;
					border: solid 1px #fff;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					padding: 12px 24px 28px;
				}

				ul {
					li {
						display: grid;
						grid-template-columns: 1fr 1fr;
						align-items: center;
						line-height: 26px;
						border-bottom: dashed 1px #fff7;
						padding: 0;

						form {
							display: grid;
							grid-template-columns: 7fr 3fr;
							align-items: center;
						}

						.textbox {
							margin-top: 0;
						}
					}
				}

				&#sindicato {
					ul {
						li {
							grid-template-columns: 1fr 2fr;

							form {
								grid-template-columns: 1fr 1fr 1fr 1fr;

								.textbox {
									padding-bottom: 4px;

									span {
										margin: 0;
									}
								}

								button {
									align-self: end;
									margin: 10px 8px;
								}
							}
						}
					}
				}
			}
		}
	}
`
