import React, { useContext, useState, useEffect } from 'react'
import { FiPlus, FiSave } from 'react-icons/fi'
import { Formik, Form } from 'formik'

import { api } from '../../../../../services/api'
import Yup from '../../../../../services/yup'

import { AcervoContext } from '../index'

import {
    File, Textbox, Button, Select, Calendar, Checkbox, Textarea, Chips
} from '../../../../../components/Form'

import { Container, ButtonContainer } from './styles'

const validation = Yup.object({
    titulo: Yup.string().required('Defina um título.'),
    link: Yup.string().site(),
    thumbnail: Yup.number().integer().nullable(),
    arquivo: Yup.number().integer().nullable(),
    data_publicacao: Yup.string().nullable(),
    exibir_espaco_medico: Yup.boolean(),
    resumo: Yup.string().nullable(),
    palavras_chave: Yup.string().nullable(),
    publico: Yup.boolean()
})

export default function ({ niveis }) {
    const {
        initialValues, setInitialValues, handleAdd, editId, handleEdit, segmentos, segmentosSelecionados, setSegmentosSelecionados
    } = useContext(AcervoContext)

    const [palavrasChave, setPalavrasChave] = useState([])

    function obterSegmentosFilhos(nivel) {
        let segmentoAberto = segmentos

        for (let i = 0; i < nivel; i++) {
            const selecionado = segmentosSelecionados[i]

            if (i === 0) {
                segmentoAberto = segmentos.find(s => s.id === selecionado).segmentos_filhos
            } else {
                segmentoAberto = segmentoAberto.find(s => s.id === selecionado).segmentos_filhos
            }
        }

        return segmentoAberto?.filter(s => !s.oculto_no_cadastro)
    }

    useEffect(() => {
        setPalavrasChave(initialValues.palavras_chave || [])
    }, [initialValues])

    useEffect(() => {
        if (segmentos.length && niveis) {
            setSegmentosSelecionados(niveis)

            const niveisValues = {}
            let pastaIteracao = segmentos

            niveis.forEach((n, idx) => {
                if (idx === 0) {
                    pastaIteracao = segmentos.find(s => s.id === n)
                } else {
                    pastaIteracao = pastaIteracao.segmentos_filhos?.find(s => s.id === n)
                }

                niveisValues[`nivel_${idx + 1}`] = {
                    value: pastaIteracao.id,
                    label: pastaIteracao.descricao
                }
            })

            setInitialValues(old => ({
                ...old,
                ...niveisValues
            }))
        }
    }, [segmentos, niveis])

    return (
        <Container>
            <Formik
                onSubmit={async (values, { resetForm }) => {
                    if (!editId) {
                        await handleAdd({
                            ...values,
                            palavras_chave: palavrasChave
                        })
                    } else {
                        await handleEdit({
                            ...values,
                            palavras_chave: palavrasChave
                        })
                    }

                    setPalavrasChave([])
                    setSegmentosSelecionados([])

                    resetForm()
                }}
                initialValues={initialValues}
                validationSchema={validation}
                enableReinitialize
            >
                {({
                    setFieldValue, resetForm, errors, values
                }) => (
                    <Form>
                        <File
                            name="arquivo"
                            onSuccess={fileId => { setFieldValue('arquivo', fileId) }}
                            label="Arquivo com conteúdo (opcional)"
                            format="square"
                            previewSize={['100%', '188px']}
                            error={errors.anexo}
                            style={{ gridArea: 'f1' }}
                            getPreloadImage={async () => {
                                if (values.arquivo) {
                                    const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                    return arquivo?.link
                                }
                            }}
                            acceptedTypes={['*']}
                            tip="Tamanho máximo: 30MB. São permitidos documentos de texto, planilhas, slides, vídeos e áudios."
                        />

                        <div className="classificacao-container" style={{ gridArea: 'f2' }}>
                            <p>Classifique o documento</p>

                            <Select
                                name="nivel_1"
                                label=""
                                placeholder="Nível 1"
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)
                                    setFieldValue('nivel_2', null)

                                    setSegmentosSelecionados([selected.value])
                                }}
                                options={segmentos?.filter(segmento => !segmento.oculto_no_cadastro).map(segmento => ({
                                    label: segmento.descricao,
                                    value: segmento.id
                                })) || []}
                            />

                            {segmentos.length > 0 && segmentosSelecionados.map((seg, index) => {
                                if (!obterSegmentosFilhos(index + 1)?.length) {
                                    return null
                                }

                                return (
                                    <Select
                                        key={seg}
                                        name={`nivel_${index + 2}`}
                                        label=""
                                        placeholder={`Nível ${index + 2}`}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setSegmentosSelecionados(old => [...old.slice(0, index + 1), selected.value])
                                        }}
                                        options={obterSegmentosFilhos(index + 1)?.map(segmento => ({
                                            label: segmento.descricao,
                                            value: segmento.id
                                        })) || []}
                                    />
                                )
                            })}
                        </div>

                        <Textbox
                            name="titulo"
                            label="Título"
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Textbox
                            name="link"
                            label="Link (opcional)"
                            containerStyle={{ gridArea: 'f6' }}
                        />

                        {values.setor?.value === 1 ? (
                            <File
                                name="thumbnail"
                                onSuccess={fileId => {
                                    setFieldValue('thumbnail', fileId)
                                }}
                                label="Thumbnail (opcional)"
                                format="square"
                                previewSize={['100%', '188px']}
                                error={errors.anexo}
                                style={{ gridArea: 'f7' }}
                                getPreloadImage={async () => {
                                    if (values.thumbnail) {
                                        const arquivo = await api.get(`arquivo/${values.thumbnail}`)
                                        return arquivo?.link
                                    }
                                }}
                            />
                        ) : (
                            <div style={{ gridArea: 'f7' }} />
                        )}

                        <Calendar
                            name="data_publicacao"
                            label={values.veiculo ? `Data do(a) ${values.veiculo?.label.toLowerCase()}` : 'Data'}
                            yearNavigator
                            yearRange={`${new Date().getFullYear() - 20}:${new Date().getFullYear()}`}
                            containerStyle={{ gridArea: 'f8' }}
                        />

                        <Checkbox
                            name="exibir_espaco_medico"
                            label="Exibir no Espaço do Associado"
                            handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            containerStyle={{ gridArea: 'f9' }}
                        />

                        <Checkbox
                            name="publico"
                            label="Conteúdo público"
                            handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            containerStyle={{ gridArea: 'f12' }}
                        />

                        <Chips
                            name="palavras_chave"
                            id="palavras_chave"
                            label="Palavras-chave (tecle Enter para incluir cada palavra)"
                            withoutForm
                            value={palavrasChave}
                            placeholder="Pressione Enter para cada palavra-chave incluída"
                            onChange={e => {
                                setPalavrasChave(e.value)
                            }}
                            containerStyle={{ gridArea: 'f10' }}
                        />

                        <Textarea
                            name="resumo"
                            label="Resumo"
                            containerStyle={{ gridArea: 'f11' }}
                        />

                        <ButtonContainer>
                            <Button onClick={resetForm} className="transparent">
                                Limpar
                            </Button>

                            <Button type="submit" className="white">
                                {editId ? (
                                    <>
                                        Salvar alterações
                                        <FiSave />
                                    </>
                                ) : (
                                    <>
                                        Cadastrar
                                        <FiPlus />
                                    </>
                                )}
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
