import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'form-edicao-instituicao'
})`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas:
		'tips tips tips'
		'f1 f2 f3'
		'f4 f5 f6'
		'f7 f8 f9'
		'f10 f11 f12'
		'f13 x x'
		'line line line'
		'org org org'
		'button button button';

	hr {
		grid-area: line;
		margin: 12px 8px;
		width: calc(100% - 16px);
		border: solid #82BE86 0.5px;
	}

	.organograma {
		grid-area: org;
		width: calc(100% - 16px);

		> h1 {
			font-size: 16px;
			font-weght: 600;
		}

		.organograma-button-container {
			display: flex;
			justify-content: center;
		}
	}

	.button-container {
		grid-area: button;
		display: flex;
		justify-content: end;
	}

	@media(max-width: 700px) {
		display: flex;
		flex-direction: column;
	}
`

export const OrganogramaLinhaContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr) 40px;
	align-items: end;

	+ .button-container {
		margin-top: 8px;
		display: flex;
		justify-content: center;
	}
`
