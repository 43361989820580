import styled from 'styled-components'
import { darken } from 'polished'

export const Container = styled.div`
	.menu-trigger:focus {
		background-color: transparent;

		svg {
			color: #fff !important;
		}
	}

	@media (min-width: 601px) {
		.menu-trigger {
			display: none;
		}
	}
`

export const Menu = styled.div.attrs({
    className: 'navbar'
})`
	background: #334155;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	display: flex;
	flex-direction: column;
	width: 3rem;
	padding: 0 1px;
	overflow-x: hidden;
	overflow-y: auto;
	transition: all 0.4s;
	position: fixed;
	height: ${props => props.fullHeight ? '100vh' : 'calc(100vh - 60px)'};
	top: ${props => props.fullHeight ? 0 : 67}px;
	z-index: 1;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;

	@media(max-width: 600px) {
		top: 67px;
		width: ${props => props.visivel ? 'calc(100vw - 14px)' : '0px'};
		height: calc(100vh - 65px);
	}

	&:hover {
		width: 12rem;
		padding: 0 1px;

		a {
			span {
				display: block;
			}

			svg {
				margin-right: 8px;
			}
		}

		@media(max-width: 600px) {
			width: calc(100vw - 14px);
		}
	}

	a {
		padding: 10px 12px;
		transition: all 0.2s;
		color: #FFF;
		display: flex;
		align-items: center;

		span {
			font-size: 12px;
			white-space: nowrap;
			display: none;
		}

		svg {
			width: 18px;
		}

		&:last-child {
			margin-bottom: 30px;
		}

		&:hover {
			background: ${darken(0.04, '#334155')};
			color: #FFF;
		}
	}

	@media(max-width: 600px) {
		a span {
			display: block;
		}

		svg {
			margin: 0 8px;
		}
	}

	@media(max-width: 915px) {
		padding: 0;
	}

	@media(max-width: 800px) {
		span {
			display: none;
		}
	}
`
