import React, { useContext, useEffect, useRef } from 'react'
import { useField } from 'formik'
import ReactTooltip from 'react-tooltip'
import { FiHelpCircle } from 'react-icons/fi'
import { FaQuestionCircle } from 'react-icons/fa'

import Spinner from '../../Spinner'
import { TipContext } from '../../../contexts/TipContext'
import { TipContainer } from '../styles'
import {
    InputContainer, Textbox, MaskedTextbox
} from './styles'

export default function ({
    label,
    mask,
    icon,
    withoutForm,
    loading,
    containerClass = '',
    containerStyle = {},
    className = '',
    style = {},
    tooltip,
    hint,
    initialValue,
    id,
    searchTooltip,
    disableSubmitWithEnter = true,
    ...props
}) {
    let field
    let meta

    const Icon = icon?.source

    const input = useRef()

    if (!withoutForm) {
        [field, meta] = useField(props)
    }

    const { tips, handleSpeak } = useContext(TipContext)

    const tip = tips.campos?.find(item => item.campo === id || props.name === item.campo || item.campo === field?.name)
    
    const tipText = tip?.texto
    const tipAvatarText = tip?.texto_avatar

    useEffect(() => {
        if (props.focused) {
            input.current.focus()
        }
    })

    if (withoutForm) {
        return (
            <InputContainer className={containerClass} style={containerStyle}>
                <span className="label">{label}</span>

                {!mask ? (
                    <Textbox
                        {...field}
                        {...props}
                        id={id || props.name}
                        data-multiline
                        defaultValue={initialValue}
                        data-tip={tooltip}
                        ref={input}
                        style={{ ...style, paddingRight: icon || tipText ? 42 : 0 }}
                        className={className}
                        onKeyPress={e => {
                            if (disableSubmitWithEnter && e.key === 'Enter') {
                                e.preventDefault()
                            }
                        }}
                    />
                ) : (
                    <MaskedTextbox
                        {...field}
                        {...props}
                        id={id || props.name}
                        data-multiline
                        defaultValue={initialValue}
                        data-tip={tooltip}
                        ref={input}
                        style={{ ...style, paddingRight: icon || tipText ? 42 : 0 }}
                        mask={mask}
                        className={className}
                        onKeyPress={e => {
                            if (disableSubmitWithEnter && e.key === 'Enter') {
                                e.preventDefault()
                            }
                        }}
                    />
                )}

                {loading ? (
                    <Spinner label="" containerStyle={{ margin: 'auto' }} />
                ) : icon && (
                    <Icon size={icon.size || 14} />
                )}

                {hint && (
                    <span className="hint">{hint}</span>
                )}

                {(tipText || searchTooltip) && !loading ? (
                    <>
                        {tipText ? (
                            <div>
                                <FiHelpCircle size={16} className="mt-1" />

                                <TipContainer>
                                    <FaQuestionCircle size={13} />
                                    <span>{tipText}</span>
                                </TipContainer>
                            </div>
                        ) : (
                            <TipContainer>
                                <Icon {...icon} size={icon.size || 14} />
                                <span>{searchTooltip}</span>
                            </TipContainer>
                        )}
                    </>
                ) : null}
            </InputContainer>
        )
    }

    return (
        <InputContainer
            className={`${containerClass} ${field.name}`}
            style={containerStyle}
            error={meta.touched && meta.error}
        >
            <span className="label">{label}</span>

            {!mask ? (
                <>
                    <Textbox
                        {...field}
                        {...props}
                        id={id || field.name}
                        data-multiline
                        defaultValue={initialValue}
                        data-tip={tooltip}
                        ref={input}
                        style={{ ...style, paddingRight: icon || tipText ? 42 : 0 }}
                        className={className}
                        onKeyPress={e => {
                            if (disableSubmitWithEnter && e.key === 'Enter') {
                                e.preventDefault()
                            }
                        }}
                    />

                    {loading ? (
                        <Spinner label="" containerStyle={{ margin: 'auto' }} />
                    ) : icon && (
                        <Icon size={icon.size || 14} />
                    )}
                </>
            ) : (
                <>
                    <MaskedTextbox
                        {...field}
                        {...props}
                        id={id || field.name}
                        data-multiline
                        defaultValue={initialValue}
                        data-tip={tooltip}
                        ref={input}
                        style={{ ...style, paddingRight: icon || tipText ? 42 : 0 }}
                        mask={mask}
                        className={className}
                        onKeyPress={e => {
                            if (disableSubmitWithEnter && e.key === 'Enter') {
                                e.preventDefault()
                            }
                        }}
                    />

                    {loading ? (
                        <Spinner label="" containerStyle={{ margin: 'auto' }} />
                    ) : icon && (
                        <Icon {...icon} size={icon.size || 14} />
                    )}
                </>
            )}

            {hint && (
                <span className="hint">{hint}</span>
            )}

            {tipText && !loading ? (
                <>
                    <FiHelpCircle size={16} className="mt-1" onMouseEnter={() => handleSpeak(tipAvatarText || tipText)} />

                    <TipContainer>
                        <FaQuestionCircle size={13} />
                        <span>{tipText}</span>
                    </TipContainer>
                </>
            ) : null}

            {meta.touched && meta.error
                ? (
                    <span className="error mt-1">
                        {meta.error}
                    </span>
                )
                : null}

            <ReactTooltip
                place="top"
                effect="solid"
                type="dark"
            />
        </InputContainer>
    )
}
