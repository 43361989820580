import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { lastDayOfMonth, format } from 'date-fns'
import { FaEdit, FaEye, FaFilePdf } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { FiSearch } from 'react-icons/fi'

import { firstDayOfMonth } from '../../../../util/date'
import masks from '../../../../util/masks'
import formUtils from '../../../../util/form'
import { forceDownloadFileFromURL } from '../../../../util/file'

import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import { Button, Textbox, Select } from '../../../../components/Form'
import Modal from '../../../../components/Modal'

import { api, authHeaders } from '../../../../services/api'
import { loadTips } from '../../../../util/tip'

import { VisitaContext } from '../../../../contexts/VisitaContext'
import { TipContext } from '../../../../contexts/TipContext'
import { GlobalContext } from '../../../../contexts/GlobalContext'

import { Container, FiltrosContainer, ModalDetalhesContainer } from './styles'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const { user } = useContext(GlobalContext)
    const {
        visitas, setVisitaEdit, handleDelete, motivos, handleSearch
    } = useContext(VisitaContext)

    const [gerandoRelatorio, setGerandoRelatorio] = useState(false)
    const [showDetalhes, setShowDetalhes] = useState(null)

    async function handleGerarRelatorio(values) {
        try {
            setGerandoRelatorio(true)

            values = formUtils.extractFormValues(values)

            const response = await api.post('visita/relatorio', values, authHeaders())

            forceDownloadFileFromURL(response.link)
        } catch (e) {
            toast.error(e.msg)
        }

        setGerandoRelatorio(false)
    }

    useEffect(() => {
        loadTips(setCodigo, 'form_relacionamento_buscar_visita')
    }, [])

    if (!visitas) {
        return <Spinner />
    }

    return (
        <>
            <Container>
                <p>{tips?.formulario}</p>
                <FiltrosContainer>
                    <Formik
                        onSubmit={handleSearch}
                        initialValues={{
                            inicio: format(firstDayOfMonth(new Date()), 'dd/MM/yyyy'),
                            fim: format(lastDayOfMonth(new Date()), 'dd/MM/yyyy'),
                            motivo: null,
                            crm: ''
                        }}
                    >
                        {({ setFieldValue, values }) => (
                            <Form>
                                <Textbox
                                    label="Data inicial"
                                    name="inicio"
                                    mask={masks.date}
                                />

                                <Textbox
                                    label="Data final"
                                    name="fim"
                                    mask={masks.date}
                                />

                                <Textbox
                                    label="Matrícula"
                                    name="crm"
                                    mask={masks.numero}
                                />

                                <Select
                                    name="motivo"
                                    label="Motivo"
                                    options={motivos?.map(motivo => ({
                                        label: motivo.descricao,
                                        value: motivo.id
                                    })) || []}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    placeholder="Todos"
                                />

                                <Button className="white" type="submit">
                                    Buscar
                                    <FiSearch size={16} />
                                </Button>

                                <Button className="transparent" onClick={() => { handleGerarRelatorio(values) }} loading={gerandoRelatorio}>
                                    Gerar relatório
                                    <FaFilePdf size={16} />
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </FiltrosContainer>

                <Table
                    headers={[
                        { name: 'crm', value: 'CRM', centered: true },
                        { name: 'nome', value: 'Nome' },
                        { name: 'data', value: 'Data', centered: true },
                        { name: 'entrada', value: 'Início', centered: true },
                        { name: 'saida', value: 'Fim', centered: true },
                        { name: 'motivo_descricao', value: 'Motivo', centered: true },
                        { name: 'atendente_nome', value: 'Atendente', centered: true },
                        { name: 'canal', value: 'Canal', centered: true }
                    ]}
                    data={visitas}
                    filterable
                    handleDelete={handleDelete}
                    confirmExclusion={{
                        template: 'Deseja realmente excluir o registro de contato de #attr#?',
                        attr: 'nome'
                    }}
                    actions={[
                        {
                            action: item => { setVisitaEdit(visitas.find(visita => visita.id === item.id)) },
                            name: 'Editar',
                            icon: FaEdit,
                            iconSize: 15,
                            checkDisabled: item => item.atendente.id !== user.pessoa.id
                        },
                        {
                            action: setShowDetalhes,
                            name: 'Detalhes',
                            icon: FaEye,
                            iconSize: 15
                        }
                    ]}
                />
            </Container>

            <Modal
                isOpen={!!showDetalhes}
                handleClose={() => { setShowDetalhes(null) }}
                title="Detalhes do contato"
            >
                <ModalDetalhesContainer>
                    <p dangerouslySetInnerHTML={{ __html: `Pessoa atendida: <b>${showDetalhes?.pessoa.nome}${showDetalhes?.crm ? ` (${showDetalhes?.crm})` : ''}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Atendente: <b>${showDetalhes?.atendente.nome}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Motivo: <b>${showDetalhes?.motivo.descricao}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Início do atendimento: <b>${showDetalhes?.data} ${showDetalhes?.entrada}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Fim do atendimento: <b>${showDetalhes?.data} ${showDetalhes?.saida || 'não concluído'}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Detalhes: <b>${showDetalhes?.detalhes}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Avaliação do atendimento: <b>${showDetalhes?.avaliacao}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Feedback do atendimento: <b>${showDetalhes?.feedback}</b>` }} />
                </ModalDetalhesContainer>
            </Modal>
        </>
    )
}
