import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			display: flex;
			flex-direction: column;
			min-height: 70vh;

			> h1 {
				margin: 0 8px 20px;
				grid-area: title;
			}
		}
	}
`

export const DetalhesContainer = styled.div`
	margin: 18px 8px 5px;
	display: grid;
	gap: 0 16px;
	grid-template-columns: 2fr 1fr;
	margin-bottom: 24px;

	.badge {
		grid-column: 1/span 2;
		padding: 8px;
		background-color: #ffeb3b;
		color: #424242;
		border-radius: 8px;
		justify-self: end;
		width: fit-content;
		margin: 0 8px 16px;
	}

	p {
		grid-column: 1;
		line-height: 30px;
	}

	.botao-ativacao-container {
		width: 100%;
		grid-column: 2;
		display: flex;
		justify-content: end;
	}

	.table-beneficiarios {
		grid-column: 1/span 2;
	}

	.table-adicionais {
		margin-bottom: 36px;
	}

	.table-logs {
		grid-column: 1/span 2;
		margin: 0 0 24px;
	}

	@media(max-width: 900px) {
		display: flex;
		flex-direction: column;

		.table-adicionais {
			margin-bottom: 0;
		}

		.table-taxas {
			margin-bottom: 36px;
		}
	}
`

export const FiltroContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: end;

	button {
		width: fit-content;
	}
`

export const FormCancelamentoContainer = styled.div`
	display: flex;
	flex-direction: column;

	> p {
		margin : 12px 8px 8px;
	}

	form {
		display: flex;
		align-items: end;

		.select {
			width: 390px;
		}

		button {
			width: 200px;
		}
	}
`

export const ModalReativacaoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 8px;

	> p {
		margin-top: 18px;
	}

	.table-container {
		margin-bottom: 18px;
	}

	button {
		align-self: end;
		width: fit-content;
	}
`
