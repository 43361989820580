import { useState, createContext, useEffect } from 'react'

import { useVoice } from '../hooks/useVoice'
import avatarAnimated from '../assets/images/animated.mp4'
import { api } from '../services/api'

export const AvatarContext = createContext()

export function AvatarProvider({ children }) {
	const [isEnabled, setIsEnabled] = useState(false)
	const [isVisible, setIsVisible] = useState(false)

	const { speak } = useVoice()

	function handleSpeak(speech, options) {
		if(!isEnabled) {
			return
		}

        if (speech) {
            speak(speech, options)

            setIsVisible(true)

            setTimeout(() => {
                setIsVisible(false)
            }, 75 * speech.length)
        }
    }

	useEffect(() => {
		async function checkAvatarSpeechStatus() {
			const { valor } = await api.get('parametro/avatar_speech_enabled')

			setIsEnabled(valor === 'true')
		}

		checkAvatarSpeechStatus()
	}, [])

	return (
		<AvatarContext.Provider 
			value={{
				handleSpeak
			}}
		>
			{children}

			{isVisible && isEnabled && (
                <div className="fixed bottom-3 left-3 w-24 h-24 sm:w-36 sm:h-36 animated slideInUp faster">
                    <video src={avatarAnimated} autoPlay loop className="w-full h-full rounded-full shadow-lg"></video>
                </div>
            )}
		</AvatarContext.Provider>
	)
}