import React, { useContext } from 'react'
import { Form, Formik } from 'formik'
import { FiPlus, FiSave } from 'react-icons/fi'

import { Button, Textbox, Checkbox } from '../../../../components/Form'
import Modal from '../../../../components/Modal'

import { EleicaoContext } from '../../../../contexts/EleicaoContext'
import masks from '../../../../util/masks'

import {
    Container, CadastroContainer, CategoriasContainer
} from './styles'

export default function () {
    const {
        setShowCadastro, showCadastro, initialValues, handleSubmit, setEditItem, editItem, resetInitialValues
    } = useContext(EleicaoContext)

    return (
        <>
            <Container>
                <Button
                    className="transparent"
                    onClick={() => {
                        resetInitialValues()
                        setShowCadastro(true)
                    }}
                >
                    Cadastrar eleição
                    <FiPlus size={16} />
                </Button>
            </Container>

            <Modal
                isOpen={showCadastro || !!editItem}
                handleClose={() => {
                    setShowCadastro(false)

                    setEditItem(null)
                }}
                title={showCadastro ? 'Cadastro de eleição' : 'Edição de eleição'}
            >
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <CadastroContainer>
                                <Textbox
                                    name="descricao"
                                    label="Descrição"
                                />

                                <Textbox
                                    name="inicio"
                                    label="Início da votação"
                                    mask={masks.datetime}
                                />

                                <Textbox
                                    name="fim"
                                    label="Fim da votação"
                                    mask={masks.datetime}
                                />

                                <CategoriasContainer>
                                    <h1>Eleitores aptos</h1>

                                    <Checkbox
                                        name="medico"
                                        label="Médicos em geral"
                                        handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                    />

                                    <Checkbox
                                        name="academico"
                                        label="Acadêmicos em geral"
                                        handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                    />

                                    <Checkbox
                                        name="quites"
                                        label="Apenas médicos quites"
                                        handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                    />

                                    <Checkbox
                                        name="colaboradores"
                                        label="Empregados SINDMEPA"
                                        handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                    />

                                    <Checkbox
                                        name="diretoria"
                                        label="Diretoria SINDMEPA"
                                        handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                    />

                                    <Checkbox
                                        name="teste"
                                        label="Eleitores teste SINDMEPA"
                                        handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                    />
                                </CategoriasContainer>

                                <div className="button-container">
                                    <Button type="submit" className="transparent" loading={isSubmitting}>
                                        {showCadastro ? 'Cadastrar' : 'Salvar alterações'}
                                        {showCadastro ? <FiPlus size={16} /> : <FiSave size={17} />}
                                    </Button>
                                </div>
                            </CadastroContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
