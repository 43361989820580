import React, { useContext, useEffect, useState } from 'react'
import { FaEye, FaExternalLinkAlt } from 'react-icons/fa'
import { format } from 'date-fns'

import Card from '../../../../components/Card'
import Modal from '../../../../components/Modal'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import SmallScreenPlaceholder from '../../../../components/SmallScreenPlaceholder'

import { api, authHeaders } from '../../../../services/api'

import { Container, EventDetails } from './styles'
import { GlobalContext } from '../../../../contexts/GlobalContext'

export default function () {
    const { user } = useContext(GlobalContext)

    const [eventos, setEventos] = useState(null)
    const [eventoDetalhes, setEventoDetalhes] = useState(null)

    async function loadPastEventos() {
        try {
            const response = await api.get('evento', {
                ...authHeaders(),
                params: {
                    grupo: 'past'
                }
            })

            setEventos(response
                .filter(evento => {
                    if([21, 22].includes(user.perfil.id)) {
                        return evento.usuario_criador.perfil_id === user.perfil.id
                    }
                
                    return true
                })
                .map(evento => {
                    let percentualPresenca = (evento.presencas.filter(presenca => presenca.presente).length * 100) / evento.presencas.length

                    if(percentualPresenca % 1 !== 0) {
                        percentualPresenca = percentualPresenca.toFixed(1).replace('.', ',')
                    }

                    return {
                        ...evento,
                        inicio: format(new Date(evento.inicio), 'dd/MM/yyyy HH:mm\'h\''),
                        tipo_evento: evento.tipo_evento.descricao,
                        presencas: `${evento.presencas.filter(presenca => presenca.presente).length} / ${evento.presencas.length}`,
                        porcentagem_presenca: evento.presencas.length
                            ? `${percentualPresenca}%` 
                            : '0%',
                        participantes: evento.presencas
                            .filter(participante => !!participante.pessoa)
                            .map(participante => {
                                const identificador = participante.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.identificador 
                                || participante.pessoa.documentos.find(doc => doc.tipo_documento.id === 2)?.identificador 

                                return {
                                    identificador,
                                    nome: participante.pessoa.nome,
                                    presente: 
                                    participante.presente
                                        ? 'FaCheck'
                                        : 'FaTimes',
                                    iconColor: participante.presente 
                                        ? '#4caf50'
                                        : '#f44336'
                                }
                            })
                    }
                }))
        } catch(e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if(user) {
            loadPastEventos()
        }
    }, [user])

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Acompanhamento de Eventos</h1>

                        {eventos ? (
                            <Table 
                                headers={[
                                    { name: 'nome', value: 'Evento' },
                                    { name: 'tipo_evento', value: 'Tipo de evento', centered: true },
                                    {
                                        name: 'presencas', value: 'Presentes / Convidados', centered: true, style: { width: 220 } 
                                    },
                                    {
                                        name: 'porcentagem_presenca', value: '% de presença', centered: true, style: { width: 160 } 
                                    },
                                    {
                                        name: 'data', value: 'Data / hora', centered: true
                                    }
                                ]}
                                data={eventos.map(evento => ({
                                    id: evento.id,
                                    nome: evento.nome,
                                    tipo_evento: evento.tipo_evento,
                                    presencas: evento.presencas,
                                    porcentagem_presenca: evento.porcentagem_presenca,
                                    data: evento.inicio,
                                    ...evento
                                }))}
                                actions={[
                                    {
                                        action: item => {
                                            setEventoDetalhes(item)
                                        },
                                        name: 'Detalhar',
                                        icon: FaEye,
                                        iconSize: 16
                                    },
                                    {
                                        action: item => {
                                            window.open(`/evento/${item.id}`, '_blank')
                                        },
                                        name: 'Ver convite',
                                        icon: FaExternalLinkAlt
                                    }
                                ]}
                            />
                        ) : (
                            <Spinner label="Carregando... (isto pode levar até 1 minuto)" />
                        )}

                        <SmallScreenPlaceholder hideOffset={700} />
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!eventoDetalhes}
                handleClose={() => {
                    setEventoDetalhes(null)
                }}
                title="Detalhes do evento"
            >
                {eventoDetalhes ? (
                    <>
                        <EventDetails>
                            <h1>{`Evento: ${eventoDetalhes.nome}`}</h1>
                            <h2>{`Local: ${eventoDetalhes.nome_local || 'Não informado'}`}</h2>
                            <h2>{`Início: ${eventoDetalhes.inicio}` || 'Não informado'}</h2>
                        </EventDetails>
                    
                        <Table 
                            headers={[
                                {
                                    name: 'identificador', value: 'CRM/CPF', centered: true, style: { width: 200 } 
                                },
                                { name: 'nome', value: 'Nome' },
                                { name: 'presente', value: 'Presente?', icon: true }
                            ]}
                            data={eventoDetalhes.participantes || []}
                            filterable
                        />
                    </>
                ) : (
                    <Spinner />
                )}

            </Modal>
        </>
    )
}
