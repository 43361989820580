import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
    className: 'modal-wrapper'
})`
	display: grid;
	grid-template-columns: auto 30px;
	grid-template-rows: auto auto;
	grid-template-areas:
		'title close_icon'
		'content content';
	color: #FFF;
	width: 100% !important;
	max-height: 80vh;

	svg {
		color: #FFF;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}

	@media(max-width: 800px) {
		min-width: unset;
	}
`

export const Content = styled.div.attrs({
    className: 'modal-content'
})`
	grid-area: content;

	> *:last-child {
		margin-bottom: 8px;
	}
`
