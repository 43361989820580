import React from 'react'

import { getFrequenceByExpression } from '../..'

import { Container } from './styles'

export default function ({ task }) {
    function getCanal(channel) {
        switch(channel) {
            case 'email': return 'E-mail'
            case 'sms': return 'SMS'
            default: return 'Canal desconhecido'
        }
    }

    return (
        <Container>
            <p>
                <b>Consulta SQL:</b>
            </p>
            
            <p>
                {task.data.sql}
            </p>

            <p style={{ marginTop: 10 }}>
                <b>Canal:</b>
            </p>

            <p>
                {getCanal(task.data.channel)}
            </p>

            <p style={{ marginTop: 10 }}>
                <b>Frequência:</b>
            </p>

            <p>
                {getFrequenceByExpression(task.expression)}
            </p>
        </Container>
    )
}
