import React, { useEffect, useState } from 'react'
import { FiFile } from 'react-icons/fi'
import { format } from 'date-fns'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../../../../services/api'
import Card from '../../../../components/Card'
import Table from '../../../../components/Table'

import { Container } from './styles'

import { forceDownloadFileFromURL } from '../../../../util/file'

export default function () {
    const [associacoes, setAssociacoes] = useState(null)

    async function loadAssociacoes() {
        const response = await api.get('associacao', authHeaders())

        setAssociacoes(response.map(a => ({
            id: a.id,
            nome: a.pessoa.nome,
            categoria: a.medico ? 'Médico' : 'Acadêmico',
            status: a.status_associacao.descricao,
            data_associacao: format(new Date(a.sindicalizacao[0].data), 'dd/MM/yyyy')
        })))
    }

    async function handleGetComprovanteAssociacao(item) {
        try {
            const { link } = await api.get(`associacao/comprovante/${item.id}`, authHeaders())

            forceDownloadFileFromURL(link)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadAssociacoes()
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Associações realizadas pelo Gestão na Mão</h1>

                    <p>Lista ordenada da associação mais recente para a mais antiga. Clique nos títulos das colunas para reordenar.</p>

                    <Table
                        headers={[
                            { name: 'nome', value: 'Nome' },
                            { name: 'categoria', value: 'Categoria', centered: true },
                            { name: 'data_associacao', value: 'Data de associação', centered: true },
                            { name: 'status', value: 'Status', centered: true }
                        ]}
                        data={associacoes || []}
                        actions={[
                            {
                                action: handleGetComprovanteAssociacao,
                                name: 'Comprovante de associação',
                                icon: FiFile,
                                iconSize: 16,
                                color: '#424242'
                            }
                        ]}
                        filterable
                    />
                </Card>
            </main>
        </Container>
    )
}
