import React, { useState } from 'react'

import Card from '../../../../components/Card'

import Geral from './Geral'
import PorAssunto from './PorAssunto'
import PorStatus from './PorStatus'
import PorSetor from './PorSetor'

import {
    Container, RelatoriosContainer, Selector, Report 
} from './styles'
import ProcessoProvider from '../../../../contexts/ProcessoContext'

export default function () {
    const [selected, setSelected] = useState(0)

    function getReport() {
        switch(selected) {
            case 0:
                return <Geral />
            case 1:
                return <PorAssunto />
            case 2:
                return <PorStatus />
            case 3:
                return <PorSetor />
            default: return null
        }
    }

    return (
        <ProcessoProvider>
            <Container>
                <main>
                    <Card>
                        <h1>Relatórios</h1>

                        <RelatoriosContainer>
                            <Selector selected={selected}>
                                <li onClick={() => { setSelected(0) }}>Geral</li>
                                <li onClick={() => { setSelected(1) }}>Por assunto</li>
                                <li onClick={() => { setSelected(2) }}>Por status</li>
                                <li onClick={() => { setSelected(3) }}>Por grupo</li>
                            </Selector>

                            <Report>
                                {getReport()}
                            </Report>

                        </RelatoriosContainer>
                    </Card>
                </main>
            </Container>
        </ProcessoProvider>
    )
}
