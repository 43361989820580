import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FaFileUpload } from 'react-icons/fa'
import Yup from '../../../../../services/yup'

import { ExtratoContext } from '../../../../../contexts/ExtratoContext'

import { api, authHeaders } from '../../../../../services/api'

import { File, Select, Button } from '../../../../../components/Form'

import { Container } from './styles'
import { TipContext } from '../../../../../contexts/TipContext'
import { loadTips } from '../../../../../util/tip'

export default function () {
    const { loadExtratosPendentes } = useContext(ExtratoContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [contasCorrente, setContasCorrente] = useState([])

    async function loadContasCorrente() {
        const response = await api.get('conta_sindicato', authHeaders())

        setContasCorrente(response)
    }

    useEffect(() => {
        loadContasCorrente()

        loadTips(setCodigo, 'form_carga_extratos_bancarios')
    }, [])

    async function handleSubmit(values, { resetForm }) {
        try {
            values.conta_corrente = values.conta_corrente.value

            const response = await api.post('carga_extratos', values, authHeaders())

            resetForm()

            toast.success(response.msg || 'Extrato carregado com sucesso!')

            loadExtratosPendentes()
        } catch (e) {
            toast.error(e.msg || 'Erro ao carregar o arquivo.')
        }
    }

    return (
        <Container>
            <p style={{ margin: 8 }}>{tips?.formulario}</p>

            <Formik
                initialValues={{
                    arquivo: null,
                    conta_corrente: ''
                }}
                validationSchema={Yup.object().shape({
                    arquivo: Yup.number().required('Anexe o arquivo'),
                    conta_corrente: Yup.string().ensure().nullable().required('Selecione o tipo de arquivo')
                })}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, errors }) => (
                    <Form>
                        <File
                            name="arquivo"
                            onSuccess={fileId => setFieldValue('arquivo', fileId)}
                            label="Arquivo para carregar"
                            format="square"
                            previewSize={['100%', '150px']}
                            error={errors.arquivo}
                        />

                        <Select
                            name="conta_corrente"
                            label="Conta corrente"
                            onChange={(selected, actionMeta) => setFieldValue(actionMeta.name, selected)}
                            options={contasCorrente.map(conta => ({
                                label: `${conta.dados_bancarios.instituicao.nome_fantasia} (${conta.dados_bancarios.conta})`,
                                value: conta.id
                            }))}
                        />

                        <Button type="submit" className="transparent">
                            Carregar extrato
                            <FaFileUpload size={20} />
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>

    )
}
