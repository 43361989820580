import React, { useState } from 'react'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'

import { Container } from './styles'
import { Button } from '../../../../../components/Form'
import { api, authHeaders } from '../../../../../services/api'
import { downloadArquivoFromBuffer } from '../../../../../util/file'

const nomeRelatorio = 'Relatório de Associados com 70 anos ou mais'

export default function () {
    const [loading, setLoading] = useState(false)

    async function handleGerar() {
        try {
            setLoading(true)

            const response = await api.post('relatorio/associados_idosos', {}, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadArquivoFromBuffer(response, nomeRelatorio, 'xlsx')
        } catch (e) {
            console.log(e)
            toast.error(e.msg)
        }

        setLoading(false)
    }

    return (
        <Container className="animated fadeIn faster form form-report">
            <h2>{nomeRelatorio}</h2>

            <div className="button-container">
                <Button className="transparent" onClick={handleGerar} loading={loading}>
                    Gerar relatório
                    <FiFile size={16} />
                </Button>
            </div>
        </Container>
    )
}
