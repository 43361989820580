import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 8px;

		> h1 {
			grid-column: 1/span 3;
			margin: 0 8px;
		}

		.button-container {
			grid-column: 1/span 3;
			display: flex;
			justify-content: end;
		}

		.table-container {
			margin: 24px 8px 0;
			grid-column: 1/span 3;
			width: calc(100% - 16px);
		}
	}
`
