import React, { useContext } from 'react'
import { format } from 'date-fns'

import maskUtil from '../../../../../util/masks'

import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'

import { ExtratoContext } from '../../../../../contexts/ExtratoContext'

import { Container } from './styles'

export default function () {
    const { extratosPendentes, handleDelete, handlePrepareEdit } = useContext(ExtratoContext)

    return (
        <Container>
            {extratosPendentes ? (
                <Table 
                    headers={[
                        { name: 'historico', value: 'Histórico' },
                        { name: 'tipo', value: 'Tipo', centered: true },
                        { name: 'valor', value: 'Valor', centered: true },
                        { name: 'data', value: 'Data', centered: true },
                        { name: 'conta_corrente', value: 'Conta corrente', centered: true }
                    ]}
                    data={extratosPendentes.map(extrato => ({
                        id: extrato.id,
                        historico: extrato.historico,
                        tipo: extrato.tipo,
                        valor: maskUtil.maskApply.currency(extrato.valor),
                        data: format(new Date(extrato.data_consolidacao), 'dd/MM/yyyy'),
                        conta_corrente: `${extrato.conta_corrente.dados_bancarios.instituicao.sigla} ${extrato.conta_corrente.dados_bancarios.conta}`
                    }))}
                    emptyLabel="Nenhum extrato encontrado"
                    handleDelete={handleDelete}
                    handlePrepareEdit={handlePrepareEdit}
                    showId
                    confirmExclusion={{
                        template: 'Deseja realmente excluir o lançamento #attr#',
                        attr: 'historico'
                    }}
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
