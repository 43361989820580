import React, { useState } from 'react'
import { FiPlusCircle, FiXCircle } from 'react-icons/fi'

import FormMediasSociais from '../../../components/FormMediasSociais'
import Table from '../../../components/Table'
import Spinner from '../../../components/Spinner'

import { api, authHeaders } from '../../../services/api'

import { Container } from './styles'

export default function ({ pessoa, reload, editable = true }) {
    const [showForm, setShowForm] = useState(false)
    const [initialValues, setInitialValues] = useState(null)
    const [editId, setEditId] = useState(null)

    async function handlePrepareEdit(id) {
        setEditId(id)

        const media = pessoa.medias.find(m => m.id === id)

        setInitialValues({
            tipo_media_social: { value: media.media_id, label: media.nome },
            valor_media_social: media.valor
        })

        setShowForm(true)
    }

    async function handleDelete(id) {
        try {
            await api.delete(`associado_media_social/${id}`, authHeaders())

            reload()
        } catch (e) {
            return false
        }

        return true
    }

    return (
        <Container className="animated fadeInUp delay-800ms">
            <h1>Mídias Socias</h1>

            {editable ? (
                <>
                    {showForm ? (
                        <FiXCircle
                            size={24}
                            data-tip="Fechar"
                            data-for="tooltip"
                            style={{ gridArea: 'icon' }}
                            onClick={() => {
                                setShowForm(false)
                                setInitialValues(null)
                                setEditId(null)
                            }}
                        />
                    ) : (
                        <FiPlusCircle
                            size={24}
                            data-tip="Adicionar"
                            data-for="tooltip"
                            style={{ gridArea: 'icon' }}
                            onClick={() => {
                                setShowForm(true)
                            }}
                        />
                    )}
                </>
            ) : (
                <div />
            )}

            {showForm && (
                <FormMediasSociais
                    mediaInitialValues={initialValues}
                    editId={editId}
                    pessoa={pessoa}
                    style={{ gridArea: 'form' }}
                    className="animated fadeIn"
                    reload={() => {
                        reload()
                        setShowForm(false)
                        setInitialValues(null)
                        setEditId(null)
                    }}
                />
            )}

            {pessoa ? (
                <Table
                    headers={[
                        { name: 'icone', value: 'Rede', icon: true },
                        { name: 'valor', value: 'Perfil' }
                    ]}
                    data={pessoa?.medias || []}
                    handlePrepareEdit={editable && handlePrepareEdit}
                    handleDelete={editable && handleDelete}
                    hideOnSmallHeaders={['valor']}
                    confirmExclusion={{
                        attr: 'valor',
                        template: 'Deseja realmente excluir a midia social #attr#?'
                    }}
                    emptyLabel="Nenhuma rede social adicionada"
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
