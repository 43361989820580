import React, { useContext, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import {
    FiEdit, FiPlus, FiSave, FiTrash
} from 'react-icons/fi'

import { Textbox, Button, Select } from '../../../../components/Form'
import Table from '../../../../components/Table'

import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'

import { Container, FormContainer } from './styles'
import { TipContext } from '../../../../contexts/TipContext'
import Icon from '../../../../components/Icon'

const baseInitialValues = {
    descricao: '',
    assunto: '',
    prazo: ''
}

const validation = Yup.object({
    descricao: Yup.string().required('Informe um nome.')
})

export default function ({ edicao, reload }) {
    const { tips } = useContext(TipContext)

    const eAssessoriaJuridica = edicao?.id === 1

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [filtros, setFiltros] = useState([])
    const [filtroSelecionado, setFiltroSelecionado] = useState(null)
    const [assuntoEdicao, setAssuntoEdicao] = useState(null)
    const [assuntos, setAssuntos] = useState([])
    const [assuntoAtendentes, setAssuntoAtendentes] = useState([])

    async function loadFiltros() {
        try {
            const response = await api.get('visao', authHeaders())

            setFiltros(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadFiltros()
    }, [])

    async function loadAtendentes() {
        const { visao, index } = filtroSelecionado

        const response = await api.get(`visao/${visao}`, authHeaders())

        const outros = assuntoAtendentes.filter(a => a.assunto !== assuntos[index].descricao)

        setAssuntoAtendentes([
            ...outros.slice(0, index),
            {
                assunto: assuntos[index].descricao,
                atendentes: response.pessoas
            },
            ...outros.slice(index)
        ])
    }

    async function handleAdd(values) {
        try {
            await api.post('atendimento_tipo', values, authHeaders())
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(assunto) {
        try {
            await api.delete(`atendimento_assunto/${assunto.id}`, authHeaders())

            setAssuntos(old => old.filter(a => a.id !== assunto.id))

            toast.success('Assunto removido com sucesso.')
        } catch (e) {
            toast.error('Erro ao remover o assunto.')
        }
    }

    function handlePrepareEditAssunto(assunto) {
        setAssuntoEdicao(assunto)

        setInitialValues({
            descricao: edicao.descricao,
            assunto: assunto.descricao,
            prazo: assunto.prazo
        })
    }

    async function handleEdit(values) {
        try {
            await api.put(`atendimento_tipo/${edicao.id}`, values, authHeaders())
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if (edicao) {
            setInitialValues(edicao)

            const assuntosVisiveis = edicao.assuntos

            setAssuntos(assuntosVisiveis)

            setAssuntoAtendentes(assuntosVisiveis.map(a => ({
                assunto: a.descricao,
                atendentes: a.atendentes
            })))
        }
    }, [edicao])

    useEffect(() => {
        if (filtroSelecionado) {
            setAssuntoAtendentes([])

            loadAtendentes()
        }
    }, [filtroSelecionado])

    async function handleSubmit(values) {
        try {
            const body = {
                id: edicao?.id || null,
                descricao: values.descricao,
                assuntos: []
            }

            for (let i = 0; i < assuntos.length; i++) {
                const assunto = assuntos[i]

                const checkeds = document.querySelectorAll(`#atendente-table-${i} tbody tr td > input[type=checkbox]:checked`)

                const pessoasMarcadas = [...checkeds].map(cb => Number(cb.classList[0].split('-')[1]))

                body.assuntos.push({
                    id: assunto.id || null,
                    descricao: assunto.descricao,
                    prazo: assunto.prazo,
                    pessoas: pessoasMarcadas
                })
            }

            try {
                if (edicao) {
                    await handleEdit(body)
                } else {
                    await handleAdd(body)
                }

                toast.success(`Tipo de atendimento ${edicao ? 'alterado' : 'cadastrado'} com sucesso.`)

                reload()
            } catch (e) {
                toast.error(e.msg)
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    const handleSubmitDebounce = debounce(handleSubmit, 200)

    async function addAssunto(assuntoItem, setFieldValue) {
        if (!assuntoItem) {
            toast.warn('Informe o nome do assunto.')
            return
        }

        const { assunto, prazo } = assuntoItem

        if (assuntos.map(a => a.descricao).includes(assunto)) {
            toast.warn('Este assunto já foi inserido.')
            return
        }

        setAssuntos([
            ...assuntos,
            {
                id: 0,
                descricao: assunto,
                prazo
            }
        ])

        setAssuntoAtendentes([
            ...assuntoAtendentes,
            {
                assunto,
                atendentes: []
            }
        ])

        setFieldValue('assunto', '')
    }

    return (
        <Container>
            <FormContainer>
                <Formik
                    onSubmit={handleSubmitDebounce}
                    initialValues={initialValues}
                    validationSchema={validation}
                    enableReinitialize
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <p style={{ margin: 8, gridColumn: '1/span 2' }}>{tips?.formulario}</p>

                            <Textbox
                                name="descricao"
                                label="Tipo de atendimento"
                            />

                            <div className="form-assunto">
                                <Textbox
                                    name="assunto"
                                    label="Assunto"
                                />

                                {!eAssessoriaJuridica && (
                                    <Textbox
                                        name="prazo"
                                        label="Prazo em dias úteis"
                                        type="number"
                                    />
                                )}

                                <Button
                                    onClick={() => {
                                        if (assuntoEdicao) {
                                            const posicao = assuntos.findIndex(a => a.id === assuntoEdicao.id)
                                            const assuntoEmEdicao = assuntos.find(a => a.id === assuntoEdicao.id)

                                            setAssuntos(old => [
                                                ...old.slice(0, posicao),
                                                {
                                                    ...assuntoEmEdicao,
                                                    descricao: values.assunto,
                                                    prazo: values.prazo
                                                },
                                                ...old.slice(posicao + 1)
                                            ])

                                            setFieldValue('prazo', '')
                                            setFieldValue('assunto', '')

                                            setAssuntoEdicao(null)
                                        } else if (eAssessoriaJuridica || values.prazo) {
                                            addAssunto(values, setFieldValue)

                                            setFieldValue('prazo', '')
                                        } else if (!values.prazo) {
                                            toast.warn('Informe o prazo em horas.')
                                        }
                                    }}
                                    fab
                                    className="transparent"
                                    tooltip="Adicionar assunto"
                                >
                                    {assuntoEdicao ? <FiSave /> : <FiPlus />}
                                </Button>
                            </div>

                            {assuntos.length > 0 && eAssessoriaJuridica ? assuntos.map((assunto, index) => (
                                <details key={assunto.descricao}>
                                    <summary>
                                        {assunto.descricao}

                                        <Icon
                                            Icon={FiTrash}
                                            onClick={() => { handleDelete(assunto) }}
                                            confirmable
                                            texto="Deseja realmente remover este assunto?"
                                        />
                                    </summary>

                                    <Select
                                        name={`visao-${index}`}
                                        label="Grupo"
                                        withoutForm
                                        onChange={selected => {
                                            setFiltroSelecionado({
                                                visao: selected.value,
                                                name: `visao-${index}`,
                                                index
                                            })
                                        }}
                                        options={filtros.map(filtro => ({
                                            label: filtro.descricao,
                                            value: filtro.id
                                        })) || []}
                                        value={filtroSelecionado}
                                    />

                                    <Table
                                        headers={[
                                            { name: 'nome', value: 'Nome' }
                                        ]}
                                        data={assuntoAtendentes?.[index]?.atendentes || assunto?.atendentes || []}
                                        tableId={`atendente-table-${index}`}
                                        checkable
                                        checkedItems={assunto.atendentes?.map(atendente => atendente.id) || []}
                                        pagination={false}
                                    />
                                </details>
                            )) : (
                                <Table
                                    headers={[
                                        { name: 'descricao', value: 'Assunto' },
                                        { name: 'prazo', value: 'Prazo em dias úteis' }
                                    ]}
                                    data={assuntos}
                                    actions={[
                                        {
                                            action: handlePrepareEditAssunto,
                                            name: 'Editar',
                                            icon: FiEdit,
                                            iconSize: 16
                                        },
                                        {
                                            action: handleDelete,
                                            name: 'Excluir',
                                            icon: FiTrash,
                                            iconSize: 16
                                        }
                                    ]}
                                />
                            )}

                            <Button type="submit" className="transparent" disabled={isSubmitting}>
                                {edicao ? 'Salvar alterações' : 'Cadastrar'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </Container>
    )
}
