import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px;

		h1 {
			margin-bottom: 20px;
		}

		.button-container {
			display: flex;
			justify-content: end;
		}

		table {
			line-height: 20px;
		}

		@media(max-width: 600px) {
			padding: 10px 0;
		}

		@media(min-width: 600px) {
			margin-left: 50px;
		}
	}
`

export const DetalhesContainer = styled.div`
	display: flex;
	flex-direction: column;
	line-height: 28px;
	margin: 12px 8px;

	.button-container {
		width: 100%;
		display: flex;
		justify-content: end;
	}

	> h2 {
		font-size: 14px;
		font-weight: 600;
		margin-top: 10px;
		border-bottom: solid 1px #ddd;
		padding-bottom: 8px;
		margin-bottom: 8px;
	}
	
	.envio {
		margin-left: 12px;
	}

	.questao-container {
		display: flex;
		flex-direction: column;
		margin-bottom: 12px;
		padding-bottom: 8px;
		border-bottom: solid 1px #ddd;

		> h3 {
			font-size: 14px;
			font-weight: 600;
			margin-left: 12px;
		}

		.alternativa {
			margin-left: 12px;
		}
	}
`
