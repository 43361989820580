import React, { useContext, useState, useEffect } from 'react'
import {
    FaUserFriends, FaBullhorn, FaUserCheck, FaAward, FaFileAlt
} from 'react-icons/fa'
import { toast } from 'react-toastify'
import { FiPlus } from 'react-icons/fi'
import { format, isBefore } from 'date-fns'

import { Form, Formik } from 'formik'
import { getDate } from '../../../../../util/date'

import { api, authHeaders } from '../../../../../services/api'

import { EventoContext } from '../../../../../contexts/EventoContext'

import FormCadastro from '../FormCadastro'
import Convite from '../Convite'
import PresencaEvento from '../Presenca'
import Certificado from '../Certificado'
import Avisos from '../Avisos'

import Table from '../../../../../components/Table'
import Modal from '../../../../../components/Modal'
import { Button, Calendar } from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'
import { GlobalContext } from '../../../../../contexts/GlobalContext'
import Arquivos from '../Arquivos'

import { Container, FilterContainer } from './styles'

export default function () {
    const {
        setEvento,
        setEditItem,
        editItem,
        items,
        showEventoConvite,
        setShowEventoConvite,
        reload,
        modoCadastro,
        setModoCadastro,
        setShowEventoPresenca,
        showEventoPresenca,
        setShowEventoCertificado,
        showEventoCertificado,
        showEventoAviso,
        setShowEventoAviso,
        showEventoArquivos,
        setShowEventoArquivos,
        setPeriodoBusca,
        periodoBusca
    } = useContext(EventoContext)
    const { user } = useContext(GlobalContext)

    const [editable, setEditable] = useState(false)

    async function handleDelete(id) {
        try {
            await api.delete(`evento/${id}`, authHeaders())

            reload()
            
            toast.success('Evento removido com sucesso.')

            return true
        } catch (e) {
            toast.error(e.msg)
            return false
        }
    }

    async function handleBuscar(values) {
        setPeriodoBusca(values.periodo)
    }

    async function handlePrepareEdit(id) {
        const evento = await api.get(`evento/${id}`, authHeaders())

        setEditItem({
            ...evento,
            inicio: format(new Date(evento.inicio), 'dd/MM/yyyy HH:mm\'h\''),
            fim: format(new Date(evento.fim), 'dd/MM/yyyy HH:mm\'h\''),
            descricao: evento.descricao?.replace(/(<br>)/g, '\n') || ''
        })
    }

    useEffect(() => {
        if (user) {
            setEditable(user.perfil.id !== 21)
        }
    }, [user])

    return (
        <Container>
            {editable && (
                <Button className="transparent button-cadastrar" onClick={() => setModoCadastro(true)}>
                    Cadastrar novo evento
                    <FiPlus />
                </Button>
            )}

            <FilterContainer>
                <Formik
                    onSubmit={handleBuscar}
                    initialValues={{ periodo: periodoBusca }}
                >
                    {({ submitForm }) => (
                        <Form>
                            <Calendar 
                                label="Período de busca"
                                yearNavigator 
                                selectionMode="range"
                                name="periodo"
                                onChange={submitForm}
                            />
                        </Form>
                    )}
                </Formik>
            </FilterContainer>

            {items ? (
                <Table
                    headers={[
                        { name: 'name', value: 'Nome' },
                        { name: 'inicio', value: 'Início', centered: true },
                        { name: 'fim', value: 'Término', centered: true },
                        { name: 'nome_local', value: 'Local' },
                        { name: 'presencas_participantes', value: 'Presenças / Participantes', centered: true }
                    ]}
                    data={
                        items.map(item => ({
                            ...item,
                            presencas_participantes: `${item.participantes.filter(p => p.presente).length} / ${item.participantes.length}`
                        }))
                    }
                    handleDelete={editable ? handleDelete : null}
                    handlePrepareEdit={editable ? handlePrepareEdit : null}
                    confirmExclusion={{
                        attr: 'name',
                        template: 'Deseja realmente cancelar o evento #attr#?'
                    }}
                    filterable
                    actions={editable ? [
                        {
                            action: item => {
                                setShowEventoConvite(true)

                                setEvento(item)
                            },
                            icon: FaUserFriends,
                            name: 'Convidar pessoas',
                            iconSize: 16,
                            checkDisabled: item => isBefore(new Date(item.inicio_datetime), new Date())
                        },
                        {
                            action: item => {
                                setShowEventoPresenca(true)

                                setEvento(item)
                            },
                            icon: FaUserCheck,
                            name: 'Marcar presença',
                            iconSize: 16,
                            checkDisabled: item => !item.participantes.length || !isBefore(getDate(item.inicio_datetime), new Date()),
                            disabledTooltip: 'Opção desabilitada para este evento'
                        },
                        {
                            action: item => {
                                setShowEventoCertificado(true)

                                setEvento(item)
                            },
                            icon: FaAward,
                            name: 'Certificados',
                            iconSize: 16,
                            checkDisabled: item => !item.participantes.length || !isBefore(new Date(item.inicio_datetime), new Date()),
                            disabledTooltip: 'Opção desabilitada para este evento'
                        },
                        {
                            action: item => {
                                setShowEventoAviso(true)

                                setEvento(item)
                            },
                            name: 'Emitir avisos',
                            iconSize: 16,
                            icon: FaBullhorn,
                            checkDisabled: item => !item.participantes.length || isBefore(getDate(item.fim_datetime), new Date())
                        },
                        {
                            action: item => {
                                setShowEventoArquivos(true)

                                setEvento(item)
                            },
                            name: 'Arquivos',
                            iconSize: 16,
                            icon: FaFileAlt
                        }
                    ] : []}
                    hideOnSmallHeaders={['presencas_participantes', 'nome_local']}
                    hideOffset={860}
                    legends={[
                        {
                            color: '#ffcdd2',
                            text: 'Eventos públicos'
                        }
                    ]}
                />
            ) : (
                <Spinner />
            )}

            <Modal
                isOpen={!!editItem || modoCadastro}
                handleClose={() => {
                    setEditItem(null)
                    setModoCadastro(false)
                }}
                title={`${modoCadastro ? 'Cadastrar' : 'Editar'} evento`}
            >
                <FormCadastro
                    evento={editItem || {}}
                    edicao={!!editItem}
                />
            </Modal>

            <Modal
                isOpen={showEventoConvite}
                handleClose={() => {
                    setShowEventoConvite(false)
                }}
                title="Convidar pessoas"
                closeOnOverlayClick={false}
            >
                <Convite />
            </Modal>

            <Modal
                isOpen={showEventoPresenca}
                handleClose={() => {
                    setShowEventoPresenca(false)
                }}
                title="Marcar presenças"
            >
                <PresencaEvento />
            </Modal>

            <Modal
                isOpen={showEventoCertificado}
                handleClose={() => {
                    setShowEventoCertificado(false)
                }}
                title="Certificado"
            >
                <Certificado />
            </Modal>

            <Modal
                isOpen={showEventoAviso}
                handleClose={() => {
                    setShowEventoAviso(false)
                }}
                title="Emitir avisos aos participantes"
            >
                <Avisos />
            </Modal>

            <Modal
                isOpen={showEventoArquivos}
                handleClose={() => {
                    setShowEventoArquivos(false)
                }}
                title="Mídias e documentos do evento"
            >
                <Arquivos />
            </Modal>
        </Container>
    )
}
