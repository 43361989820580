import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import { Textbox } from '../../../../components/Form'

import maskUtils from '../../../../util/masks'
import { isBRDate, getDate } from '../../../../util/date'

import { api, authHeaders } from '../../../../services/api'

import { Container, DatesContainer } from './styles'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [medicos, setMedicos] = useState(null)
    const [diretoria, setDiretoria] = useState(null)
    const [disabled, setDisabled] = useState([])

    async function loadMedicos() {
        const { pessoas } = await api.get('visao/16', authHeaders())

        setMedicos(pessoas.map(p => ({
            id: p.id,
            crm: p.crm,
            nome: p.nome
        })))

        setDisabled(pessoas.map(p => p.id))
    }

    async function loadDiretoriaAtual() {
        const response = await api.get('diretoria', authHeaders())

        setDiretoria(response)
    }

    useEffect(() => {
        loadMedicos()
    }, [])

    useEffect(() => {
        if (medicos) {
            loadDiretoriaAtual()
        }
    }, [medicos])


    async function handleUncheck(item) {
        try {
            await api.delete(`diretoria/${item.id}`, authHeaders())
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleCheck(item) {
        try {
            const inicio = document.querySelector('#inicio').value
            const fim = document.querySelector('#fim').value

            if (!isBRDate(inicio) || !isBRDate(fim)) {
                document.querySelector(`.check-${item.id}`).checked = false

                toast.warn('As datas são inválidas. Verifique e tente novamente.')

                return
            }

            await api.post('diretoria', {
                pessoa_id: item.id,
                inicio: getDate(inicio),
                fim: getDate(fim)
            }, authHeaders())
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function checkDisableCheckbox() {
        const inicio = document.querySelector('#inicio').value
        const fim = document.querySelector('#fim').value

        if (!isBRDate(inicio) || !isBRDate(fim)) {
            setDisabled(medicos.map(m => m.id))
            return
        }

        setDisabled([])
    }

    useEffect(() => {
        loadTips(setCodigo, 'form_membros_diretoria')
    }, [])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Membros da diretoria</h1>

                    <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                    <DatesContainer>
                        <Textbox
                            withoutForm
                            label="Início"
                            id="inicio"
                            mask={maskUtils.date}
                            onChange={checkDisableCheckbox}
                        />

                        <Textbox
                            withoutForm
                            label="Fim"
                            id="fim"
                            mask={maskUtils.date}
                            onChange={checkDisableCheckbox}
                        />
                    </DatesContainer>

                    {medicos && diretoria ? (
                        <Table
                            headers={[
                                { name: 'crm', value: 'CRM' },
                                { name: 'nome', value: 'Nome' }
                            ]}
                            data={medicos}
                            checkable
                            filterable
                            checkedItems={diretoria.map(d => d.pessoa.id)}
                            onCheckItem={handleCheck}
                            onUncheckItem={handleUncheck}
                            checkedReadonlyItems={disabled}
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
