import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import {
    FiEdit, FiEye, FiPlus, FiSearch, FiTrash 
} from 'react-icons/fi'
import { extname } from '../../../../util/path'
import arrayUtils from '../../../../util/array'
import {
    Container, PreviewContent, CadastroContainer, FiltrosContainer 
} from './styles'
import Card from '../../../../components/Card'
import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'
import formUtils from '../../../../util/form'
import {
    Button, Calendar, File, Select,
    Textbox
} from '../../../../components/Form'
import Modal from '../../../../components/Modal'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import { imageFormats } from '../../../../boxes/Arquivos'

const baseInitialValues = {
    arquivo: null,
    data: '',
    competencia: '',
    tipo: null,
    descricao: ''
}

const today = new Date()
const inicioMes = startOfMonth(today)
const fimMes = endOfMonth(today)

export const initialValuesFiltro = {
    periodo: [inicioMes, fimMes]
}

const validation = Yup.object({
    arquivo: Yup.number().required('Adicione um arquivo.').nullable(),
    data: Yup.string().date().required('Informe a data.'),
    competencia: Yup.string().required('Informe a competência.').nullable(),
    tipo: Yup.string().ensure().required('Selecione o tipo de documento.').nullable(),
    descricao: Yup.string().required('Forneça uma descrição.')
})

export default function () {
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [documentos, setDocumentos] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [showAdd, setShowAdd] = useState(false)
    const [previewDocumento, setPreviewDocumento] = useState(null)
    const [documentoEdicao, setDocumentoEdicao] = useState(null)

    async function loadDocumentos(values) {
        const tiposIds = tiposDocumento.map(tipo => tipo.id)

        const response = await api.get('documento', {
            ...authHeaders(),
            params: {
                tipo: tiposIds,
                periodo: values ? values.periodo : [inicioMes, fimMes]
            }
        })

        response.sort(arrayUtils.sort.comparisonFunction('data', 'desc'))

        setDocumentos(response)
    }

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento', {
            params: {
                grupo: 'financeiro'
            }
        })

        setTiposDocumento(response)
    }

    async function handleSubmit(values) {
        values = formUtils.extractFormValues(values)

        try {
            if (showAdd) {
                await api.post('documento', {
                    id: values.id,
                    financeiro: true,
                    tipo_documento_id: values.tipo,
                    arquivo_id: values.arquivo,
                    data: format(new Date(values.data), 'yyyy-MM-dd'),
                    competencia: format(new Date(values.competencia), 'yyyy-MM-dd'),
                    descricao: values.descricao
                }, authHeaders())

                toast.success('Documento cadastrado.')
            } else {
                await api.put(`documento/${documentoEdicao.id}`, {
                    tipo_documento: values.tipo,
                    arquivo_id: values.arquivo,
                    data: format(new Date(values.data), 'yyyy-MM-dd'),
                    competencia: format(new Date(values.competencia), 'yyyy-MM-dd'),
                    descricao: values.descricao
                }, authHeaders())

                toast.success('Documento atualizado.')
            }

            setShowAdd(false)
            setDocumentoEdicao(null)

            loadDocumentos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleExcluir(documento) {
        try {
            await api.delete(`documento/${documento.id}`, authHeaders())

            toast.success('Documento excluído.')

            loadDocumentos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadTiposDocumento()
    }, [])

    useEffect(() => {
        if (tiposDocumento.length) {
            loadDocumentos()
        }
    }, [tiposDocumento])

    useEffect(() => {
        if (documentoEdicao) {
            const {
                data, descricao, tipo, arquivo, competencia
            } = documentoEdicao

            setInitialValues({
                arquivo: arquivo.id,
                data,
                competencia,
                tipo: {
                    label: tipo.descricao,
                    value: tipo.id
                },
                descricao
            })
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [documentoEdicao])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Documentos financeiros</h1>

                    <Button onClick={() => { setShowAdd(true) }} className="transparent">
                        Cadastrar documento
                        <FiPlus />
                    </Button>

                    <Formik
                        initialValues={initialValuesFiltro}
                        onSubmit={loadDocumentos}
                    >
                        {() => (
                            <Form>
                                <FiltrosContainer>
                                    <Calendar
                                        name="periodo"
                                        label="Período"
                                        selectionMode="range"
                                        required
                                    />

                                    <Button className="transparent" type="submit">
                                        Pesquisar
                                        <FiSearch size={16} />
                                    </Button>
                                </FiltrosContainer>
                            </Form>
                        )}
                    </Formik>

                    {documentos ? (
                        <Table
                            headers={[
                                { name: 'descricao', value: 'Descrição' },
                                { name: 'data_formatada', value: 'Data', centered: true },
                                { name: 'competencia_formatada', value: 'Competência', centered: true },
                                { name: 'tipo_descricao', value: 'Tipo', centered: true }
                            ]}
                            data={documentos.map(doc => ({
                                id: doc.id,
                                descricao: doc.descricao,
                                data: new Date(doc.data),
                                data_formatada: format(new Date(doc.data), 'dd/MM/yyyy'),
                                competencia: new Date(doc.competencia),
                                competencia_formatada: format(new Date(doc.competencia), 'MM/yyyy'),
                                tipo: doc.tipo_documento,
                                tipo_descricao: doc.tipo_documento.descricao,
                                arquivo: doc.arquivo,
                                link: doc.arquivo.link
                            }))}
                            actions={[
                                {
                                    action: setPreviewDocumento,
                                    name: 'Visualizar',
                                    icon: FiEye,
                                    iconSize: 16
                                },
                                {
                                    action: setDocumentoEdicao,
                                    name: 'Editar',
                                    icon: FiEdit,
                                    iconSize: 16
                                },
                                {
                                    action: handleExcluir,
                                    name: 'Remover',
                                    icon: FiTrash,
                                    iconSize: 16,
                                    confirmation: 'Deseja realmente excluir este documento?'
                                }
                            ]}
                            filterable
                        />
                    ) : (
                        <Spinner />
                    )}

                    <Modal
                        isOpen={showAdd || !!documentoEdicao}
                        handleClose={() => {
                            setShowAdd(false)
                            setDocumentoEdicao(null)
                        }}
                        title={`${showAdd ? 'Cadastro' : 'Atualização'} de documento`}
                    >
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initialValues}
                            validationSchema={validation}
                            enableReinitialize
                        >
                            {({ setFieldValue, errors, values }) => (
                                <Form style={{ height: '75vh' }}>
                                    <CadastroContainer>
                                        <File
                                            name="arquivo"
                                            onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                            label="Documento"
                                            format="square"
                                            previewSize={['100%', '200px']}
                                            error={errors.arquivo}
                                            getPreloadImage={async () => {
                                                const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                                return arquivo?.link
                                            }}
                                            previewPDF
                                            style={{
                                                gridRow: '1/span 2'
                                            }}
                                        />

                                        <Select
                                            name="tipo"
                                            label="Tipo de documento"
                                            options={tiposDocumento.map(tipo => ({
                                                label: tipo.descricao,
                                                value: tipo.id
                                            }))}
                                            onChange={(selected, meta) => {
                                                setFieldValue(meta.name, selected)
                                            }}
                                        />

                                        <Calendar
                                            name="competencia"
                                            dateFormat="mm/yy"
                                            yearNavigator
                                            view="month"
                                            label="Competência"
                                        />

                                        <Calendar
                                            name="data"
                                            label="Data"
                                        />

                                        <Textbox
                                            name="descricao"
                                            label="Descrição"
                                        />

                                        <div className="button-container">
                                            <Button type="submit" className="white">
                                                Enviar documento
                                            </Button>
                                        </div>
                                    </CadastroContainer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal
                        isOpen={!!previewDocumento}
                        handleClose={() => {
                            setPreviewDocumento(null)
                        }}
                        title={`Visualizando ${previewDocumento?.tipo_descricao} ${previewDocumento?.competencia_formatada}`}
                    >
                        <PreviewContent>
                            {previewDocumento && (
                                <>
                                    {imageFormats.includes(extname(previewDocumento.link).toLowerCase()) ? (
                                        <img src={previewDocumento.link} alt="" />
                                    ) : (
                                        <iframe src={previewDocumento.link} frameBorder="0" title="Anexo" />
                                    )}
                                </>
                            )}
                        </PreviewContent>
                    </Modal>
                </Card>
            </main>
        </Container>
    )
}
