import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: auto auto ${props => props['edit-mode'] ? 200 : 140}px;
		align-items: end;

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;
			align-items: stretch;

			> button {
				margin-top: 14px;
			}
		}

		.label {
			margin: 0;
		}
	}
`
