import {
    Field, FieldArray, Form, Formik
} from 'formik'
import React, { useState, useEffect, useContext } from 'react'
import { FiMinus, FiPlus, FiSearch } from 'react-icons/fi'
import { toast } from 'react-toastify'

import {
    Textbox, Button, Select, Calendar
} from '../../../../components/Form'
import Modal from '../../../../components/Modal'
import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'

import masks from '../../../../util/masks'
import scrollUtils from '../../../../util/scroll'
import { getDateObject } from '../../../../util/date'
import formUtils from '../../../../util/form'

import {
    Container, RegrasDescontoContainer, RegraDesconto, CadastroFaturaAvulsaContainer, BuscaAssociadoContainer, AssociadosListaContainer
} from './styles'
import { openTabSafari } from '../../../../util/file'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

const initialValues = {
    associado_id: '',
    descricao: '',
    vencimento: '',
    juros: '',
    multa: '',
    valor: '',
    descontos: [],
    formas_pagamento: [
        { label: 'Boleto bancário', value: 'bank_slip' },
        { label: 'PIX', value: 'pix' }
    ],
    tipo_cobranca: null,
    competencia: ''
}

const validation = Yup.object({
    associado_id: Yup.number().nullable().required('Busque o associado.'),
    descontos: Yup.array().of(
        Yup.object({
            data: Yup.string(),
            percentual: Yup.string()
        })
    ),
    formas_pagamento: Yup.array().of(
        Yup.object({
            label: Yup.string(),
            value: Yup.string()
        })
    ).required('Selecione uma ou mais formas de pagamento.'),
    vencimento: Yup.string().required('Informe a data de vencimento.'),
    valor: Yup.string().money().required('Informe o valor.'),
    competencia: Yup.string().nullable().required('Defina a competência da cobrança.'),
    tipo_cobranca: Yup.string().ensure().nullable().required('Informe o tipo de cobrança.')
})

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [showForm, setShowForm] = useState(false)
    const [associadosEncontrados, setAssociadosEncontrados] = useState(null)
    const [tiposCobranca, setTiposCobranca] = useState([])

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)

            const {
                juros, multa, formas_pagamento, valor, associado_id, vencimento, descontos, tipo_cobranca, competencia, descricao
            } = values

            const body = {
                vencimento: getDateObject(vencimento),
                associado_id: Number(associado_id),
                descontos: descontos.map(desc => ({
                    data: getDateObject(desc.data),
                    percentual: Number(desc.percentual.replace('%', ''))
                })),
                formas_pagamento: formas_pagamento.map(forma => forma.value),
                valor: masks.unmask.money(valor),
                juros: juros ? Number(juros.replace('%', '')) : undefined,
                multa: multa ? Number(multa.replace('%', '')) : undefined,
                tipo_cobranca,
                competencia,
                descricao
            }

            const { link_fatura } = await api.post('fatura_avulsa_plano_saude', body, authHeaders())

            // forceDownloadFileFromURL(link_fatura)
            openTabSafari(link_fatura)

            toast.success('Cobrança gerada com sucesso.')

            setShowForm(false)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSearchAssociado(setFieldValue) {
        const search = document.querySelector('.busca-associado').value

        const nome = isNaN(search) ? search : undefined
        const crm = isNaN(search) ? undefined : Number(search)

        const associados = await api.get('associado', {
            params: {
                crm,
                nome
            }
        })

        if (!associados) {
            toast.warn('Nenhum associado foi encontrado.')

            return
        }

        if (associados.length === 1) {
            const [associado] = associados

            setFieldValue('associado_id', associado.id)

            document.querySelector('.busca-associado').value = associado.pessoa.nome

            setAssociadosEncontrados(null)

            toast.info(`O associado ${associado.pessoa.nome} foi encontrado em sua busca. Verifique se o nome está correto e continue o preenchimento.`)

            return
        }

        setAssociadosEncontrados(associados)
    }

    async function loadTiposCobranca() {
        const response = await api.get('tipo_cobranca', authHeaders())

        setTiposCobranca(response.map(item => ({
            label: item.descricao,
            value: item.id
        })))
    }

    useEffect(() => {
        loadTiposCobranca()
    }, [])

    useEffect(() => {
        if (showForm) {
            loadTips(setCodigo, 'form_plano_saude_fatura_avulsa')
        } else {
            loadTips(setCodigo, 'form_plano_saude_pesquisa_contribuicao')
        }
    }, [showForm])

    return (
        <>
            <Container>
                <Button onClick={() => { setShowForm(true) }} className="transparent">
                    Gerar fatura
                    <FiPlus size={16} />
                </Button>
            </Container>

            <Modal
                isOpen={showForm}
                handleClose={() => { setShowForm(false) }}
                title="Criar fatura"
            >
                <CadastroFaturaAvulsaContainer>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validation}
                    >
                        {({
                            isSubmitting, values, setFieldValue
                        }) => (
                            <Form>
                                <p style={{ gridArea: 'tip', margin: '20px 8px' }}>{tips.formulario}</p>

                                <BuscaAssociadoContainer>
                                    <Field type="hidden" name="associado_id" />

                                    <Textbox
                                        withoutForm
                                        label="CRM / Nome do associado"
                                        className="busca-associado"
                                        onChange={() => {
                                            setAssociadosEncontrados(null)
                                        }}
                                        onKeyUp={e => {
                                            if (e.keyCode === 13) {
                                                handleSearchAssociado(setFieldValue)
                                            }
                                        }}
                                    />

                                    <Button onClick={() => handleSearchAssociado(setFieldValue)} className="transparent">
                                        Buscar associado
                                        <FiSearch size={16} />
                                    </Button>

                                    {associadosEncontrados?.length > 1 && (
                                        <AssociadosListaContainer>
                                            {associadosEncontrados.map(item => {
                                                const crm = item.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.identificador
                                                const cpf = item.pessoa.documentos.find(doc => doc.tipo_documento.id === 2)?.identificador

                                                return (
                                                    <li
                                                        key={item.id}
                                                        onClick={() => {
                                                            setFieldValue('associado_id', item.id)

                                                            setAssociadosEncontrados(null)

                                                            document.querySelector('.busca-associado').value = `${item.pessoa.nome} - ${crm ? `CRM: ${crm}` : `CPF: ${cpf}`}`
                                                        }}
                                                    >
                                                        {`${item.pessoa.nome} - ${crm ? `CRM: ${crm}` : `CPF: ${cpf}`}`}
                                                    </li>
                                                )
                                            })}
                                        </AssociadosListaContainer>
                                    )}
                                </BuscaAssociadoContainer>

                                {values.associado_id && (
                                    <>
                                        <Select
                                            name="tipo_cobranca"
                                            label="Tipo de cobrança"
                                            options={tiposCobranca}
                                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                        />

                                        <Textbox
                                            name="descricao"
                                            label="Detalhes"
                                            containerStyle={{ gridArea: 'f1' }}
                                        />

                                        <Calendar
                                            name="competencia"
                                            dateFormat="mm/yy"
                                            yearNavigator
                                            view="month"
                                            label="Competência"
                                            yearRange={`${new Date().getFullYear()}:${new Date().getFullYear() + 1}`}
                                        />

                                        <Textbox
                                            name="valor"
                                            label="Valor"
                                            mask={masks.money}
                                        />

                                        <Textbox
                                            name="vencimento"
                                            label="Vencimento"
                                            mask={masks.date}
                                        />

                                        <Textbox
                                            name="multa"
                                            label="Multa (%)"
                                            mask={masks.percent}
                                            hint="Cobrado após o vencimento"
                                        />

                                        <Textbox
                                            name="juros"
                                            label="Juros ao mês (%)"
                                            mask={masks.percent}
                                            hint="Cobrado após o vencimento"
                                        />

                                        <Select
                                            isMulti
                                            placeholder="Boleto, cartão e PIX"
                                            name="formas_pagamento"
                                            label="Formas de pagamento"
                                            options={[
                                                { label: 'Boleto bancário', value: 'bank_slip' },
                                                { label: 'Cartão de crédito', value: 'credit_card' },
                                                { label: 'PIX', value: 'pix' }
                                            ]}
                                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                            containerClass="forma_pagamento"
                                        />

                                        <FieldArray
                                            name="descontos"
                                            render={arrayHelpers => (
                                                <RegrasDescontoContainer>
                                                    <h1>Regras para descontos</h1>

                                                    {values.descontos?.map((_, index) => (
                                                        <RegraDesconto key={index} className={`desconto-item-${index}`}>
                                                            <Textbox
                                                                name={`descontos.${index}.data`}
                                                                label="Pago até a data"
                                                                mask={masks.date}
                                                            />

                                                            <Textbox
                                                                name={`descontos.${index}.percentual`}
                                                                label="Percentual de desconto"
                                                                mask={masks.percent}
                                                            />

                                                            <Button
                                                                fab
                                                                tiny
                                                                className="white"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <FiMinus />
                                                            </Button>
                                                        </RegraDesconto>
                                                    ))}

                                                    <Button
                                                        onClick={() => {
                                                            arrayHelpers.push('')

                                                            scrollUtils.toElementContent('.descontos-container', '.desconto-item')
                                                        }}
                                                        className="transparent"
                                                    >
                                                        Adicionar desconto
                                                    </Button>
                                                </RegrasDescontoContainer>
                                            )}
                                        />

                                        <Button type="submit" className="white" style={{ gridArea: 'button' }} loading={isSubmitting}>
                                            Gerar cobrança
                                            <FiPlus size={16} />
                                        </Button>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </CadastroFaturaAvulsaContainer>
            </Modal>
        </>
    )
}
