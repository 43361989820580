import React, { useContext } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'

import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'

import { RegraContext } from '../../../../contexts/RegraContext'

import { Container } from './styles'

export default function () {
    const { regras, handlePrepareEdit, handleDelete } = useContext(RegraContext)
        
    return (
        <Container>
            {regras ? (
                <Table 
                    headers={[
                        { name: 'descricao', value: 'Nome da regra' },
                        { name: 'status', value: 'Status', centered: true }
                    ]}
                    data={regras?.map(regra => ({
                        ...regra,
                        status: regra.visao ? 'Disponível' : 'Não atendido'
                    })) || []}
                    actions={[
                        {
                            name: 'Editar',
                            action: handlePrepareEdit,
                            icon: FaEdit,
                            iconSize: 15
                        },
                        {
                            name: 'Excluir',
                            action: handleDelete,
                            icon: FaTrash,
                            iconSize: 13,
                            confirmation: 'Deseja realmente excluir esta regra do sistema?'
                        }
                    ]}
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
