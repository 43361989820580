import React, {
    useState, useEffect, useCallback, useContext
} from 'react'
import { toast } from 'react-toastify'
import {
    Tab, Tabs, TabList, TabPanel
} from 'react-tabs'
import { FiPlus } from 'react-icons/fi'

import { api, authHeaders } from '../../../../services/api'

import scrollUtils from '../../../../util/scroll'
import formUtils from '../../../../util/form'
import masks from '../../../../util/masks'

import { GlobalContext } from '../../../../contexts/GlobalContext'

import Card from '../../../../components/Card'
import Modal from '../../../../components/Modal'
import { Button } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'

import Pesquisa from './Pesquisa'
import Listagem from './Listagem'
import Cadastro from './Cadastro'

import { Container, ButtonContainer } from './styles'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

const baseFormState = {
    fornecedor: null,
    evento: null,
    conta_corrente: null,
    forma_pagamento: '',
    competencia: '',
    data_consolidacao: '',
    valor: '',
    historico: '',
    caixa: false,
    parcelado: false,
    parcelas: '',
    cozinha: false,
    fornecedor_pendencia: false,
    fornecedor_pendencia_observacao: '',
    arquivo: null,
    tipo_documento: null
}

export default function () {
    const { user } = useContext(GlobalContext)
    const { setCodigo } = useContext(TipContext)

    const [editId, setEditID] = useState(null)
    const [lancamentos, setLancamentos] = useState(null)
    const [initialValues, setInitialValues] = useState(baseFormState)
    const [showForm, setShowForm] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [editable, setEditable] = useState(false)
    const [searchData, setSearchData] = useState(null)

    async function loadLancamentos() {
        const response = await api.get('lancamento', authHeaders())

        setLancamentos(response)
    }

    useEffect(() => {
        loadLancamentos()
    }, [])

    useEffect(() => {
        if (user) {
            setEditable(user.perfil.id !== 17)
        }
    }, [user])

    useEffect(() => {
        if (showForm) {
            loadTips(setCodigo, 'form_lancamentos_cadastro')
        } else {
            loadTips(setCodigo, 'form_lancamentos_pesquisa')
        }
    }, [showForm])

    const handlePrepareEdit = useCallback(async id => {
        setShowForm(true)
        setShowSpinner(true)
        setEditID(id)

        scrollUtils.toTop(0, '.card')

        const lancamento = await api.get(`lancamento/${id}`, authHeaders())

        const {
            fornecedor,
            evento,
            conta_corrente,
            forma_pagamento = '',
            competencia,
            data_consolidacao,
            valor,
            historico = '',
            caixa = false,
            fornecedor_pendencia = false,
            cozinha = false,
            fornecedor_pendencia_observacao = '',
            documentos,
            consolidado
        } = lancamento

        setInitialValues({
            fornecedor: fornecedor ? { label: fornecedor.pessoa.nome, value: fornecedor.id } : '',
            evento: { label: evento.nome, value: evento.id },
            conta_corrente: conta_corrente ? {
                label: `${conta_corrente.dados_bancarios.instituicao.sigla} - AG ${conta_corrente.dados_bancarios.agencia} C/C ${conta_corrente.dados_bancarios.conta}`,
                value: conta_corrente.id
            } : '',
            forma_pagamento: forma_pagamento || '',
            competencia: competencia ? new Date(competencia) : '',
            data_consolidacao: new Date(data_consolidacao),
            valor: masks.maskApply.currency(valor),
            historico: historico || '',
            caixa,
            fornecedor_pendencia,
            cozinha,
            consolidado,
            fornecedor_pendencia_observacao: fornecedor_pendencia_observacao || '',
            documentos: documentos.map(doc => ({
                arquivo: doc.arquivo.id,
                tipo_documento: {
                    label: doc.tipo_documento.descricao,
                    value: doc.tipo_documento.id
                }
            }))
        })

        setShowSpinner(false)
    }, [])

    async function handleSearch(values) {
        try {
            setSearchData(values)

            values = formUtils.extractFormValues(values)

            const result = await api.post('lancamento_query', values, authHeaders())

            setLancamentos(result)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function refreshSearch() {
        handleSearch(searchData)
    }

    async function handleDelete(id) {
        try {
            await api.delete(`lancamento/${id}`, authHeaders())

            await refreshSearch()

            toast.success('Lançamento excluído!')
        } catch (e) {
            return false
        }

        return true
    }

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Lançamentos contábeis</h1>

                        {editable ? (
                            <ButtonContainer>
                                <Button
                                    onClick={() => {
                                        setShowForm(true)

                                        setEditID(null)
                                    }}
                                    className="transparent"
                                >
                                    Cadastrar lançamento
                                    <FiPlus size={18} />
                                </Button>
                            </ButtonContainer>
                        ) : (
                            <div />
                        )}

                        <Tabs>
                            <TabList>
                                <Tab>Consulta</Tab>
                            </TabList>

                            <TabPanel>
                                <Pesquisa onSearch={handleSearch} />
                            </TabPanel>
                        </Tabs>

                        <Listagem
                            editId={editId}
                            lancamentos={lancamentos}
                            handlePrepareEdit={handlePrepareEdit}
                            handleDelete={handleDelete}
                            userData={user}
                        />

                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={showForm}
                handleClose={() => {
                    setShowForm(false)

                    setInitialValues(baseFormState)
                }}
                title={`${!editId ? 'Cadastro' : 'Edição'} de Lançamento`}
                closeOnOverlayClick={false}
            >
                {!showSpinner ? (
                    <Cadastro
                        editId={editId}
                        initialValues={initialValues}
                        handleClear={() => {
                            setEditID(null)

                            setInitialValues(baseFormState)
                        }}
                        reload={() => {
                            refreshSearch()

                            setEditID(null)

                            setInitialValues(baseFormState)

                            setShowForm(false)
                        }}
                    />
                ) : (
                    <Spinner
                        containerStyle={{ height: '40vh' }}
                    />
                )}
            </Modal>
        </>
    )
}
