import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile, FiFileText } from 'react-icons/fi'
import { format } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar, Textbox
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeCalendarGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    contaCorrente: '',
    pagamento: '',
    tipo: '',
    fornecedor: '',
    evento: '',
    palavraChave: '',
    consolidado: ''
}

const validation = Yup.object().shape({
    pagamento: Yup.string().nullable(),
    contaCorrente: Yup.string().ensure(),
    tipo: Yup.string().ensure(),
    fornecedor: Yup.string().nullable(),
    evento: Yup.string().nullable(),
    palavraChave: Yup.string().nullable(),
    consolidado: Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de Lançamentos'

export default function (props) {
    const [contas, setContas] = useState([])
    const [fornecedores, setFornecedores] = useState([])
    const [eventos, setEventos] = useState([])
    const [isSubmittingPlanilha, setIsSubmittingPlanilha] = useState(false)

    async function loadContas() {
        const response = await api.get('conta_sindicato', authHeaders())

        setContas(response)
    }

    async function loadFornecedores() {
        const response = await api.get('fornecedor', authHeaders())

        setFornecedores(response)
    }

    async function loadEventos() {
        const response = await api.get('evento_contabil', authHeaders())

        setEventos(response)
    }

    useEffect(() => {
        loadFornecedores()
        loadEventos()
        loadContas()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            contaCorrente,
            pagamento,
            evento,
            fornecedor,
            tipo,
            palavraChave,
            consolidado
        } = values

        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioLancamentos',
                parametros: {
                    idConta: contaCorrente?.value || '',
                    idEvento: evento ? evento.map(e => e.value).join(',') : '',
                    idFornecedor: fornecedor ? fornecedor.map(f => f.value).join(',') : '',
                    historico: palavraChave || '',
                    caixa: tipo?.value === 'MV' ? '1' : null,
                    tipo: tipo?.value === 'MV' ? null : tipo?.value,
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    consolidado: consolidado?.value === 0 || consolidado?.value === 1 ? consolidado?.value.toString() : null,
                    fornecedor_pendencia: consolidado?.value === 2 ? '1' : null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    const handleSubmitPlanilha = useCallback(async (values, resetForm) => {
        setIsSubmittingPlanilha(true)

        const {
            contaCorrente,
            pagamento,
            evento,
            fornecedor,
            tipo,
            palavraChave,
            consolidado
        } = values

        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null

        try {
            const response = await api.post('relatorio/lancamento_excel', {
                relatorio: 'RelatorioLancamentos',
                parametros: {
                    idConta: contaCorrente?.value || null,
                    idEvento: evento ? evento.map(e => e.value).join(',') : null,
                    idFornecedor: fornecedor ? fornecedor.map(f => f.value).join(',') : null,
                    historico: palavraChave || null,
                    caixa: tipo?.value === 'MV' ? '1' : null,
                    tipo: tipo?.value === 'MV' ? null : tipo?.value,
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    consolidado: consolidado?.value === 0 || consolidado?.value === 1 ? consolidado?.value.toString() : null,
                    fornecedor_pendencia: consolidado?.value === 2 ? '1' : null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            if (response.type === 'application/json') {
                const fr = new FileReader()

                fr.onload = function (e) {
                    const json = e.target.result
                    toast.warn(JSON.parse(json).msg)
                }
                fr.readAsText(response)
            } else {
                downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')
            }

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }

        setIsSubmittingPlanilha(false)
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm, values
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="pagamento"
                            label="Periodo de busca"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f1' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeCalendarGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="tipo"
                            label="Tipo de evento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Receitas', value: 'R' },
                                { label: 'Receitas fixas', value: 'RF' },
                                { label: 'Receitas variáveis', value: 'RV' },
                                { label: 'Despesas', value: 'D' },
                                { label: 'Despesas fixas', value: 'DF' },
                                { label: 'Despesas variáveis', value: 'DV' },
                                { label: 'Movimento de Caixa', value: 'MV' }
                            ]}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="contaCorrente"
                            label="Conta corrente"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={contas.map(({ id, dados_bancarios }) => ({
                                label: `${dados_bancarios.instituicao.sigla} - AG ${dados_bancarios.agencia} C/C ${dados_bancarios.conta}`,
                                value: id
                            }))}
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Select
                            name="fornecedor"
                            label="Fornecedor"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={fornecedores.map(({ id, pessoa }) => ({
                                label: pessoa.nome,
                                value: id
                            }))}
                            isMulti
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Select
                            name="consolidado"
                            label="Tipo de lançamento"
                            placeholder="Todos os lançamentos"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Todos os lançamentos', value: '' },
                                { label: 'Não consolidados', value: 0 },
                                { label: 'Consolidados', value: 1 },
                                { label: 'Com pendências', value: 2 }
                            ]}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Select
                            name="evento"
                            label="Evento"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            options={eventos.map(({ id, nome, conta_contabil }) => ({
                                label: `${nome}${conta_contabil?.codigo_reduzido ? ` - ${conta_contabil.codigo_reduzido}` : ''}`,
                                value: id
                            }))}
                            isMulti
                            containerStyle={{ gridArea: 'f6' }}
                        />

                        <Textbox
                            label="Palavra-chave"
                            name="palavraChave"
                            containerStyle={{ gridArea: 'f7' }}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => handleSubmitPlanilha(values, resetForm)}
                                loading={isSubmittingPlanilha}
                                className="white"
                            >
                                Gerar planilha
                                <FiFileText size={20} />
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
