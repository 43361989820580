import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		> p {
			grid-column: 1/span 3;
			margin: 8px 8px 0;
		}

		.checkbox {
			grid-column: 1/span 3;
			margin: 16px auto;
		}

		.table-container {
			grid-column: 1/span 3;
			margin: 8px;
			width: calc(100% - 16px);
		}

		.react-tabs {
			width: calc(100% - 16px);
			margin: 20px 8px 10px;
			grid-column: 1/span 3;

			.react-tabs__tab-panel > p {
				margin: 0 8px;
			}

			.react-tabs__tab-list {
				display: flex;
				flex-wrap: nowrap;
				overflow-x: scroll;
				overflow-y: hidden;
				margin-bottom: -1px;
				width: calc(100% - 32px);
			}
		}

		.badge {
			background-color: #ffeb3b;
			border-radius: 6px;
			color: #424242;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			font-size: 12px;
			margin-top: 0;
			width: fit-content;
			padding: 8px;
			line-height: 18px;

			svg {
				color: #f44336;
				margin: 0 2px 0 3px;
			}

			&.red {
				background-color: #f44336;
				color: #fff;
				justify-self: center;
				margin: 16px 8px;
			}
		}

		.badge.white {
			background-color: #fff;
			display: inline;
			font-size: 13px;
			grid-column: 1/span 3;
			margin: 16px 8px 0;
		}

		button[type=button] {
			margin-top: 20px;
			justify-self: center;
			grid-column: 1/span 3;
		}

		button[type=submit] {
			justify-self: flex-end;
			grid-column: 1/span 3;
		}

		.button-container {
			grid-column: 1/span 3;
			display: flex;
			justify-content: flex-end;
			margin: 5px 8px;
		}

		@media(max-width: 900px) {
			.field {
				grid-column: 1/span 2;
			}
		}

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const DocumentosTemplatesContainer = styled.div`
	display: flex;
	grid-column: 1/span 3;
	width: calc(100% - 16px);
	flex-wrap: wrap;

	p {
		width: 100%;
		margin: 5px 0;

		svg {
			margin: 0 4px;
			font-size: 16px;

			&:hover {
				cursor: text;
				opacity: 1;
			}
		}
	}

	a {
		margin: 5px 16px 5px 0;
		text-align: center;
	}

	@media(max-width: 700px) {
		flex-direction: column;

		a {
			width: 100%;
			margin: 5px 0;
		}
	}
`
export const PortabilidadeContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 8px;
	grid-column: 1/span 3;

	.select {
		margin: 5px 0 10px;
		max-width: 330px;
	}

	button {
		margin: 8px 0;
		max-width: 280px;
		align-self: center;
	}
`

export const CarteirinhaContainer = styled.div`
	display: grid;
	grid-gap: 16px;
	grid-column: 1/span 3;
	align-items: flex-start;
	grid-template-columns: 1fr 1fr;
	margin: 0 100px;

	.file,
	.textbox {
		margin: 5px 0;
		width: 100%;
	}

	.carteirinha {
		grid-column: 1/span 2;
		justify-self: center;
		max-width: 400px;
	}

	@media(max-width: 900px) {
		display: flex;
		flex-direction: column;
	}

	@media(max-width: 700px) {
		margin: 0;
	}
`

export const RegrasPortabilidadeContainer = styled.div`
	display: flex;
	justify-content: center;

	iframe {
		width: 100%;
		height: 80vh;
	}
`

export const ComprovantesPagamentoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
`

export const FiliacaoContainer = styled.div`
	display: flex;
	flex-direction: column;
	grid-column: 1/span 3;
	
	> h1 {
		font-size: 16px;
		width: 100%;
	}

	.nomes-pais {
		display: flex;
		max-width: 800px;

		@media(max-width: 800px) {
		flex-direction: column;
		}
	}
`
