import React, {
    useCallback, useState, useEffect, useContext
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { lastDayOfMonth } from 'date-fns'

import date from '../../../../../util/date'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import {
    Button, Select, Calendar, Textbox
} from '../../../../../components/Form'

import { Container } from './styles'
import { TipContext } from '../../../../../contexts/TipContext'

const validation = Yup.object().shape({
    inicio: Yup.date().typeError('Data inválida.').required('Campo obrigatório.'),
    fim: Yup.date().typeError('Data inválida.').required('Campo obrigatório.'),
    tipo: Yup.string().ensure(),
    conta: Yup.string().ensure(),
    fornecedor: Yup.string().ensure(),
    evento: Yup.string().ensure(),
    historico: Yup.string(),
    tipo_lancamento: Yup.string().ensure()
})

export default function ({ onSearch }) {
    const { tips } = useContext(TipContext)

    const [tipos, setTipos] = useState([])
    const [contas, setContas] = useState([])
    const [eventos, setEventos] = useState([])
    const [fornecedores, setFornecedores] = useState([])
    const [initialValues, setInitialValues] = useState({
        inicio: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        fim: lastDayOfMonth(new Date()),
        tipo: '',
        conta: '',
        fornecedor: '',
        historico: '',
        evento: '',
        tipo_lancamento: ''
    })

    async function loadEventos() {
        const response = await api.get('evento_contabil', authHeaders())

        setEventos(response)
    }

    useEffect(() => {
        async function loadTipos() {
            const response = await api.get('tipo_evento_contabil', authHeaders())

            setTipos([
                { sigla: 'R', descricao: 'Receita' },
                ...response.slice(0, 2),
                { sigla: 'D', descricao: 'Despesa' },
                ...response.slice(2, 4),
                { sigla: 'MV', descricao: 'Movimento de caixa' }
            ])
        }

        loadTipos()
    }, [])

    useEffect(() => {
        async function loadContas() {
            const response = await api.get('conta_sindicato', authHeaders())

            setContas(response)
        }

        loadContas()
    }, [])

    useEffect(() => {
        async function loadFornecedores() {
            const response = await api.get('fornecedor', authHeaders())

            setFornecedores(response.map(fornecedor => ({
                id: fornecedor.id,
                nome: fornecedor.pessoa.nome
            })))
        }

        loadFornecedores()
    }, [])

    useEffect(() => {
        loadEventos()
    }, [])

    const search = useCallback(async values => {
        onSearch(values)
    }, [onSearch])

    const handleSubmit = useCallback(async values => {
        if (values.inicio > values.fim) {
            toast.warn('A data de início deve ser menor ou igual à data de fim.')
            return
        }

        await search(values)
    }, [search])

    const handlePresetFilter = useCallback((preset, submitForm) => {
        const currentMonth = new Date().getMonth()
        let month
        let anoInicio
        let anoFim

        switch (preset) {
            case 'mes passado':
                month = currentMonth > 0 ? currentMonth - 1 : 11
                anoInicio = currentMonth > 0 ? new Date().getFullYear() : new Date().getFullYear() - 1
                anoFim = currentMonth > 0 ? new Date().getFullYear() : new Date().getFullYear() - 1
                break
            default:
                month = currentMonth
                anoInicio = new Date().getFullYear()
                anoFim = new Date().getFullYear()
                break
        }

        setInitialValues({
            inicio: new Date(
                anoInicio,
                month,
                1
            ),
            fim: lastDayOfMonth(
                new Date(
                    anoFim,
                    month
                )
            ),
            tipo: '',
            conta: '',
            fornecedor: '',
            historico: '',
            tipo_lancamento: ''
        })

        submitForm()
    }, [])

    return (
        <Container className="animated fadeIn faster">
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, submitForm, resetForm
                }) => (
                    <Form>
                        <p style={{ gridArea: 'tip', margin: 8 }}>
                            {tips?.formulario}
                        </p>

                        <Calendar
                            name="inicio"
                            dateFormat="dd/mm/yy"
                            label="Data inicial"
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Calendar
                            name="fim"
                            dateFormat="dd/mm/yy"
                            label="Data final"
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="tipo"
                            label="Tipo de evento"
                            placeholder="Todos os tipos de evento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                {
                                    label: 'Todos os tipos de evento',
                                    value: ''
                                },
                                ...tipos.map(tipo => ({
                                    label: tipo.descricao,
                                    value: tipo.sigla
                                }))
                            ]}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Select
                            name="conta"
                            label="Conta corrente"
                            placeholder="Todas as contas"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                {
                                    label: 'Todas as contas',
                                    value: ''
                                },
                                ...contas.map(conta => ({
                                    label: conta.todas ? conta.descricao : `${conta.dados_bancarios.instituicao.sigla} c/c ${conta.dados_bancarios.agencia} ${conta.dados_bancarios.conta}`,
                                    value: conta.id
                                }))
                            ]}
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Select
                            name="fornecedor"
                            label="Parceiro"
                            placeholder="Todos os parceiros"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                {
                                    label: 'Todos os parceiros',
                                    value: ''
                                },
                                ...fornecedores.map(fornecedor => ({
                                    label: fornecedor.nome,
                                    value: fornecedor.id
                                }))
                            ]}
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Select
                            name="tipo_lancamento"
                            label="Tipo de lançamento"
                            placeholder="Todos os lançamentos"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                {
                                    label: 'Todos os lançamentos',
                                    value: ''
                                },
                                {
                                    label: 'Não consolidados',
                                    value: 0
                                },
                                {
                                    label: 'Consolidados',
                                    value: 1
                                }
                                // {
                                //     label: 'Com pendências',
                                //     value: 2
                                // }
                            ]}
                            containerStyle={{ gridArea: 'f6' }}
                        />

                        <Select
                            name="evento"
                            label="Evento"
                            placeholder="Todos os eventos"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                {
                                    label: 'Todos os eventos',
                                    value: ''
                                },
                                ...eventos.map(evento => ({
                                    label: `${evento.nome}${evento.conta_contabil?.codigo_reduzido ? ` - ${evento.conta_contabil.codigo_reduzido}` : ''}`,
                                    value: evento.id
                                }))
                            ]}
                            containerStyle={{ gridArea: 'f7' }}
                        />

                        <Textbox
                            label="Palavra-chave (descrição)"
                            name="historico"
                            containerStyle={{ gridArea: 'f8' }}
                        />

                        <div className="button-months-container">
                            <Button className="transparent" onClick={() => handlePresetFilter('mes passado', submitForm)}>
                                {`Todos de ${date.months.find(month => month.value === (new Date().getMonth() === 0 ? 12 : new Date().getMonth())).label}`}
                            </Button>

                            <Button className="transparent" onClick={() => handlePresetFilter('mes atual', submitForm)}>
                                {`Todos de ${date.months.find(month => month.value === new Date().getMonth() + 1).label}`}
                            </Button>
                        </div>

                        <div className="button-container">
                            <Button
                                onClick={() => {
                                    resetForm()
                                }}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Pesquisar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
