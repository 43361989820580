import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	.table-container,
	.spinner-container {
		grid-area: table;
		margin-top: 0;
	}

	.instrucoes {
		margin: 24px 8px 0;
	}
`

export const EventDetails = styled.div`
	h1 {
		font-size: 17px;
	}

	h2 {
		font-size: 14px;
		margin: 0 8px;
		line-height: 24px;
	}
`
