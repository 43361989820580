import styled from 'styled-components'

export const Container = styled.div`
	form {
		.action-footer {
			grid-area: button;
		}

		@media(max-width: 900px) {
			display: flex !important;
			flex-direction: column;
		}
	}
`
