import { FieldArray, Form, Formik } from 'formik'
import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { FiMinus } from 'react-icons/fi'

import Card from '../../../components/Card'
import {
    Button, Textbox, Select, Checkbox
} from '../../../components/Form'
import { api, authHeaders } from '../../../services/api'
import Yup from '../../../services/yup'
import masks from '../../../util/masks'
import formUtils from '../../../util/form'
import { getDateObject } from '../../../util/date'
import {
    Container, RegrasDescontoContainer, RegraDesconto, Badge
} from './styles'
import ProgressBar from '../../../components/ProgressBar'
import Table from '../../../components/Table'
import { pluralize } from '../../../util/string'
import { loadTips } from '../../../util/tip'
import { TipContext } from '../../../contexts/TipContext'
import Spinner from '../../../components/Spinner'

const currentYear = new Date().getFullYear()
const sliceSize = 10

const initialValues = {
    vencimento: '',
    ano: { label: currentYear, value: currentYear },
    descontos: [],
    multa: '',
    juros: '',
    valor: '',
    formas_pagamento: [
        { label: 'Boleto bancário', value: 'bank_slip' },
        { label: 'PIX', value: 'pix' }
    ],
    reenvio: false
}

const validation = Yup.object({
    vencimento: Yup.string().required('Informe da data de vencimento.'),
    ano: Yup.string().ensure().required('Informe o ano.'),
    descontos: Yup.array().of(
        Yup.object({
            data: Yup.string(),
            percentual: Yup.string()
        })
    ),
    multa: Yup.string(),
    juros: Yup.string(),
    valor: Yup.string().required('Insira o valor integral da anuidade.'),
    formas_pagamento: Yup.string().ensure().required('Informe as formas de pagamento permitidas.')
})

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [totalRegistros, setTotalRegistros] = useState(null)
    const [totalRegistrosReenvio, setTotalRegistrosReenvio] = useState(null)
    const [progresso, setProgresso] = useState(null)
    const [inconsistencias, setInconsistencias] = useState([])

    async function loadTotalAnuidadesAGerar(ano) {
        try {
            setTotalRegistros(null)
            setTotalRegistrosReenvio(null)

            const response = await api.post('contribuicao/anuidades', { ano }, {
                params: { count: 1 },
                ...authHeaders()
            })

            const responseReenvio = await api.post('contribuicao/anuidades/reenvio', { ano }, {
                params: { count: 1 },
                ...authHeaders()
            })

            setTotalRegistros(response.count)
            setTotalRegistrosReenvio(responseReenvio.count)
        } catch (e) {
            toast.error(e.msg)

            setTotalRegistros(undefined)
            setTotalRegistrosReenvio(undefined)
        }
    }

    async function loadInconsistencias() {
        const response = await api.get('inconsistencia_cobranca', authHeaders())

        setInconsistencias(response.map(item => ({
            id: item.id,
            nome: item.pessoa.nome,
            motivo: item.motivo
        })))
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            values = formUtils.extractFormValues(values)

            if (!totalRegistros && !totalRegistrosReenvio) {
                toast.warn('Não há nenhum associado a receber faturas de anuidade.')

                return
            }

            values.formas_pagamento = values.formas_pagamento.map(item => item.value)
            values.descontos = values.descontos.map(item => ({
                data: getDateObject(item.data),
                percentual: masks.unmask.percent(item.percentual)
            }))
            values.multa = masks.unmask.percent(values.multa)
            values.juros = masks.unmask.percent(values.juros)
            values.valor = masks.unmask.money(values.valor)
            values.quantidade = 1

            const total = values.reenvio ? totalRegistrosReenvio : totalRegistros

            for (let i = 0; i < total; i += sliceSize) {
                console.log(`Iniciando rodada ${Math.round((i / 10)) + 1}...`)

                setProgresso(Math.trunc((i * 100) / total))

                await api.post(values.reenvio ? 'contribuicao/anuidades/reenvio' : 'contribuicao/anuidades', values, {
                    params: {
                        quantidade: sliceSize,
                        start: i === 0 ? 1 : 0
                    },
                    ...authHeaders()
                })
            }

            setProgresso(100)

            resetForm()

            loadInconsistencias()

            loadTotalAnuidadesAGerar(currentYear)

            setTimeout(() => {
                setProgresso(null)
            }, 5000)

            toast.success('As cobranças foram enviadas com sucesso.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadTotalAnuidadesAGerar(currentYear)

        loadInconsistencias()

        loadTips(setCodigo, 'form_geracao_anuidades')
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Gerar cobranças anuais de contribuição social</h1>

                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validation}
                    >
                        {({ setFieldValue, values, isSubmitting }) => (
                            <Form>
                                <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                                <Select
                                    label="Ano"
                                    name="ano"
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)
                                        setFieldValue('vencimento', '')

                                        setTotalRegistros(0)

                                        loadTotalAnuidadesAGerar(selected.value)
                                    }}
                                    options={[
                                        { label: new Date().getFullYear(), value: new Date().getFullYear() },
                                        { label: new Date().getFullYear() + 1, value: new Date().getFullYear() + 1 }
                                    ]}
                                />

                                <Textbox
                                    name="valor"
                                    label="Valor integral da anuidade"
                                    mask={masks.money}
                                />

                                <Textbox
                                    label="Vencimento"
                                    name="vencimento"
                                    mask={masks.date}
                                    onBlur={e => {
                                        const date = getDateObject(e.target.value)

                                        if (date && date.getFullYear() !== values.ano.value) {
                                            toast.warn('Atenção! O ano selecionado não corresponde ao ano inserido na data de vencimento das faturas.', {
                                                autoClose: 7000
                                            })
                                        }
                                    }}
                                />

                                <Checkbox
                                    label="Reenviar faturas expiradas"
                                    name="reenvio"
                                    handleChange={e => {
                                        setFieldValue(e.target.name, e.target.checked)
                                    }}
                                />

                                <Textbox
                                    name="juros"
                                    label="Juros ao mês (%)"
                                    mask={masks.percent}
                                    hint="Cobrado após o vencimento"
                                    containerStyle={{ gridArea: 'f4' }}
                                />

                                <Textbox
                                    name="multa"
                                    label="Multa (%)"
                                    mask={masks.percent}
                                    hint="Cobrado após o vencimento"
                                    containerStyle={{ gridArea: 'f6' }}
                                />

                                <Select
                                    isMulti
                                    placeholder="Boleto, cartão e PIX"
                                    name="formas_pagamento"
                                    label="Formas de pagamento"
                                    options={[
                                        { label: 'Boleto bancário', value: 'bank_slip' },
                                        { label: 'Cartão de crédito', value: 'credit_card' },
                                        { label: 'PIX', value: 'pix' }
                                    ]}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    containerStyle={{ gridArea: 'f5' }}
                                />

                                <FieldArray
                                    name="descontos"
                                    render={arrayHelpers => (
                                        <RegrasDescontoContainer>
                                            <h1>Regras para descontos</h1>

                                            {values.descontos?.map((_, index) => (
                                                <RegraDesconto key={index} className={`desconto-item-${index}`}>
                                                    <Textbox
                                                        label="Pago até a data"
                                                        name={`descontos.${index}.data`}
                                                        mask={masks.date}
                                                    />

                                                    <Textbox
                                                        name={`descontos.${index}.percentual`}
                                                        label="Percentual de desconto"
                                                        mask={masks.percent}
                                                    />

                                                    <Button
                                                        fab
                                                        tiny
                                                        className="white"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        <FiMinus />
                                                    </Button>
                                                </RegraDesconto>
                                            ))}

                                            <Button
                                                onClick={() => {
                                                    arrayHelpers.push('')
                                                }}
                                                className="transparent"
                                            >
                                                Adicionar desconto
                                            </Button>
                                        </RegrasDescontoContainer>
                                    )}
                                />

                                {totalRegistros === null ? (
                                    <Spinner
                                        label="Calculando quantidade de cobranças..."
                                        containerClass="badge-spinner"
                                    />
                                ) : totalRegistros === undefined ? <div /> : (
                                    <Badge className="animated fadeIn">
                                        <div dangerouslySetInnerHTML={{ __html: `<b>${pluralize(totalRegistros, 'fatura', 'faturas', 'Nenhuma')} </b> a gerar em <b>${values.ano.value}. ${pluralize(totalRegistrosReenvio, 'fatura expirada', 'faturas expiradas', 'Nenhuma')} </b> a sere(m) regerada(s) em <b>${values.ano.value}</b>` }} />
                                    </Badge>
                                )}

                                {inconsistencias.length > 0 && (
                                    <Table
                                        caption="Não foram geradas cobranças para os seguintes associados. Resolva as pendências apontadas e tente gerar as cobranças novamente."
                                        headers={[
                                            { name: 'nome', value: 'Associado' },
                                            { name: 'motivo', value: 'Motivo', centered: true }
                                        ]}
                                        data={inconsistencias}
                                        style={{ gridArea: 'inconsistencias' }}
                                        className="animated fadeIn"
                                    />
                                )}

                                <Button
                                    type="submit"
                                    className="transparent"
                                    disabled={isSubmitting || (progresso > 0 && progresso < 100) || (!totalRegistros && !totalRegistrosReenvio)}
                                >
                                    Gerar cobranças
                                </Button>

                                <ProgressBar
                                    visible={progresso !== null}
                                    percentage={progresso}
                                />
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
