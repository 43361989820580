import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'

import Yup from '../../../services/yup'
import { api, authHeaders } from '../../../services/api'

import formUtils from '../../../util/form'

import ActionFooter from '../../ActionFooter'
import {
    Button, Textbox, Select
} from '../../Form'

import { Container } from './styles'

const baseInitialValues = {
    instituicao: '',
    matricula: ''
}

const validation = Yup.object({
    instituicao: Yup.string().required('Selecione a instituição.'),
    matricula: Yup.string().required('Digite a matrícula.')
})

export default function ({ defaultValues, pessoaId, callback }) {
    const [instituicoes, setInstituicoes] = useState([])
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    useEffect(() => {
        async function loadInstituicoes() {
            const response = await api.get('instituicao')

            setInstituicoes(response.filter(instituicao => instituicao.desconto && !['banco', 'microempresa'].includes(instituicao.tipo_instituicao.descricao) && (instituicao.id !== 13636)))
        }

        loadInstituicoes()

        if (defaultValues) {
            setInitialValues(defaultValues.dadosPagamento)
        }
    }, [defaultValues])

    async function handleSubmit(values) {
        try {
            const data = formUtils.extractFormValues(values)

            const body = {
                tipo_pagamento_id: 2,
                dados_pagamento: {
                    instituicao_id: data.instituicao,
                    matricula: data.matricula
                }
            }

            await api.put(`pagamento/${pessoaId}`, body, authHeaders())

            toast.success('Dados atualizados.')

            callback()
        } catch (e) {
            toast.error('Ocorreu um erro.')
        }
    }

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <Select
                            name="instituicao"
                            id="instituicao_debito"
                            label="Instituicao"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={instituicoes.map(instituicao => ({
                                label: instituicao.nome_fantasia,
                                value: instituicao.id
                            }))}
                            containerClass="instituicao-container"
                        />

                        <Textbox
                            label="Matrícula"
                            name="matricula"
                        />

                        <ActionFooter>
                            <Button type="submit" loading={isSubmitting} className="white">
                                Salvar alterações
                                <FaRegSave />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
