import styled from 'styled-components'

export const MovimentacaoContainer = styled.div`
	margin: 18px 8px 0;
	display: flex;
	flex-direction: column;

	form {
		display: flex;
		flex-direction: column;
		grid-template-columns: 1fr 1fr;
		gap: 16px;

		.selecao-setor {
			display: flex;
			gap: 16px;
		}

		.selecao-acao {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 64px;

			button {
				display: flex;
				flex-direction: column;
				gap: 6px;
				height: fit-content;
				width: 200px;
				font-size: 16px;
			}

			.tip {
				font-size: 11px;
			}
		}

		> p,
		.textarea,
		.button-container {
			grid-column: 1/span 2;
		}
	}

	.select {
		max-width: 400px;
		width: 100%;
	}

	button {
		align-self: end;
	}
`

export const HeaderContainer = styled.div`
	display: grid;
	grid-template-columns: 4fr 1fr;

	> div {
		display: flex;
		flex-direction: column;
		margin: 0 8px;

		p {
			margin-top: 10px;
		}
	}

	button {
		width: 220px;
		justify-self: end;
	}

	@media(max-width: 700px) {
		display: flex;
		flex-direction: column;

		button {
			margin-top: 18px;
		}
	}
`

export const BadgeContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
`

export const BadgeAlerta = styled.div`
	padding: 12px;
	border-radius: 6px;
	background-color: #ffeb3b;
	color: #424242;
	margin: 0 8px 16px;
	width: fit-content;
`

export const MensagensContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 12px;

	.textarea {
		width: 319px;
	}

	.checkbox {
		margin-top: 0;
	}
`

export const SetoresContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px 0;

	textarea {
		margin-bottom: 0;
	}

	.tip {
		font-size: 11px;
		width: 319px;
		margin: 0 8px;
	}
`

export const Dropdown = styled.div.attrs({
    className: 'recados-lista animated zoomIn'
})`
	position: absolute;
	top: 240px;
	right: 88px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	color: #424242;
	border-radius: 4px;
	max-width: 500px;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
	transform-origin: 100% 0%;
	animation-duration: 200ms;
	max-height: 500px;
	overflow-y: auto;

	@media(max-width: 600px) {
		top: 60px;
		right: 20px;
		left: 20px;
		max-height: 400px;
	}

	.item {
		padding: 12px;
		display: grid;
		grid-template-columns: auto 26px;
		gap: 8px;
		align-items: center;
		border-top: solid 1px #0002;
		cursor: pointer;
		width: 220px;

		b {
			font-weight: 500;
			font-size: 12px;
		}
		
		.info {
			font-size: 14px;
			color: #424242;
			text-overflow: ellipsis;
			width: 168px;
			white-space: nowrap;
  			overflow: hidden;
		}

		svg {
			color: #999;
		}

		&:hover {
			background-color: #eee;
		}

		&:first-child {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		&:last-child {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	@media(max-width: 700px) {
		position: relative;
		top: unset;
		right: unset;
		width: 220px;
	}

	@media(max-width: 600px) {
		transform: translateX(-20px);
	}
`

export const ModalConfirmacaoMovimentacaoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 8px;

	.button-container {
		align-self: end;
		display: flex;
		gap: 8px;
	}
`

export const FormAlertaContainer = styled.div`
	display: grid;
	align-items: end;
	grid-template-columns: 1fr 1fr 1fr;

	.textarea {
		grid-column: 1/span 3;
	}

	.input-titulo {
		grid-column: 2/span 2;
	}

	button {
		grid-column: 2/span 3;
		grid-row: 3;
		width: fit-content;
		justify-self: end;
		width: fit-content;
	}

	@media(max-width: 500px) {
		display: flex;
		flex-direction: column;

		.textarea {
			width: calc(100% - 16px);
		}
	}
`
