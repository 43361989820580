import styled from 'styled-components'

export const Container = styled.div`
	height: 74vh;

	form {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			'f1 f2 f3 f4'
			'f5 f6 f7 f8'
			'button button button button';

		button {
			grid-area: button;
			width: 200px;
			justify-self: end;
			margin-top: 16px;
		}

		@media(max-width: 950px) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-areas:
				'f1 f2 f3'
				'f4 f5 f6'
				'f7 f8 x'
				'button button button';
		}

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;

			button {
				align-self: flex-end;
			}
		}

		@media(max-width: 500px) {
			button {
				width: calc(100% - 16px);
			}
		}
	}
`
