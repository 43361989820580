import styled from 'styled-components'

export const Container = styled.div`
	margin: 30px auto 80px;
	max-width: 750px;

	h1, h2, h3, p {
		color: #424242;
		margin: 0 14px;
	}

	h1 {
		margin-top: 10px;
	}

	h2 {
		font-size: 18px;
		font-weight: 500;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 8px 14px;

		span {
			font-size: 14px;
			margin-left: 10px;
		}	
	}

	h3 {
		font-size: 16px;
		margin: 14px 14px;
	}

	p {
		line-height: 50px;
	}

	.event-not-found {
		font-size: 18px;
		color: #fff;
		font-weight: 600;
		text-align: center;
		padding: 100px 0;
	}

	.descricao {
		margin: 20px 0 10px;
		line-height: 24px;
	    padding-left: 14px;
		color: #fff;

		a {
			color: #fff;
			font-weight: 600;
			transition: 300ms;
			border: solid 1px transparent;
			padding: 4px;
			border-radius: 4px;

			&:hover {
				border: solid 1px #fff;
			}
		}
	}

	.card {
		padding: 8px 16px;
	}
`

export const Imagem = styled.img`
	object-fit: contain;
	width: 100%;
	height: auto;
`

export const Badge = styled.a`
	.card {
		padding: 20px;
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		
		div {
			font-weight: 600;
			font-size: 16px;
			color: #FFF;
			transition: 300ms;
		}

		svg {
			width: 18px;
			height: 18px;
			color: #FFF;
			transition: 300ms;
		}

		&:hover {
			div, svg {
				opacity: 0.7;
			}
		}
	}
`

export const LinkButton = styled.a`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #424242;
	margin-top: 20px;
	transition: 300ms;

	img {
		width: 90px;
		height: 90px;
		border-radius: 8px;
		margin-bottom: 6px;
	}

	&:hover {
		opacity: 0.8;
	}
`

export const ButtonContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	svg {
		font-size: 18px;
	}
`
