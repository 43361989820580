import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 2fr 1.5fr;
	grid-gap: 0 20px;
	grid-template-areas:
		'details details'
		'table form';

	.table-container,
	.spinner-container {
		grid-area: table;
		margin-top: 0;
	}

	form {
		margin: 42px 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 70px 200px 40px 40px;

		.select {
			grid-column: 1/span 2;
		}

		.textarea {
			grid-column: 1/span 2;
			
			textarea {
				height: 180px;
			}
		}

		.checkbox {
			margin-top: 8px;
		}

		button {
			grid-column: 1/span 2;
			width: fit-content;
			justify-self: flex-end;
		}
	}

	@media(max-width: 900px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'details select'
			'button button'
			'table table';
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`

export const EventDetails = styled.div`
	h1 {
		font-size: 17px;
	}

	h2 {
		font-size: 14px;
		margin: 0 8px;
		line-height: 24px;
	}
`
