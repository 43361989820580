// Tooltip com animaçã ode abertura. Não funciona dentro de tabelas.

import { createGlobalStyle, keyframes } from 'styled-components'

const openTooltip = keyframes`
	from {
		width: 0;
		font-size: 0;
		height: 0;
	}
	to {
		width: 300px;
		font-size: 12px;
		height: fit-content;
		color: #FFF;
	}
`

const openTooltipMobile = keyframes`
	from {
		width: 0;
		font-size: 0;
		height: 0;
	}
	to {
		width: 240px;
		font-size: 12px;
		height: fit-content;
		color: #FFF;
	}
`

export default createGlobalStyle`
	.tooltip-elem {
		.tooltip-message,
		& + .tooltip-message {
			display: none;
			position: relative;
			left: 0px;
			top: 8px;
			padding: 8px 10px;
			background-color: #424242;
			color: transparent;
			border-radius: 10px;
			box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
			transition: 300ms;
			animation: ${openTooltip} 150ms ease forwards;
			line-height: 20px;
		}

		&:hover,
		& *:hover {
			cursor: pointer !important;

			.tooltip-message,
			+ .tooltip-message {
				display: block;
			}
		}

		@media(max-width: 500px) {
			.tooltip-message,
			+ .tooltip-message {
				left: -4px;
				top: 0;
				animation: ${openTooltipMobile} 150ms ease forwards;
			}
		}
	}
`
