import styled from 'styled-components'

export const Container = styled.article`
	> button {
		margin-left: 10px !important;
	}

	form {
		& > button {
			margin: 5px;
			width: 100%;
			max-width: 200px;
			justify-self: center;
		}
	}

	@media(max-width: 900px) {
		.react-tabs .react-tabs__tab:not(.react-tabs__tab--selected) {
			display: none;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;
	}
`
export const ExpanderTab = styled.div`
	display: none;
	padding: 6px;
	background-color: #0001;
	border-radius: 50%;
	margin: 2px 8px;
	transition: 300ms;

	&:hover {
		cursor: pointer;
		background-color: #0002;
	}

	.expander-tab {
		height: 100%;
		display: flex;
		align-items: center;
	}

	@media(max-width: 910px) { 
		display: inline-block;		
	}
`
