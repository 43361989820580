import React, { useState } from 'react'
import { FaFileDownload, FaEyeSlash } from 'react-icons/fa'
import { format } from 'date-fns'

import Modal from '../../components/Modal'

import { Container, ScrollGallery, ModalContent } from './styles'

import { isUSDate, getDateObject } from '../../util/date'
import { extname } from '../../util/path'
import stringUtils from '../../util/string'

export const imageFormats = ['.jpg', '.jpeg', '.png', '.gif']

export default function ({ arquivos, title = 'Arquivos anexados', inAnimation = true }) {
    const [visibleFile, setVisibleFile] = useState(null)

    return (
        <Container className={`no-responsive ${inAnimation ? 'animated fadeIn delay-600ms' : ''}`}>
            {title && <h1>{title}</h1>}

            {arquivos?.length > 0 ? (
                <ScrollGallery>
                    {arquivos.map(arquivo => imageFormats.includes(extname(arquivo.link).toLowerCase()) ? (
                        <div
                            key={`${arquivo.link || ''}_${arquivo.identificador || ''}`}
                            onClick={arquivo.link ? () => { setVisibleFile(arquivo) } : null}
                            role="button"
                            tabIndex={0}
                            className={arquivo.link ? '' : 'tooltip center warn'}
                            tip="Nenhuma visualização disponível."
                        >
                            <div className="image" style={{ backgroundImage: `url(${arquivo.link})` }} />
                            <span>{arquivo.nome}</span>

                            {arquivo.extra?.inicio && (
                                <span style={{ fontSize: 12 }}>
                                    {format(new Date(arquivo.extra?.inicio), 'dd/MM/yyyy HH:mm\'h\'')}
                                </span>
                            )}
                        </div>
                    ) : extname(arquivo.link).toLowerCase() === '.pdf' ? (
                        <div
                            key={`${arquivo.link || ''}_${arquivo.identificador || ''}`}
                            role="button"
                            tabIndex={0}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={arquivo.link ? () => { setVisibleFile(arquivo) } : null}
                            className={arquivo.link ? '' : 'tooltip center warn'}
                            tip="Nenhuma visualização disponível."
                        >
                            <FaFileDownload size={140} />
                            <span>{arquivo.nome}</span>

                            {arquivo.extra?.inicio && (
                                <span style={{ fontSize: 12 }}>
                                    {format(new Date(arquivo.extra?.inicio), 'dd/MM/yyyy HH:mm\'h\'')}
                                </span>
                            )}
                        </div>
                    ) : (
                        <div
                            key={`${arquivo.link || ''}_${arquivo.identificador || ''}`}
                            role="button"
                            tabIndex={0}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={arquivo.link ? () => { window.open(arquivo.link, '_blank') } : null}
                            className={arquivo.link ? '' : 'tooltip center warn'}
                            tip="Nenhuma visualização disponível."
                        >
                            <FaFileDownload size={140} />
                            <span>{arquivo.nome}</span>

                            {arquivo.extra?.inicio && (
                                <span style={{ fontSize: 12 }}>
                                    {format(new Date(arquivo.extra?.inicio), 'dd/MM/yyyy HH:mm\'h\'')}
                                </span>
                            )}
                        </div>
                    ))}
                </ScrollGallery>
            ) : (
                <p className="empty">
                    <FaEyeSlash size={36} />
                    <span>Nenhum arquivo anexado</span>
                </p>
            )}

            <Modal
                isOpen={!!visibleFile}
                handleClose={() => setVisibleFile(null)}
                closeOnOverlayClick
                title={visibleFile?.nome}
            >
                <ModalContent>
                    {visibleFile?.extra && Object.entries(visibleFile?.extra)?.map(([k, v]) => {
                        const valor = !isUSDate(v) ? v
                            : v.length === 10
                                ? format(getDateObject(v), 'dd/MM/yyyy')
                                : format(getDateObject(v), 'dd/MM/yyyy HH:mm\'h\'')

                        return (
                            <p key={k}>
                                {`${stringUtils.capitalize(k)}: ${valor}`}
                            </p>
                        )
                    })}

                    {imageFormats.includes(extname(visibleFile?.link).toLowerCase()) ? (
                        <img src={visibleFile?.link} alt="" />
                    ) : (
                        <iframe src={visibleFile?.link} frameBorder="0" title="Anexo" />
                    )}
                </ModalContent>
            </Modal>
        </Container>
    )
}
