import React, { useContext } from 'react'
import { Calendar } from 'primereact/calendar'
import { useField } from 'formik'
import ReactTooltip from 'react-tooltip'
import { FaQuestionCircle } from 'react-icons/fa'
import { FiHelpCircle } from 'react-icons/fi'

import { TipContainer } from '../styles'
import { Container } from './styles'

import 'primeicons/primeicons.css'
import 'primereact/resources/themes/nova-dark/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import { TipContext } from '../../../contexts/TipContext'

const ptBR = {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar'
}

export default function ({
    dateFormat = 'dd/mm/yy',
    yearRange = `${new Date().getFullYear() - 10}:${new Date().getFullYear() + 5}`,
    yearNavigator = true,
    view = 'date', // date ou month
    label = 'Data',
    containerClass = '',
    icon,
    containerStyle = {},
    tooltip,
    withoutForm,
    onChange,
    ...props
}) {
    let field
    let meta

    const Icon = icon?.Icon

    const { tips } = useContext(TipContext)

    if(!withoutForm) {
        [field, meta] = useField(props)
    }

    const tipText = tips.campos?.find(item => item.campo === field?.name || item.campo === props.id)?.texto

    return (
        <>
            <Container error={meta?.touched && meta?.error} className={containerClass} style={containerStyle}>
                <span className="label">{label}</span>

                <Calendar 
                    dateFormat={dateFormat}
                    yearNavigator={yearNavigator}
                    yearRange={yearRange}
                    view={view}
                    locale={ptBR}
                    {...props}
                    {...field}
                    onChange={e => {
                        if(field?.onChange) {
                            field.onChange(e)
                        }

                        if(onChange) {
                            onChange(e)
                        }
                    }}
                />

                {tipText && !tooltip ? (
                    <>
                        <FiHelpCircle size={14} />

                        <TipContainer>
                            <FaQuestionCircle size={13} />
                            <span>{tipText}</span>
                        </TipContainer>
                    </>
                ) : null}

                {tooltip 
                    ? <FiHelpCircle size={16} data-tip={tooltip} data-for="calendar-tooltip" className="help-icon" /> 
                    : icon 
                        ? <Icon {...icon.props} size={icon.props?.size || 14} /> 
                        : null}

                {meta?.touched && meta?.error 
                    ? (
                        <span className="error">
                            {meta.error}
                        </span>
                    )
                    : null}
            </Container>

            {tooltip && (
                <ReactTooltip
                    id="calendar-tooltip"
                    effect="solid"
                    type="dark"
                    html
                />
            )}
        </>
    )
}
