import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FiPlus, FiEye } from 'react-icons/fi'
import { format } from 'date-fns'
import { useLocation } from 'react-router-dom'

import { api, authHeaders } from '../../../services/api'

import maskUtils from '../../../util/masks'

import Card from '../../../components/Card'
import Table from '../../../components/Table'
import Modal from '../../../components/Modal'
import Spinner from '../../../components/Spinner'
import { Button } from '../../../components/Form'

import Instituicao from './Instituicao'
import TipoDocumento from './TipoDocumento'
import Convenio from './Convenio'
import ConvenioDetalhes from './Convenio/Detalhes'
import TipoAtendimento from './TipoAtendimento'
import AreasJuridicas from './AreasJuridicas'
import ConvenioAreaAtuacao from './ConvenioAreaAtuacao'
import ContratoArea from './ContratoArea'
import TipoManutencao from './ContratoTipoManutencao'

import stringUtils from '../../../util/string'

import { Container } from './styles'

import useQuery from '../../../hooks/useQuery'

import { GlobalContext } from '../../../contexts/GlobalContext'
import { TipContext } from '../../../contexts/TipContext'
import { loadTips } from '../../../util/tip'

export default function ({ match }) {
    const { entidade } = match.params

    const { user } = useContext(GlobalContext)
    const { setCodigo } = useContext(TipContext)

    const { area, tipo_atendimento } = useQuery()
    const { state } = useLocation()

    const [items, setItems] = useState(null)
    const [title, setTitle] = useState('')
    const [editItem, setEditItem] = useState(null)
    const [modoCadastro, setModoCadastro] = useState(false)
    const [detalhesConvenio, setDetalhesConvenio] = useState(null)
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [convenioAdicionais, setConvenioAdicionais] = useState([])
    const [editable, setEditable] = useState(false)

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento')

        setTiposDocumento(response)
    }

    async function loadConvenioAdicionais() {
        const response = await api.get('convenio_adicional')

        setConvenioAdicionais(response)
    }

    function getTips() {
        switch (entidade) {
            case 'instituicao':
                loadTips(setCodigo, 'form_cadastro_instituicao')
                return
            case 'convenio':
                loadTips(setCodigo, 'form_cadastro_parceiros')
                return
            case 'tipo_documento':
                loadTips(setCodigo, '')
                return
            case 'atendimento_tipo':
                loadTips(setCodigo, 'form_cadastro_atendimento_tipo')
                return
            case 'area_juridica':
                loadTips(setCodigo, 'form_cadastro_areas_juridicas')
                return
            case 'convenio_area_atuacao':
                loadTips(setCodigo, 'form_cadastro_area_atuacao')
                return
            case 'contrato_area':
                loadTips(setCodigo, 'form_area_contrato')
                return
            case 'contrato_tipo_manutencao':
                loadTips(setCodigo, 'form_contrato_tipo_manutencao')
                return
            default: return null
        }
    }

    useEffect(() => {
        loadTiposDocumento()

        loadConvenioAdicionais()

        getTips()
    }, [])

    function getAuthHeaders() {
        switch (entidade) {
            case 'convenio': return authHeaders()
            case 'convenio_area_atuacao': return authHeaders()
            default: return undefined
        }
    }

    function getGrupoDescricao(grupo) {
        const gruposDocumento = {
            lancamento: 'lançamento',
            avulso: 'avulso',
            extrato: 'extrato',
            juridico: 'jurídico',
            acervo: 'acervo',
            financeiro: 'financeiro'
        }

        return gruposDocumento[grupo] || '-'
    }

    function normalize(data) {
        function getTextFaixaEtaria(desconto) {
            if (!desconto.idade_minima && !desconto.idade_maxima) {
                return 'Qualquer faixa etária'
            }

            if (!desconto.idade_minima && desconto.idade_maxima) {
                return `Até ${desconto.idade_maxima} anos`
            }

            if (desconto.idade_minima && !desconto.idade_maxima) {
                return `A partir de ${desconto.idade_minima} anos`
            }

            if (desconto.idade_minima && desconto.idade_maxima) {
                return `Entre ${desconto.idade_minima} e ${desconto.idade_maxima} anos`
            }
        }

        try {
            switch (entidade) {
                case 'instituicao': return data
                    .map(item => ({
                        id: item.id,
                        name: item.nome_fantasia,
                        sigla: item.sigla,
                        tipo: item.tipo_instituicao.descricao
                    }))
                case 'convenio': return data
                    .map(item => ({
                        id: item.id,
                        name: item.empresa.nome,
                        tipo_desconto: item.tipo_desconto === 'tabelado' ? 'Tabelado' : 'Fixo',
                        area_atuacao_id: item.convenio_area_atuacao.id,
                        area_atuacao: item.convenio_area_atuacao.descricao,
                        data_inicio: format(new Date(item.data_inicio), 'dd/MM/yyyy'),
                        data_fim: format(new Date(item.data_fim), 'dd/MM/yyyy'),
                        tipo: item.empresa.tipo,
                        tipo_convenio: item.tipo,
                        favorecido: item.favorecido?.split(',').map(f => f).join(', ') || 'Médicos e acadêmicos',
                        descricao: item.descricao,
                        documentos: item.empresa.documentos,
                        endereco: item.empresa.endereco,
                        email: item.empresa.contato?.find(c => c.tipo_contato.id === 6)?.contato || '',
                        fone: item.empresa.contato?.find(c => [2, 3].includes(c.tipo_contato.id))?.contato || '',
                        parentescos: item.parentescos.map(parentesco => ({
                            parentesco: parentesco.descricao,
                            documentos: parentesco.convenio_grau_parentesco.documentos?.length
                                ? parentesco.convenio_grau_parentesco.documentos
                                    ?.split(',')
                                    .map(docId => tiposDocumento.find(tipo => tipo.id === Number(docId))?.descricao)
                                    .join(', ')
                                : 'Nenhum'
                        })),
                        descontos: item.descontos
                            ? item.descontos.map(desconto => ({
                                id: desconto.id,
                                sexo: desconto.sexo,
                                universidade: desconto.universidade === true ? 'Sim' : desconto.universidade === false ? 'Não' : null,
                                pcd: desconto.pne === true ? 'Sim' : desconto.pne === false ? 'Não' : null,
                                faixa_etaria: getTextFaixaEtaria(desconto),
                                valor_sem_desconto: desconto.valor_sem_desconto ? stringUtils.moneyFormat(desconto.valor_sem_desconto) : null,
                                valor_com_desconto: desconto.valor_com_desconto ? stringUtils.moneyFormat(desconto.valor_com_desconto) : null,
                                percentual_desconto: desconto.percentual_desconto ? `${String(desconto.percentual_desconto).replace('.', ',')}%` : null
                            }))
                            : [],
                        advogados: item.convenio_area_atuacao.id === 7 && item.advogados?.filter(adv => !!adv)?.map(({ pessoa, areas }) => ({
                            id: pessoa.id,
                            nome: pessoa.nome,
                            areas: areas.map(a => a.descricao).join(', ')
                        }))
                    }))
                case 'tipo_documento': 
                    return data.map(tipo => ({
                        id: tipo.id,
                        name: tipo.descricao,
                        grupo: getGrupoDescricao(tipo.grupo),
                        digitavel: tipo.digitavel ? 'Sim' : 'Não',
                        validade: tipo.validade ? 'Sim' : 'Não'
                    }))

                default: return data.map(item => ({
                    id: item.id,
                    name: item.descricao
                }))
            }
        } catch(e) {
            console.log('ERRO NORMALIZE', e)
        }
    }

    function getTableHeaders() {
        switch (entidade) {
            case 'instituicao': return [
                { name: 'name', value: 'Nome' },
                { name: 'sigla', value: 'Sigla' },
                { name: 'tipo', value: 'Tipo' }
            ]
            case 'convenio': return [
                { name: 'name', value: 'Nome' },
                { name: 'tipo_desconto', value: 'Tipo de desconto', centered: true },
                { name: 'area_atuacao', value: 'Área de atuação', centered: true },
                { name: 'data_inicio', value: 'Data de início do contrato', centered: true },
                { name: 'data_fim', value: 'Data de término do contrato', centered: true }
            ]
            case 'tipo_documento': return [
                { name: 'name', value: 'Nome' },
                { name: 'grupo', value: 'Grupo', centered: true },
                { value: 'Possui numeração?', name: 'digitavel', centered: true },
                { value: 'Possui validade?', name: 'validade', centered: true }
            ]
            default: return [
                { name: 'name', value: 'Nome' }
            ]
        }
    }

    function getActions() {
        switch (entidade) {
            case 'convenio':
                return [
                    {
                        action: setDetalhesConvenio,
                        name: 'Ver detalhes',
                        icon: FiEye,
                        iconSize: 16
                    }
                ]
            default: return []
        }
    }

    function getParams() {
        if (!user) {
            return
        }

        switch (entidade) {
            case 'convenio':
                if (area) {
                    return {
                        convenio_area_atuacao_id: Number(area)
                    }
                }

                return {
                    convenio_area_atuacao_id: '!1'
                }
            case 'atendimento_tipo':
                if (tipo_atendimento) {
                    return {
                        tipo: Number(tipo_atendimento)
                    }
                }
                break
            default: return {}
        }

        return {}
    }

    function filterItems(itemsLista) {
        if (!tipo_atendimento) {
            return itemsLista.filter(i => i.id !== 1)
        }

        return itemsLista
    }

    async function loadEdicao(id) {
        const response = await api.get(`${entidade}/${id}`, authHeaders())

        setEditItem(response)
    }

    async function loadItems() {
        try {
            let response = await api.get(entidade, {
                ...getAuthHeaders(),
                params: getParams()
            })

            response = filterItems(response)

            setItems(normalize(response))
        } catch(e) {
            console.log('ERRO loadItems', e)
        }
    }

    function getTitle() {
        switch (entidade) {
            case 'instituicao':
                return 'Local de trabalho / Instituição'
            case 'convenio':
                return 'Serviço / Parceria'
            case 'tipo_documento':
                return 'Tipo de documento'
            case 'atendimento_tipo':
                return 'Tipo de atendimento'
            case 'area_juridica':
                return 'Área jurídica'
            case 'convenio_area_atuacao':
                return 'Áreas de atuação'
            case 'contrato_area':
                return 'Áreas do Contrato'
            case 'contrato_tipo_manutencao':
                return 'Tipos de manutenção'
            default: return ''
        }
    }

    async function handleDelete(id) {
        try {
            await api.delete(`${entidade}/${id}`, authHeaders())

            loadItems()

            toast.success(`${getTitle()} ${items.find(i => i.id === id).name} removido(a) com sucesso.`)

            return true
        } catch (e) {
            toast.error(e.msg)
            return false
        }
    }

    function getForm() {
        switch (entidade) {
            case 'instituicao':
                return (
                    <Instituicao
                        instituicao={editItem || {}}
                        reload={() => {
                            setEditItem(null)
                            setModoCadastro(false)
                            loadItems()
                        }}
                        edicao={!!editItem}
                    />
                )

            case 'convenio':
                return (
                    <Convenio
                        convenio={editItem}
                        reload={() => {
                            setEditItem(null)
                            setModoCadastro(false)
                            loadItems()
                        }}
                        edicao={!!editItem}
                    />
                )

            case 'tipo_documento':
                return (
                    <TipoDocumento
                        edicao={editItem}
                        reload={() => {
                            setEditItem(null)
                            setModoCadastro(false)
                            loadItems()
                        }}
                    />
                )

            case 'atendimento_tipo':
                return (
                    <TipoAtendimento
                        edicao={editItem}
                        reload={() => {
                            setEditItem(null)
                            setModoCadastro(false)
                            loadItems()
                        }}
                    />
                )

            case 'area_juridica':
                return (
                    <AreasJuridicas
                        edicao={editItem}
                        reload={() => {
                            setEditItem(null)
                            setModoCadastro(false)
                            loadItems()
                        }}
                    />
                )

            case 'convenio_area_atuacao':
                return (
                    <ConvenioAreaAtuacao
                        edicao={editItem}
                        reload={() => {
                            setEditItem(null)
                            setModoCadastro(false)
                            loadItems()
                        }}
                    />
                )

            case 'contrato_area':
                return (
                    <ContratoArea
                        edicao={editItem}
                        reload={() => {
                            setEditItem(null)
                            setModoCadastro(false)
                            loadItems()
                        }}
                    />
                )

            case 'contrato_tipo_manutencao':
                return (
                    <TipoManutencao
                        edicao={editItem}
                        reload={() => {
                            setEditItem(null)
                            setModoCadastro(false)
                            loadItems()
                        }}
                    />
                )

            default: throw new Error('Formulário de edição não definido.')
        }
    }

    function getTipoDescricao(tipo) {
        switch (tipo) {
            case 'servico':
                return {
                    label: 'Serviço (precisa de aprovação)',
                    value: 'servico'
                }
            case 'parceria':
                return {
                    label: 'Parceria',
                    value: 'parceria'
                }
            default: return null
        }
    }

    async function handlePrepareEdit(id, ent) {
        if (!ent) {
            setEditItem(null)
        }

        const response = await api.get(`${ent}/${id}`, authHeaders())

        switch (ent) {
            case 'convenio':
                const {
                    convenio_area_atuacao, empresa, data_inicio, data_fim, tipo_desconto, criterios, parentescos, convenio_adicionais: adicionais, descontos, logo, favorecido, ...convenioData
                } = response

                const identificador = empresa.documentos.find(doc => [2, 14].includes(doc.tipo_documento_id))?.identificador

                const data = {
                    ...convenioData,
                    descontos: descontos.map(desconto => ({
                        ...desconto,
                        idade_minima: desconto.idade_minima === 0 && desconto.idade_maxima === 999 ? '' : desconto.idade_minima,
                        idade_maxima: desconto.idade_minima === 0 && desconto.idade_maxima === 999 ? '' : desconto.idade_maxima,
                        valor_sem_desconto: stringUtils.moneyFormat(desconto.valor_sem_desconto),
                        valor_com_desconto: stringUtils.moneyFormat(desconto.valor_com_desconto),
                        percentual_desconto: String(desconto.percentual_desconto).replace('.', ','),
                        sexo: { label: desconto.sexo === 'M' ? 'Masculino' : 'Feminino', value: desconto.sexo },
                        universidade: desconto.universidade,
                        pcd: desconto.pne
                    })),
                    data_inicio: format(new Date(data_inicio), 'dd/MM/yyyy'),
                    data_fim: format(new Date(data_fim), 'dd/MM/yyyy'),
                    identificador,
                    nome: empresa.nome,
                    area_atuacao: {
                        label: convenio_area_atuacao.descricao,
                        value: convenio_area_atuacao.id
                    },
                    tipo_pessoa: {
                        label: empresa.tipo,
                        value: empresa.tipo
                    },
                    tipo_desconto: {
                        label: tipo_desconto === 'fixo' ? 'Fixo' : 'Tabelado',
                        value: tipo_desconto
                    },
                    criterios: criterios ? criterios.map(crit => ({
                        label: crit,
                        value: crit
                    })) : [],
                    parentescos: parentescos.map(parent => ({
                        parentesco: {
                            label: parent.descricao,
                            value: parent.id
                        },
                        documentos: parent.convenio_grau_parentesco.documentos?.split(',').map(docId => ({
                            label: tiposDocumento.find(tipo => tipo.id === Number(docId)).descricao,
                            value: docId
                        })) || []
                    })),
                    favorecido: favorecido ? favorecido.split(',').map(fav => ({
                        label: fav,
                        value: fav
                    })) : [],
                    adicionais: adicionais.map(adicional => ({
                        id: adicional.id,
                        descricao: adicional.descricao,
                        valor: maskUtils.maskApply.currency(adicional.valor)
                    })),
                    termo_aceite: convenioData.termo_aceite?.id || null,
                    endereco: empresa.endereco,
                    fone: empresa.contato.find(c => [2, 3].includes(c.tipo_contato.id))?.contato || '',
                    email: empresa.contato.find(c => c.tipo_contato.id === 6)?.contato || '',
                    taxa_administrativa: maskUtils.maskApply.currency(convenioData.taxa_administrativa),
                    taxa_bancaria: maskUtils.maskApply.currency(convenioData.taxa_bancaria),
                    tipo: getTipoDescricao(convenioData.tipo),
                    logo: logo?.id || null
                }

                setEditItem(data)

                break

            default:
                setEditItem(response)
                break
        }
    }

    useEffect(() => {
        if(state?.instituicao) {
            loadEdicao(state.instituicao)
        }
    }, [state])

    useEffect(() => {
        if (tiposDocumento.length && convenioAdicionais) {
            loadItems()
        }

        setTitle(getTitle(entidade))
    }, [tiposDocumento, convenioAdicionais])

    useEffect(() => {
        if (user) {
            setEditable(user.perfil.id !== 2)
        }
    }, [user])

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>{getTitle()}</h1>

                        {entidade && items !== null ? (
                            <>
                                {editable && (user.perfil.id !== 23 || entidade !== 'atendimento_tipo') && (
                                    <Button className="transparent button-cadastrar" onClick={() => setModoCadastro(true)}>
                                        Cadastrar
                                        <FiPlus />
                                    </Button>
                                )}

                                <Table
                                    headers={getTableHeaders()}
                                    data={items}
                                    handleDelete={editable ? handleDelete : null}
                                    handlePrepareEdit={editable ? id => { handlePrepareEdit(id, entidade) } : null}
                                    confirmExclusion={{
                                        attr: 'name',
                                        template: 'Deseja realmente excluir #attr#?'
                                    }}
                                    actions={editable ? getActions() : []}
                                    hideOffset={730}
                                    filterable
                                />
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!editItem || modoCadastro}
                handleClose={() => {
                    setEditItem(null)
                    setModoCadastro(false)
                }}
                title={`${modoCadastro ? 'Cadastrar' : 'Editar'} ${title}`}
            >
                {getForm()}
            </Modal>

            <Modal
                isOpen={!!detalhesConvenio}
                handleClose={() => {
                    setDetalhesConvenio(null)

                    loadItems()
                }}
                title={`Detalhes do ${detalhesConvenio?.tipo_convenio === 'servico' ? 'Serviço' : 'Parceiro'}`}
            >
                <ConvenioDetalhes
                    detalhes={detalhesConvenio}
                    reload={async () => {
                        const { id } = detalhesConvenio

                        const response = await api.get(`convenio/${id}`, authHeaders())

                        setDetalhesConvenio(normalize([response])[0])

                        loadItems()
                    }}
                />
            </Modal>
        </>
    )
}
