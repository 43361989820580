import React, {
    useState, useEffect, useCallback, useContext 
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'

import { api, authHeaders } from '../../../../services/api'

import { downloadRelatorioJasper } from '../../../../util/file'
import masks from '../../../../util/masks'

import { Button, Select, Textbox } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'

import Card from '../../../../components/Card'

import { Container, ButtonContainer } from './styles'

import Yup from '../../../../services/yup'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

const initialValues = {
    ano: '',
    cpf: ''  
}

const validation = Yup.object().shape({
    ano: Yup.string().ensure().required('Informe o ano.'),
    cpf: Yup.string().cpf('CPF inválido').required('Especifique o CPF.')
})

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [anos, setAnos] = useState([])

    useEffect(() => {
        const currentYear = new Date().getFullYear()
        const listaAnos = []

        for(let i = currentYear; i >= 2019; i--) {
            listaAnos.push({
                label: i,
                value: i
            })
        }

        setAnos(listaAnos)
    }, [])

    useEffect(() => {
        loadTips(setCodigo, 'form_declaracao_anual_unimed')
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const { 
            ano,
            cpf
        } = values

        const dadosPlano = await api.get('declaracao_anual', {
            params: {
                ano: ano?.value,
                cpf
            },
            ...authHeaders()
        })

        if (dadosPlano.length === 0) {
            toast.info('Não há dados referente ao ano informado.')
            return
        }

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioDeclaracaoAnualUnimed',
                parametros: {
                    ano: ano?.value,
                    cpf
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })
            
            downloadRelatorioJasper(response, 'RelatorioDeclaracaoAnualUnimed')

            resetForm()
        } catch(e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])
    
    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Declaração anual Unimed</h1>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                        containerStyle={{ gridArea: 'form' }}
                    >
                        {({
                            isSubmitting, setFieldValue
                        }) => (
                            <Form>
                                <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                                <Textbox 
                                    label="CPF" 
                                    name="cpf"
                                    mask={masks.cpf} 
                                    containerStyle={{ gridArea: 'f1' }}
                                />

                                <Select 
                                    name="ano"
                                    label="Ano"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={anos}
                                    selectionMode="range"
                                    containerStyle={{ gridArea: 'f2' }}
                                />
            
                                <Spinner
                                    visible={isSubmitting} 
                                    label="Gerando relatório..."
                                    containerStyle={{ gridArea: 'spinner' }}
                                />

                                <ButtonContainer>
                                    <Button
                                        type="submit"
                                        loading={isSubmitting}
                                        className="transparent"
                                    >
                                        Gerar declaração
                                        <FiFile size={20} />
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
