import React, {
    createContext, useContext, useEffect, useState
} from 'react'
import { toast } from 'react-toastify'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import { api, authHeaders } from '../../../../services/api'

export const ProcessoGerenciamentoContext = createContext()

const ProcessoGerenciamentoProvider = ({ children }) => {
    const { user } = useContext(GlobalContext)

    const [showCadastroSetor, setShowCadastroSetor] = useState(false)

    const [atendentesDoSetor, setAtendentesDoSetor] = useState([])
    const [tiposDoSetor, setTiposDoSetor] = useState([])
    const [tiposDoPerfilAbertura, setTiposDoPerfilAbertura] = useState([])

    const [setoresDisponiveis, setSetoresDisponiveis] = useState(null)
    const [atendentesDisponiveis, setAtendentesDisponiveis] = useState(null)
    const [tiposDisponiveis, setTiposDisponiveis] = useState(null)
    const [perfisDisponiveis, setPerfisDisponiveis] = useState(null)

    const [setorSelecionado, setSetorSelecionado] = useState(null)
    const [perfilSelecionado, setPerfilSelecionado] = useState(null)

    async function loadAtendentes() {
        const response = await api.get('pessoa', {
            // params: {
            //     categorias: [
            //         'funcionario',
            //         'diretor',
            //         'advogado',
            //         'imprensa'
            //     ]
            // },
            ...authHeaders()
        })

        setAtendentesDisponiveis(response)
    }

    async function loadSetores() {
        try {
            setSetoresDisponiveis(null)

            const response = await api.get('/processo/atendentes_por_setor', authHeaders())

            setSetoresDisponiveis(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function loadAtendentesDoSetor(setorId) {
        setAtendentesDoSetor(setoresDisponiveis.find(s => s.id === setorId).pessoas)
    }

    async function loadPerfis() {
        try {
            const response = await api.get('perfil', authHeaders())

            const perfisOcultos = [1, 18, 19, 24]

            setPerfisDisponiveis(response.filter(p => !perfisOcultos.includes(p.id)))
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadTipos() {
        try {
            const response = await api.get('atendimento_tipo', {
                params: {
                    sem_juridico: 1
                },
                ...authHeaders()
            })

            setTiposDisponiveis(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadTiposDoSetor(setorId) {
        try {
            const response = await api.get('processo/tipos_por_setor', {
                params: {
                    setor_id: setorId
                },
                ...authHeaders()
            })

            setTiposDoSetor(response.tipos)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadTiposDoPerfil(perfilId) {
        try {
            const response = await api.get('tipos_processo_por_perfil', {
                params: {
                    perfil: perfilId
                },
                ...authHeaders()
            })

            setTiposDoPerfilAbertura(response.tipos_abertura)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleUpdateSetorAtendente({ draggableId: atendenteId, destination, source }) {
        try {
            const adicionado = source.droppableId === 'disponiveis' && destination.droppableId === 'atuais'
            atendenteId = Number(atendenteId)

            if (adicionado) {
                const atendenteAdicionado = atendentesDisponiveis.find(a => a.id === atendenteId)

                setAtendentesDoSetor(old => [
                    ...old,
                    {
                        id: atendenteAdicionado.id,
                        nome: atendenteAdicionado.nome
                    }
                ])

                await api.post('processo/atendente_setor/vincular', {
                    setor_id: setorSelecionado,
                    atendente_id: atendenteId
                }, authHeaders())
            } else {
                setAtendentesDoSetor(old => old.filter(o => o.id !== atendenteId))

                await api.delete(`processo/atendente_setor/desvincular/${setorSelecionado}/${atendenteId}`, authHeaders())
            }
            loadSetores()
            toast.success('Lista atualizada.')
        } catch (e) {
            toast.error('Erro ao atualizar a lista de atendentes.')
        }
    }

    async function handleUpdateSetorTipo({ draggableId: tipoId, destination, source }) {
        try {
            const adicionado = source.droppableId === 'disponiveis' && destination.droppableId === 'atuais'
            tipoId = Number(tipoId)

            if (adicionado) {
                const tipoAdicionado = tiposDisponiveis.find(a => a.id === tipoId)

                await api.post('processo/tipo_setor/vincular', {
                    setor_id: setorSelecionado,
                    tipo_id: tipoId
                }, authHeaders())

                setTiposDoSetor(old => [
                    ...old,
                    {
                        id: tipoAdicionado.id,
                        descricao: tipoAdicionado.descricao
                    }
                ])
            } else {
                setTiposDoSetor(old => old.filter(o => o.id !== tipoId))

                await api.delete(`processo/tipo_setor/desvincular/${setorSelecionado}/${tipoId}`, authHeaders())
            }

            toast.success('Lista atualizada.')
        } catch (e) {
            if (e.code === 409) {
                toast.warn(e.msg)

                return
            }

            toast.error('Erro ao atualizar a lista de tipos de atendimento.')
        }
    }

    async function handleUpdatePerfilTipo({ draggableId: tipoId, destination, source }) {
        try {
            const adicionado = source.droppableId === 'disponiveis' && destination.droppableId === 'atuais'
            tipoId = Number(tipoId)

            if (adicionado) {
                const tipoAdicionado = tiposDisponiveis.find(a => a.id === tipoId)

                setTiposDoPerfilAbertura(old => [
                    ...old,
                    {
                        id: tipoAdicionado.id,
                        descricao: tipoAdicionado.descricao
                    }
                ])

                await api.post('processo/tipo_perfil_abertura/vincular', {
                    perfil_id: perfilSelecionado,
                    tipo_id: tipoId
                }, authHeaders())
            } else {
                setTiposDoPerfilAbertura(old => old.filter(o => o.id !== tipoId))

                await api.delete(`processo/tipo_perfil_abertura/desvincular/${perfilSelecionado}/${tipoId}`, authHeaders())
            }

            toast.success('Lista atualizada.')
        } catch (e) {
            toast.error('Erro ao atualizar a lista de tipos de atendimento.')
        }
    }

    async function handleCadastrarSetor(values) {
        try {
            await api.post('setor', values, authHeaders())

            toast.success('Grupo cadastrado com sucesso.')

            setShowCadastroSetor(false)

            loadSetores()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleRemoverSetor(setor) {
        try {
            await api.delete(`setor/${setor.id}`, authHeaders())

            toast.success('Grupo removido com sucesso.')

            loadSetores()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if (user) {
            loadAtendentes()

            loadSetores()

            loadTipos()

            loadPerfis()
        }
    }, [user])

    useEffect(() => {
        if (setorSelecionado) {
            loadAtendentesDoSetor(setorSelecionado)
            loadTiposDoSetor(setorSelecionado)
        } else {
            setAtendentesDoSetor([])
            setTiposDoSetor([])
        }
    }, [setorSelecionado])

    useEffect(() => {
        if (perfilSelecionado) {
            loadTiposDoPerfil(perfilSelecionado)
        } else {
            setTiposDoPerfilAbertura([])
        }
    }, [perfilSelecionado])

    return (
        <ProcessoGerenciamentoContext.Provider
            value={{
                setoresDisponiveis,
                atendentesDisponiveis,
                setSetorSelecionado,
                setorSelecionado,
                atendentesDoSetor,
                handleUpdateSetorAtendente,
                showCadastroSetor,
                setShowCadastroSetor,
                handleCadastrarSetor,
                handleRemoverSetor,
                tiposDisponiveis,
                perfisDisponiveis,
                tiposDoSetor,
                handleUpdateSetorTipo,
                perfilSelecionado,
                tiposDoPerfilAbertura,
                setPerfilSelecionado,
                handleUpdatePerfilTipo
            }}
        >
            {children}
        </ProcessoGerenciamentoContext.Provider>
    )
}

export default ProcessoGerenciamentoProvider
