import React from 'react'

import AtendimentoProvider from '../../../../contexts/AtendimentoContext'

import Listagem from './Listagem'
import Extra from './Extra'

export default function () {
    return (
        <AtendimentoProvider>
            <Listagem />

            <Extra />
        </AtendimentoProvider>
    )
}
