import styled from 'styled-components'

import { Container as Card } from '../../components/Card/styles'

export const Container = styled.div`
	margin: 8vh 0;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 360px 1fr;
	justify-content: center;

	@media(max-width: 1050px) {
		margin: 2vh 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

export const Content = styled(Card).attrs({
    className: 'no-responsive'
})`
	grid-column: 2;
	width: 100%;
	max-width: 315px;
	text-align: center;
	justify-self: center;
	height: fit-content;

	img {
		width: 120px;
		margin: auto;
	}

	form {
		display: flex;
		flex-direction: column;

		h1 {
			font-size: 18px;
			margin: 16px 8px 10px;
			font-weight: 500;
		}

		p {
			color: #fff;
			padding: 15px;
		}

		a {
			color: #fff;
			font-size: 14px;
			opacity: 0.8;
			line-height: 30px;

			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}

		button {
			margin: 15px 8px 0 !important;

		}
	}
`

export const LoginsContainer = styled(Card)`
	max-width: 350px;
	justify-self: flex-end;
`
