import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Yup from '../../../services/yup'
import { api, authHeaders } from '../../../services/api'

import formUtils from '../../../util/form'

import Card from '../../../components/Card'
import Modal from '../../../components/Modal'
import {
    Button, Textarea, Checkbox 
} from '../../../components/Form'

import {
    Container, ButtonContainer, ModalDeleteContainer, Badge 
} from './styles'
import { GlobalContext } from '../../../contexts/GlobalContext'

const initialValues = {
    observacao: ''
}

const validation = Yup.object({
    observacao: Yup.string().required('Preencha o campo acima.')
})

export default function () {
    const history = useHistory()

    const { user } = useContext(GlobalContext)

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [acordo, setAcordo] = useState(false)
    const [impedimentoMotivo, setImpedimentoMotivo] = useState(null)

    async function loadImpedimentoMotivo() {
        try {
            const { impedimento, motivo } = await api.get('cancelamento_associacao/verificar_impedimento', authHeaders())

            if(impedimento) {
                setImpedimentoMotivo(`<b>Não é possível solicitar seu cancelamento.</b><br/><br/>${motivo}`)
                return
            }
        } catch(e) {
            toast.error(e.msg)
        }
    }
	
    async function handleConfirm({ values, formikBag: { resetForm } }) {
        try {
            const data = formUtils.extractFormValues(values)

            await api.post(`cancelamento_associacao/${user.associado.id}`, {
                ...data,
                motivo: 2
            }, authHeaders())
			
            toast.success('Cancelamento realizado com sucesso.')

            setShowConfirmDelete(false)

            resetForm()

            sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN)
            sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_USER)
        
            history.push('/')
        } catch(e) {
            toast.error('Ocorreu um erro ao realizar o cancelamento.')
        }
    }

    useEffect(() => {
        loadImpedimentoMotivo()
    }, [])
	
    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Cancelamento de associação</h1>

                    {!impedimentoMotivo ? (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validation}
                            onSubmit={(values, formikBag) => { setShowConfirmDelete({ values, formikBag }) }}
                            enableReinitialize
                        >
                            {({ resetForm }) => (
                                <Form>
                                    <Textarea
                                        name="observacao"
                                        label="Descreva abaixo, resumidamente, o motivo de sua desassociação:"
                                        containerStyle={{ gridArea: 'f4' }}
                                    />

                                    <p>Ao cancelar sua associação, você perderá automaticamente todos os benefícios.</p>
                                    <p>Caso você tenha débitos, será necessário quitá-los, caso queira retomar sua associação.</p>

                                    <Checkbox 
                                        label="Estou ciente de que PERDEREI o direito a TODOS os benefícios ao efetuar minha desassociação."
                                        withoutForm
                                        onChange={e => { setAcordo(e.target.checked) }}
                                        value={acordo}
                                    />

                                    <ButtonContainer>
                                        <Button
                                            onClick={resetForm}
                                            className="transparent"
                                        >
                                            Limpar
                                        </Button>

                                        <Button type="submit" className="transparent" disabled={!acordo}>
                                            Confirmar
                                        </Button>
                                    </ButtonContainer>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <Badge dangerouslySetInnerHTML={{ __html: impedimentoMotivo }} />
                    )}
                </Card>
            </main>

            <Modal
                isOpen={!!showConfirmDelete} 
                title="Confirmação"
                handleClose={() => { setShowConfirmDelete(false) }}
            >
                <ModalDeleteContainer>
                    Deseja realmente cancelar sua associação ao SINDMEPA? Você perderá direito a todos os serviços oferecidos pelo Sindicato, incluindo descontos no Plano de Saúde e Assessoria Jurídica. Esta ação é IRREVERSÍVEL.

                    <div className="button-container">
                        <Button 
                            onClick={() => { setShowConfirmDelete(false) }}
                            className="transparent"
                        >
                            Não
                        </Button>
                        
                        <Button 
                            onClick={() => { 
                                handleConfirm(showConfirmDelete)
                            }}
                            className="white"
                        >
                            Sim
                        </Button>
                    </div>
                </ModalDeleteContainer>
            </Modal>
        </Container>
    )
}
