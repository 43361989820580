import styled from 'styled-components'

import { Container as Card } from '../../../components/Card/styles'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px;

		${Card} {
			display: grid;
			grid-gap: 0 14px;
			min-height: 80vh;
			grid-template-columns: 350px 1fr 1fr 1fr;
			grid-template-rows: 40px auto;
			grid-template-areas:
				'title title title title'
				'list form form form';

			form,
			.form-report {
				background-color: #0002;
				border-radius: 10px;
				padding: 20px;

				h2 {
					grid-area: title;
					font-size: 18px;
					margin: 0 8px 12px;
					font-weight: 500;
				}
			}

			@media(max-width: 900px) {
				grid-template-columns: 1fr;
				grid-template-areas:
					'title'
					'list'
					'form';
				
				form {
					margin-top: 30px;
				}
			}

			.title {
				margin-bottom: 20px;
				grid-area: title;
				margin: 0 8px 20px;
			}
		}

		@media(max-width: 800px) {
			padding: 20px;
		}

		@media(max-width: 400px) {
			padding: 15px 0;
		}

		@media(max-width: 600px) {
			padding: 10px 0;
		}

		@media(min-width: 600px) {
			margin-left: 50px;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`
