import styled from 'styled-components'

import { Container as Card } from '../../../../components/Card/styles'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;
		display: grid;
		grid-template-columns: 1fr 330px;
		grid-template-rows: 84px auto 42vh;
		grid-template-areas: 
			'dialogo atendente'
			'dialogo arquivos'
			'dialogo visitas';

		${Card} {
			.spinner-container {
				grid-column: 1/span 2;
			}

			@media(max-width: 442px) {
				&:not(.no-responsive) {
					margin: 30px 0;
					padding: 30px 15px;
				}
			}
		}

		> h1 {
			grid-area: identificador;
			margin: 0 24px;
			font-size: 24px;
			font-weight: 500;
		}

		.dialog-box {
			grid-area: dialogo;
			min-height: 70vh;
		}

		.files-box {
			grid-area: arquivos;
		}

		.visitas-box {
			grid-area: visitas;
		}

		@media(max-width: 1100px) {
			padding: 10px 0;
		}

		@media(max-width: 900px) {
			display: flex;
			flex-direction: column;

			> ${Card} {
				min-height: 0;
			}
		}
	}
`

export const TourHelper = styled.div`
	display: flex;
	justify-content: flex-end;
	
	> div {
		width: fit-content;
		align-items: center;
		color: #424242;
		transition: 300ms;
		gap: 8px;
		background-color: #fff;
		border-radius: 10px;
		position: fixed;
		bottom: 12px;
		left: 16px;
		display: flex;
		justify-content: center;
		padding: 9px;
		border-radius: 50%;
		box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);

		img {
			height: 45px;
		}

		&:hover {
			opacity: 0.8;
			cursor: pointer;
		}
	}
`
