import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { FiSearch } from 'react-icons/fi'

import { Textbox, Button } from '../../../../components/Form'

import { Container, ResultadoContainer } from './styles'

import { DependenteContext } from '../../../../contexts/DependenteContext'

import { unmask } from '../../../../util/string'

import { api } from '../../../../services/api'

export default function () {
    const { setIdentificador, showFiltro, associado } = useContext(DependenteContext)

    if(!showFiltro) {
        return null
    }

    async function handleSearch(values) {
        const params = {}

        if(unmask(values.identificador).length === 11) {
            params.cpf = unmask(values.identificador)
        } else {
            params.crm = unmask(values.identificador)
        }

        const associados = await api.get('associado', {
            params
        })

        setIdentificador(associados[0].id)
    }
    
    return (
        <Container>
            <Formik
                onSubmit={handleSearch}
                initialValues={{ identificador: '' }}
            >
                {() => (
                    <Form>
                        <Textbox 
                            name="identificador"
                            label="Matrícula do associado"
                        />

                        <Button type="submit" className="transparent">
                            Pesquisar
                            <FiSearch />
                        </Button>
                    </Form>
                )}
            </Formik>

            {associado && (
                <ResultadoContainer>
                    <span>{`Associad${associado.pessoa.sexo === 'M' ? 'o' : 'a'} encontrad${associado.pessoa.sexo === 'M' ? 'o' : 'a'}:`}</span>
                                
                    <h2>{associado.pessoa.nome}</h2>
                </ResultadoContainer>
            )}
        </Container>
    )
}
