import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'form-pagamento'
})`
	min-height: 150px;
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-areas: 
		'tipo x'
		'form form';

	> .select {
		grid-area: tipo;
	}

	> div {
		grid-area: form;
	}

	.field.select:not(.banco-container):not(.instituicao-container) {
		width: calc(100% - 16px);
	}

	.field.checkbox {
		width: calc(100% - 16px) !important;
	}

	@media(max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
`
