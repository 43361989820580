import styled, { css } from 'styled-components'
import ReactSelect from 'react-select'

const maxWidth = css`
	max-width: ${props => props.style?.maxWidth}px;
`

export const Select = styled(ReactSelect)`
	width: 100%;
	box-shadow: ${props => props['no-shadow'] ? 'none' : '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)'};

	${props => props.style?.maxWidth && maxWidth}

	> div {
		height: auto;

		> div {
			min-height: 42px;
		}
	}

	div > svg {
		fill: #424242;

		&:hover {
			fill: red;
		}
	}
`

export const SelectContainer = styled.div.attrs({
    className: 'field select'
})`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 5px 8px;
	width: calc(100% - 16px);

	.label {
		font-size: 11px;
		margin: 4px 4px 2px;
	}

	.error {
		width: 100%;
		margin-top: 6px;
	}

	.hint {
		font-size: 11px;
		color: #FFF;
		margin: 4px;

		&.warn {
			background-color: #ffeb3b;
			border-radius: 10px;
			padding: 6px 8px;
		}
	}

	> svg {
		position: absolute;
		right: 14px;
		top: 32px;
		width: 18px;
		opacity: 0.7;
		color: rgba(0, 0, 0, 0.3) !important;

		&:hover {
			cursor: help;

			& + .tip-container {
				display: grid;
			}
		}
	}

	${props => props.error && css`
		padding-left: 8px;
		border-left: solid 4px #ffeb3b;
	`};
`
