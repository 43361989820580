import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}
	}
`

export const FormContainer = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		.button-container {
			display: flex;
			grid-column: 1/span 3;
			justify-content: end;

			button {
				width: 200px;
			}
		}
	}
`

export const ModalDetalhesContainer = styled.div`
	line-height: 20px;
	padding: 20px 0;

	.empty {
		text-align: center;
	}
`
