import React from 'react'

import ContratoProvider from '../../../contexts/ContratoContext'

import Card from '../../../components/Card'
import Cadastro from './Cadastro'
import Listagem from './Listagem'
import Detalhes from './Detalhes'

import { Container } from './styles'

export default function () {
    return (
        <ContratoProvider>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Contratos</h1>
            	
                        <Cadastro />

                        <Detalhes />

                        <Listagem />
                    </Card>
                </main>
            </Container>
        </ContratoProvider>
    )
}
