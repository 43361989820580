import {
    getMonth, getYear, isBefore, areIntervalsOverlapping, isSameDay, isSameMonth, format, set, addHours
} from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { fillWithZeros } from './string'

export function isBRDate(value) {
    return /^\d{2}\/\d{2}\/\d{4}( \d{2}:\d{2}(h)?(:\d{2})?)?$/.test(value)
}

export function isUSDate(value) {
    return /^\d{4}-\d{2}-\d{2}([T ]\d{2}:\d{2}:\d{2}(\.\d{3}Z)?)?$/.test(value)
}

export function stringToDate(date) {
    if (isUSDate(date)) {
        const [y, m, d] = date.substr(0, 10).split('-')
        return new Date(y, Number(m) - 1, d)
    }

    if (isBRDate(date)) {
        const [d, m, y] = date.substr(0, 10).split('/')
        return new Date(y, Number(m) - 1, d)
    }
}

export function isTimeFormat(value) {
    if (!value) {
        return false
    }

    return /^\d{2}:\d{2}:\d{2}$/.test(value)
}

function getYears(min, max) {
    const result = []

    for (let i = min; i < max; i++) {
        result.push({ value: i, label: String(i) })
    }

    return result
}

export function getAge(birthdate) {
    if (typeof birthdate === 'string') {
        birthdate = stringToDate(birthdate)
    }

    const d = new Date()
    const ano_atual = d.getFullYear()
    const mes_atual = d.getMonth() + 1
    const dia_atual = d.getDate()

    const ano_aniversario = birthdate.getFullYear()
    const mes_aniversario = birthdate.getMonth() + 1
    const dia_aniversario = birthdate.getDate()

    let quantos_anos = ano_atual - ano_aniversario

    if (mes_atual < mes_aniversario || (mes_atual === mes_aniversario && dia_atual < dia_aniversario)) {
        quantos_anos--
    }

    return quantos_anos < 0 ? 0 : quantos_anos
}

export const firstDayOfMonth = date => {
    if (typeof date === 'string') {
        const [year, month] = date.split('-')

        return new Date(year, Number(month) - 1, 1)
    }

    return new Date(getYear(date), getMonth(date), 1)
}

export function getDateObject(dateString, gmt = 0) {
    if (!dateString) {
        return null
    }

    const date = dateString.split(dateString.includes('T') ? 'T' : ' ')[0]
    let time = dateString.split(dateString.includes('T') ? 'T' : ' ')?.[1]

    if (time?.length === 5) {
        time += ':00'
    } else if (!time) {
        time = '00:00:00'
    }

    let [h, m, s] = time.split(':')
    s = s.substr(0, 2) // remove .000Z do final

    let dataRetorno

    if (isBRDate(date)) {
        const [day, month, year] = dateString.substr(0, 10).split('/')

        dataRetorno = new Date(year, Number(month) - 1, day, h, m, s)
    }

    if (isUSDate(date)) {
        const [year, month, day] = dateString.substr(0, 10).split('-')

        dataRetorno = new Date(year, Number(month) - 1, day, h, m, s)
    }

    if (gmt) {
        dataRetorno = addHours(dataRetorno, gmt)
    }

    return dataRetorno
}

export function getMinutesFromTime(time) {
    const [horas, minutos] = time.split(':')

    return Number(horas) * 60 + Number(minutos)
}

export function getTimeFromMinutes(minutes) {
    const horas = fillWithZeros(Math.floor(minutes / 60))
    const minutos = fillWithZeros(minutes % 60)

    return `${horas}:${minutos}:00`
}

export function isBeforeTime(time1, time2) {
    if (!isTimeFormat(time1) || !isTimeFormat(time2)) {
        throw new Error('Horário inválido passado para a função isBeforeTime().')
    }

    const [h1, m1, s1] = time1.split(':')
    const [h2, m2, s2] = time2.split(':')

    const date1 = new Date(2000, 0, 1, h1, m1, s1)
    const date2 = new Date(2000, 0, 1, h2, m2, s2)

    return isBefore(date1, date2)
}

export function haveIntersectionTimes(interval1, interval2) {
    const [h1, m1, s1] = interval1.start.split(':')
    const [h2, m2, s2] = interval1.end.split(':')
    const [h3, m3, s3] = interval2.start.split(':')
    const [h4, m4, s4] = interval2.end.split(':')

    interval1.start = new Date(2000, 0, 1, h1, m1, s1)
    interval1.end = new Date(2000, 0, 1, h2, m2, s2)
    interval2.start = new Date(2000, 0, 1, h3, m3, s3)
    interval2.end = new Date(2000, 0, 1, h4, m4, s4)

    return areIntervalsOverlapping(interval1, interval2)
}

export function getPeriodoPorExtenso(periodo) {
    if (isSameDay(periodo.inicio, periodo.fim)) {
        return `em ${format(periodo.inicio, 'dd\' de \'MMMM\' de \'yyyy', { locale: ptBR })}`
    }

    if (isSameMonth(periodo.inicio, periodo.fim)) {
        return `de ${format(periodo.inicio, 'dd')} a ${format(periodo.fim, 'dd\' de \'MMMM\' de \'yyyy', { locale: ptBR })}`
    }

    return `de ${format(periodo.inicio, 'dd\' de \'MMMM', { locale: ptBR })} a ${format(periodo.fim, 'dd\' de \'MMMM\' de \'yyyy', { locale: ptBR })}`
}

export function getMesPorExtenso(data) {
    return `${format(data, 'MMMM\' de \'yyyy', { locale: ptBR })}`
}

export function convertBRDateToUSDate(brDate) {
    if (isBRDate(brDate)) {
        const [d, m, y] = brDate.split('/')

        return `${y}-${m}-${d}`
    }

    return brDate
}

export function convertUSDateToBRDate(usDate) {
    if (isUSDate(usDate)) {
        const [y, m, d] = usDate.split('-')

        return `${d}/${m}/${y}`
    }

    return usDate
}

export function getDate(dateString) {
    if (!dateString || String(dateString).includes('_')) {
        return ''
    }

    if (typeof dateString === 'object') { // dateString é um objeto Date
        const date = set(dateString, {
            hours: 0,
            minutes: 0,
            seconds: 0
        })

        return date
    }

    if (isBRDate(dateString)) {
        const [day, month, year] = dateString.substr(0, 10).split('/').map(Number)

        return new Date(year, month - 1, day, 0, 0, 0)
    }

    if (isUSDate(dateString)) {
        const [year, month, day] = dateString.substr(0, 10).split('-')

        return new Date(year, Number(month) - 1, day, 0, 0, 0)
    }

    throw new Error('dateString não é uma data válida.')
}

export const getDateString = date => {
    return date.toISOString().split('T')[0]
}

export function dateValidate(date) {
    const data = date

    if (!data) {
        return false
    }

    if (!isBRDate(data)) {
        throw new Error('Data informada não está no formato dd/MM/yyyy.')
    }

    const dia = data.substring(0, 2)
    const mes = data.substring(3, 5)
    const ano = data.substring(6, 10)

    const novaData = new Date(ano, (mes - 1), dia)

    const mesmoDia = parseInt(dia) === parseInt(novaData.getDate())
    const mesmoMes = parseInt(mes) === parseInt(novaData.getMonth()) + 1
    const mesmoAno = parseInt(ano) === parseInt(novaData.getFullYear())

    return mesmoDia && mesmoMes && mesmoAno
}

export const isEarlier = (date1, date2, inclusive = false) => {
    if (!date1.getFullYear || !date2.getFullYear) {
        throw new Error('As datas devem ser do tipo Date.')
    }

    return inclusive ? date1 <= date2 : date1 < date2
}

export const isLater = (date1, date2, inclusive = false) => {
    if (!date1.getFullYear || !date2.getFullYear) {
        throw new Error('As datas devem ser do tipo Date.')
    }

    return inclusive ? date1 >= date2 : date1 > date2
}

export default {
    months: [
        { value: 1, label: 'janeiro' },
        { value: 2, label: 'fevereiro' },
        { value: 3, label: 'março' },
        { value: 4, label: 'abril' },
        { value: 5, label: 'maio' },
        { value: 6, label: 'junho' },
        { value: 7, label: 'julho' },
        { value: 8, label: 'agosto' },
        { value: 9, label: 'setembro' },
        { value: 10, label: 'outubro' },
        { value: 11, label: 'novembro' },
        { value: 12, label: 'dezembro' }
    ],

    futureYears: getYears(new Date().getFullYear(), new Date().getFullYear() + 20),

    getDate,

    getDateString,

    isBRDate: value => isBRDate(value),

    isUSDate: value => isUSDate(value),

    dateValidate,

    isLater,

    isEarlier
}

export function isBetweenTimes(reference, time1, time2) {
    const [hr, mr, sr] = reference.split(':')
    const [h1, m1, s1] = time1.split(':')
    const [h2, m2, s2] = time2.split(':')

    const ref = new Date(2000, 0, 1, hr, mr, sr)
    const t1 = new Date(2000, 0, 1, h1, m1, s1)
    const t2 = new Date(2000, 0, 1, h2, m2, s2)

    return isBefore(t1, ref) && isBefore(ref, t2)
}

export function isBetweenDateTimes(reference, date1, date2) {
    return isBefore(date1, reference) && isBefore(reference, date2)
}

export function getDuracaoExtenso(horas) {
    if (horas === undefined || horas === null) {
        return '-'
    }

    if (horas < 1) {
        return 'Menos de 1 hora'
    }

    if (horas % 24 === 0) {
        const dia = horas / 24

        return `${dia} dia${dia > 1 ? 's' : ''}`
    }

    if (horas > 24) {
        const dia = Math.trunc(horas / 24)
        const hora = horas % 24

        return `${dia} dia${dia > 1 ? 's' : ''} e ${hora} horas`
    }

    return `${horas} hora${horas > 1 ? 's' : ''}`
}

export function calcularDuracao(totalMinutos) {
    let duracao = ''
    // Calcula a quantidade de dias
    const dias = Math.trunc(totalMinutos / (24 * 60))
    totalMinutos %= 24 * 60

    // Calcula a quantidade de horas
    const horas = Math.trunc(totalMinutos / 60)
    totalMinutos %= 60

    // O restante são os minutos
    const minutos = totalMinutos

    if (dias > 0) {
        duracao += `${dias} dia${dias > 1 ? 's' : ''}`
    }

    if (horas > 0) {
        if (duracao) duracao += minutos > 0 ? ', ' : ' e '
        duracao += `${horas} hora${horas > 1 ? 's' : ''}`
    }

    if (minutos > 0) {
        if (duracao) duracao += ' e '
        duracao += `${minutos} minuto${minutos > 1 ? 's' : ''}`
    }

    return duracao
}
