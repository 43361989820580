import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FaCheck } from 'react-icons/fa'
import { toast } from 'react-toastify'

import dateUtils from '../../../util/date'

import Yup from '../../../services/yup'

import ActionFooter from '../../ActionFooter'
import CreditCard from '../../CreditCard'
import Modal from '../../Modal'
import formUtils from '../../../util/form'
import {
    Button, Textbox, Checkbox, Select
} from '../../Form'

import { Container, TermoContainer, ModalTermoContainer } from './styles'
import { GlobalContext } from '../../../contexts/GlobalContext'

const { Iugu } = window

const initialValues = {
    mes: '',
    ano: '',
    numero: '',
    titular: '',
    cvv: '',
    autorizado: false
}

const validation = Yup.object({
    mes: Yup.string().ensure().required('Selecione o mês de vencimento.'),
    ano: Yup.string().ensure().required('Selecione o ano de vencimento.'),
    numero: Yup.string().creditCard().required('Digite o número do cartão.'),
    titular: Yup.string().required('Digite o nome como consta no cartão.'),
    cvv: Yup.number().typeError('Código de segurança inválido.').required('Digite o código de segurança impresso em seu cartão.'),
    autorizado: Yup.boolean()
})

export default function ({ onSubmit, valorMensalidade }) {
    const { clienteRobot } = useContext(GlobalContext)

    const [submitting, setSubmitting] = useState(false)
    const [showTermoCiencia, setShowTermoCiencia] = useState(false)

    async function handleSubmit(values) {
        setSubmitting(true)

        try {
            await (new Promise((resolve, reject) => {
                const {
                    numero, titular, cvv, mes, ano
                } = formUtils.extractFormValues(values)

                const posicao = titular.indexOf(' ')
                const nome = titular.slice(0, posicao)
                const sobrenome = titular.slice(posicao + 1)
        
                const cartaoCredito = Iugu.CreditCard(numero, mes, ano, nome, sobrenome, cvv)
                const bandeira = Iugu.utils.getBrandByCreditCardNumber(numero)

                Iugu.createPaymentToken(cartaoCredito, async payment => {
                    if (payment.errors) {
                        reject(payment.errors)
                    } else {
                        const token = payment.id

                        onSubmit({ 
                            token,
                            bandeira,
                            autorizado: values.autorizado
                        })

                        setSubmitting(false)

                        resolve()
                    }
                })
            }))
        } catch (e) {
            if (e.verification_value) {
                toast.error('O código de segurança é inválido.')
            } else if (e.expiration) {
                toast.error('Este cartão está vencido.')
            } else if (e.record_invalid) {
                toast.error('Número do cartão é inválido. Verifique e tente novamente.')
            } else if (e.adblock) {
                toast.error('Por favor desabilite seu bloqueador de anúncios e recarregue a página para conseguir efetivar a transação com seu cartão de crédito!.')
            } else {
                toast.error(e.msg || 'Ocorreu um erro na troca da forma de pagamento.')
            }
        }
    }

    useEffect(() => {
        Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
        Iugu.setTestMode(process.env.REACT_APP_IUGU_TEST_MODE === 'true')
    }, [])

    return (
        <>
            <Container>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({
                        isSubmitting, setFieldValue, values
                    }) => (
                        <Form>
                            <CreditCard
                                name={values.titular}
                                number={values.numero}
                                expiration={values.ano?.value && values.mes?.value && new Date(values.ano.value, values.mes.value - 1)}
                                cvv={values.cvv}
                            />

                            <Select
                                name="mes"
                                label="Mês de vencimento"
                                isSearchable={false}
                                containerStyle={{ gridArea: 'mes' }}
                                options={dateUtils.months}
                                onChange={selected => { setFieldValue('mes', selected) }}
                            />

                            <Select
                                name="ano"
                                label="Ano de vencimento"
                                isSearchable={false}
                                containerStyle={{ gridArea: 'ano' }}
                                options={dateUtils.futureYears}
                                onChange={selected => {
                                    setFieldValue('ano', selected)
                                }}
                            />

                            <Textbox
                                label="Número do cartão"
                                name="numero"
                                id="numero_cartao"
                                inputMode="numeric"
                                maxLength={16}
                                containerStyle={{ gridArea: 'numero' }}
                            />

                            <Textbox
                                label="Nome do titular"
                                name="titular"
                                style={{ textTransform: 'uppercase' }}
                                containerStyle={{ gridArea: 'titular' }}
                            />

                            <Textbox
                                label="CVV"
                                name="cvv"
                                maxLength={4}
                                inputMode="numeric"
                                containerStyle={{ gridArea: 'cvv' }}
                            />

                            <TermoContainer>
                                <Button onClick={() => setShowTermoCiencia(true)} className="transparent">
                                    VER TERMO DE CIÊNCIA
                                </Button>
                            </TermoContainer>

                            <Checkbox
                                name="autorizado"
                                label={`Li e concordo com o Termo de Ciência acima e autorizo o desconto da mensalidade através de débito em meu cartão de crédito, no dia ${new Date().getDate() < 10 ? `0${new Date().getDate()}` : `${new Date().getDate()}`} de cada mês, com valor correspondente a R$ ${valorMensalidade.toFixed(2).replace('.', ',')} em favor do ${clienteRobot?.name}. Estou ciente que este valor será reajustado anualmente no mês de janeiro.`}
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            />

                            <ActionFooter style={{ gridArea: 'button' }}>
                                <Button type="submit" loading={isSubmitting || submitting} className="white" disabled={!values.autorizado}>
                                    Concluir
                                    <FaCheck />
                                </Button>
                            </ActionFooter>
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={showTermoCiencia}
                handleClose={() => setShowTermoCiencia(false)}
                title="Termo de Ciência do Associado"
            >
                <ModalTermoContainer>
                    <iframe src={`${process.env.REACT_APP_BACKEND_URL}/resources/TERMO DE CIENCIA.pdf`} frameBorder="0" title="Termo de Ciência" />
                </ModalTermoContainer>
            </Modal>
        </>
    )
}
