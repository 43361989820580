import React, { useContext } from 'react'

import { Button } from '../../../../../components/Form'
import Modal from '../../../../../components/Modal'

import { TutorialContext } from '../../../../../contexts/TutorialContext'

import Cadastrar from './Cadastrar'
import Listagem from './Listagem'

import { Container } from './styles'

export default function () {
    const {
        setShowCadastroAssunto, showCadastroAssunto, editAssunto, setEditAssunto 
    } = useContext(TutorialContext)

    return (
        <Container>
            <Button className="transparent" onClick={() => { setShowCadastroAssunto(true) }}>
                Cadastrar
            </Button>

            <Listagem />

            <Modal
                isOpen={showCadastroAssunto || !!editAssunto}
                handleClose={() => { 
                    setShowCadastroAssunto(false)
                    setEditAssunto(null)
                }}
                title="Cadastro de assuntos para tópicos de ajuda"
            >
                <Cadastrar />
            </Modal>
        </Container>
    )
}
