import React, { useContext } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import { useField } from 'formik'
import { FaQuestionCircle } from 'react-icons/fa'

import Option from './Option'

import { TipContainer } from '../styles'
import { Select, SelectContainer } from './styles'
import { TipContext } from '../../../contexts/TipContext'

const selectStyle = {
    input: (styles) => ({
        ...styles,
        color: '#424242'
    }),
    control: (styles, { isDisabled }) => ({ 
        ...styles, 
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        border: 0,
        boxShadow: 'none',
        height: 44,
        cursor: 'pointer',
        opacity: isDisabled ? 0.8 : 1,
        ':focus-within': {
            border: 'dashed 1px rgba(255, 255, 255, 0.9)'
        }
    }),
    singleValue: (styles) => ({
        ...styles,
        color: '#424242'
    }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#FFF',
        borderRadius: 15,
        color: '#424242',
        padding: '4px 0'
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }),
    clearIndicator: () => ({
        display: 'none'
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#acacac'
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9 })
}

export default function ({
    withoutForm, 
    label, 
    placeholder = 'Selecione...', 
    options, 
    style = {}, 
    containerStyle = {}, 
    isDisabled, 
    containerClass = '', 
    className = '', 
    hint,
    ...props
}) {
    let field
    let meta

    if(!withoutForm) {
        [field, meta] = useField(props)
    }

    const { tips, handleSpeak } = useContext(TipContext)

    const tip = tips.campos?.find(item => item.campo === field?.name || item.campo === props.id || props.name === item.campo || props.id === item.campo)
    const tipText = tip?.texto
    const tipAvatarText = tip?.texto_avatar

    if(withoutForm) {
        return (
            <SelectContainer className={containerClass} style={containerStyle}>
                <span className="label">{label}</span>

                <Select 
                    options={options} 
                    className={`select ${className || ''}`}
                    noOptionsMessage={() => 'Nenhuma opção disponível'}
                    menuPortalTarget={document.querySelector('body')}
                    components={{
                        Option, 
                        IndicatorSeparator: null, 
                        DropdownIndicator: null // Chevron 
                    }}
                    styles={selectStyle}
                    style={style}
                    isDisabled={isDisabled || !options?.length}
                    placeholder={placeholder}
                    {...field} 
                    {...props}
                />

                {hint && <div className={`hint ${hint.type}`} dangerouslySetInnerHTML={{ __html: hint.text }} />}

                {tipText ? (
                    <>
                        <FiHelpCircle size={14} />

                        <TipContainer>
                            <FaQuestionCircle size={13} />
                            <span>{tipText}</span>
                        </TipContainer>
                    </>
                ) : null}
            </SelectContainer>
        )
    }

    return (
        <SelectContainer className={`${containerClass} ${field.name}`} style={containerStyle} error={meta.touched && meta.error}>
            <span className="label">{label}</span>

            <Select 
                options={options} 
                className={className || ''}
                noOptionsMessage={() => 'Nenhuma opção disponível'}
                menuPortalTarget={document.querySelector('body')}
                components={{
                    Option, 
                    IndicatorSeparator: null, 
                    DropdownIndicator: null // Chevron 
                }}
                styles={selectStyle}
                style={style}
                placeholder={placeholder}
                isDisabled={isDisabled || !options?.length}
                {...field} 
                {...props}
            />

            {hint && <div className={`hint ${hint.type}`} dangerouslySetInnerHTML={{ __html: hint.text }} />}

            {tipText ? (
                <>
                    <FiHelpCircle size={14} onMouseEnter={() => handleSpeak(tipAvatarText || tipText)} />

                    <TipContainer>
                        <FaQuestionCircle size={13} />
                        <span>{tipText}</span>
                    </TipContainer>
                </>
            ) : null}

            {meta.touched && meta.error ? (
                <span className="error">{meta.error}</span>
            ) : null}
        </SelectContainer>
    )
}
