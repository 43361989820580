import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	> button {
		align-self: end;
	}

	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas: 
			'tip tip tip'
			'fornecedor area objeto'
			'link x x'
			'inicio periodo manutencao'
			'arquivo tipo_manut periodo_manut'
			'arquivo y button';

		.file {
			grid-area: arquivo;
		}

		.link-cadastro-fornecedor {
			color: #fff;
			font-size: 11px;
			margin: 0 12px;
			text-decoration: underline;
			justify-self: end;
			grid-area: link;

			&:hover {
				opacity: 0.8;
			}
		}

		button {
			grid-area: button;
			width: fit-content;
			justify-self: end;
			align-self: end;
			margin-bottom: 20px;
		}

		.inicio {
			grid-area: inicio;
		} 

		.periodo {
			grid-area: periodo;
		}

		.manutencao {
			grid-area: manutencao;
		}

		.tipo_manutencao {
			grid-area: tipo_manut;
		}

		.periodo_manutencao {
			grid-area: periodo_manut;
		}

		.objeto {
			grid-area: objeto;
		}

		@media(max-width: 900px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
			    'tip tip'
				'fornecedor area'
				'link x'
				'inicio periodo'
				'objeto manutencao'
				'tipo_manut periodo_manut'
				'arquivo arquivo'
				'y button';
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;

			.link-cadastro-fornecedor {
				align-self: end;
			}
		}
	}
`
