import React from 'react'
import ReactModal from 'react-modal'
import { FiX } from 'react-icons/fi'

import { Wrapper, Content } from './styles'

import '../../styles/modal.css'

ReactModal.setAppElement('#root')

export default function ({
    children,
    isOpen,
    title = '',
    handleClose = () => { },
    style = {},
    closeOnOverlayClick
}) {
    return (
        <ReactModal
            isOpen={isOpen}
            closeTimeoutMS={400}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            onRequestClose={handleClose}
        >
            <Wrapper style={style}>
                <h1>{title}</h1>

                <FiX size={24} onClick={handleClose} />

                <Content>
                    {children}
                </Content>
            </Wrapper>
        </ReactModal>
    )
}
