import React, {
    useState, useEffect, createContext, useContext 
} from 'react'
import {
    Tab, Tabs, TabList, TabPanel 
} from 'react-tabs'
import { format } from 'date-fns'

import { api, authHeaders } from '../../../../services/api'

import Card from '../../../../components/Card'

import Listagem from './Listagem'
import Cadastro from './Cadastro'

import { Container } from './styles'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

const taxaAtualizacao = 10000

export const AutomaticoContext = createContext()

export default function () {
    const { setCodigo } = useContext(TipContext)

    const [tasks, setTasks] = useState(null)
    const [isReloading, setReloading] = useState(false)

    async function loadTasks() {
        const response = await api.get('task', authHeaders())

        setTasks(response.map(task => ({
            ...task,
            period: task.start && task.end 
                ? `${format(new Date(task.start), 'dd/MM/yyyy HH:mm\'h\'')} até ${format(new Date(task.end), 'dd/MM/yyyy HH:mm\'h\'')}` 
                : 'Até interrupção manual'
        })))
    }

    function reload() {
        setReloading(true)

        loadTasks()
        
        setReloading(false)
    }

    useEffect(() => {
        setInterval(() => {
            loadTasks()
        }, taxaAtualizacao)

        loadTips(setCodigo, 'form_rotinas')
    }, [])

    return (
        <AutomaticoContext.Provider value={{
            tasks,
            setTasks,
            reload,
            isReloading
        }}
        >
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Rotinas automáticas</h1>

                        <Tabs>
                            <TabList>
                                <Tab>Cadastro</Tab>
                            </TabList>

                            <TabPanel>
                                <Cadastro />
                            </TabPanel>
				
                        </Tabs>

                        <p className="observacao">{`O tempo de execução das tarefas é atualizado a cada ${taxaAtualizacao / 1000} segundos.`}</p>

                        <Listagem />

                    </Card>
                </main>
            </Container>
        </AutomaticoContext.Provider>
    )
}
