import React, { useState, useEffect, useContext } from 'react'
import { Form, Formik } from 'formik'
import { FiSend } from 'react-icons/fi'
import { toast } from 'react-toastify'

import arrayUtils from '../../../util/array'
import formUtils from '../../../util/form'

import Card from '../../../components/Card'
import {
    Button, Select, Textarea
} from '../../../components/Form'

import { Container } from './styles'

import { api, authHeaders } from '../../../services/api'

import { loadTips } from '../../../util/tip'

import { TipContext } from '../../../contexts/TipContext'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [destinatarios, setDestinatarios] = useState([])

    async function loadDestinatarios() {
        let response = await api.get('pessoa', {
            params: {
                categorias: ['diretor', 'funcionario', 'advogado']
            },
            ...authHeaders()
        })

        response = response.sort(arrayUtils.sort.comparisonFunction('nome'))

        setDestinatarios(response.map(pessoa => ({
            value: pessoa.id,
            label: pessoa.nome
        })))
    }

    async function handleEnviar(values, { resetForm }) {
        try {
            values = formUtils.extractFormValues(values)

            await api.post('recepcao/recado', {
                destinatario: values.destinatario,
                recado: values.recado
            }, authHeaders())

            toast.success('Recado enviado com sucesso.')

            resetForm()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadDestinatarios()
    }, [])

    useEffect(() => {
        loadTips(setCodigo, 'form_relacionamento_recado')
    }, [])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Recados</h1>
                    <p>{tips.formulario}</p>

                    <Formik
                        onSubmit={handleEnviar}
                        initialValues={{
                            destinatario: null,
                            recado: ''
                        }}
                    >
                        {({ setFieldValue, isSubmitting }) => (
                            <Form>
                                <Select
                                    name="destinatario"
                                    label="Destinatario"
                                    options={destinatarios}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                <Textarea
                                    name="recado"
                                    label="Recado"
                                />

                                <Button className="transparent" type="submit" loading={isSubmitting}>
                                    Enviar
                                    <FiSend size={16} />
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
