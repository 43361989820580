import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'
import { FiPlay } from 'react-icons/fi'

import Card from '../../../../components/Card'
import { Container } from './styles'
import { api, authHeaders } from '../../../../services/api'
import Spinner from '../../../../components/Spinner'
import Stepper from '../../../../components/Stepper'
import { Button } from '../../../../components/Form'

export default function () {
    const { id } = useParams()

    const history = useHistory()

    const [enquete, setEnquete] = useState(null)
    const [iniciando, setIniciando] = useState(false)
    const [iniciada, setIniciada] = useState(false)

    if (!id) {
        toast.error('Enquete não encontrada.')

        history.push('/dashboard')

        return
    }

    async function verificarParticipacao() {
        try {
            const response = await api.get(`enquete/${id}/situacao_participacao`, authHeaders())

            switch (response.situacao) {
                case 'encerrada':
                    toast.info('Você já encerrou sua participação nesta enquete. Obrigado!')
                    history.push('/enquete/participacao')
                    break
                case 'em curso':
                    setIniciada(true)
                    break
                default: break
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadEnquete() {
        try {
            const response = await api.get(`enquete/${id}`, authHeaders())

            setEnquete(response)
        } catch (e) {
            if (e?.code === 401) {
                history.push('/enquete/participacao')
            }

            toast.error(e.msg)
        }
    }

    async function handleIniciar() {
        try {
            setIniciando(true)

            await api.put(`enquete/${id}/iniciar`, {}, authHeaders())

            setIniciada(true)
        } catch (e) {
            toast.error(e.msg)
        }

        setIniciando(false)
    }

    async function handleFinalizar() {
        try {
            await api.put(`enquete/${id}/finalizar`, {}, authHeaders())

            history.push(`/enquete/${id}/feedback`)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleNextStep(questao, alternativa, isLast) {
        try {
            if(!isLast) {
                await api.put(`enquete/${id}/questao_resposta`, {
                    questao_id: questao.id,
                    alternativa_id: alternativa.id
                }, authHeaders())
            } else {
                await api.put(`enquete/${id}/mensagem_finalizacao`, {
                    mensagem: alternativa
                }, authHeaders())
            }

            if (isLast) {
                handleFinalizar()
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        verificarParticipacao()

        loadEnquete()
    }, [])

    if (!enquete) {
        return (
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <Spinner />
                    </Card>
                </main>
            </Container>
        )
    }

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>
                        {`Enquete: ${enquete.nome || ''}`}
                    </h1>

                    {iniciada ? (
                        <Stepper
                            steps={enquete.questoes}
                            onNextStep={handleNextStep}
                        />
                    ) : (
                        <div className="button-container">
                            {iniciando ? (
                                <Spinner label="Iniciando enquete..." />
                            ) : (
                                <Button onClick={() => { handleIniciar() }} className="white">
                                    Iniciar enquete
                                    <FiPlay size={16} />
                                </Button>
                            )}
                        </div>
                    )}
                </Card>
            </main>
        </Container>
    )
}
