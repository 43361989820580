import React, { useContext } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'

import { Button, File, Textbox } from '../../../../components/Form'

import masks from '../../../../util/masks'
import formUtils from '../../../../util/form'

import { Container } from './styles'

import { api, authHeaders } from '../../../../services/api'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import { ConvenioContext } from '../../../../contexts/ConvenioContext'

export default function ({ reload }) {
    const { user } = useContext(GlobalContext)
    const { setShowFormAtualizacaoComprovanteMatricula } = useContext(ConvenioContext)

    async function handleAtualizarComprovante(values) {
        try {
            values = formUtils.extractFormValues(values)

            await api.put(`associado/comprovante_matricula/${user.associado.id}`, values, authHeaders())

            toast.success('Seu comprovante foi atualizado.')

            setShowFormAtualizacaoComprovanteMatricula(false)

            reload()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <Formik
                onSubmit={handleAtualizarComprovante}
                initialValues={{
                    arquivo: null,
                    validade: ''
                }}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <File 
                            name="arquivo"
                            label="Anexe o comprovante"
                            onSuccess={fileId => setFieldValue('arquivo', fileId)}
                            format="square"
                            previewSize={['100%', '140px']}
                        />

                        <Textbox 
                            name="validade"
                            label="Data de validade"
                            mask={masks.date}
                        />

                        <Textbox 
                            name="periodo"
                            label="Início do período atual"
                            mask={masks.date}
                        />

                        <Button type="submit" className="transparent" loading={isSubmitting}>Atualizar comprovante</Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
