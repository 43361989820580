import React, { createContext, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { api } from '../services/api'
import { AvatarContext } from './AvatarContext'

export const AssociacaoContext = createContext({})

export default function AssociacaoProvider({ children, avancarEtapa }) {
    const history = useHistory()

    const { handleSpeak } = useContext(AvatarContext)

    const [tempData, setTempData] = useState({})

    async function sincronizarDadosTemporarios(identificador) {
        const response = await api.get(`associacao/dados_temporarios/${identificador}`)

        if(response) {
            setTempData(response.dados)

            handleSpeak('Parece que você já havia iniciado sua associação antes, recuperamos todos os dados pra você. Basta continuar de onde parou.', { rate: 1.4 })
        }
    }

    async function syncTempData(data) {
        data = Object.entries(data).reduce((result, [key, value]) => ({
            ...result,
            [key]: typeof value === 'string' ? value.trim() : value
        }), {})

        const dadosAtualizados = {
            ...tempData,
            ...data
        }

        setTempData(dadosAtualizados)

        // Gravar os dados também no localStorage

        try {
            await api.put('associacao/dados_temporarios', dadosAtualizados)
        } catch(e) {
            toast.error(e.message)
        }
    }

    async function associar(values) {
        try {
            if(!tempData.matricula) {
                toast.error('Matrícula não localizada.')
            }

            await api.post(`associar/${tempData.matricula}`, values)

            toast.success('Associação concluída!')

            history.push('/')
        } catch(e) {
            toast.error(e.msg)
        }
    }

    return (
        <AssociacaoContext.Provider
            value={{
                tempData,
                syncTempData,
                sincronizarDadosTemporarios,
                avancarEtapa,
                associar
            }}
        >
            {children}
        </AssociacaoContext.Provider>
    )
}
