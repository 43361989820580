import React, { useContext, useState } from 'react'
import {
    FiTrash,
    FiDollarSign,
    FiMinus,
    FiSearch
} from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Formik, Form, FieldArray } from 'formik'

import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext'

import masks from '../../../../util/masks'
import scrollUtils from '../../../../util/scroll'

import { api, authHeaders } from '../../../../services/api'

import Table from '../../../../components/Table'
import {
    Textbox, Button, Calendar, Select
} from '../../../../components/Form'
import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'
import SmallScreenPlaceholder from '../../../../components/SmallScreenPlaceholder'

import {
    Container, ExtraText, BadgeContainer, DadosAssociadoContainer, RegrasDescontoContainer, RegraDesconto
} from './styles'

const initialValues = {
    vencimento: '',
    juros: '',
    multa: '',
    descontos: [],
    formas_pagamento: [],
    parcelas: {
        label: '1',
        value: 1
    }
}

export default function () {
    const {
        handlePrepareEdit, contribuicoes, valorPendente, valorEfetuado, valorACobrar, handleDelete, handleDeleteMultiple, setCobrancaAgrupada, cobrancaAgrupada, reload
    } = useContext(ContribuicaoContext)

    const [faturaUrl, setFaturaUrl] = useState(null)

    function handleAgruparCobrancas(ids) {
        const contribuicoesAAgrupar = contribuicoes.filter(c => ids.includes(c.id))

        if (!contribuicoesAAgrupar.every(c => c.crm === contribuicoesAAgrupar[0].crm)) {
            toast.warn('Para agrupar cobranças, todas devem ser do mesmo associado.')
            return
        }

        if (contribuicoesAAgrupar.some(c => c.status_id)) {
            toast.warn('Você selecionou uma contribuição que já está paga. Verifique e tente novamente.')
            return
        }

        const somatoria = contribuicoesAAgrupar.reduce((result, current) => result + masks.unmask.money(current.valor), 0)

        setCobrancaAgrupada({
            contribuicoes: contribuicoesAAgrupar.map(c => ({
                id: c.id,
                competencia: c.competencia,
                valor: c.valor
            })),
            somatoria,
            crm: contribuicoesAAgrupar[0].crm,
            nome: contribuicoesAAgrupar[0].nome
        })
    }

    async function handleGerarCobranca(values) {
        try {
            values.contribuicoesIds = cobrancaAgrupada.contribuicoes.map(c => c.id)
            values.associado_id = cobrancaAgrupada.associado_id
            values.descontos = values.descontos.map(desc => ({
                data: new Date(desc.data),
                percentual: Number(desc.percentual.replace('%', ''))
            }))
            values.formas_pagamento = values.formas_pagamento.map(forma => forma.value)
            values.parcelas = values.parcelas.value

            let { link } = await api.post('fatura_avulsa', values, authHeaders())

            setFaturaUrl(link)

            toast.success('Cobrança gerada com sucesso.')

            setCobrancaAgrupada(null)

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <>
            <Container>
                {contribuicoes === 'loading' ? (
                    <Spinner />
                ) : contribuicoes ? (
                    <>
                        <SmallScreenPlaceholder
                            text="Gire o dispositivo para visualizar os lançamentos."
                            hideOffset={670}
                        />

                        <BadgeContainer>
                            <ExtraText>{`Pagamentos em aberto: ${masks.maskApply.currency(valorPendente)}`}</ExtraText>
                            <ExtraText color="#2196f3" textColor="#fff">{`Pagamentos efetuados: ${masks.maskApply.currency(valorEfetuado)}`}</ExtraText>
                            {/* <ExtraText color="#f44336" textColor="#fff">{`Pagamentos a cobrar: ${masks.maskApply.currency(valorACobrar)}`}</ExtraText> */}
                        </BadgeContainer>

                        <Table
                            tableId="contribuicoes-table"
                            headers={[
                                { name: 'crm', value: 'Matrícula', centered: true },
                                { name: 'nome', value: 'Nome' },
                                { name: 'competencia', value: 'Competência', centered: true },
                                { name: 'tipo_pagamento', value: 'Tipo pagamento', centered: true },
                                { name: 'tipo_baixa', value: 'Tipo baixa', centered: true },
                                { name: 'valor', value: 'Valor', centered: true },
                                { name: 'data_consolidacao', value: 'Data', centered: true },
                                {
                                    name: 'status', value: 'Status', tooltip: true, icon: true
                                }
                            ]}
                            data={contribuicoes}
                            handlePrepareEdit={handlePrepareEdit}
                            handleDelete={handleDelete}
                            hideOnSmallHeaders={['nome', 'tipo_pagamento', 'tipo_baixa']}
                            hideOffset={960}
                            confirmExclusion={{
                                attr: 'nome',
                                template: 'Deseja realmente excluir a fatura de #attr#?'
                            }}
                            checkable
                            checkboxActions={[
                                {
                                    icon: FiTrash,
                                    handle: handleDeleteMultiple,
                                    confirmationMessage: 'Tem certeza que deseja excluir todas as faturas selecionadas?',
                                    tooltip: 'Excluir selecionados'
                                },
                                {
                                    icon: FiDollarSign,
                                    handle: handleAgruparCobrancas,
                                    tooltip: 'Gerar cobrança'
                                }
                            ]}
                            actions={[
                                {
                                    name: 'Visualizar fatura',
                                    icon: FiSearch,
                                    iconSize: 16,
                                    action: item => {
                                        setFaturaUrl(item.link_fatura || null)
                                    },
                                    checkDisabled: item => !item.link_fatura,
                                    disableTooltip: true
                                }
                            ]}
                        />
                    </>
                ) : (
                    <div />
                )}
            </Container>

            <Modal
                isOpen={!!faturaUrl}
                handleClose={() => {
                    setFaturaUrl('')
                }}
                title="Fatura"
            >
                <iframe
                    src={faturaUrl?.replace('checkout', 'checkout_auth_iframe')}
                    title="Fatura"
                    className="w-full h-[80vh] rounded-md"
                />
            </Modal>

            <Modal
                isOpen={!!cobrancaAgrupada}
                handleClose={() => { setCobrancaAgrupada(null) }}
                title="Cobrança de contribuição"
            >
                <Formik
                    onSubmit={handleGerarCobranca}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <DadosAssociadoContainer>
                                <span style={{ gridArea: 'f1' }}>
                                    CRM:
                                    <b>{` ${cobrancaAgrupada?.crm}`}</b>
                                </span>

                                <span style={{ gridArea: 'f2' }}>
                                    Nome:
                                    <b>{` ${cobrancaAgrupada?.nome}`}</b>
                                </span>

                                <Table
                                    headers={[
                                        { name: 'competencia', value: 'Competência', centered: true },
                                        { name: 'valor', value: 'Valor', centered: true }
                                    ]}
                                    data={cobrancaAgrupada?.contribuicoes}
                                    footers={[
                                        {
                                            text: 'TOTAL',
                                            align: 'center'
                                        },
                                        {
                                            text: masks.maskApply.currency(cobrancaAgrupada?.somatoria),
                                            align: 'center'
                                        }
                                    ]}
                                />

                                <Textbox
                                    name="vencimento"
                                    label="Vencimento"
                                    mask={masks.date}
                                />

                                <Textbox
                                    name="juros"
                                    label="Juros ao mês (%)"
                                    mask={masks.percent}
                                    hint="Cobrado após o vencimento"
                                />

                                <Textbox
                                    name="multa"
                                    label="Multa (%)"
                                    mask={masks.percent}
                                    hint="Cobrado após o vencimento"
                                />

                                <Select
                                    isMulti
                                    placeholder="Boleto, cartão e PIX"
                                    name="formas_pagamento"
                                    label="Formas de pagamento"
                                    options={[
                                        { label: 'Boleto bancário', value: 'bank_slip' },
                                        { label: 'Cartão de crédito', value: 'credit_card' },
                                        { label: 'PIX', value: 'pix' }
                                    ]}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                <Select
                                    name="parcelas"
                                    label="Informe o número de parcelas"
                                    options={[
                                        { label: '1', value: 1 },
                                        { label: '2', value: 2 },
                                        { label: '3', value: 3 },
                                        { label: '4', value: 4 },
                                        { label: '5', value: 5 }
                                    ]}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                <Textbox
                                    name="descricao"
                                    label="Descrição"
                                    containerStyle={{ gridArea: 'f3' }}
                                />

                                <FieldArray
                                    name="descontos"
                                    render={arrayHelpers => (
                                        <RegrasDescontoContainer>
                                            <h1>Regras para descontos</h1>

                                            {values.descontos?.map((_, index) => (
                                                <RegraDesconto key={index} className={`desconto-item-${index}`}>
                                                    <Calendar
                                                        name={`descontos.${index}.data`}
                                                        label="Pago até a data"
                                                    />

                                                    <Textbox
                                                        name={`descontos.${index}.percentual`}
                                                        label="Percentual de desconto"
                                                        mask={masks.percent}
                                                    />

                                                    <Button
                                                        fab
                                                        tiny
                                                        className="white"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        <FiMinus />
                                                    </Button>
                                                </RegraDesconto>
                                            ))}

                                            <Button
                                                onClick={() => {
                                                    arrayHelpers.push('')

                                                    scrollUtils.toElementContent('.descontos-container', '.desconto-item')
                                                }}
                                                className="transparent"
                                            >
                                                Adicionar desconto
                                            </Button>
                                        </RegrasDescontoContainer>
                                    )}
                                />

                                <Button type="submit" className="white" style={{ gridArea: 'button' }} loading={isSubmitting}>
                                    Gerar cobrança
                                </Button>
                            </DadosAssociadoContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
