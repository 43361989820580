import React, { useContext, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { Container, List } from './styles'
import { ProcessoGerenciamentoContext } from '../ProcessoGerenciamentoContext'
import { Select } from '../../../../../components/Form'
import Item from './Item'
import { TipContext } from '../../../../../contexts/TipContext'
import { loadTips } from '../../../../../util/tip'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)
    const {
        perfisDisponiveis, tiposDisponiveis, setPerfilSelecionado, perfilSelecionado, tiposDoPerfilAbertura, handleUpdatePerfilTipo
    } = useContext(ProcessoGerenciamentoContext)

    function getTiposNaoAlocados() {
        const alocadosIds = tiposDoPerfilAbertura.map(a => a.id)

        return tiposDisponiveis.filter(a => !alocadosIds.includes(a.id))
    }

    function getPerfilSelecionado() {
        return perfisDisponiveis.find(s => s.id === perfilSelecionado)?.descricao || ''
    }

    useEffect(() => {
        loadTips(setCodigo, 'form_processo_perfis_tipos')
    }, [])

    return (
        <Container>
            <p className="tip">{tips?.formulario}</p>

            <DragDropContext onDragEnd={handleUpdatePerfilTipo}>
                {tiposDisponiveis ? (
                    <Formik
                        onSubmit={() => { }}
                        initialValues={{ perfil: '' }}
                    >
                        {({ setFieldValue, values }) => (
                            <Form>
                                <Select
                                    label="Perfil"
                                    name="perfil"
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)

                                        setPerfilSelecionado(selected.value)
                                    }}
                                    options={perfisDisponiveis?.map(perfil => ({
                                        label: perfil.descricao,
                                        value: perfil.id
                                    })) || []}
                                />

                                <div>
                                    <h1>Tipos de atendimentos disponíveis</h1>

                                    <Droppable droppableId="disponiveis">
                                        {provided => (
                                            <List
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {values.perfil?.value
                                                    ? getTiposNaoAlocados().map((item, index) => (
                                                        <Item
                                                            key={item.id}
                                                            draggableId={String(item.id)}
                                                            item={item}
                                                            index={index}
                                                        />
                                                    ))
                                                    : tiposDisponiveis.map((item, index) => (
                                                        <Item
                                                            key={item.id}
                                                            draggableId={String(item.id)}
                                                            item={item}
                                                            index={index}
                                                        />
                                                    ))}
                                            </List>
                                        )}
                                    </Droppable>

                                    {values.perfil?.value && !tiposDisponiveis.length && (
                                        <i>Nenhum tipo disponível</i>
                                    )}
                                </div>

                                <div>
                                    {values.perfil?.value ? (
                                        <>
                                            <h1>{`Tipos de atendimentos disponíveis para o perfil ${getPerfilSelecionado()}`}</h1>

                                            <Droppable droppableId="atuais">
                                                {provided => (
                                                    <>
                                                        {tiposDoPerfilAbertura && (
                                                            <List
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {tiposDoPerfilAbertura.map((item, index) => (
                                                                    <Item
                                                                        key={item.id}
                                                                        draggableId={String(item.id)}
                                                                        item={item}
                                                                        index={index}
                                                                    />
                                                                ))}
                                                            </List>
                                                        )}
                                                    </>
                                                )}
                                            </Droppable>
                                        </>
                                    ) : (
                                        <p>
                                            <i>Selecione um perfil para definir os tipos</i>
                                        </p>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <p>
                        <i>Selecione um perfil para gerenciar os tipos</i>
                    </p>
                )}
            </DragDropContext>
        </Container>
    )
}
