import React, { useState, useEffect, useContext } from 'react'
import { Form, Formik } from 'formik'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { FiCheck, FiPlus } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import { Button, Textbox } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'

import { api, authHeaders } from '../../../../services/api'

import { Container } from './styles'

import Yup from '../../../../services/yup'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

const baseInitialValues = {
    descricao: ''
}

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [faculdades, setFaculdades] = useState(null)
    const [showCadastro, setShowCadastro] = useState(false)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [emEdicao, setEmEdicao] = useState(null)

    async function loadFaculdades() {
        setFaculdades(null)
		
        const response = await api.get('faculdade')

        setFaculdades(response)
    }

    async function handleDelete(item) {
        try {
            await api.delete(`faculdade/${item.id}`, authHeaders())

            toast.success('Faculdade excluída.')

            loadFaculdades()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmit(values) {
        try {
            if(emEdicao) {
                await api.put(`faculdade/${emEdicao.id}`, values, authHeaders())	

                toast.success('Faculdade atualizada.')
            } else {
                await api.post('faculdade', values, authHeaders())

                toast.success('Faculdade cadastrada.')
            }

            setShowCadastro(false)

            setEmEdicao(null)

            setInitialValues(baseInitialValues)

            loadFaculdades()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handlePrepareEdit(item) {
        setEmEdicao(item)

        setShowCadastro(true)

        setInitialValues({
            descricao: item.descricao
        })
    }

    useEffect(() => {
        loadFaculdades()

        loadTips(setCodigo, 'form_faculdades')
    }, [])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Faculdades</h1>

                    {showCadastro ? (
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={Yup.object({
                                descricao: Yup.string().required('Informe o nome da faculdade.')
                            })}
                        >
                            {() => (
                                <Form>
                                    <p style={{ margin: 8, paddingTop: 12 }}>{tips?.formulario}</p>

                                    <div>
                                        <Textbox 
                                            name="descricao"
                                            label="Nome"
                                        />

                                        <Button className="transparent" type="submit">
                                            {emEdicao ? 'Salvar alterações' : 'Cadastrar'}
                                            <FiCheck size={17} />
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <Button className="transparent" onClick={() => { setShowCadastro(true) }}>
                            Cadastrar novo
                            <FiPlus size={16} />
                        </Button>
                    )}

                    {faculdades ? (
                        <Table 
                            headers={[
                                { name: 'descricao', value: 'Nome' }
                            ]}
                            data={faculdades}
                            filterable
                            actions={[
                                {
                                    action: handlePrepareEdit,
                                    name: 'Editar',
                                    icon: FaEdit,
                                    iconSize: 15
                                },
                                {
                                    action: handleDelete,
                                    name: 'Excluir',
                                    icon: FaTrash,
                                    iconSize: 13,
                                    confirmation: 'Deseja realmente excluir esta faculdade?'
                                }
                            ]}
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
