import React, { useEffect, useState } from 'react'
import { FiEye, FiCheck, FiX } from 'react-icons/fi'
import { format } from 'date-fns'

import { toast } from 'react-toastify'
import { extname } from '../../../../util/path'
import { api, authHeaders } from '../../../../services/api'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import { Button } from '../../../../components/Form'
import BoxArquivos from '../../../../boxes/Arquivos'

import { Container, DocumentoContainer } from './styles'

export default function () {
    const [associados, setAssociados] = useState(null)
    const [associadoEmAnalise, setAssociadoEmAnalise] = useState(null)
    const [documentoVisivel, setDocumentoVisivel] = useState(null)

    async function loadAssociados() {
        const response = await api.get('associado', {
            params: {
                documentos_aprovacao_pendente: 1,
                medico: 0
            },
            ...authHeaders()
        })

        if(!response) {
            setAssociados([])
            return
        }

        setAssociados(response.map(a => ({
            id: a.id,
            nome: a.pessoa.nome,
            quantidade_docs: a.pessoa.documentos.filter(d => !d.aprovado).length,
            documentos: a.pessoa.documentos.filter(d => !d.aprovado)
        })))
    }

    async function handleAprovarDocumento() {
        try {
            await api.put(`documento/aprovar/${documentoVisivel.id}`, {}, authHeaders())

            toast.success('Documento aprovado.')

            setDocumentoVisivel(null)

            if(associadoEmAnalise.documentos.length - 1 === 0) {
                setAssociadoEmAnalise(null)
            }

            loadAssociados()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleReprovarDocumento() {
        try {
            await api.put(`documento/reprovar/${documentoVisivel.id}`, {}, authHeaders())

            toast.success('Documento devolvido ao associado.')

            setDocumentoVisivel(null)

            if(associadoEmAnalise.documentos.length - 1 === 0) {
                setAssociadoEmAnalise(null)
            }

            loadAssociados()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadAssociados()
    }, [])

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Avaliar documentação</h1>

                        <Table 
                            headers={[
                                { name: 'nome', value: 'Associado' },
                                { name: 'quantidade_docs', value: 'Quant. documentos' }
                            ]}
                            data={associados || []}
                            actions={[
                                {
                                    action: setAssociadoEmAnalise,
                                    name: 'Verificar',
                                    icon: FiEye,
                                    iconSize: 16
                                }
                            ]}
                        />
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!associadoEmAnalise}
                handleClose={() => { setAssociadoEmAnalise(null) }}
                title={`Documentos de ${associadoEmAnalise?.nome}`}
            >
                <Table 
                    headers={[
                        { name: 'tipo', value: 'Documento' },
                        { name: 'validade', value: 'Validade', centered: true },
                        { name: 'periodo', value: 'Período', centered: true }
                    ]}
                    data={associadoEmAnalise?.documentos.map(doc => ({
                        ...doc,
                        tipo: doc.tipo_documento.descricao,
                        validade: format(new Date(doc.extra.validade), 'dd/MM/yyyy'),
                        periodo: doc.extra.periodo
                    })) || []}
                    actions={[
                        {
                            action: setDocumentoVisivel,
                            name: 'Visualizar',
                            icon: FiEye,
                            iconSize: 16
                        }
                    ]}
                />
            </Modal>

            <Modal
                isOpen={!!documentoVisivel}
                handleClose={() => { setDocumentoVisivel(null) }}
                title={documentoVisivel?.tipo_documento?.descricao}
            >
                <DocumentoContainer>
                    <BoxArquivos 
                        title=""
                        arquivos={[
                            {
                                ...documentoVisivel,
                                nome: documentoVisivel?.tipo_documento?.descricao,
                                link: documentoVisivel?.arquivo?.link,
                                extensao: extname(documentoVisivel?.arquivo?.link)
                            }
                        ]} 
                    />

                    <div className="button-container">
                        <Button className="transparent" onClick={handleReprovarDocumento}>
                            Documento não conforme
                            <FiX size={18} />
                        </Button>

                        <Button className="white" onClick={handleAprovarDocumento}>
                            Aprovar documento
                            <FiCheck size={18} />
                        </Button>
                    </div>
                </DocumentoContainer>
            </Modal>
        </>
    )
}
