import React, { useCallback, useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FaArrowRight } from 'react-icons/fa'
import { FiPlus } from 'react-icons/fi'

import Yup from '../../../../../../services/yup'
import { api } from '../../../../../../services/api'

import masks from '../../../../../../util/masks'
import formUtils from '../../../../../../util/form'

import { Textbox, Button, Select } from '../../../../../../components/Form'
import ActionFooter from '../../../../../../components/ActionFooter'
import Table from '../../../../../../components/Table'

import { Container } from './styles'

const initialValues = { 
    tipo_contato: '',
    valor_contato: '',
    responsavel_contato: ''
}

export default function ({ callback, dadosFornecedor }) {
    const [tipoContato, setTipoContato] = useState([])
    const [validacao, setValidacao] = useState(Yup.object({
        tipo_contato: Yup.string().ensure().required('Selecione um tipo de contato.')
    }))

    async function loadTipoContato() {
        const response = await api.get('tipo_contato')

        setTipoContato(response)
    }
    
    useEffect(() => {
        loadTipoContato()
    }, [dadosFornecedor])

    function adicionarContato(values, { setSubmitting, resetForm }) {
        const data = formUtils.extractFormValues(values)

        data.responsavel_contato = data.responsavel_contato || ''

        setSubmitting(false)
        resetForm()

        const { contatos } = dadosFornecedor

        callback({ 
            contatos: [
                ...contatos,
                data
            ]
        }, false)
    }

    const handleProximaEtapa = useCallback(values => {
        const { site } = values

        callback({ site })
    }, [callback])

    const excluirContato = useCallback(async valor => {
        callback({ 
            contatos: dadosFornecedor.contatos.filter(contato => contato.valor_contato !== valor)
        }, false)

        return true
    }, [callback, dadosFornecedor.contatos])

    const getMascaraContato = useCallback(tipo => {
        switch(tipo) {
            case 1: case 2:
                return masks.phone
            case 3: case 4: case 5:
                return masks.mobile
            default: 
                return null
        }
    }, [])

    const handleTipoContatoChange = useCallback((selected, setFieldValue) => {
        setFieldValue('tipo_contato', selected)

        const tipoId = selected.value
        let validacaoValorContato
        
        switch(tipoId) {
            case 1: case 2:
                validacaoValorContato = Yup.string().phone()
                break
            case 3: case 4: case 5:
                validacaoValorContato = Yup.string().mobile()
                break
            case 6:
                validacaoValorContato = Yup.string().email('E-mail inválido.')
                break
            default: 
                validacaoValorContato = Yup.string()
        }

        setValidacao(Yup.object({
            tipo_contato: Yup.string().ensure().required('Selecione um tipo.'),
            valor_contato: validacaoValorContato.concat(Yup.string().required('Defina o valor.')),
            responsavel_contato: Yup.string()
        }))
    }, [])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validacao}
                onSubmit={adicionarContato}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="contato-form">
                        <Select 
                            name="tipo_contato"
                            label="Tipo de contato"
                            onChange={selected => handleTipoContatoChange(selected, setFieldValue)}
                            options={tipoContato.length > 0 ? tipoContato.map(tipo => ({
                                label: tipo.descricao,
                                value: tipo.id
                            })) : []}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Textbox 
                            name="valor_contato" 
                            label={values.tipo_contato.value === 6 ? 'Endereço' : 'Número'} 
                            mask={getMascaraContato(values.tipo_contato.value)}
                            disabled={!values.tipo_contato.value}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Textbox 
                            name="responsavel_contato" 
                            label="Responsável"
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Button type="submit" loading={isSubmitting} className="transparent">
                            Adicionar
                            <FiPlus />
                        </Button>
                    </Form>
                )}
            </Formik>

            <Table 
                headers={[
                    { name: 'tipo_contato', value: 'Tipo', centered: true },
                    { name: 'valor_contato', value: 'Contato', centered: true }, 
                    { name: 'responsavel_contato', value: 'Responsável', centered: true }
                ]}
                data={dadosFornecedor?.contatos?.length > 0 ? dadosFornecedor.contatos.map(({ valor_contato, tipo_contato, responsavel_contato = '' }, index) => ({
                    id: index,
                    tipo_contato: tipoContato.find(tipo => tipo.id === tipo_contato)?.descricao || '',
                    valor_contato,
                    responsavel_contato
                })) : []}
                filterable={false}
                handleDelete={excluirContato}
                keyProp="valor_contato"
                className="lista-contatos"
            />

            <Formik
                initialValues={{ site: dadosFornecedor?.site || '' }}
                onSubmit={handleProximaEtapa}
                validationSchema={Yup.object({
                    site: Yup.string().site()
                })}
                enableReinitialize
            >
                {({ isSubmitting }) => (
                    <Form className="contato-form">
                        <Textbox 
                            name="site" 
                            label="Site"
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <ActionFooter style={{ gridArea: 'button' }}>
                            <Button type="submit" className="white" loading={isSubmitting}>
                                Continuar
                                <FaArrowRight />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
