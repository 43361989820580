import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: end;
`

export const ArquivosContainer = styled.div`
	gria-area: arquivo;
	display: grid;
	grid-template-rows: 
		'botao'
		'tabela';

	button {
		height: 44px;
		margin-top: 22px;
	}

	.table-container {
		margin-top: 8px;
		width: calc(100% - 16px);
		justify-self: center;
	}
`

export const FormularioContainer = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr;

		.button-container {
			display: flex;
			flex-direction: row;
			justify-content: end;
			align-self: end;
		}

		@media(max-width: 800px) {
			grid-template-columns: 1fr 1fr;
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const FormUploadContainer = styled.div`
	display: flex;
	flex-direction: column;

	button {
		align-self: end;
	}
`
