import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 30px;
		display: flex;
		justify-content: center;

		.card {
			width: 80%;
			display: flex;
			flex-direction: column;
		}

		h1 {
			margin-bottom: 20px;
		}

		form {
			display: flex;
			flex-direction: column;


			p {
				margin: 0 8px;
				line-height: 24px;
			}
		}

		@media(max-width: 400px) {
			padding: 14px 0;

			.card {
				width: 100%;
			}
		}
	}
`

export const ButtonContainer = styled.div`
	grid-area: bt;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 10px;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`

export const ModalDeleteContainer = styled.div`
	padding: 0 8px;
	display: grid;
	grid-template-rows: 30px auto;
	margin-top: 20px;

	.button-container {
		display: flex;
		justify-content: flex-end;
	}
`

export const Badge = styled.div`
	padding: 18px;
	width: fit-content;
	align-self: center;
	color: #424242;
	background-color: #ffeb3b;
	border-radius: 10px;
	line-height: 24px;
	
	ul {
		list-style: circle;
		margin-left: 18px;
	}
`
