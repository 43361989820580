import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import {
    FiVideo, FiThumbsUp, FiThumbsDown, FiCheck, FiX
} from 'react-icons/fi'

import { activateLinks } from '../../util/string'

import { api } from '../../services/api'

import Spinner from '../../components/Spinner'
import Card from '../../components/Card'
import { Button } from '../../components/Form'

import {
    Container, Imagem, Badge, LinkButton, ButtonContainer
} from './styles'

import syndyHeader from '../../assets/images/syndi-header.png'
import fileIcon from '../../assets/images/file.png'

export default function () {
    const { id, pessoa_id } = useParams()

    const [evento, setEvento] = useState(null)
    const [confirmado, setConfirmado] = useState(null)

    async function loadEvento() {
        const response = await api.get(`evento/${id}`)

        if (!response) {
            setEvento(false)
            return
        }

        response.descricao = activateLinks(response.descricao)

        setEvento(response)

        if (!pessoa_id) {
            return
        }

        const presencas = response.presencas.map(p => ({
            pessoa_id: p.pessoa.id,
            data_hora_confirmacao_presenca: p.data_hora_confirmacao_presenca,
            data_hora_confirmacao_ausencia: p.data_hora_confirmacao_ausencia
        }))

        const presenca = presencas.find(p => p.pessoa_id === Number(pessoa_id))

        if (presenca) {
            if (presenca.data_hora_confirmacao_presenca && !presenca.data_hora_confirmacao_ausencia) {
                setConfirmado(true)
            } else if (!presenca.data_hora_confirmacao_presenca && presenca.data_hora_confirmacao_ausencia) {
                setConfirmado(false)
            }
        }
    }

    useEffect(() => {
        loadEvento()
    }, [])

    async function handleConfirmar(presenca) {
        if (confirmado === presenca) {
            return
        }

        await api.post(`evento/${evento.id}/confirmar_presenca/${pessoa_id}/${presenca ? 1 : 0}`)

        setConfirmado(presenca)

        if (presenca) {
            toast.success('Sua presença foi confirmada')
        } else {
            toast.warn('Lamentamos que você não possa comparecer.')
        }
    }

    if (evento === null) {
        return (
            <Container>
                <Spinner />
            </Container>
        )
    }

    if (evento === false) {
        return (
            <Container>
                <Card>
                    <p className="event-not-found">Evento não encontrado!</p>
                </Card>
            </Container>
        )
    }

    return (
        <Container>
            {evento.imagem?.link ? (
                <Imagem src={evento.imagem.link} />
            ) : (
                <Imagem src={syndyHeader} />
            )}

            <h1>{evento.nome}</h1>

            <h2>
                {evento.nome_local ? evento.nome_local : <div />}
                {evento.endereco?.uf && (
                    <span>
                        {`${evento.endereco.rua}, ${evento.endereco.numero} ${evento.endereco.complemento && `(${evento.endereco.complemento})`} - ${evento.endereco.cep} - ${evento.endereco.uf}`}
                    </span>
                )}
            </h2>

            <h3>{`Período: ${format(new Date(evento.inicio), 'dd/MM/yyyy\' às \'HH:mm\'h\'')} a ${format(new Date(evento.fim), 'dd/MM/yyyy\' às \'HH:mm\'h\'')}`}</h3>

            {pessoa_id && (
                <>
                    <p>Confirme sua presença:</p>

                    <ButtonContainer>
                        <Button className={confirmado === true ? 'blue' : 'white'} onClick={() => { handleConfirmar(true) }}>
                            Estarei presente
                            {confirmado === true ? (
                                <FiCheck />
                            ) : (
                                <FiThumbsUp />
                            )}
                        </Button>

                        <Button className={confirmado === false ? 'red' : 'white'} onClick={() => { handleConfirmar(false) }}>
                            Não estarei presente
                            {confirmado === false ? (
                                <FiX />
                            ) : (
                                <FiThumbsDown />
                            )}
                        </Button>
                    </ButtonContainer>
                </>
            )}

            {evento.descricao && (
                <Card>
                    <p className="descricao" dangerouslySetInnerHTML={{ __html: evento.descricao }} />
                </Card>
            )}

            {evento.link_video_conferencia && (
                <>
                    <p>Segue o link da vídeo-conferência:</p>
                    <Badge href={evento.link_video_conferencia} target="_blank" rel="noopener noreferrer">
                        <Card>
                            <div>{evento.link_video_conferencia}</div>
                            <FiVideo />
                        </Card>
                    </Badge>
                </>
            )}

            {evento.documento?.link && (
                <LinkButton href={evento.documento?.link} target="_blank" rel="noopener noreferrer" className="link-button">
                    <img src={fileIcon} alt="programação" />
                    Acesse a programação completa aqui
                </LinkButton>
            )}
        </Container>
    )
}
