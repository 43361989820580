import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		.card {
			display: flex;
			flex-direction: column;

			> button {
				align-self: end;
			}
		}

		@media(max-width: 800px) {
			padding: 20px;
		}

		@media(max-width: 400px) {
			padding: 15px 0;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`

export const PreviewContent = styled.div`
	img, 
	iframe {
		width: 100%;
	}

	iframe {
		height: 75vh;
	}
`

export const CadastroContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	
	.button-container {
		grid-column: 1/span 3;
		display: flex;
		justify-content: end;
	}
`

export const FiltrosContainer = styled.div`
	background-color: #0003;
	border-radius: 10px;
	padding: 16px;
	margin-top: 16px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: end;

	button {
		width: fit-content;
	}

	@media(max-width: 1100px) {
		grid-template-columns: 1fr 1fr;
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`
