import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;
	
	main {
		padding: 35px 50px 35px 90px;

		.card {
			display: flex;
			flex-direction: column;
			gap: 16px;
			
			> h1 {
				margin: 0 8px;
			}

			> p {
				margin-top: 10px;
				margin-bottom: 18px;
				margin-left: 9px;
			}

			.react-tabs__tab-panel {
				min-height: 80vh;

				.tip {
					text-align: left;
					margin: 16px 8px 12px;
					line-height: 24px;
				}
			}
		}
	}
`
