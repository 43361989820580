import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { FaEye } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { FiCheck, FiSearch, FiX } from 'react-icons/fi'
import { Formik, Form } from 'formik'

import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import { Button, Select } from '../../../../components/Form'

import { getAge } from '../../../../util/date'
import maskUtils from '../../../../util/masks'
import formUtils from '../../../../util/form'

import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'

import {
    Container, DetalhesContainer, FiltroContainer, FormCancelamentoContainer, ModalReativacaoContainer 
} from './styles'

export default function () {
    const [associadosComPlano, setAssociadosComPlano] = useState(null)
    const [showDetalhes, setShowDetalhes] = useState(null)
    const [status, setStatus] = useState(null)
    const [buscando, setBuscando] = useState(false)
    const [cancelamento, setCancelamento] = useState(null)
    const [motivosCancelamento, setMotivosCancelamento] = useState(null)
    const [reativacao, setReativacao] = useState(null)
    const [dependentesAReativar, setDependentesAReativar] = useState([])

    async function loadAdesoes() {
        try {
            if(!status) {
                return
            }

            setBuscando(true)

            const statusSelecionadoId = status.value

            const response = await api.get('convenio_associado/load_adesoes', {
                ...authHeaders(),
                params: { status_id: statusSelecionadoId }
            })

            const data = response.map(adesao => ({
                id: adesao.id,
                adesao_id: adesao.id,
                nome: adesao.nome,
                plano_descricao: adesao.convenio,
                inicio_vigencia: adesao.inicio_vigencia ? format(new Date(adesao.inicio_vigencia), 'dd/MM/yyyy') : '-',
                status: adesao.status,
                status_id: adesao.status_id,
                dependentes_count: adesao.quant_dependentes,
                adicionais_count: adesao.quant_adicionais,
                identificador: adesao.crm || adesao.cpf
            }))

            setAssociadosComPlano(data)
        } catch(e) {
            toast.error(e.msg)
        }

        setBuscando(false)
    }

    async function handleShowDetalhes(convenioAssociado) {
        try {
            const adesao = await api.get(`convenio_associado/load_adesao_detalhes/${convenioAssociado.adesao_id}`, authHeaders())

            const adicionaisDependentes = [] 

            for(const dep of adesao.dependentes) {
                const item = dep.dependente.adicionais.map(adc => ({
                    id: adc.id,
                    dependente_id: dep.dependente.id,
                    nome: dep.dependente.pessoa.nome,
                    descricao: adc.descricao,
                    valor: maskUtils.maskApply.currency(adc.valor)
                }))

                adicionaisDependentes.push(...item)
            }

            const normalizado = {
                id: `${adesao.id}_${adesao.convenio.id}`,
                adesao_id: adesao.id,
                convenio: adesao.convenio.empresa.nome,
                convenio_id: adesao.convenio.id,
                nome: adesao.associado.pessoa.nome,
                status_id: adesao.convenio_status.id,
                inicio_vigencia: adesao.inicio_vigencia ? format(new Date(adesao.inicio_vigencia), 'dd/MM/yyyy') : '-',
                portabilidade: adesao.portabilidade,
                dependentes: [
                    {
                        id: adesao.id,
                        adesao_id: adesao.id,
                        nome: `${adesao.associado.pessoa.nome} (titular)`,
                        data_nascimento: `${format(new Date(adesao.associado.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(adesao.associado.pessoa.data_nascimento)} anos)`,
                        admissao: format(new Date(adesao.inicio_vigencia), 'dd/MM/yyyy'),
                        demissao: adesao.deleted_at ? format(new Date(adesao.deleted_at), 'dd/MM/yyyy') : '-',
                        valor: adesao.convenio_status.id === 4 ? '-' : maskUtils.maskApply.currency(adesao.valor_pago),
                        status: adesao.convenio_status.id,
                        tipo_beneficiario: 'titular',
                        convenio_id: adesao.convenio.id
                    },
                    ...adesao.dependentes.map(dep => ({
                        id: dep.dependente.id,
                        adesao_id: adesao.id,
                        nome: dep.dependente.pessoa.nome,
                        data_nascimento: `${format(new Date(dep.dependente.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(dep.dependente.pessoa.data_nascimento)} anos)`,
                        admissao: format(new Date(dep.inicio_vigencia), 'dd/MM/yyyy'),
                        demissao: dep.deleted_at ? format(new Date(dep.deleted_at), 'dd/MM/yyyy') : '-',
                        valor: dep.status === 4 ? '-' : maskUtils.maskApply.currency(dep.dependente.valor_pago),
                        status: dep.status,
                        tipo_beneficiario: 'dependente',
                        convenio_id: adesao.convenio.id
                    }))
                ],
                adicionais: [
                    ...adesao.adicionais.map(adc => ({
                        id: adc.id,
                        nome: adesao.associado.pessoa.nome,
                        descricao: adc.adicional.descricao,
                        valor: maskUtils.maskApply.currency(adc.adicional.valor)
                    })),
                    ...adicionaisDependentes
                ],
                valor_total_plano: maskUtils.maskApply.currency(adesao.valor_total_plano),
                valor_total_adicionais: maskUtils.maskApply.currency(adesao.valor_total_adicionais),
                valor_total: maskUtils.maskApply.currency(adesao.valor_total),
                taxas: [
                    {
                        id: 'taxa_adm',
                        descricao: 'Taxa administrativa',
                        valor: maskUtils.maskApply.currency(adesao.convenio.taxa_administrativa),
                        ocultar: !Number(adesao.convenio.taxa_administrativa)
                    },
                    {
                        id: 'taxa_ban',
                        descricao: 'Taxa bancária',
                        valor: maskUtils.maskApply.currency(adesao.convenio.taxa_bancaria),
                        ocultar: !Number(adesao.convenio.taxa_bancaria)
                    }
                ],
                total_taxas: maskUtils.maskApply.currency(adesao.convenio.total_taxas),
                logs: adesao.logs.map(log => ({
                    ...log,
                    data_hora: format(new Date(log.data_hora), 'dd/MM/yyyy HH:mm\'h\'')
                }))
            }

            setShowDetalhes(normalizado)
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function loadMotivosCancelamento() {
        try {
            const response = await api.get('plano_de_saude/motivos_cancelamento', authHeaders())

            setMotivosCancelamento(response)
        } catch(e) {
            toast.error(e.msg)
        }
    }
   
    function handleAlternarStatus() {
        const desativacao = showDetalhes.status_id === 6

        if(desativacao) {
            setCancelamento(showDetalhes)
        } else {
            setReativacao(showDetalhes)
        }
    }

    async function handleReativar(dependente) {
        try {
            if(dependente) {
                await api.put('plano_de_saude/ativar', {
                    id: dependente.adesao_id,
                    tipo_beneficiario: dependente.tipo_beneficiario,
                    dependentes_a_reativar: [dependente.id]
                }, authHeaders())

                handleShowDetalhes(dependente)
            } else { // titular e seus dependentes
                await api.put('plano_de_saude/ativar', {
                    id: reativacao.adesao_id,
                    tipo_beneficiario: 'titular',
                    dependentes_a_reativar: dependentesAReativar.map(d => d.id)
                }, authHeaders())

                handleShowDetalhes(reativacao)
            }
            
            toast.success('Plano de saúde ativado com sucesso.')

            setReativacao(null)

            let plano = associadosComPlano.find(a => a.adesao_id === showDetalhes.adesao_id)

            if(!plano) {
                toast.warn('Plano não encontrado.')

                return
            }

            if(dependente?.tipo_beneficiario === 'dependente') {
                const adicionaisDependente = showDetalhes.adicionais.filter(adc => adc.dependente_id === dependente.id)

                plano.dependentes_count++

                if(adicionaisDependente.length) {
                    plano.adicionais_count = adicionaisDependente.length
                }
            } else { // titular
                plano = {
                    ...plano,
                    status: 'Regular',
                    status_id: 6,
                    adicionais_count: 0,
                    dependentes_count: dependentesAReativar.length,
                    background: '#c8e6c9'
                }
            }

            setAssociadosComPlano(old => [
                plano,
                ...old.filter(i => i.adesao_id !== plano.adesao_id)
            ])
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleCancelar(values) {
        try {
            values = formUtils.extractFormValues(values)

            const { motivo } = values
            const { tipo_beneficiario, id, convenio_id } = cancelamento

            await api.put('plano_de_saude/desativar', {
                tipo_beneficiario, 
                id,
                motivo,
                convenio: convenio_id
            }, authHeaders())

            setCancelamento(false)

            handleShowDetalhes(showDetalhes)

            let plano = associadosComPlano.find(a => a.id === showDetalhes.adesao_id)

            if(!plano) {
                toast.warn('Plano não encontrado.')

                return
            }

            if(tipo_beneficiario === 'dependente') {
                const adicionaisDependente = showDetalhes.adicionais.filter(adc => adc.dependente_id === id)

                plano.dependentes_count--

                if(adicionaisDependente.length) {
                    plano.adicionais_count--
                }
            } else { // titular
                plano = {
                    ...plano,
                    status: 'Cancelado',
                    status_id: 4,
                    adicionais_count: 0,
                    dependentes_count: 0,
                    background: '#ffcdd2'
                }
            }

            setAssociadosComPlano(old => [
                plano,
                ...old.filter(i => i.adesao_id !== plano.adesao_id)
            ])

            toast.success('Plano de saúde cancelado com sucesso.')
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadMotivosCancelamento()
    }, [])
    
    useEffect(() => {
        setDependentesAReativar([])
        setReativacao(null)
        setCancelamento(null)
    }, [showDetalhes])

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Ativação/desativação de Plano de Saúde</h1>

                        <FiltroContainer>
                            <Select 
                                name="status"
                                withoutForm
                                label="Status"
                                options={[
                                    { label: 'Regular', value: '6' },
                                    { label: 'Cancelado', value: '4' }
                                ]}
                                onChange={selected => {
                                    setStatus(selected)
                                }}
                                value={status}
                            />

                            <Button 
                                onClick={() => {
                                    loadAdesoes()
                                }}
                                className="transparent"
                                disabled={buscando}
                            >
                                Buscar
                                <FiSearch size={16} />
                            </Button>
                        </FiltroContainer>

                        {buscando ? (
                            <Spinner />
                        ) : associadosComPlano ? (
                            <Table
                                headers={[
                                    { value: 'CRM / CPF', name: 'identificador', style: { width: 110 } },
                                    { value: 'Associado', name: 'nome' },
                                    { value: 'Plano', name: 'plano_descricao', centered: true },
                                    { value: 'Inicio vigência', name: 'inicio_vigencia', centered: true },
                                    { value: 'Situação', name: 'status', centered: true },
                                    { value: 'Nº dependentes', name: 'dependentes_count', centered: true },
                                    { value: 'Nº serviços opcionais', name: 'adicionais_count', centered: true }
                                ]}
                                data={associadosComPlano}
                                actions={[
                                    {
                                        name: 'Detalhar',
                                        action: item => {
                                            handleShowDetalhes(item)
                                        },
                                        icon: FaEye,
                                        iconSize: 16
                                    }
                                ]}
                                filterable
                            />
                        ) : null}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!showDetalhes}
                handleClose={() => { setShowDetalhes(null) }}
                title="Detalhes da associação ao plano de saúde"
            >
                {showDetalhes && (
                    <DetalhesContainer>
                        {showDetalhes.status_id === 4 && (
                            <div className="badge">
                                <p dangerouslySetInnerHTML={{ __html: `O plano de saúde de <b>${showDetalhes.nome}</b> e de todos os seus dependentes está <b>CANCELADO</b>.` }} />
                            </div>
                        )}

                        <p>
                            {'Associado(a): '}
                            <b>{showDetalhes.nome}</b>
                        </p>

                        <div className="botao-ativacao-container">
                            <Button className="transparent" onClick={handleAlternarStatus}>
                                {showDetalhes.status_id === 4 ? 'Reativar o plano' : 'Cancelar o plano'}
                                {showDetalhes.status_id === 4 ? <FiCheck size={17} /> : <FiX size={16} />}
                            </Button>
                        </div>

                        <p>
                            {'Serviço: '}
                            <b>{showDetalhes.convenio}</b>
                        </p>

                        <p>
                            {'Inicio vigência: '}
                            <b>{showDetalhes.inicio_vigencia}</b>
                        </p>

                        <p>
                            {'Portabilidade: '}
                            <b>{showDetalhes.portabilidade ? showDetalhes.portabilidade : 'Não'}</b>
                        </p>

                        <p>
                            {'Valor total do plano: '}
                            <b>{showDetalhes.valor_total_plano}</b>
                        </p>

                        <Table
                            caption="Beneficiários"
                            headers={[
                                { name: 'nome', value: 'Dependente' },
                                { name: 'data_nascimento', value: 'Data de nascimento', centered: true },
                                { name: 'admissao', value: 'Admissão', centered: true },
                                { name: 'demissao', value: 'Demissão', centered: true },
                                { name: 'valor', value: 'Valor', centered: true }
                            ]}
                            data={showDetalhes.dependentes}
                            className="table-beneficiarios"
                            footers={[
                                {
                                    text: 'Total',
                                    colspan: 4
                                },
                                {
                                    text: showDetalhes.valor_total,
                                    align: 'center'
                                },
                                {
                                    text: '',
                                    colspan: 2
                                }
                            ]}
                            actions={[
                                {
                                    action: item => item.tipo_beneficiario === 'titular' ? setReativacao(showDetalhes) : handleReativar(item),
                                    name: 'Reativar o plano',
                                    icon: FiCheck,
                                    iconSize: 16,
                                    checkDisabled: item => item.status !== 4 || (!item.titular && showDetalhes.status_id === 4),
                                    confirmation: 'Deseja realmente reativar o plano de saúde deste beneficiário?'
                                },
                                {
                                    action: setCancelamento,
                                    name: 'Desativar o plano',
                                    icon: FiX,
                                    iconSize: 16,
                                    checkDisabled: item => item.tipo_beneficiario === 'titular' ? (item.status !== 6) : (item.status !== 2)
                                }
                            ]}
                        />

                        <Table
                            caption="Serviços opcionais contratados"
                            headers={[
                                { name: 'nome', value: 'Nome' },
                                { name: 'descricao', value: 'Serviço adicional' },
                                { name: 'valor', value: 'Valor', centered: true }
                            ]}
                            data={showDetalhes.adicionais}
                            className="table-adicionais"
                            footers={[
                                {
                                    text: 'Total',
                                    colspan: 2
                                },
                                {
                                    text: showDetalhes.valor_total_adicionais,
                                    align: 'center'
                                }
                            ]}
                            emptyLabel="Nenhum serviço opcional contratado"
                        />

                        <Table
                            caption="Taxas"
                            headers={[
                                { name: 'descricao', value: 'Taxa' },
                                { name: 'valor', value: 'Valor', centered: true }
                            ]}
                            data={showDetalhes.taxas.filter(tx => !tx.ocultar)}
                            className="table-taxas"
                            footers={[
                                {
                                    text: 'Total'
                                },
                                {
                                    text: showDetalhes.total_taxas,
                                    align: 'center'
                                }
                            ]}
                            emptyLabel="Nenhuma taxa a exibir"
                        />

                        <Table 
                            caption="Log de atividades"
                            className="table-logs"
                            headers={[
                                { name: 'acao', value: 'Ação' },
                                { name: 'motivo', value: 'Motivo', centered: true },
                                { name: 'usuario', value: 'Usuário', centered: true },
                                { name: 'data_hora', value: 'Data/hora', centered: true }
                            ]}
                            data={showDetalhes.logs}
                            emptyLabel="Nenhum registro de log encontrado"
                            itemsByPage={5}
                        />
                    </DetalhesContainer>
                )}
            </Modal>

            <Modal
                isOpen={!!cancelamento}
                handleClose={() => { setCancelamento(null) }}
                title="Cancelamento do plano de saúde"
            >
                {cancelamento && (
                    <FormCancelamentoContainer>
                        <p dangerouslySetInnerHTML={{ __html: `Deseja realmente cancelar o plano de saúde de <b>${cancelamento?.nome}</b>.` }} />

                        <Formik
                            onSubmit={handleCancelar}
                            initialValues={{ motivo: null }}
                            validationSchema={Yup.object({
                                motivo: Yup.string().nullable().ensure().required('Selecione o motivo.')
                            })}
                        >
                            {({ setFieldValue }) => (
                                <Form>
                                    <Select 
                                        name="motivo"
                                        label="Motivo de cancelamento"
                                        options={motivosCancelamento.map(m => ({
                                            label: m.descricao,
                                            value: m.id
                                        }))}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)
                                        }}
                                    />

                                    <Button className="transparent" type="submit">
                                        Cancelar o plano
                                        <FiCheck size={17} />
                                    </Button>
                                </Form>
                            )}   
                        </Formik>
                    </FormCancelamentoContainer>
                )}
            </Modal>

            <Modal
                isOpen={!!reativacao}
                handleClose={() => {
                    setReativacao(null)
                }}
                title="Reativação de plano de saúde"
            >
                <ModalReativacaoContainer>
                    {reativacao?.dependentes?.length > 1 && (
                        <>
                            <p>Selecione os dependentes desejados para reativar o plano de saúde.</p>

                            <Table 
                                caption={`Dependentes de ${reativacao?.nome}`}
                                headers={[
                                    { name: 'nome', value: 'Nome' },
                                    { name: 'data_nascimento', value: 'Data de nasc. / idade' },
                                    { name: 'admissao', value: 'Admissão' },
                                    { name: 'demissao', value: 'Demissão' }
                                ]}
                                data={reativacao?.dependentes.slice(1)}
                                checkable
                                onCheckItem={item => {
                                    setDependentesAReativar(old => [
                                        ...old,
                                        item
                                    ])
                                }}
                                onUncheckItem={item => {
                                    setDependentesAReativar(old => old.filter(adc => adc.id !== item.id))
                                }}
                                toggleCheckAll={stt => {
                                    if (stt) {
                                        setDependentesAReativar(reativacao?.dependentes.slice(1))
                                    } else {
                                        setDependentesAReativar([])
                                    }
                                }}
                            />
                        </>
                    )}

                    <Button onClick={() => { handleReativar() }} className="transparent">
                        Reativar plano
                        <FiCheck size={16} />
                    </Button>
                </ModalReativacaoContainer>
            </Modal>
        </>
    )
}
