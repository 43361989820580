import React, {
    useCallback, useContext, useState, useEffect
} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FaUserMd, FaLock, FaUser } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'

import { api } from '../../services/api'
import history from '../../services/history'

import { criptografar } from '../../util/crypto'
import scroll from '../../util/scroll'

import { GlobalContext } from '../../contexts/GlobalContext'

import { Textbox, Button, List } from '../../components/Form'

import { Container, Content, LoginsContainer } from './styles'

import useQuery from '../../hooks/useQuery'

const validation = Yup.object({
    crm: Yup.string().required('CRM obrigatório.'),
    senha: Yup.string().required('Senha obrigatória.')
})

const baseInitialValues = {
    crm: '',
    senha: ''
}

export default function SignIn() {
    scroll.toTop()

    const { setUser, clienteRobot } = useContext(GlobalContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [login, setLogin] = useState({
        crm: '',
        senha: ''
    })
    const [crm, setCrm] = useState('')

    const {
        atendimento, processo, enquete, atendimento_abrir
    } = useQuery()
    const location = useLocation()

    const abertura_atendimento = location?.state?.abertura_atendimento

    const handleSubmit = useCallback(async (values, { setSubmitting }) => {
        try {
            const body = {
                ...values,
                crm: Number(values.crm) || 0
            }

            const session = await api.post('sessao', body)

            if (session.usuario.associado?.status_associacao.id === 6) {
                session.usuario.perfil.grupos = session.usuario.perfil.grupos.filter(g => [2, 24].includes(g.id))
            }

            setUser(session.usuario)

            const ciphed = criptografar(JSON.stringify(session.usuario))

            sessionStorage.setItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN, session.token)
            sessionStorage.setItem(process.env.REACT_APP_SESSION_STORAGE_USER, ciphed)

            setSubmitting(false)

            if (atendimento) {
                history.push(`/atendimento/${atendimento}`)
            } else if (processo) {
                history.push(`/processos/${processo}`)
            } else if (enquete) {
                history.push(`/enquete/${enquete}/votar`)
            } else if (abertura_atendimento) {
                history.push({
                    pathname: '/processos',
                    state: {
                        abertura: true
                    }
                })
            } else if (atendimento_abrir) {
                history.push('/processos?abertura=1')
            } else {
                history.push('/dashboard')
            }
        } catch (e) {
            console.log(e)
            toast.error(e.msg || e)
        }
    }, [])

    useEffect(() => {
        setInitialValues(login)
        setCrm(login.crm)
    }, [login])

    useEffect(() => {
        const storedLogin = JSON.parse(localStorage.getItem('gestaonamao_admin'))

        if (storedLogin) {
            setLogin({
                ...storedLogin,
                hasStoredLogin: true
            })
        }
    }, [])

    return (
        <>
            <Container>
                <div className="space" style={{ width: '100%' }} />

                <Content className="animated fadeInUp faster delay-300ms">
                    <img src={clienteRobot?.logo_square} alt={clienteRobot?.name} />

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <h1>Login</h1>

                                <Textbox
                                    name="crm"
                                    label="Identificação"
                                    onChange={e => {
                                        setCrm(e.target.value)
                                        setLogin({
                                            crm: e.target.value,
                                            senha: login.senha
                                        })
                                    }}
                                    value={crm}
                                    icon={{ source: FaUser, size: 18 }}
                                />

                                <Textbox name="senha" type="password" label="Senha" autocomplete="on" icon={{ source: FaLock, size: 16 }} disableSubmitWithEnter={false} />

                                <Button type="submit" loading={isSubmitting} className="transparent">Entrar</Button>

                                <p>ou</p>

                                <Link to="/pass_recover">Esqueci a senha</Link>

                                {/* <Link to="/matricula_recover">Esqueci meu código (acadêmico)</Link> */}
                            </Form>
                        )}
                    </Formik>
                </Content>

                {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                    <LoginsContainer className="animated fadeInRight faster delay-500ms">
                        <h1>Logins predefinidos</h1>

                        <p>{`Ambiente: ${process.env.REACT_APP_ENVIRONMENT}`}</p>

                        <List
                            options={[
                                { label: 'Admin Básico', value: 'admin basico' },
                                { label: 'Pedro Magalhães (associado básico)', value: 'associado basico' },
                                { label: 'Admin Intermediário 1', value: 'admin intermediario 1' },
                                { label: 'Valentina Rocha (associado intermediário 1)', value: 'associado intermediario 1' },
                                { label: 'Admin Intermediário 2', value: 'admin intermediario 2' },
                                { label: 'Túlio Nogueira (associado intermediário 2)', value: 'associado intermediario 2' }
                            ]}
                            onChange={e => {
                                switch (e.value) {
                                    case 'admin basico':
                                        setLogin({
                                            crm: 999,
                                            senha: '22222222222'
                                        })
                                        break

                                    case 'associado basico':
                                        setLogin({
                                            crm: 10610,
                                            senha: '11111111111'
                                        })
                                        break

                                    case 'admin intermediario 1':
                                        setLogin({
                                            crm: 888,
                                            senha: '22222222222'
                                        })
                                        break

                                    case 'associado intermediario 1':
                                        setLogin({
                                            crm: 12345,
                                            senha: '11111111111'
                                        })
                                        break

                                    case 'admin intermediario 2':
                                        setLogin({
                                            crm: 777,
                                            senha: '22222222222'
                                        })
                                        break

                                    case 'associado intermediario 2':
                                        setLogin({
                                            crm: 54321,
                                            senha: '22222222222'
                                        })
                                        break

                                    default: break
                                }
                            }}
                        />
                    </LoginsContainer>
                )}
            </Container>

            <ReactTooltip
                place="top"
                effect="solid"
                type="dark"
            />
        </>
    )
}
