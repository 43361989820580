import styled from 'styled-components'

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const FormaPagamentoContainer = styled.div`
	display: flex;
	gap: 24px;
	margin-top: 24px;

	> button {
		background-color: #0006;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 200px;
		border: 0;
		cursor: pointer;
		transition: 300ms;
	
		&:hover {
			opacity: 0.8;
		}

		img, svg {
			width: 300px;
		}

		h1 {
			font-size: 13px;
		}
	}

	@media(max-width: 850px) {
		flex-direction: column;
	}

	@media(max-width: 450px) {
		> button {
			img, svg {
				width: 200px;
			}
		}
	}
`

export const QRCodeContainer = styled.div.attrs({
    className: 'animated fadeIn slow'
})`
	padding: 24px;
	background-color: #FFF;
	border-radius: 10px;
	width: 300px;

	img {
		width: 100%;
		height: 100%;
	}

	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	span {
		font-size: 14px;
		color: #424242;
	}

	span.pix-code-copy {
		border: solid 1px #424242;
		padding: 8px;
		text-align: center;
		border-radius: 4px;
		cursor: pointer;
	}

	span.hint {
		font-size: 11px;
		margin-top: 14px;
	}

	+ button {
		margin-top: 20px;
		align-self: flex-end;
	}
`
