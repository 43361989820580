import styled from 'styled-components'

export const Container = styled.div`
	h1 {
		font-size: 16px;
		margin-top: 16px;
	}

	.button-container {
		display: flex;
		justify-content: end;
		width: 100%;
	}

	.progress {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin-bottom: 20px;
	}

	form {
		.button-container {
			display: flex;
			justify-content: end;
			width: 100%;
		}
	}
`
