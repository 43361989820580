import React, { useEffect, useState } from 'react'

import { Container } from './styles'

export default function EnqueteQuestao({ questao, onAnswer, eUltimaQuestao }) {
    const [processando, setProcessando] = useState(false)

    useEffect(() => {
        setProcessando(false)
    }, [questao])

    if (!questao) {
        return null
    }

    return (
        <Container>
            <p>{questao.texto}</p>

            <ul>
                {questao.alternativas.map(alternativa => (
                    <li
                        key={alternativa.id}
                        onClick={!processando ? () => {
                            onAnswer(questao, alternativa, eUltimaQuestao)

                            setProcessando(true)
                        } : null}
                        style={{ opacity: processando ? 0.6 : 1 }}
                    >
                        {alternativa.texto}
                    </li>
                ))}
            </ul>
        </Container>
    )
}
