import React, { useEffect, useState, useContext } from 'react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { FaRegCheckSquare, FaEye } from 'react-icons/fa'

import { FiFileText } from 'react-icons/fi'
import axios from 'axios'
import Card from '../../../../components/Card'
import Modal from '../../../../components/Modal'
import Table from '../../../../components/Table'
import { Button } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'

import { api, authHeaders } from '../../../../services/api'

import { getDateObject } from '../../../../util/date'
import arrayUtils from '../../../../util/array'

import avatarDefault from '../../../../assets/images/avatar.png'

import {
    Container, VotacaoContainer, ChapaContainer, ConfirmacaoVotoContainer, DetalhesEleicaoContainer, VotosAlternativosContainer 
} from './styles'

import { GlobalContext } from '../../../../contexts/GlobalContext'

export default function () {
    const { user } = useContext(GlobalContext)

    const [eleicoes, setEleicoes] = useState(null)
    const [emVotacao, setEmVotacao] = useState(null)
    const [chapaConfirmacao, setChapaConfirmacao] = useState(null)
    const [detalhes, setDetalhes] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [processandoVoto, setProcessandoVoto] = useState(false)
    const [votoAlternativoConfirmacao, setVotoAlternativoConfirmacao] = useState(null)
	
    async function loadEleicoes() {
        setEleicoes(null)

        const response = await api.get('eleicao', {
            params: {
                em_curso: 1,
                votacao: [2, 3, 4, 5, 6, 8, 9, 11, 12, 13, 14, 15, 16, 20, 21, 22, 23].includes(user.perfil.id) ? 1 : null
            },
            ...authHeaders()
        })

        setEleicoes(response.map(el => ({
            ...el,
            id: el.id,
            descricao: el.descricao,
            inicio: format(getDateObject(el.periodo_inicio, -3), 'dd/MM/yyyy HH:mm\'h\''),
            fim: format(getDateObject(el.periodo_fim, -3), 'dd/MM/yyyy HH:mm\'h\''),
            data_hora_meu_voto: el.data_hora_meu_voto || null,
            meu_voto: el.meu_voto
        })))
    }

    async function handleVotar({
        chapa,
        branco
    }) {
        try {
            setProcessandoVoto(true)

            let ip = 'não identificado'

            try {
                const { data } = await axios.get('https://api.ipify.org?format=json')
                
                ip = data.ip
            } catch(e) {
                console.log('Erro ao obter o IP do cliente.', e)
            }

            await api.post(`eleicao/votar/${emVotacao.id}/${user.pessoa.id}`, { 
                ip,
                chapa_id: chapa?.id,
                branco
            }, authHeaders())

            toast.success('Seu voto foi registrado com sucesso! Obrigado!')
        } catch(e) {
            switch(e.status) {
                case 'ja_votou':
                    toast.warn(e.msg)
                    break

                case 'encerrado':
                    toast.warn(e.msg)
                    break

                default: 
                    toast.error(e.msg)
                    break
            }
        } finally {
            setEmVotacao(null)
            setChapaConfirmacao(null)
            setDetalhes(null)
            setVotoAlternativoConfirmacao(null)
            setProcessandoVoto(false)

            loadEleicoes()
        }
    }

    async function handleDetalhes(eleicao) {
        try {
            const eleicaoDados = eleicoes.find(e => e.id === eleicao.id)

            setDetalhes(eleicaoDados)
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if(user) {
            loadEleicoes()
        }
    }, [user])

    useEffect(() => {
        if(emVotacao) {
            const cargosLista = []

            for(const chapa of emVotacao.chapas) {
                for(const candidato of chapa.candidatos) {
                    if(!cargosLista.find(c => c.id === candidato.cargo.id)) {
                        cargosLista.push(candidato.cargo)
                    }
                }
            }

            setCargos(cargosLista)
        } else {
            setCargos(null)
        }
    }, [emVotacao])

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Eleições em curso</h1>

                        {eleicoes ? (
                            <Table 
                                headers={[
                                    { name: 'descricao', value: 'Nome da eleição' },
                                    { name: 'inicio', value: 'Início da votação', centered: true },
                                    { name: 'fim', value: 'Fim da votação', centered: true },
                                    { name: 'data_hora_meu_voto', value: 'Data/hora do voto', centered: true }
                                ]}
                                data={eleicoes || []}
                                actions={[
                                    {
                                        action: setEmVotacao,
                                        name: 'Votar agora',
                                        icon: FaRegCheckSquare,
                                        iconSize: 16,
                                        color: '#4caf50',
                                        checkDisabled: item => !!item.data_hora_meu_voto,
                                        disabledTooltip: () => 'Você já registrou seu voto'
                                    },
                                    {
                                        action: handleDetalhes,
                                        name: 'Detalhes',
                                        icon: FaEye,
                                        iconSize: 16,
                                        checkDisabled: item => !item.data_hora_meu_voto,
                                        disabledTooltip: () => 'Dê seu voto para poder visualizar os detalhes'
                                    }
                                ]}
                                hideOnSmallHeaders={['inicio', 'fim']}
                                hideOffset={500}
                                emptyLabel="Nenhuma eleição em curso no momento."
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!emVotacao}
                handleClose={() => { setEmVotacao(null) }}
                title={emVotacao?.descricao}
            >
                <VotacaoContainer>
                    <span>{`Data início: ${emVotacao?.inicio}`}</span>
                    <span>{`Data fim: ${emVotacao?.fim}`}</span>

                    {emVotacao?.chapas?.map(chapa => (
                        <details key={chapa.id}>
                            <summary>
                                <span>{chapa.descricao}</span>
                                
                                <span>Clique aqui para visualizar os candidatos que compõem a chapa</span>
                            </summary>

                            <ChapaContainer>
                                <img src={chapa.imagem?.link} alt="" />

                                <div>
                                    <h1>{chapa.descricao}</h1>

                                    <p>{chapa.proposta}</p>

                                    {cargos && [...cargos].sort(arrayUtils.sort.comparisonFunction('descricao'))?.map(cargo => {
                                        const cargoPreenchido = chapa.candidatos.filter(cand => cand.cargo.id === cargo.id).length

                                        if(!cargoPreenchido) {
                                            return null
                                        }
                                    
                                        return (
                                            <div className="cargo-container" key={cargo.id}>
                                                <h1>{cargo.descricao}</h1>

                                                <ul className="lista-candidatos">
                                                    {chapa.candidatos.filter(cand => cand.cargo.id === cargo.id).map(cand => (
                                                        <li key={cand.id} className="item-candidato">
                                                            <img src={cand.foto || avatarDefault} alt="Foto do candidato" />

                                                            <span>{cand.pessoa?.nome}</span>
                                                        
                                                            <a 
                                                                href={cand.curriculo} 
                                                                target="_blank" 
                                                                rel="noopener noreferrer" 
                                                            >
                                                                <FiFileText size={18} />
                                                                Ver currículo
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                    })}
                                </div>

                                <Button 
                                    onClick={() => {
                                        setChapaConfirmacao(chapa)
                                    }}
                                    className="yellow"
                                >
                                    {`Votar na chapa ${chapa.descricao.toUpperCase()}`}
                                </Button>
                            </ChapaContainer>
                        </details>
                    )) || null}

                    <VotosAlternativosContainer>
                        <Button
                            className="red"
                            onClick={() => {
                                setVotoAlternativoConfirmacao('nulo')
                            }}
                        >
                            Votar NULO
                        </Button>

                        <Button
                            className="white"
                            onClick={() => {
                                setVotoAlternativoConfirmacao('em branco')
                            }}
                        >
                            Votar em BRANCO
                        </Button>
                    </VotosAlternativosContainer>
                </VotacaoContainer>
            </Modal>

            <Modal
                isOpen={!!votoAlternativoConfirmacao}
                handleClose={() => { setVotoAlternativoConfirmacao(null) }}
                title="Confirmação de voto"
            >
                <ConfirmacaoVotoContainer>
                    <p dangerouslySetInnerHTML={{ __html: `Você está votando <b class="chapa-voto">${votoAlternativoConfirmacao}</b>.` }} />

                    <p>Confirma seu voto?</p>

                    <p className="hint">Obs.: Após a confirmação, seu voto não poderá ser alterado.</p>
					
                    <div className="button-container">
                        <Button 
                            className="red" 
                            onClick={() => { setVotoAlternativoConfirmacao(null) }} 
                            disabled={processandoVoto}
                        >
                            Cancelar
                        </Button>
                        <Button
                            className="green"
                            loading={processandoVoto}
                            onClick={() => { 
                                handleVotar({
                                    chapa: null,
                                    branco: votoAlternativoConfirmacao === 'em branco'
                                }) 
                            }}
                        >
                            Confirmado
                        </Button>
                    </div>
                </ConfirmacaoVotoContainer>
            </Modal>

            <Modal
                isOpen={!!chapaConfirmacao}
                handleClose={() => { setChapaConfirmacao(null) }}
                title="Confirmação de voto"
            >
                <ConfirmacaoVotoContainer>
                    <p dangerouslySetInnerHTML={{ __html: `Você está votando na chapa <b class="chapa-voto">${chapaConfirmacao?.descricao}</b>.` }} />

                    <p>Confirma seu voto?</p>

                    <p className="hint">Obs.: Após a confirmação, seu voto não poderá ser alterado.</p>
					
                    <div className="button-container">
                        <Button 
                            className="red" 
                            onClick={() => { setChapaConfirmacao(null) }} 
                            disabled={processandoVoto}
                        >
                            Cancelar
                        </Button>
                        <Button
                            className="green"
                            loading={processandoVoto}
                            onClick={() => { 
                                handleVotar({
                                    chapa: chapaConfirmacao
                                }) 
                            }}
                        >
                            Confirmado
                        </Button>
                    </div>
                </ConfirmacaoVotoContainer>
            </Modal>

            <Modal
                isOpen={!!detalhes}
                handleClose={() => { setDetalhes(null) }}
                title="Detalhes da eleição"
            >
                <DetalhesEleicaoContainer>
                    <p dangerouslySetInnerHTML={{ __html: `Eleição: <b>${detalhes?.descricao}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Início da votação: ${detalhes?.inicio}` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Término da votação: ${detalhes?.fim}` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Você votou <b>${detalhes?.meu_voto.descricao}</b>` }} className="voto" />
                    <p dangerouslySetInnerHTML={{ __html: `Data e hora do voto: ${detalhes?.data_hora_meu_voto}` }} />
                    <p className="resultado">Aguarde a divulgação do resultado oficial da eleição.</p>
                </DetalhesEleicaoContainer>
            </Modal>
        </>
    )
}
