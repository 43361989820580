import React, { useContext, useEffect } from 'react'
import {
    Tab, Tabs, TabList, TabPanel
} from 'react-tabs'
import { Formik, Form } from 'formik'

import Yup from '../../../../services/yup'

import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext'

import {
    Button, Select, Textbox, Calendar
} from '../../../../components/Form'

import { Container, ButtonContainer } from './styles'

import rangeMonthGif from '../../../../assets/images/gifs/periodo-mes.gif'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

const validation = Yup.object().shape({
    competencia: Yup.string().nullable(),
    crm: Yup.string().nullable(),
    nome: Yup.string().nullable(),
    tipo_pagamento_id: Yup.string().ensure(),
    instituicao: Yup.string().ensure(),
    status: Yup.string().ensure()
})

const initialValues = {
    crm: '',
    competencia: '',
    instituicao: '',
    tipo_pagamento_id: '',
    status: '',
    nome: ''
}

export default function () {
    const { handleSearch, showCadastro, tiposPagamento } = useContext(ContribuicaoContext)
    const { setCodigo, tips } = useContext(TipContext)

    useEffect(() => {
        loadTips(setCodigo, 'form_contribuicao_pesquisa')
    }, [])

    return (
        <Container>
            <Button
                onClick={() => { showCadastro() }}
                className="transparent"
            >
                Cadastrar pagamento
            </Button>

            <Tabs>
                <TabList>
                    <Tab>Consulta</Tab>
                </TabList>

                <TabPanel>
                    <Formik
                        onSubmit={handleSearch}
                        validationSchema={validation}
                        initialValues={initialValues}
                        enableReinitialize
                    >
                        {({ setFieldValue, resetForm, values }) => (
                            <Form>
                                <p style={{ margin: 8, gridArea: 'tip' }}>
                                    {tips?.formulario}
                                </p>

                                <Calendar
                                    label="Competência"
                                    name="competencia"
                                    view="month"
                                    dateFormat="mm/yy"
                                    yearRange={`${new Date().getFullYear() - 100}:${new Date().getFullYear()}`}
                                    selectionMode="range"
                                    tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeMonthGif}" style="width:300px; height:auto" />`}
                                />

                                <Textbox label="Matrícula" name="crm" />

                                <Textbox label="Nome" containerStyle={{ gridArea: 'f3' }} name="nome" />

                                <Select
                                    label="Tipo de pagamento"
                                    name="tipo_pagamento_id"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={tiposPagamento.map(tipo => ({
                                        label: tipo.descricao_resumida,
                                        value: tipo.id
                                    }))}
                                />

                                <Select
                                    label="Status do pagamento"
                                    name="status"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={[
                                        { label: 'Efetuados', value: 1 },
                                        { label: 'Pendentes', value: 0 }
                                    ]}
                                />

                                <ButtonContainer>
                                    <Button
                                        onClick={resetForm}
                                        className="transparent"
                                    >
                                        Limpar
                                    </Button>

                                    <Button
                                        type="submit"
                                        className="white"
                                        disabled={Object.values(values).every(v => !v)}
                                    >
                                        Pesquisar
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </TabPanel>
            </Tabs>
        </Container>
    )
}
