import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { Container, ButtonContainer } from './styles'

import formUtils from '../../../../../util/form'

import { Calendar, Button, Select } from '../../../../../components/Form'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

import { forceDownloadFileFromURL } from '../../../../../util/file'

const nomeRelatorio = 'Relatório Ativação/Cancelamento'

const validation = Yup.object().shape({
    periodo: Yup.string().nullable().required('Informe um periodo para a busca.')
})

export default function (props) {
    const [motivosCancelamento, setMotivosCancelamento] = useState([])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        values = formUtils.extractFormValues(values)

        try {
            const { periodo } = values
            const inicio = periodo ? periodo[0] : null
            const fim = periodo ? periodo[1] : null

            values.inicio = inicio
            values.fim = fim

            const response = await api.post('relatorio/plano_saude_geral', values, authHeaders())

            forceDownloadFileFromURL(response.link, nomeRelatorio)

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    useEffect(() => {
        async function loadMotivosCancelamento() {
            try {
                const response = await api.get('plano_de_saude/motivos_cancelamento', authHeaders())

                setMotivosCancelamento(response)
            } catch (e) {
                toast.error(e.msg)
            }
        }
        loadMotivosCancelamento()
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{
                    periodo: '', 
                    operacao: '', 
                    categoria: '', 
                    motivos: '' 
                }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, resetForm }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="periodo"
                            label="Período para busca"
                            selectionMode="range"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Select
                            name="operacao"
                            label="Operação"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Reativação', value: 'reativacao' },
                                { label: 'Cancelamento', value: 'cancelamento' }
                            ]}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="categoria"
                            label="Tipo beneficiário"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Médico', value: 'medico' },
                                { label: 'Acadêmico', value: 'academico' }
                            ]}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Select
                            name="motivos"
                            label="Motivo de cancelamento"
                            options={motivosCancelamento.map(m => ({
                                label: m.descricao,
                                value: m.id
                            }))}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
