import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: flex-end;
		width: 100%;

		button {
			width: calc(100% - 16px);
		}

		@media(max-width: 800px) {
			grid-template-columns: 1fr 1fr;	
		}

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;

			button {
				margin-top: 16px;
			}
		}
	}

	.table-container {
		width: calc(100% - 16px);
	}
`
