import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FaCheck } from 'react-icons/fa'

import Yup from '../../../services/yup'
import { api } from '../../../services/api'

import ActionFooter from '../../ActionFooter'
import {
    Button, Textbox, Select, Checkbox
} from '../../Form'
import Modal from '../../Modal'

import { Container, TermoContainer, ModalTermoContainer } from './styles'
import { GlobalContext } from '../../../contexts/GlobalContext'

const validation = Yup.object({
    banco: Yup.string().required('Selecione o banco.'),
    agencia: Yup.string().required('Digite o número da agência.'),
    conta: Yup.string().required('Digite o número da conta.'),
    autorizado: Yup.boolean()
})

export default function ({ onSubmit, valorMensalidade }) {
    const { clienteRobot } = useContext(GlobalContext)

    const [bancos, setBancos] = useState([])
    const [showTermoCiencia, setShowTermoCiencia] = useState(false)
    const [initialValues, setInitialValues] = useState({
        banco: '',
        agencia: '',
        conta: '',
        autorizado: false
    })

    useEffect(() => {
        async function loadBancos() {
            const response = await api.get('instituicao?type=banco&desconto=1')

            setBancos(response)
        }

        loadBancos()

        const storedData = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_DADOS_ASSOCIACAO))

        if (storedData?.financeiro) {
            setInitialValues({
                ...storedData.financeiro,
                autorizado: false
            })
        }
    }, [])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                        <Select
                            name="banco"
                            label="Banco"
                            onChange={selected => setFieldValue('banco', selected)}
                            options={bancos.map(banco => ({
                                label: banco.razao_social,
                                value: banco.id
                            }))}
                            containerClass="banco-container"
                        />

                        <Textbox
                            label="Agência"
                            name="agencia"
                            inputMode="numeric"
                        />

                        <Textbox
                            label="Conta"
                            name="conta"
                            inputMode="numeric"
                        />

                        <TermoContainer>
                            <Button onClick={() => setShowTermoCiencia(true)} className="transparent">
                                VER TERMO DE CIÊNCIA
                            </Button>
                        </TermoContainer>

                        <Checkbox
                            name="autorizado"
                            label={`Li e concordo com o Termo de Ciência acima e autorizo o desconto da mensalidade através de débito em minha conta corrente, no dia 10 de cada mês, com valor correspondente a R$ ${valorMensalidade.toFixed(2).replace('.', ',')} em favor do ${clienteRobot?.name}. Estou ciente que este valor será reajustado anualmente no mês de janeiro.`}
                            handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            containerStyle={{ gridArea: 'check' }}
                        />

                        <ActionFooter>
                            <Button type="submit" loading={isSubmitting} className="white" disabled={!values.autorizado}>
                                Concluir
                                <FaCheck />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>

            <Modal
                isOpen={showTermoCiencia}
                handleClose={() => setShowTermoCiencia(false)}
                title="Termo de Ciência do Associado"
            >
                <ModalTermoContainer>
                    <iframe src={`${process.env.REACT_APP_BACKEND_URL}/resources/TERMO DE CIENCIA.pdf`} frameBorder="0" title="Termo de Ciência" />
                </ModalTermoContainer>
            </Modal>
        </Container>
    )
}
