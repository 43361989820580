import styled from 'styled-components'

export const Container = styled.div`
	margin: 8px;
	margin-bottom: 40px !important;
	display: grid;
	grid-template-columns: 3fr 200px;
	grid-template-rows: 70px 70px auto auto auto;
	grid-template-areas:
		'dados botao-contrato'
		'dados botao-renovacao'
		'dados y'
		'manutencoes-efetuadas botao-manutencao'
		'manutencoes-previstas x';
	gap: 0 24px;

	.info {
		grid-area: dados;

		> div {
			line-height: 30px;
		}
	}

	> button {
		margin: 16px 0;
		width: fit-content;
		justify-self: end;

		&.cadastrar-manutencao {
			align-self: start;
			grid-area: botao-manutencao;
			margin-top: 50px;
		}

		&.botao-renovacao {
			grid-area: botao-renovacao
		}
	}

	.manutencoes-efetuadas {
		grid-area: manutencoes-efetuadas;
	}

	.manutencoes-previstas {
		grid-area: manutencoes-previstas;
	}

	@media(max-width: 890px) {
		display: flex;
		flex-direction: column;
		align-items: center;

		> button {
			margin-top: 16px;
			align-self: center;

			&.cadastrar-manutencao {
				margin-top: 16px;
				align-self: center;
			}
		}
	}
`

export const AvaliacaoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 8px;

	button {
		align-self: center;
		width: 200px;
	}
`
