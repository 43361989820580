import React, { useContext, useState, useEffect } from 'react'
import { FaFileAlt, FaLink } from 'react-icons/fa'

import { extname } from '../../../../../util/path'
import { AcervoContext } from '../index'

import { forceDownloadFileFromURL } from '../../../../../util/file'

import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'
import { previewableFormats } from '../../../../../components/Form/File'

import { Container } from './styles'

export default function () {
    const { acervo, handleDelete, handlePrepareEdit } = useContext(AcervoContext)

    const [arquivoVisualizacao, setArquivoVisualizacao] = useState(null)

    useEffect(() => {
        if (arquivoVisualizacao) {
            const extensao = extname(arquivoVisualizacao)

            if (extensao !== '.pdf' && !previewableFormats.includes(extensao)) {
                forceDownloadFileFromURL(arquivoVisualizacao)
            }
        }
    }, [arquivoVisualizacao])

    return (
        <Container>
            {acervo ? (
                <Table
                    headers={[
                        { name: 'titulo', value: 'Título' },
                        { name: 'localizacao', value: 'Localização' },
                        { name: 'data', value: 'Data de publicação', centered: true }
                    ]}
                    data={acervo}
                    actions={[
                        {
                            name: 'Abrir link',
                            icon: FaLink,
                            iconSize: 13,
                            action: item => {
                                forceDownloadFileFromURL(item.link)
                            },
                            checkDisabled: item => !item.link,
                            disableTooltip: true
                        },
                        {
                            name: 'Visualizar',
                            icon: FaFileAlt,
                            iconSize: 13,
                            action: item => {
                                setArquivoVisualizacao(item.arquivo)
                            },
                            checkDisabled: item => !item.arquivo?.link,
                            disableTooltip: true
                        }
                    ]}
                    hideOffset={600}
                    hideOnSmallHeaders={['formato', 'veiculo']}
                    confirmExclusion={{
                        template: 'Deseja realmente excluir o documento?'
                    }}
                    handleDelete={handleDelete}
                    handlePrepareEdit={handlePrepareEdit}
                    filterable
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
