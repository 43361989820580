import React, {
    createContext, useState, useEffect, useContext
} from 'react'
import { endOfMonth, format, startOfMonth } from 'date-fns'

import { api, authHeaders } from '../services/api'

import { GlobalContext } from './GlobalContext'

export const EventoContext = createContext()

const today = new Date()
const inicio = startOfMonth(today)
const fim = endOfMonth(today)

const EventoProvider = ({ children }) => {
    const { user } = useContext(GlobalContext)

    const [items, setItems] = useState(null)
    const [evento, setEvento] = useState(null)
    const [editItem, setEditItem] = useState(null)
    const [showEventoConvite, setShowEventoConvite] = useState(false)
    const [modoCadastro, setModoCadastro] = useState(false)
    const [showEventoPresenca, setShowEventoPresenca] = useState(false)
    const [showEventoCertificado, setShowEventoCertificado] = useState(false)
    const [showEventoAviso, setShowEventoAviso] = useState(false)
    const [showEventoArquivos, setShowEventoArquivos] = useState(false)
    const [periodoBusca, setPeriodoBusca] = useState([inicio, fim])

    async function loadItems() {
        setItems(null)

        let response = await api.get('evento', {
            params: {
                periodo: periodoBusca
            },
            ...authHeaders()
        })

        if ([21, 22].includes(user.perfil.id)) {
            response = response.filter(e => [21, 22].includes(e.usuario_criador.perfil.id))
        }

        const eventos = response
            .map(item => ({
                id: item.id,
                name: item.nome,
                inicio: format(new Date(item.inicio), 'dd/MM/yyyy HH:mm\'h\''),
                fim: format(new Date(item.fim), 'dd/MM/yyyy HH:mm\'h\''),
                inicio_datetime: new Date(item.inicio),
                fim_datetime: new Date(item.fim),
                nome_local: item.nome_local,
                participantes: item.presencas.map(presenca => ({
                    ...presenca,
                    ...presenca.pessoa
                })),
                background: item.publico ? '#ffcdd2' : 'unset'
            }))

        setItems(eventos)
    }

    async function reload() {
        loadItems()

        setModoCadastro(false)

        setEditItem(null)

        setShowEventoAviso(false)
    }

    async function reloadEvento() {
        loadItems()
    }

    useEffect(() => {
        if (items?.length && evento) {
            const eventoAtual = items.find(i => i.id === evento.id)

            setEvento(eventoAtual)
        }
    }, [items])

    useEffect(() => {
        if (user && periodoBusca?.[1]) {
            loadItems()
        }
    }, [user, periodoBusca])

    return (
        <EventoContext.Provider value={{
            evento,
            setEvento,
            editItem,
            setEditItem,
            items,
            showEventoConvite,
            setShowEventoConvite,
            reload,
            reloadEvento,
            modoCadastro,
            setModoCadastro,
            setShowEventoPresenca,
            showEventoPresenca,
            setShowEventoCertificado,
            showEventoCertificado,
            showEventoAviso,
            setShowEventoAviso,
            showEventoArquivos,
            setShowEventoArquivos,
            setPeriodoBusca,
            periodoBusca
        }}
        >
            {children}
        </EventoContext.Provider>
    )
}

export default EventoProvider
