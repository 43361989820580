import styled, { css } from 'styled-components'

const cardStyle = css`
	background-color: #fff;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
	color: #424242;
	padding: 24px 24px 30px;
	border-radius: 2px;

	h1, h2, h3 {
		color: #424242;
	}
`

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 80px;

		h1 {
			margin-bottom: 20px;
		}

		.card {
			.actions-container {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.select {
					max-width: 300px;
					width: 100%;
				}

				.button-container {
					display: flex;
					justify-content: flex-end;

					button {
						width: fit-content;
					}
				}
			}

			.react-tabs__tab-panel {
				min-height: 80vh;
			}
		}

		@media(max-width: 920px) {
			padding: 35px 30px;

			.card {
				.actions-container {
					flex-direction: column;
					align-items: center;

					button {
						max-width: 300px;
						width: 100%;
					}
				}
			}
		}

		@media(max-width: 440px) {
			padding: 20px 0;
		}
	}
`

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;

	.fields-container {
		align-self: center;
		max-width: 300px;
		width: 100%;

		.field {
			max-width: 400px;
		}
	}

	.button-container {
		display: flex;
		grid-column: 1/span 2;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

export const ReceitasContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;

	.table-container {
		grid-column: 1/span 2;
	}
`

export const ReceitaContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 16px 8px 0;
	align-items: flex-start;
	gap: 8px;
	height: fit-content;

	${cardStyle}

	svg {
		font-size: 13px;
	}

	> h1 {
		font-size: 21px;
		font-weight: 500;
		margin-bottom: 10px !important;
		align-self: center;
	}

	h2 {
		font-size: 16px;
		font-weight: 500;
		display: flex;
		align-items: center;
		gap: 6px;
	}

	h3 {
		font-size: 13px;
		font-weight: 400;
		display: flex;
		align-items: center;
		gap: 6px;
	}

	.chart-container {
		width: 100%;
		grid-area: chart;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 12px 0;

		svg {
			color: #ccc;
			font-size: 40px;
			margin-top: 14px;
		}

		.placeholder {
			color: #ccc;
			font-style: italic;
			padding: 14px 0 24px;
		}
	}	

	.table-container {
		margin-top: 8px;
	}

	> details {
		margin: 20px 0 10px;
		border-radius: 6px;
		border: solid 1px #ddd;
		border-bottom-width: 3px;
		height: fit-content;
		width: 100%;

		&:last-child {
			margin-top: 0;
		}

		> div {
			margin: 18px;
			display: flex;
			flex-direction: column;
			grid-gap: 8px 16px;

			> div {
				.table-container {
					margin-top: 0;
				}

				p {
					margin-bottom: 14px;
				}
			}

			> button {
				color: #424242 !important;
				border-color: #424242 !important;
				width: fit-content;
				align-self: end;
			}
		}

		&[open] {
			summary {
				background-color: #FFF;
				color: #424242;

				h1 {
					color: #424242;
				}

				> svg {
					color: #424242;
				}
			}
		}

		summary {
			cursor: pointer;
			padding: 12px 18px;
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 500;
			border-radius: 6px;

			> svg {
				margin-right: 8px;
			}

			&:hover {
				background-color: #FFF;
				color: #424242;

				svg {
					color: #424242;
				}

				h1 {
					color: #424242;
				}
			}
		}
	}
`

export const TourHelper = styled.div`
	display: flex;
	justify-content: flex-end;
	
	> div {
		width: fit-content;
		align-items: center;
		color: #424242;
		transition: 300ms;
		gap: 8px;
		background-color: #fff;
		border-radius: 10px;
		position: fixed;
		bottom: 12px;
		left: 16px;
		display: flex;
		justify-content: center;
		padding: 9px;
		border-radius: 50%;
		box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);

		img {
			height: 45px;
		}

		&:hover {
			opacity: 0.8;
			cursor: pointer;
		}
	}
`

export const ValoresPlanoSaudeContainer = styled.div`
	border: solid 1px #424242;
	width: 100%;
	padding: 14px 20px;
	border-radius: 8px;

	h1 {
		font-size: 16px;
		margin-bottom: 10px !important;
	}

	h3 {
		line-height: 24px;
	}
`

export const MovimentacaoContainer = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 20px 1fr 110px 230px;
		gap: 8px;
		grid-template-areas:
			'origem arrow destino ano valor'
			'saldo_origem y saldo_destino x x'
			'button button button button button';

		> svg {
			cursor: unset;
			align-self: center;
			justify-self: center;
			margin-top: 16px;

			&:hover {
				opacity: unset;
			}
		}

		> span {
			margin: 0 16px;
		}

		.button-container {
			grid-area: button;
			display: flex;
			justify-content: end;
		}
	}
`
