import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { FiCheck } from 'react-icons/fi'
import { toast } from 'react-toastify'

import { Button, Checkbox } from '../../../../../components/Form'
import Table from '../../../../../components/Table'

import { api, authHeaders } from '../../../../../services/api'
import { getAge } from '../../../../../util/date'

import maskUtils from '../../../../../util/masks'

import { Container, ButtonContainer } from './styles'

export default function ({ detalhes, reload }) {
    const [verificado, setVerificado] = useState(false)
    const [cancelandoAdesao, setCancelandoAdesao] = useState(false)
    const [adicionaisPorBeneficiario, setAdicionaisPorBeneficiario] = useState(null)

    function toggleVerificacao() {
        setVerificado(!verificado)
    }

    async function handleCancelar() {
        try {
            setCancelandoAdesao(true)
            
            await api.delete(`convenio_associado/aprovar/${detalhes.associado_id}/${detalhes.convenio_id}`, authHeaders())

            toast.success('Cancelamento aprovado.')

            reload()
        } catch(e) {
            toast.error(e.msg)
        }

        setCancelandoAdesao(false)
    }

    useEffect(() => {
        if(detalhes) {
            setAdicionaisPorBeneficiario([
                ...detalhes?.adicionais.map(adc => ({
                    id: `${adc.associado.pessoa.id}_${adc.adicional.id}`,
                    adicional_id: adc.adicional.id,
                    beneficiario: adc.associado.pessoa.nome,
                    adicional: adc.adicional.descricao,
                    valor: maskUtils.maskApply.currency(adc.adicional.valor),
                    status: adc.status.descricao,
                    beneficiario_id: adc.associado.id,
                    status_id: adc.status.id,
                    titular: true
                })),
                ...detalhes?.dependentes.reduce((result, dep) => [
                    ...result,
                    ...dep.adicionais.map(adc => ({
                        id: `${dep.pessoa.id}_${adc.id}`,
                        adicional_id: adc.id,
                        beneficiario: dep.pessoa.nome,
                        adicional: adc.descricao,
                        valor: maskUtils.maskApply.currency(adc.valor),
                        status: adc.status,
                        beneficiario_id: dep.id,
                        status_id: adc.status_id
                    }))
                ], [])
            ])
        }
    }, [detalhes])

    return (
        <Container>
            <p style={{ gridArea: 'd1' }}>
                Nome do associado:
                <b>{` ${detalhes?.associado}`}</b>
            </p>

            <p style={{ gridArea: 'd2' }}>
                Serviço solicitado:
                <b>{` ${detalhes?.servico}`}</b>
            </p>

            <p style={{ gridArea: 'd3' }}>
                Data de solcitação do cancelamento:
                <b>{` ${detalhes?.data}`}</b>
            </p>

            <Table
                caption="Titular e dependentes incluídos"
                headers={[
                    { name: 'nome', value: 'Beneficiário' },
                    { name: 'parentesco', value: 'Parentensco', centered: true },
                    { name: 'data_nascimento', value: 'Data de nascimento / Idade', centered: true }
                ]}
                data={detalhes ? [
                    {
                        id: detalhes.associado_id,
                        nome: detalhes.associado,
                        parentesco: 'Titular',
                        data_nascimento: `${format(new Date(detalhes.data_nascimento), 'dd/MM/yyyy')} (${getAge(detalhes.data_nascimento)} anos)`
                    },
                    ...detalhes.dependentes.map(dep => ({
                        id: dep.id,
                        nome: dep.pessoa.nome,
                        parentesco: dep.grau_parentesco.descricao,
                        data_nascimento: `${format(new Date(dep.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(dep.pessoa.data_nascimento)} anos)`
                    }))
                ] : []}
                className="table-beneficiarios"
            />

            {adicionaisPorBeneficiario && (
                <Table 
                    caption="Serviços opcionais solicitados"
                    headers={[
                        { name: 'beneficiario', value: 'Beneficiário' },
                        { name: 'adicional', value: 'Serviço solicitado', centered: true },
                        { name: 'valor', value: 'Valor', centered: true },
                        { name: 'status', value: 'Status', centered: true }
                    ]}
                    data={adicionaisPorBeneficiario}
                    className="table-adicionais"
                />
            )}

            <Checkbox
                name="verificacao"
                withoutForm
                label={`Realizei todos os trâmites de cancelamento junto ao plano ${detalhes?.servico} e estou ciente de que o cancelamento não poderá ser desfeito.`}
                onChange={toggleVerificacao}
            />

            <ButtonContainer>
                <Button onClick={handleCancelar} className="white" disabled={!verificado} loading={cancelandoAdesao}>
                    Prosseguir com o cancelamento
                    <FiCheck size={16} />
                </Button>
            </ButtonContainer>
        </Container>
    )
}
