import React, { useContext, useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { getYear } from 'date-fns'
import Tour from 'reactour'
import {
    FaArrowRight, FaEdit, FaQuestionCircle, FaTrash
} from 'react-icons/fa'
import {
    FiFileText, FiDollarSign, FiPieChart, FiInfo
} from 'react-icons/fi'
import {
    Tab, TabList, TabPanel, Tabs
} from 'react-tabs'
import ReactTooltip from 'react-tooltip'

import Card from '../../../../components/Card'
import DonutChart from '../../../../components/Chart/Donut'
import {
    Button, Select, Textbox
} from '../../../../components/Form'
import Modal from '../../../../components/Modal'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'

import Cadastro from './Cadastro'
import Saldos from './Saldos'
import Aplicacoes from './Aplicacoes'

import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'

import { forceDownloadFileFromURL } from '../../../../util/file'
import masks from '../../../../util/masks'
import dateUtils from '../../../../util/date'
import stringUtils from '../../../../util/string'
import formUtils from '../../../../util/form'

import { OrcamentoContext } from '../../../../contexts/OrcamentosContext'

import {
    Container, FormContainer, ReceitaContainer, ReceitasContainer, TourHelper, ValoresPlanoSaudeContainer, MovimentacaoContainer
} from './styles'

import syndi from '../../../../assets/images/syndi.png'
import syndiHelp from '../../../../assets/images/syndi-help.png'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

const primeiroAnoOrcamento = 2023
const anoAtual = new Date().getFullYear()

export default function () {
    const {
        handlePrepareEdit, handleDelete, handleEdit,
        ano, setAno,
        showCadastro, setShowCadastro,
        orcamentoEmEdicao, setOrcamentoEmEdicao,
        dadosAnoAtual, dadosAnoAnterior,
        loading, reload
    } = useContext(OrcamentoContext)
    const { setCodigo } = useContext(TipContext)

    const anoAnterior = Number(ano) - 1

    const [gerandoRelatorio, setGerandoRelatorio] = useState(false)
    const [showTour, setShowTour] = useState(false)
    const [contaMovimentacao, setContaMovimentacao] = useState(null)
    const [gerandoRelatorioRealizado, setGerandoRelatorioRealizado] = useState(false)

    const receita_realizada_sem_plano_saude = dadosAnoAtual.somatoria_receitas_ano - dadosAnoAtual.receita_plano_saude

    const tourSteps = [
        {
            selector: '#startup-tour',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Quer conhecer melhor o módulo de orçamentos? Eu posso lhe ajudar. Vamos?</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(1)}>COMEÇAR</Button>
                </div>
            )
        },
        {
            selector: '#ano',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Comece selecionando o ano e aguarde o carregamento dos dados.</p>
                        <p className="extra">
                            <FiInfo />
                            Pode levar alguns segundos.
                        </p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(2)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '#previsto',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Aqui você verá os valores projetados para o ano selecionado.</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(3)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '#realizado',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Já aqui você terá os valores reais do ano selecionado.</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(4)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '#orcamentos',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Nesta tabela estarão listados todos os orçamentos cadastrados para o ano selecionado, incluindo as contas sintéticas e analíticas.</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(5)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '#btn-definir-orcamento',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Aqui você tem acesso ao cadastro de orçamentos, que serão vinculados ao ano selecionado.</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(6)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '#btn-gerar-relatorio',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Aqui você pode gerar o relatório dos orçamentos do ano selecinado em PDF, incluindo os valores orçados e realizados.</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(7)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '.react-tabs__tab-list > li:nth-child(2)',
            content: ({ goTo }) => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Aqui você pode visualizar as receitas, despesas e saldos atualizados de todas as contas correntes do sindicato.</p>
                    </div>
                    <br />
                    <Button onClick={() => goTo(8)}>Próximo</Button>
                </div>
            )
        },
        {
            selector: '.helper-tour-trigger',
            content: () => (
                <div className="container">
                    <div className="content">
                        <img src={syndi} alt="Syndi" style={{ width: 80 }} />
                        <p>Ótimo! Espero ter ajudado.</p>
                        <p className="extra">
                            <FiInfo />
                            Qualquer dúvida, estarei disponível aqui.
                        </p>
                    </div>
                    <br />
                    <Button
                        onClick={() => {
                            setShowTour(false)

                            document.querySelector('.helper-tour-trigger').classList.remove('fadeInUp')
                            document.querySelector('.helper-tour-trigger').classList.add('heartBeat')
                        }}
                    >
                        Finalizar
                    </Button>
                </div>
            )
        }
    ]

    function getAnos() {
        const anos = []

        for (let i = primeiroAnoOrcamento; i <= getYear(new Date()) + 1; i++) {
            anos.push(i)
        }

        return anos.map(a => ({
            label: a,
            value: a
        }))
    }

    async function handleGerarRelatorio() {
        try {
            setGerandoRelatorio(true)

            const { link } = await api.post('orcamento/relatorio', { ano }, authHeaders())

            forceDownloadFileFromURL(link)
        } catch (e) {
            toast.error(e.msg)
        }

        setGerandoRelatorio(false)
    }

    async function checkTour() {
        try {
            const response = await api.post('tour/orcamento', {}, authHeaders())

            if (!response.visualizado) {
                setShowTour(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function handleMovimentarSaldo(values) {
        try {
            values = formUtils.extractFormValues(values)

            values = {
                ...values,
                valor_movimentacao: masks.unmask.money(values.valor_movimentacao)
            }

            await api.post('orcamento/movimentacao', values, authHeaders())

            toast.success('A movimentação de saldo foi realizada.')

            setContaMovimentacao(null)

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleGerarRelatorioRealizado() {
        try {
            setGerandoRelatorioRealizado(true)

            const { link } = await api.get('orcamento/relatorio_realizado', {
                params: {
                    ano: new Date().getFullYear()
                },
                ...authHeaders()
            })

            forceDownloadFileFromURL(link)
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }

        setGerandoRelatorioRealizado(false)
    }

    useEffect(() => {
        checkTour()

        loadTips(setCodigo)
    }, [])

    return (
        <>
            <Container id="form_orcamento_conta_contabil">
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Orçamento</h1>

                        <Tabs>
                            <TabList>
                                <Tab>Orçamento</Tab>
                                {!loading && <Tab>Movimentações por conta corrente</Tab>}
                                {!loading && <Tab>Aplicações</Tab>}
                            </TabList>

                            <TabPanel>
                                <div className="actions-container">
                                    <Select
                                        label="Ano"
                                        id="ano"
                                        withoutForm
                                        onChange={selected => {
                                            setAno(selected.value)
                                        }}
                                        options={getAnos()}
                                        value={{
                                            label: ano,
                                            value: ano
                                        }}
                                    />

                                    <div className="button-container">
                                        <Button className="transparent" onClick={handleGerarRelatorio} loading={gerandoRelatorio} disabled={loading} id="btn-gerar-relatorio">
                                            Gerar relatório
                                            <FiFileText size={18} />
                                        </Button>

                                        <Button className="transparent" onClick={() => { setShowCadastro(true) }} disabled={loading} id="btn-definir-orcamento">
                                            {`Definir orçamento ${ano}`}
                                            <FiDollarSign size={18} />
                                        </Button>
                                    </div>
                                </div>

                                {dadosAnoAtual?.orcamentos ? (
                                    <ReceitasContainer>
                                        <ReceitaContainer className="animated fadeIn" id="previsto">
                                            <h1>{`Previsto ${ano}`}</h1>

                                            <h3
                                                style={{ color: dadosAnoAnterior.restante_cc < 0 ? 'red' : 'unset' }}
                                            >
                                                {`Saldo restante ${anoAnterior}: ${masks.maskApply.currency(dadosAnoAnterior.restante_cc)}`}
                                            </h3>

                                            <h3>
                                                {`Receita projetada ${ano}: `}
                                                {masks.maskApply.currency(dadosAnoAtual.receita_prevista)}
                                            </h3>

                                            <h2
                                                title={`Saldo restante ${anoAnterior} + receita projetada`}
                                            >
                                                {`Receita projetada ${ano} + saldo de ${anoAnterior}: `}
                                                {masks.maskApply.currency(dadosAnoAnterior.restante_cc + dadosAnoAtual.receita_prevista)}
                                            </h2>

                                            <h3>
                                                Valor orçado:
                                                {' '}
                                                {masks.maskApply.currency(dadosAnoAtual.total_orcado)}
                                                {' (apenas despesas)'}

                                                <FaQuestionCircle
                                                    data-tip="Valor orçado nas contas contábeis"
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h3>

                                            <h3>
                                                {`Saldo: ${masks.maskApply.currency(dadosAnoAtual.saldo_orcamento)}`}

                                                <FaQuestionCircle
                                                    data-tip="Receita anual - valor orçado"
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h3>

                                            <h3>
                                                Despesas recorrentes previstas:
                                                {' '}
                                                {masks.maskApply.currency(dadosAnoAnterior.total_despesas_recorrentes)}

                                                <FaQuestionCircle
                                                    data-tip={`Valor baseado na média de despesas recorrentes em ${anoAnterior}`}
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h3>

                                            <h2>
                                                Percentual consumido:
                                                {' '}
                                                {masks.maskApply.percent(dadosAnoAtual.orcamento_previsto_pecentual_consumo)}

                                                <FaQuestionCircle
                                                    data-tip={`Percentual do valor orçado em relação à receita anual ${ano}`}
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h2>

                                            <ValoresPlanoSaudeContainer>
                                                <h1>Valores sem Plano de Saúde</h1>

                                                <h3>
                                                    Receita anual sem plano de saúde:
                                                    {' '}
                                                    {masks.maskApply.currency(dadosAnoAnterior.restante_cc + dadosAnoAtual.receita_prevista - dadosAnoAnterior.receita_plano_saude)}

                                                    <FaQuestionCircle
                                                        data-tip={`Saldo restante ${anoAnterior} + receita prevista - receitas de plano de saúde em ${anoAnterior}`}
                                                        data-for="orcamento-tooltip"
                                                    />
                                                </h3>

                                                <h3>
                                                    {`Saldo sem plano de saúde: ${masks.maskApply.currency(dadosAnoAnterior.restante_cc + dadosAnoAtual.receita_prevista - dadosAnoAnterior.receita_plano_saude - dadosAnoAtual.total_orcado)}`}

                                                    <FaQuestionCircle
                                                        data-tip={`Receita anual - valor orçado para ${ano} - receita de plano de saúde ${anoAnterior}`}
                                                        data-for="orcamento-tooltip"
                                                    />
                                                </h3>
                                            </ValoresPlanoSaudeContainer>

                                            <div className="chart-container">
                                                {dadosAnoAtual?.receita_prevista > 0 && dadosAnoAtual.total_orcado > 0 ? (
                                                    <DonutChart
                                                        width={350}
                                                        height={175}
                                                        data={{
                                                            'Valor orçado': {
                                                                value: dadosAnoAtual.total_orcado,
                                                                color: '#2196f3'
                                                            },
                                                            'Saldo excedente': {
                                                                value: dadosAnoAtual.saldo_orcamento,
                                                                color: '#ccc'
                                                            }
                                                        }}
                                                        legencColor="#424242"
                                                        enableTooltips={false}
                                                    />
                                                ) : (
                                                    <>
                                                        <FiPieChart />
                                                        <p className="placeholder">A visualização gráfica só fica disponível quando o saldo é positivo.</p>
                                                    </>
                                                )}
                                            </div>

                                            <details>
                                                <summary>
                                                    Projeção mês a mês
                                                </summary>

                                                <div>
                                                    <p>{`A tabela a seguir exibe a divisão mensal da receita projetada para ${ano} com seus respectivos valores orçados e saldos previstos.`}</p>

                                                    <Table
                                                        headers={[
                                                            { name: 'mes', value: 'Mês', centered: true },
                                                            { name: 'receita', value: 'Receita', centered: true },
                                                            { name: 'orcado', value: 'Valor orçado', centered: true },
                                                            { name: 'saldo', value: 'Saldo', centered: true },
                                                            { name: 'consumo', value: '% consumido', centered: true }
                                                        ]}
                                                        data={dateUtils.months.map(m => ({
                                                            id: `${m.value}_${m.label}`,
                                                            mes: stringUtils.capitalize(m.label),
                                                            receita: masks.maskApply.currency(dadosAnoAtual.receita_prevista / dateUtils.months.length),
                                                            orcado: masks.maskApply.currency(dadosAnoAtual.total_orcado / dateUtils.months.length),
                                                            saldo: masks.maskApply.currency(dadosAnoAtual.saldo_orcamento / dateUtils.months.length),
                                                            consumo: masks.maskApply.percent(dadosAnoAtual.orcamento_previsto_pecentual_consumo)
                                                        }))}
                                                        textSize={11}
                                                    />
                                                </div>
                                            </details>
                                        </ReceitaContainer>

                                        <ReceitaContainer className="animated fadeIn" id="realizado">
                                            <h1>{`Realizado ${ano}`}</h1>

                                            <h3 style={{ color: dadosAnoAnterior.restante_cc < 0 ? 'red' : 'unset' }}>
                                                {`Saldo restante ${anoAnterior} (contas correntes): ${masks.maskApply.currency(dadosAnoAnterior.restante_cc)}`}
                                            </h3>

                                            <h3 style={{ color: dadosAnoAnterior.restante_aplicacoes < 0 ? 'red' : 'unset' }}>
                                                {`Saldo restante ${anoAnterior} (aplicações): ${masks.maskApply.currency(dadosAnoAnterior.restante_aplicacoes)}`}
                                            </h3>

                                            <h3 style={{ color: dadosAnoAnterior.restante_total < 0 ? 'red' : 'unset' }}>
                                                {`Saldo total restante ${anoAnterior}: ${masks.maskApply.currency(dadosAnoAnterior.restante_total)}`}

                                                <FaQuestionCircle
                                                    data-tip={`Saldo restante contas corrente ${anoAnterior} + saldo restante aplicações ${anoAnterior}`}
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h3>

                                            <h2>
                                                {`Receita ${ano}:`}
                                                {' '}
                                                {masks.maskApply.currency(Number(receita_realizada_sem_plano_saude))}

                                                <FaQuestionCircle
                                                    data-tip={`Receitas lançadas em ${ano}, sem plano de saúde.`}
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h2>

                                            <h2>
                                                {`Despesa ${ano}:`}
                                                {' '}
                                                {masks.maskApply.currency(dadosAnoAtual.somatoria_despesas_ano)}

                                                <FaQuestionCircle
                                                    data-tip={`Somatória de despesas ${ano}, sem plano de saúde.`}
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h2>

                                            <h2
                                                style={{ color: dadosAnoAtual.saldo < 0 ? 'red' : 'unset' }}
                                            >
                                                {`Saldo ${ano}: ${masks.maskApply.currency(Number(receita_realizada_sem_plano_saude) - (dadosAnoAtual.somatoria_despesas_ano))}`}

                                                <FaQuestionCircle
                                                    data-tip={`Receitas ${ano} - Despesas ${ano}`}
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h2>

                                            <h3>
                                                {`Investimentos ${ano}: ${masks.maskApply.currency(Number(dadosAnoAtual.investimentos))}`}

                                                <FaQuestionCircle
                                                    data-tip={`Investimentos ${ano}`}
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h3>

                                            <h2
                                                style={{ color: dadosAnoAtual.percentual_consumo > 100 ? 'red' : 'unset' }}
                                            >
                                                Percentual consumido:
                                                {' '}
                                                {masks.maskApply.percent(dadosAnoAtual.percentual_consumo >= 0 ? dadosAnoAtual.percentual_consumo : 0)}

                                                <FaQuestionCircle
                                                    data-tip={`Percentual de despesas em relação às receitas em ${ano}`}
                                                    data-for="orcamento-tooltip"
                                                />
                                            </h2>

                                            <ValoresPlanoSaudeContainer>
                                                <h1>Valores de Plano de Saúde</h1>

                                                <h3>
                                                    {`Receitas ${ano} de plano de saúde:`}
                                                    {' '}
                                                    {masks.maskApply.currency(dadosAnoAtual.receita_plano_saude)}

                                                    <FaQuestionCircle
                                                        data-tip={`Receitas de plano de saúde em ${ano}`}
                                                        data-for="orcamento-tooltip"
                                                    />
                                                </h3>

                                                <h3>
                                                    {`Despesas ${ano} de plano de saúde:`}
                                                    {' '}
                                                    {masks.maskApply.currency(dadosAnoAtual.despesa_plano_saude)}

                                                    <FaQuestionCircle
                                                        data-tip={`Despesas de plano de saúde em ${ano}`}
                                                        data-for="orcamento-tooltip"
                                                    />
                                                </h3>

                                                <h3
                                                    style={{ color: (dadosAnoAtual.receita_plano_saude - dadosAnoAtual.despesa_plano_saude) < 0 ? 'red' : 'unset' }}
                                                >
                                                    {`Saldo plano de saúde: ${masks.maskApply.currency(dadosAnoAtual.receita_plano_saude - dadosAnoAtual.despesa_plano_saude)}`}

                                                    <FaQuestionCircle
                                                        data-tip={`Receita de plano em ${ano} - despesas de plano de saúde em ${ano}`}
                                                        data-for="orcamento-tooltip"
                                                    />
                                                </h3>
                                            </ValoresPlanoSaudeContainer>

                                            <div className="chart-container">
                                                {dadosAnoAtual.somatoria_despesas_ano > 0 && dadosAnoAtual.saldo > 0 ? (
                                                    <DonutChart
                                                        width={350}
                                                        height={175}
                                                        data={dadosAnoAtual.percentual_consumo > 100
                                                            ? {
                                                                'Despesa total': {
                                                                    value: dadosAnoAtual.somatoria_despesas_ano,
                                                                    color: '#2196f3'
                                                                }
                                                            }
                                                            : {
                                                                'Despesa total': {
                                                                    value: dadosAnoAtual.somatoria_despesas_ano,
                                                                    color: '#2196f3'
                                                                },
                                                                'Saldo excedente': {
                                                                    value: Math.abs(dadosAnoAtual.saldo),
                                                                    color: '#ccc'
                                                                }
                                                            }}
                                                        legencColor="#424242"
                                                        enableTooltips={false}
                                                    />
                                                ) : (
                                                    <>
                                                        <FiPieChart />
                                                        <p className="placeholder">A visualização gráfica só fica disponível quando o saldo é positivo.</p>
                                                    </>
                                                )}
                                            </div>

                                            {dadosAnoAnterior?.lancamentos_despesa_por_mes_por_conta && (
                                                <details>
                                                    <summary>
                                                        Realizado mês a mês sem Plano de Saúde
                                                    </summary>

                                                    <div>
                                                        <p>A tabela a seguir exibe as receitas e despesas realizadas por mês, assim como seus respectivos saldos e percentuais consumidos.</p>

                                                        <Button className="transparent" onClick={handleGerarRelatorioRealizado} loading={gerandoRelatorioRealizado}>
                                                            Gerar relatório
                                                        </Button>

                                                        <Table
                                                            headers={[
                                                                { name: 'mes', value: 'Mês', centered: true },
                                                                { name: 'receita_masked', value: 'Receita', centered: true },
                                                                { name: 'despesa_masked', value: 'Despesa', centered: true },
                                                                { name: 'saldo_masked', value: 'Saldo', centered: true },
                                                                { name: 'consumo_masked', value: '% consumido', centered: true }
                                                            ]}
                                                            data={dadosAnoAtual.valores_por_mes_por_conta.map(valor => ({
                                                                id: valor.mes,
                                                                ...valor
                                                            }))}
                                                            textSize={11}
                                                            legends={[
                                                                {
                                                                    color: '#fff59d',
                                                                    text: 'Parcelas a vencer'
                                                                },
                                                                {
                                                                    color: '#facacf',
                                                                    text: 'Saldo negativo'
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </details>
                                            )}
                                        </ReceitaContainer>

                                        <Table
                                            tableId="orcamentos"
                                            headers={[
                                                { name: 'conta_contabil', value: 'Conta contábil', style: { width: 150 } },
                                                { name: 'descricao', value: 'Descrição', enableIndent: true },
                                                { name: 'orcado', value: 'Valor orçado', centered: true },
                                                { name: 'executado', value: 'Valor executado', centered: true },
                                                { name: 'percentual_executado', value: '% executado', centered: true },
                                                {
                                                    name: 'ano', value: 'Ano', centered: true, style: { width: 74 }
                                                }
                                            ]}
                                            data={dadosAnoAtual.orcamentos || []}
                                            hideHeadersOffset={1010}
                                            hideOnSmallHeaders={['conta_contabil', 'percentual_executado']}
                                            hideOffset={685}
                                            legends={[
                                                {
                                                    color: '#fff9c4',
                                                    text: 'Nível 1 de alerta atingido'
                                                },
                                                {
                                                    color: '#fff59d',
                                                    text: 'Nível 2 de alerta atingido'
                                                },
                                                {
                                                    color: '#fff176',
                                                    text: 'Nível 3 de alerta atingido'
                                                },
                                                {
                                                    color: '#ffcdd2',
                                                    text: 'Orçamentos esgotados'
                                                }
                                            ]}
                                            filterable
                                            actions={[
                                                {
                                                    action: setContaMovimentacao,
                                                    name: 'Movimentar saldo',
                                                    icon: FaArrowRight,
                                                    iconSize: 15,
                                                    checkDisabled: item => !item.analitica || item.orcado_decimal === 0,
                                                    disabledTooltip: 'Esta conta não tem saldo para movimentação.'
                                                },
                                                {
                                                    action: handlePrepareEdit,
                                                    name: 'Editar',
                                                    checkDisabled: item => !item.analitica,
                                                    icon: FaEdit,
                                                    iconSize: 16,
                                                    disabledTooltip: 'Esta conta não é analítica'
                                                },
                                                {
                                                    action: item => { handleDelete(item.orcamento_id) },
                                                    name: 'Excluir',
                                                    checkDisabled: item => !item.analitica,
                                                    icon: FaTrash,
                                                    iconSize: 13,
                                                    confirmation: 'Deseja realmente excluir este orçamento?',
                                                    disabledTooltip: 'Esta conta não é analítica'
                                                }
                                            ]}
                                            textSize={12}
                                        />

                                        <ReactTooltip
                                            id="orcamento-tooltip"
                                            place="top"
                                            effect="solid"
                                            type="dark"
                                        />
                                    </ReceitasContainer>
                                ) : (
                                    <Spinner label="Calculando saldos..." />
                                )}
                            </TabPanel>

                            {!loading && (
                                <>
                                    <TabPanel>
                                        <Saldos />
                                    </TabPanel>
                                    <TabPanel>
                                        <Aplicacoes />
                                    </TabPanel>
                                </>
                            )}
                        </Tabs>
                    </Card>
                </main>
            </Container>

            <Tour
                steps={tourSteps}
                isOpen={showTour}
                onRequestClose={() => {
                    setShowTour(false)
                }}
                rounded={10}
                scrollDuration={200}
                startAt={0}
                showNumber={false}
            />

            <TourHelper>
                <div className="helper-tour-trigger animated fadeInUp" role="button" tabIndex={0} onClick={() => { setShowTour(true) }}>
                    <img src={syndiHelp} alt="" />
                </div>
            </TourHelper>

            <Modal
                isOpen={showCadastro}
                handleClose={() => { setShowCadastro(false) }}
                title={`Cadastro do orçamento ${ano}`}
            >
                <Cadastro />
            </Modal>

            <Modal
                isOpen={!!orcamentoEmEdicao}
                handleClose={() => { setOrcamentoEmEdicao(false) }}
                title={`Editando orçamento da conta ${orcamentoEmEdicao?.descricao}`}
            >
                <div>
                    <Formik
                        onSubmit={handleEdit}
                        initialValues={{
                            ano: { label: orcamentoEmEdicao?.ano, value: orcamentoEmEdicao?.ano },
                            valor: orcamentoEmEdicao?.orcado
                        }}
                        validationSchema={Yup.object({
                            ano: Yup.string().nullable().required('Selecione o ano do orçamento.'),
                            valor: Yup.string().money().required('Preencha o valor.')
                        })}
                    >
                        {({ setFieldValue, isSubmitting }) => (
                            <Form>
                                <FormContainer>
                                    <div className="fields-container">
                                        <Select
                                            name="ano"
                                            label="Ano"
                                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                            options={getAnos()}
                                        />

                                        <Textbox
                                            name="valor"
                                            label="Valor"
                                            mask={masks.money}
                                        />
                                    </div>

                                    <div className="button-container">
                                        <Button className="transparent" type="submit" loading={isSubmitting}>
                                            Atualizar orçamento
                                        </Button>
                                    </div>
                                </FormContainer>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>

            <Modal
                isOpen={!!contaMovimentacao}
                handleClose={() => { setContaMovimentacao(null) }}
                title="Movimentação de saldo orçamentário"
            >
                <MovimentacaoContainer>
                    <Formik
                        onSubmit={handleMovimentarSaldo}
                        initialValues={{
                            conta_origem: {
                                label: contaMovimentacao?.descricao,
                                value: contaMovimentacao?.conta_id
                            },
                            conta_destino: null,
                            ano: { label: contaMovimentacao?.ano, value: contaMovimentacao?.ano },
                            valor_movimentacao: ''
                        }}
                    >
                        {({ setFieldValue, values }) => {
                            const orcamentoContaDestino = values.conta_destino
                                ? dadosAnoAtual.orcamentos?.find(o => o.conta_id === values.conta_destino?.value)?.orcado_decimal || 0
                                : undefined
                            const valorDigitado = values.valor_movimentacao || 0

                            return (
                                <Form>
                                    <Select
                                        name="conta_origem"
                                        label="Conta origem"
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                        options={[
                                            {
                                                label: contaMovimentacao?.descricao,
                                                value: contaMovimentacao?.conta_id
                                            }
                                        ]}
                                    />

                                    <FaArrowRight size={16} />

                                    <Select
                                        name="conta_destino"
                                        label="Conta destino"
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                        options={dadosAnoAtual?.contas_contabeis_analiticas?.map(conta => ({
                                            label: conta.descricao,
                                            value: conta.id
                                        }))}
                                    />

                                    <Select
                                        name="ano"
                                        label="Ano"
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                        options={[
                                            { label: anoAtual - 1, value: anoAtual - 1 },
                                            { label: anoAtual, value: anoAtual },
                                            { label: anoAtual + 1, value: anoAtual + 1 }
                                        ]}
                                    />

                                    <Textbox
                                        name="valor_movimentacao"
                                        label="Valor"
                                        mask={masks.money}
                                        onChange={e => {
                                            const { value } = e.target

                                            const valueDecimal = masks.unmask.money(value)

                                            if (valueDecimal && Number(valueDecimal) > contaMovimentacao?.orcado_decimal) {
                                                toast.warn('O saldo digitado é maior do que o disponível.')
                                            }

                                            setFieldValue('valor_movimentacao', value)
                                        }}
                                    />

                                    <span>{`Saldo atual: ${contaMovimentacao?.orcado}`}</span>

                                    <span style={{ gridArea: 'saldo_destino' }}>
                                        {orcamentoContaDestino !== undefined ? `Saldo após movimentação: ${masks.maskApply.currency(orcamentoContaDestino + Number(masks.unmask.money(valorDigitado)))}` : null}
                                    </span>

                                    <div className="button-container">
                                        <Button type="submit" className="transparent">
                                            Movimentar
                                            <FaArrowRight size={16} />
                                        </Button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </MovimentacaoContainer>
            </Modal>
        </>
    )
}
