import React, { useContext, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FiCheck } from 'react-icons/fi'

import { Container } from './styles'
import { Button, Textbox } from '../../../../../../components/Form'
import { ProcessoGerenciamentoContext } from '../../ProcessoGerenciamentoContext'
import { TipContext } from '../../../../../../contexts/TipContext'
import { loadTips } from '../../../../../../util/tip'

const initialValues = {
    descricao: ''
}

export default function () {
    const { handleCadastrarSetor } = useContext(ProcessoGerenciamentoContext)
    const { setCodigo, tips } = useContext(TipContext)

    useEffect(() => {
        loadTips(setCodigo, 'form_processo_setores_cadastro')
    }, [])

    return (
        <Container>
            <p className="tip">{tips?.formulario}</p>

            <Formik
                onSubmit={handleCadastrarSetor}
                initialValues={initialValues}
            >
                {() => (
                    <Form>
                        <Textbox
                            name="descricao"
                            label="Grupo"
                        />

                        <Button type="submit" className="transparent">
                            Cadastrar
                            <FiCheck size={16} />
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
