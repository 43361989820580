import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FaUser } from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { api } from '../../../services/api'

import scroll from '../../../util/scroll'

import { Textbox, Button } from '../../../components/Form'

import { Container, Content } from './styles'

import logo from '../../../assets/images/logo-mini.png'

const validation = Yup.object({
    crm: Yup.string().required('Especifique o CRM cadastrado no sistema.')
})

export default function () {
    scroll.toTop()

    const history = useHistory()

    async function handleSubmit({ crm }) {
        try {
            const response = await api.post('pass_recover', { crm })

            setTimeout(() => {
                history.push('/')

                toast.info(response.msg)
            }, 1000)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <Content className="animated fadeInUp faster delay-300ms">
                <img src={logo} alt="SINDMEPA" />

                <Formik
                    initialValues={{ crm: '' }}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <h1>Recuperação de senha</h1>

                            <Textbox name="crm" label="Seu CRM ou matrícula de acadêmico" icon={{ source: FaUser }} />

                            <Button type="submit" loading={isSubmitting} className="transparent">Recuperar senha</Button>

                            <p>ou</p>

                            <Link to="/">Voltar para o login</Link>
                        </Form>
                    )}
                </Formik>

            </Content>
        </Container>
    )
}
