import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		.button-container {
			grid-column: 1/span 3;
			display: flex;
			justify-content: flex-end;
		}
	
		@media(max-width: 900px) {
			grid-template-columns: 1fr 1fr;

			.button-container {
				grid-column: 1/span 2;
			}
		}

		@media(max-width: 900px) {
			display: flex;
			flex-direction: column;
		}
	}
`
