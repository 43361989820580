import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			display: grid;
			grid-template-columns: 332px 280px 1fr;
			grid-gap: 0 24px;
			grid-template-areas: 
				'title title title'
				'tip tip tip'
				'nome grupo attrs'
				'editor editor editor'
				'button button button'
				'tabela tabela tabela';

			> h1 {
				margin: 0 8px 20px;
				grid-area: title;
			}

			> span {
				grid-area: attrs;
				align-self: center;
				line-height: 24px;
				width: calc(100% - 16px);
				margin-top: 10px;
			}

			.textbox {
				grid-area: nome;
				width: 100%;
				max-width: 300px;
				margin-bottom: 20px;
			}

			.select {
				grid-area: grupo;
				width: 200px;
			}

			.editor-container {
				grid-area: editor;

				.p-editor-content > .ql-editor {
					box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
				}
			}

			.table-container {
				margin: 24px 8px 0;
				width: calc(100% - 16px);
				grid-area: tabela;
			}

			@media(max-width: 1050px) {
				grid-template-columns: 1fr 1fr;
				grid-template-areas: 
					'title title'
					'tip tip'
					'nome grupo'
					'attrs attrs'
					'editor editor'
					'button button';

					.select {
						width: calc(100% - 16px);
					}

					.textbox {
						max-width: unset;
						width: calc(100% - 16px);	
					}
			}

			@media(max-width: 700px) {
				display: flex;
				flex-direction: column;

				> span {
					margin-bottom: 18px;
				}
			}
		}

		@media(max-width: 600px) {
			padding: 35px 20px;

			.card .textbox {
				width: calc(100% - 16px);
			}
		}

		@media(max-width: 400px) {
			padding: 35px 0;
		}
	}
`

export const ButtonContainer = styled.div`
	grid-area: button;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin: 10px 4px 0;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`
