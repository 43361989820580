import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}
	}
`

export const SelectPerfilContainer = styled.div`
	min-height: 70vh;
	
	.select {
		max-width: 350px;
	}
`
