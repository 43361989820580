import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import BoxLink from '../../../components/BoxLink'
import Spinner from '../../../components/Spinner'

import { api } from '../../../services/api'

import { GlobalContext } from '../../../contexts/GlobalContext'

import scroll from '../../../util/scroll'

import { Container } from './styles'

export default function Dashboard() {
    scroll.toTop()

    const { grupoId } = useParams()

    const { user } = useContext(GlobalContext)

    const [permissoes, setPermissoes] = useState(null)
    const [associado, setAssociado] = useState(null)

    async function loadPermissoes() {
        setPermissoes(user?.perfil?.grupos?.find(grupo => grupo.id === Number(grupoId))?.permissoes || [])
    }

    async function loadAssociado() {
        if (user.associado) {
            const response = await api.get(`associado/${user.associado.id}`)

            setAssociado(response)
        }
    }

    useEffect(() => {
        if (user) {
            loadPermissoes()

            loadAssociado()
        }
    }, [grupoId, user])

    return (
        <Container>
            {permissoes?.length && permissoes ? permissoes.map((permissao, index) => {
                const contribuicoesVisiveis = (permissao.id === 11 && [2, 3, 4].includes(associado?.status_associacao.id)) || permissao.id !== 11

                if (!contribuicoesVisiveis) {
                    return null
                }

                return (
                    <BoxLink
                        key={permissao.id}
                        descricao={permissao.descricao}
                        subdescricao={permissao.subdescricao}
                        to={permissao.link}
                        icone={permissao.icone}
                        className={`delay-${100 + 100 * index}ms`}
                        enabled={permissao.status}
                    />
                )
            }) : (
                <Spinner
                    type="Grid"
                    size={60}
                    color="#676767"
                    label=""
                    containerClass="animated fadeIn"
                />
            )}
        </Container>
    )
}
