import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas:
		'details select button'
		'details ou button'
		'details carga button'
		'x x checkbox'
		'table table table';

	.table-container,
	.spinner-container {
		grid-area: table;
		margin-top: 0;
	}

	p {
		text-align: center;
	}

	.checkbox {
		grid-area: checkbox;
		justify-self: end;
	}

	@media(max-width: 900px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'details button'
			'details select'
			'details ou'
			'details carga'
			'details checkbox'
			'table table';

		
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`

export const EventDetails = styled.div`
	grid-area: details;

	h1 {
		font-size: 17px;
	}

	h2 {
		font-size: 14px;
		margin: 0 8px;
		line-height: 24px;
	}
`

export const ButtonContainer = styled.div`
	grid-area: button;
	width: 100%;
	display: flex;
	justify-content: flex-end;

	@media(max-width: 600px) {
		align-self: end;
	}

	@media(min-width: 900px) {
		justify-self: end;
	}

	button {
		width: fit-content;
		margin-top: 24px;
	}
`
