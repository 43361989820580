import React from 'react'

import FormPagamentoContaCorrente from '../ContaCorrente2'
import FormPagamentoContraCheque from '../ContraCheque2'
import FormPagamentoCartaoCredito from '../CartaoCredito2'
import FormPagamentoBoleto from '../Boleto2'
import FormPagamentoBoletoResidente from '../BoletoResidente2'

import { Container } from './styles'

export default function ({
    tipoPagamento, defaultValues, pessoaId, callback
}) {
    return (
        <Container>
            {tipoPagamento?.value === 1 && (
                <FormPagamentoContaCorrente defaultValues={defaultValues} callback={callback} pessoaId={pessoaId} />
            )}

            {tipoPagamento?.value === 2 && (
                <FormPagamentoContraCheque defaultValues={defaultValues} callback={callback} pessoaId={pessoaId} />
            )}

            {tipoPagamento?.value === 3 && (
                <FormPagamentoCartaoCredito defaultValues={defaultValues} callback={callback} pessoaId={pessoaId} />
            )}

            {tipoPagamento?.value === 4 && (
                <FormPagamentoBoleto callback={callback} pessoaId={pessoaId} />
            )}

            {tipoPagamento?.value === 5 && (
                <FormPagamentoBoletoResidente callback={callback} pessoaId={pessoaId} />
            )}
        </Container>
    )
}
