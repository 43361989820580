import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			> h1 {
				margin: 0 8px;
			}

			> p {
				margin-top: 10px;
				margin-bottom: 18px;
				margin-left: 9px;
			}
		}

		@media(max-width: 590px) {
			padding: 20px;
		}

		@media(max-width: 550px) {
			padding: 20px 0;

			table {
				tr *:nth-child(5) {
					display: none;
				}
			}
		}
	}
`

export const BotaoAberturaContainer = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 12px;
`
