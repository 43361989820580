import React, {
    useState, useEffect, useCallback
} from 'react'
import { toast } from 'react-toastify'
import { FiPlus } from 'react-icons/fi'

import { api, authHeaders } from '../../../../services/api'

import scrollUtils from '../../../../util/scroll'

import useQuery from '../../../../hooks/useQuery'

import Card from '../../../../components/Card'
import Modal from '../../../../components/Modal'
import { Button } from '../../../../components/Form'

import Listagem from './Listagem'
import Cadastro from './Cadastro'

import { Container, ButtonContainer } from './styles'

export default function () {
    const { add } = useQuery()

    const [fornecedores, setFornecedores] = useState([])
    const [editId, setEditId] = useState(null)
    const [showForm, setShowForm] = useState(add || false)

    async function loadFornecedores() {
        const response = await api.get('fornecedor', authHeaders())

        setFornecedores(response)
    }

    useEffect(() => {
        loadFornecedores()
    }, [])

    const handleDelete = useCallback(async id => {
        try {
            await api.delete(`fornecedor/${id}`, authHeaders())

            loadFornecedores()

            toast.success('Fornecedor excluído!')

            return true
        } catch (e) {
            toast.error(e.msg)
            return false
        }
    }, [])

    const handlePrepareEdit = useCallback(async id => {
        setEditId(id)

        setShowForm(true)

        scrollUtils.toElement('.card')
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Parceiros</h1>

                    <ButtonContainer>
                        <Button
                            className="transparent"
                            onClick={() => {
                                setEditId(null)

                                setShowForm(true)
                            }}
                        >
                            Cadastrar parceiros
                            <FiPlus size={18} />
                        </Button>
                    </ButtonContainer>

                    <Modal
                        isOpen={showForm}
                        handleClose={() => { setShowForm(false) }}
                        title={`${!editId ? 'Cadastro' : 'Edição'} de parceiro`}
                    >
                        <Cadastro
                            editId={editId}
                            reload={() => {
                                loadFornecedores()

                                setEditId(null)

                                setShowForm(false)
                            }}
                        />
                    </Modal>

                    <Listagem
                        fornecedores={fornecedores}
                        handlePrepareEdit={handlePrepareEdit}
                        handleDelete={handleDelete}
                    />
                </Card>
            </main>
        </Container>
    )
}
