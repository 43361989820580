import React, { useContext, useState } from 'react'

import { VisaoContext } from '../../../../contexts/VisaoContext'

import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import { Select } from '../../../../components/Form'

import { api, authHeaders } from '../../../../services/api'

import { Container } from './styles'

export default function () {
    const { visoes } = useContext(VisaoContext)

    const [pessoas, setPessoas] = useState([])

    async function handleChange(selected) {
        if(selected) {
            setPessoas(null)

            const response = await api.get(`visao/${selected.value}`, authHeaders())
            
            setPessoas(response.pessoas)
        }
    }

    if(!visoes) {
        return <Spinner label="Aguarde. Isto pode demorar alguns segundos." />
    }

    return (
        <Container>
            <Select 
                name="visao"
                label="Grupo"
                withoutForm
                options={visoes?.map(visao => ({
                    label: visao.descricao,
                    value: visao.id
                }))}
                onChange={handleChange}
            />

            {pessoas ? (
                <Table 
                    headers={[
                        { name: 'nome', value: 'Nome' },
                        { name: 'email', value: 'E-mail', centered: true },
                        { name: 'celular', value: 'Celular', centered: true }
                    ]}
                    data={pessoas}
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
