import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 70px 70px 70px auto 60px;

		.file {
			grid-column: 2;
			grid-row: 1/span 4;
			align-self: flex-start;
		}

		button {
			grid-column: 2;
			width: fit-content;
			justify-self: flex-end;
		}
	}
`
