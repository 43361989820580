import React, { useEffect, useState } from 'react'
import { format, isAfter } from 'date-fns'
import { FiArrowLeft, FiArrowRight, FiEye } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Container, DetalhesContainer } from './styles'

import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import { api, authHeaders } from '../../../../services/api'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import { Button } from '../../../../components/Form'

const enqueteStatus = {
    em_curso: 'Em curso',
    nao_iniciada: 'Não iniciada',
    finalizada: 'Finalizada'
}

export default function () {
    const [enquetes, setEnquetes] = useState(null)
    const [enqueteDetails, setEnqueteDetails] = useState(null)

    const history = useHistory()

    async function loadEnquetes() {
        const response = await api.get('enquete', {
            ...authHeaders(),
            params: {
                scope: 'participacao'
            }
        })

        const listaEnquetes = []

        for (const enquete of response) {
            const visoes = await api.get('visao', authHeaders())

            listaEnquetes.push({
                id: enquete.id,
                ...enquete,
                data_criacao: format(new Date(enquete.createdAt), 'dd/MM/yyyy HH:mm\'h\''),
                inicio: format(new Date(enquete.inicio), 'dd/MM/yyyy HH:mm\'h\''),
                fim: format(new Date(enquete.fim), 'dd/MM/yyyy HH:mm\'h\''),
                visoes: visoes.map(v => `${v.descricao} (${v.quantidade} pessoas)`).join('<br/>'),
                visoes_linha: visoes.map(v => v.descricao).join(', '),
                quantidade_questoes: enquete.questoes.length,
                status: enqueteStatus[enquete.status],
                participacao_finalizada: enquete.participacao_finalizada,
                nao_iniciada: isAfter(new Date(enquete.inicio), new Date()),
                finalizada: isAfter(new Date(), new Date(enquete.fim)),
                status_conclusao: enquete.participacao_finalizada ? 'Finalizada' : 'Pendente',
                
                quantidade_participantes: 'carregando...' // visoes.reduce((total, v) => total + v.quantidade, 0)
            })
        }

        setEnquetes(listaEnquetes)
    }

    async function loadNotificacoes(enquete) {
        try {
            const response = await api.get(`enquete/${enquete.id}/notificacoes`, authHeaders())

            setEnqueteDetails(old => ({
                ...old,
                notificacoes_enviadas: response
            }))
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadEnquetes()
    }, [])

    useEffect(() => {
        if (enqueteDetails && enqueteDetails.notificacoes_enviadas === undefined) {
            loadNotificacoes(enqueteDetails)
        }
    }, [enqueteDetails])

    if (!enquetes) {
        return (
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <Spinner label="Carregando enquetes..." />
                    </Card>
                </main>
            </Container>
        )
    }

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1>Enquetes abertas</h1>
                            <Button className="transparent" onClick={() => { history.push('/grupo_permissoes/29') }} style={{ gap: 10 }}>
                                <FiArrowLeft size={16} style={{ marginLeft: 0 }} />
                                Voltar
                            </Button>
                        </div>

                        <Table
                            headers={[
                                { name: 'nome', value: 'Enquete' },
                                {
                                    name: 'inicio', value: 'Início', centered: true, style: { width: 110 }
                                },
                                {
                                    name: 'fim', value: 'Fim', centered: true, style: { width: 110 }
                                },
                                {
                                    name: 'status', value: 'Status', centered: true, style: { width: 100 }
                                },
                                {
                                    name: 'status_conclusao', value: 'Participação', centered: true, style: { width: 124 }
                                }
                            ]}
                            data={enquetes}
                            actions={[
                                {
                                    name: 'visualizar',
                                    icon: FiEye,
                                    iconSize: 16,
                                    action: item => {
                                        setEnqueteDetails(enquetes.find(e => e.id === item.id))
                                    }
                                },
                                {
                                    name: 'participar',
                                    icon: FiArrowRight,
                                    iconSize: 16,
                                    action: item => {
                                        history.push(`/enquete/${item.id}/votar`)
                                    },
                                    checkDisabled: item => item.participacao_finalizada || item.nao_iniciada || item.finalizada,
                                    disabledTooltip: 'Você já finalizou sua participação'
                                }
                            ]}
                            hideOnSmallHeaders={['inicio']}
                            hideOffset={630}
                        />
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!enqueteDetails}
                handleClose={() => {
                    setEnqueteDetails(null)
                }}
                title={`Detalhes da enquete ${enqueteDetails?.nome || ''}`}
            >
                {enqueteDetails && (
                    <DetalhesContainer>
                        <p>
                            Motivo da enquete:
                            <b>
                                {` ${enqueteDetails.motivo}`}
                            </b>
                        </p>
                        <p>
                            Status da enquete:
                            <b>
                                {` ${enqueteDetails.status}`}
                            </b>
                        </p>
                        <p>
                            Status da sua participação:
                            <b>
                                {` ${enqueteDetails.status_conclusao}`}
                            </b>
                        </p>
                        <p>
                            Início:
                            <b>
                                {` ${enqueteDetails.inicio}`}
                            </b>
                        </p>
                        <p>
                            Fim:
                            <b>
                                {` ${enqueteDetails.fim}`}
                            </b>
                        </p>
                    </DetalhesContainer>
                )}
            </Modal>
        </>
    )
}
