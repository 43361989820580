import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'

import { api, authHeaders } from '../../../../../services/api'

import { Container, EventDetails } from './styles'

import { EventoContext } from '../../../../../contexts/EventoContext'

export default function () {
    const { evento, reload } = useContext(EventoContext)

    const [pessoas, setPessoas] = useState(null)

    useEffect(() => {
        if (evento) {
            setPessoas(evento.participantes.filter(pessoa => pessoa.presente)
                .map(pessoa => {
                    let documento = pessoa.documentos?.find(doc => doc.tipo_documento.id === 3)

                    if (!documento) {
                        documento = pessoa.documentos?.find(doc => doc.tipo_documento.id === 2)
                    }

                    return {
                        id: pessoa.id,
                        identificador: documento?.identificador || '-',
                        nome: pessoa.nome
                    }
                }))
        }
    }, [evento])

    async function handleEmitirCertificado(item, emitir) {
        try {
            await api.post(`evento/${evento.id}/certificado`, {
                pessoas: [item.id],
                emitir
            }, authHeaders())

            reload()

            if (emitir) {
                toast.success('Certificado registrado com sucesso.')
            } else {
                toast.success('O certificado foi cancelado.')
            }
        } catch (e) {
            toast.error('Erro ao registrar o certificado.')
        }
    }

    async function toggleCheckAll(isCheckedAll) {
        try {
            await api.post(`evento/${evento.id}/certificado`, {
                pessoas: pessoas.map(p => p.id),
                emitir: isCheckedAll
            }, authHeaders())

            reload()

            if (isCheckedAll) {
                toast.success('Certificados registrados com sucesso.')
            } else {
                toast.success('Todos os certificados foram cancelados.')
            }
        } catch (e) {
            toast.error('Erro ao registrar os certificados.')
        }
    }

    if (!evento) {
        return null
    }

    return (
        <Container>
            <EventDetails>
                <h1>{`Evento: ${evento.name}`}</h1>
                <h2>{`Local: ${evento.nome_local}`}</h2>
                <h2>{`Início: ${evento.inicio}`}</h2>
            </EventDetails>

            <p className="instrucoes">Marque abaixo os participantes que receberão o certificado do evento:</p>

            {pessoas ? (
                <Table
                    headers={[
                        {
                            name: 'identificador', value: 'CRM/CPF', centered: true, style: { width: 200 }
                        },
                        { name: 'nome', value: 'Nome' }
                    ]}
                    data={pessoas || []}
                    filterable
                    tableId="convite-table"
                    checkable
                    checkedItems={
                        evento.participantes.filter(participante => participante.certificado)
                            .map(participante => participante.id)
                    }
                    onCheckItem={item => { handleEmitirCertificado(item, true) }}
                    onUncheckItem={item => { handleEmitirCertificado(item, false) }}
                    toggleCheckAll={isCheckedAll => toggleCheckAll(isCheckedAll)}
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
