import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { Container, ButtonContainer } from './styles'

import { Button, Select } from '../../../../../components/Form'

import { forceDownloadFileFromURL } from '../../../../../util/file'
import formUtils from '../../../../../util/form'

const nomeRelatorio = 'Relatório Geral de Enquetes'

const validation = Yup.object().shape({
    enquete: Yup.string().ensure().required('Selecione uma enquete')
})

export default function (props) {
    const [enquetes, setEnquetes] = useState([])
    const [enqueteSelecionada, setEnqueteSelecionada] = useState(null)
    const [visoesDisponiveis, setVisoesDisponiveis] = useState(null)
    const [visaoSelecionada, setVisaoSelecionada] = useState(null)

    async function loadEnquetes() {
        const response = await api.get('enquete', authHeaders())

        setEnquetes(response)
    }

    async function loadVisoesDisponiveis() {
        try {
            const response = await api.get(`enquete/${enqueteSelecionada.id}/visoes`, authHeaders())

            setVisoesDisponiveis(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            values = formUtils.extractFormValues(values)

            if (visaoSelecionada) {
                values.grupo = visaoSelecionada.id
            }

            const response = await api.post('enquete/relatorio/mensagens', values, authHeaders())

            forceDownloadFileFromURL(response.link, nomeRelatorio)

            resetForm()
            setEnqueteSelecionada(null)
            setVisoesDisponiveis(null)
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }

    useEffect(() => {
        loadEnquetes()
    }, [])

    useEffect(() => {
        if (enqueteSelecionada) {
            loadVisoesDisponiveis()
        }
    }, [enqueteSelecionada])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{ enquete: '' }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="enquete"
                            label="Enquete"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                                setEnqueteSelecionada(enquetes.find(e => e.id === selected.value))
                            }}
                            options={enquetes.map(enquete => ({
                                label: enquete.nome,
                                value: enquete.id
                            }))}
                        />

                        {visoesDisponiveis && (
                            <Select
                                withoutForm
                                name="grupo"
                                label="Grupo"
                                onChange={selected => {
                                    setVisaoSelecionada(visoesDisponiveis.find(v => v.id === selected.value))
                                }}
                                options={[
                                    { label: 'Todos os grupos', value: 0 },
                                    ...(visoesDisponiveis?.map(visao => ({
                                        label: visao.descricao,
                                        value: visao.id
                                    })) || [])
                                ]}
                                placeholder="Selecione a enquete..."
                            />
                        )}

                        <ButtonContainer>
                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
