import StringMask from 'string-mask'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import emailMask from 'text-mask-addons/dist/emailMask'

export default {
    mobile: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],

    phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],

    cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],

    cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],

    pis: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/],

    zipcode: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],

    email: emailMask,

    cardNumber: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    cardNumberAmex: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    cvv: [/\d/, /\d/, /\d/],
    shortDate: [/\d/, /\d/, '/', /\d/, /\d/],
    date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    datetime: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/],
    time: [/\d/, /\d/, ':', /\d/, /\d/],

    timeInHours: [/\d/, /\d/, 'h'],
    timeInMinutes: [/\d/, /\d/, ' min'],

    agencia: [/\d/, /\d/, /\d/, /\d/],

    numero: createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol: '',
        allowDecimal: false,
        requireDecimal: false
    }),

    money: createNumberMask({
        prefix: 'R$ ',
        thousandsSeparatorSymbol: '.',
        decimalSymbol: ',',
        decimalLimit: 2,
        allowNegative: true,
        allowDecimal: true,
        requireDecimal: false
    }),

    percent: createNumberMask({
        prefix: '',
        suffix: '%',
        thousandsSeparatorSymbol: '',
        decimalSymbol: ',',
        decimalLimit: 2,
        allowDecimal: true,
        requireDecimal: false
    }),

    unmask: {
        mobile: value => value ? value.replace(/[() -]/g, '') : '',

        money: value => {
            if (value) {
                const isNegative = value.indexOf('– ') === 0

                return Number(value.replace(/(R\$ )/g, '').replace('– ', '').replace(/\./g, '').replace(',', '.')) * (isNegative ? -1 : 1)
            }

            return ''
        },

        percent: value => value ? Number(value.replace('%', '').replace('.', '').replace(',', '.')) : ''
    },

    maskApply: {
        mobile: number => {
            if (number.length === 11) {
                return `(${number.slice(0, 2)}) ${number.slice(2, 7)}-${number.slice(7)}`
            }

            return number
        },

        phone: number => {
            if (number.length === 10) {
                return `(${number.slice(0, 2)}) ${number.slice(2, 6)}-${number.slice(6)}`
            }

            return number
        },

        percent: (number, decimals = 2) => {
            if (!number) {
                return '0%'
            }

            if (decimals) {
                number = Number(number).toFixed(decimals)
            }

            return `${String(number).replace('.', ',')}%`
        },

        cpf: number => {
            if (number.length === 11) {
                return `${number.slice(0, 3)}.${number.slice(3, 6)}.${number.slice(6, 9)}-${number.slice(9, 11)}`
            }

            return number
        },

        cnpj: number => {
            if (number.length === 14) {
                return `${number.slice(0, 2)}.${number.slice(2, 5)}.${number.slice(5, 8)}/${number.slice(8, 12)}-${number.slice(12, 14)}`
            }

            return number
        },

        cep: number => {
            if (number.length === 8) {
                return `${number.slice(0, 5)}-${number.slice(5)}`
            }

            return number
        },

        currency: value => {
            if (value === null || value === undefined) {
                return ''
            }

            const formatter = new StringMask('#.##0,00', { reverse: true })

            value = Number(value).toFixed(2)

            let result = `R$ ${formatter.apply(value.replace('.', ''))}`

            if (Number(value) < 0) {
                result = `– ${result.replace('R$ .', 'R$ ')}`
            }

            return result
        },

        month: value => {
            if (value === null || value === undefined) {
                return ''
            }

            value = Number(value) < 10 ? `0${value}` : value

            return value.toString()
        }
    }
}
