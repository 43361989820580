import styled from 'styled-components'

export const Container = styled.div`
	
`

export const FormContainer = styled.div`
	display: flex;
	justify-content: center;

	form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		align-items: end;

		> button[type=submit] {
			grid-column: 2;
			width: fit-content;
			justify-self: flex-end;
			align-self: flex-end;
		}

		.form-assunto {
			max-width: 526px;
			width: 100%;
			grid-column: 1/span 2;
			display: grid;
			grid-template-columns: 3fr 1fr 52px;
			align-items: flex-end;

			> button {
				width: 36px;
				height: 36px;
				margin-bottom: 12px;

				svg {
					font-size: 16px;
				}
			}
		}

		.table-container {
			margin: 16px 8px 0;
		}

		details {
			grid-column: 1/span 2;
			width: calc(100% - 16px);
			margin: 12px 8px 0;
			transition: 300ms;
			border: solid 1px #fff;
			border-bottom-width: 3px;
			padding: 16px;
			border-radius: 6px;
			padding: 0;
			display: flex;
			flex-direction: column;

			> .assunto {
				display: grid;
				grid-template-columns: 1fr 44px;
				align-items: center;

				> button {
					width: 24px;
					align-self: center;
					margin-top: 22px;
				}
			}

			> button {
				width: calc(100% - 16px);
			}

			&[open] {
				summary {
					background-color: #FFF;
					color: #424242;

					svg {
						color: #424242;
					}
				}
			}

			summary {
				cursor: pointer;
				padding: 18px;
				transition: 300px;
				display: flex;
				justify-content: space-between;

				&:hover {
					background-color: #FFF;
					color: #424242;

					svg {
						color: #424242;
					}
				}
			}

			.table-container {
				margin: 8px;
				width: calc(100% - 16px);
			}
		}

		@media(max-width: 830px) {
			display: flex;
			flex-direction: column;
		}
	}
`
