import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { Editor } from 'primereact/editor'
import ReactTooltip from 'react-tooltip'

import { FiRefreshCw } from 'react-icons/fi'
import useEventListener from '../../hooks/useEventListener'

import Mensagem from './Mensagem'

import { Container } from './styles'

import { api, authHeaders } from '../../services/api'

import scrollUtils from '../../util/scroll'
import SmallScreenPlaceholder from '../SmallScreenPlaceholder'

export default function ({
    sender,
    messages,
    atendimento_id,
    readOnly,
    singleLine = true,
    sendWithEnterKey = true,
    readOnlyPlaceholder,
    sendEmail = false,
    className = '',
    id = '',
    height = 732,
    title = {
        text: 'Atendimento',
        color: 'unset'
    },
    alertaEditor = '',
    routePrefix = 'atendimento/mensagem',
    onSendMessage,
    onUpdate,
    minWidthToHide = 390
}) {
    const [messageList, setMessageList] = useState(null)
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [inputFocus, setInputFocus] = useState(false)
    const [synchronizing, setSynchronizing] = useState(false)

    async function handleEnviarMensagem() {
        try {
            if (!message) {
                return
            }

            const lastMessage = {
                id: new Date().getTime(),
                datetime: format(new Date(), 'dd/MM/yyyy HH:mm\'h\''),
                text: message,
                sender: {
                    id: sender?.id || null,
                    name: sender?.name,
                    image: sender?.image
                },
                sending: true
            }

            setMessageList([
                ...messageList,
                lastMessage
            ])

            setMessage('')

            scrollUtils.toEnd('.conversation__body')

            const messageId = await api.post(`${routePrefix}/${atendimento_id}`, {
                mensagem: message,
                send_email: sendEmail
            }, authHeaders())

            const idAnterior = lastMessage.id

            lastMessage.id = messageId
            lastMessage.sending = false

            setMessageList([
                ...messageList.filter(msg => msg.id !== idAnterior),
                lastMessage
            ])

            onSendMessage?.(message)
        } catch (e) {
            toast.error('Erro ao enviar a mensagem.')
        }
    }

    function handleTextChange({ htmlValue }) {
        if (!htmlValue) {
            return
        }

        const linkRegex = /(<a href=")(http[a-z0-9:/.-]+)(".+<\/a>)/g

        const htmlTratado = htmlValue.replace(linkRegex, '$2')

        setMessage(htmlTratado)
    }

    useEventListener('keyup', e => {
        if (sendWithEnterKey && e.keyCode === 13) {
            handleEnviarMensagem()
        }
    })

    useEffect(() => {
        if (!singleLine && sendWithEnterKey) {
            throw new Error('Não é possível habilitar o envio com Enter quando a caixa de texto é um Editor.')
        }
    }, [])

    useEffect(() => {
        setMessageList(messages)
    }, [messages])

    useEffect(() => {
        if (messageList) {
            setLoading(true)

            setTimeout(() => {
                scrollUtils.toEnd(id ? `#${id} .conversation__body` : '.conversation__body')
            }, 600)

            setLoading(false)
        }
    }, [messageList])

    useEffect(() => {
        window.addEventListener('focus', onUpdate)

        return () => {
            window.removeEventListener('focus', onUpdate)
        }
    }, [])

    return (
        <>
            <Container className={className} height={height} id={id} minWidthToHide={minWidthToHide}>
                <SmallScreenPlaceholder hideOffset={minWidthToHide} text="Gire o dispositivo para visualizar o chat" />

                <div className={`mask ${readOnly ? 'readonly' : ''}`}>
                    <div className="container">

                        <section className="conversation">
                            <header className="conversation__header">
                                <div className="conversation__info">
                                    <span
                                        className="conversation__name"
                                        dangerouslySetInnerHTML={{ __html: title.text }}
                                        style={{ color: title.color }}
                                    />
                                </div>

                                {onUpdate && (
                                    <div className="update-container">
                                        {synchronizing && (
                                            <span>Sincronizando as mensagens...</span>
                                        )}

                                        <FiRefreshCw
                                            size={16}
                                            color="#424242"
                                            data-tip="Atualizar mensagens"
                                            data-for="tooltip-dialogo"
                                            className={synchronizing ? 'rotate' : ''}
                                            onClick={() => {
                                                setSynchronizing(true)

                                                onUpdate()

                                                setTimeout(() => {
                                                    setSynchronizing(false)
                                                }, 5000)
                                            }}
                                        />
                                    </div>
                                )}
                            </header>

                            <div className="conversation__body">
                                {loading ? (
                                    <p className="loading-message">Carregando mensagens...</p>
                                ) : messageList?.map(m => (
                                    <Mensagem
                                        key={m.id}
                                        me={m.sender.id === sender.id}
                                        message={m}
                                    />
                                ))}
                            </div>

                            <footer className="conversation__footer">
                                {alertaEditor && (
                                    <p
                                        className="alerta-chat-principal"
                                        dangerouslySetInnerHTML={{ __html: alertaEditor }}
                                    />
                                )}

                                {!readOnly ? (
                                    <>
                                        {singleLine ? (
                                            <input
                                                id="message"
                                                type="text"
                                                onChange={e => { setMessage(e.target.value) }}
                                                value={message}
                                                onFocus={() => { setInputFocus(true) }}
                                                onBlur={() => { setInputFocus(false) }}
                                                className="conversation__write"
                                                disabled={readOnly}
                                                placeholder={readOnly
                                                    ? readOnlyPlaceholder || 'Não é possível enviar mensagens.'
                                                    : inputFocus
                                                        ? 'Digite sua mensagem agora...'
                                                        : 'Clique para enviar uma mensagem'}
                                            />
                                        ) : (
                                            <Editor
                                                id="message"
                                                style={{ height: 300 }}
                                                className="conversation__write"
                                                value={message}
                                                readOnly={readOnly}
                                                onTextChange={handleTextChange}
                                                placeholder={readOnly
                                                    ? readOnlyPlaceholder || 'Não é possível enviar mensagens.'
                                                    : 'Digite sua mensagem aqui...'}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <p className="placeholder-text">{readOnlyPlaceholder || 'É preciso iniciar o atendimento para enviar mensagens.'}</p>
                                )}

                                <button className="btn btn--send" onClick={handleEnviarMensagem} disabled={readOnly}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <line x1="22" y1="2" x2="11" y2="13" />
                                        <polygon points="22 2 15 22 11 13 2 9 22 2" />
                                    </svg>
                                    <span className="title">Enviar</span>
                                </button>
                            </footer>

                        </section>

                    </div>
                </div>

                <p className="observacao">Obs.: Cada mensagem enviada acima será copiada por e-mail a todos os envolvidos neste diálogo.</p>
            </Container>

            <ReactTooltip
                id="tooltip-dialogo"
                place="top"
                effect="solid"
                type="dark"
            />
        </>
    )
}
