import React, { useContext, useEffect, useState } from 'react'
import {
    FiCheck,
    FiCheckCircle, FiMenu, FiMessageSquare, FiShare2
} from 'react-icons/fi'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { FaUsers } from 'react-icons/fa'

import Dialogo from '../../../../../components/Dialogo'
import Modal from '../../../../../components/Modal'
import ArquivosBox from '../Interacao/Arquivos'

import Table from '../../../../../components/Table'
import { perfisAssociado, ProcessoContext } from '../../../../../contexts/ProcessoContext'
import { GlobalContext } from '../../../../../contexts/GlobalContext'

import {
    MensagemMovimentacaoContainer, AtendimentoContainer
} from './styles'
import { api, authHeaders } from '../../../../../services/api'
import scrollUtils from '../../../../../util/scroll'
import Spinner from '../../../../../components/Spinner'

import avatarDefault from '../../../../../assets/images/avatar.png'
import { Button } from '../../../../../components/Form'

export default function () {
    const { user } = useContext(GlobalContext)
    const {
        processo, reloadProcesso, tramite, setTramite, reloadTramite, syncDialogos, setShowModalMovimentacao, setTramiteMovimentacao, setAcao
    } = useContext(ProcessoContext)

    const [interacaoBloqueada, setInteracaoBloqueada] = useState(false)
    const [diretores, setDiretores] = useState(null)
    const [tiposDocumento, setTiposDocumento] = useState([])

    async function loadDiretores() {
        const response = await api.get('diretoria', authHeaders())

        setDiretores(response)
    }

    async function handleAssumirAtendimento(item) {
        try {
            await api.patch(`processo/tramite/assumir/${processo.id}/${item.id}/${user.pessoa.id}`, {}, authHeaders())

            toast.success('Você assumiu o atendimento desta etapa do chamado. Aguarde...')

            reloadProcesso()

            return true
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function eParticipante(tram) {
        const [setor] = await api.get('processo/atendentes_por_setor', {
            params: {
                setor_id: tram.destino?.id
            },
            ...authHeaders()
        })

        const interacaoLiberada = processo.status_id !== 2
            && (
                (tramite.pessoa_origem?.id === user.pessoa.id && tramite.status_id !== 2)
                || (!tramite.atendente && tram.destino && setor.pessoas.some(pessoa => pessoa.id === user.pessoa.id))
                || (tramite.atendente?.id === user.pessoa.id && tramite.status_id === 1)
                || (tramite.convidados?.some(c => c.id === user.pessoa.id) && tramite.status_id !== 2)
            )

        setInteracaoBloqueada(!interacaoLiberada)
    }

    async function loadTiposDocumento() {
        const response = await api.get('acervo/segmento/59', {
            params: {
                publico: 0
            }
        })

        setTiposDocumento(response)
    }

    async function handleAprovarDocumento() {
        toast.success('ok')
    }

    useEffect(() => {
        if (tramite) {
            eParticipante(tramite)
        }
    }, [tramite])

    useEffect(() => {
        loadDiretores()
    }, [])

    function getActions() {
        const actions = [
            {
                action: setTramite,
                name: 'Visualizar',
                icon: FiMessageSquare,
                no_preload: true,
                iconSize: 17,
                checkDisabled: item => !item.permitir_acessar_dialogo,
                disabledTooltip: 'Este trâmite é restrito e não pode ser acessado por seu usuário.'
            }
        ]

        if (!perfisAssociado.includes(user.perfil.id)) {
            actions.unshift({
                action: handleAssumirAtendimento,
                name: 'Assumir atendimento',
                icon: FiCheckCircle,
                iconSize: 17,
                checkDisabled: item => !item.permitir_assumir_atendimento
            })

            actions.push({
                action: item => {
                    setTramiteMovimentacao(item)
                    setShowModalMovimentacao(true)
                },
                name: 'Opções',
                icon: FiMenu,
                iconSize: 16,
                checkDisabled: item => {
                    const eOrigem = user?.pessoa.id === item.pessoa_origem.id
                    const eAtendente = user?.pessoa.id === item.atendente?.id

                    return item.status_id === 2 || (!eOrigem && !eAtendente)
                }
            })
        }

        return actions
    }

    if (!processo || !user) {
        return null
    }

    const { participantes } = processo
    const primeiroTramiteId = processo.tramites[0].id
    const ultimoTramiteId = processo.tramites.at(-1).id
    const eTramiteExtremidade = processo.status_id !== 2
        ? primeiroTramiteId === tramite?.id
        : (primeiroTramiteId === tramite?.id || ultimoTramiteId === tramite?.id)
    const tramiteSemDialogo = !tramite?.dialogo_id

    function getHeaders() {
        const headers = [
            { name: 'created_at', value: 'Data', centered: true },
            { name: 'origem', value: 'Origem', centered: true },
            { name: 'destino_descricao', value: 'Destino', centered: true },
            { name: 'status', value: 'Status', centered: true },
            { name: 'atendente_nome', value: 'Responsável', centered: true }
        ]

        if (!perfisAssociado.includes(user.perfil.id)) {
            headers.push({
                name: 'prazo', value: 'Prazo previsto', centered: true
            })
        }

        return headers
    }

    return (
        <>
            <Table
                caption={`Histórico de movimentações (trâmites) - Atendimento ${processo.identificador}`}
                headers={getHeaders()}
                data={processo.tramites || []}
                emptyLabel="Nenhuma movimentação até o momento"
                actions={getActions()}
                hideOnSmallHeaders={['atendente_nome', 'origem', 'prazo']}
                hideHeadersOffset={850}
                hideOffset={480}
                legends={[
                    { text: 'Prazo ultrapassado', color: '#ffcdd2' }
                ]}
            />

            <Modal
                isOpen={!!tramite}
                handleClose={() => { setTramite(null) }}
                title="Detalhes do trâmite"
            >
                <MensagemMovimentacaoContainer>
                    {eTramiteExtremidade || tramiteSemDialogo ? (
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: `Enviado por <b>${tramite?.origem}</b>` }} />
                            <p>{tramite?.mensagem}</p>
                        </div>
                    ) : tramite?.messages ? (
                        <AtendimentoContainer>
                            {tramite.participantes?.length > 0 && (
                                <div className="participantes-container">
                                    <div className="participantes">
                                        <span>Participantes deste trâmite:</span>

                                        {tramite.participantes.map(part => (
                                            <div key={part.id} className="participante">
                                                <img
                                                    src={part.usuario?.avatar?.link || avatarDefault}
                                                    alt={part.nome}
                                                />
                                                <div>
                                                    <span className="nome">{part.nome}</span>
                                                    {part.convidado && (
                                                        <span className="convidado">
                                                            <FiShare2 size={12} />
                                                            Convidado
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {tramite.status_id !== 2 && (
                                        <Button
                                            className="convidar transparent"
                                            onClick={() => {
                                                setTramite(null)

                                                setShowModalMovimentacao(true)
                                                setTramiteMovimentacao(tramite)

                                                setAcao('convidar')
                                            }}
                                        >
                                            Convidar pessoas
                                            <FaUsers size={16} />
                                        </Button>
                                    )}
                                </div>
                            )}

                            <Dialogo
                                sender={{
                                    id: user.pessoa.id,
                                    name: user.pessoa.nome,
                                    image: user.avatar
                                }}
                                sendWithEnterKey={false}
                                singleLine={false}
                                className="dialogo"
                                title={{
                                    text: 'Chat privado'
                                }}
                                id={`dialogo-tramite-${tramite.id}`}
                                atendimento_id={`${processo.id}/${tramite.id}`}
                                routePrefix="processo/tramite/mensagem"
                                messages={participantes && tramite ? tramite.messages.map(msg => {
                                    const sender = participantes.find(part => part.id === msg.sender)

                                    return {
                                        id: msg.id,
                                        datetime: format(new Date(msg.created_at), 'dd/MM/yyyy HH:mm\'h\''),
                                        text: msg.content,
                                        sender: {
                                            id: sender?.id || null,
                                            name: sender?.nome,
                                            image: sender?.usuario?.avatar?.link
                                        }
                                    }
                                }) : []}
                                sendEmail
                                height={700}
                                onUpdate={syncDialogos}
                                onSendMessage={() => {
                                    scrollUtils.toEnd(`.dialogo-tramite-${tramite.id} .conversation__body`)

                                    // if (!processo.participantes.some(p => p.id === user.pessoa.id)) {
                                    //     processo.participantes.push({
                                    //         id: user.pessoa.id,
                                    //         nome: user.pessoa.nome,
                                    //         usuario: {
                                    //             avatar: { link: user.avatar }
                                    //         }
                                    //     })
                                    // }

                                    reloadTramite()
                                }}
                                readOnly={interacaoBloqueada}
                                readOnlyPlaceholder={processo?.status_id === 2
                                    ? 'Este atendimento já foi finalizado. Não é possível enviar novas mensagens.'
                                    : tramite?.status_id === 2
                                        ? 'Esta etapa do atendimento já foi finalizada. Não é possível interagir.'
                                        : undefined}
                            />

                            <ArquivosBox
                                send_email
                                tramiteId={tramite.id}
                                uploadURL={`processo/tramite/arquivo/${processo.id}/${tramite.id}`}
                                readOnly={interacaoBloqueada}
                            />

                            {diretores?.map(d => d.pessoa.id).includes(user.pessoa.id) && (
                                <Button
                                    className="transparent"
                                    onClick={() => {
                                        loadTiposDocumento()
                                    }}
                                >
                                    Aprovar documento
                                    <FiCheck size={17} />
                                </Button>
                            )}
                        </AtendimentoContainer>
                    ) : (
                        <Spinner label="Carregando mensagens e arquivos..." />
                    )}
                </MensagemMovimentacaoContainer>
            </Modal>

            <Modal
                isOpen={!!tiposDocumento?.length}
                handleClose={() => {
                    setTiposDocumento([])
                }}
                title="Aprovação de documento"
            >
                <p>{JSON.stringify(tiposDocumento)}</p>

                <Button onClick={() => { handleAprovarDocumento() }} className="transparent">
                    Aprovar
                    <FiCheck size={17} />
                </Button>
            </Modal>
        </>
    )
}
