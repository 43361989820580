import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			display: flex;
			flex-direction: column;

			.form-tipo-cobranca {
				display: flex;
				flex-direction: column;
				gap: 8px;

				> div {
					display: flex;
					align-items: end;
				}
			}
		}
	}
`
