import styled from 'styled-components'

export const Container = styled.div`

`

export const FormContainer = styled.div`
	display: flex;
	justify-content: center;

	form {
		display: flex;
		flex-direction: column;
		max-width: 350px;
		width: 100%;

		button {
			width: fit-content;
			align-self: flex-end;
		}
	}
`
