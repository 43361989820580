import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas: 
			'title title title'
			'f1 f2 f3'
			'f4 f5 f6'
			'btn btn btn';

		> h1 {
			grid-area: title;
			margin: 5px 8px;
		}

		@media(max-width: 800px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const ButtonContainer = styled.div`
	grid-area: btn;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 10px;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`
