import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { FiArrowRight, FiSearch } from 'react-icons/fi'
import { Container, IdentificacaoMedicoContainer } from './styles'
import Card from '../../components/Card'
import {
    Button, Checkbox, File, Select, Textarea, Textbox
} from '../../components/Form'
import { TipContext } from '../../contexts/TipContext'
import masks from '../../util/masks'
import formUtils from '../../util/form'
import { api } from '../../services/api'
import Yup from '../../services/yup'
import Spinner from '../../components/Spinner'
import cpfApi from '../../services/cpf'
import { validarCPF } from '../../util/validadores'
import { stringNomalize } from '../../util/string'

const baseInitialValues = {
    texto: '',
    arquivo: null,
    cpf: '',
    nome: '',
    fone: '',
    email: '',
    tipo: null,
    medico: false,
    crm: ''
}

const validation = Yup.object({
    crm: Yup.string().required('Informe seu CRM.'),
    cpf: Yup.string().required('Informe seu CPF.'),
    fone: Yup.string().required('Informe um número para contato.'),
    email: Yup.string().required('Informe um e-mail para contato.'),
    tipo: Yup.string().ensure().nullable().required('Selecione o assunto da denúncia.'),
    texto: Yup.string().required('Forneça detalhes relacionados à sua denúncia.')
})

export default function () {
    const { tips, setCodigo } = useContext(TipContext)

    const history = useHistory()

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [tipos, setTipos] = useState(null)
    const [especialidades, setEspecialidades] = useState(null)
    const [tipoDemandante] = useState('medico')
    const [associadoEncontrado, setAssociadoEncontrado] = useState(false)
    const [loadingCRM, setLoadingCRM] = useState(false)
    const [submissaoLiberada, setSubmissaoLiberada] = useState(false)

    async function loadTipos() {
        const response = await api.get('atendimento_assunto', {
            params: { tipoId: 4 }
        })

        setTipos(response)
    }

    async function handleEnviar(values) {
        try {
            values = formUtils.extractFormValues(values)

            const {
                nome, fone, email, arquivo, texto, cpf, tipo, crm
            } = values

            const body = {
                pessoa: {
                    nome,
                    fone,
                    email,
                    cpf,
                    crm,
                    especialidades
                },
                arquivo,
                mensagem: texto,
                assunto_id: tipo
            }

            await api.post('processo/publico', body)

            toast.success('Denúncia enviada com sucesso. Você receberá um e-mail com mais informações.')

            history.push('/')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function searchByCPF(ev) {
        try {
            const { target } = ev
            const cpf = ev.target.value.replace(/[.\-_]/g, '')

            setSubmissaoLiberada(false)

            if (cpf.length === 11) {
                if (associadoEncontrado) {
                    if (!validarCPF(cpf)) {
                        toast.warn('O CPF não é válido. Verifique o número digitado.')

                        return
                    }

                    try {
                        const { data: response } = await cpfApi.get(cpf)

                        const nomeAPICPF = stringNomalize(response.nome)
                        const nomeCFM = stringNomalize(initialValues.nome)

                        if (nomeAPICPF !== nomeCFM) {
                            toast.warn('Seu nome completo não coincide no cadastro do CFM e da Receita Federal. Verifique sua situação para regularizá-la o quanto antes.')
                        } else {
                            setSubmissaoLiberada(true)
                        }
                    } catch (e) { }

                    return
                }

                const pessoa = await api.get('pessoa/publico', {
                    params: {
                        cpf: ev.target.value
                    }
                })

                if (pessoa) {
                    if (pessoa.associado && ![1, 5].includes(pessoa.associado.status_associacao.id)) {
                        history.push({
                            pathname: '/',
                            state: { abertura_atendimento: true }
                        })

                        toast.warn('Como você está sindicalizado, faça seu login para poder registrar sua denúncia.')

                        return
                    }

                    const { nome, contato } = pessoa
                    const email = contato.find(c => c.tipo_contato_id === 6)?.contato
                    const fone = contato.find(c => [3, 4].includes(c.tipo_contato_id))?.contato

                    setInitialValues(old => ({
                        ...old,
                        cpf: target.value,
                        nome,
                        fone,
                        email
                    }))

                    setSubmissaoLiberada(true)
                } else {
                    if (!validarCPF(cpf)) {
                        toast.warn('O CPF digitado não é válido. Verifique o número digitado.')

                        return
                    }

                    try {
                        const { data: response } = await cpfApi.get(cpf)

                        if (response.nome) {
                            setInitialValues(old => ({
                                ...old,
                                cpf: target.value,
                                nome: response.nome
                            }))
                        }

                        setSubmissaoLiberada(true)
                    } catch (e) { }
                }
            }
        } catch (e) {
            console.log(e)

            toast.error(e.msg || e.message)
        }
    }

    async function searchByCRM(ev) {
        setLoadingCRM(true)

        try {
            const crmSeach = ev.target.value

            if (!crmSeach) {
                setLoadingCRM(false)

                return
            }

            const associados = await api.get('associado', {
                params: {
                    crm: crmSeach,
                    status: [2, 3, 4]
                }
            })

            const associado = associados?.[0]

            if (associado) {
                history.push({
                    pathname: '/',
                    state: { abertura_atendimento: true }
                })

                toast.warn('Para iniciar seu atendimento, faça login e você será encaminhado(a) ao formulário de abertura.')

                setAssociadoEncontrado(true)

                setLoadingCRM(false)

                return
            }

            const response = await api.get(`consulta_cfm/PA/${crmSeach}`)

            if (!response) {
                toast.warn('Este CRM não foi localizado pelo serviço do CFM. Verifique o número digitado e tente novamente em instantes.')

                setLoadingCRM(false)

                return
            }

            setInitialValues(old => ({
                ...old,
                nome: response.nome.toUpperCase(),
                crm: crmSeach
            }))

            const esps = response.especialidade.map(e => e.split('-')[0].trim())

            setEspecialidades(esps)

            setAssociadoEncontrado(true)
        } catch (e) {
            toast.error(e.msg)

            setAssociadoEncontrado(false)
        }

        setLoadingCRM(false)
    }

    // function resetForm() {
    //     setInitialValues(baseInitialValues)

    //     setEspecialidades(null)

    //     setAssociadoEncontrado(false)
    // }

    useEffect(() => {
        setCodigo('form_processo_abertura')

        loadTipos()
    }, [])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Denúncia</h1>
                    <p>{tips.formulario}</p>

                    <Formik
                        onSubmit={handleEnviar}
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validation}
                    >
                        {({
                            isSubmitting, errors, setFieldValue, values
                        }) => (
                            <Form>
                                {/* <BotoesContainer>
                                    <Button
                                        className="transparent"
                                        onClick={() => {
                                            setTipoDemandante('medico')

                                            resetForm()
                                        }}
                                    >
                                        <FaUserMd />
                                        Sou médico(a)
                                    </Button>

                                    <Button
                                        className="transparent"
                                        onClick={() => {
                                            setTipoDemandante('nao_medico')

                                            resetForm()
                                        }}
                                    >
                                        <FaUser />
                                        NÃO sou médico(a)
                                    </Button>
                                </BotoesContainer> */}

                                {tipoDemandante === 'medico' && (
                                    <IdentificacaoMedicoContainer>
                                        <Textbox
                                            name="crm"
                                            label="Matrícula"
                                            onBlur={searchByCRM}
                                            containerClass="input-crm"
                                        />

                                        {loadingCRM ? (
                                            <Spinner size={24} label="" />
                                        ) : (
                                            <Button className="transparent">
                                                Buscar
                                                <FiSearch size={16} />
                                            </Button>
                                        )}

                                        {especialidades ? (
                                            <Textbox
                                                name="especialidade"
                                                label="Especialidade médica"
                                                readOnly
                                                withoutForm
                                                value={especialidades.length ? especialidades.join(', ') : 'Nenhuma'}
                                                containerClass="input-especialidade"
                                            />
                                        ) : <div />}
                                    </IdentificacaoMedicoContainer>
                                )}

                                {(tipoDemandante === 'nao_medico' || associadoEncontrado) && (
                                    <>
                                        <div className="dados-pessoais">
                                            <Textbox
                                                name="cpf"
                                                label="CPF"
                                                mask={masks.cpf}
                                                onKeyUp={searchByCPF}
                                            />

                                            <Textbox
                                                name="nome"
                                                label="Nome"
                                                readOnly
                                                containerClass="input-nome"
                                            />

                                            <Textbox
                                                name="fone"
                                                label="Fone"
                                                mask={masks.mobile}
                                            />

                                            <Textbox
                                                name="email"
                                                label="E-mail"
                                                type="email"
                                            />

                                            <Select
                                                name="tipo"
                                                label="Assunto a tratar"
                                                options={tipos?.map(tipo => ({
                                                    label: tipo.descricao,
                                                    value: tipo.id
                                                })) || []}
                                                onChange={(selected, meta) => {
                                                    setFieldValue(meta.name, selected)
                                                }}
                                            />
                                        </div>

                                        <div className="input-container">
                                            <Textarea
                                                name="texto"
                                                label="Detalhes da denúncia"
                                            />

                                            <File
                                                name="arquivo"
                                                onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                                label="Anexo (opcional)"
                                                format="square"
                                                previewSize={['100%', '150px']}
                                                error={errors.arquivo}
                                            />
                                        </div>

                                        <Checkbox
                                            name="aceite"
                                            label="Declaro que todas as informações fornecidas acima são verídicas e assumo total responsabilidade pelas mesmas."
                                            handleChange={e => {
                                                setFieldValue(e.target.name, e.target.checked)
                                            }}
                                        />

                                        <Button
                                            type={!values.aceite || !submissaoLiberada ? 'button' : 'submit'}
                                            className={`transparent ${!submissaoLiberada || !values.aceite ? 'disabled' : ''}`}
                                            loading={isSubmitting}
                                            tooltip={!values.aceite ? 'Você deve concordar com a declaração acima para continuar.' : !submissaoLiberada ? 'Não é possível submeter sua denúncia. Seus dados não foram validados.' : undefined}
                                        >
                                            Enviar denúncia
                                            <FiArrowRight size={16} />
                                        </Button>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
