import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { FaLock } from 'react-icons/fa'
import * as Yup from 'yup'

import { Button, Textbox } from '../../../components/Form'

import { api } from '../../../services/api'

import { Container, Content } from './styles'

import logo from '../../../assets/images/logo-mini.png'

const validation = Yup.object({
    senha: Yup.string()
        .required('Senha obrigatória.')
        .min(6, 'A senha deve conter pelo menos 6 caracteres.'),
    confirmacao: Yup.string()
        .required('Confirme a senha digitada.')
        .oneOf([Yup.ref('senha')], 'As senhas não coincidem.')
})

export default function ({ match }) {
    const history = useHistory()

    useEffect(() => {
        async function checkToken() {
            try {
                await api.get(`pass_recover/${match.params.token}/${match.params.crm}`)
            } catch (e) {
                toast.error(e.msg)

                history.push('/pass_recover')
            }
        }

        checkToken()
    }, [history, match.params])

    async function handleSubmit({ senha }) {
        try {
            await api.put('save_password', {
                crm: match.params.crm,
                senha
            })

            toast.success('Senha alterada com sucesso!')

            history.push('/')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <Content className="animated fadeInUp faster delay-300ms">
                <img src={logo} alt="SINDMEPA" />

                <Formik
                    initialValues={{ senha: '', confirmacao: '' }}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <h1>Recuperação de senha</h1>

                            <Textbox name="senha" type="password" label="Nova senha" icon={{ source: FaLock }} />

                            <Textbox name="confirmacao" type="password" label="Confirme a nova senha" icon={{ source: FaLock }} />

                            <Button type="submit" loading={isSubmitting} className="transparent">Alterar minha senha</Button>
                        </Form>
                    )}
                </Formik>

            </Content>
        </Container>
    )
}
