import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	button {
		align-self: end;
		width: fit-content;
	}
`
