import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import { Button, Textbox } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import { TipContext } from '../../../../contexts/TipContext'
import { api, authHeaders } from '../../../../services/api'
import { loadTips } from '../../../../util/tip'

import { Container } from './styles'

const baseInitialValues = {
    descricao: ''
}

export default function TipoCobranca() {
    const { setCodigo, tips } = useContext(TipContext)

    const [tiposCobranca, setTiposCobranca] = useState(null)
    const [editItem, setEditItem] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function loadTiposCobranca() {
        const response = await api.get('tipo_cobranca', authHeaders())

        setTiposCobranca(response)
    }

    async function handleAdd(values, { resetForm }) {
        try {
            if(!values.descricao) {
                return
            }
        
            await api.post('tipo_cobranca', values, authHeaders())

            toast.success('Tipo de cobrança cadastrado com sucesso.')

            loadTiposCobranca()

            resetForm()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(item) {
        try {
            await api.delete(`tipo_cobranca/${item.id}`, authHeaders())

            toast.success('Tipo de cobrança removida com sucesso.')

            loadTiposCobranca()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleEdit(values, { resetForm }) {
        try {
            await api.put(`tipo_cobranca/${editItem.id}`, values, authHeaders())

            toast.success('Tipo de cobrança atualizado com sucesso.')

            resetForm()

            setInitialValues(baseInitialValues)
            setEditItem(null)

            loadTiposCobranca()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadTiposCobranca()

        loadTips(setCodigo, 'form_tipo_cobranca_plano_saude')
    }, [])

    useEffect(() => {
        if(editItem) {
            setInitialValues({
                descricao: editItem.descricao
            })
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [editItem])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Tipos de cobrança para Plano de Saúde</h1>

                    <Formik
                        onSubmit={editItem ? handleEdit : handleAdd}
                        initialValues={initialValues}
                        enableReinitialize
                    >
                        {() => (
                            <Form className="form-tipo-cobranca">
                                <p style={{ margin: 8, paddingTop: 12 }}>{tips?.formulario}</p>

                                <div>
                                    <Textbox 
                                        name="descricao"
                                        label="Tipo de cobrança"
                                    />

                                    <Button type="submit" className="white">
                                        {editItem ? 'Atualizar' : 'Cadastrar'}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                    {tiposCobranca ? (
                        <Table 
                            headers={[
                                { name: 'descricao', value: 'Descrição' }
                            ]}
                            data={tiposCobranca}
                            actions={[
                                {
                                    name: 'editar',
                                    icon: FaEdit,
                                    iconSize: 16,
                                    action: setEditItem
                                },
                                {
                                    name: 'excluir',
                                    icon: FaTrash,
                                    iconSize: 14,
                                    action: handleDelete,
                                    confirmation: 'Deseja realmente excluir este tipo de cobrança?'
                                }
                            ]}
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
