import React, { useContext } from 'react'
import { Form, Formik } from 'formik'
import { Droppable } from 'react-beautiful-dnd'

import { PermissoesContext } from '../../../../contexts/PermissoesContext'

import { Select } from '../../../../components/Form'

import PermissaoItem from '../PermissaoItem'

import { Container, List } from './styles'
import { TipContext } from '../../../../contexts/TipContext'

export default function () {
    const {
        perfis, permissoes, filterPermissoes 
    } = useContext(PermissoesContext)
    const { tips } = useContext(TipContext)

    return (
        <Container>
            <Formik
                onSubmit={() => {}}
                initialValues={{ perfil: '' }}
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <p style={{ margin: 8, gridColumn: '1/span 2', height: 0 }}>{tips?.formulario}</p>

                        <Select  
                            label="Perfil"
                            name="perfil"
                            onChange={(selected, meta) => { 
                                setFieldValue(meta.name, selected) 
                            }}
                            options={perfis?.map(perfil => ({
                                label: perfil.descricao,
                                value: perfil.id
                            })) || []}
                        />

                        <div>
                            <h1>Permissões disponíveis</h1>

                            <Droppable droppableId="disponiveis">
                                {provided => (
                                    <List
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {values.perfil?.value 
                                            ? filterPermissoes(values.perfil.value).map((permissao, index) => (
                                                <PermissaoItem
                                                    key={permissao.id}
                                                    draggableId={String(permissao.id)}
                                                    permissao={permissao}
                                                    index={index}
                                                />
                                            ))
                                            : permissoes?.map((permissao, index) => (
                                                <PermissaoItem
                                                    key={permissao.id}
                                                    draggableId={String(permissao.id)}
                                                    permissao={permissao}
                                                    index={index} 
                                                />
                                            ))}
                                    </List>
                                )}
                            </Droppable>

                            {values.perfil?.value && !filterPermissoes(values.perfil.value).length && (
                                <i>Nenhuma permissão disponível</i>
                            )}
                        </div>

                        <div>
                            <h1>Permissões adicionadas</h1>

                            <Droppable droppableId="atuais">
                                {provided => (
                                    <>
                                        {perfis?.find(perfil => perfil.id === values.perfil.value)?.permissoes && (
                                            <List
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {perfis?.find(perfil => perfil.id === values.perfil.value)?.permissoes.map((permissao, index) => (
                                                    <PermissaoItem
                                                        key={permissao.id}
                                                        draggableId={String(permissao.id)}
                                                        permissao={permissao}
                                                        index={index} 
                                                    />
                                                ))}
                                            </List>
                                        )}
                                    </>
                                )}
                            </Droppable>

                            {!values.perfil?.value && (
                                <i>Selecione um perfil</i>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
