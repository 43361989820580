import styled, { css } from 'styled-components'

function getBackgroundProgress(consumo) {
    if(consumo >= 95) {
        return css`background-color: #f44336;`
    } 
	
    if(consumo >= 90) {
        return css`background-color: #f9a825;`
    } 
	
    return css`background-color: #43a047;`
}

export const Container = styled.div`
	display: flex;
	justify-content: center;

	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 80px 30px 46px auto auto auto auto;
		grid-template-areas: 
			'saldo_anterior receita_prevista x'
			'orcado y saldo'
			'consumo consumo consumo'
			'conta conta conta'
			'tip tip tip'
			'adicionar adicionar adicionar'
			'button button button';
		grid-gap: 0 16px;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-right: 20px;

		.saldo_anterior {
			grid-area: saldo_anterior;
		}

		.receita {
			grid-area: receita_prevista;
		}

		> span {
			align-self: flex-end;
		}

		span.orcado {
			grid-area: orcado;
			margin-left: 16px;
		}

		span.saldo {
			grid-area: saldo;
		}

		.alerta {
			grid-area: alerta;
			text-align: center;
			font-weight: 600;
			margin-bottom: 14px;
		}

		table {
			grid-area: tabela;
			width: 100%;
			background-color: #fff;
			color: #424242;

			thead tr th {
				padding: 8px 0;
				border: solid 1px #ddd;
			}

			td {
				border: solid 1px #ddd;
				padding: 2px 6px;
			}

			input {
				color: #424242;
				padding: 4px 6px;
				border-radius: 4px;
				border: 0;
				max-width: 100px;
			}
		}

		.button-container {
			grid-area: button;
			display: flex;
			margin-top: 14px;
			justify-content: flex-end;
		}

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const ProgressoConsumo = styled.div`
	grid-area: consumo;
	background-color: #ddd;
	border-radius: 4px;
	margin: 0 16px;
	width: calc(100% - 16px);

	.consumo {
		width: ${props => props.consumo > 100 ? 100 - 16 : props.consumo}%;
		text-align: center;
		padding: 3px;
		border-radius: 4px;

		${props => getBackgroundProgress(props.consumo)}
	}
`

export const FormAdd = styled.div`
	grid-area: adicionar;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;

	> button {
		max-width: 300px;
		margin: 24px 0;
	}
`

export const ContasCorrenteContainer = styled.div`
	grid-area: conta;
	display: flex;
	flex-direction: column;
	gap: 8px 16px;
	width: calc(100% - 16px);
	margin: 8px 16px 0;

	> h1 {
		font-size: 18px;
		font-weight: 600;
		margin: 8px 0 0;
	}

	> details {
		margin: 20px 0 10px;
		border-radius: 6px;
		border: solid 1px #fff;
		border-bottom-width: 3px;
		height: fit-content;

		&:nth-child(n + 2) {
			margin-top: 0;
		}

		> div {
			margin: 18px;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 8px 16px;

			> h1 {
				grid-column: 1/span 4;
				margin: 0;
				font-size: 19px !important;

				span {
					font-weight: 100;
					background-color: #4caf50;
					color: #fff;
					padding: 6px;
					border-radius: 10px;
					font-size: 18px !important;

					&.negativo {
						background-color: #f44336;
					}
				}
			}

			> div {
				h1 {
					font-size: 14px;
					margin: 8px 0 2px;
				} 
				
				h2 {
					font-size: 11px;
				}

				h3 {
					font-size: 20px;
					font-weight: 100;
				}
			}

			.table-container {
				grid-column: 1/span 4;
				margin-top: 0;
			}
		}

		&[open] {
			summary {
				background-color: #FFF;
				color: #424242;

				h1 {
					color: #424242;
				}

				> svg {
					color: #424242;
				}
			}
		}

		summary {
			cursor: pointer;
			padding: 12px 18px;
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 500;

			> svg {
				margin-right: 8px;
			}

			&:hover {
				background-color: #FFF;
				color: #424242;

				svg {
					color: #424242;
				}

				h1 {
					color: #424242;
				}
			}
		}
	}

	> div {
		> h1 {
			font-size: 14px;
			font-weight: 500;
			margin: 8px 0 0;
		}

		> h2 {
			font-size: 12px;
		}

		> h3 {
			font-weight: 100;
			font-size: 20px;
		}
	}
`

export const OrcamentoItemContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	grid-gap: 0;
	margin-left: 16px;

	.field {
		margin: 0 !important;

		> span {
			display: none;
		}

		input, 
		div:first-child {
			box-shadow: none;
			border-radius: 0;
			border: solid 1px #bbb;
		}

		&:not(:first-child) {
			width: 180px;
		}

		&.value-field {
			width: 300px;
		}
	}

	button {
		padding: 11px;
		margin: 0 8px;
	}
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: rgba(255,255,255,0.9);
	width: calc(100% - 56px);
	align-self: flex-start;

	span {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		color: #424242;
	}
`

export const ConfirmClearContainer = styled.div`
	display: flex;
	flex-direction: column;
	
	> div {
		margin: 20px 8px;
	}

	.button-container {
		display: flex;
		justify-content: flex-end;
	}
`
