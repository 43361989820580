import styled, { css } from 'styled-components'

export const Container = styled.div.attrs({
    className: 'field calendar'
})`
	width: calc(100% - 16px);
	margin: 5px 8px;
	position: relative;
	display: flex;
	flex-direction: column;

	input {
		box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
	}

	.label {
		font-size: 11px;
		margin: 4px 4px 2px;
		text-align: left;
	}

	.p-calendar {
		width: 100%;

		+ span.error {
			width: 100%;
			margin-top: 6px;
		}
	}

	.p-inputtext {
		background: rgba(255,255,255,0.8);
		border: 0;
		border-radius: 4px;
		height: 42px;
		padding: 0 15px;
		color: #424242;
		width: 100%;

		&:focus {
			border: dashed 1px rgba(0,0,0,0.2) !important;
    		background: rgba(255,255,255,0.9);
			box-shadow: none !important;
		}
	}

	.p-datepicker-header .p-datepicker-year {
		margin-left: 10px !important; 
	}

	${props => props.error && css`
		padding-left: 8px;
		border-left: solid 4px #ffeb3b;
	`}

	&.header-without-year .p-datepicker-title .p-datepicker-year {
		display: none;
	}

	input:focus + svg {
		opacity: 1;
	}

	> svg {
		position: absolute;
		right: 14px;
		top: 32px;
		width: 18px;
		opacity: 0.7;
		color: rgba(0, 0, 0, 0.3) !important;

		&:hover {
			cursor: help;

			& + .tip-container {
				display: grid;
			}
		}
	}
`
