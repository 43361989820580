import styled from 'styled-components'

import { Container as Card } from '../../../components/Card/styles'

export const Container = styled.div`
	margin: 8vh;
	width: 100%;
	display: flex;
	justify-content: center;

	@media(max-width: 600px) {
		margin: 2vh 0;
	}
`

export const Content = styled(Card).attrs({
	className: 'no-responsive'
})`
	width: 100%;
	max-width: 315px;
	text-align: center;

	img {
		width: 80px;
	}

	form {
		display: flex;
		flex-direction: column;

		h1 {
			font-size: 18px;
			margin: 16px 8px 10px;
			font-weight: 500;
		}

		p {
			color: #fff;
			padding: 15px;
		}

		a {
			color: #fff;
			font-size: 14px;
			opacity: 0.8;

			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}

		button {
			margin: 15px 8px 0 !important;

		}
	}
`
