import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		.alerta-tamanho-tela {
			display: none;
			align-items: center;
			grid-template-columns: 30px auto;
			background-color: #ffeb3b;
			padding: 14px;
			border-radius: 10px;
			gap: 10px;
			
			span {
				font-size: 13px;
				color: #424242;
			}

			svg {
				color: #424242;
			}
		}

		@media(max-width: 1168px) {
			.card {
				display: none;
			}

			.alerta-tamanho-tela {
				display: grid;
			}
		}
	}
`
