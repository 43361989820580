import styled from 'styled-components'

import { Container as Card } from '../../../../components/Card/styles'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		${Card} {
			display: flex;
			flex-direction: column;

			form {
				background-color: #0002;
				border-radius: 10px;
				padding: 20px;
                display: grid;
			    grid-template-columns: 1fr 1fr 1fr;
			    grid-template-areas:
					'tip tip tip'
					'f1 f2 f3'
					'button button button';
				
			}

			@media(max-width: 900px) {
				form {
					margin-top: 30px;
                    grid-template-columns: 1fr 1fr;
					grid-template-areas:
						'tip tip'
						'f1 f2'
						'button button';
				}
			}

			@media(max-width: 600px) {
				form {
					margin-top: 30px;
                    display: flex;
					flex-direction: column;
				}
			}

			h1 {
				margin-bottom: 20px;
				grid-area: title;
				margin: 0 8px 20px;
			}
		}

		@media(max-width: 800px) {
			padding: 20px;
		}

		@media(max-width: 400px) {
			padding: 15px 0;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`
export const ButtonContainer = styled.div`
	grid-area: button;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 10px;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`
