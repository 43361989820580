import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'credit-card'
})`
	display: flex;
	justify-content: center;
	margin: 20px 8px;

	@media (max-width: 640px) 
	{
		& {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
`

export const Card = styled.div`
	background: #ffd740;
	color: #424242;
	max-width: 260px;
	width: 100%;
	height: 180px;
	padding: 8px 18px;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	font-family: 'Space Grotesk', Arial, sans-serif;
	font-weight: 800;
	box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);

	.name {
		font-size: 13px;
		margin: 0 10px 15px 4px;
		text-transform: uppercase;
	}

	.number {
		font-size: 15px;
		background: rgba(255, 255, 255, 0.7);
		padding: 8px;
		display: flex;
		justify-content: center;
	}

	.brand {
		display: flex;
		justify-content: flex-end;
	}

	.brand > img,
	.brand-placeholder {
		width: 48px;
		height: 48px;
	}

	footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.expiration > span {
		font-size: 7px;
		display: flex;
		justify-content: flex-start;
	}

	.expiration,
	.cvv {
		font-size: 10px;
		margin: 15px 10px;
		display: flex;
		justify-content: flex-start;
	}

	.expiration {
		flex-direction: column;
	}

	.cvv {
		flex-direction: row;
		align-items: center;

		svg {
			flex-grow: 1;
			fill: #424242;
			cursor: default;
			margin-right: 4px;
			height: 12px;
		}

		div {
			flex-grow: 4;
			display: flex;
			flex-direction: column;

			span {
				font-size: 7px;
			}
		}
	}

	@media (max-width: 640px) 
	{
		& {
			display: none;
		}

		.name {
			font-size: 10px;
			margin: 4px 10px 15px 4px;
		}

		.number {
			font-size: 12px;
			padding: 6px;
		}
		
		.brand > img,
		.brand-placeholder {
			width: 37px;
			height: 37px;
		}
	}
`
