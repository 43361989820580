import styled, { css } from 'styled-components'

export const InputContainer = styled.div.attrs({
    className: 'field chips'
})`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 5px 8px;
	width: calc(100% - 16px);

	input {
		box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
	}

	svg {
		position: absolute;
		right: 14px;
		top: 32px;
		width: 18px;
		opacity: 0.7;
		color: rgba(0, 0, 0, 0.3) !important;

		&:hover {
			cursor: text;
		}
	}

	input:focus + svg {
		opacity: 1;
	}

	input:disabled {
		opacity: 0.4;
	}

	input + span.error,
	svg + span.error {
		width: 100%;
		margin-top: 6px;
	}

	.spinner svg {
		margin-top: -8px;
	}

	.label {
		font-size: 11px;
		margin: 4px 4px 2px;
		text-align: left;
	}

	${props => props.error && css`
		padding-left: 8px;
		border-left: solid 4px #ffeb3b;
	`};

	.p-inputtext {
		background: rgba(255, 255, 255, 0.8);
		border: 0;
		border-radius: 4px;
		min-height: 44px;
		height: auto;
		padding: 0 15px !important;
		color: #424242;
		width: 100%;
		box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);

		.p-chips-token {
			margin-top: 10px !important;
			background-color: #FFF !important;
			border-radius: 15px !important;
			min-width: 0;
			box-sizing: border-box;
			color: #424242;
			padding: 4px 0;
			padding: 0 6px;
			width: fit-content !important;

			> span {
				border-radius: 2px;
				color: hsl(0,0%,20%);
				font-size: 85%;
				overflow: hidden;
				text-overflow: ellipsis;	
				white-space: nowrap;
				box-sizing: border-box;
			}
		}

		.p-chips-input-token {
			padding: 0 !important;
			width: 100%;

			input {
				background-color: transparent;
				box-shadow: none;
				outline: 0;
				padding: 0 !important;
			}
		}

		&::placeholder {
			color: rgba(0, 0, 0, 0.2);
			font-weight: 400;
		}

		&:focus {
			border: dashed 1px rgba(0, 0, 0, 0.2);
			background: rgba(255, 255, 255, 0.9);
		}
	}
`
