import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		.textarea,
		> p {
			grid-column: 1/span 3;
		}

		> p {
			margin: 12px 8px 8px;
		}

		> button {
			grid-column: 3;
			justify-self: end;
			width: fit-content;
		}

		@media(max-width: 800px) {
			grid-template-columns: 1fr 1fr;

			> button {
				grid-column: 2;
			}

			.textarea,
			> p {
				grid-column: 1/span 2;
			}
		}

		@media(max-width: 500px) {
			display: flex;
			flex-direction: column;

			> button {
				align-self: end;
			}
		}
	}
`
