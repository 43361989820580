import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		form {
			display: grid;
			grid-template-columns: 2fr 1.5fr 2fr 1.5fr;
			grid-template-rows: auto auto auto auto minmax(0, auto) auto minmax(70px, auto) auto auto;
			grid-template-areas:
				'tip tip tip tip'
				'canal mensagens mensagens template'
				't2 t2 t2 t2'
				'visao copia subconjunto subconjunto'
				'esp esp filtros filtros'
				'x x badge badge'
				'button button button button'
				'preview preview preview preview';

			> h2 {
				font-size: 18px;
				font-weight: 500;
				margin: 14px 0 8px 12px;
				padding-bottom: 8px;
				border-bottom: solid 1px #fff;
				width: calc(100% - 24px);
			}

			.visao-container {
				p {
					margin: 8px;
				}
			}

			.badge {
				grid-area: badge;
				background-color: #ffeb3b;
				border-radius: 6px;
				color: #424242;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				font-weight: 500;
				margin: 16px 8px 5px;

				b {
					margin-right: 4px;
				}

				.checkbox {
					margin: 16px 0;
				}
			}

			@media(max-width: 950px) {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto auto auto 70px auto auto auto auto auto;
				grid-template-areas:
					'tip tip'
					'canal mensagens'
					'template x'
					't2 t2'
					'visao subconjunto'
					'copia esp'
					'filtros filtros'
					'badge badge'
					'button button';
			}
		}

		@media(max-width: 650px) {
			padding: 14px 0 20px;

			form {
				grid-template-columns: 1fr;
				grid-template-rows: auto auto 70px auto 70px 70px 70px auto auto auto auto auto;
				grid-template-areas:
					'tip'
					'canal'
					'template'
					'mensagens'
					't2'
					'visao'
					'subconjunto'
					'copia'
					'esp'
					'filtros'
					'badge'
					'button';
			}
		}
	}
`

export const ButtonContainer = styled.div`
	grid-area: button;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 10px;
	
	@media(max-width: 950px) {
		align-items: flex-end;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}

	@media(max-width: 650px) {
		button {
			width: 100%;
		}
	}
`

export const FiltroContainer = styled.div.attrs({
	className: 'animated fadeIn'
})`
	grid-area: filtros;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 10px 0 0 10px;

	.checkbox {
		margin: 12px 8px;
	}

	@media(max-width: 650px) {
		display: flex;
		flex-direction: column;
	}
`

export const PreviewContainer = styled.div.attrs({
	className: 'preview-container animated'
})`
	grid-area: preview;
	background-color: #FFF;
	color: #424242;
	height: auto;
	margin: 20px 8px;
	border-radius: 6px;
	padding: 30px 32px;
	color: #424242;
	display: grid;
	grid-template-columns: 60px auto 250px 40px 40px 40px;
	grid-template-rows: 50px 60px auto auto 108px 34px;
	grid-template-areas:
		'title title title title title title'
		'avatar recipient recipient recipient arrow menu'
		'y header header header header z'
		'y msg msg msg msg z'
		'y footer footer footer footer z'
		'y btn btn btn btn z';

	h1, h2, p, span {
		color: #424242;
	}

	> h1 {
		grid-area: title;
	}

	.header,
	.footer {
		justify-self: center;
		width: 600px;
		
		> img {
			z-index: 1;
		}
	}

	textarea {
		box-shadow: unset;
		resize: none;
		justify-self: center;

		&:focus {
			border: none;
		}
	}

	.button-container {
		display: grid;
		grid-template-columns: 120px 120px auto;
		grid-gap: 8px;

		.btn {
			border-radius: 4px;
			padding: 0 12px;
			color: #424242;
			border: solid 1px grey;
			background-color: #FFF;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: space-around;

			svg {
				margin-right: 4px;
			}
		}
	}

	> span {
		font-size: 12px;

		strong {
			font-size: 15px;
			margin-right: 4px;
		}
	}

	.datetime {
		display: flex;
		align-items: flex-start;

		svg {
			margin-right: 4px;
		}
	}

	.p-editor-toolbar {
		display: none;
	}

	.editor-container {
		grid-area: msg;
		justify-self: center;

		.ql-container {
			width: 600px;
			border: none;
		}
	}

	@media(max-width: 950px) {
		display: none;
	}
`

export const MensagemAvulsaContainer = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr !important;
		grid-template-rows: 80px 136px auto !important;
		grid-template-areas: unset !important;
		
		.textarea {
			grid-column: 1/span 3;
		}

		button {
			width: fit-content;
			justify-self: flex-end;
			grid-column: 3;
		}
	}

	@media(max-width: 880px) {
		form {
			display: flex !important;
			flex-direction: column;

			button {
				align-self: flex-end;
			}
		}
	}
`

export const FormEnvioContainer = styled.details`
	margin-bottom: 10px;
	border-radius: 6px;
	border: solid 1px #fff;
	border-bottom-width: 3px;
	height: fit-content;
	
	.detalhes {
		margin: 18px;
	}

	&[open] {
		summary {
			background-color: #FFF;
			color: #424242;

			h1 {
				color: #424242;
			}
		}
	}

	summary {
		cursor: pointer;
		padding: 0 18px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		img {
			height: 180px;
			object-fit: cover;
			border-radius: 10px;
		}

		div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 50px;

			h1 {
				font-size: 16px;
				font-weight: 500;
				color: #FFF;
				margin: 0 !important;
			}

			svg {
				font-size: 22px;
			}
		}

		&:hover {
			background-color: #FFF;
			color: #424242;

			h1 {
				color: #424242;
			}
		}
	}
`
