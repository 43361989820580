import styled from 'styled-components'

export const Container = styled.div`
	.card {
		margin: 30px 100px;
		min-height: 400px;
			
		h1 {
			margin: 0 8px;
		}

		> p {
			margin: 16px 8px;
		}

		form {
			display: flex;
			flex-direction: column;

			.input-container {
				display: grid;
				grid-template-columns: 3fr 1fr;

				@media(max-width: 800px) {
					display: flex;
					flex-direction: column;
				}
			}

			.dados-pessoais {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr;

				.input-nome {
					grid-column: 2/span 3;
				}

				@media(max-width: 1000px) {
					grid-template-columns: 1fr 1fr;

					.input-nome {
						grid-column: 2;
					}
				}

				@media(max-width: 650px) {
					display: flex;
					flex-direction: column;
				}
			}

			button {
				align-self: end;
			}
		}

		@media(max-width: 800px) {
			margin: 30px;
		}

		@media(max-width: 440px) {
			margin: 20px 0;
		}
	}
`

export const IdentificacaoMedicoContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	> button {
		width: fit-content;
	}

	.spinner-container {
		width: fit-content;
	}

	.input-especialidade {
		grid-column: 1/span 2;
	}

	@media(max-width: 1000px) {
		grid-template-columns: 1fr 1fr;
	}

	@media(max-width: 400px) {
		grid-template-columns: 1fr 1fr 1fr;

		.input-crm {
			grid-column: 1/span 2;
		}

		.input-especialidade {
			grid-column: 1/span 3;
		}
	}
`

export const BotoesContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 24px;
	margin: 0 16px;

	button {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;
		height: unset;
		padding: 12px 12px;
		width: 180px;

		svg {
			margin: 0 !important;
			font-size: 32px !important;
		}
	}

	@media(max-width: 570px) {
		flex-direction: column;
		align-items: center;

		button {
			width: 100%;
		}
	} 
`
