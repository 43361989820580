import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { FiFileText, FiFile } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'
import ibge from '../../../../../services/ibge'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar, Textbox
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    cidade: '',
    'tipo-associado': '',
    'data-nascimento': '',
    'data-sindicalizacao': '',
    crm: '',
    tipoPagamento: ''
}

const validation = Yup.object().shape({
    tipoPagamento: Yup.string().ensure(),
    cidade: Yup.string().ensure(),
    'tipo-associado': Yup.string().ensure(),
    'data-nascimento': Yup.string().nullable(),
    crm: Yup.string().nullable(),
    'data-sindicalizacao': Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de Membros'

export default function (props) {
    const [cidades, setCidades] = useState([])
    const [instituicoes, setInstituicoes] = useState([])
    const [tiposPagamentos, setTiposPagamentos] = useState([])
    const [isSubmittingPlanilha, setIsSubmittingPlanilha] = useState(false)

    useEffect(() => {
        async function loadCidades() {
            const { data } = await ibge.get('estados/PA/municipios')

            setCidades(data.map(({ nome }) => nome).sort())
        }

        loadCidades()
    }, [])

    useEffect(() => {
        async function loadInstituicoes() {
            const response = await api.get('instituicao')
            setInstituicoes(response)
        }

        loadInstituicoes()
    }, [])

    useEffect(() => {
        async function loadTiposPagamentos() {
            const response = await api.get('tipo_pagamento', {
                params: {
                    ativos: 1
                }
            })

            setTiposPagamentos(response)
        }

        loadTiposPagamentos()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            cidade,
            'data-nascimento': nascimento,
            'tipo-associado': tipoAssociado,
            crm,
            'data-sindicalizacao': sindicalizacao,
            tipoPagamento
        } = values

        const nascimentoInicio = nascimento ? nascimento[0] : null
        const nascimentoFim = nascimento ? nascimento[1] : null
        const inicio = sindicalizacao ? sindicalizacao[0] : null
        const fim = sindicalizacao ? sindicalizacao[1] : null

        let filtros = ''

        if (tipoAssociado) {
            filtros += `Situação: ${tipoAssociado.label}, `
        }

        if (nascimentoInicio) {
            filtros += `Data nasc.: ${format(new Date(nascimentoInicio), 'dd/MM/yyyy')} a ${nascimentoFim !== null ? format(new Date(nascimentoFim), 'dd/MM/yyyy') : format(new Date(nascimentoInicio), 'dd/MM/yyyy')}, `
        }

        if (inicio) {
            filtros += `Data cadastro: ${format(new Date(inicio), 'dd/MM/yyyy')} a ${fim !== null ? format(new Date(fim), 'dd/MM/yyyy') : format(new Date(inicio), 'dd/MM/yyyy')}, `
        }

        if (cidade) {
            filtros += `Cidade: ${cidade.label}, `
        }

        if (tipoPagamento) {
            filtros += `Forma de pagamento: ${tipoPagamento.label}, `
        }

        if (crm) {
            filtros += `Matricula: ${crm}, `
        }

        try {
            const response = await api.post('/relatorio', {
                relatorio: 'RelatorioAssociados',
                parametros: {
                    dataNascimentoInicio: nascimentoInicio !== null ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : '',
                    dataNascimentoFim: nascimentoFim !== null ? format(new Date(nascimentoFim), 'yyyy-MM-dd') : (nascimentoInicio !== null) ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : '',
                    dataSindicalizacaoInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    dataSindicalizacaoFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    cidade: cidade?.value || '',
                    socio: tipoAssociado?.value || '',
                    tipo_pagamento: tipoPagamento?.value || '',
                    crm: crm || '',
                    filtros: filtros !== '' ? filtros.slice(0, -2) : ''
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])


    const handleSubmitPlanilha = useCallback(async (values, resetForm) => {
        setIsSubmittingPlanilha(true)

        const {
            cidade,
            'data-nascimento': nascimento,
            'tipo-associado': tipoAssociado,
            crm,
            'data-sindicalizacao': sindicalizacao,
            tipoPagamento
        } = values

        const nascimentoInicio = nascimento ? nascimento[0] : null
        const nascimentoFim = nascimento ? nascimento[1] : null
        const inicio = sindicalizacao ? sindicalizacao[0] : null
        const fim = sindicalizacao ? sindicalizacao[1] : null

        try {
            const response = await api.post('/relatorio/associados_excel', {
                relatorio: 'RelatorioAssociados',
                parametros: {
                    dataNascimentoInicio: nascimentoInicio !== null ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : null,
                    dataNascimentoFim: nascimentoFim !== null ? format(new Date(nascimentoFim), 'yyyy-MM-dd') : (nascimentoInicio !== null) ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : null,
                    cidade: cidade?.value || null,
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    tipo_associado: tipoAssociado?.value || null,
                    tipo_pagamento: tipoPagamento?.value || null,
                    crm: crm || null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            if (response.type === 'application/json') {
                const fr = new FileReader()

                fr.onload = function (e) {
                    const json = e.target.result
                    toast.warn(JSON.parse(json).msg)
                }
                fr.readAsText(response)
            } else {
                downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')
            }


            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }

        setIsSubmittingPlanilha(false)
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm, values
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="tipo-associado"
                            label="Situação"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Ativo', value: 3 },
                                { label: 'Cadastro em análise', value: 2 },
                                { label: 'Aguardando pagamento', value: 4 },
                                { label: 'Inativo', value: 5 }
                            ]}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Calendar
                            name="data-nascimento"
                            dateFormat="dd/mm/yy"
                            label="Data de Nascimento"
                            selectionMode="range"
                            maxDate={new Date(new Date().getFullYear() - 14, 11, 31)}
                            yearRange={`${new Date().getFullYear() - 100}:${new Date().getFullYear() - 14}`}
                            containerStyle={{ gridArea: 'f2' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="tipoPagamento"
                            label="Forma de pagamento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={tiposPagamentos.map(t => ({
                                label: t.descricao,
                                value: t.id
                            }))}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Calendar
                            name="data-sindicalizacao"
                            label="Data de cadastro"
                            maxDate={new Date()}
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Select
                            name="instituicao"
                            label="Instituição"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={instituicoes.filter(insti => insti.desconto).map(instituicao => ({
                                label: `${instituicao.nome_fantasia} ${instituicao.sigla ? `(${instituicao.sigla})` : ''}`,
                                value: instituicao.id
                            }))}
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Select
                            name="cidade"
                            label="Cidade"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={cidades.map(cidade => ({
                                label: cidade,
                                value: cidade
                            }))}
                            containerStyle={{ gridArea: 'f6' }}
                        />

                        <Textbox
                            name="crm"
                            label="Matrícula"
                            containerStyle={{ gridArea: 'f7' }}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => handleSubmitPlanilha(values, resetForm)}
                                loading={isSubmittingPlanilha}
                                className="white"
                            >
                                Gerar planilha
                                <FiFileText size={20} />
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
