import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'

import {
    Calendar, Textbox, Textarea, Button, Select 
} from '../../../../../components/Form'

import Modal from '../../../../../components/Modal'

import { AtendimentoContext } from '../../../../../contexts/AtendimentoContext'

import Yup from '../../../../../services/yup'

import maskUtils from '../../../../../util/masks'

import { Container } from './styles'

const baseInitialValues = {
    atendimento: null,
    advogado: null,
    data: '',
    inicio: '',
    fim: '',
    motivo: ''
}

const validation = Yup.object({
    advogado: Yup.string().nullable().required('Selecione o advogado.'),
    data: Yup.string().required('Selecione a data do atendimento.'),
    inicio: Yup.string().required('Informe o horário de início do atendimento.'),
    fim: Yup.string().required('Informe o horário de término do atendimento.'),
    motivo: Yup.string()
})

export default function () {
    const {
        showAtendimentoExtra, setShowAtendimentoExtra, advogados, atendimentos, handleSubmitAgendamentoExtra, setAgendamentoExtraEdit, agendamentoExtraEdit 
    } = useContext(AtendimentoContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [demandas, setDemandas] = useState([])

    useEffect(() => {
        if(atendimentos) {
            setDemandas(atendimentos?.map(a => ({
                label: `#${a.id} - ${a.associado.pessoa.nome} (${a.crm})`,
                value: a.id
            })))
        }
    }, [atendimentos])

    useEffect(() => {
        if(agendamentoExtraEdit && advogados) {
            setInitialValues({
                motivo: agendamentoExtraEdit.motivo,
                atendimento: demandas.find(d => d.value === agendamentoExtraEdit.atendimento.id),
                advogado: {
                    label: agendamentoExtraEdit.atendente.nome,
                    value: agendamentoExtraEdit.atendente.id
                },
                data: new Date(`${agendamentoExtraEdit.data} 00:00:00`),
                inicio: agendamentoExtraEdit.inicio.substr(0, 5),
                fim: agendamentoExtraEdit.fim.substr(0, 5)
            })
        }
    }, [agendamentoExtraEdit, advogados]) 

    return (
        <Modal
            isOpen={showAtendimentoExtra || !!agendamentoExtraEdit}
            handleClose={() => { 
                setShowAtendimentoExtra(false) 
                setAgendamentoExtraEdit(null)
            }}
            title="Marcar atendimento extraordinário"
        >
            <Container>
                <Formik
                    onSubmit={handleSubmitAgendamentoExtra}
                    initialValues={initialValues}
                    validationSchema={validation}
                    enableReinitialize
                >
                    {({ setFieldValue }) => (
                        <Form>
                            <Select 
                                name="atendimento"
                                label={`Demanda${agendamentoExtraEdit ? '' : ' (busque por código, nome ou CRM)'}`}
                                options={demandas}
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)
                                }}
                                containerStyle={{ gridArea: 'demanda' }}
                                isDisabled={!!agendamentoExtraEdit}
                            />

                            <Select 
                                name="advogado"
                                label="Advogado"
                                options={advogados?.map(a => ({
                                    label: a.pessoa.nome,
                                    value: a.pessoa.id
                                })) || []}
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)
                                }}
                                containerStyle={{ gridArea: 'advogado' }}
                            />
                            
                            <Calendar 
                                name="data" 
                                label="Data do atendimento"
                                minDate={new Date()}
                                readOnlyInput
                                containerStyle={{ gridArea: 'data' }}
                            />

                            <Textbox 
                                name="inicio"
                                label="Início do atendimento"
                                mask={maskUtils.time}
                                containerStyle={{ gridArea: 'inicio' }}
                            />

                            <Textbox 
                                name="fim"
                                label="Término do atendimento"
                                mask={maskUtils.time}
                                containerStyle={{ gridArea: 'fim' }}
                            />

                            <Textarea 
                                name="motivo"
                                label="Motivo do atendimento"
                                containerStyle={{ gridArea: 'motivo' }}
                            />

                            <div className="button-container">
                                <Button type="submit" className="transparent">
                                    Agendar
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Modal>
    )
}
