import styled from 'styled-components'

export const Container = styled.div`
	main {
		padding: 35px 50px 35px 90px;

		.card {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 60px auto;
			min-height: 78vh;
			gap: 0 24px;

			> h1 {
				grid-column: 1/span 2;
				margin-bottom: 16px;
			}

			iframe {
				width: 100%;
				height: 600px;
				border: solid 10px;
    			border-radius: 4px;
			}

			.modelo-container {
				display: flex;
				flex-direction: column;
				gap: 12px 0;

				p {
					font-size: 18px;
					font-weight: 600;
				}
			}

			.spinner-container {
				grid-column: 1/span 2;
			}

			@media(max-width: 900px) {
				display: flex;
				flex-direction: column;
				gap: 24px;
			}
		}

		@media(max-width: 700px) {
			padding: 24px 0;
		}
	}
`

export const OrientacaoContainer = styled.div`
	> h2 {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 24px;
	}

	p {
		line-height: 24px;
	}
`

export const DownloadContainer = styled.div`
	justify-self: center;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 20px;

	button {
		display: flex;
		flex-direction: column;
		height: 110px;

		svg {
			font-size: 40px;
			margin: 0 0 8px;
		}

		&:hover {
			svg {
				margin: 0 0 8px !important;
			}
		}
	}
`
