import React, { useContext } from 'react'

import Table from '../../../../../../components/Table'
import { Select } from '../../../../../../components/Form'

import { TutorialContext } from '../../../../../../contexts/TutorialContext'

import { Container } from './styles'

export default function () {
    const {
        assuntos, setAssuntoSelecionado, tutoriais, assuntoSelecionado, handleDeleteTutorial, handlePrepareEditTutorial 
    } = useContext(TutorialContext)
    
    return (
        <Container>
            <Select 
                withoutForm
                label="Assunto"
                options={assuntos?.map(a => ({
                    label: a.descricao,
                    value: a.id
                })) || []}
                onChange={selected => { setAssuntoSelecionado(selected) }}
                value={assuntoSelecionado}
            />

            <Table 
                headers={[
                    { name: 'descricao', value: 'Nome do tópico' }
                ]}
                data={tutoriais || []}
                handlePrepareEdit={handlePrepareEditTutorial}
                handleDelete={handleDeleteTutorial}
                confirmExclusion={{
                    template: 'Tem certeza que deseja remover o tutorial <b>#attr#</b>?',
                    attr: 'descricao'
                }}
            />
        </Container>
    )
}
