import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'

import Yup from '../../../services/yup'
import { api, authHeaders } from '../../../services/api'

import masks from '../../../util/masks'

import Calendar from '../../Form/Calendar'
import ActionFooter from '../../ActionFooter'
import CreditCard from '../../CreditCard'
import {
    Button, Textbox
} from '../../Form'

import { Container } from './styles'

const { Iugu } = window

const baseInitialValues = {
    expiracao: '',
    numero: '',
    titular: '',
    cvv: ''
}

const validation = Yup.object({
    expiracao: Yup.string().required('Selecione a data de validade.').nullable(),
    numero: Yup.string().creditCard().required('Digite o número do cartão.'),
    titular: Yup.string().required('Digite o nome como consta no cartão.'),
    cvv: Yup.number().typeError('Código de segurança inválido.').required('Digite o código de segurança impresso em seu cartão.')
})

export default function ({ defaultValues, pessoaId, callback }) {
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    useEffect(() => {
        if (defaultValues) {
            setInitialValues(defaultValues.dadosPagamento)
        }
    }, [defaultValues])

    useEffect(() => {
        Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
        Iugu.setTestMode(process.env.REACT_APP_IUGU_TEST_MODE === 'true')
    }, [])

    async function handleSubmit(values) {
        const {
            expiracao, numero, titular, cvv
        } = values

        const mes = masks.maskApply.month(expiracao.getMonth() + 1)
        const ano = expiracao.getFullYear().toString()

        const posicao = titular.indexOf(' ')
        const nome = titular.slice(0, posicao)
        const sobrenome = titular.slice(posicao + 1)

        const cartaoCredito = Iugu.CreditCard(numero, mes, ano, nome, sobrenome, cvv)
        const bandeira = Iugu.utils.getBrandByCreditCardNumber(numero)

        try {
            await (new Promise((resolve, reject) => {
                Iugu.createPaymentToken(cartaoCredito, async payment => {
                    if (payment.errors) {
                        reject(payment.errors)
                    } else {
                        const token = payment.id

                        try {
                            const body = {
                                tipo_pagamento_id: 3,
                                dados_pagamento: { token, bandeira }
                            }

                            await api.put(`pagamento/${pessoaId}`, body, authHeaders())

                            callback()

                            resolve()
                        } catch (e) {
                            reject(e)
                        }
                    }
                })
            }))
        } catch (e) {
            if (e.verification_value) {
                toast.error('O código de segurança é inválido.')
            } else if (e.expiration) {
                toast.error('Este cartão está vencido.')
            } else if (e.record_invalid) {
                toast.error('Número do cartão é inválido. Verifique e tente novamente.')
            } else if (e.adblock) {
                toast.error('Por favor desabilite seu bloqueador de anúncios e recarregue a página para conseguir efetivar a transação com seu cartão de crédito!.')
            } else {
                toast.error(e.msg || 'Ocorreu um erro na troca da forma de pagamento.')

                await api.post('iugu/erro', {
                    stack: e
                }, authHeaders())
            }
        }
    }

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <CreditCard
                            name={values?.titular}
                            number={values?.numero}
                            expiration={values?.expiracao}
                            cvv={values?.cvv}
                        />

                        <Calendar
                            name="expiracao"
                            dateFormat="mm/yy"
                            yearNavigator
                            view="month"
                            label="Data de validade"
                            yearRange={`${new Date().getFullYear()}:${new Date().getFullYear() + 20}`}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Textbox
                            label="Número do cartão"
                            name="numero"
                            id="numero_cartao"
                            inputMode="numeric"
                            containerStyle={{ gridArea: 'f2' }}
                            maxLength={16}
                        />

                        <Textbox
                            label="Nome do titular"
                            name="titular"
                            style={{ textTransform: 'uppercase' }}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Textbox
                            label="CVV"
                            name="cvv"
                            inputMode="numeric"
                            maxLength={4}
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <ActionFooter style={{ gridArea: 'button' }}>
                            <Button type="submit" loading={isSubmitting} className="white">
                                Salvar alterações
                                <FaRegSave />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
