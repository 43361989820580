import React, { useContext } from 'react'
import { FaQuestionCircle } from 'react-icons/fa'

import { TipContext } from '../../../contexts/TipContext'

import { TipContainer } from '../styles'

import { CheckboxContainer, Checkbox } from './styles'

export default function ({
    label, 
    handleChange, 
    containerStyle = {}, 
    containerClass = '', 
    withoutForm = false,
    tooltip,
    ...props 
}) {
    const { tips } = useContext(TipContext)

    const tipText = tips.campos?.find(item => item.campo === props.name || item.campo === props.id)?.texto

    return (
        <CheckboxContainer hasTip={!!tipText} style={containerStyle} className={containerClass}>
            {withoutForm ? (
                <input type="checkbox" onChange={handleChange} {...props} />
            ) : (
                <Checkbox onChange={handleChange} {...props} />
            )}

            <label>{label}</label>

            {tipText ? (
                <>
                    <FaQuestionCircle size={14} />

                    <TipContainer>
                        <FaQuestionCircle size={13} />
                        <span>{tipText}</span>
                    </TipContainer>
                </>
            ) : null}

            {tooltip && <div className="tooltip-message" dangerouslySetInnerHTML={{ __html: tooltip }} /> }
        </CheckboxContainer>
    )
}
