const signedDefaultPermissions = [
    {
        link: '/dashboard'
    },
    {
        link: '/grupo_permissoes/:grupoId'
    },
    {
        link: '/perfil'
    },
    {
        link: '/not_allowed'
    },
    {
        link: '/relatorio/:grupoId'
    },
    {
        link: '/gerenciar/:entidade'
    },
    {
        link: '/evento/:id'
    },
    {
        link: '/atendimento/:id'
    },
    {
        link: '/processos/:processo_id'
    },
    {
        link: '/enquete/:id/votar'
    },
    {
        link: '/enquete/:id/feedback'
    },
    {
        link: '/enquete/:id/respostas'
    },
    {
        link: '/enquete/participacao'
    },
    {
        link: '/checkout_auth/:fatura_id'
    },
    {
        link: '/checkout_auth_iframe/:fatura_id'
    }
]

const unsignedDefaultPermissions = [
    {
        link: '/'
    },
    {
        link: '/signup'
    },
    {
        link: '/associacao'
    },
    {
        link: '/not_allowed'
    },
    {
        link: '/pass_recover'
    },
    {
        link: '/pass_recover/:token/:crm'
    },
    {
        link: '/matricula_recover'
    },
    {
        link: '/compra_cartao'
    },
    {
        link: '/evento/:id/:pessoa_id'
    },
    {
        link: '/evento/:id/painel'
    },
    {
        link: '/consulta_publica'
    },
    {
        link: '/atendimento/feedback/:token'
    },
    {
        link: '/processo/feedback/:token'
    },
    {
        link: '/documentos/:id'
    },
    {
        link: '/visita/feedback/:token'
    },
    {
        link: '/socket'
    },
    {
        link: '/fatura_paga'
    },
    {
        link: '/checkout/:fatura_id'
    },
    {
        link: '/publicacao/publico'
    }
]

export {
    unsignedDefaultPermissions,
    signedDefaultPermissions
}
