import {
    addDays, differenceInDays, format, getYear, isAfter, isBefore
} from 'date-fns'
import React, { createContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

import { fillWithZeros } from '../util/string'
import { forceDownloadFileFromURL } from '../util/file'
import formUtils from '../util/form'
import { isBetweenDateTimes } from '../util/date'

const baseInitialValues = {
    inicio: '',
    periodo: '',
    manutencao_periodica: false,
    arquivo: null,
    objeto: '',
    area: null,
    fornecedor: null,
    tipo_manutencao: null,
    periodo_manutencao: ''
}

export const ContratoContext = createContext()

export default function ContratoProvider({ children }) {
    const [contratos, setContratos] = useState(null)
    const [contratoDetalhe, setContratoDetalhe] = useState(null)
    const [modoCadastro, setModoCadastro] = useState(false)
    const [contratoEdicao, setContratoEdicao] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function loadContratos() {
        try {
            setContratos(null)

            const response = await api.get('contrato', authHeaders())

            const listaContratos = response.map(cont => {
                const proximasManutencoes = cont.manutencoes_previstas.filter(m => isAfter(new Date(m.data), new Date()))
                let proximaManutencao
                const prazoProximidadeDias = 3
                const periodoManutencoes = cont.tipos_manutencao?.[0]?.ContratoTiposManutencao.periodo
                const encerrado = isAfter(new Date(), new Date(cont.fim))

                if (!periodoManutencoes) {
                    return {
                        ...cont,
                        background: encerrado ? '#ddd' : undefined,
                        fornecedor_nome: cont.fornecedor.nome_fantasia || cont.fornecedor.pessoa.nome,
                        tipo: cont.contrato_area.descricao,
                        manutencao_periodica_string: cont.manutencao_periodica ? 'sim' : 'não',
                        arquivo_link: cont.arquivo?.link,
                        inicio_formatado: format(new Date(cont.inicio), 'dd/MM/yyyy'),
                        fim_formatado: format(new Date(cont.fim), 'dd/MM/yyyy'),
                        numero_contrato: `#${fillWithZeros(cont.id, 6)}`,
                        manutencoes: [],
                        manutencoes_previstas: [],
                        tipo_manutencao: cont.tipos_manutencao.length ? cont.tipos_manutencao[0] : null,
                        periodo_manutencao: cont.tipos_manutencao.length ? cont.tipos_manutencao[0].ContratoTiposManutencao.periodo : null,
                        manutencao_prevista: '-'
                    }
                }

                const verificarPeriodoManutencao = (m, manut) => isBetweenDateTimes(new Date(m.data), new Date(manut.data), addDays(new Date(manut.data), periodoManutencoes))

                if (proximasManutencoes.length) {
                    [proximaManutencao] = proximasManutencoes
                }

                const manutencoes = cont.manutencoes.map(manut => ({
                    ...manut,
                    profissional_nome: manut.profissional.pessoa.nome,
                    tipo_descricao: manut.tipo_manutencao.descricao,
                    data_formatada: format(new Date(manut.data), 'dd/MM/yyyy HH:mm\'h\''),
                    os: manut.os?.link,
                    os_id: manut.os?.id
                }))

                const ultimaManutencaoEfetuada = manutencoes.length ? manutencoes.first() : null

                const manutencoesPassadas = cont.manutencoes_previstas.filter(manut => getYear(new Date(manut.data)) === new Date().getFullYear() && isBefore(new Date(manut.data), new Date()))

                const manutencoesPrevistas = cont.manutencoes_previstas.filter(manut => getYear(new Date(manut.data)) === new Date().getFullYear())

                const manutencoesFuturas = cont.manutencoes_previstas.filter(manut => getYear(new Date(manut.data)) === new Date().getFullYear() && isBefore(new Date(), new Date(manut.data)))

                const manutencoes_previstas = manutencoesPrevistas.map((manut, index) => {
                    const dataManutencaoPassou = !isAfter(new Date(manut.data), new Date())
                    const eAUltimaManutencaoPrevista = index === manutencoesPrevistas.length - 1
                    const dataFimPeriodoManutencao = eAUltimaManutencaoPrevista
                        ? `${format(new Date(cont.fim), 'dd/MM/yyyy')} (fim do contrato)`
                        : format(addDays(new Date(manut.data), periodoManutencoes - 1), 'dd/MM/yyyy')

                    return {
                        ...manut,
                        tipo_descricao: cont.tipos_manutencao.find(t => t.id === manut.tipo_manutencao_id).descricao,
                        data_formatada: `a partir de ${format(new Date(manut.data), 'dd/MM/yyyy')}`,
                        periodo_manutencao: `de ${format(new Date(manut.data), 'dd/MM/yyyy')} a ${dataFimPeriodoManutencao}`,
                        periodo: cont.tipos_manutencao ? `a cada ${periodoManutencoes} dias (${differenceInDays(new Date(manut.data), new Date()) + 1} dias para a próxima)` : '',
                        background: proximaManutencao?.id === manut.id ? '#ffee58' : undefined,
                        efetuada: !dataManutencaoPassou ? 'FaMinus' : cont.manutencoes.some(m => verificarPeriodoManutencao(m, manut)) ? 'FaCheck' : 'FaTimes',
                        iconColor: !dataManutencaoPassou ? '#999' : cont.manutencoes.some(m => verificarPeriodoManutencao(m, manut)) ? '#4caf50' : '#f44336'
                    }
                })

                const temManutencaoProxima = manutencoesFuturas.some(manut => isBetweenDateTimes(new Date(manut.data), new Date(), addDays(new Date(), prazoProximidadeDias)))

                const manutencoesNaoEfetuadasAposUltimaEfetuada = ultimaManutencaoEfetuada
                    ? manutencoesPassadas.filter(manut => !manutencoes.map(m => m.id).includes(manut.id)
                        && isBefore(new Date(ultimaManutencaoEfetuada.data), new Date(manut.data)))
                    : manutencoesPassadas

                const primeiraManutencaoNaoEfetuadaAposAUltimaEfetuada = manutencoesNaoEfetuadasAposUltimaEfetuada.length ? manutencoesNaoEfetuadasAposUltimaEfetuada[0] : null

                const temManutencaoEmAtraso = manutencoesNaoEfetuadasAposUltimaEfetuada.some(m => !verificarPeriodoManutencao(m, { data: new Date() }))

                let background

                if (encerrado) {
                    background = '#ddd'
                } else if (temManutencaoEmAtraso) {
                    background = '#ffcdd2'
                } else if (temManutencaoProxima) {
                    background = '#fff59d'
                }

                return {
                    ...cont,
                    fornecedor_nome: cont.fornecedor.nome_fantasia || cont.fornecedor.pessoa.nome,
                    tipo: cont.contrato_area.descricao,
                    manutencao_periodica_string: cont.manutencao_periodica ? 'sim' : 'não',
                    arquivo_link: cont.arquivo?.link,
                    inicio_formatado: format(new Date(cont.inicio), 'dd/MM/yyyy'),
                    fim_formatado: format(new Date(cont.fim), 'dd/MM/yyyy'),
                    numero_contrato: `#${fillWithZeros(cont.id, 6)}`,
                    manutencoes,
                    manutencoes_previstas,
                    tipo_manutencao: cont.tipos_manutencao.length ? cont.tipos_manutencao[0] : null,
                    periodo_manutencao: cont.tipos_manutencao.length ? cont.tipos_manutencao[0].ContratoTiposManutencao.periodo : null,
                    manutencao_prevista: temManutencaoEmAtraso && primeiraManutencaoNaoEfetuadaAposAUltimaEfetuada ? format(new Date(primeiraManutencaoNaoEfetuadaAposAUltimaEfetuada.data), 'dd/MM/yyyy') : proximaManutencao ? format(new Date(proximaManutencao.data), 'dd/MM/yyyy') : '-',
                    background
                }
            })

            setContratos(listaContratos)

            if (contratoDetalhe) {
                setContratoDetalhe(listaContratos.find(c => c.id === contratoDetalhe.id))
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function handleDownloadContrato(contrato) {
        forceDownloadFileFromURL(contrato.arquivo_link)
    }

    function handlePrepararEdicao(contrato) {
        setContratoEdicao(contrato)

        setInitialValues({
            inicio: contrato.inicio_formatado,
            fim: contrato.fim_formatado,
            manutencao_periodica: contrato.manutencao_periodica,
            arquivo: contrato.arquivo?.id,
            objeto: contrato.objeto,
            area: {
                label: contrato.contrato_area.descricao,
                value: contrato.contrato_area.id
            },
            fornecedor: {
                label: contrato.fornecedor.nome_fantasia || contrato.fornecedor.pessoa.nome,
                value: contrato.fornecedor.id
            },
            periodo: contrato.periodo_manutencao || '',
            tipo_manutencao: contrato.tipo_manutencao ? {
                label: contrato.tipo_manutencao.descricao,
                value: contrato.tipo_manutencao.id
            } : null
        })
    }

    async function handleSubmit(values) {
        values = formUtils.extractFormValues(values)

        if (modoCadastro) {
            await api.post('contrato', values, authHeaders())

            toast.success('Contrato cadastrado com sucesso.')
        } else if (contratoEdicao) {
            await api.put(`contrato/${contratoEdicao.id}`, values, authHeaders())

            toast.success('Contrato atualizado com sucesso.')

            setInitialValues(baseInitialValues)
        }

        loadContratos()

        setModoCadastro(false)
        setContratoEdicao(null)
    }

    async function handleRemoverContrato(contrato) {
        try {
            await api.delete(`contrato/${contrato.id}`, authHeaders())

            toast.success('O contrato foi removido com sucesso.')

            loadContratos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleRenovarContrato(contrato) {
        try {
            await api.patch(`contrato/${contrato.id}`, {}, authHeaders())

            loadContratos()

            toast.success(`O contrato foi renovado por mais ${contrato.periodo_meses} meses`)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadContratos()
    }, [])

    useEffect(() => {
        setInitialValues(baseInitialValues)
    }, [modoCadastro])

    return (
        <ContratoContext.Provider
            value={{
                contratos,
                setContratoDetalhe,
                contratoDetalhe,
                contratoEdicao,
                setContratoEdicao,
                handleDownloadContrato,
                reload: loadContratos,
                setModoCadastro,
                modoCadastro,
                handleSubmit,
                initialValues,
                handlePrepararEdicao,
                handleRemoverContrato,
                handleRenovarContrato
            }}
        >
            {children}
        </ContratoContext.Provider>
    )
}
