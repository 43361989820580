import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'

import { api, authHeaders } from '../../../../../services/api'

import { Container, EventDetails } from './styles'

import { EventoContext } from '../../../../../contexts/EventoContext'
import { Checkbox } from '../../../../../components/Form'

export default function () {
    const { evento, reload } = useContext(EventoContext)

    const [pessoas, setPessoas] = useState(null)
    const [pessoasConfirmadas, setPessoasConfirmadas] = useState(null)

    useEffect(() => {
        if (evento) {
            setPessoas(evento.participantes.map(pessoa => {
                let documento = pessoa.documentos?.find(doc => doc.tipo_documento.id === 3)

                if (!documento) {
                    documento = pessoa.documentos?.find(doc => doc.tipo_documento.id === 2)
                }

                return {
                    id: pessoa.id,
                    identificador: documento?.identificador || '-',
                    nome: pessoa.nome,
                    confirmada: !!pessoa.data_hora_confirmacao_presenca,
                    confirmacao_presenca:
                        pessoa.data_hora_confirmacao_presenca
                            ? 'FaCheck'
                            : pessoa.data_hora_confirmacao_ausencia
                                ? 'FaTimes'
                                : null,
                    iconColor: pessoa.data_hora_confirmacao_presenca
                        ? '#4caf50'
                        : pessoa.data_hora_confirmacao_ausencia
                            ? '#f44336' : '#ccc'
                }
            }))
        }
    }, [evento])

    async function handlePresenca(item, marcar) {
        try {
            await api.post(`evento/${evento.id}/presenca`, {
                pessoas: [item.id],
                marcar
            }, authHeaders())

            toast.success(`${marcar ? 'Presença marcada' : 'Presença desmarcada'}.`)

            reload()
        } catch (e) {
            toast.error('Erro ao marcar a presença.')
        }
    }

    async function toggleCheckAll(isCheckedAll) {
        try {
            await api.post(`evento/${evento.id}/presenca`, {
                pessoas: pessoas.map(p => p.id),
                marcar: isCheckedAll
            }, authHeaders())

            toast.success('Todas as presenças foram registradas.')

            reload()
        } catch (e) {
            toast.error('Erro ao marcar a presença.')
        }
    }

    function handleChangeMostrarConfirmados(e) {
        if(e.target.checked) {
            setPessoasConfirmadas(pessoas.filter(p => p.confirmada))
        } else {
            setPessoasConfirmadas(null)
        }
    }

    if (!evento) {
        return null
    }

    return (
        <Container>
            <EventDetails>
                <h1>{`Evento: ${evento.name}`}</h1>
                <h2>{`Local: ${evento.nome_local}`}</h2>
                <h2>{`Início: ${evento.inicio}`}</h2>
            </EventDetails>

            <p className="instrucoes">Marque abaixo os participantes que compereceram ao evento:</p>

            <Checkbox 
                withoutForm
                label="Mostrar apenas presenças confirmadas"
                handleChange={handleChangeMostrarConfirmados}
            />

            {pessoas ? (
                <Table
                    headers={[
                        {
                            name: 'identificador', value: 'CRM/CPF', centered: true, style: { width: 160 }
                        },
                        { name: 'nome', value: 'Nome' },
                        {
                            name: 'confirmacao_presenca', value: 'Presença confirmada?', icon: true, style: { width: 190 }
                        }
                    ]}
                    data={pessoasConfirmadas || pessoas || []}
                    filterable
                    tableId="convite-table"
                    checkable
                    checkedItems={
                        evento.participantes.filter(participante => participante.presente)
                            .map(participante => participante.id)
                    }
                    onCheckItem={item => { handlePresenca(item, true) }}
                    onUncheckItem={item => { handlePresenca(item, false) }}
                    toggleCheckAll={isCheckedAll => toggleCheckAll(isCheckedAll)}
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
