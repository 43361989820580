import React, { createContext, useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { api } from '../services/api'
// import { useVoice } from '../hooks/useVoice'
import avatarAnimated from '../assets/images/animated.mp4'
import { AvatarContext } from './AvatarContext'

export const TipContext = createContext()

export function TipProvider({ children }) {
    const { handleSpeak } = useContext(AvatarContext)

    const [tips, setTips] = useState({})
    const [codigo, setCodigo] = useState('')
    // const [isVisible, setIsVisible] = useState(false)

    const history = useHistory()

    // const { speak } = useVoice()

    history.listen(() => {
        setCodigo('')
    })

    async function loadTips() {
        const response = await api.get('tips_form', {
            params: {
                identificador_form: codigo
            }
        })

        if(!response) {
            return
        }

        setTips({
            formulario: response.texto,
            campos: response.campos.map(campo => ({
                campo: campo.nome,
                texto: campo.texto,
                texto_avatar: campo.texto_avatar
            }))
        })
    }

    // function handleSpeak(speech) {
    //     if (speech) {
    //         speak(speech)

    //         setIsVisible(true)

    //         setTimeout(() => {
    //             setIsVisible(false)
    //         }, 75 * speech.length)
    //     }
    // }

    useEffect(() => {
        if(codigo) {
            loadTips()
        } else {
            setTips({})
        }
    }, [codigo])

    return (
        <TipContext.Provider 
            value={{
                setCodigo,
                tips,
                handleSpeak
            }}
        >
            {children}

            {/* {isVisible && (
                <div className="fixed bottom-3 left-3 w-24 h-24 sm:w-36 sm:h-36 animated slideInUp faster">
                    <video src={avatarAnimated} autoPlay loop className="w-full h-full rounded-full shadow-lg"></video>
                </div>
            )} */}
        </TipContext.Provider>
    )
}
