import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import {
    FaExclamationTriangle, FaFileAlt, FaTrash, FaFilePdf
} from 'react-icons/fa'
import { Form, Formik } from 'formik'
import {
    Tab, TabList, TabPanel, Tabs
} from 'react-tabs'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import { ConvenioContext } from '../../../../contexts/ConvenioContext'

import maskUtils from '../../../../util/masks'
import stringUtils from '../../../../util/string'
import { getAge, getDateString, getDate } from '../../../../util/date'
import { forceDownloadFileFromURL } from '../../../../util/file'

import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import { Button, Select } from '../../../../components/Form'
import GerenciamentoDocumentos from '../../../../components/GerenciamentoDocumentos'
import Spinner from '../../../../components/Spinner'
import SmallScreenPlaceholder from '../../../../components/SmallScreenPlaceholder'

import { api, authHeaders } from '../../../../services/api'

import {
    Container, DadosContainer, ButtonsContainer, AddDependenteContainer, ModalCancelarContainer, Badge, MiniBadge
} from './styles'
import AtualizacaoComprovanteMatricula from '../AtualizacaoComprovanteMatricula'
import { tiposPortabilidade } from '../Cadastro'

export default function ({
    detalhes, reload, refreshDetalhes, titular
}) {
    const { user } = useContext(GlobalContext)
    const { setShowFormAtualizacaoComprovanteMatricula, showFormAtualizacaoComprovanteMatricula } = useContext(ConvenioContext)

    const [showAddDependenteModal, setShowAddDependenteModal] = useState(false)
    const [dependentes, setDependentes] = useState([])
    const [parentescosPermitidos, setParentescosPermitidos] = useState([])
    const [confirmCancelarServico, setConfirmCancelarServico] = useState(false)
    const [showDependenteDocumentos, setShowDependenteDocumentos] = useState(null)
    const [showTitularDocumentos, setShowTitularDocumentos] = useState(null)
    const [showAdicionais, setShowAdicionais] = useState(false)
    const [convenio, setConvenio] = useState(null)
    const [adicionais, setAdicionais] = useState(null)
    const [loading, setLoading] = useState(false)
    const [detalhesFinanceiro, setDetalhesFinanceiro] = useState([])
    const [detalhesAdicionais, setDetalhesAdicionais] = useState([])
    const [gerandoPDF, setGerandoPDF] = useState(false)
    const [adicionandoAdicional, setAdicionandoAdicional] = useState(false)
    const [openedBeneficiarioAdicionalTabIndex, setOpenedBeneficiarioAdicionalTabIndex] = useState(0)
    const [beneficiariosAdicionais, setBeneficiariosAdicionais] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [temAlteracao, setTemAlteracao] = useState(false)
    const [valorMaioridadePlano, setValorMaioridadePlano] = useState(0)
    const [dependentesIncluidos, setDependentesIncluidos] = useState([])
    const [disableText, setDisableText] = useState('')
    const [tiposDocumento, setTiposDocumento] = useState([])

    const disableDependenteSubmit = dependentesIncluidos.length === 0

    async function loadConvenio() {
        const response = await api.get(`convenio/${detalhes.id}`, authHeaders())

        setConvenio(response)
    }

    async function loadValorMaioridadePlano() {
        const { valor } = await api.get('parametro/valor_maioridade_plano')
        setValorMaioridadePlano(valor)
    }

    async function loadAdicionais() {
        const response = await api.get('convenio_adicional', {
            params: {
                convenio_id: detalhes.id
            }
        })

        setAdicionais(response)
    }

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento')

        setTiposDocumento(response)
    }

    useEffect(() => {
        loadConvenio()

        loadAdicionais()

        loadValorMaioridadePlano()

        loadTiposDocumento()
    }, [])

    async function loadDependentes() {
        const response = await api.get('dependente', {
            params: {
                associado_id: user.associado.id
            },
            ...authHeaders()
        })

        const conveniosAssociado = await api.get('convenio_associado', {
            ...authHeaders(),
            params: {
                associado_id: user.associado.id,
                convenio_id: detalhes?.id
            }
        })

        if (conveniosAssociado.length) {
            const { dependentes: dependentesJaVinculados } = conveniosAssociado[0]

            setDependentes(response
                .filter(dep => !dependentesJaVinculados.map(d => d.dependente.id).includes(dep.id))
                .map(dep => ({
                    ...dep,
                    data_nascimento: `${format(new Date(dep.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(dep.pessoa.data_nascimento)} anos)`,
                    idade: getAge(dep.pessoa.data_nascimento)
                })))
        } else {
            toast.error('Ocorreu um erro ao carregar os dados.')
        }
    }

    async function loadParentescosPermitidos() {
        setParentescosPermitidos(convenio.parentescos.map(p => p.id))
    }

    function getValor(descontos, idade) {
        const desconto = descontos.find(desc => (!desc.idade_minima || desc.idade_minima <= idade) && (!desc.idade_maxima || idade <= desc.idade_maxima))

        const valor = desconto ? desconto.valor_com_desconto : 0.0

        return Number(valor)
    }

    async function loadFinanceiro() {
        const adicionaisIdsSemRepeticao = [...new Set(detalhes.adicionais.map(a => a.id))]

        const financeiro = [
            {
                id: new Date().getTime(),
                nome: titular?.nome,
                parentesco: 'Titular',
                idade: titular?.idade,
                valor: maskUtils.maskApply.currency(getValor(convenio.descontos, titular?.idade)),
                valorNumber: getValor(convenio.descontos, titular?.idade)
            },
            ...detalhes.dependentes.map(dep => ({
                id: `dep_${dep.id}`,
                nome: dep.nome,
                parentesco: dep.parentesco,
                idade: dep.idade,
                valor: maskUtils.maskApply.currency(getValor(convenio.descontos, dep.idade)),
                valorNumber: getValor(convenio.descontos, dep.idade)
            })),
            ...adicionaisIdsSemRepeticao.map(adcId => {
                const adc = detalhes.adicionais.find(a => a.id === adcId)
                const quantidade = detalhes.adicionais.filter(a => a.id === adc.id && a.status).length
                const total = Number(maskUtils.unmask.money(adc.valor)) * quantidade

                return {
                    id: `adc_${adc.id}`,
                    nome: `${adc.descricao} (${quantidade} beneficiário${quantidade > 1 ? 's' : ''})`,
                    parentesco: '-',
                    idade: '-',
                    valor: maskUtils.maskApply.currency(total),
                    valorNumber: total
                }
            })
        ]

        const taxaBancaria = Number(detalhes.taxa_bancaria)
        const taxaAdministrativa = Number(detalhes.taxa_administrativa)

        if (taxaBancaria) {
            financeiro.push({
                id: 'tx_1',
                nome: 'TAXA BANCÁRIA',
                parentesco: '-',
                idade: '-',
                valor: maskUtils.maskApply.currency(taxaBancaria),
                valorNumber: taxaBancaria
            })
        }

        if (taxaAdministrativa) {
            financeiro.push({
                id: 'tx_2',
                nome: 'TAXA ADMINISTRATIVA',
                parentesco: '-',
                idade: '-',
                valor: maskUtils.maskApply.currency(taxaAdministrativa),
                valorNumber: taxaAdministrativa
            })
        }

        setDetalhesFinanceiro(financeiro)
    }

    async function normalizeAdicionais() {
        setDetalhesAdicionais(detalhes.adicionais.map(t => ({
            ...t,
            id: `${t.beneficiario.id}_${t.id}`,
            nome: t.beneficiario.pessoa.nome
        })))
    }

    function temDadosAlterados() {
        const temAlteracoes = beneficiariosAdicionais.some(beneficiario => beneficiario.adicionais.some(adc => adc.status_original !== adc.status))

        setTemAlteracao(temAlteracoes)
    }

    useEffect(() => {
        if (user) {
            loadDependentes()
        }
    }, [user])

    useEffect(() => {
        if (convenio && user) {
            loadParentescosPermitidos()
        }
    }, [convenio, user])

    useEffect(() => {
        if (convenio && detalhes) {
            loadFinanceiro()

            normalizeAdicionais()
        }
    }, [convenio, detalhes])

    useEffect(() => {
        if (showAdicionais) {
            reload(false, detalhes.id)
        }
    }, [showAdicionais])

    useEffect(() => {
        if (detalhes && adicionais) {
            setDisabled([1, 2, 3, 7, 8, 9].includes(detalhes.status?.id))

            setBeneficiariosAdicionais([
                {
                    titular: true,
                    ...user.pessoa,
                    id: user.associado.id,
                    adicionais: adicionais.map(a => ({
                        ...a,
                        alterado: false,
                        status: detalhes.adicionais.filter(aa => aa.titular && aa.status).map(aa => aa.id).includes(a.id),
                        status_original: detalhes.adicionais.filter(aa => aa.titular && aa.status).map(aa => aa.id).includes(a.id)
                    }))
                },
                ...detalhes.dependentes.map(dep => ({
                    ...dep,
                    id: dep.dependente_id,
                    adicionais: adicionais.map(a => ({
                        ...a,
                        alterado: false,
                        status: detalhes.adicionais.filter(aa => aa.beneficiario.id === dep.dependente_id && aa.status).map(aa => aa.id).includes(a.id),
                        status_original: detalhes.adicionais.filter(aa => aa.beneficiario.id === dep.dependente_id && aa.status).map(aa => aa.id).includes(a.id)
                    }))
                }))
            ])
        }
    }, [detalhes, adicionais])

    async function handleDelete(id) {
        try {
            await api.delete(`convenio_associado_dependente/${id}`, authHeaders())

            toast.success('Foi enviada uma solicitação de cancelamento para o SINDMEPA. Aguarde resposta por e-mail.')

            refreshDetalhes(detalhes.id)

            loadDependentes()

            return true
        } catch (e) {
            toast.error('Ocorreu um erro ao tentar excluir o dependente.')

            return false
        }
    }

    async function handleAddDependente() {
        try {
            setLoading(true)

            const dependentesList = dependentesIncluidos.map(dep => ({
                id: dep.id,
                portabilidade: dep.portabilidade
            }))

            if (dependentesIncluidos.length) {
                await api.post('convenio_associado_dependente', {
                    associado_id: user.associado.id,
                    convenio_id: detalhes.id,
                    dependentes: dependentesList
                }, authHeaders())

                toast.success('Os dependentes foram adicionados.')
            }

            setShowAddDependenteModal(false)

            loadDependentes()

            reload(false, detalhes.id)
        } catch (e) {
            toast.error(e.msg)
        }

        setLoading(false)
    }

    async function handleCancelarServico() {
        try {
            await api.delete(`convenio_associado/${user.associado.id}/${detalhes?.id}`, authHeaders())

            setShowAddDependenteModal(false)

            reload(true)

            toast.success(`O cancelamento do serviço ${detalhes?.name} foi solicitado com sucesso.`)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function isDisableCheck(dep) {
        if (!detalhes || !convenio) {
            return
        }

        const { descontos, criterios } = convenio

        if (parentescosPermitidos.length && !parentescosPermitidos.includes(dep.grau_parentesco.id)) {
            return 'parentesco'
        }

        const sexoAceito = descontos.find(d => !d.sexo || d.sexo === dep.pessoa.sexo)
        const idadeAceita = descontos.find(d => (!d.idade_minima || d.idade_minima <= dep.idade) && (!d.idade_maxima || d.idade_maxima >= dep.idade))
        const universidadeAceita = descontos.find(d => !d.universidade || (d.universidade && dep.pessoa.documentos?.find(doc => doc.tipo_documento.id === 21)))

        if (criterios.includes('sexo') && !sexoAceito) {
            return 'sexo'
        }

        if ([3, 4, 14, 15, 16, 17].includes(dep.grau_parentesco.id) && Number(getAge(dep.pessoa.data_nascimento)) > Number(valorMaioridadePlano)) {
            return 'idade'
        }

        if (criterios.includes('idade') && !idadeAceita) {
            return 'idade'
        }

        if (criterios.includes('universidade') && !universidadeAceita) {
            return 'universidade'
        }

        const documentosNecessarios = convenio.parentescos.find(p => p.id === dep.grau_parentesco.id)?.documentacao_necessaria || []

        let documentosEmFalta = documentosNecessarios
            .filter(tipoDoc => !dep.pessoa.documentos
                .map(d => d.tipo_documento.id)
                .includes(tipoDoc.id)
                || !dep.pessoa.documentos.find(d => d.tipo_documento.id === tipoDoc.id)?.arquivo)

        let documentosExigidos = []
        let exigidosPelaPortabilidade
        let documentoPortabilidadeEmFalta

        switch (dep.portabilidade) {
            case 1:
                documentosExigidos = [36]

                exigidosPelaPortabilidade = tiposDocumento.filter(tipo => documentosExigidos.includes(tipo.id))
                documentoPortabilidadeEmFalta = documentosExigidos.filter(d => !dep.pessoa.documentos.map(doc => doc.tipo_documento.id).includes(d))

                if (documentoPortabilidadeEmFalta.length > 0) {
                    documentosEmFalta.push(...exigidosPelaPortabilidade.filter(d => documentoPortabilidadeEmFalta.includes(d.id)))
                }

                documentosEmFalta = documentosEmFalta.filter(d => ![33, 34].includes(d.id))

                break
            case 2:
                documentosExigidos = [36, 37, 38, 41]

                exigidosPelaPortabilidade = tiposDocumento.filter(tipo => documentosExigidos.includes(tipo.id))
                documentoPortabilidadeEmFalta = documentosExigidos.filter(d => !dep.pessoa.documentos.map(doc => doc.tipo_documento.id).includes(d))

                if (documentoPortabilidadeEmFalta.length > 0) {
                    documentosEmFalta.push(...exigidosPelaPortabilidade.filter(d => documentoPortabilidadeEmFalta.includes(d.id)))
                }

                documentosEmFalta = documentosEmFalta.filter(d => ![33, 34].includes(d.id))
                break
            default: break
        }

        if (documentosEmFalta.length) {
            return `documentação. Os seguintes documentos devem ser digitalizados e anexados ao sistema: <b>${documentosEmFalta.map(d => d.descricao).join(', ')}</b><br/>`
        }

        return false
    }

    async function handleReenviarSolicitacao() {
        try {
            await api.patch(`convenio_associado/reenviar/${detalhes.id}/${user.associado.id}`, {}, authHeaders())

            toast.success('Solicitação de adesão reenviada para análise. Aguarde resposta em seu e-mail.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function handleCheckAdicional(beneficiario, checkAlterado, status) {
        const id = beneficiario.dependente_id || beneficiario.id

        const dependenteIndex = detalhes.dependentes.map(d => d.dependente_id).indexOf(id)

        if (dependenteIndex === -1) { // É o titular
            const statusAtuaisTitular = beneficiariosAdicionais[0].adicionais.filter(aa => aa.id !== checkAlterado?.id)

            setBeneficiariosAdicionais([
                {
                    titular: true,
                    ...beneficiario,
                    id,
                    adicionais: adicionais.map(a => {
                        const statusAtual = !checkAlterado ? status : checkAlterado?.id === a.id ? status : statusAtuaisTitular.find(s => s.id === a.id).status
                        const statusOriginal = beneficiariosAdicionais[0].adicionais.find(aa => aa.id === a.id)?.status_original || false

                        return {
                            ...a,
                            status: statusAtual,
                            alterado: statusAtual !== statusOriginal,
                            status_original: statusOriginal
                        }
                    })
                },
                ...beneficiariosAdicionais.filter(a => !a.titular)
            ])

            return
        }

        const posicao = dependenteIndex + 1

        const statusAtuais = beneficiariosAdicionais[posicao].adicionais.filter(aa => aa.id !== checkAlterado?.id)

        setBeneficiariosAdicionais([
            ...beneficiariosAdicionais.slice(0, posicao),
            {
                ...beneficiariosAdicionais.find(d => d.id === id),
                adicionais: adicionais.map(a => {
                    const statusAtual = !checkAlterado ? status
                        : checkAlterado?.id === a.id ? status : statusAtuais.find(s => s.id === a.id).status
                    const statusOriginal = beneficiariosAdicionais[posicao].adicionais.find(aa => aa.id === a.id).status_original

                    return {
                        ...a,
                        status: statusAtual,
                        alterado: statusAtual !== statusOriginal,
                        status_original: statusOriginal
                    }
                })
            },
            ...beneficiariosAdicionais.slice(posicao + 1)
        ])
    }

    async function handleSubmit() {
        try {
            setAdicionandoAdicional(true)

            const body = {
                convenio_id: convenio.id,
                associado_id: user.associado.id,
                beneficiarios: beneficiariosAdicionais.map(b => ({
                    id: b.id,
                    titular: b.titular,
                    adicionais: b.adicionais || []
                }))
            }

            await api.post('convenio_associado/incluir_adicionais', body, authHeaders())

            toast.success('Os serviços opcionais foram atualizados.')

            setShowAdicionais(false)

            reload(false, detalhes.id)
        } catch (e) {
            toast.error('Ocorreu um erro ao tentar gerenciar os serviços adicionais.')
        }

        setAdicionandoAdicional(false)
    }

    async function handleUpdateDependenteDocumentos(values) {
        const dependente = await api.get(`dependente/${showDependenteDocumentos.dependente_id}`, authHeaders())

        try {
            await api.put(`documentos/${dependente.pessoa.id}`, {
                documentos: values.documentos
                    .map(doc => ({
                        arquivo: doc.arquivo,
                        identificador: doc.identificador,
                        extra: doc.validade ? {
                            validade: getDateString(getDate(doc.validade))
                        } : null,
                        tipo_documento: doc.tipo_documento.value
                    }))
            }, authHeaders())

            toast.success('Documentos atualizados.')

            setShowDependenteDocumentos(null)

            loadDependentes()

            reload(false, detalhes.id)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleUpdateTitularDocumentos(values) {
        try {
            await api.put(`documentos/${showTitularDocumentos.id}`, {
                documentos: values.documentos
                    .map(doc => ({
                        arquivo: doc.arquivo,
                        identificador: doc.identificador,
                        extra: doc.validade ? {
                            validade: getDateString(getDate(doc.validade))
                        } : null,
                        tipo_documento: doc.tipo_documento.value
                    }))
            }, authHeaders())

            toast.success('Documentos atualizados.')

            setShowTitularDocumentos(null)

            reload(false, detalhes.id)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleGerarPDF() {
        setGerandoPDF(true)

        try {
            const response = await api.get(`/convenio_associado/gerar_pdf_adesao/${detalhes.id}/${user.associado.id}`, authHeaders())

            await new Promise(resolve => { setTimeout(() => resolve(), 3000) }).then(() => { })

            forceDownloadFileFromURL(response.link)
        } catch (e) {
            toast.error(e.msg)
        }

        setGerandoPDF(false)
    }

    async function handleIncluirDependente(values, { resetForm }) {
        setDisableText('')

        const dependente = dependentes.find(dep => dep.id === values.dependente.value)
        dependente.portabilidade = values.portabilidade.value

        const checkDisableText = isDisableCheck(dependente)

        if (checkDisableText) {
            setDisableText(`Este dependente não atende ao critério ${checkDisableText}.`)

            return
        }

        dependente.portabilidade = values.portabilidade.value

        setDependentesIncluidos(old => [...old, dependente])

        resetForm()
    }

    async function handleDeleteListaDependentesIncluidos(id) {
        console.log(id)
        setDependentesIncluidos(dependentesIncluidos.filter(dep => dep.id !== id))
    }

    useEffect(() => {
        if (beneficiariosAdicionais) {
            temDadosAlterados()
        }
    }, [beneficiariosAdicionais])

    if (!detalhes) {
        return <Spinner />
    }

    return (
        <>
            <Container>
                <DadosContainer>
                    <div>
                        <b>{detalhes?.name}</b>
                    </div>

                    <div>
                        <b>Área de atuação: </b>
                        {detalhes?.area_atuacao}
                    </div>

                    <div className="datas-container">
                        <div>
                            <b>Data da adesão: </b>
                            {detalhes?.data_adesao || ''}
                        </div>

                        <div>
                            <b>Início da vigência: </b>
                            {detalhes?.inicio_vigencia || <i>Não definida</i>}
                        </div>
                    </div>

                    {detalhes?.status_periodo_renovacao_matricula_academico && detalhes?.validade && (
                        <Badge style={{ gridColumn: '1/span 2', width: '100%' }}>
                            <FaExclamationTriangle />

                            <div dangerouslySetInnerHTML={{ __html: `Você tem até o dia <b>${format(new Date(detalhes.validade), 'dd/MM/yyyy')}</b> para renovar seu comprovante de matrícula no sistema. Clique no botão abaixo para atualizar agora mesmo.` }} />

                            <Button
                                className="transparent dark"
                                onClick={() => { setShowFormAtualizacaoComprovanteMatricula(true) }}
                            >
                                Atualizar agora
                            </Button>
                        </Badge>
                    )}

                    <div className="detalhes">
                        <div dangerouslySetInnerHTML={{ __html: detalhes?.descricao?.replace(/\n/g, '<br />') }} />
                    </div>
                </DadosContainer>

                {detalhes?.status && (
                    <ButtonsContainer>
                        <Button
                            className="red"
                            onClick={() => { setConfirmCancelarServico(true) }}
                            disabled={detalhes.status.id === 9 || detalhes.contribuicoes_pendentes_quantidade > 0}
                        >
                            {detalhes.status.id === 9 ? 'Você solicitou o cancelamento' : 'Cancelar serviço'}
                        </Button>
                    </ButtonsContainer>
                )}

                {detalhes?.contribuicoes_pendentes_quantidade > 0 && (
                    <MiniBadge>
                        <div dangerouslySetInnerHTML={{ __html: `Não é possível solicitar o cancelamento do plano de saúde enquanto houverem pagamentos pendentes. <b class="large">Você tem ${detalhes.contribuicoes_pendentes_quantidade} mensalidade${detalhes.contribuicoes_pendentes_quantidade > 1 ? 's' : ''} pendente${detalhes.contribuicoes_pendentes_quantidade > 1 ? 's' : ''}</b>.` }} />
                    </MiniBadge>
                )}

                {detalhes?.justificativa && (
                    <Badge lines={4}>
                        <FaExclamationTriangle />

                        <span><b>{detalhes?.justificativa}</b></span>

                        <span>
                            {'Caso precise alterar alguma documentação anexada, utilize o ícone '}
                            <FaFileAlt size={16} />
                            {' nas tabelas abaixo.'}
                        </span>

                        <span>Quando você resolver as pendências acima, clique no botão Reenviar.</span>

                        <Button onClick={handleReenviarSolicitacao} className="transparent dark">
                            Reenviar
                        </Button>
                    </Badge>
                )}

                {detalhes?.portabilidade && (
                    <>
                        <p className="table-title">Portabilidade selecionada</p>
                        <p><b>{detalhes.portabilidade}</b></p>
                    </>
                )}

                <p className="table-title">Dados do titular</p>

                <Table
                    headers={[
                        { name: 'nome', value: 'Nome' },
                        { name: 'parentesco', value: 'Parentesco', centered: true },
                        { name: 'data_nascimento', value: 'Data de nascimento / Idade', centered: true },
                        { name: 'sexo', value: 'Sexo', centered: true }
                    ]}
                    data={[
                        {
                            id: titular?.id,
                            nome: titular?.nome,
                            parentesco: 'Titular',
                            data_nascimento: titular?.data_nascimento,
                            sexo: titular?.sexo,
                            documentos: titular?.documentos
                        }
                    ]}
                    emptyLabel="Nenhum dependente incluído"
                    actions={[
                        {
                            action: setShowTitularDocumentos,
                            name: '',
                            icon: FaFileAlt,
                            iconSize: 16
                        }
                    ]}
                    hideOffset={700}
                />

                <div className="table-title">
                    <span>Dependentes incluídos</span>
                </div>

                <Table
                    headers={[
                        { name: 'nome', value: 'Nome' },
                        { name: 'parentesco', value: 'Parentesco', centered: true },
                        { name: 'data_nascimento', value: 'Data de nascimento / Idade', centered: true },
                        { name: 'sexo', value: 'Sexo', centered: true },
                        { name: 'status_descricao', value: 'Status', centered: true }
                    ]}
                    data={detalhes?.dependentes}
                    actions={[
                        {
                            action: setShowDependenteDocumentos,
                            name: '',
                            icon: FaFileAlt,
                            iconSize: 16,
                            checkDisabled: item => item.status.id === 3
                        },
                        {
                            action: dependente => handleDelete(dependente.id),
                            name: 'Cancelar',
                            icon: FaTrash,
                            iconSize: 14,
                            checkDisabled: item => item.status.id !== 2 || disabled,
                            confirmation: 'Tem certeza que deseja remover este dependente do plano de saúde?',
                            badge: 'A exclusão de qualquer dependente deve ser realizada até o dia 10 de cada mês, para que a mesma seja atendida no mês subsequente.'
                        }
                    ]}
                    hideOffset={700}
                    emptyLabel="Nenhum dependente incluído"
                />

                <div className="table-title">
                    <span>Dependentes não incluídos</span>

                    {detalhes?.status?.id !== 4 && (
                        <>
                            <Button
                                className="transparent"
                                onClick={() => { setShowAddDependenteModal(true) }}
                                disabled={disabled}
                            >
                                Adicionar dependentes
                            </Button>

                            {disabled && (
                                <MiniBadge>Não é possível adicionar dependentes enquanto a solicitação anterior não for regularizada pelo SINDMEPA.</MiniBadge>
                            )}
                        </>
                    )}
                </div>

                <Table
                    headers={[
                        { name: 'nome', value: 'Nome' },
                        { name: 'parentesco', value: 'Parentesco', centered: true },
                        { name: 'data_nascimento', value: 'Data de nascimento / Idade', centered: true },
                        { name: 'sexo', value: 'Sexo', centered: true }
                    ]}
                    data={dependentes.map(dependente => ({
                        id: dependente.id,
                        nome: dependente.pessoa.nome,
                        sexo: dependente.pessoa.sexo,
                        parentesco: dependente.grau_parentesco.descricao,
                        data_nascimento: dependente.data_nascimento,
                        documentos: dependente.pessoa.documentos,
                        dependente_id: dependente.id
                    }))}
                    actions={[
                        {
                            action: setShowDependenteDocumentos,
                            name: '',
                            icon: FaFileAlt,
                            iconSize: 16
                        }
                    ]}
                    hideOffset={700}
                    emptyLabel="Nenhum dependente incluído"
                />

                <div className="table-title">
                    <span>Serviços opcionais contratados</span>

                    {detalhes?.status?.id !== 4 && (
                        <>
                            <Button className="transparent" onClick={() => { setShowAdicionais(true) }} disabled={disabled}>
                                Gerenciar serviços opcionais
                            </Button>

                            {disabled && (
                                <MiniBadge>Não é possível adicionar serviços enquanto a solicitação anterior não for regularizada pelo SINDMEPA.</MiniBadge>
                            )}
                        </>
                    )}
                </div>

                <Table
                    headers={[
                        { name: 'descricao', value: 'Serviço' },
                        { name: 'nome', value: 'Beneficiário' },
                        { name: 'valor', value: 'Valor por beneficiário', centered: true }
                    ]}
                    data={detalhesAdicionais}
                    emptyLabel="Nenhum serviço adicional incluído"
                    hideOffset={400}
                    hideOnSmallHeaders={['valor']}
                    hideHeadersOffset={700}
                />

                <div className="table-title">
                    <span>Detalhes Financeiros</span>
                </div>

                <Table
                    headers={[
                        { name: 'nome', value: 'Nome' },
                        { name: 'parentesco', value: 'Parentesco', centered: true },
                        { name: 'idade', value: 'idade', centered: true },
                        { name: 'valor', value: 'Valor', centered: true }
                    ]}
                    data={detalhesFinanceiro}
                    footers={[
                        {
                            text: 'TOTAL',
                            colspan: 3
                        },
                        {
                            text: maskUtils.maskApply.currency(detalhesFinanceiro.reduce((sum, current) => sum + current.valorNumber, 0)),
                            align: 'center'
                        }
                    ]}
                    emptyLabel="Nenhum serviço adicional incluído"
                    hideOffset={700}
                />

                <div className="table-title">
                    <Button onClick={handleGerarPDF} className="transparent" loading={gerandoPDF}>
                        Gerar resumo da solicitação
                        <FaFilePdf size={20} />
                    </Button>
                </div>
            </Container>

            <Modal
                title="Adicionar dependentes"
                handleClose={() => {
                    setShowAddDependenteModal(false)
                    setDisableText('')
                }}
                isOpen={showAddDependenteModal}
            >
                <AddDependenteContainer>
                    {!dependentes.length ? (
                        <div className="aviso-nenhum-dependente">
                            <p className="empty-placeholder">Não há nenhum dependente disponível em seu cadastro.</p>

                            <p>
                                {'Acesse o cadastro de '}
                                <Link to="/dependente/gerenciar">DEPENDENTES</Link>
                                {' para incluir seus dependentes.'}
                            </p>
                        </div>
                    ) : (
                        <>
                            <p>Selecione os dependentes que deseja incluir no serviço contratado.</p>

                            <Formik
                                initialValues={{
                                    dependente: null,
                                    portabilidade: null
                                }}
                                onSubmit={handleIncluirDependente}
                            >
                                {({ setFieldValue, values }) => (
                                    <Form className="dependente-form">
                                        <Select
                                            name="dependente"
                                            label="Dependente"
                                            options={dependentes
                                                .filter(dep => !dependentesIncluidos.map(d => d.id).includes(dep.id))
                                                .map(dep => ({
                                                    value: dep.id,
                                                    label: `${dep.pessoa.nome} (${dep.grau_parentesco.descricao})`
                                                }
                                                ))}
                                            onChange={(selected, meta) => {
                                                setFieldValue(meta.name, selected)
                                            }}
                                        />

                                        <Select
                                            name="portabilidade"
                                            label="Informe se deseja ou não fazer a portabilidade do plano"
                                            options={tiposPortabilidade}
                                            onChange={(selected, meta) => {
                                                setFieldValue(meta.name, selected)
                                            }}
                                        />

                                        <Button className="transparent" type="submit" disabled={!values.portabilidade || !values.dependente}>
                                            Incluir
                                        </Button>

                                        {disableText && (
                                            <Badge style={{ gridColumn: '1/span 3' }} className="animated flash">
                                                <FaExclamationTriangle />
                                                <div dangerouslySetInnerHTML={{ __html: disableText }} />
                                                <p>
                                                    Retorne a tela anterior e adicione os documentos do seu dependente clicando no ícone
                                                    <FaFileAlt />
                                                </p>
                                            </Badge>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}

                    <Table
                        tableId="dependentes-convenio"
                        caption="Dependentes a incluir"
                        headers={[
                            { name: 'nome', value: 'Nome' },
                            { name: 'parentesco', value: 'Parentesco', centered: true },
                            { name: 'data_nascimento', value: 'Data de nascimento / idade', centered: true },
                            { name: 'portabilidade', value: 'Portabilidade', centered: true }
                        ]}
                        data={dependentesIncluidos.map(dep => ({
                            id: dep.id,
                            nome: dep.pessoa.nome,
                            parentesco: dep.grau_parentesco.descricao,
                            data_nascimento: dep.data_nascimento,
                            portabilidade: tiposPortabilidade.find(tipo => tipo.value === dep.portabilidade).label
                        }))}
                        actions={[
                            {
                                action: dependente => handleDeleteListaDependentesIncluidos(dependente.id),
                                name: 'Cancelar',
                                icon: FaTrash,
                                iconSize: 14,
                                confirmation: 'Tem certeza que deseja remover este dependente?'
                            }
                        ]}
                        hideOnSmallHeaders={['parentesco', 'data_nascimento']}
                        hideHeadersOffset={700}
                        hideOffset={300}
                        emptyLabel="Nenhum dependente incluído"
                    />

                    <Badge lines={1}>
                        <FaExclamationTriangle />

                        <div>
                            <div dangerouslySetInnerHTML={{ __html: 'A inclusão de dependentes deve ser realizada <b>até o dia 10 de cada mês</b>, para que a mesma seja atendida no <b>mês subsequente</b>.' }} style={{ marginBottom: 16 }} />

                            <div dangerouslySetInnerHTML={{ __html: 'No caso de dependente <b>recém-nascido</b>, a inclusão deve ser feita em <b>até 30 dias</b>, a contar da <b>data de nascimento</b>, para que este dependente <b>não cumpra carência</b>.' }} style={{ marginBottom: 16 }} />

                            <div dangerouslySetInnerHTML={{ __html: 'A inclusão ou exclusão de dependentes estará sujeita à análise e aprovação do SINDMEPA. Ao solicitar qualquer alteração em seu plano de saúde, certifique-se de que tudo está correto, pois só será possível realizar novas alterações após a análise do sindicato.' }} />
                        </div>
                    </Badge>

                    <Button
                        className="white"
                        onClick={handleAddDependente}
                        disabled={disableDependenteSubmit}
                        loading={loading}
                    >
                        Incluir dependentes
                    </Button>
                </AddDependenteContainer>
            </Modal>

            <Modal
                isOpen={confirmCancelarServico}
                title="Cancelamento de serviço"
                handleClose={() => {
                    setConfirmCancelarServico(false)
                }}
            >
                <ModalCancelarContainer>
                    <div dangerouslySetInnerHTML={{ __html: `Deseja realmente cancelar o serviço <b>${detalhes?.name}</b>?` }} />

                    <Badge>
                        <FaExclamationTriangle />
                        <span>O cancelamento ou ativação de qualquer solicitação deve ser realizada até o dia 10 de cada mês, para que a mesma seja atendida no mês subsequente.</span>
                    </Badge>

                    <div className="button-container">
                        <Button
                            onClick={() => { setConfirmCancelarServico(false) }}
                            className="transparent"
                        >
                            Não
                        </Button>

                        <Button
                            onClick={() => {
                                handleCancelarServico(detalhes?.id, true)

                                setConfirmCancelarServico(false)
                            }}
                            className="white"
                        >
                            Sim
                        </Button>
                    </div>
                </ModalCancelarContainer>
            </Modal>

            <Modal
                isOpen={!!showDependenteDocumentos}
                handleClose={() => { setShowDependenteDocumentos(null) }}
                title={`Documentos de ${showDependenteDocumentos?.nome}`}
            >
                <GerenciamentoDocumentos
                    handleSubmit={handleUpdateDependenteDocumentos}
                    documentos={showDependenteDocumentos?.documentos.map(doc => ({
                        id: doc.id,
                        arquivo: doc.arquivo,
                        tipo_documento: doc.tipo_documento,
                        extra: doc.extra,
                        identificador: doc.identificador
                    }))}
                    categoria="dependente"
                />
            </Modal>

            <Modal
                isOpen={!!showTitularDocumentos}
                handleClose={() => { setShowTitularDocumentos(null) }}
                title={`Documentos de ${showTitularDocumentos?.nome}`}
            >
                <GerenciamentoDocumentos
                    handleSubmit={handleUpdateTitularDocumentos}
                    documentos={showTitularDocumentos?.documentos.map(doc => ({
                        id: doc.id,
                        arquivo: doc.arquivo,
                        tipo_documento: doc.tipo_documento,
                        extra: doc.extra,
                        identificador: doc.identificador
                    }))}
                    categoria={titular.associado.medico ? 'medico' : 'academico'}
                />
            </Modal>

            <Modal
                title="Serviços opcionais"
                handleClose={() => {
                    setShowAdicionais(false)
                }}
                isOpen={showAdicionais}
            >
                <AddDependenteContainer>
                    <p>Marque abaixo os serviços opcionais que deseja incluir.</p>

                    <SmallScreenPlaceholder hideOffset={500} />

                    <Tabs
                        selectedIndex={openedBeneficiarioAdicionalTabIndex}
                        onSelect={indiceTab => setOpenedBeneficiarioAdicionalTabIndex(indiceTab)}
                    >
                        <TabList>
                            {/* Titular */}
                            <Tab>{stringUtils.reduceName(user.pessoa.nome)}</Tab>

                            {detalhes.dependentes.map(dep => (
                                <Tab key={dep.dependente_id}>
                                    {stringUtils.reduceName(dep.nome)}
                                </Tab>
                            ))}
                        </TabList>

                        {/* Titular */}
                        <TabPanel>
                            <Table
                                tableId={`convenio-adicionais-${user.associado.id}`}
                                headers={[
                                    { name: 'descricao', value: 'Serviço opcional' },
                                    { name: 'valor', value: 'Valor', centered: true }
                                ]}
                                data={adicionais?.map(a => ({
                                    id: a.id,
                                    descricao: a.descricao,
                                    valor: maskUtils.maskApply.currency(a.valor)
                                })) || []}
                                hideOnSmallHeaders={['valor']}
                                hideHeadersOffset={650}
                                hideOffset={500}
                                checkable
                                checkedItems={beneficiariosAdicionais
                                    ?.find(a => a.titular)
                                    ?.adicionais
                                    .filter(a => a.status)
                                    .map(a => a.id) || []}
                                toggleCheckAll={status => { handleCheckAdicional(user.associado, null, status) }}
                                onCheckItem={(check, status) => { handleCheckAdicional(user.associado, check, status) }}
                                onUncheckItem={(check, status) => { handleCheckAdicional(user.associado, check, status) }}
                            />
                        </TabPanel>

                        {detalhes?.dependentes && detalhes.dependentes.map(dep => (
                            <TabPanel key={dep.dependente_id}>
                                <Table
                                    tableId={`convenio-adicionais-${dep.dependente_id}`}
                                    headers={[
                                        { name: 'descricao', value: 'Serviço opcional' },
                                        { name: 'valor', value: 'Valor', centered: true }
                                    ]}
                                    data={adicionais?.map(a => ({
                                        id: a.id,
                                        descricao: a.descricao,
                                        valor: maskUtils.maskApply.currency(a.valor)
                                    })) || []}
                                    hideOnSmallHeaders={['valor']}
                                    hideHeadersOffset={650}
                                    hideOffset={500}
                                    checkable
                                    checkedItems={beneficiariosAdicionais
                                        ?.filter(a => !a.titular)
                                        .find(a => a.id === dep.dependente_id)
                                        ?.adicionais
                                        .filter(a => a.status)
                                        .map(a => a.id) || []}
                                    toggleCheckAll={status => { handleCheckAdicional(dep, null, status) }}
                                    onCheckItem={(check, status) => { handleCheckAdicional(dep, check, status) }}
                                    onUncheckItem={(check, status) => { handleCheckAdicional(dep, check, status) }}
                                />
                            </TabPanel>
                        ))}
                    </Tabs>

                    <Badge lines={2}>
                        <FaExclamationTriangle />

                        <span>
                            Alterações na lista de serviços opcionais devem ser realizadas até o dia 10 de cada mês, para que as mesmas sejam atendidas no mês subsequente.
                        </span>

                        <span>
                            A inclusão ou exclusão de serviços opcionais estará sujeita à análise e aprovação do SINDMEPA. Ao solicitar qualquer alteração em seu plano de saúde, certifique-se de que tudo está correto, pois só será possível realizar novas alterações após a análise do sindicato.
                        </span>
                    </Badge>

                    <Button
                        className="white"
                        onClick={handleSubmit}
                        loading={adicionandoAdicional}
                        disabled={!temAlteracao}
                    >
                        Atualizar serviços opcionais
                    </Button>
                </AddDependenteContainer>
            </Modal>

            <Modal
                isOpen={showFormAtualizacaoComprovanteMatricula}
                handleClose={() => { setShowFormAtualizacaoComprovanteMatricula(false) }}
                title="Atualização do Comprovante de Matrícula"
            >
                <AtualizacaoComprovanteMatricula reload={() => { refreshDetalhes(detalhes?.id) }} />
            </Modal>
        </>
    )
}
