import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

import { Container } from './styles'

import FormEndereco from '../../../../../../components/FormEndereco'

export default function ({ callback, dadosFornecedor }) {
    function handleSubmit(values) {
        values.uf = values.uf.value
        values.cidade = values.cidade.value
        
        callback({ endereco: values })
    }

    return (
        <Container>
            <FormEndereco 
                onSubmit={handleSubmit} 
                defaultValues={dadosFornecedor.endereco}
                buttonContent={(
                    <>
                        Continuar
                        <FaArrowRight />
                    </>
                )}
                required={false}
            />
        </Container>
    )
}
