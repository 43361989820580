import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			'f1 f2 f3'
			'button button button';	

		.action-footer {
			grid-area: button;
		}

		@media(max-width: 800px) {
			display: flex;
			flex-direction: column;
		}
	}
`
