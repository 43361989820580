import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			> h1 {
				margin-bottom: 24px;
			}
		}

		@media(max-width: 900px) {
			padding: 35px 40px;
		}

		@media(max-width: 440px) {
			padding: 8px 0;
		}
	}
`
