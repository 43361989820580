import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'search-form'
})`
    display: flex;
    gap: 0 30px;
    flex-wrap: wrap;

    form {
        display: flex;
        width: 100%;
        max-width: 400px;
        align-items: center;

        .field {
            width: 100%;
            margin: 8px 0;
        }

        button {
            margin-top: 26px;
        }
    }
`

export const ResultadoContainer = styled.div`
    margin: 24px 0;
`
