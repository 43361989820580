import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		@media(max-width: 550px) {
			padding: 35px 10px;
		}

		@media(max-width: 400px) {
			padding: 35px 0;
		}
	}
`
