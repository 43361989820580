import React, { createContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import formUtils from '../util/form'
import maskUtils from '../util/masks'

import { api, authHeaders } from '../services/api'

export const InconsistenciaContext = createContext()

const baseInitialValues = {
    crm: '',
    nome: '',
    competencia: '',
    data_consolidacao: '',
    valor: '',
    status: null,
    tipo_pagamento: '',
    instituicao: ''
}

const InconsistenciaProvider = ({ children }) => {
    const [inconsistencias, setInconsistencias] = useState(null)
    const [tiposPagamento, setTiposPagamento] = useState([])
    const [instituicoes, setInstituicoes] = useState([])
    const [editId, setEditId] = useState(null)
    const [initialValues, setinitialValues] = useState(baseInitialValues)

    async function loadInconsistencias() {
        const response = await api.get('inconsistencia', authHeaders())

        setInconsistencias(response)
    }

    async function loadInstituicoes() {
        const response = await api.get('instituicao')

        setInstituicoes(response.filter(instituicao => ![17782, 17783].includes(instituicao.id)))
    }

    async function loadTiposPagamento() {
        const response = await api.get('tipo_pagamento')

        setTiposPagamento(response)
    }

    useEffect(() => {
        loadInconsistencias()

        loadInstituicoes()

        loadTiposPagamento()
    }, [])

    async function handlePrepareEdit(item) {
        setEditId(item.id)

        const response = await api.get(`inconsistencia/${item.id}`, authHeaders())

        const {
            identificador, associado, competencia, data_consolidacao, valor, status, tipo_pagamento, instituicao
        } = response

        setinitialValues({
            crm: identificador,
            nome: associado.pessoa.nome,
            competencia: new Date(competencia),
            data_consolidacao: new Date(data_consolidacao),
            valor: maskUtils.maskApply.currency(valor),
            status: status ? { label: 'Efetuados', value: 1 } : { label: 'Pendentes', value: 0 },
            tipo_pagamento: {
                label: tipo_pagamento.descricao,
                value: tipo_pagamento.id
            },
            instituicao: {
                label: instituicao.sigla,
                value: instituicao.id
            }
        })
    }

    async function handleEdit(values) {
        try {
            values = formUtils.extractFormValues(values)

            const body = {
                ...values,
                tipo_pagamento: values.tipo_pagamento || null,
                instituicao: values.instituicao || null,
                data_consolidacao: values.data_consolidacao || null
            }

            await api.post('contribuicao', body, authHeaders())

            await api.delete(`inconsistencia/${editId}`, authHeaders())

            setinitialValues(baseInitialValues)

            setEditId(null)

            toast.success('Inconsistência resolvida!')

            loadInconsistencias()
        } catch (e) {
            toast.error('Erro ao alterar a contribuição!')
        }
    }

    async function handleDelete(id) {
        try {
            await api.delete(`inconsistencia/${id}`, authHeaders())

            toast.success('Inconsistência removida.')

            loadInconsistencias()

            return true
        } catch (e) {
            return false
        }
    }

    return (
        <InconsistenciaContext.Provider value={{
            inconsistencias,
            loadInconsistencias,
            editId,
            setEditId,
            handlePrepareEdit,
            handleDelete,
            handleEdit,
            initialValues,
            tiposPagamento,
            instituicoes
        }}
        >
            {children}
        </InconsistenciaContext.Provider>
    )
}

export default InconsistenciaProvider
