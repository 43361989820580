import React from 'react'

import VisitaProvider from '../../../contexts/VisitaContext'

import Cadastro from './Cadastro'
import Listagem from './Listagem'

import { Container } from './styles'

export default function () {
    return (
        <VisitaProvider>
            <Container>
                <h1>Controle de visitas e contatos</h1>

                <Cadastro />

                <Listagem />			
            </Container>
        </VisitaProvider>
    )
}
