import styled from 'styled-components'

export const Container = styled.div`
	.table-container {
		width: calc(100% - 16px);
		margin: 24px 8px 16px;
	}

	form {
		display: grid;
		grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
		grid-template-rows: 1fr 80px;

		.file {
			grid-row: 1/span 2;
			grid-column: 2;
		}

		.textbox {
			grid-column: 3;
		}

		.button-container {
			grid-column: 3;
			display: flex;
			justify-content: flex-end;

			button {
				min-width: 200px;
			}
		}

		@media(max-width: 1100px) {
			grid-template-columns: 1fr 1fr;

			.file {
				grid-column: 1;
			}

			.textbox {
				grid-column: 2;
			}

			.button-container {
				grid-column: 2;
			}
		}

		@media(max-width: 850px) {
			display: flex;
			flex-direction: column;
		}
	}
`
