import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		h2 {
			font-size: 18px;
			font-weight: 600;
			margin: 28px 8px 16px;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.select-container {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}

			button {
				width: fit-content;
			}

			.button-container {
				align-self: end;
				margin: 0 8px;
				margin-top: 16px;
			}
		}
	}
`

export const FieldRow = styled.div`
	display: grid;
	grid-template-columns: 0.8fr 2.2fr 2fr;
	align-items: start;
	gap: 8px;
	margin: 0 8px;

	> span {
		margin-top: 32px;
	}
`
