import React, { useContext, useEffect, useState } from 'react'
import { lastDayOfMonth, format } from 'date-fns'

import { api, authHeaders } from '../../../../services/api'

import BarChart from '../../../../components/Chart/Bar'
import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import { Calendar, Select } from '../../../../components/Form'
import SmallScreenPlaceholder from '../../../../components/SmallScreenPlaceholder'

import { Container, FilterContainer, SomatoriasContainer } from './styles'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

export default function () {
    const { setCodigo } = useContext(TipContext)

    const [data, setData] = useState(null)
    const [date, setDate] = useState(new Date())
    const [canalSelecionado, setCanalSelecionado] = useState({
        label: 'E-mail',
        value: 'email'
    })
    const [somatorias, setSomatorias] = useState(null)

    async function loadData() {
        setData(null)
        setSomatorias(null)
        
        const response = await api.get('mensageria/logs', {
            ...authHeaders(),
            params: {
                start: format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
                end: format(lastDayOfMonth(date), 'yyyy-MM-dd'),
                channel: canalSelecionado.value
            }
        })

        const dados = response.reduce((result, current) => {
            const resultant = {
                ...result,
                [current.date.substr(0, 5)]: null
            }

            switch(canalSelecionado.value) {
                case 'email': 
                    resultant[current.date.substr(0, 5)] = {
                        count: {
                            value: current.count,
                            label: 'Enviados'
                        },
                        openeds: {
                            value: current.openeds,
                            label: 'Visualizados'
                        }
                    }
                    break
                
                case 'whatsapp':
                    resultant[current.date.substr(0, 5)] = {
                        sents: {
                            value: current.sents,
                            label: 'Enviados'
                        },
                        delivereds: {
                            value: current.delivereds,
                            label: 'Entregues'
                        },
                        openeds: {
                            value: current.openeds,
                            label: 'Visualizados'
                        }
                    }
                    break
                
                default: break
            }

            return resultant
        }, {})

        const somatoriasIndicativos = response.reduce((result, current) => ({
            delivereds: result.delivereds + current.delivereds,
            openeds: result.openeds + current.openeds,
            count: result.count + current.count,
            sents: result.sents + current.sents
        }), {
            delivereds: 0,
            openeds: 0,
            count: 0,
            sents: 0
        })

        setData(dados)
        setSomatorias(somatoriasIndicativos)
    }

    useEffect(() => {
        loadData()
    }, [date])

    useEffect(() => {
        if(canalSelecionado) {
            loadData()
        }
    }, [canalSelecionado])

    useEffect(() => {
        loadTips(setCodigo, 'form_estatisticas_mensageria')
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Estatística de envio de mensagens</h1>

                    <FilterContainer>
                        <div className="fields">
                            <Select 
                                withoutForm
                                id="canal"
                                onChange={setCanalSelecionado}
                                label="Canal de envio"
                                value={canalSelecionado}
                                options={[
                                    { label: 'E-mail', value: 'email' },
                                    { label: 'WhatsApp', value: 'whatsapp' }
                                ]}
                            />

                            <Calendar 
                                withoutForm
                                label="Mês/ano"
                                dateFormat="mm/yy"
                                maxDate={new Date()}
                                view="month"
                                id="mes"
                                value={date}
                                onChange={e => {
                                    setDate(e.value)
                                }}
                            />
                        </div>

                        {somatorias && canalSelecionado && (
                            <SomatoriasContainer>
                                {canalSelecionado.value === 'whatsapp' && (
                                    <>
                                        <p>
                                            {'Enviados: '}
                                            <b>{somatorias.sents}</b>    
                                        </p>
                                        <p>
                                            {'Entregues: '}
                                            <b>{somatorias.delivereds}</b> 
                                        </p>
                                        <p>
                                            {'Visualizados: '}
                                            <b>{somatorias.openeds}</b> 
                                        </p>
                                    </>
                                )}

                                {canalSelecionado.value === 'email' && (
                                    <>
                                        <p>
                                            {'Enviados: '}
                                            <b>{somatorias.count}</b>
                                        </p>
                                        <p>
                                            {'Visualizados: '}
                                            <b>{somatorias.openeds}</b>
                                        </p>
                                    </>
                                )}
                            </SomatoriasContainer>
                        )}
                    </FilterContainer>
			
                    <SmallScreenPlaceholder 
                        text="Gire o dispositivo para visualizar o gráfico." 
                        hideOffset={800} 
                    />
                            
                    {data ? (
                        <BarChart 
                            data={data}
                            width={1150}
                            height={750}
                            className="chart"
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
