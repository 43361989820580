import { Base64 } from 'base64-string'

const encriptador = new Base64()

function criptografar(content) {
    if(!content) {
        return null
    }
    
    try {
        const crypted = encriptador.encode(content)
        
        return crypted
    } catch(e) {
        return content
    }
}

function descriptografar(content) {
    if(!content) {
        return null
    }

    try {
        const decipheredText = encriptador.decode(content)

        return JSON.parse(decipheredText)
    } catch(e) {
        return { error: true }
    }
}

export {
    criptografar,
    descriptografar
}
