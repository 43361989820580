import styled from 'styled-components'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.spinner-container {
		margin: 60px 0 30px;
	}

	form {
		display: grid;
		grid-template-areas:
              'f1 f2 f3 f3'
              'f4 f5 f5 f5'
              'bt bt bt bt';
		padding-bottom: 20px;

		@media(max-width: 900px) {
			grid-template-areas:
              'f1 f2'
              'f3 f4'
			  'f5 f5'
              'bt bt';
		}

		@media(max-width: 600px) {
			grid-template-areas:
              'f1'
              'f2'
			  'f3'
			  'f4'
			  'f5'
              'bt';

			.field.checkbox {
				grid-column: unset;
			}

			& > button {
				max-width: unset;
			}
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;
	}

	@media(max-width: 730px) {
		.table-container {
			display: none;
		}
	}
`
