import React, { useContext } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import Lists from '../Lists'

import { PermissoesContext } from '../../../../contexts/PermissoesContext'

export default function () {
    const { handleUpdate } = useContext(PermissoesContext)

    return (
        <DragDropContext onDragEnd={handleUpdate}>
            <Lists />
        </DragDropContext>
    )
}
