import React, { useContext, useState } from 'react'
import { FiFile, FiPlus, FiRefreshCcw } from 'react-icons/fi'
import { FaDownload, FaEdit, FaStarHalfAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'

import { Button } from '../../../../components/Form'
import Modal from '../../../../components/Modal'
import Table from '../../../../components/Table'
import RatingStarts from '../../../../components/RatingStarts'

import ManutencaoCadastro from '../Manutencao/Cadastro'

import { ContratoContext } from '../../../../contexts/ContratoContext'

import { Container, AvaliacaoContainer } from './styles'

import { api, authHeaders } from '../../../../services/api'
import { forceDownloadFileFromURL } from '../../../../util/file'

export default function () {
    const {
        contratoDetalhe, setContratoDetalhe, handleDownloadContrato, handleRenovarContrato, reload
    } = useContext(ContratoContext)

    const encerrado = new Date(contratoDetalhe?.fim) < new Date()

    const [manutencaoAvaliacao, setManutencaoAvaliacao] = useState(null)
    const [avaliacaoSelecionada, setAvaliacaoSelecionada] = useState(null)
    const [formCadastroManutencao, setFormCadastroManutencao] = useState(false)
    const [manutencaoEdicao, setManutencaoEdicao] = useState(null)

    async function handleAvaliar() {
        try {
            await api.put(`contrato/${contratoDetalhe.id}/manutencao/${manutencaoAvaliacao.id}/avaliacao`, {
                avaliacao: avaliacaoSelecionada
            }, authHeaders())

            toast.success('Atendimento avaliado.')

            setManutencaoAvaliacao(null)
            
            reload()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    return (
        <>
            <Modal
                isOpen={!!contratoDetalhe}
                handleClose={() => { 
                    setContratoDetalhe(null)
                }}
                title={contratoDetalhe?.fornecedor_nome}
            >
                <Container>
                    <div className="info">
                        <div dangerouslySetInnerHTML={{ __html: `Número do contrato: <b>${contratoDetalhe?.numero_contrato}</b>` }} />
                        <div dangerouslySetInnerHTML={{ __html: `Fornecedor: <b>${contratoDetalhe?.fornecedor_nome}</b>` }} />
                        <div dangerouslySetInnerHTML={{ __html: `Tipo de contrato: <b>${contratoDetalhe?.tipo}</b>` }} />
                        <div dangerouslySetInnerHTML={{ __html: `Período do contrato: <b>${contratoDetalhe?.inicio_formatado} a ${contratoDetalhe?.fim_formatado}</b>` }} />
                        <div dangerouslySetInnerHTML={{ __html: `Manutenção períodoca: <b>${contratoDetalhe?.manutencao_periodica_string}</b> ${contratoDetalhe?.periodo_manutencao ? `(a cada ${contratoDetalhe?.periodo_manutencao} dias)` : ''}` }} />
                        <div dangerouslySetInnerHTML={{ __html: `Objeto do contrato: <b>${contratoDetalhe?.objeto}</b>` }} />
                    </div>

                    <Button 
                        className="transparent" 
                        onClick={contratoDetalhe?.arquivo_link ? () => { handleDownloadContrato(contratoDetalhe) } : () => {}} 
                        tooltip={contratoDetalhe?.arquivo_link ? null : 'Não foi anexado nenhum contrato digitalizado.'}
                        tooltipPosition="left"
                    >
                        Baixar o contrato
                        <FiFile size={19} />
                    </Button>

                    {!encerrado ? (
                        <Button 
                            className="transparent botao-renovacao" 
                            onClick={() => { handleRenovarContrato(contratoDetalhe) }} 
                        >
                            Renovar o contrato
                            <FiRefreshCcw size={19} />
                        </Button>
                    ) : <div />}

                    {contratoDetalhe?.manutencao_periodica ? (
                        <>
                            {!encerrado ? (
                                <Button 
                                    className="transparent cadastrar-manutencao" 
                                    onClick={() => {
                                        setFormCadastroManutencao(true)
                                    }}
                                >
                                    Registrar manutenção
                                    <FiPlus size={18} />
                                </Button>
                            ) : <div />}
                    
                            <Table 
                                caption="Manutenções efetuadas"
                                headers={[
                                    { name: 'profissional_nome', value: 'Profissional' },
                                    { name: 'data_formatada', value: 'Data', centered: true },
                                    { name: 'tipo_descricao', value: 'Tipo', centered: true }
                                ]}
                                data={contratoDetalhe?.manutencoes}
                                hideOnSmallHeaders={['profissional_nome']}
                                hideHeadersOffset={635}
                                hideOffset={500}
                                className="manutencoes-efetuadas"
                                actions={[
                                    {
                                        action: item => {
                                            forceDownloadFileFromURL(item.os)
                                        },
                                        icon: FaDownload,
                                        iconSize: 15,
                                        name: 'Baixar Ordem de Serviço',
                                        checkDisabled: item => !item.os,
                                        disabledTooltip: () => 'Esta manutenção não tem Ordem de Serviço.'
                                    },
                                    {
                                        action: setManutencaoEdicao,
                                        icon: FaEdit,
                                        iconSize: 16,
                                        name: 'Editar',
                                        checkDisabled: () => encerrado
                                    },
                                    {
                                        action: setManutencaoAvaliacao,
                                        icon: FaStarHalfAlt,
                                        iconSize: 16,
                                        name: 'Avaliar atendimento',
                                        checkDisabled: item => item.avaliacao,
                                        disabledTooltip: item => `Este atendimento já foi avaliado com ${item.avaliacao} estrelas`
                                    }
                                ]}
                                emptyLabel="Nenhuma manutenção realizada"
                            />

                            <Table 
                                caption="Próximas manutenções"
                                headers={[
                                    { name: 'periodo_manutencao', value: 'Período previsto', centered: true },
                                    { name: 'tipo_descricao', value: 'Tipo', centered: true },
                                    {
                                        name: 'efetuada', value: 'Efetuada', centered: true, icon: true 
                                    }
                                ]}
                                data={contratoDetalhe?.manutencoes_previstas}
                                emptyLabel="Nenhuma manutenção prevista"
                                hideOnSmallHeaders={['tipo_descricao']}
                                hideHeadersOffset={635}
                                hideOffset={500}
                                className="manutencoes-previstas"
                                legends={[
                                    {
                                        color: '#ffee58',
                                        text: 'Próxima manutenção prevista'
                                    }
                                ]}
                            />
                        </>
                    ) : null}
                </Container>
            </Modal>

            <Modal
                isOpen={!!manutencaoAvaliacao}
                handleClose={() => { setManutencaoAvaliacao(null) }}
                title="Avaliação de atendimento"
            >
                <AvaliacaoContainer>
                    <div dangerouslySetInnerHTML={{ __html: `Avalie o atendimento do prestador de serviço <b>${manutencaoAvaliacao?.profissional_nome}</b> na data <b>${manutencaoAvaliacao?.data_formatada}</b>.` }} />

                    <RatingStarts onSelect={avaliacao => { setAvaliacaoSelecionada(Number(avaliacao)) }} />

                    <Button className="transparent" onClick={handleAvaliar} disabled={!avaliacaoSelecionada}>
                        Avaliar
                    </Button>
                </AvaliacaoContainer>
            </Modal>

            <Modal
                isOpen={!!contratoDetalhe && (formCadastroManutencao || !!manutencaoEdicao)}
                handleClose={() => { 
                    setFormCadastroManutencao(false) 

                    setManutencaoEdicao(null)
                }}
                title={`${manutencaoEdicao ? 'Editar' : 'Cadastrar'} manutenção períódica`}
            >
                <ManutencaoCadastro 
                    manutencaoEdicao={manutencaoEdicao} 
                    contrato={contratoDetalhe}
                    onSubmit={() => { 
                        setFormCadastroManutencao(false) 

                        setManutencaoEdicao(null)

                        reload()
                    }} 
                />
            </Modal>
        </>
    )
}
