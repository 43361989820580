import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { FiFile, FiFileText } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'
import ibge from '../../../../../services/ibge'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar, Textbox
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'
import rangeMonthGif from '../../../../../assets/images/gifs/periodo-mes.gif'

const initialValues = {
    situacao: '',
    'plano-saude-status': '',
    'plano-saude-situacao': '',
    juridico: '',
    cidade: '',
    competencia: '',
    instituicao: '',
    'tipo-associado': '',
    'grupo-associado': '',
    'data-nascimento': '',
    aniversario: '',
    crm: '',
    pagamento: '',
    tipoPagamento: '',
    'motivo-nao-debito': ''
}

const validation = Yup.object().shape({
    situacao: Yup.string().ensure(),
    'plano-saude-status': Yup.string().ensure(),
    'plano-saude-situacao': Yup.string().ensure(),
    juridico: Yup.string().ensure(),
    cidade: Yup.string().ensure(),
    competencia: Yup.string().ensure(),
    instituicao: Yup.string().ensure(),
    'tipo-associado': Yup.string().ensure(),
    'grupo-associado': Yup.string().ensure(),
    'data-nascimento': Yup.string().nullable(),
    aniversario: Yup.string().nullable(),
    crm: Yup.string().nullable(),
    pagamento: Yup.string().nullable(),
    tipoPagamento: Yup.string().nullable(),
    'motivo-nao-debito': Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de Mensalidades'

export default function (props) {
    const [cidades, setCidades] = useState([])
    const [instituicoes, setInstituicoes] = useState([])
    const [tiposPagamentos, setTiposPagamentos] = useState([])
    const [motivosNaoDebito, setMotivosNaoDebito] = useState([])
    const [isSubmittingPlanilha, setIsSubmittingPlanilha] = useState(false)

    useEffect(() => {
        async function loadCidades() {
            const { data } = await ibge.get('estados/PA/municipios')

            setCidades(data.map(({ nome }) => nome).sort())
        }

        loadCidades()
    }, [])

    useEffect(() => {
        async function loadMotivosNaoDebito() {
            const response = await api.get('motivo_falha_debito')

            setMotivosNaoDebito(response)
        }

        loadMotivosNaoDebito()
    }, [])

    useEffect(() => {
        async function loadInstituicoes() {
            const response = await api.get('instituicao', {
                params: {
                    type: 'banco'
                }
            })

            setInstituicoes(response)
        }

        loadInstituicoes()
    }, [])

    useEffect(() => {
        async function loadTiposPagamentos() {
            const response = await api.get('tipo_pagamento', {
                params: {
                    ativos: 1
                }
            })

            setTiposPagamentos(response)
        }

        loadTiposPagamentos()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            situacao,
            cidade,
            'data-nascimento': nascimento,
            aniversario,
            competencia,
            'plano-saude-status': planoSaudeStatus,
            'plano-saude-situacao': planoSaudeSituacao,
            juridico,
            instituicao,
            'tipo-associado': tipoAssociado,
            'grupo-associado': grupoAssociado,
            crm,
            pagamento,
            tipoPagamento,
            'motivo-nao-debito': motivoNaoDebito
        } = values

        const compInicio = competencia ? competencia[0] : null
        const compFim = competencia ? competencia[1] : null
        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null
        const nascimentoInicio = nascimento ? nascimento[0] : null
        const nascimentoFim = nascimento ? nascimento[1] : null
        const aniversarioInicio = aniversario ? aniversario[0] : null
        const aniversarioFim = aniversario ? aniversario[1] : null

        let filtros = ''

        if (tipoAssociado) {
            filtros += `Membros: ${tipoAssociado.label}, `
        }

        if (situacao) {
            filtros += `Situação do pagamento: ${situacao.label}, `
        } else {
            filtros += 'Situação de pagamento: Todos, '
        }

        if (instituicao) {
            filtros += `Instituição: ${instituicao.label}, `
        }

        // if (nascimentoInicio) {
        //     filtros += `Data nasc.: ${format(new Date(nascimentoInicio), 'dd/MM/yyyy')} a ${nascimentoFim !== null ? format(new Date(nascimentoFim), 'dd/MM/yyyy') : format(new Date(nascimentoInicio), 'dd/MM/yyyy')}, `
        // }

        // if (aniversarioInicio) {
        //     filtros += `Aniversário: ${format(new Date(aniversarioInicio), 'dd/MM/yyyy')} a ${aniversarioFim !== null ? format(new Date(aniversarioFim), 'dd/MM/yyyy') : format(new Date(aniversarioInicio), 'dd/MM/yyyy')}, `
        // }

        if (cidade) {
            filtros += `Cidade: ${cidade.label}, `
        }

        // if (planoSaudeStatus) {
        //     filtros += `${planoSaudeStatus.value === 1 ? 'Com plano de saúde' : 'Sem plano de Saúde'}, `
        // }

        // if (planoSaudeSituacao) {
        //     filtros += `${planoSaudeSituacao.value === 1 ? 'Quites com o plano de saúde' : 'Pendentes com o plano de Saúde'}, `
        // }

        // if (juridico) {
        //     filtros += `${juridico.value === 1 ? 'Com causa no jurídico' : 'Sem causa no jurídico'}, `
        // }

        // if (grupoAssociado) {
        //     filtros += `Médicos: ${grupoAssociado.label}, `
        // }

        // if (motivoNaoDebito) {
        //     filtros += `Motivo não débito: ${motivoNaoDebito.label}, `
        // }

        if (tipoPagamento) {
            filtros += `Forma de pagamento: ${tipoPagamento.label}, `
        }

        try {
            const response = await api.post('/relatorio', {
                relatorio: 'RelatorioContribuicao',
                parametros: {
                    competenciaInicio: compInicio !== null ? format(new Date(compInicio), 'yyyy-MM-dd') : '',
                    competenciaFim: compFim !== null ? format(new Date(compFim), 'yyyy-MM-dd') : compInicio !== null ? format(new Date(compInicio), 'yyyy-MM-dd') : '',
                    instituicao: instituicao?.value || '',
                    cidade: cidade?.value || '',
                    planodeSaude: planoSaudeStatus?.value || '',
                    situacaoPlanodeSaude: planoSaudeSituacao?.value || '',
                    juridico: juridico?.value || '',
                    dataNascimentoInicio: nascimentoInicio !== null ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : '',
                    dataNascimentoFim: nascimentoFim !== null ? format(new Date(nascimentoFim), 'yyyy-MM-dd') : (nascimentoInicio !== null) ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : '',
                    tipoPagamentoId: tipoPagamento?.value || '',
                    crm: crm || '',
                    situacao: situacao?.value || '',
                    dataPagamentoInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    dataPagamentoFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    tipoAssociado: tipoAssociado?.value || '',
                    grupoAssociado: grupoAssociado?.value || '',
                    dataAniversarioInicio: aniversarioInicio !== null ? format(new Date(aniversarioInicio), 'yyyy-MM-dd') : '',
                    dataAniversarioFim: aniversarioFim !== null ? format(new Date(aniversarioFim), 'yyyy-MM-dd') : (aniversarioInicio !== null) ? format(new Date(aniversarioInicio), 'yyyy-MM-dd') : '',
                    motivo_nao_debito: motivoNaoDebito?.value || '',
                    filtros: filtros !== '' ? filtros.slice(0, -2) : ''
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    const handleSubmitPlanilha = useCallback(async (values, resetForm) => {
        setIsSubmittingPlanilha(true)

        const {
            situacao,
            cidade,
            'data-nascimento': nascimento,
            aniversario,
            competencia,
            'plano-saude-status': planoSaudeStatus,
            'plano-saude-situacao': planoSaudeSituacao,
            juridico,
            'tipo-associado': tipoAssociado,
            'grupo-associado': grupoAssociado,
            crm,
            pagamento,
            instituicao,
            tipoPagamento,
            'motivo-nao-debito': motivoNaoDebito
        } = values

        const competenciaInicio = competencia ? competencia[0] : null
        const competenciaFim = competencia ? competencia[1] : null
        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null
        const nascimentoInicio = nascimento ? nascimento[0] : null
        const nascimentoFim = nascimento ? nascimento[1] : null
        const aniversarioInicio = aniversario ? aniversario[0] : null
        const aniversarioFim = aniversario ? aniversario[1] : null

        try {
            const response = await api.post('/relatorio/contribuicao_mensalidade_excel', {
                relatorio: 'RelatorioMensalidade',
                parametros: {
                    status: situacao?.value || null,
                    cidade: cidade?.value || null,
                    competenciaInicial: competenciaInicio !== null ? format(new Date(competenciaInicio), 'yyyy-MM-dd') : null,
                    competenciaFinal: competenciaFim !== null ? format(new Date(competenciaFim), 'yyyy-MM-dd') : competenciaInicio !== null ? format(new Date(competenciaInicio), 'yyyy-MM-dd') : null,
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataNascimentoInicio: nascimentoInicio !== null ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : null,
                    dataNascimentoFim: nascimentoFim !== null ? format(new Date(nascimentoFim), 'yyyy-MM-dd') : (nascimentoInicio !== null) ? format(new Date(nascimentoInicio), 'yyyy-MM-dd') : null,
                    dataAniversarioInicio: aniversarioInicio !== null ? format(new Date(aniversarioInicio), 'yyyy-MM-dd') : null,
                    dataAniversarioFim: aniversarioFim !== null ? format(new Date(aniversarioFim), 'yyyy-MM-dd') : (aniversarioInicio !== null) ? format(new Date(aniversarioInicio), 'yyyy-MM-dd') : null,
                    planoSaude: planoSaudeStatus?.value || null,
                    situacaoPlanoSaude: planoSaudeSituacao?.value || null,
                    juridico: juridico?.value || null,
                    tipoAssociado: tipoAssociado?.value || null,
                    grupoAssociado: grupoAssociado?.value || null,
                    crm: crm || null,
                    instituicao: instituicao?.value || null,
                    tipoPagamento: tipoPagamento?.value || null,
                    motivo_nao_debito: motivoNaoDebito?.value || null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            if (response.type === 'application/json') {
                const fr = new FileReader()

                fr.onload = function (e) {
                    const json = e.target.result
                    toast.warn(JSON.parse(json).msg)
                }
                fr.readAsText(response)
            } else {
                downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')
            }

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }

        setIsSubmittingPlanilha(false)
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm, values, setSubmitting
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="situacao"
                            label="Situação do pagamento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Efetuados', value: 1 },
                                { label: 'Pendentes', value: 2 }
                            ]}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Calendar
                            name="competencia"
                            dateFormat="mm/yy"
                            view="month"
                            label="Competência"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f2' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeMonthGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="instituicao"
                            label="Instituição"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={instituicoes.filter(insti => insti.desconto).map(instituicao => ({
                                label: `${instituicao.nome_fantasia} ${instituicao.sigla ? `(${instituicao.sigla})` : ''}`,
                                value: instituicao.id
                            }))}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Calendar
                            name="pagamento"
                            label="Data de Pagamento"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f4' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        {/* <Select
                            name="motivo-nao-debito"
                            label="Motivo não debito em conta"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={motivosNaoDebito.map(motivo => ({
                                label: motivo.descricao,
                                value: motivo.id
                            }))}
                            containerStyle={{ gridArea: 'f5' }}
                        /> */}

                        <Select
                            name="tipoPagamento"
                            label="Forma de Pagamento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={tiposPagamentos.map(tipos => ({
                                label: tipos.descricao,
                                value: tipos.id
                            }))}
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Textbox
                            name="crm"
                            label="Matrícula"
                            containerStyle={{ gridArea: 'f6' }}
                        />

                        {/* <Select
                            name="plano-saude-status"
                            label="Plano de Saúde"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Com Plano', value: 1 },
                                { label: 'Sem plano', value: 2 }
                            ]}
                            containerStyle={{ gridArea: 'f8' }}
                        />

                        <Select
                            name="plano-saude-situacao"
                            label="Situação Plano de Saúde"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Quite', value: 1 },
                                { label: 'Pendente', value: 2 }
                            ]}
                            containerStyle={{ gridArea: 'f9' }}
                        />

                        <Select
                            name="juridico"
                            label="Causas no Jurídico"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Com causa', value: 1 },
                                { label: 'Sem causa', value: 2 }
                            ]}
                            containerStyle={{ gridArea: 'f10' }}
                        /> */}

                        <Select
                            name="cidade"
                            label="Cidade"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={cidades.map(cidade => ({
                                label: cidade,
                                value: cidade
                            }))}
                            containerStyle={{ gridArea: 'f7' }}
                        />

                        <Select
                            name="tipo-associado"
                            label="Situação do membro"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Ativo', value: 3 },
                                { label: 'Inativo', value: 5 }
                            ]}
                            containerStyle={{ gridArea: 'f8' }}
                        />

                        {/* <Select
                            name="grupo-associado"
                            label="Grupo Associado"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Falecido', value: 3 }
                            ]}
                            containerStyle={{ gridArea: 'f13' }}
                        />

                        <Calendar
                            name="data-nascimento"
                            dateFormat="dd/mm/yy"
                            label="Data de Nascimento"
                            selectionMode="range"
                            maxDate={new Date(new Date().getFullYear() - 14, 11, 31)}
                            yearRange={`${new Date().getFullYear() - 100}:${new Date().getFullYear() - 14}`}
                            containerStyle={{ gridArea: 'f14' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Calendar
                            name="aniversario"
                            dateFormat="dd/mm"
                            label="Data de Aniversário"
                            yearNavigator={false}
                            containerClass="header-without-year"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f15' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        /> */}

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => handleSubmitPlanilha(values, resetForm, setSubmitting)}
                                loading={isSubmittingPlanilha}
                                className="white"
                            >
                                Gerar planilha
                                <FiFileText size={20} />
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
