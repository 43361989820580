import styled from 'styled-components'

export const Container = styled.div`
	h1 {
		margin-bottom: 20px;
	}

	h2 {
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 10px;
	}

	> div {
		margin: 16px 8px 0;

		> div {
			line-height: 28px;
			border-bottom: solid 1px #fff;
			padding-top: 12px;
			padding-bottom: 12px;

			.resposta-container {
				display: grid;
				grid-template-columns: 1fr 132px;
				gap: 16px;

				.data {
					font-size: 12px;
					justify-self: flex-end;
				}
			}
		}
	}
`
