import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { FiEdit } from 'react-icons/fi'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'

import { useLocation } from 'react-router-dom'
import Yup from '../../services/yup'

import Spinner from '../../components/Spinner'
import Modal from '../../components/Modal'
import ActionFooter from '../../components/ActionFooter'
import { Textarea, Button } from '../../components/Form'

import { api, authHeaders } from '../../services/api'

import { Container, ModalContainer } from './styles'

const baseInitialValues = {
    observacao: ''
}

const boxName = 'Observação'

const validation = Yup.object().shape({
    observacao: Yup.string()
})

export default function ({ pessoa, reload, editable = true }) {
    const location = useLocation()

    const [editMode, setEditMode] = useState(false)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function handlePrepareEdit() {
        const { observacao } = pessoa

        setInitialValues({
            observacao: observacao || ''
        })

        setEditMode(true)
    }

    async function handleEdit(values) {
        try {
            const { observacao } = values

            await api.put(`associado/${pessoa.associado.id}`, { observacao }, authHeaders())

            toast.success('Dados atualizados.')

            setEditMode(false)

            setInitialValues(baseInitialValues)

            reload()
        } catch (e) {
            toast.error('Erro ao atualizar os dados pessoais.')
        }
    }

    return (
        <>
            <Container className="animated fadeInUp delay-200ms no-responsive" colorScheme={location.pathname.includes('ficha_associado') ? 'dark' : 'light'}>
                <h1>{boxName}</h1>

                {editable ? (
                    <FiEdit
                        size={20}
                        data-tip="Editar"
                        data-for="tooltip"
                        onClick={handlePrepareEdit}
                    />
                ) : (
                    <div />
                )}

                {pessoa ? (
                    <div className="observacao-conteudo">
                        {pessoa?.observacao
                            ? pessoa?.observacao
                            : <p className="placeholder"><i>Nenhuma observação cadastrada</i></p>}
                    </div>
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={editMode}
                handleClose={() => setEditMode(false)}
                title={boxName}
            >
                <ModalContainer>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={editMode ? handleEdit : () => null}
                        enableReinitialize
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Textarea
                                    label="Observação"
                                    name="observacao"
                                />

                                <ActionFooter>
                                    <Button type="submit" loading={isSubmitting} className="white">
                                        Salvar alterações
                                        <FaRegSave />
                                    </Button>
                                </ActionFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContainer>
            </Modal>
        </>
    )
}
