import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	margin: 8px;

	.button-container {
		display: flex;
		gap: 4px;
		width: 100%;
		justify-content: end;
	}
`
