import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		button {
			justify-self: flex-end;
			grid-column: 1/span 3;
			width: fit-content;
		}

		@media(max-width: 800px) {
			display: flex;
			flex-direction: column;
		}
	}
`
