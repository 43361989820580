import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin: 5px 8px;
	font-size: 15px;
`

export const ModalContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 14px;

	img, 
	iframe {
		width: 100%;
	}

	iframe {
		height: 86vh;
	}
`
