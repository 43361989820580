import styled, { css } from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	${props => !props.compacto && css`
		margin-bottom: 30px;
	`}

	${props => props.compacto && css`
		display: grid;
		align-items: center;
		grid-template-columns: auto 30px;
		grid-template-rows: min-content min-content auto;
		grid-template-areas:
			'title icon'
			'extra extra'
			'table table';
	`}

	> h1 {
		font-size: 19px;
		font-weight: 500;
	}

	> p {
		grid-area: extra;
		margin-top: 14px;
	}

	> svg {
		cursor: pointer;
		transition: 300ms;

		&:hover {
			opacity: 0.7;
		}
	}

	.table-container {
		grid-area: table;

		table {
			margin-top: 0;
		}
	}
`

export const ExtraText = styled.p`
	display: flex;
	align-items: center;
	background-color: #ffeb3bDD;
	color: #424242;
	padding: 6px 12px;
	font-size: 12px;
	border-radius: 10px;
	margin-top: 20px;
	font-weight: 500;
	width: fit-content;

	svg {
		margin-right: 10px;
	}
`
