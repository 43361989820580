import React from 'react'

import { Container } from './styles'

import Card from '../../components/Card'

import syndi from '../../assets/images/syndi.png'
import invoice from '../../assets/images/payment/paid_invoice.webp'

export default function () {
    return (
        <Container>
            <img src={syndi} alt="" />

            <Card>
                <h1>Obrigada!</h1>

                <h2>Sua fatura está paga.</h2>

                <img src={invoice} alt="" className="animated bounceIn faster delay-500ms" />

                <p>
                    {'Agora você pode fechar esta aba e continuar o que estava fazendo. '}
                    <span role="img" aria-label="">😊</span>
                </p>
            </Card>
        </Container>
    )
}
