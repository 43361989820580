import styled, { css } from 'styled-components'

export const Container = styled.div.attrs({
    className: 'field file'
})`
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: calc(100% - 16px); 
	max-width: ${props => `${Array.isArray(props.previewSize) ? props.previewSize[0] : props.previewSize}`};
	margin: 5px 8px;

	.label {
		font-size: 11px;
		margin: 4px 4px 2px;
	}

	.tip {
		font-size: 10px;
		margin: 6px 4px;
		line-height: 16px;
	}

	.tip-icon {
		position: relative;
		right: -92%;
		top: 16px;
		width: 18px;
		opacity: 0.7;
		z-index: 1;
		color: rgba(0, 0, 0, 0.3) !important;

		&:hover {
			cursor: help;

			& + .tip-container {
				display: grid;
			}
		}
	}

	.file-select-area {
		height: ${props => `${Array.isArray(props.previewSize) ? props.previewSize[1] : props.previewSize}`};
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0.8);
		border-radius: ${props => props.format === 'circle' ? '50%' : '4px'};
		border-bottom-left-radius: ${props => props.format === 'circle' ? '50%' : props.progress ? 0 : '4px'};
		border-bottom-right-radius: ${props => props.format === 'circle' ? '50%' : props.progress ? 0 : '4px'};
		color: #424242;
		background-size: contain;
		background-image: ${props => props.previewImage || 'unset'};
		background-position: center;
		background-repeat: no-repeat;
		transition: all 300ms;
		box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);

		p {
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 300ms;

			svg {
				margin-right: 6px;
			}

			&.with-preview {
				opacity: ${props => props.previewImage ? 0 : 0.85};
				background-color: transparent;
				padding: 10px;
				border-radius: 10px;
			}
		}

		&:hover {
			border: dashed 1px rgba(0,0,0,0.2);
			opacity: 0.8;

			p.with-preview {
				background-color: rgba(255, 255, 255, 0.94);
				opacity: 0.9;
			}
		}

		iframe {
			border-radius: 4px;
		}

		.overlay {
			position: absolute;
			border-radius: ${props => props.format === 'circle' ? '50%' : '4px'};
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				background-color: ${props => props.previewImage ? '#0005' : '#0001'};
			}
		}	
	}

	.error {
		width: 100%;
		margin-top: 6px;
	}

	${props => props.error && css`
		padding-left: 8px;
		border-left: solid 4px #ffeb3b;
	`};

	.progress {
		color: #424242;
		font-size: 50px;
	}
`

export const TipContainer = styled.div.attrs({
    className: 'tip-container animated fadeIn faster'
})`
	display: none;
	grid-template-columns: 24px auto;
	position: absolute;
	margin-left: 12px;
	gap: 4px;
	font-size: 11px;
	align-items: center;
	color: #424242;
	background-color: #fff;
	padding: 4px 8px 4px 2px;
	z-index: 2;
	border-radius: 6px;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);

	> svg {
		width: 28px;
		fill: #424242;
	}
`
