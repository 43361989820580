import React, {
    useEffect, useState, useCallback, useContext
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import {
    FaLock, FaEnvelope
} from 'react-icons/fa'
import { FiSave } from 'react-icons/fi'

import { api, authHeaders } from '../../../services/api'
import yup from '../../../services/yup'
import history from '../../../services/history'

import scrollUtils from '../../../util/scroll'

import useQuery from '../../../hooks/useQuery'

import { GlobalContext } from '../../../contexts/GlobalContext'

import Spinner from '../../../components/Spinner'
import {
    Textbox, Button, File, Checkbox
} from '../../../components/Form'

import {
    Container, SpinnerContainer, ButtonContainer, AvatarContainer, Content, PasswordContainer, EmailFuncionalContainer, NotificacaoPermissaoContainer
} from './styles'

import avatarDefault from '../../../assets/images/avatar.png'

const initialValues = {
    senha_anterior: '',
    senha: '',
    senha_confirmacao: ''
}

const validation = yup.object({
    senha_anterior: yup.string().notRequired(),
    senha: yup.string()
        .when('senha_anterior', {
            is: ref => !!ref,
            then: yup.string().required('Digite uma nova senha').min(6, 'A senha deve conter pelo menos 6 caracteres'),
            otherwise: yup.string().notRequired()
        }),
    senha_confirmacao: yup.string()
        .when('senha', {
            is: ref => !!ref,
            then: yup.string().required('Confirme sua nova senha').oneOf([yup.ref('senha')], 'As senhas não coincidem'),
            otherwise: yup.string().notRequired()
        })
})

const textoDetalhesPermissaoNotificacoes = `
    <p>Somos totalmente contra o spam. Nós comunicamos aos nossos membros apenas o que realmente é relevante.</p>
    <p>Clicando nas autorizações abaixo, você receberá notificações relacionadas aos seu pagamentos, chamados e serviços contratados por você.</p>
`

export default function () {
    const { user, reload } = useContext(GlobalContext)

    const { notificacao } = useQuery()

    const [email, setEmail] = useState(null)
    const [permissoesNotificacao, setPermissoesNotificacao] = useState(null)
    const [contatos, setContatos] = useState([])

    const temWhatsApp = contatos.find(c => c.tipo_contato.id === 4)

    useEffect(() => {
        if (notificacao) {
            setTimeout(() => {
                scrollUtils.toElement('.notificacao-container')
            }, 500)
        }
    }, [])

    const handleChangePassword = useCallback(async (values, { setSubmitting, resetForm }) => {
        try {
            const { senha, senha_anterior } = values

            await api.put('usuario', {
                senha,
                senha_anterior
            }, authHeaders())

            setSubmitting(false)

            resetForm()

            toast.success('Os dados foram atualizados.')

            history.goBack()
        } catch (e) {
            toast.error(e.msg || 'Ocorreu um erro ao tentar salvar os dados.')
        }
    }, [])

    const updateAvatar = useCallback(async fileId => {
        try {
            await api.put('usuario', { avatar_id: fileId }, authHeaders())

            reload()
        } catch (e) {
            toast.error('Erro ao atualizar a foto de perfil.')
        }
    }, [])

    async function handleUpdateEmail(values) {
        try {
            const { email: novoEmail } = values

            const body = {
                contato: novoEmail,
                tipo_contato_id: 6,
                pessoa_id: user.pessoa.id
            }

            if (email) {
                await api.put(`contato/${email.id}`, body, authHeaders())
            } else {
                await api.post('contato', body, authHeaders())
            }

            toast.success('E-mail alterado com sucesso.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function getChannelName(channel) {
        switch (channel) {
            case 'whatsapp': return 'WhatsApp'
            case 'sms': return 'SMS'
            case 'email': return 'e-mail'
            default: return ''
        }
    }

    async function handleUpdatePermissaoNotificacao({ checked: allow, name: channel }, showToast = true) {
        try {
            setPermissoesNotificacao({
                ...permissoesNotificacao,
                [channel]: allow
            })

            await api.put('usuario/allow_notification', {
                channel,
                allow
            }, authHeaders())

            if (!showToast) {
                return
            }

            if (allow) {
                toast.success(`A partir de agora você será comunicado via ${getChannelName(channel)}.`)
            } else {
                toast.success('Permissão de notificações atualizada.')
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadUsuarioPermissoesNotificacao() {
        const { whatsapp, sms, email: mail } = await api.get('usuario', authHeaders())
        const responseContatos = await api.get('contato', {
            params: {
                pessoa_id: user.pessoa.id
            }
        })

        if (mail === null) {
            handleUpdatePermissaoNotificacao({ checked: false, name: 'email' }, false)
        }

        if (sms === null) {
            handleUpdatePermissaoNotificacao({ checked: false, name: 'sms' }, false)
        }

        if (whatsapp === null) {
            handleUpdatePermissaoNotificacao({ checked: false, name: 'whatsapp' }, false)
        }

        setPermissoesNotificacao({
            whatsapp,
            sms,
            email: mail
        })

        setContatos(responseContatos)
    }

    useEffect(() => {
        if (user) {
            loadUsuarioPermissoesNotificacao()

            setEmail(user?.pessoa.contato.find(c => c.tipo_contato_id === 6))
        }
    }, [user])

    return (
        <Container>

            <Content className="animated fadeIn">
                <h1>Perfil de usuário</h1>

                {user ? (
                    <>
                        <AvatarContainer>
                            <File
                                getPreloadImage={() => user?.avatar || avatarDefault}
                                onSuccess={updateAvatar}
                                accept={['image/*']}
                                placeholderText="Selecione um avatar"
                                previewSize="180px"
                            />
                        </AvatarContainer>

                        {![2, 16, 21].includes(user?.perfil.id) && (
                            <Formik
                                initialValues={{ email: email?.contato || '' }}
                                validationSchema={validation}
                                onSubmit={handleUpdateEmail}
                                enableReinitialize
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <EmailFuncionalContainer>
                                            <h2>E-mail funcional</h2>

                                            <Textbox name="email" label="E-mail corporativo" icon={{ source: FaEnvelope }} />

                                            <ButtonContainer>
                                                <Button type="submit" className="white" loading={isSubmitting}>
                                                    <span>Salvar</span>
                                                    <FiSave size={18} />
                                                </Button>
                                            </ButtonContainer>
                                        </EmailFuncionalContainer>
                                    </Form>
                                )}
                            </Formik>
                        )}

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validation}
                            onSubmit={handleChangePassword}
                            enableReinitialize
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <PasswordContainer>
                                        <h2>Trocar senha de acesso</h2>

                                        <Textbox name="senha_anterior" type="password" label="Senha atual" icon={{ source: FaLock }} />

                                        <Textbox name="senha" type="password" label="Nova senha" icon={{ source: FaLock }} />

                                        <Textbox name="senha_confirmacao" type="password" label="Confirme a nova senha" icon={{ source: FaLock }} />

                                        <ButtonContainer>
                                            <Button type="submit" className="white" loading={isSubmitting}>
                                                <span>Salvar</span>
                                                <FiSave size={18} />
                                            </Button>
                                        </ButtonContainer>
                                    </PasswordContainer>
                                </Form>
                            )}
                        </Formik>

                        <NotificacaoPermissaoContainer>
                            <h2>Permissões para notificação</h2>

                            <div className="detalhes" dangerouslySetInnerHTML={{ __html: textoDetalhesPermissaoNotificacoes }} />

                            <Checkbox
                                name="email"
                                label="E-mail"
                                withoutForm
                                disabled
                                checked={permissoesNotificacao?.email}
                                tooltip="Este canal não pode ser desabilitado, pois é por meio dele que você poderá recuperar sua senha, caso a perca."
                            />

                            {/* <Checkbox 
                                name="whatsapp" 
                                label="WhatsApp" 
                                withoutForm
                                disabled={!temWhatsApp}
                                onChange={e => { handleUpdatePermissaoNotificacao(e.target) }}
                                checked={temWhatsApp ? permissoesNotificacao?.whatsapp : false}
                                tooltip={
                                    temWhatsApp 
                                        ? 'Marque esta opção caso você queira receber notificações do Sindicato direto em seu WhatsApp.' 
                                        : 'Você não cadastrou nenhum número para WhatsApp. Clique <a href="/associado/dados_cadastrais?contato=1"><b>AQUI</b></a> para incluir seu WhatsApp e poder receber notificações por este canal.'
                                }
                            /> */}
                        </NotificacaoPermissaoContainer>
                    </>
                ) : (
                    <SpinnerContainer>
                        <Spinner size={30} />
                    </SpinnerContainer>
                )}
            </Content>
        </Container>
    )
}
