import React, { memo, useContext } from 'react'

import Header from '../../../components/Header'
import NavOptions from '../../../components/NavOptions'
import Footer from '../../../components/Footer'

import { Container } from './styles'
import { GlobalContext } from '../../../contexts/GlobalContext'

const DefaultLayout = memo(({ children, userData }) => {
    const { clienteRobot } = useContext(GlobalContext)

    if(!clienteRobot) {
        return null
    }

    return (
        <Container>
            <Header usuario={userData} />

            <NavOptions />

            {children}

            <Footer />
        </Container>
    )
})

export default DefaultLayout
