import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'
import { format } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeCalendarGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    periodo: '',
    evento: null,
    consolidado: {
        label: 'Consolidado',
        value: '1'
    }
}

const validation = Yup.object().shape({
    periodo: Yup.string().nullable(),
    evento: Yup.string().nullable(),
    consolidado: Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de Repasses da Instituição de Pagamentos'

export default function (props) {
    const [eventos, setEventos] = useState([])

    async function loadEventos() {
        const response = await api.get('evento_contabil', authHeaders()) 

        setEventos(response)
    }

    useEffect(() => {
        loadEventos()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            periodo,
            evento,
            consolidado
        } = values

        const [inicio, fim] = periodo

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioRepassesIP',
                parametros: {
                    idEvento: evento?.value || '',
                    dataInicio: format(new Date(inicio), 'yyyy-MM-dd'),
                    dataFim: format(new Date(fim), 'yyyy-MM-dd'),
                    consolidado: consolidado.value
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })
            
            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch(e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>
                        
                        <Calendar 
                            name="periodo" 
                            label="Periodo de busca"
                            selectionMode="range"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeCalendarGif}" style="width:300px; height:auto" />`}
                        />

                        <Select 
                            name="consolidado"
                            label="Situação"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Consolidado', value: '1' },
                                { label: 'Não consolidado', value: '0' }
                            ]}
                        />

                        <Select 
                            name="evento"
                            label="Evento"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            options={eventos.filter(evt => [123, 188, 158].includes(evt.id)).map(({ id, nome, conta_contabil }) => ({
                                label: `${nome}${conta_contabil?.codigo_reduzido ? ` - ${conta_contabil.codigo_reduzido}` : ''}`,
                                value: id
                            }))}
                        />
    
                        <Spinner
                            visible={isSubmitting} 
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
