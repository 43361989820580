import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 80px auto;
		grid-gap: 0 18px;
		margin: 0 8px;
		width: calc(100% - 16px);

		.file {
			grid-row: 1/span 2;
			grid-column: 2;
			max-width: 800px;
			width: calc(100% - 8px);
			align-self: flex-start;
		}

		.textbox {
			max-width: 400px;
			width: 100%;
			margin: 5px 0;
		}

		.textarea {
			margin: 5px 0;
			
			textarea {
				height: 162px;
			}
		}

		button {
			margin: 5px 0;
			width: fit-content;
			justify-self: flex-end;
			grid-column: 2;
		}
	}
`
