import React, {
    useEffect, useState, useCallback, useContext
} from 'react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { Formik, Form } from 'formik'
import {
    FiSearch, FiEye, FiEdit, FiPlusCircle, FiDownload, FiFile
} from 'react-icons/fi'
import * as icons from 'react-icons/fi'
import ReactTooltip from 'react-tooltip'

import Yup from '../../../../services/yup'
import { api, authHeaders } from '../../../../services/api'

import {
    Textbox, Button, Select, Calendar
} from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import Card from '../../../../components/Card'
import Modal from '../../../../components/Modal'
import MenuOptions from '../../../../components/MenuOptions'

import arrayUtils from '../../../../util/array'
import scrollUtils from '../../../../util/scroll'
import formUtils from '../../../../util/form'
import { stringToDate, getDateString, getDate } from '../../../../util/date'
import { forceDownloadFileFromURL } from '../../../../util/file'
import { loadTips } from '../../../../util/tip'

import BoxDadosPessoais from '../../../../boxes/EspacoMedico/DadosPessoais'
import BoxEndereco from '../../../../boxes/EspacoMedico/Endereco'
import BoxPagamento from '../../../../boxes/EspacoMedico/Pagamento'
import BoxContatos from '../../../../boxes/EspacoMedico/Contatos'
import BoxArquivos from '../../../../boxes/Arquivos'
import BoxObservacao from '../../../../boxes/Observacao'

import { Financeiro, Juridico, PlanoSaude } from '../../../../components/Associado'
import GerenciamentoDocumentos from '../../../../components/GerenciamentoDocumentos'
import Table from '../../../../components/Table'

import avatarDefault from '../../../../assets/images/avatar.png'

import {
    Container, Profile, Avatar, DadosContainer, SearchFormContainer, ResultTableContainer, ModalEdicaoStatusContainer, BoxDocumentos, SelecaoDocumentosContainer
} from './styles'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import { TipContext } from '../../../../contexts/TipContext'

import useQuery from '../../../../hooks/useQuery'

const validation = Yup.object({
    crm: Yup.number()
        .typeError('Digite apenas números.')
        .min(1, 'O CRM não pode ser zero.')
        .max(9999999, 'Permitido até 7 dígitos.'),
    nome: Yup.string()
})

export default function () {
    const { setCodigo } = useContext(TipContext)
    const { user } = useContext(GlobalContext)

    const { c: crmURL } = useQuery()

    const eAcademico = [16, 21, 22].includes(user?.perfil.id)

    const [pessoa, setPessoa] = useState(null)
    const [searchFields, setSearchFields] = useState(crmURL ? { crm: crmURL } : {})
    const [searching, setSearching] = useState(false)
    const [showFinanceiroDetalhe, setShowFinanceiroDetalhe] = useState(false)
    const [showPlanoSaudeDetalhe, setShowPlanoSaudeDetalhe] = useState(false)
    const [showEdicaoStatusAssociacao, setShowEdicaoStatusAssociacao] = useState(false)
    const [associadosFound, setAssociadosFound] = useState([])
    const [editable, setEditable] = useState(false)
    const [statusAssociacao, setStatusAssociacao] = useState([])
    const [motivosDesligamento, setMotivosDesligamento] = useState([])
    const [showModalDocumentos, setShowModalDocumentos] = useState(false)
    const [showModalDownloadDocumentos, setShowModalDownloadDocumentos] = useState(false)
    const [gerandoPDFDocumentos, setGerandoPDFDocumentos] = useState(false)
    const [gerandoComprovanteAssociacao, setGerandoComprovanteAssociacao] = useState(false)

    const loadPessoa = useCallback(async (name_exact = false) => {
        try {
            const { crm: searchCRM, nome: searchNome } = searchFields

            const associados = await api.get('associado', {
                params: {
                    matricula: searchCRM || null,
                    nome: searchNome?.trim() || null,
                    name_exact: name_exact && searchNome ? 1 : 0
                }
            })

            if (!associados) {
                toast.error('Nenhum associado encontrado.')
                return
            }

            if (associados.length > 1) {
                setAssociadosFound(associados)
                return
            }

            const associado = associados[0]

            const response = await api.get(`pessoa/${associado.pessoa.id}`, authHeaders())

            const matricula = associado.pessoa.documentos.find(documento => documento.tipo_documento.id === 999)?.identificador

            if (!matricula) {
                toast.error('Matrícula não consta na base de dados.')
                return
            }

            const contribuicoesPendentes = await api.get('contribuicao', {
                params: {
                    associado_id: associado.id,
                    status: 0
                },
                ...authHeaders()
            })

            response.matricula = matricula
            response.tipoPagamentoAprovado = response.associado.tipo_pagamento_aprovacao_status

            const tipoPagamento = response?.associado?.tipo_pagamento
            const dadosBancarios = response?.associado?.dados_bancarios

            const dadosInstituicao = response?.associado?.instituicoes?.find(instituicao => instituicao.associado_instituicao.desconto)

            const dadosCartaoCredito = response?.associado?.dados_cartao_credito

            response.pagamento = {
                tipo: tipoPagamento,
                dados_bancarios: tipoPagamento?.id === 1 && dadosBancarios ? {
                    ...dadosBancarios,
                    instituicao: dadosBancarios.instituicao
                } : null,
                dados_instituicao: tipoPagamento?.id === 2 && dadosInstituicao ? {
                    ...dadosInstituicao,
                    matricula: dadosInstituicao.associado_instituicao.matricula
                } : null,
                dados_cartao_credito: dadosCartaoCredito
            }

            const [sindicalizacaoMaisRecente] = response.associado.sindicalizacao.length ? response.associado.sindicalizacao
                .sort(arrayUtils.sort.comparisonFunction('data', 'desc')) : [null]

            response.sindicalizacao = sindicalizacaoMaisRecente?.data ? {
                data: format(new Date(sindicalizacaoMaisRecente.data), 'dd/MM/yyyy'),
                status: sindicalizacaoMaisRecente.status,
                motivo: sindicalizacaoMaisRecente.motivo_desligamento?.descricao,
                observacao: sindicalizacaoMaisRecente.observacao
            } : ''

            response.contribuicoes_pendentes = contribuicoesPendentes.length

            const nomes = response.nome.split(' ')
            response.nome_reduzido = `${nomes[0]} ${nomes.slice(1, nomes.length - 1).map(sobrenome => `${sobrenome[0]}. `).join(' ')}${nomes[nomes.length - 1]}`

            response.socio = response.associado.status_associacao.id === 3
            response.status = response.associado.status_associacao.descricao

            response.documentos = response.associado.pessoa.documentos

            response.endereco = response.associado.pessoa.endereco

            response.contatos = [
                ...response.associado.pessoa.contato.map(contato => ({
                    ...contato,
                    icone: contato.tipo_contato.icone
                }))
            ]

            const rgExtraData = response.documentos?.find(documento => documento.tipo_documento.id === 1)?.extra
            const rg_data_emissao = rgExtraData?.data_emissao || ''
            const rg_orgao_emissor = rgExtraData?.orgao_emissor || ''

            response.dados_pessoais = {
                cpf: response.documentos?.find(documento => documento.tipo_documento.id === 2)?.identificador || '',
                data_nascimento: response.data_nascimento ? format(stringToDate(response.data_nascimento), 'dd/MM/yyyy') : '',
                rg: response.documentos?.find(documento => documento.tipo_documento.id === 1)?.identificador || '',
                rg_data_emissao: rg_data_emissao ? format(stringToDate(`${rg_data_emissao} 00:00:00`), 'dd/MM/yyyy') : '',
                rg_orgao_emissor
            }

            response.observacao = response.associado.observacao

            setPessoa(response)
        } catch (e) {
            console.log(e)
            toast.error(e.msg)
        } finally {
            setSearching(false)
        }
    }, [searchFields])

    async function loadStatusAssociacao() {
        const response = await api.get('associado_status', authHeaders())

        setStatusAssociacao(response
            .filter(status => [3, 5].includes(status.id))
            .map(status => ({
                value: status.id,
                label: status.descricao
            })))
    }

    async function loadMotivosDesligamento() {
        const response = await api.get('motivo_desligamento')

        setMotivosDesligamento(response.map(m => ({
            label: m.descricao,
            value: m.id
        })))
    }

    // async function loadLocaisAposentadoria() {
    //     const locais = await api.get('instituicao', authHeaders())

    //     setLocaisAposentadoria(locais)
    // }

    useEffect(() => {
        if (!statusAssociacao.length && showEdicaoStatusAssociacao) {
            loadStatusAssociacao()
            loadMotivosDesligamento()
            //loadLocaisAposentadoria()
        }
    }, [showEdicaoStatusAssociacao])

    useEffect(() => {
        const { crm, nome, name_exact } = searchFields

        if (crm || nome) {
            loadPessoa(name_exact)
        }
    }, [searchFields, loadPessoa])

    function verDetalhes(associado) {
        setSearchFields({
            name_exact: true,
            crm: associado.crm,
            nome: associado.nome
        })
    }

    useEffect(() => {
        if (user) {
            setEditable(![12, 15].includes(user.perfil.id))
        }
    }, [user])

    useEffect(() => {
        loadTips(setCodigo, 'form_associado_ficha_associado')
    }, [])

    async function reload() {
        setPessoa(null)
        setSearching(true)
        setShowModalDocumentos(false)

        await loadPessoa()

        scrollUtils.toTop()
    }

    function handleSearch(values) {
        const { crm, nome } = values

        if (!crm && !nome) {
            toast.info('Digite um termo de busca.')
            return
        }

        setSearching(true)

        const transformedCRM = Number(crm).toString()

        setSearchFields({
            crm: transformedCRM === '0' ? null : transformedCRM,
            nome: nome || null,
            name_exact: false,
            medico: true
        })
    }

    async function alterarStatusAssociacao(values) {
        try {
            values = formUtils.extractFormValues(values)

            const {
                status, motivo_desligamento, data_falecimento, data_aposentadoria, locais_aposentadoria
            } = values

            let body

            switch (status) {
                case 3:
                    body = {
                        status
                    }
                    break

                case 5:
                    body = {
                        status,
                        motivo_desligamento,
                        data_falecimento
                    }
                    break

                // case 7:
                //     body = {
                //         status,
                //         data_aposentadoria,
                //         locais_aposentadoria: locais_aposentadoria.map(local => local.value)
                //     }
                //     break

                default:
                    body = {
                        status
                    }
            }

            await api.put(`associado/${pessoa.associado.id}`, body, authHeaders())

            toast.success('Status alterado com sucesso.')

            setShowEdicaoStatusAssociacao(false)

            loadPessoa()
        } catch (e) {
            console.log(e)

            toast.error(e.message)
        }
    }

    // async function alterarStatusAcademico(values) {
    //     try {
    //         const { status } = formUtils.extractFormValues(values)

    //         await api.put(`associado/${pessoa.associado.id}`, {
    //             aspirante: status
    //         }, authHeaders())

    //         toast.success('Alteração realizada com sucesso.')
    //         setShowEdicaoAspirante(false)
    //         loadPessoa()
    //     } catch (e) {
    //         toast.error(e.message)
    //     }
    // }

    async function updateDocumentos(values) {
        try {
            await api.put(`documentos/${pessoa.id}`, {
                documentos: values.documentos
                    .map(doc => ({
                        arquivo: doc.arquivo,
                        identificador: doc.identificador,
                        extra: doc.validade ? {
                            validade: getDateString(getDate(doc.validade))
                        } : doc.dataEmissao && doc.orgaoEmissor ? {
                            data_emissao: getDateString(getDate(doc.dataEmissao)),
                            orgao_emissor: doc.orgaoEmissor
                        } : doc.orgaoEmissor ? { orgao_emissor: doc.orgaoEmissor }
                            : doc.dataEmissao ? {
                                data_emissao: getDateString(getDate(doc.dataEmissao))
                            } : null,
                        tipo_documento: doc.tipo_documento.value
                    }))
            }, authHeaders())

            toast.success('Documentos atualizados.')

            reload()
        } catch (e) {
            console.log(e)
            toast.error('Ocorreu um erro ao tentar atualizar os documentos.')
        }
    }

    async function handleDownloadMultiple(selecionados, pessoaId) {
        try {
            setGerandoPDFDocumentos(true)

            const response = await api.post(`convenio_associado/documentos/${pessoaId}`, {
                tiposDocumentoIds: selecionados
            }, authHeaders())

            forceDownloadFileFromURL(response.file)

            toast.success('Faça o download do arquivo PDF.')

            setShowModalDownloadDocumentos(false)
        } catch (e) {
            console.log(e)

            toast.error(e.msg)
        }

        setGerandoPDFDocumentos(false)
    }

    async function handleGetComprovanteAssociacao() {
        try {
            setGerandoComprovanteAssociacao(true)

            const { link } = await api.get(`associacao/comprovante/${pessoa.associado.id}`, authHeaders())

            forceDownloadFileFromURL(link)
        } catch (e) {
            toast.error(e.msg)
        } finally {
            setGerandoComprovanteAssociacao(false)
        }
    }

    return (
        <>
            <Container loadedData={!!pessoa}>
                <main className="animated fadeIn">

                    {pessoa ? (
                        <>
                            <Profile>
                                <Avatar
                                    image={pessoa.usuario?.avatar?.link || avatarDefault}
                                    className="animated fadeIn slow"
                                />

                                <h1>
                                    {pessoa.nome}
                                </h1>

                                {pessoa.associado.medico && (
                                    <h2 className="badge">
                                        {`Matrícula: ${pessoa.matricula}`}
                                    </h2>
                                )}

                                {pessoa.associado.medico && (
                                    <>
                                        {[1, 5].includes(pessoa.associado.status_associacao.id)
                                            && !pessoa.sindicalizacao?.status
                                            && pessoa.sindicalizacao?.data
                                            && pessoa.sindicalizacao?.motivo
                                            && (
                                                <h3 className="badge danger">
                                                    {`Cadastro cancelado em: ${pessoa.sindicalizacao?.data || ''}`}
                                                    <br />
                                                    {`Motivo: ${pessoa.sindicalizacao?.motivo}`}
                                                    <br />
                                                    {pessoa.sindicalizacao.observacao && `Observação: ${pessoa.sindicalizacao.observacao}`}
                                                </h3>
                                            )}

                                        {![1, 5].includes(pessoa.associado.status_associacao.id)
                                            && pessoa.sindicalizacao?.status
                                            && pessoa.sindicalizacao?.data
                                            && (
                                                <h3>{`Membro desde: ${pessoa.sindicalizacao?.data || ''}`}</h3>)}
                                    </>
                                )}

                                <MenuOptions
                                    options={
                                        [
                                            {
                                                editable,
                                                icon: FiEdit,
                                                text: `Situação: ${pessoa.status}`,
                                                action: () => { setShowEdicaoStatusAssociacao(true) }
                                            },
                                            {
                                                editable,
                                                icon: FiFile,
                                                text: 'Comprovante de cadastro',
                                                action: handleGetComprovanteAssociacao,
                                                loading: gerandoComprovanteAssociacao
                                            }
                                        ]
                                    }
                                />
                            </Profile>

                            <DadosContainer>
                                <BoxDadosPessoais pessoa={pessoa} reload={reload} editable={editable} />

                                <BoxEndereco pessoa={pessoa} reload={reload} editable={editable} />

                                <BoxPagamento
                                    pessoa={pessoa}
                                    usuario={user}
                                    reload={reload}
                                    showEditIcon={editable}
                                    enableEdit={!pessoa.contribuicoes_pendentes && pessoa?.associado.status_associacao.id === 3 && pessoa.tipoPagamentoAprovado && ![12, 15].user?.perfil.id}
                                    disabled={eAcademico}
                                    disableLabel={pessoa.contribuicoes_pendentes
                                        ? 'Não é possível alterar a forma de pagamento enquanto houverem pagamentos pendentes.'
                                        : pessoa?.associado.status_associacao.id !== 3
                                            ? 'Não é possível alterar a forma de pagamento enquanto a situação do seu cadastro não for regularizada.'
                                            : !pessoa.tipoPagamentoAprovado
                                                ? 'Não é possível alterar a forma de pagamento, pois já há uma alteração em análise.'
                                                : 'Esta funcionalidade não está disponível.'}
                                />

                                <BoxObservacao
                                    pessoa={pessoa}
                                    reload={reload}
                                    editable={editable}
                                />

                                <Card className="animated fadeInUp delay-300ms" style={{ gridArea: 'financeiro' }} disabled={!pessoa.associado.medico}>
                                    <Financeiro
                                        pessoa={pessoa}
                                        onIconClick={pessoa.associado.medico ? () => { setShowFinanceiroDetalhe(true) } : null}
                                    />
                                </Card>

                                <BoxContatos pessoa={pessoa} reload={reload} editable={editable} />

                                <BoxDocumentos>
                                    <h1>Documentos</h1>

                                    <FiDownload
                                        data-tip="Baixar"
                                        data-for="tooltip"
                                        onClick={() => { setShowModalDownloadDocumentos(true) }}
                                    />

                                    {editable && (
                                        <FiPlusCircle
                                            data-tip="Adicionar"
                                            data-for="tooltip"
                                            onClick={() => { setShowModalDocumentos(true) }}
                                        />
                                    )}

                                    <BoxArquivos
                                        title={null}
                                        arquivos={pessoa?.documentos.map(doc => ({
                                            link: doc.arquivo?.link || '',
                                            nome: doc.tipo_documento.descricao,
                                            identificador: doc.identificador || '-'
                                        }))}
                                    />
                                </BoxDocumentos>
                            </DadosContainer>
                        </>
                    ) : (
                        <>
                            {!searching && !crmURL ? (
                                <>
                                    <SearchFormContainer>
                                        <Formik
                                            initialValues={{ crm: '', nome: '' }}
                                            validationSchema={validation}
                                            onSubmit={handleSearch}
                                        >
                                            {() => (
                                                <Form>
                                                    <h1>Buscar membro</h1>

                                                    <Textbox name="crm" label="Matrícula" disableSubmitWithEnter={false} />

                                                    <Textbox name="nome" label="Nome" disableSubmitWithEnter={false} />

                                                    <Button type="submit" className="transparent" style={{ gridArea: 'button' }}>
                                                        Buscar
                                                        <FiSearch />
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>

                                        {associadosFound.length > 1 && (
                                            <ResultTableContainer>
                                                <h2>Médicos encontrados</h2>

                                                <Table
                                                    style={{ gridArea: 'table' }}
                                                    headers={[
                                                        { name: 'crm', value: 'Matrícula' },
                                                        { name: 'nome', value: 'Nome' }
                                                    ]}
                                                    data={associadosFound.map(associado => ({
                                                        id: associado.id,
                                                        crm: associado.pessoa.documentos?.find(doc => doc.tipo_documento.id === 3)?.identificador || '',
                                                        nome: associado.pessoa.nome
                                                    }))}
                                                    actions={[
                                                        {
                                                            name: 'visualizar',
                                                            action: line => {
                                                                verDetalhes(line)
                                                            },
                                                            icon: FiEye,
                                                            iconSize: 16
                                                        }
                                                    ]}
                                                />
                                            </ResultTableContainer>
                                        )}

                                    </SearchFormContainer>
                                </>
                            ) : (
                                <Spinner
                                    type="Rings"
                                    size={60}
                                    color="#676767"
                                    label="Buscando..."
                                    containerClass="animated fadeInUp fast"
                                />
                            )}
                        </>
                    )}
                </main>
            </Container>

            <Modal
                isOpen={showFinanceiroDetalhe}
                handleClose={() => setShowFinanceiroDetalhe(false)}
                title="Pagamentos"
                closeOnOverlayClick
            >
                <Financeiro compacto={false} pessoa={pessoa} showTitle={false} />
            </Modal>

            {/* <Modal
                isOpen={showJuridicoDetalhe}
                handleClose={() => setShowJuridicoDetalhe(false)}
                title="Demandas Jurídicas"
                closeOnOverlayClick
            >
                <Juridico compacto={false} pessoa={pessoa} showTitle={false} />
            </Modal> */}

            <Modal
                isOpen={showPlanoSaudeDetalhe}
                handleClose={() => setShowPlanoSaudeDetalhe(false)}
                title="Pagamentos de Plano de Saúde"
                closeOnOverlayClick
            >
                <PlanoSaude compacto={false} pessoa={pessoa} showTitle={false} />
            </Modal>

            <Modal
                isOpen={showEdicaoStatusAssociacao}
                handleClose={() => setShowEdicaoStatusAssociacao(false)}
                title="Alteração de status"
                closeOnOverlayClick
            >
                <Formik
                    onSubmit={alterarStatusAssociacao}
                    initialValues={{ status: null }}
                    validationSchema={Yup.object({
                        status: Yup.string().ensure().required('Selecione o novo status.')
                    })}
                >
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form style={{ height: values.motivo_desligamento?.value === 3 ? 360 : 'auto' }} id="form-status">
                            <ModalEdicaoStatusContainer>
                                <Select
                                    name="status"
                                    label="Situação"
                                    options={statusAssociacao}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                {values.status?.value === 5 && (
                                    <Select
                                        name="motivo_desligamento"
                                        label="Motivo da inativação"
                                        options={motivosDesligamento}
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    />
                                )}

                                {values.status?.value === 5 && values.motivo_desligamento?.value === 3 && (
                                    <Calendar
                                        name="data_falecimento"
                                        label="Data de falecimento"
                                        yearNavigator
                                        maxDate={new Date()}
                                    />
                                )}

                                {/* {values.status?.value === 7 && (
                                    <>
                                        <Calendar
                                            name="data_aposentadoria"
                                            label="Data da aposentadoria"
                                            yearNavigator
                                            maxDate={new Date()}
                                        />

                                        <Select
                                            name="locais_aposentadoria"
                                            label="Locais de aposentadoria"
                                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                            options={locaisAposentadoria.map(local => ({
                                                label: local.nome_fantasia,
                                                value: local.id
                                            }))}
                                            isMulti
                                        />
                                    </>
                                )} */}

                                <div className="button-container">
                                    <Button type="submit" className="white" loading={isSubmitting}>
                                        Salvar alterações
                                    </Button>
                                </div>
                            </ModalEdicaoStatusContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            {/* <Modal
                isOpen={showEdicaoAspirante}
                handleClose={() => setShowEdicaoAspirante(false)}
                title="Tornar acâdemico aspirante"
                closeOnOverlayClick
            >
                <Formik
                    onSubmit={alterarStatusAcademico}
                    initialValues={{ status: null }}
                    validationSchema={Yup.object({
                        status: Yup.string().ensure().required('Selecione o novo status.')
                    })}
                >
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form style={{ height: values.motivo_desligamento?.value === 3 ? 360 : 'auto' }}>
                            <ModalEdicaoStatusContainer>
                                <Select
                                    name="status"
                                    label="Aspirante?"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={[
                                        { label: 'Sim', value: 1 },
                                        { label: 'Não', value: 0 }
                                    ]}
                                />

                                <Button type="submit" className="white" loading={isSubmitting}>
                                    Alterar
                                </Button>
                            </ModalEdicaoStatusContainer>
                        </Form>
                    )}
                </Formik>
            </Modal> */}

            <Modal
                isOpen={showModalDocumentos}
                handleClose={() => { setShowModalDocumentos(false) }}
                title="Gerenciamento de documentos"
            >
                <GerenciamentoDocumentos
                    handleSubmit={updateDocumentos}
                    documentos={pessoa?.documentos.map(doc => ({
                        id: doc.id,
                        arquivo: doc.arquivo,
                        tipo_documento: doc.tipo_documento,
                        extra: doc.extra,
                        identificador: doc.identificador
                    }))}
                    categoria="medico"
                />
            </Modal>

            <Modal
                isOpen={!!showModalDownloadDocumentos}
                handleClose={(() => { setShowModalDownloadDocumentos(false) })}
                title="Selecione os documentos"
            >
                <SelecaoDocumentosContainer>
                    <Table
                        headers={[
                            { name: 'descricao', value: 'Documento' }
                        ]}
                        data={pessoa?.documentos.map(doc => ({
                            id: doc.tipo_documento.id,
                            descricao: doc.tipo_documento.descricao
                        }))}
                        checkable
                        checkboxActions={[
                            {
                                icon: FiDownload,
                                handle: selecionados => handleDownloadMultiple(selecionados, pessoa.id),
                                tooltip: 'Fazer download',
                                display: 'button',
                                text: 'Fazer download',
                                loading: gerandoPDFDocumentos
                            }
                        ]}
                    />
                </SelecaoDocumentosContainer>
            </Modal>

            <ReactTooltip
                id="tooltip"
                place="top"
                effect="solid"
                type="light"
            />
        </>
    )
}
