import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'

import {
    Button, Textbox, Textarea, File, Select 
} from '../../../../../../components/Form'

import { Container } from './styles'

import { TutorialContext } from '../../../../../../contexts/TutorialContext'

import { api } from '../../../../../../services/api'

const baseInitialValues = {
    descricao: '',
    detalhes: '',
    palavras_chave: '',
    arquivo: null,
    assunto: null
}

export default function () {
    const { handleSubmitTutorial, assuntos, editTutorial } = useContext(TutorialContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)

    useEffect(() => {
        if(editTutorial) {
            setInitialValues({
                ...editTutorial,
                arquivo: editTutorial.arquivo.id,
                assunto: {
                    label: editTutorial.assunto.descricao,
                    value: editTutorial.assunto.id
                }
            })
        }
    }, [editTutorial])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitTutorial}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <Select 
                            name="assunto"
                            label="Assunto"
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            options={assuntos.map(a => ({
                                label: a.descricao,
                                value: a.id
                            }))}
                        />

                        <File
                            name="arquivo"
                            label="Vídeo, imagem ou PDF do tutorial (opcional)"
                            format="square"
                            onSuccess={fileId => setFieldValue('arquivo', fileId)}
                            previewSize={['100%', '242px']}
                            accept={['image/*', 'video/*', 'application/pdf']}
                            maxSize={100 * 1024 * 1024}
                            getPreloadImage={async () => {
                                if(values.arquivo) {
                                    const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                    return arquivo?.link
                                }
                            }}
                        />

                        <Textbox 
                            name="descricao"
                            label="Nome do tópico"
                        />

                        <Textbox 
                            name="palavras_chave"
                            label="Palavras-chave"
                            placeholder="perfil senha acesso segurança..."
                        />

                        <Textarea 
                            name="detalhes"
                            label="Detalhes"
                        />

                        <Button className="transparent" type="submit">
                            Cadastrar
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
