import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			min-height: 82vh;

			> h1 {
				margin: 0 8px 24px;
			}

			> p {
				margin-top: 10px;
				margin-bottom: 18px;
				margin-left: 9px;
			}
		}

		@media(max-width: 700px) {
			padding: 0;
		}
	}
`
