import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import { format, getYear } from 'date-fns'

import Card from '../../../components/Card'
import { Button, Textbox } from '../../../components/Form'
import Spinner from '../../../components/Spinner'

import { api, authHeaders } from '../../../services/api'

import { Container } from './styles'

import { stringToDate } from '../../../util/date'
import stringUtils from '../../../util/string'
import masks from '../../../util/masks'

function getGrupoDescricao(grupo) {
    if(grupo === 'medico') {
        return 'médico'
    }

    return grupo
}

export default function () {
    const [parametrosSistema, setParametrosSistema] = useState(null)
    const [parametrosSindicato, setParametrosSindicato] = useState(null)

    async function loadParametros(tipos = ['sindicato', 'sistema']) {
        const { parametros_sistema, valores_mensalidade, descontos_anuidade } = await api.get('parametro', {
            ...authHeaders(),
            params: {
                manipulaveis: 1
            }
        })

        if(tipos.includes('sistema')) {
            setParametrosSistema(parametros_sistema)
        }

        if(tipos.includes('sindicato')) {
            setParametrosSindicato([
                ...valores_mensalidade.map(val => ({
                    id: val.id,
                    nome: `Valor da mensalidade ${val.ano}`,
                    valor: masks.maskApply.currency(val.valor),
                    ano: val.ano,
                    editavel: val.ano === getYear(new Date()),
                    tipo: 'mensalidade'
                })),
                ...descontos_anuidade.map(val => ({
                    id: val.id,
                    nome: `Descontos para ${getGrupoDescricao(val.grupo)}s ${val.ano} até ${format(stringToDate(val.fim_desconto), 'dd/MM/yyyy')}`,
                    valor: val.desconto,
                    grupo: stringUtils.capitalize(getGrupoDescricao(val.grupo)),
                    inicio: format(stringToDate(val.inicio_desconto), 'dd/MM/yyyy'),
                    fim: format(stringToDate(val.fim_desconto), 'dd/MM/yyyy'),
                    ano: val.ano,
                    editavel: val.ano === getYear(new Date()),
                    tipo: 'desconto'
                }))
            ])
        }
    }

    async function handleSubmitSistema(values) {
        try {
            const { nome, valor } = values

            await api.put(`parametro/${nome}`, { valor }, authHeaders())

            toast.success('Parâmetro atualizado.')

            loadParametros(['sistema'])

            document.querySelector('#sistema').setAttribute('open', true)
        } catch(e) {
            toast.error(e.message)
        }
    }

    async function handleSubmitSindicato(values) {
        try {
            const {
                identificador, valor, inicio, fim 
            } = values
            const [tipo, id] = identificador.split('_')

            const body = tipo === 'mensalidade' ? {
                valor
            } : {
                valor, 
                inicio: stringToDate(inicio), 
                fim: stringToDate(fim)
            }

            await api.put(`parametro_sindicato/${tipo}/${id}`, body, authHeaders())

            toast.success('Parâmetro atualizado.')

            loadParametros(['sindicato'])

            document.querySelector('#sindicato').setAttribute('open', true)
        } catch(e) {
            toast.error(e.message)
        }
    }

    function handleAlterarParametroSistema(value, index) {
        parametrosSistema[index].valor_alterado = value

        parametrosSistema[index].alterado = parametrosSistema[index].valor_alterado !== parametrosSistema[index].valor
        
        setParametrosSistema(parametrosSistema)
    }

    function handleAlterarParametroSindicato({ value, name }, index, tipo) {
        parametrosSindicato[index].valor_alterado = value

        const valorOriginal = tipo === 'desconto' && name === 'valor' 
            ? masks.maskApply.percent(parametrosSindicato[index][name], 0)
            : parametrosSindicato[index][name] 
        
        parametrosSindicato[index].alterado = parametrosSindicato[index].valor_alterado !== valorOriginal

        setParametrosSindicato(parametrosSindicato)
    }

    useEffect(() => {
        loadParametros()
    }, [])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Parâmetros</h1>

                    {parametrosSistema ? (
                        <details id="sistema">
                            <summary>Parâmetros de sistema</summary>

                            <div className="content animated fadeIn fast delay-700ms">
                                <ul>
                                    {parametrosSistema.map((param, index) => (
                                        <li key={param.nome}>
                                            <span>{param.descricao}</span>

                                            <Formik
                                                onSubmit={handleSubmitSistema}
                                                initialValues={{
                                                    valor: param.valor,
                                                    nome: param.nome
                                                }}
                                            >
                                                {({ setFieldValue, isSubmitting }) => (
                                                    <Form>
                                                        <Textbox 
                                                            name="valor"
                                                            label={null}
                                                            disabled={param.critico}
                                                            onChange={e => { 
                                                                setFieldValue('valor', e.target.value)
															
                                                                handleAlterarParametroSistema(e.target.value, index)
                                                            }}
                                                        />
											
                                                        {param.critico ? (
                                                            <span>Parâmetro crítico</span>
                                                        ) : (
                                                            <>
                                                                {param.alterado ? (
                                                                    <Button className="transparent" type="submit" loading={isSubmitting}>
                                                                        Confirmar alteração
                                                                    </Button>
                                                                ) : (
                                                                    <div />
                                                                )}
                                                            </>
                                                        )}

                                                        <Field 
                                                            type="hidden"
                                                            name="nome"
                                                        />
                                                    </Form>
                                                )}
                                            </Formik>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </details>
                    ) : (
                        <Spinner />
                    )}

                    {parametrosSindicato ? (
                        <details id="sindicato">
                            <summary>Parâmetros do sindicato</summary>

                            <div className="content animated fadeIn fast delay-700ms">
                                <ul>
                                    {parametrosSindicato.map((param, index) => (
                                        <li>
                                            <span>{param.nome}</span>

                                            <Formik
                                                onSubmit={handleSubmitSindicato}
                                                initialValues={param.tipo === 'mensalidade' ? {
                                                    valor: param.valor,
                                                    identificador: `${param.tipo}_${param.id}`
                                                } : {
                                                    valor: param.valor,
                                                    identificador: `${param.tipo}_${param.id}`,
                                                    inicio: param.inicio,
                                                    fim: param.fim
                                                }}
                                            >
                                                {({ isSubmitting, setFieldValue }) => (
                                                    <Form>
                                                        <Textbox 
                                                            name="valor"
                                                            label={param.tipo === 'mensalidade' ? 'Valor da mensalidade' : 'Percentual de desconto'}
                                                            mask={param.tipo === 'mensalidade' ? masks.money : masks.percent}
                                                            onChange={e => { 
                                                                setFieldValue('valor', e.target.value)
															
                                                                handleAlterarParametroSindicato(e.target, index, param.tipo)
                                                            }}
                                                        />

                                                        {param.tipo === 'desconto' && (
                                                            <>
                                                                <Textbox 
                                                                    label="Início do desconto"
                                                                    name="inicio"
                                                                    mask={masks.date}
                                                                    onChange={e => { 
                                                                        setFieldValue('inicio', e.target.value)
                                                                    
                                                                        handleAlterarParametroSindicato(e.target, index, param.tipo)
                                                                    }}
                                                                />

                                                                <Textbox 
                                                                    label="Fim do desconto"
                                                                    name="fim"
                                                                    mask={masks.date}
                                                                    onChange={e => { 
                                                                        setFieldValue('fim', e.target.value)
                                                                    
                                                                        handleAlterarParametroSindicato(e.target, index, param.tipo)
                                                                    }}
                                                                />
                                                            </>
                                                        )}

                                                        {param.alterado && (
                                                            <Button className="transparent" type="submit" loading={isSubmitting}>
                                                                Confirmar alteração
                                                            </Button>
                                                        )}

                                                        <Field 
                                                            type="hidden"
                                                            name="identificador"
                                                        />
                                                    </Form>
                                                )}
                                            </Formik>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </details>
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
