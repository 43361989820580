import styled from 'styled-components'

export const Container = styled.div`
	@media(max-width: 670px) {
		.table-container {
			display: none;
		}
	}
`
export const ExtraText = styled.p`
	display: flex;
	align-items: center;
	background-color: ${(props) => props.color || '#ffeb3bDD'};
	color: ${(props) => props.textColor || '#424242'};
	padding: 6px 12px;
	font-size: 12px;
	border-radius: 10px;
	margin-top: 20px;
	font-weight: 500;
	width: fit-content;

	svg {
		margin-right: 10px;
	}
`
export const BadgeContainer = styled.p`
	display: flex;
	gap: 10px;
`

export const DadosAssociadoContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1.9fr;
	grid-template-areas: 
		'f1 f2 f2 f2'
		'tabela tabela tabela tabela'
		'vencimento juros multa forma_pagamento'
		'parcelas f3 f3 x'
		'descontos descontos descontos descontos'
		'button button button button';
	margin: 14px 8px 8px;
	font-size: 16px;

	.table-container {
		grid-area: tabela;
		margin-bottom: 20px;
	}

	button {
		width: fit-content;
		justify-self: flex-end;
		margin-bottom: 10px;
	}
`

export const RegrasDescontoContainer = styled.div.attrs({
	className: 'descontos-container'
})`
	grid-area: descontos;
	max-width: 700px;

	h1 {
		font-size: 18px;
	}
`

export const RegraDesconto = styled.div.attrs({
	className: 'desconto-item animated fadeInLeft faster'
})`
	display: grid;
	grid-template-columns: 1fr 1fr 40px;
	align-items: flex-end;
	grid-gap: 12px;

	button {
		width: 34px;
		height: 34px;
		margin-bottom: 12px;
	}
`
