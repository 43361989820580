import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FiEdit, FiAlertTriangle } from 'react-icons/fi'
import { format } from 'date-fns'

import { api, authHeaders } from '../../../services/api'

import Spinner from '../../../components/Spinner'
import Modal from '../../../components/Modal'
import FormPagamento from '../../../forms/Pagamento'

import { Container } from './styles'
import { Checkbox } from '../../../components/Form'

const boxName = 'Método de pagamento'

const baseInitialValues = { tipo: '' }

export default function ({
    pessoa,
    reload,
    enableEdit,
    showEditIcon = true,
    disableLabel = 'Não é possível alterar a forma de pagamento enquanto houverem contribuições pendentes.',
    ignoreAlert = false,
    title,
    style = {},
    data,
    disabled = false
}) {
    const [editMode, setEditMode] = useState(false)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    function handlePrepareEdit() {
        const {
            tipo, dados_bancarios, dados_instituicao, dados_cartao_credito
        } = pessoa.pagamento

        let dadosPagamento = {}

        switch (tipo?.id) {
            case 1:
                dadosPagamento = dados_bancarios
                break
            case 2:
                dadosPagamento = {
                    instituicao: {
                        value: dados_instituicao.id,
                        label: dados_instituicao.nome_fantasia
                    },
                    matricula: dados_instituicao.associado_instituicao.matricula
                }
                break
            case 3:
                if (!dados_cartao_credito) {
                    dadosPagamento = {
                        titular: '',
                        numero: '',
                        expiracao: '',
                        cvv: ''
                    }
                    break
                }

                const {
                    holder_name, display_number, month, year
                } = dados_cartao_credito

                dadosPagamento = {
                    titular: holder_name,
                    numero: display_number,
                    expiracao: new Date(year, month - 1),
                    cvv: 'xxx'
                }
                break
            case 4:
                break
            case 5:
                break
            default: break
        }

        setInitialValues({
            tipo,
            dadosPagamento
        })

        setEditMode(true)
    }

    return (
        <>
            <Container className="animated fadeInUp delay-400ms no-responsive" style={style} disabled={disabled}>
                <h1>{title || boxName}</h1>

                {showEditIcon ? (
                    <FiEdit
                        size={20}
                        data-for="tooltip"
                        data-tip={enableEdit ? 'Editar' : 'Edição desabilitada'}
                        onClick={enableEdit ? handlePrepareEdit : null}
                        style={!enableEdit ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                    />
                ) : (
                    <div />
                )}

                {pessoa?.associado.medico && (
                    <div>
                        <span>
                            <b>Tipo de pagamento: </b>
                            {pessoa.pagamento.tipo?.descricao || 'Indefinido'}
                        </span>

                        {pessoa.pagamento.tipo?.id === 1 && (
                            <>
                                <span>
                                    <b>Banco: </b>
                                    {pessoa.pagamento.dados_bancarios.instituicao.sigla}
                                </span>
                                <span>
                                    <b>Agência: </b>
                                    {pessoa.pagamento.dados_bancarios.agencia}
                                </span>
                                <span>
                                    <b>Conta: </b>
                                    {pessoa.pagamento.dados_bancarios.conta}
                                </span>
                            </>
                        )}

                        {pessoa.pagamento.tipo?.id === 2 && (
                            <>
                                <span>
                                    <b>Instituição: </b>
                                    {pessoa.pagamento.dados_instituicao.nome_fantasia}
                                </span>
                                <span>
                                    <b>Matrícula: </b>
                                    {pessoa.pagamento.dados_instituicao.matricula}
                                </span>
                            </>
                        )}

                        {pessoa.pagamento.tipo?.id === 3 && (
                            <>
                                <span>
                                    <b>Bandeira: </b>
                                    {pessoa.pagamento.dados_cartao_credito?.brand}
                                </span>
                                <span>
                                    <b>Número do cartão: </b>
                                    {pessoa.pagamento.dados_cartao_credito?.display_number}
                                </span>
                                <span>
                                    <b>Vencimento: </b>
                                    {pessoa.pagamento.dados_cartao_credito && format(new Date(pessoa.pagamento.dados_cartao_credito?.year, pessoa.pagamento.dados_cartao_credito.month - 1), 'MM/yyyy')}
                                </span>
                            </>
                        )}

                        {disableLabel && !enableEdit && !ignoreAlert && (
                            <div className="razao-bloqueio animated zoomIn faster delay-500ms">
                                <FiAlertTriangle size={38} />
                                {disableLabel}
                            </div>
                        )}
                    </div>
                )}

                {data && (
                    <div>
                        <span>
                            <b>Tipo de pagamento: </b>
                            {data.pagamento.tipo?.descricao || 'Indefinido'}
                        </span>

                        {disableLabel && !enableEdit && !ignoreAlert && (
                            <div className="razao-bloqueio animated zoomIn faster delay-500ms">
                                <FiAlertTriangle size={38} />
                                {disableLabel}
                            </div>
                        )}
                    </div>
                )}

                {!pessoa && !data && (
                    <Spinner />
                )}
            </Container>

            {pessoa && (
                <Modal
                    isOpen={editMode}
                    handleClose={() => setEditMode(false)}
                    title={boxName}
                >
                    <FormPagamento
                        defaultValues={initialValues}
                        pessoaId={pessoa.id}
                        callback={() => {
                            setEditMode(false)

                            reload()
                        }}
                    />
                </Modal>
            )}

            {data && (
                <Modal
                    isOpen={editMode}
                    handleClose={() => setEditMode(false)}
                    title={boxName}
                >
                    <FormPagamento
                        defaultValues={initialValues}
                        pessoaId={data.id}
                        callback={() => {
                            setEditMode(false)

                            reload()
                        }}
                    />
                </Modal>
            )}
        </>
    )
}
