import React, { useState, useEffect } from 'react'

import { api, authHeaders } from '../../../../services/api'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'

import { Container } from './styles'

import PieChart from '../../../../components/Chart/Pie'

export default function () {
    const [data, setData] = useState(null)
    const [dataTable, setDataTable] = useState(null)

    async function loadAssociadoData() {
        const { count: quites } = await api.get('estatisticas/associado', {
            ...authHeaders(),
            params: {
                count_only: true,
                view: 'quites_carga'
            }
        })

        const { count: nao_quites } = await api.get('estatisticas/associado', {
            ...authHeaders(),
            params: {
                count_only: true,
                view: 'nao_quites_carga'
            }
        })

        const { count: ex_associados } = await api.get('estatisticas/associado', {
            ...authHeaders(),
            params: {
                count_only: true,
                view: 'ex_associados'
            }
        })

        const total = quites + nao_quites + ex_associados

        function getPercentual(value) {
            return `${String(Math.trunc(value * 10) / 10).replace('.', ',')}%`
        }

        setData({
            'Membros quites': {
                value: quites,
                percent: getPercentual((quites * 100) / total)
            },
            'Membros não quites': {
                value: nao_quites,
                percent: getPercentual((nao_quites * 100) / total)
            },
            'Membros Inativos': {
                value: ex_associados,
                percent: getPercentual((ex_associados * 100) / total)
            }
        })

        setDataTable({
            'Membros quites': {
                value: quites,
                percent: getPercentual((quites * 100) / total)
            },
            'Membros não quites': {
                value: nao_quites,
                percent: getPercentual((nao_quites * 100) / total)
            },
            'Membros Inativos': {
                value: ex_associados,
                percent: getPercentual((ex_associados * 100) / total)
            },
            'Total de membros (quites + não quites)': {
                value: quites + nao_quites,
                percent: ''
            }
        })
    }

    useEffect(() => {
        loadAssociadoData()
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Estatísticas</h1>

                    {data && dataTable ? (
                        <>
                            <PieChart data={data} />

                            <Table
                                headers={[
                                    { name: 'grupo', value: 'Grupo' },
                                    { name: 'count', value: 'Quantidade' },
                                    { name: 'percent', value: '% (valores arredondados)', centered: true }
                                ]}
                                data={Object.entries(dataTable).map(([grupo, item]) => ({
                                    id: grupo,
                                    grupo,
                                    count: item.value,
                                    percent: item.percent
                                }))}
                            />
                        </>
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
