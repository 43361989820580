import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import {
    FaPlayCircle, FaPauseCircle, FaTrash, FaEye 
} from 'react-icons/fa'

import { api, authHeaders } from '../../../../../services/api'

import Table from '../../../../../components/Table'
import Modal from '../../../../../components/Modal'
import Spinner from '../../../../../components/Spinner'
import SmallScreenPlaceholder from '../../../../../components/SmallScreenPlaceholder'

import Mensageria from './Detalhes/Mensageria'
import Comando from './Detalhes/Comando'
import Consulta from './Detalhes/Consulta'

import { AutomaticoContext } from '../index'

import { fillWithZeros } from '../../../../../util/number'

import useEventListener from '../../../../../hooks/useEventListener'

import { Container } from './styles'

const tableHideOffset = 500

export function getFrequenceByExpression(expression) {
    const asterisks = expression.match(/\*/g).length
    const [, minute, hour, day, month] = expression.split(' ')

    switch(asterisks) {
        case 1: return `Todo dia ${fillWithZeros(day)}/${fillWithZeros(month)}`
        case 2: return `Todo dia ${fillWithZeros(day)}`
        case 3: return `Todo dia, às ${fillWithZeros(hour)}:${fillWithZeros(minute)}h`
        case 4: return 'Uma vez por hora'
        case 5: return 'Uma vez por minuto'
        default: return 'Frequência não identificada'
    }
}

export default function () {
    const {
        tasks, setTasks, reload, isReloading 
    } = useContext(AutomaticoContext)

    const [taskDetail, setTaskDetail] = useState(null)

    useEventListener('change', () => {
        const horizontal = [90, 270].includes(window.screen.orientation.angle)

        if(horizontal && tableHideOffset > window.innerHeight) {
            toast.info('Infelizmente seu dispositivo não tem dimensões suficientes para visualização dos dados. Consulte a versão web.', { autoClose: 6000 })
        }
    }, window.screen.orientation)

    async function handlePlay(task) {
        try {
            await api.put(`task/play/${task.id}`, {}, authHeaders())

            const targetTask = tasks.find(t => t.id === task.id)

            setTasks(old => [
                ...old.filter(t => t.id !== task.id),
                {
                    ...targetTask,
                    status: true
                }
            ])

            reload()
        } catch(e) {
            toast.error('Ocorreu um erro ao inicializar a tarefa.')
        }
    }

    async function handlePause(task) {
        try {
            await api.put(`task/stop/${task.id}`, {}, authHeaders())

            toast.success('Tarefa interrompida.')

            const targetTask = tasks.find(t => t.id === task.id)
            
            setTasks(old => [
                ...old.filter(t => t.id !== task.id),
                {
                    ...targetTask,
                    status: false
                }
            ])

            reload()
        } catch(e) {
            toast.error('Ocorreu um erro ao inicializar a tarefa.')
        }
    }

    async function handleDelete(task) {
        try {
            setTasks(tasks.filter(t => t.id !== task.id))

            await api.delete(`task/${task.id}`, authHeaders())

            toast.success('Tarefa excluída.')
            
            reload()

            return true
        } catch(e) {
            toast.error('Erro ao excluir tarefa.')

            return false
        }
    }

    async function handleShowTaskDetails(task) {
        setTaskDetail(task)
    }

    function getTaskDetails(task) {
        switch(Number(task.profile_id)) {
            case 1: return <Mensageria task={task} />
            case 2: return <Consulta task={task} />
            case 3: return <Comando task={task} />
            default: return null
        }
    }
    
    function getUptimePorExtenso(uptime) { // uptime está em segundos
        const minute = 60
        const hour = minute * 60
        const day = hour * 24

        if(uptime < minute) {
            return `${uptime} segundo${uptime > 1 ? 's' : ''}`
        } 
        
        if(uptime < hour) {
            const calculado = Math.round(uptime / minute)

            return `${calculado} minuto${calculado > 1 ? 's' : ''}`
        } 
        
        if(uptime < day) {
            const calculado = Math.round(uptime / hour)

            return `${calculado} hora${calculado > 1 ? 's' : ''}`
        }

        const calculado = Math.round(uptime / day)

        return `${calculado} dia${calculado > 1 ? 's' : ''}`
    }

    return (
        <>
            <Container>
                {tasks ? (
                    <>
                        <SmallScreenPlaceholder text="Gire o dispositivo para visualizar as tasks." />

                        <Table 
                            headers={[
                                { name: 'description', value: 'Tarefa' }, 
                                { name: 'frequence', value: 'Frequência', centered: true }, 
                                { name: 'profile', value: 'Tipo de tarefa', centered: true },
                                { name: 'period', value: 'Período', centered: true },
                                { name: 'uptime', value: 'Tempo em execução', centered: true }
                            ]}
                            data={tasks.map(task => ({
                                id: task.id,
                                description: task.description,
                                frequence: getFrequenceByExpression(task.expression),
                                profile: task.profile.description,
                                profile_id: task.profile.id,
                                created_at: format(new Date(task.created_at), 'dd/MM/yyyy HH:mm:ss'),
                                period: task.period,
                                uptime: task.up_time ? getUptimePorExtenso(task.up_time) : '-',
                                status: task.status,
                                data: task.data,
                                expression: task.expression
                            }))}
                            showId
                            filterable
                            hideOnSmallHeaders={['id', 'created_at']}
                            hideOffset={600}
                            itemsByPage={999}
                            filterPosition="left"
                            confirmExclusion={{
                                attr: 'description',
                                template: 'Deseja realmente excluir a tarefa #attr#?'
                            }}
                            actions={[
                                {
                                    action: handlePlay,
                                    name: 'Iniciar',
                                    icon: FaPlayCircle,
                                    iconSize: 16,
                                    checkDisabled: item => item.status || isReloading
                                },
                                {
                                    action: handlePause,
                                    name: 'Parar',
                                    icon: FaPauseCircle,
                                    iconSize: 16,
                                    checkDisabled: item => !item.status || isReloading
                                },
                                {
                                    action: handleDelete,
                                    name: 'Excluir',
                                    icon: FaTrash,
                                    iconSize: 14,
                                    checkDisabled: item => item.status || isReloading,
                                    confirmation: 'Deseja mesmo excluir esta tarefa?'
                                },
                                {
                                    action: handleShowTaskDetails,
                                    name: 'Detalhes',
                                    icon: FaEye,
                                    iconSize: 16
                                }
                            ]}
                        />
                    </>
                ) : (
                    <Spinner size={36} label="Comunicando com o módulo de tarefas automatizadas..." />
                )}
            </Container>

            <Modal
                isOpen={!!taskDetail}
                handleClose={() => { setTaskDetail(null) }}
                title="Detalhes"
            >
                {taskDetail && getTaskDetails(taskDetail)}
            </Modal>
        </>
    )
}
