import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 280px;
		grid-template-areas: 
			'f1 f2 card'
			'f3 f4 card'
			'check check check'
			'button button button';

		.field.calendar,
		.field.textbox {
			width: calc(100% - 16px) !important;
		}

		.field.checkbox {
			width: calc(100% - 16px) !important;
			grid-area: check;
		}

		.credit-card {
			grid-area: card;
			justify-content: start;
		}

		@media(max-width: 1000px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas: 
				'card card'
				'f1 f2'
				'f3 f4'
				'check check'
				'button button';

			.credit-card {
				grid-area: card;
				justify-content: center;
			}
		}

		@media(max-width: 600px) {
			grid-template-columns: 1fr;
			grid-template-areas: 
				'card'
				'f1'
				'f2'
				'f3'
				'f4'
				'check'
				'button';
		}
	}
`
