import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'
import { format } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'
import rangeMonthGif from '../../../../../assets/images/gifs/periodo-mes.gif'

const initialValues = {
    tipo: { label: 'Todas', value: 'R' },
    competencia: '',
    pagamento: ''
}

const validation = Yup.object().shape({
    tipo: Yup.string().ensure().required('Selecione o tipo'),
    competencia: Yup.string().nullable(),
    pagamento: Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de Receitas'

export default function (props) {
    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            tipo,
            competencia,
            pagamento 
        } = values

        const [dataInicio, dataFim] = pagamento
        
        let comp = null
        let competenciaInicio = null
        let competenciaFim = null

        if (competencia) {
            const [inicio, fim] = competencia

            if(inicio.getTime() === fim.getTime()) {
                comp = format(new Date(inicio), 'yyyy-MM-dd')
            } else {
                competenciaInicio = format(new Date(inicio), 'yyyy-MM-dd')
                competenciaFim = format(new Date(fim), 'yyyy-MM-dd') 
            }
        }

        try {
            const response = await api.post('relatorio', {
                relatorio: tipo?.value === 'D' ? 'RelatorioReceitasDetalhado' : 'RelatorioReceitas',
                parametros: {
                    tipo: tipo?.value === 'D' ? 'R' : tipo?.value,
                    dataInicio: dataInicio ? format(new Date(dataInicio), 'yyyy-MM-dd') : null,
                    dataFim: dataFim ? format(new Date(dataFim), 'yyyy-MM-dd') : null,
                    competencia: comp,
                    competenciaInicio,
                    competenciaFim
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })
            
            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch(e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm 
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select 
                            name="tipo"
                            label="Tipo de evento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[ 
                                { label: 'Todas', value: 'R' },
                                { label: 'Receitas detalhadas', value: 'D' },
                                { label: 'Receitas fixas', value: 'RF' }, 
                                { label: 'Receitas variáveis', value: 'RV' }  
                            ]}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Calendar 
                            name="competencia" 
                            dateFormat="mm/yy" 
                            view="month"
                            selectionMode="range"
                            label="Competência"
                            containerStyle={{ gridArea: 'f2' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeMonthGif}" style="width:300px; height:auto" />`}
                        /> 

                        <Calendar 
                            name="pagamento" 
                            label="Período para busca"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f3' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        /> 

                        <Spinner
                            visible={isSubmitting} 
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>
                            
                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
