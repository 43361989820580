import React from 'react'

import AdvogadoProvider from '../../../contexts/AdvogadoContext'

import Listagem from './Listagem'

export default function () {
    return (
        <AdvogadoProvider>
            <Listagem />
        </AdvogadoProvider>
    )
}
