import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form, FieldArray } from 'formik'
import { toast } from 'react-toastify'

import { FiPlus, FiSave, FiTrash } from 'react-icons/fi'
import FormEndereco from '../../../../components/FormEndereco/v2'
import {
    Select, Textbox, Checkbox, Button 
} from '../../../../components/Form'

import Yup from '../../../../services/yup'
import masks from '../../../../util/masks'
import stringUtils from '../../../../util/string'
import formUtils from '../../../../util/form'

import { api, authHeaders } from '../../../../services/api'

import { Container, OrganogramaLinhaContainer } from './styles'
import { TipContext } from '../../../../contexts/TipContext'

const baseInitialValues = {
    razao_social: '',
    nome_fantasia: '',
    sigla: '',
    cnpj: '',
    tipo: null,
    desconto: false,
    endereco_id: null,
    cep: '',
    uf: '',
    cidade: '',
    bairro: '',
    logradouro: '',
    numero: '',
    complemento: '',
    organograma: []
}

const validation = Yup.object({
    razao_social: Yup.string().required('Informe a Razão Social.'),
    nome_fantasia: Yup.string().required('Informe o Nome Fantasia.'),
    sigla: Yup.string(),
    cnpj: Yup.string().required('Informe o CNPJ.'),
    tipo: null,
    desconto: false,
    endereco_id: null,
    cep: Yup.string().required('Informe o CEP.'),
    uf: Yup.string().required('Informe a UF.'),
    cidade: Yup.string().required('Informe a cidade.'),
    bairro: Yup.string().required('Informe o bairro.'),
    logradouro: Yup.string().required('Informe o logradouro.'),
    numero: Yup.string(),
    complemento: Yup.string(),
    organograma: Yup.array().of(Yup.object({
        descricao: Yup.string().required('Informe o nome do nível.'),
        pessoa: Yup.string().required('Informe o nome do representante.'),
        email: Yup.string().required('Informe o e-mail do representante.'),
        fone: Yup.string()
    }))
})

export default function ({ instituicao, reload, edicao }) {
    const { tips } = useContext(TipContext)

    const [tiposInstituicao, setTiposInstituicao] = useState(null)
    const [initialValues] = useState(instituicao?.id ? {
        ...instituicao,
        ...(instituicao.endereco || {}),
        logradouro: instituicao.endereco?.rua || '',
        uf: instituicao.endereco?.uf ? { label: instituicao.endereco.uf, value: instituicao.endereco.uf } : null,
        cidade: instituicao.endereco?.cidade ? { label: instituicao.endereco.cidade, value: instituicao.endereco.cidade } : null,
        tipo: instituicao.tipo_instituicao ? {
            label: instituicao.tipo_instituicao.descricao,
            value: instituicao.tipo_instituicao.id
        } : null,
        organograma: instituicao.organograma.map(org => ({
            id: org.id,
            descricao: org.descricao,
            pessoa: org.pessoa.nome,
            email: org.pessoa.contato.find(c => c.tipo_contato_id === 6)?.contato || '',
            fone: org.pessoa.contato.find(c => c.tipo_contato_id !== 6)?.contato || '',
            pai: { label: org.pai?.descricao, value: org.pai?.descricao }
        }))
    } : baseInitialValues)

    async function loadInsituicoes() {
        const response = await api.get('tipo_instituicao', authHeaders())

        setTiposInstituicao(response.map(tipo => ({
            label: stringUtils.capitalize(tipo.descricao),
            value: tipo.id
        })))
    }

    useEffect(() => {
        loadInsituicoes()
    }, [])

    async function handleSubmit(values) {
        values = formUtils.extractFormValues(values)

        for(const item of values.organograma) {
            if(item.pai) {
                item.pai = item.pai.value
            }
        }

        values.endereco = {
            cep: values.cep,
            uf: values.uf,
            cidade: values.cidade,
            bairro: values.bairro,
            rua: values.logradouro,
            numero: values.numero,
            complemento: values.complemento
        }

        delete values.cep
        delete values.uf
        delete values.cidade
        delete values.bairro
        delete values.logradouro
        delete values.numero
        delete values.complemento

        try {
            if(edicao) {
                await api.put(`instituicao/${instituicao.id}`, values, authHeaders())

                toast.success('Instituição alterada com sucesso.')
            } else {
                await api.post('instituicao', values, authHeaders())

                toast.success('Instituição cadastrada com sucesso.')
            }

            reload()
        } catch(e) {
            if(e.code === 409) {
                toast.warn(e.msg)
            } else {
                toast.error(e.msg)
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validation}
        >
            {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                    <Container>
                        <p style={{ margin: 8, gridArea: 'tips' }}>{tips?.formulario}</p>

                        <Textbox
                            label="Razão social"
                            name="razao_social"
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Textbox
                            label="Nome fantasia"
                            name="nome_fantasia"
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Textbox
                            label="Sigla"
                            name="sigla"
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Textbox
                            label="CNPJ"
                            name="cnpj"
                            mask={masks.cnpj}
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Select
                            label="Tipo"
                            name="tipo"
                            options={tiposInstituicao}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Checkbox
                            name="desconto"
                            label="Habilitar desconto em folha"
                            onChange={e => {
                                setFieldValue('desconto', e.target.checked)
                            }}
                            containerStyle={{ gridArea: 'f6' }}
                        />

                        <input type="hidden" value={instituicao.endereco?.id} name="endereco_id" />

                        <FormEndereco setFieldValue={setFieldValue} />

                        <hr />

                        <div className="organograma">
                            <h1>Organograma</h1>

                            <FieldArray
                                name="organograma"
                                render={arrayHelpers => (
                                    <>
                                        {values.organograma?.map((_, index) => (
                                            <OrganogramaLinhaContainer key={index}>
                                                <Textbox 
                                                    name={`organograma.${index}.descricao`}
                                                    label="Nome do nível"
                                                    onChange={e => {
                                                        const text = e.target.value

                                                        if(values.organograma.some(o => o.descricao === text)) {
                                                            setFieldValue(`organograma.${index}.descricao`, text.substring(0, text.length - 1))

                                                            toast.warn('Não é possível incluir níveis de mesmo nome.')

                                                            return
                                                        }

                                                        setFieldValue(`organograma.${index}.descricao`, text)
                                                    }}
                                                />

                                                <Textbox 
                                                    name={`organograma.${index}.pessoa`}
                                                    label="Representante"
                                                />

                                                <Textbox 
                                                    name={`organograma.${index}.email`}
                                                    label="E-mail"
                                                />

                                                <Textbox 
                                                    name={`organograma.${index}.fone`}
                                                    label="Fone"
                                                    mask={masks.phone}
                                                />

                                                <Select 
                                                    name={`organograma.${index}.pai`}
                                                    label="Nível superior"
                                                    onChange={(selected, meta) => {
                                                        setFieldValue(meta.name, selected)
                                                    }}
                                                    placeholder="Nenhum"
                                                    options={values.organograma.filter(i => i.descricao !== values.organograma[index].descricao)
                                                        .map(i => ({
                                                            label: i.descricao,
                                                            value: i.descricao
                                                        }))}
                                                />

                                                <Button fab className="transparent" onClick={() => { arrayHelpers.remove(index) }}>
                                                    <FiTrash size={16} />
                                                </Button>
                                            </OrganogramaLinhaContainer>
                                        ))}

                                        <div className="organograma-button-container">
                                            <Button onClick={() => { arrayHelpers.push('') }} className="transparent">
                                                Adicionar nível
                                                <FiPlus size={16} />
                                            </Button>
                                        </div>
                                    </>
                                )}
                            />
                        </div>

                        <div className="button-container">
                            <Button type="submit" className="transparent" loading={isSubmitting}>
                                {edicao ? 'Salvar alterações' : 'Cadastrar'}
                                {edicao ? <FiSave size={16} /> : <FiPlus size={16} />}
                            </Button>
                        </div>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}
