import styled from 'styled-components'

export const AtendimentoContainer = styled.div`
	display: grid;
	grid-template-columns: 3fr 1fr;
	gap: 24px;
	margin-top: 24px;

	.alerta-chat-principal {
		font-size: 13px;
		color: red;
	}

	@media(max-width: 700px) {
		display: flex;
		flex-direction: column;
	}
`
