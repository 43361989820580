import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;
		display: flex;
		flex-direction: column;

		.card {
			min-height: 70vh;
			margin-bottom: 24px;

			> h1 {
				margin-bottom: 20px;
			}

			> details {
				margin: 20px 8px 10px;
				border-radius: 6px;
				border: solid 1px #fff;
				border-bottom-width: 3px;
				height: fit-content;
				width: calc(100% - 16px);
				justify-self: center;

				&:last-child {
					margin-top: 0;
				}

				> div {
					margin: 18px;
					display: flex;
					flex-direction: column;
					grid-gap: 8px 16px;

					> div {
						.table-container {
							margin-top: 0;
						}

						p {
							margin-bottom: 14px;
						}
					}
				}

				&[open] {
					summary {
						background-color: #FFF;
						color: #424242;

						h1 {
							color: #424242;
						}

						> svg {
							color: #424242;
						}
					}
				}

				summary {
					cursor: pointer;
					padding: 12px 18px;
					display: flex;
					align-items: center;
					font-size: 16px;
					font-weight: 500;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;

					> svg {
						margin-right: 8px;
					}

					&:hover {
						background-color: #FFF;
						color: #424242;

						svg {
							color: #424242;
						}

						h1 {
							color: #424242;
						}
					}
				}
			}
		}

		@media(max-width: 600px) {
			padding: 30px 0;
		}
	}
`

export const ModalRegularizacaoContent = styled.div.attrs({
	className: 'regularizacao-container'
})`
	form {
		display: flex;
		flex-direction: column;
		width: 100%;

		> p {
			margin: 12px 8px 20px;
			width: 100%;
		}

		.field {
			max-width: 400px;
			align-self: center;
		}

		> button {
			max-width: 200px;
			align-self: flex-end;
		}
	}
`

export const ModalServicosOpcionaisContainer = styled.div`
	.button-container {
		display: flex;
		justify-content: flex-end;
		padding-top: 14px;

		button {
			margin: 10px 0 0;
		}
	}
`

export const ListaContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;

	@media(max-width: 1120px) {
		flex-direction: column;

		> .lista-associados {
			width: 100%;
			height: fit-content;
			padding: 6px 20px;
			
			.cards {
				display: flex;
				gap: 8px;
				overflow-x: auto;

				.card-item {
					width: 200px;
				}
			}
		}
	}
`

export const Lista = styled.div.attrs({
	className: 'lista-associados'
})`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	height: 70vh;
	background-color: #0005;
	padding: 6px;
	width: 20%;
	border-radius: 8px;

	> h1, > h2 {
		text-align: center;
	}

	> h1 {
		font-size: 16px;
		padding: 8px;
	}

	> h2 {
		font-size: 12px;
		font-weight: 300;
		margin-bottom: 10px;
	}
`

export const CardItem = styled.div.attrs({
	className: 'card-item',
	role: 'button'
})`
	background-color: #0007;
	border-radius: 6px;
	padding: 14px;
	margin-bottom: 8px;
	display: grid;
	grid-template-columns: 50px auto;
	gap: 10px;
	transition: 300ms;

	.avatar {
		width: 100%;
		border-radius: 50%;
		align-self: center;
		grid-row: 1/span 3;
	}

	> h1 {
		font-size: 12px;
	}

	> h2 {
		font-size: 10px;
		font-weight: 200;
		grid-column: 1/span 2;
	}

	span {
		font-size: 9px;
	}

	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}
`

export const DependenteQuantidadeContainer = styled.div`
	display: flex;
	align-items: center;
	
	svg {
		margin-right: 4px;
	}

	span {
		font-size: 11px;
	}
`

export const ConfirmacaoCancelamentoContainer = styled.div`
	display: flex;
	flex-direction: column;

	p {
		margin: 0 8px;
	}

	.button-container {
		display: flex;
		justify-content: flex-end;
	}
`
