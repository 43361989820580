import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { Container, ButtonContainer } from './styles'

import { Calendar, Button } from '../../../../../components/Form'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

import { forceDownloadFileFromURL } from '../../../../../util/file'
import formUtils from '../../../../../util/form'

const nomeRelatorio = 'Relatório Geral de Enquetes'

const validation = Yup.object().shape({
    periodo: Yup.string().nullable().required('Informe um periodo para a busca.')
})

export default function (props) {
    const handleSubmit = useCallback(async (values, { resetForm }) => {
        try {
            values = formUtils.extractFormValues(values)

            const response = await api.post('enquete/relatorio/geral', values, authHeaders())

            forceDownloadFileFromURL(response.link, nomeRelatorio)

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{ periodo: '' }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="periodo"
                            label="Período para busca"
                            selectionMode="range"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <ButtonContainer>
                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
