import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { format } from 'date-fns'

import { Button, File, Select, Textbox } from '../../../../components/Form'
import { AssociacaoContext } from '../../../../contexts/AssociacaoContext'
import formUtils from '../../../../util/form'
import masks from '../../../../util/masks'
import yup from '../../../../services/yup'
import { validarCPF } from '../../../../util/validadores'
import { AvatarContext } from '../../../../contexts/AvatarContext'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { api } from '../../../../services/api'

const baseInitialValues = {
    matricula: '',
    nome: '',
    cpf: '',
    rg: '',
    data_nascimento: '',
    sexo: '',
    rg_arquivo: null,
    cpf_arquivo: null
}

const validation = yup.object({
    matricula: yup.string().required('Informe sua matrícula.'),
    nome: yup.string().required('Informe seu nome completo e sem abreviações.'),
    cpf: yup.string().required('Informe seu CPF.')
        .test('validar-cpf', 'CPF inválido.', value => validarCPF(value || '')),
    rg: yup.string().required('Informe seu RG.'),
    data_nascimento: yup.string().date().required('Informe sua data de nascimento.'),
    sexo: yup.string().required('Selecione o sexo.')
})

export function Pessoal() {
    const { clienteRobot } = useContext(GlobalContext)
    const {
        tempData, syncTempData, sincronizarDadosTemporarios, avancarEtapa 
    } = useContext(AssociacaoContext)
    const { handleSpeak } = useContext(AvatarContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)

    function handleSubmit(values) {
        const data = formUtils.extractFormValues(values)

        syncTempData(data)

        avancarEtapa()
    }

    useEffect(() => {
        if(!tempData.matricula) {
            return
        }
        
        const {
            matricula, nome, cpf, rg, data_nascimento, sexo, cpf_arquivo, rg_arquivo
        } = tempData

        setInitialValues({
            matricula,
            nome,
            cpf,
            rg,
            data_nascimento: format(new Date(data_nascimento), 'dd/MM/yyyy'),
            sexo: {
                label: sexo === 'M' ? 'Masculino' : 'Feminino',
                value: sexo
            },
            cpf_arquivo,
            rg_arquivo
        })
    }, [tempData])

    useEffect(() => {
        handleSpeak(`Seja bem-vindo à associação ${clienteRobot.name}! Comece informando sua matrícula.`)
    }, [])

    return (
        <div className="pb-6">
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validation}
            >
                {({ setFieldValue, errors, values }) => (
                    <Form className="flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4">
                        <Textbox 
                            name="matricula"
                            label="Matrícula"
                            onBlur={e => {
                                sincronizarDadosTemporarios(e.target.value)
                            }}
                        />

                        <Textbox 
                            name="nome"
                            label="Nome"
                        />

                        <Select 
                            name="sexo"
                            label="Sexo"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Masculino', value: 'M' },
                                { label: 'Feminino', value: 'F' }
                            ]}
                            isSearchable={false}
                        />

                        <Textbox 
                            name="data_nascimento"
                            label="Data de nascimento"
                            inputMode="numeric"
                            mask={masks.date} 
                        />

                        <Textbox 
                            name="rg"
                            label="RG"
                        />

                        <Textbox 
                            name="cpf"
                            label="CPF"
                            mask={masks.cpf} 
                            inputMode="numeric"
                        />

                        <File
                            name="cpf_arquivo"
                            onSuccess={fileId => setFieldValue('cpf_arquivo', fileId)}
                            label="Imagem do CPF"
                            format="square"
                            previewSize={['100%', '170px']}
                            error={errors.documento}
                            getPreloadImage={async () => {
                                if(!values.cpf_arquivo) {
                                    return
                                }
                                const arquivo = await api.get(`arquivo/${values.cpf_arquivo}`)
                                return arquivo?.link
                            }}
                            accept={[
                                'application/pdf',
                                'image/jpeg',
                                'image/png'
                            ]}
                        />

                        <File
                            name="rg_arquivo"
                            onSuccess={fileId => setFieldValue('rg_arquivo', fileId)}
                            label="Imagem do RG"
                            format="square"
                            previewSize={['100%', '170px']}
                            error={errors.documento}
                            getPreloadImage={async () => {
                                if(!values.rg_arquivo) {
                                    return
                                }
                                const arquivo = await api.get(`arquivo/${values.rg_arquivo}`)
                                return arquivo?.link
                            }}
                            accept={[
                                'application/pdf',
                                'image/jpeg',
                                'image/png'
                            ]}
                        />

                        <div className="sm:col-span-2 md:col-span-4 flex justify-end mt-3 border-t border-gray-300">
                            <Button type="submit" className="transparent w-full sm:w-fit">
                                Continuar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
} 
