import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

// if(!history.ownListen) {
//     history.listen(location => {
//         if(location.state?.reload) {
//             window.location.reload()
//         }
//     })

//     history.ownListen = true
// }

export default history
