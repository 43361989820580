import React, { useContext, useState } from 'react'
import { FaHandsHelping } from 'react-icons/fa'

import Card from '../../../../components/Card'
import Modal from '../../../../components/Modal'
import Table from '../../../../components/Table'
import { ConvenioContext } from '../../../../contexts/ConvenioContext'

import { api, authHeaders } from '../../../../services/api'

import maskUtils from '../../../../util/masks'

import { Container, ServicoDetalhesContainer } from './styles'

export default function () {
    const { servicosDisponiveis } = useContext(ConvenioContext)

    const [servico, setServico] = useState(null)

    async function loadServico(id) {
        const response = await api.get(`convenio/${id}`, authHeaders())

        setServico(response)
    }

    function getTabelaHeaders() {
        if (!servico?.descontos?.length) {
            return
        }

        const [desconto] = servico.descontos

        const headers = [
            { name: 'faixa_etaria', value: 'Faixa etária', centered: true }
        ]

        if (desconto.valor_sem_desconto) {
            headers.push({ name: 'valor_sem_desconto', value: 'Valor sem desconto', centered: true })
        }

        if (desconto.valor_com_desconto) {
            headers.push({ name: 'valor_com_desconto', value: 'Valor a pagar', centered: true })
        }

        if (desconto.percentual_desconto) {
            headers.push({ name: 'percentual_desconto', value: '% desconto', centered: true })
        }

        if (desconto.sexo) {
            headers.push({ name: 'sexo', value: 'Sexo', centered: true })
        }

        if (desconto.universidade) {
            headers.push({ name: 'universidade', value: 'Universitario(a)', centered: true })
        }

        return headers
    }

    function getTabelaDescontos() {
        if (!servico) {
            return
        }

        const { descontos } = servico

        function getTextFaixaEtaria(desconto) {
            if (!desconto.idade_minima && !desconto.idade_maxima) {
                return 'Qualquer faixa etária'
            }

            if (!desconto.idade_minima && desconto.idade_maxima) {
                return `Até ${desconto.idade_maxima} anos`
            }

            if (desconto.idade_minima && !desconto.idade_maxima) {
                return `A partir de ${desconto.idade_minima} anos`
            }

            if (desconto.idade_minima && desconto.idade_maxima) {
                return `Entre ${desconto.idade_minima} e ${desconto.idade_maxima} anos`
            }
        }

        return descontos.map(desc => ({
            id: desc.id,
            percentual_desconto: maskUtils.maskApply.percent(desc.percentual_desconto),
            valor_sem_desconto: maskUtils.maskApply.currency(desc.valor_sem_desconto),
            valor_com_desconto: maskUtils.maskApply.currency(desc.valor_com_desconto),
            faixa_etaria: getTextFaixaEtaria(desc),
            sexo: desc.sexo || 'Sem restrição',
            universidade: desc.universidade === null ? 'Sem restrição' : desc.universidade ? 'Sim' : 'Não'
        }))
    }

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Serviços disponíveis</h1>
                        <span>Clique para conhecer:</span>

                        <ul>
                            {servicosDisponiveis?.map(s => (
                                <li key={s.id} onClick={() => { loadServico(s.id) }}>
                                    <div className="background-logo">
                                        {s.logo?.link ? (
                                            <img src={s.logo.link} alt="" />
                                        ) : (
                                            <FaHandsHelping />
                                        )}
                                    </div>

                                    <h1>{s.empresa.nome}</h1>
                                    <h2>{s.convenio_area_atuacao.descricao}</h2>
                                </li>
                            ))}
                        </ul>

                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!servico}
                handleClose={() => { setServico(null) }}
                title={servico?.empresa.nome}
            >
                <ServicoDetalhesContainer>
                    <p>
                        <b>{`Serviço disponível para: ${servico?.favorecido?.split(',').map(f => f).join(', ') || 'Associados'}`}</b>
                    </p>

                    {servico?.descricao && (
                        <p>
                            <div dangerouslySetInnerHTML={{ __html: servico?.descricao.replace(/\n/g, '<br />') }} />
                        </p>
                    )}

                    <Table
                        headers={getTabelaHeaders()}
                        data={getTabelaDescontos()}
                        caption="Tabela de valores do serviço"
                        hideOffset={654}
                        className="table-valores"
                    />

                    <Table
                        headers={[
                            { name: 'documentacao', value: 'Documentação necessária', centered: true }
                        ]}
                        data={servico?.parentescos.filter(p => p.id === 1).map(parentesco => ({
                            id: parentesco.id,
                            documentacao: parentesco.documentacao_necessaria.filter(doc => ![36, 37, 38, 41].includes(doc.id)).map(doc => doc.descricao).join(', ')
                        }))}
                        caption="Documentos exigidos para o TITULAR"
                    />

                    <Table
                        headers={[
                            { name: 'descricao', value: 'Serviço opcional' },
                            { name: 'valor', value: 'Valor', centered: true }
                        ]}
                        data={servico?.convenio_adicionais.map(adicional => ({
                            id: adicional.id,
                            descricao: adicional.descricao,
                            valor: maskUtils.maskApply.currency(adicional.valor)
                        })) || []}
                        caption="Itens opcionais disponibilizados"
                        className="table-adicionais"
                    />

                    <Table
                        headers={[
                            { name: 'descricao', value: 'Parentesco', centered: true },
                            { name: 'documentacao', value: 'Documentação necessária', centered: true }
                        ]}
                        data={servico?.parentescos.filter(p => p.id !== 1).map(parentesco => ({
                            id: parentesco.id,
                            descricao: parentesco.descricao,
                            documentacao: parentesco.documentacao_necessaria.map(doc => [36, 37, 38, 41].includes(doc.id) ? `${doc.descricao} (Portabilidade)` : doc.descricao).join(', ')
                        }))}
                        caption="Parentescos aceitos como dependente pelo serviço"
                    />
                </ServicoDetalhesContainer>
            </Modal>
        </>
    )
}
