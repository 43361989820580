import React, { createContext, useState, useEffect } from 'react'
import { format, lastDayOfMonth } from 'date-fns'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

import maskUtils from '../util/masks'
import formUtils from '../util/form'

export const ContribuicaoContext = createContext()

const baseInitialValues = {
    crm: '',
    nome: '',
    competencia: '',
    data_consolidacao: '',
    valor: '',
    status: '',
    tipo_pagamento: '',
    instituicao: ''
}

const ContribuicaoProvider = ({ children }) => {
    const [contribuicoes, setContribuicoes] = useState(null)
    const [valorPendente, setValorPendente] = useState(null)
    const [valorACobrar, setValorACobrar] = useState(null)
    const [valorMensalidade, setValorMensalidade] = useState(0.0)
    const [valorEfetuado, setValorEfetuado] = useState(null)
    const [tiposPagamento, setTiposPagamento] = useState([])
    const [instituicoes, setInstituicoes] = useState([])
    const [modalMode, setModalMode] = useState(null) // null, 'cadastro', 'edicao', 'carregando'
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [editId, setEditId] = useState(null)
    const [currentQuery, setCurrentQuery] = useState(null)
    const [cobrancaAgrupada, setCobrancaAgrupada] = useState(null)

    async function loadInstituicoes() {
        const response = await api.get('instituicao', {
            params: {
                type: 'banco'
            }
        })

        setInstituicoes(response.filter(instituicao => ![17782, 17783].includes(instituicao.id)))
    }

    async function loadTiposPagamento() {
        let response = await api.get('tipo_pagamento', {
            params: { ativos: '1' }
        })

        setTiposPagamento(response)
    }

    async function loadValorMensalidade() {
        const { valor } = await api.get(`mensalidade_valor/${new Date().getFullYear()}`)

        const mensalidade = Number(valor)

        setValorMensalidade(mensalidade)
    }

    useEffect(() => {
        loadInstituicoes()

        loadTiposPagamento()

        loadValorMensalidade()
    }, [])

    async function handleSearch(values) {
        setCurrentQuery(values)
        setContribuicoes('loading')

        values = formUtils.extractFormValues(values)

        if (Object.values(values).every(v => v === '')) {
            toast.warn('Aplique pelo menos um filtro para a pesquisa.')
            setCurrentQuery(null)
            setContribuicoes(null)
            return
        }

        const params = Object.entries(values).reduce((final, [key, value]) => {
            if (value !== '') {
                if (key === 'competencia') {
                    return {
                        ...final,
                        [key]: `${format(value[0], 'yyyy-MM-dd')}|${format(value[1] || lastDayOfMonth(value[0]), 'yyyy-MM-dd')}`
                    }
                }

                return {
                    ...final,
                    [key]: value
                }
            }

            return final
        }, {})

        const response = await api.get('contribuicao', {
            params,
            ...authHeaders()
        })

        setContribuicoes(response.map(contribuicao => ({
            id: contribuicao.id,
            crm: contribuicao.associado.pessoa.documentos.find(doc => doc.tipo_documento.id === 999)?.identificador,
            nome: contribuicao.associado.pessoa.nome,
            competencia: format(new Date(contribuicao.competencia), ![4, 5, 7].includes(contribuicao.tipo_pagamento?.id) ? 'MM/yyyy' : 'yyyy'),
            tipo_pagamento: contribuicao.tipo_pagamento?.descricao_resumida || '',
            tipo_baixa: contribuicao.tipo_baixa?.descricao || '',
            valor: maskUtils.maskApply.currency(contribuicao.valor),
            data_consolidacao: contribuicao.data_consolidacao ? format(new Date(contribuicao.data_consolidacao), 'dd/MM/yyyy') : '',
            status_id: contribuicao.status,
            status: {
                icon: contribuicao.status ? 'FaCheckCircle' : contribuicao.fatura?.length === 32 ? 'FaFileInvoiceDollar' : 'FaMinusCircle',
                color: contribuicao.status ? '#4caf50' : contribuicao.fatura?.length === 32 ? '#2196f3' : '#f44336',
                tooltip: contribuicao.status ? 'Pago' : contribuicao.fatura?.length === 32 ? 'Fatura gerada' : 'Não pago'
            },
            link_fatura: contribuicao.link_fatura
        })))

        setValorPendente(response.reduce((result, contribuicao) => {
            if (!contribuicao.status) {
                return result + Number(contribuicao.valor)
            }
            return result
        }, 0))

        setValorACobrar(response.reduce((result, contribuicao) => {
            if (!contribuicao.status) {
                return result + Number(contribuicao.valor)
            }
            return result
        }, valorMensalidade))

        setValorEfetuado(response.reduce((result, contribuicao) => {
            if (contribuicao.status) {
                return result + Number(contribuicao.valor)
            }
            return result
        }, 0))
    }

    async function reload(crm) {
        await handleSearch(currentQuery || { crm })
    }

    async function handlePrepareEdit(id) {
        setModalMode('carregando')
        setEditId(id)

        const contribuicao = await api.get(`contribuicao/${id}`, authHeaders())

        const tipoPagamento = contribuicao.tipo_pagamento?.id ? await api.get(`tipo_pagamento/${contribuicao.tipo_pagamento?.id}`, authHeaders()) : null

        const instituicao = contribuicao.instituicao ? await api.get(`instituicao/${contribuicao.instituicao.id}`, authHeaders()) : null

        setInitialValues({
            crm: contribuicao.associado.pessoa.documentos.find(doc => doc.tipo_documento.id === 999)?.identificador,
            nome: contribuicao.associado.pessoa.nome,
            competencia: new Date(contribuicao.competencia),
            data_consolidacao: contribuicao.data_consolidacao ? new Date(contribuicao.data_consolidacao) : '',
            valor: maskUtils.maskApply.currency(contribuicao.valor),
            status: {
                label: contribuicao.status ? 'Efetuado' : 'Pendente',
                value: contribuicao.status
            },
            tipo_pagamento: tipoPagamento ? {
                label: tipoPagamento.descricao_resumida,
                value: tipoPagamento.id
            } : '',
            instituicao: instituicao ? {
                label: instituicao.sigla,
                value: instituicao.id
            } : ''
        })

        setModalMode('edicao')
    }

    function showCadastro(show = true) {
        setModalMode(show ? 'cadastro' : null)

        setEditId(null)

        setInitialValues(baseInitialValues)
    }

    async function handleAdd(values) {
        values = formUtils.extractFormValues(values)

        try {
            const body = {
                ...values,
                tipo_pagamento: values.tipo_pagamento || null,
                instituicao: values.instituicao || null,
                data_consolidacao: values.data_consolidacao || null
            }

            await api.post('contribuicao', body, authHeaders())

            showCadastro(false)

            reload(values.crm)

            toast.success('Contribuição cadatrada com sucesso.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleEdit(values) {
        values = formUtils.extractFormValues(values)

        try {
            await api.put(`contribuicao/${editId}`, values, authHeaders())

            setEditId(null)
            showCadastro(false)

            reload()

            toast.success('Contribuição alterada com sucesso.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(id) {
        try {
            await api.delete(`contribuicao/${id}`, authHeaders())

            toast.success('Contribuição excluída com sucesso.')

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handleDeleteMultiple(ids) {
        try {
            await api.post('contribuicao/delete', {
                contribuicoes: ids
            }, authHeaders())

            toast.success('Contribuições excluídas com sucesso.')

            reload()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    return (
        <ContribuicaoContext.Provider value={{
            initialValues,
            handlePrepareEdit,
            contribuicoes,
            valorPendente,
            valorEfetuado,
            valorACobrar,
            handleSearch,
            showCadastro,
            modalMode,
            setModalMode,
            tiposPagamento,
            instituicoes,
            handleAdd,
            handleEdit,
            handleDelete,
            handleDeleteMultiple,
            setCobrancaAgrupada,
            cobrancaAgrupada,
            editId,
            reload
        }}
        >
            {children}
        </ContribuicaoContext.Provider>
    )
}

export default ContribuicaoProvider
