import styled, { css } from 'styled-components'

const agendado = css`
	background-color: #ffeb3b;
	color: #424242;
`

const atendido = css`
	background-color: #4caf50;
	color: #fff;
`

const cancelado = css`
	background-color: #f44336;
`

const naoCompareceu = css`
	background-color: #ff6e40;
`

export const Container = styled.div`
	min-height: 10vh;
	height: fit-content;
	display: grid;
	grid-template-columns: 1fr 22px;

	> h1 {
		font-size: 18px;
		margin-bottom: 16px;
	}

	> svg {
		font-size: 20px;
		cursor: pointer;
		transition: 300ms;

		&:hover {
			opacity: 0.8;
		}
	}

	ul {
		grid-column: 1/span 2;
		display: flex;
		flex-direction: column;
		gap: 14px 0;
		height: 25vh;
		overflow: hidden auto;

		li {
			display: grid;
			grid-template-columns: 1fr 90px;
			grid-template-rows: 26px auto auto;
			border-bottom: solid 1px #fff;
			padding: 0 0 10px;
			margin-right: 10px;

			span {
				line-height: 22px;

				&.atendente,
				&.motivo {
					font-size: 12px;
					grid-column: 1/span 2;
				}

				&.motivo {
					line-height: 13px;
				}
			}

			span.status {
				justify-self: flex-end;
				font-size: 10px;
				padding: 0 3px;
				border-radius: 10px;
				height: fit-content;

				&.c-1 {
					${agendado}
				}

				&.c-2 {
					${naoCompareceu}
				}
				
				&.c-3 {
					${atendido}
				}

				&.c-4 {
					${cancelado}
				}
			}
		}
	}

	.empty-label {
		grid-column: 1/span 2;
		text-align: center;
		font-style: italic;
		margin: 20px;
	}
`
