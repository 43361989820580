import React, { useEffect, useState } from 'react'

import FormPagamentoContaCorrente from './ContaCorrente'
import FormPagamentoContraCheque from './ContraCheque'
import FormPagamentoCartaoCredito from './CartaoCredito'
import FormPagamentoBoleto from './Boleto'
import FormPagamentoBoletoResidente from './BoletoResidente'

import { api } from '../../services/api'

import { Container } from './styles'

export default function ({ tipoPagamento, onSubmit }) {
    const [valorMensalidade, setValorMensalidade] = useState(0.0)

    useEffect(() => {
        async function loadValorMensalidade() {
            const { valor } = await api.get(`mensalidade_valor/${new Date().getFullYear()}`)

            const mensalidade = Number(valor)

            setValorMensalidade(mensalidade)
        }

        loadValorMensalidade()
    }, [])

    return (
        <Container>
            {tipoPagamento?.value === 1 && (
                <FormPagamentoContaCorrente onSubmit={onSubmit} valorMensalidade={valorMensalidade} />
            )}

            {tipoPagamento?.value === 2 && (
                <FormPagamentoContraCheque onSubmit={onSubmit} valorMensalidade={valorMensalidade} />
            )}

            {tipoPagamento?.value === 3 && (
                <FormPagamentoCartaoCredito onSubmit={onSubmit} valorMensalidade={valorMensalidade} />
            )}

            {tipoPagamento?.value === 4 && (
                <FormPagamentoBoleto onSubmit={onSubmit} />
            )}
        </Container>
    )
}
