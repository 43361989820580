import styled from 'styled-components'

import Card from '../../../components/Card'

export const Container = styled(Card).attrs({
	className: 'box-local-trabalho'
})`
	grid-area: locais_trabalho;
	display: grid;
	grid-template-columns: auto 24px;
	grid-template-areas:
		'title add_icon'
		'form form'
		'table table';

	> h1 {
		font-size: 19px;
		font-weight: 500;
	}

	.table-container {
		grid-area: table;
	}

	.spinner-container {
		grid-area: table;
		justify-self: center;
	}

	> svg {
		color: #FFF;
		transition: 300ms;

		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}
	}

	form {
		grid-area: form;
	}
	
	@media(max-width: 440px) {
		margin: 0 !important;
	}
`

export const AddInstituicao = styled.div`
	display: grid;
	align-items: flex-end;
	grid-template-columns: auto 135px 24px 200px;
	grid-template-areas: 'local add_button ou novo';

	> span {
		grid-area: ou;
		text-align: center;	
		height: 42px;
	}

	.add-button {
		grid-area: add_button;
	}

	.new-button {
		grid-area: novo;
	}

	@media(max-width: 800px) {
		grid-template-columns: auto 135px;
		grid-template-areas:
			'local add_button'
			'ou ou'
			'novo novo';

		> span {
			height: 32px;
		}
	}
`

export const FormNovoLocal = styled.div`
	display: flex;
	flex-direction: column;

	> div:first-child {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		line-height: initial;
	}

	form {
		.label, .error {
			line-height: initial;
		}
	}

	@media(max-width: 1100px) {
		> .field:first-child {
			width: calc(100% - 24px);
			margin-right: 0 !important;
		}
	}

	@media(max-width: 800px) {
		> .field:first-child {
			width: calc(100% - 16px);
			margin-right: 0 !important;
		}
	}
`
