import styled, { keyframes } from 'styled-components'

const rotation = keyframes`
	0% {
		transform: rotate(0);
		opacity: 0;
	}
	5% {
		opacity: 1;
	}
	70% {
		opacity: 1;
	}
	90% {
		transform: rotate(-90deg);
	}
	100% {
		opacity: 0;
	}
`

export const Container = styled.div.attrs({
    className: 'require-rotation'
})`
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #FFF;
	padding: 30px 20px 0; 

	img {
		height: 150px !important;
		width: 80px !important;
		animation: ${rotation} 1.8s ease-in-out infinite;
	}

	span {
		font-size: 16px;
		text-align: center;
		margin-top: 20px;
		line-height: 30px;
		font-weight: 500;
	}

	@media(max-width: ${props => props.hideOffset}px) {
		display: flex;
	}
`
