import React, {
    createContext, useState, useEffect, useContext
} from 'react'
import {
    Tab, Tabs, TabList, TabPanel
} from 'react-tabs'
import { toast } from 'react-toastify'
import { FaPlus, FaSearch } from 'react-icons/fa'
import { useLocation, useHistory } from 'react-router-dom'

import { endOfMonth, format, startOfMonth } from 'date-fns'
import { api, authHeaders } from '../../../../services/api'

import formUtils from '../../../../util/form'

import Card from '../../../../components/Card'
import { Button } from '../../../../components/Form'

import Listagem from './Listagem'
import Pesquisa from './Pesquisa'
import Cadastro from './Cadastro'

import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

import { Container, ButtonContent } from './styles'

export const AcervoContext = createContext()

const baseInitialValues = {
    titulo: '',
    link: '',
    thumbnail: null,
    arquivo: null,
    data_publicacao: null,
    exibir_espaco_medico: false,
    publico: false,
    resumo: '',
    palavras_chave: ''
}

export default function () {
    const { setCodigo } = useContext(TipContext)

    const history = useHistory()
    const location = useLocation()

    const pastas = location?.state?.state?.pastas || null

    const [acervo, setAcervo] = useState(null)
    const [segmentos, setSegmentos] = useState([])
    const [searchMode, setSearchMode] = useState(!pastas)
    const [editId, setEditId] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [segmentosSelecionados, setSegmentosSelecionados] = useState([])

    async function loadAcervo() {
        const today = new Date()
        const inicio = startOfMonth(today)
        const fim = endOfMonth(today)

        const response = await api.get('acervo', {
            params: {
                periodo: [inicio, fim]
            },
            ...authHeaders()
        })

        setAcervo(response.map(acervoItem => {
            const nivel1 = acervoItem.segmento.descricao
            const nivel2 = acervoItem.segmento.segmento_pai?.descricao
            const nivel3 = acervoItem.segmento.segmento_pai?.segmento_pai?.descricao
            const nivel4 = acervoItem.segmento.segmento_pai?.segmento_pai?.segmento_pai?.descricao
            const nivel5 = acervoItem.segmento.segmento_pai?.segmento_pai?.segmento_pai?.segmento_pai?.descricao

            const segmentosLocal = [nivel1, nivel2, nivel3, nivel4, nivel5].filter(s => !!s).reverse()

            const localizacao = segmentosLocal.join(' ➔ ')

            return {
                ...acervoItem,
                data: format(new Date(acervoItem.data_publicacao), 'dd/MM/yyyy'),
                link: acervoItem.link || null,
                arquivo: acervoItem.arquivo?.link || null,
                localizacao
            }
        }))
    }

    async function loadSegmentos() {
        const response = await api.get('acervo/segmento', {
            params: {
                publico: 0
            }
        })

        setSegmentos(response)
    }

    async function handleSearch(values) {
        values = formUtils.extractFormValues(values)

        setAcervo(null)

        const response = await api.get('acervo', {
            params: {
                ...values,
                segmento: segmentosSelecionados.length ? segmentosSelecionados.at(-1) : null
            },
            ...authHeaders()
        })

        setAcervo(response.map(acervoItem => {
            const nivel1 = acervoItem.segmento.descricao
            const nivel2 = acervoItem.segmento.segmento_pai?.descricao
            const nivel3 = acervoItem.segmento.segmento_pai?.segmento_pai?.descricao
            const nivel4 = acervoItem.segmento.segmento_pai?.segmento_pai?.segmento_pai?.descricao
            const nivel5 = acervoItem.segmento.segmento_pai?.segmento_pai?.segmento_pai?.segmento_pai?.descricao

            const segmentosLocal = [nivel1, nivel2, nivel3, nivel4, nivel5].filter(s => !!s).reverse()

            const localizacao = segmentosLocal.join(' ➔ ')

            return {
                ...acervoItem,
                data: format(new Date(acervoItem.data_publicacao), 'dd/MM/yyyy'),
                link: acervoItem.link || null,
                arquivo: acervoItem.arquivo?.link || null,
                localizacao
            }
        }))
    }

    async function handleAdd(values) {
        try {
            values = formUtils.extractFormValues(values)

            const niveisKeys = Object.keys(values).filter(k => k.startsWith('nivel_'))
            const niveisIds = niveisKeys.map(k => Number(values[k]))

            niveisKeys.reverse()

            for (const nvl of niveisKeys) {
                if (values[nvl]) {
                    values.segmento_id = values[nvl]

                    break
                }
            }

            await api.post('acervo', values, authHeaders())

            loadAcervo()

            toast.success('Documento cadastrado com sucesso!')

            if (pastas) {
                history.push('/acervo/documentos', {
                    state: {
                        pastas: niveisIds
                    }
                })
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(id) {
        try {
            await api.delete(`acervo/${id}`, authHeaders())

            toast.success('Documento excluído.')

            return true
        } catch (e) {
            toast.error(e.msg)
            return false
        }
    }

    async function handlePrepareEdit(acervoItemId) {
        setInitialValues(baseInitialValues)
        setEditId(acervoItemId)
        setSegmentosSelecionados([])

        // eslint-disable-next-line no-unused-vars
        const {
            segmento,
            ...response
        } = await api.get(`acervo/${acervoItemId}`, authHeaders())

        const nivelMax = segmento.segmento_pai?.segmento_pai?.segmento_pai?.segmento_pai
        const nivelAvo = segmento.segmento_pai?.segmento_pai?.segmento_pai
        const nivelPai = segmento.segmento_pai?.segmento_pai
        const nivelFilho = segmento.segmento_pai
        const nivelDocumento = segmento

        const prepared = {
            ...response,
            data_publicacao: new Date(response.data_publicacao),
            thumbnail: response.thumbnail?.id || null,
            arquivo: response.arquivo?.id || null,
            palavras_chave: response.palavras_chave
        }

        if (nivelMax) {
            Object.assign(prepared, {
                nivel_1: {
                    label: nivelMax.descricao,
                    value: nivelMax.id
                },
                nivel_2: {
                    label: nivelAvo.descricao,
                    value: nivelAvo.id
                },
                nivel_3: {
                    label: nivelPai.descricao,
                    value: nivelPai.id
                },
                nivel_4: {
                    label: nivelFilho.descricao,
                    value: nivelFilho.id
                },
                nivel_5: {
                    label: nivelDocumento.descricao,
                    value: nivelDocumento.id
                }
            })

            setSegmentosSelecionados([
                nivelMax.id,
                nivelAvo.id,
                nivelPai.id,
                nivelFilho.id,
                nivelDocumento.id
            ])
        } else if (nivelAvo) {
            Object.assign(prepared, {
                nivel_1: {
                    label: nivelAvo.descricao,
                    value: nivelAvo.id
                },
                nivel_2: {
                    label: nivelPai.descricao,
                    value: nivelPai.id
                },
                nivel_3: {
                    label: nivelFilho.descricao,
                    value: nivelFilho.id
                },
                nivel_4: {
                    label: nivelDocumento.descricao,
                    value: nivelDocumento.id
                }
            })

            setSegmentosSelecionados([
                nivelAvo.id,
                nivelPai.id,
                nivelFilho.id,
                nivelDocumento.id
            ])
        } else if (nivelPai) {
            Object.assign(prepared, {
                nivel_1: {
                    label: nivelPai.descricao,
                    value: nivelPai.id
                },
                nivel_2: {
                    label: nivelFilho.descricao,
                    value: nivelFilho.id
                },
                nivel_3: {
                    label: nivelDocumento.descricao,
                    value: nivelDocumento.id
                }
            })

            setSegmentosSelecionados([
                nivelPai.id,
                nivelFilho.id,
                nivelDocumento.id
            ])
        } else if (nivelFilho) {
            Object.assign(prepared, {
                nivel_1: {
                    label: nivelFilho.descricao,
                    value: nivelFilho.id
                },
                nivel_2: {
                    label: nivelDocumento.descricao,
                    value: nivelDocumento.id
                }
            })

            setSegmentosSelecionados([
                nivelFilho.id,
                nivelDocumento.id
            ])
        } else {
            Object.assign(prepared, {
                nivel_1: {
                    label: nivelDocumento.descricao,
                    value: nivelDocumento.id
                }
            })

            setSegmentosSelecionados([
                nivelDocumento.id
            ])
        }

        setInitialValues(prepared)

        setSearchMode(false)
    }

    async function handleEdit(values) {
        try {
            values = formUtils.extractFormValues(values)

            const niveisKeys = Object.keys(values).filter(k => k.startsWith('nivel_'))

            niveisKeys.reverse()

            for (const nvl of niveisKeys) {
                if (values[nvl]) {
                    values.segmento_id = values[nvl]

                    break
                }
            }

            await api.put(`acervo/${editId}`, values, authHeaders())

            toast.success('As alterações foram salvas.')

            setInitialValues(baseInitialValues)
            setEditId(null)

            loadAcervo()
        } catch (e) {
            toast.error('Ocorreu um erro ao editar o documento.')
        }
    }

    useEffect(() => {
        if (searchMode) {
            loadTips(setCodigo, 'form_acervo_buscar_publicacao')
        } else {
            loadTips(setCodigo, 'form_acervo_cadastro_publicacao')
        }
    }, [searchMode])

    useEffect(() => {
        loadAcervo()

        loadSegmentos()
    }, [])

    return (
        <AcervoContext.Provider value={{
            acervo,
            handleSearch,
            handleAdd,
            handleDelete,
            handlePrepareEdit,
            handleEdit,
            initialValues,
            setInitialValues,
            editId,
            segmentos,
            segmentosSelecionados,
            setSegmentosSelecionados
        }}
        >
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Gerenciamento do Acervo de Documentos</h1>

                        <ButtonContent>
                            <Button className="transparent" onClick={() => { setSearchMode(old => !old) }}>
                                {!searchMode ? (
                                    <>
                                        Pesquisar
                                        <FaSearch />
                                    </>
                                ) : (
                                    <>
                                        Cadastrar
                                        <FaPlus />
                                    </>
                                )}
                            </Button>
                        </ButtonContent>

                        <Tabs>
                            <TabList>
                                <Tab>{searchMode ? 'Pesquisa' : editId ? 'Edição' : 'Cadastro'}</Tab>
                            </TabList>

                            <TabPanel>
                                {searchMode ? <Pesquisa /> : <Cadastro niveis={pastas} />}
                            </TabPanel>
                        </Tabs>

                        <Listagem />

                    </Card>
                </main>
            </Container>
        </AcervoContext.Provider>
    )
}
