import React from 'react'
import { Doughnut } from '@reactchartjs/react-chart.js'

import { Container } from './styles'

const colors = [
    '#74d678',
    '#FF3D67',
    '#FFC533',
    '#8142FF',
    '#81c784',
    '#00b0ff'
]

export default function ({
    width = 600, height = 300, data, legencColor, enableTooltips = true 
}) {
    return (
        <Container canvas={(width && height) ? { width, height } : null}>
            <Doughnut 
                data={{
                    labels: Object.keys(data),
                    datasets: [
                        {
                            data: Object.values(data).map(d => d.value),
                            backgroundColor: Object.values(data)?.[0].color ? Object.values(data).map(i => i.color) : colors,
                            borderColor: Object.values(data)?.[0].color ? Object.values(data).map(i => i.color) : colors
                        }
                    ]
                }} 
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        position: 'right',
                        labels: {
                            fontColor: legencColor || '#FFF',
                            fontSize: 14
                        }
                    },
                    tooltips: {
                        enabled: enableTooltips
                    }
                    // plugins: {
                    //     datalabels: {
                    //         formatter(value) {
                    //             return `R$ ${value}`
                    //         }
                    //     }
                    //     // doughnutlabel: {
                    //     //     labels: [{
                    //     //         text: '550',
                    //     //         font: {
                    //     //             size: 20,
                    //     //             weight: 'bold'
                    //     //         }
                    //     //     }, {
                    //     //         text: 'total'
                    //     //     }]
                    //     // }
                    // }
                }}
            />
        </Container>
    )
}
