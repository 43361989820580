import React, { useState, useEffect, useContext } from 'react'
import { Form, Formik } from 'formik'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { FiCheck, FiPlus } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import { Button, Textbox } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'

import { api, authHeaders } from '../../../../services/api'

import { Container } from './styles'

import Yup from '../../../../services/yup'

import scrollUtils from '../../../../util/scroll'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

const baseInitialValues = {
    motivo: ''
}

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [motivos, setMotivos] = useState(null)
    const [showCadastro, setShowCadastro] = useState(false)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [emEdicao, setEmEdicao] = useState(null)

    async function loadMotivos() {
        setMotivos(null)
		
        const response = await api.get('visita_motivo', authHeaders())

        setMotivos(response)
    }

    async function handleDelete(item) {
        try {
            await api.delete(`visita_motivo/${item.id}`, authHeaders())

            toast.success('Motivo excluído.')

            loadMotivos()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmit(values) {
        try {
            if(emEdicao) {
                await api.put(`visita_motivo/${emEdicao.id}`, values, authHeaders())	

                toast.success('Motivo atualizado.')
            } else {
                await api.post('visita_motivo', values, authHeaders())

                toast.success('Motivo cadastrado.')
            }

            setShowCadastro(false)

            setEmEdicao(null)

            setInitialValues(baseInitialValues)

            loadMotivos()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handlePrepareEdit(item) {
        setEmEdicao(item)

        setShowCadastro(true)

        setInitialValues({
            motivo: item.descricao
        })

        scrollUtils.toTop()
    }

    useEffect(() => {
        loadMotivos()

        loadTips(setCodigo, 'form_motivos_visita')
    }, [])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Motivos de visita/contato</h1>

                    {showCadastro ? (
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={Yup.object({
                                motivo: Yup.string().required('Informe o nome do motivo.')
                            })}
                        >
                            {() => (
                                <Form className={emEdicao ? 'animated flash' : ''}>
                                    <p style={{ margin: 8, paddingTop: 12 }}>{tips?.formulario}</p>

                                    <div>
                                        <Textbox 
                                            name="motivo"
                                            label="Motivo de contato"
                                        />

                                        <Button className="transparent" type="submit">
                                            {emEdicao ? 'Salvar alterações' : 'Cadastrar'}
                                            <FiCheck size={17} />
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <Button className="transparent" onClick={() => { setShowCadastro(true) }}>
                            Cadastrar novo
                            <FiPlus size={16} />
                        </Button>
                    )}

                    {motivos ? (
                        <Table 
                            headers={[
                                { name: 'descricao', value: 'Motivo' }
                            ]}
                            data={motivos}
                            filterable
                            actions={[
                                {
                                    action: handlePrepareEdit,
                                    name: 'Editar',
                                    icon: FaEdit,
                                    iconSize: 15
                                },
                                {
                                    action: handleDelete,
                                    name: 'Excluir',
                                    icon: FaTrash,
                                    iconSize: 13,
                                    confirmation: 'Deseja realmente excluir este motivo?'
                                }
                            ]}
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
