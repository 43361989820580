import React, { useCallback, useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile, FiFileText } from 'react-icons/fi'
import { format } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    tipo: { label: 'Todas', value: 'D' },
    pagamento: '',
    fornecedor: '',
    evento: '',
    contaCorrente: ''
}

const validation = Yup.object().shape({
    tipo: Yup.string().ensure().required('Selecione o tipo.'),
    pagamento: Yup.string().required('Selecione um período.'),
    contaCorrente: Yup.string().ensure(),
    fornecedor: Yup.string().nullable(),
    evento: Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de Contabilidade Despesas'

export default function (props) {
    const [contas, setContas] = useState([])
    const [fornecedores, setFornecedores] = useState([])
    const [eventos, setEventos] = useState([])
    const [isSubmittingPlanilha, setIsSubmittingPlanilha] = useState(false)

    async function loadContas() {
        const response = await api.get('conta_sindicato', authHeaders())

        setContas(response)
    }

    async function loadFornecedores() {
        const response = await api.get('fornecedor', authHeaders())

        setFornecedores(response)
    }

    async function loadEventos() {
        const response = await api.get('evento_contabil', authHeaders())

        setEventos(response)
    }

    useEffect(() => {
        loadContas()
        loadFornecedores()
        loadEventos()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            tipo,
            pagamento,
            evento,
            fornecedor,
            contaCorrente
        } = values

        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioContabilidadeDespesas',
                parametros: {
                    idConta: contaCorrente?.value || '',
                    tipo: tipo?.value || '',
                    idEvento: evento?.value || '',
                    idFornecedor: fornecedor?.value || '',
                    data: inicio !== null && fim !== null && (inicio.getTime() === fim.getTime()) ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    const handleSubmitPlanilha = useCallback(async (values, resetForm) => {
        setIsSubmittingPlanilha(true)

        const {
            tipo,
            pagamento,
            evento,
            fornecedor,
            contaCorrente
        } = values

        const inicio = pagamento ? pagamento[0] : null
        const fim = pagamento ? pagamento[1] : null

        try {
            const response = await api.post('/relatorio/contabilidade_despesa_excel', {
                relatorio: 'RelatorioContabilidadeDespesas',
                parametros: {
                    idConta: contaCorrente?.value || null,
                    tipo: tipo?.value || null,
                    idEvento: evento?.value || null,
                    idFornecedor: fornecedor?.value || null,
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null,
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            if (response.type === 'application/json') {
                const fr = new FileReader()

                fr.onload = function (e) {
                    const json = e.target.result
                    toast.warn(JSON.parse(json).msg)
                }
                fr.readAsText(response)
            } else {
                downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')
            }

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }

        setIsSubmittingPlanilha(false)
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm, values
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="pagamento"
                            label="Período para busca"
                            selectionMode="range"
                            containerStyle={{ gridArea: 'f1' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="tipo"
                            label="Tipo de evento"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Todas', value: 'D' },
                                { label: 'Despesas fixas', value: 'DF' },
                                { label: 'Despesas variáveis', value: 'DV' }
                            ]}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="contaCorrente"
                            label="Conta corrente"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={contas.map(({ id, dados_bancarios }) => ({
                                label: `${dados_bancarios.instituicao.sigla} - AG ${dados_bancarios.agencia} C/C ${dados_bancarios.conta}`,
                                value: id
                            }))}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Select
                            name="fornecedor"
                            label="Fornecedor"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={fornecedores.map(({ id, pessoa }) => ({
                                label: pessoa.nome,
                                value: id
                            }))}
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Select
                            name="evento"
                            label="Evento"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            options={eventos.filter(({ tipo_evento_contabil }) => tipo_evento_contabil.id === 3 || tipo_evento_contabil.id === 4).map(({ id, nome, conta_contabil }) => ({
                                label: `${nome}${conta_contabil?.codigo_reduzido ? ` - ${conta_contabil.codigo_reduzido}` : ''}`,
                                value: id
                            }))}
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => handleSubmitPlanilha(values, resetForm)}
                                loading={isSubmittingPlanilha}
                                className="white"
                            >
                                Gerar planilha
                                <FiFileText size={20} />
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
