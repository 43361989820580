import { format } from 'date-fns'

import { isBRDate, isUSDate, getDateObject } from './date'

export default {
    extractFormValues: (data, handleDates = true) => Object.entries(data).reduce((result, [key, value]) => {
        if(value?.value !== undefined) { // Extrai a propriedade value dos campos do tipo select (Formik) e insere na raiz do objeto
            value = value.value
        }

        if(handleDates) {
            if(isBRDate(value)) {
                value = format(getDateObject(value), 'yyyy-MM-dd HH:mm:ss')
            } else if(isUSDate(value)) {
                value = value.substr(0, 10)
            } else if(value instanceof Date) {
                value = format(value, 'yyyy-MM-dd HH:mm:ss')
            }
        }

        return {
            ...result,
            [key]: value === undefined ? '' : value
        }
    }, {})
}
