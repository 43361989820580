import React, { useContext } from 'react'
import {
    FaPhoneSquare, FaLink 
} from 'react-icons/fa'

import {
    Footer, Content, Logo, List, FootBar 
} from './styles'
import { GlobalContext } from '../../contexts/GlobalContext'

export default function () {
    const { clienteRobot } = useContext(GlobalContext) 

    return (
        <Footer>
            <Content>
                <List>
                    <Logo>
                        <img src={clienteRobot?.logo} alt="" className="object-contain" />
                    </Logo>

                    <p>{clienteRobot?.footer_content.endereco}</p>
                    <br />
                    <p>{clienteRobot?.footer_content.extra}</p>
                </List>
                
                <List>
                    <h2>
                        <FaLink size={16} />
                        Links úteis
                    </h2>

                    <ul>
                        {clienteRobot?.footer_content.links.map(link => (
                            <li key={link.href}><a href={link.href}>{link.label}</a></li>    
                        ))}
                    </ul>
                </List>

                <List>
                    <h2>
                        <FaPhoneSquare size={20} />
                        Informações de contato
                    </h2>

                    <ul>
                        {clienteRobot?.footer_content.contatos.map(contato => (
                            <li>{contato}</li>
                        ))}
                    </ul>
                </List>
            </Content>

            <FootBar>
                <p>
                    &copy; 
                    {' '}
                    {new Date().getFullYear()}
                    {' '}
                    {clienteRobot?.footer_content.copyright}
                    , todos os direitos reservados
                </p>
            </FootBar>

        </Footer>
    )
}
