import styled, { css } from 'styled-components'

export const Container = styled.div.attrs({
    className: 'pie'
})`
	canvas {
		margin: 0 auto;

		${props => props.canvas && css`
			max-width: ${props.canvas.width}px !important;
			max-height: ${props.canvas.height}px !important;
		`}
	}
`
