import React, { useContext, useEffect, useState } from 'react'
import {
    addDays, getMonth, getYear, format
} from 'date-fns'
import { toast } from 'react-toastify'
import {
    FiEye, FiChevronLeft, FiChevronRight
} from 'react-icons/fi'

import Line from '../../../../components/Chart/Line'
import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import { Select, Button } from '../../../../components/Form'
import Table from '../../../../components/Table'
import SmallScreenPlaceholder from '../../../../components/SmallScreenPlaceholder'
import Modal from '../../../../components/Modal'

import {
    Container, CardsContainer, CardImage, Paginator, ModalContent
} from './styles'

import arrayUtils from '../../../../util/array'
import dateUtils from '../../../../util/date'

import mediaImage2 from '../../../../assets/images/media_social/2.png'
import mediaImage3 from '../../../../assets/images/media_social/3.png'
import mediaImage5 from '../../../../assets/images/media_social/5.png'
import like from '../../../../assets/images/media_social/facebook/reações/like.gif'
import amei from '../../../../assets/images/media_social/facebook/reações/amei.gif'
import grr from '../../../../assets/images/media_social/facebook/reações/grr.gif'
import haha from '../../../../assets/images/media_social/facebook/reações/haha.gif'
import triste from '../../../../assets/images/media_social/facebook/reações/triste.gif'
import uau from '../../../../assets/images/media_social/facebook/reações/uau.gif'

import { api, authHeaders } from '../../../../services/api'
import { loadTips } from '../../../../util/tip'

import { TipContext } from '../../../../contexts/TipContext'

const years = []

export default function () {
    const { setCodigo } = useContext(TipContext)

    const [cardsData, setCardsData] = useState(null)
    const [engajamentosPorMes, setEngajamentosPorMes] = useState(null)
    const [visualizacoesPorMes, setVisualizacoesPorMes] = useState(null)
    const [curtidasPorMes, setCurtidasPorMes] = useState(null)
    const [year, setYear] = useState(getYear(new Date()))
    const [posts, setPosts] = useState(null)
    const [postView, setPostView] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLastPage, setIsLastPage] = useState(false)
    const [pageChanging, setPageChanging] = useState(false)

    function getSum(data) {
        return data.values.reduce((result, current) => {
            const currentDate = addDays(new Date(current.end_time), -1)
            const currentMonth = getMonth(currentDate)
            const monthName = dateUtils.months[currentMonth].label

            if (result[monthName]) {
                return {
                    ...result,
                    [monthName]: result[monthName] + current.value
                }
            }

            return {
                ...result,
                [monthName]: current.value
            }
        }, {})
    }

    async function loadPostList(page = 1) {
        try {
            const response = await api.get('facebook/posts', {
                ...authHeaders(),
                params: {
                    ano: year,
                    page,
                    itensPorPagina: 10
                }
            })

            if (response) {
                setPosts(response)
                setIsLastPage(false)
            } else {
                setCurrentPage(currentPage - 1)
                setIsLastPage(true)
            }

            setPageChanging(false)
        } catch (e) {
            toast.error('Erro ao carregar a lista de publicações.')
        }
    }

    async function loadPageInsights() {
        try {
            const response = await api.get('facebook/page_insights', {
                ...authHeaders(),
                params: { year }
            })

            const curtidas = arrayUtils.get.getLast(response[0].values).value
            const engajamentos = arrayUtils.get.getLast(response[1].values).value
            const visualizacoes = arrayUtils.get.getLast(response[2].values).value

            setCardsData({
                curtidas,
                engajamentos,
                visualizacoes
            })

            const engajamentosMes = getSum(response[1])
            const visualizacoesMes = getSum(response[2])
            const curtidasMes = getSum(response[3])

            setEngajamentosPorMes(engajamentosMes)
            setVisualizacoesPorMes(visualizacoesMes)
            setCurtidasPorMes(curtidasMes)
        } catch {
            toast.error('Houve um erro ao carregar as informações do Facebook.')
        }
    }

    useEffect(() => {
        loadPageInsights()
    }, [year])

    useEffect(() => {
        for (let i = 2014; i <= getYear(new Date()); i++) {
            years.push(i)
        }
    }, [])

    useEffect(() => {
        setPageChanging(true)

        loadPostList(currentPage)
    }, [currentPage])

    useEffect(() => {
        loadTips(setCodigo, 'form_acervo_midia_social')
    }, [])

    async function verDetalhes(item) {
        try {
            const response = await api.get(`facebook/posts_reactions/${item.id}`, authHeaders())

            setPostView({
                ...item,
                reaction: {
                    like: response.like,
                    sorry: response.sorry,
                    wow: response.wow,
                    love: response.love,
                    haha: response.haha,
                    anger: response.anger
                }
            })

            toast.success('As reações foram atualizadas.')
        } catch (e) {
            toast.error('Ocorreu um erro ao tentar atualizar as reações da publicação.')
        }
    }

    async function handlePrev() {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1)
        }
    }

    async function handleNext() {
        if (currentPage) {
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <>
            <Container>
                <CardsContainer>
                    <Select
                        label="Ano da pesquisa"
                        id="ano"
                        options={years.map(y => ({
                            label: y,
                            value: y
                        }))}
                        onChange={selected => { setYear(selected.value) }}
                        value={{ label: year, value: year }}
                        withoutForm
                        containerStyle={{ gridArea: 'select' }}
                    />

                    <Card light style={{ gridArea: 'card1' }}>
                        <CardImage src={mediaImage2} />
                        <h2>
                            {cardsData?.curtidas ?? <Spinner type="ThreeDots" color="#424242" label="" />}
                        </h2>
                        <span>curtidas na página</span>
                    </Card>

                    <Card light style={{ gridArea: 'card2' }}>
                        <CardImage src={mediaImage3} />
                        <h2>
                            {cardsData?.engajamentos ?? <Spinner type="ThreeDots" color="#424242" label="" />}
                        </h2>
                        <span>engajamentos</span>
                        <span className="extra">(apenas último dia do período)</span>
                    </Card>

                    <Card light style={{ gridArea: 'card3' }}>
                        <CardImage src={mediaImage5} />
                        <h2>
                            {cardsData?.visualizacoes ?? <Spinner type="ThreeDots" color="#424242" label="" />}
                        </h2>
                        <span>visualizações</span>
                        <span className="extra">(apenas último dia do período)</span>
                    </Card>

                    <div />

                </CardsContainer>

                <Card light style={{ gridArea: 'chart' }} className="chart">
                    {engajamentosPorMes && visualizacoesPorMes && curtidasPorMes ? (
                        <Line
                            data={[
                                {
                                    dados: engajamentosPorMes,
                                    borderColor: '#8bc34a',
                                    label: 'Engajamentos'
                                },
                                {
                                    dados: visualizacoesPorMes,
                                    borderColor: '#ff6f00',
                                    label: 'Visualizações'
                                },
                                {
                                    dados: curtidasPorMes,
                                    borderColor: '#4267B2',
                                    label: 'Curtidas'
                                }
                            ]}
                            width={900}
                            height={450}
                            fontColor="#424242"
                        />
                    ) : (
                        <Spinner color="#424242" />
                    )}
                </Card>

                <SmallScreenPlaceholder
                    text="Gire o dispositivo para visualizar as publicações."
                    hideOffset={600}
                />

                <Table
                    headers={[
                        { name: 'text', value: 'Texto da publicação' },
                        {
                            name: 'datetime', value: 'Data da publicação', style: { width: 180 }, centered: true
                        }
                    ]}
                    data={posts ? posts.map(post => ({
                        id: post.post_id,
                        text: post.conteudo,
                        datetime: format(new Date(post.data), 'dd/MM/yyyy HH:mm')
                    })) : []}
                    actions={[
                        {
                            name: 'visualizar',
                            action: line => {
                                verDetalhes(line)
                            },
                            icon: FiEye,
                            iconSize: 16
                        }
                    ]}
                    style={{ gridArea: 'table' }}
                    hideOffset={600}
                />

                <Paginator>
                    <Button onClick={handlePrev} fab className="white" disabled={currentPage === 1}>
                        <FiChevronLeft />
                    </Button>

                    <span>{pageChanging ? <Spinner label="" type="ThreeDots" size={24} containerStyle={{ marginTop: 8 }} /> : currentPage}</span>

                    <Button onClick={handleNext} fab className="white" disable={isLastPage}>
                        <FiChevronRight />
                    </Button>
                </Paginator>

            </Container>

            <Modal
                isOpen={!!postView}
                handleClose={() => setPostView(null)}
                closeOnOverlayClick
                title="Detalhes da publicação"
            >
                <ModalContent>
                    <p className="data">{`Data da publicação: ${postView?.datetime}`}</p>

                    <p className="conteudo">{postView?.text}</p>

                    <Card light className="animated fadeInUp delay-200ms">
                        <img src={like} alt="like" />
                        <h1>{postView?.reaction?.like}</h1>
                        <span>Like</span>
                    </Card>

                    <Card light className="animated fadeInUp delay-300ms">
                        <img src={amei} alt="like" />
                        <h1>{postView?.reaction?.love}</h1>
                        <span>Amei</span>
                    </Card>

                    <Card light className="animated fadeInUp delay-400ms">
                        <img src={uau} alt="like" />
                        <h1>{postView?.reaction?.wow}</h1>
                        <span>Uau!</span>
                    </Card>

                    <Card light className="animated fadeInUp delay-500ms">
                        <img src={triste} alt="like" />
                        <h1>{postView?.reaction?.sorry}</h1>
                        <span>Triste</span>
                    </Card>

                    <Card light className="animated fadeInUp delay-600ms">
                        <img src={haha} alt="like" />
                        <h1>{postView?.reaction?.haha}</h1>
                        <span>Haha</span>
                    </Card>

                    <Card light className="animated fadeInUp delay-700ms">
                        <img src={grr} alt="like" />
                        <h1>{postView?.reaction?.anger}</h1>
                        <span>Grr</span>
                    </Card>
                </ModalContent>
            </Modal>
        </>
    )
}
