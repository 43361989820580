import React, { useContext } from 'react'
import { format } from 'date-fns'
import { FaClock } from 'react-icons/fa'

import Table from '../../../../components/Table'

import { fillWithZeros } from '../../../../util/string'

import { Container } from './styles'
import { EleicaoContext } from '../../../../contexts/EleicaoContext'

export default function () {
    const { eleicao } = useContext(EleicaoContext)

    return (
        <Container>
            <div className="principal">
                {!eleicao?.chapas?.length ? (
                    <h2>Não há chapas disputando esta eleição.</h2>
                ) : eleicao.encerrado ? (
                    <>
                        {eleicao.empate ? (
                            <>
                                <h1>Resultado final</h1>
                                <h2>Houve um empate!</h2>
                            </>
                        ) : eleicao.resultado ? (
                            <>
                                <h1>Resultado final</h1>
                                <h2 dangerouslySetInnerHTML={{ __html: `Chapa <b>${eleicao?.chapas[0].descricao}</b> venceu com ${eleicao?.chapas[0].total_votos} voto${eleicao?.chapas[0].total_votos > 1 ? 's' : ''}` }} />
                            </>
                        ) : null}
                    </>
                ) : eleicao.nao_iniciada ? (
                    <h2>{`Esta eleição inicia em ${format(eleicao.inicio_date, 'dd/MM/yyyy\', às\' HH:mm\'h\'')}`}</h2>
                ) : ( // Em votação
                    <>
                        {/* <h1>Resultado parcial</h1> */}

                        {/* {!eleicao.empate && eleicao?.chapas?.length ? (
                            <h2>{`Chapa ${eleicao?.chapas[0].descricao} está na frente com ${eleicao?.chapas[0].total_votos} voto${eleicao?.chapas[0].total_votos > 1 ? 's' : ''}`}</h2>
                        ) : (
                            <h2>A eleição está empatada</h2>
                        )} */}

                        <h3>
                            <FaClock />
                            {
                                `Faltando ${eleicao?.tempo_restante_minutos > 60 * 24
                                    ? `menos de ${Math.trunc(eleicao?.tempo_restante_minutos / (60 * 24)) + 1} dias` 
                                    : eleicao?.tempo_restante_minutos >= 60 
                                        ? `menos de ${Math.trunc(eleicao?.tempo_restante_minutos / 60) + 1} hora${Math.trunc(eleicao?.tempo_restante_minutos / 60) > 1 ? 's' : ''}` 
                                        : `${fillWithZeros(Math.trunc(eleicao?.tempo_restante_minutos))} minutos`} 
                                    para encerrar a votação`
                            }
                        </h3>
                    </>
                )}
            </div>

            <Table 
                caption="Chapas"
                headers={eleicao.encerrado ? [
                    { name: 'descricao', value: 'Nome da chapa' },
                    { name: 'total_votos', value: 'Total de votos', centered: true },
                    { name: 'percentual', value: 'Percentual', centered: true }
                ] : [
                    { name: 'descricao', value: 'Nome da chapa' }
                ]}
                data={eleicao?.chapas?.map(chapa => ({
                    id: chapa.id,
                    descricao: chapa.descricao,
                    total_votos: chapa.total_votos || '0',
                    percentual: chapa.total_votos ? `${((chapa.total_votos * 100) / eleicao.total_validos).toFixed(1).replace('.', ',')}%` : '-'
                })) || []}
                emptyLabel="Nenhuma chapa foi incluída"
            />

            <div className="detalhe" style={{ gridArea: 'periodo' }}>
                <h1>Período de votação</h1>
                <span>{eleicao.periodo}</span>
            </div>

            <div className="detalhe" style={{ gridArea: 'progresso' }}>
                <h1>Progresso da votação</h1>
                <p>{`Total de eleitores aptos: ${eleicao.total_eleitores}`}</p>
                <p>{`Total de votos computados: ${eleicao.total_votos}`}</p>
                <p>{`Percentual de votos computados: ${eleicao.progresso_votacao}`}</p>
                
                {eleicao.status === 'encerrado' ? (
                    <>
                        <p>{`Total de votos válidos: ${eleicao.total_validos}`}</p>
                        <p>{`Votos nulos: ${eleicao.total_nulos}`}</p>
                        <p>{`Votos em branco: ${eleicao.total_brancos}`}</p>
                        <p>{`Abstenções: ${eleicao.total_abstencoes} (${Math.round(((eleicao.total_abstencoes * 100) / eleicao.total_eleitores) * 10) / 10}%)`.replace('.', ',')}</p>
                    </>
                ) : null}
            </div>
        </Container>
    )
}
