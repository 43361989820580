import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'box-arquivos'
})`
	grid-area: arquivos;
	display: flex;
	flex-direction: column;

	> h1 {
		font-size: 19px;
		font-weight: 500;
	}

	.empty {
		width: 100%;
		padding: 20px 0;
		margin-top: 24px;
		color: #FFF;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: #FFF3;
		border-radius: 6px;
		
		span {
			opacity: 0.5;
			font-style: italic;
		}

		svg {
			margin-bottom: 15px;
			opacity: 0.5;
		}
	}
`

export const ScrollGallery = styled.div.attrs({
    className: 'scroll-gallery'
})`
	overflow: auto;
	max-width: 830px;
	width: 100%;
	height: auto;
	align-self: center;
	display: flex;
	margin-top: 10px;
	justify-content: flex-start;
	margin: 0 8px;

	@media(max-width: 1100px) {
		max-width: 730px;
	}

	@media(max-width: 980px) {
		max-width: 600px;
	}

	@media(max-width: 810px) {
		max-width: 240px;
	}

	> div {
		display: flex;
		flex-direction: column;
		justify-content: baseline;
		align-items: center;
		margin: 0 10px 14px;

		span {
			font-size: 14px;
			color: #FFFFFF99;
			width: 120px;
			height: fit-content;
			text-align: center;
			white-space: pre-wrap;
			line-height: 20px;
		}

		span.extra {
			border: solid 1px #FFF;
			padding: 8px;
			margin-top: 4px;
			font-size: 11px;
			border-radius: 4px;
		}

		svg, .image {
			height: 140px;
			width: 100px;
			margin: 10px;
		}

		.image {
			border-radius: 4px;
			background-size: cover;
			background-position: center;
			width: 120px;
			opacity: 0.8;
		}
		
		&:hover {
			cursor: pointer;

			span {
				color: #FFF;
			}

			.image {
				opacity: 1;
			}
		}
	}

	.tooltip:after {
		max-width: 100px;
	}
`

export const ModalContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 14px;

	> p {
		text-align: left;
		width: 100%;
		padding: 0 8px 12px !important;
	}

	img, 
	iframe {
		width: 100%;
	}

	iframe {
		height: 75vh;
	}
`
