import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		min-height: 74vh;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 80px 80px 140px auto;
		grid-template-areas: 
			'demanda demanda demanda'
			'advogado data inicio'
			'motivo motivo fim'
			'button button button';

		.button-container {
			grid-area: button;
			display: flex;
			justify-content: flex-end;
		}

		@media(max-width: 850px) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 80px 80px 140px auto;
			grid-template-areas: 
				'demanda demanda'
				'advogado inicio'
				'data fim'
				'motivo motivo'
				'button button';
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
		}
	}
`
