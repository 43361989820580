import React, { useContext } from 'react'
import { format } from 'date-fns'

import ArquivosBox from './Arquivos'

import { GlobalContext } from '../../../../../contexts/GlobalContext'

import {
    AtendimentoContainer
} from './styles'
import Dialogo from '../../../../../components/Dialogo'
import { ProcessoContext } from '../../../../../contexts/ProcessoContext'
import Spinner from '../../../../../components/Spinner'

export default function () {
    const { user } = useContext(GlobalContext)
    const { processo, interacaoHabilitada, syncDialogos } = useContext(ProcessoContext)

    if (!processo) {
        return null
    }

    const { participantes } = processo
    const eDemandante = processo.demandante.id === user.pessoa.id

    return (
        <AtendimentoContainer>
            {!processo ? (
                <Spinner label="Buscando mensagens..." />
            ) : (
                <Dialogo
                    sender={{
                        id: user.pessoa.id,
                        name: user.pessoa.nome,
                        image: user.avatar
                    }}
                    minWidthToHide={359}
                    title={{
                        text: 'Chat principal'
                    }}
                    alertaEditor={eDemandante ? '' : '<b>ATENÇÃO!</b> Mensagens enviadas aqui serão compartilhadas com <b>TODOS</b> os envolvidos no atendimento.'}
                    sendWithEnterKey={false}
                    singleLine={false}
                    atendimento_id={processo.id}
                    routePrefix="processo/mensagem"
                    messages={participantes && processo.messages ? processo.messages.map(msg => {
                        const sender = participantes.find(part => part.id === msg.sender)

                        return {
                            id: msg.id,
                            datetime: format(new Date(msg.created_at), 'dd/MM/yyyy HH:mm\'h\''),
                            text: msg.content,
                            sender: {
                                id: sender?.id || null,
                                name: sender?.nome,
                                image: sender?.usuario?.avatar?.link
                            }
                        }
                    }) : null}
                    sendEmail
                    className="animated fadeIn"
                    id="dialogo-root"
                    height={750}
                    onUpdate={syncDialogos}
                    readOnly={!interacaoHabilitada}
                    readOnlyPlaceholder={processo?.status_id === 2 || !interacaoHabilitada
                        ? 'Não é possível enviar mensagens.' : undefined}
                />
            )}

            <ArquivosBox
                send_email
                uploadURL={`processo/${processo.id}/arquivo`}
            />
        </AtendimentoContainer>
    )
}
