import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FaRegSave } from 'react-icons/fa'
import { FiPlusCircle } from 'react-icons/fi'
import { format } from 'date-fns'

import { toast } from 'react-toastify'
import Modal from '../../../../components/Modal'
import Yup from '../../../../services/yup'
import BoxArquivos from '../../../../boxes/Arquivos'
import GerenciamentoDocumentos from '../../../../components/GerenciamentoDocumentos'

import { ColaboradorContext } from '../../../../contexts/ColaboradorContext'

import { Container, BoxDocumentos } from './styles'
import {
    Button, Textbox, Select, Textarea
} from '../../../../components/Form'
import getFormEnderecoV2 from '../../../../components/FormEndereco/v2'

import { api, authHeaders } from '../../../../services/api'
import masks from '../../../../util/masks'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'
import { getDate, getDateObject, getDateString } from '../../../../util/date'

const validation = Yup.object({
    nome: Yup.string().required('Informe o nome do colaborador.'),
    data_nascimento: Yup.string().required('Informe a data de nascimento do colaborador.'),
    data_admissao: Yup.string().required('Informe a data de admissão do colaborador.'),
    sexo: Yup.string().ensure().required('Informe o sexo do funcioário.'),
    perfil: Yup.string().ensure().required('Informe o perfil de usuário que o colaborador deve ter.'),
    email: Yup.string().email('E-mail inválido').required('Informe o e-mail do colaborador.'),
    fone: Yup.string().required('Informe o telefone do colaborador.'),
    atividades: Yup.string().nullable(),
    setor: Yup.string().ensure().required('Informe o setor do colaborador.'),
    cargo: Yup.string().required('Informe o cargo do colaborador.'),
    observacao: Yup.string().nullable()
})

const baseInitialValues = {
    nome: '',
    data_nascimento: '',
    data_admissao: '',
    sexo: null,
    perfil: null,
    email: '',
    fone: '',
    atividades: '',
    setor: null,
    cargo: '',
    observacao: '',

    cep: '',
    uf: null,
    cidade: null,
    bairro: '',
    logradouro: '',
    numero: '',
    complemento: ''
}

export default function () {
    const {
        colaboradorEdicao, showCadastro, setColaboradorEdicao, setShowCadastro, handleSubmit, reload
    } = useContext(ColaboradorContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [showModalDocumentos, setShowModalDocumentos] = useState(false)
    const [setores, setSetores] = useState(null)
    const [perfis, setPerfis] = useState(null)

    async function loadSetores() {
        const response = await api.get('setor', authHeaders())

        setSetores(response.map(setor => ({
            label: setor.descricao,
            value: setor.id
        })))
    }

    async function loadPerfis() {
        const response = await api.get('perfil')

        setPerfis(response.map(perfil => ({
            label: perfil.descricao,
            value: perfil.id
        })))
    }

    function getExtra(doc) {
        if (doc.validade) {
            return {
                validade: getDateString(getDate(doc.validade))
            }
        } if (doc.dataEmissao && doc.orgaoEmissor) {
            return {
                data_emissao: getDateString(getDate(doc.dataEmissao)),
                orgao_emissor: doc.orgaoEmissor
            }
        } if (doc.orgaoEmissor) {
            return {
                orgao_emissor: doc.orgaoEmissor
            }
        } if (doc.dataEmissao) {
            return {
                data_emissao: getDateString(getDate(doc.dataEmissao))
            }
        } if (doc.inicio && doc.fim) {
            return {
                inicio: format(getDateObject(doc.inicio), 'yyyy-MM-dd HH:mm:ss'),
                fim: format(getDateObject(doc.fim), 'yyyy-MM-dd HH:mm:ss'),
                cid: doc.cid
            }
        }
        return null
    }

    async function updateDocumentos(values) {
        try {
            await api.put(`documentos/${colaboradorEdicao.pessoa.id}`, {
                documentos: values.documentos
                    .map(doc => ({
                        arquivo: doc.arquivo,
                        identificador: doc.identificador,
                        tipo_documento: doc.tipo_documento.value,
                        extra: getExtra(doc)
                    }))
            }, authHeaders())

            toast.success('Documentos atualizados.')

            reload()
        } catch (e) {
            toast.error('Ocorreu um erro ao tentar atualizar os documentos.')
        }
    }

    useEffect(() => {
        loadSetores()
        loadPerfis()

        loadTips(setCodigo, 'form_colaboradores')
    }, [])

    useEffect(() => {
        if (colaboradorEdicao) {
            const email = colaboradorEdicao.pessoa.contato.find(c => c.tipo_contato_id === 6)?.contato || ''
            const fone = colaboradorEdicao.pessoa.contato.find(c => [1, 2, 3, 4, 5].includes(c.tipo_contato_id))?.contato || ''
            const cpf = colaboradorEdicao.pessoa.documentos.find(c => c.tipo_documento.id === 2)?.identificador || ''
            const rg = colaboradorEdicao.pessoa.documentos.find(c => c.tipo_documento.id === 1)?.identificador || ''
            const pis = colaboradorEdicao.pessoa.documentos.find(c => c.tipo_documento.id === 11)?.identificador || ''
            const pisArquivo = colaboradorEdicao.pessoa.documentos.find(c => c.tipo_documento.id === 11)?.arquivo?.id || null
            const curriculoArquivo = colaboradorEdicao.pessoa.documentos.find(c => c.tipo_documento.id === 48)?.arquivo?.id || null
            const arquivoCadastral = colaboradorEdicao.arquivo_cadastral?.id || null
            const foto = colaboradorEdicao.foto?.id || null
            const { endereco } = colaboradorEdicao.pessoa
            const observacao = colaboradorEdicao.observacao || null

            setInitialValues({
                nome: colaboradorEdicao.nome,
                sexo: colaboradorEdicao.pessoa.sexo === null ? null : {
                    label: colaboradorEdicao.pessoa.sexo === 'M' ? 'Masculino' : 'Feminino',
                    value: colaboradorEdicao.pessoa.sexo
                },
                perfil: {
                    label: colaboradorEdicao.pessoa.usuario.perfil.descricao,
                    value: colaboradorEdicao.pessoa.usuario.perfil.id
                },
                data_nascimento: colaboradorEdicao.pessoa.data_nascimento ? format(new Date(colaboradorEdicao.pessoa.data_nascimento), 'dd/MM/yyyy') : '',
                data_admissao: colaboradorEdicao?.data_admissao,
                email,
                fone,
                rg,
                cpf,
                pis,
                atividades: colaboradorEdicao.atividades,
                setor: {
                    label: colaboradorEdicao.setor,
                    value: colaboradorEdicao.setor_id
                },
                cargo: colaboradorEdicao.cargo,
                arquivo_cadastral: arquivoCadastral,
                arquivo_curriculo: curriculoArquivo,
                arquivo_pis: pisArquivo,
                foto,
                observacao,

                cep: endereco?.cep || '',
                uf: endereco?.uf ? { label: endereco.uf, value: endereco.uf } : null,
                cidade: endereco?.cidade ? { label: endereco.cidade, value: endereco.cidade } : null,
                bairro: endereco?.bairro || '',
                logradouro: endereco?.rua || '',
                numero: endereco?.numero || '',
                complemento: endereco?.complemento || ''
            })
        } else {
            setInitialValues(baseInitialValues)
        }
    }, [colaboradorEdicao])

    return (
        <>
            <Modal
                isOpen={!!colaboradorEdicao || showCadastro}
                handleClose={() => {
                    setColaboradorEdicao(null)
                    setShowCadastro(false)
                    setInitialValues(baseInitialValues)
                }}
                title={colaboradorEdicao ? 'Edição de colaborador' : 'Cadastro de colaborador'}
            >
                <Container>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validation}
                        enableReinitialize
                    >
                        {({
                            setFieldValue, isSubmitting
                        }) => (
                            <Form>
                                <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                                <Textbox
                                    name="nome"
                                    label="Nome"
                                />

                                <Textbox
                                    name="data_nascimento"
                                    label="Data de nascimento"
                                    mask={masks.date}
                                />

                                <Textbox
                                    name="data_admissao"
                                    label="Data de admissão"
                                    mask={masks.date}
                                />

                                <Textbox
                                    name="email"
                                    label="E-mail"
                                />

                                <Select
                                    name="setor"
                                    label="Setor"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={setores || []}
                                    containerStyle={{ gridArea: 'setor' }}
                                />

                                <Textbox
                                    name="cargo"
                                    label="Cargo"
                                    containerStyle={{ gridArea: 'cargo' }}
                                />

                                <Textarea
                                    name="atividades"
                                    label="Atividades desenvolvidas"
                                />

                                <Textbox
                                    name="fone"
                                    label="Telefone"
                                    mask={masks.mobile}
                                />

                                <Select
                                    name="sexo"
                                    label="Sexo"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={[
                                        { label: 'Masculino', value: 'M' },
                                        { label: 'Feminino', value: 'F' }
                                    ]}
                                    containerStyle={{ gridArea: 'sexo' }}
                                />

                                <Select
                                    name="perfil"
                                    label="Perfil de usuário"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={perfis || []}
                                    containerStyle={{ gridArea: 'perfil' }}
                                />

                                <Textarea
                                    name="observacao"
                                    label="Observação"
                                    containerStyle={{ gridArea: 'observacao' }}
                                />

                                <div style={{ gridArea: 'title_documentos' }} className="title">
                                    <h1>Contatos</h1>
                                </div>

                                <div style={{ gridArea: 'title_endereco' }} className="title">
                                    <h1>Endereço</h1>
                                </div>

                                {getFormEnderecoV2({ setFieldValue })}

                                {!showCadastro && (
                                    <BoxDocumentos>
                                        <h1>Documentos</h1>

                                        <FiPlusCircle
                                            data-tip="Adicionar"
                                            data-for="tooltip"
                                            onClick={() => { setShowModalDocumentos(true) }}
                                        />

                                        <BoxArquivos
                                            title={null}
                                            arquivos={colaboradorEdicao?.pessoa?.documentos.map(doc => ({
                                                link: doc.arquivo?.link || '',
                                                nome: doc.tipo_documento.descricao,
                                                extra: doc.extra
                                            }))}
                                            inAnimation={false}
                                        />
                                    </BoxDocumentos>
                                )}

                                <div className={colaboradorEdicao ? 'button-container-edicao' : 'button-container'}>
                                    <Button className="transparent" type="submit" loading={isSubmitting}>
                                        {colaboradorEdicao ? 'Salvar alterações' : 'Cadastrar'}
                                        <FaRegSave size={16} />
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </Modal>

            <Modal
                isOpen={showModalDocumentos}
                handleClose={() => { setShowModalDocumentos(false) }}
                title="Gerenciamento de documentos"
            >
                <GerenciamentoDocumentos
                    handleSubmit={updateDocumentos}
                    documentos={colaboradorEdicao?.pessoa.documentos.map(doc => ({
                        id: doc.id,
                        arquivo: doc.arquivo,
                        tipo_documento: doc.tipo_documento,
                        extra: doc.extra,
                        identificador: doc.identificador
                    }))}
                />
            </Modal>
        </>
    )
}
