import React, { useState, useEffect, useContext } from 'react'
import { endOfMonth, startOfMonth } from 'date-fns'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { FiEye, FiSearch } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { Container } from './styles'
import { api, authHeaders } from '../../../../../services/api'
import Table from '../../../../../components/Table'
import {
    Button, Calendar, Select 
} from '../../../../../components/Form'
import formUtils from '../../../../../util/form'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'
import { TipContext } from '../../../../../contexts/TipContext'
import { loadTips } from '../../../../../util/tip'

const today = new Date()

const initialValues = {
    periodo: [
        startOfMonth(today),
        endOfMonth(today)
    ],
    status: { label: 'Todos', value: '' },
    assunto: { label: 'Todos', value: '' },
    setor: { label: 'Todos', value: '' }
}

export default function () {
    const { tips, setCodigo } = useContext(TipContext)

    const [processos, setProcessos] = useState(null)
    const [statusList, setStatusList] = useState(null)
    const [assuntos, setAssuntos] = useState(null)
    const [setores, setSetores] = useState(null)

    const history = useHistory()

    const totalQuantidade = processos?.reduce((result, current) => result + current.quantidade, 0) || 0

    async function loadAssuntos() {
        const response = await api.get('atendimento_assunto', {
            params: {
                sem_juridico: 1
            },
            ...authHeaders()
        })

        setAssuntos(response)
    }

    async function loadStatus() {
        const response = await api.get('atendimento_status', authHeaders())

        setStatusList(response)
    }

    async function loadSetores() {
        const response = await api.get('setor', {
            params: {
                sem_juridico: 1
            },
            ...authHeaders()
        })

        setSetores(response)
    }

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)
            
            const {
                periodo, assunto, status, setor, quantidade 
            } = values
            const [inicio, fim] = periodo

            let response = await api.get('processo/relatorio/por_assunto', {
                params: {
                    inicio,
                    fim,
                    assunto_id: assunto,
                    status_id: status,
                    setor_id: setor,
                    quantidade_minima: quantidade
                },
                ...authHeaders()
            })

            if(!Array.isArray(response)) {
                response = [response]
            }

            setProcessos(response.map(item => ({
                id: item.assunto.id,
                descricao: item.assunto.descricao,
                quantidade: item.quantidade
            })))
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadAssuntos()

        loadStatus()

        loadSetores()
    }, [])

    useEffect(() => {
        loadTips(setCodigo, 'form_processo_relatorio_por_assunto')
    }, [])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <h1>Por assunto</h1>

                        <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                        <Calendar
                            name="periodo"
                            yearNavigator
                            selectionMode="range"
                            label="Período de busca (início e fim)"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Select 
                            label="Assunto"
                            name="assunto"
                            options={[
                                { label: 'Todos', value: '' },
                                ...(assuntos?.map(ass => ({
                                    label: ass.descricao,
                                    value: ass.id
                                })) || [])
                            ]}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <Select 
                            label="Status"
                            name="status"
                            options={[
                                { label: 'Todos', value: '' },
                                ...(statusList?.map(stt => ({
                                    label: stt.descricao,
                                    value: stt.id
                                })) || [])
                            ]}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <Select 
                            label="Grupo de atendimento"
                            name="setor"
                            options={[
                                { label: 'Todos', value: '' },
                                ...(setores?.map(set => ({
                                    label: set.descricao,
                                    value: set.id
                                })) || [])
                            ]}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <div className="button-container">
                            <Button type="submit" className="transparent">
                                Pesquisar
                                <FiSearch size={16} />
                            </Button>
                        </div>
                    
                        {processos && (
                            <Table 
                                headers={[
                                    { name: 'descricao', value: 'Assunto' },
                                    { name: 'quantidade', value: 'Quantidade de processos' }
                                ]}
                                data={processos}
                                footers={[
                                    { text: 'TOTAL', style: { fontWeight: 'bold' }, align: 'center' },
                                    { text: totalQuantidade, style: { fontWeight: 'bold' }, align: 'center' },
                                    { text: '' }
                                ]}
                                actions={[
                                    {
                                        action: item => {
                                            const data = formUtils.extractFormValues(values)

                                            history.push({
                                                pathname: '/processos',
                                                state: {
                                                    ...data,
                                                    assunto: item.id
                                                }
                                            })
                                        },
                                        name: 'Listar',
                                        icon: FiEye,
                                        iconSize: 16,
                                        checkDisabled: item => !item.quantidade
                                    }
                                ]}
                            />
                        )}

                    </Form>
                )}
            </Formik>
        </Container>
    )
}
