import styled from 'styled-components'

export const Container = styled.div`
	form {
        display: grid;
        grid-auto-columns: 1fr 1fr 1fr;
        grid-template-areas: 
            'f1 f2 f3'
            'f4 f5 f6'
            'f7 f7 f8'
            'button button button';

        @media(max-width: 900px) {
            grid-template-areas: 
                'f1 f2'
                'f3 f4'
                'f5 f6'
                'f7 f7'
                'button button';
        }

        @media(max-width: 700px) {
            grid-template-areas: 
                'f1'
                'f2'
                'f3'
                'f4'
                'f5'
                'f6'
                'f7'
                'button';
        }

        .textarea {
            width: calc(100% - 16px);
            margin: 5px 8px;
        }
    }
`
