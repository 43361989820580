import React from 'react'

import PlanoSaudeProvider from '../../../../contexts/PlanoSaudeContext'

import Listagem from './Listagem'
import Pesquisa from './Pesquisa'

import Card from '../../../../components/Card'

import { Container } from './styles'
import FaturaAvulsa from '../FaturaAvulsa'

export default function () {
    return (
        <PlanoSaudeProvider>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Contribuições de Plano de Saúde</h1>

                        <FaturaAvulsa />

                        <Pesquisa />
                        
                        <Listagem />
                    </Card>
                </main>
            </Container>
        </PlanoSaudeProvider>
    )
}
