import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			min-height: 82vh;
		}

		@media(max-width: 1110px) {
			padding: 24px 30px;
		}

		@media(max-width: 440px) {
			padding: 16px 0;
		}
	}
`
