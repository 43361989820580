import React, { createContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import maskUtils from '../util/masks'
import formUtils from '../util/form'

import { api, authHeaders } from '../services/api'

export const ExtratoContext = createContext()

const baseInitialValues = {
    fornecedor: null,
    evento: null,
    conta_corrente: null,
    forma_pagamento: '',
    competencia: '',
    data_consolidacao: '',
    valor: '',
    historico: '',
    caixa: false,
    fornecedor_pendencia: false,
    fornecedor_pendencia_observacao: ''
}

const ExtratoProvider = ({ children }) => {
    const [extratosPendentes, setExtratosPendentes] = useState(null)
    const [extratos, setExtratos] = useState(null)
    const [editId, setEditId] = useState(null)
    const [initialValues, setinitialValues] = useState(baseInitialValues)
    const [contasCorrente, setContasCorrente] = useState([])

    async function loadContasCorrente() {
        const response = await api.get('conta_sindicato', authHeaders())

        setContasCorrente(response)
    }

    async function loadExtratosPendentes() {
        const response = await api.get('extrato', authHeaders())

        setExtratosPendentes(response)
    }

    async function loadExtratos(values) {
        values = formUtils.extractFormValues(values)

        const { competencia, conta_corrente } = values

        const response = await api.get('extratos_bancarios', {
            ...authHeaders(),
            params: {
                competencia,
                conta_corrente
            }
        })

        setExtratos(response)
    }

    async function handleFilterExtratos(values) {
        const { conta_corrente, competencia } = values

        loadExtratos({
            competencia: competencia ? format(new Date(competencia), 'yyyy-MM-\'01\'') : null,
            conta_corrente
        })
    }

    useEffect(() => {
        loadExtratos({
            competencia: format(new Date(), 'yyyy-MM-\'01\'')
        })

        loadExtratosPendentes()

        loadContasCorrente()
    }, [])

    async function handlePrepareEdit(id) {
        setEditId(id)

        const {
            historico, valor, data_consolidacao, conta_corrente
        } = await api.get(`extrato/${id}`, authHeaders())

        setinitialValues({
            conta_corrente: {
                value: conta_corrente.id,
                label: `${conta_corrente.dados_bancarios.instituicao.sigla} - AG ${conta_corrente.dados_bancarios.agencia} C/C ${conta_corrente.dados_bancarios.conta}`
            },
            data_consolidacao: new Date(data_consolidacao),
            valor: maskUtils.maskApply.currency(valor),
            historico,
            fornecedor: '',
            evento: '',
            forma_pagamento: '',
            competencia: '',
            caixa: false,
            fornecedor_pendencia: false,
            fornecedor_pendencia_observacao: ''
        })
    }

    async function handleDelete(id) {
        try {
            await api.delete(`extrato/${id}`, authHeaders())

            toast.success('Lançamento excluído com sucesso!')

            return true
        } catch (e) {
            return false
        }
    }

    async function handleAdd(values) {
        try {
            values = formUtils.extractFormValues(values)

            const body = {
                ...values,
                fornecedor: values.fornecedor || null,
                evento: values.evento || null,
                conta_corrente: values.conta_corrente || null
            }

            await api.post('lancamento', body, authHeaders())

            await api.delete(`extrato/${editId}`, authHeaders())

            setEditId(null)

            setinitialValues(baseInitialValues)

            loadExtratosPendentes()

            toast.success('Lançamento cadastrado!')
        } catch (e) {
            toast.error('Erro ao cadastrar lançamento!')
        }
    }

    return (
        <ExtratoContext.Provider value={{
            extratosPendentes,
            loadExtratosPendentes,
            loadExtratos,
            extratos,
            editId,
            setEditId,
            handlePrepareEdit,
            handleDelete,
            handleAdd,
            initialValues,
            handleFilterExtratos,
            contasCorrente
        }}
        >
            {children}
        </ExtratoContext.Provider>
    )
}

export default ExtratoProvider
