import styled, { css } from 'styled-components'

function getGridAreas(lines) {
    switch(lines) {
        case 1: return css`
			grid-template-areas: 'icon x';
		`

        case 2: return css`
			grid-template-areas: 
				'icon x'
				'icon y';
		`

        case 4: return css`
			grid-template-areas: 
				'icon x'
				'icon y'
				'icon z'
				'icon w';
			`

        default: return css`
			grid-template-areas: 
				'icon x'
				'icon y'
				'icon z';
		`
    }
}

export const Container = styled.div`
	display: grid;
	grid-template-columns: 4fr 1fr;
	grid-gap: 10px;
	margin: 5px 8px;
	font-size: 15px;

	.table-container {
		grid-column: 1/span 2;
		margin-top: 12px;
	}

	.table-title {
		grid-column: 1/span 2;
		margin-top: 12px;
		display: grid;
		grid-template-columns: 1fr 200px;
		font-size: 17px;
		font-weight: 500;

		.badge-mini {
			margin-top: 14px;
		}

		button {
			grid-row: 1/span 2;
			grid-column: 2;
		}

		@media(max-width: 1000px) {
			> span {
				grid-column: 1;
			}

			button {
				grid-row: 1;
			}

			.badge-mini {
				grid-column: 1/span 2;
			}
		}

		@media(max-width: 580px) {
			display: flex;
			flex-direction: column;

			button {
				width: 100%;
				margin: 10px 0 0;
			}

			.badge-mini {
				width: 100%;
				margin-top: 10px;
			}
		}
	}

	.badge {
		grid-column: 1/span 2;
		justify-self: center;
	}

	@media(max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
`

export const DadosContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	grid-column: 1/span 2;

	.datas-container {
		display: flex;
		justify-content: space-between;
		gap: 0 18px;
		
		@media(max-width: 900px) {
			flex-direction: column;
			line-height: 30px;
		}
	}

	.detalhes {
		margin-top: 10px;
	}
`

export const ButtonsContainer = styled.div`
	display: flex;
	grid-column: 1/span 2;
	gap: 10px;
	justify-content: flex-end;
	align-items: flex-end;

	+ .badge-mini {
		display: block;
		display: flex;
		justify-self: flex-end;
		grid-column: 1/span 2;

		.large {
			font-size: 13px;
		}
	}

	button {
		margin: 8px;
		align-self: center;
	}

	@media(max-width: 900px) {
		justify-content: center;
	}

	@media(max-width: 600px) {
		flex-direction: column;
		
		button {
			width: 100%;
		}
	}
`

export const AddDependenteContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	p {
		margin: 8px;
	}

	.table-container {
		margin: 5px 8px;
		width: calc(100% - 16px);
	}
	
	.react-tabs {
		margin-bottom: 20px;
		width: 100%;

		.react-tabs__tab-list {
			display: flex;
			gap: 3px;
			white-space: nowrap;
			overflow-x: auto;
			overflow-y: hidden;
			margin-bottom: -1px;
			width: calc(100vw - 20vw - 80px);

			@media(max-width: 600px) {
				width: calc(100vw - 20vw - 95px);
			}
		}

		.react-tabs__tab-panel {
			width: 100%;
		}

		@media(max-width: 500px) {
			display: none;
		}
	}

	button {
		align-self: flex-end;
		width: fit-content;
		margin-top: 20px;
	}

	.badge {
		margin-top: 20px;
		width: 100%;
	}

	.table-container {
		margin-top: 24px;
	}

	.dependente-form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		@media(max-width: 800px) {
			display: flex;
			flex-direction: column;
		}
	}

	.empty-placeholder {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 15px;
		grid-column: 1/span 3;
		font-style: italic;
	}

	.aviso-nenhum-dependente {
		p {
			text-align: center;
		}
		
		a {
			color: white;
			font-weight: 700;
		}
	}
`

export const ModalCancelarContainer = styled.div`
	padding: 0 8px;
	display: flex;
	flex-direction: column;
	margin-top: 20px;

	.button-container {
		display: flex;
		flex: 1;
		justify-content: flex-end;
	}

	.badge {
		align-self: center;
		max-width: 600px;
		width: 100%;
		margin: 24px;
	}
`

export const ModalDependentesDocumentosContainer = styled.div`
	form {
		display: flex;
		flex-direction: column;

		> button {
			align-self: flex-end;

			svg {
				font-size: 16px;
			}
		}
	}
`

export const DocumentosContainer = styled.div`
	grid-area: arquivos;
	display: grid;
	margin-bottom: 20px;
	grid-template-columns: 1.4fr 1fr 1.6fr;
	grid-template-rows: 70px 70px 76px auto;
	grid-template-areas: 
		'anexo tipo table'
		'anexo f1 table'
		'anexo f2 table'
		'anexo button table';

	> h1 {
		grid-area: title;
		align-self: flex-end;
	}

	.select {
		grid-area: tipo;
	}

	.file {
		grid-area: anexo;
		align-self: flex-start;
	}

	.table-container {
		grid-area: table;
		width: calc(100% - 8px);
		justify-self: flex-end;
		margin-top: 24px;
	}

	> button {
		grid-area: button;
		align-self: flex-start;
	}
`

export const Badge = styled.div.attrs({
    className: 'badge'
})`
	background-color: #ffeb3b;
	border-radius: 6px;
	color: #424242;
	display: grid;
	grid-gap: 14px 0;
	line-height: 22px;
	grid-template-columns: 60px auto;
	align-items: center;
	font-size: 13px;
	margin-top: 0;
	width: fit-content;
	padding: 24px 14px 14px;

	${props => getGridAreas(props.lines)}

	span {
		display: flex;

		> svg {
			margin: 0 4px;
		}
	}

	> button {
		width: fit-content;
		justify-self: center;
	}

	svg {
		color: #424242;
		cursor: default;

		&:hover {
			opacity: 1;
		}
	}

	> svg {
		grid-area: icon;
		justify-self: center;
		margin-right: 12px;
		font-size: 26px;
	}

	a {
		color: #424242;
		font-weight: 700;
	}
`

export const MiniBadge = styled.div.attrs({
    className: 'badge-mini'
})`
	background-color: #ffeb3b;
	border-radius: 6px;
	color: #424242;
	line-height: 22px;
	font-size: 11px;
	margin-top: 0;
	width: fit-content;
	padding: 2px 8px;
`
