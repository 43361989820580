import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		@media(max-width: 800px) {
			.chart {
				display: none;
			}
		}
	}
`

export const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.fields {
		display: flex;
		gap: 16px;
		width: 600px;
	}

	@media(max-width: 970px) {
		.fields {
			width: 400px;
		}
	}

	@media(max-width: 800px) {
		display: none;
	}
`

export const SomatoriasContainer = styled.div`
	display: flex;
	flex-direction: column;
	line-height:  24px;
	background-color: #fff;
	padding: 8px 18px;
	color: #424242;
	border-radius: 10px;
`
