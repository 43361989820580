import React, { useContext, useEffect } from 'react'

import {
    FaEdit, FaEye, FaTrash 
} from 'react-icons/fa'

import { FiCheck, FiX } from 'react-icons/fi'
import { OperacaoContext } from '../../../../contexts/OperacaoContext'

import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'

import { Container, OperacaoDetalheContainer, DocumentosContainer } from './styles'
import { GlobalContext } from '../../../../contexts/GlobalContext'

import { Button } from '../../../../components/Form'
import { forceDownloadFileFromURL } from '../../../../util/file'

export default function ({ id }) {
    const {
        operacoes, handleDelete, handlePrepareEdit, handleAutorizar, handleDesautorizar, operacaoDetalhe, setOperacaoDetalhe
    } = useContext(OperacaoContext)
    const { user } = useContext(GlobalContext)

    useEffect(() => {
        if(id && operacoes) {
            const operacaoAberta = operacoes.find(o => String(o.id) === id)

            setOperacaoDetalhe(operacaoAberta || null)
        }
    }, [operacoes, id])

    return (
        <>
            <Container>
                {operacoes ? (
                    <Table 
                        headers={[
                            { name: 'descricao', value: 'Operação' },
                            { name: 'tipo', value: 'Tipo de operação', centered: true },
                            { name: 'autorizacoes_quantidade', value: 'Autorizações concedidas', centered: true },
                            { name: 'data_autorizacao_formatada', value: 'Data de autorização', centered: true }
                        ]}
                        data={operacoes}
                        actions={[
                            {
                                action: setOperacaoDetalhe,
                                name: 'Detalhar',
                                icon: FaEye,
                                iconSize: 16
                            },
                            {
                                action: item => { handlePrepareEdit(item.id) },
                                name: 'Editar',
                                icon: FaEdit,
                                iconSize: 16,
                                checkDisabled: item => item.criador.id !== user.pessoa.id
                            },
                            {
                                action: item => { handleDelete(item.id) },
                                name: 'Excluir',
                                icon: FaTrash,
                                iconSize: 15,
                                checkDisabled: item => item.criador.id !== user.pessoa.id,
                                confirmation: 'Deseja realmente excluir esta operação?'
                            }
                        ]}
                    />
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={!!operacaoDetalhe}
                handleClose={() => { setOperacaoDetalhe(null) }}
                title="Detalhes da operação"
            >
                <OperacaoDetalheContainer>
                    <h1>{operacaoDetalhe?.descricao}</h1>

                    <div className="tipo" dangerouslySetInnerHTML={{ __html: `Tipo: <b>${operacaoDetalhe?.operacao_tipo.descricao}</b>` }} />

                    <div className="acao-container">
                        {operacaoDetalhe?.operacao_tipo.autorizadores.some(a => a.id === user.pessoa.id) && !operacaoDetalhe?.autorizacoes.some(a => a.id === user.pessoa.id) ? (
                            <Button className="white" onClick={() => { handleAutorizar(operacaoDetalhe) }}>
                                Autorizar operação
                                <FiCheck size={16} />
                            </Button>    
                        ) : (operacaoDetalhe?.operacao_tipo.autorizadores.some(a => a.id === user.pessoa.id) && operacaoDetalhe?.autorizacoes.some(a => a.id === user.pessoa.id)) ? (
                            <Button className="white" onClick={() => { handleDesautorizar(operacaoDetalhe) }}>
                                Revogar autorização
                                <FiX size={16} />
                            </Button>
                        ) : null}
                    </div>

                    <Table 
                        headers={[
                            { name: 'nome', value: 'Autorizador' },
                            { name: 'status', value: 'Status', icon: true }
                        ]}
                        data={operacaoDetalhe?.operacao_tipo.autorizadores.map(a => ({
                            id: a.id,
                            nome: a.nome,
                            status: operacaoDetalhe?.autorizacoes.find(aut => aut.id === a.id) ? 'FaCheck' : null,
                            iconColor: operacaoDetalhe?.autorizacoes.find(aut => aut.id === a.id) ? '#4caf50' : '#fff'
                        })) || []}
                        caption="Autorizadores"
                    />

                    <DocumentosContainer>
                        <Table 
                            headers={[
                                { name: 'descricao', value: 'Documento' }
                            ]}
                            data={operacaoDetalhe?.arquivos.map((a, index) => ({
                                id: a.id,
                                descricao: `Arquivo ${index + 1}`,
                                link: a.link
                            })) || []}
                            actions={[
                                {
                                    action: item => { forceDownloadFileFromURL(item.link) },
                                    name: 'Visualizar',
                                    icon: FaEye,
                                    iconSize: 16
                                }
                            ]}
                            caption="Documentos"
                        />
                    </DocumentosContainer>
                </OperacaoDetalheContainer>
            </Modal>
        </>
    )
}
