import React from 'react'

import { Container } from './styles'

export default function ({ children, style = {}, ...props }) {
    return (
        <Container style={style} {...props}>           
            {children}
        </Container>
    )
}
