import React, { useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useHistory } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'

import { Container } from './styles'
import Card from '../../../../components/Card'
import RespostasPorQuestao from './ProporcaoRespostasPorQuestao'
import { Button } from '../../../../components/Form'

export default function () {
	const [indiceTabAtiva, setIndiceTabAtiva] = useState(0)
	const history = useHistory()

	return (
		<Container>
			<main className="animated fadeIn faster">
				<Card>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h1>Estatísticas de enquetes</h1>
						<Button className="transparent" onClick={() => { history.push('/grupo_permissoes/29') }} style={{ gap: 10 }}>
							<FiArrowLeft size={16} style={{ marginLeft: 0 }} />
							Voltar
						</Button>
					</div>

					<Tabs
						selectedIndex={indiceTabAtiva}
						onSelect={indiceTab => setIndiceTabAtiva(indiceTab)}
					>
						<TabList>
							<Tab>Respostas por questão</Tab>
						</TabList>

						<TabPanel>
							<RespostasPorQuestao />
						</TabPanel>
					</Tabs>
				</Card>
			</main>
		</Container>
	)
}
