import styled from 'styled-components'

export const Container = styled.div`
	form {
		.actions-container,
		.button-container {
			display: flex;
			justify-content: flex-end;
		}

		.button-container {
			margin-top: 20px;
		}
	}
`

export const DiaAtendimentoContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr) 40px;
	align-items: flex-end;
	width: calc(100% - 16px);
	grid-template-areas: 
		'semana inicio fim tempo excluir'
		'badge badge badge badge badge';

	.remove {
		margin-bottom: 8px;
	}

	svg {
		font-size: 18px;
	}

	.badge {
		padding: 6px 8px;
		background-color: #ffeb3b;
		border-radius: 10px;
		height: fit-content;
		width: fit-content;
		color: #424242;
		grid-area: badge;
		margin: 8px;
		line-height: 24px;

		p {
			text-indent: 30px;
		}
	}

	@media(max-width: 900px) {
		grid-template-columns: repeat(2, 1fr) 40px;
		border-bottom: solid 1px #fff;
		padding-bottom: 16px;
		grid-template-areas: 
			'semana tempo excluir'
			'inicio fim excluir'
			'badge badge badge';

		.remove {
			grid-area: excluir;
			align-self: center;
		}
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`
