import React from 'react'

import OrcamentoProvider from '../../../contexts/OrcamentosContext'

import Gerenciar from './Gerenciar'

export default function () {
    return (
        <OrcamentoProvider>
            <Gerenciar />
        </OrcamentoProvider>
    )
}
