import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 14px; 
`

export const RegrasDescontoContainer = styled.div.attrs({
    className: 'descontos-container'
})`
	grid-area: desc;
	display: flex;
	flex-direction: column;
	margin-top: 16px;

	h1 {
		font-size: 18px;
	}

	button {
		justify-self: end;
		width: fit-content;
	}
`

export const RegraDesconto = styled.div.attrs({
    className: 'desconto-item animated fadeInLeft faster'
})`
	display: grid;
	grid-template-columns: 1fr 1fr 40px;
	align-items: flex-end;
	grid-gap: 12px;

	button {
		width: 34px;
		height: 34px;
		margin-bottom: 12px;
	}
`

export const CadastroFaturaAvulsaContainer = styled.div`
	form {
		min-height: 85vh;
		display: grid;
		grid-template-areas:
			'tip tip tip tip'
			'busca busca busca busca'
			'f0 f1 f1 f3'
			'f4 f5 f6 y'
			'f8 f8 x x'
			'desc desc desc desc'
			'button button button button';
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: min-content;

		.forma_pagamento {
			grid-area: f8;
		}

		> button {
			justify-self: end;
			width: fit-content;
		}

		@media(max-width: 900px) {
			grid-template-areas:
				'tip tip'
				'busca busca'
				'f0 f3'
				'f1 f1'
				'f4 f5'
				'f6 y'
				'f8 f8'
				'desc desc'
				'button button';
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto 90px 90px;		
		}

		@media(max-width: 650px) {
			display: flex;
			flex-direction: column;

			> button {
				align-self: end;
			}
		}
	}
`

export const BuscaAssociadoContainer = styled.div`
	grid-area: busca;
	display: flex;
	gap: 8px;

	button {
		width: 210px;
		margin-top: 24px;
	}

	@media(max-width: 650px) {
		flex-direction: column;

		button {
			width: 210px;
			margin-top: 0;
			align-self: end;
		}
	}
`

export const AssociadosListaContainer = styled.ul`
	width: 80%;

	li {
		padding: 8px 12px;
		background-color: #fff;
		color: #424242;

		&:hover {
			background-color: #dedede;
			cursor: pointer;
		}
	}
`
