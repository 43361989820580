import React, { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'

import { format } from 'date-fns'
import { Textbox } from '../Form'

import { api, authHeaders } from '../../services/api'

import { Container, ResultadosContainer } from './styles'

export default function ({
    name,
    label,
    onChange = () => { },
    onNotFound = () => { },
    style = {},
    onSelectResultado = () => { },
    limiteResultados = 8,
    normalizar
}) {
    const [resultadoBusca, setResultadoBusca] = useState([])
    const [busca, setBusca] = useState('')
    const [selecionado, setSelecionado] = useState('')

    async function buscar(search) {
        try {
            if (!search) {
                onNotFound?.()
                return
            }

            let response = await api.get('pessoa', {
                params: {
                    nome: search
                },
                ...authHeaders()
            })

            if (normalizar) {
                response = normalizar(response)
            }

            const listaParcial = response.filter(p => (p.fornecedor && response.filter(r => r.nome === p.nome).length === 1) || !p.fornecedor)

            if (listaParcial.length) {
                setResultadoBusca(listaParcial.slice(0, limiteResultados).map(r => ({
                    ...r,
                    crm: r.documentos.find(d => d.tipo_documento.id === 3)?.identificador,
                    nome: r.nome,
                    status_associacao: r.associado?.status_associacao?.descricao,
                    data_ultima_atualizacao: r.data_ultima_atualizacao && r.associado ? format(new Date(r.data_ultima_atualizacao), 'dd/MM/yyyy') : null,
                    alerta_atualizacao: !!r.associado
                })))
            } else {
                setResultadoBusca([])
            }
        } catch (e) {
            console.log(e)

            toast.error('Erro ao buscar por nome.')
        }
    }

    useEffect(() => {
        if (selecionado) {
            setBusca(selecionado.nome)
        }
    }, [selecionado])

    const handleBuscar = debounce(buscar, 1000)

    return (
        <Container>
            <Textbox
                withoutForm
                id={name}
                label={label}
                onChange={e => {
                    setBusca(e.target.value)

                    handleBuscar(e.target.value)

                    if (onChange) {
                        onChange(e)
                    }
                }}
                value={busca}
                style={style}
            />

            {resultadoBusca.length > 0 && (
                <ResultadosContainer>
                    {resultadoBusca.map(result => (
                        <li
                            key={result.id}
                            onClick={() => {
                                setResultadoBusca([])

                                setSelecionado(result)

                                onSelectResultado(result)
                            }}
                        >
                            {result.nome}
                        </li>
                    ))}
                </ResultadosContainer>
            )}
        </Container>
    )
}
