import styled from 'styled-components'

export const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	grid-column: 1/span 2;
`

export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background: rgba(0, 0, 0, 0.4);
	padding: 30px 60px;

	@media (max-width: 700px) {
		flex-direction: column;
		padding: 30px 20px;
	}
`

export const Logo = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 20px 0;

	img {
		height: 50px;
	}

	@media (max-width: 700px) {
		min-height: 1vh;
		padding: 24px;
	}
`

export const List = styled.div`
	width: 30%;
	padding: 20px 10px;

	h2 {
		font-size: 18px;
		font-weight: 400;
		display: flex;
		color: #fff;
		align-items: center;
		margin-left: 16px;
		margin-bottom: 14px;

		svg {
			margin-right: 10px;
		}
	}

	p {
		font-size: 13px;
		color: #fff;
		margin-left: 16px;
	}

	ul {
		li {
			margin: 4px 30px;
			display: flex;
			align-items: center;
			color: #fff;
			font-size: 13px;

			svg {
				margin-left: 8px;
			}
		}
	}

	a {
		font-size: 13px;
		color: #fff;

		&:hover {
			opacity: 0.6;
			color: #fff;
		}
	}

	@media (max-width: 700px) {
		width: 100%;
		min-height: 10vh;
	}
`

export const FootBar = styled.div`
	background: rgba(0, 0, 0, 0.6);
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		font-size: 11px;
		color: #8cbaa0;
	}
`
