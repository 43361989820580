import React, { createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

import formUtils from '../util/form'

export const RegraContext = createContext()

const baseInitialValues = {
    descricao: '',
    definicao: '',
    visao: null
}

export default function RegraProvider({ children }) {
    const [regras, setRegras] = useState(null)
    const [visoes, setVisoes] = useState(null)
    const [regraEdit, setRegraEdit] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function loadRegras() {
        const response = await api.get('regra', authHeaders())

        setRegras(response)
    }

    async function loadVisoes() {
        const response = await api.get('visao', authHeaders())

        setVisoes(response)
    }

    function handlePrepareEdit(regra) {
        setRegraEdit(regra)

        const { descricao, definicao, visao } = regra

        setInitialValues({
            descricao,
            definicao,
            visao: visao ? {
                label: visao.descricao,
                value: visao.id
            } : null
        })
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            values = formUtils.extractFormValues(values)

            if (Object.values(values).some(v => !v)) {
                toast.error('Preencha todos os campos.')
                return
            }

            if (regraEdit) {
                await api.put(`regra/${regraEdit.id}`, values, authHeaders())

                toast.success('Regra atualizada.')

                setRegraEdit(null)
            } else {
                await api.post('regra', values, authHeaders())

                toast.success('Regra cadastrada.')
            }

            loadRegras()

            setInitialValues(baseInitialValues)

            resetForm()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(regra) {
        try {
            await api.delete(`regra/${regra.id}`, authHeaders())

            loadRegras()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadRegras()

        loadVisoes()
    }, [])

    return (
        <RegraContext.Provider
            value={{
                regras,
                handleSubmit,
                handleDelete,
                handlePrepareEdit,
                initialValues,
                regraEdit,
                visoes
            }}
        >
            {children}
        </RegraContext.Provider>
    )
}
