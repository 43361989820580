import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'atendente-container'
})`
	grid-area: atendente;
	display: grid;
	grid-template-columns: 76px 1fr;
	grid-template-rows: 25px 30px;
	padding: 12px;

	img {
		object-fit: cover;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
		grid-row: 1/span 2;
	}

	h2, p {
		font-size: 11px;
		color: #424242;
		font-weight: 500;
		align-self: flex-end;
	}

	h2 {
		font-size: 13px;
		font-weight: 500;
		align-self: flex-start;
	}
`
