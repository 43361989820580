import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify' 

import {
    Select, Button, Textbox 
} from '../../../../../components/Form'

import maskUtils from '../../../../../util/masks'
import formUtils from '../../../../../util/form'
import { getDateObject } from '../../../../../util/date'

import { Container } from './styles'

import ComandoForm from './Comando'
import DatabaseForm from './Database'
import MensageriaForm from './Mensageria'

import { api, authHeaders } from '../../../../../services/api'
import Yup from '../../../../../services/yup'

import { AutomaticoContext } from '..'
import { TipContext } from '../../../../../contexts/TipContext'

const baseInitialValues = {
    tipo: null,
    nome: '',
    recorrencia: null,
    hora: '',
    inicio: '',
    fim: ''
}

const recorrencias = [
    { label: 'Uma vez por minuto', value: 9 },
    { label: 'Uma vez por hora', value: 8 },
    { label: 'Diário', value: 1 },
    { label: 'Semanal', value: 2 },
    { label: 'Quinzenal', value: 3 },
    { label: 'Mensal', value: 4 },
    { label: 'Trimestral', value: 5 },
    { label: 'Semestral', value: 6 },
    { label: 'Anual', value: 7 }
]

const baseValidation = Yup.object({})

export default function () {
    const { reload } = useContext(AutomaticoContext)
    const { tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [visoes, setVisoes] = useState(null)
    const [profiles, setProfiles] = useState(null)
    const [validation, setValidation] = useState(baseValidation)

    async function loadProfiles() {
        const response = await api.get('task_profiles', authHeaders())

        setProfiles(response)
    }

    async function loadVisoes() {
        const response = await api.get('visao', authHeaders())

        setVisoes(response)
    }

    useEffect(() => {
        loadVisoes()

        loadProfiles()
    }, [])

    function getCronExpressionByRecorrencia(recorrencia) {
        switch(recorrencia) {
            case 1: return '* * *'
            case 2: return '*/7 * *'
            case 3: return '*/15 * *'
            case 4: return '1 * *'
            case 5: return '1 */3 *'
            case 6: return '1 */6 *'
            case 7: return '1 */12 *'
            case 8: return '0 0 * * * *'
            case 9: return '0 * * * * *'
            default: throw new Error('Recorrência não tratada.')
        }
    }

    function getTaskData(values) {
        switch(values.tipo) {
            case 1: return {
                sql: `SELECT email recipient FROM ${visoes.find(v => v.id === values.visao).view}`,
                rule_id: values.template
            }
            case 2: return {
                sql: values.sql
            }
            case 3: return {
                comando: values.comando
            }
            default: return null
        }
    }

    async function handleSubmit(values) {
        try {
            const { inicio, fim } = values

            values = formUtils.extractFormValues(values)

            const {
                recorrencia, hora: horario
            } = values

            const [hora, min] = horario.split(':')

            const expressaoRecorencia = getCronExpressionByRecorrencia(recorrencia)

            const isCompleteExpression = expressaoRecorencia.length === 11

            const expressaoCron = isCompleteExpression ? expressaoRecorencia : `0 ${Number(min)} ${Number(hora)} ${expressaoRecorencia}`

            const data = getTaskData(values)

            await api.post('task', {
                description: values.nome,
                expression: expressaoCron,
                profile_id: values.tipo,
                db_connection_id: values.conexao,
                inicio: getDateObject(inicio),
                fim: getDateObject(fim),
                data
            }, authHeaders())

            toast.success('Tarefa cadastrada com sucesso.')

            setInitialValues(baseInitialValues)

            reload()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    function getFormByType(tipoId, setFieldValue) {
        switch(tipoId) {
            case 1: return <MensageriaForm setFieldValue={setFieldValue} />
            case 2: return <DatabaseForm setFieldValue={setFieldValue} />
            case 3: return <ComandoForm />
            default: return null
        }
    }

    function updateInitialValuesAndValidation(tipoId, values) {
        const profile = profiles.find(p => p.id === tipoId)
        const tipo = {
            label: profile.description,
            value: profile.id
        }
        const { nome } = values

        switch(tipoId) {
            case 1: 
                setValidation(baseValidation.concat(
                    Yup.object({
                        tipo: Yup.string().ensure().required('Selecione o tipo de rotina.'),
                        nome: Yup.string().required('Dê um nome à rotina.'),
                        conexao: Yup.string().ensure().required('Selecione a base de dados.'),
                        template: Yup.string().ensure().required('Selecione a mensagem a ser enviada.'),
                        visao: Yup.string().ensure().required('Selecione o grupo de destinatários da mensagem.')
                    })
                ))

                setInitialValues({
                    ...baseInitialValues,
                    tipo,
                    nome,
                    conexao: null,
                    template: null,
                    visao: null
                })
                return

            case 2: 
                setValidation(baseValidation.concat(
                    Yup.object({
                        tipo: Yup.string().ensure().required('Selecione o tipo de rotina.'),
                        nome: Yup.string().required('Dê um nome à rotina.'),
                        conexao: Yup.string().ensure().required('Selecione a base de dados.'),
                        sql: Yup.string().required('Informe a consulta SQL a ser executada na rotina.')
                    })
                ))
            
                setInitialValues({
                    ...baseInitialValues,
                    tipo,
                    nome,
                    sql: '',
                    conexao: null
                })
                return

            case 3: 
                setValidation(baseValidation.concat(
                    Yup.object({
                        tipo: Yup.string().ensure().required('Selecione o tipo de rotina.'),
                        nome: Yup.string().required('Dê um nome à rotina.'),
                        comando: Yup.string().required('Informe o comando a ser executado na rotina.')
                    })
                ))

                setInitialValues({
                    ...baseInitialValues,
                    tipo,
                    nome,
                    comando: ''
                })
                return

            default: return null
        }
    }

    return (
        <>
            <Container className="animated fadeIn faster">
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validation}
                    enableReinitialize
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>
                            
                            <Textbox 
                                name="nome"
                                label="Nome da tarefa"
                            />

                            <Select 
                                label="Tipo de tarefa"
                                name="tipo"
                                onChange={(selected, meta) => { 
                                    setFieldValue(meta.name, selected) 

                                    updateInitialValuesAndValidation(selected.value, values)
                                }}
                                options={profiles?.map(profile => ({
                                    label: profile.description, 
                                    value: profile.id
                                })) || []}
                            />

                            {getFormByType(values.tipo?.value, setFieldValue)}

                            <Select 
                                label="Recorrência"
                                name="recorrencia"
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                options={recorrencias}
                            />

                            <Textbox 
                                name="hora"
                                label="Horário"
                                mask={maskUtils.time}
                            />

                            <Textbox 
                                name="inicio"
                                label="Iniciar em"
                                mask={maskUtils.datetime}
                            />

                            <Textbox 
                                name="fim"
                                label="Terminar em"
                                mask={maskUtils.datetime}
                            />

                            <div className="button-container">
                                <Button type="submit" className="transparent">
                                    Criar tarefa
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}
