import React, { useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FaFileUpload } from 'react-icons/fa'
import Yup from '../../../../../services/yup'

import { apiLegadoCarga } from '../../../../../services/api'

import { File, Select, Button } from '../../../../../components/Form'

import { InconsistenciaContext } from '../../../../../contexts/InconsistenciaContext'

import { Container } from './styles'

export default function () {
    const { loadInconsistencias } = useContext(InconsistenciaContext)

    const [arquivo, setArquivo] = useState(null)

    async function handleSubmit(values, { resetForm }) {
        try {
            const formData = new FormData()           
            formData.append('tipoArquivo', values.tipo.value)
            formData.append('arquivo', arquivo)

            const { data } = await apiLegadoCarga.post('processamentoArquivoAtualizacoes', formData)

            if(!data.status) {
                throw new Error(data.msg)
            }

            resetForm()

            toast.success(data.msg || 'Arquivo carregado com sucesso!')

            loadInconsistencias()
        } catch(e) {
            toast.error(e.message || 'Erro ao carregar o arquivo.')
        }
    }

    async function handleUpload(file) {
        setArquivo(file)
    }

    return (
        <Container>
            <Formik
                initialValues={{
                    arquivo: null,
                    tipo: ''
                }}
                validationSchema={Yup.object().shape({
                    arquivo: Yup.number().required('Anexe o arquivo'),
                    tipo: Yup.string().ensure().nullable().required('Selecione o tipo de arquivo')
                })}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, errors, isSubmitting }) => (
                    <Form>
                        <h1>Carga de arquivos de contribuição</h1>
							
                        <File 
                            name="arquivo"
                            onSuccess={fileId => setFieldValue('arquivo', fileId)}
                            label="Arquivo para carregar"
                            format="square"
                            previewSize={['100%', '140px']}
                            error={errors.declaracao}
                            getFile={handleUpload}
                        />

                        <Select 
                            name="tipo"
                            label="Tipo de arquivo"
                            onChange={(selected, actionMeta) => setFieldValue(actionMeta.name, selected)}
                            options={[
                                { label: 'Anuidades', value: '3' },
                                { label: 'Mensalidades', value: '5' }
                            ]}
                        />

                        <Button type="submit" className="white" loading={isSubmitting}>
                            Carregar aquivo
                            <FaFileUpload size={20} />
                        </Button>
                    </Form>
                )}
            </Formik>   
        </Container>
    )
}
