import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			'f1 f2 f3'
			'f4 f5 f6'
			'subtitle subtitle subtitle'
			'arquivos arquivos arquivos'
			'button button button';

		> button {
			grid-area: button;
			justify-self: flex-end;
		}

		> h2 {
			grid-area: subtitle;
			font-size: 18px;
			font-weight: 500;
			margin: 12px 8px 5px;
		}

		.gerenciador-documentos-container {
			grid-area: arquivos;
		}

		@media(max-width: 900px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				'f1 f2'
				'f3 f4'
				'f5 f6'
				'subtitle subtitle'
				'arquivos arquivos'
				'button button';
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const DocumentosContainer = styled.div`
	grid-area: arquivos;
	display: grid;
	margin-bottom: 20px;
	grid-template-columns: 1.4fr 1fr 1.6fr;
	grid-template-rows: 50px 70px 70px 76px auto;
	grid-template-areas: 
		'title title title'
		'anexo tipo table'
		'anexo f1 table'
		'anexo f2 table'
		'anexo button table';

	> h1 {
		grid-area: title;
		align-self: flex-end;
	}

	.select {
		grid-area: tipo;
	}

	.file {
		grid-area: anexo;
		align-self: flex-start;
	}

	.table-container {
		grid-area: table;
		width: calc(100% - 8px);
		justify-self: flex-end;
		margin-top: 24px;
	}

	> button {
		grid-area: button;
		align-self: flex-start;
	}
`
