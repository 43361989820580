import React, { useState, useEffect, useContext } from 'react'
import { Form, Formik } from 'formik'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { FiCheck, FiPlus } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import { Button, Textbox } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'

import { api, authHeaders } from '../../../../services/api'

import { Container, FormContainer } from './styles'

import Yup from '../../../../services/yup'

import scrollUtils from '../../../../util/scroll'
import Modal from '../../../../components/Modal'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

const baseInitialValues = {
    tipo: ''
}

export default function () {
    const { setCodigo } = useContext(TipContext)

    const [tipos, setTipos] = useState(null)
    const [showCadastro, setShowCadastro] = useState(false)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [emEdicao, setEmEdicao] = useState(null)
    const [diretores, setDiretores] = useState([])
    const [diretoresSelecionados, setDiretoresSelecionados] = useState([])

    async function loadMotivos() {
        setTipos(null)
		
        const response = await api.get('operacao_tipo', authHeaders())

        setTipos(response)
    }

    async function handleDelete(item) {
        try {
            await api.delete(`operacao_tipo/${item.id}`, authHeaders())

            toast.success('Tipo de operação excluído.')

            loadMotivos()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmit(values) {
        try {
            if(emEdicao) {
                await api.put(`operacao_tipo/${emEdicao.id}`, {
                    descricao: values.tipo,
                    autorizadores: diretoresSelecionados
                }, authHeaders())	

                toast.success('Tipo de operação atualizado.')
            } else {
                await api.post('operacao_tipo', {
                    descricao: values.tipo,
                    autorizadores: diretoresSelecionados
                }, authHeaders())

                toast.success('Tipo de operação cadastrado.')
            }

            setShowCadastro(false)

            setEmEdicao(null)

            setInitialValues(baseInitialValues)

            setDiretoresSelecionados([])

            loadMotivos()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function handlePrepareEdit(item) {
        setEmEdicao(item)

        setShowCadastro(true)

        setInitialValues({
            tipo: item.descricao
        })

        setDiretoresSelecionados(item.autorizadores.map(a => a.id))

        scrollUtils.toTop()
    }

    async function loadDiretores() {
        const response = await api.get('diretoria', authHeaders())

        setDiretores(response.map(diretor => ({
            id: diretor.pessoa.id,
            nome: diretor.pessoa.nome
        })))
    }

    useEffect(() => {
        loadMotivos()

        loadDiretores()

        loadTips(setCodigo, 'form_operacao_tipo')
    }, [])

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Tipos de operação autorizada</h1>

                        <Button
                            className="transparent"
                            onClick={() => { 
                                setDiretoresSelecionados([])
                                
                                setShowCadastro(true) 
                            }}
                        >
                            Cadastrar novo
                            <FiPlus size={16} />
                        </Button>
                        
                        {tipos ? (
                            <Table 
                                headers={[
                                    { name: 'descricao', value: 'Tipo' }
                                ]}
                                data={tipos}
                                filterable
                                actions={[
                                    {
                                        action: handlePrepareEdit,
                                        name: 'Editar',
                                        icon: FaEdit,
                                        iconSize: 15
                                    },
                                    {
                                        action: handleDelete,
                                        name: 'Excluir',
                                        icon: FaTrash,
                                        iconSize: 13,
                                        confirmation: 'Deseja realmente excluir este tipo de operação?'
                                    }
                                ]}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={showCadastro || emEdicao}
                handleClose={() => {
                    setShowCadastro(false)
                    setEmEdicao(null)
                    setInitialValues(baseInitialValues)
                }}
                title={emEdicao ? `Editando ${emEdicao?.descricao}` : 'Cadastro de tipos de operação autorizada'}
            >
                <FormContainer>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={Yup.object({
                            tipo: Yup.string().required('Informe o nome do tipo de operação.')
                        })}
                    >
                        {({ isLoading }) => (
                            <Form>
                                <Table 
                                    headers={[
                                        { name: 'nome', value: 'Diretor' }
                                    ]}
                                    data={diretores}
                                    itemsByPage={30}
                                    checkable
                                    onCheckItem={item => {
                                        setDiretoresSelecionados(old => [
                                            ...old,
                                            item.id
                                        ])
                                    }}
                                    onUncheckItem={item => {
                                        setDiretoresSelecionados(old => old.filter(id => id !== item.id))
                                    }}
                                    toggleCheckAll={marcado => {
                                        if(marcado) {
                                            setDiretoresSelecionados(diretores.map(d => d.id))
                                        } else {
                                            setDiretoresSelecionados([])
                                        }
                                    }}
                                    checkedItems={diretoresSelecionados}
                                    caption="Diretores a autorizador"
                                />

                                <Textbox 
                                    name="tipo"
                                    label="Tipo de operação"
                                />

                                <Button className="transparent" type="submit" loading={isLoading}>
                                    {emEdicao ? 'Salvar alterações' : 'Cadastrar'}
                                    <FiCheck size={17} />
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </FormContainer>
            </Modal>
        </>
    )
}
