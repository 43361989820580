import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr;

		.orientacao {
			grid-column: 1/span 2;
		}

		button {
			grid-column: 1/span 2;
			justify-self: end;
			width: fit-content;
		}
	}
`

export const Bagde = styled.div.attrs({
    className: 'animated flash faster iterations-3'
})`
	border-radius: 10px;
	padding: 12px;
	background-color: #ffeb3b;
	color: #424242;
	width: fit-content;
`

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
