import styled from 'styled-components'

export const Container = styled.div`
    form {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 
            'f1 f2 f3' 
            'button button button';

        @media(max-width: 800px) {
            grid-template-columns: 1fr;
            grid-template-areas: 
                'f1'
                'f2'
                'f3'
                'button';
        }
    }
`
