import React, { createContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { lastDayOfMonth, format } from 'date-fns'

import { firstDayOfMonth } from '../util/date'

import formUtils from '../util/form'
import stringUtils from '../util/string'

import { api, authHeaders } from '../services/api'

export const VisitaContext = createContext()

function VisitaProvider({ children }) {
    const [visitas, setVisitas] = useState(null)
    const [visita, setVisita] = useState(null)
    const [showCadastro, setShowCadastro] = useState(false)
    const [visitaEdit, setVisitaEdit] = useState(null)
    const [motivos, setMotivos] = useState(null)
    const [periodoBusca, setPeriodoBusca] = useState({
        inicio: firstDayOfMonth(new Date()),
        fim: lastDayOfMonth(new Date())
    })

    async function loadMotivos() {
        const response = await api.get('visita_motivo', authHeaders())

        setMotivos([
            {
                id: null,
                descricao: 'Todos'
            },
            ...response
        ])
    }

    async function loadVisitas({
        inicio, fim, motivo: motivoId, crm
    }) {
        const response = await api.get('visita', {
            params: {
                inicio: inicio || firstDayOfMonth(new Date()),
                fim: fim || lastDayOfMonth(new Date())
            },
            ...authHeaders()
        })

        let listaParcial = response.map(r => ({
            ...r,
            nome: r.pessoa.nome,
            data: format(new Date(r.data_hora), 'dd/MM/yyyy'),
            entrada: format(new Date(r.data_hora), 'HH:mm\'h\''),
            saida: r.data_hora_saida ? format(new Date(r.data_hora_saida), 'HH:mm\'h\'') : '',
            motivo_descricao: r.motivo.descricao,
            avaliacao: r.avaliacao ? `${r.avaliacao}⭐` : '-',
            feedback: r.feedback ? r.feedback : '-',
            atendente_nome: stringUtils.reduceName(r.atendente.nome),
            crm: r.pessoa.documentos.find(doc => doc.tipo_documento_id === 3)?.identificador || '',
            canal: stringUtils.capitalize(r.tipo)
        }))

        if (motivoId) {
            listaParcial = listaParcial.filter(i => i.motivo.id === motivoId)
        }

        if (crm) {
            listaParcial = listaParcial.filter(i => i.crm === crm)
        }

        setVisitas(listaParcial)
    }

    async function handleDelete(id) {
        try {
            await api.delete(`visita/${id}`, authHeaders())

            toast.success('Registro de contato excluído.')

            const { inicio, fim } = periodoBusca

            loadVisitas({ inicio, fim })

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values, false)

            values.avaliacao = values.avaliacao ? values.avaliacao.replace('⭐', '') : null

            if (visitaEdit) {
                await api.put(`visita/${visitaEdit.id}`, values, authHeaders())

                toast.success('Contato atualizado.')

                setVisitaEdit(null)
            } else {
                await api.post('visita', values, authHeaders())

                toast.success('Contato registrado.')

                setShowCadastro(false)
            }

            const { inicio, fim } = periodoBusca

            loadVisitas({ inicio, fim })
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSearch(values) {
        const {
            inicio, fim, motivo, crm
        } = formUtils.extractFormValues(values)

        setPeriodoBusca({
            inicio,
            fim
        })

        await loadVisitas({
            inicio, fim, motivo, crm
        })
    }

    useEffect(() => {
        loadMotivos()

        loadVisitas({})
    }, [])

    return (
        <VisitaContext.Provider
            value={{
                visitas,
                setVisita,
                visita,
                setShowCadastro,
                showCadastro,
                visitaEdit,
                setVisitaEdit,
                handleDelete,
                motivos,
                handleSubmit,
                handleSearch
            }}
        >
            {children}
        </VisitaContext.Provider>
    )
}

export default VisitaProvider
