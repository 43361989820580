import React, { createContext, useEffect, useState } from 'react'
import { format } from 'date-fns'

import { toast } from 'react-toastify'
import { api, authHeaders } from '../services/api'

import formUtils from '../util/form'

import avatarDefault from '../assets/images/avatar.png'

export const AdvogadoContext = createContext()

const AdvogadoProvider = ({ children }) => {
    const [convenio, setConvenio] = useState(null)
    const [advogados, setAdvogados] = useState(null)
    const [showCadastro, setShowCadastro] = useState(false)
    const [advogadoEdit, setAdvogadoEdit] = useState(null)
    const [showFormAgenda, setShowFormAgenda] = useState(null)
    const [showDetalhes, setShowDetalhes] = useState(null)

    async function loadAdvogados() {
        const response = await api.get('advogado', authHeaders())

        setAdvogados(response.map(adv => ({
            id: adv.id,
            nome: adv.pessoa.nome,
            oab: adv.pessoa.documentos.find(doc => doc.tipo_documento.id === 32)?.identificador || '-',
            areas_lista: adv.areas.map(a => a.descricao).join(', '),
            data_nascimento: adv.pessoa.data_nascimento,
            areas: adv.areas,
            assuntos: adv.pessoa.assuntos,
            cpf: adv.pessoa.documentos.find(d => d.tipo_documento.id === 2)?.identificador || '-',
            email: adv.pessoa.contato.find(c => c.tipo_contato_id === 6)?.contato || '-',
            fone: adv.pessoa.contato.find(c => [1, 2, 3, 4, 5].includes(c.tipo_contato_id))?.contato || '-',
            pessoa_id: adv.pessoa.id,
            plantonista: adv.agenda.length ? 'Sim' : 'Não',
            agenda: adv.agenda,
            avatar: adv.pessoa.usuario?.avatar?.link || avatarDefault
        })))
    }

    async function reload() {
        loadAdvogados()

        setAdvogadoEdit(null)

        setShowCadastro(false)

        setShowFormAgenda(null)
    }

    async function handlePrepareEdit(id) {
        const advogado = advogados.find(a => a.id === id)

        setAdvogadoEdit({
            id: advogado.id,
            nome: advogado.nome,
            data_nascimento: format(new Date(advogado.data_nascimento), 'dd/MM/yyyy'),
            areas: advogado.areas.map(a => ({
                label: a.descricao,
                value: a.id
            })),
            assuntos: advogado.assuntos.map(a => ({
                label: a.descricao,
                value: a.id
            })),
            cpf: advogado.cpf,
            email: advogado.email,
            fone: advogado.fone,
            oab: advogado.oab
        })
    }

    async function handleDelete(advogadoId) {
        try {
            const { pessoa_id } = advogados.find(a => a.id === Number(advogadoId))

            await api.delete(`advogado/${convenio.id}/${pessoa_id}`, authHeaders())

            toast.success('Advogado excluído.')

            reload()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function loadConvenio() {
        const response = await api.get('convenio', {
            params: {
                convenio_area_atuacao_id: 7
            },
            ...authHeaders()
        })

        setConvenio(response.length ? response[0] : null)
    }

    async function handleSalvarAgenda(values) {
        try {
            values = formUtils.extractFormValues(values)

            await api.put(`advogado/${showFormAgenda.id}/agenda`, {
                dias_plantao: values.dias_plantao.map(dia => ({
                    ...dia,
                    dia: dia.dia.value,
                    tempo_atendimento: Number(String(dia.tempo_atendimento).replace(' min', ''))
                }))
            }, authHeaders())

            toast.success('Agenda salva com sucesso.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function handleEditAgenda(advogado) {
        setShowDetalhes(null)

        setShowFormAgenda(advogado)

        loadAdvogados()
    }

    function handleEditCadastro(advogado) {
        setShowDetalhes(null)
        setAdvogadoEdit({
            ...advogado,
            data_nascimento: format(new Date(advogado.data_nascimento), 'dd/MM/yyyy'),
            areas: advogado.areas.map(a => ({
                label: a.descricao,
                value: a.id
            })),
            assuntos: advogado.assuntos.map(a => ({
                label: a.descricao,
                value: a.id
            }))
        })
    }

    useEffect(() => {
        loadAdvogados()

        loadConvenio()
    }, [])

    return (
        <AdvogadoContext.Provider value={{
            advogados,
            showCadastro,
            setShowCadastro,
            handlePrepareEdit,
            advogadoEdit,
            convenio,
            reload,
            setAdvogadoEdit,
            handleDelete,
            showFormAgenda,
            setShowFormAgenda,
            handleSalvarAgenda,
            setShowDetalhes,
            showDetalhes,
            handleEditAgenda,
            handleEditCadastro
        }}
        >
            {children}
        </AdvogadoContext.Provider>
    )
}

export default AdvogadoProvider
