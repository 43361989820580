import React, { useContext } from 'react'
import { format } from 'date-fns'
import { FiExternalLink } from 'react-icons/fi'

import { Container } from './styles'

import Spinner from '../../../../../../components/Spinner'
import { AtendimentoContext } from '../../../../../../contexts/AtendimentoContext'

export default function () {
    const { setShowAgendamentos, agendamentos } = useContext(AtendimentoContext)

    return (
        <Container className="atendimentos-presenciais-container">
            <h1>Atendimentos presenciais</h1>

            <FiExternalLink onClick={() => { setShowAgendamentos(true) }} />

            {agendamentos === null ? (
                <Spinner />
            ) : agendamentos.length ? (
                <ul>
                    {agendamentos.map(atend => (
                        <li key={atend.id}>
                            <span className="data-hora">
                                {format(new Date(`${atend.data} ${atend.inicio}`), 'dd/MM/yyyy HH:mm\'h\'')}
                            </span>

                            <span className={`status c-${atend.status.id}`}>
                                {atend.status.descricao}
                            </span>

                            <span className="atendente">
                                <div dangerouslySetInnerHTML={{ __html: `${atend.status.id === 3 ? 'Atendido por' : 'Agendado com'} <b>${atend.atendente.nome}</b>` }} />
                            </span>
                            <span className="motivo">
                                {`Motivo: ${atend.motivo || 'Não especificado.'}`}
                            </span>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="empty-label">
                    Nenhum atendimento presencial
                </p>
            )}
        </Container>
    )
}
