import styled from 'styled-components'

import Card from '../../../../components/Card'
import { Container as CardStyle } from '../../../../components/Card/styles'

export const Container = styled.article`
	min-height: 82vh;
	width: 100%;

	main {
		padding: 35px 50px 35px 90px;
		display: grid;
		grid-template-columns: 3fr 1fr;
		grid-template-rows: auto 0fr auto auto;
		grid-template-areas: 
			'dados_cadastrais news'
			'warn news'
			'dados_extras news'
			'dados_extras news';

		@media(max-width: 1200px) {
			padding: 14px 10px;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: repeat(4, auto);
			grid-template-areas:
				'dados_cadastrais dados_cadastrais'
				'news news'
				'dados_extras dados_extras'
				'dados_extras dados_extras';
		}

		@media(max-width: 440px) {
			padding: 14px 0;
			display: block;
		}
	}
`

export const DadosCadastrais = styled.div`
	grid-area: dados_cadastrais;

	${CardStyle} {
		justify-content: space-between;
		display: grid;
		grid-template-rows: 23px auto 32px auto;
		grid-template-columns: 150px 340px auto 180px;
		grid-gap: 0 34px;
		grid-template-areas:
			'foto x x detail_button'
			'foto nome nome y'
			'foto crm status status'
			'foto data z z';

		> img {
			border-radius: 50%;
		}

		h1 {
			font-size: 28px;
			font-weight: 100;
			color: #FFF;
			text-transform: uppercase;
		}

		span.blue {
			font-weight: 700;
			font-size: 18px;
		}

		span.red {
			font-weight: 700;
			font-size: 18px;
		}

		a {
			transition: 300ms;
			color: #FFF;
			display: flex;
			align-items: center;
			border: solid 1px #FFF;
			padding: 8px 12px;
			border-radius: 6px;

			&:hover {
				opacity: 0.7;
			}

			span {
				font-size: 12px;
				margin-left: 4px;
			}
		}

		.crm {
			display: flex;
			align-items: baseline;

			.situacao {
				display: flex; 
				align-items: center;
				margin-left: 20px;

				svg {
					margin-right: 4px;
				}
			}
		}

		.nome_completo {
			margin-bottom: 8px;
			grid-area: nome;
		}

		.nome_reduzido {
			grid-area: nome;
			display: none;
		}

		.spinner-container {
			grid-column: 1/5;
			grid-row: 1/5;
			align-self: center;
		}

		@media(max-width: 880px) {
			grid-template-rows: 170px auto 32px auto 60px;
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				'foto foto'
				'nome nome'
				'crm status'
				'especialidade data'
				'detail_button detail_button';

			img {
				width: 140px;
				justify-self: center;
			}

			h1 {
				justify-self: center;
				font-size: 22px;
			}

			.detail-button {
				align-self: flex-end;
			}

			.nome_completo {
				display: none;
			}

			.nome_reduzido {
				text-align: center;
				display: block;
				margin-bottom: 10px;
			}

			> div {
				justify-self: center;
			}
		}

		@media(max-width: 400px) {
			width: 100%;
			margin: 14px 0 20px;
			grid-template-rows: 170px auto 28px 28px 28px 28px 45px;
			grid-template-columns: 1fr;
			grid-template-areas:
				'foto'
				'nome'
				'crm'
				'status'
				'especialidade'
				'data'
				'detail_button';
		}
	}
`

export const DadosExtras = styled.div`
	grid-area: dados_extras;

	${CardStyle} {
		height: max(324px, fit-content);
		display: flex;
		align-items: start;
		justify-content: center;

		> div {
			width: 100%;
		}

		.spinner-container {
			align-self: center;
			flex-flow: row;
		}

		h1 {
			color: #FFF;
		}
	}

	@media(max-width: 600px) {
		grid-template-columns: 1fr;
		grid-gap: 0;

		${CardStyle} {
			margin: 8px 0;
		}
	}
`

export const NoticiasContainer = styled(Card).attrs({
	className: 'news-container'
})`
	grid-area: news;
	display: flex;
	flex-direction: column;
	padding: 30px 0 30px 30px;

	.news-list {
		height: 880px;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		padding-right: 10px;
	}

	> h1 {
		color: #FFF !important;
		padding-bottom: 14px;
	}

	.em-construcao {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #00000011;
		border-radius: 10px;
		padding: 10px;

		svg {
			margin-top: 12px;
		}

		h2 {
			font-size: 18px;
			text-align: center;
			font-style: italic;
			margin: 12px 0;
		}

		p {
			color: #FFFFFF88;
			text-align: center;
		}

		@media(min-width: 1201px) {
			margin-right: 30px;
		}
	}

	a:not(:first-child) {
		border-top: solid 1px #FFF3;
	}

	@media(max-width: 1200px) {
		margin: 10px;
		height: 300px;
		padding: 30px;

		.news-list {
			display: flex;
			flex-direction: row;
			overflow-y: hidden;
			overflow-x: scroll;
			padding-bottom: 20px;
			padding-right: 0;

			.new-container {
				padding: 0 10px;
				width: 340px;
			}
		}

		a:not(:first-child) {
			border-top: unset;
		}
	}

	@media(max-width: 400px) {
		display: none;
	}
`

export const Avatar = styled.div`
	grid-area: foto;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	background-image: url(${props => props.image});
	background-position: center;
	background-size: cover;

	@media(max-width: 880px) {
		width: 150px;
		height: 150px;
		padding-top: unset;
	}
`

export const Badge = styled.div`
	grid-area: warn;
	padding: 12px;
	background-color: ${props => props.background || '#ffeb3b'};
	color: ${props => props.color || '#424242'};
	width: fit-content;
	display: grid;
	align-items: center;
	grid-template-columns: 56px auto;
	margin: 0 8px;
	border-radius: 10px;
	justify-self: center;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);

	svg {
		grid-row: 1/span 2;
	}

	span {
		line-height: 30px;
	}

	.button-container {
		display: flex;
		width: 100%;
		justify-content: center;

		button {
			width: fit-content;
		}
	}
`
