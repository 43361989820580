import styled, { css } from 'styled-components'

import waves from '../../assets/images/card-waves.svg'

export const Container = styled.article.attrs({
    className: 'card'
})`
	padding: 30px;
	border-radius: 12px;
	margin: 8px;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	background-color: #334155;
	/* background-image: ${props => props.light ? 'unset' : `url(${waves})`}; */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	${props => props.disabled && css`
		opacity: 0.7 !important;
		cursor: not-allowed;
	`}

	${props => props.light && css`
		background-color: #FFF;
		border-radius: 4px;
		color: #212121;
		
		h1, h2, h3, h4, h5, h6 {
			color: #212121;
		}
	`}

	h1 {
		font-size: 22px;
		font-weight: 500;
	}

	@media(max-width: ${props => props.expandOffset}px) {
		&:not(.no-responsive) {
			margin: 30px 0;
			border-radius: unset;
			padding: 30px 15px;

			h1 {
				margin: 0 10px 20px;
			}
		}
	}
`
