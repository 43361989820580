import React, { useContext } from 'react'
import { format } from 'date-fns'
import { extname } from '../../../../util/path'
import Spinner from '../../../../components/Spinner'
import BoxArquivos from '../../../../boxes/Arquivos'

import { ColaboradorContext } from '../../../../contexts/ColaboradorContext'

import { Container, DadosContainer, TarjaDesligamento } from './styles'

import avatarDefault from '../../../../assets/images/avatar.png'

export default function () {
    const { colaboradorDetalhes } = useContext(ColaboradorContext)

    const email = colaboradorDetalhes?.pessoa.contato.find(c => c.tipo_contato_id === 6)?.contato || '-'
    const celular = colaboradorDetalhes?.pessoa.contato.find(c => [1, 2, 3, 4, 5].includes(c.tipo_contato_id))?.contato || '-'
    const rg = colaboradorDetalhes?.pessoa.documentos.find(c => c.tipo_documento.id === 1)?.identificador || '-'
    const pis = colaboradorDetalhes?.pessoa.documentos.find(c => c.tipo_documento.id === 11)?.identificador || '-'

    if (!colaboradorDetalhes) {
        return <Spinner />
    }

    const listaArquivos = colaboradorDetalhes.pessoa?.documentos
        .filter(doc => doc.arquivo?.link)
        .map(doc => ({
            nome: doc.tipo_documento.descricao,
            extra: doc.extra,
            link: doc.arquivo?.link,
            extensao: extname(doc.arquivo?.link)
        }))

    if (colaboradorDetalhes.arquivo_cadastral?.link) {
        listaArquivos.push({
            nome: 'Arquivo cadastral',
            link: colaboradorDetalhes.arquivo_cadastral?.link,
            extensao: extname(colaboradorDetalhes.arquivo_cadastral?.link)
        })
    }

    if (colaboradorDetalhes.recisao?.link) {
        listaArquivos.push({
            nome: 'Documento de recisão',
            link: colaboradorDetalhes.recisao?.link,
            extensao: extname(colaboradorDetalhes.recisao?.link)
        })
    }

    const foto = colaboradorDetalhes?.pessoa.documentos.find(doc => doc.tipo_documento.id === 15)?.arquivo.link || colaboradorDetalhes.foto?.link

    return (
        <Container>
            <img src={foto || avatarDefault} alt="" />

            <h1>{colaboradorDetalhes.nome}</h1>

            <p>
                {'Matrícula: '}
                <b>{colaboradorDetalhes.matricula}</b>
            </p>

            {colaboradorDetalhes.data_desligamento && (
                <TarjaDesligamento>
                    <div dangerouslySetInnerHTML={{ __html: 'Este colaborador está <b>DESLIGADO</b>.' }} />
                </TarjaDesligamento>
            )}

            <DadosContainer>
                <h2>Dados pessoais e contatos</h2>

                <p>
                    {'Data de nascimento: '}
                    <b>{colaboradorDetalhes.pessoa.data_nascimento ? format(new Date(colaboradorDetalhes.pessoa.data_nascimento), 'dd/MM/yyyy') : '-'}</b>
                </p>

                <p>
                    {'Sexo: '}
                    <b>{colaboradorDetalhes.pessoa.sexo === 'M' ? 'Masculino' : 'Feminino'}</b>
                </p>

                <p>
                    {'E-mail: '}
                    <b>{email}</b>
                </p>

                <p>
                    {'Fone: '}
                    <b>{celular}</b>
                </p>

                <p>
                    {'RG: '}
                    <b>{rg}</b>
                </p>

                <p>
                    {'CPF: '}
                    <b>{colaboradorDetalhes.cpf}</b>
                </p>

                <p>
                    {'PIS: '}
                    <b>{pis}</b>
                </p>

                <p style={{ gridColumn: '1/span 3' }}>
                    {'Observação: '}
                    <b>{colaboradorDetalhes?.observacao || '-'}</b>
                </p>

                <h2>Dados corporativos</h2>

                <p>
                    {'Setor: '}
                    <b>{colaboradorDetalhes.setor}</b>
                </p>

                <p>
                    {'Cargo: '}
                    <b>{colaboradorDetalhes.cargo}</b>
                </p>

                <p>
                    {'Data de admissão: '}
                    <b>{colaboradorDetalhes.data_admissao || '-'}</b>
                </p>

                <p>
                    {'Perfil de usuário: '}
                    <b>{colaboradorDetalhes.pessoa.usuario.perfil.descricao}</b>
                </p>

                {colaboradorDetalhes?.data_desligamento && (
                    <p>
                        {'Data de desligamento: '}
                        <b>{colaboradorDetalhes.data_desligamento || '-'}</b>
                    </p>
                )}

                <p style={{ gridColumn: '1/span 3' }}>
                    {'Atividades desenvolvidas: '}
                    <b>{colaboradorDetalhes?.atividades || '-'}</b>
                </p>

                <h2>Dados de endereço</h2>

                <p>
                    {'CEP: '}
                    <b>{colaboradorDetalhes.pessoa.endereco?.cep}</b>
                </p>

                <p>
                    {'UF: '}
                    <b>{colaboradorDetalhes.pessoa.endereco?.uf}</b>
                </p>

                <p>
                    {'Município: '}
                    <b>{colaboradorDetalhes.pessoa.endereco?.cidade}</b>
                </p>

                <p>
                    {'Bairro: '}
                    <b>{colaboradorDetalhes.pessoa.endereco?.bairro}</b>
                </p>

                <p style={{ gridColumn: '2/span 2' }}>
                    {'Logradouro / localidade: '}
                    <b>{colaboradorDetalhes.pessoa.endereco?.rua}</b>
                </p>

                <p>
                    {'Número: '}
                    <b>{colaboradorDetalhes.pessoa.endereco?.numero || '-'}</b>
                </p>

                <p style={{ gridColumn: '2/span 2' }}>
                    {'Complemento: '}
                    <b>{colaboradorDetalhes.pessoa.endereco?.complemento || '-'}</b>
                </p>

                <p style={{ gridColumn: '1/span 3' }}>
                    <BoxArquivos
                        title="Arquivos anexados"
                        arquivos={listaArquivos}
                    />
                </p>
            </DadosContainer>
        </Container>
    )
}
