import React, { useState } from 'react'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../../../services/api'

import ActionFooter from '../../ActionFooter'
import { Button } from '../../Form'

import { Container } from './styles'

export default function ({ pessoaId, callback }) {
    const [submitting, setSubmitting] = useState(false)

    async function handleSubmit() {
        setSubmitting(true)

        try {
            const body = {
                tipo_pagamento_id: 4,
                dados_pagamento: {}
            }

            await api.put(`pagamento/${pessoaId}`, body, authHeaders())

            toast.success('Dados atualizados.')

            setSubmitting(false)

            callback()
        } catch (e) {
            toast.error('Ocorreu um erro.')
            setSubmitting(false)
        }
    }

    return (
        <Container>
            <ActionFooter>
                <Button
                    type="submit"
                    loading={submitting}
                    className="white"
                    onClick={handleSubmit}
                >
                    Salvar alterações
                    <FaRegSave />
                </Button>
            </ActionFooter>
        </Container>
    )
}
