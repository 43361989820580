import styled from 'styled-components'

export const Container = styled.div`
	margin: 30px auto 80px;
	max-width: 750px;

	.card {
		h1 {
			margin: 0 0 10px 8px;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 4;
			margin-top: 16px;

			> p {
				margin-left: 8px;
				margin-bottom: 10px;
			}
		}
	}
`

export const ResultadoContainer = styled.div.attrs({
    className: 'animated fadeIn fast'
})`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;

	> div {
		margin-top: 24px;
		margin-bottom: 24px;
		display: grid;
		grid-template-columns: 140px auto;
		gap: 12px;

		img {
			grid-row: 1 / 6;
			width: 130px;
			height: 130px;
			border-radius: 100%;
			object-fit: cover;
		}

		> h2 {
			font-weight: 700;
			font-size: 17px;
		}
	}
`
