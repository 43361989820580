import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px 0;
`

export const FiltroPeriodoContainer = styled.div`
	display: flex;
	grid-column: 1/span 4;
	max-width: 540px;
	gap: 0 20px;

	button {
		align-self: flex-end;
		margin: 4px 0;
	}
`

export const BorderCard = styled.div`
	border: solid 1px #fff;
	border-radius: 8px;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
	padding: 30px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	.textbox {
		grid-column: 1/span 4;
		max-width: 200px;
		margin: 4px 0;
	}

	h1 {
		font-size: 16px;
		margin: 14px 0 2px !important;
	}

	> h1 {
		grid-column: 1/span 4;
		font-size: 21px !important;
		margin: 0 !important;
		text-align: center;
	}

	h2 {
		font-size: 12px;
		margin-top: 2px;
	}

	h3 {
		font-size: 18px;
		font-weight: 100;
	}

	.spinner-container {
		grid-column: 1/span 4;
	}

	.placeholder {
		grid-column: 1/span 4;
		font-style: italic;
		text-align: center;
		padding: 40px 0;
	}

	.total {
		grid-column: 1/span 4;
		padding: 10px 0 14px;
		border-bottom: solid 1px #ddd;
		display: flex;
		align-items: center;
		
		> h1 {
			font-size: 18px !important;

			span {
				font-weight: 600;
				font-size: 18px;
				padding: 6px;
				border-radius: 10px;

				&.positivo {
					background-color: #4caf50;
					font-weight: 100;
				}

				&.negativo {
					background-color: #f44336;
					font-weight: 100;
				}
			}
		}
	}

	.title {
		grid-column: 1/span 4;
		font-size: 18px;
		border-bottom: solid 1px #ddd;
		font-weight: 500;
		margin-top: 20px;
		padding: 10px 0 14px;
	}
`
