import styled from 'styled-components'

export const Container = styled.div`
	> p {
		margin-bottom: 24px;
	}
`

export const FiltrosContainer = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 0.7fr 2fr auto auto;
		align-items: flex-end;

		button {
			width: fit-content;
		}
	}

`

export const ModalDetalhesContainer = styled.div`
	> p {
		margin: 24px 8px 0;
	}
`
