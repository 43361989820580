import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 300px;
	padding: 35px 50px 35px 90px;
	
	@media(max-width: 1070px) {
		display: flex;
		flex-direction: column;
	}

	@media(max-width: 760px) {
		padding: 20px;
	}

	@media(max-width: 440px) {
		padding: 20px 0;
	}
`
