import React, { useContext, useState } from 'react'
import {
    FiChevronDown, FiPlayCircle, FiImage, FiFileText 
} from 'react-icons/fi'

import { extname } from '../../../../util/path'
import Card from '../../../../components/Card'
import Spinner from '../../../../components/Spinner'
import Modal from '../../../../components/Modal'
import { Textbox } from '../../../../components/Form'

import { TutorialContext } from '../../../../contexts/TutorialContext'

import { stringNomalize } from '../../../../util/string'

import { Container, TutorialItem, TutorialContent } from './styles'

const videoExtensions = ['.mp4', '.avi', '.mpg', '.wmv', '.mkv', '.flv']
const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp']

export default function () {
    const { assuntos } = useContext(TutorialContext)

    const [tutorialAberto, setTutorialAberto] = useState(null)
    const [pesquisa, setPesquisa] = useState('')

    function getTutorialIcone(tutorial) {
        let Icon = FiFileText

        if(videoExtensions.includes(extname(tutorial.arquivo.link))) {
            Icon = FiPlayCircle
        } else if(imageExtensions.includes(extname(tutorial.arquivo.link))) {
            Icon = FiImage
        }

        return <Icon />
    }

    function getTutorialContent(tutorial) {
        if(!tutorial) {
            return null
        }

        if(!tutorial.arquivo) {
            return null
        } 
		
        if(videoExtensions.includes(extname(tutorial.arquivo.link))) {
            return (
                <video controls autoPlay>
                    <source src={tutorial.arquivo.link} type={`video/${extname(tutorial.arquivo.link).substr(1)}`} />
                </video>
            )
        } 
		
        if(imageExtensions.includes(extname(tutorial.arquivo.link))) {
            return (
                <img src={tutorial.arquivo.link} alt={tutorial.descricao} />
            )
        }

        if(extname(tutorial.arquivo.link) === '.pdf') {
            return (
                <iframe src={tutorial.arquivo.link} title="Documento" style={{ height: '69vh' }} />
            )
        }

        return null
    }

    function assuntoFiltro(assunto) {
        if(!pesquisa) {
            return true
        }

        return assunto.tutoriais.find(tutorial => stringNomalize(tutorial.palavras_chave).indexOf(pesquisa) >= 0
            || stringNomalize(tutorial.assunto.descricao).indexOf(pesquisa) >= 0
            || stringNomalize(tutorial.assunto.detalhes).indexOf(pesquisa) >= 0)
    }

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Tópicos de ajuda</h1>

                        <Textbox 
                            withoutForm
                            label="Digite o assunto"
                            onChange={e => {
                                setPesquisa(stringNomalize(e.target.value))
                            }}
                        />

                        {assuntos ? assuntos
                            .filter(assuntoFiltro)
                            .map((assunto, index) => {
                                const resultadosEncontrados = assuntos.filter(assuntoFiltro)
                                
                                return (
                                    <details key={assunto.id} className={`animated fadeIn delay-${index * 100}ms`} open={resultadosEncontrados.length === 1}>
                                        <summary>
                                            <img src={assunto.imagem?.link} alt="" />
                                            <div>
                                                <h1>{assunto.descricao}</h1>
                                                <FiChevronDown />
                                            </div>
                                        </summary>

                                        <div className="detalhes" dangerouslySetInnerHTML={{ __html: assunto.detalhes.replace(/\n/g, '<br/>') }} />

                                        {assunto.tutoriais.map(tutorial => (
                                            <TutorialItem key={tutorial.id} onClick={() => { setTutorialAberto(tutorial) }}>
                                                {getTutorialIcone(tutorial)}

                                                <h1>{tutorial.descricao}</h1>
                                            </TutorialItem>
                                        ))}
                                    </details>
                                )
                            }) 
                            : <Spinner />}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!tutorialAberto}
                handleClose={() => { setTutorialAberto(null) }}
                title={tutorialAberto?.descricao}
            >
                <TutorialContent>
                    <div className="detalhes" dangerouslySetInnerHTML={{ __html: tutorialAberto?.detalhes.replace(/\n/g, '<br/>') }} />

                    {getTutorialContent(tutorialAberto)}
                </TutorialContent>
            </Modal>
        </>
    )
}
