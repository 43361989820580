import styled from 'styled-components'

import { Container as Card } from '../../../../components/Card/styles'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		${Card} {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 54px auto;
			min-height: 70vh;

			.table-container,
			.require-rotation,
			.spinner-container {
				grid-column: 1/3;
			}

			> h1,
			.react-tabs {
				grid-column: 1/span 2;

				.react-tabs__tab-panel {
					.badge {
						padding: 4px 8px;
						background-color: #fff;
						color: #424242;
						border-radius: 10px;
						font-size: 13px;
						width: fit-content;
					}

					.button-container {
						display: flex;
						justify-content: flex-end;

						button {
							width: fit-content;
						}
					}

					.table-container {
						margin-top: 0;
					}
				}
			}			
		}

		@media(max-width: 1000px) {
			padding: 20px 16px;
		}

		@media(max-width: 560px) {
			.button-cadastrar {
				display: none;
			}
		}

		@media(max-width: 1000px) {
			padding: 10px 0;
		}
	}
`
