import styled, { css } from 'styled-components'

export const Container = styled.div.attrs({
    className: 'donut'
})`
	canvas {
		margin: 0 auto;

		${props => props.canvas && css`
			width: ${props.canvas.width}px !important;
			height: ${props.canvas.height}px !important;
		`}
	}
`
