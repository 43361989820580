import { Formik, Form } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import {
    Tab, TabList, TabPanel, Tabs
} from 'react-tabs'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import { Button, Select, Textbox } from '../../../../components/Form'
import Table from '../../../../components/Table'

import { api, authHeaders } from '../../../../services/api'

import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

import formUtils from '../../../../util/form'

import { Container, DataContainer } from './styles'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [segmentos, setSegmentos] = useState([])
    const [segmentosPorSetor, setSegmentosPorSetor] = useState([])
    const [segmentosPorFormato, setSegmentosPorFormato] = useState([])
    const [segmentoEdicao, setSegmentoEdicao] = useState(null)

    async function loadSegmentos() {
        const response = await api.get('acervo/segmento', {
            params: {
                publico: 0
            }
        })

        setSegmentos(response)

        const segmentosTratadosPorSetor = response.reduce((result, setor) => {
            const tiposDoSetor = setor.segmentos_filhos.map(tipo => ({
                id: tipo.id,
                id_setor: setor.id,
                nivel: tipo.nivel,
                setor: setor.descricao,
                tipo: tipo.descricao
            }))

            return [
                ...result,
                ...tiposDoSetor
            ]
        }, [])

        setSegmentosPorSetor(segmentosTratadosPorSetor)

        const segmentosTratadosPorTipo = response.reduce((result, setor) => {
            const tiposDoSetor = setor.segmentos_filhos.reduce((resultTipos, tipo) => {
                const formatosDoTipo = tipo.segmentos_filhos.map(formato => ({
                    id: formato.id,
                    id_tipo: tipo.id,
                    id_setor: setor.id,
                    nivel: formato.nivel,
                    setor: setor.descricao,
                    tipo: tipo.descricao,
                    formato: formato.descricao
                }))

                return [
                    ...resultTipos,
                    ...formatosDoTipo
                ]
            }, [])

            return [
                ...result,
                ...tiposDoSetor
            ]
        }, [])

        setSegmentosPorFormato(segmentosTratadosPorTipo)
    }

    async function handleSubmit(values, segmento, resetForm) {
        try {
            values = formUtils.extractFormValues(values)

            values.segmento_pai = values.tipo || values.setor || null

            if (segmentoEdicao) {
                await api.put(`acervo/segmento/${segmentoEdicao.id}`, values, authHeaders())

                toast.success(`${segmento} atualizado com sucesso.`)
            } else {
                await api.post('acervo/segmento', values, authHeaders())

                toast.success(`${segmento} cadastrado com sucesso.`)
            }

            setSegmentoEdicao(null)

            resetForm()

            loadSegmentos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDeleteSegmento(item, segmento) {
        try {
            await api.delete(`acervo/segmento/${item.id}`, authHeaders())

            toast.success(`O ${segmento} foi removido.`)

            loadSegmentos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function obterTiposPorSetor(setorId) {
        if (!setorId) {
            return []
        }

        return segmentos.find(s => s.id === setorId).segmentos_filhos
    }

    function handlePrepareEdit(item) {
        setSegmentoEdicao(item)
    }

    useEffect(() => {
        loadSegmentos()
        loadTips(setCodigo, 'form_acervo_gerenciamento_conteudo')
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Tabelas de apoio</h1>
                    <p>{tips?.formulario}</p>

                    <Tabs>
                        <TabList>
                            <Tab>Setor</Tab>
                            <Tab>Tipos de conteúdo</Tab>
                            <Tab>Formatos</Tab>
                        </TabList>

                        <TabPanel>
                            <DataContainer>
                                <Formik
                                    onSubmit={(values, { resetForm }) => {
                                        handleSubmit(values, 'Setor', resetForm)
                                    }}
                                    initialValues={segmentoEdicao?.nivel === 1
                                        ? {
                                            descricao: segmentoEdicao.descricao
                                        } : { descricao: '' }}
                                    enableReinitialize
                                >
                                    {({ values }) => (
                                        <Form>
                                            <Textbox
                                                name="descricao"
                                                id="descricao_setor"
                                                label="Nome do setor"
                                            />

                                            <div className="button-container">
                                                <Button
                                                    className="transparent"
                                                    type="submit"
                                                    disabled={Object.values(values)?.some(v => !v)}
                                                >
                                                    {segmentoEdicao?.nivel === 1 ? 'Salvar alterações' : 'Cadastrar'}
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                                <Table
                                    headers={[
                                        { name: 'descricao', value: 'Setor', centered: true }
                                    ]}
                                    data={segmentos}
                                    actions={[
                                        {
                                            action: handleDeleteSegmento,
                                            icon: FaTrash,
                                            iconSize: 13,
                                            name: 'Excluir',
                                            confirmation: 'Deseja realmente excluir este setor?'
                                        },
                                        {
                                            name: 'Editar',
                                            icon: FaEdit,
                                            iconSize: 14,
                                            action: handlePrepareEdit
                                        }
                                    ]}
                                    filterable
                                />
                            </DataContainer>
                        </TabPanel>

                        <TabPanel>
                            <DataContainer>
                                <Formik
                                    onSubmit={(values, { resetForm }) => {
                                        handleSubmit(values, 'Tipo', resetForm)
                                    }}
                                    initialValues={segmentoEdicao?.nivel === 2
                                        ? {
                                            descricao: segmentoEdicao.tipo,
                                            setor: {
                                                label: segmentoEdicao.setor,
                                                value: segmentoEdicao.id_setor
                                            }
                                        }
                                        : {
                                            descricao: '',
                                            setor: null
                                        }}
                                    enableReinitialize
                                >
                                    {({ setFieldValue, values }) => (
                                        <Form>
                                            <Select
                                                name="setor"
                                                label="Setor"
                                                options={segmentos.map(segmento => ({
                                                    label: segmento.descricao,
                                                    value: segmento.id
                                                }))}
                                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                            />

                                            <Textbox
                                                name="descricao"
                                                id="descricao_tipo"
                                                label="Nome do tipo"
                                            />

                                            <div className="button-container">
                                                <Button
                                                    className="transparent"
                                                    type="submit"
                                                    disabled={Object.values(values)?.some(v => !v)}
                                                >
                                                    {segmentoEdicao?.nivel === 2 ? 'Salvar alterações' : 'Cadastrar'}
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                                <Table
                                    headers={[
                                        { name: 'setor', value: 'Setor', centered: true },
                                        { name: 'tipo', value: 'Tipo', centered: true }
                                    ]}
                                    data={segmentosPorSetor}
                                    actions={[
                                        {
                                            action: handleDeleteSegmento,
                                            icon: FaTrash,
                                            iconSize: 13,
                                            name: 'Excluir',
                                            confirmation: 'Deseja realmente excluir este tipo de conteúdo?'
                                        },
                                        {
                                            name: 'Editar',
                                            icon: FaEdit,
                                            iconSize: 14,
                                            action: handlePrepareEdit
                                        }
                                    ]}
                                    filterable
                                />
                            </DataContainer>
                        </TabPanel>

                        <TabPanel>
                            <DataContainer>
                                <Formik
                                    onSubmit={(values, { resetForm }) => {
                                        handleSubmit(values, 'Formato', resetForm)
                                    }}
                                    initialValues={segmentoEdicao?.nivel === 3
                                        ? {
                                            descricao: segmentoEdicao.formato,
                                            tipo: {
                                                label: segmentoEdicao.tipo,
                                                value: segmentoEdicao.id_tipo
                                            },
                                            setor: {
                                                label: segmentoEdicao.setor,
                                                value: segmentoEdicao.id_setor
                                            }
                                        }
                                        : {
                                            descricao: '',
                                            tipo: null
                                        }}
                                    enableReinitialize
                                >
                                    {({ setFieldValue, values }) => (
                                        <Form>
                                            <Select
                                                name="setor"
                                                label="Setor"
                                                options={segmentos.map(segmento => ({
                                                    label: segmento.descricao,
                                                    value: segmento.id
                                                }))}
                                                onChange={(selected, meta) => {
                                                    setFieldValue(meta.name, selected)

                                                    setFieldValue('tipo', null)
                                                }}
                                            />

                                            <Select
                                                name="tipo"
                                                label="Tipo de conteúdo"
                                                options={obterTiposPorSetor(values.setor?.value).map(tipo => ({
                                                    label: tipo.descricao,
                                                    value: tipo.id
                                                }))}
                                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                            />

                                            <Textbox
                                                name="descricao"
                                                id="descricao_formato"
                                                label="Formato"
                                            />

                                            <div className="button-container">
                                                <Button
                                                    className="transparent"
                                                    type="submit"
                                                    disabled={Object.values(values)?.some(v => !v)}
                                                >
                                                    {segmentoEdicao?.nivel === 3 ? 'Salvar alterações' : 'Cadastrar'}
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                                <Table
                                    headers={[
                                        { name: 'setor', value: 'Setor', centered: true },
                                        { name: 'tipo', value: 'Tipo', centered: true },
                                        { name: 'formato', value: 'Formato', centered: true }
                                    ]}
                                    data={segmentosPorFormato}
                                    actions={[
                                        {
                                            action: handleDeleteSegmento,
                                            icon: FaTrash,
                                            iconSize: 13,
                                            name: 'Excluir',
                                            confirmation: 'Deseja realmente excluir este formato?'
                                        },
                                        {
                                            name: 'Editar',
                                            icon: FaEdit,
                                            iconSize: 14,
                                            action: handlePrepareEdit
                                        }
                                    ]}
                                    filterable
                                />
                            </DataContainer>
                        </TabPanel>
                    </Tabs>
                </Card>
            </main>
        </Container>
    )
}
