export function validarDocumentos(categoria, documentos) {
    let tiposRequeridos = []

    switch (categoria) {
        case 'medico':
            tiposRequeridos = [1, 2, 3]
            break
        case 'academico':
            tiposRequeridos = [1, 2]
            break
        case 'dependente':
            tiposRequeridos = [2]
            break
        default:
            tiposRequeridos = []
            break
    }

    const tiposDocumentosIds = documentos.map(doc => doc.tipo_documento.value || doc.tipo_documento.id)

    if (!tiposRequeridos.every(doc => tiposDocumentosIds.includes(doc))) {
        return tiposRequeridos.find(tipo => !tiposDocumentosIds.some(item => item === tipo))
    }

    return null
}
