import React from 'react'
import { toast } from 'react-toastify'

import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'
import SmallScreenPlaceholder from '../../../../../components/SmallScreenPlaceholder'

import useEventListener from '../../../../../hooks/useEventListener'

import { Container } from './styles'

const tableHideOffset = 500

export default function ({
    eventos, 
    handlePrepareEdit, 
    handleDelete 
}) {
    useEventListener('change', () => {
        const horizontal = [90, 270].includes(window.screen.orientation.angle)

        if(horizontal && tableHideOffset > window.innerHeight) {
            toast.info('Infelizmente seu dispositivo não tem dimensões suficientes para visualização dos dados. Consulte a versão web.', { autoClose: 6000 })
        }
    }, window.screen.orientation)

    return (
        <Container>
            {eventos ? (
                <>
                    <SmallScreenPlaceholder 
                        text="Gire o dispositivo para visualizar os eventos contábeis." 
                        hideOffset={600} 
                    />

                    <Table 
                        headers={[
                            { name: 'nome', value: 'Nome' }, 
                            { name: 'tipo', value: 'Tipo', centered: true },
                            { name: 'codigo', value: 'Código' },
                            { name: 'recorrente', value: 'Recorrente', centered: true }
                        ]}
                        data={eventos}
                        showId
                        filterable
                        handlePrepareEdit={handlePrepareEdit}
                        handleDelete={handleDelete}
                        hideOnSmallHeaders={['id']}
                        hideOffset={600}
                        filterPosition="left"
                        confirmExclusion={{
                            attr: 'nome',
                            template: 'Deseja realmente excluir o evento #attr#?'
                        }}
                    />
                </>
            ) : (
                <Spinner size={36} />
            )}
        </Container>
    )
}
