import React from 'react'

import Card from '../../../components/Card'

import InconsistenciaProvider from '../../../contexts/InconsistenciaContext'

import Cadastro from './Cadastro'
import Listagem from './Listagem'
import Edicao from './Edicao'

import { Container } from './styles'

export default function () {
    return (
        <InconsistenciaProvider>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <Cadastro />
                    
                        <Listagem />
                    </Card>
                </main>
            </Container>

            <Edicao />
        </InconsistenciaProvider>
    )
}
