import React from 'react'
import Loader from 'react-loader-spinner'

import { Container } from './styles'

export default function ({
    type = 'Bars', 
    color = '#FFF',
    size = 30,
    fontSize = 16,
    visible = true,
    label = 'Carregando...',
    labelPosition = '',
    containerClass = '',
    containerStyle = {},
    ...props 
}) {
    // More types in: https://mhnpd.github.io/react-loader-spinner/?path=/story/loader--bars

    return (
        <Container 
            visible={visible} 
            className={containerClass} 
            style={containerStyle} 
            display={labelPosition === 'right' ? 'row' : 'column'}
        >
            <Loader
                {...props}
                type={type}
                color={color}
                height={size}
                width={size}
                visible
                className="spinner"
            />
            <label style={{ color, fontSize }}>{label}</label>
        </Container>
    )
}
