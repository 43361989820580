import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			'f1 f2 f3'
			'tip tip tip'
			'obs obs obs'
			'info info info'
			'button button button';
		
		.textarea {
			grid-area: obs;
		}

		.tip {
			grid-area: tip;
			margin: 8px 12px;
		}

		.badge {
			grid-area: info;
			margin: 0 8px 10px;
			width: fit-content;
			background-color: #ffeb3b;
			border-radius: 10px;
			padding: 6px;
			color: #424242;
			font-size: 13px;
			line-height: 18px;
		}
	
		> button {
			grid-area: button;
			justify-self: flex-end;
		}

		@media(max-width: 800px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				'f1 f2'
				'f3 x'
				'tip tip'
				'obs obs'
				'info info'
				'button button';
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;
		}
	}
`
