import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FaUserLock, FaUserCog } from 'react-icons/fa'

import Card from '../../../components/Card'
import Spinner from '../../../components/Spinner'
import Table from '../../../components/Table'
import Modal from '../../../components/Modal'
import { Select } from '../../../components/Form'

import { api, authHeaders } from '../../../services/api'

import { GlobalContext } from '../../../contexts/GlobalContext'

import { Container, SelectPerfilContainer } from './styles'

export default function () {
    const { user } = useContext(GlobalContext)

    const [perfis, setPerfis] = useState([])
    const [usuarios, setUsuarios] = useState(null)
    const [usuarioPerfil, setUsuarioPerfil] = useState(null)

    function getCategoria(usuario) {
        if(usuario.pessoa.associado) {
            return usuario.pessoa.associado.medico ? 'Médico' : 'Acadêmico'
        }

        if([20, 23].includes(usuario.perfil.id)) {
            return 'Jurídico'
        }

        return 'Colaborador'
    }

    async function loadUsuarios() {
        try {
            const response = await api.get('usuario/list', authHeaders())

            const normalizado = response.map(usuario => ({
                id: usuario.id,
                nome: usuario.pessoa.nome,
                categoria: getCategoria(usuario),
                perfil: usuario.perfil.descricao,
                pessoa_id: usuario.pessoa.id,
                perfil_id: usuario.perfil.id
            }))

            setUsuarios(normalizado)
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function loadPerfis() {
        const response = await api.get('perfil')
		
        setPerfis(response
            .filter(p => user.perfil.id === 1 ? true : p.id !== 1)
            .map(p => ({
                value: p.id,
                label: p.descricao
            })))
    }

    async function resetPassword(item) {
        try {
            const response = await api.put(`usuario/reset_password/${item.id}`, {
                pessoa_id: item.pessoa_id
            }, authHeaders())

            toast.success(response.msg)
        } catch(e) {
            toast.error(e.msg)
        }
    }

    async function changePerfil(perfilId) {
        try {
            setUsuarios(old => {
                const position = old.findIndex(u => u.id === usuarioPerfil.id)
                const restOfUsers = old.filter(u => u.id !== usuarioPerfil.id)

                const usuario = old.find(u => u.id === usuarioPerfil.id)
                usuario.perfil = '...'

                return [
                    ...restOfUsers.slice(0, position),
                    usuario,
                    ...old.slice(position + 1, old.length - 1)
                ]
            })

            setUsuarioPerfil(null)

            await api.put(`usuario/change_perfil/${usuarioPerfil.id}`, {
                perfil_id: perfilId
            }, authHeaders())

            loadUsuarios()
			
            toast.success('Perfil alterado com sucesso.')
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadUsuarios()
    }, [])

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Gerenciamento de usuários</h1>
						
                        {usuarios ? (
                            <Table 
                                headers={[
                                    { name: 'nome', value: 'Nome' },
                                    { name: 'categoria', value: 'Categoria', centered: true },
                                    { name: 'perfil', value: 'Perfil de acesso', centered: true }
                                ]}
                                data={usuarios}
                                filterable
                                actions={[
                                    {
                                        action: resetPassword,
                                        name: 'Redefinir senha',
                                        icon: FaUserLock,
                                        iconSize: 16
                                    },
                                    {
                                        action: item => {
                                            setUsuarioPerfil(item)
											
                                            loadPerfis()
                                        },
                                        name: 'Alterar perfil',
                                        icon: FaUserCog,
                                        iconSize: 16
                                    }
                                ]}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!usuarioPerfil}
                handleClose={() => {
                    setUsuarioPerfil(null)
                }}
                title={`Alterar perfil de ${usuarioPerfil?.nome}`}
            >
                <SelectPerfilContainer>
                    <Select 
                        withoutForm
                        options={perfis}
                        label="Selecione o perfil de acesso"
                        value={perfis.find(p => p.value === usuarioPerfil?.perfil_id)}
                        onChange={selected => {
                            changePerfil(selected.value)
                        }}
                    />
                </SelectPerfilContainer>
            </Modal>
        </>
    )
}
