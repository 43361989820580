import React, { useContext, useState } from 'react'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import { FiSearch, FiArrowLeft, FiEye, FiCheck, FiCheckCircle } from 'react-icons/fi'
import { toast } from 'react-toastify'

import { api } from '../../services/api'

import arrayUtils from '../../util/array'

import Card from '../../components/Card'
import Table from '../../components/Table'
import Spinner from '../../components/Spinner'
import { Textbox, Button } from '../../components/Form'

import {
    Container, CrachaContainer, MedicoContainer, AcademicoContainer
} from './styles'

import defaultAvatar from '../../assets/images/avatar.png'
import { GlobalContext } from '../../contexts/GlobalContext'

export default function () {
    const { clienteRobot } = useContext(GlobalContext)

    const [resultados, setResultados] = useState(null)
    const [associado, setAssociado] = useState(null)
    const [buscando, setBuscando] = useState(false)

    function defineAssociado(assoc) {
        const sindicalizacaoMaisRecente = arrayUtils.get.getLast(assoc.sindicalizacao)

        setAssociado({
            ...assoc,
            data_sindicalizacao: sindicalizacaoMaisRecente?.data
        })
    }

    async function handleSubmit(values) {
        setResultados(null)
        setAssociado(null)
        setBuscando(true)

        try {
            const response = await api.get('consulta_publica', {
                params: values
            })

            setBuscando(false)

            if (response.length === 1) {
                defineAssociado(response[0])
            } else {
                setResultados(response)
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleShowAssociado(item) {
        const response = await api.get(`associado/${item.id}`)

        if (response.status_associacao.id !== 3) {
            toast.warn('Nenhum associado encontrado.')
            return
        }

        defineAssociado(response)
    }

    return (
        <Container>
            {associado ? (
                <>
                    <CrachaContainer>
                        <img src={associado.pessoa.usuario.avatar?.link || defaultAvatar} alt="avatar" className="foto" />

                        <h1>{associado.pessoa.nome}</h1>

                        <MedicoContainer>
                            <h2>
                                {`Matrícula: ${associado.pessoa.usuario.crm}`}
                            </h2>
                        </MedicoContainer>

                        <img src={clienteRobot?.logo} alt="" className="logo" />

                        <div sytle={{ gridArea: 'extra' }} className="mt-12 flex gap-3 items-center">
                            <FiCheckCircle size={20} />

                            {`Associad${associado.pessoa.sexo === 'M' ? 'o' : 'a'} ${clienteRobot.name}`}
                        </div>
                    </CrachaContainer>

                    <Link to="/consulta_publica" className="button transparent">
                        Voltar
                        <FiArrowLeft size={24} />
                    </Link>
                </>
            ) : (
                <Card>
                    <h1>Consulta de membros</h1>

                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                            nome: '',
                            identificador: ''
                        }}
                    >
                        {() => (
                            <Form>
                                <Textbox
                                    name="identificador"
                                    label="Matrícula"
                                />

                                <Textbox
                                    name="nome"
                                    label="Nome"
                                />

                                <Button type="submit" className="transparent">
                                    Pesquisar
                                    <FiSearch />
                                </Button>
                            </Form>
                        )}
                    </Formik>

                    {resultados ? (
                        <Table
                            headers={[
                                { name: 'identificador', value: 'Matrícula', centered: true },
                                { name: 'nome', value: 'Nome' }
                            ]}
                            data={resultados.map(resultado => ({
                                id: resultado.id,
                                identificador: resultado.pessoa.usuario.crm,
                                nome: resultado.pessoa.nome
                            }))}
                            actions={[
                                {
                                    action: item => { handleShowAssociado(item) },
                                    name: 'Mostrar detalhes',
                                    icon: FiEye,
                                    iconSize: 16
                                }
                            ]}
                            emptyLabel="Nenhum associado encontrado"
                        />
                    ) : buscando ? (
                        <Spinner />
                    ) : (
                        <></>
                    )}
                </Card>
            )}
        </Container>
    )
}
