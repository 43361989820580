import React, { useContext, useEffect, useState } from 'react'
import {
    Tab, TabList, TabPanel, Tabs 
} from 'react-tabs'

import Card from '../../../components/Card'

import PermissoesProvider from '../../../contexts/PermissoesContext'

import PermissoesContainer from './PermissoesContainer'
import Perfis from './Perfis'
import Permissoes from './Permissoes'
import Grupos from './Grupos'

import { Container } from './styles'
import { TipContext } from '../../../contexts/TipContext'
import { loadTips } from '../../../util/tip'

export default function () {
    const { setCodigo } = useContext(TipContext)

    const [indiceTabAtiva, setIndiceTabAtiva] = useState(0)

    useEffect(() => {
        switch(indiceTabAtiva) {
            case 0:
                loadTips(setCodigo, 'form_vinculacao_permissoes')
                break
            case 1: 
                loadTips(setCodigo, 'form_perfis')
                break
            case 2:
                loadTips(setCodigo, 'form_permissoes')
                break
            case 3:
                loadTips(setCodigo, 'form_grupos_permissoes')
                break
            default: break
        }
    }, [indiceTabAtiva])

    return (
        <PermissoesProvider>
            <Container>
                <main>
                    <Card>
                        <Tabs
                            selectedIndex={indiceTabAtiva}
                            onSelect={indiceTab => setIndiceTabAtiva(indiceTab)}
                        >
                            <TabList>
                                <Tab>Vinculação</Tab>
                                <Tab>Perfis</Tab>
                                <Tab>Permissões</Tab>
                                <Tab>Grupos de permissões</Tab>
                            </TabList>

                            <TabPanel>
                                <PermissoesContainer />
                            </TabPanel>

                            <TabPanel>
                                <Perfis />
                            </TabPanel>

                            <TabPanel>
                                <Permissoes />
                            </TabPanel>

                            <TabPanel>
                                <Grupos />
                            </TabPanel>
                        </Tabs>
                    </Card>
                </main>
            </Container>
        </PermissoesProvider>
    )
}
