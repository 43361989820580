import React, { useEffect, useState, useContext } from 'react'
import { format } from 'date-fns'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { FiTrash } from 'react-icons/fi'

import { extname } from '../../../../../util/path'
import { api, authHeaders } from '../../../../../services/api'
import genericIcon from '../../../../../assets/images/file.png'
import Modal from '../../../../../components/Modal'
import Spinner from '../../../../../components/Spinner'
import {
    File, Textbox, Button
} from '../../../../../components/Form'
import {
    Container, PreviewContainer, UploadContainer, ConfirmDeleteContainer
} from './styles'
import { AtendimentoContext } from '../../../../../contexts/AtendimentoContext'
import { GlobalContext } from '../../../../../contexts/GlobalContext'

const previewableFormats = ['.jpg', '.jpeg', '.bmp', '.png', '.gif']

export default function ({ send_email = false }) {
    const { user } = useContext(GlobalContext)
    const { atendimento, reloadDemanda } = useContext(AtendimentoContext)

    const uploadDesbloqueado = [1, 3].includes(atendimento?.status.id)
        && (
            [2, 3, 9, 27, 29, 28, 34, 33].includes(user?.perfil.id)
            || (
                ![2, 3, 9, 27, 29, 28, 34, 33].includes(user?.perfil.id)
                && (atendimento?.ultimo_atendente?.id === user?.pessoa.id || user?.perfil.id === 23)
                && atendimento?.status.id === 1
            )
        )

    const [arquivos, setArquivos] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [showUploadForm, setShowUploadForm] = useState(false)
    const [confirmDeleteArquivo, setConfirmDeleteArquivo] = useState(null)

    function handleShowArquivo(arquivo) {
        const { arquivo: { link } } = arquivo

        const extension = extname(link)

        if (previewableFormats.includes(extension)) {
            setPreviewImage(link)
        } else {
            window.open(link, '_blank').focus()
        }
    }

    async function handleAdd(values) {
        try {
            await api.post(`atendimento/arquivo/${atendimento.id}`, {
                ...values,
                send_email
            }, authHeaders())

            toast.success('Documento anexado.')

            setShowUploadForm(false)

            reloadDemanda()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(arquivo) {
        try {
            await api.delete(`atendimento/arquivo/${atendimento.id}/${arquivo.arquivo.id}`, authHeaders())

            toast.success('Documento removido.')

            setConfirmDeleteArquivo(null)

            reloadDemanda()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if (atendimento) {
            setArquivos(atendimento.arquivos)
        }
    }, [atendimento])

    return (
        <>
            <Container className="animated fadeIn delay-300ms">
                <h1>Documentos</h1>

                {uploadDesbloqueado ? (
                    <Button id="button-enviar" className="transparent" onClick={() => { setShowUploadForm(true) }}>Enviar documento</Button>
                ) : null}

                {arquivos ? (
                    <>
                        {!arquivos.length ? (
                            <p className="empty-label">
                                {atendimento.status.id === 3 && [20, 23].includes(user.perfil.id) ? (
                                    <>Você deve iniciar o atendimento para poder enviar documentos.</>
                                ) : atendimento.status.id === 2 ? (
                                    <>Esta demanda já foi finalizada. Não é possível enviar novos documentos.</>
                                ) : (
                                    <>Nenhum documento anexado</>
                                )}
                            </p>
                        ) : (
                            <ul>
                                {arquivos.map(arquivo => {
                                    const extension = extname(arquivo.arquivo.link)

                                    return (
                                        <li key={arquivo.id}>
                                            <img
                                                src={previewableFormats.includes(extension) ? arquivo.arquivo.link : genericIcon}
                                                alt={arquivo.descricao}
                                                onClick={() => { handleShowArquivo(arquivo) }}
                                            />

                                            <h1 style={{ gridColumn: atendimento.status.id === 2 ? '2/span 2' : '2' }}>{arquivo.descricao}</h1>

                                            {uploadDesbloqueado && (
                                                <FiTrash
                                                    onClick={() => {
                                                        setConfirmDeleteArquivo(arquivo)
                                                    }}
                                                />
                                            )}

                                            <h2>{format(new Date(arquivo.createdAt), 'dd/MM/yyyy HH:mm\'h\'')}</h2>
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                    </>
                ) : (
                    <Spinner label="Carregando documentos..." />
                )}
            </Container>

            <Modal
                isOpen={!!previewImage}
                handleClose={() => { setPreviewImage(null) }}
            >
                <PreviewContainer>
                    <p>Para baixar a imagem, clique com o botão direito do mouse.</p>

                    <img src={previewImage} alt="" />
                </PreviewContainer>
            </Modal>

            <Modal
                isOpen={showUploadForm}
                handleClose={() => { setShowUploadForm(false) }}
                title="Enviar novo documento"
            >
                <UploadContainer>
                    <Formik
                        onSubmit={handleAdd}
                        initialValues={{ arquivo: null, descricao: '' }}
                    >
                        {({ setFieldValue, isSubmitting }) => (
                            <Form>
                                <File
                                    name="arquivo"
                                    onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                    label="Anexe aqui o documento"
                                    format="square"
                                    style={{ width: 'calc(100% - 16px)' }}
                                    previewSize={['100%', '140px']}
                                    maxSize={30 * 1024 * 1024}
                                />

                                <Textbox
                                    label="Nome do documento"
                                    name="descricao"
                                />

                                <Button type="submit" className="transparent" loading={isSubmitting}>Enviar</Button>
                            </Form>
                        )}
                    </Formik>
                </UploadContainer>
            </Modal>

            <Modal
                isOpen={!!confirmDeleteArquivo}
                handleClose={() => { setConfirmDeleteArquivo(null) }}
                title="Confirmação"
            >
                <ConfirmDeleteContainer>
                    <p>{`Tem certeza de que deseja excluir o documento ${confirmDeleteArquivo?.descricao}?`}</p>

                    <div className="button-container">
                        <Button className="transparent" onClick={() => { setConfirmDeleteArquivo(null) }}>Não</Button>
                        <Button className="white" onClick={() => { handleDelete(confirmDeleteArquivo) }}>Sim</Button>
                    </div>
                </ConfirmDeleteContainer>
            </Modal>
        </>
    )
}
