import { useLocation } from 'react-router-dom'

export default function useQuery() {
    const queryParams = new URLSearchParams(useLocation().search)

    const normalized = [...queryParams].reduce((result, curr) => {
        const [key, value] = curr

        return {
            ...result,
            [key]: value
        }
    }, {})

    return normalized
}
