import React, { useContext, useEffect } from 'react'
import Card from '../../../components/Card'

import EleicaoProvider from '../../../contexts/EleicaoContext'

import Cadastro from './Cadastro'
import Listagem from './Listagem'

import { loadTips } from '../../../util/tip'

import { TipContext } from '../../../contexts/TipContext'

import { Container } from './styles'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    useEffect(() => {
        loadTips(setCodigo, 'form_eleicao_cadastro')
    }, [])

    return (
        <EleicaoProvider>
            <Container>
                <main>
                    <Card>
                        <h1>Eleições</h1>
                        <p>{tips?.formulario}</p>

                        <Cadastro />

                        <Listagem />
                    </Card>
                </main>
            </Container>
        </EleicaoProvider>
    )
}
