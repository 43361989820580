import React, { useContext, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FaFileUpload } from 'react-icons/fa'
import { lastDayOfMonth, set } from 'date-fns'
import Yup from '../../../../services/yup'

import { api, authHeaders } from '../../../../services/api'
import { getDateString } from '../../../../util/date'

import { File, Select, Button } from '../../../../components/Form'

import { InconsistenciaContext } from '../../../../contexts/InconsistenciaContext'

import { Container } from './styles'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

export default function () {
    const { loadInconsistencias } = useContext(InconsistenciaContext)
    const { setCodigo, tips } = useContext(TipContext)

    async function notificarFalhaCobrancaBanco(instituicao) {
        const primeiroDiaMes = set(new Date(), { date: 1 })

        await api.get('/carga/falha_debito_conta_corrente', {
            params: {
                inicio: getDateString(primeiroDiaMes),
                fim: getDateString(lastDayOfMonth(new Date())),
                instituicao
            },
            ...authHeaders()
        })
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            values.tipo = values.tipo.value

            const response = await api.post('carga_mensalidades', values, authHeaders())

            resetForm()

            toast.success(response.msg || 'Arquivo processado com sucesso!')

            if (values.tipo.label === 'Banco do Brasil' || values.tipo.label === 'Santander') {
                notificarFalhaCobrancaBanco(values.tipo.label)
            }

            loadInconsistencias()
        } catch (e) {
            console.log(e)
            toast.error(e.msg || 'Erro ao processar o arquivo.')
        }
    }

    useEffect(() => {
        loadTips(setCodigo, 'form_carga_arquivo_instituicao')
    }, [])

    return (
        <Container>
            <Formik
                initialValues={{
                    arquivo: null,
                    tipo: '',
                    nome_arquivo: ''
                }}
                validationSchema={Yup.object().shape({
                    arquivo: Yup.number().required('Anexe o arquivo'),
                    tipo: Yup.string().ensure().nullable().required('Selecione o tipo de arquivo')
                })}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, errors, isSubmitting }) => (
                    <Form>
                        <h1>Carga de arquivo</h1>

                        <p style={{ gridArea: 'tip', margin: '0 8px 10px' }}>{tips?.formulario}</p>

                        <File
                            name="arquivo"
                            onSuccess={fileId => setFieldValue('arquivo', fileId)}
                            onName={nome => setFieldValue('nome_arquivo', nome)}
                            label="Arquivo para carregar"
                            format="square"
                            previewSize={['100%', '150px']}
                            error={errors.arquivo}
                        />

                        <Select
                            name="tipo"
                            label="Tipo de arquivo"
                            onChange={(selected, actionMeta) => setFieldValue(actionMeta.name, selected)}
                            options={[
                                { label: 'Santander', value: '1' },
                                { label: 'Banco do Brasil', value: '2' },
                                { label: 'Estado', value: '3' },
                                { label: 'SESMA', value: '4' },
                                { label: 'UNIMED', value: '5' },
                                { label: 'Sicredi', value: '6' },
                                { label: 'SIA LTDA', value: '7' },
                                { label: 'IOL', value: '8' }
                            ]}
                        />

                        <Button type="submit" className="transparent" loading={isSubmitting}>
                            Carregar aquivo
                            <FaFileUpload size={20} />
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
