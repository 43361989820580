import React, { useEffect, useState } from 'react'
import { format, isAfter } from 'date-fns'
import {
    FiArrowLeft, FiBell, FiEdit, FiExternalLink, FiEye, FiPlus, FiTrash
} from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Container, DetalhesContainer } from './styles'

import Card from '../../../../components/Card'
import { Button } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import { api, authHeaders } from '../../../../services/api'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import stringUtils, { pluralize } from '../../../../util/string'

export default function () {
    const [enquetes, setEnquetes] = useState(null)
    const [enqueteDetails, setEnqueteDetails] = useState(null)
    const [sendingNotifications, setSendingNotifications] = useState(false)

    const history = useHistory()

    async function loadEnquetes() {
        try {
            const response = await api.get('enquete', authHeaders())
            const listaEnquetes = []

            for (const enquete of response) {
                const filtroVisao = enquete.filtros.find(f => f.tipo_filtro === 'visoes')

                if (!filtroVisao) {
                    continue
                }

                const visoes = await api.get('visao', {
                    ...authHeaders(),
                    params: {
                        visoes: filtroVisao ? JSON.parse(filtroVisao.valor_filtro) : []
                    }
                })

                listaEnquetes.push({
                    id: enquete.id,
                    ...enquete,
                    data_criacao: format(new Date(enquete.createdAt), 'dd/MM/yyyy HH:mm\'h\''),
                    inicio: format(new Date(enquete.inicio), 'dd/MM/yyyy HH:mm\'h\''),
                    fim: format(new Date(enquete.fim), 'dd/MM/yyyy HH:mm\'h\''),
                    visoes: visoes.map(v => v.descricao).join('<br/>'), // v.quantidade
                    quantidade_questoes: enquete.questoes.length,
                    status: isAfter(new Date(), new Date(enquete.fim)) ? 'Encerrada' : isAfter(new Date(enquete.inicio), new Date()) ? 'Não iniciada' : 'Em curso',
                    quantidade_participantes: 'carregando...',
                    filtros: '',
                    participacoes: {}
                })
            }

            setEnquetes(listaEnquetes)
        } catch (e) {
            console.log(e)

            toast.error(e.msg)
        }
    }

    async function loadQuantidadeParticipantes() {
        try {
            const quantidades = await api.get('enquete/quant_participantes', authHeaders())

            setEnquetes(quantidades.map(quant => {
                const enquete = enquetes.find(e => e.id === quant.enquete_id)

                const naoIniciadas = quant.quantidade - enquete.encerradas - enquete.abstencoes - enquete.em_curso

                return {
                    ...enquete,
                    quantidade_participantes: quant.quantidade,
                    participacoes: {
                        nao_iniciadas: naoIniciadas,
                        nao_iniciadas_percent: ((naoIniciadas * 100) / quant.quantidade).toFixed(1).replace('.', ','),
                        em_curso: enquete.em_curso,
                        em_curso_percent: ((enquete.em_curso * 100) / quant.quantidade).toFixed(1).replace('.', ','),
                        encerradas: enquete.encerradas,
                        encerradas_percent: ((enquete.encerradas * 100) / quant.quantidade).toFixed(1).replace('.', ','),
                        abstencoes: enquete.abstencoes,
                        abstencoes_percent: ((enquete.abstencoes * 100) / quant.quantidade).toFixed(1).replace('.', ',')
                    },
                    filtros: quant.filtros
                }
            }))
        } catch (e) {
            console.log(e)

            toast.error(e.message)
        }
    }

    async function handleDelete(enquete) {
        try {
            await api.delete(`enquete/${enquete.id}`, authHeaders())

            toast.success('Enquete removida com sucesso.')

            loadEnquetes()
        } catch (e) {
            toast.error(e.message)
        }
    }

    function handlePrepareEdit(enquete) {
        history.push({
            pathname: '/enquete/criar',
            state: {
                enqueteId: enquete.id
            }
        })
    }

    async function loadNotificacoes(enquete) {
        try {
            const response = await api.get(`enquete/${enquete.id}/notificacoes`, authHeaders())

            setEnqueteDetails(old => ({
                ...old,
                notificacoes_enviadas: response
            }))
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleNotificarPendentes(enquete) {
        try {
            setSendingNotifications(true)

            const response = await api.post(`enquete/${enquete.id}/notificar`, {}, authHeaders())

            toast.success(`${response.quantidade_notificadas} pessoas foram notificadas.`)
        } catch (e) {
            toast.error(e.msg)
        }

        setTimeout(() => {
            setSendingNotifications(false)
        }, 2000)
    }

    useEffect(() => {
        loadEnquetes()
    }, [])

    useEffect(() => {
        if (enquetes?.length && enquetes.every(e => typeof e.quantidade_participantes === 'string')) {
            loadQuantidadeParticipantes()
        }
    }, [enquetes])

    useEffect(() => {
        if (enqueteDetails && enqueteDetails.notificacoes_enviadas === undefined) {
            loadNotificacoes(enqueteDetails)
        }
    }, [enqueteDetails])

    if (!enquetes) {
        return (
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <Spinner />
                    </Card>
                </main>
            </Container>
        )
    }

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1>Gerenciar enquetes</h1>

                            <Button className="transparent" onClick={() => { history.push('/grupo_permissoes/29') }} style={{ gap: 10 }}>
                                <FiArrowLeft size={16} style={{ marginLeft: 0 }} />
                                Voltar
                            </Button>
                        </div>

                        <div className="button-container">
                            <Button onClick={() => { history.push('/enquete/criar') }} className="white">
                                Nova enquete
                                <FiPlus size={16} />
                            </Button>
                        </div>

                        <Table
                            headers={[
                                { name: 'nome', value: 'Enquete' },
                                {
                                    name: 'data_criacao', value: 'Criada em', centered: true, style: { width: 110 }
                                },
                                {
                                    name: 'inicio', value: 'Início', centered: true, style: { width: 110 }
                                },
                                {
                                    name: 'fim', value: 'Fim', centered: true, style: { width: 110 }
                                },
                                { name: 'visoes', value: 'Grupos' },
                                { name: 'quantidade_participantes', value: 'Participantes', centered: true },
                                { name: 'status', value: 'Status', centered: true }
                            ]}
                            data={enquetes}
                            actions={[
                                {
                                    name: 'visualizar',
                                    icon: FiEye,
                                    iconSize: 16,
                                    action: item => {
                                        setEnqueteDetails(enquetes.find(e => e.id === item.id))
                                    },
                                    checkDisabled: item => typeof item.quantidade_participantes === 'string'
                                },
                                {
                                    name: 'editar',
                                    icon: FiEdit,
                                    iconSize: 15,
                                    action: handlePrepareEdit,
                                    checkDisabled: item => item.status !== 'Não iniciada' || typeof item.quantidade_participantes === 'string'
                                },
                                {
                                    name: 'excluir',
                                    icon: FiTrash,
                                    iconSize: 16,
                                    action: handleDelete,
                                    confirmation: 'Tem certeza que deseja remover esta enquete do sistema?',
                                    checkDisabled: item => item.status !== 'Não iniciada' || typeof item.quantidade_participantes === 'string'
                                },
                                {
                                    name: 'ver respostas',
                                    icon: FiExternalLink,
                                    iconSize: 16,
                                    action: item => { history.push(`/enquete/${item.id}/respostas`) },
                                    checkDisabled: item => item.status === 'Não iniciada'
                                }
                            ]}
                            hideOnSmallHeaders={['visoes', 'data_criacao', 'quantidade_participantes']}
                            hideHeadersOffset={1070}
                            hideOffset={760}
                        />
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!enqueteDetails}
                handleClose={() => {
                    setEnqueteDetails(null)
                }}
                title={`Detalhes da enquete ${enqueteDetails?.nome || ''}`}
            >
                {enqueteDetails && (
                    <DetalhesContainer>
                        <div className="button-container">
                            <Button onClick={() => { handleNotificarPendentes(enqueteDetails) }} className="transparent" loading={sendingNotifications}>
                                Enviar notificações
                                <FiBell size={17} />
                            </Button>
                        </div>
                        <p>
                            Motivo da enquete:
                            <b>
                                {` ${enqueteDetails.motivo}`}
                            </b>
                        </p>
                        <p>
                            Criada em:
                            <b>
                                {` ${enqueteDetails.data_criacao}`}
                            </b>
                        </p>
                        <p>
                            Status:
                            <b>
                                {` ${enqueteDetails.status}`}
                            </b>
                        </p>
                        <p>
                            Início:
                            <b>
                                {` ${enqueteDetails.inicio}`}
                            </b>
                        </p>
                        <p>
                            Fim:
                            <b>
                                {` ${enqueteDetails.fim}`}
                            </b>
                        </p>
                        <p>
                            Quantidade de questões:
                            <b>
                                {` ${enqueteDetails.quantidade_questoes}`}
                            </b>
                        </p>

                        <h2>Participações</h2>

                        <div>
                            Participantes:
                            <br />
                            {enqueteDetails.filtros.map(filtro => {
                                const [key, value] = filtro.split(': ')

                                return (
                                    <div key={filtro} style={{ marginLeft: 16 }}>
                                        <span>{`${key}: `}</span>
                                        <b>{value}</b>
                                    </div>
                                )
                            }) || 'carregando...'}
                        </div>
                        <p>
                            Número total de participantes:
                            <b>
                                {enqueteDetails.quantidade_participantes ? ` ${enqueteDetails.quantidade_participantes} pessoas` : 'carregando...'}
                            </b>
                        </p>
                        <p>
                            Participações não iniciadas:
                            <b>
                                {` ${enqueteDetails.participacoes.nao_iniciadas} (${enqueteDetails.participacoes.nao_iniciadas_percent}%)`}
                            </b>
                        </p>
                        <p>
                            Participações em curso:
                            <b>
                                {` ${enqueteDetails.participacoes.em_curso} (${enqueteDetails.participacoes.em_curso_percent}%)`}
                            </b>
                        </p>
                        <p>
                            Participações encerradas:
                            <b>
                                {` ${enqueteDetails.participacoes.encerradas} (${enqueteDetails.participacoes.encerradas_percent}%)`}
                            </b>
                        </p>
                        <p>
                            Abstenções:
                            <b>
                                {` ${enqueteDetails.participacoes.abstencoes} (${enqueteDetails.participacoes.abstencoes_percent}%)`}
                            </b>
                        </p>

                        <h2>Notificações</h2>

                        <p>
                            Método de notificação dos participantes:
                            <b>
                                {` ${enqueteDetails.tipo_envio === 'auto' ? 'Automático' : 'Manual'}`}
                            </b>
                        </p>

                        {enqueteDetails.tipo_envio === 'auto' && (
                            <>
                                <p>
                                    Periodicidade das notificações:
                                    <b>
                                        {` a cada ${pluralize(enqueteDetails.periodicidade_envio, 'dia', 'dias')}`}
                                    </b>
                                </p>

                                {enqueteDetails.prazo_interrupcao_envio && (
                                    <p>
                                        Enviar notificações até:
                                        <b>
                                            {` ${format(new Date(enqueteDetails.prazo_interrupcao_envio), 'dd/MM/yyyy HH:mm\'h\'')}`}
                                        </b>
                                    </p>
                                )}

                                {enqueteDetails.quantidade_maxima_envio && (
                                    <p>
                                        Notificar até:
                                        <b>
                                            {` ${pluralize(enqueteDetails.quantidade_maxima_envio, 'vez', 'vezes')}`}
                                        </b>
                                    </p>
                                )}
                            </>
                        )}

                        <div>
                            Notificações já enviadas:

                            {enqueteDetails.notificacoes_enviadas?.length ? (
                                <>
                                    {enqueteDetails.notificacoes_enviadas.map((notif, idx) => (
                                        <p key={notif.id} className="envio">
                                            {`${idx + 1} - ${notif.metodo === 'auto' ? 'Disparo programado em' : 'Disparo manual em'} ${format(new Date(notif.createdAt), 'dd/MM/yyyy HH:mm\'h\'')} (${notif.quantidade} pessoas notificadas)`}
                                        </p>
                                    ))}
                                </>
                            ) : (
                                <span style={{ marginLeft: 6 }}>Nenhuma</span>
                            )}
                        </div>

                        <h2>Questões</h2>

                        {enqueteDetails.questoes.map(questao => (
                            <div key={questao.id} className="questao-container">
                                <p>{`${questao.identificador} - ${questao.texto}`}</p>

                                <h3>Alternativas</h3>

                                {questao.alternativas.map(alternativa => (
                                    <p className="alternativa" key={alternativa.id}>{`${alternativa.texto} ${alternativa.proxima_questao_identificador ? ` -> Questão ${alternativa.proxima_questao_identificador}` : ''} `}</p>
                                ))}
                            </div>
                        ))}
                    </DetalhesContainer>
                )}
            </Modal>
        </>
    )
}
