import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`

export const Container = styled.div`
	background-color: #334155;
	padding: 0 30px;
	border-bottom: solid 3px rgba(0, 0, 0, 0.1);
	grid-column: 1/span 2;

	@media (max-width: 600px) {
		padding: 0 10px;
	}
`

export const Content = styled.div`
	height: 64px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 600px) {
		> div {
			margin-left: 48px;
		}
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			height: 60px;
			width: 120px;
			padding: 10px;
		}

		a {
			transition: background 0.3s;

			&:hover {
				background: rgba(0, 0, 0, 0.1);
			}
		}
	}

	aside {
		display: flex;
		align-items: center;
	}
`

export const Profile = styled.div`
	display: flex;
	margin-left: 4px;
	padding-left: 20px;
	border-left: solid 1px rgba(255, 255, 255, 0.3);
	align-items: center;
	justify-content: end;
	max-width: 200px;

	div {
		text-align: right;
		margin-right: 10px;

		strong {
			display: block;
			color: #fff;
			font-weight: 500;
			font-size: 12px;

			@media(max-width: 600px) {
				font-size: 10px;
			}
		}

		button {
			height: 17px !important;
			font-size: 11px !important;
			padding: 0 4px !important;
			opacity: 0.8 !important;
			margin: 5px 0 0 calc(100% - 24px) !important;

			&:hover {
				opacity: 1 !important;
			}
		}
	}
`

export const Avatar = styled.div`
	height: 44px;
	width: 44px;
	border-radius: 50%;
	transition: filter 0.3s;
	background-image: url(${props => props.image});
	background-position: center;
	background-size: cover;

	&:hover {
		filter: brightness(150%);
	}
`

export const AreaNotificacoes = styled.div.attrs({
    className: 'lista-avisos-trigger'
})`
	display: flex;
	align-items: center;
	height: 64px;
	min-width: 50px;
	padding: 0 4px 0 10px;
	transition: 300ms;

	&:hover {
		background-color: #0002;
		cursor: pointer;
	}

	svg {
		width: 26px;

		@media(max-width: 600px) {
			width: 20px;
		}
	}

	.badge {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f44336;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		text-align: center;
		position: relative;
		left: -8px;
		top: 8px;

		span {
			color: #FFF;
			font-size: 10px;
		}
	}

	.loading {
		animation: ${rotate} 1s linear infinite;
	}

	@media(max-width: 400px) {
		min-width: 30px;

		.refresh-notificacao {
			display: none;
		}
	}
`

export const Dropdown = styled.div.attrs({
    className: 'recados-lista animated zoomIn'
})`
	position: absolute;
	z-index: 1;
	top: 60px;
	right: 230px;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	color: #424242;
	border-radius: 4px;
	max-width: 500px;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
	transform-origin: 100% 0%;
	animation-duration: 200ms;
	max-height: 500px;
	overflow-y: auto;

	@media(max-width: 600px) {
		top: 60px;
		right: 20px;
		left: 20px;
		max-height: 400px;
	}

	.item {
		padding: 12px;
		display: grid;
		grid-template-columns: 26px auto 50px;
		gap: 8px;
		align-items: center;
		border-top: solid 1px #0002;
		cursor: pointer;

		svg {
			grid-row: 1/span 2;
		}

		b {
			font-weight: 500;
			font-size: 12px;
		}
		
		.info {
			font-size: 12px;
			color: #999;
			text-overflow: ellipsis;
			width: 200px;
			max-height: 40px;
			overflow: hidden;
			/* white-space: nowrap; */
		}

		.datetime {
			font-size: 9px;
			color: #999;
			grid-column: 3;
			grid-row: 1/span 2;
			text-align: right;
		}

		&:hover {
			background-color: #eee;
		}

		&:first-child {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		&:last-child {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}

		&.ver-mais {
			cursor: pointer;
			
			span {
				grid-column: 1/span 2;
				justify-self: center;
			}
		}
	}
`

export const RecadoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 20px 8px 0;
	gap: 14px;

	.message-container {
		padding: 12px;
		background-color: #fff;
		color: #424242;
		border-radius: 4px;
	}
	
	.button-container {
		display: flex;
		justify-content: center;

		.button {
			width: fit-content;
		}
	}
`
