import React, {
    useState, useEffect, forwardRef, useContext
} from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { FiHelpCircle, FiUpload } from 'react-icons/fi'
import { FaQuestionCircle } from 'react-icons/fa'

import { Container, TipContainer } from './styles'

import { api } from '../../../services/api'
import fileIcon from '../../../assets/images/file.png'
import { TipContext } from '../../../contexts/TipContext'
import { extname, basename } from '../../../util/path'

export const previewableFormats = ['.jpg', '.jpeg', '.bmp', '.png', '.gif']

const formatsByType = {
    video: ['.mp4', '.webm', '.avi'],
    imagem: ['.png', '.jpeg', '.jpg', '.gif', '.bmp', '.webp'],
    texto: ['.pdf', '.doc', '.docx', '.odt'],
    planilhas: ['.xls', '.xlsx', '.ods'],
    audio: ['.mp3', '.m4a', '.wav', '.ogg'],
    slide: ['.ppt', '.pptx', '.odp']
}
formatsByType['*'] = Object.values(formatsByType).reduce((result, current) => [...result, ...current], [])

function File({
    name,
    getPreloadImage,
    placeholderText = 'Enviar',
    onSuccess = () => toast.success('Upload concluído.'),
    onError = () => toast.error('Desculpe, este arquivo não é suportado.'),
    onName = () => { },
    getFile,
    accept = null,
    acceptedExtensions,
    acceptedTypes,
    maxSize = 30 * 1024 * 1024,
    previewSize = '140px',
    format = 'circle',
    label = '',
    error = null,
    previewPDF = false,
    requiredSize,
    tip,
    ...props
}, ref) {
    const { tips } = useContext(TipContext)

    const [preview, setPreview] = useState(null)
    const [extension, setExtension] = useState(null)
    const [progress, setProgress] = useState(0)

    async function onDrop(acceptedFiles) {
        const acceptedFile = acceptedFiles[0]

        if (!acceptedFile) {
            return
        }

        setPreview(null)

        const ext = extname(acceptedFile.path)

        const nome = basename(acceptedFile.path)
        onName(nome)

        setExtension(ext)

        if (getFile) {
            getFile(acceptedFile)
        }

        const reader = new FileReader()

        reader.readAsDataURL(acceptedFile)

        const data = new FormData()

        data.append('arquivo', acceptedFile, acceptedFile.name)

        const options = {
            onUploadProgress: (prog) => {
                const { loaded, total } = prog

                const percent = Math.floor((loaded * 100) / total)

                setProgress(percent)
            }
        }

        const uploadedFile = await api.post('arquivo', data, options)

        onSuccess(uploadedFile.id)

        if (previewableFormats.includes(ext)) {
            setPreview(`url(${URL.createObjectURL(acceptedFile)})`)
        } else if (previewPDF && ext === '.pdf') {
            setPreview(URL.createObjectURL(acceptedFile))
        } else {
            setPreview(`url(${fileIcon})`)
        }

        if (acceptedExtensions && !acceptedExtensions.includes(ext)) {
            toast.warn(`São aceitos apenas arquivos com extensão ${acceptedExtensions.join(', ')}.`)

            setTimeout(() => {
                setPreview(null)
            }, 500)
        }

        if (acceptedTypes) {
            const extensions = []

            acceptedTypes.forEach(type => {
                extensions.push(...formatsByType[type])
            })

            if (!extensions.includes(ext)) {
                toast.warn(`São aceitos apenas arquivos com extensão ${extensions.join(', ')}.`)

                setTimeout(() => {
                    setPreview(null)
                }, 500)
            }
        }

        if (previewableFormats.includes(ext) && requiredSize) {
            const img = new Image()

            img.src = URL.createObjectURL(acceptedFile)

            img.onload = function () {
                if (requiredSize.width !== this.width || requiredSize.height !== this.height) {
                    toast.warn(`A imagem deve ter ${requiredSize.width}px de largura por ${requiredSize.height}px de altura.`)

                    setTimeout(() => {
                        setPreview(null)
                    }, 500)
                }
            }

            img.remove()
        }

        URL.revokeObjectURL(acceptedFile)
    }

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept,
        maxSize,
        multiple: false,
        onDropRejected: onError,
        onDrop
    })

    function isPreviewable(url) {
        return previewableFormats.includes(extname(url).toLowerCase())
    }

    function isPDF(url) {
        return extname(url).toLowerCase() === '.pdf'
    }

    async function loadPreviewImage() {
        const imageUrl = await getPreloadImage()

        if (imageUrl) {
            setExtension(extname(imageUrl))

            if (isPreviewable(imageUrl)) {
                setPreview(`url(${imageUrl})`)
            } else if (isPDF(imageUrl) && previewPDF) {
                setPreview(imageUrl)
            } else {
                setPreview(`url(${fileIcon})`)
            }
        } else {
            setPreview(null)
        }
    }

    useEffect(() => {
        if (getPreloadImage) {
            loadPreviewImage()
        }
    }, [getPreloadImage])

    const tipText = tips.campos?.find(item => item.campo === props.id || item.campo === name)?.texto

    return (
        <Container
            previewSize={previewSize}
            format={format}
            ref={ref}
            withPlaceholderText={!!placeholderText}
            error={!!error}
            isImage={previewableFormats.includes(extension)}
            previewImage={preview}
            progress={progress > 0 && progress < 100}
            {...props}
        >
            <span className="label">{label}</span>

            {tipText ? (
                <div style={{ height: 0 }}>
                    <FiHelpCircle size={14} className="tip-icon" />

                    <TipContainer>
                        <FaQuestionCircle size={13} />
                        <span>{tipText}</span>
                    </TipContainer>
                </div>
            ) : null}

            <div
                {...getRootProps({
                    className: 'file-select-area'
                })}
            >
                <input {...getInputProps()} name={name} />

                {extension === '.pdf' && previewPDF && (
                    <iframe src={preview} title="Arquivo" frameBorder="0" style={{ width: '100%', height: '100%' }} />
                )}

                <div className="overlay">
                    <p className={preview !== 'unset' ? 'with-preview' : ''}>
                        <FiUpload size={16} color="#424242" />
                        {preview ? 'Alterar' : placeholderText}
                    </p>
                </div>
            </div>

            {progress > 0 && progress < 100 && (
                <div className="cssProgress">
                    <div className={`progress2 ${format === 'square' ? 'adjacent' : ''}`}>
                        <div className="cssProgress-bar cssProgress-default cssProgress-active" data-percent={progress} style={{ width: `${progress}%` }} />
                    </div>
                </div>
            )}

            <div className="tip" dangerouslySetInnerHTML={{ __html: tip || `Tamanho máximo: ${maxSize / 1024 / 1024}MB` }} />

            {error && <span className="error">{error}</span>}
        </Container>
    )
}

export default forwardRef(File)
