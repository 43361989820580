import styled from 'styled-components'

export const Container = styled.div`
	main {
		padding: 14px 30px;
	
		.card {
			max-width: 900px;
			min-height: 60vh;
			margin: 30px auto;

			> h1 {
				font-size: 20px;
				margin-bottom: 20px;
			}
		}

		@media(max-width: 470px) {
			padding: 14px 0;
		}
	}
`
