import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'form-financeiro'
})`
	/* .instituicao-container {
		width: calc(50% - 16px) !important;

		@media(max-width: 600px) {
			width: calc(100% - 16px) !important;
		}
	} */
`
