import React, { useEffect, useState, useContext } from 'react'
import { format } from 'date-fns'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'
import { FiPlusCircle } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { api, authHeaders } from '../../../../services/api'

import scrollUtils from '../../../../util/scroll'
import arrayUtils from '../../../../util/array'
import { stringToDate, getDateString, getDate } from '../../../../util/date'

import useQuery from '../../../../hooks/useQuery'

import BoxDadosBasicos from '../../../../boxes/EspacoMedico/DadosBasicos'
import BoxDadosPessoais from '../../../../boxes/EspacoMedico/DadosPessoais'
import BoxEndereco from '../../../../boxes/EspacoMedico/Endereco'
import BoxPagamento from '../../../../boxes/EspacoMedico/Pagamento'
import BoxContatos from '../../../../boxes/EspacoMedico/Contatos'
import BoxArquivos from '../../../../boxes/Arquivos'

import Modal from '../../../../components/Modal'
import GerenciamentoDocumentos from '../../../../components/GerenciamentoDocumentos'

import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

import {
    Container,
    BoxDocumentos
} from './styles'

import { GlobalContext } from '../../../../contexts/GlobalContext'

export default function () {
    const { setCodigo } = useContext(TipContext)

    const { contato, upd_doc } = useQuery()
    const history = useHistory()

    const { user } = useContext(GlobalContext)

    const [pessoa, setPessoa] = useState(null)
    const [showModalDocumentos, setShowModalDocumentos] = useState(false)

    async function loadPessoa() {
        try {
            const associado = await api.get(`associado/${user.associado.id}`, authHeaders())

            const matricula = associado.pessoa.documentos.find(documento => documento.tipo_documento.id === 999)?.identificador

            if (!matricula) {
                toast.error('Matrícula não consta na base de dados.')
                return
            }

            const response = await api.get(`pessoa/${associado.pessoa.id}`, authHeaders())

            const contribuicoesPendentes = await api.get('contribuicao', {
                params: {
                    associado_id: associado.id,
                    status: 0
                },
                ...authHeaders()
            })

            response.matricula = matricula
            response.tipoPagamentoAprovado = response.associado.tipo_pagamento_aprovacao_status

            const tipoPagamento = response?.associado?.tipo_pagamento
            const dadosBancarios = response?.associado?.dados_bancarios

            const dadosInstituicao = response?.associado?.instituicoes?.find(instituicao => instituicao.associado_instituicao.desconto)

            const dadosCartaoCredito = response?.associado?.dados_cartao_credito

            response.pagamento = {
                tipo: tipoPagamento,
                dados_bancarios: tipoPagamento?.id === 1 && dadosBancarios ? {
                    ...dadosBancarios,
                    instituicao: dadosBancarios.instituicao
                } : null,
                dados_instituicao: tipoPagamento?.id === 2 && dadosInstituicao ? {
                    ...dadosInstituicao,
                    matricula: dadosInstituicao.associado_instituicao.matricula
                } : null,
                dados_cartao_credito: dadosCartaoCredito
            }

            const [sindicalizacaoMaisRecente] = response.associado.sindicalizacao.length ? response.associado.sindicalizacao
                .sort(arrayUtils.sort.comparisonFunction('data', 'desc')) : [null]

            response.sindicalizacao = sindicalizacaoMaisRecente?.data ? {
                data: format(new Date(sindicalizacaoMaisRecente.data), 'dd/MM/yyyy'),
                status: sindicalizacaoMaisRecente.status,
                motivo: sindicalizacaoMaisRecente.motivo_desligamento?.descricao,
                observacao: sindicalizacaoMaisRecente.observacao
            } : ''

            response.contribuicoes_pendentes = contribuicoesPendentes.length

            const nomes = response.nome.split(' ')
            response.nome_reduzido = `${nomes[0]} ${nomes.slice(1, nomes.length - 1).map(sobrenome => `${sobrenome[0]}. `).join(' ')}${nomes[nomes.length - 1]}`

            response.socio = response.associado.status_associacao.id === 3
            response.status = response.associado.status_associacao.descricao

            response.documentos = response.associado.pessoa.documentos

            response.endereco = response.associado.pessoa.endereco
            
            response.contatos = [
                ...response.associado.pessoa.contato.map(contato => ({
                    ...contato,
                    icone: contato.tipo_contato.icone
                }))
            ]

            const rgExtraData = response.documentos?.find(documento => documento.tipo_documento.id === 1)?.extra
            const rg_data_emissao = rgExtraData?.data_emissao || ''
            const rg_orgao_emissor = rgExtraData?.orgao_emissor || ''

            response.dados_pessoais = {
                cpf: response.documentos?.find(documento => documento.tipo_documento.id === 2)?.identificador || '',
                data_nascimento: response.data_nascimento ? format(stringToDate(response.data_nascimento), 'dd/MM/yyyy') : '',
                rg: response.documentos?.find(documento => documento.tipo_documento.id === 1)?.identificador || '',
                rg_data_emissao: rg_data_emissao ? format(stringToDate(`${rg_data_emissao} 00:00:00`), 'dd/MM/yyyy') : '',
                rg_orgao_emissor
            }

            response.observacao = response.associado.observacao

            setPessoa(response)
        } catch (e) {
            console.log(e)
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        scrollUtils.toTop()

        if(user) {
            loadPessoa()
        }
    }, [user])

    useEffect(() => {
        if (contato) {
            setTimeout(() => {
                scrollUtils.toElement('.box-local-trabalho')
            }, 500)
        }
    }, [])

    useEffect(() => {
        setShowModalDocumentos(upd_doc && pessoa)
    }, [pessoa])

    useEffect(() => {
        loadTips(setCodigo, 'form_associado_espaco_medico')
    }, [])

    async function reload() {
        setPessoa(null)
        setShowModalDocumentos(false)

        await loadPessoa()
    }

    async function updateDocumentos(values) {
        try {
            await api.put(`documentos/${pessoa.id}`, {
                documentos: values.documentos
                    .map(doc => ({
                        arquivo: doc.arquivo,
                        identificador: doc.identificador,
                        extra: doc.validade ? {
                            validade: getDateString(getDate(doc.validade))
                        } : doc.dataEmissao && doc.orgaoEmissor ? {
                            data_emissao: getDateString(getDate(doc.dataEmissao)),
                            orgao_emissor: doc.orgaoEmissor
                        } : doc.orgaoEmissor ? { orgao_emissor: doc.orgaoEmissor }
                            : doc.dataEmissao ? {
                                data_emissao: getDateString(getDate(doc.dataEmissao))
                            } : null,
                        tipo_documento: doc.tipo_documento.value
                    }))
            }, authHeaders())

            toast.success('Documentos atualizados.')

            history.push('/associado/dados_cadastrais')
        } catch (e) {
            toast.error('Ocorreu um erro ao tentar atualizar os documentos.')
        }
    }

    return (
        <Container>
            <main className="animated fadeIn faster">
                <BoxDadosBasicos pessoa={pessoa} reload={reload} />

                <BoxDadosPessoais pessoa={pessoa} reload={reload} />

                <BoxEndereco pessoa={pessoa} reload={reload} />

                <BoxPagamento
                    pessoa={pessoa}
                    reload={reload}
                    disableLabel={
                        !pessoa?.tipoPagamentoAprovado
                            ? 'Não é possível alterar a forma de pagamento, pois já há uma alteração em análise.'
                            : !pessoa?.pagamento?.tipo
                                ? 'Não é possível alterar a forma de pagamento pois não há uma forma de pagamento definida.'
                                : pessoa?.associado.status_associacao.id !== 3
                                    ? 'Não é possível alterar a forma de pagamento enquanto a situação da associação não for regularizada.'
                                    : 'Não é possível alterar a forma de pagamento enquanto houverem contribuições pendentes.'
                    }
                    enableEdit={pessoa?.associado.status_associacao.id === 3 && !pessoa?.contribuicoes_pendentes && pessoa?.tipoPagamentoAprovado}
                    disabled={!pessoa?.associado.medico}
                />

                <BoxContatos pessoa={pessoa} reload={reload} />

                <BoxDocumentos>
                    <h1>Documentos</h1>

                    <FiPlusCircle
                        data-tip="Adicionar"
                        data-for="tooltip"
                        onClick={() => { setShowModalDocumentos(true) }}
                    />

                    <BoxArquivos
                        title={null}
                        arquivos={pessoa?.documentos.map(doc => ({
                            ...doc,
                            link: doc.arquivo?.link || '',
                            nome: doc.tipo_documento.descricao
                        }))}
                    />
                </BoxDocumentos>
            </main>

            <ReactTooltip
                id="tooltip"
                place="top"
                effect="solid"
                backgroundColor="#fff"
                arrowColor="#fff"
                textColor="#424242"
                className="tooltip-default"
            />

            <Modal
                isOpen={showModalDocumentos}
                handleClose={() => { setShowModalDocumentos(false) }}
                title="Gerenciamento de documentos"
            >
                <GerenciamentoDocumentos
                    handleSubmit={updateDocumentos}
                    documentos={pessoa?.documentos.map(doc => ({
                        id: doc.id,
                        arquivo: doc.arquivo,
                        tipo_documento: doc.tipo_documento,
                        extra: doc.extra,
                        identificador: doc.identificador
                    }))}
                    enablePreview={false}
                    categoria={pessoa?.associado.medico ? 'medico' : 'academico'}
                />
            </Modal>
        </Container>
    )
}
