import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		.card {
			display: flex;
			flex-direction: column;

			button {
				width: fit-content;
				align-self: end;
			}

			form {
				display: flex;
				align-items: end;
				justify-content: end;
				align-self: end;
				
				button {
					width: fit-content;
				}

				.textbox {
					width: 300px;
				}
			}
		}
	}
`

export const FormContainer = styled.div`
	form {
		display: flex;
		gap: 8px;
		align-self: end;
		margin-bottom: 36px;

		.textbox {
			max-width: 400px;
			margin-top: 30px;
		}

		> button {
			margin-top: 48px;

			svg {
				width: 26px;
			}
		}
	}
`
