import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 200px 1fr 1fr 20px;
	grid-template-rows: 30px 30px 30px auto;
	grid-template-areas: 
		'avatar f1 f2 icon'
		'avatar f3 f4 icon'
		'avatar f5 f6 icon'
		'avatar f7 f8 icon'
		'agenda agenda agenda icon';
	grid-gap: 0 14px;
	margin: 20px 8px 0;

	img {
		grid-area: avatar;
		width: 180px;
		height: 180px;
		border-radius: 50%;
		box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
		margin-bottom: 24px;
		justify-self: center;
	}

	> svg {
		grid-area: icon;
		font-size: 18px;
	}

	> div {
		line-height: 24px;
		margin-bottom: 10px;
	}

	@media(max-width: 1000px) {
		grid-template-columns: 1fr 1fr 20px;
		grid-template-rows: 200px 30px 30px 30px auto;
		grid-template-areas:
			'avatar avatar avatar'
			'f1 f2 icon'
			'f3 f4 icon'
			'f5 f6 icon'
			'f7 f8 icon'
			'agenda agenda icon';
	}

	@media(max-width: 700px) {
		display: flex;
		flex-direction: column;
		
		img {
			align-self: center;
		}

		.agenda-container {
			margin-left: 0;
		}

		> svg { 
			align-self: flex-end;
		}
	}
`

export const AgendaContainer = styled.div.attrs({
    className: 'agenda-container'
})`
	margin: 0 8px;
	grid-area: agenda;

	> h1 {
		margin-left: 0;

		svg {
			font-size: 18px;
			margin-left: 20px;
		}
	}

	li {
		display: flex;
		align-items: center;
		gap: 6px;
		line-height: 30px;

		svg {
			font-size: 18px;
		}
	}
`
