import React, { useState, useEffect } from 'react'
import {
    FaArrowLeft, FaChevronRight, FaUpload, FaInbox
} from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router-dom'
import {
    FiEdit, FiEye, FiEyeOff, FiLock, FiPlus, FiShare2, FiTrash, FiUpload
} from 'react-icons/fi'
import { format } from 'date-fns'
import { useDebouncedCallback } from 'use-debounce'

import Card from '../../../../components/Card'
import { Button, Textbox } from '../../../../components/Form'
import Modal from '../../../../components/Modal'

import { api, authHeaders } from '../../../../services/api'
import stringUtils from '../../../../util/string'

import {
    Container, Pasta, BreadcrumbContainer, HeaderContainer, ContextMenu, NovaPasta, ModalExclusaoContainer, PastaAdd, ListaPastas, ListaDocumentos, EmptyPlaceholder
} from './styles'
import Icon from '../../../../components/Icon'
import Spinner from '../../../../components/Spinner'

export default function () {
    const history = useHistory()
    const location = useLocation()

    const pastas = location?.state?.state?.pastas || null

    const [segmentos, setSegmentos] = useState([])
    const [documentos, setDocumentos] = useState(null)
    const [showContextMenu, setShowContextMenu] = useState(null)
    const [showContextMenuFolder, setShowContextMenuFolder] = useState(null)
    const [nomeNovaPasta, setNomeNovaPasta] = useState(null)
    const [pastaRenomeada, setPastaRenomeada] = useState(null)
    const [pastaExclusao, setPastaExclusao] = useState(null)
    const [nivel, setNivel] = useState([])
    const [pastaAberta, setPastaAberta] = useState([])
    const [pastasVisiveis, setPastasVisiveis] = useState([])
    const [docsVisiveis, setDocsVisiveis] = useState(null)
    const [docsExtrasVisiveis, setDocsExtrasVisiveis] = useState(null)
    const [filtro, setFiltro] = useState('')

    async function loadSegmentos() {
        const response = await api.get('acervo/segmento', {
            params: {
                publico: 0
            }
        })

        setSegmentos(response || [])
    }

    async function loadDocumentos() {
        const ultimoNivel = nivel.at(-1)

        if (!ultimoNivel) {
            setDocumentos(null)
            setDocsVisiveis(null)

            return
        }

        const response = await api.get('acervo', {
            params: {
                segmento: ultimoNivel
            },
            ...authHeaders()
        })

        const documentosResponse = response.map(doc => ({
            ...doc,
            data: format(new Date(doc.data_publicacao), 'dd/MM/yyyy HH:mm\'h\'')
        }))

        setDocumentos(documentosResponse)
        setDocsVisiveis(documentosResponse)
    }

    async function handleSalvarPasta() {
        try {
            if (segmentos?.map(s => s.descricao).includes(nomeNovaPasta)) {
                toast.warn('Já existe uma pasta com este nome.')

                return
            }

            if (nomeNovaPasta) {
                await api.post('acervo/segmento', {
                    descricao: nomeNovaPasta,
                    segmento_pai: nivel.length ? nivel.at(-1) : null
                }, authHeaders())

                await loadSegmentos()
            } else if (pastaRenomeada) {
                await api.put(`acervo/segmento/${pastaRenomeada.id}`, {
                    descricao: pastaRenomeada.descricao
                }, authHeaders())

                loadSegmentos()
            }

            setNomeNovaPasta(null)
            setPastaRenomeada(null)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function handleCliqueFora(e) {
        setShowContextMenu(null)
        setShowContextMenuFolder(null)

        const cliqueFora = e.target.id === 'card-acervo'

        if (cliqueFora) {
            if (nomeNovaPasta || pastaRenomeada) {
                handleSalvarPasta()
            } else if (nomeNovaPasta === '') {
                setNomeNovaPasta(null)
            } else if (pastaRenomeada?.descricao === '') {
                setPastaRenomeada(null)
            }
        }
    }

    function getPastaClicada(elem) {
        let elementoAtual = elem
        const classePasta = 'pasta'

        if (!elementoAtual.classList.contains(classePasta)) {
            while (elementoAtual.parentNode && !elementoAtual.classList.contains(classePasta)) {
                elementoAtual = elementoAtual.parentNode
            }
        }

        if (!elementoAtual.classList?.contains('pasta')) {
            return null
        }

        return elementoAtual
    }

    function handleCardContextMenu(e) {
        e.preventDefault()

        let pastaClicada = getPastaClicada(e.target)

        if (!showContextMenuFolder && !pastaClicada) {
            setShowContextMenu({ x: e.clientX, y: e.clientY + window.scrollY })
        } else if (!pastaClicada) {
            setShowContextMenuFolder(null)
        }

        if (nomeNovaPasta === '') {
            setNomeNovaPasta(null)
        }
    }

    function handlePastaContextMenu(e) {
        e.preventDefault()

        let elementoAtual = getPastaClicada(e.target)

        const segm = pastaAberta.find(s => s.id === Number(elementoAtual.id))

        const pastaContainer = document.querySelector('.pastas-container')

        if (pastaContainer.contains(e.target)) {
            setShowContextMenu(null)

            setShowContextMenuFolder({
                id: Number(elementoAtual.id),
                descricao: segm.descricao,
                bloqueado: segm.bloqueado,
                publico: segm.publico,
                oculto_no_cadastro: segm.oculto_no_cadastro,
                x: e.clientX,
                y: e.clientY + window.scrollY
            })
        }
    }

    async function handleDeletePasta() {
        try {
            const idPasta = pastaExclusao.id

            await api.delete(`acervo/segmento/${idPasta}`, authHeaders())

            setSegmentos(old => old.filter(i => i.id !== idPasta))

            setPastaExclusao(null)

            loadSegmentos()

            toast.success('Pasta removida.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function handleNovaPasta() {
        setNomeNovaPasta('Nova pasta')

        setTimeout(() => {
            const input = document.querySelector('#nome-nova-pasta')
            input.focus()
            input.select()
        }, 200)
    }

    function handleRenomearPasta() {
        setPastaRenomeada(showContextMenuFolder)

        setTimeout(() => {
            const input = document.querySelector('#nome-pasta-renomeada')
            input.focus()
            input.select()
        }, 200)
    }

    async function handleDeleteArquivo(id) {
        try {
            await api.delete(`acervo/${id}`, authHeaders())

            toast.success('Arquivo removido com sucesso.')

            loadDocumentos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function sincronizarPastas() {
        if (!segmentos?.length) {
            return
        }

        let aberta = segmentos

        nivel.forEach((n, index) => {
            if (index === 0) {
                aberta = segmentos.find(s => s.id === n).segmentos_filhos
            } else {
                aberta = aberta.find(s => s.id === n).segmentos_filhos
            }
        })

        setPastaAberta(aberta)

        setPastasVisiveis(aberta)
    }

    async function handleAbrirDocumento(doc) {
        window.open(doc.arquivo?.link || doc.link, '_blank')
    }

    function handleUpload() {
        history.push('/acervo/gerenciar', {
            state: {
                pastas: nivel
            }
        })
    }

    async function handleAlternarPublico(segmento) {
        try {
            await api.patch(`acervo/segmento/${segmento.id}/publico`, {}, authHeaders())

            setShowContextMenuFolder(null)

            toast.success('Alterações salvas com sucesso.')

            loadSegmentos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleAlternarDisponibilidadeNoCadastro(segmento) {
        try {
            await api.patch(`acervo/segmento/${segmento.id}/oculto_cadastro`, {}, authHeaders())

            setShowContextMenuFolder(null)

            toast.success('Alterações salvas com sucesso.')

            loadSegmentos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleAlternarPublicoDocumento(docId) {
        try {
            await api.patch(`acervo/documento/${docId}/publico`, {}, authHeaders())

            toast.success('Alterações salvas com sucesso.')

            loadDocumentos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function searchDocumentosExtras(search) {
        if (!search) {
            setDocsExtrasVisiveis(null)
            return
        }

        try {
            setDocsExtrasVisiveis('loading')

            const response = await api.get('acervo', {
                params: {
                    titulo: search,
                    palavras_chave: search
                },
                ...authHeaders()
            })

            let documentosResponse = response.map(doc => ({
                ...doc,
                data: format(new Date(doc.data_publicacao), 'dd/MM/yyyy HH:mm\'h\'')
            }))

            const nivelAtual = nivel.at(-1)

            documentosResponse = documentosResponse.filter(d => d.segmento.id !== nivelAtual)

            if (documentosResponse.length >= 200) {
                setDocsExtrasVisiveis(null)
                toast.warn(`Foram encontrados ${documentosResponse.length} itens. Seja mais específico.`)
                return
            }

            documentosResponse.forEach(doc => {
                let localizacao = [doc.segmento.descricao]
                let referencia = doc.segmento

                while (referencia.segmento_pai) {
                    localizacao.unshift(referencia.segmento_pai.descricao)

                    referencia = referencia.segmento_pai
                }

                doc.localizacao = localizacao.join(' ➔ ')
            })

            setDocsExtrasVisiveis(documentosResponse.length ? documentosResponse : null)
        } catch (e) {
            setDocsExtrasVisiveis(null)
        }
    }

    const searchDocumentosExtrasDebounced = useDebouncedCallback(searchDocumentosExtras, 1000)

    useEffect(() => {
        loadSegmentos()
    }, [])

    useEffect(() => {
        setFiltro('')

        sincronizarPastas()

        loadDocumentos()
    }, [nivel])

    useEffect(() => {
        if (!filtro) {
            setDocsVisiveis(documentos)
            setPastasVisiveis(pastaAberta)
            setDocsExtrasVisiveis(null)

            return
        }

        const termoBuscaNormalizado = filtro.trim().toLowerCase()

        const pastasFiltradas = pastaAberta?.filter(doc => doc.descricao.trim().toLowerCase().includes(termoBuscaNormalizado))

        const documentosFiltrados = documentos?.filter(doc => doc.titulo.trim().toLowerCase().includes(termoBuscaNormalizado)
            || doc.criador.nome.toLowerCase().includes(termoBuscaNormalizado)
            || doc.arquivo.destinatarios?.some(dest => dest.pessoa.nome.toLowerCase().includes(termoBuscaNormalizado))
            || format(new Date(doc.created_at), 'dd/MM/yyyy HH:mm:ss').includes(termoBuscaNormalizado)
            || filtro.toLowerCase()
                .split(' ')
                .map(palavraFiltro => palavraFiltro.trim())
                .every(palavraFiltro => doc.palavras_chave.split(',')
                    .map(pp => pp.toLowerCase())
                    .some(i => i.includes(palavraFiltro))))

        setDocsVisiveis(documentosFiltrados || [])

        setPastasVisiveis(pastasFiltradas || [])
    }, [filtro])

    useEffect(() => {
        if (segmentos !== null) {
            sincronizarPastas()
        }
    }, [segmentos, nivel])

    useEffect(() => {
        if (pastas) {
            setNivel(pastas)
        }
    }, [pastas])

    const nenhumItemAExibir = !pastaAberta?.length && !documentos?.length
    const listagemVazia = !pastasVisiveis?.length && !docsVisiveis?.length
    const idNivelAtual = nivel.at(-1)
    const ePastaEnviados = idNivelAtual === 56
    const eNivelRaiz = !nivel.length
    const eUltimoNivelPermitido = nivel.length === 5

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card
                        id="card-acervo"
                        onContextMenu={handleCardContextMenu}
                        onClick={handleCliqueFora}
                        className="pastas-container"
                    >
                        <h1>Documentos</h1>

                        <HeaderContainer>
                            {!!nivel?.length ? (
                                <Button
                                    className="transparent"
                                    onClick={() => {
                                        setNivel(old => old.slice(0, -1))
                                    }}
                                >
                                    <FaArrowLeft size={18} className="back-icon" />
                                    Voltar
                                </Button>
                            ) : <div />}

                            <BreadcrumbContainer>
                                {segmentos?.length > 0 && nivel.map((n, index, niveis) => {
                                    let descricao
                                    const eUltimoNivel = index === niveis.length - 1

                                    if (index > 3) {
                                        const seg = segmentos.find(s => s.id === niveis[index - 4])
                                            .segmentos_filhos.find(s => s.id === niveis[index - 3])
                                            .segmentos_filhos.find(s => s.id === niveis[index - 2])
                                            .segmentos_filhos.find(s => s.id === niveis[index - 1])
                                            .segmentos_filhos.find(s => s.id === n)

                                        descricao = seg.descricao
                                    } else if (index > 2) {
                                        const seg = segmentos.find(s => s.id === niveis[index - 3])
                                            .segmentos_filhos.find(s => s.id === niveis[index - 2])
                                            .segmentos_filhos.find(s => s.id === niveis[index - 1])
                                            .segmentos_filhos.find(s => s.id === n)

                                        descricao = seg.descricao
                                    } else if (index > 1) {
                                        const seg = segmentos.find(s => s.id === niveis[index - 2])
                                            .segmentos_filhos.find(s => s.id === niveis[index - 1])
                                            .segmentos_filhos.find(s => s.id === n)

                                        descricao = seg.descricao
                                    } else if (index > 0) {
                                        const seg = segmentos.find(s => s.id === niveis[index - 1])
                                            .segmentos_filhos.find(s => s.id === n)

                                        descricao = seg.descricao
                                    } else {
                                        const seg = segmentos.find(s => s.id === n)

                                        descricao = seg.descricao
                                    }

                                    return (
                                        <div key={n}>
                                            {index > 0 && (
                                                <FaChevronRight size={12} />
                                            )}

                                            <span
                                                onClick={eUltimoNivel ? null : () => {
                                                    setNivel(old => old.slice(0, index + 1))
                                                }}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                {descricao}
                                            </span>
                                        </div>
                                    )
                                })}
                            </BreadcrumbContainer>
                        </HeaderContainer>

                        {!nenhumItemAExibir && (
                            <Textbox
                                withoutForm
                                name="filtro"
                                label="Filtrar"
                                value={filtro}
                                onChange={e => {
                                    const search = e.target.value

                                    setFiltro(search)

                                    setDocsExtrasVisiveis('loading')
                                }}
                                onKeyUp={e => {
                                    const search = e.target.value

                                    searchDocumentosExtrasDebounced(search)
                                }}
                            />
                        )}

                        {listagemVazia && !docsExtrasVisiveis && nomeNovaPasta === null && (
                            <EmptyPlaceholder>
                                <div className="text">
                                    <FaInbox size={130} />
                                    <p className="empty-placeholder animated fadeIn">
                                        <i>Nenhum documento ou pasta a exibir</i>
                                    </p>
                                </div>

                                {!filtro && (
                                    <div className="options">
                                        {!eUltimoNivelPermitido && (
                                            <PastaAdd onClick={handleNovaPasta}>
                                                <img src="/assets/images/folder_add.png" alt="" style={{ height: 48 }} draggable={false} />

                                                <span>Criar pasta</span>
                                            </PastaAdd>
                                        )}

                                        {segmentos?.length > 0 && (
                                            <PastaAdd onClick={handleUpload}>
                                                <FaUpload size={50} />

                                                <span>Anexar arquivo</span>
                                            </PastaAdd>
                                        )}
                                    </div>
                                )}
                            </EmptyPlaceholder>
                        )}

                        <ListaPastas>
                            {pastasVisiveis?.filter(s => s.id !== pastaRenomeada?.id)
                                .map(set => (
                                    <Pasta
                                        key={set.id}
                                        onClick={!showContextMenu ? () => {
                                            setNivel(old => [...old, set.id])
                                        } : null}
                                        onContextMenu={handlePastaContextMenu}
                                        id={set.id}
                                    >
                                        <img
                                            src={set.publico ? '/assets/images/shared_folder.png' : '/assets/images/folder.png'}
                                            alt={set.descricao}
                                            style={{ height: 60 }}
                                            draggable={false}
                                        />

                                        <span>{set.descricao}</span>
                                    </Pasta>
                                )) || null}

                            {pastaRenomeada !== null && (
                                <NovaPasta>
                                    <img
                                        src="/assets/images/folder.png"
                                        alt=""
                                        style={{ height: 60 }}
                                        draggable={false}
                                    />

                                    <input
                                        type="text"
                                        id="nome-pasta-renomeada"
                                        value={pastaRenomeada.descricao}
                                        onChange={e => {
                                            e.persist()

                                            setPastaRenomeada(old => ({
                                                ...old,
                                                descricao: e.target.value
                                            }))
                                        }}
                                        onKeyUp={e => {
                                            if (e.keyCode === 13) { // Enter
                                                handleSalvarPasta()
                                            } else if (e.keyCode === 27) { // ESC
                                                setPastaRenomeada(null)
                                            }
                                        }}
                                    />
                                </NovaPasta>
                            )}

                            {nomeNovaPasta !== null && (
                                <NovaPasta>
                                    <img
                                        src="/assets/images/folder.png"
                                        alt=""
                                        style={{ height: 60 }}
                                        draggable={false}
                                    />

                                    <input
                                        type="text"
                                        id="nome-nova-pasta"
                                        value={nomeNovaPasta}
                                        onChange={e => {
                                            setNomeNovaPasta(e.target.value)
                                        }}
                                        onKeyUp={e => {
                                            if (e.key === 'Enter') {
                                                handleSalvarPasta()
                                            } else if (e.key === 'Escape') {
                                                setNomeNovaPasta(null)
                                            }
                                        }}
                                    />
                                </NovaPasta>
                            )}

                            {nomeNovaPasta === null && !listagemVazia && !eUltimoNivelPermitido && (
                                <PastaAdd onClick={handleNovaPasta}>
                                    <img src="/assets/images/folder_add.png" alt="" style={{ height: 48 }} draggable={false} />

                                    <span>Criar pasta</span>
                                </PastaAdd>
                            )}

                            {nomeNovaPasta === null && !listagemVazia && !eNivelRaiz && (
                                <PastaAdd onClick={handleUpload}>
                                    <FaUpload size={50} />

                                    <span>Anexar arquivo</span>
                                </PastaAdd>
                            )}
                        </ListaPastas>

                        {!!docsVisiveis?.length && (
                            <ListaDocumentos style={{ marginTop: 16 }}>
                                <thead>
                                    <tr>
                                        <th>Documento</th>
                                        <th style={{ width: 200 }}>Anexado por</th>
                                        {ePastaEnviados && (
                                            <>
                                                <th style={{ width: 200 }}>Aprovador por</th>
                                                <th style={{ width: 280 }}>Enviado para</th>
                                            </>
                                        )}
                                        <th style={{ width: 160 }}>Data de criação</th>
                                        <th style={{ width: 24 }}>{' '}</th>
                                        <th style={{ width: 24 }}>{' '}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {docsVisiveis.map(doc => (
                                        <tr key={doc.id}>
                                            <td
                                                onClick={() => {
                                                    handleAbrirDocumento(doc)
                                                }}
                                            >
                                                <div>
                                                    <div className="left">
                                                        {doc.publico ? (
                                                            <Icon
                                                                Icon={FiShare2}
                                                                tooltip="Documento público"
                                                                size={21}
                                                            />
                                                        ) : (
                                                            <Icon
                                                                Icon={FiLock}
                                                                tooltip="Documento privado"
                                                                size={21}
                                                            />
                                                        )}

                                                        {doc.titulo}
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                onClick={() => {
                                                    handleAbrirDocumento(doc)
                                                }}
                                            >
                                                <span dangerouslySetInnerHTML={{ __html: doc.criador?.nome ? stringUtils.reduceName(doc.criador?.nome) : '<i>não identificado</i>' }} />
                                            </td>

                                            {ePastaEnviados && (
                                                <>
                                                    <td
                                                        onClick={() => {
                                                            handleAbrirDocumento(doc)
                                                        }}
                                                    >
                                                        <span dangerouslySetInnerHTML={{ __html: doc.aprovador?.nome ? stringUtils.reduceName(doc.aprovador?.nome) : '<i>não aprovado</i>' }} />
                                                    </td>

                                                    <td
                                                        onClick={() => {
                                                            handleAbrirDocumento(doc)
                                                        }}
                                                    >
                                                        <div style={{ lineHeight: 1.8 }} dangerouslySetInnerHTML={{ __html: doc.arquivo.destinatarios.map(d => `- ${d.pessoa.nome}`).join('<br/>') }} />
                                                    </td>
                                                </>
                                            )}

                                            <td
                                                onClick={() => {
                                                    handleAbrirDocumento(doc)
                                                }}
                                            >
                                                {format(new Date(doc.data_publicacao), 'dd/MM/yyyy HH:mm\'h\'')}
                                            </td>
                                            <td>
                                                <Icon
                                                    Icon={doc.publico ? FiLock : FiShare2}
                                                    onClick={() => { handleAlternarPublicoDocumento(doc.id) }}
                                                    tooltip={doc.publico ? 'Tornar restrito' : 'Tornar público'}
                                                    confirmable
                                                    texto={doc.publico
                                                        ? 'Deseja realmente tornar este documento <b>RESTRITO</b>?<br/><br/>Fazendo isto, ele <b>não estará mais disponível</b> para consultas externas na página Acervo SINDMEPA.'
                                                        : 'Deseja realmente tornar este documento <b>PÚBLICO</b>?<br/><br/>Fazendo isto, ele <b>estará disponível para ser consultado publicamente</b> na página Acervo SINDMEPA.'}
                                                />
                                            </td>
                                            <td>
                                                <Icon
                                                    Icon={FiTrash}
                                                    onClick={() => { handleDeleteArquivo(doc.id) }}
                                                    tooltip="Remover documento"
                                                    confirmable
                                                    texto="Deseja realmente remover este documento?"
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </ListaDocumentos>
                        )}

                        {docsExtrasVisiveis === 'loading' ? (
                            <Spinner label="Procurando em outros locais..." />
                        ) : docsExtrasVisiveis?.length ? (
                            <>
                                <h2 className="titulo-documentos-extras">Documentos em outros locais</h2>

                                <ListaDocumentos>
                                    <thead>
                                        <tr>
                                            <th>Documento</th>
                                            <th>Localizado em</th>
                                            <th style={{ width: 200 }}>Anexado por</th>
                                            {ePastaEnviados && <th style={{ width: 280 }}>Enviado para</th>}
                                            <th style={{ width: 160 }}>Data de criação</th>
                                            <th style={{ width: 24 }}>{' '}</th>
                                            <th style={{ width: 24 }}>{' '}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {docsExtrasVisiveis.map(doc => (
                                            <tr key={doc.id}>
                                                <td
                                                    onClick={() => {
                                                        handleAbrirDocumento(doc)
                                                    }}
                                                >
                                                    <div>
                                                        <div className="left">
                                                            {doc.publico ? (
                                                                <Icon
                                                                    Icon={FiShare2}
                                                                    tooltip="Documento público"
                                                                    size={21}
                                                                />
                                                            ) : (
                                                                <Icon
                                                                    Icon={FiLock}
                                                                    tooltip="Documento privado"
                                                                    size={21}
                                                                />
                                                            )}

                                                            {doc.titulo}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    onClick={() => {
                                                        handleAbrirDocumento(doc)
                                                    }}
                                                >
                                                    {doc.localizacao}
                                                </td>
                                                <td
                                                    onClick={() => {
                                                        handleAbrirDocumento(doc)
                                                    }}
                                                >
                                                    <span dangerouslySetInnerHTML={{ __html: doc.criador?.nome ? stringUtils.reduceName(doc.criador?.nome) : '<i>não enviado</i>' }} />
                                                </td>

                                                {ePastaEnviados && (
                                                    <td
                                                        onClick={() => {
                                                            handleAbrirDocumento(doc)
                                                        }}
                                                    >
                                                        <div
                                                            style={{ lineHeight: 1.8 }}
                                                            dangerouslySetInnerHTML={{ __html: doc.arquivo?.destinatarios?.map(d => `- ${d.pessoa.nome}`).join('<br/>') || '' }}
                                                        />
                                                    </td>
                                                )}

                                                <td
                                                    onClick={() => {
                                                        handleAbrirDocumento(doc)
                                                    }}
                                                >
                                                    {format(new Date(doc.data_publicacao), 'dd/MM/yyyy HH:mm\'h\'')}
                                                </td>
                                                <td>
                                                    <Icon
                                                        Icon={doc.publico ? FiLock : FiShare2}
                                                        onClick={() => { handleAlternarPublicoDocumento(doc.id) }}
                                                        tooltip={doc.publico ? 'Tornar restrito' : 'Tornar público'}
                                                        confirmable
                                                        texto={doc.publico
                                                            ? 'Deseja realmente tornar este documento <b>RESTRITO</b>?<br/><br/>Fazendo isto, ele <b>não estará mais disponível</b> para consultas externas na página Acervo SINDMEPA.'
                                                            : 'Deseja realmente tornar este documento <b>PÚBLICO</b>?<br/><br/>Fazendo isto, ele <b>estará disponível para ser consultado publicamente</b> na página Acervo SINDMEPA.'}
                                                    />
                                                </td>
                                                <td>
                                                    <Icon
                                                        Icon={FiTrash}
                                                        onClick={() => { handleDeleteArquivo(doc.id) }}
                                                        tooltip="Remover documento"
                                                        confirmable
                                                        texto="Deseja realmente remover este documento?"
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </ListaDocumentos>
                            </>
                        ) : null}

                        {showContextMenu && (
                            <ContextMenu className="animated fadeIn faster" posicao={showContextMenu}>
                                <ul>
                                    {!eUltimoNivelPermitido && (
                                        <li onClick={handleNovaPasta}>
                                            <span>Criar pasta</span>
                                            <FiPlus size={16} />
                                        </li>
                                    )}

                                    {!eNivelRaiz && (
                                        <li onClick={handleUpload}>
                                            <span>Enviar documento</span>
                                            <FiUpload size={16} />
                                        </li>
                                    )}
                                </ul>
                            </ContextMenu>
                        )}

                        {showContextMenuFolder && (
                            <ContextMenu className="animated fadeIn faster" posicao={showContextMenuFolder}>
                                <ul>
                                    {showContextMenuFolder.bloqueado ? (
                                        <li className="pasta-bloqueada">
                                            <FiLock size={16} />
                                            <span>Esta pasta é protegida e não pode ser editada nem removida.</span>
                                        </li>
                                    ) : (
                                        <>
                                            <li onClick={handleRenomearPasta}>
                                                <span>Renomear</span>
                                                <FiEdit size={16} />
                                            </li>
                                            <li
                                                onClick={() => {
                                                    const { descricao } = pastaAberta.find(s => s.id === showContextMenuFolder.id)

                                                    setPastaExclusao({
                                                        ...showContextMenuFolder,
                                                        descricao
                                                    })
                                                }}
                                            >
                                                <span>Excluir</span>
                                                <FiTrash size={16} />
                                            </li>

                                            {showContextMenuFolder.publico ? (
                                                <li onClick={() => { handleAlternarPublico(showContextMenuFolder) }}>
                                                    <span>Tornar privado</span>
                                                    <FiLock size={16} />
                                                </li>
                                            ) : (
                                                <li onClick={() => { handleAlternarPublico(showContextMenuFolder) }}>
                                                    <span>Tornar público</span>
                                                    <FiShare2 size={16} />
                                                </li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </ContextMenu>
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!pastaExclusao}
                handleClose={() => { setPastaExclusao(null) }}
                title="Confirmação"
            >
                <ModalExclusaoContainer>
                    <p dangerouslySetInnerHTML={{ __html: `Deseja realmente remover a pasta <b>${pastaExclusao?.descricao}</b>?` }} />

                    <div className="button-container">
                        <Button className="transparent" onClick={() => { setPastaExclusao(null) }}>
                            Não
                        </Button>

                        <Button className="white" onClick={handleDeletePasta}>
                            Sim
                        </Button>
                    </div>
                </ModalExclusaoContainer>
            </Modal>
        </>
    )
}
