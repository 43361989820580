import React from 'react'
import { FiCheckCircle, FiCircle } from 'react-icons/fi'

import { Container, TimelineItem } from './styles'

export default function ({ dates }) {
    return (
        <Container>
            <hr className="line" />

            <p>
                {dates.map(date => (
                    <TimelineItem key={date.title}>
                        {date.date ? (
                            <FiCheckCircle size={22} />
                        ) : (
                            <FiCircle size={22} />
                        )}
                        <p>{date.title}</p>
                        <p>{date.date}</p>
                    </TimelineItem>
                ))}
            </p>
        </Container>
    )
}
