import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	> p {
		margin: 0 8px;
		width: calc(100% - 16px);
		line-height: 24px;
	}

	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		button {
			grid-column: 3;
			width: fit-content;
			justify-self: end;
		}

		@media(max-width: 900px) {
			grid-template-columns: 1fr 1fr;

			button {
				grid-column: 1/span 2;
			}
		}

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;
		}
	}
`
