import React, { useState } from 'react'
import { Form, Formik } from 'formik'

import { Container } from './styles'
import EnqueteQuestao from '../EnqueteQuestao'
import ProgressBar from '../ProgressBar'
import { Button, Textarea } from '../Form'

export default function ({ steps, onNextStep }) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)

    const isLastStep = currentStepIndex === steps.length

    const percentage = Math.round((currentStepIndex * 100) / steps.length)

    return (
        <Container>
            <div className="progress">
                <ProgressBar
                    percentage={percentage}
                    visible
                    style={{ width: '100%', maxWidth: 350 }}
                />
            </div>

            {!isLastStep ? (
                <EnqueteQuestao
                    questao={steps[currentStepIndex]}
                    onAnswer={(questao, alternativa, isLast) => {
                        if (!isLast) {
                            const proxima = alternativa.proxima_questao_identificador

                            if (proxima) {
                                setCurrentStepIndex(proxima - 1)
                            } else {
                                setCurrentStepIndex(steps.length)
                            }

                            onNextStep(questao, alternativa, isLast)
                        }
                    }}
                    eUltimaQuestao={isLastStep}
                />
            ) : (
                <Formik
                    onSubmit={values => {
                        onNextStep(null, values.mensagem, isLastStep)
                    }}
                    initialValues={{
                        mensagem: ''
                    }}
                >
                    {() => (
                        <Form>
                            <Textarea
                                label="Você gostaria de adicionar algum comentário ao tema pesquisado? (opcional)"
                                name="mensagem"
                            />

                            <div className="button-container">
                                <Button className="white" type="submit">
                                    Finalizar
                                </Button>
                            </div>
                        </Form>
                    )}

                </Formik>
            )}
        </Container>
    )
}
