import React, { useContext, useState } from 'react'
import { format } from 'date-fns'
import { FiFileText } from 'react-icons/fi'
import { extname } from '../../../../util/path'
import Modal from '../../../../components/Modal'
import Table from '../../../../components/Table'

import { DependenteContext } from '../../../../contexts/DependenteContext'

import { Container, ModalContent } from './styles'

const imageFormats = ['.jpg', '.png', '.gif']

export default function () {
    const {
        detalhesDependente,
        setDetalhesDependente
    } = useContext(DependenteContext)

    const [showDocumento, setShowDocumento] = useState(null)

    return (
        <>
            <Modal
                isOpen={!!detalhesDependente}
                handleClose={() => {
                    setDetalhesDependente(null)
                }}
                title="Detalhes do dependente"
            >
                <Container>
                    <div>
                        <b>Nome: </b>
                        {detalhesDependente?.name}
                    </div>

                    <div>
                        <b>Sexo: </b>
                        {detalhesDependente?.sexo}
                    </div>

                    <div>
                        <b>Data de nascimento: </b>
                        {detalhesDependente ? format(new Date(detalhesDependente.pessoa.data_nascimento), 'dd/MM/yyyy') : ''}
                        {` (${detalhesDependente?.idade} anos)`}
                    </div>

                    <div>
                        <b>Parentesco: </b>
                        {detalhesDependente?.grau_parentesco.descricao}
                    </div>

                    <div>
                        <b>Estado civil: </b>
                        {detalhesDependente?.estado_civil}
                    </div>

                    <div>
                        <b>Deficiência: </b>
                        {detalhesDependente?.pne ? 'Sim' : 'Não'}
                    </div>

                    <Table
                        headers={[
                            { name: 'tipo', value: 'Tipo de documento', centered: true },
                            { name: 'identificador', value: 'Nº do documento', centered: true },
                            { name: 'validade', value: 'Data de validade', centered: true }
                        ]}
                        data={detalhesDependente?.pessoa.documentos?.map(doc => {
                            const validade = doc.extra && JSON.parse(doc.extra)?.validade

                            return {
                                id: doc.id,
                                tipo: doc.tipo_documento.descricao,
                                identificador: doc.identificador || 'Não se aplica',
                                validade: validade ? format(new Date(validade), 'dd/MM/yyyy') : 'Não se aplica',
                                link: doc.arquivo?.link || '',
                                extensao: doc.arquivo?.link ? extname(doc.arquivo.link) : ''
                            }
                        })}
                        actions={[
                            {
                                action: item => {
                                    setShowDocumento(item)
                                },
                                name: 'Visualizar',
                                icon: FiFileText,
                                iconSize: 16
                            }
                        ]}
                    />
                </Container>
            </Modal>

            <Modal
                isOpen={!!showDocumento}
                handleClose={() => {
                    setShowDocumento(null)
                }}
                closeOnOverlayClick
            >
                <ModalContent>
                    {imageFormats.includes(showDocumento?.extensao) ? (
                        <img src={showDocumento?.link} alt="" />
                    ) : (
                        <iframe src={showDocumento?.link} frameBorder="0" title="Anexo" />
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
