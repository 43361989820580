import styled, { css, keyframes } from 'styled-components'
import { darken } from 'polished'

const blink = keyframes`
	0% {
		opacity: 0.1;
	}
	100% {
		opacity: 0.8;
	}
`

export const Button = styled.button`
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	background: #578a5a;
	border: none;
	border-radius: 4px;
	transition: all 0.3s;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	margin: 5px 8px;
	height: 42px;
	padding: 0 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 300ms;

	&:hover {
		box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 5px 8px 0 rgba(0,0,0,0.2);
		background: ${darken(0.03, '#578a5a')};
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	> svg {
		margin-left: 10px;
		color: #FFF !important;
	}

	${props => props.fab && css`
		border-radius: 50%;
		height: 40px;
		width: 40px;
		padding: 2px;

		> svg {
			margin: 0;
			font-size: 30px;
		}
	`}

	${props => props.tiny && css`
		height: 28px;
		padding: 8px;
		font-size: 13px;
	`}

	${props => props.tiny && props.fab && css`
		height: 24px;
		width: 24px;
		padding: 6px;
	`}

	${props => props.loading && css`
		animation: 400ms ${blink} infinite alternate ease-out;
		cursor: wait;
	`}

	/* Colors */

	&.transparent {
		cursor: pointer;
		background: rgba(255, 255, 255, 0.16);
		border: solid 1px #FFF;
		border-bottom: solid 3px #FFF;
		box-shadow: none;
		color: #FFF;
		font-weight: 500;

		&:hover {
			color: #2c2c2c;
			background: #FFF;
			font-weight: 500;
			box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 5px 8px 0 rgba(0,0,0,0.2);

			svg {
				color: #2c2c2c !important;

				${props => !props.fab && css`
					margin-left: 12px;
				`}
			}
		}

		&:disabled {
			cursor: not-allowed;
			color: #eee;
			font-weight: 300;
			background: transparent;

			&:hover {
				box-shadow: unset;
			}
		}

		&.dark {
			color: #424242;
			border: solid 1px #424242;
			border-bottom: solid 3px #424242;
		}
	}

	&.white {
		background: ${darken(0.03, '#FFF')};
		color: #424242;

		svg {
			stroke: #424242;
		}

		&:hover {
			background: #FFF;
			font-weight: 500;
		}
	}

	&.green {
		background: #4caf50;

		&:hover {
			background: ${darken(0.03, '#4caf50')};
		}
	}

	&.blue {
		background: #2196f3;

		&:hover {
			background: ${darken(0.03, '#2196f3')};
		}
	}

	&.red {
		background: #f44336;

		&:hover {
			background: ${darken(0.03, '#f44336')};
		}
	}

	&.yellow {
		background: #ffeb3b;
		color: #2c2c2c;

		&:hover {
			background: ${darken(0.03, '#ffeb3b')};
		}
	}
`
