import React, {
    useCallback, memo, useContext, useState, useEffect
} from 'react'
import { Link } from 'react-router-dom'
import { FiBell } from 'react-icons/fi'
import * as icons from 'react-icons/fa'
import { FaStickyNote } from 'react-icons/fa'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { toast } from 'react-toastify'

import { Button } from '../Form'
import Modal from '../Modal'

import { GlobalContext } from '../../contexts/GlobalContext'

import {
    Container, Content, Profile, AreaNotificacoes, Avatar, Dropdown, RecadoContainer
} from './styles'

import history from '../../services/history'

import avatarDefault from '../../assets/images/avatar.png'
import { api, authHeaders } from '../../services/api'
import stringUtils from '../../util/string'

const profileNameMaxLength = 13

const Header = memo(({ usuario }) => {
    const { user, setUser, clienteRobot } = useContext(GlobalContext)

    const [listaNotificacoesVisible, setListaNotificacoesVisible] = useState(false)
    const [closeNotificacoesListaOnClickOutside, setCloseNotificacoesListaOnClickOutside] = useState(false)

    const [notificacoes, setNotificacoes] = useState([])
    const [quantidadeNotificacoesNaoLidas, setQuantidadeNotificacoesNaoLidas] = useState(0)
    const [notificacoesVerMaisVisible, setNotificacoesVerMaisVisible] = useState(false)
    const [openNotificacao, setOpenNotificacao] = useState(null)

    async function loadNotificacoes() {
        try {
            const response = await api.get('notificacao', authHeaders())

            setNotificacoes(response.recados.map(recado => ({
                ...recado,
                remetente: {
                    id: recado.remetente?.id || 1,
                    nome: recado.remetente?.nome ? stringUtils.reduceName(recado.remetente.nome) : 'Syndi'
                },
                destinatario: {
                    id: recado.destinatario.id,
                    nome: stringUtils.reduceName(recado.destinatario.nome)
                },
                mensagem: recado.mensagem || recado.tipo.mensagem,
                icone: recado.tipo.icone
            })))

            setQuantidadeNotificacoesNaoLidas(response.nao_lidos)

            setNotificacoesVerMaisVisible(response.total_recados > response.recados.length)
        } catch (e) {
            console.error('Erro ao atualizar as notificações.')
        }
    }

    async function loadNotificacoesMaisAntigas() {
        try {
            const response = await api.get('notificacao', {
                params: {
                    recados_visiveis: notificacoes.length
                },
                ...authHeaders()
            })

            setNotificacoes(response.recados.map(recado => ({
                ...recado,
                remetente: {
                    id: recado.remetente?.id || 1,
                    nome: recado.remetente?.nome ? stringUtils.reduceName(recado.remetente.nome) : 'Syndi'
                },
                destinatario: {
                    id: recado.destinatario.id,
                    nome: stringUtils.reduceName(recado.destinatario.nome)
                },
                mensagem: recado.mensagem || recado.tipo.mensagem,
                icone: recado.tipo.icone
            })))

            setQuantidadeNotificacoesNaoLidas(response.nao_lidos)

            setNotificacoesVerMaisVisible(response.total_recados > response.recados.length)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleLerRecado(recado) {
        setOpenNotificacao(recado)

        try {
            await api.patch(`recepcao/recado/${recado.id}`, {}, authHeaders())

            const currentRecado = notificacoes.find(item => item.id === recado.id)

            currentRecado.visto = true

            const posicao = notificacoes.map(item => item.id).indexOf(recado.id)

            setNotificacoes(old => [
                ...old.slice(0, posicao),
                currentRecado,
                ...old.slice(posicao + 1)
            ])

            setQuantidadeNotificacoesNaoLidas(old => old > 0 ? old - 1 : 0)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    const handleLogout = useCallback(() => {
        sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN)
        sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_USER)

        setUser(null)

        history.push('/')
    }, [])

    useEffect(() => {
        if (listaNotificacoesVisible && !closeNotificacoesListaOnClickOutside) {
            setCloseNotificacoesListaOnClickOutside(true)

            document.addEventListener('click', e => {
                const trigger = document.querySelector('.lista-avisos-trigger')
                const lista = document.querySelector('.recados-lista')
                const modal = document.querySelector('.ReactModal__Overlay')

                if (!lista?.contains(e.target) && !trigger?.contains(e.target) && !modal?.contains(e.target)) {
                    setListaNotificacoesVisible(false)
                }
            })
        }
    }, [listaNotificacoesVisible, closeNotificacoesListaOnClickOutside])

    useEffect(() => {
        if (user) {
            loadNotificacoes()

            const interval = setInterval(loadNotificacoes, 2 * 60 * 1000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [user])

    const foto = user?.avatar || user?.pessoa.documentos?.find(doc => doc.tipo_documento_id === 15)?.arquivo?.link

    return (
        <>
            <Container>
                <Content>
                    <div>
                        <Link to="/dashboard">
                            <img src={clienteRobot?.logo} alt="Home" className="object-contain" />
                        </Link>
                    </div>

                    <aside>
                        <AreaNotificacoes onClick={() => { setListaNotificacoesVisible(old => !old) }}>
                            <FiBell size={28} />

                            {quantidadeNotificacoesNaoLidas > 0 && (
                                <div className="badge">
                                    <span>{quantidadeNotificacoesNaoLidas}</span>
                                </div>
                            )}
                        </AreaNotificacoes>

                        {listaNotificacoesVisible && (
                            <Dropdown>
                                {notificacoes.map((recado, index) => (
                                    <div
                                        key={recado.id}
                                        className={`item animated fadeIn fast delay-${100 * index}ms`}
                                        onClick={() => { handleLerRecado(recado) }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        {React.createElement(recado.icone ? icons[recado.icone] : FaStickyNote, {
                                            size: 20,
                                            color: recado.visto ? '#777' : 'gold'
                                        })}

                                        <b>{recado.remetente?.nome || 'Syndi'}</b>

                                        <span className="info" dangerouslySetInnerHTML={{ __html: recado.mensagem || recado.tipo.descricao }} />

                                        <span className="datetime">{format(new Date(recado.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR })}</span>
                                    </div>
                                ))}

                                {notificacoesVerMaisVisible && (
                                    <div
                                        className="item ver-mais"
                                        onClick={loadNotificacoesMaisAntigas}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <span>Ver recados mais antigos</span>
                                    </div>
                                )}
                            </Dropdown>
                        )}

                        <Profile>
                            <div>
                                <strong>{usuario?.pessoa.nome.length > profileNameMaxLength ? `${usuario?.pessoa.nome.substr(0, profileNameMaxLength)}...` : usuario?.pessoa.nome}</strong>

                                <Button onClick={handleLogout} className="transparent" id="logout-button">
                                    Sair
                                </Button>
                            </div>

                            <Link to="/perfil">
                                <Avatar image={foto || avatarDefault} />
                            </Link>
                        </Profile>
                    </aside>

                </Content>
            </Container>

            <Modal
                isOpen={!!openNotificacao}
                handleClose={() => { setOpenNotificacao(null) }}
                title={`Nova mensagem${openNotificacao?.remetente?.nome ? ` de ${openNotificacao?.remetente?.nome}` : ''}`}
            >
                {openNotificacao ? (
                    <RecadoContainer>
                        <div dangerouslySetInnerHTML={{ __html: `Data e hora do recado: <b>${format(new Date(openNotificacao?.created_at), 'dd/MM/yyyy HH:mm\'h\'')}</b>` }} />

                        <div className="message-container" dangerouslySetInnerHTML={{ __html: openNotificacao?.mensagem }} />

                        <div className="button-container">
                            {openNotificacao?.link && (
                                <Link className="button transparent" to={openNotificacao.link} onClick={() => { setOpenNotificacao(null) }}>Acessar</Link>
                            )}
                        </div>
                    </RecadoContainer>
                ) : null}
            </Modal>
        </>
    )
})

export default Header
