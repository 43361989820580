import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'

import { api, authHeaders } from '../../../../../services/api'

import formUtils from '../../../../../util/form'
import { forceDownloadFileFromURL } from '../../../../../util/file'

import { Button, Select } from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

const initialValues = {
    modulo: null,
    formulario: null
}

const nomeRelatorio = 'Relatório de Ajuda do Sistema'

export default function (props) {
    const [modulos, setModulos] = useState(null)
    const [forms, setForms] = useState(null)

    async function loadModulos() {
        const response = await api.get('tips/modulos', authHeaders())

        setModulos(response)
    }

    async function handleFilterByModulo(selected) {
        const selectedModulo = modulos.find(item => item.id === selected.value)

        const response = await api.get(`tips/${selectedModulo.id}/forms`, authHeaders())

        setForms(response)
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            values = formUtils.extractFormValues(values)

            const response = await api.get('relatorio/documentacao', {
                params: values,
                ...authHeaders()
            })

            forceDownloadFileFromURL(response.file, nomeRelatorio)

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }

    useEffect(() => {
        loadModulos()
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, resetForm, setFieldValue
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        {modulos ? (
                            <>
                                <Select
                                    name="modulo"
                                    label="Selecione o módulo"
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)

                                        handleFilterByModulo(selected)
                                    }}
                                    options={modulos.map(modulo => ({
                                        label: modulo.descricao,
                                        value: modulo.id
                                    }))}
                                />

                                <Select
                                    label="Formulário"
                                    name="formulario"
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)
                                    }}
                                    options={forms?.map(mod => ({
                                        label: mod.descricao,
                                        value: mod.id
                                    })) || []}
                                />

                                <ButtonContainer>
                                    <Button
                                        onClick={resetForm}
                                        className="transparent"
                                    >
                                        Limpar
                                    </Button>

                                    <Button
                                        type="submit"
                                        loading={isSubmitting}
                                        className="white"
                                    >
                                        Gerar relatório
                                        <FiFile size={20} />
                                    </Button>
                                </ButtonContainer>
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
