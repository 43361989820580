import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FaCheck } from 'react-icons/fa'

import Yup from '../../../services/yup'
import { api } from '../../../services/api'

import maskUtils from '../../../util/masks'

import ActionFooter from '../../ActionFooter'
import { Button, Checkbox, File } from '../../Form'
import Modal from '../../Modal'

import { Container, TermoContainer, ModalTermoContainer } from './styles'

const initialValues = {
    autorizado: false,
    declaracao: ''
}

const validation = Yup.object({
    autorizado: Yup.boolean(),
    declaracao: Yup.number().nullable().required('Anexe a declaração da Residência Médica')
})

export default function ({ onSubmit }) {
    const [valorAnuidade, setValorAnuidade] = useState(null)
    const [valorDesconto, setValorDesconto] = useState(null)
    const [showTermoCiencia, setShowTermoCiencia] = useState(false)

    async function loadValores() {
        const response = await api.get('desconto_anuidade', {
            params: { grupo: 'residente' }
        })

        setValorAnuidade(response.valor)
        setValorDesconto(50)
    }

    useEffect(() => {
        loadValores()
    }, [])

    return (
        <>
            <Container>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, setFieldValue, errors }) => (
                        <Form>
                            <File
                                name="declaracao"
                                id="declaracao_residencia"
                                onSuccess={fileId => setFieldValue('declaracao', fileId)}
                                label="Anexe aqui a Declaração de Residência Médica"
                                format="square"
                                error={errors.declaracao}
                                style={{ width: 'calc(100% - 16px)' }}
                                previewSize={['100%', '140px']}
                            />

                            <p style={{ gridArea: 'valor_integral' }}>
                                Valor da anuidade:
                                <span style={{ fontWeight: 800 }}>
                                    {valorAnuidade && ` ${maskUtils.maskApply.currency(valorAnuidade * 2)}`}
                                </span>
                            </p>

                            <p style={{ gridArea: 'valor_desconto' }}>
                                {`Desconto de ${valorDesconto}% para médicos residentes:`}
                                <span style={{ fontWeight: 800 }}>
                                    {valorAnuidade && ` ${maskUtils.maskApply.currency(valorAnuidade)}`}
                                </span>
                            </p>

                            <TermoContainer>
                                <Button onClick={() => setShowTermoCiencia(true)} className="transparent">
                                    VER TERMO DE CIÊNCIA
                                </Button>
                            </TermoContainer>

                            <Checkbox
                                name="autorizado"
                                label={`Li e concordo com o Termo de Ciência acima e autorizo a emissão do boleto da anuidade de ${new Date().getFullYear()}, no valor correspondente a ${maskUtils.maskApply.currency(valorAnuidade)}, em favor do Sindicato dos Médicos. Estou ciente que este valor será reajustado anualmente no mês de janeiro.`}
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                containerStyle={{ gridArea: 'check' }}
                            />

                            <ActionFooter style={{ gridArea: 'button' }}>
                                <Button type="submit" loading={isSubmitting} className="white">
                                    Concluir
                                    <FaCheck />
                                </Button>
                            </ActionFooter>
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={showTermoCiencia}
                handleClose={() => setShowTermoCiencia(false)}
                title="Termo de Ciência do Associado"
            >
                <ModalTermoContainer>
                    <iframe src={`${process.env.REACT_APP_BACKEND_URL}/resources/TERMO DE CIENCIA.pdf`} frameBorder="0" title="Termo de Ciência" />
                </ModalTermoContainer>
            </Modal>
        </>
    )
}
