import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	> button {
		width: fit-content;
		align-self: flex-end;
		margin-top: 20px;
	}

	.data-ultima-atualizacao {
		align-self: flex-end;
		margin: 25px 8px 0;
	}

	@media(max-width: 1200px) {
		.chart {
			display: none;
		}
	}
`

export const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-areas: 
		'select card1 card2 card3 x'
		'chart chart chart chart chart'
		'table table table table table';

	.card {
		display: flex;
		flex-direction: column;
		
		h2 {
			font-size: 50px;
			font-weight: 500;
			align-self: center;
			color: #4267B2;
		}

		span {
			font-size: 14px;
			font-weight: 400;
			align-self: center;
		}

		.extra {
			font-size: 9px;
			line-height: 16px;
			text-align: center;
		}
	}

	@media(max-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-areas: 
			'select card1 card2 card3'
			'chart chart chart chart'
			'table table table table';
	}

	@media(max-width: 1000px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas: 
			'select select select'
			'card1 card2 card3'
			'chart chart chart'
			'table table table';
	}

	@media(max-width: 700px) {
		grid-template-columns: 1fr;
		grid-template-areas: 
			'select'
			'card1'
			'card2'
			'card3'
			'table';
	}
`

export const CardImage = styled.div`
	background-image: url(${props => props.src});
	background-size: cover;
	background-position: center;
	object-fit: contain;
	height: 100px;
	width: 100%;

	@media(max-width: 700px) {
		display: none;
	}
`

export const Paginator = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;

	span {
		font-weight: 500;
		font-size: 20px;
	}

	@media(max-width: 600px) {
		display: none;
	}
`

export const ModalContent = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-areas: 
		'data data data data data data'
		'conteudo conteudo conteudo conteudo conteudo conteudo'
		'ultima_atualizacao ultima_atualizacao ultima_atualizacao ultima_atualizacao ultima_atualizacao ultima_atualizacao'
		'like amei uau triste haha grr'
		'button button button button button button';

	p.data { 
		grid-area: data; 
		margin: 8px 8px 16px;
	}

	p.conteudo {
		grid-area: conteudo;
		margin: 8px 8px 16px;
	}

	p.data-ultima-atualizacao {
		grid-area: ultima_atualizacao;
		margin: 8px 8px 16px;
	}

	button {
		grid-area: button;
		justify-self: flex-end;
	}

	.card {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		> img {
			object-fit: contain;
			width: 100px;
		}

		h1 {
			font-size: 24px;
			font-weight: 100;
		}

		> span {
			font-weight: 100;
			font-size: 14px;
		}
	}
`
