import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import {
    format, getDay, isBefore, isSameDay, differenceInMinutes
} from 'date-fns'

import Yup from '../../../../../../services/yup'

import {
    Button, Textarea, Select, Calendar 
} from '../../../../../../components/Form'

import formUtils from '../../../../../../util/form'

import { Container } from './styles'

import { api, authHeaders } from '../../../../../../services/api'

import { GlobalContext } from '../../../../../../contexts/GlobalContext'
import { AtendimentoContext } from '../../../../../../contexts/AtendimentoContext'
import { TipContext } from '../../../../../../contexts/TipContext'

const baseInitialValues = {
    advogado: null,
    motivo: '',
    horario: null,
    data: null
}

export default function () {
    const {
        advogados, agendamentoEdit, atendimento, reloadDemanda
    } = useContext(AtendimentoContext)
    const { user } = useContext(GlobalContext)
    const { tips } = useContext(TipContext)

    const [advogadoSelecionado, setAdvogadoSelecionado] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [alertaHorario, setAlertaHorario] = useState(null)

    async function handleAgendarAtendimento(values) {
        try {
            values = formUtils.extractFormValues(values)

            const [inicio, fim] = values.horario.split('-')

            const body = {
                pessoa_id: values.advogado,
                atendimento_id: agendamentoEdit?.atendimento.id || atendimento.id,
                inicio: `${inicio}:00`,
                fim: `${fim}:00`,
                data: values.data,
                motivo: values.motivo
            }

            if(agendamentoEdit) {
                await api.put(`atendimento/agendamento/${agendamentoEdit.id}`, body, authHeaders())

                toast.success('Agendamento atualizado com sucesso.')
            } else {
                await api.post('atendimento/agendamento', body, authHeaders())

                toast.success('Agendamento realizado com sucesso.')
            }

            reloadDemanda()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    function getDatasLotadas(advogado) {
        const datasBloqueadas = []
        const agendamentosComDatas = []

        for(const agendamento of advogado.agendamentos) {
            const diaSemana = getDay(new Date(`${agendamento.data} ${agendamento.inicio}`)) + 1
            const dataAgendamento = new Date(`${agendamento.data} 00:00:00`)

            if(!agendamentosComDatas.find(a => isSameDay(a.data, dataAgendamento))) {
                agendamentosComDatas.push({
                    dia_semana: diaSemana,
                    data: new Date(`${agendamento.data} 00:00:00`),
                    periodos: advogado.agenda.find(a => a.dia_semana === diaSemana)?.periodo.map(p => ({
                        ...p,
                        ocupado: false
                    })) || []
                })
            }
        }

        for(const agendamento of advogado.agendamentos) {
            const dataAgendamento = new Date(`${agendamento.data} 00:00:00`)

            const periodoAOcupar = agendamentosComDatas.find(a => isSameDay(a.data, dataAgendamento)).periodos.find(p => p.inicio === agendamento.inicio)

            if(periodoAOcupar) {
                periodoAOcupar.ocupado = true
            }
        }

        for(const agendaData of agendamentosComDatas) {
            if(agendaData.periodos.every(p => p.ocupado)) {
                datasBloqueadas.push(agendaData.data)
            }
        }

        for(const itemAgenda of advogado.agenda) {
            if(getDay(new Date()) + 1 === itemAgenda.dia_semana) {
                const aposTodosOsPeriodos = itemAgenda.periodo.every(p => isBefore(new Date(`${format(new Date(), 'yyyy-MM-dd')} ${p.fim}`), new Date()))

                if(aposTodosOsPeriodos) {
                    datasBloqueadas.push(new Date())
                }
            }
        }

        return datasBloqueadas
    }

    function getDisabledDays(advogado) {
        const disabledDays = [0, 1, 2, 3, 4, 5, 6].filter(d => !advogado?.agenda?.map(a => a.dia_semana - 1).includes(d))

        return disabledDays
    }

    function getHorariosDisponiveis(advogado, data) {
        if(!data) {
            return []
        }

        const diaSemana = getDay(data) + 1

        const agendamentosData = advogado.agendamentos.filter(a => isSameDay(new Date(`${a.data} ${a.inicio}`), data))

        const horariosAgendados = agendamentosData.map(a => a.inicio)

        const horariosDisponiveis = advogado.agenda
            .find(a => a.dia_semana === diaSemana)
            ?.periodo.filter(p => !horariosAgendados.includes(p.inicio)) || []

        return horariosDisponiveis.map(h => ({
            label: `${format(new Date(`2000-01-01 ${h.inicio}`), 'HH:mm')} às ${format(new Date(`2000-01-01 ${h.fim}`), 'HH:mm')}`,
            value: `${format(new Date(`2000-01-01 ${h.inicio}`), 'HH:mm')}-${format(new Date(`2000-01-01 ${h.fim}`), 'HH:mm')}`
        }))
    }

    function checkAlertaHorario(advogado, data, periodo) {
        const diaSemana = getDay(data) + 1
        const tempoAtendimento = Number(advogado.agenda.find(a => a.dia_semana === diaSemana).tempo_atendimento.split(':')[1])

        const [, fim] = periodo.split('-')

        const tempoRestante = differenceInMinutes(new Date(`${format(new Date(), 'yyyy-MM-dd')} ${fim}`), new Date())

        if(tempoRestante > 0 && tempoRestante < tempoAtendimento) {
            setAlertaHorario(`Restam apenas ${tempoRestante} minuto(s) para encerrar o horário selecionado.`)
        } else {
            setAlertaHorario(null)
        }
    }
    
    useEffect(() => {
        if(agendamentoEdit) {
            setAdvogadoSelecionado(advogados.find(a => a.pessoa.id === agendamentoEdit.atendente.id))

            setInitialValues({
                ...baseInitialValues,
                advogado: { label: agendamentoEdit.atendente.nome, value: agendamentoEdit.atendente.id },
                motivo: agendamentoEdit.motivo,
                horario: {
                    label: `${agendamentoEdit.inicio.substr(0, 5)} às ${agendamentoEdit.fim.substr(0, 5)}`,
                    value: `${agendamentoEdit.inicio.substr(0, 5)}-${agendamentoEdit.fim.substr(0, 5)}`
                },
                data: new Date(`${agendamentoEdit.data} ${agendamentoEdit.inicio}`)
            })
        }
    }, [agendamentoEdit])

    return (
        <Container>
            <Formik
                onSubmit={handleAgendarAtendimento}
                initialValues={initialValues}
                validationSchema={Yup.object({
                    advogado: Yup.string().nullable().required('Selecione um advogado.'),
                    motivo: Yup.string(),
                    horario: Yup.string().nullable().required('Selecione o horário de atendimento.')
                })}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                        <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                        <Select 
                            name="advogado"
                            label="Atendente"
                            options={advogados
                                .filter(a => a.agenda.length)
                                .filter(a => user.perfil.id === 20 ? user.pessoa.id === a.pessoa.id : true)
                                .map(a => ({
                                    label: a.pessoa.nome,
                                    value: a.pessoa.id
                                }))}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)

                                setFieldValue('data', null)

                                setAdvogadoSelecionado(advogados.find(a => a.pessoa.id === selected.value))
                            }}
                        />

                        {advogadoSelecionado && (
                            <>
                                <Calendar 
                                    name="data" 
                                    label="Data do atendimento"
                                    minDate={new Date()}
                                    disabledDays={getDisabledDays(advogadoSelecionado)}
                                    disabledDates={getDatasLotadas(advogadoSelecionado)}
                                    onChange={e => {
                                        setFieldValue('data', e.target.value)
                                        setFieldValue('horario', null)
                                    }}
                                    readOnlyInput
                                />

                                {values.data && (
                                    <Select 
                                        name="horario"
                                        label="Horário"
                                        options={getHorariosDisponiveis(advogadoSelecionado, values.data)}
                                        onChange={(selected, meta) => { 
                                            setFieldValue(meta.name, selected) 

                                            checkAlertaHorario(advogadoSelecionado, values.data, selected.value)
                                        }}
                                    />
                                )}
                            </>	
                        )}

                        {alertaHorario && (
                            <span className="badge">
                                {alertaHorario}
                            </span>
                        )}

                        <Textarea 
                            label="Motivo do atendimento"
                            name="motivo"
                        />

                        <div className="button-container">
                            <Button className="transparent" type="submit" loading={isSubmitting}>
                                Agendar atendimento
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
