import React, { useContext, useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import {
    FiPlus, FiCamera, FiTrash, FiEdit, FiFileText, FiCheck
} from 'react-icons/fi'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import ReactTooltip from 'react-tooltip'

import {
    Button, Textbox, Textarea, File, Select
} from '../../../../components/Form'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'

import arrayUtils from '../../../../util/array'
import scrollUtils from '../../../../util/scroll'

import { EleicaoContext } from '../../../../contexts/EleicaoContext'

import { api, authHeaders } from '../../../../services/api'

import {
    Container, CandidatosContainer, UploadFotoContainer, ChapaItemContainer, ChapaListaContainer, DeleteChapaContainer
} from './styles'

export default function () {
    const {
        handleSubmitChapas, handleDeleteChapa, cadastroChapa, setCadastroChapa, handleUploadFoto, candidatoUploadFoto, setCandidatoUploadFoto, setCandidatoUploadCurriculo, candidatoUploadCurriculo, handleUploadCurriculo, reloadEleicoes
    } = useContext(EleicaoContext)

    const [resultadoBusca, setResultadoBusca] = useState(null)
    const [candidatos, setCandidatos] = useState([])
    const [chapaDelete, setChapaDelete] = useState(null)
    const [chapaEditInfo, setChapaEditInfo] = useState(null)
    const [openChapa, setOpenChapa] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [candidatoAIncluir, setCandidatoAIncluir] = useState(null)

    async function buscar(search, chapa) {
        try {
            if (!search) {
                setResultadoBusca(null)
                return
            }

            // const response = await api.get('pessoa', {
            //     params: {
            //         nome: search,
            //         categorias: ['medico', 'funcionario', 'academico', 'advogado']
            //     },
            //     ...authHeaders()
            // })

            const response = await api.get('pessoa', {
                params: {
                    nome: search,
                    categorias: ['medico', 'funcionario'],
                    quites: 1
                },
                ...authHeaders()
            })

            const listaFiltrada = response.filter(item => !chapa.candidatos.map(cand => cand.pessoa.id).includes(item.id))

            if (listaFiltrada.length) {
                setResultadoBusca(listaFiltrada.slice(0, 8).map(r => ({
                    ...r,
                    crm: r.documentos.find(d => d.tipo_documento.id === 3)?.identificador,
                    nome: r.nome,
                    status_associacao: r.associado?.status_associacao?.descricao,
                    data_ultima_atualizacao: r.data_ultima_atualizacao && r.associado ? format(new Date(r.data_ultima_atualizacao), 'dd/MM/yyyy') : null,
                    alerta_atualizacao: !!r.associado
                })))
            } else {
                setResultadoBusca([])
            }
        } catch (e) {
            toast.error('Erro ao buscar por nome.')
        }
    }

    const handleBuscar = debounce(buscar, 1000)

    function handleSelecionarPessoa(result, chapa) {
        document.querySelector(`.nome-candidato-${chapa.id}`).value = result.nome

        setResultadoBusca(null)

        setCandidatoAIncluir(result)
    }

    async function handleAddCandidato(chapa, cargo) {
        if (!candidatoAIncluir?.nome || !cargo) {
            toast.warn('Preencha todos os campos para incluir um candidato.')
            return
        }

        const response = await api.post('eleicao/chapa/candidato', {
            pessoa_id: candidatoAIncluir.id,
            chapa_id: chapa.id,
            cargo_id: cargo
        }, authHeaders())

        chapa.candidatos = [
            ...chapa.candidatos,
            {
                id: response.id,
                pessoa: {
                    id: candidatoAIncluir.id,
                    nome: candidatoAIncluir.nome
                },
                foto: candidatoAIncluir.usuario?.avatar?.link,
                cargo: cargos.find(c => c.id === cargo)
            }
        ]

        setCadastroChapa({
            ...cadastroChapa,
            chapas: [
                ...cadastroChapa.chapas.filter(c => c.id !== chapa.id),
                chapa
            ]
        })

        toast.success('Candidato incluído.')

        document.querySelector(`.nome-candidato-${chapa.id}`).value = ''

        setCandidatoAIncluir(null)
    }

    async function handleUpdateChapaInfo(evt) {
        try {
            if (!evt.target.value) {
                toast.warn('Informe o nome da chapa.')
                return
            }

            const { chapa, prop } = chapaEditInfo

            setCadastroChapa({
                ...cadastroChapa,
                chapas: [
                    ...cadastroChapa.chapas.filter(c => c.id !== chapa.id),
                    {
                        ...chapa,
                        [prop]: evt.target.value,
                        new: false
                    }
                ]
            })

            api.put(`eleicao/chapa/${chapa.id}`, {
                [prop]: evt.target.value
            }, authHeaders())

            setChapaEditInfo(null)

            toast.success('Dados atualizados.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handlDeleteCandidatoChapa(item, chapa) {
        try {
            chapa.candidatos = chapa.candidatos.filter(cand => cand.id !== item.id)

            setCadastroChapa({
                ...cadastroChapa,
                chapas: [
                    ...cadastroChapa.chapas.filter(c => c.id !== chapa.id),
                    chapa
                ]
            })

            await api.delete(`eleicao/chapa/candidato/${item.id}`, authHeaders())

            toast.success('Candidato removido.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleAddChapa() {
        try {
            const response = await api.post('eleicao/chapa', {
                eleicao_id: cadastroChapa.id,
                descricao: 'Informe o nome da chapa aqui...',
                candidatos: [],
                proposta: 'Informe a proposta da chapa aqui...',
                imagem: null
            }, authHeaders())

            toast.info('Nova chapa criada. Preencha os dados.')

            response.new = true

            setCadastroChapa({
                ...cadastroChapa,
                chapas: [
                    response,
                    ...cadastroChapa.chapas
                ]
            })

            scrollUtils.toTop(0, '.chapa-lista')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleUpdateChapaImagem(chapa, arquivoId) {
        try {
            const response = await api.put(`eleicao/chapa/imagem/${chapa.id}`, { imagem: arquivoId }, authHeaders())

            setCadastroChapa({
                ...cadastroChapa,
                chapas: [
                    ...cadastroChapa.chapas.filter(c => c.id !== chapa.id),
                    {
                        ...chapa,
                        imagem: response
                    }
                ]
            })
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadCargos() {
        const responsse = await api.get('eleicao/cargos', authHeaders())

        setCargos(responsse)
    }

    useEffect(() => {
        if (chapaEditInfo) {
            const { chapa, prop } = chapaEditInfo

            document.querySelector(`.${prop}-chapa-${chapa.id}`).focus()
        }
    }, [chapaEditInfo])

    useEffect(() => {
        loadCargos()
    }, [])

    return (
        <>
            <Container>
                <Formik
                    onSubmit={values => {
                        values.candidatos = candidatos.map(c => c.id)

                        handleSubmitChapas(values)

                        setCandidatos([])
                    }}
                    initialValues={{
                        descricao: '',
                        imagem: null,
                        proposta: ''
                    }}
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <div className="button-container">
                                <Button
                                    className="transparent"
                                    onClick={handleAddChapa}
                                >
                                    Nova chapa
                                    <FiPlus size={16} />
                                </Button>
                            </div>

                            {cadastroChapa.chapas ? (
                                <>
                                    {cadastroChapa.chapas.length ? (
                                        <ChapaListaContainer>
                                            {[...cadastroChapa.chapas].sort(arrayUtils.sort.comparisonFunction('descricao'))
                                                .map(chapa => (
                                                    <ChapaItemContainer
                                                        key={chapa.id}
                                                        open={openChapa?.id === chapa.id}
                                                        onClick={e => {
                                                            e.preventDefault()

                                                            setOpenChapa(chapa)
                                                        }}
                                                    >
                                                        <summary>
                                                            <span>
                                                                {
                                                                    chapa.new || chapa.descricao.endsWith('...')
                                                                        ? 'Preencha os campos desta seção para incluir a chapa...'
                                                                        : chapa.descricao
                                                                }
                                                            </span>

                                                            <div
                                                                className="action"
                                                                role="button"
                                                                focusable
                                                                tabIndex={-1}
                                                                onClick={() => {
                                                                    setChapaDelete(chapa)
                                                                }}
                                                            >
                                                                <FiTrash size={16} />
                                                            </div>
                                                        </summary>

                                                        <div className="content">
                                                            <File
                                                                name="imagem"
                                                                onSuccess={fileId => {
                                                                    setFieldValue('imagem', fileId)

                                                                    handleUpdateChapaImagem(chapa, fileId)
                                                                }}
                                                                label="Imagem da chapa"
                                                                format="square"
                                                                previewSize={['100%', '200px']}
                                                                getPreloadImage={() => chapa.imagem?.link}
                                                                style={{ gridArea: 'imagem' }}
                                                            />

                                                            <div className="chapa-info">
                                                                {chapaEditInfo?.prop === 'descricao' ? (
                                                                    <Textbox
                                                                        withoutForm
                                                                        label="Nome da chapa (tecle Enter para salvar as alterações)"
                                                                        initialValue={chapa.descricao}
                                                                        className={`descricao-chapa-${chapa.id}`}
                                                                        onKeyUp={e => {
                                                                            if (e.keyCode === 13) {
                                                                                handleUpdateChapaInfo(e)
                                                                            }
                                                                        }}
                                                                        hint="Tecle Enter para salvar as alterações."
                                                                    />
                                                                ) : (
                                                                    <h1
                                                                        className="descricao"
                                                                        onClick={() => {
                                                                            setChapaEditInfo({
                                                                                chapa,
                                                                                prop: 'descricao'
                                                                            })
                                                                        }}
                                                                    >
                                                                        {chapa.descricao}
                                                                        <FiEdit size={17} />
                                                                    </h1>
                                                                )}

                                                                <div
                                                                    className="proposta"
                                                                    onClick={() => {
                                                                        setChapaEditInfo({
                                                                            chapa,
                                                                            prop: 'proposta'
                                                                        })
                                                                    }}
                                                                    role="button"
                                                                    tabIndex={-1}
                                                                >
                                                                    <div className="title">
                                                                        <h1>Proposta</h1>

                                                                        <FiEdit size={15} />
                                                                    </div>

                                                                    {chapaEditInfo?.prop === 'proposta' ? (
                                                                        <Textarea
                                                                            withoutForm
                                                                            label=""
                                                                            className={`proposta-chapa-${chapa.id}`}
                                                                            initialValue={chapa.proposta}
                                                                            onBlur={handleUpdateChapaInfo}
                                                                            onClick={() => {
                                                                                setChapaEditInfo({
                                                                                    chapa,
                                                                                    prop: 'proposta'
                                                                                })
                                                                            }}
                                                                            tip="Para salvar as alterações, basta clicar fora deste campo."
                                                                        />
                                                                    ) : (
                                                                        <p>{chapa.proposta}</p>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <CandidatosContainer className="form-busca-candidato">
                                                                <h1>Candidatos da chapa</h1>

                                                                <Textbox
                                                                    label="Nome do candidato"
                                                                    name="nome"
                                                                    onChange={e => {
                                                                        handleBuscar(e.target.value, chapa)
                                                                    }}
                                                                    className={`nome-candidato-${chapa.id}`}
                                                                />

                                                                {resultadoBusca ? resultadoBusca.length > 0 ? (
                                                                    <ul className="result-list">
                                                                        {resultadoBusca.map(result => (
                                                                            <li key={result.id} onClick={() => { handleSelecionarPessoa(result, chapa) }}>
                                                                                {result.nome}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                ) : <p className="badge">O nome informado não foi encontrado.</p> : ''}

                                                                <Select
                                                                    name="cargo"
                                                                    label="Função"
                                                                    className={`cargo-candidato-${chapa.id}`}
                                                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                                                    options={cargos?.map(c => ({
                                                                        label: c.descricao,
                                                                        value: c.id
                                                                    })) || []}
                                                                />

                                                                <Button
                                                                    onClick={() => {
                                                                        handleAddCandidato(chapa, values.cargo?.value)

                                                                        setFieldValue('cargo', null)
                                                                    }}
                                                                    className="transparent"
                                                                >
                                                                    Adicionar candidato
                                                                    <FiPlus size={16} />
                                                                </Button>
                                                            </CandidatosContainer>

                                                            <Table
                                                                headers={[
                                                                    { name: 'nome', value: 'Nome' },
                                                                    { name: 'cargo', value: 'Função', centered: true }
                                                                ]}
                                                                data={[...chapa.candidatos].sort(arrayUtils.sort.comparisonFunction2Levels('cargo', 'descricao'))?.map(c => ({
                                                                    id: c.id,
                                                                    nome: c.pessoa?.nome,
                                                                    cargo: c.cargo?.descricao,
                                                                    pessoa: c.pessoa
                                                                })) || []}
                                                                actions={[
                                                                    {
                                                                        action: setCandidatoUploadFoto,
                                                                        name: 'Enviar uma foto',
                                                                        icon: FiCamera,
                                                                        iconSize: 16
                                                                    },
                                                                    {
                                                                        action: setCandidatoUploadCurriculo,
                                                                        name: 'Enviar currículo',
                                                                        icon: FiFileText,
                                                                        iconSize: 16
                                                                    },
                                                                    {
                                                                        action: item => { handlDeleteCandidatoChapa(item, chapa) },
                                                                        icon: FiTrash,
                                                                        iconSize: 16,
                                                                        name: 'Remover candidato'
                                                                    }
                                                                ]}
                                                            />
                                                        </div>
                                                    </ChapaItemContainer>
                                                ))}
                                        </ChapaListaContainer>
                                    ) : (
                                        <p className="placeholder">
                                            <i>Nenhuma chapa foi adicionada.</i>
                                        </p>
                                    )}
                                </>
                            ) : (
                                <Spinner />
                            )}

                            <div className="button-container">
                                {cadastroChapa?.chapas?.length > 0 && (
                                    <Button
                                        className="transparent"
                                        onClick={handleAddChapa}
                                    >
                                        Nova chapa
                                        <FiPlus size={16} />
                                    </Button>
                                )}

                                <Button
                                    className="transparent"
                                    onClick={() => {
                                        setCadastroChapa(null)

                                        reloadEleicoes()
                                    }}
                                >
                                    Concluir
                                    <FiCheck size={16} />
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={!!candidatoUploadFoto}
                handleClose={() => { setCandidatoUploadFoto(null) }}
                title={`Enviar foto do candidato(a) ${candidatoUploadFoto?.nome}`}
            >
                <UploadFotoContainer>
                    <Formik
                        onSubmit={values => {
                            values.pessoa = candidatoUploadFoto.pessoa
                            values.candidato_id = candidatoUploadFoto.id

                            handleUploadFoto(values)
                        }}
                        initialValues={{ foto: null }}
                    >
                        {({ setFieldValue }) => (
                            <Form>
                                <File
                                    label="Foto"
                                    name="foto"
                                    onSuccess={fileId => setFieldValue('foto', fileId)}
                                    format="square"
                                    previewSize={['100%', '172px']}
                                    maxSize={3 * 1024 * 1024}
                                />

                                <Button type="submit" className="transparent">
                                    Enviar foto
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </UploadFotoContainer>
            </Modal>

            <Modal
                isOpen={!!candidatoUploadCurriculo}
                handleClose={() => { setCandidatoUploadCurriculo(null) }}
                title={`Enviar currículo do candidato(a) ${candidatoUploadCurriculo?.nome}`}
            >
                <UploadFotoContainer>
                    <Formik
                        onSubmit={values => {
                            values.pessoa = candidatoUploadCurriculo.pessoa
                            values.candidato_id = candidatoUploadCurriculo.id

                            handleUploadCurriculo(values)
                        }}
                        initialValues={{ curriculo: null }}
                    >
                        {({ setFieldValue }) => (
                            <Form>
                                <File
                                    label="Currículo"
                                    name="curriculo"
                                    onSuccess={fileId => setFieldValue('curriculo', fileId)}
                                    format="square"
                                    previewSize={['100%', '172px']}
                                    maxSize={3 * 1024 * 1024}
                                />

                                <Button type="submit" className="transparent">
                                    Enviar currículo
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </UploadFotoContainer>
            </Modal>

            <Modal
                isOpen={!!chapaDelete}
                handleClose={() => { setChapaDelete(null) }}
                title="Confirmação"
            >
                <DeleteChapaContainer>
                    <div className="text" dangerouslySetInnerHTML={{ __html: `Deseja realmente remover a chapa <b>${chapaDelete?.descricao}</b>?` }} />

                    <div className="button-container">
                        <Button className="transparent" onClick={() => { setChapaDelete(null) }}>
                            Cancelar
                        </Button>

                        <Button
                            className="white"
                            onClick={() => {
                                handleDeleteChapa(chapaDelete)

                                setChapaDelete(null)
                            }}
                        >
                            Remover chapa
                        </Button>
                    </div>
                </DeleteChapaContainer>
            </Modal>

            <ReactTooltip
                place="left"
                effect="solid"
                type="dark"
            />
        </>
    )
}
