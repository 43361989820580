import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik'
import { FaCheck } from 'react-icons/fa'

import Yup from '../../../services/yup'
import { api } from '../../../services/api'

import maskUtils from '../../../util/masks'

import ActionFooter from '../../ActionFooter'
import Modal from '../../Modal'
import { Button, Checkbox } from '../../Form'

import { Container, TermoContainer, ModalTermoContainer } from './styles'
import { GlobalContext } from '../../../contexts/GlobalContext'

const initialValues = {
    autorizado: false
}

const validation = Yup.object({
    autorizado: Yup.boolean()
})

export default function ({ onSubmit }) {
    const { clienteRobot } = useContext(GlobalContext)

    const [valoresAnuidade, setValoresAnuidade] = useState([])
    const [showTermoCiencia, setShowTermoCiencia] = useState(false)

    useEffect(() => {
        async function loadValores() {
            const response = await api.get('desconto_anuidade')
            
            setValoresAnuidade(response.filter(valor => new Date() >= new Date(valor.inicio_desconto) && new Date() <= new Date(valor.fim_desconto)))
        }

        loadValores()
    }, [])

    return (
        <>
            <Container>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <p style={{ margin: '24px 8px 0' }}>
                                Valor da anuidade:
                                <span style={{ fontWeight: 800 }}>
                                    {valoresAnuidade.length && valoresAnuidade[0].valor > 0 ? ` ${maskUtils.maskApply.currency(valoresAnuidade[0].valor)}` : ' -'}
                                </span>
                            </p>

                            <TermoContainer>
                                <Button onClick={() => setShowTermoCiencia(true)} className="transparent">
                                    VER TERMO DE CIÊNCIA
                                </Button>
                            </TermoContainer>

                            <Checkbox
                                name="autorizado"
                                label={`Li e concordo com o Termo de Ciência acima e autorizo a emissão do boleto da anuidade de ${new Date().getFullYear()}, no valor correspondente a ${maskUtils.maskApply.currency(valoresAnuidade[0]?.valor)}, em favor do ${clienteRobot?.name}. Estou ciente que este valor será reajustado anualmente no mês de janeiro.`}
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                containerStyle={{ gridArea: 'check' }}
                            />

                            <ActionFooter style={{ gridArea: 'button' }}>
                                <Button type="submit" loading={isSubmitting} className="white" disabled={!values.autorizado}>
                                    Concluir
                                    <FaCheck />
                                </Button>
                            </ActionFooter>
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={showTermoCiencia}
                handleClose={() => setShowTermoCiencia(false)}
                title="Termo de Ciência do Associado"
            >
                <ModalTermoContainer>
                    <iframe src={`${process.env.REACT_APP_BACKEND_URL}/resources/TERMO DE CIENCIA.pdf`} frameBorder="0" title="Termo de Ciência" />
                </ModalTermoContainer>
            </Modal>
        </>
    )
}
