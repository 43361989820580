import React, { useState, useEffect, useContext } from 'react'
import { format } from 'date-fns'
import { FiUsers } from 'react-icons/fi'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'
import {
    Button, Textbox
} from '../../../../components/Form'

import { GlobalContext } from '../../../../contexts/GlobalContext'

import Detalhes from './Detalhes'
import Cancelamento from './Cancelamento'

import { getAge } from '../../../../util/date'
import maskUtils from '../../../../util/masks'
import formUtils from '../../../../util/form'

import { api, authHeaders } from '../../../../services/api'

import avatarDefault from '../../../../assets/images/avatar.png'

import {
    Container, ModalRegularizacaoContent, ModalServicosOpcionaisContainer, ListaContainer, Lista, CardItem, DependenteQuantidadeContainer, ConfirmacaoCancelamentoContainer
} from './styles'
import useQuery from '../../../../hooks/useQuery'

export function getPortabilidadeDescricao(portabilidadeId) {
    switch (portabilidadeId) {
        case 1: return 'UNIMED Belém para UNIMED Belém'
        case 2: return 'Outras operadoras para UNIMED'
        default: return 'Sem portabilidade'
    }
}

export default function () {
    const { user } = useContext(GlobalContext)
    const { area } = useQuery()

    const [pendencias, setPendencias] = useState(null)
    const [pendenciasRegularizacao, setPendenciasRegularizacao] = useState(null)
    const [showDetalhes, setShowDetalhes] = useState(null)
    const [showRegularizarServico, setShowRegularizarServico] = useState(null)
    const [servicosAAprovar, setServicosAAprovar] = useState(null)
    const [servicosOpcionaisEmAnalise, setServicosOpcionaisEmAnalise] = useState(false)
    const [adicionaisPorBeneficiario, setAdicionaisPorBeneficiario] = useState(null)
    const [adicionaisSelecionadosParaAprovacao, setAdicionaisSelecionadosParaAprovacao] = useState([])
    const [servicosACancelar, setServicosACancelar] = useState([])
    const [cancelamentoEmAnalise, setCancelamentoEmAnalise] = useState(null)
    const [matriculasNaoRenovadas, setMatriculasNaoRenovadas] = useState(null)
    const [associadoACancelarPorNaoRenovacao, setAssociadoACancelarPorNaoRenovacao] = useState(null)
    const [loading, setLoading] = useState(false)

    function filtrarConveniosPorPerfil(convenioAssociado) {
        switch (Number(area)) {
            case 1: return convenioAssociado.convenio.convenio_area_atuacao.id === 1
            default: return convenioAssociado.convenio.convenio_area_atuacao.id !== 1
        }
    }

    async function loadConveniosAssociados() {
        setPendencias(null)

        const adesoes = await api.get('convenio_associado', {
            params: {
                status_id: [1, 3, 5]
            },
            ...authHeaders()
        })

        const status = await api.get('convenio_status', authHeaders())
        const convenioAdicionalStatus = await api.get('convenio_adicional_status')

        const convenios = []

        for (const associado of adesoes) {
            convenios.push(
                ...associado.conveniosAssociado
                    .filter(filtrarConveniosPorPerfil)
                    .map(convenioAssociado => ({
                        id: convenioAssociado.convenio.id,
                        crm: associado.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.identificador || '',
                        foto_titular: associado.pessoa.usuario.avatar?.link || avatarDefault,
                        associado_id: associado.id,
                        associado,
                        titular: {
                            id: associado.pessoa.id,
                            pessoa_id: associado.pessoa.id,
                            nome: associado.pessoa.nome,
                            parentesco: 'Titular',
                            data_nascimento: `${format(new Date(associado.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(associado.pessoa.data_nascimento)} anos)`,
                            documentos: associado.pessoa.documentos.map(doc => ({
                                tipo: doc.tipo_documento.descricao,
                                tipo_id: doc.tipo_documento.id,
                                identificador: doc.identificador,
                                link: doc.arquivo?.link || '',
                                extra: doc.extra || null
                            })),
                            aprovado: convenioAssociado.documentacao_titular_aprovada,
                            aprovado_icon: convenioAssociado.documentacao_titular_aprovada ? 'FaCheck' : 'FaTimes',
                            status: convenioAssociado.documentacao_titular_aprovada ? 'Regular' : 'Em análise',
                            portabilidade: getPortabilidadeDescricao(convenioAssociado.portabilidade),
                            iconColor: convenioAssociado.documentacao_titular_aprovada ? '#4caf50' : '#f44336'
                        },
                        nome: associado.pessoa.nome,
                        servico: convenioAssociado.convenio.empresa.nome,
                        servico_area_atuacao_id: convenioAssociado.convenio.convenio_area_atuacao.id,
                        portabilidade: getPortabilidadeDescricao(convenioAssociado.portabilidade),
                        portabilidade_id: convenioAssociado.portabilidade,
                        data_solicitacao: format(new Date(convenioAssociado.createdAt), 'dd/MM/yyyy'),
                        quantidade_dependentes: convenioAssociado.dependentes.length,
                        status_id: convenioAssociado.status,
                        status: status.find(s => s.id === convenioAssociado.status)?.descricao || '',
                        adicionais: convenioAssociado.adicionais.filter(adc => adc.status.id !== 1),
                        dependentes: convenioAssociado.dependentes.map(dep => ({
                            id: dep.id,
                            pessoa_id: dep.dependente.pessoa.id,
                            nome: dep.dependente.pessoa.nome.toUpperCase(),
                            parentesco: dep.dependente.grau_parentesco.descricao,
                            data_nascimento: `${format(new Date(dep.dependente.pessoa.data_nascimento), 'dd/MM/yyyy')} (${getAge(dep.dependente.pessoa.data_nascimento)} anos)`,
                            documentos: dep.dependente.pessoa.documentos.map(doc => ({
                                tipo: doc.tipo_documento.descricao,
                                tipo_id: doc.tipo_documento.id,
                                identificador: doc.identificador,
                                link: doc.arquivo.link,
                                extra: doc.extra ? JSON.parse(doc.extra) : null
                            })),
                            aprovado: dep.status.id === 2,
                            status: dep.status.descricao,
                            adicionais: dep.dependente.adicionais.filter(a => a.convenio_dependente_adicional.status_id !== 1).map(adc => ({
                                ...adc,
                                status: convenioAdicionalStatus.find(s => s.id === adc.convenio_dependente_adicional.status_id).descricao,
                                status_id: adc.convenio_dependente_adicional.status_id
                            })),
                            portabilidade: getPortabilidadeDescricao(dep.portabilidade)
                        }))
                    }))
            )
        }

        setPendencias(convenios.filter(conv => [1, 5].includes(conv.status_id)))
        setPendenciasRegularizacao(convenios.filter(conv => conv.status_id === 3))
    }

    async function loadPendenciasServicosOpcionais() {
        const response = await api.get('convenio_associado', {
            params: {
                status_id: 8
            },
            ...authHeaders()
        })

        const convenioStatus = await api.get('convenio_status', authHeaders())

        const convenioAdicionalStatus = await api.get('convenio_adicional_status')

        const opcionaisLista = []

        for (const assoc of response) {
            const { nome } = assoc.pessoa

            for (const convenioAssociado of assoc.conveniosAssociado) {
                opcionaisLista.push({
                    ...convenioAssociado,
                    convenio_id: convenioAssociado.convenio.id,
                    id: `${convenioAssociado.convenio.id}_${assoc.id}`,
                    associado: nome,
                    associado_id: assoc.id,
                    medico: !!assoc.pessoa.documentos.find(d => d.tipo_documento_id === 3) || false,
                    foto_titular: assoc.pessoa.usuario.avatar?.link || avatarDefault,
                    servico: convenioAssociado.convenio.empresa.nome,
                    data: format(new Date(convenioAssociado.updatedAt), 'dd/MM/yyyy'),
                    beneficiarios_quantidade: convenioAssociado.dependentes
                        .filter(dep => !!dep.dependente.adicionais
                            .filter(a => a.convenio_dependente_adicional.status_id !== 1).length).length
                        + (convenioAssociado.adicionais.filter(adc => adc.status.id !== 1).length ? 1 : 0),
                    status: convenioStatus.find(s => s.id === convenioAssociado.status).descricao,
                    adicionais: convenioAssociado.adicionais.filter(adc => adc.status.id !== 1),
                    dependentes: convenioAssociado.dependentes.map(({ dependente: d }) => ({
                        ...d,
                        adicionais: d.adicionais.filter(a => [2, 3].includes(a.convenio_dependente_adicional.status_id)).map(adc => ({
                            ...adc,
                            status: convenioAdicionalStatus.find(s => s.id === adc.convenio_dependente_adicional.status_id).descricao,
                            status_id: adc.convenio_dependente_adicional.status_id
                        }))
                    }))
                })
            }
        }

        setServicosAAprovar(opcionaisLista)
    }

    async function loadServicosACancelar() {
        const response = await api.get('convenio_associado', {
            params: {
                status_id: 9
            },
            ...authHeaders()
        })

        const convenioStatus = await api.get('convenio_status', authHeaders())

        const convenioAdicionalStatus = await api.get('convenio_adicional_status')

        const opcionaisLista = []

        for (const assoc of response) {
            const { nome, data_nascimento } = assoc.pessoa

            for (const convenioAssociado of assoc.conveniosAssociado) {
                opcionaisLista.push({
                    ...convenioAssociado,
                    convenio_id: convenioAssociado.convenio.id,
                    id: `${convenioAssociado.convenio.id}_${assoc.id}`,
                    associado: nome,
                    foto_titular: assoc.pessoa.usuario.avatar?.link || avatarDefault,
                    associado_id: assoc.id,
                    data_nascimento,
                    medico: assoc.pessoa.documentos.find(d => d.tipo_documento.id === 3) || false,
                    servico: convenioAssociado.convenio.empresa.nome,
                    data: format(new Date(convenioAssociado.updatedAt), 'dd/MM/yyyy'),
                    beneficiarios_quantidade: convenioAssociado.dependentes.length + 1,
                    status: convenioStatus.find(s => s.id === convenioAssociado.status).descricao,
                    adicionais: convenioAssociado.adicionais.filter(adc => adc.status.id === 1),
                    dependentes: convenioAssociado.dependentes.map(({ dependente: d }) => ({
                        ...d,
                        adicionais: d.adicionais.filter(a => a.convenio_dependente_adicional.status_id === 1).map(adc => ({
                            ...adc,
                            status: convenioAdicionalStatus.find(s => s.id === adc.convenio_dependente_adicional.status_id).descricao,
                            status_id: adc.convenio_dependente_adicional.status_id
                        }))
                    }))
                })
            }
        }

        setServicosACancelar(opcionaisLista)
    }

    async function loadAcademicosMatriculaVencida() {
        const response = await api.get('academico/matricula_vencida', authHeaders())

        setMatriculasNaoRenovadas(response.map(a => ({
            id: `${a.id}_${a.associado.id}`,
            associado_id: a.associado.id,
            convenio_id: a.convenio.id,
            medico: false,
            foto_titular: a.associado.pessoa.usuario.avatar?.link || avatarDefault,
            associado: a.associado.pessoa.nome,
            servico: a.convenio.empresa.nome,
            dependentes: a.convenio.dependentes,
            beneficiarios_quantidade: a.convenio.dependentes.length + 1
        })))
    }

    function reload() {
        loadConveniosAssociados()
        loadServicosACancelar()
        loadPendenciasServicosOpcionais()
        loadAcademicosMatriculaVencida()

        setShowDetalhes(null)
        setShowRegularizarServico(null)
        setServicosOpcionaisEmAnalise(null)
        setCancelamentoEmAnalise(null)
        setAssociadoACancelarPorNaoRenovacao(null)
    }

    async function handleCancelarPlanoAcademico(item) {
        try {
            setLoading(true)

            await api.delete(`convenio_associado/aprovar/${item.associado_id}/${item.convenio_id}`, authHeaders())

            toast.success(`O plano de saúde de ${item.associado} foi cancelado com sucesso.`)

            reload()
        } catch (e) {
            toast.error(e.msg)
        }

        setLoading(false)
    }

    async function handleRegularizar(values) {
        try {
            values = formUtils.extractFormValues(values)

            await api.patch(`convenio_associado/regularizar/${showRegularizarServico.id}/${showRegularizarServico.associado_id}`, values, authHeaders())

            toast.success('A solicitação de adesão foi regularizada.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleAprovarOpcionais() {
        try {
            await api.patch(`convenio_associado/aprovar_adicionais/${servicosOpcionaisEmAnalise.convenio_id}/${servicosOpcionaisEmAnalise.associado_id}`, {
                nao_aprovados: adicionaisPorBeneficiario.length - adicionaisSelecionadosParaAprovacao.length,
                adicionais: adicionaisSelecionadosParaAprovacao.map(a => ({
                    titular: !!a.titular,
                    status_id: a.status_id,
                    beneficiario_id: a.beneficiario_id,
                    adicional_id: a.adicional_id,
                    data_solicitacao: a.data_solicitacao
                }))
            }, authHeaders())

            toast.success('Os serviços opcionais foram aprovados.')

            setServicosOpcionaisEmAnalise(null)

            loadPendenciasServicosOpcionais()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if (user) {
            loadConveniosAssociados()

            loadPendenciasServicosOpcionais()

            loadServicosACancelar()

            loadAcademicosMatriculaVencida()
        }
    }, [user])

    useEffect(() => {
        if (servicosOpcionaisEmAnalise) {
            setAdicionaisPorBeneficiario([
                ...servicosOpcionaisEmAnalise?.adicionais.map(adc => ({
                    id: `${adc.associado.pessoa.id}_${adc.adicional.id}`,
                    adicional_id: adc.adicional.id,
                    beneficiario: adc.associado.pessoa.nome,
                    adicional: adc.adicional.descricao,
                    data_solicitacao: adc.created_at,
                    valor: maskUtils.maskApply.currency(adc.adicional.valor),
                    status: adc.status.descricao,
                    beneficiario_id: adc.associado.id,
                    status_id: adc.status.id,
                    titular: true
                })),
                ...servicosOpcionaisEmAnalise?.dependentes.reduce((result, dep) => [
                    ...result,
                    ...dep.adicionais.map(adc => ({
                        id: `${dep.pessoa.id}_${adc.id}`,
                        adicional_id: adc.id,
                        beneficiario: dep.pessoa.nome,
                        adicional: adc.descricao,
                        data_solicitacao: adc.created_at,
                        valor: maskUtils.maskApply.currency(adc.valor),
                        status: adc.status,
                        beneficiario_id: dep.id,
                        status_id: adc.status_id
                    }))
                ], [])
            ])
        } else {
            setAdicionaisSelecionadosParaAprovacao([])
        }
    }, [servicosOpcionaisEmAnalise])

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Pendências de Plano de Saúde</h1>

                        <ListaContainer>
                            <Lista>
                                <h1>Verificação de documentos de beneficiários</h1>
                                <h2>
                                    {pendencias?.length === undefined ? '' : pendencias?.length === 0 ? 'Nenhum associado' : `${pendencias?.length} associado(s)`}
                                </h2>

                                <div className="cards">
                                    {pendencias?.map((p, index) => (
                                        <CardItem onClick={() => { setShowDetalhes(p) }} className={`animated fadeIn delay-${100 * index}ms`}>
                                            <img src={p.foto_titular} alt="Avatar" className="avatar" />

                                            <h1>{p.nome}</h1>

                                            <span className="tipo">{p.crm ? '(médico)' : '(acadêmico)'}</span>

                                            <DependenteQuantidadeContainer>
                                                <FiUsers size={12} />
                                                <span>{`${p.dependentes.length} dependente(s)`}</span>
                                            </DependenteQuantidadeContainer>

                                            <h2>{p.servico}</h2>
                                        </CardItem>
                                    ))}
                                </div>
                            </Lista>

                            <Lista>
                                <h1>Regularização do serviço junto ao parceiro</h1>
                                <h2>
                                    {pendenciasRegularizacao?.length === undefined ? '' : pendenciasRegularizacao?.length === 0 ? 'Nenhum associado' : `${pendenciasRegularizacao?.length} associado(s)`}
                                </h2>

                                {pendenciasRegularizacao?.map((p, index) => (
                                    <CardItem onClick={() => { setShowRegularizarServico(p) }} className={`animated fadeIn delay-${100 * index}ms`}>
                                        <img src={p.foto_titular} alt="Avatar" className="avatar" />
                                        <h1>{p.nome}</h1>
                                        <span className="tipo">{p.crm ? '(médico)' : '(acadêmico)'}</span>
                                        <DependenteQuantidadeContainer>
                                            <FiUsers size={12} />
                                            <span>{`${p.dependentes.length} dependente(s)`}</span>
                                        </DependenteQuantidadeContainer>
                                        <h2>{p.servico}</h2>
                                    </CardItem>
                                ))}
                            </Lista>

                            <Lista>
                                <h1>Análise de alterações em serviços opcionais</h1>
                                <h2>
                                    {servicosAAprovar?.length === undefined ? '' : servicosAAprovar?.length === 0 ? 'Nenhum associado' : `${servicosAAprovar?.length} associado(s)`}
                                </h2>

                                {servicosAAprovar?.map((p, index) => (
                                    <CardItem onClick={() => { setServicosOpcionaisEmAnalise(p) }} className={`animated fadeIn delay-${100 * index}ms`}>
                                        <img src={p.foto_titular} alt="Avatar" className="avatar" />
                                        <h1>{p.associado}</h1>
                                        <span className="tipo">{p.medico ? '(médico)' : '(acadêmico)'}</span>
                                        <DependenteQuantidadeContainer>
                                            <FiUsers size={12} />
                                            <span>{`${p.dependentes.length} dependente(s)`}</span>
                                        </DependenteQuantidadeContainer>
                                        <h2>{p.servico}</h2>
                                    </CardItem>
                                ))}
                            </Lista>

                            <Lista>
                                <h1>Análise de cancelamento de plano de saúde</h1>
                                <h2>
                                    {servicosACancelar?.length === undefined ? '' : servicosACancelar?.length === 0 ? 'Nenhum associado' : `${servicosACancelar?.length} associado(s)`}
                                </h2>

                                {servicosACancelar?.map((p, index) => (
                                    <CardItem onClick={() => { setCancelamentoEmAnalise(p) }} className={`animated fadeIn delay-${100 * index}ms`}>
                                        <img src={p.foto_titular} alt="Avatar" className="avatar" />
                                        <h1>{p.associado}</h1>
                                        <span className="tipo">{p.medico ? '(médico)' : '(acadêmico)'}</span>
                                        <DependenteQuantidadeContainer>
                                            <FiUsers size={12} />
                                            <span>{`${p.dependentes.length} dependente(s)`}</span>
                                        </DependenteQuantidadeContainer>
                                        <h2>{p.servico}</h2>
                                    </CardItem>
                                ))}
                            </Lista>

                            <Lista>
                                <h1>Acadêmicos que não renovaram comprovante de matrícula</h1>
                                <h2>
                                    {matriculasNaoRenovadas?.length === undefined ? '' : matriculasNaoRenovadas?.length === 0 ? 'Nenhum associado' : `${matriculasNaoRenovadas?.length} associado(s)`}
                                </h2>

                                {matriculasNaoRenovadas?.map((p, index) => (
                                    <CardItem onClick={() => { setAssociadoACancelarPorNaoRenovacao(p) }} className={`animated fadeIn delay-${100 * index}ms`}>
                                        <img src={p.foto_titular} alt="Avatar" className="avatar" />
                                        <h1>{p.associado}</h1>
                                        <span className="tipo">{p.medico ? '(médico)' : '(acadêmico)'}</span>
                                        <DependenteQuantidadeContainer>
                                            <FiUsers size={12} />
                                            <span>{`${p.dependentes.length} dependente(s)`}</span>
                                        </DependenteQuantidadeContainer>
                                        <h2>{p.servico}</h2>
                                    </CardItem>
                                ))}
                            </Lista>
                        </ListaContainer>
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!showDetalhes}
                handleClose={() => { setShowDetalhes(null) }}
                title="Detalhes da Solicitação de Adesão"
            >
                <Detalhes
                    detalhes={showDetalhes}
                    reload={() => {
                        setShowDetalhes(null)

                        loadConveniosAssociados()
                    }}
                />
            </Modal>

            <Modal
                isOpen={!!showRegularizarServico}
                handleClose={() => { setShowRegularizarServico(null) }}
                title="Confirmação de regularidade do serviço"
            >
                <ModalRegularizacaoContent>
                    <Formik
                        onSubmit={handleRegularizar}
                        initialValues={showRegularizarServico?.servico_area_atuacao_id === 1 ? {
                            comprovante: null,
                            vencimento: ''
                        } : {
                            comprovante: null
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <p>Quando o parceiro confirmar a regularidade do serviço, anexe um arquivo que comprove esta regularidade e clique no botão para iniciar a cobrança pelo serviço.</p>

                                {showRegularizarServico?.servico_area_atuacao_id === 1 && (
                                    <Textbox
                                        name="vencimento"
                                        label="Data de vencimento da fatura"
                                        mask={maskUtils.date}
                                    />
                                )}

                                <Button type="submit" className="transparent" loading={isSubmitting}>
                                    Confirmar
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalRegularizacaoContent>
            </Modal>

            <Modal
                isOpen={!!servicosOpcionaisEmAnalise}
                handleClose={() => { setServicosOpcionaisEmAnalise(null) }}
                title={`Análise de serviços opcionais: ${servicosOpcionaisEmAnalise?.associado}`}
            >
                <ModalServicosOpcionaisContainer>
                    {adicionaisPorBeneficiario && (
                        <Table
                            headers={[
                                { name: 'beneficiario', value: 'Beneficiário' },
                                { name: 'adicional', value: 'Serviço solicitado', centered: true },
                                { name: 'valor', value: 'Valor', centered: true },
                                { name: 'status', value: 'Status', centered: true }
                            ]}
                            data={adicionaisPorBeneficiario}
                            checkable
                            onCheckItem={item => {
                                setAdicionaisSelecionadosParaAprovacao([
                                    ...adicionaisSelecionadosParaAprovacao,
                                    item
                                ])
                            }}
                            onUncheckItem={item => {
                                setAdicionaisSelecionadosParaAprovacao(adicionaisSelecionadosParaAprovacao.filter(adc => adc.id !== item.id))
                            }}
                            toggleCheckAll={status => {
                                if (status) {
                                    setAdicionaisSelecionadosParaAprovacao(adicionaisPorBeneficiario)
                                } else {
                                    setAdicionaisSelecionadosParaAprovacao([])
                                }
                            }}
                        />
                    )}

                    <div className="button-container">
                        <Button onClick={handleAprovarOpcionais} className="transparent" disabled={!adicionaisSelecionadosParaAprovacao.length}>
                            Aprovar serviços opcionais
                        </Button>
                    </div>
                </ModalServicosOpcionaisContainer>
            </Modal>

            <Modal
                isOpen={!!cancelamentoEmAnalise}
                handleClose={() => { setCancelamentoEmAnalise(null) }}
                title={`Análise de cancelamento de plano - ${cancelamentoEmAnalise?.servico}`}
            >
                <Cancelamento
                    detalhes={cancelamentoEmAnalise}
                    reload={() => {
                        setCancelamentoEmAnalise(null)

                        loadServicosACancelar()
                    }}
                />
            </Modal>

            <Modal
                isOpen={!!associadoACancelarPorNaoRenovacao}
                handleClose={() => { setAssociadoACancelarPorNaoRenovacao(null) }}
                title="Confirmação"
            >
                <ConfirmacaoCancelamentoContainer>
                    <p>Deseja confirmar o CANCELAMENTO do plano de saúde deste acadêmico?</p>

                    <div className="button-container">
                        <Button className="transparent" loading={loading} onClick={() => { handleCancelarPlanoAcademico(associadoACancelarPorNaoRenovacao) }}>
                            Confirmar
                        </Button>
                    </div>
                </ConfirmacaoCancelamentoContainer>
            </Modal>
        </>
    )
}
