import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FaCheck } from 'react-icons/fa'

import Yup from '../../../services/yup'
import { api } from '../../../services/api'

import ActionFooter from '../../ActionFooter'
import {
    Button, Textbox, Select, Checkbox
} from '../../Form'
import Modal from '../../Modal'

import { Container, TermoContainer, ModalTermoContainer } from './styles'
import { GlobalContext } from '../../../contexts/GlobalContext'

const initialValues = {
    instituicao: '',
    matricula: '',
    autorizado: false
}

const validation = Yup.object({
    instituicao: Yup.string().required('Selecione a instituição.'),
    matricula: Yup.string().required('Digite a matrícula.'),
    autorizado: Yup.boolean()
})

export default function ({ onSubmit, valorMensalidade }) {
    const { clienteRobot } = useContext(GlobalContext)

    const [instituicoes, setInstituicoes] = useState([])
    const [showTermoCiencia, setShowTermoCiencia] = useState(false)

    useEffect(() => {
        async function loadInstituicoes() {
            const response = await api.get('instituicao')

            setInstituicoes(response.filter(instituicao => instituicao.desconto && !['banco', 'microempresa'].includes(instituicao.tipo_instituicao.descricao) && (instituicao.id !== 13636)))
        }

        loadInstituicoes()
    }, [])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                        <Select
                            name="instituicao"
                            label="Instituicao"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={instituicoes.map(instituicao => ({
                                label: instituicao.nome_fantasia,
                                value: instituicao.id
                            }))}
                            containerClass="instituicao-container"
                        />

                        <Textbox
                            label="Matrícula"
                            name="matricula"
                        />

                        <TermoContainer>
                            <Button onClick={() => setShowTermoCiencia(true)} className="transparent">
                                VER TERMO DE CIÊNCIA
                            </Button>
                        </TermoContainer>

                        <Checkbox
                            name="autorizado"
                            label={`Li e concordo com o Termo de Ciência acima e autorizo o desconto da mensalidade através de débito em meu contra-cheque, no dia 10 de cada mês, com valor correspondente a R$ ${valorMensalidade.toFixed(2).replace('.', ',')} em favor do ${clienteRobot?.name}. Estou ciente que este valor será reajustado anualmente no mês de janeiro.`}
                            handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            containerStyle={{ gridArea: 'check' }}
                        />

                        <ActionFooter>
                            <Button type="submit" loading={isSubmitting} className="white" disabled={!values.autorizado}>
                                Concluir
                                <FaCheck />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>

            <Modal
                isOpen={showTermoCiencia}
                handleClose={() => setShowTermoCiencia(false)}
                title="Termo de Ciência do Associado"
            >
                <ModalTermoContainer>
                    <iframe src={`${process.env.REACT_APP_BACKEND_URL}/resources/TERMO DE CIENCIA.pdf`} frameBorder="0" title="Termo de Ciência" />
                </ModalTermoContainer>
            </Modal>
        </Container>
    )
}
