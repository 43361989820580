import React, { useState, useContext, useEffect } from 'react'
import { Editor } from 'primereact/editor'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../../../../services/api'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import { Button, Textbox, Select } from '../../../../components/Form'

import { GlobalContext } from '../../../../contexts/GlobalContext'

import { Container, ButtonContainer } from './styles'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

const attrs = ['crm', 'nome', 'data_nascimento']

export default function () {
    const { user } = useContext(GlobalContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [nome, setNome] = useState('')
    const [grupo, setGrupo] = useState(null)
    const [texto, setTexto] = useState('')
    const [rules, setRules] = useState(null)

    async function loadRules() {
        try {
            const response = await api.get('mensageria/template', authHeaders())

            setRules(response)
        } catch(e) {
            toast.error('Houve um problema ao carregar as mensagens predefinidas.')
        }
    }

    async function handleSubmit() {
        try {
            await api.post('mensageria/cadastro', {
                nome, 
                grupo: grupo.value,
                texto
            }, authHeaders())

            toast.success('Mensagem cadastrada!')

            setNome('')
            setTexto('')
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if(user) {
            loadRules()
        }
    }, [user])

    useEffect(() => {
        loadTips(setCodigo, 'form_cadastro_mensagem')
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Cadastro de mensagens</h1>

                    <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                    <Textbox 
                        withoutForm
                        id="titulo"
                        label="Título da mensagem"
                        onChange={e => { setNome(e.target.value) }}
                        value={nome}
                    />

                    <Select 
                        withoutForm
                        id="finalidade"
                        label="Finalidade"
                        onChange={setGrupo}
                        value={grupo}
                        options={[
                            { label: 'Comunicados gerais', value: 'geral' },
                            { label: 'Notícias', value: 'noticia' }
                        ]}
                    />

                    <span>
                        Alguns atributos podem ser utilizados para personalizar a mensagem.
                        <br />
                        {`Atributos permitidos: ${attrs.join(', ')}`}
                        <br />
                        Para utilizá-los, basta envolvê-lo entre 
                        {' '}
                        <b>#</b>
                        , dessa forma:
                        {' '}
                        <b>#nome#</b>
                        .
                    </span>

                    <Editor 
                        style={{ height: 300 }}
                        className="editor-container"
                        value={texto} 
                        onTextChange={(e) => setTexto(e.htmlValue)}
                    />

                    <ButtonContainer>
                        <Button onClick={handleSubmit} className="white">
                            Cadastrar mensagem
                        </Button>
                    </ButtonContainer>

                    {rules ? (
                        <Table 
                            headers={[
                                { name: 'name', value: 'Mensagem (mais recentes no topo)' }
                            ]}
                            data={rules}
                        />
                    ) : (
                        <Spinner label="Carregando mensagens..." containerStyle={{ gridArea: 'tabela' }} />
                    )}

                </Card>
            </main>
        </Container>
    )
}
