import styled from 'styled-components'

export const Container = styled.article`
	display: flex;
	justify-content: center;
	align-items: center;

	.spinner-container {
		margin: 60px 0 30px;
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;
	}

	@media(max-width: 500px) {
		.table-container {
			display: none;
		}
	}
`
