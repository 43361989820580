import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	grid-template-rows: repeat(7, auto);
	grid-gap: 10px;
	margin: 5px 8px;
	font-size: 15px;

	.descricao {
		grid-column: 1/span 2;
		margin-top: 12px;
	}

	.table-container {
		grid-column: 1/span 2;
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`
