import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	> button { 
		margin-top: 14px;
	}

	p {
		margin: 8px;
	}

	.button-container {
		width: 100%;
		display: flex;
		justify-content: end;
	}

	form {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: flex-end;

		> button {
			width: fit-content;
		}

		@media(max-width: 800px) {
			grid-template-columns: 1fr 1fr;

			> button {
				grid-column: 2;
				justify-self: flex-end;
				margin-top: 12px;
			}
		}

		@media(max-width: 600px) {
			display: flex;
			flex-direction: column;	

			> button {
				width: calc(100% - 16px);
			}
		}
	}
`

export const ModalCadastroContainer = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		> button {
			justify-self: flex-end;
			grid-column: 3;
		}
	}
`

export const DocumentosContainer = styled.div`
	grid-column: 1/span 3;
	display: grid;
	margin-bottom: 20px;
	grid-template-columns: 1.4fr 1fr 1.6fr;
	grid-template-rows: 50px 70px auto;
	grid-template-areas: 
		'title title title'
		'anexo tipo table'
		'anexo button table';

	> h1 {
		grid-area: title;
		align-self: flex-end;
	}

	.select {
		grid-area: tipo;
	}

	.file {
		grid-area: anexo;
		align-self: flex-start;
	}

	.table-container {
		grid-area: table;
		width: calc(100% - 8px);
		justify-self: flex-end;
		margin-top: 24px;
	}

	> button {
		grid-area: button;
		align-self: flex-end;
	}
`
