import React from 'react'
import { endOfMonth, startOfMonth } from 'date-fns'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { FiFileText } from 'react-icons/fi'

import { Container } from './styles'
import { api, authHeaders } from '../../../../../services/api'
import Yup from '../../../../../services/yup'
import {
    Button, Calendar, Checkbox
} from '../../../../../components/Form'
import formUtils from '../../../../../util/form'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'
import { forceDownloadFileFromURL } from '../../../../../util/file'

const today = new Date()

const validation = Yup.object({
    periodo: Yup.array().of(
        Yup.date().typeError('Selecione a data inicial e a final, em seguida.').required()
    ).min(2, 'Selecione a data inicial e a final, em seguida.')
})

const initialValues = {
    periodo: [
        startOfMonth(today),
        endOfMonth(today)
    ],
    por_demandante: true
}

export default function () {
    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)

            const { periodo, por_demandante } = values
            const [inicio, fim] = periodo

            const response = await api.get('processo/relatorio/geral', {
                params: {
                    inicio,
                    fim,
                    por_demandante: por_demandante ? 1 : 0
                },
                ...authHeaders()
            })

            forceDownloadFileFromURL(response.link)

            toast.success('Relatório gerado com sucesso.')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <h1>Relatório geral de chamados</h1>

                        <Calendar
                            name="periodo"
                            yearNavigator
                            selectionMode="range"
                            label="Período de busca (início e fim)"
                        // tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Checkbox
                            name="por_demandante"
                            label="Mostrar chamados por solicitante"
                            handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                        />

                        <div className="button-container">
                            <Button type="submit" className="transparent" loading={isSubmitting}>
                                Gerar relatório
                                <FiFileText size={18} />
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
