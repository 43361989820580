import styled from 'styled-components'

export const Container = styled.div`
	
`

export const OperacaoDetalheContainer = styled.div`
	padding: 8px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0 32px;
	grid-template-areas: 
		'descricao acao'
		'tipo acao'
		'autorizadores documentos';

	> h1 {
		grid-area: descricao;
		font-size: 18px;
		margin: 12px 0;
	}

	.acao-container {
		grid-area: acao;
		display: flex;
		justify-content: end;
	}

	.tipo {
		grid-area: tipo;
	}
`

export const DocumentosContainer = styled.div`
	display: flex;
	flex-direction: column;

	iframe, img {
		width: 100%;
		height: 80vh;
	}
`
