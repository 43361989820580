import { getDate } from './date'

Array.prototype.last = function () {
    return this[this.length - 1]
}

Array.prototype.first = function () {
    return this.length ? this[0] : undefined
}

function isDate(value) {
    return /\d{2}\/\d{2}\/\d{4}( \d{2}:\d{2}(:\d{2})?)?/g.test(value) 
        || /\d{4}-\d{2}-\d{2}( \d{2}:\d{2}(:\d{2})?)?/g.test(value)
}

function comparisonFunction(attrName, sortDirection = 'asc') {
    return (a, b) => {
        let A
        let B

        if(isDate(a[attrName]) && isDate(b[attrName])) {
            A = new Date(getDate(a[attrName]))
            B = new Date(getDate(b[attrName]))
        } else {
            A = a[attrName]
            B = b[attrName]
        }

        if(A > B) {
            return sortDirection === 'asc' ? 1 : -1
        }

        if(A < B) {
            return sortDirection === 'asc' ? -1 : 1
        }
            
        return 0
    }
}

function comparisonFunction2Levels(level1Attr, level2Attr, sortDirection = 'asc') {
    return (a, b) => {
        let A
        let B

        if(isDate(a[level1Attr][level2Attr]) && isDate(b[level1Attr][level2Attr])) {
            A = new Date(getDate(a[level1Attr][level2Attr]))
            B = new Date(getDate(b[level1Attr][level2Attr]))
        } else {
            A = a[level1Attr][level2Attr]
            B = b[level1Attr][level2Attr]
        }

        if(A > B) {
            return sortDirection === 'asc' ? 1 : -1
        }

        if(A < B) {
            return sortDirection === 'asc' ? -1 : 1
        }
            
        return 0
    }
}

export default {
    sort: {
        comparisonFunction,
        comparisonFunction2Levels
    },

    get: {
        getLast: array => {
            if(!array?.length) {
                return null
            }

            return array[array.length - 1]
        }
    },

    merge: {
        mergeWithoutDuplicates: (original, newArray) => {
            const merged = [...original, ...newArray]
            return Array.from(new Set([...merged]))
        }
    }
}

