import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik'
import Yup from '../../../../../services/yup'

import { ExtratoContext } from '../../../../../contexts/ExtratoContext'

import { api, authHeaders } from '../../../../../services/api'

import masks from '../../../../../util/masks'

import {
    Textbox, Button, Select, Checkbox, Textarea, Calendar 
} from '../../../../../components/Form'
import Modal from '../../../../../components/Modal'

import { Container } from './styles'

const baseValidation = Yup.object().shape({
    evento: Yup.string().ensure().required('Especifique o evento.'),
    conta_corrente: Yup.string().ensure().required('Especifique a conta corrente.'),
    competencia: Yup.string().required('Especifique o mês de competência.'),
    data_consolidacao: Yup.string().required('Especifique a data.'),
    valor: Yup.string().money('Complete com os centavos.').required('Especifique o valor do lançamento.'),
    historico: Yup.string().notRequired(),
    caixa: Yup.boolean(),
    fornecedor_pendencia: Yup.boolean(),
    fornecedor_pendencia_observacao: Yup.string(),
    forma_pagamento: Yup.string(),
    fornecedor: Yup.string().ensure()
})

export default function () {
    const {
        editId, setEditId, initialValues, handleAdd 
    } = useContext(ExtratoContext)

    const [fornecedores, setFornecedores] = useState([])
    const [eventos, setEventos] = useState([])
    const [contas, setContas] = useState([])
    const [pendencia, setPendencia] = useState(false)
    
    async function loadFornecedores() {
        const response = await api.get('fornecedor', authHeaders()) 

        setFornecedores(response)
    }

    async function loadEventos() {
        const response = await api.get('evento_contabil', authHeaders()) 

        setEventos(response)
    }

    async function loadContas() {
        const response = await api.get('conta_sindicato', authHeaders())

        setContas(response)
    }

    useEffect(() => {
        loadFornecedores()
        loadEventos()
        loadContas()
    }, [])

    useEffect(() => {
        setPendencia(!!initialValues?.fornecedor_pendencia)
    }, [initialValues])

    return (
        <Modal
            isOpen={!!editId}
            handleClose={() => { setEditId(null) }}
            title="Edição de extrato"
        >
            <Container className="animated fadeIn faster">
                <Formik
                    initialValues={initialValues}
                    validationSchema={baseValidation}
                    onSubmit={handleAdd}
                    enableReinitialize
                >
                    {({
                        isSubmitting, setFieldValue 
                    }) => (
                        <Form style={{ marginBottom: 30 }}>
                            <Select 
                                name="fornecedor"
                                label="Fornecedor"
                                onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                options={fornecedores.map(({ id, pessoa }) => ({
                                    label: pessoa.nome,
                                    value: id
                                }))}
                                containerStyle={{ gridArea: 'f1' }}
                            />

                            <Select 
                                name="evento"
                                label="Evento"
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)
                                }}
                                options={eventos.map(({ id, nome, conta_contabil }) => ({
                                    label: `${nome}${conta_contabil?.codigo_reduzido ? ` - ${conta_contabil.codigo_reduzido}` : ''}`,
                                    value: id
                                }))}
                                containerStyle={{ gridArea: 'f2' }}
                            />

                            <Select 
                                name="conta_corrente"
                                label="Conta corrente"
                                onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                options={contas.map(({ id, dados_bancarios }) => ({
                                    label: `${dados_bancarios.instituicao.sigla} - AG ${dados_bancarios.agencia} C/C ${dados_bancarios.conta}`,
                                    value: id
                                }))}
                                containerStyle={{ gridArea: 'f3' }}
                            />

                            <Textbox 
                                label="Forma de pagamento" 
                                name="forma_pagamento"
                                containerStyle={{ gridArea: 'f4' }}
                            />

                            <Calendar 
                                name="competencia" 
                                dateFormat="mm/yy" 
                                yearNavigator 
                                view="month"
                                label="Competência"
                                containerStyle={{ gridArea: 'f5' }}
                            />
                         
                            <Calendar 
                                name="data_consolidacao" 
                                label="Data"
                                containerStyle={{ gridArea: 'f6' }}
                            />

                            <Textbox 
                                label="Valor" 
                                name="valor"
                                mask={masks.money}
                                containerStyle={{ gridArea: 'f7' }}
                            />

                            <Textarea 
                                label="Histórico" 
                                name="historico"
                                containerStyle={{ gridArea: 'f8' }}
                            />

                            <div style={{ gridArea: 'f9' }}>
                                <Checkbox 
                                    name="caixa" 
                                    label="Movimento de caixa" 
                                    handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                />

                                <Checkbox 
                                    name="fornecedor_pendencia" 
                                    label="Pendência fornecedor" 
                                    handleChange={e => { 
                                        setFieldValue(e.target.name, e.target.checked) 
                                        setPendencia(e.target.checked)
                                    }}
                                />
                            </div>

                            {pendencia && (
                                <Textarea 
                                    name="fornecedor_pendencia_observacao"
                                    label="Observação"
                                    containerStyle={{ gridArea: 'f10' }}
                                    containerClass="animated fadeIn"
                                />
                            )}

                            <Button 
                                type="submit" 
                                loading={isSubmitting} 
                                className="white" 
                                style={{ gridArea: 'bs' }}
                            >
                                {editId ? 'Salvar alterações' : 'Cadastrar lançamento'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Modal>
    )
}
