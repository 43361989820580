import React, { useState, useEffect, useContext } from 'react'
import {
    Tab, Tabs, TabList, TabPanel
} from 'react-tabs'

import AssociacaoProvider from '../../contexts/AssociacaoContext'

import scrollUtils from '../../util/scroll'

import Card from '../../components/Card'

import { Container, ImageSelect } from './styles'

import categoria1 from '../../assets/images/doctor.png'
import categoria2 from '../../assets/images/engineer.png'
import { TipContext } from '../../contexts/TipContext'
import { loadTips } from '../../util/tip'
import { Pessoal } from './Etapas/Pessoal'
import { Contato } from './Etapas/Contato'
import { Endereco } from './Etapas/Endereco'
import { Financeiro } from './Etapas/Financeiro'

const categorias = [
    {
        identificador: 'categoria1',
        nome: 'Categoria 1',
        imagem: categoria1
    },
    {
        identificador: 'categoria2',
        nome: 'Categoria 2',
        imagem: categoria2
    }
]

const etapas = [
    {
        posicao: 0,
        label: '1 - Dados pessoais',
        Componente: Pessoal
    },
    {
        posicao: 1,
        label: '2 - Contatos',
        Componente: Contato
    },
    {
        posicao: 2,
        label: '3 - Endereço',
        Componente: Endereco
    },
    {
        posicao: 3,
        label: '4 - Financeiro',
        Componente: Financeiro
    }
]

export default function () {
    const { setCodigo } = useContext(TipContext)

    const [tabsStatus, setTabsStatus] = useState([true, false, false, false])
    const [indiceTabAtiva, setIndiceTabAtiva] = useState(0)
    const [categoria, setCategoria] = useState(null)

    useEffect(() => {
        localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_DADOS_ASSOCIACAO)

        loadTips(setCodigo, 'form_associacao')
    }, [])

    useEffect(() => {
        setTabsStatus([true, false, false, false])
        setIndiceTabAtiva(0)
    }, [categoria])

    function handleCategoriaChange(value) {
        setCategoria(value)
        scrollUtils.toElement('nav')

        localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_DADOS_ASSOCIACAO, JSON.stringify({
            academico: value === 'academico'
        }))
    }

    const categoriaSelecionada = categorias.find(cat => cat.identificador === categoria)

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    {!categoriaSelecionada ? (
                        <h1>Selecione sua categoria</h1>
                    ) : (
                        <h1>
                            {categoriaSelecionada.nome}
                        </h1>
                    )}

                    <ImageSelect academico={categoria}>
                        {categorias.map(cat => (
                            <div className="categoria">
                                <img
                                    src={cat.imagem}
                                    alt=""
                                    onClick={() => { handleCategoriaChange(cat.identificador) }}
                                />
    
                                <span>{cat.nome}</span>
                            </div>
                        ))}
                    </ImageSelect>

                    {!categoriaSelecionada ? null : (
                        <Tabs
                            selectedIndex={indiceTabAtiva}
                            onSelect={indiceTab => setIndiceTabAtiva(indiceTab)}
                        >
                            <TabList>
                                {etapas.map(etapa => (
                                    <Tab 
                                        key={etapa.posicao} 
                                        disabled={etapa.posicao > 1 && !tabsStatus[etapa.posicao]}
                                    >
                                        {etapa.label}
                                    </Tab>
                                ))}
                            </TabList>

                            <AssociacaoProvider 
                                avancarEtapa={() => {
                                    setIndiceTabAtiva(old => old === tabsStatus.length - 1 ? old : old + 1)
                                }}
                            >
                                {etapas.map(etapa => (
                                    <TabPanel key={etapa.posicao}>
                                        <etapa.Componente />
                                    </TabPanel>
                                ))}
                            </AssociacaoProvider>
                        </Tabs>
                    )}
                </Card>
            </main>
        </Container>
    )
}
