import React, { useContext } from 'react'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { Button } from '../../../../../components/Form'

import Table from '../../../../../components/Table'
import { ProcessoContext, perfisAssociado } from '../../../../../contexts/ProcessoContext'

import { Container } from './styles'
import { GlobalContext } from '../../../../../contexts/GlobalContext'

export default function () {
    const { user } = useContext(GlobalContext)
    const {
        processo, alertas, handleRemoverAlerta, setShowFormAlerta 
    } = useContext(ProcessoContext)
	
    if(perfisAssociado?.includes(user?.perfil.id) || !alertas || !alertas.length || !user || !processo) {
        return null
    }

    return (
        <Container>
            <h1>Alertas personalizados</h1>

            <Button 
                onClick={() => {
                    setShowFormAlerta(true)
                }}
                className="transparent"
            >
                Criar alerta
                <FiPlus size={16} />
            </Button>
        
            <Table 
                headers={[
                    { name: 'titulo', value: 'Título' },
                    {
                        name: 'prazo', value: 'Prazo', centered: true, style: { width: 240 } 
                    },
                    { name: 'nome', value: 'Criado por', centered: true },
                    { name: 'emitido_descricao', value: 'Emitido', centered: true }
                ]}
                data={alertas}
                actions={[
                    {
                        action: handleRemoverAlerta,
                        name: 'Remover',
                        icon: FiTrash,
                        iconSize: 16,
                        checkDisabled: alerta => processo.status_id === 2 || alerta.pessoa_id !== user.pessoa.id,
                        disabledTooltip: item => `Não é possível remover este alerta, pois foi criado por ${item.nome}.`,
                        confirmation: 'Deseja realmente excluir o alerta?'
                    }
                ]}
                hideOnSmallHeaders={['nome', 'emitido_descricao']}
                hideHeadersOffset={600}
                hideOffset={315}
            />
        </Container>
    )
}
