import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'

import { Textbox, Button } from '../../../../components/Form'

import { api, authHeaders } from '../../../../services/api'

import { Container, FormContainer } from './styles'
import { TipContext } from '../../../../contexts/TipContext'

const baseInitialValues = {
    descricao: ''
}

export default function ({ edicao, reload }) {
    const { tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function handleAdd(values) {
        await api.post('contrato_area', values, authHeaders())
    }

    async function handleEdit(values) {
        await api.put(`contrato_area/${edicao.id}`, values, authHeaders())
    }

    async function handleSubmit(values) {
        try {
            if (edicao) {
                await handleEdit(values)
            } else {
                await handleAdd(values)
            }

            toast.success(`Área de contrato ${edicao ? 'alterada' : 'cadastrada'} com sucesso.`)

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }
	
    useEffect(() => {
        if (edicao) {
            setInitialValues(edicao)
        }
    }, [edicao])
	
    return (
        <Container>
            <FormContainer>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                >
                    {() => (
                        <Form>
                            <p style={{ margin: 8 }}>{tips?.formulario}</p>

                            <Textbox
                                name="descricao"
                                label="Descrição"
                            />

                            <Button type="submit" className="transparent">
                                {edicao ? 'Salvar alterações' : 'Cadastrar'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </Container>
    )
}
