import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { FaUsers } from 'react-icons/fa'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import Modal from '../../../../components/Modal'

import { api, authHeaders } from '../../../../services/api'

import { getDateObject } from '../../../../util/date'

import { Container, ChapaContainer } from './styles'

export default function () {
    const [eleicoes, setEleicoes] = useState(null)
    const [chapa, setChapa] = useState(null)
    const [cargos, setCargos] = useState(null)

    async function loadEleicoes() {
        const response = await api.get('eleicao', {
            params: {
                divulgadas: 1
            },
            ...authHeaders()
        })

        setEleicoes(response.map(r => ({
            ...r,
            fim: format(getDateObject(r.periodo_inicio), 'dd/MM/yyyy'),
            vencedora: r.chapa_vencedora.descricao,
            data_resultado: format(getDateObject(r.data_divulgacao_resultado), 'dd/MM/yyyy')
        })))
    }

    async function loadCargos() {
        const response = await api.get('eleicao/cargos', authHeaders())

        setCargos(response)
    }

    function showMembrosChapa(chapaVencedora) {
        setChapa({
            id: chapaVencedora.id,
            descricao: chapaVencedora.descricao,
            candidatos: chapaVencedora.candidatos.map(cand => ({
                id: cand.pessoa.id,
                nome: cand.pessoa.nome,
                cargo_id: cand.cargo.id
            })) || []
        })
    }

    useEffect(() => {
        loadEleicoes()
    }, [])

    useEffect(() => {
        if(eleicoes) {
            loadCargos()
        }
    }, [eleicoes])

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Resultados das eleições</h1>

                        {eleicoes ? (
                            <Table 
                                headers={[
                                    { name: 'descricao', value: 'Eleição' },
                                    { name: 'data_resultado', value: 'Término', centered: true },
                                    { name: 'vencedora', value: 'Chapa eleita', centered: true },
                                    { name: 'votos_primeiro_lugar', value: 'Quant. votos', centered: true }
                                ]}
                                data={eleicoes || []}
                                actions={[
                                    {
                                        action: item => {
                                            showMembrosChapa(item.chapa_vencedora)
                                        },
                                        name: 'Membros da chapa',
                                        icon: FaUsers,
                                        iconSize: 16,
                                        checkDisabled: item => !item.chapa_vencedora
                                    }
                                ]}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!chapa}
                handleClose={() => { setChapa(null) }}
                title="Membros da chapa"
            >
                <ChapaContainer>
                    <h1 dangerouslySetInnerHTML={{ __html: `Chapa: <b>${chapa?.descricao}</b>` }} />

                    {cargos?.map(cargo => {
                        const cargosUsados = chapa?.candidatos.map(cand => cand.cargo_id)

                        if(cargosUsados?.includes(cargo.id)) {
                            return (
                                <div>
                                    <h2>{cargo.descricao}</h2>
    
                                    <ul>
                                        {chapa?.candidatos.filter(cand => cand.cargo_id === cargo.id)
                                            .map(cand => (
                                                <li key={cand.id}>{cand.nome}</li>
                                            ))}
                                    </ul>
                                </div>
                            )
                        }
                    })}
                </ChapaContainer>
            </Modal>
        </>
    )
}
