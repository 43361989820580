import React, {
    useState, useEffect, useContext
} from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { api, authHeaders } from '../../../services/api'

import Card from '../../../components/Card'
import { Button, List } from '../../../components/Form'

import scrollUtils from '../../../util/scroll'

import relatorioComponents from './reports'

import { Container } from './styles'

import { GlobalContext } from '../../../contexts/GlobalContext'

export default function ({ match }) {
    const { user } = useContext(GlobalContext)
    const history = useHistory()

    const [form, setForm] = useState(null)
    const [relatorios, setRelatorios] = useState([])

    const { grupoId } = match.params

    async function loadRelatorios() {
        const response = await api.get('relatorio', {
            params: { grupo_relatorio_id: grupoId },
            ...authHeaders()
        })

        const permitidos = user.perfil.id === 17 ? response.filter(resp => resp.id !== 30 && resp.id !== 27) : response

        setRelatorios(permitidos.reduce((result, current, index) => ({
            ...result,
            [current.identificador]: {
                nome: current.nome,
                component: relatorioComponents[current.identificador],
                position: index
            }
        }), {}))
    }

    useEffect(() => {
        if (user) {
            loadRelatorios()
        }
    }, [user])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <div className="title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1>Relatórios</h1>

                        <Button className="white" onClick={() => { history.goBack() }} style={{ gap: 10 }}>
                            <FiArrowLeft size={16} style={{ marginLeft: 0 }} />
                            Voltar
                        </Button>
                    </div>

                    <List
                        options={Object.entries(relatorios).map(([key, formulario]) => ({
                            label: formulario.nome,
                            value: key
                        }))}
                        onChange={e => {
                            setForm(e.value)

                            if (window.innerWidth <= 900) {
                                scrollUtils.toElement('article.form')
                            }
                        }}
                        containerStyle={{ gridArea: 'list' }}
                        filter
                        selectedIndex={form && relatorios[form]?.position}
                        filterPlaceholder="Buscar por nome"
                    />

                    {form && React.createElement(relatorioComponents[form], { style: { gridArea: 'form' } })}

                </Card>
            </main>
        </Container>
    )
}
