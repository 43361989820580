import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			min-height: 74vh;
		}

		h1 {
			margin-bottom: 20px;
		}

		h2 {
			font-size: 16px;
			font-weight: 600;
			border-bottom: solid 1px #fff;
			padding: 8px 0;
			margin: 14px 8px 12px;
			height: fit-content;
			grid-column: 1/span 4;
		}

		.button-container {
			display: flex;
			justify-content: center;
			width: 100%;
		}
	}
`
