import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Card from '../../../../components/Card'
import { Button, Textarea } from '../../../../components/Form'
import RatingStarts from '../../../../components/RatingStarts'
import Spinner from '../../../../components/Spinner'

import { api } from '../../../../services/api'

import { Container } from './styles'

import syndi from '../../../../assets/images/syndi.png'

export default function () {
    const { token } = useParams()

    const [visita, setVisita] = useState(null)
    const [alreadyRated, setAlreadyRated] = useState(false)
    const [avaliado, setAvaliado] = useState(false)

    async function loadVisita() {
        const response = await api.get(`visita/${token}`)

        if(response.avaliacao) {
            setAlreadyRated(true)
        }

        setVisita(response)
    }

    async function handleSubmit(values) {
        try {
            const [rating] = [...document.querySelectorAll('input[name=rating]')]
                .map(r => ({
                    id: r.id,
                    value: r.checked
                }))
                .filter(r => r.value)

            await api.post(`visita/feedback/${token}`, {
                ...values,
                rating: Number(rating.id.split('-')[1])
            })

            toast.success('Obrigado pelo seu feedback! Conte conosco!')

            setAvaliado(true)
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if(token) {
            loadVisita()
        }
    }, [token])

    return (
        <Container>
            <main>
                <Card>
                    <h1>Visita ao SINDMEPA</h1>

                    <img src={syndi} alt="Syndi" />

                    {!visita ? (
                        <Spinner />
                    ) : alreadyRated ? (
                        <div className="balloon">
                            <p>Esta visita já foi avaliada. Obrigada!</p>
                        </div>
                    ) : avaliado ? (
                        <div className="balloon">
                            <p>Agradecemos sua avaliação!</p>
                        </div>
                    ) : (
                        <>
                            <div className="balloon">
                                {visita ? (
                                    <>
                                        <p>{`Olá, ${visita?.pessoa.nome}, obrigada pela sua visita. Eu gostaria de contar com sua opinião sobre o nosso atendimento.`}</p>
                                        <p>Por favor, qualifique a visita de 1 a 5 estrelas.</p>
                                    </>
                                ) : (
                                    <p>Aguarde...</p>
                                )}
                            </div>

                            {visita && (
                                <Formik
                                    initialValues={{ observacao: '' }}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <RatingStarts />

                                            <Textarea 
                                                name="observacao"
                                                label="Digite seu feedback"
                                            />

                                            <Button type="submit" className="transparent" loading={isSubmitting}>
                                                Enviar feedback
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </>
                    )}
                </Card>
            </main>
        </Container>
    )
}
