import * as yup from 'yup'
import { cpf } from 'cpf-cnpj-validator'
import { format } from 'date-fns'

import {
    dateValidate, isEarlier, isLater, getDateObject
} from '../util/date'

import regex from '../util/regex'

const { Iugu } = window

yup.addMethod(yup.string, 'creditCard', function () {
    return this.test('card-number-test', 'O número do cartão não é válido.', value => value ? Iugu.utils.validateCreditCardNumber(value.replace(/_/, '')) : true)
})

yup.addMethod(yup.string, 'money', function (message) {
    return this.matches(regex.money, message || 'Este valor não é válido.')
})

yup.addMethod(yup.string, 'percent', function (message) {
    return this.matches(regex.percent, message || 'Percentual inválido. Use o formato 0%.')
})

yup.addMethod(yup.string, 'cep', function (message) {
    return this.matches(regex.cep, message || 'Este CEP é inválido.')
})

yup.addMethod(yup.string, 'cpf', function (message) {
    return this.test('cpf-test', message || 'Este CPF não é válido.', value => value ? cpf.isValid(value.replace(/[.-]/g, '')) : true)
})

yup.addMethod(yup.string, 'cnpj', function (message) {
    return this.matches(regex.cnpj, message || 'Este CNPJ é inválido.')
})

yup.addMethod(yup.string, 'site', function (message) {
    return this.matches(regex.url, message || 'Endereço inválido.')
})

yup.addMethod(yup.string, 'shortDate', function (message) {
    return this.matches(regex.shortDate, message || 'Esta data é inválida.')
})

yup.addMethod(yup.string, 'date', function (restrictions, message) {
    return this.test(
        'date-validator',
        message,
        function (value) {
            const { createError, parent } = this

            const minDate = !restrictions?.min ? null : restrictions.min.value?.key
                ? (parent[restrictions.min.value.key] ? getDateObject(parent[restrictions.min.value.key]) : restrictions?.min)
                : restrictions.min.value
            const maxDate = !restrictions?.max ? null : restrictions.max.value?.key
                ? (parent[restrictions.max.value.key] ? getDateObject(parent[restrictions.max.value.key]) : restrictions?.max)
                : restrictions.max.value
            const required = !!restrictions?.required

            if (required && !dateValidate(value)) {
                return createError({
                    message: message || 'Esta data não é válida.'
                })
            }

            const date = getDateObject(value)

            if (minDate && date && isEarlier(
                date,
                minDate,
                true
            )) {
                return createError({
                    message: restrictions.min?.message || `A data deve ser posterior a ${format(minDate, 'dd/MM/yyyy HH:mm\'h\'')}.`
                })
            }

            if (maxDate && date && isLater(
                date,
                maxDate,
                true
            )) {
                return createError({
                    message: restrictions.max?.message || `A data deve ser anterior a ${format(maxDate, 'dd/MM/yyyy HH:mm\'h\'')}.`
                })
            }

            return true
        }
    )
})

yup.addMethod(yup.string, 'future', function () {
    return this.test('date-future-test', 'A data deve estar no futuro.', value => {
        // return Iugu.utils.validateExpirationString(value)

        const [mes, ano] = value.split('/')
        const anoAtual = new Date().getFullYear()
        const mesAtual = new Date().getMonth()

        return new Date(ano, mes - 1) >= new Date(anoAtual, mesAtual)
    })
})

yup.addMethod(yup.string, 'mobile', function (message) {
    return this.matches(regex.mobile, message || 'Este número de celular é inválido.')
})

yup.addMethod(yup.string, 'phone', function (message) {
    return this.matches(regex.phone, message || 'Este número de telefone fixo é inválido.')
})

yup.addMethod(yup.string, 'mobileOrPhone', function (message) {
    return this.matches(regex.mobileOrPhone, message || 'Este número de telefone é inválido.')
})

yup.addMethod(yup.string, 'uf', function (message) {
    return this.matches(regex.uf, message || 'UF inválida.')
})

yup.addMethod(yup.string, 'oab', function (message) {
    return this.matches(regex.oab, message || 'Número de carteira OAB inválido.')
})

export default yup
