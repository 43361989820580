import styled from 'styled-components'

export const Container = styled.div`
	width: 800px;

	.line {
		border: solid 0.5px #81c784;
		margin: 11px 38px 0;
		position: absolute;
		width: 734px;
		z-index: -1;
	}

	.line + p {
		display: flex;
		justify-content: space-between;
		margin: 0;

		span {
			display :flex;
			flex-direction: column;
			align-items: center;
			
			p {
				margin: 0;
				font-size: 11px;
			}
		}
	}
`

export const TimelineItem = styled.span`
	svg {
		fill: #FFF;
		stroke: #81c784;
	}
`
