import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}
		
		@media(max-width: 960px) {
			padding: 20px;
		}

		@media(max-width: 500px) {
			padding: 20px 0;
		}
	}
`

export const ButtonContainer = styled.div`
	display: flex;
`
