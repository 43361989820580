import React from 'react'

import Filtro from './Filtro'
import Listagem from './Listagem'
import Cadastro from './Cadastro'
import Detalhes from './Detalhes'

import DependenteProvider from '../../../contexts/DependenteContext'

import Card from '../../../components/Card'

import { Container } from './styles'

export default function () {
    return (
        <DependenteProvider>
            <Container>
                <Card>
                    <h1>Gerenciamento de dependentes</h1>

                    <Filtro />

                    <Listagem />

                    <Cadastro />

                    <Detalhes />
                </Card>
            </Container>
        </DependenteProvider>
    )
}
