import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		> h1 {
			margin-bottom: 20px;
		}

		.card {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 16px;
			min-height: 70vh;

			> h1,
			.textbox,
			.spinner-container {
				grid-column: 1/span 4;
			}

			.textbox {
				max-width: 400px;
				justify-self: center;
			}

			@media(max-width: 1300px) {
				grid-template-columns: 1fr 1fr 1fr;

				> h1,
				.textbox,
				.spinner-container {
					grid-column: 1/span 3;
				}
			}

			@media(max-width: 1050px) {
				grid-template-columns: 1fr 1fr;

				> h1,
				.textbox,
				.spinner-container {
					grid-column: 1/span 2;
				}
			}

			@media(max-width: 772px) {
				display: flex;
				flex-direction: column;
			}
		}

		details {
			border-radius: 6px;
			border: solid 1px #fff;
    		border-bottom-width: 3px;
			height: fit-content;
			
			.detalhes {
				margin: 18px;
			}

			summary {
				background-color: #FFF;
				color: #424242;
				cursor: pointer;
				padding: 18px 18px 0;
				display: flex;
				flex-direction: column;

				img {
					height: 180px;
					width: 240px;
					object-fit: cover;
					border-radius: 10px;
					align-self: center;
				}

				div {
					display: flex;
					justify-content: space-between;
					align-items: center;

					h1 {
						font-size: 16px;
						font-weight: 500;
						margin-top: 14px;
						color: #424242;
						margin-bottom: 20px;
					}

					svg {
						font-size: 22px;
					}
				}

				&:hover {
					background-color: #FFF;
    				color: #424242;

					h1 {
						color: #424242;
					}
				}
			}

			@media(max-width: 520px) {
				margin: 0 8px;
			}
		}

		@media(max-width: 440px) {
			padding: 10px 0;
		}
	}
`

export const TutorialItem = styled.div`
	display: grid;
	grid-template-columns: 60px auto;
	grid-template-rows: auto;
	margin-bottom: 14px;
	align-items: center;
	cursor: pointer;
	transition: 300ms;

	&:hover {
		opacity: 0.8;
		
		svg {
			transform: scale(1.1);
		}
	}

	svg {
		font-size: 26px;
		justify-self: center;
		cursor: pointer;
		transition: 300ms;
	}

	h1 {
		font-size: 14px;
	}

	p {
		margin: 0 0 20px !important;
	}
`

export const TutorialContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	.detalhes {
		margin: 5px 8px 18px;
	}

	video, img {
		height: 74vh;
	}

	img {
		object-fit: contain;
	}
`
