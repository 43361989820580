import styled from 'styled-components'

export const Container = styled.article`
	p {
		margin: 8px;
	}

	form {
		margin-top: 14px;
		display: grid;
		grid-template-areas:
			'title title title'
			'f1 f2 x'
			'button button button';
	}

	.file {
		width: calc(100% - 16px);
	}

	button {
		grid-area: button;
		width: fit-content;
		justify-self: flex-end;
	}

	@media(max-width: 800px) {
		padding: 20px;

		form {
			display: grid;
			grid-template-areas:
				'title'
				'f1'
				'f2'
				'button';
		}
	}

	@media(max-width: 400px) {
		padding: 15px 0;
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`
