import React, { createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

export const PermissoesContext = createContext()

const PermissoesProvider = ({ children }) => {
    const [perfis, setPerfis] = useState(null)
    const [permissoes, setPermissoes] = useState(null)
    const [gruposPermissao, setGruposPermissao] = useState(null)
    const [idPerfilSelecionado, setIdPerfilSelecionado] = useState(null)

    async function loadPerfis() {
        const response = await api.get('perfil')

        setPerfis(response)
    }

    async function loadPermissoes() {
        const response = await api.get('permissao')

        setPermissoes(response)
    }

    async function loadGruposPermissao() {
        const response = await api.get('grupo_permissao')

        setGruposPermissao(response)
    }

    function reload() {
        loadPerfis()
        loadPermissoes()
        loadGruposPermissao()
    }

    useEffect(() => {
        reload()
    }, [])

    function filterPermissoes(perfilId) {
        setIdPerfilSelecionado(perfilId)

        const permissoesIdPerfilSelecionado = perfis.find(perfil => perfil.id === perfilId).permissoes.map(p => p.id)

        return permissoes.filter(permissao => !permissoesIdPerfilSelecionado.includes(permissao.id))
    }

    async function handleUpdate({ draggableId: permissaoId, destination, source }) {
        const perfilSelecionado = perfis.find(p => p.id === idPerfilSelecionado)

        if (source.droppableId === destination.droppableId) {
            setPerfis([
                ...perfis.slice(0, destination.index),
                perfilSelecionado,
                ...perfis.slice(destination.index)
            ])

            return
        }

        const adicionado = source.droppableId === 'disponiveis' && destination.droppableId === 'atuais'

        if (adicionado) {
            perfilSelecionado.permissoes.push({
                id: Number(permissaoId),
                descricao: permissoes.find(p => p.id === Number(permissaoId)).descricao,
                subdescricao: permissoes.find(p => p.id === Number(permissaoId)).subdescricao
            })
        } else {
            perfilSelecionado.permissoes = perfilSelecionado.permissoes.filter(p => p.id !== Number(permissaoId))
        }

        setPerfis([
            ...perfis.filter(p => p.id !== perfilSelecionado.id),
            perfilSelecionado
        ])

        try {
            await api.put(`perfil_permissao/${idPerfilSelecionado}`, {
                permissao_id: Number(permissaoId),
                adicionado
            }, authHeaders())

            toast.success('Permissões atualizadas.')
        } catch (e) {

        }
    }

    return (
        <PermissoesContext.Provider value={{
            perfis,
            permissoes,
            gruposPermissao,
            filterPermissoes,
            handleUpdate,
            reload
        }}
        >
            {children}
        </PermissoesContext.Provider>
    )
}

export default PermissoesProvider
