export function basename(path, withoutExtension = false) {
    const file = path.split('/').at(-1)

    if(withoutExtension) {
        let parts = file.split('.')
        
        parts = parts.slice(0, parts.length - 1)

        return parts.join('.')
    }

    return file
}

export function extname(path) {
    if(!path) {
        return ''
    }

    const file = path.split('/').at(-1)
	
    const extension = file.split('.').at(-1).toLowerCase()
	
    return `.${extension}`
}
