import styled from 'styled-components'

export const Container = styled.div`
    form:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: 
            'f1 f2 f3 f3'
            'button button button button';

        > button {
            grid-area: button;
            justify-self: end;
            margin: 5px 8px;
        }

        @media(max-width: 900px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
                'f1 f2'
                'f3 f3'
                'button button';
        }

        @media(max-width: 700px) {
            grid-template-columns: 1fr;
            grid-template-areas: 
                'f1'
                'f2'
                'f3'
                'button';

            > button {
                max-width: calc(100% - 16px);
                justify-self: unset;
            }
        }
    }

    form:last-child {
        display: grid;
        grid-template-areas: 
            'f1 f2'
            'button button';

        @media(max-width: 700px) {
            grid-template-areas: 
                'f1'
                'button';
        }

        > button {
            justify-self: end;
        }
    }

    .lista-contatos {
        margin: 14px 8px;
        width: calc(100% - 16px);
    }
`
