import React from 'react'
import { Formik, Form } from 'formik'
import { FaCheck } from 'react-icons/fa'

import bancos from '../../../../../../util/bancos'

import Yup from '../../../../../../services/yup'

import ActionFooter from '../../../../../../components/ActionFooter'
import {
    Button, Textbox, Select 
} from '../../../../../../components/Form'

import { Container } from './styles'

const validation = Yup.object({
    banco: Yup.string().ensure(),
    agencia: Yup.string().nullable(),
    conta: Yup.string().nullable()
})

export default function ({ callback, dadosFornecedor }) {
    function handleSubmit(values) {
        values.banco = values.banco?.value || ''
        
        callback({ dados_bancarios: values })
    }
    
    return (
        <Container>
            <Formik
                initialValues={{
                    ...dadosFornecedor.dados_bancarios,
                    banco: bancos.find(banco => banco.value === dadosFornecedor.dados_bancarios.banco)
                }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <Select 
                            label="Banco" 
                            name="banco"
                            options={bancos}
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}  
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Textbox 
                            label="Agência" 
                            name="agencia"
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Textbox 
                            label="Conta" 
                            name="conta"
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <ActionFooter style={{ gridArea: 'button' }}>
                            <Button type="submit" loading={isSubmitting} className="white">
                                Concluir
                                <FaCheck />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
