import React, { useState, useEffect } from 'react'
import { FaLock } from 'react-icons/fa'
import { format } from 'date-fns'

import { Container, Card } from './styles'

import mastercard from '../../assets/images/payment/mastercard.png'
import visa from '../../assets/images/payment/visa.png'
import elo from '../../assets/images/payment/elo.png'
import diners from '../../assets/images/payment/diners.png'
import amex from '../../assets/images/payment/amex.png'

const { Iugu } = window

const brands = {
    mastercard,
    visa,
    elo,
    diners,
    amex
}

export default function CreditCard({
    name, number, expiration, cvv, hideCvv = false, className = '', style
}) {
    const [brand, setBrand] = useState(null)

    useEffect(() => {
        const brandName = Iugu.utils.getBrandByCreditCardNumber(number)

        setBrand(brands[brandName])
    }, [number])

    function maskApply(cardNumber) {
        if (!cardNumber) {
            return '0000 0000 0000 0000'
        }

        if (cardNumber.length === 16) {
            let output = ''

            for (let i = 0; i < 16; i += 4) {
                output += `${cardNumber.slice(i, i + 4)} `
            }

            return output.trim()
        }

        // Amex
        if (cardNumber.length === 15) {
            return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 11)} ${cardNumber.slice(-4)}`
        }

        // Diners
        if (cardNumber.length === 14) {
            return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 10)} ${cardNumber.slice(-4)}`
        }

        return cardNumber
    }

    return (
        <Container style={style} className={className}>
            <Card>
                <div className="brand">
                    {brand ? (
                        <img src={brand} alt="" className="animated bounceIn faster delay-400ms" />
                    ) : (
                        <div className="brand-placeholder" />
                    )}
                </div>

                <span className="name">{name || 'SEU NOME'}</span>

                <span className="number">{maskApply(number)}</span>

                <footer>
                    <span className="expiration">
                        <span>Validade</span>
                        {expiration ? format(expiration, 'MM/yy') : '__/__'}
                    </span>

                    <span className="cvv">
                        <FaLock size={14} className="grey-text text-darken-3" />
                        <div>
                            <span>cvv</span>
                            {cvv ? (hideCvv ? cvv.replaceAll(/\d/g, '*') : cvv) : '___'}
                        </div>
                    </span>
                </footer>
            </Card>
        </Container>
    )
}
