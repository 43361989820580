export function forceDownloadFile(blobData, fileName = 'Download', extension = 'pdf') {
    const url = window.URL.createObjectURL(new Blob([blobData]))

    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.setAttribute('download', `${fileName}.${extension}`)

    document.body.appendChild(link)

    link.click()

    return { url, link }
}

export function forceDownloadFileFromURL(url, fileName = 'Download', extension = 'pdf') {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.setAttribute('download', `${fileName}.${extension}`)

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)

    return { url, link }
}

export function downloadRelatorioJasper(arrayBuffer, fileName, extension = 'pdf') {
    const tempURL = window.URL.createObjectURL(arrayBuffer)

    forceDownloadFileFromURL(tempURL, fileName, extension)
}

export function downloadArquivoFromBuffer(arrayBuffer, fileName, extension = 'pdf') {
    const tempURL = window.URL.createObjectURL(arrayBuffer)

    forceDownloadFileFromURL(tempURL, fileName, extension)
}

export function openTabSafari(url) {
    const a = window.document.createElement('a')
    a.target = '_blank'
    a.href = url

    const e = window.document.createEvent('MouseEvents')
    e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    a.dispatchEvent(e)
}

export function downloadFileFromBlob(arrayBuffer, { name = 'file', extension = 'pdf', open = false }) {
    const url = URL.createObjectURL(arrayBuffer)
    
    const link = document.createElement('a')
    
    link.href = url
    link.target = '_blank'
    link.style.display = 'none'

    if(!open) {
        link.setAttribute('download', `${name}.${extension}`)
    }

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
}
