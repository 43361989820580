import styled, { keyframes } from 'styled-components'

import { Container as Card } from '../../../../components/Card/styles'

const blink = keyframes`
	from {
		background-color: #FFF;
	}
	to {
		background-color: #F002;
	}
`

export const Container = styled.article`
	min-height: 60vh;

	main {
		${Card} {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 54px auto;
			min-height: 70vh;

			.table-container,
			.require-rotation,
			.spinner-container,
			.search-form {
				grid-column: 1/span 2;
			}

			button {
				width: fit-content;
				justify-self: flex-end;

				@media(max-width: 580px) {
					grid-column: 1/span 2;
					width: calc(100% - 16px);
				}
			}
			
			.blink {
				background-color: #F002;
				animation: 400ms linear 0s 8 alternate ${blink};
			}

			@media(max-width: 580px) {
				> h1 {
					grid-column: 1/span 2;
				}
			}
		}
	}
`
