import styled from 'styled-components'

export const Item = styled.div`
	background-color: #FFF;
	padding: 8px 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: solid 1px #0003;
	color: #424242;
	transition: 300ms;

	h1 {
		font-size: 14px;
		color: #424242;
	}

	&:hover {
		background-color: #0001;
		cursor: grab;
	}
`
