import React, { useCallback, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFileText } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeMonthGif from '../../../../../assets/images/gifs/periodo-mes.gif'

const initialValues = {
    competencia: ''
}

const validation = Yup.object().shape({
    competencia: Yup.string().nullable().required('Informe o periodo de competencia para a busca.')
})

const nomeRelatorio = 'Relatório de Associados Quites SulAmérica Seguros'

// const nomeRelatorio = 'Relatório Seguro'

export default function (props) {
    const [isSubmittingPlanilha, setIsSubmittingPlanilha] = useState(false)

    const handleSubmitPlanilha = useCallback(async (values, resetForm) => {
        setIsSubmittingPlanilha(true)

        try {
            const response = await api.post('/relatorio/unimed_seguros_excel', {
                relatorio: 'RelatorioUnimedSeguros'
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            // const response = await api.post('/relatorio/seguros_excel', {
            //     relatorio: 'RelatorioSeguros'
            // }, {
            //     ...authHeaders(),
            //     responseType: 'blob'
            // })

            if (response.type === 'application/json') {
                const fr = new FileReader()

                fr.onload = function (e) {
                    const json = e.target.result
                    toast.warn(JSON.parse(json).msg)
                }
                fr.readAsText(response)
            } else {
                downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')
            }

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }

        setIsSubmittingPlanilha(false)
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmitPlanilha}
                enableReinitialize
            >
                {({
                    isSubmitting, resetForm, values, setSubmitting
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="competencia"
                            dateFormat="mm/yy"
                            view="month"
                            label="Competência"
                            containerStyle={{ gridArea: 'f1' }}
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeMonthGif}" style="width:300px; height:auto" />`}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => handleSubmitPlanilha(values, resetForm, setSubmitting)}
                                loading={isSubmittingPlanilha}
                                className="white"
                            >
                                Gerar planilha
                                <FiFileText size={20} />
                            </Button>

                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
