import React, { useState, useEffect, useContext } from 'react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'
import { FaDownload } from 'react-icons/fa'

import Card from '../../../components/Card'
import { Button, Select, Textbox } from '../../../components/Form'
import Table from '../../../components/Table'
import Spinner from '../../../components/Spinner'

import { forceDownloadFileFromURL } from '../../../util/file'
import formUtils from '../../../util/form'
import masks from '../../../util/masks'

import { api, authHeaders } from '../../../services/api'

import { Container } from './styles'
import { loadTips } from '../../../util/tip'
import { TipContext } from '../../../contexts/TipContext'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [bancos, setBancos] = useState([])
    const [arquivosGerados, setArquivosGerados] = useState(null)

    async function loadBancos() {
        const response = await api.get('instituicao', {
            params: {
                type: 'banco'
            }
        })

        setBancos(response.filter(banco => [16627, 13688].includes(banco.id)))
    }

    async function loadArquivosGerados() {
        const response = await api.get('arquivo_bancario', authHeaders())

        setArquivosGerados(response.map(item => ({
            id: item.id,
            descricao: `[${item.banco.codigo}] ${item.banco.nome_fantasia} - Seq. ${item.banco.codigo === '001' ? item.sequencial : item.sequencial / 100}`,
            pessoa: item.usuario.pessoa.nome,
            data_hora: format(new Date(item.data), 'dd/MM/yyyy HH:mm\'h\''),
            valor: masks.maskApply.currency(Number(item.valor)),
            quant_cobrancas: item.quantidade_cobrancas,
            link: item.arquivo.link
        })))
    }

    useEffect(() => {
        loadBancos()

        loadArquivosGerados()

        loadTips(setCodigo, 'form_geracao_remessa')
    }, [])

    async function handleGerarRemessa(values) {
        try {
            const { vencimento, banco } = formUtils.extractFormValues(values)

            const response = await api.get('remessa', {
                ...authHeaders(),
                params: {
                    vencimento,
                    banco
                }
            })

            forceDownloadFileFromURL(response.link)

            loadArquivosGerados()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Arquivo de remessa bancária</h1>

                    <Formik
                        onSubmit={handleGerarRemessa}
                        initialValues={{
                            banco: null,
                            vencimento: ''
                        }}
                    >
                        {({ setFieldValue, isSubmitting, values }) => {
                            const liberado = Object.values(values).every(k => !!k)

                            return (
                                <Form>
                                    <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                                    <Select
                                        name="banco"
                                        label="Banco"
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                        options={bancos.map(banco => ({
                                            label: banco.nome_fantasia,
                                            value: banco.codigo
                                        }))}
                                    />

                                    <Textbox
                                        name="vencimento"
                                        label="Data de vencimento"
                                        mask={masks.date}
                                    />

                                    <Button className="transparent" type="submit" loading={isSubmitting} disabled={!liberado}>
                                        Gerar remessa
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>

                    {arquivosGerados ? (
                        <Table
                            headers={[
                                { name: 'descricao', value: 'Identificação do arquivo' },
                                { name: 'pessoa', value: 'Gerado por', centered: true },
                                { name: 'data_hora', value: 'Data/hora da geração', centered: true },
                                { name: 'valor', value: 'Valor total', centered: true },
                                { name: 'quant_cobrancas', value: 'Associados cobrados' }
                            ]}
                            data={arquivosGerados}
                            actions={[
                                {
                                    action: item => forceDownloadFileFromURL(item.link),
                                    name: 'Baixar arquivo remessa',
                                    icon: FaDownload,
                                    iconSize: 16
                                }
                            ]}
                            filterable
                            legends={[
                                {
                                    color: '#ffcdd2',
                                    text: 'Arquivos sem retorno'
                                }
                            ]}
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
