import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile, FiFileText } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'
import ibge from '../../../../../services/ibge'

import { downloadRelatorioJasper } from '../../../../../util/file'

import masks from '../../../../../util/masks'

import {
    Button, Select, Textbox
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

const initialValues = {
    cidade: '',
    identificador: '',
    fornecedor: ''
}

const validation = Yup.object().shape({
    cidade: Yup.string().ensure(),
    identificador: Yup.string(),
    fornecedor: Yup.string().ensure().nullable()
})

const nomeRelatorio = 'Relatório de parceiros'

export default function (props) {
    const [fornecedores, setFornecedores] = useState([])
    const [cidades, setCidades] = useState([])
    const [isSubmittingPlanilha, setIsSubmittingPlanilha] = useState(false)

    async function loadFornecedores() {
        const response = await api.get('fornecedor', authHeaders())

        setFornecedores(response)
    }

    useEffect(() => {
        async function loadCidades() {
            const { data } = await ibge.get('estados/PA/municipios')

            setCidades(data.map(({ nome }) => nome).sort())
        }

        loadCidades()
    }, [])

    useEffect(() => {
        loadFornecedores()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            cidade,
            identificador,
            fornecedor
        } = values

        try {
            const response = await api.post('relatorio', {
                relatorio: 'RelatorioFornecedores',
                parametros: {
                    cidade: cidade?.value || null,
                    cnpj_cpf: identificador || null,
                    fornecedor_id: fornecedor || null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, 'RelatorioFornecedores')

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    const handleSubmitPlanilha = useCallback(async (values, resetForm) => {
        setIsSubmittingPlanilha(true)

        const {
            cidade,
            identificador,
            fornecedor
        } = values

        try {
            const response = await api.post('/relatorio/fornecedor_excel', {
                relatorio: 'RelatorioFornecedores',
                parametros: {
                    cidade: cidade?.value || null,
                    cnpj_cpf: identificador || null,
                    fornecedor: fornecedor ? fornecedor.map(f => f.value) : null
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            if (response.type === 'application/json') {
                const fr = new FileReader()

                fr.onload = function (e) {
                    const json = e.target.result
                    toast.warn(JSON.parse(json).msg)
                }
                fr.readAsText(response)
            } else {
                downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')
            }

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }

        setIsSubmittingPlanilha(false)
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm, values
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="fornecedor"
                            label="Parceiro"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={fornecedores.map(({ id, pessoa }) => ({
                                label: pessoa.nome,
                                value: id
                            }))}
                            isMulti
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Textbox
                            name="identificador"
                            label="Informe CNPJ/CPF"
                            id="identificador"
                            onBlur={() => {
                                const field = document.querySelector('#identificador')
                                const { length } = field.value

                                if (length === 11) {
                                    field.value = masks.maskApply.cpf(field.value)
                                } else if (length === 14) {
                                    field.value = masks.maskApply.cnpj(field.value)
                                }
                            }}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="cidade"
                            label="Cidade"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={cidades.map(cidade => ({
                                label: cidade,
                                value: cidade
                            }))}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => handleSubmitPlanilha(values, resetForm)}
                                loading={isSubmittingPlanilha}
                                className="white"
                            >
                                Gerar planilha
                                <FiFileText size={20} />
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
