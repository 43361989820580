import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import {
    FiArrowRight, FiEye, FiFileText, FiTrash 
} from 'react-icons/fi'

import { format } from 'date-fns'
import {
    Select, Textbox, Button
} from '../../../../components/Form'
import Card from '../../../../components/Card'

import {
    Container, ParametrosLista, PreviewTextContainer, LinkDocumentoContainer 
} from './styles'

import { api, authHeaders } from '../../../../services/api'

import { loadTips } from '../../../../util/tip'
import stringUtils from '../../../../util/string'

import { TipContext } from '../../../../contexts/TipContext'
import Modal from '../../../../components/Modal'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'

const initialValues = {
    descricao: '',
    formato: null,
    tipo: null,
    arquivo: null
}

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [parametros, setParametros] = useState([])
    const [modelos, setModelos] = useState(null)
    const [documentos, setDocumentos] = useState(null)
    const [textoDocumento, setTextoDocumento] = useState('')
    const [arquivoVisualizacao, setArquivoVisualizacao] = useState(null)
    const [textoOriginalDocumento, setTextoOriginalDocumento] = useState(null)
    const [proximoProtocolo, setProximoProtocolo] = useState(null)
    const [linkDocumentoGerado, setLinkDocumentoGerado] = useState(null)

    async function loadModelos() {
        const response = await api.get('documento_modelo', authHeaders())

        setModelos(response.map(m => ({
            ...m,
            tipo: m.tipo_documento.descricao
        })))
    }

    async function loadDocumentos() {
        try {
            setDocumentos(null)

            const response = await api.get('documento_geracao', authHeaders())

            setDocumentos(response)
        } catch(e) {
            toast.error(e.msg)
        }
    }

    function clearParametros() {
        const parameters = [...document.querySelectorAll('input[name^=parametro]')]

        parameters.forEach(p => { p.value = '' })
    }

    function clearFormData({ resetForm, setFieldValue, values }) {
        const { descricao } = values

        resetForm()

        setFieldValue('descricao', descricao)

        clearParametros()
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            const modeloSelecionado = modelos.find(m => m.id === Number(values.modelo.value))

            values = {
                descricao: values.descricao,
                modelo_id: values.modelo.value,
                texto: modeloSelecionado.arquivo ? '' : textoDocumento
            }

            const { link } = await api.post('documento_geracao', values, authHeaders())

            setLinkDocumentoGerado(link)

            toast.success('Documento gerado com sucesso.')

            resetForm()

            setTextoDocumento('')

            loadDocumentos()
        } catch (e) {
            toast.error(e.msg || 'Ocorreu um erro ao gerar o documento.')
        } finally {
            loadModelos()
        }
    }

    function checkParametros() {
        const inputsParametro = [...document.querySelectorAll('input[name^=parametro]')]

        return inputsParametro.every(p => !!p.value)
    }

    async function handleChangeModelo(modeloId, { resetForm, setFieldValue, values }) {
        clearFormData({ resetForm, setFieldValue, values })

        const modelo = modelos.find(m => m.id === modeloId)

        setParametros([])

        if (modelo.texto) {
            const matches = modelo.texto.match(/(#[a-zA-Zçãõáéíóúâêôà]+#)/g)

            if (matches) {
                setParametros(matches.map(item => item.replace(/#/g, '')))
            }

            const textoOriginal = modelo.texto?.replace(/\n/g, '<br/>')

            setArquivoVisualizacao(null)

            setTextoOriginalDocumento(textoOriginal)
            setTextoDocumento(textoOriginal)
        } else if (modelo.arquivo?.link) {
            setTextoOriginalDocumento(null)
            setTextoDocumento(null)

            setArquivoVisualizacao(modelo.arquivo.link)
        }

        const { protocolo } = await api.get(`documento/obter_proximo_protocolo/${modeloId}`, authHeaders())

        setProximoProtocolo(protocolo)
    }

    function handleChangeParametro() {
        const inputsParametro = [...document.querySelectorAll('input[name^=parametro]')]

        const parametrosValores = inputsParametro.map(i => ({
            nome: i.name.replace('parametro', ''),
            valor: i.value
        }))

        let textoAtualizado = textoOriginalDocumento

        for (const param of parametrosValores) {
            const regex = new RegExp(`(#${param.nome}#)`, 'g')

            if (param.valor) {
                textoAtualizado = textoAtualizado.replace(regex, param.valor)
            }
        }

        setTextoDocumento(textoAtualizado)
    }

    async function handleDeleteDocumento(documento) {
        try {
            await api.delete(`documento_geracao/${documento.id}`, authHeaders())

            toast.success('Documento removido com sucesso.')

            loadDocumentos()
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadModelos()

        loadDocumentos()
    }, [])

    useEffect(() => {
        loadTips(setCodigo, 'form_documento_geracao')
    }, [])

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Geração de documentos</h1>

                        <p>{tips?.formulario}</p>

                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({
                                setFieldValue, isSubmitting, values, resetForm 
                            }) => (
                                <Form>
                                    <Textbox
                                        name="descricao"
                                        id="descricao"
                                        label="Descrição"
                                    />

                                    <Select
                                        name="modelo"
                                        label="Modelo"
                                        options={modelos?.map(tipo => ({
                                            label: tipo.descricao,
                                            value: tipo.id
                                        })) || []}
                                        onChange={(selected, meta) => {
                                            handleChangeModelo(selected.value, { resetForm, setFieldValue, values })

                                            setFieldValue(meta.name, selected)
                                        }}
                                    />

                                    <PreviewTextContainer>
                                        {(textoDocumento || arquivoVisualizacao) && (
                                            <h1>Previsualização do documento</h1>
                                        )}

                                        {textoDocumento ? (
                                            <>
                                                <p>O número do documento será gerado pelo Gestão na Mão no formato ano/sequencial e inserido automaticamente no documento.</p>
                                                <div dangerouslySetInnerHTML={{ __html: textoDocumento.replace('[número do documento]', proximoProtocolo) }} />
                                            </>
                                        ) : arquivoVisualizacao ? (
                                            <iframe src={arquivoVisualizacao} frameBorder="0" title="Arquivo visualização" />
                                        ) : null}
                                    </PreviewTextContainer>

                                    {textoDocumento && (
                                        <ParametrosLista>
                                            <h1>Parâmetros utilizados</h1>

                                            {parametros.length > 0 ? parametros.map(param => (
                                                <Textbox
                                                    key={param}
                                                    name={`parametro${param}`}
                                                    label={stringUtils.capitalize(param)}
                                                    onChange={handleChangeParametro}
                                                />
                                            )) : (
                                                <i>Nenhum parâmetro incluído</i>
                                            )}
                                        </ParametrosLista>
                                    )}

                                    <div className="button-container">
                                        <Button 
                                            type="submit" 
                                            className="white" 
                                            loading={isSubmitting} 
                                            disabled={!values.descricao || !values.modelo || (!textoDocumento && !arquivoVisualizacao) || !checkParametros()}
                                        >
                                            Gerar documento
                                            <FiFileText size={16} />
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        {documentos ? (
                            <Table 
                                headers={[
                                    { name: 'titulo', value: 'Título' },
                                    { name: 'modelo', value: 'Modelo', centered: true },
                                    { name: 'data', value: 'Data de geração', centered: true },
                                    { name: 'criador', value: 'Criador por', centered: true }
                                ]}
                                filterable
                                data={documentos.map(doc => ({
                                    ...doc,
                                    criador: doc.pessoa.nome,
                                    titulo: doc.descricao,
                                    modelo: doc.modelo.descricao,
                                    data: format(new Date(doc.created_at), 'dd/MM/yyyy')
                                }))}
                                actions={[
                                    {
                                        action: item => {
                                            window.open(item.arquivo?.link, '_blank')
                                        },
                                        name: 'Visualizar',
                                        icon: FiEye,
                                        iconSize: 16,
                                        checkDisabled: item => !item.arquivo?.link
                                    },
                                    {
                                        action: handleDeleteDocumento,
                                        icon: FiTrash,
                                        iconSize: 16,
                                        name: 'Excluir',
                                        confirmation: 'Deseja realmente excluir este documento?'
                                    }
                                ]}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!linkDocumentoGerado}
                handleClose={() => {
                    setLinkDocumentoGerado(null)
                }}
                title="Documento gerado com sucesso"
                closeOnOverlayClick={false}
            >
                <LinkDocumentoContainer>
                    <a href={linkDocumentoGerado} className="button white" target="_blank" rel="noopener noreferrer">
                        Clique para visualizar o documento gerado
                        <FiArrowRight size={16} />
                    </a>
                </LinkDocumentoContainer>
            </Modal>
        </>
    )
}
