import styled from 'styled-components'

export const Container = styled.div`
	grid-area: chapas;
	margin-top: 16px;
	padding-bottom: 30px;

	> h1 {
		font-size: 15px;
		font-weight: 600;
	}

	form {
		min-height: 86px;

		.table-container {
			width: calc(100% - 16px);
			margin: 30px 8px;
		}

		.button-container {
			display: flex;
			justify-content: flex-end;
		}

		> p.placeholder {
			text-align: center;
		}

		.button-container {
			display: flex;
			justify-content: flex-end;
		}
	}

	.badge {
		background-color: #ffeb3b;
		border-radius: 6px;
		color: #424242;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		font-size: 11px;
		margin-top: 0;
		margin-left: 8px;
		width: fit-content;
		padding: 6px;
		line-height: 18px;
	}
`

export const CandidatosContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px 0;
	margin: 16px 0;
	grid-area: candidato;

	> h1 {
		font-size: 19px;
		font-weight: 500;
		margin: 0 8px;
	}

	.textbox {
		margin: 0 8px !important;
	}

	.table-container {
		width: calc(100% - 16px);
		margin: 0 8px !important;
	}

	.result-list {
		z-index: 2;
		width: calc(100% - 16px);
		margin-left: 8px;
		margin-top: -14px;
		border-radius: 6px;
		background-color: #FFF;
		box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
		height: fit-content;

		li {
			padding: 8px;
			color: #424242;
			display: flex;
			align-items: center;

			&:hover {
				background-color: #0001;
				cursor: pointer;
			}
		}
	}
`

export const UploadFotoContainer = styled.div`
	display: flex;
	justify-content: center;

	form {
		max-width: 400px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
`

export const ChapaListaContainer = styled.div.attrs({
	className: 'chapa-lista'
})`
	margin: 24px 0 40px;

	details summary {
		display: flex;
		justify-content: space-between;

		&:hover svg {
			color: #424242;
		}

		.action {
			padding: 10px;
			border-radius: 6px;

			svg:hover {
				color: #f44336;
			}
		}
	}

	details:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;

		summary {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
	}

	details:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		summary {
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		&[open] summary {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	details[open] summary {
		background-color: #FFF;

		&, svg {
			color: #424242;
		}
	}
`

export const ChapaItemContainer = styled.details`
	border: solid 1px #fff;

	summary {
		font-size: 16px;
		font-weight: 500;
		padding: 0;
		transition: 300ms;

		span {
			padding: 8px 14px;
		}

		&:hover {
			background-color: #FFF;
			color: #424242;
			cursor: pointer;
		}
	}

	.content {
		padding: 20px;
		display: grid;
		grid-template-columns: 300px 0.3fr 3fr;
		gap: 10px 24px;
		grid-template-areas:
			'imagem info info'
			'imagem info info'
			'form form candidatos';

		.chapa-info {
			grid-area: info;
			display: flex;
			flex-direction: column;
			gap: 24px 0;
		}

		.descricao,
		.textbox {
			grid-area: descricao;
			margin: 0;
		}

		.descricao {
			height: fit-content;
			width: fit-content;

			svg {
				margin-left: 14px;
				opacity: 0;
				transition: 300ms;
			}

			&:hover {
				svg {
					opacity: 1;
				}
			}
		}

		.form-busca-candidato {
			grid-area: form;
		}

		.proposta {
			grid-area: proposta;
			display: flex;
			flex-direction: column;

			.title {
				display: flex;
				gap: 0 12px;
			}

			h1 {
				font-size: 16px;
				font-weight: 500;
				margin: 0 0 8px;
			}

			.textarea {
				margin: 0;
			}

			svg {
				opacity: 0;
				transition: 300ms;
			}

			p {
				line-height: 20px;
			}

			&:hover {
				svg {
					opacity: 1;
				}
			}
		}

		.table-container {
			grid-area: candidatos;
			width: 100%;
			margin: 0;
			margin-top: 70px;
		}
	}
`

export const DeleteChapaContainer = styled.div`
	display: flex;
	flex-direction: column;

	.text {
		margin: 8px;
	}
	
	.button-container {
		display: flex;
		justify-content: flex-end;
	}
`
