import React from 'react'
import {
    Tab, Tabs, TabList, TabPanel 
} from 'react-tabs'

import Card from '../../../../components/Card'

import TutorialProvider from '../../../../contexts/TutorialContext'

import Assunto from './Assunto'
import Tutorial from './Tutorial'

import { Container } from './styles'

export default function () {
    return (
        <TutorialProvider>
            <Container>
                <main>
                    <Card>
                        <h1>Gerenciamento de tópicos de ajuda</h1>

                        <Tabs>
                            <TabList>
                                <Tab>Assuntos</Tab>
                                <Tab>Tutoriais</Tab>
                            </TabList>

                            <TabPanel>
                                <Assunto />
                            </TabPanel>

                            <TabPanel>
                                <Tutorial />
                            </TabPanel>
                        </Tabs>
                    </Card>
                </main>
            </Container>
        </TutorialProvider>
    )
}
