import React from 'react'

import Card from '../../../components/Card'

import Formulario from './Formulario'
import Listagem from './Listagem'

import OperacaoProvider from '../../../contexts/OperacaoContext'

import { Container } from './styles'

import useQuery from '../../../hooks/useQuery'

export default function () {
    const { id } = useQuery()

    return (
        <OperacaoProvider>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Autorização de Operações Financeiras</h1>

                        <Formulario />

                        <Listagem id={id} />
                    </Card>
                </main>
            </Container>
        </OperacaoProvider>
    )
}
