import React, { useContext, useEffect, useState } from 'react'

import { Formik, Form } from 'formik'
import Yup from '../../../../services/yup'

import { AtendimentoContext } from '../../../../contexts/AtendimentoContext'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

import {
    Select, Textarea, Button
} from '../../../../components/Form'
import Modal from '../../../../components/Modal'

import { api } from '../../../../services/api'

import { Container } from './styles'

const baseInitialValues = {
    tipo_atendimento: null,
    tipo_assunto: null,
    mensagem: '',
    crm: ''
}

const validation = Yup.object().shape({
    mensagem: Yup.string().required('Preencha o campo de mensagem.')
})

export default function () {
    const {
        tipos,
        setShowNovoAtendimento,
        showNovoAtendimento,
        handleSubmit,
        tipoAtendimento
    } = useContext(AtendimentoContext)

    const { setCodigo } = useContext(TipContext)

    const [tiposAssunto, setTiposAssunto] = useState([])
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    async function loadTipoAssunto(tipoId) {
        const response = await api.get('atendimento_assunto', {
            params: {
                tipoId
            }
        })

        setTiposAssunto(response)
    }

    function handleSelectChange(selected, field, setFieldValue) {
        const { value, label } = selected

        setFieldValue(field, { label, value })

        loadTipoAssunto(value)
    }

    useEffect(() => {
        if (tipos?.length === 1) {
            const tipoSelecionado = {
                label: tipos[0].descricao,
                value: tipos[0].id
            }

            setInitialValues({
                ...baseInitialValues,
                tipo_atendimento: tipoSelecionado
            })

            loadTipoAssunto(tipoSelecionado.value)
        }
    }, [tipos])

    useEffect(() => {
        loadTips(setCodigo, 'form_relacionamento_atendimento_abertura')
    }, [])

    return (
        <Modal
            isOpen={showNovoAtendimento}
            handleClose={() => {
                setShowNovoAtendimento(false)
            }}
            title={tipoAtendimento?.id === 1 ? 'Nova demanda jurídica' : 'Novo atendimento'}
        >
            <Container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validation}
                    enableReinitialize
                >
                    {({ setFieldValue, isSubmitting, values }) => (
                        <Form>
                            {tipoAtendimento?.id !== 1 && (
                                <>
                                    <Select
                                        name="tipo_atendimento"
                                        label="Tipo de atendimento"
                                        onChange={(selected, meta) => handleSelectChange(selected, meta.name, setFieldValue)}
                                        options={tipos?.filter(tipo => ![3, 4].includes(tipo.id)).map(tipo => ({
                                            label: tipo.descricao,
                                            value: tipo.id
                                        }))}
                                    />

                                    {values.tipo_atendimento?.value && values.tipo_atendimento.value !== 1 && (
                                        <Select
                                            name="tipo_assunto"
                                            label="Assunto"
                                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                            options={tiposAssunto?.map(assunto => ({
                                                label: assunto.descricao,
                                                value: assunto.id
                                            }))}
                                        />
                                    )}
                                </>
                            )}

                            <p className="tip">
                                Preencha o campo abaixo descrevendo, em linhas gerais, do que se trata sua demanda. Seja específico quanto ao assunto tratado (denúncia, intimação, outros).
                            </p>

                            <Textarea
                                name="mensagem"
                                label="Digite sua mensagem"
                            />

                            <p className="badge">Caso você precise anexar documentos, após a abertura da demanda, acesse-a e faça o envio dos arquivos na opção &quot;Enviar documento&quot;.</p>

                            <Button type="submit" className="transparent" loading={isSubmitting}>
                                Enviar demanda
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Modal>
    )
}
