import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { format } from 'date-fns'
import { FiAlertTriangle } from 'react-icons/fi'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Spinner from '../../../../../components/Spinner'
import Dialogo from '../../../../../components/Dialogo'
import Modal from '../../../../../components/Modal'
import {
    Button, Textarea, Select, Textbox
} from '../../../../../components/Form'

import { api, authHeaders } from '../../../../../services/api'

import formUtils from '../../../../../util/form'

import avatarDefault from '../../../../../assets/images/avatar.png'

import AgendamentoListagem from '../Agendamento/Listagem'
import Alerta from '../Agendamento/Alerta'

import {
    Container, DetalhesContainer, ModalFinalizacaoContainer, ConfirmCancelamentoContainer, ProtocoloFormContainer, FormAssuntoContainer
} from './styles'

import { AtendimentoContext } from '../../../../../contexts/AtendimentoContext'
import { GlobalContext } from '../../../../../contexts/GlobalContext'

import { TipContext } from '../../../../../contexts/TipContext'
import { loadTips } from '../../../../../util/tip'

export default function () {
    const history = useHistory()

    const {
        loadAtendimento, atendimento, reloadDemanda, assuntos, setShowFormVisita, advogados, destinatariosGenericos,
        showAgendamentos, setShowAgendamentos, registroAtendimentoPresencial, setRegistroAtendimentoPresencial
    } = useContext(AtendimentoContext)
    const { setCodigo } = useContext(TipContext)
    const { user } = useContext(GlobalContext)

    const { id } = useParams()

    const finalizacaoHabilitada = atendimento?.status.id === 1
        && (
            atendimento?.ultimo_atendente?.id === user?.pessoa?.id
            || user?.perfil.id === 23
        )
        && user?.perfil.id !== (2)

    // 9, 27, 28, 29, 30, 31, 33, 34)

    const dialogoDesbloqueado = atendimento?.status.id !== 2 && ([2, 9, 27, 28, 29, 30, 31, 33, 34].includes(user?.perfil.id) || finalizacaoHabilitada)

    const [participantes, setParticipantes] = useState(null)
    const [showFormFinalizacao, setShowFormFinalizacao] = useState(false)
    const [showCancelamentoConfirmacao, setShowCancelamentoConfirmacao] = useState(false)
    const [protocolo, setProtocolo] = useState(null)
    const [showAssuntoForm, setShowAssuntoForm] = useState(false)
    const [acoes, setAcoes] = useState([])
    const [showEncaminhamento, setShowEncaminhamento] = useState(false)
    const [acaoSelecionada, setAcaoSelecionada] = useState(null)
    const [executandoAcao, setExecutandoAcao] = useState(false)
    const [cancelando, setCancelando] = useState(false)

    async function loadParticipantes() {
        const response = await api.get('pessoa', {
            headers: {
                pessoas_ids: atendimento.dialogo.participants.filter(p => !!p).join(','),
                ...authHeaders().headers
            }
        })

        setParticipantes(response)
    }

    async function handleFinalizarAtendimento(values) {
        try {
            values = formUtils.extractFormValues(values)

            await api.patch(`atendimento/finalizar/${atendimento.id}`, values, authHeaders())

            setShowFormFinalizacao(false)

            toast.success('Atendimento finalizado')

            history.push('/atendimento/listar')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleVincularAtendente() {
        try {
            await api.patch(`atendimento/${atendimento.id}/vincular`, {}, authHeaders())

            toast.success('Atendimento iniciado. Agora você pode enviar mensagens e documentos.')

            loadAtendimento(id)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleCancelarAtendimento() {
        try {
            setCancelando(true)

            await api.delete(`atendimento/${atendimento.id}`, authHeaders())

            setShowCancelamentoConfirmacao(false)

            toast.success('O atendimento foi cancelado.')

            history.push(atendimento.tipo.id === 1 ? '/atendimento/listar?tipo=1' : '/atendimento/listar')

            setCancelando(false)
        } catch (e) {
            toast.error(e.msg)

            setCancelando(false)
        }
    }

    async function handleSetProtocolo(values) {
        try {
            await api.put(`atendimento/${atendimento.id}`, {
                protocolo: values.protocolo
            }, authHeaders())

            toast.success('Número do processo definido com sucesso.')

            setProtocolo(null)

            reloadDemanda()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSetAssunto(values) {
        try {
            await api.put(`atendimento/${atendimento.id}`, {
                atendimento_assunto_id: values.assunto.value
            }, authHeaders())

            toast.success('Assunto definido com sucesso.')

            setShowAssuntoForm(false)

            reloadDemanda()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function loadAcoes() {
        const acoesDisponiveis = []

        if (atendimento.status.id === 3 && ![2, 9, 27, 28, 29, 30, 31, 33, 34].includes(user?.perfil.id)) {
            acoesDisponiveis.push({
                label: 'Iniciar atendimento',
                value: 1
            })
        }

        if (atendimento.status.id === 3 && [2, 9, 27, 28, 29, 30, 31, 33, 34].includes(user?.perfil.id)) {
            acoesDisponiveis.push({
                label: 'Cancelar esta demanda',
                value: 2
            })
        }

        if (finalizacaoHabilitada) {
            acoesDisponiveis.push({
                label: 'Classificar assunto da demanda',
                value: 3
            })

            if (atendimento.tipo.id === 1) {
                acoesDisponiveis.push({
                    label: 'Inserir número do processo',
                    value: 4
                })
            }

            acoesDisponiveis.push({
                label: 'Finalizar esta demanda',
                value: 5
            })
        }

        if (atendimento.status.id !== 2
            && (
                user.perfil.id === 23
                || ([14, 5, 3, 9].includes(user.perfil.id) && atendimento.ultimo_atendente?.id === user.pessoa.id)
            )
        ) {
            acoesDisponiveis.push({
                label: 'Encaminhar demanda',
                value: 6
            })
        }

        const advogadoComumTemAgenda = advogados.find(a => a.pessoa.id === Number(user.pessoa.id))?.agenda.length

        if (atendimento.tipo.id === 1 && atendimento.status.id !== 2 && ([2, 9, 27, 28, 29, 30, 31, 33, 34, 23].includes(user.perfil.id) || user.perfil.id === 23 || (user.perfil.id === 20 && advogadoComumTemAgenda))) {
            acoesDisponiveis.push({
                label: 'Marcar atendimento presencial',
                value: 7
            })
        }

        if (atendimento.tipo.id === 1) {
            acoesDisponiveis.push({
                label: 'Listar atendimentos presenciais',
                value: 8
            })
        }

        setAcoes(acoesDisponiveis)
    }

    async function handleAction(actionId) {
        setExecutandoAcao(true)

        switch (actionId) {
            case 1:
                await handleVincularAtendente()
                break

            case 2:
                setShowCancelamentoConfirmacao(true)
                break

            case 3:
                setShowAssuntoForm(true)
                break

            case 4:
                setProtocolo(atendimento?.protocolo || '')
                break

            case 5:
                setShowFormFinalizacao(true)
                break

            case 6:
                setShowEncaminhamento(true)
                break

            case 7:
                setShowFormVisita(true)
                break

            case 8:
                setShowAgendamentos(true)
                break

            default:
                break
        }

        loadAcoes()

        setExecutandoAcao(false)
    }

    async function handleEncaminhar(values) {
        try {
            await api.patch(`atendimento/encaminhar/${atendimento.id}/${values.destinatario.value}`, {}, authHeaders())

            toast.success('Demanda encaminhada.')

            setShowEncaminhamento(false)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadAtendimento(id)
    }, [])

    useEffect(() => {
        if (atendimento) {
            loadParticipantes()
        }
    }, [atendimento])

    useEffect(() => {
        if (atendimento && user && advogados) {
            loadAcoes()
        }
    }, [atendimento, user, advogados])

    useEffect(() => {
        loadTips(setCodigo, 'form_relacionamento_atendimento_dialogo')
    }, [])

    return (
        <>
            <Container>
                {atendimento && user ? (
                    <>
                        <DetalhesContainer>
                            {atendimento?.status.id === 2 ? (
                                <p className="badge">
                                    <FiAlertTriangle />
                                    <span>Esta demanda está finalizada e disponível apenas para consulta.</span>
                                    <span>{`Situação: ${atendimento.situacao_finalizacao}`}</span>
                                </p>
                            ) : <div />}

                            <img
                                src={atendimento.associado.pessoa.usuario.avatar?.link || avatarDefault}
                                alt={atendimento.associado.pessoa.nome}
                                className="animated fadeIn delay-100ms"
                            />

                            <h1 className="animated fadeIn">{atendimento.associado.pessoa.nome}</h1>
                            <h2 className="animated fadeIn delay-300ms">
                                {`CRM: ${atendimento.associado.pessoa.documentos.find(doc => doc.tipo_documento.id === 3).identificador}`}
                            </h2>

                            <div className="info animated fadeIn delay-400ms">
                                <span>{`E-mail: ${atendimento.associado.pessoa.contato.find(c => c.tipo_contato_id === 6)?.contato || '-'}`}</span>
                                <span>{`Celular: ${atendimento.associado.pessoa.contato.find(c => [3, 4, 5].includes(c.tipo_contato_id))?.contato || '-'}`}</span>
                                <span>{`Data de abertura: ${format(new Date(atendimento.createdAt), 'dd/MM/yyyy HH:mm\'h\'')}`}</span>
                                <span>{`Tipo de demanda: ${atendimento.tipo.descricao}`}</span>
                                <span>
                                    {'Assunto da demanda: '}
                                    {atendimento.assunto?.descricao || <div dangerouslySetInnerHTML={{ __html: '<i>Não informado</i>' }} />}
                                </span>
                                <span>{`Status da demanda: ${atendimento.status.descricao}`}</span>
                                <span>
                                    {'Nº do processo: '}
                                    {atendimento.protocolo || <div dangerouslySetInnerHTML={{ __html: '<i>Não informado</i>' }} />}
                                </span>

                                <span>
                                    {'Nº da demanda: '}
                                    {`#${atendimento.id}` || <div dangerouslySetInnerHTML={{ __html: '<i>Não informado</i>' }} />}
                                </span>
                            </div>

                            <div className="acoes animated fadeIn delay-700ms">
                                {executandoAcao ? (
                                    <Spinner label="Aguarde..." />
                                ) : acoes.length ? (
                                    <Select
                                        label="Ações disponíveis"
                                        withoutForm
                                        options={acoes}
                                        onChange={selected => {
                                            handleAction(selected.value)

                                            setAcaoSelecionada(selected)

                                            setAcaoSelecionada(null)
                                        }}
                                        isSearchable={false}
                                        value={acaoSelecionada}
                                        name="acoes_disponiveis"
                                        id="acoes-disponiveis"
                                    />
                                ) : atendimento.status.id !== 2
                                    && ![2, 20, 23].includes(user.perfil.id)
                                    && !!atendimento.ultimo_atendente
                                    && atendimento.associado.pessoa.id !== user.pessoa.id ? (
                                    <div className="badge" style={{ width: 'fit-content' }}>
                                        <FiAlertTriangle />
                                        <span>
                                            {`Para interagir com esta demanda, peça a ${atendimento.ultimo_atendente.nome} que a encaminhe para você.`}
                                        </span>
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </div>

                            <Dialogo
                                sender={{
                                    id: user.pessoa.id,
                                    name: user.pessoa.nome,
                                    image: user.avatar
                                }}
                                singleLine={false}
                                sendWithEnterKey={false}
                                atendimento_id={atendimento.id}
                                messages={participantes && atendimento ? atendimento.dialogo.messages.map(msg => {
                                    const sender = participantes.find(part => part.id === msg.sender)

                                    return {
                                        id: msg.id,
                                        datetime: format(new Date(msg.created_at), 'dd/MM/yyyy HH:mm\'h\''),
                                        text: msg.content,
                                        sender: {
                                            id: sender?.id || null,
                                            name: sender?.nome,
                                            image: sender?.usuario?.avatar?.link
                                        }
                                    }
                                }) : null}
                                readOnly={!dialogoDesbloqueado}
                                sendEmail
                                className="animated fadeIn delay-900ms"
                                readOnlyPlaceholder={atendimento.status.id === 3 && [20, 23].includes(user.perfil.id)
                                    ? 'Você precisa iniciar o atendimento para poder enviar mensagens.'
                                    : atendimento?.status.id === 2 ? 'Esta demanda já foi finalizada. Não é possível enviar novas mensagens.' : undefined}
                            />
                        </DetalhesContainer>
                    </>
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={showFormFinalizacao}
                handleClose={() => { setShowFormFinalizacao(false) }}
                title="Finalização da demanda"
            >
                <ModalFinalizacaoContainer>
                    <Formik
                        onSubmit={handleFinalizarAtendimento}
                        initialValues={{
                            observacao: '',
                            situacao: null
                        }}
                    >
                        {({ isSubmitting, setFieldValue }) => (
                            <Form>
                                <Textarea
                                    name="observacao"
                                    label="Observação"
                                />

                                <Select
                                    name="situacao"
                                    label="Situação"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={[
                                        { label: 'Resolvido', value: 'R' },
                                        { label: 'Parcialmente resolvido', value: 'PR' },
                                        { label: 'Não aplicável', value: 'NA' }
                                    ]}
                                />

                                <div className="button-container">
                                    <Button type="submit" className="white" loading={isSubmitting}>
                                        Finalizar
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalFinalizacaoContainer>
            </Modal>

            <Modal
                isOpen={showCancelamentoConfirmacao}
                handleClose={() => { setShowCancelamentoConfirmacao(false) }}
                title="Confirmação"
            >
                <ConfirmCancelamentoContainer>
                    <p>Tem certeza de que deseja cancelar esta demanda? Não haverá como recuperá-la posteriormente.</p>

                    <div className="button-container">
                        <Button
                            onClick={() => { setShowCancelamentoConfirmacao(false) }}
                            className="transparent"
                            disabled={cancelando}
                        >
                            Não
                        </Button>
                        <Button
                            onClick={handleCancelarAtendimento}
                            className="white"
                            loading={cancelando}
                        >
                            Sim
                        </Button>
                    </div>
                </ConfirmCancelamentoContainer>
            </Modal>

            <Modal
                isOpen={protocolo !== null && [20, 23].includes(user?.perfil.id)}
                handleClose={() => { setProtocolo(null) }}
                title="Número do processo"
            >
                <ProtocoloFormContainer>
                    <Formik
                        onSubmit={handleSetProtocolo}
                        initialValues={{ protocolo: protocolo || '' }}
                    >
                        {() => (
                            <Form>
                                <Textbox
                                    label="Número do processo"
                                    name="protocolo"
                                />

                                <Button type="submit" className="white">Confirmar</Button>
                            </Form>
                        )}
                    </Formik>
                </ProtocoloFormContainer>
            </Modal>

            <Modal
                isOpen={showAssuntoForm}
                handleClose={() => { setShowAssuntoForm(false) }}
                title="Definir assunto da demanda"
            >
                <FormAssuntoContainer>
                    <Formik
                        onSubmit={handleSetAssunto}
                        initialValues={{ assunto: null }}
                    >
                        {({ setFieldValue }) => (
                            <Form>
                                <Select
                                    name="assunto"
                                    label="Assunto"
                                    options={assuntos.map(assunto => ({
                                        label: assunto.descricao,
                                        value: assunto.id
                                    }))}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                <div className="action-container">
                                    <Button type="submit" className="transparent">
                                        Confirmar
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </FormAssuntoContainer>
            </Modal>

            <Modal
                isOpen={showEncaminhamento}
                handleClose={() => { setShowEncaminhamento(false) }}
                title="Encaminhar demanda"
            >
                <FormAssuntoContainer>
                    <Formik
                        onSubmit={handleEncaminhar}
                        initialValues={{ destinatario: null }}
                    >
                        {({ setFieldValue, isSubmitting }) => {
                            const eDemandaJuridica = atendimento.tipo.id === 1
                            const destinatarios = eDemandaJuridica ? advogados : destinatariosGenericos
                            const mapAdvogados = destinatario => ({
                                label: destinatario.pessoa.nome,
                                value: destinatario.pessoa.id
                            })
                            const mapGenerico = destinatario => ({
                                label: destinatario.nome,
                                value: destinatario.id
                            })

                            return (
                                <Form>
                                    <Select
                                        name="destinatario"
                                        label={eDemandaJuridica ? 'Advogado(a)' : 'Destinatário'}
                                        options={destinatarios?.map(eDemandaJuridica ? mapAdvogados : mapGenerico) || []}
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    />

                                    <div className="action-container">
                                        <Button type="submit" className="transparent" loading={isSubmitting}>
                                            Encaminhar
                                        </Button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </FormAssuntoContainer>
            </Modal>

            <Modal
                isOpen={showAgendamentos}
                handleClose={() => { setShowAgendamentos(false) }}
                title={`Agendamentos de ${atendimento?.associado.pessoa.nome}`}
            >
                <AgendamentoListagem />
            </Modal>

            <Modal
                isOpen={!!registroAtendimentoPresencial}
                handleClose={() => { setRegistroAtendimentoPresencial(null) }}
                title="Registrar atendimento presencial"
            >
                <Alerta />
            </Modal>
        </>
    )
}
