import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'

import formUtils from '../../../util/form'

import Yup from '../../../services/yup'
import { api, authHeaders } from '../../../services/api'

import ActionFooter from '../../ActionFooter'
import {
    Button, Textbox, Select
} from '../../Form'

import { Container } from './styles'

const validation = Yup.object({
    banco: Yup.string().required('Selecione o banco.'),
    agencia: Yup.string().required('Digite o número da agência.'),
    conta: Yup.string().required('Digite o número da conta.')
})

const baseInitialValues = {
    banco: '',
    agencia: '',
    conta: ''
}

export default function ({ defaultValues, pessoaId, callback }) {
    const [bancos, setBancos] = useState([])
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    useEffect(() => {
        async function loadBancos() {
            const response = await api.get('instituicao?type=banco&desconto=1')

            setBancos(response)
        }

        loadBancos()

        if (defaultValues) {
            const { dadosPagamento } = defaultValues

            setInitialValues({
                ...dadosPagamento,
                banco: {
                    label: dadosPagamento.instituicao.nome_fantasia,
                    value: dadosPagamento.instituicao.id
                }
            })
        }
    }, [defaultValues])

    async function handleSubmit(values) {
        try {
            const data = formUtils.extractFormValues(values)

            const body = {
                tipo_pagamento_id: 1,
                dados_pagamento: {
                    ...data,
                    instituicao_id: data.banco
                }
            }

            await api.put(`pagamento/${pessoaId}`, body, authHeaders())

            toast.success('Dados atualizados.')

            callback()
        } catch (e) {
            console.log(e)

            toast.error('Ocorreu um erro.')
        }
    }

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <Select
                            name="banco"
                            id="instituicao_banco"
                            label="Banco"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={bancos.map(banco => ({
                                label: banco.razao_social,
                                value: banco.id
                            }))}
                            containerClass="banco-container"
                        />

                        <Textbox
                            label="Agência"
                            name="agencia"
                            inputMode="numeric"
                        />

                        <Textbox
                            label="Conta"
                            name="conta"
                            inputMode="numeric"
                        />

                        <ActionFooter>
                            <Button type="submit" loading={isSubmitting} className="white">
                                Salvar alterações
                                <FaRegSave />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
