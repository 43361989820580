import React, { useContext, useState, useEffect } from 'react'
import { format } from 'date-fns'
import { FaWhatsapp, FaRegCheckCircle } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { FiArrowRight } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import scrollUtils from '../../../util/scroll'
import stringUtils, { pluralize } from '../../../util/string'
import masks from '../../../util/masks'
import { forceDownloadFileFromURL } from '../../../util/file'

import Modal from '../../../components/Modal'
import Table from '../../../components/Table'
import { Button } from '../../../components/Form'
import Spinner from '../../../components/Spinner'

import { GlobalContext } from '../../../contexts/GlobalContext'

import ResumoAssociado from '../EspacoMedico/Resumo'

import {
    Container, ModalPendenciasContainer, PendenciasContainer, ModalEnqueteContainer, ModalEleicaoContainer
} from './styles'

import { api, authHeaders } from '../../../services/api'
import { getDateObject } from '../../../util/date'

export default function Dashboard({ profile, location }) {
    scrollUtils.toTop()

    const { user, clienteRobot } = useContext(GlobalContext)

    const history = useHistory()

    const [showModalNotificacoes, setShowModalNotificacoes] = useState(false)
    const [pendenciasContribuicao, setPendenciasContribuicao] = useState(null)
    const [pendenciasPlanoSaude, setPendenciasPlanoSaude] = useState(null)
    const [pendencias, setPendencias] = useState(null)
    const [contribuicoesSelecionadas, setContribuicoesSelecionadas] = useState([])
    const [contribuicoesPlanoSelecionadas, setContribuicoesPlanoSelecionadas] = useState([])
    const [jurosContribuicao, setJurosContribuicao] = useState(null)
    const [jurosPlano, setJurosPlano] = useState(null)
    const [avisoEnquetes, setAvisoEnquetes] = useState(null)
    const [avisoEleicoes, setAvisoEleicoes] = useState(null)
    const [dispensandoEnquetes, setDispensandoEnquetes] = useState(false)
    const [dispensandoEleicoes, setDispensandoEleicoes] = useState(false)

    async function loadPendencias() {
        setPendenciasContribuicao(null)
        setPendenciasPlanoSaude(null)
        setPendencias(null)

        const pendenciasSocial = await api.get('contribuicao', {
            params: {
                associado_id: user.associado.id,
                status: 0,
                iugu_status: 1
            },
            ...authHeaders()
        })

        const pendenciasPlano = await api.get('contribuicao_plano_saude', {
            params: {
                associado_id: user.associado.id,
                status: 0,
                iugu_status: 1
            },
            ...authHeaders()
        })

        const pendenciasContribuicaoNormalizado = pendenciasSocial.map(pend => ({
            ...pend,
            competencia: format(new Date(pend.competencia), [4, 5, 7].includes(pend.tipo_pagamento.id) ? 'yyyy' : 'MM/yyyy'),
            tipo: [4, 5, 7].includes(pend.tipo_pagamento.id) ? 'Anuidade' : 'Mensalidade',
            valor_formatado: masks.maskApply.currency(pend.valor),
            checkbox: {
                disabled: pend.status_iugu === 'pending',
                tooltip: 'Esta contribuição foi enviada para o seu e-mail e está aguardando pagamento. Caso já o tenha efetuado, aguarde a compensação.'
            }
        }))

        const pendenciasPlanoSaudeNormalizado = pendenciasPlano.map(pend => ({
            ...pend,
            competencia: format(new Date(pend.competencia), 'MM/yyyy'),
            tipo: 'Plano de saúde',
            valor_formatado: masks.maskApply.currency(pend.valor),
            checkbox: {
                disabled: pend.status_iugu === 'pending',
                tooltip: 'Esta contribuição foi enviada para o seu e-mail e está aguardando pagamento. Caso já o tenha efetuado, aguarde a compensação.'
            }
        }))

        setPendenciasContribuicao(pendenciasContribuicaoNormalizado)

        setPendenciasPlanoSaude(pendenciasPlanoSaudeNormalizado)

        setPendencias([
            ...pendenciasContribuicaoNormalizado,
            ...pendenciasPlanoSaudeNormalizado
        ])

        const { pessoa } = await api.get(`associado/${user.associado.id}`)

        user.associado.crm = pessoa.documentos?.find(d => d.tipo_documento.id === 3).identificador
    }

    async function loadJuros() {
        try {
            const { valor: porcentagemJurosContribuicao } = await api.get('parametro/taxa_juros_contribuicao')
            const [porcentagemContribuicao] = porcentagemJurosContribuicao.split('%_')
            setJurosContribuicao(Number(porcentagemContribuicao))

            const { valor: porcentagemJurosPlano } = await api.get('parametro/taxa_juros_plano_saude')
            const [porcentagemPlano] = porcentagemJurosPlano.split('%_')
            setJurosPlano(Number(porcentagemPlano))
        } catch (e) {
            toast.error(e.msg)
        }
    }

    function reload() {
        setContribuicoesSelecionadas([])
        setContribuicoesPlanoSelecionadas([])

        loadPendencias()
    }

    async function verificarEnquetes() {
        try {
            const enquetes = await api.get('enquete', {
                ...authHeaders(),
                params: {
                    scope: 'a_notificar'
                }
            })

            if (enquetes.length) {
                setAvisoEnquetes(enquetes)
            }
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function verificarEleicoes() {
        const response = await api.get('eleicao_aviso', {
            params: {
                em_curso: 1,
                votacao: 1,
                nao_dispensada: 1
            },
            ...authHeaders()
        })

        if (!response.length) {
            setAvisoEleicoes(null)
        } else {
            setAvisoEleicoes(response.map(el => ({
                id: el.id,
                descricao: el.descricao,
                inicio: format(getDateObject(el.periodo_inicio, -3), 'dd/MM/yyyy HH:mm\'h\''),
                fim: format(getDateObject(el.periodo_fim, -3), 'dd/MM/yyyy HH:mm\'h\'')
            })))
        }
    }

    useEffect(() => {
        if (user?.associado?.status_associacao.id === 6) {
            setShowModalNotificacoes(true)

            loadPendencias()

            loadJuros()
        }

        if (user) {
            verificarEnquetes()
            verificarEleicoes()
        }
    }, [user])

    async function gerarFaturaPendencias(tipo) {
        try {
            let faturaUrl

            switch (tipo) {
                case 'contribuicao':
                    const { link: linkFaturaContribuicao } = await api.post(`contribuicao/pendencias/${user.associado.id}`, {
                        contribuicoes_ids: contribuicoesSelecionadas.map(c => c.id)
                    }, authHeaders())

                    faturaUrl = linkFaturaContribuicao

                    break

                case 'plano_saude':
                    const { link: linkFaturaPlano } = await api.post(`contribuicao_plano_saude/pendencias/${user.associado.id}`, {
                        contribuicoes_ids: contribuicoesPlanoSelecionadas.map(c => c.id)
                    }, authHeaders())

                    faturaUrl = linkFaturaPlano

                    break

                default: break
            }

            forceDownloadFileFromURL(faturaUrl, 'Fatura')

            toast.success('A fatura foi gerada com sucesso. Você receberá uma cópia por e-mail.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function onCheckContribuicao(item, tipo) {
        if (tipo === 'contribuicao') {
            setContribuicoesSelecionadas([
                ...contribuicoesSelecionadas,
                item
            ])
        } else {
            setContribuicoesPlanoSelecionadas([
                ...contribuicoesPlanoSelecionadas,
                item
            ])
        }
    }

    async function onUncheckContribuicao(item, tipo) {
        if (tipo === 'contribuicao') {
            setContribuicoesSelecionadas(contribuicoesSelecionadas.filter(c => c.id !== item.id))
        } else {
            setContribuicoesPlanoSelecionadas(contribuicoesPlanoSelecionadas.filter(c => c.id !== item.id))
        }
    }

    async function onCheckAllContribuicao(checking, tipo) {
        switch (tipo) {
            case 'contribuicao':
                if (checking) {
                    setContribuicoesSelecionadas(pendenciasContribuicao)
                } else {
                    setContribuicoesSelecionadas([])
                }
                break
            case 'contribuicao_plano':
                if (checking) {
                    setContribuicoesPlanoSelecionadas(pendenciasPlanoSaude)
                } else {
                    setContribuicoesPlanoSelecionadas([])
                }
                break
            default: break
        }
    }

    function getValorPendenciaComJuros(selecionadas, taxa) {
        const somatoria = selecionadas?.length > 0 ? selecionadas.reduce((total, current) => total + Number(current.valor), 0) : 0

        const juros = (somatoria / 100) * taxa

        return somatoria + juros || 0
    }

    async function handleDispensarAlertaEnquetes() {
        try {
            setDispensandoEnquetes(true)

            await api.post('enquete/dispensar', {}, authHeaders())

            toast.success('Você não será mais alertado a respeito das enquetes atuais.')

            setAvisoEnquetes(null)
        } catch (e) {
            toast.error(e.msg)
        }

        setDispensandoEnquetes(false)
    }

    async function handleDispensarAlertaEleicoes() {
        try {
            setDispensandoEleicoes(true)

            await api.post('eleicao/dispensar', {}, authHeaders())

            toast.success('Você não será mais alertado a respeito das eleições atuais.')

            setAvisoEleicoes(null)
        } catch (e) {
            toast.error(e.msg)
        }

        setDispensandoEleicoes(false)
    }

    return (
        <>
            <Container>
                {![2, 16, 21, 22].includes(profile) ? (
                    <>
                        <img src={clienteRobot?.logo} alt="" className="animated fadeInUp faster object-contain" />

                        <h1 className="animated fadeInUp faster delay-600ms">Seja bem-vindo!</h1>
                    </>
                ) : (
                    <ResumoAssociado showContribuicoes={!!new URLSearchParams(location.search).get('contribuicoes')} />
                )}
            </Container>

            <Modal
                isOpen={showModalNotificacoes}
                handleClose={() => { setShowModalNotificacoes(false) }}
                title={`Seja bem-vind${pendencias?.[0].associado.pessoa.sexo === 'F' ? 'a' : 'o'} de volta!`}
            >
                <ModalPendenciasContainer>
                    <p>{`Olá, ${stringUtils.reduceName(user?.pessoa.nome)}!`}</p>
                    <p>{`Vi que você já tem cadastro no SINDMEPA. É bom tê-l${pendencias?.[0].associado.pessoa.sexo === 'F' ? 'a' : 'o'} de volta!`}</p>
                    <p>No entanto, consultei seu histórico de contribuições e vi que você possui algumas pendências.</p>

                    {!pendencias && (
                        <Spinner label="Consultando histórico de pagamentos..." fontSize={14} />
                    )}

                    {pendenciasContribuicao !== null && pendenciasContribuicao.length > 0 ? (
                        <PendenciasContainer>
                            <Table
                                tableId="tabela-contribuicoes"
                                headers={[
                                    { name: 'competencia', value: 'Competência', centered: true },
                                    { name: 'tipo', value: 'Tipo', centered: true },
                                    { name: 'valor_formatado', value: 'Valor', centered: true }
                                ]}
                                data={pendenciasContribuicao}
                                itemsByPage={10}
                                footers={[
                                    {
                                        colspan: 1,
                                        text: 'TOTAL'
                                    },
                                    {
                                        colspan: 2,
                                        text: `(com ${jurosContribuicao}% de juros)`,
                                        style: {
                                            fontSize: 12,
                                            fontWeight: 300
                                        }
                                    },
                                    {
                                        colspan: 1,
                                        text: masks.maskApply.currency(getValorPendenciaComJuros(contribuicoesSelecionadas, jurosContribuicao)),
                                        align: 'center'
                                    }
                                ]}
                                style={{ gridColumn: pendenciasPlanoSaude?.length > 0 ? 1 : '1/span 2' }}
                                hideOffset={565}
                                checkable
                                onCheckItem={item => { onCheckContribuicao(item, 'contribuicao') }}
                                onUncheckItem={item => { onUncheckContribuicao(item, 'contribuicao') }}
                                toggleCheckAll={status => { onCheckAllContribuicao(status, 'contribuicao') }}
                            />

                            <Button
                                onClick={() => { gerarFaturaPendencias('contribuicao') }}
                                className="white tooltip-elem"
                                style={{ gridColumn: pendenciasPlanoSaude?.length > 0 ? 1 : '1/span 2' }}
                                disabled={!contribuicoesSelecionadas?.length}
                            >
                                Quitar pagamentos pendentes
                                <FaRegCheckCircle size={18} />
                            </Button>

                            {contribuicoesSelecionadas?.length > 0 && (
                                <div className="tooltip-message">
                                    Clique para gerar uma fatura contendo apenas as contribuições selecionadas acima.
                                </div>
                            )}
                        </PendenciasContainer>
                    ) : (
                        <></>
                    )}

                    {pendenciasPlanoSaude !== null && pendenciasPlanoSaude.length > 0 ? (
                        <PendenciasContainer>
                            <Table
                                tableId="tabela-plano"
                                headers={[
                                    { name: 'competencia', value: 'Competência', centered: true },
                                    { name: 'tipo', value: 'Tipo', centered: true },
                                    { name: 'valor_formatado', value: 'Valor', centered: true }
                                ]}
                                data={pendenciasPlanoSaude}
                                itemsByPage={10}
                                footers={[
                                    {
                                        colspan: 1,
                                        text: 'TOTAL'
                                    },
                                    {
                                        colspan: 2,
                                        text: `(com ${jurosPlano}% de juros)`,
                                        style: {
                                            fontSize: 12,
                                            fontWeight: 300
                                        }
                                    },
                                    {
                                        colspan: 1,
                                        text: masks.maskApply.currency(getValorPendenciaComJuros(contribuicoesPlanoSelecionadas, jurosPlano)),
                                        align: 'center'
                                    }
                                ]}
                                style={{ gridColumn: pendenciasContribuicao?.length > 0 ? 2 : '1/span 2' }}
                                hideOffset={565}
                                checkable
                                onCheckItem={item => { onCheckContribuicao(item, 'contribuicao_plano') }}
                                onUncheckItem={item => { onUncheckContribuicao(item, 'contribuicao_plano') }}
                                toggleCheckAll={status => { onCheckAllContribuicao(status, 'contribuicao_plano') }}
                            />

                            <Button
                                onClick={() => { gerarFaturaPendencias('plano_saude') }}
                                className="white tooltip-elem"
                                style={{ gridColumn: pendenciasContribuicao?.length > 0 ? 2 : '1/span 2' }}
                                disabled={!contribuicoesPlanoSelecionadas?.length}
                            >
                                Quitar plano de saúde
                                <FaRegCheckCircle size={18} />
                            </Button>

                            {contribuicoesPlanoSelecionadas?.length > 0 && (
                                <div className="tooltip-message">
                                    Clique para gerar uma fatura contendo apenas as contribuições selecionadas acima.
                                </div>
                            )}
                        </PendenciasContainer>
                    ) : (
                        <></>
                    )}

                    <p>Para poder usufruir dos benefício e vantagens disponibilizados pelo sindicato, você precisa quitar as pendências acima.</p>
                    <p>Para isso, efetue o pagamento no valor total mostrado acima ou entre em contato conosco para negociar o pagamento.</p>

                    <div className="button-container">
                        <a className="button transparent" href={`https://api.whatsapp.com/send?phone=559199893486&text=Olá, sou ${user?.pessoa.nome}${user?.associado?.crm ? ` (CRM: ${user.associado.crm})` : ''}. Voltei a me associar e preciso quitar minhas pendências.`} target="_blank" rel="noreferrer noopener">
                            Negociar quitação
                            <FaWhatsapp size={20} />
                        </a>
                    </div>
                </ModalPendenciasContainer>
            </Modal>

            <Modal
                isOpen={!!avisoEnquetes}
                handleClose={() => {
                    setAvisoEnquetes(null)
                }}
                title="Novas enquetes"
            >
                <ModalEnqueteContainer>
                    <p dangerouslySetInnerHTML={{ __html: `Há <b style="font-size: 19px">${pluralize(avisoEnquetes?.length, 'enquete aberta', 'enquetes abertas')}</b> para votação. Que tal participar? Contamos com a sua colaboração.` }} />

                    <div>
                        <Button
                            className="transparent"
                            onClick={handleDispensarAlertaEnquetes}
                            loading={dispensandoEnquetes}
                        >
                            Dispensar
                        </Button>

                        <Button
                            className="white"
                            onClick={() => {
                                history.push('/enquete/participacao')
                            }}
                        >
                            Participar agora
                            <FiArrowRight size={16} />
                        </Button>
                    </div>
                </ModalEnqueteContainer>
            </Modal>

            <Modal
                isOpen={!!avisoEleicoes}
                handleClose={() => {
                    setAvisoEleicoes(null)
                }}
                title="Novas eleições"
            >
                <ModalEleicaoContainer>
                    <p dangerouslySetInnerHTML={{ __html: `Há <b style="font-size: 19px">${pluralize(avisoEleicoes?.length, 'eleição', 'eleições')}</b> em curso. Não deixe de votar. É muito importante para o SINDMEPA contar com sua participação.` }} />

                    <div>
                        <Button
                            className="transparent"
                            onClick={handleDispensarAlertaEleicoes}
                            loading={dispensandoEleicoes}
                        >
                            Dispensar
                        </Button>

                        <Button
                            className="white"
                            onClick={() => {
                                history.push('/eleicao/voto')
                            }}
                        >
                            Participar agora
                            <FiArrowRight size={16} />
                        </Button>
                    </div>
                </ModalEleicaoContainer>
            </Modal>
        </>
    )
}
