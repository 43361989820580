import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border: solid 1px #fff;
	border-radius: 4px;
	padding: 8px;
	padding-bottom: 14px;
	margin-top: 16px;

	h1 {
		font-size: 15px;
		font-weight: 600;
	}
`
