import React, { createContext, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

import formUtils from '../util/form'

export const ColaboradorContext = createContext()

const ColaboradorProvider = ({ children }) => {
    const [colaboradores, setColaboradores] = useState(null)
    const [colaboradorEdicao, setColaboradorEdicao] = useState(null)
    const [showCadastro, setShowCadastro] = useState(false)
    const [colaboradorDesligamento, setColaboradorDesligamento] = useState(null)
    const [colaboradorDetalhes, setColaboradorDetalhes] = useState(null)

    async function loadColaboradores() {
        const response = await api.get('colaborador', authHeaders())

        setColaboradores(response.map(colaborador => {
            let background = 'unset'

            if (colaborador.data_desligamento) {
                background = '#dfdfdf'
            } else if (!colaborador.pessoa.documentos.length) {
                background = '#fff59d'
            }

            return {
                id: colaborador.id,
                ...colaborador,
                data_admissao: colaborador.data_admissao ? format(new Date(colaborador.data_admissao), 'dd/MM/yyyy') : '',
                data_desligamento: colaborador.data_desligamento ? format(new Date(colaborador.data_desligamento), 'dd/MM/yyyy') : '',
                nome: colaborador.pessoa.nome,
                setor_id: colaborador.setor.id,
                setor: colaborador.setor.descricao,
                cpf: colaborador.pessoa.documentos.find(doc => doc.tipo_documento.id === 2)?.identificador || '-',
                background
            }
        }))
    }

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)

            if (colaboradorEdicao) {
                await api.put(`colaborador/${colaboradorEdicao.id}`, values, authHeaders())

                toast.success('Funcionário atualizado.')

                setColaboradorEdicao(null)
            } else {
                await api.post('colaborador', values, authHeaders())

                toast.success('Funcionário cadastrado.')

                setShowCadastro(false)
            }

            loadColaboradores()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDesligarColaborador(values) {
        try {
            values = formUtils.extractFormValues(values)

            await api.put(`colaborador/${colaboradorDesligamento.id}/desligar`, values, authHeaders())

            toast.success('O colaborador foi desligado.')

            setColaboradorDesligamento(null)

            loadColaboradores()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function reloadColaboradorEdicao() {
        const colaborador = await api.get(`colaborador/${colaboradorEdicao.id}`, authHeaders())

        setColaboradorEdicao({
            id: colaborador.id,
            ...colaborador,
            data_admissao: colaborador.data_admissao ? format(new Date(colaborador.data_admissao), 'dd/MM/yyyy') : '',
            data_desligamento: colaborador.data_desligamento ? format(new Date(colaborador.data_desligamento), 'dd/MM/yyyy') : '',
            nome: colaborador.pessoa.nome,
            setor_id: colaborador.setor.id,
            setor: colaborador.setor.descricao,
            cpf: colaborador.pessoa.documentos.find(doc => doc.tipo_documento.id === 2)?.identificador || '-',
            background: colaborador.data_desligamento ? '#dfdfdf' : 'unset'
        })
    }

    useEffect(() => {
        loadColaboradores()
    }, [])

    return (
        <ColaboradorContext.Provider
            value={{
                colaboradores,
                setColaboradorEdicao,
                colaboradorEdicao,
                setShowCadastro,
                showCadastro,
                handleSubmit,
                setColaboradorDesligamento,
                colaboradorDesligamento,
                handleDesligarColaborador,
                colaboradorDetalhes,
                setColaboradorDetalhes,
                reload: () => {
                    reloadColaboradorEdicao()

                    loadColaboradores()
                }
            }}
        >
            {children}
        </ColaboradorContext.Provider>
    )
}

export default ColaboradorProvider
