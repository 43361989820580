import React from 'react'
import { toast } from 'react-toastify'

import Table from '../../../../../components/Table'
import SmallScreenPlaceholder from '../../../../../components/SmallScreenPlaceholder'

import useEventListener from '../../../../../hooks/useEventListener'

import { Container } from './styles'

const tableHideOffset = 500

export default function ({ fornecedores, handlePrepareEdit, handleDelete }) {
    useEventListener('change', () => {
        const horizontal = [90, 270].includes(window.screen.orientation.angle)

        if (horizontal && tableHideOffset > window.innerHeight) {
            toast.info('Infelizmente seu dispositivo não tem dimensões suficientes para visualização dos dados. Consulte a versão web.', { autoClose: 6000 })
        }
    }, window.screen.orientation)

    return (
        <Container>
            <>
                <SmallScreenPlaceholder
                    text="Gire o dispositivo para visualizar os parceiros."
                    hideOffset={600}
                />

                <Table
                    headers={[
                        { name: 'nome', value: 'Nome' }
                    ]}
                    data={fornecedores.map(({ id, pessoa }) => ({
                        id,
                        nome: pessoa.nome
                    }))}
                    filterable
                    hideOffset={600}
                    handlePrepareEdit={handlePrepareEdit}
                    handleDelete={handleDelete}
                    filterPosition="left"
                    confirmExclusion={{
                        attr: 'nome',
                        template: 'Deseja realmente excluir o parceiro #attr#?'
                    }}
                />
            </>
        </Container>
    )
}
