import React, {
    useEffect, useState, useCallback, useContext
} from 'react'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { Formik, Form } from 'formik'
import {
    FiSearch, FiEye, FiEdit, FiFile, FiPlusCircle, FiAlertTriangle
} from 'react-icons/fi'
import ReactTooltip from 'react-tooltip'

import Yup from '../../../../services/yup'
import { api, authHeaders } from '../../../../services/api'

import {
    Textbox, Button, Select,
    Calendar
} from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import Modal from '../../../../components/Modal'
import GerenciamentoDocumentos from '../../../../components/GerenciamentoDocumentos'
import Table from '../../../../components/Table'
import MenuOptions from '../../../../components/MenuOptions'

import arrayUtils from '../../../../util/array'
import scrollUtils from '../../../../util/scroll'
import formUtils from '../../../../util/form'
import maskUtils from '../../../../util/masks'
import { stringToDate, getDateString, getDate } from '../../../../util/date'

import BoxDadosPessoais from '../../../../boxes/EspacoMedico/DadosPessoais'
import BoxEndereco from '../../../../boxes/EspacoMedico/Endereco'
import BoxContatos from '../../../../boxes/EspacoMedico/Contatos'
import BoxMediaSocial from '../../../../boxes/EspacoMedico/MediaSocial'
import BoxArquivos from '../../../../boxes/Arquivos'
import BoxObservacao from '../../../../boxes/Observacao'

import avatarDefault from '../../../../assets/images/avatar.png'

import {
    Container, Profile, Avatar, DadosContainer, SearchFormContainer, ResultTableContainer, ModalEdicaoStatusContainer, BoxDocumentos, ModalEdicaoFaculdade, Badge
} from './styles'

import { GlobalContext } from '../../../../contexts/GlobalContext'

import Card from '../../../../components/Card'
import { PlanoSaude } from '../../../../components/Associado'
import { forceDownloadFileFromURL } from '../../../../util/file'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'
import { replaceSpecialChars } from '../../../../util/string'

const validation = Yup.object({
    cpf_academico: Yup.string().cpf(),
    nome: Yup.string()
})

export default function () {
    const { setCodigo } = useContext(TipContext)
    const { user } = useContext(GlobalContext)

    const [pessoa, setPessoa] = useState(null)
    const [searchFields, setSearchFields] = useState({})
    const [searching, setSearching] = useState(false)
    const [showEdicaoAspirante, setShowEdicaoAspirante] = useState(false)
    const [associadosFound, setAssociadosFound] = useState([])
    const [editable, setEditable] = useState(false)
    const [showModalDocumentos, setShowModalDocumentos] = useState(false)
    const [showEdicaoFaculdade, setShowEdicaoFaculdade] = useState(false)
    const [faculdades, setFaculdades] = useState(null)
    const [gerandoComprovanteAssociacao, setGerandoComprovanteAssociacao] = useState(false)
    const [showTornarMedico, setShowTornarMedico] = useState(false)
    const [showEdicaoStatusAssociacao, setShowEdicaoStatusAssociacao] = useState(false)
    const [statusAssociacao, setStatusAssociacao] = useState([])
    const [motivosDesligamento, setMotivosDesligamento] = useState([])

    const history = useHistory()

    setTimeout(() => { scrollUtils.toTop() }, 600)

    const loadPessoa = useCallback(async (name_exact = false) => {
        try {
            const { cpf_academico: searchCPF, nome: searchNome } = searchFields

            const associados = await api.get('associado', {
                params: {
                    cpf: searchCPF.trim() || null,
                    nome: searchNome.trim() || null,
                    name_exact: name_exact && searchNome ? 1 : 0,
                    medico: 0
                }
            })

            if (!associados) {
                toast.error('Nenhum acadêmico associado encontrado.')
                return
            }

            if (associados.length > 1) {
                setAssociadosFound(associados)
                return
            }

            const associado = associados[0]

            const response = await api.get(`pessoa/${associado.pessoa.id}`, authHeaders())

            response.dados_academicos = associado.dados_academicos

            const [sindicalizacaoMaisRecente] = response.associado.sindicalizacao.length ? response.associado.sindicalizacao
                .sort(arrayUtils.sort.comparisonFunction('data', 'desc')) : [null]

            response.sindicalizacao = sindicalizacaoMaisRecente?.data ? {
                data: format(new Date(sindicalizacaoMaisRecente.data), 'dd/MM/yyyy'),
                status: sindicalizacaoMaisRecente.status,
                motivo: sindicalizacaoMaisRecente.motivo_desligamento?.descricao
            } : ''

            const nomes = response.nome.split(' ')
            response.nome_reduzido = `${nomes[0]} ${nomes.slice(1, nomes.length - 1).map(sobrenome => `${sobrenome[0]}. `).join(' ')}${nomes[nomes.length - 1]}`

            response.socio = response.associado.status_associacao.id === 3
            response.status = response.associado.status_associacao.descricao

            response.documentos = response.associado.pessoa.documentos

            response.medias = response.associado.medias.map(media => ({
                id: media.associado_media_social.id,
                media_id: media.id,
                icone: media.icone,
                nome: media.nome,
                valor: media.associado_media_social.valor
            }))

            response.endereco = response.associado.pessoa.endereco

            response.contatos = [
                ...response.associado.pessoa.contato.map(contato => ({
                    ...contato,
                    icone: contato.tipo_contato.icone
                }))
            ]

            const rgExtraData = response.documentos?.find(documento => documento.tipo_documento.id === 1)?.extra
            const rg_data_emissao = rgExtraData?.data_emissao || ''
            const rg_orgao_emissor = rgExtraData?.orgao_emissor || ''

            response.dados_pessoais = {
                cpf: response.documentos?.find(documento => documento.tipo_documento.id === 2)?.identificador || '',
                data_nascimento: response.data_nascimento ? format(stringToDate(response.data_nascimento), 'dd/MM/yyyy') : '',
                rg: response.documentos?.find(documento => documento.tipo_documento.id === 1)?.identificador || '',
                rg_data_emissao: rg_data_emissao ? format(stringToDate(`${rg_data_emissao} 00:00:00`), 'dd/MM/yyyy') : '',
                rg_orgao_emissor
            }

            response.observacao = response.associado.observacao

            response.comprovante_matricula_pendente = associado.comprovante_matricula_pendente

            const filtrarPlanoAtivo = c => c.convenio.convenio_area_atuacao_id === 1 && c.status !== 4
            const filtrarPlano = c => c.convenio.convenio_area_atuacao_id === 1

            response.plano_saude = response.associado.conveniosAssociado?.find(filtrarPlanoAtivo)
                || response.associado.conveniosAssociado?.find(filtrarPlano)
                || null

            setPessoa(response)
        } catch (e) {
            console.log(e)

            toast.error(e.msg)
        } finally {
            setSearching(false)
        }
    }, [searchFields])

    async function loadFaculdades() {
        const response = await api.get('faculdade')

        setFaculdades(response.map(faculdade => ({
            label: faculdade.descricao,
            value: faculdade.id
        })))
    }

    async function loadStatusAssociacao() {
        const response = await api.get('associado_status', authHeaders())

        setStatusAssociacao(response
            .filter(status => [3, 5].includes(status.id))
            .map(status => ({
                value: status.id,
                label: status.descricao
            })))
    }

    async function loadMotivosDesligamento() {
        const response = await api.get('motivo_desligamento')

        setMotivosDesligamento(response.map(m => ({
            label: m.descricao,
            value: m.id
        })))
    }

    useEffect(() => {
        const { cpf_academico, nome, name_exact } = searchFields

        if (cpf_academico || nome) {
            loadPessoa(name_exact)
        }
    }, [searchFields, loadPessoa])

    useEffect(() => {
        if (showEdicaoFaculdade) {
            loadFaculdades()
        }
    }, [showEdicaoFaculdade])

    function verDetalhes(associado) {
        setSearchFields({
            name_exact: true,
            cpf_academico: associado.cpf,
            nome: associado.nome
        })
    }

    useEffect(() => {
        if (user) {
            const editavel = ![3, 11, 12].includes(user.perfil.id)

            setEditable(editavel)
        }
    }, [user])

    useEffect(() => {
        loadTips(setCodigo, 'form_associado_ficha_academico')
        loadStatusAssociacao()
        loadMotivosDesligamento()
    }, [])

    async function reload() {
        setPessoa(null)
        setSearching(true)
        setShowModalDocumentos(false)

        await loadPessoa()
    }

    function handleSearch(values) {
        const { cpf_academico, nome } = values

        if (!cpf_academico && !nome) {
            toast.info('Digite um termo de busca.')
            return
        }

        setSearching(true)

        setSearchFields({
            cpf_academico,
            nome,
            name_exact: false
        })
    }

    async function alterarStatusAssociacao(values) {
        try {
            values = formUtils.extractFormValues(values)

            const {
                status, motivo_desligamento, data_falecimento
            } = values

            let body

            switch (status) {
                case 3:
                    body = {
                        status
                    }
                    break

                case 5:
                    body = {
                        status,
                        motivo_desligamento,
                        data_falecimento
                    }
                    break
                default:
                    body = {
                        status
                    }
            }

            await api.put(`associado/${pessoa.associado.id}`, body, authHeaders())

            toast.success('Status alterado com sucesso.')

            setShowEdicaoStatusAssociacao(false)

            loadPessoa()
        } catch (e) {
            console.log(e)

            toast.error(e.message)
        }
    }

    async function alterarStatusAcademico(values) {
        try {
            const { status } = formUtils.extractFormValues(values)

            await api.put(`associado/${pessoa.associado.id}`, {
                aspirante: status
            }, authHeaders())

            toast.success('Alteração realizada com sucesso.')

            setShowEdicaoAspirante(false)

            loadPessoa()
        } catch (e) {
            toast.error(e.message)
        }
    }

    async function tornarMedico(values) {
        const { crm } = values
        try {
            const dadosCFM = await api.get(`consulta_cfm/PA/${Number(crm)}`)

            if (dadosCFM.codigoErro) {
                throw new Error()
            }

            if (replaceSpecialChars(dadosCFM.nome).toUpperCase().trim() !== replaceSpecialChars(pessoa.nome).toUpperCase().trim()) {
                toast.warn(`CRM informado não corresponde ao acadêmico. Nome no CFM: ${dadosCFM.nome.toUpperCase()} , nome no Gestão ${pessoa.nome.toUpperCase()}`)
                return
            }

            if (!dadosCFM) {
                toast.warn('CRM não encontrado. Verifique o número digitado.')
                return
            }
        } catch (e) {
            toast.warn('O sistema de verificação da CFM está indisponível. Por favor, tente novamente dentro de alguns minutos.')
            return
        }

        try {
            await api.put(`associado/${pessoa.associado.id}`, {
                tornar_medico: true,
                crm
            }, authHeaders())

            toast.success('Alteração realizada com sucesso.')

            setShowTornarMedico(false)

            history.push('/associado/ficha_academico')

            // loadPessoa()
        } catch (e) {
            toast.error(e.message)
        }
    }

    async function updateDocumentos(values) {
        try {
            await api.put(`documentos/${pessoa.id}`, {
                documentos: values.documentos
                    .map(doc => ({
                        arquivo: doc.arquivo,
                        identificador: doc.identificador,
                        extra: doc.validade ? {
                            validade: getDateString(getDate(doc.validade))
                        } : doc.dataEmissao && doc.orgaoEmissor ? {
                            data_emissao: getDateString(getDate(doc.dataEmissao)),
                            orgao_emissor: doc.orgaoEmissor
                        } : doc.orgaoEmissor ? { orgao_emissor: doc.orgaoEmissor }
                            : doc.dataEmissao ? {
                                data_emissao: getDateString(getDate(doc.dataEmissao))
                            } : null,
                        tipo_documento: doc.tipo_documento.value
                    }))
            }, authHeaders())

            toast.success('Documentos atualizados.')

            reload()
        } catch (e) {
            toast.error('Ocorreu um erro ao tentar atualizar os documentos.')
        }
    }

    async function handleUpdateFaculdade(values) {
        values = formUtils.extractFormValues(values)

        try {
            await api.put(`dados_academicos/${pessoa.associado.id}`, values, authHeaders())

            toast.success('Faculdade atualizada.')

            setShowEdicaoFaculdade(false)

            loadPessoa()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleGetComprovanteAssociacao() {
        try {
            setGerandoComprovanteAssociacao(true)

            const { link } = await api.get(`associacao/comprovante/${pessoa.associado.id}`, authHeaders())

            forceDownloadFileFromURL(link)
        } catch (e) {
            toast.error(e.msg)
        } finally {
            setGerandoComprovanteAssociacao(false)
        }
    }

    return (
        <>
            <Container loadedData={!!pessoa}>
                <main className="animated fadeIn">

                    {pessoa ? (
                        <>
                            <Profile>
                                <Avatar
                                    image={pessoa.usuario?.avatar?.link || avatarDefault}
                                    className="animated fadeIn slow"
                                />

                                <h1>{pessoa.nome}</h1>

                                {!pessoa.associado.medico && (
                                    <>
                                        <h3>{`Acadêmic${pessoa.sexo === 'M' ? 'o' : 'a'}`}</h3>

                                        <hr />

                                        {[1, 5].includes(pessoa.associado.status_associacao.id)
                                            && !pessoa.sindicalizacao?.status
                                            && pessoa.sindicalizacao?.data
                                            && pessoa.sindicalizacao?.motivo
                                            && (
                                                <h3 className="badge danger">
                                                    {`Associação cancelada em: ${pessoa.sindicalizacao?.data || ''}`}
                                                    <br />
                                                    {`Motivo: ${pessoa.sindicalizacao?.motivo}`}
                                                    <br />
                                                    {pessoa.sindicalizacao.observacao && `Observação: ${pessoa.sindicalizacao.observacao}`}
                                                </h3>
                                            )}

                                        {(![1, 5].includes(pessoa.associado.status_associacao.id) && pessoa.sindicalizacao?.status
                                            && pessoa.sindicalizacao?.data) && (<h3>{`Associad${pessoa.sexo === 'M' ? 'o' : 'a'} desde: ${pessoa.sindicalizacao?.data || ''}`}</h3>
                                            )}

                                        <MenuOptions
                                            options={
                                                [
                                                    {
                                                        editable,
                                                        icon: FiEdit,
                                                        text: `Aspirante: ${pessoa.associado.aspirante ? 'SIM' : 'NÃO'}`,
                                                        action: () => { setShowEdicaoAspirante(true) }
                                                    },
                                                    {
                                                        editable,
                                                        icon: FiEdit,
                                                        text: `Status: ${pessoa.status}`,
                                                        action: () => { setShowEdicaoStatusAssociacao(true) }
                                                    },
                                                    {
                                                        editable,
                                                        icon: FiEdit,
                                                        text: 'Tornar médico',
                                                        action: () => { setShowTornarMedico(true) }
                                                    },
                                                    {
                                                        editable,
                                                        icon: FiFile,
                                                        text: 'Comprovante de Associação',
                                                        action: handleGetComprovanteAssociacao,
                                                        loading: gerandoComprovanteAssociacao
                                                    },
                                                    {
                                                        editable,
                                                        icon: FiEdit,
                                                        text: `Faculdade: ${pessoa.associado.dados_academicos?.faculdade.descricao || 'Não informado'}`,
                                                        action: () => { setShowEdicaoFaculdade(true) }
                                                    }
                                                ]
                                            }
                                        />

                                        {pessoa.associado.dados_academicos?.data_ingresso && (
                                            <>
                                                <h3>{`Ano de ingresso no curso: ${format(new Date(pessoa.associado.dados_academicos?.data_ingresso), 'yyyy')}`}</h3>
                                                <h3>{`Ano de provável conclusão do curso: ${format(new Date(pessoa.associado.dados_academicos?.data_conclusao), 'yyyy')}`}</h3>
                                            </>
                                        )}
                                    </>
                                )}

                            </Profile>

                            <DadosContainer>
                                {pessoa.comprovante_matricula_pendente && (
                                    <Badge className="animated shake delay-500s">
                                        <FiAlertTriangle size={36} />

                                        <p>O comprovante de matrícula deste acadêmico encontra-se DESATUALIZADO. Atualize o comprovante de matrícula e/ou realize sua associação após formatura.</p>

                                        <div className="button-container">
                                            <Button className="transparent dark" onClick={() => { setShowModalDocumentos(true) }}>
                                                Atualizar agora
                                            </Button>
                                        </div>
                                    </Badge>
                                )}

                                <BoxDadosPessoais pessoa={pessoa} reload={reload} editable={editable} />

                                <BoxEndereco pessoa={pessoa} reload={reload} editable={editable} />

                                <BoxObservacao
                                    pessoa={pessoa}
                                    reload={reload}
                                    editable={editable}
                                />

                                <Card className="animated fadeInUp delay-500ms" style={{ gridArea: 'plano_saude' }}>
                                    <PlanoSaude pessoa={pessoa} />
                                </Card>

                                <BoxContatos pessoa={pessoa} reload={reload} editable={editable} />

                                <BoxMediaSocial pessoa={pessoa} reload={reload} editable={editable} />

                                <BoxDocumentos>
                                    <h1>Documentos</h1>

                                    {editable && (
                                        <FiPlusCircle
                                            data-tip="Adicionar"
                                            data-for="tooltip"
                                            onClick={() => { setShowModalDocumentos(true) }}
                                        />
                                    )}

                                    <BoxArquivos
                                        title={null}
                                        arquivos={pessoa?.documentos.map(doc => ({
                                            ...doc,
                                            link: doc.arquivo?.link || '',
                                            nome: doc.tipo_documento.descricao
                                        }))}
                                    />
                                </BoxDocumentos>
                            </DadosContainer>
                        </>
                    ) : (
                        <>
                            {!searching ? (
                                <SearchFormContainer>
                                    <Formik
                                        initialValues={{ cpf_academico: '', nome: '' }}
                                        validationSchema={validation}
                                        onSubmit={handleSearch}
                                    >
                                        {() => (
                                            <Form>
                                                <h1>Buscar acadêmico</h1>

                                                <Textbox name="cpf_academico" label="CPF" mask={maskUtils.cpf} disableSubmitWithEnter={false} />

                                                <Textbox name="nome" label="Nome" disableSubmitWithEnter={false} />

                                                <Button type="submit" className="transparent" style={{ gridArea: 'button' }}>
                                                    Buscar
                                                    <FiSearch />
                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>

                                    {associadosFound.length > 1 && (
                                        <ResultTableContainer>
                                            <h2>Acadêmicos encontrados</h2>

                                            <Table
                                                style={{ gridArea: 'table' }}
                                                headers={[
                                                    { name: 'cpf', value: 'CPF', centered: true },
                                                    { name: 'nome', value: 'Nome' }
                                                ]}
                                                data={associadosFound.map(associado => ({
                                                    id: associado.id,
                                                    cpf: associado.pessoa.documentos?.find(doc => doc.tipo_documento.id === 2)?.identificador || '',
                                                    nome: associado.pessoa.nome
                                                }))}
                                                actions={[
                                                    {
                                                        name: 'visualizar',
                                                        action: verDetalhes,
                                                        icon: FiEye,
                                                        iconSize: 16
                                                    }
                                                ]}
                                            />
                                        </ResultTableContainer>
                                    )}
                                </SearchFormContainer>
                            ) : (
                                <Spinner
                                    type="Rings"
                                    size={60}
                                    color="#676767"
                                    label="Buscando..."
                                    containerClass="animated fadeInUp fast"
                                />
                            )}
                        </>
                    )}
                </main>
            </Container>

            <ReactTooltip
                id="tooltip"
                place="top"
                effect="solid"
                type="light"
            />

            <Modal
                isOpen={showEdicaoAspirante}
                handleClose={() => setShowEdicaoAspirante(false)}
                title="Tornar acâdemico aspirante"
            >
                <Formik
                    onSubmit={alterarStatusAcademico}
                    initialValues={{ status: null }}
                    validationSchema={Yup.object({
                        status: Yup.string().ensure().required('Selecione o novo status.')
                    })}
                >
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form style={{ height: values.motivo_desligamento?.value === 3 ? 360 : 'auto' }}>
                            <ModalEdicaoStatusContainer>
                                <Select
                                    name="status"
                                    label="Aspirante?"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={[
                                        { label: 'Sim', value: 1 },
                                        { label: 'Não', value: 0 }
                                    ]}
                                />

                                <Button type="submit" className="white" loading={isSubmitting}>
                                    Alterar
                                </Button>
                            </ModalEdicaoStatusContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal
                isOpen={showModalDocumentos}
                handleClose={() => { setShowModalDocumentos(false) }}
                title="Gerenciamento de documentos"
            >
                <GerenciamentoDocumentos
                    handleSubmit={updateDocumentos}
                    documentos={pessoa?.documentos.map(doc => ({
                        id: doc.id,
                        arquivo: doc.arquivo,
                        tipo_documento: doc.tipo_documento,
                        extra: doc.extra,
                        identificador: doc.identificador
                    }))}
                    categoria="academico"
                />
            </Modal>

            <Modal
                isOpen={showEdicaoFaculdade}
                handleClose={() => { setShowEdicaoFaculdade(false) }}
                title="Definir faculdade"
            >
                <Formik
                    initialValues={{
                        faculdade: pessoa?.associado.dados_academicos?.faculdade ? {
                            label: pessoa?.associado.dados_academicos?.faculdade.descricao,
                            value: pessoa?.associado.dados_academicos?.faculdade.id
                        } : null,
                        inicio: pessoa?.associado.dados_academicos?.data_ingresso
                            ? format(getDate(pessoa?.associado.dados_academicos.data_ingresso), 'dd/MM/yyyy')
                            : '',
                        fim: pessoa?.associado.dados_academicos?.data_conclusao
                            ? format(getDate(pessoa?.associado.dados_academicos.data_conclusao), 'dd/MM/yyyy')
                            : ''
                    }}
                    onSubmit={handleUpdateFaculdade}
                    validationSchema={Yup.object({
                        faculdade: Yup.string().ensure().required('Selecione a faculdade.'),
                        inicio: Yup.string().date().required('Preencha a data.'),
                        fim: Yup.string().date().required('Preencha a data.')
                    })}
                >
                    {({ setFieldValue, isSubmitting }) => (
                        <Form>
                            <ModalEdicaoFaculdade>
                                <Select
                                    label="Faculdade"
                                    name="faculdade"
                                    options={faculdades || []}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                <Textbox
                                    name="inicio"
                                    label="Ano de ingresso"
                                    mask={maskUtils.date}
                                />

                                <Textbox
                                    name="fim"
                                    label="Ano de conclusão"
                                    mask={maskUtils.date}
                                />

                                <Button className="transparent" type="submit" loading={isSubmitting}>
                                    Alterar
                                </Button>
                            </ModalEdicaoFaculdade>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal
                isOpen={showTornarMedico}
                handleClose={() => setShowTornarMedico(false)}
                title="Tornar médico"
            >
                <Formik
                    onSubmit={tornarMedico}
                    initialValues={{ crm: null }}
                    validationSchema={Yup.object({
                        crm: Yup.string().nullable().required('Informe o crm.')
                    })}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <ModalEdicaoStatusContainer>
                                <p className="badge">Permitido somente CRMs vinculados ao estado do Pará.</p>

                                <Textbox
                                    name="crm"
                                    label="Informe o crm"
                                />

                                <Button type="submit" className="white" loading={isSubmitting}>
                                    Alterar
                                </Button>
                            </ModalEdicaoStatusContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal
                isOpen={showEdicaoStatusAssociacao}
                handleClose={() => setShowEdicaoStatusAssociacao(false)}
                title="Alteração de status"
                closeOnOverlayClick
            >
                <Formik
                    onSubmit={alterarStatusAssociacao}
                    initialValues={{ status: null }}
                    validationSchema={Yup.object({
                        status: Yup.string().ensure().required('Selecione o novo status.')
                    })}
                >
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form style={{ height: values.motivo_desligamento?.value === 3 ? 360 : 'auto' }} id="form-status">
                            <ModalEdicaoStatusContainer>
                                <Select
                                    name="status"
                                    label="Situação"
                                    options={statusAssociacao}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                {values.status?.value === 5 && (
                                    <Select
                                        name="motivo_desligamento"
                                        label="Motivo da inativação"
                                        options={motivosDesligamento}
                                        onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    />
                                )}

                                {values.status?.value === 5 && values.motivo_desligamento?.value === 3 && (
                                    <Calendar
                                        name="data_falecimento"
                                        label="Data de falecimento"
                                        yearNavigator
                                        maxDate={new Date()}
                                    />
                                )}

                                <div className="button-container">
                                    <Button type="submit" className="white" loading={isSubmitting}>
                                        Salvar alterações
                                    </Button>
                                </div>
                            </ModalEdicaoStatusContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
