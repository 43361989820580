export function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '') // Remove caracteres não numéricos
  
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida
    }
  
    let soma = 0
  
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i)
    }
  
    let resto = soma % 11
    let digitoVerificador1 = (resto < 2) ? 0 : 11 - resto
  
    if (digitoVerificador1 !== parseInt(cpf.charAt(9))) {
        return false // Verifica se o primeiro dígito verificador está correto
    }
  
    soma = 0
  
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i)
    }
  
    resto = soma % 11
    let digitoVerificador2 = (resto < 2) ? 0 : 11 - resto
  
    if (digitoVerificador2 !== parseInt(cpf.charAt(10))) {
        return false // Verifica se o segundo dígito verificador está correto
    }
  
    return true // CPF válido
}
