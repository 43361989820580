export default {
    toTop: async (delay = 0, view = '#root') => {
        let scrolled = false
        const viewElem = document.querySelector(view)

        if(!viewElem) {
            return
        }

        if(!delay) {
            viewElem.scrollIntoView({ behavior: 'smooth' })
            return 
        }

        do {
            try {
                await new Promise((resolve) => {
                    setTimeout(() => resolve(), delay)
                })
                    .then(() => {
                        viewElem.scrollIntoView({ behavior: 'smooth' })
                    })
                
                scrolled = true
            } catch(e) { 
                scrolled = false
            }
        } while(!scrolled)
    },

    toBottom: () => {
        document.querySelector('footer').scrollIntoView({ behavior: 'smooth' })
    },
	
    toElement: (selector) => {
        const element = document.querySelector(selector)
        
        if(element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    },

    toEnd: (selector = 'body') => {
        const element = document.querySelector(selector)

        if(element) {
            element.scroll(0, element.scrollHeight)
        }
    },

    toElementContent: (containerSelector, contentSelector) => {
        const container = document.querySelector(containerSelector)

        if(container) {
            setTimeout(() => {
                container.querySelector(contentSelector).scrollIntoView({ behavior: 'smooth' })
            }, 200)
        }
    }
}
