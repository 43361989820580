import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FaSearch } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import { extname } from '../../../../util/path'
import arrayUtils from '../../../../util/array'
import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'
import Card from '../../../../components/Card'
import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import Modal from '../../../../components/Modal'
import {
    Button, Textbox, Calendar, File
} from '../../../../components/Form'
import ActionFooter from '../../../../components/ActionFooter'
import SmallScreenPlaceholder from '../../../../components/SmallScreenPlaceholder'

import BoxDadosPessoais from '../../../../boxes/EspacoMedico/DadosPessoais'
import BoxPagamento from '../../../../boxes/EspacoMedico/Pagamento'
import BoxLocalTrabalho from '../../../../boxes/EspacoMedico/LocalTrabalho'
import BoxEndereco from '../../../../boxes/EspacoMedico/Endereco'
import BoxContatos from '../../../../boxes/EspacoMedico/Contatos'
import BoxArquivos from '../../../../boxes/Arquivos'
import { Container, ModalContent, ModalConfirmationContent } from './styles'
import masks from '../../../../util/masks'
import formUtils from '../../../../util/form'
import { loadTips } from '../../../../util/tip'

import { TipContext } from '../../../../contexts/TipContext'

const confirmValidation = Yup.object({
    valor: Yup.string().required('Forneça o valor.'),
    competencia: Yup.string().required('Informa a competência.'),
    data: Yup.string().required('Informe a data do pagamento.'),
    comprovante: Yup.number().nullable().required('Anexe o comprovante de pagamento.')
})

const initialValues = {
    valor: '',
    competencia: '',
    data: '',
    comprovante: null
}

export default function () {
    const { setCodigo } = useContext(TipContext)

    const [pendencias, setPendencias] = useState(null)
    const [associado, setAssociado] = useState(null)
    const [showConfirmacao, setShowConfirmacao] = useState(false)
    const [showConfirmacaoResidente, setShowConfirmacaoResidente] = useState(false)
    const [aprovando, setAprovando] = useState(false)

    async function loadPendencias() {
        const response = await api.get('associado', {
            params: {
                status: [2, 6],
                medico: 1
            }
        })

        setPendencias(response ? response.map(pendencia => ({
            id: pendencia.id,
            crm: pendencia.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.identificador,
            nome: pendencia.pessoa.nome,
            forma_pagamento: pendencia.tipo_pagamento.descricao_resumida,
            instituicao: pendencia.dados_bancarios?.instituicao.sigla,
            residente: pendencia.residente ? 'Sim' : 'Não',
            situacao: pendencia.status_associacao.descricao,
            data_submissao: format(new Date(arrayUtils.get.getLast(pendencia.sindicalizacao.filter(sindicalizacao => sindicalizacao.status)).data), 'dd/MM/yyy')
        })) : [])
    }

    useEffect(() => {
        loadPendencias()
    }, [])

    useEffect(() => {
        loadTips(setCodigo, 'form_associado_aprovacao_associacao')
    }, [])

    async function handleShowAssociacao(associacaoLinha) {
        const partialResponse = await api.get('associado', {
            params: {
                crm: associacaoLinha.crm
            }
        })

        if (!partialResponse) {
            toast.error('Nenhuma associação pendente para este CRM.')
        }

        const response = partialResponse[0]

        response.nome = response.pessoa.nome
        response.crm = response.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.identificador || ''
        response.associado = response

        response.situacao = {
            descricao: response.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.extra?.situacao,
            icone: response.pessoa.documentos.find(doc => doc.tipo_documento.id === 3)?.extra?.situacao === 'Regular' ? 'FiCheckCircle' : 'FiXCircle'
        }

        response.especialidades = !response.especialidades?.length ? [] : response.especialidades.map(espec => espec.descricao)

        response.locais_trabalho = [
            ...response.locais_trabalho.map(local => {
                const {
                    rua, numero, complemento, bairro, cidade, uf, cep
                } = local.endereco

                return {
                    ...local,
                    endereco: `${rua}, ${numero}, ${complemento} - ${bairro} - ${cidade} - ${uf} - ${cep}`,
                    editable: false
                }
            }),
            ...response.instituicoes.filter(instituicao => !instituicao.associado_instituicao.desconto).map(instituicao => ({
                id: instituicao.id,
                nome: instituicao.razao_social,
                endereco: '',
                editable: false
            }))
        ]

        response.documentos = response.pessoa.documentos

        response.endereco = response.pessoa.endereco

        const rgExtraData = response.documentos?.find(documento => documento.tipo_documento.id === 1)?.extra
        const rg_data_emissao = rgExtraData ? (JSON.parse(rgExtraData)?.data_emissao || '') : ''
        const rg_orgao_emissor = rgExtraData ? (JSON.parse(rgExtraData)?.orgao_emissor || '') : ''

        response.dados_pessoais = {
            cpf: response.pessoa.documentos?.find(documento => documento.tipo_documento.id === 2)?.identificador || '',
            data_nascimento: response.pessoa.data_nascimento ? format(new Date(response.pessoa.data_nascimento), 'dd/MM/yyyy') : '',
            rg: response.pessoa.documentos?.find(documento => documento.tipo_documento.id === 1)?.identificador || '',
            rg_data_emissao: rg_data_emissao ? format(new Date(rg_data_emissao), 'dd/MM/yyyy') : '',
            rg_orgao_emissor
        }

        const tipoPagamento = response?.tipo_pagamento

        const dadosBancarios = response?.dados_bancarios

        const dadosInstituicao = response?.instituicoes?.find(instituicao => instituicao.associado_instituicao.desconto)

        response.pagamento = {
            tipo: tipoPagamento,
            dados_bancarios: tipoPagamento?.id === 1 && dadosBancarios ? {
                ...dadosBancarios,
                instituicao: dadosBancarios.instituicao
            } : null,
            dados_instituicao: tipoPagamento?.id === 2 && dadosInstituicao ? {
                ...dadosInstituicao,
                matricula: dadosInstituicao.associado_instituicao.matricula
            } : null
        }

        response.contatos = [
            ...response.pessoa.contato.map(contato => ({
                ...contato,
                icone: contato.tipo_contato.icone
            }))
        ]

        setAssociado(response)
    }

    function handleShowConfirmation() {
        if (associado.tipo_pagamento.id === 5) {
            setShowConfirmacaoResidente(true)
        } else {
            setShowConfirmacao(true)
        }
    }

    async function handleAprovarAssociacao(values) {
        setAprovando(true)

        try {
            if (!associado) {
                toast.error('Ocorreu um erro ao aprovar a associação do médico.')
            }

            if (associado.tipo_pagamento.id === 5) {
                await api.post(`associacao_aprovacao/${associado.id}`, null, authHeaders())
            } else {
                values = formUtils.extractFormValues(values)
                await api.post(`associacao_aprovacao/${associado.id}`, values, authHeaders())
            }

            loadPendencias()

            setShowConfirmacao(false)
            setShowConfirmacaoResidente(false)
            setAssociado(null)

            toast.success('Associação aprovada. O médico receberá um e-mail com orientações.')
        } catch (e) {
            toast.error(e.msg || e)
        } finally {
            setAprovando(false)
        }
    }

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Associações pendentes</h1>

                        <SmallScreenPlaceholder hideOffset={920} />

                        {pendencias ? (
                            <Table
                                headers={[
                                    {
                                        name: 'crm', value: 'Matrícula', centered: true, style: { width: 80 }
                                    },
                                    { name: 'nome', value: 'Nome' },
                                    {
                                        name: 'forma_pagamento', value: 'Pagamento', centered: true, style: { width: 150 }
                                    },
                                    {
                                        name: 'instituicao', value: 'Instituição', centered: true, style: { width: 150 }
                                    },
                                    {
                                        name: 'situacao', value: 'Situação', centered: true, style: { width: 130 }
                                    },
                                    {
                                        name: 'data_submissao', value: 'Data de submissão', centered: true, style: { width: 180 }
                                    }
                                ]}
                                data={pendencias}
                                actions={[
                                    {
                                        action: handleShowAssociacao,
                                        name: 'Abrir',
                                        icon: FaSearch
                                    }
                                ]}
                                hideOnSmallHeaders={['forma_pagamento', 'residente', 'data_submissao']}
                                hideOffset={920}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!associado}
                handleClose={() => {
                    setAssociado(null)
                }}
                title="Dados da associação"
            >
                <ModalContent>
                    <h2>
                        <span>{associado?.nome}</span>
                        <span className="badge">
                            {`CRM: ${associado?.crm}`}
                        </span>
                    </h2>

                    <Button type="button" className="transparent" style={{ gridArea: 'buttonTop' }} onClick={handleShowConfirmation}>
                        Aprovar associação
                    </Button>

                    <BoxDadosPessoais pessoa={associado} editable={false} />

                    <BoxPagamento pessoa={associado} showEditIcon={false} enableEdit />

                    <BoxLocalTrabalho pessoa={associado} editable={false} />

                    <BoxEndereco pessoa={associado} editable={false} />

                    <BoxContatos pessoa={associado} editable={false} />

                    <Card style={{ gridArea: 'arquivos' }} className="animated fadeInUp delay-600ms">
                        <BoxArquivos
                            arquivos={
                                associado?.documentos.filter(documento => documento.arquivo?.link).map(documento => ({
                                    nome: documento.tipo_documento.descricao,
                                    link: documento.arquivo?.link,
                                    extensao: extname(documento.arquivo?.link)
                                })) || []
                            }
                        />
                    </Card>

                    <ActionFooter>
                        <Button type="button" className="transparent" onClick={handleShowConfirmation}>
                            Aprovar associação
                        </Button>
                    </ActionFooter>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={showConfirmacao && associado}
                handleClose={() => {
                    setShowConfirmacao(false)
                    setAssociado(null)
                }}
                title="Confirmação"
                style={{ width: 'fit-content' }}
                closeOnOverlayClick={false}
            >
                <ModalConfirmationContent>
                    <Formik
                        onSubmit={handleAprovarAssociacao}
                        initialValues={initialValues}
                        validationSchema={confirmValidation}
                    >
                        {({ errors, setFieldValue }) => (
                            <Form>
                                <File
                                    name="comprovante"
                                    onSuccess={fileId => setFieldValue('comprovante', fileId)}
                                    placeholderText="Enviar comprovante"
                                    label="Comprovante de pagamento"
                                    format="square"
                                    previewSize={['100%', '240px']}
                                    style={{ gridArea: 'anexo' }}
                                    error={errors.comprovante}
                                />

                                <Textbox
                                    name="valor"
                                    label="Valor"
                                    mask={masks.money}
                                />

                                <Calendar
                                    name="competencia"
                                    dateFormat="mm/yy"
                                    yearNavigator
                                    view="month"
                                    label="Competência"
                                />

                                <Calendar
                                    name="data"
                                    yearNavigator
                                    label="Data"
                                />

                                <p className="badge">Ao confirmar, o médico receberá um e-mail com orientações sobre os procedimentos necessários.</p>

                                <ActionFooter>
                                    <Button type="submit" className="white" disabled={aprovando}>
                                        Confirmar associação
                                    </Button>
                                </ActionFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalConfirmationContent>
            </Modal>

            <Modal
                isOpen={showConfirmacaoResidente && associado}
                handleClose={() => {
                    setShowConfirmacaoResidente(false)
                    setAssociado(null)
                }}
                title="Confirmação"
                style={{ width: 'fit-content' }}
            >
                <ModalConfirmationContent>
                    <p style={{ margin: 8 }}>{`Tem certeza que deseja aprovar a associação do médico ${associado?.nome}?`}</p>
                    <p className="badge">Ao clicar em SIM, o médico receberá um e-mail com orientações sobre os procedimentos necessários.</p>

                    <ActionFooter>
                        <Button
                            type="button"
                            className="transparent"
                            onClick={() => {
                                setShowConfirmacaoResidente(false)
                                setAssociado(null)
                            }}
                            disabled={aprovando}
                        >
                            Não
                        </Button>

                        <Button type="button" className="white" onClick={handleAprovarAssociacao} disabled={aprovando}>
                            Sim
                        </Button>
                    </ActionFooter>
                </ModalConfirmationContent>
            </Modal>
        </>
    )
}
