import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'gerenciador-documentos-container'
})`
	form {
		display: flex;
		flex-direction: column;

		> button {
			align-self: flex-end;
		}
	}
`

export const DocumentosContainer = styled.div`
	grid-area: arquivos;
	display: grid;
	margin-bottom: 20px;
	grid-template-columns: 1.4fr 1fr 1.6fr;
	grid-template-rows: 68px 68px 68px auto auto;
	grid-template-areas: 
		'anexo tipo table'
		'anexo f1 table'
		'anexo f2 table'
		'anexo f3 table'
		'anexo f4 table'
		'anexo button table';

	> h1 {
		grid-area: title;
		align-self: flex-end;
	}

	.select {
		grid-area: tipo;
	}

	.file {
		grid-area: anexo;
		align-self: flex-start;
	}

	.table-container {
		grid-area: table;
		width: calc(100% - 8px);
		justify-self: flex-end;
		margin-top: 24px;
	}

	> button {
		grid-area: button;
		align-self: flex-start;
	}
`

export const ModalContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 14px;

	p {
		text-align: left;
		width: 100%;
		padding: 0 8px 12px !important;
	}

	img, 
	iframe {
		width: 100%;
	}

	iframe {
		height: 75vh;
	}
`
