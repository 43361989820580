import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'

import { Container, ResultadoContainer } from './styles'
import Card from '../../components/Card'
import { api } from '../../services/api'
import Spinner from '../../components/Spinner'
import { Button, Textbox } from '../../components/Form'
import avatarDefault from '../../assets/images/foto_medico.png'
import masks from '../../util/masks'

export default function () {
    const { id } = useParams()

    const identificadorRef = useRef()

    const [eleicao, setEleicao] = useState(null)
    const [resultado, setResultado] = useState(null)
    const [searchByCPF, setSearchByCPF] = useState(false)

    async function loadEleicao() {
        const response = await api.get(`eleicao/${id}/publico`)

        setEleicao(response)
    }

    async function handleCheck({ identificador }) {
        try {
            const response = await api.get(`eleicao/${id}/verificacao/${identificador}`)

            setResultado(response)

            setSearchByCPF(false)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadEleicao()
    }, [])

    useEffect(() => {
        if (identificadorRef) {
            const input = document.querySelector('[name=identificador]')

            setTimeout(() => {
                input?.setSelectionRange(8, 8)
                input?.focus()
            }, 130)
        }
    }, [searchByCPF])

    if (!eleicao) {
        return <Spinner />
    }

    return (
        <Container>
            <Card>
                <h1>
                    {eleicao.descricao}
                </h1>

                {resultado ? (
                    <ResultadoContainer>
                        <div>
                            <img src={resultado.foto || avatarDefault} alt={resultado.nome} />
                            <h2>{resultado.nome}</h2>
                            {resultado.crm && <p dangerouslySetInnerHTML={{ __html: `CRM: <b>${resultado.crm}</b>` }} />}
                            <p dangerouslySetInnerHTML={{ __html: `CPF: <b>${resultado.cpf}</b>` }} />
                            <p dangerouslySetInnerHTML={{ __html: `Status da participação: <b>${resultado.status ? 'VOTO VÁLIDO' : 'NÃO VOTOU'}</b>` }} />
                            {resultado.status && <p dangerouslySetInnerHTML={{ __html: `Data/hora do voto: <b>${resultado.data_hora}</b>` }} />}
                        </div>

                        <Button onClick={() => { setResultado(null) }} className="transparent">
                            Realizar nova consulta
                        </Button>
                    </ResultadoContainer>
                ) : (
                    <Formik
                        onSubmit={handleCheck}
                        initialValues={{ identificador: '' }}
                    >
                        {({ setFieldValue }) => (
                            <Form>
                                <p>Verifique abaixo a participação dos eleitores por CRM.</p>

                                <Textbox
                                    name="identificador"
                                    label="CRM ou CPF do eleitor"
                                    onChange={e => {
                                        setFieldValue('identificador', e.target.value)

                                        setSearchByCPF(e.target.value.length > 5)
                                    }}
                                    mask={searchByCPF ? masks.cpf : undefined}
                                    ref={identificadorRef}
                                    disableSubmitWithEnter={false}
                                />

                                <Button type="submit">
                                    Verificar participação
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card>
        </Container>
    )
}
