import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import {
    FaTimes, FaEye, FaEdit
} from 'react-icons/fa'

import Card from '../../../../components/Card'
import { Button } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'

import Desligamento from '../Desligamento'
import Detalhes from '../Detalhes'

import { ColaboradorContext } from '../../../../contexts/ColaboradorContext'

import { Container } from './styles'

export default function () {
    const {
        colaboradores,
        setColaboradorEdicao,
        setShowCadastro,
        colaboradorDesligamento,
        setColaboradorDesligamento,
        setColaboradorDetalhes,
        colaboradorDetalhes
    } = useContext(ColaboradorContext)

    function handlePrepareEdit(id) {
        const emEdicao = colaboradores.find(c => c.id === id)

        setColaboradorEdicao(emEdicao)
    }

    return (
        <Container>
            <main>
                <Card>
                    <h1>Colaboradores</h1>

                    <div className="button-container">
                        <Button className="transparent" onClick={() => { setShowCadastro(true) }}>
                            Cadastrar
                            <FiPlus size={16} />
                        </Button>
                    </div>

                    {colaboradores ? (
                        <Table
                            headers={[
                                { name: 'matricula', value: 'Matrícula', centered: true },
                                { name: 'nome', value: 'Nome' },
                                { name: 'setor', value: 'Setor', centered: true },
                                { name: 'data_admissao', value: 'Data de admissão', centered: true },
                                { name: 'data_desligamento', value: 'Data de desligamento', centered: true },
                                { name: 'cpf', value: 'CPF', centered: true }
                            ]}
                            data={colaboradores || []}
                            hideOnSmallHeaders={['data_admissao', 'cpf']}
                            hideHeadersOffset={710}
                            hideOffset={450}
                            actions={[
                                {
                                    action: setColaboradorDetalhes,
                                    name: 'Visualizar',
                                    icon: FaEye,
                                    iconSize: 15
                                },
                                {
                                    action: item => { handlePrepareEdit(item.id) },
                                    name: 'Editar',
                                    icon: FaEdit,
                                    iconSize: 14,
                                    checkDisabled: item => !!item.data_desligamento
                                },
                                {
                                    action: setColaboradorDesligamento,
                                    name: 'Desligamento',
                                    icon: FaTimes,
                                    iconSize: 16,
                                    checkDisabled: item => !!item.data_desligamento
                                }
                            ]}
                            legends={[
                                { text: 'Colaboradores desligados', color: '#ccc' },
                                { text: 'Documentação pendente', color: '#fff59d' }
                            ]}
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>

                <Modal
                    isOpen={!!colaboradorDesligamento}
                    handleClose={() => { setColaboradorDesligamento(null) }}
                    title="Desligamento do colaborador"
                >
                    <Desligamento />
                </Modal>

                <Modal
                    isOpen={!!colaboradorDetalhes}
                    handleClose={() => { setColaboradorDetalhes(null) }}
                    title="Detalhes do colaborador"
                >
                    <Detalhes />
                </Modal>
            </main>
        </Container>
    )
}
