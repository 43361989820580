import React from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { Container } from './styles'

import syndi from '../../assets/images/syndi.png'

import Spinner from '../Spinner'
import { Textarea, Button } from '../Form'
import RatingStarts from '../RatingStarts'

import { api } from '../../services/api'

export default function ({ demanda, onFeedbackSubmit }) {
    const alreadyRated = !!demanda?.avaliacao

    async function handleSubmit(values) {
        try {
            const [rating] = [...document.querySelectorAll('input[name=rating]')]
                .map(r => ({
                    id: r.id,
                    value: r.checked
                }))
                .filter(r => r.value)

            await api.post(`atendimento/token/${demanda.token}`, {
                ...values,
                rating: Number(rating.id.split('-')[1])
            })

            toast.success('Obrigado pelo seu feedback! Conte conosco!')

            onFeedbackSubmit?.()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <img src={syndi} alt="Syndi" />

            {!demanda ? (
                <Spinner />
            ) : alreadyRated ? (
                <div className="balloon">
                    <p>Este atendimento já foi avaliado. Obrigada!</p>
                </div>
            ) : (
                <>
                    <div className="balloon">
                        {demanda ? (
                            <>
                                <p>{`Olá, Dr${demanda?.associado.pessoa.sexo === 'F' ? 'ª' : ''} ${demanda?.associado.pessoa.nome}, eu gostaria de contar com sua opinião sobre o nosso atendimento.`}</p>
                                <p>Por favor, qualifique o atendimento de 1 a 5 estrelas.</p>
                            </>
                        ) : (
                            <p>Aguarde...</p>
                        )}
                    </div>

                    {demanda && (
                        <Formik
                            initialValues={{ observacao: '' }}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <RatingStarts />

                                    <Textarea
                                        name="observacao"
                                        label="Digite seu feedback"
                                    />

                                    <Button type="submit" className="transparent" loading={isSubmitting}>
                                        Enviar feedback
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    )}
                </>
            )}
        </Container>
    )
}
