import React from 'react'

import Card from '../../../components/Card'

import RelatorioCustomizadoProvider from '../../../contexts/RelatorioCustomizadoContext'

import Formulario from './Formulario'
import Listagem from './Listagem'

import { Container } from './styles'

export default function GeradorRelatorio() {
    return (
        <RelatorioCustomizadoProvider>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Relatórios customizados</h1>

                        <Formulario />

                        <Listagem />

                    </Card>
                </main>
            </Container>
        </RelatorioCustomizadoProvider>
    )
}
