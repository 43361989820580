import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'form-edicao-evento'
})`
	display: grid;
	grid-template-columns: 2fr 1.6fr 1fr 1fr;
	grid-template-areas:
		'tip tip tip tip'
		'imagem imagem imagem imagem'	
		'f1 f2 f3 f4'
		'f5 f6 f7 f8'
		'descricao descricao programacao programacao'
		'header_endereco header_endereco header_endereco header_endereco'
		'form_certificado form_certificado form_certificado form_certificado'
		'form_endereco form_endereco form_endereco form_endereco';

	.imagem {
		width: calc(100% - 16px);
		grid-area: imagem;
	}

	.textarea {
		grid-area: descricao;
		margin-bottom: 0;
	}

	.programacao {
		grid-area: programacao;
		height: 120px;
		width: calc(100% - 16px);
	}

	h2 {
		grid-area: header_endereco;
		margin: 12px 10px 4px;
		font-size: 18px;
		font-weight: 600;
	}

	@media(max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'tip tip'
			'imagem imagem'
			'f1 f2'
			'f3 f4'
			'f5 f5'
			'f6 f7'
			'descricao descricao'
			'programacao programacao'
			'header_endereco header_endereco'
			'form_certificado form_certificado'
			'form_endereco form_endereco';
	}

	@media(max-width: 700px) {
		display: flex;
		flex-direction: column;
	}
`

export const CertificadoForm = styled.div`
	grid-area: form_certificado;
	display: grid;
	grid-template-columns: 1fr 1.3fr;
	align-items: flex-start;
	grid-gap: 6px 20px;

	> h2 {
		grid-column: 1/span 2;
		grid-row: 1;
		margin-top: 20px;
	}

	.texto-orientacao {
		line-height: 20px;
		margin: 0 12px;
		font-size: 12px;

		ul {
			text-indent: 20px;
		}
	}

	.button-container {
		display: flex;
		justify-content: center;
	}

	.p-editor-toolbar {
		.ql-formats:first-child,
		.ql-formats:nth-child(n + 3) {
			display: none;
		}
	}

	@media(max-width: 1200px) {
		display: flex;
		flex-direction: column;

		.editor-container {
			width: 100%;
		}
	}
`

export const CertificadoPreviewContainer = styled.div`
	img {
		width: 100%;
		height: 77vh;
		object-fit: contain;
	}

	div {
		font-size: 1rem;
		color: #424242;
		line-height: 38px;
		text-align: center;
		width: calc(100% - 260px * 2);
		font-weight: 400;
		position: absolute;
		top: 220px;
		left: 260px;
	}
`
