import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	> p {
		margin-top: 24px;
	}

	.filtros-container {
		display: flex;
		gap: 10px;
		align-items: end;
	}

	.questoes-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin-top: 24px;

		.questao-container {
			display: flex;
			flex-direction: column;
			gap: 12px;
		}
	}
`
