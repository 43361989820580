import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			'banco agencia conta'
			'termo termo termo'
			'check check check'
			'action_footer action_footer action_footer';

		@media(max-width: 700px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const TermoContainer = styled.div`
	grid-area: termo;
	display: flex;
	justify-content: center;
	width: 100%;
	grid-area: termo;
	margin-top: 20px;
`

export const ModalTermoContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	iframe {
		width: calc(100% - 16px);
		height: 80vh;
	}
`
