import styled from 'styled-components'

export const Container = styled.div`
	
`

export const DetalhesContainer = styled.div`
	display: grid;
	grid-template-columns: 170px 1fr;
	grid-template-rows: auto auto auto 95px;
	flex-direction: column;

	> img {
		grid-row: 1/span 4;
		width: 140px;
		height: 140px;
    	object-fit: cover;
		border-radius: 50%;
	}

	> h1 {
		align-self: center;
		padding: 8px 0;
	}

	> h2 {
		font-size: 16px;
		padding: 8px 0;
	}

	.info {
		display: grid;
		grid-gap: 8px;
		grid-template-columns: 1.2fr 1fr;
		grid-template-rows: repeat(3, max-content);

		span {
			font-size: 13px;
			padding-right: 10px;
			display: flex;
			
			> div {
				margin-left: 4px;
			}
		}
	}

	.acoes {
		grid-column: 2;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-bottom: 12px;

		.select {
			width: 320px;
		}

		.spinner-container {
			width: 100%;
		}
	}

	.badge {
		grid-column: 2;
		background-color: #ffeb3b;
		border-radius: 6px;
		color: #424242;
		display: grid;
		grid-template-columns: 34px auto;
		align-items: center;
		flex-wrap: wrap;
		font-size: 12px;
		margin-top: 0;
		padding: 8px 12px;
		font-weight: 600;
		line-height: 26px;

		svg {
			grid-row: 1/span 2;
			margin-right: 10px;
			font-size: 22px;
		}
	}

	.dialog-container {
		grid-column: 1/span 2;
	}

	@media(max-width: 600px) {
		grid-template-columns: 90px 1fr;
		grid-template-rows: auto 140px 0 0 auto auto auto auto auto;

		.badge {
			grid-column: 1/span 2;
			margin-bottom: 20px;
			font-size: 12px;
		}

		.acoes {
			display: flex;
			justify-content: flex-end;
			grid-column: 1/span 2;

			.select {
				width: 100%;
			}
		}

		.info > span {
			display: none;
		}

		> img {
			grid-column: 1/span 2;
			grid-row: 2/span 4;
			justify-self: center;
		}

		> h1 {
			font-size: 18px;
			grid-column: 1/span 2;
			text-align: center;
		}

		> h2 {
			font-size: 14px;
			grid-column: 1/span 2;
			text-align: center;
		}
	}
`

export const ModalFinalizacaoContainer = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 300px;
		
		.button-container{
			grid-column: 1/span 2;
			display:flex;
			justify-content: flex-end;

			> button {
				width: fit-content;
				align-self: flex-end;
			}
		}
		
		@media(max-width:800px){
			display: flex;
			flex-direction: column;
		}
	}
`

export const ConfirmCancelamentoContainer = styled.div`
	display: flex;
	flex-direction: column;

	> p {
		margin: 0 8px;
	}
	
	.button-container {
		display: flex;
		justify-content: flex-end;
		gap: 4px;
	}
`

export const ProtocoloFormContainer = styled.div`
	margin: 8px;
	display: flex;
	justify-content: center;
	
	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 350px;
		width: 100%;

		button {
			align-self: flex-end;
		}
	}
`

export const FormAssuntoContainer = styled.div`
	display: flex;
	justify-content: center;

	form {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		min-height: 350px;
		max-width: 350px;
		width: 100%;

		.action-container {
			display: flex;
			width: 100%;
			justify-content: flex-end;
		}
	}
`
