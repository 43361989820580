import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FiPlus, FiSave } from 'react-icons/fi'

import { Link } from 'react-router-dom'
import {
    Button, Checkbox, Select, Textbox, File
} from '../../../../components/Form'
import Modal from '../../../../components/Modal'

import { ContratoContext } from '../../../../contexts/ContratoContext'

import { api, authHeaders } from '../../../../services/api'

import { Container } from './styles'

import masks from '../../../../util/masks'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

export default function () {
    const {
        contratoEdicao, setContratoEdicao, modoCadastro, setModoCadastro, handleSubmit, initialValues
    } = useContext(ContratoContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [fornecedores, setFornecedores] = useState(null)
    const [areas, setAreas] = useState(null)
    const [tiposManutencao, setTiposManutencao] = useState(null)

    async function loadFornecedores() {
        const response = await api.get('fornecedor', authHeaders())

        setFornecedores(response.map(forn => ({
            label: forn.nome_fantasia || forn.pessoa.nome,
            value: forn.id
        })))
    }

    async function loadAreas() {
        const response = await api.get('contrato_area')

        setAreas(response.map(forn => ({
            label: forn.descricao,
            value: forn.id
        })))
    }

    async function loadTiposManutencao() {
        const response = await api.get('contrato_tipo_manutencao', authHeaders())

        setTiposManutencao(response.map(tipo => ({
            label: tipo.descricao,
            value: tipo.id
        })))
    }

    useEffect(() => {
        loadFornecedores()
        loadAreas()
        loadTiposManutencao()

        loadTips(setCodigo, 'form_contratos_cadastro')
    }, [])

    return (
        <Container>
            <Button className="transparent" onClick={() => { setModoCadastro(true) }}>
                Cadastrar
                <FiPlus size={18} />
            </Button>

            <Modal
                isOpen={modoCadastro || !!contratoEdicao}
                handleClose={() => {
                    setModoCadastro(false)
                    setContratoEdicao(null)
                }}
                title={modoCadastro ? 'Cadastro de contrato' : 'Edição de contrato'}
            >
                <Container>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        enableReinitialize
                    >
                        {({
                            setFieldValue, errors, values, isSubmitting
                        }) => (
                            <Form>
                                <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                                <Select
                                    name="fornecedor"
                                    label="Parceiro"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={fornecedores}
                                />

                                <Link
                                    to="/financeiro/fornecedor?add=1"
                                    className="link-cadastro-fornecedor"
                                >
                                    Não encontrei o parceiro
                                </Link>

                                <Select
                                    name="area"
                                    label="Área do contrato"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={areas}
                                />

                                <Textbox
                                    label="Objeto do contrato"
                                    name="objeto"
                                    containerClass="objeto"
                                />

                                <Textbox
                                    name="inicio"
                                    mask={masks.date}
                                    label="Data de início"
                                    containerClass="inicio"
                                />

                                <Textbox
                                    name="periodo"
                                    mask={masks.numero}
                                    label="Período do contrato (em meses)"
                                    containerClass="periodo"
                                />

                                <Checkbox
                                    name="manutencao_periodica"
                                    label="Possui manutenção periódica?"
                                    handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                    containerClass="manutencao"
                                />

                                {values.manutencao_periodica ? (
                                    <>
                                        <Select
                                            name="tipo_manutencao"
                                            label="Tipo de manutenção"
                                            options={tiposManutencao}
                                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                            containerClass="animated fadeIn fast tipo_manutencao"
                                        />

                                        <Textbox
                                            name="periodo_manutencao"
                                            type="number"
                                            label="Período de manutenção (dias)"
                                            containerClass="animated fadeIn fast periodo"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div />
                                        <div />
                                    </>
                                )}

                                <File
                                    name="arquivo"
                                    onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                    label="Contrato digitalizado"
                                    format="square"
                                    previewSize={['100%', '242px']}
                                    error={errors.documento}
                                    getPreloadImage={async () => {
                                        const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                        return arquivo?.link
                                    }}
                                    maxSize={5 * 1024 * 1024}
                                    accept={['application/pdf']}
                                />

                                <Button className="transparent" type="submit" loading={isSubmitting}>
                                    {modoCadastro ? 'Cadastrar' : 'Salvar alterações'}
                                    {modoCadastro ? <FiPlus /> : <FiSave />}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </Modal>
        </Container>
    )
}
