import React from 'react'
  
import Card from '../../../components/Card'
import RegraProvider from '../../../contexts/RegraContext'

import Formulario from './Formulario'
import Listagem from './Listagem'

import { Container } from './styles'

export default function () {
    return (
        <RegraProvider>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Regras de envio</h1>
                        
                        <Formulario />

                        <Listagem />
                    </Card>
                </main>
            </Container>
        </RegraProvider>
    )
}
