import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'

import { VisaoContext } from '../../../../contexts/VisaoContext'

import Table from '../../../../components/Table'
import { Button } from '../../../../components/Form'
import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'
import Cadastro from '../Cadastro'

import { Container } from './styles'

export default function () {
    const {
        visoes, setShowAddGrupo, showAddGrupo, handleDelete 
    } = useContext(VisaoContext)

    if(!visoes) {
        return <Spinner label="Aguarde. Isto pode demorar alguns segundos." />
    }

    return (
        <>
            <Container>
                <Button onClick={() => { setShowAddGrupo(true) }} className="transparent">
                    Criar um grupo
                    <FiPlus />
                </Button>

                {visoes ? (
                    <Table 
                        headers={[
                            { name: 'descricao', value: 'Nome' },
                            { name: 'quantidade', value: 'Quantidade de pessoas', style: { width: 200 } }
                        ]}
                        data={visoes}
                        handleDelete={handleDelete}
                    />
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={showAddGrupo}
                handleClose={() => { setShowAddGrupo(false) }}
                title="Novo grupo"
            >
                <Cadastro />
            </Modal>
        </>
    )
}
