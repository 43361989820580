import styled from 'styled-components'

export const Container = styled.article`
	form {
		display: grid;
		grid-template-areas:
			  'tip tip tip tip tip tip'
              'f1 f2 f2 f2 f3 f3'
              'f4 f5 f5 f5 f6 f6'
              'bt bt bt bt bt bt';

		.button-container {
			display: flex;
			grid-area: bt;
			width: 100%;
			justify-content: space-between;
			padding-top: calc(20px - 5px);
		}

		@media(max-width: 900px) {
			grid-template-areas:
			  'tip tip'
              'f1 f2'
              'f3 f4'
			  'f5 f6'
              'bt bt';
		}

		@media(max-width: 600px) {
			grid-template-areas:
			  'tip'
              'f1'
              'f2'
			  'f3'
			  'f4'
			  'f5'
			  'f6'
              'bt';

			.field.checkbox {
				grid-column: unset;
			}

			& > button {
				max-width: unset;
			}
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`
