import styled from 'styled-components'

export const FiltrosContainer = styled.div`
	background-color: #0003;
	border-radius: 10px;
	padding: 16px;
	margin-top: 16px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: end;

	button {
		grid-column: 4;
	}
`
