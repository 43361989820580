import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'

import Yup from '../../../services/yup'
import { api, authHeaders } from '../../../services/api'

import maskUtils from '../../../util/masks'

import ActionFooter from '../../ActionFooter'
import { Button, File } from '../../Form'

import { Container } from './styles'

const initialValues = {
    declaracao: ''
}

const validation = Yup.object({
    declaracao: Yup.number().nullable().required('Anexe a declaração da Residência Médica')
})

export default function ({ callback, pessoaId }) {
    const [valorAnuidade, setValorAnuidade] = useState(null)
    const [valorMensalidade, setValorMensalidade] = useState(null)

    async function loadValores() {
        const response = await api.get('desconto_anuidade', {
            params: { grupo: 'residente' }
        })

        setValorAnuidade(response.valor)
    }

    async function loadValorMensalidade() {
        const { valor } = await api.get(`mensalidade_valor/${new Date().getFullYear()}`)

        const mensalidade = Number(valor)

        setValorMensalidade(mensalidade)
    }

    async function handleSubmit(values) {
        try {
            const body = {
                tipo_pagamento_id: 5,
                dados_pagamento: values
            }

            await api.put(`pagamento/${pessoaId}`, body, authHeaders())

            toast.success('Dados atualizados.')

            callback()
        } catch (e) {
            toast.error('Ocorreu um erro.')
        }
    }

    useEffect(() => {
        loadValores()
        loadValorMensalidade()
    }, [])

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue, errors }) => (
                    <Form>
                        <p>
                            Valor da anuidade:
                            <span style={{ fontWeight: 800 }}>
                                {valorAnuidade && ` ${maskUtils.maskApply.currency(valorAnuidade)}`}
                            </span>
                        </p>
                        {/* {new Date() < new Date(getYear(new Date()), 5, 30) ? (
                            <p>
                                Valor da anuidade:
                                <span style={{ fontWeight: 800 }}>
                                    {valorAnuidade && ` ${maskUtils.maskApply.currency(valorAnuidade)}`}
                                </span>
                            </p>
                        ) : (
                            <p>
                                Valor da anuidade:
                                <span style={{ fontWeight: 800 }}>
                                    {valorMensalidade && ` ${maskUtils.maskApply.currency((Number(valorMensalidade) * (12 - getMonth(new Date()))))}`}
                                </span>
                            </p>
                        )} */}

                        <File
                            name="declaracao"
                            label="Anexe aqui a Declaração de Residência Médica"
                            onSuccess={fileId => setFieldValue('declaracao', fileId)}
                            format="square"
                            error={errors.declaracao}
                            style={{ width: 'calc(100% - 16px)' }}
                            previewSize={['100%', '140px']}
                        />

                        <ActionFooter style={{ gridArea: 'button' }}>
                            <Button type="submit" loading={isSubmitting} className="white">
                                Salvar alterações
                                <FaRegSave />
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
