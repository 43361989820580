import React from 'react'

import TutorialProvider from '../../../contexts/TutorialContext'

import Visualizar from './Visualizar'

export default function () {
    return (
        <TutorialProvider>
            <Visualizar />
        </TutorialProvider>
    )
}
