import React from 'react'

import ContribuicaoProvider from '../../../contexts/ContribuicaoContext'

import Card from '../../../components/Card'

import Cadastro from './Cadastro'
import Listagem from './Listagem'
import Pesquisa from './Pesquisa'

import { Container } from './styles'

export default function () {
    return (
        <ContribuicaoProvider>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Pagamentos</h1>

                        <Pesquisa />

                        <Listagem />
                    </Card>
                </main>
            </Container>

            <Cadastro />

        </ContribuicaoProvider>
    )
}
