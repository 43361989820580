import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import Yup from '../../../../services/yup'
import { api } from '../../../../services/api'

import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext'

import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'
import {
    Textbox, Button, Select, Calendar
} from '../../../../components/Form'

import masks from '../../../../util/masks'

import { Container, ButtonContainer } from './styles'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

const validation = Yup.object().shape({
    crm: Yup.string(),
    nome: Yup.string(),
    competencia: Yup.string().nullable(),
    data_consolidacao: Yup.string().nullable(),
    valor: Yup.string().money('Complete com os centavos.').required('Especifique o valor da contribuição.'),
    status: Yup.string().ensure(),
    tipo_pagamento: Yup.string().ensure(),
    instituicao: Yup.string().ensure()
})

export default function () {
    const {
        initialValues, modalMode, showCadastro, instituicoes, tiposPagamento, handleAdd, handleEdit, editId
    } = useContext(ContribuicaoContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [loadingData, setLoadingData] = useState(false)

    async function handleCRMBlur(value, { setFieldValue }) {
        if (!value) {
            return
        }

        const crm = Number(value)

        setFieldValue('crm', crm.toString())

        setLoadingData(true)

        const associados = await api.get(`associado?crm=${crm}`)
        const associado = associados?.[0]

        if (!associado) {
            toast.error('Associado não encontrado.')
            return
        }

        setFieldValue('nome', associado.pessoa.nome)

        setLoadingData(false)
    }

    useEffect(() => {
        if (modalMode !== null) {
            loadTips(setCodigo, 'form_contribuicao_cadastro')
        } else {
            loadTips(setCodigo, 'form_contribuicao_pesquisa')
        }
    }, [modalMode])

    return (
        <Modal
            isOpen={modalMode !== null}
            closeOnOverlayClick={false}
            handleClose={() => {
                showCadastro(false)
            }}
            title={modalMode && modalMode === 'cadastro' ? 'Cadastro de pagamentos' : 'Edição de pagamentos'}
        >
            {modalMode === 'carregando' ? (
                <Spinner containerStyle={{ height: '40vh' }} />
            ) : (
                <Container>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={modalMode === 'cadastro' ? handleAdd : handleEdit}
                        enableReinitialize
                    >
                        {({ setFieldValue, resetForm }) => (
                            <Form>
                                <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                                <Textbox
                                    name="crm"
                                    label="Matrícula"
                                    onBlur={e => handleCRMBlur(e.target.value, { setFieldValue })}
                                />

                                <Textbox
                                    name="nome"
                                    label="Nome"
                                    loading={loadingData}
                                />

                                <Textbox
                                    name="valor"
                                    label="Valor"
                                    mask={masks.money}
                                />

                                <Select
                                    label="Status do pagamento"
                                    name="status"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={[
                                        { label: 'Efetuados', value: 1 },
                                        { label: 'Pendentes', value: 0 }
                                    ]}
                                />

                                <Calendar
                                    label="Competência"
                                    name="competencia"
                                    view="month"
                                    dateFormat="mm/yy"
                                    yearRange={`${new Date().getFullYear() - 100}:${new Date().getFullYear()}`}
                                />

                                <Calendar
                                    label="Data"
                                    name="data_consolidacao"
                                    yearRange={`${new Date().getFullYear() - 100}:${new Date().getFullYear()}`}
                                />

                                <Select
                                    label="Tipo de pagamento"
                                    name="tipo_pagamento"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={tiposPagamento.map(tipo => ({
                                        label: tipo.descricao_resumida,
                                        value: tipo.id
                                    }))}

                                />

                                <Select
                                    label="Instituição"
                                    name="instituicao"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={instituicoes.map(instituicao => ({
                                        label: instituicao.sigla,
                                        value: instituicao.id
                                    }))}
                                />

                                <ButtonContainer>
                                    <Button
                                        onClick={resetForm}
                                        className="transparent"
                                    >
                                        Limpar
                                    </Button>

                                    <Button type="submit" className="white">
                                        {editId ? 'Salvar alterações' : 'Cadastrar'}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </Container>
            )}
        </Modal>
    )
}
