import React, { useContext } from 'react'
import { format } from 'date-fns'
import { FaEdit } from 'react-icons/fa'

import maskUtils from '../../../../util/masks'

import { InconsistenciaContext } from '../../../../contexts/InconsistenciaContext'

import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'

import { Container } from './styles'

export default function () {
    const { inconsistencias, handleDelete, handlePrepareEdit } = useContext(InconsistenciaContext)

    return (
        <Container>
            {inconsistencias ? (
                <Table
                    headers={[
                        { name: 'identificador', value: 'CRM', centered: true },
                        { name: 'nome', value: 'Associado' },
                        { name: 'competencia', value: 'Competência', centered: true },
                        { name: 'valor', value: 'Valor', centered: true },
                        { name: 'observacao', value: 'Observação' },
                        { name: 'status', value: 'Status', icon: true }
                    ]}
                    data={inconsistencias.map(inconsistencia => ({
                        id: inconsistencia.id,
                        identificador: inconsistencia.identificador,
                        nome: inconsistencia.associado?.pessoa.nome || '',
                        competencia: format(new Date(inconsistencia.competencia), 'dd/MM/yyyy'),
                        valor: maskUtils.maskApply.currency(inconsistencia.valor),
                        observacao: inconsistencia.observacao,
                        status: Number(inconsistencia.status) ? 'FaCheckCircle' : 'FaMinusCircle',
                        iconColor: Number(inconsistencia.status) ? '#4caf50' : '#f44336'
                    }))}
                    emptyLabel="Nenhuma inconsistência encontrada"
                    caption="Inconsistências"
                    handleDelete={handleDelete}
                    actions={[
                        {
                            action: handlePrepareEdit,
                            name: 'Editar',
                            icon: FaEdit,
                            checkDisabled: item => !item.nome || item.observacao === 'Ex-associado' || item.observacao === 'Associado está sem método de pagamento'
                                || item.observacao.includes('Associado está vinculado a instituição') || item.observacao.includes('Associado possuí o método de pagamento')

                        }
                    ]}
                    showId
                    confirmExclusion
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
