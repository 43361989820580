import React, { useEffect, useRef } from 'react'
import { useField } from 'formik'
import { Chips } from 'primereact/chips'

import Spinner from '../../Spinner'

import {
    InputContainer
} from './styles'

export default function ({
    label, icon, withoutForm, loading, containerClass = '', containerStyle = {}, style = {}, ...props 
}) {
    let field
    let meta

    const Icon = icon?.Icon

    const input = useRef()

    if(!withoutForm) {
        [field, meta] = useField(props)
    }

    useEffect(() => {
        if(props.focused) {
            input.current.focus()
        }
    })
    
    if(withoutForm) {
        return (
            <InputContainer className={containerClass} style={containerStyle}>
                <span className="label">{label}</span>

                <Chips 
                    {...field} 
                    {...props} 
                    ref={input} 
                    style={{ ...style, paddingRight: icon ? 42 : 0 }}
                />

                {loading ? (
                    <Spinner color="rgba(0, 0, 0, 0.3)" label="" containerStyle={{ margin: 'auto' }} />
                ) : icon && (
                    <Icon {...icon} size={icon.size || 14} />
                )}
            </InputContainer>
        )
    }

    return (
        <InputContainer className={containerClass} style={containerStyle} error={meta.touched && meta.error}>
            <span className="label">{label}</span>

            <Chips 
                {...field} 
                {...props} 
                ref={input} 
                style={{ ...style, paddingRight: icon ? 42 : 0 }}
            />
            {loading ? (
                <Spinner color="rgba(0, 0, 0, 0.3)" label="" containerStyle={{ margin: 'auto' }} />
            ) : icon && (
                <Icon {...icon} size={icon.size || 14} />
            )}

            {meta.touched && meta.error 
                ? (
                    <span className="error">
                        {meta.error}
                    </span>
                )
                : null}
        </InputContainer>
    )
}
