import React from 'react'
import { Link } from 'react-router-dom'
import * as icons from 'react-icons/fa'

import { Container } from './styles'

export default function ({
    to, descricao, subdescricao, icone, iconSize = 22, style, className, enabled = true 
}) {
    return (
        <Container 
            style={style} 
            className={`${className} animated fadeIn`} 
            disabled={!enabled}
        >
            {enabled ? (
                <Link to={to}>
                    {React.createElement(icons[icone], {
                        size: iconSize
                    }, null)}

                    <h1>{descricao}</h1>
                    
                    {subdescricao && <span className="subdescricao">{subdescricao}</span>}
                </Link>
            ) : (
                <div>
                    {React.createElement(icons[icone], {
                        size: iconSize
                    }, null)}

                    <h1>{descricao}</h1>
                    
                    {subdescricao && <span className="subdescricao">{subdescricao}</span>}
                </div>
            )}
        </Container>
    )
}
