import styled from 'styled-components'

export const Container = styled.main`
	display: flex;
	justify-content: center;
	margin: 50px 20px;

	.card {
		width: 100%;
		max-width: 1100px;
	}

	@media(min-width: 601px) {
		margin: 50px 50px 50px 90px;
	}

	@media(max-width: 600px) {
		margin: 10px 0;
	}

	@media(max-width: 400px) {
		margin: 30px 0;
	}
`
