import React from 'react'
import { Textbox } from '../../../../../components/Form'

import { Container } from './styles'

export default function () {
    return (
        <Container>
            <h1>Dados do profissional</h1>

            <Textbox 
                name="credencial"
                label="Credencial"
            />

            <Textbox 
                name="nome"
                label="Nome"
            />
        </Container>
    )
}
