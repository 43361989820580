import React, { useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { GlobalContext } from '../../../contexts/GlobalContext'
import AtendimentoProvider from '../../../contexts/AtendimentoContext'

import Listagem from './Listagem'
import Cadastro from './Cadastro'

import { Container } from './styles'
import useQuery from '../../../hooks/useQuery'

export default function () {
    const { user } = useContext(GlobalContext)

    const history = useHistory()

    const { tipo } = useQuery()

    useEffect(() => {
        if(user?.perfil.id === 2 && user?.associado.status_associacao.id !== 3 && tipo === '1') {
            toast.warn('Você não pode acessar o Módulo de Demandas Jurídicas. Sua associação não está regular.')
            history.push('/dashboard')
        }
    }, [user])

    return (
        <AtendimentoProvider>
            <Container>
                <Listagem />

                <Cadastro />
            </Container>
        </AtendimentoProvider>
    )
}
