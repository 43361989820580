import React, { useContext, useEffect } from 'react'
import { Form, Formik } from 'formik'

import { loadTips } from '../../../../../util/tip'

import {
    Textbox, Button, Select, Calendar
} from '../../../../../components/Form'

import { PlanoSaudeContext } from '../../../../../contexts/PlanoSaudeContext'

import { Container } from './styles'
import { TipContext } from '../../../../../contexts/TipContext'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)
    const { handleSearch } = useContext(PlanoSaudeContext)

    useEffect(() => {
        loadTips(setCodigo, 'form_plano_saude_pesquisa_contribuicao')
    }, [])

    return (
        <Container>
            <Formik
                initialValues={{
                    crm: '',
                    nome: '',
                    competencia: '',
                    status: null,
                    tipo_beneficiario: null
                }}
                onSubmit={handleSearch}
            >
                {({ setFieldValue, resetForm, isSubmitting }) => (
                    <Form>
                        <p>{tips.formulario}</p>

                        <Textbox name="crm" label="Matrícula" />

                        <Textbox name="nome" label="Nome" />

                        <Calendar
                            name="competencia"
                            dateFormat="mm/yy"
                            view="month"
                            label="Competência"
                            selectionMode="range"
                        />

                        <Select
                            label="Status"
                            name="status"
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            options={[
                                { value: 1, label: 'Efetuado' },
                                { value: 0, label: 'Pendente' }
                            ]}
                        />

                        <Select
                            label="Tipo beneficiário"
                            name="tipo_beneficiario"
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            options={[
                                { value: 1, label: 'Médico' },
                                { value: 0, label: 'Acâdemico' }
                            ]}
                        />

                        <div className="button-container">
                            <Button
                                onClick={() => {
                                    resetForm()
                                }}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                className="white"
                                loading={isSubmitting}
                            >
                                Pesquisar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
