import React, { useContext, useEffect, useState } from 'react'
import { differenceInSeconds } from 'date-fns'
import {
    FaEye, FaEdit, FaTrash, FaUsers, FaCheck
} from 'react-icons/fa'

import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import Modal from '../../../../components/Modal'

import { EleicaoContext } from '../../../../contexts/EleicaoContext'

import CadastroChapa from '../CadastroChapa'
import Detalhes from '../Detalhes'

import { Container } from './styles'
import { GlobalContext } from '../../../../contexts/GlobalContext'

export default function () {
    const {
        eleicoes, eleicao, setEleicao, setEditItem, handleDelete, setCadastroChapa, cadastroChapa, handleDivulgarResultado, reloadEleicoes
    } = useContext(EleicaoContext)

    const { user } = useContext(GlobalContext)

    const [tempoRestanteSegundos, setTempoRestanteSegundos] = useState(null)

    useEffect(() => {
        if (eleicao?.tempo_restante_minutos > 0 && eleicao.tempo_restante_minutos < 60) {
            const segundosDiferenca = differenceInSeconds(eleicao.fim_date, new Date())

            setTempoRestanteSegundos(segundosDiferenca)
        }
    }, [eleicao])

    useEffect(() => {
        if (tempoRestanteSegundos > 0) {
            setTimeout(() => {
                setTempoRestanteSegundos(old => old - 1)
            }, 1000)
        }
    }, [tempoRestanteSegundos])

    return (
        <>
            <Container>
                {eleicoes ? (
                    <Table
                        headers={[
                            { name: 'descricao', value: 'Descrição' },
                            { name: 'periodo', value: 'Período votação', centered: true },
                            { name: 'status_label', value: 'Status', centered: true },
                            { name: 'chapas_quantidade', value: 'Chapas' },
                            { name: 'total_eleitores', value: 'Total eleitores' },
                            { name: 'total_votos', value: 'Votos computados' },
                            { name: 'progresso_votacao', value: 'Progresso votação', centered: true }
                        ]}
                        data={eleicoes}
                        hideOnSmallHeaders={['chapas_quantidade', 'total_eleitores', 'total_votos', 'progresso_votacao']}
                        hideHeadersOffset={970}
                        hideOffset={652}
                        actions={[
                            {
                                action: setEleicao,
                                name: 'Visualizar',
                                icon: FaEye,
                                iconSize: 16,
                                checkDisabled: () => (user.perfil.id !== 34)
                            },
                            {
                                action: setCadastroChapa,
                                name: 'Incluir chapas',
                                icon: FaUsers,
                                iconSize: 16,
                                checkDisabled: item => !item.nao_iniciada,
                                disabledTooltip: () => 'Não é possível alterar as chapas desta eleição.'
                            },
                            {
                                action: item => { handleDivulgarResultado(item) },
                                name: 'Divulgar resultado',
                                icon: FaCheck,
                                iconSize: 16,
                                confirmation: 'Tem certeza que deseja divulgar o resultado desta eleição para todos os eleitores participantes?',
                                checkDisabled: item => user.perfil.id !== 34 || item.data_divulgacao_resultado || !item.resultado,
                                disabledTooltip: item => item.status !== 'encerrado'
                                    ? 'Esta eleição ainda não terminou.'
                                    : item.data_divulgacao_resultado
                                        ? 'O resultado já foi divulgado.'
                                        : null
                            },
                            {
                                action: item => {
                                    setEditItem({
                                        ...item,
                                        inicio_date: new Date(item.periodo_inicio),
                                        fim_date: new Date(item.periodo_fim)
                                    })
                                },
                                name: 'Editar',
                                icon: FaEdit,
                                iconSize: 15,
                                checkDisabled: item => !!item.encerrado || item.iniciada
                            },
                            {
                                action: handleDelete,
                                name: 'Excluir',
                                icon: FaTrash,
                                iconSize: 13,
                                confirmation: 'Tem certeza que deseja remover esta eleição do sistema?',
                                checkDisabled: item => !!item.encerrado || item.iniciada
                            }
                        ]}
                    />
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={!!eleicao}
                handleClose={() => { setEleicao(null) }}
                title={`Eleição: ${eleicao?.descricao}`}
            >
                {eleicao && (
                    <Detalhes />
                )}
            </Modal>

            <Modal
                isOpen={!!cadastroChapa}
                handleClose={() => {
                    setCadastroChapa(null)

                    reloadEleicoes()
                }}
                title={`Chapas para a eleição ${cadastroChapa?.descricao}`}
            >
                {cadastroChapa && (
                    <CadastroChapa />
                )}
            </Modal>
        </>
    )
}
