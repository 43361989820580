import { Formik, Form } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Button, File, Textbox } from '../../../../../components/Form'
import Table from '../../../../../components/Table'

import { EventoContext } from '../../../../../contexts/EventoContext'

import { api, authHeaders } from '../../../../../services/api'
import Yup from '../../../../../services/yup'

import { Container } from './styles'

const validation = Yup.object({
    arquivo: Yup.number().required('Anexe o arquivo.'),
    descricao: Yup.string().required('Forneça uma descrição para o arquivo.')
})

export default function () {
    const { evento } = useContext(EventoContext)

    const [galeria, setGaleria] = useState(null)

    async function loadGaleriaEvento() {
        const response = await api.get(`evento/${evento.id}`, authHeaders())

        const documentos = []

        if (response.documento) {
            documentos.push({
                id: response.documento.id,
                descricao: 'Programação do evento',
                link: response.documento.link
            })
        }

        documentos.push(...response.galeria.map(g => ({
            id: g.arquivo.id,
            descricao: g.descricao,
            link: g.arquivo.link || '#'
        })))

        setGaleria(documentos)
    }

    useEffect(() => {
        loadGaleriaEvento()
    }, [])

    async function handleCadastrar(values, { resetForm }) {
        try {
            const { arquivo, descricao } = values

            await api.post(`evento_galeria/${evento.id}`, {
                arquivo_id: arquivo,
                descricao
            }, authHeaders())

            toast.success('Arquivo adicionado ao evento.')

            resetForm()

            loadGaleriaEvento()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(id) {
        try {
            await api.delete(`evento_galeria/${id}`, authHeaders())

            toast.success('Arquivo removido do evento.')

            loadGaleriaEvento()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    return (
        <Container>
            <Formik
                onSubmit={handleCadastrar}
                initialValues={{
                    arquivo: null,
                    descricao: ''
                }}
                validationSchema={validation}
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <File
                            name="arquivo"
                            label="Arquivo"
                            onSuccess={fileId => setFieldValue('arquivo', fileId)}
                            format="square"
                            previewSize={['100%', '250px']}
                            maxSize={30 * 1024 * 1024}
                            getPreloadImage={async () => {
                                if (values.arquivo) {
                                    const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                    return arquivo?.link
                                }
                            }}
                        />

                        <Textbox
                            label="Descrição"
                            name="descricao"
                        />

                        <div className="button-container">
                            <Button type="submit" className="transparent">Enviar</Button>
                        </div>
                    </Form>
                )}
            </Formik>

            <Table
                headers={[
                    { name: 'descricao', value: 'Descrição' },
                    {
                        name: 'link', value: '', link: true
                    }
                ]}
                data={galeria || []}
                handleDelete={handleDelete}
                confirmExclusion={{
                    attr: 'descricao',
                    template: 'Deseja realmente remover o arquivo #attr#?'
                }}
            />
        </Container>
    )
}
