import { differenceInMinutes, format } from 'date-fns'
import React, { createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

export const EleicaoContext = createContext()

const baseInitialValues = {
    descricao: '',
    inicio: '',
    fim: '',
    medico: false,
    academico: false,
    quites: false,
    diretoria: false
}

export default function EleicaoProvider({ children }) {
    const [eleicoes, setEleicoes] = useState(null)
    const [eleicao, setEleicao] = useState(null)
    const [showCadastro, setShowCadastro] = useState(false)
    const [cadastroChapa, setCadastroChapa] = useState(null)
    const [editItem, setEditItem] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [candidatoUploadFoto, setCandidatoUploadFoto] = useState(null)
    const [candidatoUploadCurriculo, setCandidatoUploadCurriculo] = useState(null)

    async function loadEleicoes() {
        setCadastroChapa(old => old ? ({
            ...old,
            chapas: null
        }) : null)

        setEleicoes(null)

        const response = await api.get('eleicao', authHeaders())

        setEleicoes(response.map(el => ({
            ...el,
            status_label: el.data_divulgacao_resultado
                ? 'Resultado divulgado'
                : el.status === 'nao_iniciado'
                    ? 'Não iniciada'
                    : el.status === 'em_votacao'
                        ? 'Em votação'
                        : 'Finalizada',
            chapas_quantidade: el.chapas.length,
            categorias: el.categorias.split(','),
            periodo: `${format(new Date(el.periodo_inicio), 'dd/MM/yyyy HH:mm\'h\'')} a ${format(new Date(el.periodo_fim), 'dd/MM/yyyy HH:mm\'h\'')}`,
            progresso_votacao: `${(Math.round(((100 * el.total_votos) / el.total_eleitores) * 10) / 10)}%`.replace('.', ','),
            tempo_restante_minutos: el.status === 'encerrado' ? 0 : differenceInMinutes(new Date(el?.periodo_fim), new Date()),
            inicio_date: new Date(el.periodo_inicio),
            fim_date: new Date(el.periodo_fim),
            encerrado: el.status === 'encerrado',
            nao_iniciada: el.status === 'nao_iniciado',
            iniciada: el.status === 'em_votacao',
            empate: el.empatado,
            resultado: el.status !== 'encerrado' ? null : el.empatado ? 'empate' : 'vitória'
        })))
    }

    async function handleSubmit(values) {
        try {
            values.categorias_eleitores = []

            if (values.medico) {
                values.categorias_eleitores.push('medico')
            }

            if (values.academico) {
                values.categorias_eleitores.push('academico')
            }

            if (values.quites) {
                values.categorias_eleitores.push('quites')
            }

            if (values.colaboradores) {
                values.categorias_eleitores.push('colaboradores')
            }

            if (values.diretoria) {
                values.categorias_eleitores.push('diretoria')
            }

            if (values.teste) {
                values.categorias_eleitores.push('teste')
            }

            if (showCadastro) {
                await api.post('eleicao', values, authHeaders())

                setShowCadastro(false)

                toast.success('Eleição cadastrada.')
            } else {
                await api.put(`eleicao/${editItem.id}`, values, authHeaders())

                setEditItem(null)

                toast.success('Eleição atualizada.')
            }

            loadEleicoes()
        } catch (e) {
            if (e.alert) {
                toast.warn(e.msg)
                return
            }

            toast.error(e.msg)
        }
    }

    async function handleDelete({ id }) {
        try {
            await api.delete(`eleicao/${id}`, authHeaders())

            toast.success('Eleição removida!')

            loadEleicoes()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handleSubmitChapas(values) {
        try {
            values.eleicao_id = cadastroChapa.id

            await api.post('eleicao/chapa', values, authHeaders())

            toast.success('Chapa adicionada!')

            loadEleicoes()
        } catch (e) {
            if (e.alert) {
                toast.warn(e.msg)
                return
            }

            toast.error(e.msg)
        }
    }

    async function handleDeleteChapa({ id }) {
        try {
            await api.delete(`eleicao/chapa/${id}`, authHeaders())

            toast.success('Chapa removida.')

            loadEleicoes()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handleUploadFoto({ foto, pessoa, candidato_id }) {
        try {
            const response = await api.post(`eleicao/candidato/${pessoa.id}/foto`, { foto }, authHeaders())

            toast.success('Foto atualizada!')

            setCadastroChapa({
                ...cadastroChapa,
                chapas: cadastroChapa.chapas.map(chapa => {
                    const candidatoAAtualizar = chapa.candidatos.find(cand => cand.id === candidato_id)

                    if (candidatoAAtualizar) {
                        return {
                            ...chapa,
                            candidatos: [
                                ...chapa.candidatos.filter(cand => cand.id !== candidato_id),
                                {
                                    ...candidatoAAtualizar,
                                    foto: response.foto
                                }
                            ]
                        }
                    }

                    return chapa
                })
            })

            setCandidatoUploadFoto(null)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleUploadCurriculo({ curriculo, pessoa, candidato_id }) {
        try {
            const response = await api.post(`eleicao/candidato/${pessoa.id}/curriculo`, { curriculo }, authHeaders())

            toast.success('Currículo atualizado!')

            setCadastroChapa({
                ...cadastroChapa,
                chapas: cadastroChapa.chapas.map(chapa => {
                    const candidatoAAtualizar = chapa.candidatos.find(cand => cand.id === candidato_id)

                    if (candidatoAAtualizar) {
                        return {
                            ...chapa,
                            candidatos: [
                                ...chapa.candidatos.filter(cand => cand.id !== candidato_id),
                                {
                                    ...candidatoAAtualizar,
                                    curriculo: response.curriculo
                                }
                            ]
                        }
                    }

                    return chapa
                })
            })

            setCandidatoUploadCurriculo(null)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDivulgarResultado({ id }) {
        try {
            await api.put(`eleicao/${id}/divulgar`, {}, authHeaders())

            toast.success('Resultado divulgado com sucesso.')

            loadEleicoes()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function resetInitialValues() {
        setInitialValues(baseInitialValues)
    }

    useEffect(() => {
        loadEleicoes()
    }, [])

    useEffect(() => {
        if (eleicoes && cadastroChapa) {
            setCadastroChapa(eleicoes.find(e => e.id === cadastroChapa.id))
        }
    }, [eleicoes])

    useEffect(() => {
        if (editItem) {
            const editObj = {
                descricao: editItem.descricao,
                inicio: format(editItem.inicio_date, 'dd/MM/yyyy HH:mm\'h\''),
                fim: format(editItem.fim_date, 'dd/MM/yyyy HH:mm\'h\''),
                medico: false,
                academico: false,
                quites: false,
                colaboradores: false
            }

            editItem.categorias.forEach(categ => {
                editObj[categ] = true
            })

            setInitialValues(editObj)
        }
    }, [editItem])

    return (
        <EleicaoContext.Provider
            value={{
                eleicoes,
                eleicao,
                setEleicao,
                setShowCadastro,
                showCadastro,
                setEditItem,
                editItem,
                initialValues,
                handleSubmit,
                handleDelete,
                setCadastroChapa,
                cadastroChapa,
                handleSubmitChapas,
                handleDeleteChapa,
                handleUploadFoto,
                setCandidatoUploadFoto,
                candidatoUploadFoto,
                setCandidatoUploadCurriculo,
                candidatoUploadCurriculo,
                handleUploadCurriculo,
                handleDivulgarResultado,
                reloadEleicoes: loadEleicoes,
                resetInitialValues
            }}
        >
            {children}
        </EleicaoContext.Provider>
    )
}
