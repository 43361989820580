import React, { useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { toast } from 'react-toastify'

import { Container } from './styles'

import Spinner from '../../../../../components/Spinner'
import { api, authHeaders } from '../../../../../services/api'
import PieChart from '../../../../../components/Chart/Pie'
import { Button, Select } from '../../../../../components/Form'

export default function () {
    const [enquetes, setEnquetes] = useState(null)
    const [enqueteSelecionada, setEnqueteSelecionada] = useState(null)
    const [visoesDisponiveis, setVisoesDisponiveis] = useState(null)
    const [visaoSelecionada, setVisaoSelecionada] = useState(null)
    const [enqueteData, setEnqueteData] = useState(null)

    async function loadEnquetes() {
        try {
            const response = await api.get('enquete', authHeaders())

            setEnquetes(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadEnqueteData() {
        try {
            setEnqueteData('loading')

            const response = await api.get(`enquete/${enqueteSelecionada.id}`, {
                params: {
                    scope: 'estatistica',
                    grupo: visaoSelecionada?.id
                },
                ...authHeaders()
            })

            const questoesData = response.questoes.map(questao => {
                const respostas = response.respostas
                    .reduce((result, resposta) => [...result, ...resposta.questao_respostas], [])
                    .filter(resposta => resposta.questao_id === questao.id)

                const alternativas = questao.alternativas.reduce((final, alt) => {
                    const respostasAlternativa = respostas.filter(r => r.alternativa_id === alt.id).length
                    const percentual = respostas.length ? ((respostasAlternativa * 100) / respostas.length).toFixed(1).replace('.', ',') : 0
                    const labelTruncado = alt.texto.length > 15 ? `${alt.texto.substring(0, 15)}...` : alt.texto

                    return {
                        ...final,
                        [`${labelTruncado} (${percentual}%)`]: {
                            value: respostas.filter(resp => resp.alternativa_id === alt.id).length
                        }
                    }
                }, {})

                return {
                    questao,
                    dados_grafico: alternativas
                }
            })

            setEnqueteData(questoesData)
        } catch (e) {
            console.log(e)

            toast.error(e.msg)
        }
    }

    async function loadVisoesDisponiveis() {
        try {
            const response = await api.get(`enquete/${enqueteSelecionada.id}/visoes`, authHeaders())

            setVisoesDisponiveis(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadEnquetes()

        loadVisoesDisponiveis()
    }, [])

    useEffect(() => {
        if (enqueteSelecionada) {
            loadVisoesDisponiveis()
        }
    }, [enqueteSelecionada])

    if (!enquetes) {
        return (
            <Container>
                <Spinner label="Carregando enquetes..." />
            </Container>
        )
    }

    return (
        <Container>
            <div className="filtros-container">
                <Select
                    withoutForm
                    name="enquete"
                    label="Enquete"
                    onChange={selected => {
                        setEnqueteSelecionada(enquetes.find(e => e.id === selected.value))
                    }}
                    options={enquetes.map(enquete => ({
                        label: enquete.nome,
                        value: enquete.id
                    }))}
                    placeholder="Selecione a enquete..."
                />

                {visoesDisponiveis && (
                    <Select
                        withoutForm
                        name="grupo"
                        label="Grupo"
                        onChange={selected => {
                            setVisaoSelecionada(visoesDisponiveis.find(v => v.id === selected.value))
                        }}
                        options={[
                            { label: 'Todos os grupos', value: 0 },
                            ...(visoesDisponiveis?.map(visao => ({
                                label: visao.descricao,
                                value: visao.id
                            })) || [])
                        ]}
                        placeholder="Selecione a enquete..."
                    />
                )}

                <Button className="transparent" onClick={loadEnqueteData}>
                    Buscar
                    <FiSearch size={16} />
                </Button>
            </div>

            {enqueteData === 'loading' ? (
                <Spinner label="Carregando dados da enquete..." />
            ) : enqueteData ? (
                <div className="questoes-container">
                    {enqueteData.map(({ questao, dados_grafico }) => (
                        <div className="questao-container" key={questao.id}>
                            <p>{`${questao.identificador} - ${questao.texto}`}</p>

                            {Object.values(dados_grafico).every(i => i.value === 0) ? (
                                <div>Nenhuma resposta computada para esta questão.</div>
                            ) : (
                                <PieChart data={dados_grafico} width={440} height={240} />
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <p style={{ textAlign: 'center' }}>Selecione uma enquete para ver as estatísticas.</p>
            )}
        </Container>
    )
}
