import React, {
    useCallback, useState, useEffect, useContext 
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import Yup from '../../../../../services/yup'

import { api, authHeaders } from '../../../../../services/api'

import {
    Textbox, Button, Select, Checkbox 
} from '../../../../../components/Form'
import Modal from '../../../../../components/Modal'
import Table from '../../../../../components/Table'

import { Container } from './styles'
import { TipContext } from '../../../../../contexts/TipContext'
import { loadTips } from '../../../../../util/tip'

const validation = Yup.object().shape({
    nome: Yup.string().required('Informe o nome do evento.'),
    descricao: Yup.string().notRequired(),
    conta_contabil: Yup.number().integer().typeError('A conta contábil deve ser um valor numérico.').notRequired(),
    tipo: Yup.string().ensure().required('Informe o tipo de evento'),
    conta_obrigatoria: Yup.boolean()
})

export default function ({ editId, initialValues, reload }) {
    const { setCodigo, tips } = useContext(TipContext)

    const [tipos, setTipos] = useState([])
    const [planoContas, setPlanoContas] = useState(null)
    
    async function loadTipos() {
        const response = await api.get('tipo_evento_contabil', authHeaders()) 

        setTipos(response.map(tipo => ({
            label: tipo.descricao,
            value: tipo.id
        })))
    }

    async function loadPlanoContas() {
        const response = await api.get('plano_de_contas', authHeaders())
        
        setPlanoContas(response)
    }

    useEffect(() => {
        loadTipos()
    }, [])

    async function handleAdd(values, { resetForm }) {
        values.tipo = values.tipo.value
		
        try {
            await api.post('evento_contabil', values, authHeaders())
			
            reload()
			
            toast.success('Evento cadastrado com sucesso!')

            resetForm()
        } catch(e) {
            toast.error(e.msg)
        }
    }
	
    const handleEdit = useCallback(async values => {
        values.tipo = values.tipo.value
		
        try {
            await api.put(`evento_contabil/${editId}`, values, authHeaders())
			
            reload()

            toast.success('Evento atualizado!')
        } catch(e) {
            toast.error(e.msg)
        }
    }, [editId, reload])
    
    useEffect(() => {
        loadTips(setCodigo)
    }, [])
	
    return (
        <>
            <Container className="animated fadeIn faster" id="form_eventos_contabeis_cadastro">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={editId ? handleEdit : handleAdd}
                    enableReinitialize
                >
                    {({
                        isSubmitting, setFieldValue 
                    }) => (
                        <Form>
                            <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                            <Textbox 
                                label="Nome do evento" 
                                name="nome"
                                containerStyle={{ gridArea: 'f1' }}
                            />

                            <Textbox 
                                label="Descrição" 
                                name="descricao"
                                containerStyle={{ gridArea: 'f2' }}
                            />

                            <Select 
                                name="tipo"
                                label="Tipo de evento"
                                onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                options={tipos}
                                containerStyle={{ gridArea: 'f3' }}
                            />

                            <Textbox 
                                label="Conta contábil" 
                                name="conta_contabil"
                                containerStyle={{ gridArea: 'f4' }}
                            />

                            <Checkbox 
                                name="conta_obrigatoria" 
                                label="Conta corrente não obrigatória" 
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                containerStyle={{ gridArea: 'f5' }}
                            />

                            <Checkbox 
                                name="recorrente" 
                                label="Evento recorrente" 
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                containerStyle={{ gridArea: 'f6' }}
                            />

                            <div className="button-container">
                                <Button 
                                    onClick={() => {
                                        loadPlanoContas()
                                    }}
                                    className="transparent" 
                                >
                                    Ver Plano de Contas
                                </Button>

                                <Button type="submit" loading={isSubmitting} className="white">
                                    Salvar evento
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>

            <Modal
                isOpen={!!planoContas} 
                title="Plano de Contas"
                handleClose={() => { setPlanoContas(null) }}
            >
                <Table
                    showId
                    filterable
                    headers={[
                        { name: 'descricao', value: 'Descrição' },
                        { name: 'codigo_reduzido', value: 'Código reduzido' }
                    ]}
                    data={planoContas?.filter(item => item.codigo_reduzido)}
                    style={{ marginBottom: 24 }}
                />
            </Modal>
        </>
    )
}
