import styled from 'styled-components'

import { Container as Card } from '../../components/Card/styles'

export const Container = styled.div`
	margin: 5vh;
	width: 100%;
	display: flex;
	justify-content: center;

	@media(max-width: 550px) {
		margin-top: 2vh;
	}
`

export const Content = styled(Card).attrs({
	className: 'no-responsive'
})`
	width: 315px;
	text-align: center;

	img {
		width: 150px;
	}

	form {
		display: flex;
		flex-direction: column;

		input {
			width: 100%;
		}

		p {
			color: #fff;
			padding: 15px;
		}

		button {
			margin: 14px 8px;
		}

		a {
			color: #fff;
			font-size: 14px;
			opacity: 0.8;

			&:hover {
				opacity: 1;
			}
		}
	}
`

export const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
`
