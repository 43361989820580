import styled from 'styled-components'

export const Container = styled.article`
	form {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			  'tip tip tip tip'
              'f1 f2 f3 f3'
              'f4 f5 f5 f6'
			  'f7 f7 f8 f8'
              'bg bg bc bc';

		.button-months-container {
			display: flex;
			grid-area: bg;
			justify-self: start;
			padding-top: calc(20px - 5px);

			@media(max-width: 900px) {
				display: none;
			}
		}

		.button-container {
			display: flex;
			grid-area: bc;
			justify-self: end;
			padding-top: calc(20px - 5px);

			> button:last-child {
				width: 180px;
			}
		}

		@media(max-width: 900px) {
			grid-template-columns: unset;
			grid-template-areas:
			  'tip tip'
              'f1 f2'
              'f3 f4'
			  'f5 f5'
			  'f6 f6'
			  'f7 f7'
			  'f8 f8'
              'bc bc';
		}

		@media(max-width: 600px) {
			grid-template-areas:
			  'tip'
              'f1'
              'f2'
			  'f3'
			  'f4'
			  'f5'
			  'f6'
			  'f7'
			  'f8'
              'bc';

			.field.checkbox {
				grid-column: unset;
			}

			& > button {
				max-width: unset;
			}
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`
