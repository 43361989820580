import styled from 'styled-components'

import { Container as Card } from '../../../../components/Card/styles'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		${Card} {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 54px auto;
			min-height: 70vh;

			.table-container,
			.require-rotation,
			.spinner-container {
				grid-column: 1/3;
			}

			button {
				width: fit-content;
				justify-self: flex-end;
			}
		}

		@media(max-width: 1000px) {
			padding: 20px 16px;
		}

		@media(max-width: 560px) {
			.table-container,
			.button-cadastrar {
				display: none;
			}
		}

		@media(max-width: 1000px) {
			padding: 10px 0;
		}
	}
`

export const EventDetails = styled.div`
	h1 {
		font-size: 17px;
	}

	h2 {
		font-size: 14px;
		margin: 0 8px;
		line-height: 24px;
	}
`
