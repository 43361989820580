import React, { useContext } from 'react'
import { Formik, Form } from 'formik'

import Yup from '../../../../services/yup'

import masks from '../../../../util/masks'

import Modal from '../../../../components/Modal'
import {
    Button, Textbox, Select, Calendar
} from '../../../../components/Form'

import { InconsistenciaContext } from '../../../../contexts/InconsistenciaContext'

import { Container } from './styles'

const validation = Yup.object().shape({
    crm: Yup.string(),
    nome: Yup.string(),
    competencia: Yup.string(),
    data_consolidacao: Yup.string(),
    valor: Yup.string().money('Complete com os centavos.').required('Especifique o valor da contribuição.'),
    status: Yup.string().ensure(),
    tipo_pagamento: Yup.string().ensure(),
    instituicao: Yup.string().ensure()
})

export default function () {
    const {
        editId, setEditId, initialValues, handleEdit, tiposPagamento, instituicoes
    } = useContext(InconsistenciaContext)

    return (
        <Modal
            isOpen={!!editId}
            handleClose={() => { setEditId(null) }}
        >
            <Container className="animated fadeIn faster">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleEdit}
                    enableReinitialize
                >
                    {({
                        isSubmitting, setFieldValue
                    }) => (
                        <Form style={{ marginBottom: 30 }}>
                            <Textbox
                                name="crm"
                                label="Matrícula"
                            />

                            <Textbox
                                name="nome"
                                label="Nome"
                            />

                            <Textbox
                                name="valor"
                                label="Valor"
                                mask={masks.money}
                            />

                            <Select
                                label="Status do pagamento"
                                name="status"
                                onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                options={[
                                    { label: 'Efetuados', value: 1 },
                                    { label: 'Pendentes', value: 0 }
                                ]}
                            />

                            <Calendar
                                label="Competência"
                                name="competencia"
                                view="month"
                                dateFormat="mm/yy"
                                yearRange={`${new Date().getFullYear() - 100}:${new Date().getFullYear()}`}
                            />

                            <Calendar
                                label="Data"
                                name="data_consolidacao"
                                yearRange={`${new Date().getFullYear() - 100}:${new Date().getFullYear()}`}
                            />

                            <Select
                                label="Tipo de pagamento"
                                name="tipo_pagamento"
                                onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                options={tiposPagamento.map(tipo => ({
                                    label: tipo.descricao_resumida,
                                    value: tipo.id
                                }))}
                            />

                            <Select
                                label="Instituição"
                                name="instituicao"
                                onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                options={instituicoes.map(instituicao => ({
                                    label: instituicao.sigla,
                                    value: instituicao.id
                                }))}
                            />

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                                style={{ gridArea: 'button' }}
                            >
                                {editId ? 'Salvar alterações' : 'Cadastrar pagamento'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Modal>
    )
}
