import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 100px;
		
		.card {
			min-height: 64vh;
			display: flex;
			flex-direction: column;

			form {
				display: flex;
				margin-top: 24px;

				.textbox {
					width: 250px;
				}

				> button {
					margin-top: 24px;
				}
			}
		}

		@media(max-width: 700px) {
			padding: 30px 20px;
		}

		@media(max-width: 500px) {
			padding: 0;
		}
	}
`

export const CertificadoContainer = styled.div.attrs({
    className: 'animated fadeIn'
})`
	display: flex;
	justify-content: center;

	.content {
		display: grid;
		grid-template-columns: 180px auto;
		grid-template-rows: auto 60px auto auto 60px;
		grid-template-areas: 
			'title title'
			'foto nome'
			'foto evento'
			'foto detalhes'
			'button button';
		margin: 24px 0;
		grid-gap: 0 20px;
		width: 100%;
		max-width: 600px;

		> p {
			grid-area: text;
			font-size: 15px;
		}

		.title {
			grid-area: title;
			font-weight: 600;
			text-align: center;
    		font-size: 24px;
			margin-bottom: 14px;
		}
		
		h1 {
			grid-area: nome;
			align-self: flex-end;
		}

		h2 {
			grid-area: evento;
			font-size: 18px;
			font-weight: 600;
			align-self: center;
		}

		img {
			grid-area: foto;
			width: 180px;
			height: 180px;
			object-fit: cover;
			justify-self: center;
			border-radius: 50%;
			box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
		}

		> div {
			grid-area: detalhes;
			display: flex;
			gap: 0 50px;
			line-height: 24px;

			@media(max-width: 700px) {
				flex-direction: column;
			}
		}

		> button {
			grid-area: button;
			justify-self: flex-end;
		}

		@media(max-width: 700px) {
			grid-template-columns: 1fr;
			grid-template-rows: auto auto auto auto auto;
			grid-template-areas: 
				'title'
				'foto'
				'nome'
				'evento'
				'detalhes'
				'button';

				img {
					width: 160px;
					margin: 12px;
				}

				h1 { 
					text-align: center;
					margin-bottom: 12px;
				}	
				
				h2 {
					margin-bottom: 12px;
				}

				> div {
					margin-bottom: 14px;
				}
		}
	}
`

export const NaoEncontradoContainer = styled.div.attrs({
    className: 'animated fadeIn'
})`
	> button {
		width: fit-content;
		margin: 14px 0;
	}

	.certificado-nao-encontrado {
		margin-top: 24px;
	}
`
