import React, { useEffect, useState } from 'react'
import { FiEyeOff } from 'react-icons/fi'

import useEventListener from '../../hooks/useEventListener'

import rotatePhone from '../../assets/images/rotate-phone.png'

import { Container } from './styles'

export default function ({ text = 'Gire o dispositivo para visualizar os dados.', hideOffset = 500 }) {
    const [angle, setAngle] = useState(0)
    const [displayWidth, setDisplayWidth] = useState()
    const [insuficientDisplaySize, setInsuficientDisplaySize] = useState(false)

    useEventListener('orientationchange', () => {
        if (window.screen.availHeight > window.screen.availWidth) {
            setAngle(0)
            setDisplayWidth(window.screen.availWidth)
        } else {
            setAngle(90)
            setDisplayWidth(window.screen.availWidth)
        }
    })

    useEffect(() => {
        // Modo paisagem: angle = 90
        setInsuficientDisplaySize(angle === 90 && displayWidth < hideOffset)
    }, [angle, displayWidth])

    return (
        <Container hideOffset={hideOffset}>
            {!insuficientDisplaySize ? (
                <>
                    <img src={rotatePhone} alt="" />

                    <span>{text}</span>
                </>
            ) : (
                <>
                    <FiEyeOff size={50} />

                    <span>
                        Seu dispositivo não tem dimensões suficientes para exibir os dados.
                    </span>
                </>
            )}
        </Container>
    )
}
