import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { Container, ButtonContainer } from './styles'

import { Calendar, Button, Select } from '../../../../../components/Form'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

import { forceDownloadFileFromURL } from '../../../../../util/file'
import formUtils from '../../../../../util/form'
import Spinner from '../../../../../components/Spinner'

const nomeRelatorio = 'Relatório de Atestados Médicos e Declarações de Comparecimento'

const validation = Yup.object().shape({
    periodo: Yup.string().nullable().required('Informe um periodo para a busca.')
})

export default function (props) {
    const [colaboradores, setColaboradores] = useState(null)

    async function loadColaboradores() {
        const response = await api.get('colaborador', authHeaders())

        setColaboradores(response)
    }

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        try {
            values = formUtils.extractFormValues(values)

            const response = await api.post('colaborador/relatorio/atestados_medicos', values, authHeaders())

            forceDownloadFileFromURL(response.link, nomeRelatorio)

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    useEffect(() => {
        loadColaboradores()
    }, [])

    if (!colaboradores) {
        return <Spinner />
    }

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{ periodo: '', colaborador: '', tipo: '' }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, resetForm }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar
                            name="periodo"
                            label="Período para busca"
                            selectionMode="range"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="colaborador"
                            label="Empregado"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            options={colaboradores.map(col => ({
                                value: col.pessoa.id,
                                label: col.pessoa.nome
                            }))}
                        />

                        <Select
                            name="tipo"
                            label="Tipo de documento"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                            options={[
                                {
                                    value: 10,
                                    label: 'Atestado médico'
                                },
                                {
                                    value: 58,
                                    label: 'Declaração de comparecimento'
                                }
                            ]}
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
