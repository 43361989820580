import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			min-height: 80vh;

			h1 {
				margin-bottom: 20px;
			}

			form {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;

				.label-segmento {
					grid-column: 1/span 3;
					margin: 20px 12px 8px;
				}

				@media(max-width: 900px) {
					display: flex;
					flex-direction: column;
				}
			}
		}

		@media(max-width: 440px) {
			padding: 20px 0;
		}
	}
`

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 14px;
	grid-column: 1/span 3;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`
