import React from 'react'
import { FiEye } from 'react-icons/fi'
import ReactTooltip from 'react-tooltip'

import Table from '../../components/Table'
import Spinner from '../../components/Spinner'

import { Container, ExtraText } from './styles'

export default function ({
    title,
    headers,
    data,
    limit = 5,
    onIconClick,
    compacto = false,
    style = {},
    actions = [],
    extraText,
    hideOnSmallHeaders,
    hideHeadersOffset
}) {
    return (
        <>
            <Container compacto={compacto} style={style}>
                <h1>{title}</h1>

                {extraText && <ExtraText>{extraText}</ExtraText>}

                {onIconClick ? (
                    <FiEye
                        size={20}
                        data-tip="Detalhar"
                        data-for="box-list-tooltip"
                        onClick={onIconClick}
                    />
                ) : (
                    <div />
                )}

                {data ? (
                    <Table
                        headers={headers}
                        data={data}
                        limit={limit}
                        filterable={false}
                        actions={actions}
                        hideOffset={400}
                        hideOnSmallHeaders={hideOnSmallHeaders}
                        hideHeadersOffset={hideHeadersOffset}
                    />
                ) : (
                    <Spinner />
                )}
            </Container>

            <ReactTooltip
                id="box-list-tooltip"
                place="top"
                effect="solid"
                type="light"
            />
        </>
    )
}
