import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { Item } from './styles'

export default function ({ permissao, draggableId, ...props }) {
    return (
        <Draggable draggableId={draggableId} {...props}>
            {provided => (
                <Item
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <h1>{`#${permissao.id} ${permissao.descricao}`}</h1>
                    <h2>{permissao.subdescricao}</h2>
                </Item>
            )}
        </Draggable>
    )
}
