import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			> h1 {
				margin-bottom: 20px;
			}

			> p {
				margin: 8px;
			}

			form {
				display: grid;
				grid-template-columns: 2fr 1fr;
				grid-template-areas:
					'descricao modelo'
					'preview params'
					'button button';

				/* .identificacao-documento {
					display: grid;
					grid-template-columns: 2fr 1fr;
				} */

				.button-container {
					grid-area: button;
					display: flex;
					justify-content: end;
					margin-top: 16px;
				}
			}
		}

		> h1 {
			margin: 0 8px 20px;
			grid-area: title;
			margin-bottom: 24px;
		}

		@media(max-width: 800px) {
			padding: 20px;
		}

		@media(max-width: 400px) {
			padding: 15px 0;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`

export const ParametrosLista = styled.div`
	grid-area: params;
	display: flex;
	flex-direction: column;
	margin-top: 36px;
	width: 100%;

	> h1 {
		font-size: 18px;
		padding: 0 8px;
		margin: 0 0 14px !important;
	}

	> i {
		align-self: center;
	}

	> p {
		line-height: 20px;
	}
`

export const PreviewTextContainer = styled.div`
	grid-area: preview;

	> h1 {
		font-size: 18px !important;
		margin: 16px 8px 12px !important;
		font-weight: 500;

		& + p {
			margin: 0 8px 14px;
		}
	}

	> div {
		width: calc(100% - 16px);
		background-color: #fff;
		margin: 0 8px;
		box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
		color: #424242;
		padding: 30px;

		> h1 {
			color: #424242;
			margin: 0 0 18px;
		}
	}

	> iframe {
		width: 100%;
		height: 500px;
	}
`

export const LinkDocumentoContainer = styled.div`
	display: flex;
	justify-content: center;
	
	a {
		width: fit-content;
	}
`
