import React from 'react'

import { Container } from './styles'

const maxTitleLength = 60

export default function ({
    image, title, link 
}) {
    return (
        <Container href={link} target="_blank">
            <div className="image" style={{ backgroundImage: `url(${image})` }} />
            <h1>{title.length > maxTitleLength ? `${title.substr(0, maxTitleLength)}...` : title}</h1>
        </Container>
    )
}
