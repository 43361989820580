import React, {
    createContext, useState, useEffect
} from 'react'
import { toast } from 'react-toastify'
import { getYear, getMonth, lastDayOfMonth } from 'date-fns'

import { api, authHeaders } from '../services/api'

import formUtils from '../util/form'
import stringUtils from '../util/string'
import dateUtils, { firstDayOfMonth } from '../util/date'
import masks from '../util/masks'

export const OrcamentoContext = createContext()

const anoAtual = getYear(new Date())

function findConta(plano, contaId) {
    const conta = plano.find(p => p.conta_contabil === contaId)

    return conta
}

function loadPlanoContas(contas, orcamentos, lancamentos) {
    let planoContas = contas.map(conta => ({
        id: null,
        conta_id: conta.id,
        conta_contabil: conta.conta_contabil,
        orcado: 0.0,
        executado: 0.0,
        ano: getYear(new Date()),
        descricao: conta.descricao,
        analitica: conta.conta_contabil.split('.').length === 6 || conta.conta_contabil[0] === '6',
        alertas: []
    }))

    for (const orc of orcamentos) {
        const conta = planoContas.find(p => p.conta_contabil === orc.conta.conta_contabil)

        conta.orcado += Number(orc.valor)
        conta.ano = orc.ano
        conta.id = orc.id
        conta.alertas = orc.alertas
    }

    for (const { conta_contabil: conta_id, orcado } of planoContas.filter(p => p.analitica && p.orcado)) {
        let contaId = conta_id

        while (contaId.indexOf('.') > 0) {
            const posicaoUltimoSegmento = contaId.lastIndexOf('.')

            contaId = contaId.substr(0, posicaoUltimoSegmento)

            const conta = findConta(planoContas, contaId)

            conta.orcado += orcado
        }
    }

    planoContas = planoContas.filter(p => p.orcado)

    for (const orcamento of planoContas) {
        const lancamentosASomar = lancamentos.filter(l => l.evento.conta_contabil?.id === orcamento.conta_id && l.consolidado)

        orcamento.executado = lancamentosASomar.reduce((result, current) => result + Number(current.valor), 0.0)
    }

    for (const orcamentoPai of planoContas.filter(p => !p.analitica)) {
        orcamentoPai.executado += planoContas.filter(p => p.analitica && p.conta_contabil.indexOf(orcamentoPai.conta_contabil) === 0)
            .reduce((result, current) => result + current.executado, 0.0)
    }

    return planoContas
}

function getDescricaoComRecuo(orcamento) {
    const recuo = 16

    const niveis = orcamento.conta_contabil.split('.').length - 1

    return niveis * recuo
}

function obterDestaque(orcamento) {
    if (orcamento.orcado <= orcamento.executado) {
        return {
            background: '#ffcdd2',
            tooltip: 'O orçamento foi alcançado.'
        }
    }

    const percentualExecutado = (orcamento.executado * 100) / orcamento.orcado

    const alertaNiveis = ['#fff9c4', '#fff59d', '#fff176']

    for (const alerta of orcamento.alertas) {
        if (alerta.percentual < percentualExecutado) {
            return {
                background: alertaNiveis[alerta.nivel - 1],
                tooltip: `Foi atingido ${alerta.percentual}% do valor orçado.`
            }
        }
    }

    return {}
}

async function loadOrcamentos(ano) {
    const response = await api.get('orcamento', {
        params: { ano },
        ...authHeaders()
    })

    return {
        ...response,
        orcamentos: response.orcamentos.map(o => ({
            ...o,
            valor: Number(Number(o.valor).toFixed(2))
        }))
    }
}

async function loadContasContabeis() {
    const response = await api.get('plano_de_contas', authHeaders())

    return response
}

function loadContasContabeisAnaliticas(planoContas, contasContabeis) {
    const prefixoContasDespesas = ['4.01.07', '4.01.09', '4.03']

    return contasContabeis
        .filter(conta => conta.analitica && prefixoContasDespesas.find(prefixo => conta.conta_contabil.startsWith(prefixo)))
        .map(ca => ({
            ...ca,
            executado: planoContas.find(pc => pc.conta_contabil === ca.conta_contabil)?.executado || 0.0
        }))
}

function loadOrcadoTotal(planoContas) {
    return planoContas.filter(p => p.analitica && p.orcado).reduce((total, current) => total + current.orcado, 0)
}

function loadSaldoOrcamento(orcamentosAnoAtual, orcadoTotal) {
    return Number(Number(orcamentosAnoAtual.saldo_ano_anterior + orcamentosAnoAtual.receita_prevista - orcadoTotal).toFixed(2))
}

function loadPercentualOrcamentoConsumo(orcamentosAnoAtual, orcadoTotal) {
    return Number(orcamentosAnoAtual.receita_prevista) === 0 ? null : (orcadoTotal * 100) / Number(Number(orcamentosAnoAtual.receita_prevista + orcamentosAnoAtual.saldo_ano_anterior).toFixed(2))
}

function prepareOrcamentos(planoContas) {
    return planoContas.map(orc => {
        const percentualExecutado = (orc.executado * 100) / orc.orcado

        return {
            id: orc.conta_contabil,
            orcamento_id: orc.id,
            conta_id: orc.conta_id,
            conta_contabil: orc.conta_contabil,
            descricao: orc.descricao,
            orcado_decimal: orc.orcado,
            orcado: masks.maskApply.currency(orc.orcado),
            executado: masks.maskApply.currency(orc.executado),
            percentual_executado: masks.maskApply.percent(percentualExecutado <= 100 ? Number(percentualExecutado.toFixed(2)) : 100),
            ano: orc.conta_contabil.length === 20 ? orc.ano : '',
            analitica: orc.analitica,
            alertas: orc.alertas,
            indent: getDescricaoComRecuo(orc),
            ...obterDestaque(orc)
        }
    })
}

async function loadContasCorrente(inicio = '2018-12-31', fim, somar_saldo_inicial = true) {
    const contasObsoletas = [14, 20]

    const options = {
        ...authHeaders(),
        params: {
            contas_obsoletas: contasObsoletas
        }
    }

    if (fim) {
        options.params = {
            ...options.params,
            inicio,
            fim,
            somar_saldo_inicial
        }
    }

    const response = await api.get('conta_sindicato', options)

    return {
        contas_correntes: response.map(cc => ({
            ...cc,
            saldo: Number(Number(cc.saldo).toFixed(2))
        })),
        saldo_geral_contas_correntes: Number(response.reduce((total, current) => total + Number(current.saldo), 0).toFixed(2)),
        receita_geral_contas_correntes: Number(response.reduce((total, current) => total + Number(current.receita), 0).toFixed(2)),
        despesa_geral_contas_correntes: Number(response.reduce((total, current) => total + Number(current.despesa), 0).toFixed(2))
    }
}

async function loadContasAplicacoes(inicio = '2022-12-31', fim, somar_saldo_inicial = true) {
    const contasObsoletas = [12, 14, 15, 17, 18, 20, 17]

    const options = {
        ...authHeaders(),
        params: {
            contas_obsoletas: contasObsoletas
        }
    }

    if (fim) {
        options.params = {
            ...options.params,
            inicio,
            fim,
            somar_saldo_inicial
        }
    }

    const response = await api.get('conta_sindicato_aplicacoes', options)

    return {
        contas_aplicacoes: response.map(cc => ({
            ...cc,
            saldo: Number(Number(cc.saldo).toFixed(2))
        })),
        saldo_geral_contas_aplicacoes: Number(response.reduce((total, current) => total + Number(current.saldo), 0).toFixed(2)),
        receita_geral_contas_aplicacoes: Number(response.reduce((total, current) => total + Number(current.receita), 0).toFixed(2)),
        despesa_geral_contas_aplicacoes: Number(response.reduce((total, current) => total + Number(current.despesa), 0).toFixed(2))
    }
}

async function loadLancamentos(ano) {
    const response = await api.get('lancamento', {
        params: {
            start: `${ano}-01-01 00:00:00`,
            end: `${ano}-12-31 23:59:59`
        },
        ...authHeaders()
    })

    return response.length ? response.map(l => ({
        ...l,
        valor: Number(Number(l.valor).toFixed(2))
    })) : []
}

function loadLancamentosReceita(lancamentos) {
    const eventosDesconsiderar = [166, 162, 198, 178, 165]
    return lancamentos?.filter(i => i.conta_corrente && i.consolidado && !eventosDesconsiderar.includes(i.evento.id) && i.evento.tipo_evento_contabil.sigla.indexOf('R') === 0) || []
}

function loadLancamentosDespesa(lancamentos, orcamentos, anoLancamentos) {
    const eventosDesconsiderar = [163, 167, 164, 122]
    const contasAConsiderar = orcamentos?.orcamentos.map(o => o.conta.id)
    let lancamentosList = []

    lancamentosList = lancamentos?.filter(i => i.conta_corrente
        && i.consolidado
        && i.evento.tipo_evento_contabil.sigla.indexOf('D') === 0
        && !eventosDesconsiderar.includes(i.evento.id)
        && contasAConsiderar.includes(i.evento.conta_contabil.id))

    // if (anoLancamentos === anoAtual) {
    //     lancamentosList = lancamentos?.filter(i => i.conta_corrente
    //         && i.consolidado
    //         && i.evento.tipo_evento_contabil.sigla.indexOf('D') === 0
    //         && !eventosDesconsiderar.includes(i.evento.id)
    //         && contasAConsiderar.includes(i.evento.conta_contabil.id))
    // } else {
    //     lancamentosList = lancamentos?.filter(i => i.conta_corrente
    //         && i.consolidado
    //         && i.evento.tipo_evento_contabil.sigla.indexOf('D') === 0
    //         && !i.caixa
    //         && !eventosDesconsiderar.includes(i.evento.id))
    // }

    return lancamentosList
}

function loadLancamentosDespesaAnoAnterior(lancamentos) {
    const eventosDesconsiderar = [163, 167, 164, 122]
    // const contasAConsiderar = orcamentos?.orcamentos.map(o => o.conta.id)
    let lancamentosList = []

    lancamentosList = lancamentos?.filter(i => i.conta_corrente
        && i.consolidado
        && i.evento.tipo_evento_contabil.sigla.indexOf('D') === 0
        && !i.caixa
        && !eventosDesconsiderar.includes(i.evento.id))

    return lancamentosList
}

// Considera as contas que não foram orçadas, sem a despesa com plano de saúde.
function loadSomatorioInvestimentos(lancamentos, orcamentos) {
    const eventosDesconsiderar = [163, 167, 164, 122, 69, 114]
    const contasAConsiderar = orcamentos?.orcamentos.map(o => o.conta.id)

    const somatorioInvestimentos = lancamentos?.filter(i => i.conta_corrente
        && i.consolidado
        && i.evento.tipo_evento_contabil.sigla.indexOf('D') === 0
        && !eventosDesconsiderar.includes(i.evento.id)
        && !contasAConsiderar.includes(i.evento.conta_contabil.id))
        .reduce((total, current) => total + Number(current.valor), 0)

    return somatorioInvestimentos
}

function loadLancamentosPorMesPorContaCorrente(lancamentos, contasCorrente) {
    const lancamentosPorConta = []
    const eventosDesconsiderar = [122, 165, 163, 167, 164, 166, 162, 198, 178, 158, 114]

    for (let i = 1; i <= 12; i++) {
        const lancamentosLista = {
            mes: stringUtils.capitalize(dateUtils.months.find(m => m.value === i).label),
            ano: getYear(new Date(lancamentos.filter(l => l.data_consolidacao)[0].data_consolidacao))
        }

        for (const conta of contasCorrente) {
            const lancamentosContaCorrente = lancamentos.filter(l => l.conta_corrente.id === conta.id && !eventosDesconsiderar.includes(l.evento.id))

            const somatoria = lancamentosContaCorrente
                .filter(rec => getMonth(new Date(rec.data_consolidacao)) + 1 === i)
                .reduce((total, current) => total + Number(current.valor), 0)

            lancamentosLista[`conta_${conta.id}`] = somatoria
            lancamentosLista[`conta_${conta.id}_masked`] = masks.maskApply.currency(somatoria)
        }

        lancamentosPorConta.push(lancamentosLista)
    }

    return lancamentosPorConta
}

function loadSomatoriasPorContaCorrente(contasCorrente, lancamentos) {
    const valores = []

    for (const conta of contasCorrente) {
        const lancamentosContaCorrente = lancamentos.filter(l => l.conta_corrente && l.conta_corrente.id === conta.id)

        const somatoria = lancamentosContaCorrente.reduce((total, current) => total + Number(current.valor), 0)

        valores.push({
            conta: conta.id,
            total: Number(Number(somatoria).toFixed(2)),
            total_masked: masks.maskApply.currency(somatoria)
        })
    }

    return valores
}

function loadSomatoriaAno(somatoriasPorConta) {
    return Number(Number(somatoriasPorConta.reduce((total, current) => total + Number(current.total), 0)).toFixed(2))
}

function loadPercentualConsumidoRealizado(orcamentos, somatoriaReceitas, somatoriaDespesas, receitaPlanoSaude, despesaPlanoSaude) {
    const { saldo_ano_anterior } = orcamentos

    if (saldo_ano_anterior + somatoriaReceitas === 0) {
        return 0
    }

    // return Number(Number((somatoriaDespesas * 100) / (saldo_ano_anterior + (somatoriaReceitas - receitaPlanoSaude))).toFixed(2))
    return Number(Number((somatoriaDespesas * 100) / ((somatoriaReceitas - receitaPlanoSaude))).toFixed(2))
}

async function loadSomatoriaPlanoSaude(ano, tipo) {
    if (tipo === 'receita') {
        const response = await api.post('lancamento_query', {
            evento: [158],
            inicio: `${ano}-01-01`,
            fim: `${ano}-12-31`,
            tipo_lancamento: 1
        }, authHeaders())

        return response.reduce((total, current) => Number((total + Number(current.valor)).toFixed(2)), 0)
    }

    const response = await api.post('lancamento_query', {
        evento: [114],
        inicio: `${ano}-01-01`,
        fim: `${ano}-12-31`,
        tipo_lancamento: 1
    }, authHeaders())

    return response.reduce((total, current) => Number((total + Number(current.valor)).toFixed(2)), 0)
}

async function loadMediaContasRecorrentes(ano) {
    const response = await api.post('lancamento_query', {
        inicio: `${ano}-01-01`,
        fim: `${ano}-12-31`
    }, {
        params: {
            media_valor: 1,
            recorrente: 1,
            tipo_lancamento: 1
        },
        ...authHeaders()
    })

    return response
}

async function loadSaldoAplicacoes(ano) {
    const eventosReceita = [163, 167, 164, 178]
    const eventosDespesa = [166, 162, 198]
    const eventos = eventosDespesa.concat(eventosReceita)

    const saldoContaAplicacao = await api.get('saldos/14', authHeaders())

    if (ano === 2022) {
        return Number(saldoContaAplicacao.valor)
    }

    const response = await api.post('lancamento_query', {
        evento: eventos,
        inicio: '2023-01-01',
        fim: `${ano}-12-31`
    }, authHeaders())

    // Leva em consideração os rendimentos lançados manualmente no evento 178
    const saldoReceita = response.filter(item => eventosReceita.includes(item.evento.id)).reduce((total, current) => total + Number(current.valor), 0)

    const saldoDespesa = response.filter(item => eventosDespesa.includes(item.evento.id)).reduce((total, current) => total + Number(current.valor), 0)

    const totalReceita = saldoReceita + Number(saldoContaAplicacao.valor)

    return totalReceita - saldoDespesa
}

const OrcamentoProvider = ({ children }) => {
    const [dadosAnoAnterior, setDadosAnoAnterior] = useState({
        saldo: 0,
        lancamentos: 0,
        lancamentos_receita: 0,
        lancamentos_despesa: 0,
        lancamentos_receita_por_mes_por_conta: 0,
        lancamentos_despesa_por_mes_por_conta: 0,
        somatorias_receitas_conta_corrente: 0,
        somatorias_despesas_conta_corrente: 0,
        somatoria_receitas_ano: 0,
        somatoria_despesas_ano: 0,
        percentual_consumido: 0
    })
    const [dadosAnoAtual, setDadosAnoAtual] = useState({
        receita_prevista: 0,
        lancamentos: null,
        orcamentos: 0,
        plano_de_contas: 0,
        total_orcado: 0,
        saldo_orcamento: 0,
        orcamento_pecentual_consumo: 0,
        contas_contabeis: 0,
        contas_contabeis_analiticas: 0
    })
    const [ano, setAno] = useState(anoAtual)
    const [contasCorrentes, setContasCorrentes] = useState([])
    const [contasAplicacoes, setContasAplicacoes] = useState([])
    const [contasCorrentesPeriodo, setContasCorrentesPeriodo] = useState([])
    const [saldoGeralContasCorrentes, setSaldoGeralContasCorrentes] = useState(0)
    const [saldoGeralContasAplicacoes, setSaldoGeralContasAplicacoes] = useState(0)
    const [receitaGeralContasCorrentes, setReceitaGeralContasCorrentes] = useState(0)
    const [despesaGeralContasCorrentes, setDespesaGeralContasCorrentes] = useState(0)
    const [showCadastro, setShowCadastro] = useState(false)
    const [orcamentoEmEdicao, setOrcamentoEmEdicao] = useState(null)
    const [loading, setLoading] = useState(true)

    function loadValoresPorMesPorConta(receitasPorMesPorConta, despesasPorMesPorConta) {
        const valores = []

        for (let i = 0; i < 12; i++) {
            const receita = Object.entries(receitasPorMesPorConta[i])
                .filter(([k]) => /^(conta_)\d{2}$/g.test(k))
                // eslint-disable-next-line no-unused-vars
                .map(([_, v]) => v)
                .reduce((total, current) => total + Number(current), 0)
            const despesa = Object.entries(despesasPorMesPorConta[i])
                .filter(([k]) => /^(conta_)\d{2}$/g.test(k))
                // eslint-disable-next-line no-unused-vars
                .map(([_, v]) => v)
                .reduce((total, current) => total + Number(current), 0)

            const saldo = receita - despesa
            const consumo = saldo < 0 ? 100 : receita === 0 ? 0 : Number(Number((despesa * 100) / receita).toFixed(2))

            const numeroMesReceita = dateUtils.months.find(m => m.label === receitasPorMesPorConta[i].mes.toLowerCase()).value
            const numeroMesAtual = getMonth(new Date()) + 1
            const numeroAno = getYear(new Date())
            const numeroAnoReceita = receitasPorMesPorConta[i].ano

            valores.push({
                mes: receitasPorMesPorConta[i].mes,
                receita,
                despesa,
                saldo,
                consumo,

                receita_masked: masks.maskApply.currency(receita),
                despesa_masked: masks.maskApply.currency(despesa),
                saldo_masked: masks.maskApply.currency(saldo),
                consumo_masked: masks.maskApply.percent(consumo),

                background: (numeroMesReceita > numeroMesAtual && numeroAnoReceita > numeroAno) && saldo < 0 ? '#fff59d' : saldo < 0 ? '#facacf' : 'unset'
            })
        }

        return valores
    }

    async function loadSaldoFimAnoAnterior() {
        const anoAnterior = ano - 1

        const { saldo } = await api.get('saldos', {
            params: {
                fim: `${anoAnterior}-12-31`
            },
            ...authHeaders()
        })

        return saldo
    }

    function resetStates() {
        setDadosAnoAnterior({
            saldo: 0,
            lancamentos: 0,
            lancamentos_receita: null,
            lancamentos_despesa: null,
            lancamentos_receita_por_mes_por_conta: null,
            lancamentos_despesa_por_mes_por_conta: null,
            somatorias_receitas_conta_corrente: null,
            somatorias_despesas_conta_corrente: null,
            somatoria_receitas_ano: 0,
            somatoria_despesas_ano: 0,
            percentual_consumido: 0
        })
        setDadosAnoAtual({
            receita_prevista: 0,
            lancamentos: null,
            orcamentos: null,
            plano_de_contas: 0,
            total_orcado: 0,
            saldo_orcamento: 0,
            orcamento_pecentual_consumo: 0,
            contas_contabeis: null,
            contas_contabeis_analiticas: null
        })
        setContasCorrentes([])
        setSaldoGeralContasCorrentes(0)
        setReceitaGeralContasCorrentes(0)
        setDespesaGeralContasCorrentes(0)
        setShowCadastro(false)
        setOrcamentoEmEdicao(null)
    }

    async function setup() {
        setLoading(true)

        resetStates()

        const contasContabeis = await loadContasContabeis()

        const orcamentos = await loadOrcamentos(ano)

        const lancamentos = await loadLancamentos(ano)

        const planoContas = loadPlanoContas(contasContabeis, orcamentos.orcamentos, lancamentos)

        const orcamentosNormalizados = prepareOrcamentos(planoContas)

        const anoAnterior = ano - 1

        const inicioMesAtual = firstDayOfMonth(new Date())
        const fimMesAtual = lastDayOfMonth(new Date())

        const {
            contas_correntes,
            saldo_geral_contas_correntes
        } = await loadContasCorrente()

        const {
            contas_correntes: contas_correntes_mes_atual,
            receita_geral_contas_correntes,
            despesa_geral_contas_correntes
        } = await loadContasCorrente(inicioMesAtual, fimMesAtual, false)


        // ANO ATUAL

        const contasContabeisAnaliticas = loadContasContabeisAnaliticas(planoContas, contasContabeis)

        const orcadoTotal = loadOrcadoTotal(planoContas)
        const saldoOrcamento = loadSaldoOrcamento(orcamentos, orcadoTotal)
        const percentualOrcamentoConsumo = loadPercentualOrcamentoConsumo(orcamentos, orcadoTotal)

        const lancamentosReceita = loadLancamentosReceita(lancamentos)
        const lancamentosDespesa = loadLancamentosDespesa(lancamentos, orcamentos, ano)

        const lancamentosReceitaPorMesPorConta = loadLancamentosPorMesPorContaCorrente(lancamentosReceita, contas_correntes)
        const lancamentosDespesaPorMesPorConta = loadLancamentosPorMesPorContaCorrente(lancamentosDespesa, contas_correntes)

        const somatoriasReceitaPorContaCorrente = loadSomatoriasPorContaCorrente(contas_correntes, lancamentosReceita)
        const somatoriasDespesaPorContaCorrente = loadSomatoriasPorContaCorrente(contas_correntes, lancamentosDespesa)

        const somatoriaReceitas = loadSomatoriaAno(somatoriasReceitaPorContaCorrente)
        const somatoriaDespesas = loadSomatoriaAno(somatoriasDespesaPorContaCorrente)

        const saldoAplicacoesAnoAnterior = await loadSaldoAplicacoes(anoAnterior)

        const valoresPorMesPorConta = loadValoresPorMesPorConta(lancamentosReceitaPorMesPorConta, lancamentosDespesaPorMesPorConta)

        const receitaPlanoSaude = await loadSomatoriaPlanoSaude(ano, 'receita')
        const despesaPlanoSaude = await loadSomatoriaPlanoSaude(ano, 'despesa')

        // const saldo = Number(Number(orcamentos.saldo_ano_anterior + saldoAplicacoesAnoAnterior + somatoriaReceitas - somatoriaDespesas).toFixed(2))
        const saldo = Number(Number((somatoriaReceitas - receitaPlanoSaude) - somatoriaDespesas).toFixed(2))

        const somatoriInvestimentos = await loadSomatorioInvestimentos(lancamentos, orcamentos)

        // ANO ANTERIOR

        const lancamentosAnoAnterior = await loadLancamentos(anoAnterior)

        const lancamentosReceitaAnoAnterior = loadLancamentosReceita(lancamentosAnoAnterior)
        const lancamentosDespesaAnoAnterior = loadLancamentosDespesaAnoAnterior(lancamentosAnoAnterior)

        const lancamentosReceitaPorMesPorContaAnoAnterior = loadLancamentosPorMesPorContaCorrente(lancamentosReceitaAnoAnterior, contas_correntes)
        const lancamentosDespesaPorMesPorContaAnoAnterior = loadLancamentosPorMesPorContaCorrente(lancamentosDespesaAnoAnterior, contas_correntes)

        const somatoriasReceitaPorContaCorrenteAnoAnterior = loadSomatoriasPorContaCorrente(contas_correntes, lancamentosReceitaAnoAnterior)
        const somatoriasDespesaPorContaCorrenteAnoAnterior = loadSomatoriasPorContaCorrente(contas_correntes, lancamentosDespesaAnoAnterior)

        const somatoriaReceitasAnoAnterior = loadSomatoriaAno(somatoriasReceitaPorContaCorrenteAnoAnterior)
        const somatoriaDespesasAnoAnterior = loadSomatoriaAno(somatoriasDespesaPorContaCorrenteAnoAnterior)

        const saldoAteFinalDoAnoAnterior = await loadSaldoFimAnoAnterior()

        const percentualConsumidoRealizado = loadPercentualConsumidoRealizado(orcamentos, somatoriaReceitas, somatoriaDespesas, receitaPlanoSaude, despesaPlanoSaude)

        const receitaPlanoSaudeAnoAnterior = await loadSomatoriaPlanoSaude(anoAnterior, 'receita')

        const { media_despesas: mediaContasRecorrentes } = await loadMediaContasRecorrentes(anoAnterior)

        setDadosAnoAnterior({
            restante_cc: orcamentos.saldo_ano_anterior,
            restante_aplicacoes: saldoAplicacoesAnoAnterior,
            restante_total: orcamentos.saldo_ano_anterior + saldoAplicacoesAnoAnterior,
            saldo: saldoAteFinalDoAnoAnterior,
            lancamentos: lancamentosAnoAnterior,
            lancamentos_receita: lancamentosReceitaAnoAnterior,
            lancamentos_despesa: lancamentosDespesaAnoAnterior,
            lancamentos_receita_por_mes_por_conta: lancamentosReceitaPorMesPorContaAnoAnterior,
            lancamentos_despesa_por_mes_por_conta: lancamentosDespesaPorMesPorContaAnoAnterior,
            somatorias_receitas_conta_corrente: somatoriasReceitaPorContaCorrenteAnoAnterior,
            somatorias_despesas_conta_corrente: somatoriasDespesaPorContaCorrenteAnoAnterior,
            somatoria_receitas_ano: somatoriaReceitasAnoAnterior,
            somatoria_despesas_ano: somatoriaDespesasAnoAnterior,
            receita_plano_saude: receitaPlanoSaudeAnoAnterior,
            media_despesas_recorrentes: mediaContasRecorrentes,
            total_despesas_recorrentes: mediaContasRecorrentes * 12
        })

        setDadosAnoAtual({
            receita_prevista: orcamentos.receita_prevista,
            lancamentos,
            orcamentos: orcamentosNormalizados,
            plano_de_contas: planoContas,
            total_orcado: orcadoTotal,
            saldo_orcamento: saldoOrcamento,
            saldo,
            orcamento_previsto_pecentual_consumo: percentualOrcamentoConsumo,
            contas_contabeis: contasContabeis,
            contas_contabeis_analiticas: contasContabeisAnaliticas,
            lancamentos_receita_por_mes_por_conta: lancamentosReceitaPorMesPorConta,
            lancamentos_despesa_por_mes_por_conta: lancamentosDespesaPorMesPorConta,
            somatorias_receitas_conta_corrente: somatoriasReceitaPorContaCorrente,
            somatorias_despesas_conta_corrente: somatoriasDespesaPorContaCorrente,
            somatoria_receitas_ano: somatoriaReceitas,
            somatoria_despesas_ano: somatoriaDespesas,
            percentual_consumo: percentualConsumidoRealizado,
            valores_por_mes_por_conta: valoresPorMesPorConta,
            receita_plano_saude: receitaPlanoSaude,
            despesa_plano_saude: despesaPlanoSaude,
            investimentos: somatoriInvestimentos
        })

        setContasCorrentes(contas_correntes)
        setContasCorrentesPeriodo(contas_correntes_mes_atual)
        setSaldoGeralContasCorrentes(saldo_geral_contas_correntes)
        setReceitaGeralContasCorrentes(receita_geral_contas_correntes)
        setDespesaGeralContasCorrentes(despesa_geral_contas_correntes)

        setLoading(false)
    }

    async function reload() {
        setOrcamentoEmEdicao(null)

        setShowCadastro(false)

        await setup()
    }

    async function handlePrepareEdit(orc) {
        const orcamento = dadosAnoAtual.orcamentos.find(o => o.orcamento_id === orc.orcamento_id)

        setOrcamentoEmEdicao(orcamento)
    }

    async function handleEdit(values) {
        try {
            values = formUtils.extractFormValues(values)

            await api.put(`orcamento/${orcamentoEmEdicao.orcamento_id}`, values, authHeaders())

            toast.success('Orçamento atualizado.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleDelete(id) {
        try {
            await api.delete(`orcamento/${id}`, authHeaders())

            toast.success('Orçamento excluído.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleCadastrar(values) {
        try {
            await api.post('orcamento', values, authHeaders())

            toast.success('Orçamento carregado com sucesso.')

            reload()
        } catch (e) {
            if (e.warn) {
                toast.warn(e.msg)
            } else {
                toast.error(e.msg)
            }
        }
    }

    async function updateSaldosContasCorrentesPorData(inicio, fim) {
        setContasCorrentes([])
        setSaldoGeralContasCorrentes(0)

        const {
            contas_correntes,
            saldo_geral_contas_correntes
        } = await loadContasCorrente(inicio, fim)

        setContasCorrentes(contas_correntes)
        setSaldoGeralContasCorrentes(saldo_geral_contas_correntes)
    }

    async function updateReceitasEDespesasContasCorrentesPorData(inicio, fim) {
        setContasCorrentesPeriodo([])
        setReceitaGeralContasCorrentes(0)
        setDespesaGeralContasCorrentes(0)

        const {
            contas_correntes,
            receita_geral_contas_correntes,
            despesa_geral_contas_correntes
        } = await loadContasCorrente(inicio, fim, false)

        setContasCorrentesPeriodo(contas_correntes)
        setReceitaGeralContasCorrentes(receita_geral_contas_correntes)
        setDespesaGeralContasCorrentes(despesa_geral_contas_correntes)
    }

    async function updateSaldosContasAplicacoesPorData(inicio, fim) {
        setContasAplicacoes([])
        setSaldoGeralContasAplicacoes(0)

        const {
            contas_aplicacoes,
            saldo_geral_contas_aplicacoes
        } = await loadContasAplicacoes(inicio, fim)

        setContasAplicacoes(contas_aplicacoes)
        setSaldoGeralContasAplicacoes(saldo_geral_contas_aplicacoes)
    }

    useEffect(() => {
        setup()
    }, [ano])

    return (
        <OrcamentoContext.Provider
            value={{
                handleCadastrar,
                handlePrepareEdit,
                handleEdit,
                handleDelete,

                loading,
                reload,

                showCadastro,
                setShowCadastro,
                orcamentoEmEdicao,
                setOrcamentoEmEdicao,

                ano,
                setAno,

                contasCorrentes,
                contasCorrentesPeriodo,
                saldoGeralContasCorrentes,
                saldoGeralContasAplicacoes,
                contasAplicacoes,
                receitaGeralContasCorrentes,
                despesaGeralContasCorrentes,

                dadosAnoAnterior,
                dadosAnoAtual,

                updateSaldosContasCorrentesPorData,
                updateSaldosContasAplicacoesPorData,
                updateReceitasEDespesasContasCorrentesPorData
            }}
        >
            {children}
        </OrcamentoContext.Provider>
    )
}

export default OrcamentoProvider
