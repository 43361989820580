import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form, FieldArray } from 'formik'
import { toast } from 'react-toastify'
import { FiPaperclip } from 'react-icons/fi'
import { format, addMonths } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import masks from '../../../../../util/masks'

import { firstDayOfMonth } from '../../../../../util/date'
import formUtils from '../../../../../util/form'
import arrayUtils from '../../../../../util/array'

import {
    Textbox, Button, Select, Checkbox, Textarea, Calendar, File
} from '../../../../../components/Form'
import Table from '../../../../../components/Table'

import { Container, DocumentosContainer, CheckboxesContainer } from './styles'
import { TipContext } from '../../../../../contexts/TipContext'

const baseValidation = Yup.object().shape({
    fornecedor: Yup.string().ensure(),
    evento: Yup.string().ensure().required('Especifique o evento.'),
    competencia: Yup.string().required('Especifique o mês de competência.'),
    data_consolidacao: Yup.string().nullable().required('Especifique a data.'),
    valor: Yup.string().money('Complete com os centavos.').required('Especifique o valor do lançamento.'),
    historico: Yup.string().notRequired(),
    caixa: Yup.boolean(),
    fornecedor_pendencia: Yup.boolean(),
    fornecedor_pendencia_observacao: Yup.string(),
    lancamento_recorrente: Yup.boolean(),
    forma_pagamento: Yup.string(),
    consolidado: Yup.boolean()
})

export default function ({
    editId, initialValues: formInitialValues, reload
}) {
    const { tips } = useContext(TipContext)

    const [fornecedores, setFornecedores] = useState([])
    const [eventos, setEventos] = useState([])
    const [contas, setContas] = useState([])
    const [pendencia, setPendencia] = useState(false)
    const [recorrente, setRecorrente] = useState(false)
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [validation, setValidation] = useState(baseValidation.concat(Yup.object().shape({
        conta_corrente: Yup.string().ensure().required('Especifique a conta corrente.')
    })))
    const [initialValues, setInitialValues] = useState(formInitialValues)
    const [eventoERecorrente, setEventoERecorrente] = useState(false)

    async function loadFornecedores() {
        const response = await api.get('fornecedor', authHeaders())

        setFornecedores(response)
    }

    async function loadEventos() {
        const response = await api.get('evento_contabil', authHeaders())

        setEventos(response)
    }

    async function loadContas() {
        const response = await api.get('conta_sindicato', authHeaders())

        setContas(response)
    }

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento', {
            params: {
                grupo: 'lancamento'
            }
        })

        setTiposDocumento(response)
    }

    useEffect(() => {
        loadFornecedores()
        loadEventos()
        loadContas()
        loadTiposDocumento()
    }, [])

    useEffect(() => {
        setPendencia(!!initialValues.fornecedor_pendencia)

        async function loadEvento(id) {
            const evento = await api.get(`evento_contabil/${id}`, authHeaders())

            const conta = evento.conta_corrente_obrigatoria

            if (conta) {
                setValidation(baseValidation.concat(Yup.object().shape({
                    conta_corrente: Yup.string().ensure()
                })))
            }
        }

        if (initialValues?.evento?.value) {
            loadEvento(initialValues.evento.value)
        }
    }, [initialValues])

    async function handleEventoChange(selected) {
        const { value } = selected
        const fornecedor_id = initialValues.fornecedor?.value

        const evento = eventos.find(e => e.id === value)
        const conta = evento.conta_corrente_obrigatoria

        if (conta) {
            setValidation(baseValidation.concat(Yup.object().shape({
                conta_corrente: Yup.string().ensure()
            })))
        }

        setEventoERecorrente(evento.recorrente)

        if (evento.recorrente) {
            if (!fornecedor_id) {
                toast.warn('Informe o fornecedor!')
                return
            }

            const lancamentoDoEvento = await api.post('lancamento_query', {
                evento: evento.id,
                fornecedor: fornecedor_id,
                inicio: '2018-01-01',
                fim: format(new Date(), 'yyyy-MM-dd')
            }, authHeaders())

            const ultimoLancamentoDoEvento = arrayUtils.get.getLast(lancamentoDoEvento)

            if (ultimoLancamentoDoEvento) {
                setInitialValues({
                    ...initialValues,
                    fornecedor: ultimoLancamentoDoEvento.fornecedor ? {
                        label: ultimoLancamentoDoEvento.fornecedor.pessoa.nome,
                        value: ultimoLancamentoDoEvento.fornecedor.id
                    } : null,
                    evento: ultimoLancamentoDoEvento.evento ? {
                        label: ultimoLancamentoDoEvento.evento.nome,
                        value: ultimoLancamentoDoEvento.evento.id
                    } : null,
                    conta_corrente: ultimoLancamentoDoEvento.conta_corrente ? {
                        label: `${ultimoLancamentoDoEvento.conta_corrente.dados_bancarios.instituicao.sigla} - AG ${ultimoLancamentoDoEvento.conta_corrente.dados_bancarios.agencia} C/C ${ultimoLancamentoDoEvento.conta_corrente.dados_bancarios.conta}`,
                        value: ultimoLancamentoDoEvento.conta_corrente.id
                    } : null,
                    competencia: firstDayOfMonth(addMonths(new Date(ultimoLancamentoDoEvento.competencia), 1)),
                    data_consolidacao: addMonths(new Date(ultimoLancamentoDoEvento.data_consolidacao), 1),
                    historico: ultimoLancamentoDoEvento.historico,
                    caixa: false,
                    cozinha: false,
                    fornecedor_pendencia: false,
                    fornecedor_pendencia_observacao: ultimoLancamentoDoEvento.fornecedor_pendencia_observacao,
                    valor: masks.maskApply.currency(ultimoLancamentoDoEvento.valor),
                    forma_pagamento: ultimoLancamentoDoEvento.forma_pagamento
                })
            }
        }
    }

    async function handleAdd(values, { resetForm }) {
        values = formUtils.extractFormValues(values)

        values.documentos = values.documentos?.map(doc => ({
            ...doc,
            tipo_documento: doc.tipo_documento.value
        })) || []

        const event = eventos.filter(item => item.id === values.evento)

        if (values.documentos.length === 0 && event[0].tipo_evento_contabil.sigla.includes('D')) {
            toast.warn('Anexe um documento ao lançamento!')
            return
        }

        try {
            await api.post('lancamento', values, authHeaders())

            reload()

            toast.success('Lançamento cadastrado com sucesso!')

            resetForm()

            setPendencia(false)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleEdit(values) {
        values = formUtils.extractFormValues(values)

        values.documentos = values.documentos.map(doc => ({
            ...doc,
            tipo_documento: doc.tipo_documento.value
        }))

        const event = eventos.filter(item => item.id === values.evento)

        if (values.documentos.length === 0 && event[0].tipo_evento_contabil.sigla.includes('D')) {
            toast.warn('Anexe um documento ao lançamento!')
            return
        }

        try {
            await api.put(`lancamento/${editId}`, values, authHeaders())

            reload()

            toast.success('Lançamento atualizado!')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container className="animated fadeIn faster">
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={editId ? handleEdit : handleAdd}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, values, errors
                }) => (
                    <Form style={{ marginBottom: 30 }}>
                        <p style={{ gridArea: 'tip', margin: 8 }}>
                            {tips?.formulario}
                        </p>

                        <Select
                            name="fornecedor"
                            label="Parceiro"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)

                                setInitialValues(
                                    {
                                        ...initialValues,
                                        fornecedor: selected
                                    }
                                )
                            }}
                            options={fornecedores.map(({ id, pessoa }) => ({
                                label: pessoa.nome,
                                value: id
                            }))}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Select
                            name="evento"
                            label="Evento"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)

                                handleEventoChange(selected)
                            }}
                            options={eventos.map(({ id, nome, conta_contabil }) => ({
                                label: `${nome}${conta_contabil?.codigo_reduzido ? ` - ${conta_contabil.codigo_reduzido}` : ''}`,
                                value: id
                            }))}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <Select
                            name="conta_corrente"
                            label="Conta corrente"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={contas.map(({ id, dados_bancarios }) => ({
                                label: `${dados_bancarios.instituicao.sigla} - AG ${dados_bancarios.agencia} C/C ${dados_bancarios.conta}`,
                                value: id
                            }))}
                            containerStyle={{ gridArea: 'f3' }}
                        />

                        <Textbox
                            label="Forma de pagamento"
                            name="forma_pagamento"
                            containerStyle={{ gridArea: 'f4' }}
                        />

                        <Calendar
                            name="competencia"
                            dateFormat="mm/yy"
                            yearNavigator
                            view="month"
                            label="Competência"
                            containerStyle={{ gridArea: 'f5' }}
                        />

                        <Calendar
                            name="data_consolidacao"
                            label="Data"
                            containerStyle={{ gridArea: 'f6' }}
                        />

                        <Textbox
                            label="Valor"
                            name="valor"
                            mask={masks.money}
                            containerStyle={{ gridArea: 'f7' }}
                        />

                        <Textarea
                            label="Descrição"
                            name="historico"
                            containerStyle={{ gridArea: 'f8' }}
                        />

                        {eventoERecorrente && (
                            <span className="badge" dangerouslySetInnerHTML={{ __html: 'O evento selecionado é <b>RECORRENTE</b>. O lançamento cadastrado será replicado para todos os meses subsequentes, até dezembro deste ano.' }} />
                        )}

                        <CheckboxesContainer>
                            {/* <Checkbox
                                name="fornecedor_pendencia"
                                label="Pendência fornecedor"
                                handleChange={e => {
                                    setFieldValue(e.target.name, e.target.checked)
                                    setPendencia(e.target.checked)
                                }}
                            /> */}

                            {/* {pendencia && (
                                <Textarea
                                    name="fornecedor_pendencia_observacao"
                                    label="Observação de pendência do fornecedor"
                                    containerClass="animated fadeIn"
                                />
                            )} */}

                            {/* <Checkbox
                                name="caixa"
                                label="Movimento de caixa"
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            />

                            <Checkbox
                                name="cozinha"
                                label="Despesa de cozinha"
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            /> */}

                            <Checkbox
                                name="consolidado"
                                label="Consolidado"
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            />

                            <Checkbox
                                name="parcelado"
                                label="Compra parcelada"
                                handleChange={e => {
                                    setFieldValue(e.target.name, e.target.checked)
                                    setRecorrente(e.target.checked)
                                }}
                            />

                            {recorrente && (
                                <>
                                    <Textbox
                                        inputMode="numeric"
                                        name="parcelas"
                                        label="Número de parcelas"
                                        containerClass="animated fadeIn"
                                        mask={masks.numero}
                                    />

                                    {Number(values.parcelas) > 0 && values.valor && (
                                        <span className="valor-parcela">
                                            <div dangerouslySetInnerHTML={{ __html: `Valor da parcela: <b>${masks.maskApply.currency(masks.unmask.money(values.valor) / Number(values.parcelas))}</b>` }} />
                                        </span>
                                    )}
                                </>
                            )}
                        </CheckboxesContainer>

                        <DocumentosContainer>
                            <FieldArray
                                name="documentos"
                                render={arrayHelpers => (
                                    <div className="controle-documentos">
                                        <h1>Documentos</h1>

                                        <File
                                            name="arquivo"
                                            onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                            label="Documento"
                                            format="square"
                                            previewSize={['100%', '300px']}
                                            error={errors.documento}
                                            getPreloadImage={async () => {
                                                const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                                return arquivo?.link
                                            }}
                                            previewPDF
                                        />

                                        <Select
                                            name="tipo_documento"
                                            label="Tipo de documento"
                                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                            options={tiposDocumento.map(({ id, descricao }) => ({
                                                label: descricao,
                                                value: id
                                            }))}
                                        />

                                        <Button
                                            className="transparent"
                                            onClick={() => {
                                                const { tipo_documento, arquivo } = values

                                                if (!arquivo || !tipo_documento) {
                                                    toast.warn('Adicione o documento e escolha o tipo.')
                                                    return
                                                }

                                                arrayHelpers.push({
                                                    tipo_documento,
                                                    arquivo
                                                })

                                                setFieldValue('arquivo', null)
                                                setFieldValue('tipo_documento', null)
                                            }}
                                        >
                                            Anexar novo documento
                                            <FiPaperclip size={16} />
                                        </Button>

                                        <Table
                                            headers={[
                                                { name: 'tipo_documento', value: 'Documento' }
                                            ]}
                                            data={values.documentos?.map(doc => ({
                                                id: doc.arquivo,
                                                tipo_documento: doc.tipo_documento.label
                                            })) || []}
                                            handleDelete={(_, index) => {
                                                arrayHelpers.remove(index)
                                                return true
                                            }}
                                            confirmExclusion={{
                                                attr: 'tipo_documento',
                                                template: 'Deseja realmente excluir o documento #attr#?'
                                            }}
                                            emptyLabel="Nenhum documento anexado"
                                            disableTooltips
                                        />
                                    </div>
                                )}
                            />
                        </DocumentosContainer>

                        <Button
                            type="submit"
                            loading={isSubmitting}
                            className="white"
                            style={{ gridArea: 'bs' }}
                        >
                            {editId ? 'Salvar alterações' : 'Cadastrar lançamento'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
