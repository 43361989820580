import React, { useEffect, useState } from 'react'

import { descriptografar } from '../../util/crypto'

import { api } from '../../services/api'

import { Select } from '../../components/Form'
import SwitcherPagamento from '../../components/FormPagamento/Switcher'

import { Container } from './styles'

const { Iugu } = window

export default function ({ defaultValues, pessoaId, callback }) {
    const [tiposPagamento, setTiposPagamento] = useState([])
    const [tipoPagamentoSelecionado, setTipoPagamentoSelecionado] = useState(null)

    useEffect(() => {
        console.log('Modo teste Iugu habilitado:', process.env.REACT_APP_IUGU_TEST_MODE === 'true')

        Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
        Iugu.setTestMode(process.env.REACT_APP_IUGU_TEST_MODE === 'true')
    }, [])

    async function loadTiposPagamento() {
        const userData = descriptografar(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER))

        const response = await api.get('tipo_pagamento', {
            params: {
                ativos: 1
            }
        })

        setTiposPagamento(response)
    }

    useEffect(() => {
        loadTiposPagamento()

        if (defaultValues?.tipo) {
            setTipoPagamentoSelecionado({
                value: defaultValues.tipo.id,
                label: defaultValues.tipo.descricao
            })
        }
    }, [defaultValues])

    return (
        <Container>
            <Select
                name="tipo_pagamento"
                id="tipo_pagamento"
                label="Forma de pagamento"
                withoutForm
                onChange={selected => setTipoPagamentoSelecionado(selected)}
                options={tiposPagamento.filter(tipo => tipo.id !== 6).map(tipo => ({
                    label: tipo.descricao,
                    value: tipo.id
                }))}
                value={tipoPagamentoSelecionado}
                isSearchable={false}
            />

            <SwitcherPagamento
                tipoPagamento={tipoPagamentoSelecionado}
                defaultValues={tipoPagamentoSelecionado?.value === defaultValues?.tipo?.id ? defaultValues : null}
                pessoaId={pessoaId}
                callback={callback}
            />
        </Container>
    )
}
