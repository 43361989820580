import styled from 'styled-components'

export const Container = styled.details.attrs({
    className: 'tarefa-item'
})`
	summary {
		border: solid 1px #fff;
		padding: 12px 18px;
		display: grid;
		grid-template-columns: 14px 366px 110px 140px 154px 144px 1fr;
		align-items: center;
		cursor: default !important;

		span {
			font-size: 13px;

			&.title {
				font-weight: 500;
				font-size: 16px;
				margin-left: 8px;
			}
		}

		.link {
			cursor: pointer;
			display: flex;
			transition: 300ms;

			&:hover {
				transform: scale(1.1) translateX(6px);
			}

			svg {
				margin-right: 4px;
			}
		}

		svg {
			justify-self: end;
			cursor: pointer;
		}

		svg.status-icon {
			font-size: 10px;
			fill: ${props => props.color};
			stroke: ${props => props.color};
		}
	}

	.content {
		border: solid 1px #fff;
		border-top: 0;
		padding: 12px 18px;
	}

	&[open] {
		border: solid 2px #fff;
		border-radius: 10px;
		box-shadow: 0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

		summary, 
		.content {
			border: 0;
		}

		.content {
			border-top: solid 1px #fff;
		}

		summary {
			.header-item {
				display: grid !important;
			}
		}
	}
`

export const Badge = styled.div`
	background-color: ${props => props.color};
	padding: 2px 8px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	gap: 4px;
	width: fit-content;

	> h1 {
		font-size: 14px;
		margin: 0 !important;;
	}

	span {
		font-size: 10px !important;
		color: #FFF;
	}
`

export const HeaderItem = styled.div.attrs({
    className: 'header-item'
})`
	display: none !important;
	grid-column: 1/span 8;
	grid-template-columns: 14px 366px 110px 140px 154px 144px 1fr;
	margin: 4px 0;
	
	span {
		font-size: 10px;
		font-weight: 500;
		background-color: #eee8;
		padding: 4px 0;

		&:nth-child(2) {
			padding: 4px 8px !important;
		}

		&:nth-child(2) {
			border-top-left-radius: 4px !important;
			border-bottom-left-radius: 4px !important;
		}

		&:nth-child(6) {
			border-top-right-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
		}
	}
`

export const ItemContent = styled.div.attrs({
    className: 'content'
})`
	display: grid;
	grid-template-columns: 1fr 160px;
	grid-template-rows: auto 80px auto auto;
	grid-template-areas: 
		'descricao envolvidos'
		'timeline envolvidos'
		'dependencias envolvidos'
		'dependencias envolvidos';
	gap: 0 16px;

	p {
		font-size: 12px;
		line-height: 20px;
		margin: 14px 0;
	}
`

export const EnvolvidosContainer = styled.div`
	grid-area: envolvidos;
	display: flex;
	flex-direction: column;
`

export const EnvolvidoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 40px;
	border-left: solid 1px #ddd;

	> img {
		object-fit: cover;
		width: 60px;
		height: 60px;
		border-radius: 50%;
	}

	> h1 {
		font-size: 11px;
		font-weight: 500;
		color: #424242;
		margin: 8px 0 0 !important;
	}

	> h2 {
		font-size: 9px;
		font-weight: 500;
		color: #424242;
		margin: 0 12px 6px 0;
	}

	> p {
		font-size: 9px;
		text-align: center;
		margin: 0;
	}

	> .evidencia {
		margin-top: 8px;
		display: flex;
		align-items: center;
		color: #03a9f4;
		transition: 300ms;
		cursor: pointer;
		
		span {
			font-size: 11px;
			margin-left: 4px;
		}

		&:hover {
			opacity: 0.6;
		}
	}
`

export const DependenciasContainer = styled.div`
	margin-top: 8px;
	grid-area: dependencias;

	h1 {
		color: #424242;
		font-size: 14px;
		margin-bottom: 8px !important;
	}

	details {
		summary {
			display: flex;
			align-items: center;
			padding: 0 4px !important;
			border-radius: 4px;
			cursor: pointer !important;

			span {
				font-size: 12px !important;
				line-height: 24px !important;
			}

			svg {
				margin-right: 4px;
			}
		}
		
		.content {
			line-height: 18px;
			font-size: 11px;
		}

		&:hover,
		&[open] {
			summary {
				background-color: #ddd8;
			}

			.content {
				border: 0 !important;
			}
		}
	}
`

export const EvidenciaContainer = styled.div`
	display: flex;
	justify-content: center;
	
	img {
		height: 70vh;
	}
`
