import styled from 'styled-components'

export const Textarea = styled.textarea`
	background: rgba(255, 255, 255, 0.8);
	border: 0;
	border-radius: 4px;
	height: 44px;
	padding: 0 15px;
	color: #424242;
	margin: 0 0 10px;
	resize: vertical;
	min-height: 100px;
	width: 100%;
	padding: 15px;
	box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);

	&::placeholder {
		color: rgba(0, 0, 0, 0.2);
		font-weight: 400;
	}

	&:focus {
		border: dashed 1px rgba(0, 0, 0, 0.2);
		background: rgba(255, 255, 255, 0.9);
	}

	&:disabled {
		opacity: 0.4;
	}
`

export const TextareaContainer = styled.div.attrs({
    className: 'field textarea'
})`
	display: flex;
	position: relative;
	flex-direction: column;
	margin: 5px 8px;

	.label {
		font-size: 11px;
		margin: 4px 4px 2px;
		text-align: left;
	}

	textarea:focus + svg {
		opacity: 0.5;
	}

	textarea:disabled {
		opacity: 0.4;
	}

	.label {
		font-size: 11px;
		margin: 4px 4px 2px;
		text-align: left;
	}

	.tip {
		font-size: 12px;
	}

	.tip-container {
		right: 0;
		top: 48px;
	}

	> svg {
		position: absolute;
		right: 14px;
		top: 32px;
		width: 18px;
		opacity: 0.7;
		color: rgba(0, 0, 0, 0.3) !important;

		&:hover {
			cursor: help;

			& + .tip-container {
				display: grid;
			}
		}
	}
`
