import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../../../../../services/api'

import { Container, ButtonContainer, LabelContainer } from './styles'

import formUtils from '../../../../../util/form'

import { Button, Select } from '../../../../../components/Form'

import { downloadRelatorioJasper } from '../../../../../util/file'
import Spinner from '../../../../../components/Spinner'

const nomeRelatorio = 'Relatório Plano de saúde por status'

const frasesLoading = [
    'Obtendo dados do relatório...',
    'Preparando dados para o relatório...',
    'Gerando seu relatório...',
    'Formatando seu relatório...',
    'Executando os últimos passos...',
    'Aguarde só mais um pouco...'
]

export default function (props) {
    const [submiting, setSubmiting] = useState(false)
    const [fraseLoadingIndex, setFraseLoadingIndex] = useState(null)
    const [status, setStatus] = useState(null)

    async function loadStatus() {
        const response = await api.get('convenio_status', authHeaders())

        setStatus(response)
    }

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        values = formUtils.extractFormValues(values)

        values.status = values.status.map(s => s.value)

        setSubmiting(true)

        try {
            const response = await api.post('relatorio/plano_saude_por_status',
                values,
                { ...authHeaders(), responseType: 'blob' })

            downloadRelatorioJasper(response, nomeRelatorio, 'xlsx')

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }

        setSubmiting(false)
        setFraseLoadingIndex(null)
    }, [])

    useEffect(() => {
        if(submiting) {
            setInterval(() => {
                setFraseLoadingIndex(old => {
                    if(old < 5) {
                        return old + 1
                    }

                    return old
                })
            }, 3000)
        }
    }, [submiting])

    useEffect(() => {
        loadStatus()
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{ 
                    medico: { label: 'Todos', value: '' }, 
                    status: []
                }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, resetForm }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="medico"
                            label="Tipo beneficiário"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Todos', value: '' },
                                { label: 'Médico', value: '1' },
                                { label: 'Acadêmico', value: '0' }
                            ]}
                            containerStyle={{ gridArea: 'f1' }}
                        />

                        <Select
                            name="status"
                            label="Status"
                            isMulti
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={status?.map(s => ({
                                label: s.descricao,
                                value: s.id
                            })) || []}
                            containerStyle={{ gridArea: 'f2' }}
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>

                        {isSubmitting && fraseLoadingIndex !== null && (
                            <LabelContainer>
                                <Spinner 
                                    label={frasesLoading[fraseLoadingIndex]}
                                />
                            </LabelContainer>
                        )}
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
