import React, { useContext } from 'react'
import { format } from 'date-fns'

import maskUtils from '../../../../../util/masks'

import { InconsistenciaContext } from '../../../../../contexts/InconsistenciaContext'

import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'

import { Container } from './styles'

export default function () {
    const { inconsistencias, handleDelete } = useContext(InconsistenciaContext)

    return (
        <Container>

            {inconsistencias ? (
                <Table 
                    headers={[
                        { name: 'identificador', value: 'Identificador', centered: true },
                        { name: 'nome', value: 'Associado' },
                        { name: 'competencia', value: 'Competência', centered: true },
                        { name: 'valor', value: 'Valor', centered: true },
                        { name: 'observacao', value: 'Observação' },
                        { name: 'status', value: 'Status', icon: true }
                    ]}
                    data={inconsistencias.map(inconsistencia => ({
                        id: inconsistencia.id,
                        identificador: inconsistencia.identificador,
                        nome: inconsistencia.associado?.pessoa.nome || '',
                        competencia: format(new Date(inconsistencia.competencia), 'dd/MM/yyyy'),
                        valor: maskUtils.maskApply.currency(inconsistencia.valor),
                        observacao: inconsistencia.observacao,
                        status: Number(inconsistencia.status) ? 'FaCheckCircle' : 'FaMinusCircle',
                        iconColor: Number(inconsistencia.status) ? '#4caf50' : '#f44336'
                    }))}
                    emptyLabel="Nenhuma inconsistência encontrada"
                    handleDelete={handleDelete}
                    showId
                    confirmExclusion
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
