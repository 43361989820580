import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'

import { VisaoContext } from '../../../../contexts/VisaoContext'
import { TipContext } from '../../../../contexts/TipContext'
import { api, authHeaders } from '../../../../services/api'
import { Button, Textbox, Select } from '../../../../components/Form'
import { loadTips } from '../../../../util/tip'
import { Container } from './styles'

const baseInitialValues = {
    descricao: '',
    tipo_pessoa: { label: 'Associados', value: 1 },
    cidade: null,
    bairro: null,
    sexo: null,
    faculdade: null
}

export default function () {
    const { handleSumit } = useContext(VisaoContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [initialValues] = useState(baseInitialValues)
    const [cidades, setCidades] = useState([])
    const [bairros, setBairros] = useState([])
    const [faculdades, setFaculdades] = useState([])

    async function loadCidades() {
        try {
            const response = await api.get('visao_cidades', authHeaders())

            setCidades(response)
        } catch(e) {
            toast.error('Erro ao obter as cidades.')
        }
    }

    async function loadBairros() {
        try {
            const response = await api.get('endereco/bairros', authHeaders())

            setBairros(response)
        } catch(e) {
            toast.error('Erro ao obter as cidades.')
        }
    }

    async function loadFaculdades() {
        try {
            const response = await api.get('faculdade')

            setFaculdades(response)
        } catch(e) {
            toast.error('Erro ao obter as cidades.')
        }
    }

    useEffect(() => {
        loadCidades()
        loadBairros()
        loadFaculdades()

        loadTips(setCodigo, 'form_cadastro_visoes')
    }, [])

    return (
        <Container>
            <Formik
                onSubmit={handleSumit}
                initialValues={initialValues}
                enableReinitialize
            >
                {({ setFieldValue }) => (
                    <Form>
                        <p style={{ margin: 8, gridColumn: '1/span 3' }}>{tips?.formulario}</p>

                        <Textbox
                            name="descricao"
                            label="Descrição"
                        />

                        <Select 
                            name="tipo_pessoa"
                            label="Tipo de pessoa"
                            options={[
                                { label: 'Associados', value: 1 }
                            ]}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <Select 
                            name="cidade"
                            label="Cidade"
                            placeholder="Qualquer cidade"
                            options={cidades.map(cidade => ({
                                label: cidade,
                                value: cidade
                            }))}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <Select 
                            name="bairro"
                            label="Bairro"
                            placeholder="Qualquer bairro"
                            options={bairros.map(bairro => ({
                                label: bairro,
                                value: bairro
                            }))}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <Select 
                            name="sexo"
                            label="Sexo"
                            placeholder="N/A"
                            options={[
                                { value: 'M', label: 'Masculino' },
                                { value: 'F', label: 'Feminino' }
                            ]}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <Select 
                            name="faculdade"
                            label="Faculdade"
                            placeholder="N/A"
                            options={faculdades.map(faculdade => ({
                                label: faculdade.descricao,
                                value: faculdade.descricao
                            }))}
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                        />

                        <div className="button-container">
                            <Button type="submit" className="transparent">
                                Cadastrar grupo
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

        </Container>
    )
}
