import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Form, Formik } from 'formik'
import * as icons from 'react-icons/fi'
import { FiEdit } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Spinner from '../../../components/Spinner'
import { Button, Select, Textbox } from '../../../components/Form'
import Modal from '../../../components/Modal'

import Yup from '../../../services/yup'
import { api, authHeaders } from '../../../services/api'

import arrayUtils from '../../../util/array'
import formUtils from '../../../util/form'
import masks from '../../../util/masks'
import { stringToDate, getDate } from '../../../util/date'

import {
    Container, Avatar, ModalEdicaoFaculdade
} from './styles'

import avatarDefault from '../../../assets/images/avatar.png'

const validation = Yup.object({
    faculdade: Yup.string().ensure().required('Selecione a faculdade.'),
    inicio: Yup.string().date().required('Preencha a data.'),
    fim: Yup.string().date().required('Preencha a data.')
})

export default function ({ pessoa, reload }) {
    const [showEdicaoFaculdade, setShowEdicaoFaculdade] = useState(false)
    const [faculdades, setFaculdades] = useState(null)

    async function loadFaculdades() {
        const response = await api.get('faculdade')

        setFaculdades(response.map(faculdade => ({
            label: faculdade.descricao,
            value: faculdade.id
        })))
    }

    async function handleUpdateFaculdade(values) {
        values = formUtils.extractFormValues(values)

        try {
            await api.put(`dados_academicos/${pessoa.associado.id}`, values, authHeaders())

            toast.success('Dados atualizados.')

            setShowEdicaoFaculdade(false)

            await reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        loadFaculdades()
    }, [])

    return (
        <>
            <Container
                className="animated fadeInUp delay-100ms"
                medico={pessoa?.associado.medico === null ? true : pessoa?.associado.medico}
            >
                {pessoa ? (
                    <>
                        <Avatar
                            image={pessoa.usuario?.avatar?.link || avatarDefault}
                            className="animated zoomIn faster"
                        />

                        <h1 style={{ gridArea: 'nome' }} className="nome_completo">{pessoa.nome}</h1>

                        <h1 style={{ gridArea: 'nome' }} className="nome_reduzido">{pessoa.nome_reduzido}</h1>

                        <div style={{ gridArea: 'identificador' }} className="identificador">
                            <span>Matrícula: </span>
                            <b style={{ fontSize: 18 }}>{pessoa.documentos?.length > 0 && (pessoa.documentos.find(documento => documento.tipo_documento.id === 999)?.identificador || '')}</b>
                        </div>

                        <div style={{ gridArea: 'status' }}>
                            <span>Membro: </span>

                            {pessoa.socio ? (
                                <span className="blue">SIM</span>
                            ) : (
                                <span className="red">NÃO</span>
                            )}
                        </div>

                        {/* {!pessoa.associado.medico && pessoa.associado.dados_academicos?.data_ingresso && pessoa.associado.dados_academicos?.data_conclusao && (
                            <div style={{ gridArea: 'periodo_curso' }}>
                                <span>Período do curso: </span>
                                <b>{`${format(stringToDate(pessoa.associado?.dados_academicos.data_ingresso), 'yyyy')} a ${format(stringToDate(pessoa.associado?.dados_academicos.data_conclusao), 'yyyy')}`}</b>
                            </div>
                        )} */}

                        <div style={{ gridArea: 'data' }}>
                            {pessoa.socio && pessoa.associado.sindicalizacao.find(sindicalizacao => sindicalizacao.status) ? (
                                <>
                                    <span>Data de cadastro: </span>
                                    <b>{format(stringToDate(arrayUtils.get.getLast(pessoa.associado.sindicalizacao.filter(sindicalizacao => sindicalizacao.status)).data), 'dd/MM/yyy')}</b>
                                </>
                            ) : null}
                        </div>

                        {/* {pessoa.data_aposentadoria ? (
                            <>
                                <hr />

                                <h1 className="aposentado-title">Aposentado</h1>

                                <div style={{ gridArea: 'dt_aposentadoria' }}>
                                    <span>Data de aposentadoria: </span>
                                    <b>{pessoa.data_aposentadoria}</b>
                                </div>

                                <div style={{ gridArea: 'inst_aposentadoria' }} className="ints-aposentadoria">
                                    <span>Locais de aposentadoria: </span>
                                    <b>{pessoa.instituicoes_aposentadoria}</b>
                                </div>
                            </>
                        ) : null} */}
                    </>
                ) : (
                    <Spinner />
                )}
            </Container>

            {/* <Modal
                isOpen={showEdicaoFaculdade}
                handleClose={() => { setShowEdicaoFaculdade(false) }}
                title="Definir faculdade"
            >
                <Formik
                    initialValues={{
                        faculdade: pessoa?.associado.dados_academicos?.faculdade ? {
                            label: pessoa?.associado.dados_academicos?.faculdade.descricao,
                            value: pessoa?.associado.dados_academicos?.faculdade.id
                        } : null,
                        inicio: pessoa?.associado.dados_academicos?.data_ingresso
                            ? format(getDate(pessoa?.associado.dados_academicos.data_ingresso), 'dd/MM/yyyy')
                            : '',
                        fim: pessoa?.associado.dados_academicos?.data_conclusao
                            ? format(getDate(pessoa?.associado.dados_academicos.data_conclusao), 'dd/MM/yyyy')
                            : ''
                    }}
                    onSubmit={handleUpdateFaculdade}
                    validationSchema={validation}
                >
                    {({ setFieldValue, isSubmitting }) => (
                        <Form>
                            <ModalEdicaoFaculdade>
                                <Select
                                    label="Faculdade"
                                    name="faculdade"
                                    options={faculdades || []}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                <Textbox
                                    name="inicio"
                                    label="Início do curso"
                                    mask={masks.date}
                                />

                                <Textbox
                                    name="fim"
                                    label="Conclusão do curso"
                                    mask={masks.date}
                                />

                                <Button className="transparent" type="submit" loading={isSubmitting}>
                                    Alterar
                                </Button>
                            </ModalEdicaoFaculdade>
                        </Form>
                    )}
                </Formik>
            </Modal> */}
        </>
    )
}
