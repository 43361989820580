import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	form {
		width: 100%;
		max-width: 350px;

		button {
			width: calc(100% - 16px);
			margin-top: 18px;
		}
	}
`
