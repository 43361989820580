import React, { useContext } from 'react'

import Table from '../../../../../../components/Table'

import { TutorialContext } from '../../../../../../contexts/TutorialContext'

export default function () {
    const { assuntos, handleDeleteAssunto, handlePrepareEditAssunto } = useContext(TutorialContext)

    return (
        <Table 
            headers={[
                { name: 'descricao', value: 'Assunto' }
            ]}
            data={assuntos || []}
            handlePrepareEdit={handlePrepareEditAssunto}
            handleDelete={handleDeleteAssunto}
            confirmExclusion={{
                template: 'Tem certeza que deseja remover o assunto <b>#attr#</b>?',
                attr: 'descricao'
            }}
        />
    )
}
