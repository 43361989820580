import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 200px 1fr;
	grid-template-rows: 30px auto auto;
	margin: 18px 0;

	> img {
		object-fit: cover;
		border-radius: 50%;
		width: 130px;
		height: 130px;
		justify-self: center;
		display: fixed;
	}

	> h1 {
		font-size: 22px;
		margin: 0;
	}

	> p {
		grid-column: 2/span 2;
		margin: 0;
		width: calc(100% - 16px);
		line-height: 24px;
	}
`
export const DadosContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 16px;
	grid-column: 2;
	margin-top: 20px;

	> p {
		width: 100%;
		line-height: 20px;
	}

	> h2 {
		grid-column: 1/span 3;
		font-size: 18px;
		padding: 8px 0;
		font-weight: 500;
		border-bottom: solid 1px #ccc;
	}

	.box-arquivos {
		h1 {
			font-size: 18px;
			margin: 0 0 10px;
			border-bottom: solid 1px #ccc;
			padding: 8px 0;
		}
	}
`

export const TarjaDesligamento = styled.div`
	border-radius: 10px;
	background-color: #ffeb3b;
	padding: 8px;
	color: #424242;
	grid-column-start: 2;
	margin-top: 10px;
`
