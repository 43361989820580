import React, { useContext, useEffect } from 'react'
import { FiEye } from 'react-icons/fi'
import { FaStarHalfAlt } from 'react-icons/fa'

import Card from '../../../../components/Card'
import Table from '../../../../components/Table'

import { Container, BotaoAberturaContainer } from './styles'
import Spinner from '../../../../components/Spinner'
import history from '../../../../services/history'
import { ProcessoContext, perfisAssociado } from '../../../../contexts/ProcessoContext'
import { Button } from '../../../../components/Form'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import Modal from '../../../../components/Modal'
import Abertura from '../Abertura'
import FiltroAtendente from './FiltroAtendente'
import FiltroDemandante from './FiltroDemandante'
import useQuery from '../../../../hooks/useQuery'

export default function () {
    const { abertura } = useQuery()

    const { user } = useContext(GlobalContext)
    const {
        processos,
        showFormAbertura,
        setShowFormAbertura,
        setAvaliarProcesso
    } = useContext(ProcessoContext)

    useEffect(() => {
        if (abertura) {
            setShowFormAbertura(true)
        }
    }, [abertura])

    const eDemandante = perfisAssociado.includes(user?.perfil.id)

    return (
        <>
            <Container>
                <main>
                    <Card>
                        <h1>Chamados</h1>

                        <BotaoAberturaContainer>
                            <Button className="transparent" onClick={() => { setShowFormAbertura(true) }}>
                                Abrir novo chamado
                            </Button>
                        </BotaoAberturaContainer>

                        {eDemandante ? <FiltroDemandante /> : <FiltroAtendente />}

                        {processos ? (
                            <Table
                                headers={[
                                    {
                                        name: 'identificador', value: 'Nº do chamado', centered: true, style: { width: 140 }
                                    },
                                    { name: 'demandante', value: 'Solicitante', centered: true },
                                    {
                                        name: 'status', value: 'Status', centered: true, style: { width: 90 }
                                    },
                                    { name: 'tipo', value: 'Setor', centered: true },
                                    { name: 'assunto', value: 'Assunto', centered: true },
                                    {
                                        name: 'created_at', value: 'Data de abertura', centered: true, style: { width: 150 }
                                    },
                                    {
                                        name: 'finished_at', value: 'Data de finalizacao', centered: true, style: { width: 160 }
                                    },
                                    {
                                        name: 'setores_atuais_descricao', value: 'Grupo atual', centered: true, style: { width: 120 }
                                    }
                                ]}
                                data={processos}
                                actions={[
                                    {
                                        action: processo => {
                                            history.push(`processos/${processo.id}`)
                                        },
                                        name: 'Visualizar',
                                        icon: FiEye,
                                        iconSize: 16
                                    },
                                    {
                                        action: setAvaliarProcesso,
                                        name: 'Avaliar atendimento',
                                        icon: FaStarHalfAlt,
                                        iconSize: 16,
                                        checkDisabled: item => item.status_id !== 2 || !!item.avaliacao || user.pessoa.id !== item.demandante_id
                                    }
                                ]}
                                emptyLabel="Nenhum chamado corresponde ao filtro"
                                hideOnSmallHeaders={['demandante', 'setores_atuais_descricao', 'finished_at', 'created_at', 'tipo']}
                                hideHeadersOffset={1020}
                                hideOffset={250}
                                legends={eDemandante ? undefined : [
                                    {
                                        color: '#fff9c4',
                                        text: 'Atendimentos externos'
                                    }
                                ]}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={showFormAbertura}
                handleClose={() => { setShowFormAbertura(false) }}
                title="Abertura de atendimento"
            >
                <Abertura />
            </Modal>
        </>
    )
}
