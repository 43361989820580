import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		form {
			display: grid;
			grid-template-columns: 1fr 1fr 0.7fr;

			> button {
				align-self: end;
			}
		}
	}
`
