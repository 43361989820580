import React, { useContext, useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FiFileText, FiPlus } from 'react-icons/fi'
import { FaEye } from 'react-icons/fa'
import { toast } from 'react-toastify'
import {
    Container, ArquivosContainer, FormularioContainer, FormUploadContainer 
} from './styles'

import {
    Button, File, Select, Textbox 
} from '../../../../components/Form'
import Table from '../../../../components/Table'
import Modal from '../../../../components/Modal'

import { OperacaoContext } from '../../../../contexts/OperacaoContext'
import { api } from '../../../../services/api'
import { forceDownloadFileFromURL } from '../../../../util/file'
import { TipContext } from '../../../../contexts/TipContext'
import { loadTips } from '../../../../util/tip'

export default function () {
    const {
        operacaoEdicao, setOperacaoEdicao, handleSubmit, initialValues, resetInitialValues, tipos, handleCancelEdit, arquivosAdicionados, setArquivosAdicionados
    } = useContext(OperacaoContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [showCadastro, setShowCadastro] = useState(false)
    const [uploadDocumento, setUploadDocumento] = useState(false)

    function handleArquivoVisualizacao(arquivo) {
        forceDownloadFileFromURL(arquivo.link)
    }

    useEffect(() => {
        setShowCadastro(!!operacaoEdicao)
    }, [operacaoEdicao])

    useEffect(() => {
        loadTips(setCodigo, 'form_operacao_cadastro')
    }, [])

    return (
        <>
            <Container>
                <Button 
                    onClick={() => { 
                        setShowCadastro(true) 
                        
                        resetInitialValues()
                    }} 
                    className="transparent"
                >
                    Cadastrar nova operação
                    <FiPlus size={17} />
                </Button>
            </Container>

            <Modal
                isOpen={showCadastro}
                handleClose={() => { 
                    setShowCadastro(false) 
                
                    setOperacaoEdicao(null)
                }}
                title="Cadastro de operação"
            >
                <FormularioContainer>
                    <Formik
                        onSubmit={(values, formikBag) => {
                            values.documentos = arquivosAdicionados

                            handleSubmit(values, formikBag)

                            setShowCadastro(false)
                        }}
                        initialValues={initialValues}
                        enableReinitialize
                    >	
                        {({ setFieldValue }) => (
                            <Form>
                                <p style={{ gridColumn: '1/span 2' }}>{tips?.formulario}</p>

                                <Textbox
                                    name="descricao"
                                    label="Nome da operação"
                                />

                                <Select 
                                    label="Tipo de operação"
                                    name="tipo"
                                    options={tipos.map(tipo => ({
                                        label: tipo.descricao,
                                        value: tipo.id
                                    }))}
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                />

                                <ArquivosContainer>
                                    <Button className="transparent" onClick={() => { setUploadDocumento(true) }}>
                                        Anexar documento
                                        <FiFileText size={16} />
                                    </Button>

                                    <Table 
                                        headers={[
                                            { name: 'arquivo', value: 'Arquivo' }
                                        ]}
                                        data={arquivosAdicionados.map((arq, index) => ({
                                            id: arq.id,
                                            arquivo: `Arquivo ${index + 1}`,
                                            link: arq.link
                                        }))}
                                        actions={[
                                            {
                                                action: handleArquivoVisualizacao,
                                                name: 'Visualizar',
                                                icon: FaEye,
                                                iconSize: 16
                                            }
                                        ]}
                                    />
                                </ArquivosContainer>

                                <div className="button-container">
                                    {operacaoEdicao && (
                                        <Button className="transparent" onClick={handleCancelEdit}>
                                            Cancelar edição
                                        </Button>	
                                    )} 

                                    <Button type="submit" className="white">
                                        {operacaoEdicao ? 'Salvar alterações' : 'Cadastrar operação'}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </FormularioContainer>
            </Modal>

            <Modal
                isOpen={uploadDocumento}
                handleClose={() => { setUploadDocumento(false) }}
                title="Envio de documento"
            >
                <Formik
                    onSubmit={async values => {
                        const { link } = await api.get(`arquivo/${values.arquivo}`)

                        setUploadDocumento(false)

                        setArquivosAdicionados(old => [
                            ...old,
                            {
                                id: values.arquivo,
                                link
                            }
                        ])

                        toast.success('Documento enviado.')
                    }}
                    initialValues={{ arquivo: null }}
                >
                    {({ setFieldValue, errors }) => (
                        <Form>
                            <FormUploadContainer>
                                <File 
                                    name="arquivo"
                                    onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                    label="Documento"
                                    format="square"
                                    previewSize={['100%', '150px']}
                                    error={errors.arquivo}
                                />

                                <Button type="submit" className="transparent">
                                    Adicionar documento
                                </Button>
                            </FormUploadContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
