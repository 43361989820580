import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FiDownload } from 'react-icons/fi'

import { extname } from '../../../../util/path'
import Card from '../../../../components/Card'
import { Button } from '../../../../components/Form'
import Spinner from '../../../../components/Spinner'

import { forceDownloadFileFromURL } from '../../../../util/file'

import { Container, OrientacaoContainer, DownloadContainer } from './styles'
import { api } from '../../../../services/api'

const previewFormats = ['.pdf', '.png', '.jpg', '.gif', '.jpeg', '.bmp']

export default function () {
    const { id } = useParams()

    const [tipoDocumento, setTipoDocumento] = useState(null)

    async function loadTipoDocumento() {
        const response = await api.get(`tipo_documento/${id}`)

        await new Promise(resolve => { setTimeout(() => resolve(), 2500) }).then(() => {})

        setTipoDocumento(response)
    }

    useEffect(() => {
        loadTipoDocumento()
    }, [])

    async function handleDownloadTemplate() {
        if(tipoDocumento.modelo?.link) {
            forceDownloadFileFromURL(tipoDocumento.modelo.link, tipoDocumento.descricao)
        }
    }

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    {tipoDocumento ? (
                        <>
                            <h1>
                                {`Documento modelo: ${tipoDocumento?.descricao}`}
                            </h1>
                        
                            <OrientacaoContainer>
                                <div dangerouslySetInnerHTML={{ __html: tipoDocumento?.orientacao }} />

                                {tipoDocumento?.modelo?.link && previewFormats.includes(extname(tipoDocumento?.modelo.link)) && (
                                    <DownloadContainer>
                                        <Button onClick={handleDownloadTemplate} className="transparent">
                                            <FiDownload />
                                            Baixar o modelo
                                        </Button>
                                    </DownloadContainer>
                                )}
                            </OrientacaoContainer>

                            {!tipoDocumento?.modelo?.link ? (
                                <i>Este documento não possui modelo para download</i>
                            ) : previewFormats.includes(extname(tipoDocumento?.modelo.link)) ? (
                                <div className="modelo-container">
                                    <p>{`Modelo de ${tipoDocumento?.descricao}`}</p>
                                    <iframe src={tipoDocumento.modelo.link} title="documento" />
                                </div>
                            ) : (
                                <DownloadContainer>
                                    <Button onClick={handleDownloadTemplate} className="transparent">
                                        <FiDownload />
                                        Baixar o modelo
                                    </Button>
                                </DownloadContainer>
                            )}
                        </>
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
