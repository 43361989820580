import React, {
    memo, useEffect, useState, useContext
} from 'react'
import { Link } from 'react-router-dom'
import * as icons from 'react-icons/fa'

import { FiMenu } from 'react-icons/fi'
import { Container, Menu } from './styles'

import { GlobalContext } from '../../contexts/GlobalContext'
import { Button } from '../Form'

const NavOptions = memo(() => {
    const { user } = useContext(GlobalContext)

    const [grupos, setGrupos] = useState([])
    const [fullHeightMenu, setFullHeightMenu] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    function toggleShowMenu() {
        try {
            const novoStatus = !showMenu

            if (novoStatus && window.innerWidth <= 600) {
                window.scrollTo(0, 0)
            }

            setShowMenu(novoStatus)
        } catch (e) {

        }
    }

    useEffect(() => {
        if (user) {
            setGrupos(user?.perfil.grupos || [])
        }
    }, [user])

    useEffect(() => {
        const handleScroll = () => {
            setFullHeightMenu(window.scrollY >= 65)
        }

        document.addEventListener('scroll', handleScroll)

        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <Container>
            <Button
                fab
                className="transparent menu-trigger"
                style={{ position: 'absolute', top: 6, left: 6 }}
                onClick={toggleShowMenu}
            >
                <FiMenu size={18} color="#fff" />
            </Button>

            <Menu
                fullHeight={fullHeightMenu}
                visivel={showMenu}
                onMouseEnter={toggleShowMenu}
                onMouseLeave={toggleShowMenu}
            >
                <Link
                    to="/dashboard"
                    onClick={toggleShowMenu}
                >
                    <icons.FaHome size={20} />
                    <span>Home</span>
                </Link>

                {grupos.length > 0 && grupos.map(grupo => (
                    <Link key={grupo.descricao} to={`/grupo_permissoes/${grupo.id}`} className="navbar-link" onClick={toggleShowMenu}>
                        <div>
                            {React.createElement(icons[grupo.icone], { size: 20 })}
                        </div>

                        <span>{grupo.descricao}</span>
                    </Link>
                ))}
            </Menu>
        </Container>
    )
})

export default NavOptions
