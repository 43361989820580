import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { Editor } from 'primereact/editor'
import { FiSave } from 'react-icons/fi'

import Table from '../../../../components/Table'
import Spinner from '../../../../components/Spinner'
import {
    Select, Textbox, Button, File
} from '../../../../components/Form'

import { Container, ParametrosLista, Content } from './styles'

import { api, authHeaders } from '../../../../services/api'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'
import Card from '../../../../components/Card'
import { removerTag } from '../../../../util/string'

const baseInitialValues = {
    descricao: '',
    formato: null,
    tipo: null,
    arquivo: null
}

const formatos = [
    { label: 'Texto parametrizado', value: 'texto' },
    { label: 'Arquivo para download', value: 'arquivo' }
]

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [parametros, setParametros] = useState([])
    const [modelos, setModelos] = useState(null)
    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [inEdition, setInEdition] = useState(null)
    const [textoDocumento, setTextoDocumento] = useState('')
    const [textoRodape, setTextoRodape] = useState('')
    const [tiposDocumento, setTiposDocumento] = useState(null)

    async function loadModelos() {
        const response = await api.get('documento_modelo', authHeaders())

        setModelos(response.map(m => ({
            ...m,
            tipo: m.tipo_documento.descricao
        })))
    }

    async function loadTiposDocumento() {
        const response = await api.get('tipo_documento', {
            params: {
                grupo: 'acervo'
            }
        })

        setTiposDocumento(response)
    }

    function handleChangeText(text) {
        const matches = text.match(/([0-9a-záéíóúâêôãõçà]+#)/g)

        if (matches) {
            setParametros(matches.map(item => item.replace(/#/g, '')))
        }
    }

    async function handlePrepareEdit(id) {
        const response = await api.get(`documento_modelo/${id}`, authHeaders())

        setParametros([])

        setTextoDocumento(response.texto || '')

        setInitialValues({
            ...response,
            formato: response.arquivo ? formatos[1] : formatos[0],
            tipo: {
                value: response.tipo_documento.id,
                label: response.tipo_documento.descricao
            },
            arquivo: response.arquivo?.id,
            header: response.imagem_cabecalho?.id
        })

        setTextoRodape(response.texto_rodape)

        setInEdition(id)

        if (response.texto) {
            const matches = response.texto.match(/(#\w+#)/g)

            if (matches) {
                setParametros(matches.map(item => item.replace(/#/g, '')))
            }
        } else if (response.arquivo) {
            setParametros([])
        }
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            values = {
                descricao: values.descricao,
                tipo_documento_id: values.tipo.value,
                texto: values.formato.value === 'texto' ? textoDocumento : '',
                texto_rodape: values.formato.value === 'texto' ? textoRodape : '',
                arquivo_id: values.arquivo,
                header: values.header
            }

            if (inEdition) {
                await api.put(`documento_modelo/${inEdition}`, values, authHeaders())

                toast.success('Modelo atualizado com sucesso.')

                setInitialValues(baseInitialValues)
            } else {
                await api.post('documento_modelo', values, authHeaders())

                toast.success('Modelo cadastrado com sucesso.')

                resetForm()
            }

            setTextoDocumento('')
        } catch (e) {
            toast.error(e.msg || `Ocorreu um erro ao ${inEdition ? 'atualizar' : 'cadastrar'} o modelo.`)
        } finally {
            loadModelos()
        }
    }

    function handleCancelarEdicao() {
        setInEdition(null)

        setInitialValues(baseInitialValues)
    }

    async function handleDelete(id) {
        try {
            await api.delete(`documento_modelo/${id}`, authHeaders())

            toast.success('Modelo excluído com sucesso.')

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    function handleChangeTipo({ label: tipo }) {
        const titulo = `${tipo.toUpperCase()} Nº [número do documento]`

        setTextoDocumento(old => `<h1>${titulo}</h1>${removerTag(old, 'h1')}`)
    }

    useEffect(() => {
        loadModelos()
        loadTiposDocumento()
    }, [])

    useEffect(() => {
        loadTips(setCodigo, 'form_documento_geracao')
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Modelos de documentos</h1>

                    <Content>
                        <p>{tips?.formulario}</p>

                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({ setFieldValue, isSubmitting, values }) => (
                                <Form>
                                    <Textbox
                                        name="descricao"
                                        id="descricao_modelo"
                                        label="Descrição"
                                    />

                                    <Select
                                        name="tipo"
                                        label="Tipo de documento"
                                        options={tiposDocumento?.map(tipo => ({
                                            label: tipo.descricao,
                                            value: tipo.id
                                        })) || []}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            handleChangeTipo(selected)
                                        }}
                                    />

                                    <Select
                                        name="formato"
                                        label="Formato do modelo"
                                        options={formatos}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setFieldValue('texto', '')
                                            setFieldValue('arquivo', null)
                                        }}
                                    />

                                    {values.formato?.value === 'texto' ? (
                                        <>
                                            <Editor
                                                style={{ height: 250 }}
                                                className="editor-container"
                                                value={textoDocumento}
                                                onTextChange={e => {
                                                    handleChangeText(e.htmlValue)

                                                    setTextoDocumento(e.htmlValue)
                                                }}
                                            />

                                            <p className="tip">
                                                Para incluir informações variáveis, utilize a expressão
                                                {' '}
                                                <b>#informação#</b>
                                                . Entre as
                                                {' '}
                                                <b>#</b>
                                                {' '}
                                                não podem haver espaços, apenas letras minúsculas ou números.
                                            </p>

                                            <ParametrosLista>
                                                <h1>Parâmetros utilizados</h1>

                                                {parametros.length > 0 ? parametros.map(param => (
                                                    <p key={param}>{param}</p>
                                                )) : (
                                                    <i>Nenhum parâmetro incluído</i>
                                                )}
                                            </ParametrosLista>

                                            <File 
                                                name="header"
                                                id="header"
                                                onSuccess={fileId => setFieldValue('header', fileId)}
                                                label="Imagem de cabeçalho"
                                                format="square"
                                                previewSize={['100%', '180px']}
                                                maxSize={5 * 1024 * 1024}
                                                style={{ gridArea: 'header' }}
                                                tip="Recomenda-se uma imagem com 1774px de largura por 270px de altura."
                                                getPreloadImage={async () => {
                                                    if (values.header) {
                                                        const arquivo = await api.get(`arquivo/${values.header}`)
                                                        return arquivo?.link
                                                    }
                                                }}
                                            />

                                            <Editor
                                                style={{ height: 180, gridArea: 'footer' }}
                                                value={textoRodape}
                                                onTextChange={e => {
                                                    setTextoRodape(e.htmlValue)
                                                }}
                                            />
                                        </>
                                    ) : values.formato?.value === 'arquivo' ? (
                                        <>
                                            <File
                                                name="arquivo"
                                                id="arquivo_modelo"
                                                onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                                label="Modelo para download"
                                                format="square"
                                                previewSize={['100%', '300px']}
                                                maxSize={5 * 1024 * 1024}
                                                getPreloadImage={async () => {
                                                    if (values.arquivo) {
                                                        const arquivo = await api.get(`arquivo/${values.arquivo}`)
                                                        return arquivo?.link
                                                    }
                                                }}
                                            />
                                        </>
                                    ) : null}

                                    <div className="button-container">
                                        {inEdition && (
                                            <Button className="transparent" onClick={handleCancelarEdicao}>
                                                Cancelar edição
                                            </Button>
                                        )}

                                        <Button type="submit" className="white" loading={isSubmitting}>
                                            {inEdition ? 'Atualizar modelo' : 'Cadastrar modelo'}
                                            <FiSave size={18} />
                                        </Button>
                                    </div>

                                </Form>
                            )}
                        </Formik>

                        {modelos ? (
                            <Table
                                headers={[
                                    { name: 'descricao', value: 'Descrição' },
                                    { name: 'tipo', value: 'Tipo', centered: true }
                                ]}
                                data={modelos}
                                handlePrepareEdit={handlePrepareEdit}
                                handleDelete={handleDelete}
                                caption="Modelos cadastrados"
                                confirmExclusion={{
                                    attr: 'descricao',
                                    template: 'Deseja realmente excluir o modelo #attr#?'
                                }}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Content>
                </Card>
            </main>
        </Container>
    )
}
