import React, {
    createContext, useContext, useEffect, useState
} from 'react'
import { toast } from 'react-toastify'
import { format, getDay, isBefore } from 'date-fns'
import { useHistory } from 'react-router-dom'

import Modal from '../components/Modal'

import { api, authHeaders } from '../services/api'

import formUtils from '../util/form'
import { fillWithZeros } from '../util/number'
import { haveIntersectionTimes } from '../util/date'

import useQuery from '../hooks/useQuery'

import { GlobalContext } from './GlobalContext'

import AgendamentoCadastro from '../pages/UserViews/Atendimento/Acompanhar/Agendamento/Cadastro'
import { loadTips } from '../util/tip'
import { TipContext } from './TipContext'

export const AtendimentoContext = createContext()

const visaoAtendimentosDestaque = [11]

const AtendimentoProvider = ({ children }) => {
    const { tipo, add } = useQuery()

    const history = useHistory()

    const { user } = useContext(GlobalContext)
    const { setCodigo } = useContext(TipContext)

    const [atendimentos, setAtendimentos] = useState(null)
    const [atendimentosFinalizados, setAtendimentosFinalizados] = useState(null)
    const [atendimento, setAtendimento] = useState(null)
    const [tipos, setTipos] = useState(null)
    const [showNovoAtendimento, setShowNovoAtendimento] = useState(!!add)
    const [tipoAtendimento, setTipoAtendimento] = useState(null)
    const [assuntos, setAssuntos] = useState(null)
    const [atendimentoDestaque, setAtendimentosDestaque] = useState(null)
    const [showFormVisita, setShowFormVisita] = useState(false)
    const [advogados, setAdvogados] = useState(null)
    const [destinatariosGenericos, setDestinatariosGenericos] = useState(null)
    const [showAgendamentos, setShowAgendamentos] = useState(false)
    const [agendamentos, setAgendamentos] = useState(null)
    const [agendamentosGlobal, setAgendamentosGlobal] = useState(null)
    const [agendamentoEdit, setAgendamentoEdit] = useState(null)
    const [agendamentoExtraEdit, setAgendamentoExtraEdit] = useState(null)
    const [registroAtendimentoPresencial, setRegistroAtendimentoPresencial] = useState(null)
    const [showAtendimentoExtra, setShowAtendimentoExtra] = useState(false)
    const [desabilitarAberturaDemanda, setDesabilitarAberturaDemanda] = useState(false)
    const [perfisDemandaBloqueados, setPerfisDemandaBloqueados] = useState([])

    async function loadTipos() {
        const response = await api.get('atendimento_tipo')

        if (tipo) {
            setTipos(response.filter(t => t.id === Number(tipo)))
        } else {
            setTipos(response.filter(t => t.id !== 1))
        }
    }

    async function loadAssuntos() {
        const response = await api.get('atendimento_assunto', {
            params: {
                tipoId: atendimento.tipo.id
            }
        })

        setAssuntos(response.filter(r => r.id !== atendimento.assunto?.id))
    }

    function getTiposDemandaPorPerfil(perfilId) {
        const isAdvogado = [20, 23].includes(perfilId)

        return isAdvogado
            ? [1]
            : tipoAtendimento?.id
                ? tipos.filter(t => t.id === tipoAtendimento.id).map(t => t.id)
                : tipos.filter(t => t.id !== 1).map(t => t.id)
    }

    async function loadAtendimentos() {
        const demandaTipos = getTiposDemandaPorPerfil(user?.perfil.id)

        const pessoa = await api.get(`pessoa/${user.pessoa.id}/assuntos`)

        const response = await api.get('atendimento', {
            params: {
                atendimento_tipo_id: demandaTipos,
                associado_id: user?.perfil.id === 2 ? user.associado.id : undefined,
                assuntos: pessoa.assuntos?.map(a => a.id)
            },
            ...authHeaders()
        })

        setAtendimentos(response
            .filter(a => a.status.id !== 2)
            .map(a => ({
                id: a.id,
                atendimento_id: `#${fillWithZeros(a.id, 3)}`,
                protocolo: a.protocolo || '-',
                crm: a.associado.pessoa.documentos.find(doc => doc.tipo_documento.id === 3).identificador,
                nome: a.associado.pessoa.nome,
                data: format(new Date(a.createdAt), 'dd/MM/yyyy HH:mm\'h\''),
                tipo: a.tipo.descricao,
                tipo_id: a.tipo.id,
                assunto: a.assunto?.descricao || '',
                status: a.status.descricao,
                ultimo_atendente: a.ultimo_atendente?.nome || '-',
                background: user.perfil.id !== 2 && atendimentoDestaque.includes(a.associado.pessoa.id) ? '#ffcdd2' : null,
                avaliacao: a.avaliacao,
                associado: a.associado,
                token: a.token
            })))

        setAtendimentosFinalizados(response
            .filter(a => a.status.id === 2)
            .map(a => ({
                id: a.id,
                atendimento_id: `#${a.id}`,
                protocolo: a.protocolo || '-',
                crm: a.associado.pessoa.documentos.find(doc => doc.tipo_documento.id === 3).identificador,
                nome: a.associado.pessoa.nome,
                data: format(new Date(a.createdAt), 'dd/MM/yyyy HH:mm\'h\''),
                tipo: a.tipo.descricao,
                tipo_id: a.tipo.id,
                assunto: a.assunto?.descricao || '',
                status: a.status.descricao,
                ultimo_atendente: a.ultimo_atendente?.nome || '-',
                background: user.perfil.id !== 2 && atendimentoDestaque.includes(a.associado.pessoa.id) ? '#ffcdd2' : null,
                avaliacao: a.avaliacao,
                associado: a.associado,
                token: a.token
            })))
    }

    async function reload() {
        loadAtendimentos()

        setShowNovoAtendimento(false)
    }

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)

            await api.post('atendimento', {
                ...values,
                atendimento_tipo_id: tipo ? Number(tipo) : values.tipo_atendimento,
                atendimento_assunto_id: Number(tipo) === 1 ? null : values.tipo_assunto
            }, authHeaders())

            toast.success('Atendimento iniciado com sucesso.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadTipoAtendimento() {
        const atendimentoTipo = user?.perfil.id === 20 ? 1 : (tipo || '')

        const response = await api.get(`atendimento_tipo/${atendimentoTipo}`)

        setTipoAtendimento(response)
    }

    async function loadAtendimento(id) {
        try {
            const response = await api.get(`atendimento/${id}`, authHeaders())

            setAtendimento(response)
        } catch (e) {
            toast.warn(e.msg)

            history.push('/dashboard')
        }
    }

    async function loadAdvogados() {
        const response = await api.get('advogado', authHeaders())

        setAdvogados(response)
    }

    async function loadDestinatariosGenericos() {
        const diretoria = await api.get('diretoria', authHeaders())
        const funcionarios = await api.get('colaborador', authHeaders())

        setDestinatariosGenericos([
            ...diretoria.map(({ pessoa }) => ({
                id: pessoa.id,
                nome: pessoa.nome
            })),
            ...funcionarios.filter(func => [5, 7].includes(func.id))
                .map(({ pessoa }) => ({
                    id: pessoa.id,
                    nome: pessoa.nome
                }))
        ].filter(item => item.id !== user.pessoa.id))
    }

    function getAgendamentoDestaqueColor(agendamento) {
        if (isBefore(new Date(`${agendamento.data} 00:00:00`), new Date()) && agendamento.status.id === 1) {
            return '#fff59d'
        }

        if (agendamento.status.id === 3) {
            return '#c8e6c9'
        }

        return null
    }

    function verificarAlertaHorarioAgendamento() {
        if ([2].includes(user.perfil.id)) {
            return
        }

        let agendamentosNaoConcluidos = agendamentos.filter(a => {
            const fimAtendimento = new Date(`${a.data} ${a.fim}`)
            const eOAtendente = a.atendente?.id === user.pessoa.id

            return a.status.id === 1 && isBefore(fimAtendimento, new Date()) && (eOAtendente || user.perfil.id === 23)
        })

        if (!agendamentosNaoConcluidos.length) {
            agendamentosNaoConcluidos = agendamentos.filter(a => {
                const inicioAtendimento = new Date(`${a.data} ${a.inicio}`)
                const fimAtendimento = new Date(`${a.data} ${a.fim}`)

                return isBefore(inicioAtendimento, new Date()) && isBefore(new Date(), fimAtendimento)
            })
        }

        if (agendamentosNaoConcluidos.length) {
            setRegistroAtendimentoPresencial(agendamentosNaoConcluidos.map(a => ({
                ...a,
                atendente: a.atendente.nome,
                associado: a.atendimento.associado.pessoa.nome,
                status_descricao: a.status.descricao,
                data_hora: format(new Date(`${a.data} ${a.inicio}`), 'dd/MM/yyyy HH:mm\'h\''),
                background: null
            })))
        }
    }

    async function loadAgendamentos() {
        try {
            const response = await api.get(`atendimento/agendamento/${atendimento.id}`, authHeaders())

            setAgendamentos(response.map(a => ({
                ...a,
                associado: a.atendimento.associado.pessoa.nome,
                nome_atendente: a.atendente.nome,
                data_formatada: format(new Date(`${a.data} ${a.inicio}`), 'dd/MM/yyyy HH:mm\'h\''),
                status_descricao: a.status.descricao,
                background: getAgendamentoDestaqueColor(a)
            })))
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadAgendamentosGlobal() {
        const response = await api.get('atendimento/agendamento', authHeaders())

        setAgendamentosGlobal(response.map(a => ({
            codigo_demanda: `#${a.atendimento.id}`,
            ...a,
            associado: a.atendimento.associado.pessoa.nome,
            atendente_nome: a.atendente.nome,
            data_hora: format(new Date(`${a.data} ${a.inicio}`), 'dd/MM/yyyy HH:mm\'h\''),
            status_descricao: a.status.descricao,
            background: getAgendamentoDestaqueColor(a)
        })))
    }

    async function reloadDemanda() {
        setShowFormVisita(false)
        setAgendamentoEdit(null)
        setRegistroAtendimentoPresencial(null)

        if (atendimento) {
            await loadAtendimento(atendimento.id)
        } else {
            await loadAgendamentosGlobal()
        }

        await loadAdvogados()
    }

    async function reloadAtendimentoExtra() {
        setAgendamentoExtraEdit(false)
        setShowAtendimentoExtra(false)

        await loadAgendamentosGlobal()
    }

    async function loadAtendimentosDestaque() {
        try {
            const pessoasDestaque = []

            for (const visaoId of visaoAtendimentosDestaque) {
                const response = await api.get(`visao/${visaoId}`, authHeaders())

                if (response) {
                    pessoasDestaque.push(...response.pessoas.map(p => p.id))
                }

            }

            setAtendimentosDestaque(pessoasDestaque)
        } catch (e) {
            console.log('Erro ao carregar os destaques.', e)
        }
    }

    async function handleAtualizarStatus(agendamento, status) {
        try {
            setRegistroAtendimentoPresencial(null)

            await api.put(`atendimento/agendamento/${agendamento.id}`, {
                atendimento_agendamento_status_id: status
            }, authHeaders())

            toast.success('Agendamento atualizado.')

            await loadAgendamentos()
            await loadAgendamentosGlobal()
            await loadAdvogados()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmitAgendamentoExtra(values) {
        try {
            const diaSemana = getDay(values.data) + 1

            values = formUtils.extractFormValues(values)

            const body = {
                pessoa_id: values.advogado,
                atendimento_id: values.atendimento,
                inicio: `${values.inicio}:00`,
                fim: `${values.fim}:00`,
                data: values.data,
                motivo: values.motivo
            }

            const { agenda } = advogados.find(a => a.pessoa.id === body.pessoa_id)

            const agendaDiaSemana = agenda.find(a => a.dia_semana === diaSemana)

            if (agendaDiaSemana) {
                const { horario_inicio: agendaInicio, horario_fim: agendaFim } = agenda.find(a => a.dia_semana === diaSemana)

                const interseccao = haveIntersectionTimes({
                    start: agendaInicio,
                    end: agendaFim
                }, {
                    start: body.inicio,
                    end: body.fim
                })

                if (interseccao) {
                    const horaInicio = agendaInicio.substr(0, 5)
                    const horaFim = agendaFim.substr(0, 5)

                    toast.warn(`O período epecificado coincide com o período regular de atendimento. Escolha um período fora do intervalo de ${horaInicio}h às ${horaFim}h.`)
                    return
                }
            }

            if (agendamentoExtraEdit) {
                await api.put(`atendimento/agendamento_extra/${agendamentoExtraEdit.id}`, body, authHeaders())

                toast.success('Agendamento atualizado.')
            } else {
                await api.post('atendimento/agendamento_extra', body, authHeaders())

                toast.success('Agendamento realizado.')
            }

            reloadAtendimentoExtra()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function verificarPendencias() {
        if ([2, 9, 27, 29, 28, 34, 33].includes(user.perfil.id)) {
            const response = await api.get('/verificar_pendencias', authHeaders())

            setDesabilitarAberturaDemanda(response.pendencias)
        }
    }

    async function perfisVisualizacaoDemandaBloqueados() {
        const response = await api.get('/perfis_bloqueados', authHeaders())

        setPerfisDemandaBloqueados(response.perfis[0].valor.split(',').map(Number))
    }

    useEffect(() => {
        if (user) {
            loadTipoAtendimento()

            loadAtendimentosDestaque()

            loadAdvogados()

            loadDestinatariosGenericos()

            loadAgendamentosGlobal()

            verificarPendencias()

            perfisVisualizacaoDemandaBloqueados()
        }
    }, [user])

    useEffect(() => {
        if (atendimento?.tipo?.id) {
            loadAssuntos()
        }
    }, [atendimento])

    useEffect(() => {
        if (atendimentoDestaque && tipos) {
            loadAtendimentos()
        }
    }, [atendimentoDestaque, tipos])

    useEffect(() => {
        if (atendimento) {
            loadAgendamentos()
        }
    }, [atendimento])

    useEffect(() => {
        if (agendamentos) {
            verificarAlertaHorarioAgendamento()
        }
    }, [agendamentos])

    useEffect(() => {
        if (tipoAtendimento) {
            loadTipos()
        }
    }, [tipoAtendimento])

    useEffect(() => {
        if (showFormVisita || agendamentoEdit) {
            loadTips(setCodigo, 'form_cadastro_agendamento')
        }
    }, [agendamentoEdit, showFormVisita])

    return (
        <AtendimentoContext.Provider
            value={{
                atendimentos,
                tipos,
                showNovoAtendimento,
                setShowNovoAtendimento,
                handleSubmit,
                reload,
                tipoAtendimento,
                loadAtendimento,
                atendimento,
                reloadDemanda,
                atendimentosFinalizados,
                assuntos,
                showFormVisita,
                setShowFormVisita,
                advogados,
                showAgendamentos,
                setShowAgendamentos,
                agendamentos,
                agendamentoEdit,
                setAgendamentoEdit,
                setRegistroAtendimentoPresencial,
                registroAtendimentoPresencial,
                handleAtualizarStatus,
                setShowAtendimentoExtra,
                showAtendimentoExtra,
                agendamentosGlobal,
                setAgendamentoExtraEdit,
                agendamentoExtraEdit,
                handleSubmitAgendamentoExtra,
                destinatariosGenericos,
                desabilitarAberturaDemanda,
                perfisDemandaBloqueados
            }}
        >
            <>
                {children}

                <Modal
                    isOpen={!!agendamentoEdit}
                    handleClose={() => { setAgendamentoEdit(null) }}
                    title={`Editar agendamentos de ${agendamentoEdit?.atendimento?.associado.pessoa.nome}`}
                >
                    <AgendamentoCadastro />
                </Modal>

                <Modal
                    isOpen={showFormVisita}
                    handleClose={() => { setShowFormVisita(false) }}
                    title="Agendar atendimento presencial"
                >
                    <AgendamentoCadastro />
                </Modal>
            </>
        </AtendimentoContext.Provider>
    )
}

export default AtendimentoProvider
