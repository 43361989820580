import React, { createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import formUtils from '../util/form'

import { api, authHeaders } from '../services/api'

export const TutorialContext = createContext()

const TutorialProvider = ({ children }) => {
    const [assuntos, setAssuntos] = useState(null)
    const [showCadastroAssunto, setShowCadastroAssunto] = useState(false)
    const [showCadastroTutorial, setShowCadastroTutorial] = useState(false)
    const [assuntoSelecionado, setAssuntoSelecionado] = useState(null)
    const [tutoriais, setTutoriais] = useState(null)
    const [editAssunto, setEditAssunto] = useState(null)
    const [editTutorial, setEditTutorial] = useState(null)

    async function loadAssuntos() {
        const response = await api.get('tutorial_assunto', authHeaders())

        setAssuntos(response)
    }

    useEffect(() => {
        loadAssuntos()
    }, [])

    useEffect(() => {
        if (assuntoSelecionado) {
            setTutoriais(assuntos.find(a => a.id === assuntoSelecionado.value).tutoriais)
        } else {
            setTutoriais([])
        }
    }, [assuntoSelecionado])

    function reloadAssuntos() {
        setShowCadastroAssunto(false)
        setShowCadastroTutorial(false)
        setAssuntoSelecionado(null)
        setEditAssunto(null)
        setEditTutorial(null)

        loadAssuntos()
    }

    async function handleDeleteAssunto(id) {
        try {
            await api.delete(`tutorial_assunto/${id}`, authHeaders())

            toast.success('Assunto removido com sucesso.')

            loadAssuntos()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handleDeleteTutorial(id) {
        try {
            await api.delete(`tutorial/${id}`, authHeaders())

            toast.success('Tutorial removido com sucesso.')

            loadAssuntos()

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handlePrepareEditTutorial(id) {
        const tutorial = tutoriais.find(t => t.id === id)

        setEditTutorial(tutorial)
    }

    async function handlePrepareEditAssunto(id) {
        const assunto = assuntos.find(a => a.id === id)

        setEditAssunto(assunto)
    }

    async function handleSubmitAssunto(values) {
        try {
            if (editAssunto) {
                await api.put(`tutorial_assunto/${editAssunto.id}`, {
                    ...values,
                    imagem_id: values.imagem
                }, authHeaders())

                toast.success('Assunto atualizado com sucesso.')
            } else {
                await api.post('tutorial_assunto', {
                    ...values,
                    imagem_id: values.imagem
                }, authHeaders())

                toast.success('Assunto cadastrado com sucesso.')
            }

            reloadAssuntos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleSubmitTutorial(values) {
        try {
            values = formUtils.extractFormValues(values)

            if (editTutorial) {
                await api.put(`tutorial/${editTutorial.id}`, {
                    ...values,
                    arquivo_id: values.arquivo,
                    tutorial_assunto_id: values.assunto
                }, authHeaders())

                toast.success('Tutorial atualizado com sucesso.')
            } else {
                await api.post('tutorial', {
                    ...values,
                    arquivo_id: values.arquivo,
                    tutorial_assunto_id: values.assunto
                }, authHeaders())

                toast.success('Tutorial cadastrado com sucesso.')
            }

            reloadAssuntos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    return (
        <TutorialContext.Provider value={{
            assuntos,
            setShowCadastroAssunto,
            showCadastroAssunto,
            setAssuntoSelecionado,
            assuntoSelecionado,
            tutoriais,
            showCadastroTutorial,
            setShowCadastroTutorial,
            handleDeleteAssunto,
            handleDeleteTutorial,
            handlePrepareEditTutorial,
            handlePrepareEditAssunto,
            editAssunto,
            editTutorial,
            setEditAssunto,
            setEditTutorial,
            handleSubmitAssunto,
            handleSubmitTutorial
        }}
        >
            {children}
        </TutorialContext.Provider>
    )
}

export default TutorialProvider
