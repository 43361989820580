import React, { useContext, useEffect, useState } from 'react'

import { Select } from '../../../../components/Form'
import FormPagamento from '../../../../components/FormPagamento'
import { api } from '../../../../services/api'
import { AssociacaoContext } from '../../../../contexts/AssociacaoContext'
import { AvatarContext } from '../../../../contexts/AvatarContext'

export function Financeiro() {
    const { associar } = useContext(AssociacaoContext)
    const { handleSpeak } = useContext(AvatarContext)

    const [tiposPagamento, setTiposPagamento] = useState([])
    const [tipoPagamentoSelecionado, setTipoPagamentoSelecionado] = useState(null)

    useEffect(() => {
        async function loadTiposPagamento() {
            const response = await api.get('tipo_pagamento', {
                params: { ativos: '1' }
            })

            setTiposPagamento(response)
        }

        loadTiposPagamento()

        handleSpeak('Aqui você poderá optar por uma das formas de pagamento disponíveis. Selecione uma delas, leia atentamente o Termo de Ciência e verifique todos os dados antes de concluir.')
    }, [])

    return (
        <div>
            <Select
                name="tipo_pagamento"
                label="Forma de pagamento"
                withoutForm
                onChange={selected => setTipoPagamentoSelecionado(selected)}
                options={tiposPagamento.map(tipo => ({
                    label: tipo.descricao,
                    value: tipo.id
                }))}
                value={tipoPagamentoSelecionado}
                isSearchable={false}
            />

            <FormPagamento
                tipoPagamento={tipoPagamentoSelecionado}
                onSubmit={values => { 
                    if(values.autorizado && tipoPagamentoSelecionado?.value) {
                        associar({
                            tipo_pagamento: tipoPagamentoSelecionado.value,
                            ...values
                        }) 
                    }
                }}
            />
        </div>
    )
} 
