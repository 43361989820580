import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { FiSearch } from 'react-icons/fi'
import {
    Button, Calendar, Select, Textbox
} from '../../../../../components/Form'
import { ProcessoContext } from '../../../../../contexts/ProcessoContext'

import { FiltrosContainer } from './styles'

export default function () {
    const {
        setores,
        tiposDisponiveis,
        assuntosDisponiveis,
        statusDisponiveis,
        loadAssuntosDisponiveisNoTipo,
        loadProcessos,
        filtroAtual,
        handleLoadAtendentesFiltro,
        atendentesFiltro
    } = useContext(ProcessoContext)

    return (
        <Formik
            initialValues={filtroAtual}
            onSubmit={loadProcessos}
        >
            {({ setFieldValue }) => (
                <Form>
                    <FiltrosContainer>
                        <Calendar
                            name="periodo"
                            label="Período"
                            selectionMode="range"
                            required
                        />

                        <Textbox
                            name="crm"
                            label="Matrícula"
                            type="number"
                        />

                        <Textbox
                            name="nome"
                            label="Nome do solicitante"
                        />

                        <Select
                            name="setor"
                            label="Atualmente no grupo"
                            options={setores ? [
                                { label: 'Todos', value: '' },
                                ...setores.map(item => ({
                                    label: item.descricao,
                                    value: item.id
                                }))
                            ] : []}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)

                                handleLoadAtendentesFiltro(selected.value)
                            }}
                        />

                        <Select
                            name="tipo"
                            label="Tipo"
                            options={tiposDisponiveis ? [
                                { label: 'Todos', value: '' },
                                ...tiposDisponiveis.map(item => ({
                                    label: item.descricao,
                                    value: item.id
                                }))] : []}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                                setFieldValue('assunto', { label: 'Todos', value: '' })

                                loadAssuntosDisponiveisNoTipo(selected.value)
                            }}
                        />

                        <Select
                            name="assunto"
                            label="Assunto"
                            options={assuntosDisponiveis ? [
                                { label: 'Todos', value: '' },
                                ...assuntosDisponiveis.map(item => ({
                                    label: item.descricao,
                                    value: item.id
                                }))] : []}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                        />

                        <Select
                            name="atendente"
                            label="Sendo atendido por"
                            options={atendentesFiltro ? [
                                { label: 'Todos', value: '' },
                                ...atendentesFiltro.map(item => ({
                                    label: item.nome,
                                    value: item.id
                                }))] : []}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                        />

                        <Select
                            name="status"
                            label="Status atual"
                            options={statusDisponiveis ? [
                                { label: 'Todos', value: '' },
                                ...statusDisponiveis.map(item => ({
                                    label: item.descricao,
                                    value: item.id
                                }))] : []}
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)
                            }}
                        />

                        <Button className="transparent" type="submit">
                            Pesquisar
                            <FiSearch size={16} />
                        </Button>
                    </FiltrosContainer>
                </Form>
            )}
        </Formik>
    )
}
