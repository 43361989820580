import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'

import { api, authHeaders } from '../../../../../services/api'

import { Textarea, Button } from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'
import RatingStarts from '../../../../../components/RatingStarts'

import { Container } from './styles'

import syndi from '../../../../../assets/images/syndi.png'
import { GlobalContext } from '../../../../../contexts/GlobalContext'

export default function ({ processoAvaliacao = null }) {
    const { token } = useParams()

    const { user } = useContext(GlobalContext)

    const [processo, setProcesso] = useState(null)
    const [alreadyRated, setAlreadyRated] = useState(false)
    const [avaliado, setAvaliado] = useState(false)

    async function loadProcesso() {
        if(user) {
            const response = await api.get(`processo/${processoAvaliacao.id}`, authHeaders())

            setProcesso(response)

            if(response.avaliacao) {
                setAlreadyRated(true)
            }
        } else {
            const response = await api.get(`processo/token/${token}`)

            setProcesso(response)

            if(response.avaliacao) {
                setAlreadyRated(true)
            }
        }
    }

    async function handleSubmit(values) {
        try {
            const [rating] = [...document.querySelectorAll('input[name=rating]')]
                .map(r => ({
                    id: r.id,
                    value: r.checked
                }))
                .filter(r => r.value)

            await api.post(`processo/token/${processo.token}`, {
                ...values,
                rating: Number(rating.id.split('-')[1])
            })

            toast.success('Obrigado pelo seu feedback! Conte conosco!')

            setAvaliado(true)
        } catch(e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if(token || user) {
            loadProcesso()
        }
    }, [token, user])

    return (
        <Container>
            <img src={syndi} alt="Syndi" />

            {!processo ? (
                <Spinner />
            ) : alreadyRated ? (
                <div className="balloon">
                    <p>Este chamado já foi avaliado. Obrigado!</p>
                </div>
            ) : avaliado ? (
                <div className="balloon">
                    <p>Agradecemos sua avaliação!</p>
                </div>
            ) : (
                <>
                    <div className="balloon">
                        {processo ? (
                            <>
                                <p>{`Olá, ${processo?.demandante.nome}, eu gostaria de contar com sua opinião sobre o nosso atendimento.`}</p>
                                <p>Por favor, qualifique o atendimento de 1 a 5 estrelas.</p>
                            </>
                        ) : (
                            <p>Aguarde...</p>
                        )}
                    </div>

                    {processo && (
                        <Formik
                            initialValues={{ observacao: '' }}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <RatingStarts />

                                    <Textarea 
                                        name="observacao"
                                        label="Digite seu feedback"
                                    />

                                    <Button type="submit" className="transparent" loading={isSubmitting}>
                                        Enviar feedback
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    )}
                </>
            )}
        </Container>
    )
}
