import React, { useCallback, useState } from 'react'
import { Formik, Form } from 'formik'
import { FiFile, FiExternalLink } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import { Container, ButtonContainer } from './styles'

import { Calendar, Button } from '../../../../../components/Form'

import rangeDayGif from '../../../../../assets/images/gifs/periodo-dias.gif'

import Table from '../../../../../components/Table'

const nomeRelatorio = 'Relatório de documentos criados'

const validation = Yup.object().shape({
    periodo: Yup.string().nullable().required('Informe um periodo para a busca.')
})

export default function (props) {
    const [result, setResult] = useState(null)

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        try {
            const { periodo } = values

            let response = await api.get('acervo/relatorio/documentos_criados', {
                ...authHeaders(),
                params: {
                    inicio: periodo[0],
                    fim: periodo[1]
                }
            })

            response = response.map(item => {
                const nivel1 = item.segmento.descricao
                const nivel2 = item.segmento.segmento_pai?.descricao
                const nivel3 = item.segmento.segmento_pai?.segmento_pai?.descricao

                const segmentosLocal = [nivel1, nivel2, nivel3].filter(s => !!s).reverse()

                const localizacao = segmentosLocal.join(' ➔ ')

                return {
                    ...item,
                    data_publicacao_formatada: format(new Date(item.data_publicacao), 'dd/MM/yyyy'),
                    criador_nome: item.criador.nome,
                    localizacao,
                    link: item.arquivo?.link || item.link
                }
            })

            setResult(response)

            resetForm()
        } catch(e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{ periodo: '' }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Calendar 
                            name="periodo" 
                            label="Período para busca"
                            selectionMode="range"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeDayGif}" style="width:300px; height:auto" />`}
                        /> 

                        <ButtonContainer>
                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>

            {result && (
                <Table 
                    headers={[
                        { name: 'titulo', value: 'Documento' },
                        { name: 'criador_nome', value: 'Gerado por', centered: true },
                        { name: 'data_publicacao_formatada', value: 'Data de publicação', centered: true },
                        { name: 'localizacao', value: 'Localização' }
                    ]}
                    actions={[
                        {
                            name: 'Abrir',
                            action: item => {
                                window.open(item.link, '_blank')
                            },
                            icon: FiExternalLink,
                            iconSize: 16,
                            checkDisabled: item => !item.link
                        }
                    ]}
                    data={result}
                />
            )}
        </Container>
    )
}
