import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: flex-end;
`

export const FormContainer = styled.div`
	form {
		display: grid;
		gap: 0 24px;
		grid-template-columns: 1fr 2fr;
		grid-template-rows: 74px 74px 74px auto auto auto;
		grid-template-areas:
			'nome button_add'
			'motivo pessoa'
			'data_hora pessoa'
			'data_hora_saida pessoa'
			'tipo pessoa'
			'detalhes detalhes'
			'button button';
	
		.textarea {
			grid-column: 1/span 3;
		}

		.button-container {
			display: flex; 
			justify-content: flex-end;

			button {
				width: fit-content;
			}
		}

		> p {
			margin: 0 8px;
			align-self: center;
		}

		.result-list {
			position: absolute;
			z-index: 2;
			width: 400px;
			left: 28px;
			top: 134px;
			border-radius: 6px;
			background-color: #FFF;
			box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
			height: fit-content;

			li {
				padding: 8px;
				color: #424242;
				display: flex;
				align-items: center;

				&:hover {
					background-color: #0001;
					cursor: pointer;
				}
			}
		}

		.empty-list {
			position: absolute;
			z-index: 2;
			width: 324px;
			left: 28px;
			top: 134px;
			border-radius: 6px;
			background-color: gold;
			box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
			padding: 8px;
			color: #424242;
		}

		.btn-cadastro {
			width: fit-content;
			margin-top: 22px;
		}

		@media(max-width: 900px) {
			grid-template-columns: 1fr;
			grid-template-rows: 74px auto auto 74px auto auto;
			grid-template-areas:
				'nome'
				'button_add'
				'pessoa'
				'motivo'
				'data_hora'
				'data_hora_saida'
				'tipo'
				'detalhes'
				'button';
		}
	}

	> p {
		margin-bottom: 24px;
		margin-top: 15px;
		margin-left: 8px;
	}
`

export const PessoaContainer = styled.div`
	grid-area: pessoa;
	display: grid;
	grid-template-columns: 130px 1fr 1fr;
	grid-template-rows: 50px 50px auto;
	gap: 0 20px;
	margin: 20px 0;

	.avatar {
		grid-row: 1/span 3;
		box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
		border-radius: 50%;
		width: 130px;
		height: 130px;
		object-fit: cover;
	}

	> h1 {
		grid-column: 2/span 2;
		margin: 0;
	}

	> h2 {
		grid-column: 2;
		font-size: 14px;
	}

	> h3 {
		font-size: 14px;
		grid-column: 3;

		button {
			margin: 14px 0;
		}
	}

	@media(max-width: 900px) {
		grid-template-columns: 1fr;
		grid-template-rows: 130px auto;
		justify-content: center;
		gap: 4px 0;
		margin: 16px;

		> h1, > h2, > h3 {
			grid-column: 1;
		}

		> h1, .avatar {
			justify-self: center;
			text-align: center;
		}
	}
`

export const PessoaCadastroContainer = styled.div`
	grid-area: pessoa;
	display: grid;
	gap: 4px 0;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 70px 70px 70px;

	> h4 {
		font-size: 14px;
		grid-column: 1/span 2;
		margin: 22px 8px 0;
	}

	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`

export const PlaceholderPessoa = styled.div`
	grid-area: pessoa;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	svg {
		color: #ddd;
		font-size: 60px;

		&:hover {
			cursor: unset;
			opacity: unset;
		}
	}

	h1 {
		color: #ddd;
		font-size: 16px;
	}
`
