import React, { useContext, useState, useEffect } from 'react'
import {
    Formik, Form, FieldArray
} from 'formik'
import { isBefore } from 'date-fns'
import { toast } from 'react-toastify'
import { FiTrash, FiEye } from 'react-icons/fi'

import { api, authHeaders } from '../../../../../services/api'

import masks from '../../../../../util/masks'
import formUtils from '../../../../../util/form'
import arrayUtils from '../../../../../util/array'
import { getDateObject } from '../../../../../util/date'

import { Button, Textbox, Select } from '../../../../../components/Form'
import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'
import Modal from '../../../../../components/Modal'

import { OrcamentoContext } from '../../../../../contexts/OrcamentosContext'

import {
    Container, ProgressoConsumo, FormAdd, OrcamentoItemContainer, ContasCorrenteContainer, ConfirmClearContainer
} from './styles'
import { TipContext } from '../../../../../contexts/TipContext'

export default function () {
    const {
        setShowCadastro, ano, reload,
        dadosAnoAnterior, dadosAnoAtual, contasCorrentes, saldoGeralContasCorrentes
    } = useContext(OrcamentoContext)
    const { tips } = useContext(TipContext)

    const [initialValues, setInitialValues] = useState(null)
    const [orcado, setOrcado] = useState(0)
    const [consumo, setConsumo] = useState(0)
    const [saldo, setSaldo] = useState(0)
    const [confirmClearStorage, setConfirmClearStorage] = useState(null)
    const [saldoAnoAnterior, setSaldoAnoAnterior] = useState(null)

    async function setup() {
        const storedBackup = JSON.parse(localStorage.getItem('sindmepa_orcamento'))

        if (storedBackup) {
            if (ano !== storedBackup.ano) {
                setConfirmClearStorage(storedBackup)
            }

            const somatoria = storedBackup.orcamentos.reduce((total, current) => total + masks.unmask.money(current.valor), 0)

            setInitialValues({
                ...storedBackup,
                saldo_ano_anterior: masks.maskApply.currency(storedBackup.saldo_ano_anterior),
                receita_prevista: masks.maskApply.currency(storedBackup.receita_prevista),
                orcamentos: storedBackup.orcamentos
            })

            setOrcado(somatoria)

            setSaldo(storedBackup.receita_prevista + storedBackup.saldo_ano_anterior - somatoria)

            setConsumo(Math.floor((somatoria * 100) / (storedBackup.receita_prevista + storedBackup.saldo_ano_anterior)))

            toast.info('Você tem um orçamento inacabado. Recuperamos seus dados.')
        } else if (dadosAnoAnterior.restante_cc && dadosAnoAtual.orcamentos) {
            const { orcamentos } = await api.get('orcamento', {
                params: { ano },
                ...authHeaders()
            })

            if (!orcamentos.length) {
                setInitialValues({
                    saldo_ano_anterior: masks.maskApply.currency(dadosAnoAnterior.restante_cc) < 0 ? 0 : masks.maskApply.currency(dadosAnoAnterior.restante_cc),
                    receita_prevista: masks.maskApply.currency(dadosAnoAtual.receita_prevista || dadosAnoAnterior.somatoria_receitas_ano),
                    orcamentos: []
                })
            } else {
                setInitialValues({
                    saldo_ano_anterior: masks.maskApply.currency(dadosAnoAnterior.restante_cc) < 0 ? 0 : masks.maskApply.currency(dadosAnoAnterior.restante_cc),
                    receita_prevista: masks.maskApply.currency(dadosAnoAtual.receita_prevista || dadosAnoAnterior.somatoria_receitas_ano),
                    orcamentos: orcamentos.map(o => {
                        const alerta_1 = o.alertas.find(a => a.nivel === 1)?.percentual
                        const alerta_2 = o.alertas.find(a => a.nivel === 2)?.percentual
                        const alerta_3 = o.alertas.find(a => a.nivel === 3)?.percentual

                        return {
                            valor: masks.maskApply.currency(o.valor),
                            alerta_1: alerta_1 ? masks.maskApply.percent(alerta_1) : '',
                            alerta_2: alerta_2 ? masks.maskApply.percent(alerta_2) : '',
                            alerta_3: alerta_3 ? masks.maskApply.percent(alerta_3) : '',
                            conta: { label: o.conta.descricao, value: o.conta.id }
                        }
                    })
                })
            }

            const somatoria = orcamentos.reduce((total, current) => total + Number(current.valor), 0)

            setOrcado(somatoria)

            setConsumo(Math.floor((somatoria * 100) / (dadosAnoAnterior.restante_cc + (dadosAnoAtual.receita_prevista || dadosAnoAnterior.somatoria_receitas_ano))))

            setSaldo((dadosAnoAtual.receita_prevista || dadosAnoAnterior.somatoria_receitas_ano) + dadosAnoAnterior.restante_cc - somatoria)
        } else {
            setInitialValues({
                saldo_ano_anterior: masks.maskApply.currency(0),
                receita_prevista: masks.maskApply.currency(0),
                orcamentos: []
            })

            setOrcado(0)

            setConsumo(0)

            setSaldo(0)
        }
    }

    async function handleCalcularSaldoAnoAnterior() {
        const response = await api.get('conta_sindicato', {
            params: {
                inicio: '2018-12-31',
                fim: `${ano - 1}-12-31`,
                somar_saldo_inicial: 1,
                contas_obsoletas: [14, 20]
            },
            ...authHeaders()
        })

        const saldoTotal = Number(response.reduce((total, current) => total + Number(current.saldo), 0))

        await api.post('orcamento/saldo_ano_anterior', {
            ano,
            saldo: saldoTotal
        }, authHeaders())

        setSaldoAnoAnterior(masks.maskApply.currency(saldoTotal + dadosAnoAnterior.restante_aplicacoes))
    }

    useEffect(() => {
        setup()

        handleCalcularSaldoAnoAnterior()
    }, [])

    function handleReceitaPrevistaChange(receitaValor) {
        const storedBackup = JSON.parse(localStorage.getItem('sindmepa_orcamento'))

        if (storedBackup) {
            localStorage.setItem('sindmepa_orcamento', JSON.stringify({
                ...storedBackup,
                receita_prevista: receitaValor
            }))

            const somatoria = storedBackup.orcamentos.reduce((total, current) => total + masks.unmask.money(current.valor), 0)

            setSaldo(receitaValor + storedBackup.saldo_ano_anterior - somatoria)

            setConsumo(Math.floor((somatoria * 100) / (receitaValor + storedBackup.saldo_ano_anterior)))
        } else {
            const somatoria = dadosAnoAtual.orcamentos.filter(o => o.analitica && o.orcado).reduce((total, current) => total + masks.unmask.money(current.orcado), 0)

            setSaldo(receitaValor + dadosAnoAnterior.restante_cc - orcado)

            setConsumo(Math.floor((somatoria * 100) / (receitaValor + dadosAnoAnterior.restante_cc)))

            const backupObj = {
                receita_prevista: receitaValor,
                saldo_ano_anterior: dadosAnoAnterior.restante_cc,
                ano,
                orcamentos: dadosAnoAtual.orcamentos.filter(o => o.analitica && o.orcado).map(o => {
                    const alerta_1 = o.alertas.find(a => a.nivel === 1)?.percentual
                    const alerta_2 = o.alertas.find(a => a.nivel === 2)?.percentual
                    const alerta_3 = o.alertas.find(a => a.nivel === 3)?.percentual

                    return {
                        valor: o.orcado,
                        alerta_1: alerta_1 ? masks.maskApply.percent(alerta_1) : '',
                        alerta_2: alerta_2 ? masks.maskApply.percent(alerta_2) : '',
                        alerta_3: alerta_3 ? masks.maskApply.percent(alerta_3) : '',
                        conta: { label: o.descricao, value: o.conta_id }
                    }
                })
            }

            localStorage.setItem('sindmepa_orcamento', JSON.stringify(backupObj))
        }
    }

    function handleSomaOrcado(values) {
        const storedBackup = JSON.parse(localStorage.getItem('sindmepa_orcamento'))

        if (storedBackup) {
            const somatoria = values.orcamentos.reduce((total, current) => total + masks.unmask.money(current.valor), 0)

            setOrcado(somatoria)

            setConsumo(Math.floor((somatoria * 100) / (storedBackup.receita_prevista + storedBackup.saldo_ano_anterior)))

            setSaldo(storedBackup.receita_prevista + storedBackup.saldo_ano_anterior - somatoria)

            const backupObj = {
                receita_prevista: storedBackup.receita_prevista || dadosAnoAnterior.somatoria_receitas_ano,
                saldo_ano_anterior: storedBackup.saldo_ano_anterior,
                ano,
                orcamentos: values.orcamentos.filter(o => o.conta && o.valor).sort(arrayUtils.sort.comparisonFunction2Levels('conta', 'label', 'asc'))
            }

            localStorage.setItem('sindmepa_orcamento', JSON.stringify(backupObj))
        } else {
            const somatoria = values.orcamentos.reduce((total, current) => total + masks.unmask.money(current.valor), 0)

            setOrcado(somatoria)

            setConsumo(Math.floor((somatoria * 100) / (dadosAnoAnterior.somatoria_receitas_ano + dadosAnoAnterior.restante_cc)))

            setSaldo((dadosAnoAtual.receita_prevista || dadosAnoAnterior.somatoria_receitas_ano) + dadosAnoAnterior.restante_cc - somatoria)

            const backupObj = {
                receita_prevista: dadosAnoAtual.receita_prevista || dadosAnoAnterior.somatoria_receitas_ano,
                saldo_ano_anterior: dadosAnoAnterior.restante_cc,
                ano,
                orcamentos: values.orcamentos.filter(o => o.conta && o.valor).sort(arrayUtils.sort.comparisonFunction2Levels('conta', 'label', 'asc'))
            }

            localStorage.setItem('sindmepa_orcamento', JSON.stringify(backupObj))
        }
    }

    async function handleCadastrar(values) {
        try {
            values = formUtils.extractFormValues(values)

            values = {
                ano,
                receita_prevista: masks.unmask.money(values.receita_prevista),
                saldo_ano_anterior: masks.unmask.money(values.saldo_ano_anterior),
                contas: values.orcamentos.filter(v => v?.valor && v?.conta).map(v => ({
                    conta: v.conta.value,
                    valor: masks.unmask.money(v.valor),
                    alerta_1: masks.unmask.percent(v.alerta_1),
                    alerta_2: masks.unmask.percent(v.alerta_2),
                    alerta_3: masks.unmask.percent(v.alerta_3)
                }))
            }

            await api.post('orcamento', values, authHeaders())

            toast.success('Orçamento cadastrado.')

            setShowCadastro(false)

            reload()

            localStorage.removeItem('sindmepa_orcamento')
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleClearStorage() {
        localStorage.removeItem('sindmepa_orcamento')

        setConfirmClearStorage(null)

        await setup()
    }

    return (
        <>
            <Container>
                {dadosAnoAnterior && dadosAnoAnterior.restante_cc !== null && dadosAnoAnterior.receita_prevista !== null ? (
                    <>
                        {initialValues && (
                            <Formik
                                onSubmit={handleCadastrar}
                                initialValues={initialValues}
                                enableReinitialize
                            >
                                {({ values, setFieldValue, isSubmitting }) => {
                                    const anoAnterior = Number(ano) - 1
                                    const dataSaldo = isBefore(getDateObject(`31/12/${anoAnterior}`), new Date()) ? `em 31/12/${anoAnterior}` : 'atual'

                                    return (
                                        <Form>
                                            <Textbox
                                                name="saldo_ano_anterior"
                                                label={`Saldo ${dataSaldo}`}
                                                containerClass="saldo_anterior"
                                                mask={masks.money}
                                                value={saldoAnoAnterior}
                                                readOnly
                                            />

                                            <Textbox
                                                name="receita_prevista"
                                                label="Receita prevista"
                                                mask={masks.money}
                                                containerClass="receita"
                                                onBlur={e => {
                                                    handleReceitaPrevistaChange(masks.unmask.money(e.target.value))
                                                }}
                                            />

                                            <span className="orcado">
                                                Somatória orçado:
                                                {' '}
                                                <b>{masks.maskApply.currency(orcado)}</b>
                                            </span>

                                            <span style={{ textAlign: 'right' }} className="saldo">
                                                Saldo:
                                                {' '}
                                                <b>{masks.maskApply.currency(saldo)}</b>
                                            </span>

                                            {consumo > 0 ? (
                                                <ProgressoConsumo consumo={consumo}>
                                                    <div className="consumo">{`${consumo}%`}</div>
                                                </ProgressoConsumo>
                                            ) : (
                                                <div />
                                            )}

                                            {consumo > 100 && (
                                                <p className="alerta">Você ultrapassou o consumo da sua receita prevista!</p>
                                            )}

                                            <ContasCorrenteContainer>
                                                {contasCorrentes && (
                                                    <details>
                                                        <summary>
                                                            <FiEye size={16} />
                                                            Saldo atual por conta corrente
                                                        </summary>

                                                        <div>
                                                            <h1>
                                                                {'Saldo geral: '}
                                                                <span className={saldoGeralContasCorrentes < 0 ? 'negativo' : ''}>{masks.maskApply.currency(saldoGeralContasCorrentes)}</span>
                                                            </h1>

                                                            {contasCorrentes.map(c => (
                                                                <div key={c.id}>
                                                                    <h1>{c.dados_bancarios.instituicao.nome_fantasia}</h1>
                                                                    <h2>{`${c.dados_bancarios.agencia || ''} ${c.dados_bancarios.agencia ? '-' : ''} ${c.dados_bancarios.conta}`}</h2>
                                                                    <h3>{masks.maskApply.currency(contasCorrentes.find(cc => cc.id === c.id).saldo)}</h3>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </details>
                                                )}

                                                {contasCorrentes && (
                                                    <details>
                                                        <summary>
                                                            <FiEye size={16} />
                                                            {`Receita total por conta corrente ${Number(ano) - 1}`}
                                                        </summary>

                                                        <div>
                                                            {contasCorrentes.map(c => (
                                                                <div key={c.id}>
                                                                    <h1>{c.dados_bancarios.instituicao.nome_fantasia}</h1>
                                                                    <h2>{`${c.dados_bancarios.agencia || ''} ${c.dados_bancarios.agencia ? '-' : ''} ${c.dados_bancarios.conta}`}</h2>
                                                                    <h3>{dadosAnoAnterior.somatorias_receitas_conta_corrente?.find(r => r.conta === c.id)?.total_masked || ''}</h3>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </details>
                                                )}

                                                <details>
                                                    <summary>
                                                        <FiEye size={16} />
                                                        {`Receitas mês a mês por conta corrente ${Number(ano) - 1}`}
                                                    </summary>

                                                    <div>
                                                        {contasCorrentes && (
                                                            <Table
                                                                headers={[
                                                                    { name: 'mes', value: 'Mês', centered: true },
                                                                    ...contasCorrentes?.map(c => ({
                                                                        name: `conta_${c.id}_masked`,
                                                                        value: `${c.dados_bancarios.instituicao.nome_fantasia} - ${c.dados_bancarios.agencia || ''} ${c.dados_bancarios.conta}`,
                                                                        centered: true
                                                                    }))
                                                                ]}
                                                                data={dadosAnoAnterior.lancamentos_receita_por_mes_por_conta?.map(lanc => ({
                                                                    id: lanc.mes,
                                                                    ...lanc
                                                                })) || []}
                                                                textSize={11}
                                                            />
                                                        )}
                                                    </div>
                                                </details>
                                            </ContasCorrenteContainer>

                                            <p className="tip">{tips.formulario}</p>

                                            {dadosAnoAtual ? (
                                                <FormAdd>
                                                    <FieldArray
                                                        name="orcamentos"
                                                        render={arrayHelpers => (
                                                            <>
                                                                {values.orcamentos?.map((conta, index) => (
                                                                    <OrcamentoItemContainer key={index}>
                                                                        <Select
                                                                            name={`orcamentos.${index}.conta`}
                                                                            label="Conta"
                                                                            options={[
                                                                                conta.conta,
                                                                                ...dadosAnoAtual.contas_contabeis_analiticas
                                                                                    ?.filter(ca => !values.orcamentos.filter(orc => orc.conta).map(orc => orc.conta.value).includes(ca.id))
                                                                                    .map(ca => ({
                                                                                        label: ca.descricao,
                                                                                        value: ca.id
                                                                                    })) || []
                                                                            ].filter(option => !!option)}
                                                                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                                                            no-shadow
                                                                            placeholder="Selecione a Conta Contábil..."
                                                                        />

                                                                        <Textbox
                                                                            name={`orcamentos.${index}.valor`}
                                                                            label="Valor"
                                                                            placeholder="Valor orçado"
                                                                            mask={masks.money}
                                                                            onBlur={() => { handleSomaOrcado(values) }}
                                                                            containerClass="value-field"
                                                                        />

                                                                        <Textbox
                                                                            name={`orcamentos.${index}.alerta_1`}
                                                                            label="Alerta 1"
                                                                            placeholder="Alerta 1"
                                                                            mask={masks.percent}
                                                                        />

                                                                        <Textbox
                                                                            name={`orcamentos.${index}.alerta_2`}
                                                                            label="Alerta 2"
                                                                            placeholder="Alerta 2"
                                                                            mask={masks.percent}
                                                                        />

                                                                        <Textbox
                                                                            name={`orcamentos.${index}.alerta_3`}
                                                                            label="Alerta 3"
                                                                            placeholder="Alerta 3"
                                                                            mask={masks.percent}
                                                                        />

                                                                        <Button onClick={() => { arrayHelpers.remove(index) }} className="transparent" fab>
                                                                            <FiTrash size={16} />
                                                                        </Button>
                                                                    </OrcamentoItemContainer>
                                                                )) || <div />}

                                                                <Button className="transparent" onClick={() => { arrayHelpers.push('') }}>
                                                                    Adicionar orçamento
                                                                </Button>
                                                            </>
                                                        )}
                                                    />
                                                </FormAdd>
                                            ) : (
                                                <div />
                                            )}

                                            <div className="button-container">
                                                <Button className="transparent" type="submit" loading={isSubmitting}>
                                                    Cadastrar orçamento
                                                </Button>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        )}
                    </>
                ) : (
                    <Spinner />
                )}
            </Container>

            <Modal
                isOpen={!!confirmClearStorage}
                handleClose={() => {
                    setConfirmClearStorage(null)
                    setShowCadastro(false)
                }}
                title="Confirmação"
            >
                <ConfirmClearContainer>
                    <div dangerouslySetInnerHTML={{ __html: `Existem ${confirmClearStorage?.orcamentos?.length} orçamento(s) de <b>${confirmClearStorage?.ano}</b> armazenados de forma temporária.<br/ ><br/ >Ao continuar, você perderá estes dados e iniciará a definição de orçamento para o ano de <b>${ano}</b>.` }} />

                    <div className="button-container">
                        <Button
                            className="transparent"
                            onClick={() => {
                                setConfirmClearStorage(null)
                                setShowCadastro(false)
                            }}
                        >
                            Não

                        </Button>
                        <Button className="white" onClick={handleClearStorage}>Sim</Button>
                    </div>
                </ConfirmClearContainer>
            </Modal>
        </>
    )
}
