import styled, { keyframes } from 'styled-components'
import { darken } from 'polished'

const blink = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0.85;
	}
`

export const MenuOptions = styled.ul.attrs({
    className: 'menu-options'
})`
	padding: 2px 0;

	li:first-child {
		button {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}

	li:last-child {
		button {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}

	button {
		margin: 0;
		box-shadow: none;
		border-radius: 0;
		width: 100%;
		justify-content: space-between;
		background-color: #334155;

		&:hover {
			background-color: ${darken(0.05, '#334155')};
		}

		&.loading {
			animation: ${blink} 800ms alternate infinite;

			&:hover {
				box-shadow: none;
			}
		}

		.spinner-container {
			margin-top: 0;
		}

		span {
			margin-right: 10px;
		}
	}


`
