import React, { useContext, useEffect, useState } from 'react'
import {
    FiArrowRight, FiBell, FiCheck, FiX
} from 'react-icons/fi'
import { Form, Formik } from 'formik'

import Modal from '../../../../../components/Modal'
import Spinner from '../../../../../components/Spinner'
import { GlobalContext } from '../../../../../contexts/GlobalContext'
import {
    HeaderContainer, MovimentacaoContainer, BadgeAlerta, BadgeContainer, MensagensContainer, SetoresContainer, Dropdown, FormAlertaContainer, ModalConfirmacaoMovimentacaoContainer
} from './styles'
import {
    Textarea, Button, Select, Checkbox, Textbox
} from '../../../../../components/Form'
import { loadTips } from '../../../../../util/tip'
import Yup from '../../../../../services/yup'
import { TipContext } from '../../../../../contexts/TipContext'
import { ProcessoContext } from '../../../../../contexts/ProcessoContext'

const statusFinalizacao = [
    { label: 'Resolvido', value: 1 },
    { label: 'Parcialmente resolvido', value: 2 },
    { label: 'Não aplicável', value: 3 }
]

const validation = Yup.object({
    mensagem_alerta: Yup.string().required('Informe a mensagem a ser enviada no alerta.'),
    prazo_alerta: Yup.number().required('Informe o prazo do alerta em dias úteis.')
})

export default function () {
    const { user } = useContext(GlobalContext)
    const {
        processo, handleMovimentar, setores, tramite, showModalMovimentacao, setShowModalMovimentacao, showOpcoes, setShowOpcoes, tramiteMovimentacao, handleCriarAlerta, showFormAlerta, setShowFormAlerta, atendentes, acao, setAcao
    } = useContext(ProcessoContext)
    const { setCodigo, tips } = useContext(TipContext)

    const [tramiteEncaminhamento, setTramiteEncaminhamento] = useState(null)
    const [movimentando, setMovimentando] = useState(false)

    const eDemandante = processo?.demandante?.id === user?.pessoa.id
    const eSomenteConvidado = processo?.convidados.some(conv => conv.id === user?.pessoa.id)

    useEffect(() => {
        if (user) {
            loadTips(setCodigo, 'form_processo_movimentacao')
        }
    }, [user])

    if (!processo) {
        return <Spinner label="Carregando dados do chamado..." />
    }

    const existemTramitesNaoFinalizados = processo.tramites.some(tram => tram.status_id !== 2)
    const atendentesExcetoUsuarioAutenticado = atendentes.filter(atend => !tramite?.participantes?.some(p => p.id === atend.id) && atend.id !== user.pessoa.id)
    const eAtendenteAtual = processo.atendente_atual?.id === user?.pessoa.id

    return (
        <>
            <h1>{`Atendimento ${processo.identificador}`}</h1>

            {processo.status_id === 2 && (
                <BadgeContainer>
                    <BadgeAlerta dangerouslySetInnerHTML={{ __html: `Este atendimento já está <b>FINALIZADO</b> com o status <b>${processo.status_finalizacao.toUpperCase()}</b> e não pode receber novas interações.` }} />
                </BadgeContainer>
            )}

            <HeaderContainer>
                <div>
                    <p dangerouslySetInnerHTML={{ __html: `Demandante: <b>${processo.demandante.nome}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Status: <b>${processo.status}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Tipo/assunto: <b>${processo.tipo} / ${processo.assunto}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Data de criação: <b>${processo.created_at}</b>` }} />
                    <p dangerouslySetInnerHTML={{ __html: `Data de finalização: <b>${processo.finished_at}</b>` }} />

                    {processo.status_id !== 2 && !eDemandante && (
                        <p dangerouslySetInnerHTML={{ __html: `Prazo máximo para finalização: <b>${processo.tramites[0].prazo}</b> ${processo.tempo_atraso ? `(${processo.tempo_atraso} de atraso)` : ''}` }} />
                    )}

                    {!eDemandante && (
                        <>
                            {processo.status_id !== 2 && processo.setores_atuais.length > 0 && (
                                <p dangerouslySetInnerHTML={{ __html: `Última movimentação: <b>${processo.tramites.at(-1).origem} ➜ ${processo.setores_atuais.map(setor => setor.descricao).join(', ')}</b>` }} />
                            )}
                            
                            <p dangerouslySetInnerHTML={{ __html: `Movimentações: <b>${processo.quantidade_tramites}</b>` }} />
                        </>
                    )}
                </div>

                {processo.status_id === 3 && eDemandante && (
                    <Button
                        className="transparent"
                        onClick={() => {
                            setShowModalMovimentacao(true)

                            setAcao('cancelar')
                        }}
                    >
                        Cancelar este chamado
                        <FiX size={18} />
                    </Button>
                )}

                {!eDemandante && processo.status_id !== 2 && (
                    <>
                        <Button
                            className="transparent"
                            onClick={() => {
                                setShowOpcoes(old => !old)
                            }}
                        >
                            Opções
                        </Button>

                        {showOpcoes ? (
                            <Dropdown>
                                {!eSomenteConvidado && !existemTramitesNaoFinalizados && (
                                    <div
                                        className="item"
                                        onClick={() => {
                                            setShowModalMovimentacao(true)

                                            setAcao('movimentar_processo')

                                            setShowOpcoes(false)
                                        }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <span className="info">Movimentar</span>

                                        <FiArrowRight size={20} />
                                    </div>
                                )}

                                {processo.status_id !== 2 && (
                                    <div
                                        className="item"
                                        onClick={() => {
                                            setShowFormAlerta(true)

                                            setShowOpcoes(false)
                                        }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <span className="info">Criar alerta</span>

                                        <FiBell size={20} />
                                    </div>
                                )}

                                {processo.tramites.every(t => t.status_id === 2) && !eSomenteConvidado && eAtendenteAtual && (
                                    <div
                                        className="item"
                                        onClick={() => {
                                            setShowModalMovimentacao(true)

                                            setAcao('finalizar')

                                            setShowOpcoes(false)
                                        }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <span className="info">Finalizar atendimento</span>

                                        <FiCheck size={20} />
                                    </div>
                                )}
                            </Dropdown>
                        ) : null}
                    </>
                )}
            </HeaderContainer>

            <Modal
                isOpen={showModalMovimentacao}
                handleClose={() => {
                    setShowModalMovimentacao(false)

                    setAcao(null)

                    setTimeout(() => { setAcao(null) }, 1000)
                }}
                title="Opções"
            >
                <MovimentacaoContainer>
                    <Formik
                        onSubmit={values => setTramiteEncaminhamento({ ...values, acao })}
                        initialValues={{
                            destino_id: null,
                            status_finalizacao: null,
                            tramite_id: tramite?.id,
                            prazo: ''
                        }}
                    >
                        {({
                            setFieldValue, values
                        }) => (
                            <Form>
                                {acao === 'finalizar' ? (
                                    <p>Finalize o atendimento, informando se foi resolvido e inserindo uma mensagem final.</p>
                                ) : acao === 'cancelar' ? (
                                    <p>Informe o motivo para cancelamento do seu atendimento, em seguida, clique em Cancelar o chamado.</p>
                                ) : (
                                    <p>{tips.formulario}</p>
                                )}

                                <div className="selecao-acao">
                                    {!['cancelar', 'finalizar', 'movimentar_processo'].includes(acao) && (
                                        <>
                                            {tramiteMovimentacao?.pessoa_origem?.id !== user?.pessoa.id && !eSomenteConvidado && (
                                                <Button
                                                    onClick={() => {
                                                        setAcao('movimentar')
                                                    }}
                                                    className={acao === 'movimentar' ? 'white' : 'transparent'}
                                                >
                                                    Movimentar o atendimento
                                                    <span className="tip">
                                                        Clique para movimentar este atendimento para que outro grupo dê continuidade no mesmo.
                                                    </span>
                                                </Button>
                                            )}

                                            {tramiteMovimentacao?.id !== processo.tramites?.[0]?.id && (
                                                <Button
                                                    onClick={() => {
                                                        setAcao('convidar')
                                                    }}
                                                    className={acao === 'convidar' ? 'white' : 'transparent'}
                                                >
                                                    Convidar pessoas
                                                    <span className="tip">
                                                        Clique para convidar pessoas do sindicato para contribuir com este trâmite.
                                                    </span>
                                                </Button>
                                            )}

                                            {tramiteMovimentacao?.pessoa_origem.id !== user?.pessoa.id && !eSomenteConvidado && (
                                                <Button
                                                    onClick={() => {
                                                        setAcao('finalizar_tramite')
                                                    }}
                                                    className={['finalizar', 'finalizar_tramite'].includes(acao) ? 'white' : 'transparent'}
                                                >
                                                    Finalizar a tramitação
                                                    <span className="tip">
                                                        Clique para finalizar esta tramitação.
                                                    </span>
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </div>

                                {['movimentar_processo', 'movimentar'].includes(acao) ? (
                                    <>
                                        <Select
                                            name="destino_id"
                                            label="Destino"
                                            options={setores.filter(item => item.id !== tramiteMovimentacao?.destino.id)
                                                .map(item => ({
                                                    label: item.descricao,
                                                    value: item.id
                                                }))}
                                            onChange={(selected, meta) => {
                                                setFieldValue(meta.name, selected)
                                            }}
                                            isMulti
                                        />

                                        <MensagensContainer>
                                            {values.destino_id && values.destino_id.map(dest => (
                                                <SetoresContainer>
                                                    <Textarea
                                                        label={`Mensagem para ${dest.label}`}
                                                        name={`mensagem_${dest.value}`}
                                                    />

                                                    <Textbox
                                                        name={`prazo_${dest.value}`}
                                                        label="Prazo de resposta (dias úteis)"
                                                        type="number"
                                                    />

                                                    {dest.value === 7 && (
                                                        <>
                                                            {!processo?.apto_demanda_juridica ? (
                                                                <p className="tip">Obs.: Não é possível iniciar uma demanda jurídica para o demandante, pois sua contribuição social tem pendências.</p>
                                                            ) : processo?.demandante.usuario.perfil_id !== 25 ? (
                                                                <Checkbox
                                                                    name="abrir_demanda"
                                                                    label="Abrir uma demanda jurídica em paralelo"
                                                                    handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                                                />
                                                            ) : (
                                                                <p className="tip">Obs.: Não é possível iniciar uma demanda jurídica para o demandante, pois este não é associado.</p>
                                                            )}
                                                        </>
                                                    )}
                                                </SetoresContainer>
                                            ))}
                                        </MensagensContainer>

                                        <Button
                                            type="submit"
                                            className="transparent"
                                        >
                                            Movimentar atendimento
                                            <FiArrowRight size={18} />
                                        </Button>
                                    </>
                                ) : acao === 'finalizar' ? (
                                    <>
                                        <Select
                                            name="status_finalizacao"
                                            label="Status da finalização"
                                            options={statusFinalizacao}
                                            onChange={(selected, meta) => {
                                                setFieldValue(meta.name, selected)
                                            }}
                                        />

                                        <Textarea
                                            name="mensagem"
                                            label="Mensagem"
                                        />

                                        <Button
                                            type="submit"
                                            className="transparent"
                                        >
                                            Finalizar chamado
                                            <FiCheck size={18} />
                                        </Button>
                                    </>
                                ) : acao === 'finalizar_tramite' ? (
                                    <>
                                        <Textarea
                                            name="mensagem"
                                            label="Mensagem"
                                        />

                                        <Button
                                            type="submit"
                                            className="transparent"
                                        >
                                            Finalizar a tramitação
                                            <FiCheck size={18} />
                                        </Button>
                                    </>
                                ) : acao === 'cancelar' ? (
                                    <>
                                        <Textarea
                                            name="mensagem"
                                            label="Mensagem"
                                        />

                                        <Button
                                            type="submit"
                                            className="transparent"
                                        >
                                            Cancelar o chamado
                                            <FiCheck size={18} />
                                        </Button>
                                    </>
                                ) : acao === 'convidar' ? (
                                    <div style={{ display: 'flex', gap: 4 }}>
                                        <Select 
                                            name="convidado"
                                            label="Convidado"
                                            onChange={(selected, meta) => {
                                                setFieldValue(meta.name, selected)
                                            }}
                                            options={atendentesExcetoUsuarioAutenticado.map(atend => ({
                                                value: atend.id,
                                                label: atend.nome
                                            }))}
                                        />

                                        <Button className="transparent" type="submit">
                                            Convidar
                                            <FiArrowRight size={16} />
                                        </Button>
                                    </div>
                                ) : null}
                            </Form>
                        )}
                    </Formik>
                </MovimentacaoContainer>
            </Modal>

            <Modal
                isOpen={!!tramiteEncaminhamento}
                title="Confirmação"
                handleClose={() => {
                    setTramiteEncaminhamento(null)
                }}
            >
                <ModalConfirmacaoMovimentacaoContainer>
                    {['movimentar_processo', 'movimentar'].includes(acao) ? (
                        <p dangerouslySetInnerHTML={{ __html: `Deseja realmente encaminhar o atendimento para ${tramiteEncaminhamento?.destino_id.length === 1 ? 'o grupo' : 'os grupos'} <b>${setores.filter(s => tramiteEncaminhamento?.destino_id.map(i => i.value).includes(s.id)).map(i => i.descricao).join(', ')}</b>?` }} />
                    ) : acao?.includes('finalizar') ? (
                        <p>Deseja realmente finalizar este trâmite?</p>
                    ) : acao === 'convidar' ? (
                        <p>Deseja realmente convidar esta pessoa para participar do trâmite?</p>
                    ) : (
                        <p>Deseja realmente cancelar este chamado?</p>
                    )}

                    <div className="button-container">
                        <Button
                            onClick={() => {
                                setTramiteEncaminhamento(null)
                            }}
                            className="transparent"
                            disabled={movimentando}
                        >
                            Não
                        </Button>

                        <Button
                            onClick={async () => {
                                setMovimentando(true)

                                await handleMovimentar(tramiteEncaminhamento)

                                setTramiteEncaminhamento(null)
                                setMovimentando(false)
                            }}
                            className="white"
                            loading={movimentando}
                        >
                            Sim
                        </Button>
                    </div>
                </ModalConfirmacaoMovimentacaoContainer>
            </Modal>

            <Modal
                isOpen={showFormAlerta}
                handleClose={() => {
                    setShowFormAlerta(false)
                }}
                title="Novo alerta"
            >
                <Formik
                    initialValues={{
                        mensagem_alerta: '',
                        prazo_alerta: ''
                    }}
                    onSubmit={handleCriarAlerta}
                    validationSchema={validation}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <FormAlertaContainer>
                                <Textbox
                                    name="prazo_alerta"
                                    label="Prazo em dias úteis"
                                    type="number"
                                />

                                <Textbox
                                    name="titulo_alerta"
                                    label="Título do alerta"
                                    containerClass="input-titulo"
                                />

                                <Textarea
                                    name="mensagem_alerta"
                                    label="Mensagem de alerta"
                                />

                                <Button className="transparent" type="submit" loading={isSubmitting}>
                                    Criar alerta
                                    <FiCheck size={16} />
                                </Button>
                            </FormAlertaContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
