import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'

import formUtils from '../../../../../util/form'
import { forceDownloadFileFromURL } from '../../../../../util/file'

import { Button, Select } from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

const validation = Yup.object().shape({
    eleicao: Yup.string().ensure().required('Selecione uma eleição.')
})

const nomeRelatorio = 'Lista de Presença de Eleição'

export default function (props) {
    const [eleicoes, setEleicoes] = useState(null)

    async function loadEleicoes() {
        const response = await api.get('lista_eleicao', authHeaders())

        setEleicoes(response)
    }

    async function handleSubmit(values, { resetForm }) {
        try {
            values = formUtils.extractFormValues(values)

            const response = await api.post('relatorio/listaPresenca', values, authHeaders())

            forceDownloadFileFromURL(response.file, nomeRelatorio)

            resetForm()
        } catch (e) {
            toast.error('Erro ao gerar o relatório.')
        }
    }

    useEffect(() => {
        loadEleicoes()
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={{ eleicao: null }}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, resetForm, setFieldValue
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        {eleicoes ? (
                            <>
                                <Select
                                    name="eleicao"
                                    label="Selecione a eleição"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={eleicoes.map(eleicao => ({
                                        label: eleicao.descricao,
                                        value: eleicao.id
                                    }))}
                                />

                                <ButtonContainer>
                                    <Button
                                        onClick={resetForm}
                                        className="transparent"
                                    >
                                        Limpar
                                    </Button>

                                    <Button
                                        type="submit"
                                        loading={isSubmitting}
                                        className="white"
                                    >
                                        Gerar lista
                                        <FiFile size={20} />
                                    </Button>
                                </ButtonContainer>
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
