import React, { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FiPlus } from 'react-icons/fi'
import { FaRegSave } from 'react-icons/fa'

import { Textbox, Button, Select } from '../../components/Form'

import { api, authHeaders } from '../../services/api'
import Yup from '../../services/yup'

import formUtils from '../../util/form'
import masks from '../../util/masks'

import { Container } from './styles'

const baseInitialValues = {
    tipo_contato: { value: '', label: '' },
    valor_contato: ''
}

export default function ({
    reload, style, contatoInitialValues, editId, pessoa, ...rest
}) {
    const [validacao, setValidacao] = useState(null)
    const [tipoContato, setTipoContato] = useState([])
    const [initialValues, setInitialValues] = useState(baseInitialValues)

    useEffect(() => {
        setInitialValues(contatoInitialValues || baseInitialValues)
    }, [contatoInitialValues])

    useEffect(() => {
        async function loadTipoContato() {
            const response = await api.get('tipo_contato')

            setTipoContato(response)
        }

        loadTipoContato()
    }, [])

    const getMascaraContato = useCallback(tipo => {
        switch (tipo) {
            case 1: case 2:
                return masks.phone
            case 3: case 4: case 5:
                return masks.mobile
            default:
                return null
        }
    }, [])

    async function adicionar(values, { setSubmitting, resetForm }) {
        const data = formUtils.extractFormValues(values)

        await api.post('contato', {
            pessoa_id: pessoa.id,
            tipo_contato_id: data.tipo_contato,
            contato: data.valor_contato
        })

        reload()

        setSubmitting(false)
        resetForm()
    }

    async function editar(values, { setSubmitting, resetForm }) {
        const data = formUtils.extractFormValues(values)

        await api.put(`contato/${editId}`, {
            tipo_contato_id: data.tipo_contato,
            contato: data.valor_contato
        }, authHeaders())

        reload()

        setSubmitting(false)
        resetForm()
    }

    const handleTipoContatoChange = useCallback((selected, setFieldValue) => {
        setFieldValue('tipo_contato', selected)

        const tipoId = selected.value
        let validacaoValorContato

        switch (tipoId) {
            case 1: case 2:
                validacaoValorContato = Yup.string().phone()
                break
            case 3: case 4: case 5:
                validacaoValorContato = Yup.string().mobile()
                break
            case 6:
                validacaoValorContato = Yup.string().email('E-mail inválido.')
                break
            default:
                validacaoValorContato = Yup.string()
        }

        setValidacao(Yup.object({
            tipo_contato: Yup.object({ value: Yup.number(), label: Yup.string() }).required('Selecione um tipo.'),
            valor_contato: validacaoValorContato.concat(Yup.string().required('Defina o valor.'))
        }))
    }, [])

    return (
        <Container style={style} edit-mode={!!editId} {...rest}>
            <Formik
                initialValues={initialValues}
                validationSchema={validacao}
                onSubmit={editId ? editar : adicionar}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="contato-form">
                        <Select
                            name="tipo_contato"
                            label="Tipo de contato"
                            onChange={selected => handleTipoContatoChange(selected, setFieldValue)}
                            options={tipoContato.map(tipo => ({
                                label: tipo.descricao,
                                value: tipo.id
                            }))}
                        />

                        <Textbox
                            name="valor_contato"
                            label={values.tipo_contato.value === 6 ? 'Endereço' : 'Número'}
                            mask={getMascaraContato(values.tipo_contato.value)}
                            disabled={!values.tipo_contato.value}
                        />

                        <Button type="submit" loading={isSubmitting} className="transparent">
                            {editId ? (
                                <>
                                    Salvar alterações
                                    <FaRegSave />
                                </>
                            ) : (
                                <>
                                    Adicionar
                                    <FiPlus />
                                </>
                            )}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
