import styled from 'styled-components'

export const Container = styled.article`
	min-height: 74vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px 0 40px;

	img {
		width: 100%;
		max-width: 250px;
		margin-bottom: 14px;
		margin-top: 15px;
	}
`

export const ModalNotificacaoContainer = styled.div`
	display: grid;
	margin-top: 24px;
	grid-template-columns: 280px auto;
	grid-template-areas: 
		'syndi texto'
		'syndi button';

	> div {
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		padding-bottom: 20px;
	}

	img {
		grid-area: syndi;
		object-fit: contain;
		width: 220px;
		justify-self: center;
	}

	.button-container {
		display: flex;
		justify-content: center;

		.button {
			width: fit-content;
		}
	}

	@media(max-width: 770px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 0;

		img {
			width: 90px;
		}
		
		> div {
			font-size: 14px;
			padding-top: 20px;
			line-height: 18px;
		}
	}
`

export const ModalPendenciasContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	min-height: 80vh;
	grid-gap: 14px;

	p:first-child {
		margin-top: 20px;
	}

	p {
		margin: 8px;
		grid-column: 1/span 2;
	}
	
	.table-container {
		width: 100%;
		align-self: center;
		margin: 18px 0;
		align-self: flex-start;
		justify-self: center;
		margin: 0 8px;
	}

	.spinner-container {
		grid-column: 1/span 2;
	}

	.button-container {
		display: flex;
		justify-content: center;
		margin-bottom: 24px;
		grid-column: 1/span 2;

		a {
			width: fit-content;
		}
	}

	@media(max-width: 890px) {
		display: flex;
		flex-direction: column;

		.table-container {
			align-self: center;
			max-width: 500px;
		}
	}
`

export const PendenciasContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	button {
		width: fit-content;
		margin-top: 14px;
	}

	@media(max-width: 565px) {
		button {
			display: none;
		}
	}
`

export const ModalEnqueteContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 16px 8px 0;

	> div {
		display: flex;
		gap: 12px;
		justify-content: end;
	}
`

export const ModalEleicaoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 16px 8px 0;

	> div {
		display: flex;
		gap: 12px;
		justify-content: end;
	}
`
