import React, { useState, useEffect, useCallback } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import {
    FaSortDown, FaSortUp, FaChevronDown, FaSearch, FaEyeSlash, FaTrash, FaEdit, FaTimes, FaEye
} from 'react-icons/fa'
import * as icons from 'react-icons/fa'
import { toast } from 'react-toastify'

import { Textbox, Button } from '../Form'
import Modal from '../Modal'
import SmallScreenPlaceholder from '../SmallScreenPlaceholder'

import arrayUtils from '../../util/array'
import { stringNomalize } from '../../util/string'

import {
    TableContainer, Table, EmptyContainer, ModalDeleteContainer, PaginationContainer, ActionCheckboxContainer, Badge, OptionsContainer
} from './styles'
import Spinner from '../Spinner'

export default function (props) {
    const {
        headers = [],
        footers = [],
        showId,
        data = [],
        limit,
        filterable = false,
        copiable = false,
        orderable = true,
        truncateLength,
        hideOnSmallHeaders = [],
        hideHeadersOffset = 760,
        hideOffset = 600,
        className,
        style,
        tableStyle,
        textSize = 13,
        handleDelete,
        handlePrepareEdit,
        actions = [],
        keyProp = 'id',
        filterPosition = 'right',
        emptyLabel = 'Nenhum item encontrado',
        confirmExclusion = false,
        caption,
        disableTooltips = false,
        checkable = false,
        checkedItems = [],
        checkedReadonlyItems = [],
        checkboxActions = [],
        tableId,
        onCheckItem = () => { },
        onUncheckItem = () => { },
        toggleCheckAll = () => { },
        confirmUncheck = false,
        pagination = true,
        itemsByPage = 20,
        legends
    } = props

    let showReadMore = false

    const [matchedItems, setMatchedItems] = useState(data)
    const [visibleItems, setVisibleItems] = useState(data)
    const [sortDirection, setSortDirection] = useState('asc')
    const [limitData, setLimitData] = useState(limit || data.length)
    const [search, setSearch] = useState('')
    const [itemToDelete, setItemToDelete] = useState(null)
    const [checked, setChecked] = useState(checkedItems)
    const [isCheckedAll, setIsCheckedAll] = useState(false)
    const [itemToUncheck, setItemToUncheck] = useState(null)
    const [initialPageIndex, setInitialPageIndex] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [confirmationCheckboxDelete, setConfirmationCheckboxDelete] = useState(null)
    const [actionConfirmation, setActionConfirmation] = useState(null)
    const [runningActionId, setRunningActionId] = useState(null)

    function reloadPage() {
        const pagedData = matchedItems.slice(initialPageIndex, initialPageIndex + itemsByPage)

        setCurrentPage(initialPageIndex / itemsByPage + 1)

        setVisibleItems(pagedData)
    }

    const sortData = useCallback(columnName => {
        const newDirection = sortDirection === 'asc' ? 'desc' : 'asc'

        setMatchedItems(matchedItems.sort(arrayUtils.sort.comparisonFunction(columnName, newDirection)))

        setSortDirection(newDirection)
    }, [sortDirection, matchedItems])

    useEffect(() => {
        reloadPage()
    }, [sortDirection])

    const expandData = useCallback(() => {
        setLimitData(data.length)
    }, [data.length])

    useEffect(() => {
        setLimitData(data.length)
    }, [data])

    useEffect(() => {
        if (handleDelete && data.length && data[0][keyProp] === undefined) {
            console.error(`The table's data must have the '${keyProp}' attribute.`)
        }
    }, [handleDelete, data])

    useEffect(() => {
        if (!search) {
            if (pagination) {
                setMatchedItems(data)

                const pageCount = Math.ceil(data.length / itemsByPage)

                setTotalPages(pageCount || 1)
            } else {
                setVisibleItems(data)
            }
        } else {
            let matched = []
            const headerNames = headers.map(header => header.name)

            headerNames.forEach(headerName => {
                const filtered = data.filter(item => item[headerName] && stringNomalize(item[headerName]).includes(stringNomalize(search)))
                matched = arrayUtils.merge.mergeWithoutDuplicates(matched, filtered)
            })

            setMatchedItems(matched)

            const pageCount = Math.ceil(matched.length / itemsByPage)

            setTotalPages(pageCount)
        }
    }, [search, headers, data])

    useEffect(() => {
        setInitialPageIndex(0)
    }, [matchedItems])

    useEffect(() => {
        reloadPage()
    }, [initialPageIndex, matchedItems])

    const handleDeleteItem = useCallback(async (id, confirmed = false) => {
        if (!!confirmExclusion && !confirmed) {
            setItemToDelete(data.find(item => item[keyProp] === id))
            return
        }

        const index = data.findIndex(item => item[keyProp] === id)

        const deleted = await handleDelete(id, index)

        if (typeof deleted !== 'boolean') {
            throw new Error('handleDelete function must return a boolean.')
        }

        if (deleted) {
            setVisibleItems(visibleItems.filter(item => item[keyProp] !== id))
        }
    }, [visibleItems, handleDelete, data, confirmExclusion, keyProp])

    const handleEditItem = useCallback(async (id) => {
        await handlePrepareEdit(id)
    }, [handlePrepareEdit])

    function handleCheckAll(status) {
        setIsCheckedAll(status)

        if (status) {
            setChecked(data.map(i => i[keyProp]))

            for (const itemId of data.map(i => i[keyProp])) {
                const cb = document.querySelector(`#${tableId} tbody tr td:first-child > input[type=checkbox].check-${itemId}`)

                if (cb) {
                    cb.checked = true
                }
            }
        } else {
            setChecked([])

            for (const itemId of data.map(i => i[keyProp])) {
                const cb = document.querySelector(`#${tableId} tbody tr td:first-child > input[type=checkbox].check-${itemId}`)

                if (cb) {
                    cb.checked = false
                }
            }
        }

        toggleCheckAll(status)
    }

    function handleUncheck(item) {
        setChecked(checked.filter(i => i !== item[keyProp]))

        onUncheckItem(item, false)
    }

    function handleToggleCheck(item, isChecked) {
        if (isChecked) {
            setChecked([
                ...checked,
                item[keyProp]
            ])

            onCheckItem(item, isChecked)
        } else if (confirmUncheck) {
            setItemToUncheck(item)
        } else {
            handleUncheck(item)
        }
    }

    async function paginate(direction) {
        switch (direction) {
            case '>':
                if (currentPage === Math.ceil(data.length / itemsByPage)) {
                    break
                }

                setInitialPageIndex(initialPageIndex + itemsByPage)
                break

            case '<':
                if (initialPageIndex === 0) {
                    break
                }

                setInitialPageIndex(initialPageIndex - itemsByPage)
                break

            default: break
        }
    }

    async function handleActionClick(action, item) {
        if (!action.no_preload) {
            item.executing = true
        }

        if (action.checkDisabled?.(item) || !action.action) {
            return
        }

        if (action.confirmation) {
            setActionConfirmation({
                text: action.confirmation,
                handle: async i => {
                    setRunningActionId(i[keyProp])

                    await action.action(i, action.name)

                    setRunningActionId(null)
                },
                item,
                badge: action.badge
            })

            item.executing = false

            return
        }

        setRunningActionId(item[keyProp])

        try {
            await action.action(item, action.name)

            await new Promise((r) => setTimeout(r, 800)).then(() => { })
        } catch (e) {
            toast.error('Ocorreu um erro ao executar a ação.')
        }

        item.executing = false

        setRunningActionId(null)
    }

    function getActionTooltip(action, item) {
        if (action.noTooltip) {
            return null
        }

        if (action.checkDisabled?.(item)) {
            if (action.disabledTooltip) {
                if (typeof action.disabledTooltip === 'function') {
                    return action.disabledTooltip(item)
                }
                return action.disabledTooltip
            } if (action.name) {
                if (typeof action.name === 'function') {
                    return action.name(item)
                }
            }
        }

        return action.name
    }

    function getHighlightClasses(hightlightConfig) {
        const { columns, format, level } = hightlightConfig

        if (!columns || !format || !level) {
            throw new Error('columns, format ou level não foram especificados no atributo cellHighlight da Table.')
        }

        let classes = 'highlight-cell'

        switch (format) {
            case 'background':
                classes += ' highlight-background'
                break
            case 'font-color':
                classes += ' highlight-font-color'
                break
            case 'bold':
                classes += ' highlight-bold'
                break
            default:
                classes = ''
                break
        }

        switch (level) {
            case 'warn':
                classes += ' highlight-level-warn'
                break
            case 'error':
                classes += ' highlight-level-error'
                break
            case 'success':
                classes += ' highlight-level-success'
                break
            default:
                classes += ' highlight-level-info'
                break
        }

        return classes
    }

    return (
        <>
            <TableContainer
                className={className}
                style={style || {}}
                filterPosition={filterPosition}
                hideOffset={hideOffset}
            >
                {caption && (
                    <h1 className="caption">{caption}</h1>
                )}

                <OptionsContainer>
                    {legends && (
                        <div className="legends-container">
                            {legends?.map(legend => (
                                <div className="table-legend" key={legend.text}>
                                    <div className="table-legend-square" style={{ backgroundColor: legend.color }} />
                                    {legend.text}
                                </div>
                            ))}
                        </div>
                    )}

                    {filterable && (
                        <Textbox
                            withoutForm
                            label=""
                            placeholder="Faça sua busca aqui..."
                            icon={{ source: FaSearch }}
                            onChange={e => setSearch(e.target.value)}
                            value={search}
                            onKeyUp={e => {
                                if (e.keyCode === 27) {
                                    e.target.value = ''
                                    setSearch('')
                                }
                            }}
                            containerClass="search-textbox"
                            searchTooltip="Faça sua busca por qualquer coluna da tabela (uma por vez)."
                        />
                    )}
                </OptionsContainer>

                {checkboxActions.length > 0 && (
                    <ActionCheckboxContainer>
                        {checked.length > 0 && checkboxActions.map(action => {
                            const Icon = action.icon

                            return (
                                <Button
                                    fab={action.display !== 'button'}
                                    onClick={() => {
                                        if (action.confirmationMessage) {
                                            setConfirmationCheckboxDelete(action)
                                        } else {
                                            action.handle(checked)
                                        }
                                    }}
                                    className="button-delete-checkeds-container transparent animated zoomIn faster"
                                    tooltip={action.display !== 'button' ? action.tooltip : null}
                                    loading={action.loading}
                                >
                                    {action.display !== 'button' ? (
                                        <Icon />
                                    ) : (
                                        <>
                                            {action.text}
                                            <Icon />
                                        </>
                                    )}
                                </Button>
                            )
                        })}
                    </ActionCheckboxContainer>
                )}

                {visibleItems?.length ? (
                    <>
                        <Table
                            cellSpacing="0"
                            orderable={orderable}
                            style={tableStyle || {}}
                            withFooter={footers.length > 0}
                            id={tableId || null}
                            hideHeadersOffset={hideHeadersOffset}
                            textSize={textSize}
                        >
                            <thead>
                                <tr>
                                    {checkable && (
                                        <th className="check-column">
                                            <input
                                                type="checkbox"
                                                className="select-all"
                                                onChange={e => handleCheckAll(e.target.checked)}
                                                checked={isCheckedAll}
                                            />
                                        </th>
                                    )}

                                    {showId && (
                                        <th
                                            onClick={orderable ? () => sortData('id') : null}
                                            className={hideOnSmallHeaders?.includes('id') ? 'hide-on-small' : ''}
                                            style={{ width: 50 }}
                                        >
                                            ID
                                            {orderable && (
                                                <>
                                                    {sortDirection === 'asc' ? (
                                                        <FaSortUp size={14} />
                                                    ) : (
                                                        <FaSortDown size={14} />
                                                    )}
                                                </>
                                            )}
                                        </th>
                                    )}

                                    {headers.map(header => (
                                        <th
                                            key={header.name}
                                            onClick={orderable ? () => sortData(header.name) : null}
                                            style={header.style}
                                            className={hideOnSmallHeaders?.includes(header.name) ? 'hide-on-small' : ''}
                                        >
                                            {header.value}

                                            {orderable && (
                                                <>
                                                    {sortDirection === 'asc' ? (
                                                        <FaSortUp size={14} />
                                                    ) : (
                                                        <FaSortDown size={14} />
                                                    )}
                                                </>
                                            )}
                                        </th>
                                    ))}

                                    {handleDelete && (<th className="action-icon">{' '}</th>)}

                                    {handlePrepareEdit && (<th className="action-icon">{' '}</th>)}

                                    {actions.length > 0 && actions.map(action => (
                                        <th key={action.name} className="action-icon">{action.headerTitle || ''}</th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {visibleItems.map((item, index) => {
                                    if (limitData && index >= limitData) {
                                        showReadMore = true
                                        return false
                                    }

                                    return (
                                        <tr
                                            key={item[keyProp]}
                                            className={`${item.highlight ? 'animated fadeInRight faster' : ''} ${item.checkbox?.tooltip ? 'tooltip-elem' : ''} ${item.rowClass || ''}`}
                                        >
                                            {checkable && (
                                                <td style={{ padding: 0 }}>
                                                    {item.checkbox?.disabled ? (
                                                        <span className={item.checkbox.tooltip ? `tooltip ${item.checkbox.fullWidth ? 'full-width' : ''}` : ''} tip={item.checkbox.tooltip || null}>
                                                            <FaTimes color="#f44336" />
                                                        </span>
                                                    ) : (
                                                        <input
                                                            type="checkbox"
                                                            onChange={e => {
                                                                handleToggleCheck(item, e.target.checked)
                                                            }}
                                                            checked={checked.includes(item[keyProp])}
                                                            className={`check-${item[keyProp]}`}
                                                            disabled={checkedReadonlyItems.includes(item[keyProp])}
                                                        />
                                                    )}
                                                </td>
                                            )}

                                            {showId && <td className={hideOnSmallHeaders?.includes('id') ? 'hide-on-small' : ''}>{item[keyProp]}</td>}

                                            {headers.map((header, headerIndex) => {
                                                const value = item[header.name]

                                                return value !== undefined && value !== null ? (
                                                    <td
                                                        key={Math.ceil(Math.random() * 10 ** 10)}
                                                        className={`data ${hideOnSmallHeaders?.includes(header.name) ? 'hide-on-small' : ''} ${header.icon && 'icon'} ${item.cellHighlight?.columns?.includes(headerIndex) ? getHighlightClasses(item.cellHighlight) : ''}`}
                                                        style={{
                                                            textAlign: typeof value === 'number' || header.icon || header.link || header.centered ? 'center' : 'start',
                                                            cursor: copiable ? 'pointer' : 'default',
                                                            backgroundColor: item.background || null,
                                                            textIndent: header.enableIndent ? item.indent : 'unset'
                                                        }}
                                                    >
                                                        {copiable ? (
                                                            <CopyToClipboard
                                                                text={value}
                                                                onCopy={() => toast.info('Copiado!', { autoClose: 1100 })}
                                                            >
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            !truncateLength
                                                                                ? value
                                                                                : truncateLength < value.length
                                                                                    ? `${value.substr(0, truncateLength)}...`
                                                                                    : value
                                                                    }}
                                                                />
                                                            </CopyToClipboard>
                                                        ) : (
                                                            <>
                                                                {!header.icon && !header.link ? (
                                                                    <span dangerouslySetInnerHTML={{ __html: value }} />
                                                                ) : (
                                                                    <span
                                                                        style={{ cursor: header.tooltipKey ? 'help' : 'unset' }}
                                                                        className={`${header.tooltip ? 'tooltip fit left' : ''} ${header.icon ? 'icon' : ''}`}
                                                                        tip={header.tooltip && item[header.name].tooltip}
                                                                    >
                                                                        {header.icon ? React.createElement(icons[value?.icon || value || 'FaMinus'], {
                                                                            size: 16,
                                                                            color: item.iconColor || item[header.name]?.color || 'unset'
                                                                        }) : header.link ? (
                                                                            <a href={value} target="_blank" rel="noopener noreferrer">
                                                                                <FaEye size={16} color="#424242" />
                                                                            </a>
                                                                        ) : value}
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                    </td>
                                                ) : (
                                                    <td />
                                                )
                                            })}

                                            {actions && actions.map(action => {
                                                if (!action.action) {
                                                    throw new Error('The actions property from Table component should have the "action" attribute.')
                                                }

                                                return (
                                                    <td key={action.name} className={`action-icon ${action.cellClasses || ''}`}>
                                                        <>
                                                            {item.executing
                                                                ? <Spinner label="" color="#2196f3" size={18} containerStyle={{ marginTop: 4 }} /> : (
                                                                    <div className="flex justify-center">
                                                                        {React.createElement(action.icon, {
                                                                            size: action.iconSize || 12,

                                                                            onClick: () => handleActionClick(action, item),

                                                                            style: {
                                                                                opacity: (action.checkDisabled?.(item) || runningActionId === item[keyProp]) ? 0.4 : 1,
                                                                                color: action.color || 'unset'
                                                                            },

                                                                            className: `action-icon-${action.name}-${item[keyProp]} ${action.checkDisabled?.(item) ? 'disabled' : ''}`,

                                                                            'data-tip': getActionTooltip(action, item),

                                                                            'data-for': 'table-tooltip'
                                                                        }, null)}
                                                                    </div>
                                                                )}
                                                        </>
                                                    </td>
                                                )
                                            })}

                                            {handlePrepareEdit && (
                                                <td className="edit-icon">
                                                    <FaEdit
                                                        size={14}
                                                        onClick={() => handleEditItem(item[keyProp])}
                                                        data-tip="Editar"
                                                        data-for="table-tooltip"
                                                    />
                                                </td>
                                            )}

                                            {handleDelete && (
                                                <td className="delete-icon">
                                                    <FaTrash
                                                        size={12}
                                                        onClick={() => handleDeleteItem(item[keyProp])}
                                                        data-tip="Excluir"
                                                        data-for="table-tooltip"
                                                    />
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}

                                {showReadMore && (
                                    <tr className="read-more-line">
                                        <td
                                            colSpan={
                                                headers.length
                                                + actions.length
                                                + (handleDelete ? 1 : 0)
                                                + (handlePrepareEdit ? 1 : 0)
                                                + (showId ? 1 : 0)
                                            }
                                            onClick={expandData}
                                        >
                                            <FaChevronDown size={18} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                            {footers.length > 0 && (
                                <tfoot>
                                    <tr>
                                        {footers.map(footer => (
                                            <td
                                                key={footer.text}
                                                colSpan={footer.colspan || 1}
                                                style={{
                                                    ...footer.style || {},
                                                    textAlign: footer.align || 'unset'
                                                }}
                                            >
                                                {footer.text}
                                            </td>
                                        ))}
                                    </tr>
                                </tfoot>
                            )}
                        </Table>

                        {pagination && matchedItems.length > itemsByPage && (
                            <PaginationContainer hideOffset={hideOffset}>
                                <Button fab onClick={() => { paginate('<') }} className="transparent" disabled={currentPage === 1}>
                                    <FiChevronLeft />
                                </Button>

                                <div>
                                    <span className="page">{`${currentPage} / ${totalPages}`}</span>
                                    <span className="count">{`${initialPageIndex + 1} - ${currentPage === totalPages ? matchedItems.length : initialPageIndex + itemsByPage} de ${matchedItems.length} itens`}</span>
                                </div>

                                <Button fab onClick={() => { paginate('>') }} className="transparent" disabled={currentPage === Math.ceil(data.length / itemsByPage)}>
                                    <FiChevronRight />
                                </Button>
                            </PaginationContainer>
                        )}

                        <SmallScreenPlaceholder hideOffset={hideOffset} />
                    </>
                ) : (
                    <EmptyContainer>
                        <FaEyeSlash size={36} />
                        <span>{emptyLabel}</span>
                    </EmptyContainer>
                )}

                {!disableTooltips && (
                    <ReactTooltip
                        id="table-tooltip"
                        place="left"
                        effect="solid"
                        type="dark"
                        html
                    />
                )}
            </TableContainer>

            <Modal
                isOpen={!!itemToDelete}
                title="Confirmação"
                handleClose={() => { setItemToDelete(null) }}
            >
                <ModalDeleteContainer>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: confirmExclusion.template
                                ? confirmExclusion.template.replace(/#attr#/g,
                                    confirmExclusion.attr
                                        ? `<b>${itemToDelete?.[confirmExclusion.attr]}</b>`
                                        : `<b>${itemToDelete?.[keyProp]}</b>`)
                                : `Deseja realmente excluir o <b>item ${itemToDelete?.[keyProp]}</b>?`
                        }}
                    />

                    <div className="button-container">
                        <Button
                            onClick={() => { setItemToDelete(null) }}
                            className="transparent"
                        >
                            Não
                        </Button>

                        <Button
                            onClick={() => {
                                handleDeleteItem(itemToDelete?.[keyProp], true)

                                setItemToDelete(null)
                            }}
                            className="white"
                        >
                            Sim
                        </Button>
                    </div>
                </ModalDeleteContainer>
            </Modal>

            <Modal
                isOpen={!!itemToUncheck}
                title="Confirmação"
                handleClose={() => { setItemToUncheck(null) }}
            >
                <ModalDeleteContainer>
                    {confirmUncheck.template
                        ? confirmUncheck.template.replace(/#attr#/g,
                            confirmUncheck.attr
                                ? itemToUncheck?.[confirmUncheck.attr]
                                : itemToUncheck?.[keyProp])
                        : `Deseja realmente desmarcar o item ${itemToUncheck?.[keyProp]}?`}

                    <div className="button-container">
                        <Button
                            onClick={() => { setItemToUncheck(null) }}
                            className="transparent"
                        >
                            Não
                        </Button>

                        <Button
                            onClick={() => {
                                handleUncheck(itemToUncheck)

                                setItemToUncheck(null)
                            }}
                            className="white"
                        >
                            Sim
                        </Button>
                    </div>
                </ModalDeleteContainer>
            </Modal>

            <Modal
                isOpen={!!confirmationCheckboxDelete}
                handleClose={() => { setConfirmationCheckboxDelete(null) }}
                title="Confirmação"
            >
                <ModalDeleteContainer>
                    <p>{confirmationCheckboxDelete?.confirmationMessage}</p>

                    <div className="button-container">
                        <Button className="transparent" onClick={() => { setConfirmationCheckboxDelete(null) }}>
                            Não
                        </Button>

                        <Button
                            className="white"
                            onClick={() => {
                                confirmationCheckboxDelete?.handle(checked)

                                setConfirmationCheckboxDelete(null)
                            }}
                        >
                            Sim
                        </Button>
                    </div>
                </ModalDeleteContainer>
            </Modal>

            <Modal
                isOpen={!!actionConfirmation}
                handleClose={() => { setActionConfirmation(null) }}
                title="Confirmação"
            >
                <ModalDeleteContainer>
                    <p>{actionConfirmation?.text}</p>

                    {actionConfirmation?.badge && (
                        <Badge>
                            <icons.FaExclamationTriangle />

                            <span>
                                {actionConfirmation?.badge}
                            </span>
                        </Badge>
                    )}

                    <div className="button-container">
                        <Button className="transparent" onClick={() => { setActionConfirmation(null) }}>
                            Não
                        </Button>

                        <Button
                            className="white"
                            onClick={() => {
                                actionConfirmation?.handle(actionConfirmation?.item)

                                setActionConfirmation(null)
                            }}
                        >
                            Sim
                        </Button>
                    </div>
                </ModalDeleteContainer>
            </Modal>
        </>
    )
}
