import React, {
    createContext, useContext, useEffect, useState
} from 'react'
import {
    addHours, differenceInHours, endOfMonth, format, isBefore, startOfMonth
} from 'date-fns'
import { toast } from 'react-toastify'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { api, authHeaders } from '../services/api'
import { GlobalContext } from './GlobalContext'

import stringUtils, { pluralize } from '../util/string'
import formUtils from '../util/form'
import scrollUtils from '../util/scroll'
import { getDuracaoExtenso } from '../util/date'
import Modal from '../components/Modal'
import FeedbackContent from '../pages/UserViews/Processo/Feedback/Content'

export const ProcessoContext = createContext()

export const perfisAssociado = [2, 16, 21, 22, 25, 26]

const today = new Date()
const inicioMes = startOfMonth(today)
const fimMes = endOfMonth(today)

export const initialValuesFiltro = {
    crm: '',
    nome: '',
    assunto: { label: 'Todos', value: '' },
    status: { label: 'Todos', value: '' },
    status_finalizacao: { label: 'Todos', value: '' },
    periodo: [inicioMes, fimMes],
    setor: { label: 'Todos', value: '' },
    atendente: { label: 'Todos', value: '' },
    tipo: { label: 'Todos', value: '' }
}

function eAcaoNoTramite(acao) {
    return ['movimentar', 'finalizar_tramite', 'convidar'].includes(acao)
}

const ProcessoProvider = ({ children }) => {
    const { user } = useContext(GlobalContext)

    const { processo_id } = useParams()

    const location = useLocation()
    const history = useHistory()

    const [processos, setProcessos] = useState(null)
    const [processo, setProcesso] = useState(null)
    const [tramite, setTramite] = useState(null)
    const [tramiteMovimentacao, setTramiteMovimentacao] = useState(null)
    const [setoresVinculados, setSetoresVinculados] = useState(null)
    const [setoresPessoaAutenticada, setSetoresPessoaAutenticada] = useState([])
    const [setores, setSetores] = useState(null)
    const [tiposDisponiveis, setTiposDisponiveis] = useState(null)
    const [tiposDoPerfil, setTiposDoPerfil] = useState(null)
    const [assuntosDisponiveis, setAssuntosDisponiveis] = useState(null)
    const [statusDisponiveis, setStatusDisponiveis] = useState(null)
    const [showModalMovimentacao, setShowModalMovimentacao] = useState(false)
    const [interacaoHabilitada, setInteracaoHabilitada] = useState(false)
    const [movimentacaoHabilitada, setMovimentacaoHabilitada] = useState(false)
    const [atendentes, setAtendentes] = useState(null)
    const [atendentesFiltro, setAtendentesFiltro] = useState(null)
    const [filtroAtual, setFiltroAtual] = useState(initialValuesFiltro)
    const [showFormAbertura, setShowFormAbertura] = useState(location?.state?.abertura || false)
    const [showOpcoes, setShowOpcoes] = useState(false)
    const [avaliarProcesso, setAvaliarProcesso] = useState(null)
    const [alertas, setAlertas] = useState([])
    const [showFormAlerta, setShowFormAlerta] = useState(false)
    const [acao, setAcao] = useState(null)

    async function loadProcessos(filtro) {
        try {
            const params = {
                primeiro_carregamento: !filtro && !location.state ? 1 : 0
            }

            if (filtro) {
                location.state = null
            }

            if (location.state) {
                const {
                    setor, assunto, status, periodo
                } = location.state
                const [inicio, fim] = periodo

                const listaGeralAssuntos = tiposDisponiveis.reduce((result, current) => [
                    ...result,
                    ...current.assuntos.map(item => ({
                        id: item.id,
                        descricao: item.descricao
                    }))
                ], [])

                const assuntoFiltro = assunto ? listaGeralAssuntos.find(ass => ass.id === assunto) : null
                const tipoFiltro = assuntoFiltro ? tiposDisponiveis.find(tip => tip.id === assuntoFiltro.atendimento_tipo_id) : null
                const setorFiltro = setor ? setores.find(set => set.id === setor) : null
                const statusFiltro = status ? statusDisponiveis.find(stt => stt.id === status) : null

                filtro = {
                    nome: '',
                    crm: '',
                    assunto: assuntoFiltro ? { label: assuntoFiltro.descricao, value: assuntoFiltro.id } : { label: 'Todos', value: '' },
                    setor: setorFiltro ? { label: setorFiltro.descricao, value: setorFiltro.id } : { label: 'Todos', value: '' },
                    status: statusFiltro ? { label: statusFiltro.descricao, value: statusFiltro.id } : { label: 'Todos', value: '' },
                    status_finalizacao: { label: 'Todos', value: '' },
                    periodo: [inicio, fim],
                    atendente: { label: 'Todos', value: '' },
                    tipo: tipoFiltro ? { label: tipoFiltro.descricao, value: tipoFiltro.id } : { label: 'Todos', value: '' }
                }

                setFiltroAtual(filtro)
            }

            if (filtro) {
                setFiltroAtual(filtro)

                filtro = formUtils.extractFormValues(filtro)

                const {
                    periodo, crm, nome, assunto, status, status_finalizacao, atendente, setor
                } = filtro
                const [start, end] = periodo

                params.start = start
                params.end = end
                params.crm = crm || ''
                params.nome = nome || ''
                params.assunto = assunto || assuntosDisponiveis?.map(ass => ass.id) || ''
                params.status = status || ''
                params.status_finalizacao = status_finalizacao || ''
                params.atendente = atendente || ''
                params.setor = setor || ''
                params.setor_origem = !!location.state ? setor : ''
            } else {
                params.start = inicioMes
                params.end = fimMes
            }

            if (perfisAssociado.includes(user.perfil.id)) {
                params.demandante = user.pessoa.id
            } else {
                params.perfil = user.perfil.id
            }

            setProcessos(null)

            const response = await api.get('processo', {
                params,
                ...authHeaders()
            })

            setProcessos(response.map(item => {
                const setoresAtuais = item.tramites.filter(tram => tram.status.id !== 2).map(tram => tram.destino)
                const ultimaMovimentacao = item.tramites.at(-1)

                return {
                    id: item.id,
                    identificador: item.identificador,
                    demandante: item.demandante.nome,
                    background: item.demandante.usuario.perfil_id === 25 ? '#fff9c4' : undefined,
                    demandante_id: item.demandante.id,
                    tipo: item.tipo.descricao,
                    assunto: item.assunto.descricao,
                    created_at: format(new Date(item.created_at), 'dd/MM/yyyy HH:mm\'h\''),
                    finished_at: item.finished_at ? format(new Date(item.finished_at), 'dd/MM/yyyy HH:mm\'h\'') : '-',
                    status: item.status.descricao,
                    status_id: item.status.id,
                    token: item.token,
                    ultima_movimentacao: ultimaMovimentacao ? `em ${format(new Date(ultimaMovimentacao.created_at), 'dd/MM/yyyy HH:mm\'h\'')}<br/>por ${stringUtils.reduceName(ultimaMovimentacao.origem.nome)}` : 'Não movimentado',
                    quantidade_tramites: item.tramites.length - 1,
                    setores_atuais_descricao: setoresAtuais?.map(setor => setor?.descricao)?.join(', ') || '-',
                    setores_atuais: setoresAtuais,
                    avaliacao: item.avaliacao
                }
            }))
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadProcesso(clearFirst = true) {
        try {
            if (clearFirst) {
                setProcesso(null)

                scrollUtils.toTop()
            }

            const response = await api.get(`processo/load/${processo_id}`, authHeaders())

            const tramites = response.tramites.map(tram => {
                const { destino, convidados } = tram

                let perfilOrigem = tram.origem.usuario?.perfil?.descricao.toLowerCase() || 'externo'

                if (perfilOrigem.includes('acadêmico')) {
                    perfilOrigem = 'acadêmico'
                }

                const pertenceAoSetorDestino = destino ? setoresVinculados.includes(destino.id) : false
                const eAtendenteAtual = user?.pessoa.id === tram.atendente?.id
                const eOrigemOuDestino = user.pessoa.id === tram.origem.id || eAtendenteAtual
                const eConvidado = tram.convidados.some(c => c.pessoa.id === user?.pessoa.id)
                const permiteAssumirAtendimento = pertenceAoSetorDestino && tram.status.id === 3 && !perfisAssociado.includes(user.perfil.id)
                const convidadosNormalizados = convidados.map(conv => ({
                    id: conv.pessoa.id,
                    nome: stringUtils.reduceName(conv.pessoa.nome),
                    usuario: conv.pessoa.usuario,
                    convidado: true
                }))
                const participantes = [tram.origem]

                if (tram.atendente) {
                    participantes.push(tram.atendente)
                }

                participantes.push(...convidadosNormalizados)

                return {
                    id: tram.id,
                    created_at: format(new Date(tram.created_at), 'dd/MM/yyyy HH:mm\'h\''),
                    origem: stringUtils.reduceName(tram.origem.nome),
                    pessoa_origem: tram.origem,
                    destino_descricao: destino?.descricao || '-',
                    destino,
                    status_id: tram.status.id,
                    status: tram.status.descricao,
                    mensagem: tram.mensagem,
                    dialogo_id: tram.dialogo_id,
                    messages: null,
                    arquivos: tram.arquivos,
                    permitir_acessar_dialogo: !permiteAssumirAtendimento && (eOrigemOuDestino || eConvidado),
                    permitir_assumir_atendimento: permiteAssumirAtendimento,
                    atendente_nome: tram.atendente?.nome ? stringUtils.reduceName(tram.atendente?.nome) : '-',
                    atendente: tram.atendente,
                    convidados: convidadosNormalizados,
                    participantes,
                    prazo: tram.prazo ? `${format(new Date(tram.prazo_data_hora), 'dd/MM/yyyy HH:mm\'h\'')} (${pluralize(tram.prazo, 'dia útil', 'dias úteis')})` : '<i style="color: #999">Sem prazo</i>',
                    background: tram.status.id !== 2 && tram.prazo && isBefore(new Date(tram.prazo_data_hora), today) ? '#ffcdd2' : null
                }
            })

            const setoresAtuais = response.tramites.filter(tram => tram.status.id !== 2).map(tram => tram.destino)
            const ultimoTramite = response.tramites.at(-1)

            const prazoFinalizacao = new Date(response.tramites[0].prazo_data_hora)
            const estaAtrasado = differenceInHours(today, prazoFinalizacao)

            let convidados = tramites.reduce((result, tram) => [...result, ...tram.convidados], [])
            convidados = convidados.filter(conv => {
                return !tramites.some(tram => tram.pessoa_origem.id === conv.id) && !tramites.some(tram => tram.atendente?.id === conv.id)
            })

            setProcesso({
                ...response,
                created_at: format(new Date(response.created_at), 'dd/MM/yyyy HH:mm\'h\''),
                finished_at: response.finished_at ? format(new Date(response.finished_at), 'dd/MM/yyyy HH:mm\'h\'') : 'não finalizado',
                status_id: response.status.id,
                status: response.status.descricao,
                tipo: response.tipo.descricao,
                assunto: response.assunto.descricao,
                tramites,
                setor_atual: ultimoTramite.finished_at ? 'Finalizado' : (ultimoTramite.destino?.descricao || '-'),
                atendente_atual: ultimoTramite.atendente,
                quantidade_tramites: response.tramites.length - 1,
                prazo_maximo: format(prazoFinalizacao, 'dd/MM/yyyy HH:mm\'h\''),
                tempo_atraso: estaAtrasado > 0 ? getDuracaoExtenso(estaAtrasado) : null,
                setores_atuais: setoresAtuais,
                messages: null,
                convidados
            })
        } catch (e) {
            if (e?.code === 401) {
                toast.warn(e.msg)

                history.push('/processos')

                return
            }

            toast.error(e?.msg || 'Erro ao carregar os dados do processo.')
        }
    }

    async function loadSetores() {
        const response = await api.get('setor', authHeaders())

        setSetores(response)
    }

    async function loadStatusDisponiveis() {
        try {
            const response = await api.get('atendimento_status', authHeaders())

            setStatusDisponiveis(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadTiposDisponiveis() {
        const response = await api.get('atendimento_tipo', {
            ...authHeaders(),
            params: {
                sem_juridico: 1
            }
        })

        setTiposDisponiveis(response)
    }

    async function loadSetoresVinculados() {
        try {
            const response = await api.get(`setor_pessoa_vinculo/${user.pessoa.id}`, authHeaders())

            setSetoresVinculados(response)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadTiposDoPerfilAbertura() {
        try {
            const response = await api.get('tipos_processo_por_perfil', {
                params: {
                    perfil: user.perfil.id
                },
                ...authHeaders()
            })

            setTiposDoPerfil(response.tipos_abertura)
        } catch (e) {

        }
    }

    async function loadAtendentes() {
        try {
            const response = await api.get('processo/atendentes_por_setor', authHeaders())

            const listaAtendentes = response.reduce((result, current) => {
                const pessoasAInserir = current.pessoas.filter(item => !result.some(p => p.id === item.id))

                return [
                    ...result,
                    ...pessoasAInserir.map(p => ({
                        ...p,
                        setor: {
                            id: current.id,
                            descricao: current.descricao
                        }
                    }))
                ]
            }, [])

            setAtendentes(listaAtendentes)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadAssuntosDisponiveisNoTipo(tipoId) {
        if (!tipoId) {
            setAssuntosDisponiveis([])

            setFiltroAtual(old => ({
                ...old,
                assunto: { label: 'Todos', value: '' }
            }))

            return
        }

        const response = await api.get('atendimento_assunto', {
            params: {
                tipoId,
                sem_juridico: 1
            },
            ...authHeaders()
        })

        setAssuntosDisponiveis(response)
    }

    async function syncDialogos() {
        loadProcesso(false)
    }

    async function handleMovimentar(values) {
        try {
            values = formUtils.extractFormValues(values)

            if (eAcaoNoTramite(values.acao)) {
                values.tramite_id = tramiteMovimentacao.id
            }

            if (!values.status_finalizacao) {
                const destinosIds = values.destino_id?.map(i => i.value)

                switch (values.acao) {
                    case 'movimentar':
                        values = {
                            acao: values.acao,
                            status_finalizacao: values.status_finalizacao,
                            destino_id: destinosIds,
                            tramite_id: values.tramite_id,
                            abrir_demanda: values.abrir_demanda,
                            prazos: destinosIds.reduce((result, current) => ({
                                ...result,
                                [current]: Number(values[`prazo_${current}`]) || null
                            }), {}),
                            mensagens: destinosIds.reduce((result, current) => ({
                                ...result,
                                [current]: values[`mensagem_${current}`]
                            }), {})
                        }
                        break

                    case 'movimentar_processo':
                        values = {
                            acao: values.acao,
                            status_finalizacao: values.status_finalizacao,
                            destino_id: destinosIds,
                            abrir_demanda: values.abrir_demanda,
                            prazos: destinosIds.reduce((result, current) => ({
                                ...result,
                                [current]: Number(values[`prazo_${current}`]) || null
                            }), {}),
                            mensagens: destinosIds.reduce((result, current) => ({
                                ...result,
                                [current]: values[`mensagem_${current}`]
                            }), {})
                        }
                        break

                    case 'convidar':
                        values = {
                            acao: values.acao,
                            tramite_id: values.tramite_id,
                            convidado: values.convidado
                        }
                        break

                    default: break
                }
            }

            await api.patch(`processo/${processo.id}`, values, authHeaders())

            if (values.status_finalizacao) {
                toast.success('Atendimento finalizado com sucesso.')
            } else {
                toast.success('Atendimento movimentado com sucesso.')
            }

            setShowOpcoes(false)

            setShowModalMovimentacao(false)

            loadProcesso()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function reloadTramite() {
        try {
            const response = await api.get(`processo/tramite/${tramite.id}`, authHeaders())

            let perfilOrigem = response.origem.usuario?.perfil?.descricao.toLowerCase() || 'externo'

            if (perfilOrigem.includes('acadêmico')) {
                perfilOrigem = 'acadêmico'
            }

            const pertenceAoSetorDestino = response.destino ? setoresVinculados.includes(response.destino.id) : false
            const eOrigemOuDestino = user.pessoa.id === response.origem.id || pertenceAoSetorDestino
            const permiteAssumirAtendimento = pertenceAoSetorDestino && response.status.id === 3 && !perfisAssociado.includes(user.perfil.id)
            const convidados = response.convidados.map(conv => ({
                id: conv.pessoa.id,
                nome: stringUtils.reduceName(conv.pessoa.nome)
            }))

            const participantes = [
                {
                    id: response.origem.id,
                    nome: stringUtils.reduceName(response.origem.nome),
                    usuario: response.origem.usuario
                }
            ]

            if (response.atendente) {
                participantes.push(
                    {
                        id: response.atendente.id,
                        nome: stringUtils.reduceName(response.atendente.nome),
                        usuario: response.atendente.usuario
                    }
                )
            }

            participantes.push(...convidados.map(conv => ({
                id: conv.id,
                nome: stringUtils.reduceName(conv.nome),
                usuario: conv.usuario,
                convidade: true
            })))

            setTramite({
                id: response.id,
                created_at: format(new Date(response.created_at), 'dd/MM/yyyy HH:mm\'h\''),
                origem: `${stringUtils.reduceName(response.origem.nome)} (${perfilOrigem})`,
                pessoa_origem: response.origem,
                destino_descricao: response.destino?.descricao || '-',
                destino: response.destino,
                status_id: response.status.id,
                status: response.status.descricao,
                mensagem: response.mensagem,
                dialogo_id: response.dialogo_id,
                messages: response.messages,
                arquivos: response.arquivos,
                permitir_acessar_dialogo: eOrigemOuDestino && !permiteAssumirAtendimento,
                permitir_assumir_atendimento: permiteAssumirAtendimento,
                atendente_nome: response.atendente?.nome ? stringUtils.reduceName(response.atendente?.nome) : '-',
                atendente: response.atendente,
                participantes,
                convidados
            })
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function reloadArquivos(tramiteId) {
        if (!tramiteId) {
            await loadProcesso(false)
        }
    }

    async function handleAbrirProcesso(values) {
        try {
            values = formUtils.extractFormValues(values)

            await api.post('processo', {
                assunto_id: values.assunto,
                mensagem: values.mensagem
            }, authHeaders())

            toast.success('Seu atendimento foi iniciado e será atendido em breve.')

            setShowFormAbertura(false)

            loadProcessos()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function loadMessages(dialogoId, eTramite) {
        const response = await api.get(`processo/load/${processo.id}/dialogo/${dialogoId}`, authHeaders())

        if (!eTramite) {
            setProcesso(old => ({
                ...old,
                ...response
            }))
        } else {
            setTramite(old => ({
                ...old,
                ...response
            }))

            const posicaoTramite = processo.tramites.map(t => t.dialogo_id).indexOf(dialogoId)
            const tram = processo.tramites.find(t => t.dialogo_id === dialogoId)

            setProcesso(old => ({
                ...old,
                tramites: [
                    ...old.tramites.slice(0, posicaoTramite),
                    {
                        ...tram,
                        ...response
                    },
                    ...old.tramites.slice(posicaoTramite + 1)
                ]
            }))
        }
    }

    function handleLoadAtendentesFiltro(setorId) {
        if (!setorId) {
            setAtendentesFiltro(atendentes)

            return
        }

        const atendDoSetor = atendentes.filter(a => a.setor.id === setorId)

        setAtendentesFiltro(atendDoSetor)
    }

    async function loadAlertas(processoId) {
        try {
            const response = await api.get(`processo/${processoId}/alerta`, authHeaders())

            setAlertas(response.map(alerta => ({
                ...alerta,
                prazo: `${format(new Date(alerta.prazo_data_hora), 'dd/MM/yyyy HH:mm\'h\'')} (${alerta.prazo} ${alerta.prazo > 1 ? 'dias úteis' : 'dia útil'} )`,
                nome: stringUtils.reduceName(alerta.pessoa.nome),
                pessoa_id: alerta.pessoa.id,
                emitido_descricao: alerta.emitido ? 'Sim' : 'Não'
            })))
        } catch (e) {
            console.log('Erro ao carregar os alertas.')
        }
    }

    async function handleCriarAlerta(values) {
        try {
            const { mensagem_alerta, prazo_alerta, titulo_alerta } = values

            const body = {
                prazo: prazo_alerta,
                titulo: titulo_alerta,
                mensagem: mensagem_alerta,
                processo_id: processo.id,
                emitido: false,
                emitido_descricao: 'Não'
            }

            const response = await api.post('processo/alerta', body, authHeaders())

            toast.success('Alerta criado com sucesso.')

            setAlertas(old => [
                ...old,
                {
                    ...body,
                    id: response.id,
                    nome: stringUtils.reduceName(user.pessoa.nome),
                    pessoa_id: user.pessoa.id,
                    prazo: `${format(new Date(response.prazo_data_hora), 'dd/MM/yyyy HH:mm\'h\'')} (${response.prazo} ${response.prazo > 1 ? 'dias úteis' : 'dia útil'} )`
                }
            ])

            setShowFormAlerta(false)

            setTimeout(() => {
                scrollUtils.toBottom()
            }, 600)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleRemoverAlerta(alerta) {
        try {
            await api.delete(`processo/alerta/${alerta.id}`, authHeaders())

            toast.success('Alerta removido com sucesso.')

            loadAlertas(processo.id)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function obterSetoresPessoaAutenticada() {
        try {
            const setoresIds = await api.get(`setor_pessoa_vinculo/${user.pessoa.id}`, authHeaders())

            setSetoresPessoaAutenticada(setoresIds)
        } catch (e) {
            toast.error(e.msg)
        }
    }

    useEffect(() => {
        if (user) {
            loadSetoresVinculados()

            loadSetores()

            loadAtendentes()

            loadTiposDisponiveis()

            loadStatusDisponiveis()

            loadTiposDoPerfilAbertura()

            obterSetoresPessoaAutenticada()
        }
    }, [user])

    useEffect(() => {
        if (setores && statusDisponiveis && tiposDisponiveis) {
            loadProcessos()
        }
    }, [setores, statusDisponiveis, tiposDisponiveis])

    useEffect(() => {
        if (user && processo_id && setoresVinculados) {
            loadProcesso()
        }
    }, [user, processo_id, setoresVinculados])

    useEffect(() => {
        if (processo && setoresVinculados) {
            const ultimosTramites = processo.tramites.filter(tram => tram.status !== 2)
            const setoresIds = ultimosTramites.filter(tram => !!tram.destino).map(tram => tram.destino.id)
            const setorHabilitado = setoresIds.some(setor => setoresVinculados.includes(setor))
            const eAtendenteAtual = ultimosTramites.some(tram => tram.atendente?.id === user.pessoa.id)
            const emAtendimento = processo.status_id === 1
            const finalizado = processo.status_id === 2
            const aberto = processo.status_id === 3
            const eAssociado = perfisAssociado.includes(user.perfil.id)
            const eDemandante = processo.demandante.id === user.pessoa.id

            if (!processo.messages) {
                loadMessages(processo.dialogo_id)
            }

            loadAlertas(processo.id)

            const tramitesEmAbertoDoSetor = ultimosTramites.filter(tram => setoresPessoaAutenticada.includes(tram.destino?.id) && tram.status_id === 3)

            let chatLiberado = true

            if ((aberto && !eDemandante) || finalizado) {
                chatLiberado = false
            }

            if (emAtendimento && !eDemandante && tramitesEmAbertoDoSetor.length) {
                chatLiberado = false
            }

            setInteracaoHabilitada(chatLiberado)
            setMovimentacaoHabilitada(!eAssociado && setorHabilitado && eAtendenteAtual && emAtendimento)
        }
    }, [processo, setoresVinculados])

    useEffect(() => {
        if (tramite && !tramite.messages && tramite.dialogo_id) {
            loadMessages(tramite.dialogo_id, true)
        }
    }, [tramite])

    useEffect(() => {
        if (atendentes) {
            setAtendentesFiltro(atendentes)
        }
    }, [atendentes])

    return (
        <ProcessoContext.Provider
            value={{
                processos,
                setProcesso,
                processo,
                reloadProcesso: loadProcesso,
                setoresVinculados,
                handleMovimentar,
                setores,
                showModalMovimentacao,
                setShowModalMovimentacao,
                interacaoHabilitada,
                tramite,
                setTramite,
                reloadTramite,
                atendentes,
                tiposDisponiveis,
                assuntosDisponiveis,
                statusDisponiveis,
                loadAssuntosDisponiveisNoTipo,
                loadProcessos,
                filtroAtual,
                handleAbrirProcesso,
                showFormAbertura,
                setShowFormAbertura,
                tiposDoPerfil,
                movimentacaoHabilitada,
                syncDialogos,
                setTramiteMovimentacao,
                tramiteMovimentacao,
                reloadArquivos,
                handleLoadAtendentesFiltro,
                atendentesFiltro,
                showOpcoes,
                setShowOpcoes,
                setAvaliarProcesso,
                handleCriarAlerta,
                alertas,
                setShowFormAlerta,
                showFormAlerta,
                loadAlertas,
                handleRemoverAlerta,
                acao,
                setAcao
            }}
        >
            {children}

            <Modal
                isOpen={!!avaliarProcesso}
                title="Avaliar atendimento"
                handleClose={() => {
                    setAvaliarProcesso(null)
                }}
            >
                <FeedbackContent processoAvaliacao={avaliarProcesso} />
            </Modal>
        </ProcessoContext.Provider>
    )
}

export default ProcessoProvider
