import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: flex;
		flex-direction: column;
		/* display: grid !important; */
		/* justify-items: center; */
		/* grid-template-columns: 1fr 1fr 1.2fr; */
		/* grid-template-areas:
			'f1 f2 p'
			'table table table'
			'check check check'
			'button button button'; */

		> p {
			font-size: 16px;
			margin: 20px 8px;
			grid-area: p;
			width: calc(100% - 16px);
			margin: 24px 8px;
		}

		/* .residente {
			grid-area: f1;
		}

		.aspirante {
			grid-area: f2;
		}

		.table-descontos {
			margin: 0 8px !important;
			grid-area: table;
			max-width: 300px;

			table {
				margin-top: 0;
			}
		} */

		/* @media(max-width: 650px) {
			grid-template-columns: 1fr;
			grid-template-areas:
				'f1'
				'f2'
				'p'
				'table'
				'check'
				'button';
		} */
	}
`

export const TermoContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	grid-area: termo;
`

export const ModalTermoContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	iframe {
		width: calc(100% - 16px);
		height: 80vh;
	}
`
