import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	> button {
		align-self: flex-end;
	}

	form {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			'tip tip tip tip'
			'f1 f2 f3 f3'
			'f4 f5 f6 f7'
			'button button button button';
		
		button {
			grid-area: button;
			width: 200px;
			justify-self: end;
			margin-top: 16px;
		}
		
		@media(max-width: 900px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
				'tip tip'
				'f1 f2'
				'f3 f3'
				'f4 f5'
				'button button';
		} 

		@media(max-width: 750px) {
			display: flex;
			flex-direction: column;
			
			button {
				align-self: flex-end;
			}
		} 

		@media(max-width: 500px) {
			button {
				width: calc(100% - 16px);
			}
		}
	}
`

export const ButtonContainer = styled.div`
	grid-area: button;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 10px;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`
