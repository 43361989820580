import { toast } from 'react-toastify'
import { removerTagsHTML } from './string'

export async function copiarConteudo({ seletor, text }) {
    let content
	
    if(seletor) {
        const textoDiv = document.querySelector(seletor)
		
        content = textoDiv.innerText
    } else {
        content = text
    }

    await navigator.clipboard.writeText(removerTagsHTML(content))

    toast.info('Conteúdo copiado!')
}
