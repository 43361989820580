import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FiFile } from 'react-icons/fi'
import { format } from 'date-fns'

import Yup from '../../../../../services/yup'
import { api, authHeaders } from '../../../../../services/api'
import ibge from '../../../../../services/ibge'

import { downloadRelatorioJasper } from '../../../../../util/file'

import {
    Button, Select, Textbox, Calendar
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { Container, ButtonContainer } from './styles'

import rangeCalendarGif from '../../../../../assets/images/gifs/periodo-dias.gif'

const initialValues = {
    situacao: '',
    tipoAssunto: '',
    cidade: '',
    'tipo-associado': '',
    crm: '',
    periodo: ''
}

const validation = Yup.object().shape({
    situacao: Yup.string().ensure(),
    tipoAssunto: Yup.string().ensure(),
    cidade: Yup.string().ensure(),
    'tipo-associado': Yup.string().ensure(),
    crm: Yup.string().nullable(),
    periodo: Yup.string().nullable()
})

const nomeRelatorio = 'Relatório de demandas Jurídicas'

export default function (props) {
    const [cidades, setCidades] = useState([])
    const [assuntos, setTipoAssunto] = useState([])
    const [atendimentoStatus, setAtendimentoStatus] = useState([])

    useEffect(() => {
        async function loadCidades() {
            const { data } = await ibge.get('estados/PA/municipios')

            setCidades(data.map(({ nome }) => nome).sort())
        }

        loadCidades()
    }, [])

    useEffect(() => {
        async function loadStatusAtendimento() {
            const response = await api.get('atendimento_status')

            setAtendimentoStatus(response)
        }

        loadStatusAtendimento()
    }, [])

    useEffect(() => {
        async function loadTipoAssunto() {
            const response = await api.get('atendimento_assunto')

            setTipoAssunto(response)
        }

        loadTipoAssunto()
    }, [])

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const {
            situacao,
            cidade,
            crm,
            tipoAssunto,
            'tipo-associado': tipoAssociado,
            periodo
        } = values

        const inicio = periodo ? periodo[0] : null
        const fim = periodo ? periodo[1] : null

        let filtros = ''

        if (tipoAssociado) {
            filtros += `Médicos: ${tipoAssociado.label}, `
        }

        if (cidade) {
            filtros += `Cidade: ${cidade.label}, `
        }

        if (tipoAssunto) {
            filtros += `Tipo de assunto: ${tipoAssunto.label}, `
        }

        if (situacao) {
            filtros += `Status de atendimento: ${situacao.label}, `
        } else {
            filtros += 'Status de atendimento: Todos, '
        }

        if (inicio) {
            filtros += `Período: ${format(new Date(inicio), 'dd/MM/yyyy')} a ${fim !== null ? format(new Date(fim), 'dd/MM/yyyy') : format(new Date(inicio), 'dd/MM/yyyy')}, `
        }

        try {
            const response = await api.post('/relatorio', {
                relatorio: 'RelatorioJuridico',
                parametros: {
                    cidade: cidade?.value || '',
                    situacao: situacao?.value || '',
                    tipoAssociado: tipoAssociado?.value || '',
                    idTipoAssunto: tipoAssunto?.value || '',
                    crm: crm || '',
                    dataInicio: inicio !== null ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    dataFim: fim !== null ? format(new Date(fim), 'yyyy-MM-dd') : (inicio !== null) ? format(new Date(inicio), 'yyyy-MM-dd') : '',
                    filtros: filtros !== '' ? filtros.slice(0, -2) : ''
                }
            }, {
                ...authHeaders(),
                responseType: 'blob'
            })

            downloadRelatorioJasper(response, nomeRelatorio)

            resetForm()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao gerar o relatório.')
        }
    }, [])

    return (
        <Container className="animated fadeIn faster form" {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, setFieldValue, resetForm
                }) => (
                    <Form>
                        <h2>{nomeRelatorio}</h2>

                        <Select
                            name="situacao"
                            label="Situação"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={atendimentoStatus.map(status => ({
                                label: status.descricao,
                                value: status.id
                            }))}
                        />

                        <Calendar
                            name="periodo"
                            label="Periodo de busca"
                            selectionMode="range"
                            tooltip={`<p>Com o calendário aberto, selecione a primeira data,<br/>em seguida, a segunda.</p><br/><img src="${rangeCalendarGif}" style="width:300px; height:auto" />`}
                        />

                        <Select
                            name="tipoAssunto"
                            label="Tipo de Assunto"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={assuntos.filter(a => a.atendimento_tipo_id === 1).map(a => ({
                                label: a.descricao,
                                value: a.id
                            }))}
                        />

                        <Textbox
                            name="crm"
                            label="Matrícula"
                        />

                        <Select
                            name="cidade"
                            label="Cidade"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={cidades.map(cidade => ({
                                label: cidade,
                                value: cidade
                            }))}
                        />

                        <Select
                            name="tipo-associado"
                            label="Tipo Associado"
                            onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                            options={[
                                { label: 'Associado', value: 3 },
                                { label: 'Ex-associado', value: 5 }
                            ]}
                        />

                        <Spinner
                            visible={isSubmitting}
                            label="Gerando relatório..."
                        />

                        <ButtonContainer>
                            <Button
                                onClick={resetForm}
                                className="transparent"
                            >
                                Limpar
                            </Button>

                            <Button
                                type="submit"
                                loading={isSubmitting}
                                className="white"
                            >
                                Gerar relatório
                                <FiFile size={20} />
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
