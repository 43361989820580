import styled from 'styled-components'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
			grid-area: title;
		}

		.card {
			display: flex;
			flex-direction: column;
			min-height: 70vh;

			canvas {
				justify-self: center;
			}

			.table-container {
				max-width: 700px;
				width: 100%;
				align-self: center;
			}
		}

		@media(max-width: 600px) {
			padding: 35px 20px;
		}

		@media(max-width: 400px) {
			padding: 35px 0;
		}
	}
`

export const DocumentoContainer = styled.div`
	display: flex;
	flex-direction: column;

	.button-container {
		display: flex;
		justify-content: flex-end;
	}
`
