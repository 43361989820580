export default {
    mobile: /^$|^\(\d{2}\) \d{5}-\d{4}$/,
    phone: /^$|^\(\d{2}\) \d{4}-\d{4}$/,
    mobileOrPhone: /^$|^\(\d{2}\) \d{4,5}-\d{4}$/,
    shortDate: /^\d{2}\/\d{2}$/,
    date: /^\d{2}\/\d{2}\/\d{4}$/,
    cvv: /^\d{3}$/,
    url: /^$|^(http(s)?:\/\/)?(www\.)?[\w-_]+\.\w+(\.\w+)?(\/|\/\w+)?/,
    money: /^R\$ [0-9.]+,\d{2}$/,
    cep: /^\d{5}-\d{3}$/,
    uf: /^[A-Z]{2}$/,
    cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
    cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
    percent: /^\d+(,\d+)?%$/,
    urlContain: /(https?:\/\/)?(www\.)?([-a-zA-Z0-9@:%._+~#=]{1,256}\.)([a-zA-Z0-9()]{1,6}\b)([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    oab: /^\d{4,7}$/
}
