import React, { useContext, useEffect, useState } from 'react'
import { FiArrowRight } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Formik, Form } from 'formik'
import { isBefore } from 'date-fns'

import Table from '../../../../../components/Table'
import {
    Button, Textarea, Checkbox, Select 
} from '../../../../../components/Form'
import Spinner from '../../../../../components/Spinner'

import { api, authHeaders } from '../../../../../services/api'
import Yup from '../../../../../services/yup'

import { Container, EventDetails } from './styles'

import { EventoContext } from '../../../../../contexts/EventoContext'

import { clickEvent } from '../../../../../util/events'

const validation = Yup.object({
    mensagem: Yup.string().required('Escreva o aviso que deseja enviar.')
})

export default function () {
    const { evento, reload } = useContext(EventoContext)

    const [pessoas, setPessoas] = useState(null)
    const [convidados, setConvidados] = useState([])
    const [destinatarios, setDestinatarios] = useState([])

    useEffect(() => {
        if(evento) {
            setPessoas(evento.participantes.map(pessoa => {
                let documento = pessoa.documentos.find(doc => doc.tipo_documento.id === 3)

                if(!documento) {
                    documento = pessoa.documentos.find(doc => doc.tipo_documento.id === 2)
                }

                return {
                    id: pessoa.id,
                    identificador: documento?.identificador || '-',
                    nome: pessoa.nome,
                    presente: pessoa.presente
                }
            }))

            const destinatariosLista = [
                { label: 'Seleção manual', value: 'manual' },
                { label: 'Todos os convidados', value: 'todos' }
            ]

            if(isBefore(evento.fim_datetime, new Date())) {
                destinatariosLista.push({ label: 'Apenas os que compareceram', value: 'presentes' })
            }

            setDestinatarios(destinatariosLista)
        }
    }, [evento])

    async function handleEnviarAviso(values) {
        try {
            await api.post(`evento/${evento.id}/aviso`, { 
                pessoas: convidados, 
                mensagem: values.mensagem,
                canais: {
                    email: !!values.email,
                    whatsapp: !!values.whatsapp
                }
            }, authHeaders())

            toast.success('O aviso foi enviado com sucesso.')

            reload()
        } catch(e) {
            toast.error('Ocorreu um erro ao enviar o aviso para os participantes do evento.')
        }
    }

    function handleChangeDestinatarios(dest) {
        if(dest === 'todos') {
            setConvidados(pessoas.map(p => p.id))

            document.querySelector('#tabela-convidados .select-all').dispatchEvent(clickEvent)
        } else if(dest === 'presentes') {
            setConvidados(pessoas.filter(p => p.presente).map(p => p.id))
        } else {
            setConvidados([])
        }
    }

    function desmarcarTodosConvidados() {
        const convidadosTodos = document.querySelectorAll('#tabela-convidados input')
        convidadosTodos.forEach(c => { c.checked = false })
    }

    useEffect(() => {
        desmarcarTodosConvidados()

        for(const convidadoId of convidados) {
            const checkConvidado = document.querySelector(`.check-${convidadoId}`)

            if(checkConvidado) {
                checkConvidado.checked = true
            }
        }
    }, [convidados])

    if(!evento) {
        return null
    }

    return (
        <Container>
            <EventDetails>
                <h1>{`Evento: ${evento.name}`}</h1>
                <h2>{`Local: ${evento.nome_local}`}</h2>
                <h2>{`Início: ${evento.inicio}`}</h2>
            </EventDetails>

            {pessoas ? (
                <>
                    <Table 
                        tableId="tabela-convidados"
                        headers={[
                            {
                                name: 'identificador', value: 'CRM/CPF', centered: true, style: { width: 200 } 
                            },
                            { name: 'nome', value: 'Nome' }
                        ]}
                        data={pessoas || []}
                        filterable
                        checkable
                        onCheckItem={item => {
                            setConvidados(old => [
                                ...old,
                                item.id
                            ])
                        }}
                        onUncheckItem={item => { setConvidados(convidados.filter(c => c !== item.id)) }}
                        toggleCheckAll={isChecked => {
                            if(isChecked) {
                                setConvidados(pessoas.map(p => p.id))
                            } else {
                                setConvidados([])
                            }
                        }}
                    />
				
                    <Formik
                        onSubmit={handleEnviarAviso}
                        initialValues={{ 
                            mensagem: '',
                            email: null,
                            whatsapp: null
                        }}
                        validationSchema={validation}
                    >
                        {({ setFieldValue, values }) => (
                            <Form style={{ gridArea: 'form' }}>
                                <Select 
                                    name="destinatarios"
                                    label="Destinatários"
                                    onChange={(selected, meta) => { 
                                        setFieldValue(meta.name, selected) 

                                        handleChangeDestinatarios(selected.value)
                                    }}
                                    options={destinatarios}
                                />

                                <Textarea 
                                    name="mensagem"
                                    label="Aviso"
                                />

                                <Checkbox 
                                    name="email" 
                                    label="E-mail"
                                    handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                />

                                <Checkbox 
                                    name="whatsapp" 
                                    label="WhatsApp"
                                    handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                                />

                                <Button type="submit" className="transparent" disabled={!convidados.length || (!values.email && !values.whatsapp)}>
                                    Enviar aviso
                                    <FiArrowRight />
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </>
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
