import React, { useContext, useState, useEffect } from 'react'
import { Formik, Form } from 'formik'

import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'

import Modal from '../../../../components/Modal'
import {
    Button, Textbox, Select, Checkbox
} from '../../../../components/Form'
import GerenciamentoDocumentos from '../../../../components/GerenciamentoDocumentos'

import maskUtils from '../../../../util/masks'
import { loadTips } from '../../../../util/tip'

import { DependenteContext } from '../../../../contexts/DependenteContext'
import { TipContext } from '../../../../contexts/TipContext'

import { Container } from './styles'

const baseInitialValues = {
    nome: '',
    data_nascimento: '',
    sexo: { label: 'Masculino', value: 'M' },
    parentesco: null,
    documentos: [],
    estado_civil: null,
    pne: false
}

const validation = Yup.object({
    nome: Yup.string().required('Campo obrigatório.'),
    data_nascimento: Yup.string().required('Campo obrigatório.'),
    sexo: Yup.string().ensure().required('Campo obrigatório.'),
    parentesco: Yup.string().ensure().required('Campo obrigatório.'),
    estado_civil: Yup.string().ensure().required('Campo obrigatório.'),
    pne: Yup.boolean().required('Campo obrigatório.')
})

export default function ({ onSubmit }) {
    const { setCodigo, tips } = useContext(TipContext)
    const {
        modoCadastro,
        setModoCadastro,
        editItem,
        setEditItem,
        handleSubmit,
        documentos,
        setDocumentos
    } = useContext(DependenteContext)

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [grausParentesco, setGrausParentesco] = useState([])
    const [estadosCivis, setEstadosCivis] = useState([])

    async function loadGrausParentesco() {
        const response = await api.get('grau_parentesco', authHeaders())

        setGrausParentesco(response.filter(p => p.id !== 1).map(parentesco => ({
            label: parentesco.descricao,
            value: parentesco.id
        })))
    }

    async function loadEstadosCivis() {
        const response = await api.get('estado_civil')

        setEstadosCivis(response.map(estado => ({
            label: estado.descricao,
            value: estado.id
        })))
    }

    useEffect(() => {
        loadGrausParentesco()

        loadEstadosCivis()

        loadTips(setCodigo, 'form_plano_saude_dependente')
    }, [])

    useEffect(() => {
        if (editItem) {
            setInitialValues({
                nome: editItem.pessoa.nome,
                data_nascimento: editItem.pessoa.data_nascimento,
                sexo: {
                    value: editItem.pessoa.sexo,
                    label: editItem.pessoa.sexo === 'M' ? 'Masculino' : 'Feminino'
                },
                parentesco: {
                    label: editItem.grau_parentesco.descricao,
                    value: editItem.grau_parentesco.id
                },
                estado_civil: {
                    label: editItem.estado_civil.descricao,
                    value: editItem.estado_civil.id
                },
                pne: editItem.pne
            })

            setDocumentos(editItem.pessoa.documentos.map(doc => ({
                id: doc.id,
                arquivo: doc.arquivo,
                tipo_documento: {
                    descricao: doc.tipo_documento.label,
                    id: doc.tipo_documento.value
                },
                identificador: doc.identificador,
                validade: doc.validade
            })))
        }

        if (modoCadastro) {
            setDocumentos([])
            setInitialValues(baseInitialValues)
        }
    }, [editItem, modoCadastro])

    async function handleSubmitForm(values) {
        await handleSubmit(values)

        if(onSubmit) {
            await onSubmit()
        }
    }

    return (
        <Modal
            isOpen={!!editItem || modoCadastro}
            handleClose={() => {
                setEditItem(null)
                setModoCadastro(false)
            }}
            title={`${modoCadastro ? 'Cadastrar' : 'Editar'} dependente`}
        >
            <Container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={validation}
                    enableReinitialize
                >
                    {({
                        setFieldValue, isSubmitting
                    }) => (
                        <Form>
                            <p style={{ gridColumn: '1/span 3', margin: 8 }}>{tips?.formulario}</p>
                        
                            <Textbox
                                name="nome"
                                label="Nome"
                            />

                            <Textbox
                                name="data_nascimento"
                                label="Data de nascimento"
                                mask={maskUtils.date}
                            />

                            <Select
                                name="sexo"
                                label="Sexo"
                                options={[
                                    { label: 'Masculino', value: 'M' },
                                    { label: 'Feminino', value: 'F' }
                                ]}
                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            />

                            <Select
                                label="Parentesco"
                                name="parentesco"
                                options={grausParentesco}
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)
                                }}
                            />

                            <Select
                                label="Estado Civil"
                                name="estado_civil"
                                options={estadosCivis}
                                onChange={(selected, meta) => {
                                    setFieldValue(meta.name, selected)
                                }}
                            />

                            <Checkbox
                                name="pne"
                                label="Pessoa com deficiência"
                                handleChange={e => { setFieldValue(e.target.name, e.target.checked) }}
                            />

                            <GerenciamentoDocumentos
                                handleAdd={(doc) => {
                                    setDocumentos([
                                        ...documentos,
                                        {
                                            ...doc,
                                            arquivo: {
                                                id: doc.arquivo
                                            },
                                            tipo_documento: {
                                                id: doc.tipo_documento.value,
                                                descricao: doc.tipo_documento.label
                                            }
                                        }
                                    ])
                                }}
                                handleDelete={(docId) => {   
                                    setDocumentos(documentos.filter(doc => doc.id !== Number(docId)))
                                }}
                                documentos={documentos}
                                categoria="dependente"
                            />

                            <Button type="submit" className="white" loading={isSubmitting}>
                                {editItem ? 'Salvar alterações' : 'Cadastrar dependente'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Modal>
    )
}
