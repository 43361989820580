import React, { useContext, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { FiCheck } from 'react-icons/fi'

import { Container } from './styles'
import { Button, Select, Textarea } from '../../../../components/Form'
import { ProcessoContext } from '../../../../contexts/ProcessoContext'
import { TipContext } from '../../../../contexts/TipContext'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { loadTips } from '../../../../util/tip'

export default function () {
    const { user } = useContext(GlobalContext)
    const { setCodigo, tips } = useContext(TipContext)
    const {
        tiposDoPerfil, assuntosDisponiveis, loadAssuntosDisponiveisNoTipo, handleAbrirProcesso
    } = useContext(ProcessoContext)

    useEffect(() => {
        if (user) {
            loadTips(setCodigo, 'form_processo_criacao')
        }
    }, [user])

    return (
        <Container>
            <Formik
                initialValues={{
                    mensagem: '',
                    tipo: '',
                    assunto: ''
                }}
                onSubmit={handleAbrirProcesso}
            >
                {({ setFieldValue, isSubmitting }) => (
                    <Form>
                        <p>{tips.formulario}</p>

                        <Select
                            name="tipo"
                            label="Escolha o tipo de atendimento"
                            onChange={(selected, meta) => {
                                setFieldValue(meta.name, selected)

                                loadAssuntosDisponiveisNoTipo(selected.value)
                            }}
                            options={tiposDoPerfil?.map(item => ({
                                label: item.descricao,
                                value: item.id
                            })) || []}
                        />

                        <Select
                            name="assunto"
                            label="Escolha o assunto"
                            onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                            options={assuntosDisponiveis?.map(item => ({
                                label: item.descricao,
                                value: item.id
                            })) || []}
                        />

                        <Textarea
                            name="mensagem"
                            label="Explique em detalhes sua solicitação"
                        />

                        <Button type="submit" className="transparent" loading={isSubmitting}>
                            Iniciar atendimento
                            <FiCheck size={17} />
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
