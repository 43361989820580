import React from 'react'
import { format } from 'date-fns'

import { Container } from './styles'

export default function ({ respostas, mensagem }) {
    return (
        <Container>
            <div>
                {respostas.map(resp => (
                    <div key={resp.id}>
                        <b>{`${resp.questao.identificador} - ${resp.questao.texto}`}</b>

                        <div className="resposta-container">
                            <p>{resp.alternativa.texto}</p>

                            <span className="data">{format(new Date(resp.alternativa.created_at), 'dd/MM/yyyy HH:mm\'h\'')}</span>
                        </div>
                    </div>
                ))}
            </div>

            <div>
                <h2>Mensagem do participante:</h2>
                <p>{mensagem}</p>
            </div>
        </Container>
    )
}
