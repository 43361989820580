import React from 'react'

import { FiCopy } from 'react-icons/fi'
import {
    Container, SenderName, Message, ConversationText
} from './styles'

import regexUtils from '../../../util/regex'

import avatarSyndi from '../../../assets/images/syndi_dialog.png'

import avatarDefault from '../../../assets/images/avatar.png'

import stringUtils from '../../../util/string'
import Icon from '../../../components/Icon'
import { copiarConteudo } from '../../../util/text'

export default function ({ me = false, message }) {
    let { text } = message

    const urls = [...text.matchAll(new RegExp(regexUtils.urlContain, 'g'))].map(url => url[0].replace(/\.$/, ''))

    for (const url of urls) {
        const tag = url.replace(new RegExp(regexUtils.urlContain, 'g'), '<a href="$1$2$3$4$5" target="_blank">$1$2$3$4$5</a>')

        const urlVisivel = tag.match(new RegExp(`>${regexUtils.urlContain.source}<`, 'g'))

        const urlExtraida = urlVisivel?.length ? urlVisivel[0].replace(/[<>]/g, '') : ''

        const urlSubstituida = urlExtraida.replace(/&/g, '&amp;')

        const tagTratada = tag.replace(urlVisivel, `>${urlSubstituida}<`)

        text = text.replace(url, tagTratada)
    }

    return (
        <Container
            className={`conversation__bubble--${me ? 'right' : 'left'}`}
            sending={message.sending}
            me={me}
        >
            <ConversationText className="animated fadeIn fast">
                <SenderName>{message.sender.name ? stringUtils.capitalize(stringUtils.reduceName(message.sender.name)) : 'Syndi'}</SenderName>
                <img src={!message.sender.name ? avatarSyndi : (message.sender.image || avatarDefault)} alt="" />

                <div className="icon-copy-container">
                    <Icon
                        Icon={FiCopy}
                        className="icon-copy"
                        tooltip="Copiar conteúdo"
                        onClick={() => {
                            copiarConteudo({ text })
                        }}
                    />
                </div>

                <Message dangerouslySetInnerHTML={{ __html: text }} />
                <span className="sending-text">{message.sending ? 'Enviando...' : ''}</span>
                <span className="datetime">{message.datetime}</span>
            </ConversationText>
        </Container>
    )
}
