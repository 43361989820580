import React, { useEffect, useState } from 'react'

import Item from './Item'

import { api, authHeaders } from '../../../../services/api'

import { Container, Header } from './styles'

export default function () {
    const [tarefas, setTarefas] = useState(null)

    async function loadTarefas() {
        const response = await api.get('tarefa', authHeaders())

        setTarefas(response)
    }

    useEffect(() => {
        loadTarefas()
    }, [])
	
    return (
        <>
            <Header>
                <span>{' '}</span>
                <span>Funcionalidade</span>
                <span>Prioridade</span>
                <span>Prazo estimado</span>
                <span>Pontos de função</span>
                <span>Custo estimado</span>
                <div>{' '}</div>
            </Header>

            <Container>
                {tarefas?.map(tarefa => (
                    <Item 
                        key={tarefa.id} 
                        data={tarefa} 
                        setArquivoVisualizacao
                        onUpdate={loadTarefas}
                    />
                )) || null}
            </Container>
        </>
    )
}
