import styled from 'styled-components'

import { Container as Card } from '../../../../components/Card/styles'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		h1 {
			margin-bottom: 20px;
		}

		${Card} .react-tabs {
			.react-tabs__tab-list {
				border-bottom: 0;
				margin: 0 0 1px 20px;

				.react-tabs__tab {
					font-size: 16px;
					color: #fff;
					padding: 10px 14px;
					
					&.react-tabs__tab--selected {
						font-weight: 700;
						background-color: #0002;
						border: 0;

						&:focus {
							border: 0;
							box-shadow: unset;
						}
					}

					&.react-tabs__tab--disabled {
						color: #fff9;
					}
				}
			}

			.react-tabs__tab-panel {
				background-color: #00000022;
				border-radius: 16px;
				padding: 16px 24px 20px;
				min-height: 120px;
			}
		}

		@media(max-width: 800px) {
			padding: 20px;
			margin-left: 50px;
		}

		@media(max-width: 400px) {
			padding: 15px 0;
		}

		@media(max-width: 600px) {
			margin-left: 0;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
	}
`
