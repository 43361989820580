import React from 'react'

import { Textbox } from '../../../../../../components/Form'

export default function () {
    return (
        <Textbox 
            name="comando"
            label="Comando"
        />
    )
}
