import styled from 'styled-components'

export const TipContainer = styled.div.attrs({
    className: 'tip-container animated fadeIn faster'
})`
	display: none;
	grid-template-columns: 24px auto;
	position: absolute;
	gap: 4px;
	font-size: 11px;
	margin: 3px;
	align-items: center;
	top: 64px;
	color: #424242;
	background-color: #fff;
	padding: 4px 8px 4px 2px;
	z-index: 1;
	border-radius: 6px;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);

	> svg {
		width: 28px;
		fill: #424242;
	}
`
