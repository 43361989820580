import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr;

		.textarea {
			grid-column: 1/span 2;
		}

		.button-container {
			display: flex;
			justify-content: end;
			grid-column: 1/span 2;
		}
	}
`
