import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Content } from './styles'

import Header from '../../../components/AuthHeader'
import Footer from '../../../components/Footer'
import { GlobalContext } from '../../../contexts/GlobalContext'

export default function AuthLayout({ children }) {
    const { clienteRobot } = useContext(GlobalContext)

    if(!clienteRobot) {
        return null
    }

    return (
        <Wrapper>
            <Content>
                <Header />

                {children}

                <Footer />
            </Content>
        </Wrapper>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired
}
