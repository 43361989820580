import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FaFileUpload } from 'react-icons/fa'
import Yup from '../../../../services/yup'

import Card from '../../../../components/Card'

import { api, authHeaders } from '../../../../services/api'

import { File, Select, Button } from '../../../../components/Form'

import { Container, ButtonContainer } from './styles'
import { loadTips } from '../../../../util/tip'
import { TipContext } from '../../../../contexts/TipContext'

export default function () {
    const { setCodigo, tips } = useContext(TipContext)

    const [anos, setAnos] = useState([])

    async function handleSubmit(values, { resetForm }) {
        try {
            values.ano = values.ano.value

            const response = await api.post('carga/declaracao_unimed', values, authHeaders())

            resetForm()

            toast.success(response.msg || 'Arquivo carregado com sucesso!')
        } catch (e) {
            toast.error(e.message || 'Erro ao carregar o arquivo.')
        }
    }

    useEffect(() => {
        const currentYear = new Date().getFullYear()
        const listaAnos = []

        for (let i = currentYear; i > currentYear - 5; i--) {
            listaAnos.push({
                label: i,
                value: i
            })
        }

        setAnos(listaAnos)

        loadTips(setCodigo, 'form_carga_declaracao_anual_unimed')
    }, [])

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <h1>Carregar dados Declaração anual Unimed</h1>
                    <Formik
                        initialValues={{
                            arquivo: null,
                            ano: ''
                        }}
                        validationSchema={Yup.object().shape({
                            arquivo: Yup.number().required('Anexe o arquivo'),
                            ano: Yup.string().ensure().required('Informe o ano.')
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue, errors, isSubmitting }) => (
                            <Form>
                                <p style={{ gridArea: 'tip', margin: 8 }}>{tips?.formulario}</p>

                                <File
                                    name="arquivo"
                                    onSuccess={fileId => setFieldValue('arquivo', fileId)}
                                    label="Arquivo para carregar"
                                    format="square"
                                    previewSize={['100%', '150px']}
                                    error={errors.arquivo}
                                />

                                <Select
                                    name="ano"
                                    label="Ano"
                                    onChange={(selected, meta) => setFieldValue(meta.name, selected)}
                                    options={anos}
                                    selectionMode="range"
                                    containerStyle={{ gridArea: 'f2' }}
                                />

                                <ButtonContainer>
                                    <Button type="submit" className="transparent" loading={isSubmitting}>
                                        Carregar aquivo
                                        <FaFileUpload size={20} />
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
