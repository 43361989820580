import styled from 'styled-components'

export const Container = styled.div.attrs({
    className: 'form-endereco'
})`
	grid-area: form_endereco;

	> h2 {
		font-weight: 500;
	}

	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			'f1 f2 f3'
			'f4 f5 f6'
			'f7 f8 f9'
			'button button button';

		@media(max-width: 800px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				'f1 f2'
				'f3 f4'
				'f5 f6'
				'f7 f8'
				'f9 f9'
				'button button';
		}

		@media(max-width: 700px) {
			grid-template-columns: 1fr;
			grid-template-areas:
				'f1'
				'f2'
				'f3'
				'f4'
				'f5'
				'f6'
				'f7'
				'f8'
				'f9'
				'button';
		}
	}
`
