import styled from 'styled-components'

export const Container = styled.div`
	dislay: flex;
	flex-direction: column;
	gap: 16px;

	p {
		width: 100%;
		text-align: center;
		margin-top: 24px;
	}

	form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 24px;

		.select {
			grid-column: 1/span 2;
			width: 250px;
		}

		> div {
			h1 {
				font-size: 16px;
				margin: 0 8px 12px;
			}
		}
	}
`

export const List = styled.div`
	width: 100%;
	height: 480px;
	background-color: #FFF;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
	border-radius: 4px;
	margin: 5px 8px;
	overflow: hidden auto;
	display: flex;
	flex-direction: column;
	transition: 300ms;
	
	&.highlight {
		border: dashed 2px grey;
	}
`
