import React, { useState } from 'react'

import {
    Tab, TabList, TabPanel, Tabs 
} from 'react-tabs'

import VisaoProvider from '../../../contexts/VisaoContext'

import Gerenciamento from './Gerenciamento'
import Visualizacao from './Visualizacao'

import Card from '../../../components/Card'

import { Container } from './styles'

export default function () {
    const [indiceTabAtiva, setIndiceTabAtiva] = useState(0)

    return (
        <VisaoProvider>
            <Container>
                <main>
                    <Card>
                        <h1>Grupos de pessoas</h1>

                        <Tabs
                            selectedIndex={indiceTabAtiva}
                            onSelect={indiceTab => setIndiceTabAtiva(indiceTab)}
                        >
                            <TabList>
                                <Tab>Gerenciamento</Tab>
                                <Tab>Visualização</Tab>
                            </TabList>

                            <TabPanel>
                                <Gerenciamento />
                            </TabPanel>

                            <TabPanel>
                                <Visualizacao />
                            </TabPanel>
                        </Tabs>
                    </Card>
                </main>
            </Container>
        </VisaoProvider>
    )
}
