import styled, { keyframes, css } from 'styled-components'

const blink = keyframes`
	from {
		opacity: 0.3;
	}
	to {
		opacity: 1;
	}
`

export const Container = styled.div.attrs({
    className: 'conversation__bubble'
})`
	margin-bottom: 0.8rem;

	&:before,
	&:after {
		content: "";
		display: block;
		clear: both;
	}

	&.conversation__bubble--left {
		position: relative;

		.conversation__text {
			float: left;
			max-width: 85%;
			color: #fff;
			grid-template-columns: 70px 1fr 20px;
			gap: 4px 8px;
			background-color: #334155;

			${props => props.sending && !props.me && css`
				animation: ${blink} 400ms linear infinite alternate;
			`}

			> img {
				grid-column: 1;
				align-self: start;
			}

			.icon-copy-container {
				grid-column: 3;
				justify-self: end;
			}

			.sender {
				grid-column: 2;
				color: #fff;
			}

			.datetime,
			.message {
				grid-column: 2/span 2;
			}

			.sending-text {
				display: none;
			}
		}

		&:before {
			content: "";
			position: absolute;
			bottom: -6px;
			left: 0;
			width: 8px;
			height: 8px;
			border-bottom-right-radius: 10px;
			background-color: #82ba86;
		}
	}

	&.conversation__bubble--right {
		position: relative;

		.conversation__text {
			float: right;
			max-width: 85%;
			color: #332400;
			grid-template-columns: 1fr 20px 60px;
			gap: 4px 8px;
			background-color: #DDD;

			${props => props.sending && props.me && css`
				animation: ${blink} 400ms linear infinite alternate;
			`}

			> img {
				grid-column: 3;
				align-self: start;
			}
			
			.sender {
				grid-column: 1;
			}

			.icon-copy-container {
				grid-column: 2;
				justify-self: end;
			}

			.datetime {
				color: #888;
				grid-column: 2/span 2;
				margin-top: 8px;
			}

			.message {
				grid-column: 1;
			}

			.sending-text {
				font-size: 12px;
				width: 100%;
				color: #82BE86;
				grid-column: 1;
				grid-row: 4;
				align-self: end;
			}

			.message {
				> a {
					color: #424242;

					&:hover {
						color: #424242;
					}
				}
			}
		}

		&:before {
			content: "";
			position: absolute;
			bottom: -6px;
			right: 0;
			width: 8px;
			height: 8px;
			border-bottom-left-radius: 10px;
			background-color: #999;
		}
	}

	.icon-copy-container {
		width: 16px;
		grid-row: 1;
		
		.icon-copy {
			display: none;
			color: #424242;
			cursor: pointer;
		}
	}

	&.conversation__bubble > .conversation__text:hover {
		.icon-copy {
			display: block;
		}
	}
`

export const ConversationText = styled.div.attrs({
    className: 'conversation__text'
})`
	display: grid;
	border-radius: 3px;
	padding: 1rem;
	overflow-wrap: break-word;
	min-width: 200px;
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);

	> img {
		grid-row: 1/span 3;
		object-fit: cover;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		align-self: center;
	}

	.datetime {
		justify-self: flex-end;
		font-size: 10px;
	}
`

export const SenderName = styled.span.attrs({
    className: 'sender'
})`
	font-size: 16px;
	font-weight: 500;
`

export const Message = styled.span.attrs({
    className: 'message'
})`
	font-size: 14px;
	margin: 8px 0;
	font-weight: 500;

	h1, h2, h3, h4, h5, h6 {
		color: #424242;	
	}

	> a {
		font-weight: 600;
		color: #424242;

		&:hover {
			color: #424242;
			opacity: 0.8;
		}
	}
`
