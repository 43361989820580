import styled from 'styled-components'

import { Container as Card } from '../../../components/Card/styles'

export const Container = styled.article`
	min-height: 82vh;

	main {
		padding: 35px 100px;

		${Card} {
			display: flex;
			flex-direction: column;
			min-height: 70vh;

			.table-container,
			.require-rotation,
			.spinner-container,
			.search-form {
				grid-column: 1/3;
			}

			button {
				width: fit-content;
				align-self: flex-end;
			}
		}

		@media(max-width: 900px) {
			padding: 20px 16px;
		}

		@media(max-width: 600px) {
			${Card} button {
				margin-top: 16px;
				width: 100%;
			}
		}
	}
`
