import styled from 'styled-components'

export const Container = styled.div`
	margin: 50px 80px;
	display: grid;
	grid-template-columns: 360px auto;

	img {
		height: 300px;
		justify-self: center;
	}

	.card {
		display: grid;
		grid-template-columns: auto 120px;

		h1 {
			font-size: 26px;
			grid-column: 1/span 2;
		}

		h2 {
			font-size: 20px;
			font-weight: 400;
			margin: 18px 0;
		}

		img {
			height: 120px;
			width: 120px;
			object-fit: contain;
			border-radius: 50%;
		}

		.check {
			position: absolute;
			margin-top: 15px;
			margin-left: -15px;
		}
	}
`
