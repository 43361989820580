import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 20px;

		.card {
			min-height: 70vh;
			display: flex;
			flex-direction: column;
			user-select: none;

			> h1 {
				margin: 0 8px 20px;
			}

			> .textbox {
				margin-bottom: 24px;
				max-width: 300px;
				width: 100%;
				align-self: end;
			}

			.empty-placeholder {
				text-align: center;
				margin-top: 32px;
			}

			.titulo-documentos-extras {
				font-size: 17px;
				margin: 34px 8px 0;
			}
		}

		h2 {
			font-size: 18px;
			font-weight: 600;
		}

		.lista-header {
			margin-top: 16px;
			display: flex;
			gap: 14px;
			align-items: center;
		}

		.empty-info {
			text-align: center;
		}

		a {
			color: #fff;
			margin: 0 8px;
			display: flex;
			align-items: center;

			&:hover {
				opacity: 0.8;
			}
		}

		.back-icon {
			margin: 0 8px 0 0;
			cursor: pointer;
			transition: 300ms;

			&:hover {
				opacity: 0.8;
			}
		}

		.empty-placeholder {
			width: 100%;
			text-align: center;
		}	
		
		@media(min-width: 601px) {
			margin-left: 50px;
		}

		@media(max-width: 600px) {
			margin-left: 0;
			padding: 10px 0;
		}
	}
`

export const Pasta = styled.li.attrs({
	className: 'pasta animated fadeIn'
})`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	align-items: center;
	user-select: none;

	&:hover {
		opacity: 0.8 !important;
	}

	span {
		width: 100px;
		text-align: center;
	}
`

export const PastaAdd = styled.li.attrs({
	className: 'pasta-add animated fadeIn'
})`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	align-items: center;
	opacity: 0.6 !important;
	border: dashed 1px #fff;
	border-radius: 10px;
	padding: 6px 0;
	gap: 8px;
	height: 86px;

	&:hover {
		opacity: 0.9 !important;
	}

	span {
		width: 100px;
		text-align: center;
	}
`

export const NovaPasta = styled.li.attrs({
	className: 'nova-pasta'
})`
	display: flex;
	flex-direction: column;
	transition: 300ms;
	align-items: center;

	> input {
		width: 90px;
		background-color: transparent;
		border: solid #fff 1px;
		padding: 1px;
		text-align: center;
	}
`

export const BreadcrumbContainer = styled.div`
	display: flex;
	gap: 8px;

	> div {
		display: flex;
		gap: 8px;
		align-items: center;

		> span {
			cursor: pointer;
			transition: 300ms;
			border-bottom: solid 1px #fff;
			padding: 4px;

			&:hover {
				opacity: 0.8;
			}
		}
	}
`

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
`

export const ContextMenu = styled.div`
	left: ${prosp => prosp.posicao.x}px;
	top: ${prosp => prosp.posicao.y}px;
	position: absolute;
	background-color: white;
	color: #424242;
	width: 200px;
	padding: 0;
	border-radius: 4px;
	display: flex;
	box-shadow: 0 2px 6px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);

	ul {
		width: 100% !important;
		display: flex;
		flex-direction: column;

		li {
			width: 100%;
			padding: 12px 8px;
			align-items: center;
			
			display: grid;
			grid-template-columns: 1fr 20px;
			
			&:hover {
				background-color: #dedede;
				cursor: pointer;
			}

			&.pasta-bloqueada {
				font-size: 13px;
				color: #0008;
				display: grid;
				grid-template-columns: 22px auto;
				gap: 6px;
			}
		}
	}
`

export const ModalExclusaoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 8px;

	.button-container {
		display: flex;
		gap: 8px;
		justify-content: end;
	}
`

export const ListaPastas = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 30px;
	width: fit-content;
	padding: 0 8px;
	align-items: baseline;

	li a {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 24px;
		transition: 300ms;
		min-width: 80px;
		max-width: 180px;

		&:hover {
			background-color: #fff2;
			cursor: pointer;
		}
	}
`

export const ListaDocumentos = styled.table`
	width: 100%;
	font-size: 13px;

	thead {
		tr {
			th {
				font-size: 14px;
				padding: 16px 8px;
				text-align: left;
				border-bottom: solid 1px #fff;

				&:nth-child(2) {
					width: 260px;
				}

				&:nth-child(3) {
					width: 140px;
				}
			}
		}
		
	}

	tbody {
		tr {
			cursor: pointer;
			
			td {
				padding: 10px 6px;
				border-bottom: solid 1px #fff4;

				> div {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.left {
						display: flex;
						gap: 10px;
						align-items: center;
					}

					> svg {
						background-color: #fff;
						padding: 3px;
						border-radius: 50%;
						color: #424242;
					}
				}
			}

			&:hover {
				background-color: #fff3;
			}
		}
	}
`

export const EmptyPlaceholder = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;

	.text {
		display: flex;
		flex-direction: column;
		align-items: center;

		> svg {
			color: #fff6;
		}
	}
	
	.options {
		display: flex;
		gap: 24px;
		align-items: baseline;
		margin-top: 36px;
	}
`
