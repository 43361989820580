import styled from 'styled-components'

export const Container = styled.div`
	.card {
		padding: 30px 0 30px 14px;

		> h1 {
			margin: 0 16px 8px;
		}

		> span {
			margin: 0 16px;
			font-size: 14px;
		}

		ul {
			margin-top: 24px;
			height: 70vh;
			overflow-y: auto;

			li {
				display: grid;
				grid-template-columns: 80px 1fr;
				grid-gap: 4px 16px;
				border-bottom: solid 1px #fff4;
				margin-right: 8px;
				padding: 8px;
				transition: 300ms;
				
				&:hover {
					cursor: pointer;
					opacity: 0.8;
				}
				
				.background-logo {
					background-color: #FFF;
					border-radius: 50%;
					height: 80px;
					width: 80px;
					display: flex;
					justify-content: center;
					align-items: center;
					grid-row: 1/span 2;

					svg {
						font-size: 50px;
						color: #6FA272;
					}
				}

				img {
					height: 80px;
					width: 80px;
					object-fit: contain;
					border-radius: 50%;
				}

				h1 {
					font-size: 15px;
					margin: 6px 0 3px !important;
				}

				h2 {
					font-size: 12px;
					margin: 3px;
				}
			}
		}
	}
`

export const ServicoDetalhesContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0 24px;
	padding-bottom: 24px;

	> p {
		grid-column: 1/span 2;
		margin: 16px 8px 8px;
	}

	.table-valores {
		grid-column: 1/span 2;
	}

	.table-adicionais {
		grid-row: 4/span 2;
		grid-column: 2;
	}
`
