import styled from 'styled-components'

export const Container = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-areas: 
			'title title title title'
			'f1 f1 f2 f2'
			'f1 f1 f2 f2'
			'f1 f1 f5 f5'
			'f7 f7 f6 f6'
			'f7 f7 f8 y'
			'f7 f7 f10 f10'
			'f9 f12 f11 f11'
			'btn btn btn btn';

		> h1 {
			grid-area: title;
			margin: 5px 8px;
		}

		> .file {
			grid-column: 3/5;
    		width: calc(100% - 16px);
			align-self: flex-start;
		}

		.classificacao-container {
			margin-top: 16px;

			p {
				margin: 0 8px;
			}
		}

		@media(max-width: 900px) {
			display: flex;
			flex-direction: column;
		}
	}
`

export const ButtonContainer = styled.div`
	grid-area: btn;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 60px;
	margin-top: 10px;
	
	@media(max-width: 1000px) {
		button {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		flex-direction: column-reverse;
		height: unset;
		margin: 10px 8px;
		width: calc(100% - 16px);

		button {
			margin-bottom: 14px !important;
		}
	}
`
