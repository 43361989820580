import React, { useState, useEffect } from 'react'
import { FaFilePdf } from 'react-icons/fa'
import {
    FiCheck, FiX, FiDownload, FiFile 
} from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'

import BoxArquivos from '../../../../../boxes/Arquivos'

import Table from '../../../../../components/Table'
import { Button, Checkbox, Textarea } from '../../../../../components/Form'
import Modal from '../../../../../components/Modal'

import { api, authHeaders } from '../../../../../services/api'
import Yup from '../../../../../services/yup'

import { forceDownloadFileFromURL } from '../../../../../util/file'
import maskUtils from '../../../../../util/masks'

import {
    Container, ModalContent, ButtonContainer, DocumentosContainer, SelecaoDocumentosContainer 
} from './styles'
import { basename, extname } from '../../../../../util/path'

export default function ({ detalhes, reload }) {
    const [showDocumentos, setShowDocumentos] = useState(null)
    const [verificado, setVerificado] = useState(false)
    const [showReprovar, setShowReprovar] = useState(false)
    const [gerandoPDF, setGerandoPDF] = useState(false)
    const [gerandoPDFDocumentos, setGerandoPDFDocumentos] = useState(false)
    const [aprovandoAdesao, setAprovandoAdesao] = useState(false)
    const [showModalSelectTipoDocumento, setShowModalSelectTipoDocumento] = useState(false)
    const [adicionaisPorBeneficiario, setAdicionaisPorBeneficiario] = useState(null)

    async function handleAprovar() {
        setAprovandoAdesao(true)
        
        try {
            await api.patch(`convenio_associado/aprovar/${detalhes.id}/${detalhes.associado_id}`, {}, authHeaders())

            toast.success('A solicitação foi aprovada.')

            reload()
        } catch (e) {
            toast.error(e.msg)
        }

        setAprovandoAdesao(false)
    }

    async function handleReprovar({ justificativa }) {
        try {
            await api.patch(`convenio_associado/reprovar/${detalhes.id}/${detalhes.associado_id}`, {
                justificativa
            }, authHeaders())

            toast.success('A solicitação foi reprovada.')

            setShowReprovar(false)

            reload()
        } catch (e) {
            toast.error(e.msg)
        }
    }

    async function handleGerarPDF() {
        setGerandoPDF(true)

        try {
            const response = await api.get(`/convenio_associado/gerar_pdf_adesao/${detalhes.id}/${detalhes.associado_id}`, authHeaders())

            await new Promise(resolve => { setTimeout(() => resolve(), 3000) }).then(() => {})

            forceDownloadFileFromURL(response.link)
        } catch (e) {
            toast.error(e.msg)
        }

        setGerandoPDF(false)
    }

    async function handleDownloadMultiple(selecionados, pessoaId) {
        try {
            setGerandoPDFDocumentos(true)

            const response = await api.post(`convenio_associado/documentos/${pessoaId}`, {
                tiposDocumentoIds: selecionados.map(s => s.split('_')[0])
            }, authHeaders())

            forceDownloadFileFromURL(response.file)

            toast.success('Faça o download do arquivo PDF.')
        } catch(e) {
            toast.error(e.msg)
        }

        setGerandoPDFDocumentos(false)
    }

    function toggleVerificacao() {
        setVerificado(!verificado)
    }

    useEffect(() => {
        if(detalhes) {
            setAdicionaisPorBeneficiario([
                ...detalhes?.adicionais.map(adc => ({
                    id: `${adc.associado.pessoa.id}_${adc.adicional.id}`,
                    adicional_id: adc.adicional.id,
                    beneficiario: adc.associado.pessoa.nome,
                    adicional: adc.adicional.descricao,
                    valor: maskUtils.maskApply.currency(adc.adicional.valor),
                    status: adc.status.descricao,
                    beneficiario_id: adc.associado.id,
                    status_id: adc.status.id,
                    titular: true
                })),
                ...detalhes?.dependentes.reduce((result, dep) => [
                    ...result,
                    ...dep.adicionais.map(adc => ({
                        id: `${dep.pessoa_id}_${adc.id}`,
                        adicional_id: adc.id,
                        beneficiario: dep.nome,
                        adicional: adc.descricao,
                        valor: maskUtils.maskApply.currency(adc.valor),
                        status: adc.status,
                        beneficiario_id: dep.id,
                        status_id: adc.status_id
                    }))
                ], [])
            ])
        }
    }, [detalhes])

    return (
        <>
            <Container>
                <p style={{ gridArea: 'd1' }}>
                    Nome do associado:
                    <b>{` ${detalhes?.nome}`}</b>
                </p>

                <p style={{ gridArea: 'd2' }}>
                    Serviço solicitado:
                    <b>{` ${detalhes?.servico}`}</b>
                </p>

                <p style={{ gridArea: 'd3' }}>
                    Data da solicitação de adesão:
                    <b>{` ${detalhes?.data_solicitacao}`}</b>
                </p>

                <Table
                    caption="Titular e dependentes incluídos"
                    headers={[
                        { name: 'nome', value: 'Beneficiário' },
                        { name: 'parentesco', value: 'Parentensco', centered: true },
                        { name: 'data_nascimento', value: 'Data de nascimento / Idade', centered: true },
                        { name: 'status', value: 'Status', centered: true },
                        { name: 'portabilidade', value: 'Portabilidade', centered: true }
                    ]}
                    data={detalhes ? [
                        detalhes.titular,
                        ...detalhes.dependentes
                    ] : []}
                    emptyLabel="Nenhum dependente incluído"
                    actions={[
                        {
                            action: setShowDocumentos,
                            name: 'Documentos',
                            icon: FiFile,
                            iconSize: 16
                        }
                    ]}
                    className="table-beneficiarios"
                />

                {adicionaisPorBeneficiario && (
                    <Table 
                        caption="Serviços opcionais solicitados"
                        headers={[
                            { name: 'beneficiario', value: 'Beneficiário' },
                            { name: 'adicional', value: 'Serviço solicitado', centered: true },
                            { name: 'valor', value: 'Valor', centered: true },
                            { name: 'status', value: 'Status', centered: true }
                        ]}
                        data={adicionaisPorBeneficiario}
                        className="table-adicionais"
                    />
                )}

                <Checkbox
                    name="verificacao"
                    withoutForm
                    label="Verifiquei que os documentos de todos os beneficiários incluídos neste serviço estão de acordo com o exigido."
                    onChange={toggleVerificacao}
                />

                <ButtonContainer>
                    <Button onClick={handleGerarPDF} className="transparent" loading={gerandoPDF}>
                        Gerar resumo da solicitação
                        <FaFilePdf size={20} />
                    </Button>

                    {detalhes?.status_id === 1 ? (
                        <Button onClick={() => { setShowReprovar(true) }} className="transparent" disabled={verificado}>
                            Reprovar adesão
                            <FiX size={18} />
                        </Button>
                    ) : (
                        <Button onClick={() => { setShowReprovar(true) }} className="transparent" disabled={verificado}>
                            Reprovar dependentes adicionados
                            <FiX size={18} />
                        </Button>
                    )}

                    <Button onClick={handleAprovar} className="white" disabled={!verificado} loading={aprovandoAdesao}>
                        Prosseguir com a solicitação
                        <FiCheck size={18} />
                    </Button>
                </ButtonContainer>
            </Container>

            <Modal
                isOpen={!!showDocumentos}
                handleClose={(() => { setShowDocumentos(null) })}
                title={`Documentos de ${showDocumentos?.nome}`}
            >
                <DocumentosContainer>
                    <BoxArquivos
                        arquivos={showDocumentos?.documentos.map(doc => ({
                            ...doc,
                            extensao: extname(doc.link),
                            link: doc.link,
                            nome: doc.tipo
                        }))}
                        title=""
                    />

                    <div className="buttons-container">
                        <Button onClick={() => { setShowModalSelectTipoDocumento(true) }} className="transparent">
                            Download dos documentos
                        </Button>
                    </div>
                </DocumentosContainer>
            </Modal>

            <Modal
                isOpen={!!showReprovar}
                handleClose={(() => { setShowReprovar(false) })}
                title="Confirmar reprovação"
            >
                <ModalContent>
                    <Formik
                        initialValues={{ justificativa: '' }}
                        validationSchema={Yup.object({
                            justificativa: Yup.string().required('Justificativa obrigatória.')
                        })}
                        onSubmit={handleReprovar}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <p>Forneça abaixo uma justificativa para a reprovação da solicitação.</p>

                                <Textarea
                                    name="justificativa"
                                    label="Justificativa"
                                />

                                <Button type="submit" className="transparent" loading={isSubmitting}>Confirmar</Button>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={!!showModalSelectTipoDocumento}
                handleClose={(() => { setShowModalSelectTipoDocumento(false) })}
                title="Selecione os documentos"
            >
                <SelecaoDocumentosContainer>
                    <Table 
                        headers={[
                            { name: 'descricao', value: 'Documento' }
                        ]}
                        data={showDocumentos?.documentos.map(doc => ({
                            id: `${doc.tipo_id}_${basename(doc.link).replace('.', '_')}`,
                            descricao: doc.tipo,
                            checkbox: {
                                disabled: !doc.link,
                                tooltip: 'Este documento não possui um anexo (PDF ou imagem).'
                            }
                        }))}
                        checkable
                        tableId="tabela-documentos"
                        checkboxActions={[
                            {
                                icon: FiDownload,
                                handle: selecionados => handleDownloadMultiple(selecionados, showDocumentos.pessoa_id),
                                tooltip: 'Fazer download',
                                display: 'button',
                                text: 'Fazer download',
                                loading: gerandoPDFDocumentos
                            }
                        ]}
                    />
                </SelecaoDocumentosContainer>
            </Modal>
        </>
    )
}
