import styled from 'styled-components'

export const Container = styled.div`
	.table-container {
		.caption {
			font-size: 17px;
			font-weight: 500;
		}
	}
`
