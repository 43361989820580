import React, { useEffect, useState } from 'react'
import { Form, Formik, FieldArray } from 'formik'
import { toast } from 'react-toastify'
import {
    FiPlus, FiSave, FiTrash, FiArrowLeft
} from 'react-icons/fi'
import { format } from 'date-fns'
import { useLocation, useHistory } from 'react-router-dom'

import Card from '../../../../components/Card'
import ibge from '../../../../services/ibge'
import {
    Container, QuestaoLinha, AlternativaLinha, Badge, QuestoesContainer
} from './styles'
import {
    Button, Select, Textarea, Textbox
} from '../../../../components/Form'
import masks from '../../../../util/masks'
import formUtils from '../../../../util/form'
import { api, authHeaders } from '../../../../services/api'
import Yup from '../../../../services/yup'
import Spinner from '../../../../components/Spinner'
import { getDateObject } from '../../../../util/date'

const baseInitialValues = {
    nome: '',
    inicio: '',
    fim: '',
    motivo: '',
    tipo_envio: null,
    periodicidade_envio: '',
    prazo_interrupcao_envio: '',
    quantidade_envios: '',
    visoes: [],
    questoes: [],

    tipo_filtro: [],
    aspirante: null,
    quite: null,
    genero: null,
    especialidades: [],
    faculdade: [],
    local_trabalho: [],
    cidade: []
}

const baseValidation = Yup.object({
    nome: Yup.string().required('Informe o nome.'),
    inicio: Yup.string().date('Informe a data de início.'),
    motivo: Yup.string().required('Escreva o motivo desta enquete.'),
    tipo_envio: Yup.string().ensure(),
    periodicidade_envio: Yup.number().nullable(),
    quantidade_envios: Yup.number(),
    visoes: Yup.array().of(
        Yup.string().ensure()
    ).required('Selecione pelo menos um grupo.'),
    questoes: Yup.array().of(
        Yup.object({
            texto: Yup.string().required('Insira o texto da questão.'),
            alternativas: Yup.array().of(
                Yup.object({
                    texto: Yup.string().required('Insira o texto da alternativa.')
                })
            )
        })
    )
})

const tiposFiltroOptions = [
    { label: 'Por especialidade', value: 'especialidade' },
    { label: 'Por município', value: 'cidade' },
    { label: 'Por local de trabalho', value: 'local_trabalho' },
    { label: 'Por gênero', value: 'genero' },
    { label: 'Por faculdade', value: 'faculdade' },
    { label: 'Quite ou não', value: 'quite' },
    { label: 'Aspirante ou não', value: 'aspirante' }
]

const quiteOptions = [
    { value: 'sim', label: 'Quite' },
    { value: 'não', label: 'Não quite' }
]

const aspiranteOptions = [
    { value: 'sim', label: 'Aspirante' },
    { value: 'não', label: 'Não aspirante' }
]

const generoOptions = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' }
]

export default function () {
    const history = useHistory()
    const location = useLocation()

    const enqueteEdicaoId = location?.state?.enqueteId

    const [initialValues, setInitialValues] = useState(baseInitialValues)
    const [responsaveis, setResponsaveis] = useState(null)
    const [visoes, setVisoes] = useState(null)
    const [quantidadeParticipantes, setQuantidadeParticipantes] = useState(0)
    const [calculandoQuantidadeParticipantes, setCalculandoQuantidadeParticipantes] = useState(false)
    const [especialidades, setEspecialidades] = useState(null)
    const [municipios, setMunicipios] = useState(null)
    const [locaisDeTrabalho, setLocaisDeTrabalho] = useState(null)
    const [faculdades, setFaculdades] = useState(null)
    const [tiposFiltro, setTiposFiltro] = useState([])
    const [loadingDadosEdicao, setLoadingDadosEdicao] = useState(false)
    const [criterioFiltro, setCriterioFiltro] = useState({
        visoes: []
    })
    const [validation, setValidation] = useState(baseValidation)

    async function calcularQuantidadeParticipantes() {
        try {
            setCalculandoQuantidadeParticipantes(true)

            const { quantidade } = await api.patch('enquete/participante/quantidade', criterioFiltro, authHeaders())

            setQuantidadeParticipantes(quantidade)
        } catch (e) {
            console.log(e)

            toast.error(e.message)
        }

        setCalculandoQuantidadeParticipantes(false)
    }

    async function loadResponsaveis() {
        const response = await api.get('pessoa', {
            params: {
                categorias: ['diretor', 'comunicacao']
            },
            ...authHeaders()
        })
        setResponsaveis(response)
    }

    async function loadVisoes() {
        const response = await api.get('visao', authHeaders())

        setVisoes(response)
    }

    async function loadEnqueteEdicao(enqueteId) {
        try {
            setLoadingDadosEdicao(true)

            const response = await api.get(`enquete/${enqueteId}`, {
                params: {
                    scope: 'edicao'
                },
                ...authHeaders()
            })

            const {
                questoes,
                inicio,
                fim,
                prazo_interrupcao_envio,
                tipo_envio,
                quantidade_maxima_envio,
                ...props
            } = response

            let { filtros } = props

            filtros = filtros.reduce((result, f) => ({
                ...result,
                [f.tipo_filtro]: JSON.parse(f.valor_filtro)
            }), {})

            setTiposFiltro(tiposFiltroOptions.filter(f => Object.keys(filtros).includes(f.value)).map(f => f.value))

            const filtrosUsados = tiposFiltroOptions.filter(f => Object.keys(filtros).includes(f.value))

            const initialFiltros = {}
            const initialCriterioFiltro = {}

            for (const current of filtrosUsados) {
                let valueInitialFiltro

                switch (current.value) {
                    case 'quite':
                        valueInitialFiltro = quiteOptions.find(v => v.value === filtros[current.value])
                        break

                    case 'aspirante':
                        valueInitialFiltro = aspiranteOptions.find(v => v.value === filtros[current.value])
                        break

                    case 'genero':
                        valueInitialFiltro = generoOptions.find(v => v.value === filtros[current.value])
                        break

                    case 'faculdade':
                        const facs = faculdades.filter(v => filtros[current.value].includes(v.descricao))
                        valueInitialFiltro = facs.map(f => ({ label: f.descricao, value: f.descricao }))
                        break

                    case 'cidade':
                        const cids = municipios.filter(v => filtros[current.value].includes(v))
                        valueInitialFiltro = cids.map(f => ({ label: f, value: f }))
                        break

                    case 'local_trabalho':
                        const locais = locaisDeTrabalho.filter(l => filtros[current.value].includes(l.id))
                        valueInitialFiltro = locais.map(f => ({ label: f.nome_fantasia, value: f.id }))
                        break

                    case 'especialidade':
                        const espcs = especialidades.filter(l => filtros[current.value].includes(l.id))
                        valueInitialFiltro = espcs.map(f => ({ label: f.descricao, value: f.id }))
                        break

                    default: break
                }

                initialFiltros[current.value] = valueInitialFiltro
                initialCriterioFiltro[current.value] = filtros[current.value]
            }

            initialCriterioFiltro.visoes = filtros.visoes

            const filtrosValores = filtrosUsados.reduce((result, current) => {
                let value

                switch (current.value) {
                    case 'quite':
                        value = quiteOptions.find(v => v.value === filtros[current.value])
                        break

                    case 'aspirante':
                        value = aspiranteOptions.find(v => v.value === filtros[current.value])
                        break

                    case 'genero':
                        value = generoOptions.find(v => v.value === filtros[current.value])
                        break

                    case 'faculdade':
                        const facs = faculdades.filter(v => filtros[current.value].includes(v.descricao))
                        value = facs.map(f => ({ label: f.descricao, value: f.descricao }))
                        break

                    case 'cidade':
                        const cids = municipios.filter(v => filtros[current.value].includes(v))
                        value = cids.map(f => ({ label: f, value: f }))
                        break

                    case 'local_trabalho':
                        const locais = locaisDeTrabalho.filter(l => filtros[current.value].includes(l.id))
                        value = locais.map(f => ({ label: f.nome_fantasia, value: f.id }))
                        break

                    case 'especialidade':
                        const espcs = especialidades.filter(l => filtros[current.value].includes(l.id))
                        value = espcs.map(f => ({ label: f.descricao, value: f.id }))
                        break

                    default: break
                }

                return {
                    ...result,
                    [current.value]: value
                }
            }, {})

            setInitialValues({
                ...props,
                inicio: format(new Date(inicio), 'dd/MM/yyyy HH:mm'),
                fim: format(new Date(fim), 'dd/MM/yyyy HH:mm'),
                prazo_interrupcao_envio: prazo_interrupcao_envio ? format(new Date(prazo_interrupcao_envio), 'dd/MM/yyyy HH:mm') : '',
                quantidade_envios: quantidade_maxima_envio || '',
                tipo_envio: {
                    value: tipo_envio,
                    label: tipo_envio === 'auto' ? 'Automático' : 'Manual'
                },

                visoes: filtros.visoes.map(visao => {
                    const visaoEnquete = visoes.find(v => v.id === visao)

                    return {
                        label: visaoEnquete.descricao,
                        value: visaoEnquete.id
                    }
                }),

                tipo_filtro: filtrosUsados,
                ...filtrosValores,

                questoes: questoes.map(qst => ({
                    id: qst.id,
                    identificador: qst.identificador,
                    texto: qst.texto,
                    alternativas: qst.alternativas.map(alt => ({
                        id: alt.id,
                        texto: alt.texto,
                        identificador: alt.ordem,
                        proxima_questao: alt.proxima_questao_identificador ? {
                            label: `Questão ${alt.proxima_questao_identificador}`,
                            value: alt.proxima_questao_identificador
                        } : null
                    }))
                }))
            })

            setCriterioFiltro(initialCriterioFiltro)
        } catch (e) {
            toast.error(e.message)
        }

        setLoadingDadosEdicao(false)
    }

    function validacaoQuestoesEAlternativas(values) {
        if (!values.questoes?.length) {
            return 'Adicione pelo menos uma questão na enquete.'
        }

        if (!values.questoes.every(quest => !!quest.alternativas?.length)) {
            return 'Existem questões sem alternativas. Corrija e tente novamente.'
        }

        return null
    }

    async function handleSubmit(values) {
        try {
            values = formUtils.extractFormValues(values)

            const mensagemValidacao = validacaoQuestoesEAlternativas(values)

            if (mensagemValidacao) {
                throw new Error(mensagemValidacao)
            }

            const questoes = values.questoes.map(questao => ({
                ...questao,
                alternativas: questao.alternativas.map(alt => {
                    if (alt.proxima_questao) {
                        return {
                            ...alt,
                            proxima_questao: alt.proxima_questao.value
                        }
                    }

                    return alt
                })
            }))

            values = {
                ...values,
                visoes: values.visoes.map(visao => visao.value),
                questoes
            }

            const {
                nome,
                motivo,
                inicio,
                fim,
                tipo_envio,
                periodicidade_envio,
                prazo_interrupcao_envio,
                quantidade_envios
            } = values

            const body = {
                nome,
                motivo,
                inicio,
                fim,
                envio: {
                    tipo: tipo_envio,
                    periodicidade: periodicidade_envio,
                    prazo_interrupcao: prazo_interrupcao_envio,
                    quantidade_envios
                },
                questoes: values.questoes,
                filtros: criterioFiltro
            }

            if (enqueteEdicaoId) {
                await api.put(`enquete/${enqueteEdicaoId}`, body, authHeaders())

                toast.success('A enquete foi atualizada.')
            } else {
                await api.post('enquete', body, authHeaders())

                toast.success('Enquete cadastrada.')
            }

            history.push('/enquete/gerenciar')
        } catch (e) {
            toast.error(e.msg || e.message)
        }
    }

    async function loadEspecialidades() {
        const response = await api.get('especialidade')

        setEspecialidades(response)
    }

    async function loadLocaisTrabalho() {
        const response = await api.get('instituicao', {
            params: {
                types_ids: [2, 3, 4, 5, 6, 7]
            }
        })

        setLocaisDeTrabalho(response)
    }

    async function loadMunicipios() {
        const { data } = await ibge.get('estados/PA/municipios')

        setMunicipios(data.map(({ nome }) => nome).sort())
    }

    async function loadFaculdades() {
        const response = await api.get('faculdade')

        setFaculdades(response)
    }

    function validarDataFim(dataInicio) {
        const dataFimSchema = Yup.object({
            fim: Yup.string()
                .date({
                    min: { value: getDateObject(dataInicio) }
                })
                .required('Informe a data de término.')
        })

        setValidation(old => old.concat(dataFimSchema))
    }

    function validarInterrupcaoDeEnvio() {
        setValidation(old => old.concat(Yup.object({
            prazo_interrupcao_envio: Yup.string().date().nullable()
        })))
    }

    useEffect(() => {
        const porEspecialidade = tiposFiltro.includes('especialidade')
        const porLocalTrabalho = tiposFiltro.includes('local_trabalho')
        const porMunicipio = tiposFiltro.includes('cidade')
        const porFaculdade = tiposFiltro.includes('faculdade')
        const porQuite = tiposFiltro.includes('quite')
        const porAspirante = tiposFiltro.includes('aspirante')
        const porGenero = tiposFiltro.includes('genero')

        setCriterioFiltro(old => ({
            ...old,
            cidade: porMunicipio ? (old.cidade || []) : undefined,
            locais_trabalho: porLocalTrabalho ? (old.locais_trabalho || []) : undefined,
            especialidades: porEspecialidade ? (old.especialidades || []) : undefined,
            quite: porQuite ? old.quite : undefined,
            aspirante: porAspirante ? old.aspirante : undefined,
            genero: porGenero ? old.genero : undefined,
            faculdades: porFaculdade ? old.faculdades : undefined
        }))
    }, [tiposFiltro])

    useEffect(() => {
        calcularQuantidadeParticipantes()
    }, [criterioFiltro])

    useEffect(() => {
        loadResponsaveis()

        loadVisoes()

        loadFaculdades()

        loadMunicipios()

        loadLocaisTrabalho()

        loadEspecialidades()
    }, [])

    useEffect(() => {
        if (enqueteEdicaoId && responsaveis && visoes && faculdades && especialidades) {
            loadEnqueteEdicao(enqueteEdicaoId)
        }
    }, [enqueteEdicaoId, responsaveis, visoes, faculdades, especialidades])

    if (!responsaveis || !visoes) {
        return (
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <Spinner />
                    </Card>
                </main>
            </Container>
        )
    }

    return (
        <Container>
            <main className="animated fadeIn faster">
                <Card>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1>Criação de enquetes</h1>
                        <Button
                            className="transparent"
                            onClick={() => {
                                history.push('/grupo_permissoes/29')
                            }}
                        >
                            <FiArrowLeft size={17} style={{ margin: '0 8 0 0' }} />
                            Voltar
                        </Button>
                    </div>

                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validation}
                    >
                        {({
                            setFieldValue, isSubmitting, values, errors
                        }) => loadingDadosEdicao ? (
                            <Spinner label="Aguarde..." />
                        ) : (
                            <Form>
                                <Textbox label="Nome da enquete" name="nome" containerStyle={{ gridColumn: 'span 2' }} />

                                <Textarea
                                    label="Motivo da enquete"
                                    name="motivo"
                                    containerStyle={{
                                        gridColumn: 'span 2',
                                        gridRow: 'span 2'
                                    }}
                                />

                                <Textbox
                                    label="Início"
                                    name="inicio"
                                    mask={masks.datetime}
                                    onChange={e => {
                                        setFieldValue('inicio', e.target.value)

                                        const dataEstaCompleta = !e.target.value.includes('_')

                                        if (dataEstaCompleta) {
                                            validarDataFim(e.target.value)
                                        }
                                    }}
                                />

                                <Textbox
                                    label="Fim"
                                    name="fim"
                                    mask={masks.datetime}
                                    onChange={e => {
                                        setFieldValue('fim', e.target.value)

                                        const dataEstaCompleta = !e.target.value.includes('_')

                                        if (dataEstaCompleta) {
                                            validarInterrupcaoDeEnvio()
                                        }
                                    }}
                                    disabled={!values.inicio || values.inicio.includes('_')}
                                />

                                <h2>Participantes</h2>

                                <Select
                                    name="visoes"
                                    label="Grupos de participantes da enquete"
                                    onChange={(selected, meta) => {
                                        const visoesAnteriores = values.visoes

                                        setFieldValue(meta.name, selected)

                                        if (visoesAnteriores && selected?.length === visoesAnteriores.length + 1) {
                                            setCriterioFiltro(old => ({
                                                ...old,
                                                visoes: selected.map(i => i.value)
                                            }))
                                        } else {
                                            setCriterioFiltro(old => ({
                                                ...old,
                                                visoes: selected?.map(i => i.value) || []
                                            }))
                                        }
                                    }}
                                    options={visoes.map(visao => ({
                                        label: visao.descricao,
                                        value: visao.id
                                    }))}
                                    isMulti
                                    containerStyle={{ gridColumn: '1/span 2' }}
                                />

                                <Select
                                    name="tipo_filtro"
                                    label="Filtros"
                                    onChange={(selected, meta) => {
                                        setFieldValue(meta.name, selected)

                                        setTiposFiltro(selected?.map(i => i.value) || [])
                                    }}
                                    isMulti
                                    options={tiposFiltroOptions}
                                    placeholder="Selecione o critério..."
                                    containerStyle={{ gridColumn: '3/span 2' }}
                                />

                                <div style={{ gridColumn: '1/span 2' }}>
                                    {calculandoQuantidadeParticipantes ? (
                                        <Spinner
                                            label="Calculando quantidade de participantes..."
                                            labelPosition="right"
                                            containerStyle={{ gridColumn: '3/span 2', justifyContent: 'start', margin: '8px 12px 0' }}
                                        />
                                    ) : (
                                        <>                                       
                                            <Badge>{`Participantes: ${quantidadeParticipantes}`}</Badge>                                           
                                        </>
                                    )}
                                </div>

                                {tiposFiltro.includes('especialidade') && especialidades && (
                                    <Select
                                        name="especialidade"
                                        label="Especialidade médica"
                                        isMulti
                                        options={especialidades.map(espec => ({
                                            label: espec.descricao,
                                            value: espec.id
                                        }))}
                                        containerStyle={{ gridColumn: '3/span 2' }}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setCriterioFiltro(old => ({
                                                ...old,
                                                especialidades: selected?.map(i => i.value) || []
                                            }))
                                        }}
                                    />
                                )}

                                {tiposFiltro.includes('cidade') && municipios && (
                                    <Select
                                        name="cidade"
                                        label="Município"
                                        isMulti
                                        options={municipios.map(munic => ({
                                            label: munic,
                                            value: munic
                                        }))}
                                        containerStyle={{ gridColumn: '3/span 2' }}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setCriterioFiltro(old => ({
                                                ...old,
                                                cidade: selected?.map(i => i.value) || []
                                            }))
                                        }}
                                    />
                                )}

                                {tiposFiltro.includes('local_trabalho') && locaisDeTrabalho && (
                                    <Select
                                        name="local_trabalho"
                                        label="Local de trabalho"
                                        isMulti
                                        options={locaisDeTrabalho.map(local => ({
                                            label: local.nome_fantasia,
                                            value: local.id
                                        }))}
                                        containerStyle={{ gridColumn: '3/span 2' }}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setCriterioFiltro(old => ({
                                                ...old,
                                                locais_trabalho: selected?.map(i => i.value) || []
                                            }))
                                        }}
                                    />
                                )}

                                {tiposFiltro.includes('faculdade') && faculdades && (
                                    <Select
                                        name="faculdade"
                                        label="Faculdade"
                                        isMulti
                                        options={faculdades.map(fac => ({
                                            label: fac.descricao,
                                            value: fac.descricao
                                        }))}
                                        containerStyle={{ gridColumn: '3/span 2' }}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setCriterioFiltro(old => ({
                                                ...old,
                                                faculdades: selected?.map(i => i.value) || []
                                            }))
                                        }}
                                    />
                                )}

                                {tiposFiltro.includes('genero') && (
                                    <Select
                                        name="genero"
                                        label="Gênero"
                                        options={generoOptions}
                                        containerStyle={{ gridColumn: '3/span 2' }}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setCriterioFiltro(old => ({
                                                ...old,
                                                genero: selected.value
                                            }))
                                        }}
                                    />
                                )}

                                {tiposFiltro.includes('aspirante') && (
                                    <Select
                                        name="aspirante"
                                        label="Aspirante"
                                        options={aspiranteOptions}
                                        containerStyle={{ gridColumn: '3/span 2' }}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setCriterioFiltro(old => ({
                                                ...old,
                                                aspirante: selected.value
                                            }))
                                        }}
                                    />
                                )}

                                {tiposFiltro.includes('quite') && (
                                    <Select
                                        name="quite"
                                        label="Quite com o sindicato"
                                        options={quiteOptions}
                                        containerStyle={{ gridColumn: '3/span 2' }}
                                        onChange={(selected, meta) => {
                                            setFieldValue(meta.name, selected)

                                            setCriterioFiltro(old => ({
                                                ...old,
                                                quite: selected.value
                                            }))
                                        }}
                                    />
                                )}

                                <h2>Notificações</h2>

                                <Select
                                    name="tipo_envio"
                                    label="Tipo de notificação para participação"
                                    onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                    options={[
                                        { label: 'Automático', value: 'auto' },
                                        { label: 'Manual', value: 'manual' }
                                    ]}
                                />

                                {values.tipo_envio?.value === 'auto' && (
                                    <>
                                        <Textbox label="Periodicidade das notificações (em dias)" name="periodicidade_envio" type="number" />

                                        <Textbox
                                            label="Prazo para interromper as notificações"
                                            name="prazo_interrupcao_envio"
                                            mask={masks.datetime}
                                            disabled={!values.fim || values.fim.includes('_')}
                                        />

                                        <Textbox label="Número máximo de notificações (opcional)" name="quantidade_envios" type="number" />
                                    </>
                                )}

                                <h2>Questões da enquete</h2>

                                <FieldArray
                                    name="questoes"
                                    render={arrayHelpers => (
                                        <QuestoesContainer>
                                            {values.questoes.map((questao, idx) => (
                                                <QuestaoLinha key={idx}>
                                                    <summary>
                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'start',
                                                                    gap: 4
                                                                }}
                                                            >
                                                                <h1>{`Questão ${questao.identificador}`}</h1>
                                                                {questao.texto && (
                                                                    <p>{`${questao.texto?.substring(0, 50)}...`}</p>
                                                                )}
                                                            </div>

                                                            <FiTrash
                                                                onClick={() => {
                                                                    arrayHelpers.remove(idx)
                                                                }}
                                                                size={16}
                                                            />
                                                        </div>
                                                    </summary>

                                                    <div className="content">
                                                        <Textarea
                                                            name={`questoes.${idx}.texto`}
                                                            label="Texto da questão"
                                                        />

                                                        {errors.questoes?.[idx]?.texto && (
                                                            <span style={{
                                                                color: '#424242', backgroundColor: 'gold', padding: 4, marginLeft: 8, borderRadius: 6
                                                            }}
                                                            >
                                                                {errors.questoes[idx]?.texto}
                                                            </span>
                                                        )}

                                                        <FieldArray
                                                            name={`questoes.${idx}.alternativas`}
                                                            render={arrayHelpers2 => (
                                                                <div>
                                                                    {values.questoes[idx].alternativas.map((alt, idx2) => (
                                                                        <AlternativaLinha key={idx2}>
                                                                            <div style={{
                                                                                display: 'flex', flexDirection: 'column', gap: 4, width: '100%'
                                                                            }}
                                                                            >
                                                                                <Textbox
                                                                                    name={`questoes.${idx}.alternativas.${idx2}.texto`}
                                                                                    label={`Alternativa ${idx2 + 1}`}
                                                                                />
                                                                            </div>

                                                                            <Select
                                                                                name={`questoes.${idx}.alternativas.${idx2}.proxima_questao`}
                                                                                label="Ir para"
                                                                                placeholder="Fim da enquete"
                                                                                onChange={(selected, meta) => { setFieldValue(meta.name, selected) }}
                                                                                options={[
                                                                                    ...values.questoes
                                                                                        .filter(quest => quest.identificador > questao.identificador)
                                                                                        .map(q => ({
                                                                                            label: `Questão ${q.identificador}`,
                                                                                            value: q.identificador
                                                                                        })),
                                                                                    { label: 'Finalizar enquete', value: null }
                                                                                ]}
                                                                                containerStyle={{ width: 200 }}
                                                                            />

                                                                            <FiTrash
                                                                                onClick={() => {
                                                                                    arrayHelpers2.remove(idx2)
                                                                                }}
                                                                                size={16}
                                                                            />
                                                                        </AlternativaLinha>
                                                                    ))}

                                                                    <div className="button-container">
                                                                        <Button
                                                                            onClick={() => {
                                                                                arrayHelpers2.push({
                                                                                    proxima_questao: null,
                                                                                    texto: ''
                                                                                })
                                                                            }}
                                                                            className="white"
                                                                        >
                                                                            Adicionar alternativa
                                                                            <FiPlus size={16} />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </QuestaoLinha>
                                            ))}

                                            <Button
                                                onClick={() => {
                                                    const novoIdentificador = values.questoes.length + 1

                                                    arrayHelpers.push({
                                                        alternativas: [],
                                                        identificador: novoIdentificador
                                                    })

                                                    if (novoIdentificador === 1) {
                                                        return
                                                    }

                                                    const questaoAnterior = values.questoes.find(q => q.identificador === novoIdentificador - 1)

                                                    for (let i = 0; i < questaoAnterior.alternativas.length; i++) {
                                                        setFieldValue(`questoes.${questaoAnterior.identificador - 1}.alternativas.${i}.proxima_questao`, {
                                                            label: `Questão ${novoIdentificador}`,
                                                            value: novoIdentificador
                                                        })
                                                    }
                                                }}
                                                className="transparent"
                                            >
                                                Adicionar questão
                                                <FiPlus size={16} />
                                            </Button>
                                        </QuestoesContainer>
                                    )}
                                />

                                <div style={{ display: 'flex', justifyContent: 'end', gridColumn: '1/span 4' }}>
                                    <Button type="submit" loading={isSubmitting} className="white" style={{ width: 200 }}>
                                        {enqueteEdicaoId ? 'Atualizar enquete' : 'Criar enquete'}
                                        <FiSave size={16} />
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </main>
        </Container>
    )
}
