import styled from 'styled-components'

import Card from '../../../components/Card'

export const Container = styled(Card)`
	grid-area: pessoal;
	display: grid;
	grid-template-columns: auto 24px;
	grid-template-rows: min-content auto;
	grid-template-areas:
		'title edit_icon'
		'pessoal pessoal';
	
	> h1 {
		font-size: 19px;
		font-weight: 500;
	}

	> div {
		grid-area: pessoal;
		margin-top: 24px;
		display: flex;
		flex-wrap: wrap;
		align-self: flex-start;
		line-height: 24px;
		
		span {
			margin-right: 28px;
		}
	}

	> svg {
		color: #FFF;
		transition: 300ms;

		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}
	}

	.spinner-container {
		grid-area: pessoal;
		justify-self: center;
	}

	@media(max-width: 440px) {
		margin: 0;
		border-radius: 0;
	}
`

export const ModalContainer = styled.div`
	form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			'f1 f2 f3'
			'f4 f5 x'
			'button button button';

		.action-footer {
			grid-area: button;
		}

		@media(max-width: 800px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				'f1 f2'
				'f3 f4'
				'f5 x'
				'button button';
		}

		@media(max-width: 550px) {
			display: flex;
			flex-direction: column;
		}
	}
`
