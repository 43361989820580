import styled, { keyframes, css } from 'styled-components'

import { ProgressBar } from 'primereact/progressbar'

const flash = keyframes`
	0% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
`

const config = css`
	.p-progressbar-indeterminate > div > div:before,
	.p-progressbar-indeterminate > div > div:after {
		background-color: ${props => props.backgroundColor || '#388E3C'};
	}

	.progress-label {
		animation: ${flash} 600ms infinite alternate;
		text-align: center;
		width: 100%;
	}
`

export const ContainerDefault = styled.div.attrs({
    className: 'progress-bar'
})`
	max-width: 400px;
	width: 100%;
	font-size: 14px;
	text-align: center;
	height: 44px;
	display: ${props => props.visible ? 'block' : 'none'}; 

	${config}
`

export const ProgressDefault = styled(ProgressBar)`
	border-radius: 3px;
	height: 6px;
	margin-bottom: 8px;
`

export const ContainerModal = styled.div.attrs({
    className: 'progress-bar'
})`
	display: ${props => props.visible ? 'block' : 'none'};
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #000C;
	z-index: 10;
`

export const ProgressWindow = styled.div`
	width: 300px;
	background-color: #547456;
	padding: 50px;
	box-shadow: 3px 3px 33px #000;
	position: fixed;
	z-index: 11;
	left: calc(50% - 150px);
	top: calc(50% - 60px);
	border-radius: 10px;
	text-align: center;

	${config}
`

export const ProgressModal = styled(ProgressBar)`
	border-radius: 3px;
	height: 6px;
	margin-bottom: 8px;
`

export const ProgressPercentageContainer = styled.div.attrs({
    className: 'progress-bar'
})`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ProgressPercentage = styled(ProgressBar)`
	border-radius: 4px;
	width: calc(100% - 16px);
	margin-top: 14px;

	& + .hint {
		font-size: 12px;
		text-align: center;
		margin-top: 4px;
	}

	${props => props.value === 100 ? css`
		animation: ${flash} 1s linear infinite alternate;
		animation-iteration-count: 3;

		.p-progressbar-value {
			background-color: #4caf50 !important;
		}
		.p-progressbar-label {
			color: #fff !important;
		}` : null}
	
	.p-progressbar-value {
		background-color: #FFEB3B;
	}
`
