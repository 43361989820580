import styled, { css } from 'styled-components'

const filterFocusStyle = css`
	&:focus {
		box-shadow: none !important;
		border-bottom: solid 2px #FFFD;

		& + .p-listbox-filter-icon {
			opacity: 0.9;
		}
	}
`

export const Container = styled.div`
	margin: 5px 8px;
	width: calc(100% - 16px);

	> label {
		font-size: 11px;
	}

	.p-listbox {
		width: 100%;
		border-radius: 12px;
		background-color: transparent;
		border: none;

		.p-listbox-list {
			background-color: rgba(255,255,255,0.8);

			> .p-listbox-item {
				padding: 8px 10px !important;
				border: solid 1px #0002;

				&:hover {
					background-color: #0001 !important;
				}

				&:focus {
					box-shadow: none;
				}

				${props => props.selectedIndex !== null && css`
					&:nth-child(${props.selectedIndex + 1}) {
						background-color: #0002 !important;
					}
				`}
			}
		}

		.p-listbox-header {
			background-color: transparent !important;
			border: none !important;

			.p-listbox-filter-container {
				margin-bottom: 10px;

				.p-inputtext {
					border: none;
					background-color: transparent;
					color: #FFF;
					border-bottom: solid 1px #FFF9;
					border-radius: unset;
					height: 44px;
				
					${filterFocusStyle}

					&::placeholder {
						color: #FFF7;
					}
				}

				.p-listbox-filter-icon {
					color: #FFF;
					opacity: 0.7;
				}
			}
		}
	}
`
