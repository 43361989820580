import React, { useContext } from 'react'
import { isBefore } from 'date-fns'
import {
    FaCheck, FaEdit, FaTimes, FaUserAltSlash, FaUserClock 
} from 'react-icons/fa'

import Card from '../../../../../components/Card'
import Table from '../../../../../components/Table'
import Spinner from '../../../../../components/Spinner'
import { Button } from '../../../../../components/Form'

import { AtendimentoContext } from '../../../../../contexts/AtendimentoContext'
import { GlobalContext } from '../../../../../contexts/GlobalContext'

import { Container } from './styles'

export default function () {
    const { user } = useContext(GlobalContext)
    const {
        setAgendamentoEdit, setAgendamentoExtraEdit, setShowAtendimentoExtra, agendamentosGlobal, handleAtualizarStatus 
    } = useContext(AtendimentoContext)

    const naoAgendado = item => item.status.id !== 1
    const eFuturo = item => isBefore(new Date(), new Date(`${item.data} ${item.inicio}`))

    return (
        <Container>
            <main>
                <Card expandOffset={500}>
                    <h1>Agenda de atendimentos</h1>

                    {user?.perfil.id === 23 && (
                        <Button onClick={() => { setShowAtendimentoExtra(true) }} className="transparent">
                            Marcar atendimento extraordinário
                            <FaUserClock size={18} />
                        </Button>
                    )}

                    {agendamentosGlobal ? (
                        <Table 
                            headers={[
                                {
                                    name: 'codigo_demanda', value: 'Demanda', centered: true, style: { width: 110 }
                                },
                                { name: 'associado', value: 'Associado' },
                                { name: 'atendente_nome', value: 'Atendente' },
                                {
                                    name: 'data_hora', value: 'Data / hora', centered: true, style: { width: 134 } 
                                },
                                { name: 'status_descricao', value: 'Status', centered: true }
                            ]}
                            data={agendamentosGlobal || []}
                            actions={[
                                {
                                    action: item => { 
                                        if(item.extra) {
                                            setAgendamentoExtraEdit(item)
                                        } else {
                                            setAgendamentoEdit(item)
                                        }
                                    },
                                    name: 'Editar',
                                    icon: FaEdit,
                                    iconSize: 16,
                                    color: '#2196f3',
                                    checkDisabled: naoAgendado,
                                    disabledTooltip: 'Ação não disponível'
                                },
                                {
                                    action: item => { handleAtualizarStatus(item, 2) },
                                    name: 'Não compareceu',
                                    icon: FaUserAltSlash,
                                    iconSize: 16,
                                    color: '#424242',
                                    confirmation: 'Deseja confirmar que o associado NÃO COMPARECEU ao atendimento presencial?',
                                    checkDisabled: item => naoAgendado(item) || eFuturo(item),
                                    disabledTooltip: 'Ação não disponível'
                                },
                                {
                                    action: item => { handleAtualizarStatus(item, 4) },
                                    name: 'Cancelar',
                                    icon: FaTimes,
                                    iconSize: 16,
                                    color: '#f44336',
                                    confirmation: 'Deseja realmente CANCELAR este atendimento presencial?',
                                    checkDisabled: item => naoAgendado(item),
                                    disabledTooltip: 'Ação não disponível'
                                },
                                {
                                    action: item => { handleAtualizarStatus(item, 3) },
                                    name: 'Atendido',
                                    icon: FaCheck,
                                    iconSize: 16,
                                    color: '#4caf50',
                                    confirmation: 'Deseja realmente REGISTRAR este atendimento presencial?',
                                    checkDisabled: item => naoAgendado(item) || eFuturo(item),
                                    disabledTooltip: 'Ação não disponível'
                                }
                            ]}
                            filterable
                            legends={[
                                {
                                    color: '#fff59d',
                                    text: 'Agendamentos passados'
                                },
                                {
                                    color: '#c8e6c9',
                                    text: 'Atendimentos realizados'
                                }
                            ]}
                        />
                    ) : (
                        <Spinner />
                    )}
                </Card>
            </main>
        </Container>
    )
}
