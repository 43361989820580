import styled from 'styled-components'

export const Container = styled.div`
	min-height: 82vh;

	main {
		padding: 35px 50px 35px 90px;

		.card {
			min-height: 80vh;

			> h1 {
				margin: 0 8px;
			}

			> p {
				margin-top: 10px;
				margin-bottom: 18px;
				margin-left: 9px;
			}

			.totais {
				border: solid 1px red;
				padding: 8px;
				line-height: 24px;
			}
		}
	}
`

export const RelatoriosContainer = styled.div`
	margin: 24px 8px 0;
	display: grid;
	grid-template-columns: 1fr 4fr;
	gap: 12px;
`

export const Selector = styled.ul`
	li {	
		padding: 12px;
		border: solid 1px #fff5;

		&:hover {
			background-color: #0002;
			cursor: pointer;
			transition-duration: 200ms;
		}

		&:nth-child(${props => props.selected + 1}) {
			background-color: #0003;
		}
	}
`

export const Report = styled.div`
	h1 {
		font-size: 18px;
	}
`
