import React from 'react'

import Listagem from './Listagem'

import ColaboradorProvider from '../../../contexts/ColaboradorContext'
import Cadastro from './Cadastro'

export default function () {
    return (
        <ColaboradorProvider>
            <Listagem />

            <Cadastro />
        </ColaboradorProvider>
    )
}
