import React, { createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

import formUtils from '../util/form'

export const VisaoContext = createContext()

const VisaoProvider = ({ children }) => {
    const [visoes, setVisoes] = useState(null)
    const [showAddGrupo, setShowAddGrupo] = useState(false)

    async function loadVisoes() {
        const response = await api.get('visao?with_count=1', authHeaders())

        setVisoes(response)
    }

    async function handleSumit(values) {
        values = formUtils.extractFormValues(values)

        setVisoes(null)

        try {
            await api.post('visao', values, authHeaders())

            toast.success('Grupo criado com sucesso.')

            setShowAddGrupo(false)
        } catch (e) {
            toast.error(e.msg)
        }

        loadVisoes()
    }

    async function handleDelete(id) {
        setVisoes(null)

        try {
            await api.delete(`visao/${id}`, authHeaders())

            toast.success('Grupo excluído com sucesso.')

            loadVisoes()

            return true
        } catch (e) {
            toast.error(e.msg)

            loadVisoes()

            return false
        }
    }

    useEffect(() => {
        loadVisoes()
    }, [])

    return (
        <VisaoContext.Provider value={{
            visoes,
            showAddGrupo,
            setShowAddGrupo,
            handleSumit,
            handleDelete
        }}
        >
            {children}
        </VisaoContext.Provider>
    )
}

export default VisaoProvider
