import React, { createContext, useState } from 'react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'

import { api, authHeaders } from '../services/api'

import formUtils from '../util/form'
import maskUtils from '../util/masks'

export const PlanoSaudeContext = createContext()

const PlanoSaudeProvider = ({ children }) => {
    const [contribuicoes, setContribuicoes] = useState(null)
    const [parametrosBusca, setParametrosBusca] = useState(null)

    async function handleSearch(values, refresh = false) {
        if (!refresh) {
            setContribuicoes('loading')
        }

        setParametrosBusca(values)

        values = formUtils.extractFormValues(values)

        const {
            crm, nome, status, tipo_beneficiario
        } = values
        let { competencia } = values

        const query = {}

        let associados

        if (crm) {
            associados = await api.get('associado', {
                params: { crm }
            })

            if (!associados?.length) {
                toast.warn('Nenhum associado com este CRM foi encontrado.')
                return
            }

            query.associado_id = associados[0].id
        }

        if (competencia) {
            if (competencia[1] !== null) {
                competencia = `${format(competencia[0], 'yyyy-MM-dd')}|${format(competencia[1], 'yyyy-MM-dd')}`
            } else {
                competencia = `${format(competencia[0], 'yyyy-MM-dd')}|${format(competencia[0], 'yyyy-MM-dd')}`
            }

            query.competencia = competencia
        }

        if (nome) {
            query.nome = nome
        }

        if (status !== null) {
            query.status = status
        }

        if (tipo_beneficiario !== null) {
            query.tipo_beneficiario = tipo_beneficiario
        }

        const response = await api.get('contribuicao_plano_saude', {
            params: query,
            ...authHeaders()
        })

        setContribuicoes(response.map(contribuicao => ({
            id: contribuicao.id,
            crm: contribuicao.associado.pessoa.documentos.find(doc => doc.tipo_documento?.id === 3)?.identificador || contribuicao.associado.pessoa.documentos.find(doc => doc.tipo_documento?.id === 2)?.identificador,
            nome: contribuicao.associado.pessoa.nome,
            competencia: format(new Date(contribuicao.competencia), 'MM/yyyy'),
            valor: maskUtils.maskApply.currency(contribuicao.valor),
            data_consolidacao: contribuicao.data_consolidacao ? format(new Date(contribuicao.data_consolidacao), 'dd/MM/yyyy') : '',
            status: contribuicao.status ? 'Efetuado' : 'Pendente',
            status_bool: contribuicao.status,
            tipo_cobranca: contribuicao.tipo_cobranca.descricao,
            link_fatura: contribuicao.link_fatura
        })))
    }

    async function handleDelete(contribuicao) {
        try {
            await api.delete(`contribuicao_plano_saude/${contribuicao.id}`, authHeaders())

            toast.success('Contribuição excluída com sucesso.')

            handleSearch(parametrosBusca, true)

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    async function handleDeleteMultiple(ids) {
        try {
            const {
                msg: msgReponse,
                success
            } = await api.post('contribuicao_plano_saude/delete', {
                contribuicoes: ids
            }, authHeaders())

            if (success) {
                toast.success(msgReponse)
            } else {
                toast.warn(msgReponse)
            }

            handleSearch(parametrosBusca, true)

            return true
        } catch (e) {
            toast.error(e.msg)

            return false
        }
    }

    return (
        <PlanoSaudeContext.Provider value={{
            contribuicoes,
            handleSearch,
            handleDelete,
            handleDeleteMultiple
        }}
        >
            {children}
        </PlanoSaudeContext.Provider>
    )
}

export default PlanoSaudeProvider
