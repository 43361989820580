import React, { useContext } from 'react'
import { FiEye, FiPlus } from 'react-icons/fi'
import { FaRegCalendarAlt } from 'react-icons/fa'

import Modal from '../../../../components/Modal'
import Card from '../../../../components/Card'
import { Button } from '../../../../components/Form'
import Table from '../../../../components/Table'

import { Container } from './styles'

import Cadastro from '../Cadastro'
import Agenda from '../Agenda'
import Detalhes from '../Detalhes'

import { AdvogadoContext } from '../../../../contexts/AdvogadoContext'
import Spinner from '../../../../components/Spinner'

export default function () {
    const {
        setShowCadastro, showCadastro, advogados, handlePrepareEdit, advogadoEdit, setAdvogadoEdit, handleDelete, setShowFormAgenda, showFormAgenda, setShowDetalhes, showDetalhes 
    } = useContext(AdvogadoContext)

    return (
        <>
            <Container>
                <main className="animated fadeIn faster">
                    <Card>
                        <h1>Advogados cadastrados</h1>

                        <div className="button-container">
                            <Button onClick={() => { setShowCadastro(true) }} className="transparent">
                                Cadastrar
                                <FiPlus />
                            </Button>
                        </div>

                        {advogados ? (
                            <Table 
                                headers={[
                                    { name: 'nome', value: 'Nome' },
                                    { name: 'oab', value: 'OAB', centered: true },
                                    { name: 'areas_lista', value: 'Áreas de atuação', centered: true },
                                    { name: 'plantonista', value: 'Plantonista', centered: true }
                                ]}
                                data={advogados || []}
                                handlePrepareEdit={handlePrepareEdit}
                                handleDelete={handleDelete}
                                confirmExclusion={{
                                    attr: 'nome',
                                    template: 'Deseja realmente excluir o advogado #attr#?'
                                }}
                                actions={[
                                    {
                                        action: setShowFormAgenda,
                                        name: 'Agenda de plantões',
                                        icon: FaRegCalendarAlt,
                                        iconSize: 15
                                    },
                                    {
                                        action: setShowDetalhes,
                                        name: 'Detalhar',
                                        icon: FiEye,
                                        iconSize: 16
                                    }
                                ]}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Card>
                </main>
            </Container>

            <Modal
                isOpen={!!showCadastro || !!advogadoEdit}
                title={`${showCadastro ? 'Cadastrar' : 'Editar'} advogado`}
                handleClose={() => { 
                    setShowCadastro(false) 
                    setAdvogadoEdit(null)
                }}
            >
                <Cadastro />
            </Modal>

            <Modal
                isOpen={!!showFormAgenda}
                handleClose={() => { setShowFormAgenda(null) }}
                title={`Agenda de plantões - ${showFormAgenda?.nome}`}
            >
                <Agenda />
            </Modal>

            <Modal
                isOpen={!!showDetalhes}
                handleClose={() => { setShowDetalhes(null) }}
                title={showDetalhes ? `Detalhes - ${showDetalhes?.nome}` : ''}
            >
                <Detalhes />
            </Modal>
        </>
    )
}
