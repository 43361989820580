import React, { useState, useEffect } from 'react'
import { FiPlusCircle, FiPlus, FiXCircle } from 'react-icons/fi'
import {
    FaEdit, FaHospitalAlt, FaPlus, FaRegSave, FaTrash
} from 'react-icons/fa'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import Table from '../../../components/Table'
import Spinner from '../../../components/Spinner'
import {
    Button, Select, Textbox, Checkbox
} from '../../../components/Form'
import FormEndereco from '../../../components/FormEndereco'

import { api, authHeaders } from '../../../services/api'
import Yup from '../../../services/yup'

import formUtils from '../../../util/form'
import masks from '../../../util/masks'
import inputUtils from '../../../util/input'

import { Container, AddInstituicao, FormNovoLocal } from './styles'

export default function ({
    pessoa, reload, editable = true, disabled = false
}) {
    const [instituicoes, setInstituicoes] = useState([])
    const [showNovoLocal, setShowNovoLocal] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [showFormLocalPersonalizado, setShowFormLocalPersonalizado] = useState(false)
    const [initialValues, setInitialValues] = useState(null)
    const [editId, setEditId] = useState(null)

    useEffect(() => {
        async function loadInstituicoes() {
            const response = await api.get('/instituicao')

            setInstituicoes(response.filter(instituicao => instituicao.id !== 13186 && !['banco', 'micro-empresa'].includes(instituicao.tipo_instituicao.descricao)))
        }

        loadInstituicoes()
    }, [])

    async function handleAddInstituicao(values, { resetForm }) {
        const data = formUtils.extractFormValues(values)
        const { id: associadoId } = pessoa.associado

        setShowNovoLocal(false)

        try {
            await api.post('associado_instituicao', {
                instituicao_id: data.instituicao,
                associadoId
            }, authHeaders())

            resetForm()

            await reload()

            setSubmitting(false)
        } catch (e) {
            toast.error('Erro ao adicionar local.')
            setShowNovoLocal(true)
        }
    }

    async function handleAddLocal(values, { resetForm }) {
        const nome = document.querySelector('#nome')
        const contato = document.querySelector('#contato')
        const correspondencia = document.querySelector('#correspondencia')

        const { id: associadoId } = pessoa.associado

        const errorSpans = document.querySelector('.field > input + .error')
        if (errorSpans) {
            errorSpans.remove()
        }

        if (!nome.value) {
            const errorSpan = inputUtils.generateErrorSpan('Defina o nome do consultório.')
            nome.after(errorSpan)
            setSubmitting(false)
            return
        }

        if (!contato.value) {
            const errorSpan = inputUtils.generateErrorSpan('Preencha o telefone de contato.')
            contato.after(errorSpan)
            setSubmitting(false)
            return
        }

        values.uf = values.uf.value
        values.cidade = values.cidade.value

        setShowNovoLocal(false)
        setShowFormLocalPersonalizado(false)

        try {
            await api.post('local_trabalho', {
                associado_id: associadoId,
                nome: nome.value,
                endereco: values,
                correspondencia: correspondencia.checked,
                contato: contato.value
            }, authHeaders())

            resetForm()

            await reload()

            setSubmitting(false)
        } catch (e) {
            toast.error('Ocorreu um erro ao cadastrar o consultório.')

            setShowNovoLocal(true)
            setShowFormLocalPersonalizado(true)
        }
    }

    async function handleDeleteLocal(id) {
        try {
            const local = pessoa?.locais_trabalho.find(l => l.id === id)

            if (local.editable) { // Local de trabalho
                await api.delete(`local_trabalho/${id}`, authHeaders())
            } else { // Instituição
                const associadoId = pessoa?.associado.id

                await api.delete(`associado_instituicao/${associadoId}/${id}`, authHeaders())
            }

            await reload()

            return true
        } catch (e) {
            return false
        }
    }

    async function handlePrepareEdit(local) {
        const response = await api.get(`local_trabalho/${local.id}`, authHeaders())

        setEditId(local.id)

        setShowNovoLocal(false)
        setShowFormLocalPersonalizado(true)

        document.querySelector('#nome').value = response.nome
        document.querySelector('#contato').value = response.contato?.contato || ''
        document.querySelector('#correspondencia').checked = response.receber_correspondencia || false

        setInitialValues(response.endereco)
    }

    async function handleEditLocal(values, { resetForm }) {
        const nome = document.querySelector('#nome')
        const contato = document.querySelector('#contato')
        const correspondencia = document.querySelector('#correspondencia')

        const errorSpans = document.querySelector('.field > input + .error')
        if (errorSpans) {
            errorSpans.remove()
        }

        if (!nome.value) {
            const errorSpan = inputUtils.generateErrorSpan('Defina o nome do consultório.')
            nome.after(errorSpan)
            setSubmitting(false)
            return
        }

        if (!contato.value) {
            const errorSpan = inputUtils.generateErrorSpan('Preencha o telefone de contato.')
            contato.after(errorSpan)
            setSubmitting(false)
            return
        }

        values.uf = values.uf.value
        values.cidade = values.cidade.value

        setShowNovoLocal(false)
        setShowFormLocalPersonalizado(false)

        try {
            await api.put(`local_trabalho/${editId}`, {
                associado_id: pessoa?.associado.id,
                nome: nome.value,
                endereco: values,
                correspondencia: correspondencia.checked,
                contato: contato.value
            }, authHeaders())

            resetForm()

            await reload()

            setInitialValues(null)
            setSubmitting(false)

            setEditId(null)

            toast.success('Dados alterados com sucesso.')
        } catch (e) {
            console.log(e)
            toast.error(e.message)

            setShowNovoLocal(true)
            setShowFormLocalPersonalizado(true)
        }
    }

    return (
        <Container className="animated fadeInUp delay-600ms" disabled={disabled}>
            <h1>Local de Trabalho</h1>

            {editable ? (
                <>
                    {showNovoLocal || showFormLocalPersonalizado ? (
                        <FiXCircle
                            size={24}
                            data-tip="Fechar"
                            data-for="tooltip"
                            onClick={() => {
                                setShowNovoLocal(showFormLocalPersonalizado)
                                setShowFormLocalPersonalizado(false)
                                setInitialValues(null)
                            }}
                        />
                    ) : (
                        <FiPlusCircle
                            size={24}
                            data-tip="Adicionar"
                            data-for="tooltip"
                            onClick={() => {
                                setShowNovoLocal(true)
                                setShowFormLocalPersonalizado(false)
                            }}
                        />
                    )}
                </>
            ) : (
                <div />
            )}

            {showNovoLocal && (
                <Formik
                    initialValues={{ instituicao: '' }}
                    validationSchema={Yup.string().ensure().required('Forneça o nome do local.')}
                    onSubmit={handleAddInstituicao}
                >
                    {({ setFieldValue }) => (
                        <Form className="animated fadeIn">
                            <AddInstituicao>
                                <Select
                                    name="instituicao"
                                    label="Local"
                                    onChange={(selected, actionMeta) => setFieldValue(actionMeta.name, selected)}
                                    options={instituicoes.map(local => ({
                                        label: `${local.razao_social} ${local.sigla ? `(${local.sigla})` : ''}`,
                                        value: local.id
                                    }))}
                                />

                                <Button type="submit" disabled={submitting} className="transparent add-button">
                                    Adicionar
                                    <FiPlus />
                                </Button>

                                <span>ou</span>

                                <Button
                                    className="transparent new-button"
                                    onClick={() => {
                                        setShowNovoLocal(false)
                                        setShowFormLocalPersonalizado(true)
                                    }}
                                >
                                    Cadastrar um novo
                                    <FaHospitalAlt />
                                </Button>
                            </AddInstituicao>
                        </Form>
                    )}
                </Formik>
            )}

            {showFormLocalPersonalizado && (
                <FormNovoLocal>
                    <div>
                        <Textbox label="Local" name="nome_local_trabalho" withoutForm id="nome" />

                        <Textbox
                            label="Telefone"
                            name="contato"
                            id="contato"
                            withoutForm
                            maxLength={11}
                            onBlur={() => {
                                const field = document.querySelector('#contato')
                                const { length } = field.value.match(/\d/g)

                                if (length === 11) {
                                    field.value = masks.maskApply.mobile(field.value)
                                } else if (length === 10) {
                                    field.value = masks.maskApply.phone(field.value)
                                }
                            }}
                        />

                        <Checkbox
                            label="Quero receber correspondências do Sindmepa em meu consultório"
                            name="correspondencia"
                            id="correspondencia"
                            withoutForm
                        />
                    </div>

                    <FormEndereco
                        defaultValues={initialValues}
                        onSubmit={initialValues ? handleEditLocal : handleAddLocal}
                        buttonDisabled={submitting}
                        buttonContent={(
                            <>
                                {initialValues ? (
                                    <>
                                        Salvar alterações
                                        <FaRegSave color="#424242" />
                                    </>
                                ) : (
                                    <>
                                        Cadastrar
                                        <FaPlus color="#424242" />
                                    </>
                                )}
                            </>
                        )}
                    />
                </FormNovoLocal>
            )}

            {pessoa ? (
                <Table
                    headers={[
                        { name: 'nome_local_trabalho', value: 'Local' },
                        { name: 'endereco', value: 'Endereço' },
                        {
                            name: 'contato', value: 'Contato', centered: true, style: { width: 130 }
                        },
                        {
                            name: 'correspondencia', value: 'Correspondência', icon: true, style: { width: 160 }
                        }
                    ]}
                    data={pessoa?.locais_trabalho ? pessoa?.locais_trabalho.map(local => ({
                        ...local,
                        correspondencia: local.receber_correspondencia ? 'FaCheck' : 'FaTimes',
                        iconColor: local.receber_correspondencia ? '#4caf50' : '#f44336',
                        contato: local.contato?.contato || ''
                    })) : []}
                    actions={editable && [
                        {
                            action: handlePrepareEdit,
                            name: 'Editar',
                            icon: FaEdit,
                            checkDisabled: item => !item.editable
                        },
                        {
                            action: item => { handleDeleteLocal(item.id) },
                            name: 'Remover',
                            icon: FaTrash,
                            checkDisabled: item => item.desconto
                        }
                    ]}
                    hideOnSmallHeaders={['endereco', 'contato']}
                    hideOffset={1000}
                    emptyLabel="Nenhum local adicionado"
                />
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
